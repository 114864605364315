import {
  Box,
  HStack,
  Image,
  Pressable,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import {IPAD_WIDTH, IPAD_MINI_WIDTH} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {INBOX_CHANNEL_TYPE, INTEGRATION_CONSTANTS} from '../../constants';
import {styles} from '../../IntegrationsStyles';
import {IIntegrationsScreenOneProps} from '../../interfaces';

export const IntegrationScreenOne = (props: IIntegrationsScreenOneProps) => {
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  return (
    <VStack
      style={[
        styles.boxStack,
        {justifyContent: 'center', alignItems: 'center'},
      ]}
      flex={1}
      space={8}
    >
      <DisplayText
        textType="Heading"
        textLocalId="chooseChannel"
        size={'mdBold'}
        extraStyles={{fontSize: 22}}
      />
      <Text
        textAlign={'center'}
        color={Colors.Custom.DataKeyColor}
        size={'smLight'}
      >{`We support live-chat widget, WhatsApp, Email etc., as channels. If you want to build a custom channel, you can create it using
the API channel. Select one channel from the options below to proceed.`}</Text>

      {!isSmallScreen && (
        <VStack>
          <HStack>
            <Pressable
              onPress={() =>
                props.handleScreenOneChange(INTEGRATION_CONSTANTS.SCREEN_TWO, {
                  inboxChannelCode: INBOX_CHANNEL_TYPE.WEB_SITE,
                })
              }
            >
              <Box style={styles.channelBox}>
                <VStack style={styles.centerAlign} space={1}>
                  <Image
                    style={{height: 140, width: 140}}
                    source={{
                      uri: require('../../assets/website.png'),
                    }}
                    size="lg"
                  ></Image>
                  <Text size={'mdNormal'}>Website</Text>
                </VStack>
              </Box>
            </Pressable>

            <Box style={styles.channelBox}>
              <VStack style={styles.centerAlign} space={2}>
                <Pressable
                  onPress={() =>
                    props.handleScreenOneChange(
                      INTEGRATION_CONSTANTS.SCREEN_TWO,
                      {
                        inboxChannelCode: 'SMS',
                      }
                    )
                  }
                >
                  <Image
                    style={{height: 140, width: 140}}
                    source={{
                      uri: require('../../assets/sms.png'),
                    }}
                    size="lg"
                  ></Image>
                  <VStack alignItems={'center'}>
                    <Text size={'mdNormal'}>SMS</Text>
                  </VStack>
                </Pressable>
              </VStack>
            </Box>

            <Box style={styles.channelBox}>
              <VStack style={styles.centerAlign} space={2}>
                <Pressable
                  onPress={() =>
                    props.handleScreenOneChange(
                      INTEGRATION_CONSTANTS.SCREEN_TWO,
                      {
                        inboxChannelCode: INBOX_CHANNEL_TYPE.EMAIL,
                      }
                    )
                  }
                >
                  <Image
                    style={{height: 140, width: 140}}
                    source={{
                      uri: require('../../assets/email.png'),
                    }}
                    size="lg"
                  ></Image>
                  <VStack alignItems={'center'}>
                    <Text size={'mdNormal'}>Email</Text>
                  </VStack>
                </Pressable>
              </VStack>
            </Box>
          </HStack>
          <HStack>
            <Box style={styles.channelBoxBottomEnd}>
              <VStack style={styles.centerAlign} space={2}>
                <Image
                  style={{height: 140, width: 140}}
                  source={{
                    uri: require('../../assets/messenger.png'),
                  }}
                  size="lg"
                />
                <VStack alignItems={'center'}>
                  <Text size={'mdNormal'}>Messenger</Text>
                  <Text size={'smLight'} color={'gray.500'}>
                    Coming soon
                  </Text>
                </VStack>
              </VStack>
            </Box>
            <Box style={styles.channelBox}>
              <VStack style={styles.centerAlign} space={2}>
                <Image
                  style={{height: 140, width: 140}}
                  source={{
                    uri: require('../../assets/whatsapp.png'),
                  }}
                  size="lg"
                ></Image>
                <VStack alignItems={'center'}>
                  <Text size={'mdNormal'}>Whatsapp</Text>
                  <Text size={'smLight'} color={'gray.500'}>
                    Coming soon
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box style={styles.channelBoxEnd}>
              <VStack style={styles.centerAlign} space={2}>
                <Image
                  style={{height: 140, width: 140}}
                  source={{
                    uri: require('../../assets/api.png'),
                  }}
                  size="lg"
                ></Image>
                <VStack alignItems={'center'}>
                  <Text size={'mdNormal'}>API</Text>
                  <Text size={'smLight'} color={'gray.500'}>
                    Coming soon
                  </Text>
                </VStack>
              </VStack>
            </Box>
          </HStack>
        </VStack>
      )}

      {isSmallScreen && (
        <VStack>
          <HStack>
            <Pressable
              onPress={() =>
                props.handleScreenOneChange(INTEGRATION_CONSTANTS.SCREEN_TWO, {
                  inboxChannelCode: INBOX_CHANNEL_TYPE.WEB_SITE,
                })
              }
            >
              <Box style={styles.channelBox}>
                <VStack style={styles.centerAlign} space={1}>
                  <Image
                    style={{height: 140, width: 140}}
                    source={{
                      uri: require('../../assets/website.png'),
                    }}
                    size="lg"
                  ></Image>
                  <Text size={'mdNormal'}>Website</Text>
                </VStack>
              </Box>
            </Pressable>

            <Box style={styles.channelBox}>
              <VStack style={styles.centerAlign} space={2}>
                <Pressable
                  onPress={() =>
                    props.handleScreenOneChange(
                      INTEGRATION_CONSTANTS.SCREEN_TWO,
                      {
                        inboxChannelCode: 'SMS',
                      }
                    )
                  }
                >
                  <Image
                    style={{height: 140, width: 140}}
                    source={{
                      uri: require('../../assets/sms.png'),
                    }}
                    size="lg"
                  ></Image>
                  <VStack alignItems={'center'}>
                    <Text size={'mdNormal'}>SMS</Text>
                  </VStack>
                </Pressable>
              </VStack>
            </Box>
          </HStack>

          <HStack>
            <Box style={styles.channelBox}>
              <VStack style={styles.centerAlign} space={2}>
                <Pressable
                  onPress={() =>
                    props.handleScreenOneChange(
                      INTEGRATION_CONSTANTS.SCREEN_TWO,
                      {
                        inboxChannelCode: INBOX_CHANNEL_TYPE.EMAIL,
                      }
                    )
                  }
                >
                  <Image
                    style={{height: 140, width: 140}}
                    source={{
                      uri: require('../../assets/email.png'),
                    }}
                    size="lg"
                  ></Image>
                  <VStack alignItems={'center'}>
                    <Text size={'mdNormal'}>Email</Text>
                  </VStack>
                </Pressable>
              </VStack>
            </Box>
            <Box style={styles.channelBoxBottomEnd}>
              <VStack style={styles.centerAlign} space={2}>
                <Image
                  style={{height: 140, width: 140}}
                  source={{
                    uri: require('../../assets/messenger.png'),
                  }}
                  size="lg"
                />
                <VStack alignItems={'center'}>
                  <Text size={'mdNormal'}>Messenger</Text>
                  <Text size={'smLight'} color={'gray.500'}>
                    Coming soon
                  </Text>
                </VStack>
              </VStack>
            </Box>
          </HStack>

          <HStack>
            <Box style={styles.channelBox}>
              <VStack style={styles.centerAlign} space={2}>
                <Image
                  style={{height: 140, width: 140}}
                  source={{
                    uri: require('../../assets/whatsapp.png'),
                  }}
                  size="lg"
                ></Image>
                <VStack alignItems={'center'}>
                  <Text size={'mdNormal'}>Whatsapp</Text>
                  <Text size={'smLight'} color={'gray.500'}>
                    Coming soon
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box style={styles.channelBoxEnd}>
              <VStack style={styles.centerAlign} space={2}>
                <Image
                  style={{height: 140, width: 140}}
                  source={{
                    uri: require('../../assets/api.png'),
                  }}
                  size="lg"
                ></Image>
                <VStack alignItems={'center'}>
                  <Text size={'mdNormal'}>API</Text>
                  <Text size={'smLight'} color={'gray.500'}>
                    Coming soon
                  </Text>
                </VStack>
              </VStack>
            </Box>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
