export const BILLING_ATTRIBUTES = {
    COMPLEXITY: 'complexity',
    FACE_TO_FACE_DATE: 'faceTofaceDate',
    DISCHARGE_DATE_TIME: 'dischargeDateTime',
    FIRST_CONTACT_DATE_TIME: 'firstContactDateTime'
}

export const RESOURCE_ENTITIES = {
    TASK: 'TASK',
    FORM: 'FORM',
    MESSAGE: 'MESSAGE',
    APPOINTMENT: 'APPOINTMENT',
    VOICE_CALL: 'VOICE_CALL',
    CALL: 'CALL',
    VIDEO_CALL: 'VIDEO_CALL',
    care_plan_activity: 'care_plan_activity',
    appointment_scheduled: 'appointment_scheduled',
    patient_interaction: 'patient_interaction',
    assessment_activity: 'assessment_activity',
    care_management_activity: 'care_management_activity',
    access_assistance: 'access_assistance',
    health_records: 'health_records',
}

export enum CarePlanTaskType {
    care_plan_activity = 'Care Plan Established / Reviewed',
    appointment_scheduled = 'Scheduling Specialist or Doctor Appointments',
    patient_interaction = 'Calling Patient',
    assessment_activity = 'Assessment Established / Reviewed',
    care_management_activity = 'Care Management Activity',
    access_assistance = 'Assistance accessing tests, X-rays and labs',
    health_records = 'Recording Patient health information (includes Medications and allergies,Demographics,Care coordination, On-going clinical care)',
}