import React, {useCallback, useContext, useEffect, useState} from 'react';
import {View, Text} from 'react-native';
import {useQuery} from '@apollo/client';
import {TaskQueries} from '../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ITasksData} from '../CareDashboard/CareDashboardInterfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import {EVENT_NAMES, MLOV_CATEGORY} from '../../../constants';
import {getCompletedTaskStatusId} from '../Tasks/TasksUtils/TasksUtils';
import {Skeleton} from 'native-base';
import InternalTaskDrawer from './InternalTaskDrawer';
import {Colors} from '../../../styles';
import TaskIconV2 from '../../../assets/Icons/TaskIconV2';
import {EventBus} from '../../../utils/EventBus';

interface IPatientListInternalTaskProps {
  contactId: string;
  onTaskClick: () => void;
}

const PatientListInternalTask = (props: IPatientListInternalTaskProps) => {
  const contextData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];

  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const contactId = props.contactId;

  const getQueryObject = useCallback(
    () => ({
      assigneeIds: [],
      contactIds: [contactId],
      assigneeIdsNotIn: [contactId],
      statusIdsNotIn: [completedStatusId],
      orderBy: {
        endDateTime: 'asc',
      },
    }),
    [contactId]
  );

  const onEventReceived = () => {
    // Do something
    refetch();
  }

  useEffect(() => {
     let mounted = true;
     const eventBus = EventBus.getEventBusInstance();
     const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
     eventBus.addEventListener(
       code,
       onEventReceived
     );
     return () => {
       eventBus.removeEventListener(onEventReceived);
       const clear = async () => {
         mounted = false;
       };
       clear();
     };
  },[])

  const {loading, data, error, refetch} = useQuery<{
    getTasks: ITasksData;
  }>(TaskQueries.GET_CARE_DASHBOARD_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    variables: getQueryObject(),
  });

  if (loading) return <Skeleton.Text lines={1} />;
  if (error) return <Text>-</Text>;

  const totalTask = data?.getTasks?.aggregate?.total ?? 0;
  const tasks = data?.getTasks?.tasks;

  if (totalTask === 0) {
    return <Text>-</Text>;
  }

  return (
    <View>
      <Text
        onPress={() => props.onTaskClick()}
        style={{
          color: Colors.Custom.mainPrimaryPurple,
          paddingVertical: 10
        }}
      >
        <TaskIconV2 size={18} color={Colors.Custom.mainPrimaryPurple} />
        {` ${tasks?.length}`}
      </Text>
    </View>
  );
};

export default PatientListInternalTask;
