import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';
export const styles = StyleSheet.create({
  titleWrapper: {},
  container: {},
  textContainer: {
    fontWeight: '400',
    fontSize: 24,
    color: '#667085',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftText: {
    fontSize: 18,
    lineHeight: 32,
    fontWeight: '500',
    marginLeft: 16,
  },
  conversationContainer: {
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    borderTopWidth: 0,
    overflow: 'hidden',
  },
  msgWindow: {
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.Gray200,
    borderTopWidth: 0,
    borderBottomWidth:0
  },
  membersWindow: {
    borderWidth: 1,
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.Gray200,
    paddingHorizontal: 8,
    borderTopWidth: 0,
    borderLeftWidth: 0
  },
  noDataFound: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.BorderColor,
  },
  filterSlider: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
});
