import { Text, View } from 'native-base';
import { styles } from "./style";
import { Colors } from '../../../../styles/Colors';


const ScoreGroupCard = (props: {
    header: string,
    leftHeader: string,
    leftValue: string | number,
    rightHeader: string,
    rightValue: string
}) => {

    const { header, leftHeader, leftValue, rightHeader, rightValue } = props;
    return (
        <View style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 8,
            padding: 12,
            marginRight: 12,
            marginBottom: 12,
            width:300,
            height:150
        }}>
            <View style={{
                marginBottom: 12,
                borderBottomColor: Colors.Custom.Gray200,
                borderBottomWidth: 1,
                paddingBottom: 12
            }}>
                <Text size={'smSemiBold'} fontSize={14}>
                    {header}
                </Text>
            </View>
            <View flexDirection={'row'} justifyContent={'space-between'} >
                {leftHeader && <View style={{
                        width:'50%'
                    }} flexDirection={'column'}>
                    <Text size={'mdSemiBold'} color={Colors.Custom.IATextColor} fontSize={14}>
                        {leftHeader}
                    </Text>
                    <Text size={'mdBold'} fontSize={18}>
                        {leftValue}
                    </Text>
                </View>}
                {
                    rightHeader && <View flexDirection={'column'} style={{
                        width:'50%'
                    }}>
                        <Text size={'mdSemiBold'} color={Colors.Custom.IATextColor} fontSize={14}>
                            {rightHeader}
                        </Text>
                        <Text size={'mdBold'} fontSize={18}>
                            {rightValue}
                        </Text>
                    </View>
                }

            </View>

        </View>
    )
}

export default ScoreGroupCard;