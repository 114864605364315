import {HStack, Text, View, VStack} from 'native-base';
import {GROUP_MEMBER_TYPE} from '../../../../../../../constants';
import {Colors} from '../../../../../../../styles';
import {DisplayCardAvatar} from '../../../../../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {ActionsView} from '../ActionsView';
export const getVisitorTableViewColumns = (
  sortedInfo: any,
  onActionPerformed: (actionCode: string, rowData: any) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'nameData',
      key: 'nameData',
      width: '65%',
      sorter: (nameOne: any, nameTow: any) => {
        return nameOne.nameData.name.localeCompare(nameTow.nameData.name);
      },
      sortOrder: sortedInfo.columnKey === 'nameData' && sortedInfo.order,
      ellipsis: true,
      render: (text: any): JSX.Element => {
        return (
          <HStack flex={1} flexDirection={'row'} style={{marginLeft: 8}}>
            <VStack>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '12',
                }}
                isLetterAvatarShow
                userData={{
                  userId: '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  userName: text.name
                }}
              />
            </VStack>
            <VStack style={{marginLeft: 8}}>
              <HStack>
                <View flex={1} justifyContent={'center'} marginLeft={2}>
                  <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                    {text.name}
                  </Text>
                </View>
              </HStack>
              <HStack>
                <View flex={1} justifyContent={'center'} marginLeft={2}>
                  <Text color={Colors.Custom.Gray500} size={'xsMedium'}>
                    {text.email}
                  </Text>
                </View>
              </HStack>
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500}}
    //       textLocalId="email"
    //     />
    //   ),
    //   dataIndex: 'email',
    //   key: 'email',
    //   width: '20%',
    //   sorter: (nameOne: any, nameTow: any) => {
    //     return nameOne.name.localeCompare(nameTow.name);
    //   },
    //   sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    //   ellipsis: true,
    //   render: (text: string): JSX.Element => {
    //     return (
    //       <View>
    //         {text === '' || text === null ? (
    //           <Text color={'gray.400'}>--</Text>
    //         ) : (
    //           <Text>{text}</Text>
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="mobileNumber"
        />
      ),
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: '10%',
      // sorter: (nameOne: any, nameTow: any) => {
      //   return nameOne.name.localeCompare(nameTow.name);
      // },
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      // ellipsis: true,
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: <DisplayText size={'xsMedium'} extraStyles={{color: Colors.Custom.Gray500}} textLocalId="age" />,
    //   dataIndex: 'age',
    //   key: 'age',
    //   width: '10%',
    //   render: (text: string): JSX.Element => {
    //     return (
    //       <View>
    //         <Text>{text}</Text>
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="zipCode"
        />
      ),
      dataIndex: 'zipCode',
      key: 'zipCode',
      width: '10%',
      // sorter: (nameOne: any, nameTow: any) => {
      //   return nameOne.zipCode.localeCompare(nameTow.zipCode);
      // },
      // sortOrder: sortedInfo.columnKey === 'zipCode' && sortedInfo.order,
      // ellipsis: true,
      // sortDirections: ['descend', 'ascend'],
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '15%',
      render: (text: any, selectedRecord: any) => {
        return (
          <ActionsView
            record={selectedRecord}
            onActionClick={(actionCode: string): any => {
              onActionPerformed(actionCode, selectedRecord);
            }}
          />
        );
      },
      responsive: ['md'],
    },
  ];
};
