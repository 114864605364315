import SearchMessageThread from './SearchMessageThread';
import {IConversationMessage} from '../../interfaces';
import { ISearchMessageThread } from './interface';
import { Drawer } from 'antd';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { Colors } from '../../../../../../styles/Colors';
import { BUTTON_TYPE } from '../../../../../../constants';
import { CONVERSATION_ACTION_CODES } from '../../../../../../constants/ActionConst';

const SearchMessageThreadDrawer = (props: ISearchMessageThread) => {
  const {conversationUuid, onActionPerformed, onMsgClickActionPerformed} = props;

  const finalWidth = '100%';

  const closeModal = () => {
    if (onActionPerformed) {
      onActionPerformed(
        CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
        false
      );
    }
  }

  return (
    <Drawer
      visible={true}
      width={finalWidth}
      mask={false}
      onClose={() => {
        closeModal();
      }}
      title={
        <ModalActionTitle
          title={'searchInThread'}
          titleColor={''}
          titleSize={24}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'back',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                closeModal();
              },
            },
          ]}
        />
      }
    >
      <SearchMessageThread
        showTitle={false}
        conversationUuid={conversationUuid}
        onMsgClickActionPerformed={(msgData: IConversationMessage) => {
          onMsgClickActionPerformed?.(msgData);
        }}
        onActionPerformed={onActionPerformed}
      />
    </Drawer>
  );
};

export default SearchMessageThreadDrawer;
