import { Modal, notification, Checkbox } from 'antd'
import React, { useContext, useState } from 'react'
import { View, Text, Pressable } from 'react-native'
import Stack from '../../../common/LayoutComponents/Stack'
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton'
import { Colors } from '../../../../styles'
import './MultiSelectView.css';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces'
import BulkTaskEditView from './BulkTaskEditView'
import { EventBus } from '../../../../utils/EventBus'
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants'
import { MultiSelectAction } from '../../TaskInterfaces'
import Close from '../../../../assets/svg/Close'
import BulkTaskDeleteView from './BulkTaskDeleteView'
import { useIntl } from 'react-intl'
import { CommonDataContext } from '../../../../context/CommonDataContext'

interface IMultiSelectView {
  isVisible: boolean;
  selectedTasks: ITask[];
  onTaskRemoved?: (task: ITask) => void;
}

enum BulkAction {
  edit = 'EDIT',
  delete = 'DELETE'
}

const MultiSelectView = (props: IMultiSelectView) => {
  const { isVisible, selectedTasks, onTaskRemoved } = props;
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData.sidecarContext?.isSidecar;
  const eventBus = EventBus.getEventBusInstance();
  const intl = useIntl();

  const [componentState, setComponentState] = useState<{
    selectedAction?: BulkAction,
  }>({});

  const closeBulkAction = () => {
    eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: MultiSelectAction.disableMultiSelect })
  }

  const showSelectedTasksError = () => {
    notification.error({
      message: 'Please select tasks',
      duration: 3.0,
      placement: 'top',
    })
  }

  return (
    <View>
      <BulkTaskEditView
        selectedTasks={selectedTasks}
        isVisible={componentState.selectedAction === BulkAction.edit}
        onTaskRemoved={onTaskRemoved}
        onCancel={() => {
          setComponentState((prev) => ({...prev, selectedAction: undefined}));
        }}
      />
      <BulkTaskDeleteView
        selectedTasks={selectedTasks}
        isVisible={componentState.selectedAction === BulkAction.delete}
        onTaskRemoved={onTaskRemoved}
        onCancel={() => {
          setComponentState((prev) => ({...prev, selectedAction: undefined}));
        }}
      />
      {isVisible &&
        <div className={isSidecar ? "sidecar-modal-container" : "modal-container"}>
          <Stack direction="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Checkbox checked={selectedTasks.length>0} />
            <Text style={{ fontSize: 14, color: selectedTasks.length > 0 ? Colors.Custom.Gray500 : Colors.Custom.Gray300, fontWeight: '400', marginLeft: 8 }}>{selectedTasks.length} selected</Text>
            <View style={{ width: 1, height: 24, backgroundColor: Colors.Custom.Gray300, marginHorizontal: 8 }} />
            <Stack direction="row" space={8}>
              <FoldButton
                nativeProps={{
                  variant: 'solid',
                  backgroundColor: selectedTasks.length>0 ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY50,
                  height: '32px',
                  width: '42px',
                  _text: {
                    color: selectedTasks.length>0 ? Colors.Custom.White : Colors.FoldPixel.GRAY150,
                    textAlign: 'center',
                  },
                  onPress: () => {
                    if (!selectedTasks.length) {
                      showSelectedTasksError();
                      return;
                    }
                    setComponentState((prev) => ({ ...prev, selectedAction: BulkAction.edit }));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({ id: 'edit' }),
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: 'solid',
                  backgroundColor: selectedTasks.length>0 ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY50,
                  height: '32px',
                  width: '60px',
                  _text: {
                    color: selectedTasks.length>0 ? Colors.Custom.White : Colors.FoldPixel.GRAY150,
                    textAlign: 'center',
                  },
                  onPress: () => {
                    if (!selectedTasks.length) {
                      showSelectedTasksError();
                      return;
                    }
                    setComponentState((prev) => ({ ...prev, selectedAction: BulkAction.delete }));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({ id: 'delete' }),
                }}
              />
            </Stack>
            <View style={{ width: 1, height: 24, backgroundColor: Colors.Custom.Gray300, marginHorizontal: 8 }} />
            <Pressable onPress={closeBulkAction}>
              <Close fill={Colors.Custom.Gray300} size={20} />
            </Pressable>
          </Stack>
        </div>
      }
    </View>
  )
}


export default MultiSelectView
