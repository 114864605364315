import moment from 'moment';
import {IconButton, Tooltip, View, useToast} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {DeleteCampaignModal} from '../DeleteCampaignModal';
import {ICampaignTableActionsArgs} from '../interfaces';
import {
  getContactsWithinGroup,
  updateCampaignStatus,
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {getUserUUID} from '../../../../../utils/commonUtils';
import {Switch} from 'antd';
import {BUTTON_TYPE, CAMPAIGN_STATUS} from '../../../../../constants';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { FHAlertDialog } from '../../../../common/FHAlertDialog';

const CampaignTableActions = (props: ICampaignTableActionsArgs) => {
  const {onActionPerformed, record} = props;
  const intl = useIntl();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const userUuid = getUserUUID();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCampaignStateModal, setOpenCampaignStateModal] = useState(false)

  const handleDeleteCampaign = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  const handleGraphViewClick = () => {
    onActionPerformed(COMMON_ACTION_CODES.SHOW_GRAPH, record);
  };

  const getTootipText = () => {
    if (record.status != CAMPAIGN_STATUS.DRAFT) {
      return 'abort';
    }
    return 'runCampaign';
  };
  const isShowGraph = record.status != CAMPAIGN_STATUS.DRAFT;
  const [isDisabledStatusSwitch, setIsDisabledStatusSwitch] = useState(false);
  const tooltipText = getTootipText();

  const onToggle = async (status: boolean) => {
    setIsLoading(true);
    const templateId = String(record.id);
    const statusStr = status ? CAMPAIGN_STATUS.ACTIVE : CAMPAIGN_STATUS.ABORTED;
    if (record?.segmentId) {
      const data = await getContactsWithinGroup(
        record?.segmentId || '',
        undefined
      );
      if (!data?.data?.data.length) {
        showToast(
          toast,
          intl.formatMessage({id: 'populationGroupDeleted'}),
          ToastType.error
        );
        setIsLoading(false);
        return;
      }
    }
    const response = await updateCampaignStatus({
      id: templateId,
      status: statusStr,
      updatedBy: userUuid,
    });
    if (!response?.updateCampaign?.id) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
    setIsLoading(false);
    onActionPerformed(
      COMMON_ACTION_CODES.ENABLE,
      record,
      record.status !== CAMPAIGN_STATUS.DRAFT ? false : true
    );
  };

  return (
    <View flex={1} flexDirection={'row'} gap={2} alignContent={'flex-end'}>
      {record.status == CAMPAIGN_STATUS.DRAFT &&
        (
          <Tooltip
            label={intl.formatMessage({ id: tooltipText })}
            placement='top'
          >
            <IconButton
              _hover={{ backgroundColor: 'transparent' }}
              justifyContent={'center'}
              icon={
                <Feather
                  name='play'
                  style={{
                    fontSize: 18,
                    color: Colors.Custom.Gray600,
                  }}
                />
              }
              onPress={() => {
                setOpenCampaignStateModal(true);
              }}
            />
          </Tooltip>
        )
      }
      {
        record.status == CAMPAIGN_STATUS.ACTIVE &&
        <Tooltip
          label={intl.formatMessage({ id: tooltipText })}
          placement='top'
        >
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <Feather
                name='x-octagon'
                style={{
                  fontSize: 18,
                  color: Colors.Custom.crossIconColor,
                }}
              />
            }
            onPress={() => {
              setOpenCampaignStateModal(true);
            }}
          />
        </Tooltip>
      }

      {isShowGraph && (
        <Tooltip
          label={intl.formatMessage({ id: 'viewAnalytics' })}
          placement='top'
        >
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <Feather
                name='bar-chart-2'
                style={{
                  fontSize: 18,
                  color: Colors.Custom.Gray600,
                }}
              />
            }
            onPress={() => {
              handleGraphViewClick();
            }}
          />
        </Tooltip>
      )}

      {(record.status === CAMPAIGN_STATUS.DRAFT ||
        (record.status === CAMPAIGN_STATUS.ACTIVE &&
          moment().isBefore(record.startDate))) && (
          <Tooltip label={intl.formatMessage({ id: 'edit' })} placement='top'>
            <IconButton
              _hover={{ backgroundColor: 'transparent' }}
              justifyContent={'center'}
              icon={
                <Feather name='edit-2' size={18} color={Colors.Custom.Gray600} />
              }
              onPress={() => {
                onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
              }}
            />
          </Tooltip>
        )}

      <Tooltip label='Clone' placement='top'>
        <IconButton
          _hover={{ backgroundColor: 'transparent' }}
          justifyContent={'center'}
          icon={
            <Feather
              name='copy'
              style={{
                fontSize: 18,
                color: Colors.Custom.Gray600,
              }}
            />
          }
          onPress={() => {
            onActionPerformed(COMMON_ACTION_CODES.COPY, record);
          }}
        />
      </Tooltip>

      {record.status === CAMPAIGN_STATUS.DRAFT ||
        record.status === CAMPAIGN_STATUS.ENDED ||
        record.status === CAMPAIGN_STATUS.ABORTED ||
        (record.status === CAMPAIGN_STATUS.ACTIVE &&
          moment().isBefore(record.startDate)) ? (
        <Tooltip label={intl.formatMessage({ id: 'delete' })} placement='top'>
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <Feather
                name='trash-2'
                style={{
                  fontSize: 18,
                  color: Colors.Custom.Gray600,
                }}
              />
            }
            onPress={() => setOpenDeleteModal(true)}
          />
        </Tooltip>
      ) : null}

      <DeleteCampaignModal
        isOpen={openDeleteModal}
        onConfirm={() => {
          handleDeleteCampaign();
          setOpenDeleteModal(false);
        }}
        onClose={() => setOpenDeleteModal(false)}
      />
      <FHAlertDialog
        isOpen={openCampaignStateModal}
        customHeight="24%"
        header={intl.formatMessage({ id: record.status == CAMPAIGN_STATUS.ACTIVE ? 'abortTheCampaign' : 'runTheCampaign', })}
        message={intl.formatMessage({ id: record.status == CAMPAIGN_STATUS.ACTIVE ? 'abortCampaignAlertMessage' : 'runCampaignAlertMessage' })}
        buttonActions={[
          {
            textLocalId: 'cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
            },
            onPress: () => {
              setOpenCampaignStateModal(false)
            },
          },
          {
            textLocalId: record.status == CAMPAIGN_STATUS.ACTIVE ? 'abort' : 'runCampaign',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
            },
            onPress: () => {
              onToggle(record.status == CAMPAIGN_STATUS.DRAFT);
              setOpenCampaignStateModal(false)
            },
          },
        ]}
      />
    </View>
  );
};

export default CampaignTableActions;
