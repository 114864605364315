import {useLazyQuery} from '@apollo/client';
import {Drawer} from 'antd';
import {
  Box,
  FormControl,
  HStack,
  Input,
  Pressable,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {isInt8Array} from 'util/types';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {GET_FORMS_COUNT_FOR_NAME} from '../../../../../services/Forms/FormsQueries';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import FormSearch from '../../../../common/FormSearch/FormSearch';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {NoActivitySvg} from '../../../../common/Svg';
import {styles} from '../../../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/CommonStyles';
import {IAddFormProps, IAddFormState, IFormCategory} from '../../interfaces';
import FormCategoryCard from './FormCategoryCard';
import ConfirmOnExitOrReload from '../../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import {AddFormCategories} from './AddFormCategories';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { FORM_CATEGORY_TYPES } from '../../FormsConstants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { isEnableCareProgram } from '../../../../../utils/commonUtils';

const AddForm = (props: IAddFormProps) => {
  const intl = useIntl();
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const getFilteredCategories = () => {
    const categoriesToExclude: any[] = [];
    if (!isCareProgramEnabled) {
      categoriesToExclude.push(FORM_CATEGORY_TYPES.CARE_PLAN);
    }
    return props?.categoryList?.filter((category) => {
      return !categoriesToExclude.includes(category.code);
    });
  }
  const filteredCategoryList = getFilteredCategories();
  const [isFormNameAlreadyTakenState, setIsFormNameAlreadyTakenState] =
    useState({
      isTaken: false,
      isLoading: false,
      isError: false,
    });
  const [addFormState, setFormState] = useState<IAddFormState>({
    formName: undefined,
    formCategoryId: undefined,
    formCategoryName: undefined,
    isValid: false,
    isAddTemplateOpen: false
  });
  const [formValue, setFormValue] = useState<any>(undefined);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const [getFormsCountForName] = useLazyQuery(GET_FORMS_COUNT_FOR_NAME, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (data: any) => {
      setIsFormNameAlreadyTakenState((prevState) => ({
        ...prevState,
        isTaken: data?.aggregateForms?.aggregate?.count > 0,
        isLoading: false,
      }));

      setFormState((prev) => {
        let isValid: boolean;
        if (props.isAddExisting) {
          isValid = data?.aggregateForms?.aggregate?.count === 0;
        } else {
          isValid = prev.formCategoryId != undefined && prev.formName != undefined && data?.aggregateForms?.aggregate?.count === 0;
        }
        return {
          ...prev,
          isValid: isValid,
        };
      });
    },
    onError: (error) => {
      setIsFormNameAlreadyTakenState((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
      );
    },
  });

  const onCategorySelect = (category: IFormCategory) => {
    setFormState((prev) => {
      const isValid: boolean =
        (category?.id &&
          prev.formName &&
          !isFormNameAlreadyTakenState.isLoading &&
          !isFormNameAlreadyTakenState.isTaken) ||
        false;
      return {
        ...prev,
        formCategoryId: category.id,
        formCategoryName: category.name,
        isValid: isValid,
      };
    });
  };

  const isFormNameAlreadyTaken = (formName: string) => {
    setIsFormNameAlreadyTakenState((prevState) => ({
      ...prevState,
      isLoading: true,
      isError: false,
    }));
    const timerId = setTimeout(() => {
      getFormsCountForName({
        variables: {
          searchString: formName,
        },
      });
    }, 500);
  };

  const resetFormDetail = () => {
    setIsFormNameAlreadyTakenState((prev) => {
      return {
        ...prev,
        isTaken: false,
        isLoading: false,
        isError: false,
      };
    });
    setFormState((prev) => {
      return {
        ...prev,
        formName: undefined,
        formCategoryId: undefined,
        formCategoryName: undefined,
        isValid: false,
      };
    });
  };

  const onFormCreation = () => {
    props.onComplete({...addFormState, formValue});
    resetFormDetail();
  };

  const onAddFormCategoriesActionPerformed = (actionCode?:string)=> {
    setFormState((prev)=>{
      return {
        ...prev,
        isAddTemplateOpen: false
      }
    })
    if (actionCode) {
      props?.onRefetch?.()
    }
  }

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60%' : '40%';

  return (
  <ConfirmOnExitOrReload>
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetFormDetail();
        props.onCancel();
      }}
      visible={props.isVisible}
      closable
      width={drawerWidth}
      title={
        <ModalActionTitle
          title="createNewForm"
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: intl.formatMessage({id: 'cancel'}),
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                resetFormDetail();
                props.onCancel();
              },
            },
            {
              show: true,
              id: 2,
              isDisabled: !addFormState.isValid,
              btnText: intl.formatMessage({id: 'startFormCreation'}),
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                setFormState((prev) => {
                  return {
                    ...prev,
                    categoryList: filteredCategoryList || [],
                  };
                });
                onFormCreation();
              },
            },
          ]}
        />
      }
    >
      {filteredCategoryList?.length > 0 && (
        <VStack mr={5} overflow={'hidden'}>
          <VStack space={2}>
            <VStack flex={1}>
              <FormControl style={styles.formElement} isRequired>
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    textLocalId="formName"
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                  />
                </FormControl.Label>
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  placeholder="Form Name"
                  onChangeText={(value: string) => {
                    const formName = value.trim();
                    if (formName) {
                      isFormNameAlreadyTaken(formName);
                    }
                    setFormState((prev) => {
                      return {
                        ...prev,
                        formName,
                        isValid: formName && formValue,
                      };
                    });
                  }}
                />
                {isFormNameAlreadyTakenState.isTaken && (
                  <HStack marginTop={2} space={2}>
                    <Text fontSize="xs" color={Colors.danger[500]}>
                      Form name already exist. Please enter another form name.
                    </Text>
                  </HStack>
                )}
              </FormControl>
            </VStack>
            {props.isAddExisting && (
              <VStack flex={1}>
                <FormControl style={styles.formElement} isRequired>
                  <FormControl.Label style={styles.formExistingLabel}>
                    <DisplayText
                      textLocalId="importFrom"
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <View>
                    <FormSearch
                      isShowError={false}
                      isReturnCategory={true}
                      onChange={(form) => {
                        setFormValue(form);
                        setFormState((prev) => {
                          return {
                            ...prev,
                            isValid: addFormState.formName && form,
                          };
                        });
                      }}
                    />
                  </View>
                </FormControl>
              </VStack>
            )}
            {!props.isAddExisting && (
              <VStack flex={1}>
                <FormControl style={styles.formElement} isRequired>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      textLocalId="selectFormCategory"
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <HStack key={'form-category-group'}>
                    <View flex={1} flexDirection="row" flexWrap="wrap">
                    <View
                        bgColor={
                          !addFormState?.formCategoryId
                            ? Colors.primary['100'] + '7a'
                            : 'white'
                        }
                        textAlign={'start'}
                        borderWidth="1"
                        shadow="2"
                        borderRadius={10}
                        marginY={2}
                        marginX={0.5}
                        borderColor={'white'}
                      >
                        <Pressable
                          _hover={{bg: Colors.primary['100'] + '7a'}}
                          onPress={() => {
                            setFormState((prev)=>{
                              return {
                                ...prev,
                                isAddTemplateOpen: true
                              }
                            })
                          }}
                          borderRadius={10}
                        >
                          <Box justifyContent={'center'} height={60} width={40} m={4} overflow={'hidden'}>
                            <Text alignSelf={'center'} fontWeight={'bold'} fontSize={14} color={Colors.primary[400]}>
                              {intl.formatMessage({id: 'addNewCategory'})}
                            </Text>
                          </Box>
                        </Pressable>
                      </View>
                      {filteredCategoryList.map(
                        (category: IFormCategory, index: number) => {
                          return (
                            <View key={category?.name + index} marginRight={2}>
                              <FormCategoryCard
                                selectedCategoryId={addFormState.formCategoryId}
                                category={category}
                                onPress={onCategorySelect}
                              />
                            </View>
                          );
                        }
                      )}
                    </View>
                  </HStack>
                </FormControl>
              </VStack>
            )}
          </VStack>
          {addFormState?.isAddTemplateOpen && <AddFormCategories onActionPerformed={onAddFormCategoriesActionPerformed}/>}
        </VStack>
      )}
      {!filteredCategoryList?.length && (
        <VStack justifyContent="center" height={'100%'}>
          <NoActivitySvg titleId="noFormCategory" />
        </VStack>
      )}
    </Drawer>
  </ConfirmOnExitOrReload>
  );
};

export default AddForm;
