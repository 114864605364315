import React, { useCallback } from 'react';
import {Colors} from '../../../styles/Colors';
import {FilterWrapperComponent} from '../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import Stack from '../../common/LayoutComponents/Stack';
import {Text, View} from 'react-native';
import {useIntl} from 'react-intl';
import {
  BILLING_HOOK,
  IBillingSelectedFilters,
  IHookState,
} from './BillingHooks/BillingHookInterfaces';
import {Checkbox} from 'antd';
import {ALL_CHRONIC_CONDITIONS, ALL_PROGRAM_TYPE, ALL_SELECTED, BILLING_FILTER_ACTION_CODES} from './BillingHooks/BillingHooksConstants';
import {IBatchedAccountUsersInterface} from '../../CustomHooks/useGetBatchedAccountUsers';
import UserAutoComplete, {
  userAutoCompleteTypeCodes,
} from '../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import DateRangePicker from '../Analytics/CommonComponents/DateRangePicker';
import {
  getDateObjectFromStringAndFormat,
  getFormattedDate,
  subtractMilliSeconds,
} from '../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../constants/StringConst';
import {DateRange} from '../Analytics/CommonComponents/Interfaces';
import {TimeUnitValues} from '../Analytics/CommonComponents/constants';
import { useContext, useState } from 'react';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { IFormCommonData } from '../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { MLOV_CATEGORY } from '../../../constants';
import { Input, Spinner } from 'native-base';
import { debounce } from 'lodash';
import MemberInfoListItem from '../../common/MemberInfoListItem/MemberInfoListItem';

interface IBillingFilterRow {
  hookState: IHookState;
  selectedFilters: IBillingSelectedFilters;
  handleActions: (actionCode: string, actionData?: any) => void;
  accountUserList: IBatchedAccountUsersInterface[];
  onChangeCustomer: (searchText: string) => void
  contactsLoading: boolean;
}

const BillingFilterRow = (props: IBillingFilterRow) => {
  const {hookState, selectedFilters, handleActions, accountUserList, onChangeCustomer, contactsLoading} = props;
  const intl = useIntl();
  const [temporarySelectedFilters, setTemporarySelectedFilters] = useState<IBillingSelectedFilters>(selectedFilters);
  const [contactsDataState, setContactsDataState] = useState([]);
  const [searchText, setSearchText] = useState("");
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const careProgramTypes = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES) || [];

  const onChangeOfCheckBoxes = (
    previousSelectedItems: any[],
    isChecked: boolean,
    item: any,
    codeOfFilter: string,
    includedInSelectedItems: boolean,
    indexOfItem: number
  ) => {
    const selectedItems =
      previousSelectedItems?.filter?.(
        (item) => item.id !== BILLING_HOOK.ALL && item !== BILLING_HOOK.ALL && item !== ALL_SELECTED.value
      ) || [];
    if (isChecked) {
      if (!includedInSelectedItems) {
        selectedItems.push(item);
      }
    } else {
      if (includedInSelectedItems) {
        selectedItems.splice(indexOfItem, 1);
      }
    }
    let updatedActionData: any;
    switch (codeOfFilter) {
      case BILLING_FILTER_ACTION_CODES.PROGRAM_TYPE:
        updatedActionData =
          selectedItems?.length > 0 ? selectedItems : [ALL_PROGRAM_TYPE];
        setTemporarySelectedFilters((prev) => {
          return {
            ...prev,
            programTypes: updatedActionData,
          };
        });
        break;
      case BILLING_FILTER_ACTION_CODES.CPT_CODE:
        updatedActionData =
          selectedItems?.length > 0 ? selectedItems : [ALL_SELECTED.value];
        setTemporarySelectedFilters((prev) => {
          return {
            ...prev,
            cptCodes: updatedActionData,
          };
        });
        break;
    }
  };

  const debouncedOnChangeCustomer = useCallback(
    debounce((searchText) => {
      onChangeCustomer(searchText);
    }, 500),
    []
  );

  return (
    <Stack
      direction="row"
      style={{
        padding: 4,
        borderBottomColor: Colors.Custom.Gray200,
        borderBottomWidth: 1,
        alignItems: 'center',
      }}
    >
      <FilterWrapperComponent
        label={intl.formatMessage({id: 'programTypeSmall'})}
        value={temporarySelectedFilters.programTypes?.map((item) => item.value)}
        onClose={() =>
          handleActions(
            BILLING_FILTER_ACTION_CODES.PROGRAM_TYPE,
            temporarySelectedFilters.programTypes
          )
        }
      >
        {!!careProgramTypes?.length ? (
          <View style={{paddingLeft: 16}}>
            {careProgramTypes.map((programType) => {
              const isIncludedInSelectedFilters =
                temporarySelectedFilters.programTypes?.some(
                  (item) => item.id === programType.id
                );
              return (
                <Checkbox
                  key={programType.id}
                  value={programType.value}
                  style={{
                    alignItems: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: 0,
                  }}
                  checked={isIncludedInSelectedFilters}
                  onChange={(event) => {
                    onChangeOfCheckBoxes(
                      temporarySelectedFilters.programTypes,
                      event.target.checked,
                      programType,
                      BILLING_FILTER_ACTION_CODES.PROGRAM_TYPE,
                      isIncludedInSelectedFilters,
                      temporarySelectedFilters.programTypes.findIndex(
                        (item) => item.id === programType.id
                      )
                    );
                  }}
                >
                  <Text style={{color: Colors.Custom.Gray700}}>
                    {programType.value}
                  </Text>
                </Checkbox>
              );
            })}
          </View>
        ) : undefined}
      </FilterWrapperComponent>
      <FilterWrapperComponent
        label={intl.formatMessage({id: 'cptCode'})}
        value={temporarySelectedFilters.cptCodes}
        onClose={() =>
          handleActions(
            BILLING_FILTER_ACTION_CODES.CPT_CODE,
            temporarySelectedFilters.cptCodes
          )
        }
      >
        {hookState?.cptCodes?.length ? (
          <View
            style={{
              maxHeight: 400,
              paddingLeft: 16,
              overflow: 'scroll',
            }}
          >
            {hookState.cptCodes.map((code) => {
              const isIncludedInSelectedFilters =
                temporarySelectedFilters?.cptCodes?.includes(code);
              return (
                <Checkbox
                  key={code}
                  value={code}
                  style={{
                    alignItems: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: 0,
                  }}
                  checked={isIncludedInSelectedFilters}
                  onChange={(event) => {
                    onChangeOfCheckBoxes(
                      temporarySelectedFilters.cptCodes,
                      event.target.checked,
                      code,
                      BILLING_FILTER_ACTION_CODES.CPT_CODE,
                      isIncludedInSelectedFilters,
                      temporarySelectedFilters.cptCodes.findIndex(
                        (item) => item === code
                      )
                    );
                  }}
                >
                  <Text style={{color: Colors.Custom.Gray700}}>{code}</Text>
                </Checkbox>
              );
            })}
          </View>
        ) : undefined}
      </FilterWrapperComponent>
      <FilterWrapperComponent
        label={intl.formatMessage({id: 'providerSmall'})}
        value={
          !temporarySelectedFilters.providers?.length
            ? ['All']
            : temporarySelectedFilters.providers.map((item) => item.name)
        }
        onClose={() =>
          handleActions(
            BILLING_FILTER_ACTION_CODES.PROVIDER,
            temporarySelectedFilters.providers
          )
        }
      >
        <UserAutoComplete
          selectedUserUUIDList={temporarySelectedFilters.providers.map(
            (item) => item.uuid
          )}
          userAutoCompleteType={
            userAutoCompleteTypeCodes.INPUT_CHECKBOX_OPTIONS
          }
          allowClear={false}
          onChange={(userUUIDs: any) => {
            const uuidSet = new Set(userUUIDs);
            const matchingUsers = accountUserList.filter((user) =>
              uuidSet.has(user.uuid)
            );
            setTemporarySelectedFilters((prev) => {
              return {
                ...prev,
                providers: matchingUsers,
              };
            });
          }}
          usersData={accountUserList?.sort((a, b) =>
            a.name.localeCompare(b.name)
          )}
          backgroundColor={'white'}
          hideSelectAll={true}
        />
      </FilterWrapperComponent>
      <FilterWrapperComponent
        label={intl.formatMessage({id: 'dateOfServiceSmall'})}
        value={[
          `${getFormattedDate(
            temporarySelectedFilters?.dateOfService?.startDate,
            DATE_FORMATS.BILLABLE_DATE_OF_SERVICE
          )} - ${getFormattedDate(
            temporarySelectedFilters?.dateOfService?.endDate,
            DATE_FORMATS.BILLABLE_DATE_OF_SERVICE
          )}`,
        ]}
      >
        <DateRangePicker
          dateRange={{
            from: getFormattedDate(
              temporarySelectedFilters?.dateOfService?.startDate,
              DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
            ),
            to: getFormattedDate(
              temporarySelectedFilters?.dateOfService?.endDate,
              DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
            ),
          }}
          onDateRangeChange={(dateRange: DateRange) => {
            const formattedDateRange = {
              startDate: getDateObjectFromStringAndFormat(
                dateRange?.from,
                DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
              )?.toISOString(),
              endDate: subtractMilliSeconds(
                getDateObjectFromStringAndFormat(
                  dateRange?.to,
                  DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                ),
                1
              )?.toISOString(),
            };
            setTemporarySelectedFilters((prev) => {
              return {
                ...prev,
                dateOfService: {
                  startDate: getDateObjectFromStringAndFormat(
                    dateRange?.from,
                    DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                  )?.toISOString(),
                  endDate: getDateObjectFromStringAndFormat(
                    dateRange?.to,
                    DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                  )?.toISOString(),
                },
              };
            });
            handleActions(
              BILLING_FILTER_ACTION_CODES.DATE_FILTER,
              formattedDateRange
            );
          }}
          isDateRangeRestricted={true}
          dateComponents={{
            dateRange: 1,
            timeUnit: TimeUnitValues.year,
          }}
        ></DateRangePicker>
      </FilterWrapperComponent>
      <FilterWrapperComponent
        onClose={() => {
          setSearchText('');
          handleActions(
            BILLING_FILTER_ACTION_CODES.MEMBER,
            temporarySelectedFilters.members
          );
        }}
        label={'Members'}
        value={
          !temporarySelectedFilters?.members?.length
            ? ['All']
            : temporarySelectedFilters?.members?.map((item) => item?.label)
        }
      >
        <View
          style={{
            width: 350,
          }}
        >
          <View
            style={{
              borderBottomColor: Colors.Custom.Gray200,
              borderBottomWidth: 1,
              paddingBottom: 14,
            }}
          >
            <Text
              style={{
                marginHorizontal: 4,
                marginVertical: 8,
                fontWeight: '600',
              }}
            >
              Select Member
            </Text>
            <Input
              placeholder={'Search Members'}
              onChangeText={(searchText) => {
                setSearchText(searchText);
              }}
              onChange={(event) =>
                debouncedOnChangeCustomer(event?.nativeEvent?.text)
              }
              width={'100%'}
              value={searchText}
            />
          </View>
          {contactsLoading && <Spinner marginTop={4}></Spinner>}
          {!contactsLoading && (
            <View
              style={{
                maxHeight: 400,
                overflow: 'scroll',
              }}
            >
              {hookState?.customerData?.length ? (
                hookState?.customerData?.map((item, index: number) => {
                  return (
                    <Checkbox
                      key={item.key}
                      value={item.value || ''}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: 'center',
                      }}
                      checked={
                        temporarySelectedFilters?.members?.find(
                          (subitem: any) => subitem.key == item.key
                        )
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        const value = temporarySelectedFilters.members || [];
                        if (event.target.checked) {
                          value.push(item as any);
                        } else {
                          const index = value.findIndex(
                            (subitem: any) => subitem.key == item.key
                          );
                          if (index > -1) {
                            value.splice(index, 1);
                          }
                        }

                        if (value) {
                          const labelArray: string[] = [];
                          value.map((valueItem: any) => {
                            (valueItem?.label?.props?.contactData?.name ||
                              valueItem?.label) &&
                              labelArray.push(
                                valueItem?.label?.props?.contactData?.name ||
                                  valueItem?.label
                              );
                          });
                          const newContactDataArray = value.reduce(
                            (acc: any, item: any) => {
                              const id = item.key;
                              const foundContact = contactsDataState.find(
                                (contact: any) => contact.key === id
                              );
                              if (foundContact) {
                                acc.push(foundContact);
                              } else {
                                const customerInfo =
                                  hookState?.customerData?.find(
                                    (customer) => customer.key === id
                                  );
                                if (customerInfo) {
                                  acc.push({
                                    key: customerInfo.key,
                                    label: customerInfo.label,
                                    value: customerInfo.value,
                                    contactData: customerInfo.contactData,
                                  });
                                }
                              }
                              return acc;
                            },
                            []
                          );
                          setTemporarySelectedFilters((prev) => {
                            return {
                              ...prev,
                              members: value,
                            };
                          });
                          setContactsDataState(newContactDataArray);
                        }
                      }}
                    >
                      <View style={{marginLeft: 8, marginVertical: 6}}>
                        <MemberInfoListItem
                          contactData={item.contactData}
                          showDateOfBirth={true}
                        />
                      </View>
                    </Checkbox>
                  );
                })
              ) : searchText ? (
                <Text
                  style={{
                    marginTop: 8,
                    color: Colors.Custom.Gray700,
                  }}
                >
                  {intl.formatMessage({
                    id: 'noDataFound',
                  })}
                </Text>
              ) : (
                <Text
                  style={{
                    marginTop: 8,
                    color: Colors.Custom.Gray700,
                  }}
                >
                  {intl.formatMessage({
                    id: 'NoDataSelect',
                  })}
                </Text>
              )}
            </View>
          )}
        </View>
      </FilterWrapperComponent>
    </Stack>
  );
};

export default BillingFilterRow;
