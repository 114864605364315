import {useQuery} from '@apollo/client';
import {Modal, Spinner, View} from 'native-base';
import React, {useContext} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import ConvertContacts from '../../../../../services/ConvertContacts/ConvertContacts';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {EmptyStateSvg} from '../../../../common/Svg';
import {
  getConvertContactFormattedData,
  getModalTitleByPersonType,
} from '../../ContactsUtils';
import {getFormDataFromVisitorData} from '../VisitorView/AddOrUpdateVisitor/AddOrUpdateVisitorUtils';
import {ContactDisplayCard} from './ContactDisplayCard';
import {styles} from './ConvertContactsListStyles';
export interface IConvertContactListProps {
  contactData: any;
  onSave: (contactId: string | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  personType: string;
  onCreatePatient: () => void;
}

const {Content, Body, Header, CloseButton} = Modal;

const ConvertContactList = (props: IConvertContactListProps) => {
  const {contactData, onSave, isOpen, onClose} = props;

  const mlovData = useContext(CommonDataContext);
  const formattedData = getFormDataFromVisitorData(
    contactData.singleVisitorData,
    mlovData
  );

  const {data, loading} = useQuery(ConvertContacts.GetPatientList, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    variables: {
      birthDate: formattedData.birthDate,
      email: formattedData.email,
      // phone: formattedData.phone,
      zipCode: formattedData.zipCode,
      firstName: formattedData.firstName,
      lastName: formattedData.lastName,
    },
  });

  const contactDataList = getConvertContactFormattedData(
    data?.fhir?.PatientList || []
  );

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} style={styles.modalStyle}>
      <Content style={styles.modalContent}>
        <CloseButton />
        <Header>
          <DisplayText
            textType="Heading"
            textLocalId={getModalTitleByPersonType(props.personType)}
          />
        </Header>

        {loading ? (
          <Spinner />
        ) : (
          <Body style={styles.modalBodyStyle}>
            <ContactDisplayCard
              contactData={{
                contactName: `${formattedData.firstName} ${formattedData.lastName}`,
                contactCity: formattedData.cityName,
                contactGender: formattedData.gender,
                contactPostalCode: formattedData.zipCode,
                contactState: formattedData.state,
                contactId: formattedData.id,
              }}
              btnText="Create Patient"
              onBtnPress={() => props.onCreatePatient()}
            />
            <View style={styles.titleView}>
              <DisplayText textType="Heading" textLocalId="searchResults" />
            </View>

            {contactDataList && contactDataList.length > 0 ? (
              contactDataList.map((contact) => {
                return (
                  <>
                    <ContactDisplayCard
                      contactData={{
                        contactName: contact.fullName,
                        contactCity: contact.city,
                        contactGender: contact.gender,
                        contactState: contact.state,
                        contactPostalCode: contact.postalCode,
                        contactId: contact.id,
                      }}
                      btnText="Set"
                      onBtnPress={(contactData) => {
                        onSave(contactData.contactId);
                      }}
                    />
                  </>
                );
              })
            ) : (
              <View margin={'auto'} display={'flex'}>
                <EmptyStateSvg />
              </View>
            )}
          </Body>
        )}
      </Content>
    </Modal>
  );
};

export default ConvertContactList;
