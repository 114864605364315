import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Button,
  Checkbox,
  Modal,
  Spinner,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  apiError,
  BUTTON_TYPE,
  notAllowed,
  removedDefault,
  setAsDefault,
} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CloudTelephonyQueries, UserQueries} from '../../../../services';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../styles';
import {
  canCreateSMSInboxWithPhoneNumber,
  getAccountId,
  getAccountUUID,
  getCurrentUserRole,
} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {SMS_INBOX_ACTION_CODES} from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import SmsInboxDrawer from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxDrawer';
import {styles} from './SetDefaultModalStyle';

export const SetDefaultNumber = (props: any) => {
  const {selectedRecord, isSmsInbox} = props;
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    changeDefaultModal: false,
    type: '',
    typeId: '',
    loading: false,
    smsInboxView: false,
    userList: [],
    selectedPhoneNumber: '',
    id: selectedRecord?.id || -1,
    defaultPhoneNumber: '',
  });

  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const accountId = getAccountId();
  const toast = useToast();
  const currentUserRoles = getCurrentUserRole();
  const isBusinessOwner = currentUserRoles.some((role) => {
    return USER_ROLE_CODES.BUSINESS_OWNER === role.code;
  });

  const [SET_DEFAULT_NUMBER] = useMutation(
    CloudTelephonyQueries.SET_DEFAULT_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [CHECK_DEFAULT_NUMBER] = useLazyQuery(
    CloudTelephonyQueries.CHECK_DEFAULT_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [DELETE_DEFAULT_NUMBER] = useMutation(
    CloudTelephonyQueries.DELETE_DEFAULT_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GET_USERS_IVR] = useLazyQuery(UserQueries.Get_Users_IVR, {
    fetchPolicy: 'no-cache',
  });

  const [CHECK_SMS_INBOX_EXIST] = useLazyQuery(
    InboxQueries.CHECK_SMS_INBOX_EXIST,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const checkDefaultNumber = async (typeId: any) => {
    const response = await CHECK_DEFAULT_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        defaultCommunicationTypeId: typeId[0]?.id || '',
        tenantId: accountUuid
      },
    }).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
    });
    return response;
  };

  const chkInboxExist = async () => {
    const response = await CHECK_SMS_INBOX_EXIST({
      variables: {
        accountId: accountId,
        phoneNumber: selectedRecord?.phoneNumber?.phoneNumber,
      },
    }).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
    });
    return response;
  };

  const setDefault = async (typeId: any) => {
    const responses = await SET_DEFAULT_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        defaultCommObj: {
          accountUuid: accountUuid,
          defaultCommunicationTypeId: typeId[0]?.id || '',
          virtualPhoneNumberAssigneeId: selectedRecord?.id,
          tenant_id: accountUuid
        },
      },
    }).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
    });
    return responses;
  };

  const setDefaultNumber = async (type: string) => {
    const communType = type || stateData?.type;
    const typeId = CommunicationType.filter((communication) => {
      return communication?.code === communType;
    });
    const response = await checkDefaultNumber(typeId);
    if (response?.data?.numberDefaultCommunications?.length) {
      setStateData({
        ...stateData,
        changeDefaultModal: true,
        type: type,
        typeId: typeId[0]?.id,
        loading: false,
        defaultPhoneNumber:
          response?.data?.numberDefaultCommunications[0]?.virtualNumberAssignee
            ?.virtualPhoneNumber || '',
      });
    } else {
      if (stateData?.type === 'SMS') {
        const response = await chkInboxExist();
        if (response?.data?.channelTwilioSmsList?.length) {
          const responses = await setDefault(typeId);
          showToast(toast, setAsDefault, ToastType.success, 4000);
          setStateData({
            ...stateData,
            changeDefaultModal: false,
            loading: false,
          });
          props?.onActionClick(COMMON_ACTION_CODES.COMPLETED, selectedRecord);
        } else {
          setSmsView();
        }
      } else {
        const responses = await setDefault(typeId);
        showToast(toast, setAsDefault, ToastType.success, 4000);
        setStateData({...stateData, changeDefaultModal: false, loading: false});
        props?.onActionClick(COMMON_ACTION_CODES.COMPLETED, selectedRecord);
      }
    }
  };

  const removeDefault = async (typeId: string) => {
    const response = await DELETE_DEFAULT_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        defaultCommunicationTypeId: typeId || '',
        tenantId: accountUuid
      },
    });
    return response;
  };

  const removeDefaultAndAddNew = async () => {
    const response = await removeDefault(stateData?.typeId);
    if (response?.data?.updateNumberDefaultCommunications?.returning.length) {
      showToast(toast, removedDefault, ToastType.success, 4000);
      setDefaultNumber(stateData?.type);
    }
  };

  const getUsersList = async () => {
    const responseData = await GET_USERS_IVR({
      variables: {
        accountUUID: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    }).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
    });
    return responseData;
  };

  const setSmsView = async () => {
    const responseData = await getUsersList();
    if (responseData?.data?.users?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          userList: responseData?.data?.users,
          smsInboxView: true,
          selectedPhoneNumber: selectedRecord?.phoneNumber?.phoneNumber,
          loading: false,
        };
      });
    } else {
      setStateData({...stateData, changeDefaultModal: false, loading: false});
    }
  };

  const handleChkChange = async (type: string) => {
    if (isBusinessOwner) {
      const typeId = CommunicationType.filter((communication) => {
        return communication?.code === type;
      });
      setStateData({...stateData, loading: true, type: type});
      if (type === 'CALL') {
        if (selectedRecord?.isDefaultCalling) {
          const response = await removeDefault(typeId[0]?.id);
          if (
            response?.data?.updateNumberDefaultCommunications?.returning.length
          ) {
            showToast(toast, removedDefault, ToastType.success, 4000);
          }
          props?.onActionClick(COMMON_ACTION_CODES.COMPLETED, selectedRecord);
          setStateData({
            ...stateData,
            changeDefaultModal: false,
            loading: false,
          });
        } else {
          setDefaultNumber('CALL');
        }
      } else {
        if (selectedRecord?.isDefaultSMS) {
          const response = await removeDefault(typeId[0]?.id);
          if (
            response?.data?.updateNumberDefaultCommunications?.returning.length
          ) {
            showToast(toast, removedDefault, ToastType.success, 4000);
          }
          props?.onActionClick(COMMON_ACTION_CODES.COMPLETED, selectedRecord);
          setStateData({
            ...stateData,
            changeDefaultModal: false,
            loading: false,
          });
        } else {
          const responseData = await checkDefaultNumber(typeId);
          if (responseData?.data?.numberDefaultCommunications?.length) {
            setStateData({
              ...stateData,
              changeDefaultModal: true,
              type: type,
              typeId: typeId[0]?.id,
              loading: false,
              defaultPhoneNumber:
                responseData?.data?.numberDefaultCommunications[0]
                  ?.virtualNumberAssignee?.virtualPhoneNumber || '',
            });
          } else {
            // const response = await chkInboxExist();
            if (isSmsInbox) {
              setDefaultNumber('SMS');
            } else {
              setSmsView();
            }
          }
        }
      }
    } else {
      showToast(toast, notAllowed, ToastType.error, 4000);
    }
  };

  const onClose = () => {
    setStateData({...stateData, changeDefaultModal: false});
  };
  const onConfirm = async () => {
    await removeDefaultAndAddNew();
    setStateData({...stateData, changeDefaultModal: false, loading: true});
  };

  return (
    <>
      <VStack space={2}>
        <View>
          {stateData?.loading && stateData?.type === 'CALL' && (
            <Spinner zIndex={9999} position={'absolute'} size="sm" />
          )}
          {canCreateSMSInboxWithPhoneNumber(selectedRecord?.phoneNumber?.phoneNumber) ? (
            <Checkbox
              isChecked={selectedRecord?.isDefaultCalling}
              onChange={() => {
                handleChkChange('CALL');
              }}
              value={'call'}
            >
              <Text
                size={'smMedium'}
                color={Colors.Custom.Gray700}
                style={{marginLeft: 4, marginRight: 6}}
              >
                {'Set default number for Calls'}
              </Text>
            </Checkbox>
          ) : (
            <></>
          )}
        </View>
        <View>
          {stateData?.loading && stateData?.type === 'SMS' && (
            <Spinner zIndex={9999} position={'absolute'} size="sm" />
          )}
          {canCreateSMSInboxWithPhoneNumber(selectedRecord?.phoneNumber?.phoneNumber) ? (
            <Checkbox
              isChecked={selectedRecord?.isDefaultSMS}
              onChange={() => {
                handleChkChange('SMS');
              }}
              value={'sms'}
            >
              {stateData?.loading && <Spinner size="sm" />}
              <Text
                size={'smMedium'}
                color={Colors.Custom.Gray700}
                style={{marginLeft: 4, marginRight: 6}}
              >
                {'Set default number for SMS'}
              </Text>
            </Checkbox>
          ) : (
            <></>
          )}
        </View>
      </VStack>
      <Modal
        isOpen={
          stateData?.changeDefaultModal && stateData?.id === selectedRecord?.id
        }
        onClose={onClose}
      >
        <Modal.Content style={styles.modalContainer}>
          <Modal.Header>
            <Text
              mt={2}
              style={{
                fontSize: 20,
                fontWeight: '500',
              }}
            >
              {'Change default number!'}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Text>
              <Text>{'Currently '}</Text>
              <Text color={Colors.secondary['500']}>
                {stateData?.defaultPhoneNumber}
              </Text>
              {` is marked as default ${
                stateData?.type === 'CALL' ? 'calling' : 'SMS'
              }. Are you sure, you want to change the default number `}
            </Text>
          </Modal.Body>

          <Modal.Footer style={styles.modalFooter}>
            <Button.Group>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onClose();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'cancel',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onConfirm();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'yesSetDefault',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {stateData?.smsInboxView && stateData?.id === selectedRecord?.id && (
        <SmsInboxDrawer
          isDrawerVisible={true}
          allUserList={stateData?.userList}
          selectedInboxData={{} as any}
          selectedPhoneNumber={stateData?.selectedPhoneNumber}
          showError={true}
          onSmsInboxActionPerformed={async (actionCode: string) => {
            if (actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE) {
              setStateData({...stateData, smsInboxView: false});
            }
            if (actionCode == SMS_INBOX_ACTION_CODES.ON_INBOX_SAVE) {
              await setDefaultNumber(stateData?.type);
              setStateData({...stateData, smsInboxView: false});
              props?.onActionClick(
                COMMON_ACTION_CODES.COMPLETED,
                selectedRecord
              );
            }
          }}
        />
      )}
    </>
  );
};
