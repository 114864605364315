import { AGE_ATTRIBUTES } from "../../../../constants";
import { IMlov, IVitalData } from "../../../../Interfaces";
import { Vital, VitalInputType } from "../../../../utils/VitalUtils";
import { IMembersDatas } from "../../MembersManagement/RulesInterface";
import { capitalizeText } from "./Helper";

export const getFormattedGroupData = (membersTableGroupData:IMembersDatas[], gendersList: IMlov[])=> {
    const formattedArray:any = [];
    membersTableGroupData.forEach((item:any)=> {
      const ageFormattedData = ''
      const gendersString = ''

      const temp = {
        id: item.id,
        name: item.name,
        description: item.description,
        executionFrequency: item.executionFrequency,
        age : ageFormattedData,
        accountUuid: item.account_uuid,
        rawData: item,
        genderText: gendersString,
        count: item.count || '',
        inActiveCount: item.inActiveCount || '',
        passedCount: item.passedCount || '',
        failedCount: item.failedCount || '',
        executionStatus: item.executionStatus || '',
        ...(item?.nextExecutionDateTime ? { nextExecutionDateTime: item?.nextExecutionDateTime } : item?.executionDateTime && { executionDateTime: item.executionDateTime })
      }
      formattedArray.push(temp)
    })
  return formattedArray;
}

export const getFrequencyValue = (rawData: any) => {
  const frequencyMap = new Map<string, string>([
    ['1d', '1 day'],
    ['1w', '1 week'],
    ['1M', '1 month'],
  ]);
  const frequency = rawData.executionFrequency || rawData.execution_frequency || rawData?.inputRuleDetails?.executionFrequency;
  return frequencyMap.get(frequency);
};

export const getAgeFormattedData = (ageData:any)=> {
  let returnString = ''
  if(AGE_ATTRIBUTES.BETWEEN_AGE === ageData.operator){
    returnString = `${ageData?.from} - ${ageData.to}`
  }else if(AGE_ATTRIBUTES.GREATER_THEN === ageData.operator){
    returnString = `> ${ageData.from}`
  }else if(AGE_ATTRIBUTES.LESS_THEN === ageData.operator){
    returnString = `< ${ageData.to}`
  }
  return returnString
}

export const getGenderFormattedString = (genderIds:string[],genderList: IMlov[])  => {
let returnString = ''
genderIds.forEach((id)=> {
  const genderName = genderList.find((item, index)=> {
    return item.id === id
  })
  returnString  = genderName?.code ?  returnString +  capitalizeText(genderName?.code) +  ', ' : ''
})
return returnString
}

export const getVitalListForRuleEngine = (vitalList: IVitalData[]) => {
  const list = vitalList;
  const bpIndex = list.findIndex((item) => item.loinc === Vital.bloodPressure);
  if (bpIndex > -1) {
    const bpVital = list.splice(bpIndex, 1)?.[0];
    if (bpVital) {
      list.push({
        loinc: Vital.systolicBloodPressure,
        unit: bpVital.unit,
        foldDisplay: 'Systolic Blood Pressure',
        inputType: VitalInputType.numeric,
        displayUnit: bpVital.displayUnit,
      });
      list.push({
        loinc: Vital.diastolicBloodPressure,
        unit: bpVital.unit,
        foldDisplay: 'Diastolic Blood Pressure',
        inputType: VitalInputType.numeric,
        displayUnit: bpVital.displayUnit,
      });
    }
  }
  return list;
}
