import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { Divider, HStack, Icon, Spinner, useToast, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BUTTON_TYPE, DISPLAY_DATE_FORMAT, MLOV_CATEGORY } from '../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { IUser } from '../../../../../Interfaces/CommonInterfaces';
import { CareJourneyQueries, LeadQueries, UserQueries } from '../../../../../services';
import { Colors } from '../../../../../styles';
import { getAccountUUID } from '../../../../../utils/commonUtils';

import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import {
  getDateObjFromDateStrAndFormat,
  getDateToMomentISOString,
  getEndOfDate,
  getJourneyStartDateWithOffset,
  getMomentObj,
  isTodayDate
} from '../../../../../utils/DateUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import CustomStepView from '../../../../common/CustomStepView/CustomStepView';
import { IStep } from '../../../../common/CustomStepView/interfaces';
import FHAlertDialog from '../../../../common/FHAlertDialog/FHAlertDialog';
import MemberInfoListItem from '../../../../common/MemberInfoListItem/MemberInfoListItem';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { IPersonHeaderBar } from '../../../../PersonOmniView/PersonHeaderBar/interfaces';
import { PERSON_ACTION_CODES } from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import { getFormDataFromLeadData } from '../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { JOURNEY_START_TYPES } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { IProductDetailForm } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import { removeUnwantedKeyPatientDocumentObj } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/ProductFormUtils';
import { IJourneyDetailForm } from '../../AddOrUpdateJourney/AddOrUpdateJourney';
import { isNextButtonDisabled, isTabDisabled } from '../../AddOrUpdateJourney/AddOrUpdateJourneyHelper';
import AddOrUpdateJourneyTabs from '../../AddOrUpdateJourney/AddOrUpdateJourneyTabs/AddOrUpdateJourneyTabs';
import { getCareJourneyEndDate } from '../../AddOrUpdateJourney/CareJourneyPreview/CareJourneyPreviewHelper';
import { saveJourneyToWorkflow } from '../../AddOrUpdateJourney/JourneyMetadataService';
import { JOURNEY_TABS } from '../../AddOrUpdateJourney/JourneyTabs';
import { JOURNEYS_STATUS_CODE } from '../PatientCareJourney/PatientCareJourneyHelper';
import { IPatientJourneyUserData } from '../PatientCareJourney/PatientJourneyInterface';

export interface IAssignCareJourneyNavigationState {
  personData?: any;
  isFromBulkAssign?: boolean;
  bulkApiData?: any;
}

export const AssignCareJourneyWorkflow = (props: IPersonHeaderBar) => {
  const commonContextData = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as IAssignCareJourneyNavigationState;
  const isFromBulkAssign = state?.isFromBulkAssign || false;
  const patientCareJourneyId = location.state?.patientCareJourneyId as string;
  const workflowMasterId = location.state?.workflowMasterId as string;
  const patientCareJourneyStatusId = location.state?.patientCareJourneyStatusId as string;
  const mlovData = useContext(CommonDataContext);
  const params = useParams();
  const accountUUID = getAccountUUID();

  const [assignCareJourney] = useMutation(
    CareJourneyQueries.ASSIGN_PATIENT_CARE_JOURNEY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updateCareJourney] = useMutation(
    CareJourneyQueries.ASSIGN_PATIENT_CARE_JOURNEY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [assignBulkJourneys] = useMutation(CareJourneyQueries.ASSIGN_BULK_PATIENT_CARE_JOURNEY, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  });

  const [checkCareTeam, setCheckCareTeam] = useState(false)
  const [checkPrimaryCareTeam, setCheckPrimaryCareTeam] = useState(false)
  const [screenConfig, setScreenConfig] = useState({
    loading: false,
    isSaveButtonDisabled: true,
    selectedStep: JOURNEY_TABS.DETAIL_AND_MEMBERS,
  });

  const [details, setDetails] = useState<IJourneyDetailForm | undefined>();
  const [noUserConfiguredForSomeRole, setNoUserConfiguredForSomeRole] = useState(false)
  const [rolesWithNoUser, setRolesWithNoUser] = useState<(string | undefined)[]>()
  const [showNoUserConfiguredAlert, setShowNoUserConfiguredAlert] = useState(false)
  const [showStartDateAlert, setShowStartDateAlert] = useState(false)
  const [showStartDateWithDurationAlert, setShowStartDateWithDurationAlert] = useState(false)

  const [accountUsers, setAccountUsers] = useState([])
  const [missingRoles, setMissingRoles] = useState<string[] | undefined>()
  const [stateData,setStateData] = useState({
    showLocationNotMatchAlert: false
  })
  const roleIdToRoleNameMap: Map<string, string> = new Map<string, string>();
  let selectedRolesWithNoUser: (string | undefined)[] = []

  useEffect(() => {
    getAccountUserWithRole({
      variables: {
        accountId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
  }, []);

  useEffect(() => {
    handleCareTeamRoleSelect(getRoleWiseUserListMap(accountUsers), details?.careTeam);
  }, [details]);

  const {loading, data} = useQuery(LeadQueries.GetContact, {
    skip: isFromBulkAssign,
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      id: params.patientId,
    },
  });

  const roleList =
  getMlovListFromCategory(commonContextData.MLOV, MLOV_CATEGORY.USER_ROLES) ||
  [];

  const isMatchUserRoleWithCareTeamRole = (
    careTeamRoleId: string | undefined,
    user: IUser,
  ): boolean => {
    return (user.userRoles || []).some(userRole => {
      return userRole.userRole?.userRole?.id === careTeamRoleId;
    });
  };

  const fetchRoleWiseUserList = (roleId: string, accountUserList?: IUser[]) => {
    const filteredUsers = (
      accountUserList ||
      []
    ).filter(user => {
      return isMatchUserRoleWithCareTeamRole(roleId, user);
    });
    const formattedUsers: IPatientJourneyUserData[] = (filteredUsers || []).map(
      user => {
        return {
          name: user.name,
          uuid: user.uuid,
          roleId: roleId,
        } as IPatientJourneyUserData;
      },
    );
    return formattedUsers;
  };

  const getRoleWiseUserListMap = (accountUserList?: IUser[]) => {
    const roleWiseUsersMap: Map<string, IPatientJourneyUserData[]> = new Map<
      string,
      IPatientJourneyUserData[]
    >();
    (roleList || []).forEach(role => {
      const roleWiseUsers: IPatientJourneyUserData[] = fetchRoleWiseUserList(
        role.id,
        accountUserList,
      );
      roleWiseUsersMap.set(role.id, roleWiseUsers);
      roleIdToRoleNameMap.set(role.id, role.value)
    });
    return roleWiseUsersMap;
  };

  const getRoleIdToRoleNameMap = (accountUserList?: IUser[]) => {
    const roleIdToRoleNameMapping: Map<string, string> = new Map<
      string,
      string
    >();
    (roleList || []).forEach(role => {
      roleIdToRoleNameMapping.set(role.id, role.value)
    });
    return roleIdToRoleNameMapping;
  };

  const [getAccountUserWithRole] = useLazyQuery(
    UserQueries.GET_DEFAULT_ROLES_FOR_JOURNEY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const accountUsers = cloneDeep(data?.users);
        setAccountUsers(accountUsers)
      },
      onError: error => {
        showErrorToast();
      },
    },
  );

  const handleCareTeamRoleSelect = (roleWiseUserList: Map<string, IPatientJourneyUserData[]>, careTeamRoles?: any[]) => {
    selectedRolesWithNoUser = []
    setNoUserConfiguredForSomeRole(false);
    (careTeamRoles || []).forEach(roleKey => {
      const userList = roleWiseUserList.get(roleKey);
      if(userList?.length===0 || userList===undefined){
        setNoUserConfiguredForSomeRole(true)
        selectedRolesWithNoUser.push(roleIdToRoleNameMap.get(roleKey))
        setRolesWithNoUser(selectedRolesWithNoUser)
      }
    });
  };

  const patientJourneyStatusList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS) || [];

  const pData =
    state && state.personData
      ? state.personData
      : getFormDataFromLeadData(data?.contact, mlovData);

  const onPersonActionPerformed = () => {
    if (isOnLastStep()) {
      onSubmit();
    } else {
      moveToNextStep();
    }
  };

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const onSubmit = () => {
    if (screenConfig.isSaveButtonDisabled || !details || !details.nodes) {
      return;
    }
    setScreenConfig((prev) => ({...prev, loading: true}));
    // Save journey to workflow
    details.careJourneyWorkflowId = undefined;
    if (workflowMasterId) {
      details.careJourneyWorkflowId = workflowMasterId;
    }
    saveJourneyToWorkflow(
      details,
      (response) => {
        if (response?.workflowMasterId) {
          assignJourney(
            details,
            params.careJourneyId || '',
            response.workflowMasterId,
            response.id,
          );
        } else {
          setScreenConfig((prev) => ({...prev, loading: false}));
          showErrorToast();
        }
      },
      () => {
        setScreenConfig((prev) => ({...prev, loading: false}));
        showErrorToast();
      }
    );
  };

  const getCareTeamUser = (data: IProductDetailForm) => {
    const careTeamUsers: any[] = [];
    (data.careTeam || []).forEach(userRole => {
      const selectedUser = (data.careTeamUsers || []).find((user: any) => {
        return userRole === user?.roleId;
      });

      if (selectedUser) {
        careTeamUsers.push({roleId: userRole, userId: selectedUser?.uuid});
      } else {
        careTeamUsers.push({roleId: userRole, userId: undefined});
      }
      return careTeamUsers;
    })
  };

  // Save journey in care studio
  const assignJourney = (
    data: IJourneyDetailForm,
    careJourneyId: string,
    careJourneyVersionId: string,
    workflowId: string,
    ) => {
    const isAssignJourneyWorkflow = !patientCareJourneyId;
    const isEditJourneyWorkflow = patientCareJourneyId && patientCareJourneyStatusId;
    const isFixedStartDate = data.startType === JOURNEY_START_TYPES.FIXED;
    let startDate = !!data.startDate ? getDateObjFromDateStrAndFormat(
      data.startDate,
      DISPLAY_DATE_FORMAT
    ) : undefined;

    if (isFixedStartDate) {
      startDate = getMomentObj(data?.startDate as string).toDate();
    }

    if (isNaN(startDate as any)) {
      startDate = undefined;
    }

    if (!startDate && isFromBulkAssign) {
      setScreenConfig((prev) => ({...prev, loading: false}));
      showToast(toast, 'Please select start date', ToastType.error);
      return;
    }

    /*data.careTeamUsers?.map((user) => {
      return {roleId: user.roleId, userId: undefined};
    }) ||*/
    const userList: any[] =  [];
    const locationList: any[] = [];
    const documents = removeUnwantedKeyPatientDocumentObj(data?.careJourneyDocuments || []) || [];
    const recommendCareJourneyList: any[] = [];
    (data.careTeam || []).forEach(userRole => {
      const selectedUser = (data.careTeamUsers || []).find((user: any) => {
        return userRole === user?.roleId;
      });

      if (selectedUser) {
        userList.push({roleId: userRole, userId: selectedUser?.uuid});
      } else {
        userList.push({roleId: userRole, userId: undefined});
      }
    });
    data?.patientCareJourneyLocations?.forEach((location)=>{
      locationList.push({locationId: location})
    })
    data?.patientRecommendedCareJourney?.forEach((journey)=>{
      recommendCareJourneyList.push({packageId: journey})
    })
    let journeyStatusId = undefined;
    if (isAssignJourneyWorkflow) {
      const journeyStatus = startDate && startDate <= new Date() ? JOURNEYS_STATUS_CODE.ACTIVE : JOURNEYS_STATUS_CODE.ASSIGNED;
      journeyStatusId = getMlovIdFromCode(patientJourneyStatusList, journeyStatus)
    }
    if (isEditJourneyWorkflow) {
      journeyStatusId = patientCareJourneyStatusId;
      /**
       * if journey status is ASSIGNED and update start date as current date then move journey status to active
       * else pass old journey status id
       */
      const assignStatusId: string = getMlovIdFromCode(patientJourneyStatusList, JOURNEYS_STATUS_CODE.ASSIGNED);
      const activeStatusId: string = getMlovIdFromCode(patientJourneyStatusList, JOURNEYS_STATUS_CODE.ACTIVE);
      if (assignStatusId === patientCareJourneyStatusId && startDate && startDate <= new Date()) {
        journeyStatusId = activeStatusId;
      }
    }
    const journeyStartAndEndDate = { startDate: '', endDate: '' };
    if (startDate) {
      if (isFixedStartDate) {
        journeyStartAndEndDate.startDate = getDateToMomentISOString(startDate);
      } else {
        if (isTodayDate(startDate)) {
          journeyStartAndEndDate.startDate = getDateToMomentISOString();
        } else {
          journeyStartAndEndDate.startDate = getJourneyStartDateWithOffset(startDate)?.toISOString();
        }
      }
      const endDate = getCareJourneyEndDate(new Date(journeyStartAndEndDate.startDate), data);
      journeyStartAndEndDate.endDate = getDateToMomentISOString(endDate);
    }
    const params: any = {
      title: data.name,
      description: data.description,
      careJourneyId,
      careJourneyVersionId,
      memberOutcome: data.memberOutcome,
      workflowId: workflowId,
      businessOutcome: data.businessOutcome,
      patientId: pData?.patientUuid,
      duration: data?.duration?.value,
      durationUnitId: data?.duration?.unit,
      ...(journeyStatusId && { statusId: journeyStatusId }),
      startDateTime: data.startDateTime || journeyStartAndEndDate?.startDate || undefined,
      endDateTime: journeyStartAndEndDate?.endDate || undefined,
      patientCareJourneyTeam: {
        data: userList,
      },
      patientCareJourneyLocations: {
        data: locationList
      },
      careJourneyTrackingResources: data?.careJourneyTrackingResources || [],
      patientRecommendedCareJourneys: {
        data: recommendCareJourneyList
      },
      documents: documents,
      startTypeId: data.startTypeId
    };
    if (data.updatedNodes && data.updatedNodes.length > 0) {
      params.workflowConfig = data.updatedNodes;
    }

    if (isFromBulkAssign) {
      const groupInputData: any = [];
      (state?.bulkApiData?.groups || []).forEach((groupData: any) => {
        groupInputData.push({
          groupId: groupData?.groupId,
          contactIds: groupData?.contactIds || [],
        });
      });
      assignBulkJourneys({
        variables: {
          params: {
            ...(groupInputData?.length && { groups: groupInputData}),
            patientCareJourney: params,
          },
        },
        onCompleted: () => {
          setScreenConfig((prev) => ({...prev, loading: false}));
          showToast(
            toast,
            'Journey assigning in progress',
            ToastType.success
          )
          navigate(-1);
        },
        onError: (error) => {

          setScreenConfig((prev) => ({...prev, loading: false}));
          showErrorToast();
        },
      });
      return;
    }

    if (patientCareJourneyId) {
      updateCareJourney({
        variables: {
          data: {
            id: patientCareJourneyId,
            operationCode: 'UPDATE_DATA',
            data: params,
          },
        },
        onCompleted: () => {
          setScreenConfig((prev) => ({...prev, loading: false}));
          if (pData?.id) {
            props?.navigateOrOpenContactIdDrawer?.(pData.id,{ state: {middleContainerTab: "JOURNEYS" } });
          } else {
            navigate(-1);
          }
        },
        onError: (error: any) => {

          setScreenConfig((prev) => ({...prev, loading: false}));
          showErrorToast();
        },
      });
    } else {
      assignCareJourney({
        variables: {
          data: {
            operationCode: 'CREATE',
            data: params,
          },
        },
        onCompleted: () => {
          setScreenConfig((prev) => ({...prev, loading: false}));
          if (pData?.id) {
              props?.navigateOrOpenContactIdDrawer?.(pData.id,{ state: {middleContainerTab: "JOURNEYS" } });

          } else {
            navigate(-1);
          }
        },
        onError: (error: any) => {

          setScreenConfig((prev) => ({...prev, loading: false}));
          showErrorToast();
        },
      });
    }
  };

  const journeyDetailSteps = (): IStep[] => {
    const orderDetailTabView: IStep[] = [
      {
        key: JOURNEY_TABS.DETAIL_AND_MEMBERS,
        title: 'Details and Members',
        isDisabled: isTabDisabled(
          JOURNEY_TABS.DETAIL_AND_MEMBERS,
          true,
          details
        ),
      },
      {
        key: JOURNEY_TABS.JOURNEY_DESIGN,
        title: 'Journey Design',
        isDisabled: isTabDisabled(JOURNEY_TABS.JOURNEY_DESIGN, true, details),
      },
      {
        key: JOURNEY_TABS.CARE_TEAM,
        title: 'Care Team',
        isDisabled: isTabDisabled(JOURNEY_TABS.CARE_TEAM, true, details),
      },
      {
        key: JOURNEY_TABS.PREVIEW,
        title: 'Preview',
        isDisabled: isTabDisabled(JOURNEY_TABS.PREVIEW, true, details, noUserConfiguredForSomeRole),
      },
    ];
    return orderDetailTabView;
  };

  const getNextStep = (currentStep: IStep): IStep => {
    const steps = journeyDetailSteps();
    const currentIndex = steps.findIndex(
      (item) => item.key === currentStep.key
    );
    if (steps.length > currentIndex + 1) {
      return steps[currentIndex + 1];
    }
    return steps[currentIndex];
  };

  const getStepByKey = (key: string) => {
    const steps = journeyDetailSteps();
    return steps.find((item) => item.key === key);
  };

  const isNextBtnDisabled = () => {
    const currentStep = getStepByKey(screenConfig.selectedStep);
    const nextStep = getNextStep(currentStep as IStep);
    return isNextButtonDisabled(nextStep.key, true, details);
  };

  const isOnLastStep = () => {
    const currentStep = getStepByKey(screenConfig.selectedStep) as IStep;
    const lastIndex = journeyDetailSteps().length - 1;
    return journeyDetailSteps()[lastIndex].key === currentStep?.key;
  };

  const isStartDateIsLessThanCurrentDate = (addDuration: boolean) => {
    if(details?.startType === JOURNEY_START_TYPES.FIXED && details?.startDateTime){
      if(addDuration && details?.startDateTime){
        const journeyEndDate = getCareJourneyEndDate(new Date(details?.startDateTime), details)
        if(journeyEndDate < new Date()){
          return true
        }
      }
      else if(getEndOfDate(new Date(details?.startDateTime)) < new Date()){
        return true
      }

    } else{
      return false
    }
    return false
  }

  const moveToNextStep = (selectedNextStep?: IStep) => {
    getRoleIdToRoleNameMap(accountUsers)
    const nextStep = selectedNextStep ? selectedNextStep : getNextStep(
      getStepByKey(screenConfig.selectedStep) as IStep
    );

    if(getStepByKey(screenConfig.selectedStep)?.key === JOURNEY_TABS.DETAIL_AND_MEMBERS && !patientCareJourneyId && isStartDateIsLessThanCurrentDate(true)){
      setShowStartDateWithDurationAlert(true)
    }
    else if(getStepByKey(screenConfig.selectedStep)?.key === JOURNEY_TABS.DETAIL_AND_MEMBERS && !patientCareJourneyId && isStartDateIsLessThanCurrentDate(false) && !showStartDateAlert){
      setShowStartDateAlert(true)
    }
    else if(getStepByKey(screenConfig.selectedStep)?.title === "Care Team" && noUserConfiguredForSomeRole){
      setShowNoUserConfiguredAlert(true)
    }
    else if (getStepByKey(screenConfig.selectedStep)?.title === "Care Team" && (details?.startDate && details?.careTeam?.length !== details?.careTeamUsers?.length)) {
      if(details?.careTeamUsers?.length === 0){
        setMissingRoles(details?.careTeam)
      }
      else{
        const missingRoles =details?.careTeam?.filter(roleId =>
          !details?.careTeamUsers?.some(user => user.roleId === roleId)
        );
      }
      setCheckCareTeam(true);
    }
    else {
      if(showStartDateAlert){
        setShowStartDateAlert(false)
      }
      setScreenConfig((prev) => {
        return {
          ...prev,
          selectedStep: nextStep.key,
        };
      });
    }
  };

  const getPersonAction = () => {
    return isOnLastStep()
      ? PERSON_ACTION_CODES.ASSIGN
      : PERSON_ACTION_CODES.NEXT;
  };

  const roleNamesWithNoUserConfigured = () => {
    let roleNamesWithNoUserConfigured = ""
    let count = 0
    rolesWithNoUser?.forEach((roleName) => {
      count += 1
      roleNamesWithNoUserConfigured +=  "\n\t" + count + ". " + roleName
    })
    roleNamesWithNoUserConfigured += "\n\nPlease update care team to include roles for which there are users in the system. Alternatively, you can go to the accounts section and setup users for these roles."
    return roleNamesWithNoUserConfigured
  }

  const navigateToPatientOmniView = (contactId: number) => {
    const route = `/members/patient/${contactId}`;
    navigate(route, {replace: true});
  };

  return (
    <>
      {(loading || screenConfig.loading) && (
        <View
          zIndex={10}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          top="50%"
          left="50%"
        >
          <Spinner size="lg" />
        </View>
      )}
      {!loading && !screenConfig.loading && (
        <VStack backgroundColor={'#F9FAFB'} height={'100vh'} space={3}>
          <VStack
            backgroundColor={Colors.Custom.ContainerBGColor}
            // marginTop={-2.5}
            // paddingBottom={2}
            // borderBottomWidth={1}
            borderBottomColor={Colors.Custom.BorderColor}
          >
            <TitleSubtitleView
              showBackButton={true}
              containerStyle={{marginBottom: 0}}
              onBackClick={() => {
                if (isFromBulkAssign) {
                  navigate(-1);
                  return;
                }
                props?.navigateOrOpenContactIdDrawer?.(pData.id,{ state: {middleContainerTab: "JOURNEYS" } });
              }

              }
              patientContextView={
                <View marginLeft={4} marginTop={1}>
                  {
                    !isFromBulkAssign &&
                    <HStack space={4}>
                      <Divider
                        orientation="vertical"
                        thickness={1}
                        background="gray.300"
                        height="auto"
                      />
                      <MemberInfoListItem
                        contactData={data?.contact}
                        showEmail={false}
                        showDateOfBirth={true}
                      />
                    </HStack>
                  }
                </View>
              }
            />
            <View marginTop={0}>
              <CustomStepView
                stepList={journeyDetailSteps()}
                onStepClick={(step) => {
                  moveToNextStep(step)
                }}
                selectedStep={getStepByKey(screenConfig.selectedStep)}
                actionContainer={
                  <View>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          if (isOnLastStep()) {
                            if (details?.showLocationAlert) {
                              setStateData((prev)=> {
                                return {
                                  ...prev,
                                  showLocationNotMatchAlert: true
                                }
                              })
                            } else {
                              onSubmit();
                            }
                          } else {
                            moveToNextStep();
                          }
                        },
                        leftIcon: isOnLastStep() ? (
                          <Icon
                            as={AntIcon}
                            name={'save'}
                            size="4"
                            color={Colors.Custom.mainPrimaryPurple}
                          />
                        ) : undefined,
                        isDisabled: isNextBtnDisabled(),
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: isOnLastStep()
                            ? patientCareJourneyId
                              ? 'Update'
                              : 'Assign'
                            : 'next',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </View>
                }
              />
            </View>
          </VStack>
          <VStack flex={1} overflow='scroll' maxHeight={'85vh'} minHeight={'86vh'}>
            <AddOrUpdateJourneyTabs
              isAssignWorkflow={true}
              selectedStep={screenConfig.selectedStep}
              onDetailChange={(detail, nodes, edges, isSaveDisabled, noUserConfiguredForSomeRole) => {
                setDetails({...detail, nodes, edges});
                setScreenConfig((prev) => ({
                  ...prev,
                  isSaveButtonDisabled: isSaveDisabled,
                }));
                if(noUserConfiguredForSomeRole!==undefined){setNoUserConfiguredForSomeRole(noUserConfiguredForSomeRole)};
              }}
            />
          </VStack>
          <FHAlertDialog
            isOpen={checkCareTeam}
            header={intl.formatMessage({ id: 'noUserSelectedForArole' })}
            message={'Please select users for all roles added in care team to proceed.'}
            buttonActions={[
              {
                textLocalId: 'Okay',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY
                },
                onPress: () => {
                  setCheckCareTeam(false)
                },
              },
            ]}
            customHeight={"auto"}
          />
          <FHAlertDialog
            isOpen={showStartDateAlert}
            header={intl.formatMessage({ id: 'journeyStartDateErrorTitle' })}
            customHeight={"auto"}
            message={intl.formatMessage({id: 'journeyStartDateErrorMessage'})}
            buttonActions={[
              {
                textLocalId: 'no',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY
                },
                onPress: () => {
                  setShowStartDateAlert(false)
                },
              },
              {
                textLocalId: 'yes',
                buttonProps: {
                  variant: BUTTON_TYPE.PRIMARY
                },
                onPress: () => {
                  moveToNextStep()
                },
              },
            ]}
          />
           <FHAlertDialog
            isOpen={showStartDateWithDurationAlert}
            header={intl.formatMessage({ id: 'journeyStartDateDurationErrorTitle' })}
            customHeight={"auto"}
            message={intl.formatMessage({id: 'journeyStartDateDurationErrorMessage'})}
            buttonActions={[
              {
                textLocalId: 'Okay',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY
                },
                onPress: () => {
                  setShowStartDateWithDurationAlert(false);
                  navigateToPatientOmniView(data?.contact?.id || '');
                },
              },
            ]}
          />
          <FHAlertDialog
            isOpen={showNoUserConfiguredAlert}
            header={intl.formatMessage({ id: 'noUserWithARole' })}
            message={'There is no user with following roles in this account.' + " " + roleNamesWithNoUserConfigured()}
            buttonActions={[
              {
                textLocalId: 'Okay',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY
                },
                onPress: () => {
                  setShowNoUserConfiguredAlert(false)
                },
              },
            ]}
            customHeight={'33%'}
          />
          <FHAlertDialog
            isOpen={stateData?.showLocationNotMatchAlert}
            header={intl.formatMessage({ id: 'assignJourney' })}
            message={intl.formatMessage({ id: isFromBulkAssign ? 'bulkAssignLocationNotMatch' : 'locationNotMatch' })}
            buttonActions={[
              {
                textLocalId: 'cancel',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY
                },
                onPress: () => {
                  setStateData((prev)=> {
                    return {
                      ...prev,
                      showLocationNotMatchAlert: false
                    }
                  })
                },
              },
              {
                textLocalId: 'assign',
                buttonProps: {
                  variant: BUTTON_TYPE.PRIMARY
                },
                onPress: () => {
                  setStateData((prev)=> {
                    return {
                      ...prev,
                      showLocationNotMatchAlert: false
                    }
                  })
                  onSubmit();
                },
              },
            ]}
            customHeight={'23%'}
          />
        </VStack>
      )}
    </>
  );
};

export default withMiniContactViewHOC(AssignCareJourneyWorkflow);
