import { useLazyQuery } from '@apollo/client';
import { Drawer, Select as MultiSelect, Checkbox } from 'antd';
import { Divider, FormControl, HStack, Text, View, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT, MLOV_CATEGORY } from '../../../../constants';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { IMlov } from '../../../../Interfaces';
import { UserQueries } from '../../../../services';
import { Colors } from '../../../../styles';
import { getAccountUUID, getUserUUID } from '../../../../utils/commonUtils';
import { getDateStrFromMomentObj, getMomentObj, isFutureDay, getCurrentTimeZone } from '../../../../utils/DateUtils';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { ModalActionDatePicker } from '../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import { NoteFormTemplateSearch } from '../../../common/NoteFormTemplateSearch/NoteFormTemplateSearch';
import PAMISearch, { PAMISearchType } from '../../../common/PAMISearch/PAMISearch';
import ReasonForVisitSearch from '../../../common/ReasonForVisitSearch/ReasonForVisitSearch';
import { IUser } from '../../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { ICodeableParam } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import UserWithRoleItem from '../../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/UserWithRoleItem';
import { IFormListState } from '../PatientNotes/components/DocumentationView';

export enum OrderFilterCode {
  notSubmitted = 'Not submitted to the facility or pharmacy (draft)',
  submitted = 'Submitted to the facility or pharmacy',
  failedToSubmit = 'Failed to submit to the facility or pharmacy',
  pendingReports = 'Pending reports from facility',
  reportsReceived = 'Received reports from facility',
  reviewed = 'Reports reviewed by staff',
  pendingReview = 'Pending review',
}

export interface IOrderTimelineSelectedFilters {
  filterCodes?: OrderFilterCode[];

  createdByUserIds?: string[];
  createdByUsers?: IUser[];

  // signedByUserIds?: string[];
  // signedByUsers?: IUser[];

  createdOnStartDate?: string;
  // signedOnStartDate?: string;

  createdOnEndDate?: string;
  // signedOnEndDate?: string;

  // chiefComplaint?: {
  //   code: string;
  //   displayName: string;
  // };
  // diagnosis?: ICodeableParam;
  form?: IFormListState;
}

interface IProps {
  isVisible?: boolean;
  selectedFilters?: IOrderTimelineSelectedFilters,

  onClose?: () => void;
  onApply: (data?: IOrderTimelineSelectedFilters) => void;
}

interface IComponentState {
  selectedFilters: IOrderTimelineSelectedFilters;
  usersData?: IUser[];
}

export function OrderTimelineFilter(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    selectedFilters: (props.selectedFilters || {}),
    usersData: [],
  });
  const filterByList: OrderFilterCode[] = [
    OrderFilterCode.submitted,
    OrderFilterCode.notSubmitted,
    OrderFilterCode.failedToSubmit,
    OrderFilterCode.pendingReports,
    OrderFilterCode.reportsReceived,
    OrderFilterCode.reviewed,
    OrderFilterCode.pendingReview,
  ];
  const accountUuid = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const intl = useIntl();

  /**
   * Get all users
   */
  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR_WITHOUT_EMPLOYER, {
    variables: {
      accountId: accountUuid,
      roleCode: USER_ROLE_CODES.EMPLOYER
    },
  });

  const getUsersData = async () => {
    const getUsersResult = await getUsers();
    let users:  IUser[] = JSON.parse(JSON.stringify(getUsersResult?.data?.users));
    users = users?.sort?.((user1: IUser, user2: IUser) => {
      if (user1?.uuid === loggedInUserId) {
        return -1;
      }
      if (user2?.uuid === loggedInUserId) {
        return 1;
      }
      const userName1 = user1?.name?.toLowerCase() || '';
      const userName2 = user2?.name?.toLowerCase() || '';
      if (userName1 < userName2) return -1;
      if (userName2 < userName1) return 1;
      return 0;
    });
    if (users?.[0]?.uuid === loggedInUserId && users?.[0]?.name) {
      users[0].name = `${users?.[0]?.name} (You)`;
    }
    setComponentState(prev => ({
      ...prev,
      usersData: users,
    }));
  };

  const getUsersName = (userIds: any[], userList?: IUser[]) => {
    const userNames = [] as any;
    userIds.map((id) => {
      userList?.map((agentRecord: any) => {
        if (id === (agentRecord?.uuid)) {
          userNames.push(agentRecord?.name);
        }
      })
    })
    return userNames;
  }

  function renderDateElement(labelId: string, placeholderId: string, selectedFilterKey: string) {
    return (
      <ModalActionDatePicker
        label={labelId}
        value={(componentState.selectedFilters as any)?.[selectedFilterKey] ? getMomentObj((componentState.selectedFilters as any)[selectedFilterKey]) : null}
        format={DISPLAY_DATE_FORMAT}
        // placeholder={intl.formatMessage({ id: placeholderId })}
        placeholder={DISPLAY_DATE_FORMAT}
        onChange={(date) => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedFilters: {
                ...prev.selectedFilters,
                [selectedFilterKey]: (date?.toISOString?.() || null),
              },
            };
          });
        }}
        disabledDate={(current: any) => {
          return (
            current &&
            isFutureDay(current, getCurrentTimeZone())
          );
        }}
        customStyle={{flex: 1}}
      />
    );
  }

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <Drawer
      open={props.isVisible || false}
      width={700}
      onClose={props.onClose}
      closable={false}
      title={
        <>
          <ModalActionTitle
            title={'filterOrderTimelineDrawerTitle'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onClose?.();
                },
              },
              {
                show: true,
                id: 1,
                btnText: 'clearAllFiltersBtnLabel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      selectedFilters: {},
                    };
                  });
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'Apply',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  props.onApply?.(componentState.selectedFilters);
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack space={4}>
        <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray500 }}
              textLocalId={'filterPatientNotesDrawerFilterBy'}
            />
          </FormControl.Label>
          <VStack space={2}>
            {filterByList.map((filterKey) => {
              return (
              <Checkbox
                checked={componentState.selectedFilters.filterCodes?.includes(filterKey) || false}
                key={filterKey}
                value={filterKey}
                onChange={(event) => {
                  const selected = event.target.checked;
                  setComponentState((prev) => {
                    const filterCodes = prev.selectedFilters.filterCodes || [];
                    const index = filterCodes.indexOf(filterKey);
                    if (index !== -1) {
                      filterCodes.splice(index, 1);
                    }
                    if (selected) filterCodes.push(filterKey);
                    return {
                      ...prev,
                      selectedFilters: {
                        ...prev.selectedFilters,
                        filterCodes: filterCodes
                      },
                    };
                  });
                }}>
                <Text color={Colors.Custom.Gray500} paddingLeft={3} fontSize={'md'}>{filterKey}</Text>
              </Checkbox>);
            })}
          </VStack>
        </FormControl>
        <Divider marginY={5}/>

        <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray500 }}
              textLocalId={'filterPatientNotesDrawerCreatedByTitle'}
            />
          </FormControl.Label>
          <MultiSelect
            mode="multiple"
            size="large"
            allowClear
            value={getUsersName([...(componentState.selectedFilters?.createdByUserIds || [])], componentState.usersData)}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerCreatedByPlaceholder' })}
            onChange={(values: string[], selectedData: any[]) => {
              const userIds: string[] = selectedData?.map((item: any) => item.userId) || [];
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    createdByUserIds: (userIds || []),
                    createdByUsers: selectedData,
                  },
                };
              });
            }}
            maxTagCount={'responsive'}
          >
            {componentState.usersData?.map?.((agentRecord: any) => {
              return (
                <MultiSelect.Option
                  key={agentRecord?.uuid}
                  userId={agentRecord?.uuid}
                  value={agentRecord?.name}
                >
                  <UserWithRoleItem
                    agentRecord={agentRecord}
                  />
                </MultiSelect.Option>
              );
            })}
          </MultiSelect>
        </FormControl>

        {/* <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray500 }}
              textLocalId={'filterPatientNotesDrawerSignedByTitle'}
            />
          </FormControl.Label>
          <MultiSelect
            mode="multiple"
            size="large"
            allowClear
            value={getUsersName([...(componentState.selectedFilters?.signedByUserIds || [])], componentState.usersData)}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerSignedByPlaceholder' })}
            onChange={(values: string[], selectedData: any[]) => {
              const userIds: string[] = selectedData?.map((item: any) => item.userId) || [];
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    signedByUserIds: (userIds || []),
                    signedByUsers: selectedData,
                  },
                };
              });
            }}
            maxTagCount={'responsive'}
          >
            {componentState.usersData?.map?.((agentRecord: any) => {
              return (
                <MultiSelect.Option
                  key={agentRecord?.uuid}
                  userId={agentRecord?.uuid}
                  value={agentRecord?.name}
                >
                  <UserWithRoleItem
                    agentRecord={agentRecord}
                  />
                </MultiSelect.Option>
              );
            })}
          </MultiSelect>
        </FormControl> */}

        {/* <Divider marginY={5}/> */}

        <HStack space={2} alignItems={'center'}>
          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerCreatedOnStartDateTitle', 'filterPatientNotesDrawerCreatedOnStartDatePlaceholder', 'createdOnStartDate')}
          </View>

          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerCreatedOnEndDateTitle', 'filterPatientNotesDrawerCreatedOnEndDatePlaceholder', 'createdOnEndDate')}
          </View>
        </HStack>

        <Divider marginY={5}/>

        {/* <HStack space={2} alignItems={'center'}>
          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerSignedOnStartDateTitle', 'filterPatientNotesDrawerSignedOnStartDatePlaceholder', 'signedOnStartDate')}
          </View>

          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerSignedOnEndDateTitle', 'filterPatientNotesDrawerSignedOnEndDatePlaceholder', 'signedOnEndDate')}
          </View>
        </HStack> */}

        {/* <Divider marginY={5}/> */}

        {/* <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray500 }}
              textLocalId={'filterPatientNotesDrawerDiagnosisTitle'}
            />
          </FormControl.Label>
          <PAMISearch
            value={componentState.selectedFilters?.diagnosis}
            showAdditionalDetails={true}
            allowUserMultiSelect={true}
            enablePaginationOnScroll
            addNewOptionEnabled={false}
            clearOnSelection={false}
            searchType={PAMISearchType.diagnosis}
            // additionalHeaders={contextData.headers}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerDiagnosisPlaceholder' })}
            isShowError={false}
            onChange={(value) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    diagnosis: value,
                  },
                };
              });
            }}
          />
        </FormControl> */}

        {/* <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray500 }}
              textLocalId={'filterPatientNotesDrawerChiefComplaintTitle'}
            />
          </FormControl.Label>
          <ReasonForVisitSearch
            allowClear={true}
            showLabel={false}
            isRequired={false}
            value={componentState.selectedFilters?.chiefComplaint?.displayName}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerChiefComplaintPlaceholder' })}
            disallowToAddNewEntry={true}
            isShowError={false}
            onChange={(reasonForVisit) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    chiefComplaint: reasonForVisit,
                  },
                };
              });
            }}
          />
        </FormControl> */}

        <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray500 }}
              textLocalId={'filterPatientNotesDrawerNoteTemplateTitle'}
            />
          </FormControl.Label>
          <NoteFormTemplateSearch
            allowClear={true}

            value={componentState.selectedFilters?.form}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerNoteTemplatePlaceholder' })}
            onChange={(form) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    form: form,
                  },
                };
              });
            }}
          />
        </FormControl>

      </VStack>
    </Drawer>
  );
}
