import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const PracticeSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected ? Colors.primary['500'] : '#ffffff';

  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 19V3C15.5 2.46957 15.2893 1.96086 14.9142 1.58579C14.5391 1.21071 14.0304 1 13.5 1H9.5C8.96957 1 8.46086 1.21071 8.08579 1.58579C7.71071 1.96086 7.5 2.46957 7.5 3V19M3.5 5H19.5C20.6046 5 21.5 5.89543 21.5 7V17C21.5 18.1046 20.6046 19 19.5 19H3.5C2.39543 19 1.5 18.1046 1.5 17V7C1.5 5.89543 2.39543 5 3.5 5Z"
        stroke={strokeColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PracticeSvg;
