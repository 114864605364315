import { Colors } from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const Consumer = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : props.defaultColor || '#fff';
    
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9692 20C17.81 17.1085 16.9247 15 12 15C7.07526 15 6.18996 17.1085 6.03081 20M15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={strokeColor}
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Consumer;
