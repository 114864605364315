import { useApolloClient } from "@apollo/client";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TaskQueries } from "../../../../services";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import { TASK_EVENTS, TASK_MODULE_CODE } from "../CareDashboardConstants";
import { EventBus } from "../../../../utils/EventBus";
import { ITask } from "../CareDashboardInterfaces";
import useDebounceEvent from "./useDebounceEvent";
import { MLOV_CATEGORY, TASK_DUE_DATE_CODES, TASK_STATUS } from "../../../../constants/MlovConst";
import { getStartOfDay, getEndOfDay, getCurrentTimeZone } from "../../../../utils/DateUtils";
import { getMlovId } from "../../../../utils/mlovUtils";
import { CommonDataContext } from "../../../../context/CommonDataContext";

interface ICountParams {
  code: string;
  filters: any;
  skipCall?: boolean
}

export interface ITaskCount {
  code: string;
  count: number;
}

interface IUseTaskCountManagerInterface {
  params: ICountParams[];
  isFetchTodaysCount?: boolean;
  isSideAction?: boolean;
}

const useTaskCountManager = (args: IUseTaskCountManagerInterface) => {
  // Global constants
  const eventBus = EventBus.getEventBusInstance();
  // States
  const client = useApolloClient();
  const [taskCount, setTaskCount] = useState<ITaskCount[]>([]);
  const [todaysTaskCount, setTodaysTaskCount] = useState<ITaskCount[]>([]);
  const mlovData = useContext(CommonDataContext);
  const isSidecar = args.isSideAction

  const [loading, setLoading] = useState<boolean>(false);
  const {pushEvent} = useDebounceEvent({
    onDebounce: () => {
      refreshCount();
    }
  });

  // Lifecycle methods
  useEffect(() => {
    if (args.params.length) {
      refreshCount();
    }
  }, [args.params]);

  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.TASK_DELETED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.SUB_TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.SUB_TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_CATEGORY_CHANGED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.REFRESH_TASKS, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });

    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_DELETED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.SUB_TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.SUB_TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_CATEGORY_CHANGED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.REFRESH_TASKS, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    }
  }, []);

  // Public methods
  const refreshCount = () => {
    fetchTaskCountByParams();
    if(args.isFetchTodaysCount){
      fetchTodaysTaskCountByParams();
    }
  };

  // Event listeners
  const onTaskAddOrUpdate = useCallback((data: {task: ITask, code: string, data?:{new?:any}}) => {
    if (isSidecar) {
      if (data?.data?.new?.assigneeId || data?.data?.new?.statusId) {
        pushEvent();
      }
    } else {
      pushEvent();
    }
  }, [args.params.length]);

  // Private methods
  const fetchTaskCountByParams = async () => {
    setLoading(true);
    try {
      const paramList = args.params;

      const promises = paramList.map((countParams) => {
        if (countParams?.skipCall) {
          // When skipping call the count will be always 0.
          return new Promise(resolve => resolve({
            data: {getTasks: {aggregate: {total: 0}}}
          })) as any;
        };
        return client.query({
          query: TaskQueries.GET_AGGREGATE_TASK_COUNT,
          variables: {
            params: countParams.filters
          },
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          fetchPolicy: 'no-cache',
        })
      });

      if (promises.length) {
        const response = await Promise.all(promises);
        const countMap: ITaskCount[] = response.map((result, index) => {
          return {
            code: paramList?.[index]?.code,
            count: result?.data?.getTasks?.aggregate?.total || 0,
          }
        });
        setTaskCount(countMap);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }

  const fetchTodaysTaskCountByParams = async () => {
    setLoading(true);
    try {
      const paramList = args.params;

      const promises = paramList.map((countParams) => {
        if (countParams?.skipCall) {
          // When skipping call the count will be always 0.
          return new Promise(resolve => resolve({
            data: {getTasks: {aggregate: {total: 0}}}
          })) as any;
        };

        const todayStatusUuid = getMlovId(
          mlovData.CARE_STUDIO_MLOV,
          MLOV_CATEGORY.TASK_DUE_DATE_CATEGORY,
          TASK_DUE_DATE_CODES.TODAY
        );

        const params = {
          ...countParams.filters,
          startDateTime: undefined,
          endDateTime: undefined,
          timezone: getCurrentTimeZone(),
          taskDueDateCategoryIds: [todayStatusUuid]
        };

        return client.query({
          query: TaskQueries.GET_AGGREGATE_TASK_COUNT,
          variables: {
            params: params
          },
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          fetchPolicy: 'no-cache',
        })
      });

      if (promises.length) {
        const response = await Promise.all(promises);
        const countMap: ITaskCount[] = response.map((result, index) => {
          return {
            code: paramList?.[index]?.code,
            count: result?.data?.getTasks?.aggregate?.total || 0,
          }
        });
        setTodaysTaskCount(countMap);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }


  return { taskCount, loading, refreshCount, todaysTaskCount };
}

export default useTaskCountManager;
