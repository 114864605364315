import parse from 'html-react-parser';
import { Liquid } from 'liquidjs';
import {
  Pressable,
  Spacer,
  VStack,
  View
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { getAccountUUID, getFormURL } from '../../../utils/commonUtils';
import EmailTemplateCreatorEditorRightPanel from '../../RightSideContainer/ContentManagement/CampaignManagement/EmailOrSmsCreatorForm/EmailTemplateCreatorEditorRightPanel';
import { ContentTypes } from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getAccountMergeTagData,
  getAppLinkMergeTagData,
  getTemplates
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { ITemplateCategory } from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { CommonDataContext } from '../../../context/CommonDataContext';



const DisplayTemplateById = (props: {
  mergeTags?: Array<any>;
  hideEdit?: boolean;
  templateId: number;
  educationContent?: any;
  formList?: Array<any>;
  form?: any;
  message?: string;
  isGeneralTextPresent?:any;
  setIsFormListTagPresent?:any;
  setIsAppointmentLinkTagPresent?:any;
  templateCategories: ITemplateCategory[];
}) => {

  const accountUuid = getAccountUUID();
  if (!props?.templateId) {
    return <></>;
  }
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const searchTemplateList = (callback?: any) => {
    const path = `${ContentTypes.emails.path}/${props.templateId}`;
    getTemplates(path).then((response) => {
      callback ? callback(response.data) : undefined;
    });
  };
  useEffect(() => {
    if (props.templateId) {
      searchTemplateList((data: any) => {
        setSelectedTemplate(data);
        if(props.isGeneralTextPresent){
        props.isGeneralTextPresent(data.attributes.bodyHtml.indexOf('{{general.text}}') != -1)
        }
        if(props.setIsFormListTagPresent){
          props.setIsFormListTagPresent(data.attributes.bodyHtml.search('/{%\s*for\s+form\s+in\s+formList\s*%}/') != -1)
        }
        if(props.setIsAppointmentLinkTagPresent){
          props.setIsAppointmentLinkTagPresent(data.attributes.bodyHtml.search('/{{\s*appointment\.link\s*}}/') != -1)
        }
      });
    }
  }, [props.templateId]);

  // account specific merge tags
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const accountMergeTags = getAccountMergeTagData();
  const appLinkMergeTags = getAppLinkMergeTagData(commonData);



  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {

    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return { ...mergeTagsByCategory, global: accountMergeTags , ...appLinkMergeTags};
  };

  const getPreviewHtml = (): string => {
    const engine = new Liquid();
    const mergeTags: Record<string, any> = {
      general: {
        text: props.message,
      },
    };
    mergeTags['patient'] = {
      firstName: '',
    };
    if (props.form) {
      const formLink = getFormURL(props.form?.id, '', accountUuid);
      mergeTags['form'] = {
        link: formLink,
      };
    }

    if (props.message) {
      mergeTags['general'] = {
        text: props.message,
      };
    }

    if (props.educationContent) {
      mergeTags['article'] = props.educationContent;
    }

    if (props.formList) {
      const formList: any = [];
      (props.formList || []).forEach((form: any) => {
        const formLink = getFormURL(form?.id, '', accountUuid);
        formList.push({
          link: formLink,
          name: form?.name,
        });
      });
      mergeTags['formList'] = formList;
    }
    const tpl = engine.parse(selectedTemplate?.attributes?.bodyHtml);

    if(props.mergeTags){
      const mergeTags:any = {...props.mergeTags};
      mergeTags.patientapp =  mergeTags.patientapp || {};
      mergeTags.patientapp.user = '*******';
      mergeTags.patientapp.password = '*******';
      return engine.renderSync(tpl, {...mergeTags, ...appLinkMergeTags});
    } else {
      const finalMergeTag = { ...getMergeTags(selectedTemplate?.attributes?.name, (props?.templateCategories || [])), ...mergeTags };
      return engine.renderSync(tpl, finalMergeTag);
    }
    // const finalMergeTag = { ...getMergeTags(selectedTemplate?.attributes?.name, (props?.templateCategories || [])), ...mergeTags };
    // const tpl = engine.parse(selectedTemplate?.attributes?.bodyHtml);
    // return engine.renderSync(tpl, finalMergeTag);
  };
  const [shouldUpdateEmailTemplate, setShouldUpdateEmailTemplate] = useState(false);
  return (
    <>
      {' '}
      {selectedTemplate && (
        <VStack space={6} marginTop={5}>

          <VStack space={2}>
            {<View>
              {!props.hideEdit && !isSidecarContext &&<View
                padding={5}

                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'


                }}
              >
                <Pressable
                  style={{
                    backgroundColor: '#FFECD5',
                    borderRadius: 50,
                    padding: 8,
                    marginRight: 10
                  }}
                  onPress={() => {
                    setShouldUpdateEmailTemplate(true);
                  }}
                >
                  <AntDesign
                    name="edit"
                    color={'#8C210F'}
                    size={20}
                  />
                </Pressable>
              </View>}
              {parse(getPreviewHtml())}</View>}
            <Spacer />
          </VStack>
          {shouldUpdateEmailTemplate && (
            <EmailTemplateCreatorEditorRightPanel
              id={selectedTemplate.id}
              isHtml={selectedTemplate?.attributes?.bodyJson?.isHTML}
              isTemplateCreate={false}
              isVisible={true}
              onCancel={() => {
                setShouldUpdateEmailTemplate(false);
                //getOrSetEmailTemplates();
              }}
              shouldHideDefault={true}
              shouldHideTitle={true}
              preSelectedCategory={'Campaign'}
              shouldDisableChangeCategory={true}
              customOnSubmitFn={(resp: any) => {
                if (resp?.data?.id) {
                  setShouldUpdateEmailTemplate(false);
                  searchTemplateList((data: any) => {
                    setSelectedTemplate(data);
                  });
                }
              }}
            />
          )}
        </VStack>
      )}{' '}
    </>
  );
};


export default DisplayTemplateById;
