import { FILTER_COMPONENT_CODE } from '../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { IMlov } from '../../../../../Interfaces';
import {getChatDisplayTimeAgo, getMessageDisplayTimeAgoFullDate} from '../../../../../utils/DateUtils';
import { getUserConversationFilter } from '../ConversationChannelNew/ConversationAPIService';
import { getCodeAndUserConversationFilterData } from '../ConversationChannelNew/ConversationSidebarUtils';
import { IUserConversationFilterResp } from '../ConversationChannelNew/Interfaces';
import { CHANNEL_TYPE, CHANNEL_TYPE_CODE, USER_CONVERSATION_FILTER_CODES, USER_CONVERSATION_FILTER_CODES_LIST } from '../ConversationConst';
import {
  IConversationFilterState,
  IConversationHeaderBtn,
  IFilterSidebarData,
} from '../interfaces';
import {TimeType} from './ConversationConst';
import { getInboxFilterObj } from './ConversationFilters/ConversationFilterUtils';
import { ICommonFilterData } from './ConversationFilters/interface';
import { IExistingGroupMember } from './CreateGroupPopup/interfaces';
export const getConversationFilterObj = (
  actionCode: string,
  actionData: IMlov,
  converSationFilterObj: any
): any => {
  let finalConversationFilter = { ...converSationFilterObj };
  if (finalConversationFilter?._or) {
    delete finalConversationFilter._or;
  }
  switch (actionCode) {
    case COMMON_ACTION_CODES.STATUS_CHANGED:
      finalConversationFilter = updateStatusFilterObj(finalConversationFilter, actionData);
      break;
    case COMMON_ACTION_CODES.DATE_RANGED_CHANGED:
      finalConversationFilter = updateDateRangeFilterObj(finalConversationFilter, actionData);
      break;
    case COMMON_ACTION_CODES.ALL:
      finalConversationFilter = updateStatusFilterObj(finalConversationFilter, actionData);
      break;
    // care here for person type
    case COMMON_ACTION_CODES.FILTER_CHANGED:
      finalConversationFilter = updateContactTypeFilterObj(finalConversationFilter, actionData);
      break;
  }
  return finalConversationFilter;
};

export const getHeaderConversationFilterObj = (
  actionCode: string,
  actionData: any,
  headerFilterObj: IConversationFilterState
): any => {
  const finalConversationFilterObj = { ...headerFilterObj };
  switch (actionCode) {
    case COMMON_ACTION_CODES.STATUS_CHANGED:
      finalConversationFilterObj.statusObj = {
        displayString: actionData.code,
      };
      break;
    case COMMON_ACTION_CODES.DATE_RANGED_CHANGED: {
      finalConversationFilterObj.datePicker = {
        fromDate: actionData.fromDate,
        toDate: actionData.toDate,
      };
      break;
    }
    // case here for person type changed
    case COMMON_ACTION_CODES.FILTER_CHANGED:
      finalConversationFilterObj.contactType = {
        code: actionData?.code,
        displayString: actionData?.displayNameId,
      };
      break;

    case COMMON_ACTION_CODES.ALL:
      finalConversationFilterObj.statusObj = {
        displayString: actionData.code,
      };
      break;
  }
  return finalConversationFilterObj;
};

const updateStatusFilterObj = (
  conversationFilterObj: any,
  actionData: IMlov
) => {
  let finalConverSationFilterList: any = [];
  if (conversationFilterObj._or) {
    finalConverSationFilterList = [...conversationFilterObj._or];
  }
  const filterIndex = getIndexFilterObjFromKey(
    finalConverSationFilterList,
    'status'
  );
  if (actionData.showAll) {
    delete conversationFilterObj._or;
    return;
  }
  const statusObj = {
    status: {
      _eq: parseInt(actionData.value),
    },
  };

  if (filterIndex !== -1) {
    finalConverSationFilterList[filterIndex] = statusObj;
  } else {
    finalConverSationFilterList.push(statusObj);
  }
  conversationFilterObj._or = finalConverSationFilterList;
  return conversationFilterObj;
};

const updateDateRangeFilterObj = (
  conversationAndFilterObj: any,
  actionData: any
) => {
  if (actionData.fromDate && actionData.toDate) {
    conversationAndFilterObj._and = [
      {
        // assigneeLastSeenAt: {
        createdAt: {
          _lt: actionData.toDate,
        },
      },
      {
        // assigneeLastSeenAt: {
        createdAt: {
          _gt: actionData.fromDate,
        },
      },
    ];
  } else {
    delete conversationAndFilterObj._and;
  }
  return conversationAndFilterObj;
};

// a function to update person type filter object

export const updateContactTypeFilterObj = (
  conversationFilterObj: any,
  actionData: any // contact type action data
) => {
  const conversationContact = conversationFilterObj.conversationContact || {};
  conversationFilterObj.conversationContact = conversationContact;
  switch (actionData.code) {
    case 'PATIENT':
      conversationContact['contactType'] = {
        contactType: {
          code: {
            _eq: 'CUSTOMER',
          },
        },
      };
      break;
    case 'PROSPECT':
      conversationContact['contactType'] = {
        contactType: {
          _or: [
            {
              code: {
                _eq: 'VISITOR',
              },
            },
            {
              code: {
                _eq: 'LEAD',
              },
            },
          ],
        },
      };
      break;
    default:
      conversationContact['contactType'] = {
        contactType: {
          _or: [
            {
              code: {
                _eq: 'VISITOR',
              },
            },
            {
              code: {
                _eq: 'LEAD',
              },
            },
            {
              code: {
                _eq: 'CUSTOMER',
              },
            },
          ],
        },
      };
  }
  return conversationContact;
};

const getIndexFilterObjFromKey = (
  filterList: any[],
  filterKey: string
): number => {
  const filterIndex = filterList.findIndex((filterObj) => {
    return filterObj[filterKey];
  });
  return filterIndex;
};

export const updateConversationFilterObj = (
  currentFilterObj: any,
  selectedFilterObj: IFilterSidebarData
) => {
  const andFilterObj = currentFilterObj._and || [];
  const filterCodes = Object.keys(selectedFilterObj);
  filterCodes.forEach((filterCode: string) => {
    switch (filterCode) {
      case FILTER_COMPONENT_CODE.TAG: {
        const tempFilterData: any = selectedFilterObj.TAG || {};
        const filterIndex = getAndFilterObjFromKey(
          andFilterObj,
          'conversationsTaggings'
        );
        if (tempFilterData.length) {
          const tagFilterObj = getTagFilterObj(tempFilterData);
          if (filterIndex === -1) {
            andFilterObj.push(tagFilterObj);
          } else {
            andFilterObj[filterIndex] = tagFilterObj;
          }
        } else if (
          filterIndex !== -1 &&
          Object.keys(tempFilterData).length === 0
        ) {
          andFilterObj.splice(filterIndex, 1);
        }
        break;
      }
      case FILTER_COMPONENT_CODE.INBOX: {
        const tempFilterData = selectedFilterObj.INBOX || {};
        const filterIndex = getAndFilterObjFromKey(andFilterObj, 'inboxId');
        if (tempFilterData.id) {
          const inboxFilterObj = getInboxFilterObj(tempFilterData.id);
          if (filterIndex === -1) {
            andFilterObj.push(inboxFilterObj);
          } else {
            andFilterObj[filterIndex] = inboxFilterObj;
          }
        } else if (
          filterIndex !== -1 &&
          Object.keys(tempFilterData).length === 0
        ) {
          andFilterObj.splice(filterIndex, 1);
        }
        break;
      }
      case FILTER_COMPONENT_CODE.TEAM: {
        const filterIndex = getAndFilterObjFromKey(andFilterObj, 'teamId');
        const tempFilterData = selectedFilterObj.TEAM || {};
        if (tempFilterData.id) {
          const teamFilterObj = getTeamFilterObj(tempFilterData.id);
          if (filterIndex === -1) {
            andFilterObj.push(teamFilterObj);
          } else {
            andFilterObj[filterIndex] = teamFilterObj;
          }
        } else if (
          filterIndex !== -1 &&
          Object.keys(tempFilterData).length === 0
        ) {
          andFilterObj.splice(filterIndex, 1);
        }
        break;
      }
    }
  });
  if (andFilterObj && andFilterObj.length) {
    currentFilterObj._and = andFilterObj;
  } else {
    delete currentFilterObj._and;
  }
};

const getAndFilterObjFromKey = (andFilterObj: any[], key: string): number => {
  return andFilterObj.findIndex((singleFilter) => {
    let keys = [];
    if (singleFilter[key] && Object.keys(singleFilter[key])) {
      keys = Object.keys(singleFilter[key]);
    }
    return keys.length >= 1;
  });
};

export const getTagFilterObj = (tagItem: any) => {
  const tempFilterarray: any = [];
  tagItem?.forEach((item: any) => {
    const obj = {
      labelId: { _eq: item?.id },
      taggableType: { id: { _eq: item?.labelTypes[0]?.labelTypeId } },
    };
    tempFilterarray.push(obj);
  });
  return {
    conversationsTaggings: {
      _or: tempFilterarray,
    },
  };
};

export const getTeamFilterObj = (teamId: number) => {
  return {
    teamId: {
      _eq: teamId,
    },
  };
};

export const HEADER_BUTTON_CODES = {
  ALL_CONVERSATIONS: 'ALL_CONVERSATIONS',
  MENTIONS: 'MENTIONS',
};

export const HEADER_BUTTON_LIST: IConversationHeaderBtn[] = [
  {
    code: HEADER_BUTTON_CODES.ALL_CONVERSATIONS,
    name: 'allConversations',
  },
  {
    code: HEADER_BUTTON_CODES.MENTIONS,
    name: 'mentions',
  },
];

export const getAllGroupMemberListNames = (
  groupMembers: IExistingGroupMember[]
) => {
  let firstUserName = '';
  let remainingUsers = '';
  for (let index = 0; index < groupMembers.length; index++) {
    const element = groupMembers[index];
    const userName = element?.contact?.name || element?.user?.name;
    if (index === 0) {
      firstUserName = userName || '';
    } else {
      remainingUsers += userName;
    }
  }
  return {
    firstUserName,
    remainingUsers,
  };
};
export const getAppliedFilterCount = (newFilter: ICommonFilterData) => {

  let appliedFilterCount = 0;
  newFilter?.filterSections?.forEach((filterSection) => {
    filterSection?.filterOptions?.forEach((filterOption) => {
      if (filterOption.isSelected && !filterOption.isDefault) {
        appliedFilterCount++;
      }
    });
  });
  if (newFilter?.selectedAssignedUserData?.id) {
    appliedFilterCount++;
  }
  if (
    newFilter.selectedAssignedUsersData &&
    (newFilter.selectedAssignedUsersData || []).length > 0
  ) {
    appliedFilterCount += 1;
  }
  return appliedFilterCount
}

export const canShowChooseInbox = (selectedInboxTypeCode: any) => {
  return (
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL ||
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS ||
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS ||
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS
  );
};

export const getScheduleMsgTime = (dateStr: string,displayTimeType: string): string => {
  let convertedDateStr = '';
  if (dateStr && (dateStr.includes('Z') || dateStr.includes('z'))) {
    convertedDateStr = (displayTimeType && displayTimeType === TimeType.timeAgoFullDate) ? getMessageDisplayTimeAgoFullDate(dateStr) : getChatDisplayTimeAgo(dateStr);
  } else if (dateStr) {
    dateStr = dateStr + 'Z';
    convertedDateStr = (displayTimeType && displayTimeType === TimeType.timeAgoFullDate) ? getMessageDisplayTimeAgoFullDate(dateStr) : getChatDisplayTimeAgo(dateStr);
  }
  return convertedDateStr;
};
export const canShowAssignedUsers = (selectedTab: string) => {
  return (
    selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER
  );
}

export const isCheckSameChannelInboxNumber = (selectedInboxData: any, selectedInboxTypeCode: string) => {
  if (((selectedInboxData?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL)
    && (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL))
    || (((selectedInboxData?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS)
      || (selectedInboxData?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS))
      && ((selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS)
        || (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS)))
    || (selectedInboxData?.channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS)) {
    return true;
  }
  return false;
}

export const getUserConversationFilterDataFromAPI = async () => {
  try {
    const filterResp = await getUserConversationFilter({
      filterCodes: USER_CONVERSATION_FILTER_CODES_LIST,
    });
    if (!filterResp?.data?.filterList?.length) {
      return {} as IUserConversationFilterResp;
    }
    const filterCodeValueDataObj = getCodeAndUserConversationFilterData(
      filterResp?.data?.filterList
    );
    const assignedOtherFilterData =
      filterCodeValueDataObj[
        USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER
      ];
    return assignedOtherFilterData;
  } catch (error) {
    return {} as IUserConversationFilterResp;
  }
};
