import {Checkbox, Popover, Select, Tooltip} from 'antd';
import {isEqual} from 'lodash';
import {HStack, Pressable, View, Text} from 'native-base';
import {memo, useEffect, useRef, useState, useCallback, useContext} from 'react';
import { Platform} from 'react-native';
import '../../../../../components/common/CustomFilterView/OneViewFilter/popoverStyle.css';
import {FILTER_COMPONENT_CODE, SENDER_TYPE} from '../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import {IInbox} from '../../../../../services/Inbox/interfaces';
import {Colors} from '../../../../../styles';
import {getUserId, getUserUUID, isAllCallsChannelInboxType, isEmailChannelInboxType, isNumeric, isSMSMissedAllCallsChannelInboxType, isWebChannelInboxType} from '../../../../../utils/commonUtils';
import LocalStorage from '../../../../../utils/LocalStorage';
import FilterByTag from '../../../../common/CommonFilters/FilterByTags';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import CreateChannelView from '../../../../common/CreateChannelView/CreateChannelView';
import OneViewFilter from '../../../../common/CustomFilterView/OneViewFilter/OneViewFilter';
import ConversationSearchBar from '../../../../common/SearchBar/ConversationSearchBar';
import FilterNewIconSvg from '../../../../common/Svg/FilterNewIconSvg';
import TagsSvg from '../../../../common/Svg/TagsSvg';
import {formatInboxDataForSelectOptions} from '../ConversationChannelTabs/ChannelTabsUtils';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES, EMAIL_CONVERSATION_SUBTAB_CODES} from '../ConversationConst';
import {IContact, IConversationContainerProps, IConversationData} from '../interfaces';
import {CONVERSATION_STORAGE_KEY} from './ConversationConst';
import {canShowChooseInbox, HEADER_BUTTON_LIST, isCheckSameChannelInboxNumber} from './ConversationUtils';
import {styles} from './MessagingListStyles';
import EmailActionSvg from '../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import USFlagSVG from '../../../../common/Svg/FlagIcon/USFlagSVG';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import { EMAIL_CONVERSATION_LIST_SUBTAB } from '../ConversationChannelTabs/ConversationChannelTabConst';
import { IDisplayEmailSubtabList } from '../ConversationChannelTabs/interfaces';
import ConversationListV2 from './ConversationList/ConversationListV2';
import SelectPatientCallDrawer from '../../../CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';
import { EventBus } from '../../../../../utils/EventBus';

const {Option} = Select;
const ConversationContainer = (props: IConversationContainerProps) => {
  const {
    conversationFilterData,
    conversationFilterProps,
    filterModal,
    searchString,
    selectedTabCode,
    readConversationData,
    onConversationActionPerformed,
    setSearchText,
    onConversationFilterPerformed,
    selectedInboxTypeCode,
    onActionPerformed,
    allInboxList,
    isConversationSelected,
    enableConversationSelection,
    checkedSelectAllConversation,
    isDeselectedSomeConversations,
  } = props;

  const userInboxList: any = allInboxList;
  const isEFaxChannel = selectedInboxTypeCode ===  CHANNEL_TYPE_CODE.CHANNEL_E_FAX
  const selectedChannelType = selectedInboxTypeCode || '';
  const inboxRef: any = useRef()
  const inboxWidth = inboxRef?.current?.clientWidth;
  const curSelChannelTypeInboxList: IInbox[] =
    (selectedChannelType === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS ||
    selectedChannelType === CHANNEL_TYPE_CODE.ALL_CALLS
      ? userInboxList[CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]
      : userInboxList[selectedChannelType]) || [];
  const inboxFormattedData = formatInboxDataForSelectOptions(
    curSelChannelTypeInboxList
  );
  const userUuid = getUserUUID();
  const userId = getUserId();
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const currentUserInboxes =
    inboxFormattedData?.filter((item) => {
      const inboxMember = item?.inboxMembers?.find(
        (item) => item?.userId == userId
      );
      return inboxMember?.userId;
    }) || [];

  const [conversationState, setConversationState] = useState({
    popover1: false,
    popover2: false,
    displayCreateGroupPopup: false,
    createGroupPopupOpenCount: 0,
    selectedHeaderBtn: HEADER_BUTTON_LIST[0],
    selectedTabCode: CONVERSATION_TAB_CODES.RECENT,
    isDrawerVisible: false,
    groupCreatedCount: 0,
    searchString: props?.searchString,
    autoFocusCount: 1,
    resetSearchTextCount: 0,
    filterModal: false,
    selectedInboxData: {} as any,
    conversationFinalFilters: conversationFilterData || {} as any,
    enableConversationSelection: false,
    indeterminateSelectionState: false,
    checkedSelectList: false,
    checkedSelectAllConversation: false,
    isDeselectedSomeConversations: false,
    conversationListLength: 0,
    selectedConversationListLength: 0,
    deSelectedConversationListLength: 0,
    selectedEmailSubtabCode: EMAIL_CONVERSATION_SUBTAB_CODES.INBOX,
    isCallDrawerVisible: false,
  });

  useDeepEffect(() => {
    getInboxNumberFromStorage();
  }, [selectedInboxTypeCode, conversationFilterData, allInboxList, enableConversationSelection, checkedSelectAllConversation, isDeselectedSomeConversations]);

  useEffect(() => {
    setConversationState((prev) => {
      return {
        ...prev,
        searchString: props.sideCarSearchString,
      };
    });
  }, [props.sideCarSearchString]);

  const getInboxNumberFromStorage = async () => {
    let inboxLocalStorageRes = {} as any;
    if (isSMSMissedAllCallsChannelInboxType(selectedInboxTypeCode)) {
      inboxLocalStorageRes = await LocalStorage.getItem(
        CONVERSATION_STORAGE_KEY.SMS_INBOX_LOCAL_KEY
      );
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      inboxLocalStorageRes = await LocalStorage.getItem(
        CONVERSATION_STORAGE_KEY.EMAIL_INBOX_LOCAL_KEY
      );
    }

    if (inboxLocalStorageRes?.[userUuid]) {
      if (inboxLocalStorageRes?.[userUuid]?.selectedInboxAllData?.id) {
        updateConversationFilter(inboxLocalStorageRes?.[userUuid]?.selectedInboxAllData);
      } else if (ifNumberExists(inboxLocalStorageRes?.[userUuid]?.selectedInboxAllData?.channelTwilioSms?.phoneNumber)) {
        const selectedInboxAllData = inboxFormattedData.filter((item) => {
          return item?.channelTwilioSms?.phoneNumber === inboxLocalStorageRes?.[userUuid]?.selectedInboxAllData?.channelTwilioSms?.phoneNumber
        })
        updateConversationFilter(selectedInboxAllData?.[0] || []);
      } else {
        setDefaultState();
      }
    } else {
      setDefaultState();
    }
  }

  const setDefaultState = () => {
    setConversationState((prev) => {
      return {
        ...prev,
        popover1: false,
        popover2: false,
        displayCreateGroupPopup: false,
        createGroupPopupOpenCount: 0,
        selectedHeaderBtn: HEADER_BUTTON_LIST[0],
        selectedTabCode: CONVERSATION_TAB_CODES.RECENT,
        isDrawerVisible: false,
        groupCreatedCount: 0,
        searchString: props?.searchString,
        autoFocusCount: 1,
        resetSearchTextCount: 0,
        selectedInboxData: (conversationState.selectedInboxData?.selectedInboxTypeCode === selectedInboxTypeCode) ? conversationState?.selectedInboxData : {} as any,
        conversationFinalFilters: conversationFilterData,
        enableConversationSelection: enableConversationSelection || false,
        checkedSelectList: enableConversationSelection ? prev.checkedSelectList : false,
        indeterminateSelectionState: (enableConversationSelection && !checkedSelectAllConversation) ? prev.indeterminateSelectionState : false,
        checkedSelectAllConversation: checkedSelectAllConversation || false,
        isDeselectedSomeConversations: isDeselectedSomeConversations || false,
      }
    })
  }

  const ifNumberExists = (number: string) => {
    currentUserInboxes?.some((item) => {
      if (item?.channelTwilioSms?.phoneNumber === number) {
        return true;
      }
    });
    return false;
  }

  const updateConversationFilter = (selectedInboxData: any) => {
    let conversationFinalFilters: any = {};
    if (isCheckSameChannelInboxNumber(selectedInboxData, selectedInboxTypeCode)) {
      conversationFinalFilters = {
        ...conversationFilterData,
        inboxId: {
          _eq: selectedInboxData?.id,
        }
      }
    } else if (selectedInboxData?.channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      conversationFinalFilters = {
        ...conversationFilterData,
        inboxId: {
          _eq: selectedInboxData?.id,
        }
      }
      if (onConversationActionPerformed) {
        onConversationActionPerformed(COMMON_ACTION_CODES.TOGGLE_SMS_INBOX, selectedInboxData?.id)
      }
    } else {
      conversationFinalFilters = {
        ...conversationFilterData,
      }
    }
    if (selectedInboxData?.id > 0 && onConversationActionPerformed) {
      onConversationActionPerformed(COMMON_ACTION_CODES.TOGGLE_SMS_INBOX, selectedInboxData?.id)
    }

    setConversationState((prev) => {
      return {
        ...prev,
        conversationFinalFilters: conversationFinalFilters,
        selectedInboxData: selectedInboxData,
        searchString: props?.searchString,
        enableConversationSelection: enableConversationSelection || false,
        checkedSelectList: enableConversationSelection ? prev.checkedSelectList : false,
        indeterminateSelectionState: (enableConversationSelection && !checkedSelectAllConversation) ? prev.indeterminateSelectionState : false,
        checkedSelectAllConversation: checkedSelectAllConversation || false,
        isDeselectedSomeConversations: isDeselectedSomeConversations || false,
      };
    });
  }

  const onConversationHeaderActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      // case for person type changed
      case COMMON_ACTION_CODES.FILTER_CHANGED:
      case COMMON_ACTION_CODES.STATUS_CHANGED:
      case COMMON_ACTION_CODES.MESSAGE_TYPE_CHANGE:
      case COMMON_ACTION_CODES.DATE_RANGED_CHANGED: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(actionCode, actionData);
        }
        break;
      }
      case COMMON_ACTION_CODES.INBOX_TYPE_CHANGE: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(actionCode, actionData);
        }
        break;
      }
      case COMMON_ACTION_CODES.ALL: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(actionCode, actionData);
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.CONVERSATION_HEADER_BTN_CLICKED: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_HEADER_BTN_CLICKED,
            actionData
          );
        }
        break;
      }

    }
  };

  const isTypeInternal = (type: string) => {
    if (type === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
      return true;
    } else return false;
  };

  const onConversationListActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ITEM_CLICKED: {
        let tempActionCode = COMMON_ACTION_CODES.NO_DATA_FOUND;
        if (actionData?.selectedConversation?.id) {
          tempActionCode = COMMON_ACTION_CODES.ITEM_CLICKED;
        }
        if (tempActionCode === COMMON_ACTION_CODES.NO_DATA_FOUND) {
          actionData = {};
        }
        if (onConversationActionPerformed) {
          onConversationActionPerformed(
            tempActionCode,
            actionData.selectedConversation
          );
        }
        break;
      }
      case COMMON_ACTION_CODES.NO_DATA_FOUND: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(
            COMMON_ACTION_CODES.NO_DATA_FOUND,
            {} as any
          );
        }
        break;
      }
      case GROUP_ACTION_CODES.GROUP_CREATE:
        setConversationState((prev) => {
          return {
            ...prev,
            selectedTabCode: isTypeInternal(actionData)
              ? CONVERSATION_TAB_CODES.INTERNAL
              : CONVERSATION_TAB_CODES.RECENT,
            isDrawerVisible: true,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.GROUP_CONVERSATION_DATA_LOADING: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(
            CONVERSATION_ACTION_CODES.GROUP_CONVERSATION_DATA_LOADING,
            actionData
          );
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION: {
        if (
          conversationState.enableConversationSelection &&
          actionData &&
          onConversationActionPerformed
        ) {
          onConversationActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION,
            actionData
          );
          setConversationState((prev) => {
            return {
              ...prev,
              selectedConversationListLength: actionData?.length,
              checkedSelectList: prev.checkedSelectList ? !(actionData?.length < prev.conversationListLength) : prev.checkedSelectList,
              indeterminateSelectionState: prev.checkedSelectList ? (actionData?.length < prev.conversationListLength) : prev.indeterminateSelectionState
            }
          })
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.CONVERSATION_DESELECTION: {
        if (conversationState.checkedSelectAllConversation && actionData && onConversationActionPerformed) {
          onConversationActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_DESELECTION,
            actionData,
          )
        }
        if (actionData) {
          setConversationState((prev) => {
            return {
              ...prev,
              deSelectedConversationListLength: actionData?.length
            }
          })
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.CONVERSATION_LIST_LENGTH: {
        if (isNumeric(actionData)) {
          setConversationState((prev) => {
            return {
              ...prev,
              conversationListLength: actionData,
              checkedSelectList: prev.checkedSelectList ? !(prev.selectedConversationListLength < actionData) : prev.checkedSelectList,
              indeterminateSelectionState: prev.checkedSelectList ? (prev.selectedConversationListLength < actionData) : prev.indeterminateSelectionState
            }
          })
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.MESSAGE_READ: {
        if (onConversationActionPerformed) {
          onConversationActionPerformed(
            CONVERSATION_ACTION_CODES.MESSAGE_READ,
            actionData
          );
        }
        break;
      }
    }
  };

  const handleVisibleChange1 = (e: any) => {
    setConversationState((prev) => {
      return {
        ...prev,
        popover1: e,
      };
    });
  };
  const handleVisibleChange2 = (e: any) => {
    setConversationState((prev) => {
      return {
        ...prev,
        popover2: e,
      };
    });
  };

  const onCreateChannelActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationState((prev) => {
          return {
            ...prev,
            isDrawerVisible: false,
          };
        });
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        setConversationState((prev) => {
          return {
            ...prev,
            isDrawerVisible: false,
            groupCreatedCount: prev.groupCreatedCount + 1,
          };
        });
        break;
      default:
        break;
    }
  };
  const getFlextForSearchBar = () => {
    let searchBarFlex = 1;

    if (Platform.OS === 'web' && isWebChannelInboxType(selectedInboxTypeCode)) {
      if (
        conversationState.selectedTabCode === CONVERSATION_TAB_CODES.PROSPECTS
      ) {
        searchBarFlex = 0.8;
      }
    }
    if (!isWebChannelInboxType(selectedInboxTypeCode)) {
      searchBarFlex = 1;
    }
    return searchBarFlex;
  };

  const onInboxChange = (inboxOptionData: any) => {
    const selectedInboxAllData = inboxFormattedData.filter((item) => {
      return item.value === inboxOptionData
    })
    const allInboxData = {
      ...selectedInboxAllData[0],
      selectedInboxTypeCode
    }

    let conversationFinalFilters: any = {};
    // updateConversationFilter(allInboxData)
    const storageValue: any = {};
    storageValue[userUuid] = {
      selectedInboxAllData: allInboxData,
    };
    if ((selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) || (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS)) {
      const allInboxData = {
        ...selectedInboxAllData[0],
        selectedInboxTypeCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
      }
      const storageValue: any = {};
      storageValue[userUuid] = {
        selectedInboxAllData: allInboxData,
      };
      LocalStorage.setItem(CONVERSATION_STORAGE_KEY.SMS_INBOX_LOCAL_KEY, storageValue);
      const storageNumber: any = {};
      storageNumber[userUuid] = {
        phoneNumber: allInboxData?.channelTwilioSms?.phoneNumber,
      };
      LocalStorage.setItem('callLogsFilterToNumber', storageNumber);
    } else if (CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      LocalStorage.setItem(CONVERSATION_STORAGE_KEY.EMAIL_INBOX_LOCAL_KEY, storageValue);
    }

    //update conversation filter in query
    conversationFinalFilters = {
      ...conversationFilterData,
      inboxId: {
        _eq: allInboxData?.id,
      }
    }
    if (onConversationActionPerformed) {
      onConversationActionPerformed(COMMON_ACTION_CODES.TOGGLE_SMS_INBOX, allInboxData?.id)
    }
    setConversationState((prev) => {
      return {
        ...prev,
        conversationFinalFilters: conversationFinalFilters,
        selectedInboxData: allInboxData,
        searchString: props?.searchString,
      };
    });
  };

  const getInboxName = () => {
    return (
      <Select
        allowClear
        showSearch={true}
        placeholder="Select Inbox"
        value={currentUserInboxes?.length ? (
          selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL
            ? conversationState?.selectedInboxData?.value || undefined
            :
            conversationState?.selectedInboxData?.value || undefined
        ) : undefined}
        filterOption={(input: string, option: any) => {
          return (option?.searchString || '')?.toLowerCase?.()?.includes(input?.toLowerCase?.());
        }}
        onChange={(inboxOptionData: any) => {
          onInboxChange(inboxOptionData);
        }}
        className={'chat-inbox-select'}
        data-testid={TestIdentifiers.selectDropdown}
      >
        {currentUserInboxes?.length && currentUserInboxes.map((singleVirtualNum) => {
          return (
            <Option
              key={singleVirtualNum?.key}
              searchString={`${singleVirtualNum?.label} ${singleVirtualNum?.name || ''
                }`}
              value={singleVirtualNum?.value}
            >
              <HStack justifyContent={'flex-start'}>
                {selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL ? (
                  <View height={'24px'} width={'24px'} style={{padding: 2}}>
                    <EmailActionSvg customStrokeColor={Colors.Custom.Gray400} />
                  </View>
                ) : (
                  <USFlagSVG />
                )}
                <View style={{marginLeft: 5}}>
                  <Text
                    fontWeight={400}
                    fontSize={'14px'}
                    color={'black'}
                    noOfLines={1}
                    flex={1}
                    width={inboxWidth - 100}
                  >
                    {(selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                      ? singleVirtualNum?.channelEmail?.email || ''
                      : singleVirtualNum?.channelTwilioSms?.phoneNumber || '') +
                      `${singleVirtualNum?.name
                        ? ' (' + capitalizeText(singleVirtualNum?.name) + ')'
                        : ''
                      }`}
                  </Text>
                </View>
              </HStack>
            </Option>
          );
        })}
      </Select>
    );
  };

  const handleCheckbox = () => {
    if (!conversationState.checkedSelectList && !conversationState.enableConversationSelection && !conversationState.indeterminateSelectionState) {
      setConversationState((prev) => {
        return {
          ...prev,
          enableConversationSelection: true,
          indeterminateSelectionState: true,
        };
      });
    } else if (conversationState.checkedSelectAllConversation) {
      if (conversationState.deSelectedConversationListLength) {
        setConversationState((prev) => {
          return {
            ...prev,
            isDeselectedSomeConversations: true,
          }
        })
      } else {
        if (onConversationActionPerformed) {
            onConversationActionPerformed(
              CONVERSATION_ACTION_CODES.DESELECT_ALL_CONVERSATION,
            );
        }
        setConversationState((prev) => {
          return {
            ...prev,
            checkedSelectAllConversation: false,
            checkedSelectList: false,
            indeterminateSelectionState: false,
            enableConversationSelection: false,
          }
        })
      }
    } else if (!conversationState.checkedSelectList) {
      setConversationState((prev) => {
        return {
          ...prev,
          indeterminateSelectionState: false,
          checkedSelectList: true
        };
      });
    } else {
      setConversationState((prev) => {
        return {
          ...prev,
          enableConversationSelection: false,
          checkedSelectList: false,
          indeterminateSelectionState: false,
        };
      });
      if (onConversationActionPerformed) {
        onConversationActionPerformed(
          CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION,
          []
        );
      }
    }
  }

  const getCheckboxView = () => {
    return (
      <View
        flex={0.15}
        justifyContent={'center'}
        justifyItems={'center'}
        alignItems={'center'}
        alignContent={'center'}
        {...testID(TestIdentifiers.checkbox)}
      >
        <Checkbox
          className="copy-checkbox"
          indeterminate={conversationState.checkedSelectAllConversation ? (conversationState?.deSelectedConversationListLength > 0) : conversationState.indeterminateSelectionState}
          checked={conversationState.checkedSelectAllConversation ? !(conversationState?.deSelectedConversationListLength > 0) : conversationState.checkedSelectList}
          onChange={() => {
            handleCheckbox();
          }}
        />
      </View>
    )
  }

  const getSelectedInboxUserIds = () => {
    const userIdsList =
      conversationState.selectedInboxData?.inboxMembers?.map((user: any) =>
        user?.userId?.toString()
      ) || [];

    return userIdsList;
  };

  const getRightSideContainer = useCallback((): JSX.Element => {
    return (
      <>
        <ConversationListV2
          key={props.selectedInboxTypeCode}
          selectedInboxTypeCode={props.selectedInboxTypeCode}
          conversationFilter={conversationState.conversationFinalFilters}
          selectedTabCode={selectedTabCode || ''}
          readConversationData={
            readConversationData || ({} as IConversationData)
          }
          onConversationActionPerformed={onConversationListActionPerformed}
          searchString={conversationState?.searchString}
          groupCreatedCount={conversationState.groupCreatedCount}
          onActionPerformed={onActionPerformed}
          allInboxList={allInboxList}
          isConversationSelected={props.isConversationSelected}
          enableConversationSelection={conversationState.enableConversationSelection}
          checkedSelectAll={conversationState.checkedSelectAllConversation}
          checkedSelectList={conversationState.checkedSelectList}
          isDeselectedSomeConversations={conversationState.isDeselectedSomeConversations}
          setUserPreferenceChangeLoading={props?.setUserPreferenceChangeLoading}
          selectedEmailSubtabCode={conversationState.selectedEmailSubtabCode}
          selectedInboxUserIds={getSelectedInboxUserIds()}
          emailInboxUserPreference={props?.emailInboxUserPreference}
          otherContactTypeId={props?.otherContactTypeId}
          isEmailUserPreferenceEnabled={props?.isEmailUserPreferenceEnabled}
        />
      </>
    );
  }, [conversationState, readConversationData]);


  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent('CLOUD_CALL', {
        contactData: contactData,
        fromNumber: fromNumber,
      });
    }
  };

  return (
    <View
      ref={inboxRef}
      background={Colors.Custom.ConversationBgColor}
      flex={1}
    >
      {isSideCarContext ? (
        <></>
      ) : (
        <>
          {canShowChooseInbox(selectedInboxTypeCode) && (
            <View mt={2} pl={2} pr={2} pt={2}>
              {getInboxName()}
            </View>
          )}
          <View
            pt={Platform.OS !== 'web' ? 5 : 0}
            pb={Platform.OS !== 'web' ? 15 : 2}
            style={styles.conversationSearchBar}
            backgroundColor={'#ffffff'}
          >
            <HStack flex={1} width={'100%'} alignItems={'center'} mt={3}>
              <HStack flex={getFlextForSearchBar()} width={'100%'}>
                {!isAllCallsChannelInboxType(selectedInboxTypeCode) ? (
                  getCheckboxView()
                ) : (
                  <></>
                )}
                <View
                  flex={
                    selectedInboxTypeCode ===
                      CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER ||
                    isAllCallsChannelInboxType(selectedInboxTypeCode)
                      ? isEFaxChannel
                        ? 0.8
                        : 0.9
                      : isEFaxChannel
                      ? 0.7
                      : 0.8
                  }
                  height={'37px'}
                >
                  <ConversationSearchBar
                    searchText={conversationState?.searchString || ''}
                    conversationTabCode={selectedInboxTypeCode}
                    autoFocusCount={conversationState.autoFocusCount}
                    onChange={(value: string) => {
                      if (
                        setSearchText &&
                        (value.length > 2 || value.length === 0)
                      ) {
                        setSearchText(value || '');
                        setConversationState((prev) => {
                          return {
                            ...prev,
                            searchString: value,
                          };
                        });
                      }
                      setConversationState((prev) => {
                        return {
                          ...prev,
                          autoFocusCount: conversationState.autoFocusCount + 1,
                        };
                      });
                    }}
                    borderWidth={1}
                    placeholderText="Search"
                    searchBarInputStyles={{
                      marginLeft:
                        selectedInboxTypeCode ===
                          CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER ||
                        isAllCallsChannelInboxType(selectedInboxTypeCode)
                          ? 4
                          : 1,
                      marginRight:
                        conversationState.selectedTabCode ===
                        CONVERSATION_TAB_CODES.PROSPECTS
                          ? 0
                          : 0,
                      backgroundColor: '#ffffff',
                      hideIcon: isSideCarContext ? 'true' : 'false',
                    }}
                    isDialPadVisible
                    onDialPadClick={() => {
                      setConversationState((prev) => {
                        return {
                          ...prev,
                          isCallDrawerVisible: true,
                        };
                      });
                    }}
                  />
                </View>
                {isEFaxChannel && (
                  <View
                    flex={0.1}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Pressable
                      onPress={() => {
                        onActionPerformed(CHANNEL_TYPE_CODE.CHANNEL_E_FAX);
                      }}
                      {...testID('CreateEFaxChannel')}
                    >
                      <PlusIcon
                        defaultColor={Colors.Custom.Gray400}
                        height={25}
                        width={25}
                      />
                    </Pressable>
                  </View>
                )}
                <View
                  justifyContent={'center'}
                  justifyItems={'center'}
                  alignItems={'center'}
                  alignContent={'center'}
                  flex={0.1}
                  ml={1}
                  mr={4}
                >
                  <Pressable
                    onPress={() => {
                      filterModal && filterModal(true);
                    }}
                    style={[
                      {
                        padding: 4,
                        justifyContent: 'center',
                        marginHorizontal: 2,
                      },
                    ]}
                    {...testID(TestIdentifiers.filterButton)}
                  >
                    <Tooltip
                      title={conversationFilterProps?.count?.join(' \n')}
                      arrowPointAtCenter={false}
                      placement={'left'}
                      overlayStyle={{whiteSpace: 'pre-line'}}
                      style={{textAlign:"center"}}
                    >
                      {conversationFilterProps?.count?.length ? (
                        <View
                          style={{
                            position: 'absolute',
                            top: -7.5,
                            right: -7.5,
                            backgroundColor: Colors.danger[500],
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Text style={{
                            color: "#ffffff",
                            lineHeight:12,
                            fontSize: 10,
                          }} fontWeight={500}>
                            {conversationFilterProps?.count?.length}
                          </Text>
                        </View>
                      ) : null}
                      <FilterNewIconSvg isEnabled={true} />
                    </Tooltip>
                  </Pressable>
                </View>
              </HStack>
              {Platform.OS === 'web' && (
                <>
                  {isWebChannelInboxType(selectedInboxTypeCode) &&
                    conversationState.selectedTabCode ===
                      CONVERSATION_TAB_CODES.PROSPECTS && (
                      <View
                        flex={
                          conversationState.selectedTabCode ===
                          CONVERSATION_TAB_CODES.RECENT
                            ? 0.1
                            : 0.2
                        }
                        flexDirection={'row'}
                        style={{
                          marginTop: 16,

                          marginLeft: 3,
                          marginRight: 4,
                        }}
                      >
                        <Popover
                          onVisibleChange={handleVisibleChange1}
                          trigger="click"
                          visible={conversationState.popover1}
                          content={
                            <>
                              <OneViewFilter
                                onActionPerformed={
                                  onConversationHeaderActionPerformed
                                }
                                selectedMessageTabCode={
                                  conversationState.selectedTabCode
                                }
                              />
                            </>
                          }
                        >
                          <Pressable
                            onPress={() => {
                              setConversationState((prev) => {
                                return {
                                  ...prev,
                                  popover1: true,
                                };
                              });
                            }}
                            style={[
                              styles.flexStart,
                              {
                                width: 32,
                                height: 32,
                                justifyContent: 'center',
                                marginHorizontal: 2,
                              },
                            ]}
                            {...testID(TestIdentifiers.filterButton)}
                          >
                            <FilterNewIconSvg isEnabled={true} />
                          </Pressable>
                        </Popover>

                        {conversationState.selectedTabCode ===
                          CONVERSATION_TAB_CODES.PROSPECTS && (
                          <>
                            <Popover
                              onVisibleChange={handleVisibleChange2}
                              trigger="click"
                              visible={conversationState.popover2}
                              content={
                                <FilterByTag
                                  labelType={'CONVERSATION'}
                                  returnFilterItems={(item: any) => {
                                    if (onConversationFilterPerformed) {
                                      onConversationFilterPerformed(
                                        '',
                                        FILTER_COMPONENT_CODE.TAG,
                                        item
                                      );
                                    }
                                  }}
                                />
                              }
                            >
                              <Pressable
                                onPress={() => {
                                  setConversationState((prev) => {
                                    return {
                                      ...prev,
                                      popover2: true,
                                    };
                                  });
                                }}
                                style={[
                                  {paddingHorizontal: 8},
                                  styles.flexStart,
                                  {
                                    width: 32,
                                    height: 32,

                                    borderRadius: 100,
                                    justifyContent: 'center',
                                    marginHorizontal: 2,
                                  },
                                ]}
                              >
                                <TagsSvg
                                  isEnabled={true}
                                  customStrokeColor={Colors.secondary['800']}
                                  customBgColor={Colors.secondary['100']}
                                />
                              </Pressable>
                            </Popover>
                          </>
                        )}
                      </View>
                    )}
                </>
              )}
            </HStack>
            {isEmailChannelInboxType(selectedInboxTypeCode) && (
              <>
                <View
                  paddingTop={2}
                  borderBottomWidth={1}
                  borderBottomColor={Colors.Custom.Gray100}
                />
                <HStack backgroundColor={Colors.Custom.Gray50} padding={1}>
                  {EMAIL_CONVERSATION_LIST_SUBTAB.map(
                    (tabItem: IDisplayEmailSubtabList, index: number) => {
                      return (
                        <Pressable
                          onPress={() => {
                            onConversationActionPerformed?.(
                              CONVERSATION_ACTION_CODES.DESELECT_ALL_CONVERSATION
                            );
                            setConversationState((prev) => {
                              return {
                                ...prev,
                                selectedEmailSubtabCode: tabItem.code,
                                checkedSelectAllConversation: false,
                                checkedSelectList: false,
                                indeterminateSelectionState: false,
                                enableConversationSelection: false,
                              };
                            });
                          }}
                          style={styles.emailSubtabStyles}
                          key={`${tabItem.key}_${index}`}
                          flex={0.5}
                          backgroundColor={
                            conversationState.selectedEmailSubtabCode ===
                            tabItem.code
                              ? Colors.Custom.White
                              : Colors.Custom.Gray50
                          }
                          borderWidth={
                            conversationState.selectedEmailSubtabCode ===
                            tabItem.code
                              ? 0.5
                              : 0
                          }
                        >
                          <Text
                            color={
                              conversationState.selectedEmailSubtabCode ===
                              tabItem.code
                                ? Colors.Custom.Gray700
                                : Colors.Custom.Gray400
                            }
                            fontSize={14}
                            fontWeight={400}
                          >
                            {tabItem.title}
                          </Text>
                        </Pressable>
                      );
                    }
                  )}
                </HStack>
              </>
            )}
          </View>
        </>
      )}

      <View pt={Platform.OS !== 'web' ? 3 : 0} flex={1} width={inboxWidth}>
        <View flex={1}>{getRightSideContainer()}</View>

        <CreateChannelView
          isDrawerVisible={conversationState.isDrawerVisible}
          onCreateChannelActionPerformed={onCreateChannelActionPerformed}
          selectedTabCode={conversationState.selectedTabCode}
        />
      </View>

      {conversationState.isCallDrawerVisible && (
        <SelectPatientCallDrawer
          isDrawerVisible={conversationState.isCallDrawerVisible}
          selectedRecord={null}
          handleCloudCall={handleCloudCall}
          onCreateCallConversationActionPerformed={() => {
            setConversationState((prev) => {
              return {
                ...prev,
                isCallDrawerVisible: false,
              };
            });
          }}
        />
      )}
    </View>
  );
};

function useDeepEffect(fn: any, deps: any) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj: any, index: any) =>
      isEqual(obj, deps[index])
    );

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
  }, deps);
}

export default memo(ConversationContainer);
