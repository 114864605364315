import { useLazyQuery, useQuery } from '@apollo/client';
import { Skeleton, Table, Tabs } from 'antd';
import {
    Box,
    FlatList,
    HStack,
    Icon,
    Pressable,
    View,
    VStack,
    useToast,
    ScrollView,
} from 'native-base';
import { memo, useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { FormsQueries, UserQueries } from '../../../../../services';
import { Colors } from '../../../../../styles';
import {
    getAccountUUID,
} from '../../../../../utils/commonUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITableTopBarProps, TableTopBar } from '../../../../common/TableTopBar';
import MemberInfoListItem from '../../../../common/MemberInfoListItem/MemberInfoListItem';
import { SearchBar } from '../../../../common/SearchBar';
import { debounce } from 'lodash';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { FORM_STATUS_CODE, MLOV_CATEGORY, USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { getPendingFormResponsesTableColumns } from '../../../../PersonOmniView/MiddleContainer/FormResponseTableView/Helper';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { TableWrapper } from '../../../../common/TableWrapper';
import { getSearchQuery } from '../../../../common/PatientSearch/PatientSearchUtils';
import { NoDataFound } from '../../../../common/NoDataFound';
import { BUTTON_TYPE } from '../../../../../constants';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { FormReportScreen } from '../../Analytics';

export interface IFormScore {
    operation: string;
    score: number;
    state: string;
    name: string;
    groupId?: string;
}


const PendingForms = (props: {
    selectedForm?: any;
    onTabClick?: (type: string) => void;
    tabArray?: {
        type: FormReportScreen;
        label: string;
    }[],
    setResponseCount?:(count:number, type:FormReportScreen.RESPONDED|FormReportScreen.PENDING)=> void;

}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const mlovData = useContext(CommonDataContext);
    const formStatusMlovList = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_STATUS,
    );
    const pendingStatusId = getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.PENDING);
    const draftStatusId = getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.DRAFT);

    if (!location?.state && !props?.selectedForm?.id) {
        navigate('/admin/contentManagement/forms', { replace: true });
    }

    const toast = useToast();
    const [loaders, setLoaders] = useState({
        componentLoading: false,
        formDataTableLoading: false,
    })
    const [paginationState, setpaginationState] = useState({
        isLoading: false,
        isNoDataFound: false,
        page: 1,
        pageSize: 8,
        total: 0
    });
    const [selectedContact, setSelectedContact] = useState<any>()
    const [searchedContacts, setSearchedContacts] = useState<any[]>()
    const [contactUuidsOfPendingForms, setContactUuidsOfPendingForms] = useState<any[]>()
    const [contactUuidsOfPendingFormsForSearchedContacts, setContactUuidsOfPendingFormsForSearchedContacts] = useState<any[]>()
    const [formDataForSelectedContact, setFormDataForSelectedContact] = useState()
    const [contactData, setContactData] = useState<any[]>()
    const [accountUsers, setAccountUsers] = useState<any>()

    const getLocationStateVars = () => {
        const state = location?.state as any;
        return {
            selectedFormId: state?.selectedFormId || props?.selectedForm?.id || '',
            selectedForm: state?.selectedForm || props?.selectedForm,

        };
    };

    const { selectedForm, selectedFormId } = getLocationStateVars();

    useEffect(() => {
        setLoaders((prev) => {
            return {
                ...prev,
                componentLoading: true,
            }
        })
        getContactUuids({
            variables: {
                formId: selectedFormId,
                statusIds: [pendingStatusId, draftStatusId],
                limit: paginationState.pageSize,
                offset: 0
            }
        }).finally(() => {
            setLoaders((prev) => {
                return {
                    ...prev,
                    componentLoading: false,
                }
            })
        })
    }, []);

    useQuery(UserQueries.GET_ACCOUNT_USERS_NAME_AND_IS_ACTIVE, {
        variables: {
            accountId: getAccountUUID(),
            roleCode: USER_ROLE_CODES.EMPLOYER,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (response: any) => {
            setAccountUsers(response.users)
        },
        onError: (error: any) => {
            showToast(
                toast,
                'Couldnt fetch account users',
                ToastType.error,
                2000
            );
        }
    })


    const [getContactUuids] = useLazyQuery(FormsQueries.GET_PENDING_FORMS_CONTACT_UUIDS, {
        fetchPolicy: 'no-cache',
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onCompleted: async (response: any) => {
            const uniqueContactIds = [...new Set(
                response?.formLogs.map((item: any) => item?.contactId)
            ) as any].filter((id: string) => id !== undefined && id !== null);
            props.setResponseCount?.(response?.aggregateFormLogs?.aggregate?.count,FormReportScreen.PENDING)
            setContactUuidsOfPendingForms(uniqueContactIds)
            setpaginationState((prev) => {
                return {
                    ...prev,
                    total: uniqueContactIds?.length,
                }
            });
            const contactData = await getContactDetails({ variables: { where: { "uuid": { "_in": uniqueContactIds.slice(0, paginationState.pageSize) } } } })
            if (contactData?.data?.contacts) {
                setContactData(contactData?.data?.contacts)
            }
            setSelectedContact(contactData?.data?.contacts[0])
            await getPendingForms({
                variables: {
                    formId: selectedFormId,
                    statusIds: [pendingStatusId, draftStatusId],
                    contactId: contactData?.data?.contacts[0]?.uuid
                },
            })
        },
        onError: (error: any) => {
            showToast(
                toast,
                'Couldnt fetch pending forms',
                ToastType.error,
                2000
            );
        }
    })

    const [getPendingForms] = useLazyQuery(FormsQueries.GET_CONTACT_PENDING_FORM_LIST, {
        fetchPolicy: 'no-cache',
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onCompleted: async (data: any) => {
            if (data && data?.formLogs?.length > 0) {
                const formLogCopy = data?.formLogs
                formLogCopy?.forEach((formLog: any) => {
                    if (formLog?.createdBy) {
                        formLog.sentBy = accountUsers?.find((user: any) => user.uuid === formLog?.createdBy)?.name
                    }
                    else formLog.sentBy = '-'
                })
                setFormDataForSelectedContact(data?.formLogs)
            }
        },
    });

    const [getContactDetails] = useLazyQuery(
        FormsQueries.GET_DETAILS_FOR_CONTACTS,
        {
            fetchPolicy: 'no-cache',
            onCompleted: async (data: any) => {
                if (data?.contacts) {
                    setContactData(data.contacts)
                }
                setSelectedContact(data.contacts[0])
            },
            onError: (error: any) => {

            },
        }
    );

    const { height } = Dimensions.get('window');
    const finalListHeight = height - 166;

    const onCompletion = () => {
        navigate('/admin/contentManagement/forms');
    };

    const getFormResponseTopBarButtons = (): ITableTopBarProps => {
        return {
            title:
                `${selectedForm?.pendingCount} forms pending for ${selectedForm?.name}`,
            buttonList: props?.tabArray?.length ? [] : [
                {
                    btnText: 'cancel',
                    size: 'sm',
                    colorScheme: 'secondary',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    backgroundColor: Colors.danger[100],
                    variant: BUTTON_TYPE.SECONDARY,
                    btnClick: () => {
                        onCompletion();
                    },
                },

            ],
            hideSearchBar: true,
        };
    };

    const handleSearchTextChange = async (searchText: string) => {
        setLoaders((prev) => {
            return {
                ...prev,
                formDataTableLoading: true
            }
        })
        setpaginationState((prev) => {
            return {
                ...prev,
                isLoading: true
            }
        })
        setFormDataForSelectedContact(undefined)
        if (searchText) {
            const where = getSearchQuery({ searchString: searchText, isActive: true });
            await getContactDetails(
                {
                    variables: where
                }).then(async (contactData) => {
                    if (contactData?.data?.contacts) {
                        const filteredContacts = contactData?.data?.contacts.filter((contact: any) => contactUuidsOfPendingForms?.includes(contact.uuid));
                        setSearchedContacts(filteredContacts)
                        setContactData(filteredContacts.slice(0, paginationState.pageSize))
                        setSelectedContact(filteredContacts[0])
                        setContactUuidsOfPendingFormsForSearchedContacts(filteredContacts)
                        await getPendingForms({
                            variables: {
                                formId: selectedFormId,
                                statusIds: [pendingStatusId, draftStatusId],
                                contactId: filteredContacts[0]?.uuid
                            },
                        })
                        setLoaders((prev) => {
                            return {
                                ...prev,
                                formDataTableLoading: false
                            }
                        })
                        setpaginationState((prev) => {
                            return {
                                ...prev,
                                total: filteredContacts?.length,
                                page: 1,
                                offset: 0,
                                isLoading: false
                            }
                        })
                    }
                })
        } else {
            setLoaders((prev) => {
                return {
                    ...prev,
                    formDataTableLoading: false
                }
            })
            const contactData = await getContactDetails({ variables: { where: { "uuid": { "_in": contactUuidsOfPendingForms?.slice(0, paginationState.pageSize) } } } })
            if (contactData?.data?.contacts) {
                setContactData(contactData?.data?.contacts)
            }
            setSearchedContacts(undefined)
            setContactUuidsOfPendingFormsForSearchedContacts(undefined)
            setSelectedContact(contactData?.data?.contacts[0])
            await getPendingForms({
                variables: {
                    formId: selectedFormId,
                    statusIds: [pendingStatusId, draftStatusId],
                    contactId: contactData?.data?.contacts[0]?.uuid
                },
            })
            setpaginationState((prev) => {
                return {
                    ...prev,
                    total: contactUuidsOfPendingForms?.length || 0,
                    page: 1,
                    offset: 0,
                    isLoading: false
                }
            })
        }
    };
    return (
        <>
            <View style={{ height: finalListHeight }}>
                {!loaders?.componentLoading ?
                    <Box
                        borderColor={Colors.Custom.BorderColor}
                        borderWidth={1}
                        overflow="hidden"
                        rounded="lg"
                        marginBottom={2}
                        height={height - 160}
                    >
                        {!props?.tabArray?.length &&
                        <View background={Colors.Custom.BackgroundColor} marginY={2} marginBottom={4}>
                            <TableTopBar {...getFormResponseTopBarButtons()} />
                        </View>
                        }
                        <HStack>
                            <View
                                width="300"
                                background={Colors.Custom.BackgroundColor}
                                marginTop={4}
                            >
                                <View paddingLeft={2} paddingBottom={0} paddingRight={1.5}
                                    position='sticky'
                                    top={0}
                                >
                                    <SearchBar
                                        onChange={debounce(async (value: string) => {
                                            await handleSearchTextChange(value || '')
                                        }, 300)}
                                        isNotDebounce={true}
                                    />
                                </View>
                                {props?.tabArray?.length ? <Tabs
                                    defaultActiveKey={FormReportScreen.PENDING}
                                    onTabClick={(activeKey) => {
                                        props?.onTabClick?.(activeKey);
                                    }}
                                >
                                    {props?.tabArray?.map((tabObj) => {
                                        return (
                                          <Tabs.TabPane tab={tabObj.label} key={tabObj.type}>
                                            {/* <Tabs.TabPane tab={tabObj.label+`(${contactData?.length})`} key={tabObj.type}> */}
                                            </Tabs.TabPane>)
                                    })}
                                </Tabs> : null}

                                <TableWrapper
                                    minHeight={300}
                                    wrapperStyle={{ overflow: 'scroll' }}
                                    pagination={{
                                        showSizeChanger: false,
                                        current: paginationState.page,
                                        pageSize: paginationState.pageSize,
                                        total: paginationState.total,
                                        onChange: async (currentPage, currentPageSize) => {
                                            if (contactUuidsOfPendingFormsForSearchedContacts) {
                                                const newContactData = searchedContacts?.slice(((currentPage - 1) * paginationState.pageSize), ((currentPage - 1) * paginationState.pageSize) + paginationState.pageSize)
                                                setContactData(newContactData)
                                                setSelectedContact(newContactData?.[0])
                                                setpaginationState((prev) => {
                                                    return {
                                                        ...prev,
                                                        page: currentPage,
                                                    }
                                                })
                                                await getPendingForms({
                                                    variables: {
                                                        formId: selectedFormId,
                                                        statusIds: [pendingStatusId, draftStatusId],
                                                        contactId: newContactData?.[0]?.uuid
                                                    },
                                                })
                                            }
                                            else {
                                                setpaginationState((prev) => {
                                                    return {
                                                        ...prev,
                                                        page: currentPage,
                                                        isLoading: true
                                                    }
                                                })
                                                setLoaders((prev) => {
                                                    return {
                                                        ...prev,
                                                        formDataTableLoading: true,
                                                    }
                                                })
                                                await getContactDetails(
                                                    {
                                                        variables:
                                                            { where: { "uuid": { "_in": contactUuidsOfPendingForms && contactUuidsOfPendingForms.slice(((currentPage - 1) * paginationState.pageSize), ((currentPage - 1) * paginationState.pageSize) + paginationState.pageSize) } } }
                                                    }).then(async (contactData) => {
                                                        if (contactData?.data?.contacts) {
                                                            setContactData(contactData?.data?.contacts)
                                                        }
                                                        setSelectedContact(contactData?.data?.contacts[0])
                                                        await getPendingForms({
                                                            variables: {
                                                                formId: selectedFormId,
                                                                statusIds: [pendingStatusId, draftStatusId],
                                                                contactId: contactData?.data?.contacts[0]?.uuid
                                                            },
                                                        })
                                                    }).finally(() => {
                                                        setpaginationState((prev) => {
                                                            return {
                                                                ...prev,
                                                                isLoading: false
                                                            }
                                                        })
                                                        setLoaders((prev) => {
                                                            return {
                                                                ...prev,
                                                                formDataTableLoading: false,
                                                            }
                                                        })
                                                    })
                                            }
                                        },
                                    }}>
                                    {paginationState.isLoading ?
                                        <Skeleton active />
                                        :
                                        contactData?.length === 0 ?
                                            <View marginY={4}>
                                              <NoDataFound displayString="No results" />
                                            </View>
                                            :
                                            <ScrollView height={500}>
                                                <FlatList
                                                    overflow={'scroll'}
                                                    data={contactData}
                                                    renderItem={({ item }) => {
                                                        const itemElem = (
                                                            <Pressable
                                                                _hover={{
                                                                    backgroundColor:
                                                                        (selectedContact && selectedContact?.uuid === item.uuid)
                                                                            ? Colors.primary[100]
                                                                            : Colors.primary[100] + '7a',
                                                                }}
                                                                onPress={async () => {
                                                                    setSelectedContact(item);
                                                                    setLoaders((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            formDataTableLoading: true,
                                                                        }
                                                                    })
                                                                    await getPendingForms({
                                                                        variables: {
                                                                            formId: selectedFormId,
                                                                            statusIds: [pendingStatusId, draftStatusId],
                                                                            contactId: item?.uuid
                                                                        },
                                                                    }).finally(() => {
                                                                        setLoaders((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                formDataTableLoading: false,
                                                                            }
                                                                        })
                                                                    })
                                                                }}
                                                                key={item?.uuid}
                                                            >
                                                                <HStack
                                                                    padding={2}
                                                                    background={(selectedContact && (selectedContact?.uuid === item.uuid)) ? Colors.primary[100] : ''}
                                                                    borderBottomWidth={1}
                                                                    borderColor="gray.200"
                                                                    alignItems="center"
                                                                    flex={1}
                                                                >
                                                                    <VStack flex={1}>
                                                                        <MemberInfoListItem
                                                                            contactData={item}
                                                                            isFormResponse={true}
                                                                            contactNameMaxWidth='75%'
                                                                            showDateOfBirth={true}
                                                                        />
                                                                    </VStack>

                                                                    <View>
                                                                        <Icon
                                                                            as={FeatherIcon}
                                                                            name="chevron-right"
                                                                            size="4"
                                                                            color={Colors.Custom.Gray500}
                                                                        />
                                                                    </View>
                                                                </HStack>
                                                            </Pressable>
                                                        );
                                                        return itemElem;
                                                    }}
                                                    keyExtractor={(item: any) => {
                                                        return 'FormResponsesList_' + item.uuid;
                                                    }}
                                                />
                                            </ScrollView>
                                    }
                                </TableWrapper>


                            </View>
                            <View width={0.2} backgroundColor="gray.200" />
                            <View flex={1}>
                                <>
                                    <View marginTop={"-2px"}>
                                        {
                                            loaders?.formDataTableLoading ?
                                                <Skeleton style={{margin: 16}} active />
                                                :
                                                <Box
                                                    borderColor={Colors.Custom.BorderColor}
                                                    borderWidth={1}
                                                    rounded="lg"
                                                    marginBottom={2}
                                                    height={height - 38}
                                                    borderRadius={0}
                                                >
                                                    <View>
                                                        <Table
                                                            dataSource={formDataForSelectedContact}
                                                            columns={getPendingFormResponsesTableColumns({ hideFormName: true })}
                                                            pagination={false}
                                                            scroll={{ y: height - 220 }}
                                                        />
                                                    </View>
                                                </Box>
                                        }
                                    </View>
                                </>
                            </View>
                        </HStack>
                    </Box>
                    :
                    <Skeleton style={{padding: 16}} active />
                }
            </View>
        </>
    );
};

export default memo(PendingForms);
