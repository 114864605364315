import { useLazyQuery } from "@apollo/client";
import { Collapse } from "antd";
import moment from "moment";
import { Checkbox,FlatList,HStack,Skeleton,Spinner,Text,VStack } from "native-base";
import { useEffect,useState } from "react";
import { Pressable } from "react-native";
import { useParams,useSearchParams } from "react-router-dom";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../../constants/Configs";
import { MailgunErrorReason } from "../../../../../../context/WorkflowContext";
import { FormsQueries } from "../../../../../../services";
import { Colors } from "../../../../../../styles";
import CircleIconView,{ ICON_LIB } from "../../../../../common/CircleIconView/CircleIconView";
import { getEmailCommunicationLogData } from "../../AddOrUpdateWorkflow/WorkflowApi";
import GetContactPhotoAndNameByContactId from "./GetContactPhotoAndNameByContactId";
import GetUserPhotoAndNameByUserId from "./GetUserPhotoAndNameByUserId";
import WorkflowExecutionLevelInfoByTag from "./WorkflowExecutionLevelInfoByTag";
import { checkWorkflowCommunicationStatus, WORKFLOW_COMMUNICATION_STATUS } from "./WorkflowStatusTable";
const { Panel } = Collapse;

const ShowFormStatus = (props: { formId: string, subjectId: string }) => {
  const [getFormLogs] = useLazyQuery(FormsQueries.GET_FORMS_LOGS_BY_SUBJECT_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  const [formData, setFormData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getFormLogs({
      variables: {
        formId: props.formId,
        subjectId: props.subjectId,
      },
    }).then((formData) => {
      setFormData(formData.data);
      setIsLoading(false);
    })
  }, [props.formId, props.subjectId])
  let status = formData?.formLogs && formData?.formLogs.length ? formData?.formLogs[0]?.status?.code : '';
  // DRAFT status means partially filled, not submitted
  if (status === 'DRAFT') {
    status = 'PENDING';
  }
  return (isLoading ? <></> : <VStack>
    <Text>{formData?.form?.name}</Text>
    <Text size={'smNormal'} color={Colors.Custom.Gray500}>{status}</Text>
  </VStack>)
}

const WorkflowPersonLevelEmailInfoByExecutionIdV1 = (
  props: {
    isShowCheckbox?: boolean,
    setSelectedList?: any,
    logTypeList?: string[],
    total: number,
    campaignId?: string,
    workflowExecutionId?: string,
    contactId?: string, uiNodeId?: string,
    setShowServiceLogs? : (workflowExecutionId: string, itemId?: string) => void,
    showServiceLogs?: {isShow: boolean, workflowExecutionId: string, itemId?: string},
    isIconLoading?: boolean
  }): JSX.Element => {
  const [limit] = useState(5);
  const [total, setTotal] = useState(limit + 1);
  const [offset, setOffset] = useState(0);
  const [pendingCount, setPendingCount] = useState<number|undefined>();
  const [submitCount, setSubmitCount] = useState<number|undefined>();

  const [isLoading, setIsLoading] = useState(false);
  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const workflowMasterId = pathParams.workflowId;
  const [communicationLogList, setCommunicationLogList] = useState<any>([])
  const [getFormLogsBySubjectIdList] = useLazyQuery(FormsQueries.GET_FORMS_LOGS_BY_SUBJECT_ID_LIST, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    loadCommunicationLogData(false, false)
  }, [limit, offset, searchParams?.get('emailStatus'), searchParams?.get('smsStatus')])


  useEffect(()=>{
    if((props.logTypeList || []).indexOf('FORM_SEND') != -1 ){
      loadCommunicationLogData(false, true, (response:any)=>{
        const subjectIdList =(response?.serviceLogs || []).map((serviceLog:any)=>{return serviceLog?.metadata?.subjectId}).filter((subjectId?:string)=>{return subjectId})
        getFormLogsBySubjectIdList({variables:{subjectIdList}}).then(formLogResponse=>{
          setPendingCount(formLogResponse?.data?.pending?.aggregate?.count);
          setSubmitCount(formLogResponse?.data?.submitted?.aggregate?.count);
        });
      })
    }
  }, [props.uiNodeId])

  const loadCommunicationLogData = async (isReset: boolean, isFetchOnlyMetadata:boolean, callBack?:any) => {
    setIsLoading(true)

    try {
      const data: any = {
        isFetchOnlyMetadata: isFetchOnlyMetadata
      }
      if(!isFetchOnlyMetadata){
        data.limit = limit;
        data.offset = isReset ? 0 : offset;
      }
      if (props.logTypeList) {
        data.logTypeList = props.logTypeList;
      }
      if (props.workflowExecutionId) {
        data.workflowExecutionId = props.workflowExecutionId;
      }
      if (props.campaignId) {
        data.campaignId = props.campaignId;
      }
      if (props.contactId) {
        data.contactId = props.contactId;
      }
      if (props.uiNodeId) {
        data.uiNodeId = props.uiNodeId;
      }
      if (workflowMasterId) {
        data.workflowMasterId = workflowMasterId;
      }
      const emailStatus = searchParams?.get('emailStatus');
      if(emailStatus){
        data.emailStatus = emailStatus;
      }
      const smsStatus = searchParams?.get('smsStatus');
      if(smsStatus){
        data.smsStatus = smsStatus;
      }
      const response: any = await getEmailCommunicationLogData(data);
      if(callBack){
        return callBack(response)
      }

      const total = response?.aggregateServiceLog?.aggregate?.count || 0;
      setTotal(total);
      setCommunicationLogList((prev: any) => {
        const newList = (response?.serviceLogs || []).filter((item1: any) => {
          const isMatch = (prev || []).some((item2: any) => {
            return item1.id === item2.id;
          })
          return !isMatch;
        })
        const list = [...prev, ...newList]
        return list
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };


  const onChangeSelection = (item: any, isSelected: boolean) => {

    setCommunicationLogList((communicationLogList: any) => {
      const newCommunicationLogList: any = []
      communicationLogList.forEach((item2: any) => {
        const newItem = { ...item2 }
        if (item.id === newItem.id) {
          newItem.isSelected = isSelected;
        }
        newCommunicationLogList.push({ ...newItem })
      })
      if (props.setSelectedList) {
        const selectedList = newCommunicationLogList.filter((item: any) => {
          return !!item.isSelected;
        })
        props.setSelectedList(selectedList);
      }
      return [...newCommunicationLogList]
    })
  }

  const getMailTitleMessage = (metadata: any, createdOn?: string) => {
    const serviceResponse = metadata?.serviceResponse;
    const response = {
      message: '',
      email: metadata?.email,
      subject: metadata?.requestData?.subject,
      createdOn: createdOn
    }
    if (serviceResponse) {
      let jsonResponse: any = {}
      try {
        jsonResponse = JSON.parse(serviceResponse);
      } catch {
      }
      response.message = jsonResponse?.message;
      const recipientList = jsonResponse?.recipientList || [];
      const emailList = recipientList.map((recipient: any) => {
        response.subject = recipient?.subject || '';
        return (recipient?.to || '');
      }).filter((email: string) => { return !!email })
      response.email = emailList.join(', ')
    }
    return response
  }
  const getElapsedTime = (createdOn:string) => {
    const oldDate = new Date(createdOn);
    const currentDate = new Date();
    const timeElapsed: number = (currentDate.getTime() - oldDate.getTime())/(60*60*1000);
    return timeElapsed
  }

  return <VStack>
    {(props.logTypeList || []).indexOf('FORM_SEND') != -1 ? <HStack>
        {submitCount != undefined ? <HStack>{'Submitted: ' +  submitCount}</HStack> : <></>}
        {pendingCount != undefined ? <HStack marginLeft={5}>{'Pending: ' +  pendingCount}</HStack> : <></>}
    </HStack> : <></> }
    <FlatList
      flex={1}
      width={'100%'}
      numColumns={1}
      onEndReachedThreshold={0.2}
      ListFooterComponent={isLoading ? <Skeleton.Text lines={4}></Skeleton.Text> : <></>}
      onEndReached={() => {

        setOffset((prev) => {
          const newOffset = prev + limit
          if (total > newOffset) {
            return newOffset
          } else {
            return prev;
          }

        });
      }}
      height={200}
      contentContainerStyle={{ flexGrow: 1, overflow: 'scroll' }}
      data={communicationLogList}
      renderItem={(data: any) => {
        const item = data?.item || {};
        // let displayMessage = ''

        const status = checkWorkflowCommunicationStatus(item)

        const isSuccess = status.workflowCommunicationStatus === WORKFLOW_COMMUNICATION_STATUS.SUCCESS;
        const isError = status.workflowCommunicationStatus === WORKFLOW_COMMUNICATION_STATUS.ERROR;
        const errorMessage = status?.reason;
        let serviceResponse: any;

        const metaData = item?.metadata?.requestData;
        try {
          serviceResponse = JSON.parse(item?.metadata?.serviceResponse || '{}');
        } catch {
          serviceResponse = errorMessage
        }


        let borderColor = Colors.secondary[800];
        let bgColor = Colors.secondary[100];
        if(isSuccess){
          borderColor = Colors.success['800'];
          bgColor = Colors.success['100'];
        } else if(isError){
          borderColor = Colors.error['800'];
          bgColor = Colors.error['100'];
        }
        const titleForMail = getMailTitleMessage(item?.metadata, item?.createdOn)

        const handleOnClick = () => {
          if(props?.setShowServiceLogs){
            props?.setShowServiceLogs(item.workflowExecutionId, item.id);
          }
        };

        return (
          <VStack
            borderBottomColor={'gray.100'}
            borderBottomWidth={1}
            marginBottom={5}
          >
            <HStack
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
              flex={1}
              width={'100%'}
              height={50}
              padding={5}
            >
              <VStack width={'100%'}>

                <HStack width={'100%'}>

                  {item?.resourceLevelLog && item?.resourceLevelLog[0]?.resourceType == 'CONTACT' ? <HStack flex={3} alignItems='center' justifyContent={'center'}>
                    {props.isShowCheckbox && <Checkbox
                      key={item.id}
                      defaultIsChecked={item.isSelected}
                      onChange={(isSelected) => {
                        onChangeSelection(item, isSelected);
                      }}
                      value={'1'}
                      marginRight={5}
                    />}
                    <GetContactPhotoAndNameByContactId contactId={item?.resourceLevelLog[0]?.resourceId} email={ item?.logType == 'SMS_SEND' ? item?.metadata?.requestData?.data?.patient?.phoneNumber ||  item?.metadata?.requestData?.mergeTag?.patient?.phoneNumber : titleForMail.email}></GetContactPhotoAndNameByContactId>
                  </HStack> : <></>}
                  {item?.resourceLevelLog && item?.resourceLevelLog[0]?.resourceType == 'USER' ? <HStack flex={2}> <GetUserPhotoAndNameByUserId userId={item?.resourceLevelLog[0]?.resourceId}></GetUserPhotoAndNameByUserId></HStack> : <></>}
                  {item?.logType == 'EMAIL_SEND' ? <VStack flex={2}><WorkflowExecutionLevelInfoByTag marginLeft={5} isHideTotal={true} tag={item?.id}></WorkflowExecutionLevelInfoByTag></VStack> : <></>}
                  {item?.logType == 'FORM_SEND' ? <HStack flex={2}><ShowFormStatus formId={item?.metadata?.formId} subjectId={item?.metadata?.subjectId} ></ShowFormStatus></HStack> : <></>}


                  {item?.logType == 'EMAIL_SEND' ? <Pressable style={{ width: 36, height: 36 }}
                    onPress={handleOnClick}
                  >{props?.showServiceLogs?.itemId===item?.id && (props?.isIconLoading) ?
                    <Spinner size={'sm'}/>:
                    <CircleIconView
                      size={'28'}
                      iconInfo={{ icon: 'email', lib: ICON_LIB.CustomSvgIcon }}
                      backgroundColor={
                        bgColor
                      }
                      borderColor={
                        bgColor
                      }
                      iconColor={
                        borderColor
                      }
                    />
                  }


                  </Pressable> : <></>}
                  {item?.logType == 'SMS_SEND' ?
                    <Pressable style={{ width: 36, height: 36 }}
                      onPress={handleOnClick}
                    >
                      {props?.showServiceLogs?.itemId===item?.id && (props?.isIconLoading) ?
                      <Spinner size={'sm'}/>:
                      <CircleIconView
                        size={'28'}
                        iconInfo={{ icon: 'sms', lib: ICON_LIB.CustomSvgIcon }}
                        backgroundColor={
                          bgColor
                        }
                        borderColor={
                          bgColor
                        }
                        iconColor={
                          borderColor
                        }
                      />}
                    </Pressable> : <></>
                  }

                  {item?.logType == 'PUSH_NOTIFICATION_SEND' ?
                    <Pressable style={{ width: 36, height: 36 }}
                      onPress={handleOnClick}
                      >{props?.showServiceLogs?.itemId===item?.id && (props?.isIconLoading) ?
                      <Spinner size={'sm'}/>:
                      <CircleIconView
                        size={'28'}
                        iconInfo={{ icon: 'pushNotification', lib: ICON_LIB.CustomSvgIcon }}
                        backgroundColor={
                          bgColor
                        }
                        borderColor={
                          bgColor
                        }
                        iconColor={
                          borderColor
                        }
                      />
                      }


                    </Pressable> : <></>
                  }

                  {item?.logType == 'FORM_SEND' ?
                    <Pressable style={{ width: 36, height: 36 }}
                      >{props?.showServiceLogs?.itemId===item?.id && (props?.isIconLoading) ?
                      <Spinner size={'sm'}/>:

                      <CircleIconView
                        size={'28'}
                        iconInfo={{ icon: 'forms', lib: ICON_LIB.CustomSvgIcon }}
                        backgroundColor={
                          bgColor
                        }
                        borderColor={
                          bgColor
                        }
                        iconColor={
                          borderColor
                        }
                      />
                      }


                    </Pressable> : <></>
                  }
                </HStack>
                {!!titleForMail?.createdOn && <HStack marginTop={2}>
                  {'Time: ' + moment(titleForMail?.createdOn).format('MMM DD YYYY' + ' hh:mm A')}
                </HStack>}
                {!!titleForMail.subject && <HStack marginTop={2}>{'Subject: ' + titleForMail.subject?.trim()}</HStack>}
                {/* {displayMessage? <HStack marginTop={2}>{ displayMessage}</HStack>: <></> } */}
              </VStack>

            </HStack>
          </VStack>)
      }}
    />
  </VStack>
}

export default WorkflowPersonLevelEmailInfoByExecutionIdV1;
