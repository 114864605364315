import {useLazyQuery} from '@apollo/client';
import {Drawer, notification, Popover, Table} from 'antd';
import {
  HStack,
  Pressable,
  Skeleton,
  useMediaQuery,
  useToast,
  VStack,
} from 'native-base';
import './style.css';
import {useEffect, useState} from 'react';
import {Dimensions, View} from 'react-native';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {BUTTON_TYPE} from '../../../../constants';
import {LeadQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import {
  findIsAllowToRunAutomation,
  getAccountId,
  getAccountUUID,
} from '../../../../utils/commonUtils';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import AddOrUpdateCampaign from '../../../RightSideContainer/ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import {AutomationWorkflowEmployee} from '../../../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import {GroupMemberListFilter} from '../../../RightSideContainer/MembersManagement/GroupMemberListDrawer/GroupMemberListFilter';
import {getServiceLogsQueryObject} from '../../../RightSideContainer/MembersManagement/Helper';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import MessagingContactDetailsDrawer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {
  getUserPermissionByEntityAndActionCode,
  USER_ACCESS_PERMISSION,
} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import {createCampaignSegment} from '../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
import {DisplayText} from '../../DisplayText/DisplayText';
import EmailDrawerCommon from '../../EmailPopupView/EmailDrawerCommon';
import MemberInfoListItem from '../../MemberInfoListItem/MemberInfoListItem';
import {SMSDrawer} from '../../SMSDrawer';
import TitleSubtitleView from '../../TitleSubtitleView/TitleSubtitleView';
import {
  getContactWithConsent,
  GraphMemberListController,
} from './GraphMemberListUtils';
import {
  ContactResponseObject,
  GraphMemberListProps,
  GraphMemberListState,
} from './interfaces';
import { EmailDrawerCommonV2 } from '../../EmailDrawerCommonV2';

const GraphMemberList = (props: GraphMemberListProps) => {
  const {isOpen, title, onCompleteAction, isFromNewEmployer} = props;
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const [visible, setVisible] = useState(true);
  const createCampaignUrl = '/#/campaignManagement/create';
  const toast = useToast();
  const [campaignDrawerState, setCampaignDrawerState] = useState({
    isVisible: false,
    segmentId: null,
  });
  const [state, setState] = useState<GraphMemberListState>({
    loading: false,
    contactList: [],
    page: props.page,
    pageSize: props.pageSize,
    total: props.total,
    popoverOpen: false,
    contactResponse: [] as ContactResponseObject[],
    filterOpen: false,
    campaign: {},
    workflow: {},
    query: {},
    contactUuidMap: {},
    automationOpen: false,
    smsDrawerOpen: false,
    emailDrawerOpen: false,
    selectedRowKeys: [],
    selectedRowKeysWithIndex: [],
    selectedContacts: [],
    contactPageMap: {},
  });

  const contactUuidList = props.contactUuidList || [];
  const contactIdList = props.contactIdList || [];
  const {height} = Dimensions.get('window');
  const tableHeight = height - 300;

  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactsByUuid);
  const [getContactsByIds] = useLazyQuery(LeadQueries.getContactsByIds);

  const {
    onMount,
    handleOnClose,
    handleVisibleChange,
    getSelectedContacts,
    handleSelectedContacts,
  } = new GraphMemberListController({
    state,
    accountId,
    accountUuid,
    contactUuidList,
    contactIdList,
    setState,
    getContactsByUuid,
    getContactsByIds,
    onCompleteAction,
  });

  useEffect(() => {
    if (props.isOpen) {
      onMount(contactUuidList, props?.contactIdList);
    }
  }, [
    props.contactUuidList,
    props?.contactUuidList?.length,
    props?.contactIdList,
    props?.contactIdList?.length,
  ]);

  const isEmailAndSMSAllowed = getUserPermissionByEntityAndActionCode(
    'ADMIN_PANEL_WINDOW',
    'CONTENT_MANAGEMENT'
  );
  const isCreateCampaignAllowed = getUserPermissionByEntityAndActionCode(
    'DASHBOARD_WINDOW',
    'CAMPAIGN_MANAGER'
  );
  const isCreateAutomationAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );

  const [selectedContactData, setSelectedContactData] = useState<any>();
  const showButtonList =
    (isEmailAndSMSAllowed ||
      isCreateAutomationAllowed ||
      isCreateCampaignAllowed) &&
    state.selectedRowKeys.length > 0;

  const showSelectMemberToastMessage = () => {
    notification.error({
      message: 'Please Select Member First',
    });
  };

  const [isSmallScreen] = useMediaQuery([{maxWidth: 1300}]);

  const onRunAutomationClick = () => {
    if (state?.selectedRowKeys?.length > 0) {
      setState((prev) => {
        return {
          ...prev,
          automationOpen: true,
          popoverOpen: false,
        };
      });
    } else {
      showSelectMemberToastMessage();
    }
  };

  const onCreateCampaignClick = () => {
    if (state.selectedRowKeys.length > 0) {
      const selectedRowKeys = state.selectedRowKeys;
      const contactUuidMap = state.contactUuidMap;
      createCampaignSegment({
        name: '',
        tenantId: accountUuid,
        metadata: {
          patientFilter: {
            contactIds: selectedRowKeys.map((uuid) => {
              return contactUuidMap[uuid].id;
            }),
          },
        },
      }).then((response) => {
        if (response?.id) {
          setCampaignDrawerState({
            isVisible: true,
            segmentId: response?.id,
          });
        }
      });
    } else {
      showSelectMemberToastMessage();
    }
  };
  const isAllowToRunAutomation = findIsAllowToRunAutomation();
  const runAutomationBtnText = 'runAutomation';
  const createCampaignText = 'Create Campaign';
  const sendEmailTxt = 'Send Email';
  const buttonList = [
    ...(props?.onClose
      ? [
          {
            show: true,
            id: 2,
            btnText: 'Close',
            textColor: Colors.Custom.mainPrimaryPurple,
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              setState((prev) => {
                return {...prev, selectedContacts:[], selectedRowKeysWithIndex:[], selectedRowKeys:[]}
              })
              if (props?.onClose) {
                props?.onClose?.();
                setVisible(false);
              }
            },
          },
        ]
      : []),
    ...(isCreateAutomationAllowed && !isSmallScreen
      ? [
          {
            show: isAllowToRunAutomation,
            id: 2,
            btnText: runAutomationBtnText,
            textColor: Colors.Custom.mainPrimaryPurple,
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              onRunAutomationClick();
            },
          },
        ]
      : []),
    ...(isCreateCampaignAllowed && !isSmallScreen
      ? [
          {
            show: true,
            id: 3,
            btnText: createCampaignText,
            textColor: Colors.Custom.mainPrimaryPurple,
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              onCreateCampaignClick();
            },
          },
        ]
      : []),
  ];

  const popOverButtonList = [
    ...(isCreateAutomationAllowed && isSmallScreen
      ? [
          {
            btnText: runAutomationBtnText,
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: '',
            isTransBtn: true,
            onClick: () => {
              onRunAutomationClick();
            },
          },
        ]
      : []),
    ...(isCreateCampaignAllowed && isSmallScreen
      ? [
          {
            btnText: createCampaignText,
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: '',
            isTransBtn: true,
            onClick: () => {
              onCreateCampaignClick();
            },
          },
        ]
      : []),
    ...(isEmailAndSMSAllowed
      ? [
          {
            btnText: sendEmailTxt,
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: '',
            onClick: () => {
              if (state.selectedRowKeys.length > 0) {
                const selectedNumberUUids = getSelectedMemberForEmail();
                if (selectedNumberUUids.length) {
                  setState((prev) => {
                    return {
                      ...prev,
                      emailDrawerOpen: true,
                      popoverOpen: false,
                    };
                  });
                } else {
                  notification.error({
                    message:
                      'Please select the member having valid email address',
                  });
                }
              } else {
                showSelectMemberToastMessage();
              }
            },
          },
        ]
      : []),
    ...(isEmailAndSMSAllowed
      ? [
          {
            btnText: 'sendSms',
            size: 'sm',
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
            btnStype: BUTTON_TYPE.PRIMARY,
            variant: '',
            isTransBtn: true,
            onClick: () => {
              if (state.selectedRowKeys.length > 0) {
                const selectedNumberUUids = getSelectedMemberForSMS();
                if (selectedNumberUUids.length) {
                  setState((prev) => {
                    return {
                      ...prev,
                      smsDrawerOpen: true,
                      popoverOpen: false,
                    };
                  });
                } else {
                  notification.error({
                    message:
                      'Please select the member having phone number for sending sms',
                  });
                }
              } else {
                showSelectMemberToastMessage();
              }
            },
          },
        ]
      : []),
  ];

  const getSelectedMemberForEmail = () => {
    return state.selectedRowKeys.filter((uuid) => {
      return state.contactUuidMap?.[uuid]?.email ?? false;
    });
  };

  const getSelectedMemberForSMS = () => {
    return state.selectedRowKeys.filter((uuid) => {
      return state.contactUuidMap?.[uuid]?.phoneNumber ?? false;
    });
  };

  useEffect(() => {
    setState((prev) => {
      const selectedRowKeysWithIndex: string[] = [];
      (prev.contactResponse || []).forEach((contactDetails, index) => {
        if (
          state.selectedRowKeys.indexOf(contactDetails?.contact?.uuid) != -1
        ) {
          selectedRowKeysWithIndex.push(contactDetails?.contact?.uuid + index);
        }
      });
      return {...prev, selectedRowKeysWithIndex};
    });
  }, [
    state.contactResponse,
    state.selectedRowKeys,
    state.selectedRowKeys?.length,
  ]);
  return (
    <>
      <Drawer
        className="graph-member-list-drawer"
        open={isFromNewEmployer ? visible : isOpen}
        onClose={() => {
          if (isFromNewEmployer) {
            props?.onClose?.();
            setVisible(false);
          } else {
            handleOnClose();
          }
        }}
        width={isSmallScreen ? '85vw' : '50vw'}
        title={
          <VStack>
            <HStack
              justifyContent={'space-between'}
              alignItems="center"
              flex={1}
            >
              <HStack alignItems={'center'}>
                <TitleSubtitleView
                  containerStyle={{marginLeft: 0, marginBottom: 5}}
                  isHideCommonButton
                  title={title || 'Member List'}
                  titleStyle={{
                    maxWidth: 400,
                    flexWrap: 'wrap',
                    color: Colors.primary['400'],
                  }}
                />
              </HStack>

              <HStack alignItems={'center'}>
                <HStack style={{marginHorizontal: 8}} space={'2'}>
                  {buttonList.map((item) => {
                    return (
                      <FoldButton
                        key={item.id}
                        nativeProps={{
                          variant: item.btnStype,
                          onPress: () => {
                            item.onClick();
                          },
                        }}
                        customProps={{
                          hideButton: item.show === false,
                          btnText: (
                            <DisplayText
                              textLocalId={item.btnText}
                              size={'smBold'}
                              extraStyles={{
                                color: Colors.Custom.mainPrimaryPurple,
                              }}
                            />
                          ),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                    );
                  })}
                  {popOverButtonList.length ? (
                    <Popover
                      content={
                        <VStack>
                          {popOverButtonList.map((item: any) => {
                            return (
                              <Pressable
                                key={item.id || ''}
                                onPress={() => {
                                  item.onClick();
                                }}
                              >
                                <View
                                  style={{
                                    marginVertical: 8,
                                  }}
                                >
                                  <DisplayText textLocalId={item.btnText} />
                                </View>
                              </Pressable>
                            );
                          })}
                        </VStack>
                      }
                      trigger="click"
                      overlayInnerStyle={{padding: 0, borderRadius: 16}}
                      overlayStyle={{padding: 0}}
                      overlay={{padding: 0}}
                      style={{padding: 0, borderRadius: 16}}
                      placement={'bottomRight'}
                      visible={state.popoverOpen}
                      onVisibleChange={handleVisibleChange}
                    >
                      <Pressable
                        style={{
                          height: 32,
                          width: 32,
                          borderRadius: 18,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          setState((prev) => {
                            return {
                              ...prev,
                              popoverOpen: true,
                            };
                          });
                        }}
                      >
                        <Fontisto
                          name="more-v-a"
                          size={18}
                          color={Colors.Custom.foldIconColor}
                        />
                      </Pressable>
                    </Popover>
                  ) : (
                    <></>
                  )}
                </HStack>
                {/* <VStack
              style={{
                width: 32,
                height: 32,
              }}
            >
              <Pressable
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      filterOpen: true,
                    };
                  });
                }}
              >
                <FilterIconSvg
                  isEnabled={true}
                  customStrokeColor={Colors.secondary['800']}
                  customBgColor={Colors.secondary['100']}
                />
              </Pressable>
            </VStack> */}
              </HStack>
            </HStack>
          </VStack>
        }
      >
        {state.loading && !contactUuidList.length ? (
          <Skeleton.Text lines={4} />
        ) : (
          <Table
            loading={state.loading}
            scroll={{y: tableHeight}}
            dataSource={state.contactResponse}
            showHeader={true}
            onRow={(_record: any) => {
              return {
                onClick: () => {
                  setSelectedContactData(_record.contact);
                },
              };
            }}
            rowKey={(record, index) => record?.contact?.uuid + index}
            rowSelection={{
              onSelectAll(selected, selectedRows, changeRows) {
                let selectedRowKeys = [...state.selectedRowKeys];
                const contactUuidMap = {...state.contactUuidMap};
                const uuidList = changeRows.map((record) => {
                  contactUuidMap[record?.contact?.uuid] = record.contact;
                  return record?.contact?.uuid;
                });

                if (selected) {
                  uuidList.forEach((uuid) => {
                    if (selectedRowKeys.indexOf(uuid) == -1) {
                      selectedRowKeys.push(uuid);
                    }
                  });
                } else {
                  uuidList.forEach((uuid) => {
                    selectedRowKeys = selectedRowKeys.filter((selectedUuid) => {
                      return selectedUuid !== uuid;
                    });
                  });
                }
                setState((prev) => {
                  return {...prev, selectedRowKeys, contactUuidMap};
                });
              },
              onSelect(contactResponse: any, isSelected) {
                const contactUuidMap = {...state.contactUuidMap};
                const record = contactResponse?.contact || {};
                let selectedRowKeys = [...state.selectedRowKeys];
                if (isSelected && selectedRowKeys.indexOf(record.uuid) == -1) {
                  selectedRowKeys.push(record.uuid);
                } else {
                  selectedRowKeys = selectedRowKeys.filter((uuid) => {
                    return uuid != record.uuid;
                  });
                }
                contactUuidMap[record.uuid] = record;
                setState((prev) => {
                  return {
                    ...prev,
                    selectedRowKeys: selectedRowKeys,
                    contactUuidMap,
                  };
                });
              },
              selectedRowKeys: state.selectedRowKeysWithIndex,
            }}
            columns={[
              {
                title: (
                  <DisplayText
                    size={'xsMedium'}
                    extraStyles={{color: Colors.Custom.Gray500}}
                    textLocalId="members"
                  />
                ),
                dataIndex: 'contact',
                key: 'contact',
                render(value: any, record, index) {
                  const data = {...value};
                  data.person = {
                    gender: {
                      code: value?.person?.sexAtBirth?.code,
                      value: value?.person?.sexAtBirth?.value,
                    },
                  };
                  return (
                    <MemberInfoListItem
                      contactData={value as IContact}
                      showEmail
                      showPhoneNumber
                      showDateOfBirth={true}
                    />
                  );
                },
              },
            ]}
            pagination={{
              position: ['bottomCenter'],
              pageSize: props.pageSize,
              pageSizeOptions: [5, 7, 10, 15, 20, 50],
              current: props.page,
              total: props.total,
              showSizeChanger: true,
              onChange(page, pageSize) {
                if (props.onPaginationChange) {
                  if (props.isFromNewEmployer) {
                    setState((prev) => {
                      return {
                        ...prev,
                        loading: true,
                      };
                    });
                  }
                  props.onPaginationChange(page, pageSize);
                }
              },
            }}
          />
        )}

        {state.filterOpen && (
          <GroupMemberListFilter
            isOpen={state.filterOpen}
            selectedCampaign={state.campaign}
            selectedWorkflow={state.workflow}
            onSubmit={(campaign, workflow) => {
              const query = getServiceLogsQueryObject(campaign, workflow);
              setState((prev) => {
                return {
                  ...prev,
                  filterOpen: false,
                  campaign: campaign,
                  workflow: workflow,
                  query: query,
                };
              });
            }}
            onCompleteAction={() => {
              setState((prev) => {
                return {
                  ...prev,
                  filterOpen: false,
                };
              });
            }}
          />
        )}
      </Drawer>

      {state.automationOpen && (
        <Drawer
          visible={state.automationOpen}
          width={700}
          onClose={() =>
            setState((prev) => {
              return {
                ...prev,
                automationOpen: false,
              };
            })
          }
          closable={true}
          title={<></>}
        >
          <AutomationWorkflowEmployee
            tenantId={accountUuid}
            cancelBtnTxt={'Back'}
            initData={{
              selectedItems: state.selectedRowKeys.map((uuid) => {
                return state.contactUuidMap[uuid];
              }),
            }}
            onModalClose={() => {
              setState((prev) => {
                return {
                  ...prev,
                  automationOpen: false,
                };
              });
            }}
          />
        </Drawer>
      )}
      {campaignDrawerState?.isVisible ? (
        <Drawer
          visible={campaignDrawerState?.isVisible}
          width={Dimensions.get('window').width * 0.7}
          onClose={() => {
            setCampaignDrawerState({
              isVisible: false,
              segmentId: null,
            });
          }}
          headerStyle={{
            display: 'none',
          }}
        >
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={(status) => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
              if (status != 'cancel') {
                notification.success({
                  message:
                    status == 'created'
                      ? 'Campaign created successfully.'
                      : 'Campaign updated successfully.',
                });
              }
            }}
          />
        </Drawer>
      ) : null}
      {selectedContactData && (
        <MessagingContactDetailsDrawer
          contactData={selectedContactData}
          isDrawerVisible={selectedContactData ? true : false}
          contactType={selectedContactData?.contactType}
          onSideBarActionPerformed={(action?: any) => {
            setSelectedContactData('');
          }}
        />
      )}

      {state.smsDrawerOpen && (
        <SMSDrawer
          isOpen={state.smsDrawerOpen}
          data={getSelectedMemberForSMS().map((uuid) => {
            const item = state.contactUuidMap[uuid] || {};
            return {
              contactUuid: item.uuid,
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber,
              contactConsents: item?.contactConsents,
            };
          })}
          onCompleteAction={() => {
            handleOnClose();
            setState((prev) => {
              return {
                ...prev,
                smsDrawerOpen: false,
              };
            });
          }}
        />
      )}

      {state.emailDrawerOpen && (
        <EmailDrawerCommonV2
          isOpen={state.emailDrawerOpen}
          onClose={() => {
            handleOnClose();
            setState((prev) => {
              return {
                ...prev,
                emailDrawerOpen: false,
              };
            });
          }}
          onEmailSent={() => {
            handleOnClose();
            setState((prev) => {
              return {
                ...prev,
                emailDrawerOpen: false,
              };
            });
          }}
          contactIds={
            getContactWithConsent(
              state.selectedRowKeys,
              state.contactUuidMap
            ).map((item) => item.id) || []
          }
        />
      )}
    </>
  );
};

export default GraphMemberList;
