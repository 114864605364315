import {Switch} from 'antd';
import {IAllowedOperation} from '../../../../Interfaces';
import {testID} from '../../../../testUtils';
import {Colors} from '../../../../styles';
import {AllowedOperationKeys, ISectionViewConfig} from './interfaces';
import {HStack, Text} from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import PatientOutlineIcon from '../../../common/Svg/PatientOutlineIcon';
import PamiIconSvg from '../../../common/Svg/PamiIconSvg';

export const getOperationTitle = <T extends AllowedOperationKeys>(
  key: T,
  resourceName: string
): string => {
  // SPECIAL CASES 
  if(resourceName === FHIR_RESOURCE.CLINICAL_DATA){
    switch (key) {
      case 'canCreate':
        return 'Create/Update';
      default:
        return key;
    }
  }
  switch (key) {
    case 'type':
      return 'Type';
    case 'inputType':
      return 'Input type ';
    case 'canCreate':
      return 'Create';
    case 'canUpdate':
      return 'Update';
    case 'canDelete':
      return 'Delete';
    case 'canRead':
      return 'Read';
    case 'isEnabled':
      return 'Enable';
    case 'isRequired':
      return 'Required';
    case 'foldDisplay':
      return 'Fold display ';
    case 'format':
      return 'Format ';
    case 'defaultValue':
      return 'Default value ';
    case 'isHidden':
      return 'Hidden';
    case 'label':
      return 'Label ';
    case 'isPrimary':
      return 'Primary ';
    case 'possibleValues':
      return 'Possible values ';
    case 'transformValues':
      return 'Transform values ';
    case 'disableMultiple':
      return 'Disable multiple ';
    case 'maxCharLimit':
      return 'Max character limit ';
    case 'hideFieldsForFreeText':
      return 'Hide fields for free text ';
    case 'isSingleEmail':
      return 'Is single email ';
    case 'disableDeletePCP':
      return 'Disable delete PCP ';
    case 'extensionUrl':
      return 'Extension URL ';
    case 'isMultiple':
      return 'Is multiple ';
    case 'max':
      return 'Max ';
    case 'min':
      return 'Min ';
    default:
      return 'Unknown ';
  }
};

export const renderJSXForKey = (
  key: AllowedOperationKeys,
  value: any,
  disabled: boolean,
  onChange: (key: AllowedOperationKeys, value: any) => void
): JSX.Element => {
  switch (key) {
    // All boolean cases
    case 'canCreate':
    case 'canUpdate':
    case 'canDelete':
    case 'canRead':
    case 'isEnabled':
    case 'isRequired':
    case 'isHidden':
    case 'isPrimary':
    case 'disableMultiple':
    case 'hideFieldsForFreeText':
    case 'isSingleEmail':
    case 'disableDeletePCP':
    case 'isMultiple':
      return (
        <Switch
          checked={!!value}
          style={{
            backgroundColor: !!value
              ? Colors.Custom.mainPrimaryPurple
              : Colors.Custom.Gray500,
          }}
          onChange={(checked) => onChange(key, checked)}
        />
      );

    // All string cases
    case 'type':
    case 'inputType':
    case 'foldDisplay':
    case 'format':
    case 'defaultValue':
    case 'label':
    case 'extensionUrl':
      return <></>;

    // Handle other types here

    default:
      return <></>;
  }
};

export const getHeaderRightView = (
  allowedOperationsJSON: IAllowedOperation,
  allowedOperationsList: AllowedOperationKeys[]
) => {
  const total = allowedOperationsList;
  let onValues = 0;
  total.map((item) => {
    if (allowedOperationsJSON?.[item as keyof IAllowedOperation] === true) {
      onValues++;
    }
  });
  const text = `${onValues}/${total.length} Enabled`;
  if (!total.length) {
    return <></>;
  }
  return (
    <HStack alignItems={'center'}>
      <FeatherIcon name="shield" size={16} color={Colors.Custom.Gray500} />
      <Text ml={2} color={Colors.Custom.Gray500}>
        {text}
      </Text>
    </HStack>
  );
};

export const getEnabledOperations = (
  type: string,
  allOperations: IAllowedOperation
): string[] => {
  let hiddenList: AllowedOperationKeys[] = [];
  switch (type) {
    case FHIR_RESOURCE.PATIENT:
      hiddenList = ['canRead', 'canDelete'];
      break;
    case FHIR_RESOURCE.CLINICAL_DATA:
      hiddenList = ['canRead','canDelete','canUpdate'];
      break;
  }
  const list = Object.keys(allOperations) as (AllowedOperationKeys[]);
  return list.filter((item) => !hiddenList.includes(item));
};

export const getSectionViewConfig = (type: string): ISectionViewConfig => {
  switch (type) {
    case FHIR_RESOURCE.PATIENT:
      return {
        title: 'Patient',
        subtitle: 'Manage the permissions related to patients',
        icon: <PatientOutlineIcon />,
      };
    case FHIR_RESOURCE.CLINICAL_DATA:
      return {
        title: 'Clinical Data',
        subtitle:
          'Manage the permissions related to clinical records',
        icon: <PamiIconSvg />,
      };
  }
  return {
    title: '',
    subtitle: '',
    icon: <></>,
  };
};


export const GLOBAL_SWITCH_BY_RESOURCE: {
  [key: string]: AllowedOperationKeys[];
} = {
  [FHIR_RESOURCE.PATIENT]: [],
  [FHIR_RESOURCE.CLINICAL_DATA]: ['canCreate', 'canDelete', 'canUpdate'],
};