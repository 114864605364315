import {useLazyQuery} from '@apollo/client';
import {Drawer} from 'antd';
import {HStack, Image, Pressable, Text, View} from 'native-base';
import {useContext, useState} from 'react';
import {BUTTON_TYPE} from '../../../../../constants';
import {WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../../services';
import BaseService from '../../../../../services/CommonService/BaseService';
import {Colors} from '../../../../../styles';
import {getAccountId, getUserUUID} from '../../../../../utils/commonUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {getContactTypeId, getMlovId} from '../../../../../utils/mlovUtils';
import {ModalActionAutoComplete} from '../../../../common/ModalActionCommonComponent/ModalActionAutoComplete';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IExistingGroupMember} from '../ConversationContainer/CreateGroupPopup/interfaces';

export const GroupAddPatient = (props: any) => {
  const {
    isDrawerVisible,
    selectedConversation,
    onGroupAddPatientChange,
    groupId,
    groupMembers,
  } = props;
  const [stateData, setStateData] = useState({
    patientLists: [] as any,
    selectedPatientList: [] as any,
    selectedPatient: undefined as any,
    loading: false as boolean,
    btnLoading: false as boolean,
  });
  const accountId = getAccountId();
  const mlovData = useContext(CommonDataContext);
  // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const contactTypeUuid = getContactTypeId('CUSTOMER');

  const userUuid = getUserUUID();
  const axios = BaseService.getSharedInstance().axios;

  const [getPatientList] = useLazyQuery(LeadQueries.GetContactsForGroup, {
    fetchPolicy: 'no-cache',
  });

  const getPatientLists = async (searchPatient: string) => {
    if (searchPatient.length) {
      setStateData((prev) => {
        return {
          ...prev,
          patientLists: [],
          loading: true,
        };
      });
      const responseData = await getPatientList({
        variables: {
          accountId: accountId,
          typeId: contactTypeUuid,
          isDeleted: false,
          // searchString: `%${searchString}%`,
          searchString: `%${searchPatient}%`,
        },
      });

      if (responseData?.data?.contacts?.length) {
        setStateData((prev) => {
          return {
            ...prev,
            patientLists: responseData.data.contacts,
            loading: false,
          };
        });
        // return [...responseData.data.contacts];
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            patientLists: [],
            loading: false,
          };
        });
      }
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          patientLists: [],
          loading: false,
        };
      });
    }

    return [];
  };

  const handleAddMember = () => {
    setStateData((prev) => {
      return {
        ...prev,
        btnLoading: true,
      };
    });
    const patientsUuid = stateData.selectedPatientList.map((patient: any) => {
      return patient.uuid;
    });
    const body = {
      groupId: groupId,
      patientIds: patientsUuid,
    };
    axios
      .post(`crm-nest/account/${userUuid}/group/addMembers`, {
        groupId: groupId,
        patientIds: patientsUuid,
      })
      .then(() => {
        setStateData((prev) => {
          return {
            ...prev,
            btnLoading: false,
          };
        });
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          WEB_SOCKET_EVENT_CODE.MEMBER_ADDED,
          selectedConversation
        );
        onGroupAddPatientChange('patientAdded');
      })
      .catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            btnLoading: false,
          };
        });
      });
  };
  const handleRemovePatient = (patient: any) => {
    const newList = stateData.selectedPatientList.filter((person: any) => {
      return person.uuid !== patient.uuid;
    });
    setStateData({...stateData, selectedPatientList: newList});
  };

  const checkIfMember = (patient: any) => {
    const checkCurrentMember = (member: IExistingGroupMember) => {
      return member?.groupUserId === patient?.uuid;
    };
    if (groupMembers.some(checkCurrentMember)) {
      return true;
    } else {
      return false;
    }
  };

  const filterPatient = (patientList: any) => {
    const filteredPatientList = patientList.filter((patient: unknown) => {
      if (checkIfMember(patient) === false) {
        return patient;
      }
    });
    return filteredPatientList;
  };

  return (
    <View>
      <Drawer
        visible={isDrawerVisible}
        width={500}
        onClose={() => {
          onGroupAddPatientChange('closeModal');
        }}
        title={ <ModalActionTitle
          title={'addPatient'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onGroupAddPatientChange('closeModal');
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              isLoading: stateData.btnLoading,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleAddMember();
              },
            },
          ]}
        />}
      >
        <View  mx={5}>
          <ModalActionAutoComplete
            isRequired={true}
            // value={stateData.selectedPatient}
            label={'channelPatient'}
            placeholder="Search Patients"
            allowClear={true}
            onClear={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedPatient: undefined,
                };
              });
            }}
            onChange={(value: any, options: any) => {
              let patient: any = {};

              if (options.key) {
                patient = JSON.parse(options.key);
                const patientExist = stateData.selectedPatientList.filter(
                  (member: any) => {
                    return member.uuid === patient.uuid;
                  }
                );
                if (patientExist.length) {
                } else {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedPatientList: [
                        ...prev.selectedPatientList,
                        patient,
                      ],
                      selectedPatient: patient.name,
                    };
                  });
                }
              } else {
                getPatientLists(value);
              }
            }}
            loading={stateData.loading}
            customStyle={{flex: 1}}
            data={filterPatient(stateData.patientLists)}
            optionProps={{id: '', code: 'name', value: 'name'}}
          />
          <HStack mt={2} flexWrap={'wrap'} overflow={'auto'} space={2}>
            {stateData.selectedPatientList &&
              stateData.selectedPatientList.length > 0 &&
              stateData.selectedPatientList.map((patient: any) => {
                return (
                  <View
                    mt={2}
                    px={2}
                    py={1}
                    borderRadius={16}
                    bgColor={'gray.100'}
                  >
                    <HStack space={1} alignItems={'center'}>
                      <Text>{patient.name}</Text>
                      <Pressable onPress={() => handleRemovePatient(patient)}>
                        <Image
                          style={{height: 8, width: 8}}
                          source={require('../../../../../assets/images/cancel.png')}
                          alt='image'
                        />
                      </Pressable>
                    </HStack>
                  </View>
                );
              })}
          </HStack>
        </View>
      </Drawer>
    </View>
  );
};
