/* eslint-disable @typescript-eslint/no-var-requires */
import {useLazyQuery, useQuery} from '@apollo/client';
import {DatePicker, Drawer, Select, Spin} from 'antd';
import moment from 'moment';
import {Box, Divider, HStack, Image, Radio, Skeleton, Text, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  MENTION_READ_STATUS,
  MLOV_CATEGORY,
  PERSON_TYPES,
} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {VIRTUAL_ASSIGNEE_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {ILoginUserData, IUser} from '../../../../../../Interfaces';
import {UserQueries} from '../../../../../../services';
import {IAccountPracticeLocation, IUserPracticeLocation, IUserPracticeLocationData} from '../../../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../../styles/Colors';
import {getAccountId, getAccountUUID, getUserId, getUserUUID} from '../../../../../../utils/commonUtils';
import {
  getDateStrFromFormat,
  getMomentObjFromFormat,
} from '../../../../../../utils/DateUtils';
import LocalStorage from '../../../../../../utils/LocalStorage';
import {
  getContactTypeId,
  getMlovCodeIdObj,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {ModalActionMultiCheckBox} from '../../../../../common/ModalActionCommonComponent/ModalActionMultiCheckBox';
import {ModalActionRadioSelect} from '../../../../../common/ModalActionCommonComponent/ModalActionRadioSelect';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import PhoneRingSvg from '../../../../../common/Svg/PhoneRingSvg';
import {GetAllVirtualPhoneNumber} from '../../../CallLogs/VirtualPhoneNumberQueries';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {
  assignment,
  assignmentData,
  assignmentStatusData,
  callStatus,
  contact,
  contactType,
  date,
  dateType,
  directions,
  inbox,
  mention,
  mentionData,
  message,
  MessageType,
  outOfOfficeHours,
  phoneTypes,
  PHONE_TYPE_CONST,
  readType,
  status,
  statusData,
} from '../ConversationConst';
import {canShowAssignedUsers} from '../ConversationUtils';
import {getConversationFilterData} from './ConversationFilterUtils';
import {AllUsers, IAssigneeStatusType, IConversationFilterProps, IConversationFilterState, IVirtualPhoneNumberAssignee, IVirtualPhoneNumberData, Users} from './interface';
import {getAllAccountUsersWithUnreadMsgCountAPI, getAllUsersWithUnreadMsgCountAPI} from '../../ConversationChannelNew/ConversationAPIService';
import {debounce} from 'lodash';
import {capitalizeText} from '../../../../../common/ContactRelationView/ContactRelationUtils';
import React from 'react';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import EmailActionSvg from '../../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import USFlagSVG from '../../../../../common/Svg/FlagIcon/USFlagSVG';
import { IInboxOptions } from '../../../../../../services/Inbox/interfaces';
import { formatInboxDataForSelectOptions } from '../../ConversationChannelTabs/ChannelTabsUtils';
const {RangePicker} = DatePicker;
const {Option} = Select;
const ConversationFilter = (props: IConversationFilterProps) => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const userId = getUserId();
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);
  const {showModal, onModalAction, selectedTab, inboxList} = props;
  const defaultSelectedAssignType = assignmentStatusData.filter((item) => {
    return item?.isDefault === true;
  })
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const otherContactTypeId = getContactTypeId(PERSON_TYPES.OTHER);
  const isEmailUserPreferenceEnabled = isAccountConfigEnabled(CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE);
  const allSMSInboxList = formatInboxDataForSelectOptions(
    inboxList[CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS] || []
  );
  const allEmailInboxList = formatInboxDataForSelectOptions(
    inboxList[CHANNEL_TYPE_CODE.CHANNEL_EMAIL] || []
  );

  const smsInboxList = allSMSInboxList?.filter((item) => {
    const inboxMember = item?.inboxMembers?.find(
      (item) => item?.userId == userId
    );
    return inboxMember?.userId;
  }) || [];
  const emailInboxList = allEmailInboxList?.filter((item) => {
    const inboxMember = item?.inboxMembers?.find(
      (item) => item?.userId == userId
    );
    return inboxMember?.userId;
  }) || [];

  const [conversationFilterState, setConversationFilterState] =
    useState<IConversationFilterState>({
      selectedInboxId: 0,
      emailSelectedInboxId: 0,
      selectedContactType: 'ALL',
      selectedDateCode: 'ALL',
      selectedAssignedFilter: 'ALL',
      selectedAssignedStatusFilter: defaultSelectedAssignType[0] as IAssigneeStatusType,
      selectedInboxData: {} as any,
      emailSelectedInboxData: {} as any,
      readType: 'ALL',
      showOnlyMentioned: false,
      fromDate: '',
      toDate: '',
      direction: '',
      isOutOfOfficeHours: '',
      status: '',
      searchQuery: '',
      webPhoneToUserId: '',
      toNumber: '',
      internalUsers: [] as AllUsers[],
      mainUsersList: [] as AllUsers[],
      selectedAssignedUserData: {} as Users,
      fetchUserLoading: false,
      mentionReadStatus: MENTION_READ_STATUS.ALL
    });
  let selectedInboxType = '';
  if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
    selectedInboxType = 'Email';
  } else if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
    selectedInboxType = 'SMS';
  } else if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET) {
    selectedInboxType = 'Chat';
  }

  const [GetAllVirtualPhoneNumberAPI] = useLazyQuery<IVirtualPhoneNumberData>(GetAllVirtualPhoneNumber, {
    fetchPolicy: 'no-cache',
  });
  const [getUserPracticeLocationAPI] = useLazyQuery<IUserPracticeLocationData>(
    UserPracticeLocationQueries.GetUserPracticeLocations,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getUsersByUserUuidsAPI] = useLazyQuery<{
    accountUsers: {
      users: IUser
    }[]
  }>(
    UserQueries.GetUsersByUserUuids,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const getVirtualPhoneNumberList = async () => {
    const response = await GetAllVirtualPhoneNumberAPI({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid
      }
    });
    return response;
  };

  const getAssigneeIdFromAssigneeCode = (virtualPhoneNumberAssignees: IVirtualPhoneNumberAssignee[], code: string) => {
    const selectedIds: string[] = [];
    virtualPhoneNumberAssignees.forEach((singleNumber) => {
      if (singleNumber?.assigneeId && singleNumber?.assigneeType?.code === code) {
        selectedIds.push(singleNumber?.assigneeId)
      }
    });
    return selectedIds;
  }

  const getUserAndLocationDataForVirtualNumbers = async () => {
    const response = await getVirtualPhoneNumberList();
    if (response?.data) {
      const userUuids = getAssigneeIdFromAssigneeCode(response.data?.virtualPhoneNumberAssignees, VIRTUAL_ASSIGNEE_TYPE_CODES.USER);
      const userData = await getUsersByUserUuidsAPI({
        variables: {
          userUuids
        }
      });
      const accountLocationData = await getUserPracticeLocationAPI();
      updateAssigneeNameInVirtualNumber(response?.data?.virtualPhoneNumberAssignees, accountLocationData?.data?.accountLocations || [], (userData?.data?.accountUsers || [] as any))
    }
  }

  const updateAssigneeNameInVirtualNumber = (virtualPhoneNumber: IVirtualPhoneNumberAssignee[], accountLocationData: IUserPracticeLocation[], userDataList:
    {
      user: IUser;
    }[]
  ) => {
    const virtualPhoneNumberAssignees = [...virtualPhoneNumber];
    virtualPhoneNumberAssignees.forEach((singlePhoneNumber) => {
      singlePhoneNumber.assigneeName = singlePhoneNumber?.assignee;
      if (singlePhoneNumber?.assigneeId) {
        if (singlePhoneNumber.assigneeType?.code === VIRTUAL_ASSIGNEE_TYPE_CODES.USER) {
          for (let index = 0; index < userDataList.length; index++) {
            const singleUser = userDataList[index];
            if (singleUser?.user?.uuid === singlePhoneNumber?.assigneeId) {
              singlePhoneNumber.assigneeName = singleUser?.user?.name;
              break;
            }
          }
        } else if (singlePhoneNumber.assigneeType?.code === VIRTUAL_ASSIGNEE_TYPE_CODES.CLINIC) {
          for (let index = 0; index < accountLocationData.length; index++) {
            const singleLocation = accountLocationData[index];
            if (singleLocation?.uuid === singlePhoneNumber?.assigneeId) {
              singlePhoneNumber.assigneeName = singleLocation?.practiceLocation?.name;
              break;
            }
          }
        }
      }
    });
    //setUserVirtualMobileNo(virtualPhoneNumberAssignees);
  }

  const getAllUsersWithUnreadCount = async () => {
    setConversationFilterState((prev) => {
      return {
        ...prev,
        fetchUserLoading: true,
        internalUsers: [] as AllUsers[],
        mainUsersList: [] as AllUsers[],
      };
    });
    const allUserRes = await getAllAccountUsersWithUnreadMsgCountAPI().catch((err) => {
      if (err?.response?.status === 400) {
        setConversationFilterState((prev) => {
          return {
            ...prev,
            internalUsers: [] as AllUsers[],
            mainUsersList: [] as AllUsers[],
            fetchUserLoading: false,
          };
        });
      }
    });
    if (allUserRes?.data?.users?.length) {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          internalUsers: allUserRes?.data?.users as AllUsers[],
          fetchUserLoading: false,
          mainUsersList: allUserRes?.data?.users as AllUsers[],
        };
      });
    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          internalUsers: [] as AllUsers[],
          fetchUserLoading: false,
          mainUsersList: [] as AllUsers[],
        };
      });
    }
  };

  useEffect(() => {
    if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      getAllUsersWithUnreadCount();
    }
    if (selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS) {
      if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
        if (props?.userFilterData?.filterValue && Object.keys(props?.userFilterData?.filterValue)?.length) {
          const additionalData = props?.userFilterData?.additionalData;
          setConversationFilterState((prev) => {
            return {
              ...prev,
              ...additionalData?.conversationFilterProps,
            };
          });
        }
      } else {
        LocalStorage.getItem('conversationLastFilterSelected')
          .then((response) => {
            if (response) {
              const responseData = JSON.parse(response);
              let conversationFilterState =
                responseData?.[userUuid]?.conversationFilterState;
              if (props.showChooseInbox) {
                if (conversationFilterState.selectedInboxId) {
                  const selectedInbox = smsInboxList.find((smsInbox) => {
                    return smsInbox.id === conversationFilterState.selectedInboxId;
                  });
                  conversationFilterState = {
                    ...conversationFilterState,
                    selectedInboxData: selectedInbox,
                  };
                }
                if (conversationFilterState.emailSelectedInboxId) {
                  const selectedInbox = emailInboxList.find((emailInbox) => {
                    return emailInbox.id ===
                      conversationFilterState.emailSelectedInboxId;
                  });
                  conversationFilterState = {
                    ...conversationFilterState,
                    emailSelectedInboxData: selectedInbox,
                  };
                }
              }
              if (responseData?.[userUuid]) {
                setConversationFilterState((prev) => {
                  return {
                    ...prev,
                    ...conversationFilterState,
                    fetchUserLoading: true
                  };
                });
              }
            } else {
              setConversationFilterState((prev) => {
                return {
                  ...prev,
                  selectedContactType: props?.selectedData?.selectedContactType || 'ALL',
                  selectedDateCode: props?.selectedData?.selectedDateCode || 'ALL',
                  selectedAssignedStatusFilter: defaultSelectedAssignType[0] as IAssigneeStatusType,
                  selectedAssignedFilter: 'ALL',
                  showOnlyMentioned: false,
                };
              });
            }
          }).catch(() => {
            setConversationFilterState((prev) => {
              return {
                ...prev,
                selectedContactType: props?.selectedData?.selectedContactType || 'ALL',
                selectedDateCode: props?.selectedData?.selectedDateCode || 'ALL',
                selectedAssignedStatusFilter: defaultSelectedAssignType[0] as IAssigneeStatusType,
                selectedAssignedFilter: 'ALL',
              };
            });
          })
      }

    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          selectedInboxId:
            props?.selectedData?.selectedInboxId || smsInboxList?.[0]?.id || 0,
          selectedContactType: props?.selectedData?.selectedContactType || 'ALL',
          selectedDateCode: props?.selectedData?.selectedDateCode || 'ALL',
          selectedInboxData: {} as any,
          showOnlyMentioned: false,
        };
      });
    }
  }, [props?.selectedTab]);

  const getHeader = (title: string, icon: any) => {
    return (
      <Box
        justifyContent={'center'}
        borderRadius={8}
        borderColor={'gray.200'}
        borderWidth={1}
        height={44}
        width={'full'}
        backgroundColor={'gray.50'}
      >
        <HStack ml={2} alignItems={'center'} space={2}>
          <Image
            resizeMode="cover"
            style={{width: 18, height: 16}}
            source={icon}
            alt='image'
          />
          <Text fontSize={18} fontWeight={600}>
            {title}
          </Text>
        </HStack>
      </Box>
    );
  };

  const clearAllFilter = () => {
    setConversationFilterState((prev) => {
      return {
        ...prev,
        selectedInboxId: 0,
        selectedContactType: 'ALL',
        selectedDateCode: 'ALL',
        selectedInboxData: {} as any,
        selectedAssignedFilter: 'ALL',
        selectedAssignedStatusFilter: defaultSelectedAssignType[0] as IAssigneeStatusType,
        readType: 'ALL',
        showOnlyMentioned: false,
        fromDate: null,
        toDate: null,
        direction: '',
        isOutOfOfficeHours: '',
        status: '',
        searchQuery: '',
        webPhoneToUserId: '',
        toNumber: '',
        selectedAssignedUserData: {} as Users,
        mentionReadStatus: MENTION_READ_STATUS.ALL,
        selectedAssignedUserDataWeb: [],
      };
    });

    const filterData = getConversationFilterData(
      {
        ...conversationFilterState,
        selectedInboxId: 0,
        selectedContactType: 'ALL',
        selectedDateCode: 'ALL',
        selectedInboxData: {} as any,
        selectedAssignedFilter: '',
        selectedAssignedStatusFilter: {} as IAssigneeStatusType,
        readType: 'ALL',
        showOnlyMentioned: false,
        fromDate: null,
        toDate: null,
        direction: '',
        isOutOfOfficeHours: '',
        status: '',
        searchQuery: '',
        webPhoneToUserId: '',
        toNumber: '',
        selectedAssignedUserData: {} as Users,
        mentionReadStatus: MENTION_READ_STATUS.ALL,
        selectedAssignedUserDataWeb: [],
      },
      selectedTab,
      {
        accountId,
        userUuid,
        groupTypeCodeIdObj,
        id: userData?.id,
      } as any,
      '',
      false,
      isEFaxMessagingEnabled,
      isPatientMessagingEnabled,
      props?.emailInboxUserPreference,
      otherContactTypeId,
      isEmailUserPreferenceEnabled
    );

    const conversationFilterPropsData = {
      selectedContactType: 'ALL',
      selectedDateCode: 'ALL',
      selectedInboxData: {} as any,
      selectedAssignedFilter: '',
      count: [],
    };
    onModalAction(COMMON_ACTION_CODES.RESET_FILTER_TO_DEFAULT, {
      conversationFilterProps: conversationFilterPropsData,
      conversationFilterQuery: filterData,
      filterValue: {}
    });
    if (selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS && selectedTab !== CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      const filterByChannelType = 'conversationLastFilterSelected';
      LocalStorage.removeItem(filterByChannelType);
    }
  };

  const applyFilter = () => {
    const filterData = getConversationFilterData(
      conversationFilterState,
      selectedTab,
      {
        accountId,
        userUuid,
        groupTypeCodeIdObj,
        id: userData?.id,
      } as any,
      conversationFilterState?.selectedAssignedFilter,
      conversationFilterState?.showOnlyMentioned,
      isEFaxMessagingEnabled,
      isPatientMessagingEnabled,
      props?.emailInboxUserPreference,
      otherContactTypeId,
      isEmailUserPreferenceEnabled
    );
    const count = [];

    if (conversationFilterState.showOnlyMentioned) {
      count.push('@mention');
    }
    if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      if (
        conversationFilterState.mentionReadStatus === MENTION_READ_STATUS.READ
      ) {
        count.push('Read');
      } else if (
        conversationFilterState.mentionReadStatus === MENTION_READ_STATUS.UNREAD
      ) {
        count.push('Unread');
      } else {
        count.push('All');
      }
    }

    if (conversationFilterState.readType != 'ALL') {
      count.push('Read type: ' + conversationFilterState.readType);
    }

    if (conversationFilterState.selectedContactType != 'ALL') {
      count.push(
        'Contact type: ' + conversationFilterState.selectedContactType
      );
    }
    if (conversationFilterState?.toDate && conversationFilterState?.fromDate) {
      count.push('Time: ' + getDateStrFromFormat(conversationFilterState.fromDate, DATE_FORMATS.CONVERSATION_DATE_PICKER) + ' to ' + getDateStrFromFormat(conversationFilterState?.toDate, DATE_FORMATS.CONVERSATION_DATE_PICKER));
    } else if (conversationFilterState.selectedDateCode != 'ALL') {
      count.push('Time: ' + conversationFilterState.selectedDateCode);
    }

    if (conversationFilterState.selectedInboxData && JSON.stringify(conversationFilterState.selectedInboxData) != '{}') {
      count.push(
        'SMS Inbox Type: ' + conversationFilterState?.selectedInboxData?.name
      );
    }
    if (conversationFilterState.emailSelectedInboxData && JSON.stringify(conversationFilterState.emailSelectedInboxData) != '{}') {
      count.push(
        'Email Inbox Type: ' + conversationFilterState?.emailSelectedInboxData?.name
      );
    }
    if (false && selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS) {
      if (canShowAssignment()) {
        count.push(
          'Assigned to: ' + conversationFilterState.selectedAssignedFilter
        );
      }

      if (canShowAssignment()) {
        count.push(
          'Assign status: ' + conversationFilterState.selectedAssignedStatusFilter?.label
        );
      }
    }

    if (conversationFilterState.direction?.length) {
      count.push('Direction: ' + conversationFilterState.direction);
    }
    if (conversationFilterState.isOutOfOfficeHours?.length) {
      count.push(
        'Business Hours: ' + conversationFilterState.isOutOfOfficeHours
      );
    }
    if (conversationFilterState.status?.length) {
      count.push('Call Status: ' + conversationFilterState.status);
    }
    if (conversationFilterState.searchQuery?.length) {
      count.push('Phone Type: ' + conversationFilterState.searchQuery);
    }
    if (conversationFilterState.webPhoneToUserId?.length) {
      count.push('Phone Type: ' + conversationFilterState.webPhoneToUserId);
    }
    if (conversationFilterState.toNumber?.length) {
      count.push('Phone Number: ' + conversationFilterState.toNumber);
    }
    if (conversationFilterState.selectedAssignedUserData?.id) {
      count.push('Assigned User: ' + conversationFilterState.selectedAssignedUserData?.name);
    }
    if (conversationFilterState.selectedAssignedUserDataWeb?.length) {
      let assignedUserName = ''
      conversationFilterState.selectedAssignedUserDataWeb?.forEach((item) => {
        if (item.name) {
          assignedUserName = assignedUserName + item.name + ', '
        }
      })
      count.push('Assigned Users: ' + assignedUserName);
    }
    const conversationFilterPropsData: any = {
      selectedContactType: conversationFilterState.selectedContactType,
      selectedDateCode: conversationFilterState.selectedDateCode,
      selectedInboxData: conversationFilterState.selectedInboxData,
      selectedAssignedFilter: conversationFilterState.selectedAssignedFilter,
      selectedAssignedStatusFilter: conversationFilterState.selectedAssignedStatusFilter?.code,
      selectedAssignedByUser: conversationFilterState.selectedAssignedUserData,
      selectedAssignedByUserWeb: conversationFilterState.selectedAssignedUserDataWeb,
      count: count,
    };
    const apiFilterValue: any = {};
    if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      if (conversationFilterState.selectedAssignedUserDataWeb?.length) {
        apiFilterValue.assignedByUserList = conversationFilterState.selectedAssignedUserDataWeb;
        conversationFilterPropsData.selectedAssignedUserDataWeb = conversationFilterState.selectedAssignedUserDataWeb;

      }
      if (conversationFilterState?.toDate && conversationFilterState?.fromDate) {
        conversationFilterPropsData.toDate = conversationFilterState?.toDate;
        conversationFilterPropsData.fromDate = conversationFilterState?.fromDate;
        apiFilterValue.conversationCreatedDateRangeFilter = {
          toDate: conversationFilterState?.toDate,
          fromDate: conversationFilterState?.fromDate
        };
      }

      if (conversationFilterState.selectedDateCode !== 'ALL') {
        apiFilterValue.selectedDateCode = conversationFilterState.selectedDateCode;
      }

    }
    conversationLastFilterSelected(conversationFilterPropsData, filterData, apiFilterValue);
  };

  const conversationLastFilterSelected = async (conversationFilterPropsData: any, filterData: any, filterValue: any) => {
    const storageValue: any = {};
    delete conversationFilterState.internalUsers
    delete conversationFilterState.mainUsersList
    delete conversationFilterState.selectedInboxData
    delete conversationFilterState.emailSelectedInboxData
    delete conversationFilterPropsData.selectedInboxData
    delete conversationFilterPropsData.emailSelectedInboxData
    storageValue[userUuid] = {
      selectedTab: selectedTab,
      conversationFilterState: {...conversationFilterState},
      conversationFilterProps: conversationFilterPropsData,
      conversationFilterQuery: filterData,
    };
    if (selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS && selectedTab !== CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      const filterByChannelType = 'conversationLastFilterSelected';
      LocalStorage.setItem(filterByChannelType, JSON.stringify(storageValue)).then(() => {
        onModalAction(COMMON_ACTION_CODES.FILTER_CHANGED, {
          conversationFilterProps: conversationFilterPropsData,
          conversationFilterQuery: filterData,
          filterValue: filterValue,
        });
      }).catch(() => {
        onModalAction(COMMON_ACTION_CODES.FILTER_CHANGED, {
          conversationFilterProps: conversationFilterPropsData,
          conversationFilterQuery: filterData,
          filterValue: filterValue,
        });
      });
    } else {
      onModalAction(COMMON_ACTION_CODES.FILTER_CHANGED, {
        conversationFilterProps: conversationFilterPropsData,
        conversationFilterQuery: filterData,
        filterValue: filterValue,
      });
    }
  }

  const onInboxChange = (inboxOptionData: any, channelType: string) => {
    const inboxList = channelType === CHANNEL_TYPE_CODE.CHANNEL_EMAIL ? emailInboxList : smsInboxList;
    const selectedInboxAllData = inboxList.filter((item) => {
      return item.value === inboxOptionData
    })
    if (channelType === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      setConversationFilterState((prev: any) => {
        return {
          ...prev,
          emailSelectedInboxData: selectedInboxAllData[0],
          emailSelectedInboxId: selectedInboxAllData[0]?.id || 0
        };
      });
    } else {
      setConversationFilterState((prev: any) => {
        return {
          ...prev,
          selectedInboxData: selectedInboxAllData[0],
          selectedInboxId: selectedInboxAllData[0]?.id || 0
        };
      });
    }
  };

  const onInternalUserChange = (userOptionData: any) => {
    const selectedUserData = conversationFilterState?.mainUsersList?.filter((item) => {
      return userOptionData.includes(item.users?.uuid)
    })
    const selectedAssignedUser: Users[] = []
    selectedUserData?.forEach((item) => {
      if (item?.users?.id) {
        selectedAssignedUser.push(item.users)
      }
    })
    setConversationFilterState((prev: any) => {
      return {
        ...prev,
        selectedAssignedUserDataWeb: selectedAssignedUser
      };
    });
  };

  const canShowContact = () => {
    return (
      selectedTab !== CHANNEL_TYPE_CODE.CHANNEL_INTERNAL &&
      selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS
    );
  };

  const canShowAssignment = () => {
    return (
      selectedTab === CHANNEL_TYPE_CODE.CHANNEL_EMAIL ||
      selectedTab === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
    );
  };

  const canShowChooseNumber = () => {
    return (
      selectedTab === CHANNEL_TYPE_CODE.CALL_LOGS
    );
  };

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day');
  };

  const getRangePickerDefaultValue = (): any => {
    let defaultValue: any[] = [];
    if (conversationFilterState.fromDate && conversationFilterState.toDate) {
      defaultValue = [
        getMomentObjFromFormat(
          conversationFilterState.fromDate,
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        ),
        getMomentObjFromFormat(
          conversationFilterState.toDate,
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        ),
      ];
    } else {
      defaultValue = [];
    }
    return defaultValue;
  };
  const handleOnSearch = (text: string) => {
    if (text && text.length > 0) {
      const result = conversationFilterState?.internalUsers?.filter((item) => {
        return item?.users?.uuid !== userUuid && item?.users?.name?.toLowerCase().includes(text.toLowerCase());
      });
      setConversationFilterState(prev => {
        return {
          ...prev,
          internalUsers: result
        }
      })
    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          internalUsers: prev.mainUsersList
        };
      });
    }
  };
  const onDateRangeChanged = (value: any) => {
    if (value) {
      const fromDateObj = value[0].toISOString();
      const toDateObj = value[1].toISOString();
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fromDate: fromDateObj,
          toDate: toDateObj,
          selectedDateCode: 'ALL',
        };
      });
    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fromDate: '',
          toDate: '',
          selectedDateCode: 'ALL',
        };
      });
    }
  };

  const getDateRangePicker = (): JSX.Element => {
    return (
      <RangePicker
        size="small"
        format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
        allowClear={true}
        suffixIcon
        disabledDate={disabledDate}
        value={getRangePickerDefaultValue()}
        style={{
          width: '99%',
          height: '36px',
          marginRight: 10,
          fontSize: 12,
          borderRadius: 18,
        }}

        placeholder={['Start', 'End']}
        onChange={(val) => {
          onDateRangeChanged(val);
        }}
      />
    );
  };

  const getAvailableVirtualNumbers = () => {
    return (
      <Select
        showSearch={true}
        placeholder="Select Phone Number"
        value={conversationFilterState?.toNumber}
        filterOption={(input: string, option: any) => {
          return (option?.searchString || '')?.toLowerCase?.()?.includes(input?.toLowerCase?.());
        }}
        onChange={(selectedNumber: any) => {
          setConversationFilterState((prev) => {
            return {
              ...prev,
              toNumber: selectedNumber || '',
            };
          });
        }}
        className={'chat-inbox-select'}
      >
        {(props?.userVirtualMobileNo || []).map((singleVirtualNum) => {
          return (
            <Option
              key={singleVirtualNum?.id}
              searchString={`${singleVirtualNum?.virtualPhoneNumber} ${singleVirtualNum?.assigneeName || ''}`}
              value={singleVirtualNum?.virtualPhoneNumber}
            >
              <View flexDirection={'row'}>
                <View style={{padding: 5}}>
                  <PhoneRingSvg />
                </View>
                <View style={{marginLeft: 12}}>
                  <Text fontSize={'14px'} color={Colors.Custom.Gray900}>
                    {singleVirtualNum?.virtualPhoneNumber}
                  </Text>
                  {
                    singleVirtualNum?.assigneeName && (
                      <Text
                        style={{marginTop: 0}}
                        fontSize={'12px'}
                        color={Colors.Custom.Gray500}
                      >
                        {singleVirtualNum?.assigneeName || ''}
                      </Text>
                    )
                  }

                </View>
              </View>
            </Option>
          );
        })}
      </Select>
    );
  };

  const getInboxName = (channelType: string, inboxDisplayList: IInboxOptions[]) => {
    const selectValue =
      channelType === CHANNEL_TYPE_CODE.CHANNEL_EMAIL
        ? conversationFilterState?.emailSelectedInboxData?.name
        : conversationFilterState?.selectedInboxData?.name;
    return (
      <Select
        allowClear
        showSearch={true}
        placeholder="Select Inbox"
        value={selectValue || undefined}
        filterOption={(input: string, option: any) => {
          return (option?.searchString || '')?.toLowerCase?.()?.includes(input?.toLowerCase?.());
        }}
        onChange={(inboxOptionData: any) => {
          onInboxChange(inboxOptionData, channelType);
        }}
        className={'chat-inbox-select'}
        key={`${channelType}`}
      >
        {inboxDisplayList.map((singleVirtualNum) => {
          return (
            <Option
              key={singleVirtualNum?.key}
              searchString={`${singleVirtualNum?.label} ${singleVirtualNum?.name || ''
                }`}
              value={singleVirtualNum?.value}
            >
              <HStack justifyContent={'flex-start'}>
                {channelType === CHANNEL_TYPE_CODE.CHANNEL_EMAIL ? (
                  <View height={'24px'} width={'24px'} style={{padding: 2}}>
                    <EmailActionSvg customStrokeColor={Colors.Custom.Gray400} />
                  </View>
                ) : (
                  <USFlagSVG />
                )}
                <View style={{marginLeft: 5}}>
                  <Text
                    fontWeight={400}
                    fontSize={'14px'}
                    color={'black'}
                    noOfLines={1}
                    flex={1}
                    // width={inboxWidth - 100}
                  >
                    {(channelType === CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                      ? singleVirtualNum?.channelEmail?.email || ''
                      : singleVirtualNum?.channelTwilioSms?.phoneNumber || '') +
                      `${singleVirtualNum?.name
                        ? ' (' + capitalizeText(singleVirtualNum?.name) + ')'
                        : ''
                      }`}
                  </Text>
                </View>
              </HStack>
            </Option>
          );
        })}
      </Select>
    );
  };

  const getInternalUsers = () => {
    return (
      <Select
        size="large"
        allowClear
        style={{width: 520, height: '40px'}}
        showSearch={true}
        mode="multiple"
        placeholder="Select Agents"
        value={
          conversationFilterState?.selectedAssignedUserDataWeb?.map(
            (item) => item?.uuid
          ) || []
        }
        filterOption={false}
        onSearch={debounce(handleOnSearch, 500)}
        onChange={(inboxOptionData: any) => {
          onInternalUserChange(inboxOptionData);
        }}
        loading={conversationFilterState.fetchUserLoading}
        notFoundContent={
          conversationFilterState.fetchUserLoading && <Spin size="small" />
        }
        className={'chat-inbox-select'}
      >
        {conversationFilterState?.internalUsers
          ?.filter((item) => item?.users?.uuid !== userUuid)
          ?.map((singleVirtualNum) => {
            return (
              <Option
                key={singleVirtualNum?.users?.id}
                searchString={`${singleVirtualNum?.users?.name || ''}`}
                value={singleVirtualNum?.users?.uuid}
              >
                {capitalizeText(singleVirtualNum?.users?.name)}
              </Option>
            );
          })}
      </Select>
    );
  };

  return (
    <View>
      <Drawer
        visible={showModal}
        width={isSideCarContext ? '100%' : 600}
        mask={isSideCarContext ? false : true}
        closable={false}
        onClose={() => {
          onModalAction(COMMON_ACTION_CODES.CLOSE_MODAL);
        }}
        title={
          <ModalActionTitle
            title={'filters'}
            titleColor={''}
            buttonList={[
              {
                ...(isSideCarContext && {
                  show: true,
                  id: 1,
                  btnText: 'back',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    onModalAction(COMMON_ACTION_CODES.CLOSE_MODAL);
                  },
                }),
              },
              {
                show: true,
                id: 2,
                btnText: 'resetFilter',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  clearAllFilter();
                },
              },
              {
                show: true,
                id: 1,
                btnText: 'apply',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  applyFilter();
                },
              },
            ]}
          />
        }
      >
        <View mb={4}>
          {false && canShowAssignment() && (
            <View mt={12}>
              {getHeader('Assignment', assignment)}
              <View mt={6} mx={0}>
                <ModalActionRadioSelect
                  data={assignmentData}
                  onChange={(assignedType) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        selectedAssignedFilter: assignedType,
                      };
                    });
                  }}
                  selectedValue={conversationFilterState.selectedAssignedFilter}
                />
              </View>
            </View>
          )}

          {false && canShowAssignment() && (
            <View mt={12}>
              {getHeader('Filter By Status', assignment)}
              <View mt={6} mx={0}>
                <ModalActionRadioSelect
                  data={assignmentStatusData}
                  onChange={(assignedType) => {
                    const selectedAssignType = assignmentStatusData.filter(
                      (item) => {
                        return item.code === assignedType;
                      }
                    );
                    setConversationFilterState((prev: any) => {
                      return {
                        ...prev,
                        selectedAssignedStatusFilter:
                          selectedAssignType[0] as IAssigneeStatusType,
                      };
                    });
                  }}
                  selectedValue={
                    conversationFilterState.selectedAssignedStatusFilter?.code
                  }
                />
              </View>
            </View>
          )}

          {selectedTab === CHANNEL_TYPE_CODE.CHANNEL_MENTION && (
            <View>
              {getHeader('Mentions', mention)}
              <View mt={6} mx={3}>
                <Radio.Group
                  name="mentionReadStatus"
                  value={conversationFilterState.mentionReadStatus}
                  onChange={(value) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        mentionReadStatus: value,
                      };
                    });
                  }}
                >
                  <HStack space={6}>
                    <Radio
                      style={{marginVertical: 10}}
                      value={MENTION_READ_STATUS.UNREAD}
                    >
                      Unread
                    </Radio>
                    <Radio
                      style={{marginVertical: 10}}
                      value={MENTION_READ_STATUS.READ}
                    >
                      Read
                    </Radio>
                    <Radio
                      style={{marginVertical: 10}}
                      value={MENTION_READ_STATUS.ALL}
                    >
                      All
                    </Radio>
                  </HStack>
                </Radio.Group>
              </View>
            </View>
          )}

          {false && selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS && (
            <View mt={12}>
              {getHeader('Mentions', mention)}
              <View mt={6} mx={4}>
                <ModalActionMultiCheckBox
                  data={mentionData}
                  defaultValue={[]}
                  isChecked={conversationFilterState?.showOnlyMentioned}
                  onChange={(value: string[]) => {
                    if (value?.includes('Mentions')) {
                      setConversationFilterState((prev) => {
                        return {
                          ...prev,
                          showOnlyMentioned: true,
                        };
                      });
                    } else {
                      setConversationFilterState((prev) => {
                        return {
                          ...prev,
                          showOnlyMentioned: false,
                        };
                      });
                    }
                  }}
                />
              </View>
            </View>
          )}
          {props.showChooseInbox && (
            <>
              {(selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ALL ||
                selectedTab === CHANNEL_TYPE_CODE.ALL_CALLS) && (
                <View mt={6}>
                  {getHeader('Choose Inbox', inbox)}
                  <View mx={0} mt={3}>
                    {getInboxName(
                      CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
                      smsInboxList
                    )}
                  </View>
                </View>
              )}
              {selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ALL && (
                <View mt={6}>
                  {getHeader('Choose Email Inbox', inbox)}
                  <View mx={0} mt={3}>
                    {getInboxName(
                      CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
                      emailInboxList
                    )}
                  </View>
                </View>
              )}
            </>
          )}
          {false && canShowContact() && (
            <View mt={12}>
              {getHeader('Contact Type', contact)}
              <View mt={6} mx={0}>
                <ModalActionRadioSelect
                  data={contactType}
                  onChange={(selectedContactType) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        selectedContactType: selectedContactType,
                      };
                    });
                  }}
                  selectedValue={conversationFilterState.selectedContactType}
                />
              </View>
            </View>
          )}
          {false && canShowChooseNumber() && (
            <View mt={6}>
              {getHeader('Choose Number', inbox)}
              <View mx={0} mt={3}>
                {getAvailableVirtualNumbers()}
              </View>
            </View>
          )}

          <View mt={6}>
            {getHeader('By Date', date)}
            <View mt={6} mx={0}>
              <ModalActionRadioSelect
                data={dateType}
                onChange={(dateType) => {
                  setConversationFilterState((prev) => {
                    return {
                      ...prev,
                      selectedDateCode: dateType,
                      fromDate: '',
                      toDate: '',
                    };
                  });
                }}
                selectedValue={conversationFilterState.selectedDateCode}
              />
            </View>
            {true && (
              <HStack alignItems={'center'} mt={6}>
                <Divider color={'gray.200'} flex={0.49} />
                <View color={'gray.400'} mx={0}>
                  or
                </View>
                <Divider color={'gray.200'} flex={0.49} />
              </HStack>
            )}
            {true && (
              <HStack mt={6} alignItems={'center'} space={6}>
                {getDateRangePicker()}

                {/* <ModalActionDatePicker
                  label={'fromDate'}
                  format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
                  disabledDate={(current: any) => {
                    if (!conversationFilterState.fromDate && conversationFilterState.toDate) {
                      return current >= conversationFilterState.toDate;
                    } else {
                      return current >= moment();
                    }
                  }}
                  onChange={(date: any) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        fromDate: date,
                      };
                    });
                  }}
                  extraStyle={{flex: 1}}
                  value={conversationFilterState.fromDate && moment(conversationFilterState.fromDate)}
                />
                <ModalActionDatePicker
                  label={'toDate'}
                  format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
                  disabledDate={(current: any) => {
                    if (conversationFilterState.fromDate && !conversationFilterState.toDate) {
                      return current.diff(conversationFilterState.fromDate, 'days') >= 0;
                    } else {

                      return current >= moment()
                    }

                  }}
                  onChange={(date: any) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        toDate: date,
                      };
                    });
                  }}
                  extraStyle={{flex: 1}}
                  value={conversationFilterState.toDate && moment(conversationFilterState.toDate)}
                /> */}
              </HStack>
            )}
          </View>

          {selectedTab === CHANNEL_TYPE_CODE.CALL_LOGS && (
            <View>
              {false && (
                <View mt={12}>
                  {getHeader('Direction', contact)}
                  <View mt={6} mx={0}>
                    <ModalActionRadioSelect
                      data={directions}
                      onChange={(direction) => {
                        setConversationFilterState((prev) => {
                          return {
                            ...prev,
                            direction: direction,
                          };
                        });
                      }}
                      selectedValue={conversationFilterState.direction}
                    />
                  </View>
                </View>
              )}
              <View mt={6}>
                {getHeader('Business Hours', contact)}
                <View mt={6} mx={0}>
                  <ModalActionRadioSelect
                    data={outOfOfficeHours}
                    onChange={(isOutOfOfficeHours) => {
                      setConversationFilterState((prev) => {
                        return {
                          ...prev,
                          isOutOfOfficeHours: isOutOfOfficeHours,
                        };
                      });
                    }}
                    selectedValue={conversationFilterState.isOutOfOfficeHours}
                  />
                </View>
              </View>
              {false && (
                <View mt={12}>
                  {getHeader('Call Status', contact)}
                  <View mt={6} mx={0}>
                    <ModalActionRadioSelect
                      data={callStatus}
                      onChange={(status) => {
                        setConversationFilterState((prev) => {
                          return {
                            ...prev,
                            status: status,
                          };
                        });
                      }}
                      selectedValue={conversationFilterState.status}
                    />
                  </View>
                </View>
              )}
              <View mt={6}>
                {getHeader('Phone Type', contact)}
                <View mt={6} mx={0}>
                  <ModalActionRadioSelect
                    data={phoneTypes}
                    onChange={(selectedPhoneType) => {
                      let searchQuery = '';
                      let webPhoneToUserId = '';
                      if (
                        selectedPhoneType ===
                          PHONE_TYPE_CONST.MY_PHONE_NUMBER &&
                        props?.userVirtualMobileNo?.length
                      ) {
                        searchQuery =
                          props?.userVirtualMobileNo[0].virtualPhoneNumber;
                      } else if (
                        selectedPhoneType === PHONE_TYPE_CONST.SOFT_PHONE_NUMBER
                      ) {
                        webPhoneToUserId = userData.uuid;
                      }
                      setConversationFilterState((prev) => {
                        return {
                          ...prev,
                          searchQuery: searchQuery,
                          webPhoneToUserId: webPhoneToUserId,
                        };
                      });
                    }}
                    selectedValue={
                      conversationFilterState.searchQuery !== ''
                        ? PHONE_TYPE_CONST.MY_PHONE_NUMBER
                        : conversationFilterState.webPhoneToUserId !== ''
                        ? PHONE_TYPE_CONST.SOFT_PHONE_NUMBER
                        : ''
                    }
                  />
                </View>
              </View>
            </View>
          )}

          {selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS && false && (
            <View mt={12}>
              {getHeader('Type', contact)}
              <View mt={6} mx={0}>
                <ModalActionRadioSelect
                  data={readType}
                  onChange={(readType) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        readType: readType,
                      };
                    });
                  }}
                  selectedValue={conversationFilterState.readType}
                />
              </View>
            </View>
          )}

          {false && (
            <View mt={12}>
              {getHeader('Status', status)}
              <View mt={6} mx={0}>
                <ModalActionMultiCheckBox data={statusData} defaultValue={[]} />
              </View>
            </View>
          )}

          {false && (
            <View mt={12}>
              {getHeader('Message Type', message)}
              <View mt={6} mx={0}>
                <ModalActionMultiCheckBox
                  data={MessageType}
                  defaultValue={[]}
                />
              </View>
            </View>
          )}

          {canShowAssignedUsers(selectedTab) && (
            <View mt={6}>
              {getHeader('Assigned Users', contact)}
              <View mt={6} mx={0}>
                {conversationFilterState.fetchUserLoading ? (
                  <Skeleton.Text lines={2} />
                ) : (
                  getInternalUsers()
                )}
              </View>
            </View>
          )}
        </View>
      </Drawer>
    </View>
  );
};

export default React.memo(ConversationFilter);
