import {View} from 'native-base';
import {useRoutes} from 'react-router-dom';
import {getBusinessStudioRoutes} from '../../routes/BusinessStudioRoutes';
import { wrapUseRoutes } from "@sentry/react";
const RightSideContainer = (props: {
  showScrollIndicator: boolean
}) => {
  // const useSentryRoutes = wrapUseRoutes(useRoutes);

  if (props?.showScrollIndicator) {
    import('./scrollIndicatorStyle.css')
  }
  const businessStudioRoutesElement = useRoutes(getBusinessStudioRoutes());

  return <View flex={1}>{businessStudioRoutesElement}</View>;
};

export default RightSideContainer;
