import { Icon, Text, VStack } from 'native-base';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { IAllowedOperation } from '../../../../../../../Interfaces';
import { BUTTON_TYPE } from '../../../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../../../constants/ActionConst';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { Colors } from '../../../../../../../styles';
import { findIsAllowToRunAutomation } from '../../../../../../../utils/commonUtils';
import { getContactTypeId } from '../../../../../../../utils/mlovUtils';
import { CustomMenuItem } from '../../../../../../common/CustomMenuItem/CustomMenuItem';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import AutomationSvg from '../../../../../../common/Svg/GlobalActionSvg/AutomationSvg';
import CampaignSvg from '../../../../../../common/Svg/GlobalActionSvg/CampaignSvg';
import GroupSvg from '../../../../../../common/Svg/GroupSvg';
import EmailActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import PlusIcon from '../../../../../../common/Svg/PlusSvg';
import ThreeDotsSvg from '../../../../../../common/Svg/ThreeDotsSvg';
import { ITableTopBarProps } from '../../../../../../common/TableTopBar/Interfaces';

export const getLeadListTopBarButtons = (
  buttonClickFnList: (() => void)[],
  onActionPerformed: (actioncode: any, rawData: any) => void,
  title: string,
  contactType: string,
  selectedTags: any[],
  filterPropsWithCode?: {
    [index: string]: any;
  },
  patientOrgAbilites?: IAllowedOperation
): ITableTopBarProps => {
  const mlovData = useContext(CommonDataContext);
  const patientTypeUuid = getContactTypeId('CUSTOMER');
  const prospectTypeUuid = getContactTypeId('LEAD');

  const filterByPracticeLocationProps =
    (filterPropsWithCode &&
      filterPropsWithCode['FILTER_BY_PRACTICE_LOCATION']) ||
    {};
  const canCreate = () => contactType === 'CUSTOMER' ? !!patientOrgAbilites?.canCreate : true;
  const btnList = [
    {
      id: 4,
      btnText: 'bulkImport',
      borderColor: Colors.Custom.mainPrimaryPurple,
      size: 'sm',
      colorScheme: '',
      textColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: Colors.Custom.buttonWhiteBg,
      style: {
        paddingHorizontal: 16
      },
      variant: '',
      contactType: contactType,
      isTransBtn: true,
      btnType: 'IMPORT',
      leftIcon: (
        <Icon
          as={AntIcon}
          color={"#6941C7"}
          name={'download'}
          size="3"
          width={10}
          height={10}
        />
      ),
      btnClick: () => {
        buttonClickFnList[0]();
      },
    }];

    if(canCreate()){
      btnList.push({
        id: 5,
        btnText: 'addNew',
        size: 'smBold',
        textColor: Colors.Custom.mainPrimaryPurple,
        borderColor: Colors.Custom.mainPrimaryPurple,
        backgroundColor: Colors.Custom.buttonWhiteBg,
        variant: '',
        leftIcon: <PlusIcon />,
        btnClick: () => {
          const initialContactType = {
            id: 1,
            contactType: contactType,
            contactTypeUuid:
              contactType && contactType === 'CUSTOMER'
                ? patientTypeUuid
                : prospectTypeUuid,
            label:
              contactType && contactType == 'CUSTOMER'
                ? 'Add Members'
                : 'Add Lead',
          };
          onActionPerformed(COMMON_ACTION_CODES.ADD, initialContactType);
        },
      } as any);
    }
  return {
    title: title,
    buttonList: btnList,
  };
};

export const getBulkActionTopBarButton = (
  buttonClickFnList: (() => void)[],
  onActionPerformed: (actioncode: any, rawData: any) => void,
  title: string,
  showAutomationOption?: boolean
): ITableTopBarProps => {
  const intl = useIntl();
  const emailActionList =[
    {
      displayString: 'email',
      actionCode: 0,
      icon: <EmailActionSvg customStrokeColor={Colors.Custom.Gray500}/>,
    },
    {
      displayString: 'sms',
      actionCode: 1,
      icon: <SmsActionSvg customStrokeColor={Colors.Custom.Gray500}/>
    },
  ];

  const createActionList =[
    {
      displayString: 'group',
      actionCode: 5,
      icon: <GroupSvg/>,
    },
    {
      displayString: 'campaign',
      actionCode: 3,
      icon: <CampaignSvg />
    },
  ];

  const addSmsActionView = (isCreate: boolean) =>{
    return (
      <VStack>

        <Text
          fontSize={'12px'}
          fontWeight={500}
          style={{marginTop: 4, color: Colors.Custom.Gray400}}
        >
          {intl.formatMessage({id: 'send'}).toUpperCase()}
        </Text>

        {emailActionList.map((action, index) => {
          return (
            <CustomMenuItem
              label={intl.formatMessage({id: action.displayString})}
              onClick={() => {
                buttonClickFnList[action.actionCode]();
              }}
              icon={action.icon}
            ></CustomMenuItem>
          );
        })}
        <Text
          fontSize={'12px'}
          fontWeight={500}
          style={{marginTop: 16, color: Colors.Custom.Gray400}}
        >
          {intl.formatMessage({id: 'create'}).toUpperCase()}
        </Text>
        {createActionList.map((action) => {
          return (
            <CustomMenuItem
              key={action.actionCode}
              label={intl.formatMessage({id: action.displayString})}
              onClick={() => {
                buttonClickFnList[action.actionCode]();
              }}
              icon={action.icon}
            ></CustomMenuItem>
          );
        })}
      </VStack>
    );
  }

  return {
    title: title,
    buttonList: [
      ...(showAutomationOption && findIsAllowToRunAutomation()
        ? [
            {
              btnText: 'runAutomation',
              size: 'smBold',
              textColor: Colors.Custom.mainPrimaryPurple,
              borderColor: Colors.Custom.mainPrimaryPurple,
              backgroundColor: Colors.Custom.buttonWhiteBg,
              variant: '',
              isRightDividerNotVisible: false,
              leftIcon: (
                <AutomationSvg customStrokeColor={Colors.Custom.mainPrimaryPurple}/>
              ),
              style: {
                paddingHorizontal: 16
              },
              btnClick: () => {
                buttonClickFnList[4]();
              },
            },
          ]
        : []),
      {
        id: 100,
        dropDownType: 'Popover',
        btnText: '',
        size: 'smBold',
        textColor: Colors.Custom.mainPrimaryPurple,
        style: {
          paddingHorizontal: 0, minWidth: 'auto',
        },
        borderColor: Colors.Custom.mainPrimaryPurple,
        backgroundColor: Colors.Custom.buttonWhiteBg,
        variant: BUTTON_TYPE.NO_BORDER,
        rightIcon: (
          <ThreeDotsSvg></ThreeDotsSvg>
        ),
        withRightBorder: true,
        isRightDividerNotVisible: false,
        content: addSmsActionView(false),
        btnClick: () => {
          // btnClick
        },
      },
      {
        btnText: '',
        size: 'smBold',
        textColor: Colors.Custom.mainPrimaryPurple,
        borderColor: Colors.Custom.mainPrimaryPurple,
        backgroundColor: Colors.Custom.buttonWhiteBg,
        variant: BUTTON_TYPE.NO_BORDER,
        isRightDividerNotVisible: false,
        style: {
          paddingHorizontal: 0,
        },
        rightIcon: (
          <CrossIconSvg/>
        ),
        btnClick: () => {
          buttonClickFnList?.[2]?.();
        },
      }
    ],
  };
};
