import {debounce} from 'lodash';
import {Text, View} from 'native-base';
import React from 'react';
import {Dimensions, FlatList} from 'react-native';
import {
  IPageSection,
  IRowsByAssignee,
  ITaskDelayOverViewDrillDown,
  RESOURCE_LIST,
} from '../../interface';
import {secondsToHhMm} from '../UtilsConst';

const TaskDelayOverViewDrilLDown = (props: {
  rowsByAssignee: ITaskDelayOverViewDrillDown[];
  pageSection: IPageSection;
  fetchMoreItems?: () => void;
  loadingView?: JSX.Element;
  isDrillDown?: boolean;
  isLoading?: boolean;
  isEndReached?: boolean;
}) => {
  const {
    pageSection,
    rowsByAssignee,
    isDrillDown,
    loadingView,
    fetchMoreItems,
    isLoading,
    isEndReached,
  } = props;
  const height = Dimensions.get('window').height * 0.69;
  const isPoolType =
    pageSection.resource.code ===
    RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL;
  const containerRef: any = React.useRef(null);
  const handleEndReached = debounce(() => {
    if (!isLoading && !isEndReached) {
      fetchMoreItems?.();
    }
  }, 500);
  return (
    <View flex={0.5} style={{paddingLeft: 4}}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 0.5,
          borderColor: '#D0D6E1',
          borderStyle: 'solid',
        }}
      >
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '40%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          {isPoolType ? 'TASK POOL' : 'USERS'}
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '16%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          COMPLETED
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '14%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          ON TIME
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '15%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          DELAY
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '15%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          AVG. DELAY
        </Text>
      </View>
      <FlatList
        style={{
          maxHeight: isDrillDown ? height : 'auto',
        }}
        data={rowsByAssignee || []}
        renderItem={({item}: any) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              size={'smMedium'}
              color={'#3A485F'}
              style={{
                width: '40%',
                paddingHorizontal: 12,
                paddingVertical: isDrillDown ? 8 : 4,
              }}
              lineBreakMode="clip"
            >
              {item?.assigneeName || item?.userPoolName || '--'}
            </Text>
            <Text
              size={'smMedium'}
              color={'#3A485F'}
              style={{
                width: '16%',
                paddingHorizontal: 12,
                paddingVertical: 3,
              }}
            >
              {item.completedTasksCount}
            </Text>
            <Text
              size={'smMedium'}
              color={'#3A485F'}
              style={{
                width: '14%',
                paddingHorizontal: 12,
                paddingVertical: 3,
              }}
            >
              {(item.completedTasksCount || 0) -
                (item.afterDueDateCompletedTasksCount || 0)}
            </Text>
            <Text
              size={'smMedium'}
              color={'#3A485F'}
              style={{
                width: '15%',
                paddingHorizontal: 12,
                paddingVertical: 3,
              }}
            >
              {item.afterDueDateCompletedTasksCount}
            </Text>
            <Text
              size={'smMedium'}
              color={'#3A485F'}
              style={{
                width: '15%',
                paddingHorizontal: 12,
                paddingVertical: 3,
              }}
            >
              {secondsToHhMm(item?.completionTimeDelayInSecondsAverage || 0)}
            </Text>
          </View>
        )}
        keyExtractor={(item: any, index: number) =>
          `${item.assigneeId || item?.userPoolId}_${index}`
        }
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.5}
        ListFooterComponent={loadingView}
      />
    </View>
  );
};

export default TaskDelayOverViewDrilLDown;
