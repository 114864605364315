import { RouteObject } from 'react-router-dom';
import { IUserPermission, IUserRoleCode } from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import {
  getCurrentUserRole,
  getUserPermissions,
  IS_FEATURE_FLAG_ENABLED
} from '../../../utils/commonUtils';
import {
  MAIN_MENU_CODES,
  SIDE_MENU_CONST,
  USER_ROLE_SIDE_BAR_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE
} from '../../SideMenuBar/SideBarConst';
import { UserRolePermission } from './UserPermissionConst';

export const USER_ACCESS_PERMISSION = {
  DEFAULT_PERMISSION_CODE: '1111',
  ALL_PERMISSION_CODE: '1111',
  NO_PERMISSION_CODE: '0000',
  ENTITY: {
    DASHBOARD_WINDOW: {
      code: 'DASHBOARD_WINDOW',
    },
    ADMIN_PANEL_WINDOW: {
      code: 'ADMIN_PANEL_WINDOW',
    },
  },
};

export const isAllPermission = (permissionCode: string) => {
  return permissionCode === USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE;
};

export const getAllPermissionCode = () => {
  return USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE;
};

export const getNonePermissionCode = () => {
  return USER_ACCESS_PERMISSION.NO_PERMISSION_CODE;
};

export const getDefaultPermissionCode = () => {
  return USER_ACCESS_PERMISSION.DEFAULT_PERMISSION_CODE;
};
const getLastPermissionCreated = (currentUserPermission: IUserPermission[]) => {
  return currentUserPermission.reduce((latest, current) => {
      const latestDate = new Date(latest.createdAt);
      const currentDate = new Date(current.createdAt);
      return latestDate > currentDate ? latest : current;
  });
}

const getGreatestPermssion = (currentUserPermission: IUserPermission[]) => {
 const permissions = currentUserPermission.filter(permission => {
    return isAllPermission(permission.permissionCode)
  })
  return permissions.length ? permissions?.[0]?.permissionCode: USER_ACCESS_PERMISSION.NO_PERMISSION_CODE
}

export const getUserPermissionByEntityAndActionCodeWithCurrentUserPermissionMap =
  (entityCode: any, actionCode: any, currentUserPermissionMap: any) => {
    if (
      entityCode &&
      actionCode &&
      currentUserPermissionMap[entityCode] &&
      actionCode in currentUserPermissionMap[entityCode]
    ) {
      if (
        currentUserPermissionMap[entityCode][actionCode].total ==
        currentUserPermissionMap[entityCode][actionCode].userPermissions.length
      ) {
        return currentUserPermissionMap[entityCode][
          actionCode
        ].userPermissions.some((permissionData: any) => {
          return isAllPermission(permissionData.permissionCode);
        });
      } else if (currentUserPermissionMap?.[entityCode]?.[actionCode]?.userPermissions?.length > 1) {
        const currentUserPermission = currentUserPermissionMap[entityCode][actionCode].userPermissions;
        const lastPermissionCreated = getGreatestPermssion(currentUserPermission);
        return !(lastPermissionCreated === USER_ACCESS_PERMISSION.NO_PERMISSION_CODE);
      } else {
        return isAllPermission(getDefaultPermissionCode());
      }
    } else {
      return isAllPermission(getDefaultPermissionCode());
    }
  };

export const getUserPermissionByEntityAndActionCode = (
  entityCode: any,
  actionCode: any
) => {
  const currentUserPermissionMap: any = getCurrentUserPermissionMap();

  return getUserPermissionByEntityAndActionCodeWithCurrentUserPermissionMap(
    entityCode,
    actionCode,
    currentUserPermissionMap
  );
};

export const setUserRoleSideBarCodesPermissionWise = (
  defaultCurrentUserRole: IUserRoleCode[],
  defaultUserPermissions: IUserPermission[]
) => {
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.ADMIN =
    filterSideBarTabByRolePermission(
      USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      defaultCurrentUserRole,
      defaultUserPermissions
    );

  if (!IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP')) {
    USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.ADMIN =
      USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.ADMIN.filter((item) => {
        return item != MAIN_MENU_CODES.MEMBERSHIPS;
      });
  }
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER =
    filterSideBarTabByRolePermission(
      USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      defaultCurrentUserRole,
      defaultUserPermissions
    );
};

export const isLeadPath = (path:string) : boolean => {
  return path.includes('members/:memberType')
}

export const getEntityCodeAndActionCodeFromPath = (
  path: string | undefined
) => {
  let actionCode;
  let entityCode;
  
  const adminPanelSideMenuList = SIDE_MENU_CONST.filter((sideMenu) => {
    return sideMenu.path.indexOf('admin') != -1;
  });
  const dashboardSideMenuList = SIDE_MENU_CONST.filter((sideMenu) => {
    return sideMenu.path.indexOf('admin') == -1;
  });

  if (path) {
    const requestSubPathList = (path || '').trim().split('/');
    if (path.indexOf('admin') == -1) {
      entityCode = USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code;
      dashboardSideMenuList.some((sideMenuData) => {
        const sideSubPathList = sideMenuData.path.split('/');
        if (
          sideSubPathList[0] == requestSubPathList[1] &&
          requestSubPathList[1]
        ) {
          actionCode = sideMenuData.menuCode;
          if(actionCode === MAIN_MENU_CODES.LEAD || actionCode === MAIN_MENU_CODES.CONSUMER){
            actionCode = isLeadPath(path) ?   MAIN_MENU_CODES.LEAD : MAIN_MENU_CODES.CONSUMER
          }
          return true;
        }
      });
    } else {
      entityCode = USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code;
      adminPanelSideMenuList.some((sideMenuData) => {
        const sideSubPathList = sideMenuData.path.split('/');
        if (
          (sideSubPathList[1] == requestSubPathList[2] &&
            requestSubPathList[2]) ||
          requestSubPathList.length == 1
        ) {
          actionCode = sideMenuData.menuCode;
          if(actionCode === MAIN_MENU_CODES.LEAD || actionCode === MAIN_MENU_CODES.CONSUMER){
            actionCode = isLeadPath(path) ?   MAIN_MENU_CODES.LEAD : MAIN_MENU_CODES.CONSUMER
          }
          return true;
        }
      });
    }
  }
  return {entityCode, actionCode};
};

export const isPathValidForUserRolePermission = (path: string) => {
  if (path == '/' || path == '/admin') {
    return true;
  }
  const {entityCode, actionCode} = getEntityCodeAndActionCodeFromPath(path);
  return getUserPermissionByEntityAndActionCode(entityCode, actionCode);
};

export const filterRoutByUserRolePermission = (
  allRoute: RouteObject[]
): RouteObject[] => {
  let filteredRoute = allRoute.filter((routeData) => {
    return isPathValidForUserRolePermission(routeData?.path || '/');
  });
  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );
  if (!showAutomationTab) {
    filteredRoute = filteredRoute.filter(
      (item) => !item.path?.includes('/admin/:tabName/automation/create')
    );
  }
  return filteredRoute;
};

const getCurrentUserPermissionMap = (
  defaultCurrentUserRole?: IUserRoleCode[],
  defaultUserPermissions?: IUserPermission[]
) => {
  const currentUserRoles = getCurrentUserRole();
  const currentUserPermissions = getUserPermissions();
  const currentUserRole: IUserRoleCode[] = defaultCurrentUserRole
    ? defaultCurrentUserRole
    : currentUserRoles;
  const userPermissions: IUserPermission[] = defaultUserPermissions
    ? defaultUserPermissions
    : currentUserPermissions;

  const currentUserPermissionMap: any = {};
  userPermissions.forEach((userPermission: IUserPermission) => {
    const actionCode = userPermission.actionCode;
    const entityCode = userPermission.entityCode;

    const userRoleCode = userPermission.userRoleCode;
    currentUserRole.forEach((role: IUserRoleCode) => {
      if (userRoleCode == role.code) {
        if (!currentUserPermissionMap[entityCode]) {
          currentUserPermissionMap[entityCode] = {};
        }
        if (!currentUserPermissionMap[entityCode][actionCode]) {
          currentUserPermissionMap[entityCode][actionCode] = {
            total: currentUserRole.length,
            userPermissions: [],
          };
        }
        currentUserPermissionMap[entityCode][actionCode].userPermissions.push({
          permissionCode: userPermission.permissionCode,
          userRoleCode: userRoleCode,
          createdAt: userPermission?.createdAt
        });
      }
    });
  });

  return currentUserPermissionMap;
};

export const filterSideBarTabByRolePermission = (
  tabCode: string,
  defaultCurrentUserRole?: IUserRoleCode[],
  defaultUserPermissions?: IUserPermission[]
): string[] => {
  const tabCodList =
    tabCode == USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code
      ? USER_ROLE_SIDE_BAR_CODES.ADMIN
      : USER_ROLE_SIDE_BAR_CODES.USER;
  const currentUserPermissionMap: any = getCurrentUserPermissionMap(
    defaultCurrentUserRole,
    defaultUserPermissions
  );
  const isEmployer = !!defaultCurrentUserRole?.find(
    (item) => item.code == 'EMPLOYER'
  );
  const allowedTabCodList = tabCodList?.filter((actionCode) => {
    return getUserPermissionByEntityAndActionCodeWithCurrentUserPermissionMap(
      tabCode,
      actionCode,
      currentUserPermissionMap
    );
  });
  if (isEmployer) {
    if (allowedTabCodList.includes('LEADS')) {
      allowedTabCodList.splice(allowedTabCodList.indexOf('LEADS'), 1);
    }
    if (allowedTabCodList.includes('SETTING')) {
      allowedTabCodList.splice(allowedTabCodList.indexOf('SETTING'), 1);
    }
  }
  if (!isEmployer) {
    return allowedTabCodList.filter(
      (item) => item !== MAIN_MENU_CODES.ANALYTICS_EMPLOYER
    );
  } else {
    return allowedTabCodList;
  }
};

export const isAddMemberAllowed = () => {
  return getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
};

export const isPatientDetailViewAllowed = (defaultCurrentUserRole?: IUserRoleCode[],
  defaultUserPermissions?: IUserPermission[]) => {
  const currentUserPermissionMap: any = getCurrentUserPermissionMap(defaultCurrentUserRole,defaultUserPermissions);
  return getUserPermissionByEntityAndActionCodeWithCurrentUserPermissionMap(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.PATIENT_DETAILS,
    currentUserPermissionMap
  );
};

export const isAllowedToSignEHRNotesByRole = (
  defaultCurrentUserRole?: IUserRoleCode[],
  defaultUserPermissions?: IUserPermission[]
) => {
  const currentUserPermissionMap: any = getCurrentUserPermissionMap(
    defaultCurrentUserRole,
    defaultUserPermissions
  );

  return getUserPermissionByEntityAndActionCodeWithCurrentUserPermissionMap(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.SIGN_AMEND_EHR_NOTE,
    currentUserPermissionMap
  );
};

export const isAnalyticsAllowed = () => {
  return getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.ANALYTICS
  );
};

export const isAddEmployerAllowed = () => {
  return getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.EMPLOYER
  );
};

export const isMessageSettingAllowed = () => {
  return getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.COMMUNICATION
  );
};

export const addOrUpdateAllSettingTabPermissions = async (args: {
  actionCode: string;
  roleCode: string;
  accountUuid: string;
  permissionCode: string;
}) => {
  const {accountUuid, actionCode: actionCode, roleCode, permissionCode} = args;
  if (actionCode !== MAIN_MENU_CODES.SETTING) {
    return;
  }
  const postObject = getRolePermissionArrayForSingleRole({
    accountUUID: accountUuid,
    entityCode: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    permissionCode,
    roleCode: roleCode,
  });
  const axios = BaseService.getSharedInstance().axios;
  axios
    .post('crm-nest/add_or_update_role_code_permissions', {data: postObject})
    .catch((error) => {

    });
};

export const getRolePermissionArrayForSingleRole = (args: {
  entityCode: string;
  roleCode: string;
  permissionCode: string;
  accountUUID: string;
}): UserRolePermission[] => {
  const {entityCode, roleCode, accountUUID, permissionCode} = args;
  const actionCodes =
    entityCode == USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
      ? USER_ROLE_SIDE_BAR_CODES.USER
      : USER_ROLE_SIDE_BAR_CODES.ADMIN;
  return actionCodes.map((item) => {
    return {
      accountUUID: accountUUID,
      actionCode: item,
      entityCode: entityCode,
      permissionCode: permissionCode,
      userRoleCode: roleCode,
    };
  });
};
