import React, {useEffect, useState} from 'react';
import {Drawer, Select} from 'antd';
import AppointmentTypeSearch from '../../../../../AppointmentTypeSearch/AppointmentTypeSearch';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {Colors} from '../../../../../../../styles';
import {ModalActionTitle} from '../../../../../ModalActionTitle/ModalActionTitle';
import {ContentTypes} from '../../../../../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getCategoryQueryString,
  getFormattedEmailTemplateData,
} from '../../../../../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import {
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates,
} from '../../../../../../RightSideContainer/ContentManagement/ContentManagementUtils';
import {EmailApiData} from '../../../../../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesListView/interfaces';
import {HStack, Skeleton, Spinner, Text, VStack, View} from 'native-base';
import parse from 'html-react-parser';
import {IEmailTemplateData} from '../../../../../../RightSideContainer/ContentManagement/EmailTemplates';
import {Liquid} from 'liquidjs';
import {ICarePlan} from '../../../../../EmailPopupView/EmailPopupViewUtils';
import {ITemplateCategory} from '../../../../../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { IAppoinmentDataConfig } from '../../interface';

export interface IEmailAppointmentLinkDrawerProps {
  isVisble: boolean;
  onClose: () => void;
  onSend: (data: IAppoinmentDataConfig) => void;
}

const EmailAppointmentLinkDrawer = (
  props: IEmailAppointmentLinkDrawerProps
) => {
  const {isVisble, onClose, onSend} = props;
  const [componentState, setComponentState] = useState<{
    isLoading: boolean;
    appointmentData: any;
    emailTemplateList: IEmailTemplateData[];
    selectedTemplate?: IEmailTemplateData;
    categoryList: ITemplateCategory[];
  }>({
    isLoading: false,
    appointmentData: {},
    emailTemplateList: [],
    categoryList: [],
  });
  const accountMergeTags = getAccountMergeTagData();
  const onChangeHandler = (data: any) => {
    setComponentState((prev) => ({...prev, appointmentData: data}));
  };

  const confirmationbuttonList = [
    {
      show: true,
      id: 1,
      btnText: 'Cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => onClose(),
    },
    {
      show: true,
      id: 2,
      btnText: 'Send Link',
      isTransBtn: false,
      onClick: () =>
        onSend({
          appointmentTypeId: componentState?.appointmentData?.id,
          appointmentUsers: componentState?.appointmentData?.appointmentUsers,
          availabilityTypeCode:
            componentState?.appointmentData?.availabilityTypeCode,
          templateId: componentState?.selectedTemplate?.id || '',
        }),
      isLoading: false,
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
    },
  ];

  const getEmailTemplates = async () => {
    setComponentState((prev) => ({...prev, isLoading: true}));
    const categoryResponse = await getTemplateCategories();
    const categoryList = getTemplateCategoryList(categoryResponse);
    const path = `${ContentTypes.emails.path}?${getCategoryQueryString(
      categoryList,
      ['APPOINTMENT_BOOKING_LINK']
    )}`;

    const emailResponse: {
      data: EmailApiData[];
    } = await getTemplates(path);
    const formmatedEmailList = getFormattedEmailTemplateData(emailResponse);
    setComponentState((prev) => ({
      ...prev,
      emailTemplateList: formmatedEmailList,
      isLoading: false,
      selectedTemplate: formmatedEmailList[0],
      categoryList: categoryList,
    }));
  };

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {...mergeTagsByCategory, global: accountMergeTags};
  };

  const getPreviewHtml = (
    templateData: IEmailTemplateData,
    richText: string,
    carePlan?: ICarePlan
  ): string => {
    const engine = new Liquid();
    const mergeTags: Record<string, any> = {
      general: {
        text: richText,
      },
    };
    mergeTags['patient'] = {
      firstName: 'John',
    };
    if (carePlan) {
      mergeTags['careplan'] = {
        name1: carePlan.title,
        description1: carePlan.description,
        price1: carePlan.price,
      };
    }
    const finalMergeTag = {
      ...getMergeTags(
        templateData?.templateCategory,
        componentState.categoryList
      ),
      ...mergeTags,
    };
    const tpl = engine.parse(templateData?.templateHtml || '');
    const html = engine.renderSync(tpl, finalMergeTag);
    return engine.renderSync(tpl, finalMergeTag);
  };

  useEffect(() => {
    getEmailTemplates();
  }, []);

  return (
    <Drawer
      width={'50%'}
      onClose={onClose}
      open={isVisble}
      title={
        <ModalActionTitle
          title={'Appointment Type'}
          buttonList={confirmationbuttonList}
        />
      }
    >
      {componentState.isLoading && (
        <VStack mt={2}>
          <Spinner size={'lg'} />
        </VStack>
      )}
      {!componentState.isLoading && (
        <>
          <AppointmentTypeSearch
            isShowError={false}
            onChange={onChangeHandler}
            allowUserMultiSelect
          />
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Email Template'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7}>
              <View flex={1} mt={2}>
                <Select
                  placeholder="Select Category"
                  value={componentState.selectedTemplate?.id}
                  onChange={(value) =>
                    setComponentState((prev) => ({
                      ...prev,
                      selectedTemplate: componentState.emailTemplateList.find(
                        (item) => item.id === value
                      ),
                    }))
                  }
                >
                  {componentState.emailTemplateList.map((template) => (
                    <Select.Option key={template.id} value={template.id}>
                      {template.name}
                    </Select.Option>
                  ))}
                </Select>
              </View>
            </HStack>
          </HStack>

          {componentState.selectedTemplate && (
            <View flex={1} mt={5}>
              {parse(
                getPreviewHtml(
                  componentState.selectedTemplate as IEmailTemplateData,
                  ''
                )
              )}
            </View>
          )}
        </>
      )}
    </Drawer>
  );
};

export default EmailAppointmentLinkDrawer;
