import React from 'react';
import {Defs, LinearGradient, Path, Stop, Svg} from 'react-native-svg';

function LowPriorityIcon() {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7225 8.19533C13.5452 8.07034 13.3048 8.00013 13.0541 8.00013C12.8034 8.00013 12.563 8.07034 12.3857 8.19533L8.32697 11.0574L4.26825 8.19533C4.08994 8.07389 3.85112 8.00669 3.60323 8.00821C3.35534 8.00973 3.11822 8.07984 2.94293 8.20345C2.76764 8.32706 2.66821 8.49427 2.66605 8.66908C2.6639 8.84388 2.75919 9.01229 2.93141 9.13803L7.65856 12.4715C7.83585 12.5965 8.07628 12.6667 8.32697 12.6667C8.57767 12.6667 8.8181 12.5965 8.99539 12.4715L13.7225 9.13803C13.8998 9.013 13.9993 8.84346 13.9993 8.66668C13.9993 8.4899 13.8998 8.32035 13.7225 8.19533ZM13.7225 4.1952C13.5452 4.07021 13.3048 4 13.0541 4C12.8034 4 12.563 4.07021 12.3857 4.1952L8.32697 7.05729L4.26825 4.1952C4.08994 4.07375 3.85112 4.00656 3.60323 4.00807C3.35534 4.00959 3.11822 4.07971 2.94293 4.20332C2.76764 4.32693 2.66821 4.49414 2.66605 4.66895C2.6639 4.84375 2.75919 5.01216 2.93141 5.1379L7.65856 8.47134C7.83585 8.59632 8.07628 8.66653 8.32697 8.66653C8.57767 8.66653 8.8181 8.59632 8.99539 8.47134L13.7225 5.1379C13.8998 5.01287 13.9993 4.84333 13.9993 4.66655C13.9993 4.48976 13.8998 4.32022 13.7225 4.1952Z"
        fill="url(#paint0_linear_2890_275110)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_2890_275110"
          x1="8.33268"
          y1="4"
          x2="8.33268"
          y2="12.6667"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#6AA3F9" />
          <Stop offset="1" stop-color="#0065FF" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default LowPriorityIcon;
