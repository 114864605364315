import {IEhrCapability, IVitalData} from '../../../../../Interfaces';
import { IAvailableEhr } from '../../../MiddleContainer/interfaces';
import {IObservationFhirResponse} from '../OrdersInterface';
import { HieInvalidFields } from './HieRequestsUtils';

export interface IHieRequestResponse {
  data: string;
  id: string;
  display_type: string;
  time_stamp: string;
  sync_status?: HieStatus;
}

export interface IHieInvalidField {
  inputType: HieInvalidFields;
  invalid: boolean;
  label?: string;
  value?: any;
  extraData?: any;
  subType?: string;
  field: any;
  placeholder?: string;
}

export interface IHieRequest {
  resourceData: any;
  id: string;
  resourceType: string;
  syncDate: string;
  syncStatus?: HieStatus;
  display?: IHieDisplay;
  invalidFields?: IHieInvalidField[];
  isMatchDataViewVisible?: boolean;
  error?: string;
}

export interface IHieRequestGroup {
  orders: IHieRequest[];
  resourceType: string;
  acceptAllLoading?: boolean;  
}

export enum HieStatus {
  accepted = 'accepted',
  declined = 'declined',
}

export interface IHieDisplay {
  title: string;
  subTitle?: string;
  date?: string;
  status?: string;
}

export interface IFormatHieMetaData {
  enabledVitalList: IVitalData[];
  ehrCapabilities: any;
  accountUuid: string;
  patientId: string;
  practitioner?: any;
  practitionerData?: any[];
  ehrConfig?: IAvailableEhr;
}

export interface IHieFormattedData {
  display: IHieDisplay,
  resourceData: any;
  invalidFields?: IHieInvalidField[]
};
