import {IChannelTwilioSms, IInbox} from '../../../../../../services/Inbox/interfaces';
import {canCreateSMSInboxWithPhoneNumber} from '../../../../../../utils/commonUtils';
import {ICTMlov} from '../../../../../../utils/interfaces';
import {getAssigneeNameFromAssigneeIdList} from '../../../../CloudTelephony/CloudTelephonyUtils';
import {IVirtualPhoneNumber} from '../../../../CloudTelephony/interfaces';
import {IUser} from '../../../../Contacts/TeamMembers/interfaces';
import {IVirtualPhoneNumberDisplay} from './interfaces';

export const getUpdatedInboxErrorMessages = (
  smsInboxState: any,
  smsFormRef: any
): any => {
  let userErrorMsgStr = '';
  let inboxNameErrorMsg = '';
  let isErrorPresent = false;
  if (!smsInboxState?.selectedUsersIds?.length) {
    userErrorMsgStr = 'Please select users';
    isErrorPresent = true;
  }

  if (!smsFormRef?.current?.value) {
    inboxNameErrorMsg = 'Please give inbox name';
    isErrorPresent = true;
  }

  if (smsFormRef?.current?.value) {
    // Regular expression to check if input is only numbers with optional '+' at the beginning
    const numberOnlyRegex = /^\+?\d+$/;
    if (numberOnlyRegex.test(smsFormRef?.current?.value)) {
      inboxNameErrorMsg = 'Please input only letters or a combination of letters and numbers; numbers alone are not permitted.';
      isErrorPresent = true;
    }
  }

  return {
    errors: {
      users: userErrorMsgStr,
      inboxName: inboxNameErrorMsg,
    },
    isErrorPresent,
  };
};

export const getVirtualNoFormattedList = (
  tableData: IVirtualPhoneNumber[],
  assigneesType: ICTMlov[],
  userList: IUser[],
  smsChannelList: IChannelTwilioSms[]
) => {
  const tempDataList = [...tableData];
  const assigneesTypeList = [...assigneesType];
  const returnFormattedData: IVirtualPhoneNumberDisplay[] = [];

  tempDataList.forEach((singleVirtualPhoneData) => {
    const tempSingleAssignee: ICTMlov =
      assigneesTypeList.find((item: ICTMlov) => {
        return item.id == singleVirtualPhoneData.assigneeTypeId;
      }) || ({} as any);
    const assigneeName = getAssigneeNameFromAssigneeIdList(
      singleVirtualPhoneData,
      userList,
      tempSingleAssignee
    );
    if (
      isPhoneNumberExistWithChannel(
        smsChannelList,
        singleVirtualPhoneData.virtualPhoneNumber
      )
    ) {
      const tempData = {
        id: singleVirtualPhoneData.id,
        phoneNumber: {
          phoneNumber: singleVirtualPhoneData.virtualPhoneNumber,
          name: assigneeName,
          userUuid: singleVirtualPhoneData.assigneeId,
        },
      };
      returnFormattedData.push(tempData);
    }
  });
  const finalList = returnFormattedData.filter((item) =>
    canCreateSMSInboxWithPhoneNumber(item?.phoneNumber?.phoneNumber)
  );
  return finalList;
};

const isPhoneNumberExistWithChannel = (
  channelList: IChannelTwilioSms[],
  phoneNumber: string
) => {
  let isExist = false;
  const channleData = channelList.find((channelData) => {
    return channelData.phoneNumber === phoneNumber;
  });
  if (channleData?.id) {
    isExist = true;
  }
  return isExist;
};

export const checkNumberExistInVirtualPhoneAndSmsList = (
  number: string,
  virtualPhoneNumbers: IVirtualPhoneNumber[],
  smsChannelList: IChannelTwilioSms[]
) => {
  let isExist = false;
  if (virtualPhoneNumbers?.length && smsChannelList.length && number) {
    const numberExistInVirtualNumberList = virtualPhoneNumbers?.find(
      (virtualNumber) => {
        return virtualNumber.virtualPhoneNumber == number;
      }
    );
    const numberExistSmsChannelList = smsChannelList?.find((smsChannelItem) => {
      return smsChannelItem.phoneNumber == number;
    });
    if (numberExistSmsChannelList?.id && numberExistInVirtualNumberList?.id) {
      isExist = true;
    } else {
      isExist = false;
    }
  }
  return isExist;
};


export const isChannelExistWithPhoneNumber = (
  phoneNumbers: IVirtualPhoneNumber[],
  channelList: IInbox,
) => {
  let isExist = false;
  const numberData = phoneNumbers.find((item) => {
    return item.virtualPhoneNumber === channelList?.channelTwilioSms?.phoneNumber;
  });
  if (numberData?.id) {
    isExist = true;
  }
  return isExist;
};