import DeviceInfo from "react-native-device-info";
import { isIOS, isWeb } from "./platformCheckUtils";
import axios, { AxiosRequestConfig } from "axios";

export enum HeaderAppName {
  foldGrowWeb = 'fold-grow-web',
  foldGrowIos = 'fold-grow-ios',
  foldGrowAndroid = 'fold-grow-android',
  patientAppWeb = 'patient-app-web',
  patientAppIos = 'patient-app-ios',
  patientAppAndroid = 'patient-app-android',
  foldGrowSidecar = 'fold-grow-sidecar-web',
}

export const getAppHeaders = () => {
  const appName = isWeb() ? (window.isExtension ? HeaderAppName.foldGrowSidecar : HeaderAppName.foldGrowWeb) : (isIOS() ? HeaderAppName.foldGrowIos : HeaderAppName.foldGrowAndroid);
  const appVersion = !isWeb() ? DeviceInfo.getVersion() : '';
  return {
    'x-app-name': appName,
    ...(appVersion && { 'x-app-version': appVersion }),
  }
}

export const createAxiosInstance = (params: AxiosRequestConfig) => {
  const axiosInstance = axios.create(params);
  axiosInstance.interceptors.request.use((config) => {
    const configHeadersAppName = config?.headers?.['x-app-name'];
    config.headers = {
      ...(config.headers && { ...config.headers }),
      ...getAppHeaders(),
      ...(configHeadersAppName && { 'x-app-name': configHeadersAppName }),
    }
    return config;
  });
  return axiosInstance;
}

export const setAxiosCommonHeaders = () => {
  axios.interceptors.request.use((config) => {
    config.headers = {
      ...(config.headers && { ...config.headers }),
      ...getAppHeaders(),
    }
    return config;
  });
}
