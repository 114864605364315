import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  SEARCH_TASK_POOL_BY_IDS,
  SEARCH_TASK_POOL_AND_USER_BY_TEXT,
} from '../../../../services/TaskPool/TaskPoolQueries';
import {getMlovId} from '../../../../utils/mlovUtils';
import {HStack, Skeleton, Text, View, VStack} from 'native-base';
import {IInputElement} from '../../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import {GET_ACTIVE_INACTIVE_ACCOUNT_USERS} from '../../../../services/User/UserQueries';
import {Colors} from '../../../../styles/Colors';
import {IUserSearch} from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';

export interface IUserPoolUsers {
  id: string;
  userId: string;
  isDefault: string;
  isDeleted: string;
  userName?: string;
  isActive: boolean;
}

export interface IUserPool {
  id: string;
  name: string;
  userPoolUsers: IUserPoolUsers[];
}

export interface IActiveAndInActiveUser {
  name: string;
  uuid: string;
  id: string;
}

export interface IAccountUser {
  id: string;
  uuid: string;
  isActive: boolean;
  user: IActiveAndInActiveUser;
}

export interface IAccountUserResponse {
  accountUsers: IAccountUser[];
}

export interface ITaskPoolAndUserProps extends IInputElement {
  assignToCode?: string;
  onPoolChange?: (taskPoolId?: string, userId?: string, displayName?: string) => void;
}

export const TaskPoolAndUserSearch = (props: ITaskPoolAndUserProps) => {
  const {value, isShowError, onPoolChange, assignToCode} = props;
  const [poolList, setPoolList] = useState<IUserPool[]>([]);
  const [originalPoolList, setOriginalPoolList] = useState<IUserPool[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedPool, setUserPool] = useState<IUserPool>();
  const [selectedUser, setUserPoolUser] = useState<IUserPoolUsers>();
  const commonData = useContext(CommonDataContext);
  const taskPoolTypeId = getMlovId(
    commonData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool',
  );

  const [searchTaskPool] = useLazyQuery(SEARCH_TASK_POOL_AND_USER_BY_TEXT, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [accountActiveAndInActiveUser] = useLazyQuery(
    GET_ACTIVE_INACTIVE_ACCOUNT_USERS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const getMatchUserPoolData = (
    poolUser: IUserPoolUsers,
    accountUsers: IAccountUser[],
  ): IAccountUser | undefined => {
    return (accountUsers || []).find(accountUser => {
      return accountUser?.user?.uuid === poolUser?.userId;
    });
  };

  const formatPoolUserResponse = (
    accountUsers: IAccountUser[],
    userPoolResponse: IUserPool[],
  ) => {
    (userPoolResponse || []).forEach(userPool => {
      const poolUsers: any[] = [];
      (userPool?.userPoolUsers || []).forEach(poolUser => {
        const findUser = getMatchUserPoolData(poolUser, accountUsers || []);
        if (findUser?.id && findUser?.user?.name) {
          poolUser.userName = findUser?.user?.name;
          poolUser.isActive = findUser?.isActive;
          poolUsers.push(poolUser);
        }
      });
      userPool.userPoolUsers = poolUsers;
    });
  };

  const fetchInitialPoolAndUserData = async () => {
    setLoading(true);
    try {
      const promiseList: any[] = [];
      const searchParams = {
        userPoolTypeId: taskPoolTypeId,
        searchString: `%%`,
      };
      promiseList.push(searchTaskPool({variables: searchParams}));
      const apiResponseList = await Promise.all(promiseList);
      const poolResponse = apiResponseList?.[0];
      const userPools = poolResponse?.data?.userPools || [];
      const userIds: string[] = [];
      (userPools || []).forEach((userPool: IUserPool) => {
        (userPool?.userPoolUsers || []).forEach((userPoolUser: IUserPoolUsers) => {
          if (userPoolUser?.userId && !userIds.includes(userPoolUser.userId)) {
            userIds.push(userPoolUser.userId);
          }
        })
      });
      let accountUserResponse = undefined;
      if (userIds?.length) {
        accountUserResponse = await accountActiveAndInActiveUser({ variables: { userIds: userIds}});
        formatPoolUserResponse(accountUserResponse?.data?.accountUsers, userPools);
      }
      if (value?.userId || value?.taskPoolId) {
        const selectedPoolId = value?.taskPoolId;
        const findPool = (userPools || []).find((pool: any) => {
          return pool?.id === selectedPoolId;
        });
        if (findPool?.id) {
          setUserPool(findPool);
          if (value?.userId || value?.assigneeId) {
            const selectedUserId = value?.userId || value?.assigneeId;
            const findUser = (findPool?.userPoolUsers || []).find(
              (poolUser: any) => {
                return poolUser?.userId === selectedUserId;
              },
            );
            if (findUser?.id) {
              setUserPoolUser(findUser);
            }
          }
        }
      }
      setLoading(false);
      setOriginalPoolList(userPools)
      setPoolList(userPools);
    } catch (error) {

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialPoolAndUserData();
  }, []);

  const onSearch = async (searchString: string) => {
    try {
      const userPools = (originalPoolList || []).filter(pool => {
        if (!searchString ) {
          return true;
        }
        const poolName = (pool.name || '').trim().toLowerCase();
        return poolName.includes(searchString.trim().toLowerCase());
      })
      setPoolList(userPools);
    } catch (error) {

    }
  };

  const getSuffixIcon = (isAutomationView: boolean | undefined): React.ReactNode => {
    return (isAutomationView ?
      <CaretDownOutlined
        style={{
          color: Colors.FoldPixel.GRAY300
        }}
      /> :
      <DownOutlined />)
  }

  return (
    <>
      {isLoading && (
        <View padding={3}>
            <Skeleton.Text lines={3} />
        </View>
      )}
      { !isLoading && (
        <>
        <Select
          key={'user_pool_search'}
          size="large"
          allowClear={true}
          suffixIcon={getSuffixIcon(props.isAutomationView)}
          showSearch
          filterOption={false}
          value={!isLoading ? selectedPool?.id || undefined : {}}
          status={
            isShowError && !(selectedPool && selectedPool?.id) ? 'error' : ''
          }
          onSearch={debounce(onSearch, 500)}
          onChange={(poolId: any) => {
            if (poolId) {
              const pool = poolList?.find((pool: IUserPool) => {
                return pool?.id == poolId;
              });
              const defaultUser = (pool?.userPoolUsers || []).find(poolUser => {
                return poolUser?.isDefault;
              });
              if (defaultUser?.userId) {
                const displayName = `Pool: ${pool?.name}, User: ${defaultUser?.userName} ${!defaultUser?.isActive ? '(InActive)' : ''}`;
                setUserPoolUser(defaultUser);
                if (assignToCode) {
                  const member: IUserSearch = {
                    value: defaultUser?.userId || '',
                    label: defaultUser?.userName || '',
                    key: defaultUser?.userId || '',
                  };
                  const value = {
                    assignToCode: assignToCode,
                    assigneeRoleId: undefined,
                    taskPoolId: pool?.id,
                    member: member,
                    assigneeId: member?.value,
                  };
                  props.onChange(value, '');
                } else {
                  if (onPoolChange && typeof onPoolChange == 'function') {
                    onPoolChange(pool?.id, defaultUser?.userId, displayName);
                  }
                }
              } else {
                setUserPoolUser(undefined);
              }
              setUserPool(pool);
            } else {
              const values = {
                condition: value.condition,
                label: value.label,
              };
              setUserPool(undefined);
              if (onPoolChange && typeof onPoolChange == 'function') {
                onPoolChange(undefined);
              } else {
                props.onChange(values);
              }
            }
          }}
          placeholder="Select Task Pool"
          loading={isLoading}
          notFoundContent={
            isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>
          }
          style={{width: '100%', marginTop: 2}}>
          {poolList?.map((item: any) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
        <>
          {selectedPool?.name && (
            <VStack marginTop={4}>
              <HStack flex={1}>
                <Text> Assignee within task pool </Text>
                <Text color={Colors.Custom.ErrorColor}>*</Text>
              </HStack>
              <Select
                allowClear={true}
                suffixIcon={getSuffixIcon(props.isAutomationView)}
                key={'user_pool_user_search'}
                size="large"
                status={
                  isShowError && !(selectedUser && selectedUser?.userId)
                    ? 'error'
                    : ''
                }
                filterOption={false}
                value={!isLoading ? selectedUser?.id || undefined : {}}
                onChange={(value: any) => {
                  if (value) {
                    const findPoolUser = (selectedPool?.userPoolUsers || []).find(
                      poolUser => {
                        return poolUser?.id === value;
                      },
                    );
                    setUserPoolUser(findPoolUser || undefined);
                    if (assignToCode) {
                      const member: IUserSearch = {
                        value: findPoolUser?.userId || '',
                        label: findPoolUser?.userName || '',
                        key: findPoolUser?.userId || '',
                      };
                      const value = {
                        assignToCode: assignToCode,
                        assigneeRoleId: undefined,
                        taskPoolId: selectedPool?.id,
                        member: member,
                        assigneeId: member?.value,
                      };
                      props.onChange(value, '');
                    } else {
                      const displayName = `Pool: ${selectedPool?.name}, User: ${findPoolUser?.userName} ${!findPoolUser?.isActive ? '(InActive)' : ''}`;
                      if (onPoolChange && typeof onPoolChange == 'function') {
                        onPoolChange(selectedPool?.id, findPoolUser?.userId, displayName);
                      }
                    }
                  } else {
                    setUserPoolUser(undefined);
                    const value = {
                      condition: props.value.condition,
                      label: props.value.label,
                      taskPoolId: selectedPool?.id,
                    };
                    if (assignToCode) {
                      props.onChange(undefined, '');
                      return;
                    }
                    if (onPoolChange && typeof onPoolChange == 'function') {
                      onPoolChange(selectedPool?.id);
                    }
                    props.onChange(value);
                  }
                }}
                placeholder="Select User"
                loading={isLoading}
                notFoundContent={
                  isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>
                }
                style={{width: '100%', marginTop: 4}}
              >
                {(selectedPool?.userPoolUsers || [])?.map((item: any) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item?.userName}  {`${(item?.userName && !item?.isActive) ? '(InActive)' : ''}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </VStack>
          )}
        </>
      </>
      )}
    </>
  );
};
