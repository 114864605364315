import {useState, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {View, useToast, Text} from 'native-base';
import {Employer} from '../../BrazilCodeMR/pages/analytics/AnalyticsController';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {getAccountUUID, isMasterAccount} from '../../../../utils/commonUtils';
import {
  ALL_MEMBERS,
  ALL_MEMBERS_OBJ,
} from '../EmployerAnalytics/EmployerUtils/EmployerConst';
import {useIntl} from 'react-intl';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {IEmployerDropDown} from './Interfaces';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
interface IEmployerDropdown {
  key: string;
  value: string;
  children: string;
  disabled: boolean;
}
const EmployerDropDown = (props: IEmployerDropDown) => {
  const {onEmployerChange, employerData, customStyles}: IEmployerDropDown = props;
  const intl = useIntl();
  const toast = useToast();
  const accountUuid = getAccountUUID();
  const [employer, setEmployer] = useState({
    list: [] as Employer[],
    loading: true
  });
  const isMasterAccountFlag = isMasterAccount()
  const hintMembershipDisabled = isAccountConfigEnabled(CONFIG_CODES.CONTACT_MEMBERSHIP_DISABLED) && !isMasterAccountFlag
  useQuery<{employers: Employer[]}>(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: accountUuid,
      query: '%%',
    },
    onCompleted: (data) => {
      const employersList = data.employers?.length ? data.employers : []
      employersList.unshift(ALL_MEMBERS_OBJ);
      setEmployer((prev: any) => {
        return {
          ...prev,
          list: employersList || [],
          loading: false
        };
      });
    },
    onError: () => {
      setEmployer((prev: any) => {
        return {
          ...prev,
          loading: false
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    },
  });

  return (
    <View
      style={{
        marginTop: customStyles?.marginTop,
        marginRight: customStyles?.marginRight,
      }}
    >
      {hintMembershipDisabled && <View
        style={{
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#e4e7ec',
          borderStyle: 'solid',
          padding: 8,
          height: 40,
          justifyContent: 'center',
          width: 250
        }}
      >
        <Text size={'smNormal'} fontSize={13} color={'#1f2937'}>
          All Members
        </Text>
      </View>}
      {!hintMembershipDisabled && <ModalActionAntSelect
        size="large"
        showSearch
        optionFilterProp="children"
        placeholder={intl.formatMessage({id: 'selectEmployer'})}
        className={'select-employer'}
        label={''}
        value={ employer?.loading ? '' : employerData?.id || ALL_MEMBERS}
        filterOption={(input: string, option: any) => {
          return (option?.children || '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        onChange={(value: any[], data: IEmployerDropdown) => {
          const findEmployer = employer?.list?.find((item) => {
            return item?.id === data?.key;
          });
          if (findEmployer?.id) {
            onEmployerChange(findEmployer);
          }
        }}
        style={{height: 40}}
        customStyle={{height: 40, width: 250}}
        data={employer?.list}
        optionProps={{
          key: 'id',
          label: 'name',
          value: 'id',
        }}
      />}
    </View>
  );
};

export default EmployerDropDown;
