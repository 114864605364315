export const memberLimitData = (memberLimit?: any) => {
  const formattedOutput = Object.keys(memberLimit).map(
    (key) =>
      `Max: ${
        memberLimit[key] +
        (parseInt(memberLimit[key]?.toString()) > 1 ? ' Members' : ' Member')
      }`
  );
  //.join('\n');
  return formattedOutput;
};

export const memberLimitCount = (memberLimit?: any) => {
  const formattedOutput = Object.keys(memberLimit).map(
    (key) => `${memberLimit[key]?.toString()}`
  );
  return parseInt(formattedOutput?.[0]) || 0;
};
