import {InputNumber, Select} from 'antd';
import {
  Divider,
  FormControl,
  HStack,
  Input,
  Select as NativeSelect,
  TextArea,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {IMlov} from '../../../../../Interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {JOURNEY_TRIGGER_EVENTS} from '../JourneyTabs';
import {IJourneyDetails, IJourneyDurationData} from './Interfaces';
import JourneyDetailTagInput from './JourneyDetailTagInput';
import './Dropdown.css'

const DetailsAndMembers = (props: {
  journeyDetails: IJourneyDetails;
  onChange: (detail: IJourneyDetails) => void;
}) => {
  const {journeyDetails, onChange} = props;
  const [details, setDetails] = useState<IJourneyDetails>(journeyDetails);

  useEffect(() => {
    onChange(details);
  }, [details]);

  return (
    <View style={{paddingHorizontal: 8}}>
      <PageBodyContainer>
        <VStack space={4} padding={4}>
          <JourneyDetailTextInput
            isRequired
            value={details.name}
            type={JourneyDetailTextInputType.input}
            onChange={(text) => {
              setDetails({...details, name: text});
            }}
            labelId="journeyName"
            descriptionId="journeyTitleSub"
          />
          <Divider />
          {
            false &&
            <JourneyDetailTagInput
              isRequired
              labelId="journeyWillStartOn"
              descriptionId="journeyWillStartOnSub"
              value={details.triggerEvents}
              options={JOURNEY_TRIGGER_EVENTS}
              onChange={(value) => {
                setDetails({...details, triggerEvents: value});
              }}
            />
          }
          <Divider />
          <JourneyDurationInput
            isRequired
            labelId="journeyDuration"
            descriptionId="journeyDurationDescription"
            value={details.duration}
            onChange={(duration: IJourneyDurationData) => {
              setDetails({...details, duration: duration});
            }}
          />
          <Divider />
          <JourneyDetailTextInput
            value={details.description}
            type={JourneyDetailTextInputType.textArea}
            onChange={(text) => {
              setDetails({...details, description: text});
            }}
            labelId="journeyDescription"
            descriptionId="journeyDescriptionSub"
          />
          {/* <Divider />
          <JourneyDetailTextInput
            value={details.memberOutcome}
            type={JourneyDetailTextInputType.input}
            onChange={(text) => {
              setDetails({...details, memberOutcome: text});
            }}
            labelId="memberOutcome"
          />
          <Divider />
          <JourneyDetailTextInput
            value={details.businessOutcome}
            type={JourneyDetailTextInputType.input}
            onChange={(text) => {
              setDetails({...details, businessOutcome: text});
            }}
            labelId="businessOutcome"
          /> */}
        </VStack>
      </PageBodyContainer>
    </View>
  );
};

enum JourneyDetailTextInputType {
  input = 'Input',
  textArea = 'TextArea',
}

const JourneyDetailTextInput = (props: {
  labelId: string;
  descriptionId?: string;
  type: JourneyDetailTextInputType;
  value: string;
  onChange: (text: string) => void;
  isRequired?: boolean;
}) => {
  return (
    <FormControl paddingY={2}>
      <HStack alignItems="flex-start" space={2}>
        <View flex={1}>
          <VStack>
            <FormControl.Label isRequired={props.isRequired}>
              <DisplayText textLocalId={props.labelId} />
            </FormControl.Label>
            {props.descriptionId && (
              <DisplayText
                size="xsLight"
                textLocalId={props.descriptionId}
                extraStyles={{
                  marginTop: -6,
                }}
              />
            )}
          </VStack>
        </View>
        <View flex={4}>
          {props.type === JourneyDetailTextInputType.input && (
            <Input
              maxWidth="600"
              value={props.value}
              onChangeText={(text) => props.onChange(text)}
            />
          )}
          {props.type === JourneyDetailTextInputType.textArea && (
            <TextArea
              maxWidth="600"
              // h={20}
              maxLength={300}
              value={props.value}
              onChangeText={(text) => props.onChange(text)}
            />
          )}
        </View>
      </HStack>
    </FormControl>
  );
};

export const JourneyDurationInput = (props: {
  labelId: string;
  descriptionId?: string;
  value: IJourneyDurationData;
  onChange: (duration: IJourneyDurationData) => void;
  isRequired?: boolean;
  isShowError?: boolean;
  isReadOnly?: boolean;
  maxWidth?: number;
  unitDropDownHeight?: number;
}) => {
  const [duration, setDuration] = useState<IJourneyDurationData>({
    value: props.value.value,
    unit: props.value.unit,
    unitList: props.value.unitList,
  });

  const unitOrder = ['HOUR', 'DAY', 'WEEK', 'MONTH', 'YEAR']

  useEffect(() => {
    props.onChange(duration);
  }, [duration]);

  return (
    <HStack space={4} maxWidth={props?.maxWidth || 300} alignItems="center">
      <FormControl isInvalid={props.isShowError && !duration.value} flex={1}>
        <InputNumber
          readOnly={props.isReadOnly}
          value={duration.value}
          type="number"
          className={props.isShowError && !duration.value ? 'field-error' : ''}
          onChange={(text) => {
            setDuration((prev: any) => ({...prev, value: text}));
          }}
          style={{width: '100%', height: 36, borderRadius: 4}}
          min={0}
          precision={0}
        />
      </FormControl>

      <FormControl isInvalid={props.isShowError && !duration.unit} flex={2}>
        <NativeSelect
          isDisabled={props.isReadOnly}
          selectedValue={duration.unit}
          height={props?.unitDropDownHeight || 'auto'}
          onValueChange={(itemValue) => {
            setDuration((prev: any) => ({...prev, unit: itemValue}));
          }}
        >
          {duration.unitList &&
            unitOrder.map((unitCode) => {
              const unitData = duration.unitList.find(
                (data) => data.code === unitCode
              );
              if (unitData) {
                return (
                  <NativeSelect.Item
                    key={unitData.id}
                    label={unitData.value}
                    value={unitData.id}
                  />
                );
              }
            })}
        </NativeSelect>
      </FormControl>
    </HStack>
  );
};

export const JourneyDurationInputForAssign = (props: {
  labelId: string;
  descriptionId?: string;
  value: IJourneyDurationData;
  onChange: (duration: IJourneyDurationData) => void;
  isRequired?: boolean;
  isShowError?: boolean;
  isReadOnly?: boolean;
  maxWidth?: number;
  unitDropDownHeight?: number;
}) => {
  const [duration, setDuration] = useState<IJourneyDurationData>({
    value: props.value.value,
    unit: props.value.unit,
    unitList: props.value.unitList,
  });
  useEffect(() => {
    props.onChange(duration);
  }, [duration]);

  return (
    <HStack w={150} mx={1} alignItems="center" flex={1} borderWidth={1} borderRadius={6} borderColor={'#D0D5DD'} height={10}>
      <FormControl flex={0.3} isInvalid={props.isShowError && !duration.value} ml={1}>
        <InputNumber
          readOnly={props.isReadOnly}
          value={duration.value}
          type="number"
          className={props.isShowError && !duration.value ? 'field-error' : ''}
          onChange={(text) => {
            setDuration((prev: any) => ({ ...prev, value: text }));
          }}
          min={0}
          precision={0}
          controls={false}
          bordered={false}
        />
      </FormControl>
      <FormControl flex={0.7} isInvalid={props.isShowError && !duration.unit} borderWidth={0}>
        <Select
          className="custom-dropdown"
          bordered={false}
          disabled={props.isReadOnly}
          value={duration.unit}
          listItemHeight={props?.unitDropDownHeight}
          onChange={(value) => {
            setDuration((prev: any) => ({ ...prev, unit: value }));
          }}
        >
          {duration.unitList &&
            duration.unitList.map((data: IMlov) => {
              return (
                <Select.Option key={data.id} label={data.value} value={data.id}>
                  {data.value}
                </Select.Option>
              );
            })}
        </Select>
      </FormControl>
    </HStack>
  );
};

export default DetailsAndMembers;
