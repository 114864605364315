import {HStack, View, VStack, Text, Pressable} from 'native-base';
import React, { useEffect, useRef, useState} from 'react';
import {BUTTON_TYPE, DATE_FORMATS, IS_SAVE_CLICK, SHOW_COMMENT_ERROR} from '../../../../constants';
import {Colors} from '../../../../styles/Colors';
import {IAddOrUpdateComments, ITaskCommentsList} from '../interfaces';
import {ActivityIndicator} from 'react-native';
import {DisplayText} from '../../DisplayText/DisplayText';
import ModalActionBtn from '../../ModalActionBtn/ModalActionBtn';
import GroupUserMentionList from '../../GroupUserMentionList/GroupUserMentionList';
import {useLazyQuery, useMutation} from '@apollo/client';
import {TaskQueries} from '../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import CommentsItem from './CommentsItem';
import {Editor} from '@toast-ui/react-editor';
import './EditorStyles.css';
import {
  filterWorkflowUser,
  getAccountUUID,
  getUserUUID,
  parseHtmlToPlainText,
} from '../../../../utils/commonUtils';
import {Content} from 'antd/lib/layout/layout';
import {formatForMentionUsers, getContentWithMentionText, mentionPartPostPattern, showInitialCommentsCount} from '../AddTaskUtils';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {GET_USER_FOR_SCHEDULE_ACCESS} from '../../../../services/User/UserQueries';
import { isEqual } from 'lodash';
import { activityTabs, activityTabsCode } from './TaskCommentsHelper';
import ActivityTabs from '../ActivityTabs';
import { AUDIT_ACTION_CODES } from '../../Audit/AuditHelper';
import SortIcon from '../../Svg/SortIcon';
import useTaskAudit from '../Hooks/useTaskAudit';
import { testID, TestIdentifiers } from '../../../../testUtils';
import { AUDIT_RESOURCE } from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/BottomTabs';
import { Segmented } from 'antd';
import FoldButtonV2 from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { useIntl } from 'react-intl';

const AddOrUpdateCommentForTask = (props: IAddOrUpdateComments) => {
  const {accountUsers, task, handleAddLocalComment, comments, handleDeleteLocalComment, showOnlyCommentBox, commentBoxFlex} = props;
  const editorRef: any = useRef<Editor>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [newCommentAdded, setNewCommentAdded] = useState(false);
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const targetRef: any = useRef();
  const accountUuid = getAccountUUID();
  const currentUserId = getUserUUID();
  const intl = useIntl();
  const [commentsDataState, setCommentsDataState] = useState<{
    accountUsersList: any[];
    commentLoading: boolean;
    commentText: string;
    commentsList: ITaskCommentsList[];
    showAgentList: boolean;
    showAll: boolean;
    allUsersForMention: any[]
    multipleLinesForCommentBox: boolean;
  }>({
    accountUsersList: accountUsers || ([] as any),
    commentLoading: false,
    commentText: props.defaultComment || '',
    commentsList: [],
    showAgentList: false,
    showAll: false,
    allUsersForMention: formatForMentionUsers(accountUsers || []),
    multipleLinesForCommentBox: false
  });
  const [activeTabInActivity, setActiveTabInActivity] = useState(activityTabs?.find((tab) => tab?.code === activityTabsCode?.COMMENTS))
  const activityTabOptions = activityTabs.map(tab => ({
    label: tab.title,
    value: tab.code,
  }));
  const [editorAutoFocus, setEditorAutoFocus] = useState(false)
  const [createOrUpdateComment] = useMutation(
    TaskQueries.ADD_OR_UPDATE_COMMENT
  );
  const [getTaskComments] = useLazyQuery(TaskQueries.GET_TASK_COMMENTS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
  });
  const { taskLogAuditData, accountUsersMappedById, mappingUuidWithData, handleActions, offset, sortState, handleNewCommentAdded, journeyDetails, totalTransactionsFetchedCount, initialBatchLogsCountData } = useTaskAudit(
    {
      taskId: task?.id,
      commentsList: commentsDataState?.commentsList,
      activeTabCodeInActivity: activeTabInActivity?.code,
      patientCareJourneyId: task?.patientCareJourney?.careJourney?.id,
      accountUsers: accountUsers || []
    })

  useEffect(() => {
    if (task?.id && accountUsers?.length) {
        getTaskAllComments();
    } else if(comments?.length && accountUsers?.length) {
      formattedTaskCommentsData(
        comments,
        formatForMentionUsers(accountUsers || [])
      );
    }
    else{
      getTaskAndAccountUsers();
    }
  }, [accountUsers, task?.id, comments]);

  const getUserFormattedList = (users: any[]) => {
    const filterUsers = filterWorkflowUser(users || [], currentUserId);
    return (filterUsers || []).map((user: any) => {
      return {
        ...user,
        userName: user.name,
        userId: user.uuid,
        email: user.email || '',
      };
    });
  };

  const getTaskAndAccountUsers = async () => {
    const fetchAccountUsers = !accountUsers || accountUsers?.length === 0
    setCommentsDataState((prev) => {
      return {
      ...prev,
      commentLoading: true,
      };
    });
    const accountUserParams = {
      accountUUID: accountUuid,
      roleCode: USER_ROLE_CODES.EMPLOYER,
    };
  
    const promiseList = [];
    let accountUsersList = accountUsers;
  
    if (task?.id) {
      promiseList.push(
        getTaskComments({
          variables: {
            taskId: task?.id,
          },
        })
      );
    }
  
    if (fetchAccountUsers) {
      promiseList.push(getAccountUsers({ variables: accountUserParams }));
    }

    const apiResponse = await Promise.all(promiseList);
  
    const commentsListRes = apiResponse[0]?.data?.taskComments || [];
    if (fetchAccountUsers) {
      accountUsersList = getUserFormattedList(apiResponse[1]?.data?.users || []);
    }

    const allUsersForMention = formatForMentionUsers(accountUsersList || []);
    formattedTaskCommentsData(commentsListRes, allUsersForMention);

    setCommentsDataState((prev) => ({
      ...prev,
      ...(fetchAccountUsers && { accountUsersList: accountUsersList }),
      allUsersForMention: allUsersForMention,
    }));
  };
  
  const getTaskAllComments = async () => {
    setCommentsDataState((prev) => {
      return {
        ...prev,
        commentLoading: true,
      };
    });
    const commentsListRes = await getTaskComments({
      variables: {
        taskId: task?.id,
      },
    });
    formattedTaskCommentsData(
      commentsListRes?.data?.taskComments,
      formatForMentionUsers(accountUsers || [])
    );
  };

  const formattedTaskCommentsData = (
    taskComments: ITaskCommentsList[],
    accountUsersList: any[]
  ) => {
    if (taskComments?.length) {
      const commentsFinalList = taskComments?.map(
        (commentItem: ITaskCommentsList) => {
          const userData = accountUsersList.filter(
            (user) => commentItem?.createdBy === user.uuid
          );
          return {
            ...commentItem,
            userName: userData?.[0]?.name,
            comment: getContentWithMentionText(
              commentItem?.comment,
              accountUsersList
            ),
          };
        }
      );
      setCommentsDataState((prev) => {
        return {
          ...prev,
          commentLoading: false,
          commentsList: commentsFinalList,
        };
      });
    } else {
      setCommentsDataState((prev) => {
        return {
          ...prev,
          commentLoading: false,
          commentsList: [],
        };
      });
    }
  };

  const addTaskComment = async (selectedComment?: ITaskCommentsList) => {

    let plainString = '';
    setCommentsDataState((prev) => {
      return {
        ...prev,
        commentLoading: true,
      };
    });
    if (!selectedComment?.id && editorRef) {
      const inst = editorRef?.current?.getInstance();
      plainString = inst.getMarkdown();
    } else if (selectedComment?.id) {
      plainString = selectedComment.comment;
    } else {
      plainString = commentsDataState.commentText;
    }
    // Check if any mention is present in the content
    const isMentionPresent = mentionPartPostPattern.test(plainString);
    if (isMentionPresent) {
      plainString = plainString.replace(mentionPartPostPattern, (match, userName, type, uuid) => {
      return `@[mention://USER/${uuid}]`;
      });
    }
    const editorHtmlElemList: any = document?.querySelectorAll(
      '.messagewindow-editor-task-comment-customized .ProseMirror.toastui-editor-contents .ProseMirror-trailingBreak'
    );
    if (editorHtmlElemList?.length) {
      for (let index = 0; index < editorHtmlElemList.length; index++) {
        const element = editorHtmlElemList[index];
        if (element?.remove) {
          element.remove();
        }
      }
    }
    const editorHtmlElem: any = document?.querySelector(
      '.messagewindow-editor-task-comment-customized .ProseMirror.toastui-editor-contents'
    );
    if (editorHtmlElem && editorHtmlElem.innerHTML) {
      editorHtmlElem.innerHTML = '';
      if (editorHtmlElem.focus) {
        editorHtmlElem.focus();
      }
    }
    const addCommentVariables = {
      taskId: task?.id,
      comments: [
        {
          comment: plainString,
          id: selectedComment?.id ? selectedComment.id : undefined,
        },
      ],
    };

    if(!task?.id && handleAddLocalComment){
      !selectedComment && handleAddLocalComment(plainString)
      setCommentsDataState((prev) => {
        return {
          ...prev,
          commentLoading: false,
        };
      });
      return
    }

    const addCommentRes = await createOrUpdateComment({
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        data: addCommentVariables,
      },
    });
    if (!selectedComment?.id && addCommentRes?.data?.addOrUpdateTaskComments?.comments?.[0]?.id &&
      activeTabInActivity?.code === activityTabsCode.ALL) {
      handleNewCommentAdded(addCommentRes?.data?.addOrUpdateTaskComments?.comments?.[0])
    }
    if (addCommentRes?.data?.addOrUpdateTaskComments?.comments?.[0]?.id) {
      setCommentsDataState((prev) => {
        return {
          ...prev,
          commentText: '',
          multipleLinesForCommentBox: false
        };
      });
      if (editorRef?.current) {
        const inst = editorRef.current.getInstance();
        inst.reset();
      }
      if (selectedComment?.id) {
        const tempSubData = commentsDataState.commentsList || [];
        const itemIndex = tempSubData?.findIndex(
          (item) => item.id === selectedComment.id
        );
        if (itemIndex !== -1) {
          const updatedComments = {
            ...selectedComment,
            isModified: true,
          };
          tempSubData[itemIndex] = updatedComments;
          setCommentsDataState((prev) => {
            return {
              ...prev,
              commentLoading: false,
            };
          });
          const timeoutId = setTimeout(() => {
            setCommentsDataState((prev) => {
              return {
                ...prev,
                commentsList: tempSubData,
              };
            });
          }, 100);
          timeOutRefs.current.push(timeoutId);
        }
      } else {
        const tempSubData = commentsDataState.commentsList || [];
        let newComment =
          addCommentRes?.data?.addOrUpdateTaskComments?.comments?.[0];
        const userData = commentsDataState?.allUsersForMention.filter(
          (user) => currentUserId === user.uuid
        );

        newComment = {
          ...newComment,
          createdBy: currentUserId,
          createdOn: new Date().toISOString(),
          userName: userData?.[0]?.name,
          comment: getContentWithMentionText(
            addCommentRes?.data?.addOrUpdateTaskComments?.comments?.[0]
              ?.comment,
            commentsDataState?.allUsersForMention
          ),
        };

        const updatedList = [newComment, ...tempSubData];
        const timeoutId = setTimeout(() => {
          setNewCommentAdded(true);
          formattedTaskCommentsData(
            updatedList,
            commentsDataState.allUsersForMention
          );
        }, 10);
        timeOutRefs.current.push(timeoutId);
      }
    }
  };

  const handleDeleteComment = async (commentData: ITaskCommentsList) => {

    if(commentData.tempId && handleDeleteLocalComment){
      handleDeleteLocalComment(commentData)
      return
    }

    setCommentsDataState((prev) => {
      return {
        ...prev,
        commentLoading: true,
      };
    });
    const updateCommentVariables = {
      taskId: task?.id,
      comments: [
        {
          id: commentData.id,
          isDeleted: true,
        },
      ],
    };
    const addCommentRes = await createOrUpdateComment({
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        data: updateCommentVariables,
      },
    });
    if (addCommentRes?.data?.addOrUpdateTaskComments?.comments?.[0]?.id) {
      const newArry = commentsDataState.commentsList?.filter(
        (item) => item?.id !== commentData.id
      );
      setCommentsDataState((prev) => {
        return {
          ...prev,
          commentLoading: false,
          commentsList: newArry,
        };
      });
    }
  };

  const isDisplayUserAgents = (listCheck: boolean) => {
    setCommentsDataState((prev) => {
      return {
        ...prev,
        showAgentList: listCheck,
      };
    });
  };

  const scrollToComment = () => {
    if (props?.showEditCommentError ) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  useEffect(() => {
    if(props?.showEditCommentError){
      const timeoutId = setTimeout(() => {
        scrollToComment();
      }, 1000);
      timeOutRefs.current.push(timeoutId);
      props?.handleCommentErrorAction && props?.handleCommentErrorAction(IS_SAVE_CLICK,false);
    }
      return () => {
        timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
        });
    }
  }, [props?.isNeedToScrollDown]);

  const onUserAgentSelection = (userSelected: any) => {
    const inst = editorRef?.current?.getInstance();
    const plainString: string = (inst && inst.getMarkdown()) || '';
    let messagePlainStr = parseHtmlToPlainText(plainString.trim());
    messagePlainStr = messagePlainStr?.trim() || '';
    const currentText = messagePlainStr;
    const lastIndex = currentText?.lastIndexOf('@');
    const replaced =
      currentText?.slice(0, lastIndex) +
      `${userSelected}` +
      currentText?.slice(lastIndex + currentText?.length);
    setCommentsDataState((prev) => {
      return {
        ...prev,
        commentText: replaced,
        showAgentList: false,
      };
    });
    if (editorRef?.current) {
      const inst = editorRef.current.getInstance();
      inst.setMarkdown(replaced);
    }
  };

  const getUserListElem = () => {
    let listElem = <></>;
    if (commentsDataState.allUsersForMention?.length && commentsDataState.showAgentList) {
      listElem = (
        <GroupUserMentionList
          memberList={commentsDataState?.allUsersForMention?.filter((item) => item.uuid !== currentUserId)}
          searchString={commentsDataState.commentText.slice(
            commentsDataState.commentText.lastIndexOf('@')
          )}
          selectedTab={'reply'}
          mentionForTask
          taskUserRoles={true}
          onMemberSelection={(selectedUser: any) => {
            onUserAgentSelection(selectedUser);
          }}
        />
      );
    }
    return listElem;
  };

  const onCommentClick = async () => {
    await addTaskComment();
    props?.showCommentError && props?.onCommentButtonClick && props?.onCommentButtonClick();
    setCommentsDataState(prev => ({...prev, multipleLinesForCommentBox: false}));
  }
  const scrollToBottom = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  useEffect(() => {
    if (newCommentAdded) {
      scrollToBottom();
      setNewCommentAdded(false);
    }
  }, [newCommentAdded]);

  const renderAddComments = (): JSX.Element => {
    return (
        <VStack>
            <VStack >
            <View style={{flex: commentBoxFlex || 1}}>
              <Content className={`${!commentsDataState.multipleLinesForCommentBox ? 'messagewindow-editor-task-comment-singleline-customized' : 'messagewindow-editor-task-comment-customized'} ${props?.showCommentError ? 'messagewindow-editor-task-comment-error-customized' : 'messagewindow-editor-task-comment-customized'}`}>
                <Editor
                  placeholder="Add a comment"
                  initialValue={commentsDataState.commentText || ''}
                  initialEditType="wysiwyg"
                  height={!commentsDataState.multipleLinesForCommentBox ? "100px" :"140px"}
                  useCommandShortcut={true}
                  previewHighlight={false}
                  onFocus={() => setCommentsDataState((prev) => ({
                    ...prev,
                    multipleLinesForCommentBox: true
                  }))}
                  ref={editorRef}
                  toolbarItems={[['heading', 'bold', 'italic', 'strike']]}
                  autofocus={editorAutoFocus}
                  onChange={() => {
                    if (editorRef && editorRef?.current) {
                      const inst = editorRef.current.getInstance();
                      const plainString = inst.getMarkdown();
                      const includeAt = plainString.slice(
                        plainString.lastIndexOf('@')
                      );
                      if (!plainString.length) {
                        isDisplayUserAgents(false);
                      } else if (!includeAt.trim().includes('@')) {
                        isDisplayUserAgents(false);
                      } else if (includeAt.trim().includes('@')) {
                        isDisplayUserAgents(true);
                      }
                      setCommentsDataState((prev) => {
                        return {
                          ...prev,
                          commentText: plainString,
                        };
                      });
                      props.onCommentChange?.(plainString,false);
                    }
                  }}
                />
              </Content>
              {props?.showCommentError && (
                <Text marginBottom={2} color={Colors.Custom.crossIconColor}>Unsaved</Text>
              )}
            </View>
            {(!showOnlyCommentBox && commentsDataState.multipleLinesForCommentBox) &&
            <View
              alignSelf={'flex-start'}
              justifySelf={'center'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
            >
              <HStack space={2} marginBottom={3} marginTop={3}>
                <FoldButtonV2
                  key={'comment'}
                  label={intl.formatMessage({ id: 'comment' })}
                  buttonType={'primary'}
                  onPress={() => {
                    onCommentClick();
                    if (editorRef?.current) {
                      const inst = editorRef.current.getInstance();
                      inst?.blur();
                    }
                  }}
                />
                <FoldButtonV2
                  key={'comment'}
                  label={intl.formatMessage({ id: 'cancel' })}
                  buttonType={'secondary'}
                  onPress={() => {
                    const inst = editorRef?.current?.getInstance();
                    inst.setMarkdown('', false);
                    setCommentsDataState((prev) => {
                      return {
                        ...prev,
                        commentText: '',
                        multipleLinesForCommentBox: false
                      };
                    });
                    props?.onCommentChange?.('',false);
                    props?.handleCommentErrorAction && props?.handleCommentErrorAction(SHOW_COMMENT_ERROR,false);
                  }}
                />
              </HStack>
            </View>
            }
            </VStack>
        </VStack>
    );
  };

  return (
    <VStack style={!showOnlyCommentBox ? {minHeight: 230} : {}}>
      {activeTabInActivity?.code !== activityTabsCode.HISTORY && getUserListElem()}
      {!showOnlyCommentBox &&
      <HStack marginTop={1} marginBottom={4} flexDirection='row'>
        <Segmented
          options={activityTabOptions}
          value={activeTabInActivity?.code}
          onChange={(value) => {
            const selected = activityTabs.find((tab) => tab.code === value);
            setActiveTabInActivity(selected);
            if (selected?.code === activityTabsCode.HISTORY) {
              isDisplayUserAgents(false);
            } else {
              setEditorAutoFocus(true);
            }
          } }
          style={{
            marginBottom: 2,
            width: 'fit-content',
            maxWidth: '45%',
            height: 32,
          }}
          onResizeCapture={undefined}
          onResize={undefined}
        />
        {activeTabInActivity?.code !== activityTabsCode.COMMENTS &&
          <HStack justifyContent={'flex-end'} alignItems={'center'} space={1} flex={1}>
            <Pressable onPress={() => handleActions(AUDIT_ACTION_CODES.CHANGE_SORT)} alignItems={'center'}>
              <HStack alignItems={'center'} >
                <Text
                  {...testID(TestIdentifiers?.sortTaskAudit)}
                  color={Colors?.Custom?.mainPrimaryPurple}
                  fontSize={16}
                  fontWeight={700}
                >
                  {sortState.dueDate?.desc ? 'Newest first' : 'Oldest first'}
                </Text>
                <SortIcon sortMode='' strokeColor={Colors?.Custom?.mainPrimaryPurple} strokeWidth="1.5" />
              </HStack>
            </Pressable>
          </HStack>
        }
      </HStack>
      }
      <View
        style={{
          display:
            activeTabInActivity?.code === activityTabsCode.HISTORY
              ? 'none'
              : 'flex',
        }}
      >
        {renderAddComments()}
      </View>
      {!showOnlyCommentBox && activeTabInActivity?.code === activityTabsCode.COMMENTS && (
          <VStack ref={commentsContainerRef}>
            {commentsDataState.commentLoading && (
              <ActivityIndicator color={Colors.Custom.PrimaryColor} />
            )}
            {(commentsDataState.commentsList || [])?.length > 0 &&
              (!commentsDataState.showAll
                ? commentsDataState.commentsList.slice(0, 2)
                : commentsDataState.commentsList
              )?.map((data, index) => {
                return (
                  <View key={(data?.id || '') + index} ref={targetRef}>
                  <CommentsItem
                      onCommentChange={props?.onCommentChange}
                      handleCommentErrorAction={props?.handleCommentErrorAction}
                      showEditCommentError={props?.showEditCommentError}
                      key={(data?.id || '') + index}
                      accountUsers={commentsDataState.accountUsersList}
                      commentsData={data}
                      editComment={(commentData: ITaskCommentsList) => {
                        addTaskComment(commentData);
                      }}
                      deleteComment={(commentData: ITaskCommentsList) => {
                        handleDeleteComment(commentData);
                      }}
                    />
                  </View>
                );
              })}
            {!commentsDataState.showAll &&
              commentsDataState.commentsList.length > showInitialCommentsCount && (
                <Text
                  fontWeight={700}
                  fontSize={14}
                  color={Colors.Custom.PrimaryColor}
                  onPress={() => {
                    setCommentsDataState((prev) => {
                      return {
                        ...prev,
                        showAll: !commentsDataState.showAll,
                      };
                    });
                  }}
                >{`Load ${commentsDataState.commentsList.length > showInitialCommentsCount &&
                  commentsDataState.commentsList.length - showInitialCommentsCount
                  } more...`}</Text>
            )}
        </VStack>
      )}
      {!showOnlyCommentBox && activeTabInActivity?.code !== activityTabsCode.COMMENTS &&
        <ActivityTabs
          selectedTab={activeTabInActivity}
          taskLogAuditData={taskLogAuditData}
          accountUsersMappedById={accountUsersMappedById}
          mappingUuidWithData={mappingUuidWithData}
          handleActions={handleActions}
          offset={offset}
          addCommentLoading={commentsDataState?.commentLoading}
          taskId={task?.id}
          journeyDetails={journeyDetails}
          totalTransactionsFetchedCount={totalTransactionsFetchedCount}
          initialBatchLogsCountData={initialBatchLogsCountData}
          auditResource={AUDIT_RESOURCE.TASK}
        />
      }
    </VStack>
  );
};

export default AddOrUpdateCommentForTask;
