import { Menu } from 'antd';
import { View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { FlowType } from '../../../../context/WorkflowContext';
import { Colors } from '../../../../styles';
import { commonBodyContentContainer } from '../../../../styles/CommonBodyContentStyle';
import { getEhrConfig } from '../../../../utils/capabilityUtils';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../../common/CustomTabsView/interfaces';
import PlusIcon from '../../../common/Svg/PlusSvg';
import {
  TABLE_TOP_BAR_ACTION_CODES,
  TableTopBar
} from '../../../common/TableTopBar';
import WorkflowView from '../../Workflow/Workflow/WorkflowView';
import { getCreateWorkflowUrlFromFlowType } from '../../Workflow/workflowUtils';
import { styles } from './Styles';
import { getEnvVariable } from '../../../../constants/BaseUrlConst';
import {isEnableCareProgram, isMasterAccount} from '../../../../utils/commonUtils';

const baseEnv = getEnvVariable()
// const includeDBTrigger = true
const includeDBTrigger = (baseEnv === 'qa' || baseEnv === 'dev')

const WorkflowAdmin = () => {
  const currentEnv = getEnvVariable();
  const includeDBTrigger =  currentEnv == 'qa' || currentEnv == 'dev';
  const location = useLocation();
  const commonData = useContext(CommonDataContext);
  const navigate = useNavigate();
  const ehrConfig = getEhrConfig();
  const isMasterAccountFlag = isMasterAccount();

  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );

  const filteredResult = labels?.filter((item) => {
    return item.code === 'CONTACT';
  });
  const mlov = filteredResult[0];
  const basePath = '/admin/workflow';

  const [searchString, setSearchString] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const getSearch = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };
  const handleSearchChange = (actionData: any) => {
    const searchString = actionData.searchString.trim()
    setSearchString(searchString);
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };

  const getTabsForAthena = (): ITabsList[] => {
    if(ehrConfig.isAthena) {
      return [
        {
          key: 'order',
          path: 'order',
          title: 'Order',
          tabContainerElem: () => {
            return (
              <View key={'Order'}>
                <WorkflowView
                  isNewWorkflowView={true}
                  showTableTopBar={false}
                  flowType={FlowType.order}
                  searchText={searchString}
                />
              </View>
            );
          }
        },
        {
          key: 'patient-case',
          path: 'patient-case',
          title: 'Patient Case',
          tabContainerElem: () => {
            return (
              <View key={'Case'}>
                <WorkflowView
                  isNewWorkflowView={true}
                  showTableTopBar={false}
                  flowType={FlowType.PATIENT_CASE}
                  searchText={searchString}
                />
              </View>
            );
          }
        },
        {
           key: 'patient-referral',
           path: 'patient-referral',
           title: 'Patient Referral',
           tabContainerElem: () => {
             return (
               <View key={'Referral'}>
                 <WorkflowView
                   isNewWorkflowView={true}
                   showTableTopBar={false}
                   flowType={FlowType.PATIENT_REFERRAL}
                   searchText={searchString}
                 />
               </View>
             );
           }
        },
      ];
    }
    return [];
  }

  const getListForAthena = (): Array<{ title: string, code: FlowType }> => {
    if(ehrConfig.isAthena) {
      return [
        { title: 'Order Automation', code: FlowType.order },
        { title: 'Patient Case Automation', code: FlowType.PATIENT_CASE },
        { title: 'Patient Referral Automation', code: FlowType.PATIENT_REFERRAL }
      ];
    }
    return [];
  }

  const getTabsForCareProgram = () => {
    if (isEnableCareProgram(commonData.userSettings)) {
      return [
        {
          key: 'care-plan',
          path: 'care-plan',
          title: 'Care Plan',
          tabContainerElem: () => {
            return (
              <View key={'care-plan'}>
                <WorkflowView
                  isNewWorkflowView={true}
                  showTableTopBar={false}
                  flowType={FlowType.CARE_PLAN}
                  searchText={searchString}
                />
              </View>
            );
          }
        }
      ]
    }
    return [];
  }

  const getListForCareProgramAutomation = (): Array<{ title: string, code: FlowType }> => {
    if (isEnableCareProgram(commonData.userSettings)) {
      return [
        { title: 'Care Plan Automation', code: FlowType.CARE_PLAN },
      ];
    }
    return [];
  }

  const tagsTabList = (): ITabsList[] => {
    const orderDetailTabView: ITabsList[] = [
      {
        key: 'all',
        path: 'all',
        title: 'All',
        tabContainerElem: () => {
          return (
            <View
              key={'all'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={''}
                searchText={searchString}
              />
            </View>
          );
        },
      },
      {
        key: 'patients',
        path: 'patients',
        title: 'Patients/Leads',
        tabContainerElem: () => {
          return (
            <View
              key={'Patients'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.patients}
                searchText={searchString}
              />
            </View>
          );
        },

      },
      {
        key: 'communication',
        path: 'communication',
        title: 'Communication',
        tabContainerElem: () => {
          return (
            <View
              key={'communication'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.communication}
                searchText={searchString}
              />
            </View>
          );
        },

      },
      {
        key: 'schedule',
        path: 'schedule',
        title: 'Appointments',
        tabContainerElem: () => {
          return (
            <View
              key={'Schedule'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.scheduling}
                searchText={searchString}
              />
            </View>
          );
        },

      },
      {
        key: 'forms',
        path: 'forms',
        title: 'Forms',
        tabContainerElem: () => {
          return (
            <View
              key={'Forms'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.form}
                searchText={searchString}
              />
            </View>
          );
        },


      },
      {
        key: 'journey',
        path: 'journey',
        title: 'Journeys',
        tabContainerElem: () => {
          return (
            <View
              key={'Journeys'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.journeys}
                searchText={searchString}
              />
            </View>
          );
        },


      },
      ...(!isMasterAccountFlag ? [{
        key: 'tasks',
        path: 'tasks',
        title: 'Tasks',
        tabContainerElem: () => {
          return (
            <View
              key={'Task'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.task}
                searchText={searchString}
              />
            </View>
          );
        },
      }] : [])
      ,
      {
        key: 'labs',
        path: 'labs',
        title: 'Labs',
        tabContainerElem: () => {
          return (
            <View
              key={'Labs'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.lab}
                searchText={searchString}
              />
            </View>
          );
        },
      },
      {
        key: 'MICRO_AUTOMATION',
        path: 'microAutomation',
        title: "Micro-Automations",
        tabContainerElem: () => {
          return (
            <View key={'MICRO_AUTOMATION'}>
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.MICRO_AUTOMATION}
                searchText={searchString}
              />
            </View>
          );
        },
      },
      ...((ehrConfig.isAthena || ehrConfig.isFold) ?
        [
          {
            key: 'note',
            path: 'note',
            title: 'Visit-Note',
            tabContainerElem: () => {
              return (
                <View key={'Note'}>
                  <WorkflowView
                    isNewWorkflowView={true}
                    showTableTopBar={false}
                    flowType={FlowType.note}
                    searchText={searchString}
                  />
                </View>
              );
            }
          }
        ] : []
      ),

      ...getTabsForAthena(),
      ...getTabsForCareProgram(),
      ...(includeDBTrigger ?
        [
          {
            key: 'DB_TRIGGER',
            path: 'databaseTrigger',
            title: "Fold Objects",
            tabContainerElem: () => {
              return (
                <View key={'DB_TRIGGER'}>
                  <WorkflowView
                    isNewWorkflowView={true}
                    showTableTopBar={false}
                    flowType={FlowType.DB_TRIGGER}
                    searchText={searchString}
                  />
                </View>
              );
            },
          }
        ] : []
      ),
      // {
      //   key: 'DB_TRIGGER',
      //   path: 'databaseTrigger',
      //   title: "Fold Objects",
      //   tabContainerElem: () => {
      //     return (
      //       <View key={'DB_TRIGGER'}>
      //          <WorkflowView
      //           isNewWorkflowView={true}
      //           showTableTopBar={false}
      //           flowType={FlowType.DB_TRIGGER}
      //           searchText={searchString}
      //         />
      //       </View>
      //     );
      //   },
      // },
      {
        key: 'imaging-report',
        path: 'imaging-report',
        title: 'Imaging Report',
        tabContainerElem: () => {
          return (
            <View key={'Report'}>
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.IMAGING_REPORT}
                searchText={searchString}
              />
            </View>
          );
        }
      },
      {
        key: 'other',
        path: 'other',
        title: 'Other',
        tabContainerElem: () => {
          return (
            <View
              key={'Other'}
            >
              <WorkflowView
                isNewWorkflowView={true}
                showTableTopBar={false}
                flowType={FlowType.other}
                searchText={searchString}
              />
            </View>
          );
        },
      }
    ];
    return orderDetailTabView;
  };
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const TagBar: JSX.Element = (
    <View>
      <TableTopBar
        title={``}
        onActionPerformed={getSearch}
        alignSearchBarFlexEnd={true}
        isDividerNotVisible={true}
        buttonList={[
          {
            btnText: 'tagAdd',
            backgroundColor: Colors.secondary['100'],
            textColor: Colors.secondary['800'],
            borderColor: Colors.secondary['200'],
            variant: 'PRIMARY',
            size: 'sm',
            leftIcon: <PlusIcon />,
            popoverPosition: 'bottomLeft',
            btnClick: () => setOpenModal(true),
          },
        ]}
      />
    </View>
  );
  const containerStyle = {
    width: 'max-content',
    borderWidth: 1,
    borderColor: Colors.Custom.Gray100,
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  }
  const automationList = [
    { title: 'Patient/Leads Automation', code: FlowType.patients },
    { title: 'Communication Automation', code: FlowType.communication },
    { title: 'Appointments Automation', code: FlowType.scheduling },
    { title: 'Forms Automation', code: FlowType.form },
    { title: 'Journeys Automation', code: FlowType.journeys },
    { title: 'Tasks Automation', code: FlowType.task },
    { title: 'Labs Automation', code: FlowType.lab },
    ...( ehrConfig.isAthena || ehrConfig.isFold ? [{ title: 'Visit-Note', code: FlowType.note }] : []),
    { title: 'Micro-Automation', code: FlowType.MICRO_AUTOMATION },
    ...(includeDBTrigger ?
      [
        { title: 'Fold Objects', code: FlowType.DB_TRIGGER }
      ] : []),
    ...getListForAthena(),
    ...getListForCareProgramAutomation(),
    { title: 'Imaging Automation', code: FlowType.IMAGING_REPORT },
    { title: 'Other Automation', code: FlowType.other },

  ];

  const getContent = () => {
    return <VStack style={{}}>
      <Menu>
        {automationList.map((item) => {
          return (
            <Menu.Item
              key={item.code}
              onClick={() => {
                const url = getCreateWorkflowUrlFromFlowType(item.code);
                navigate(url);
              }}
            >
              {item.title}
            </Menu.Item>
          );
        })}
      </Menu>
    </VStack>
  }
  const workflowUrl = `create?currentTag=TRIGGER&flowType=${FlowType.all || ''
    }`;
  const AutomationTopBar: JSX.Element = (
    <View width={'93.5%'}>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        title={''}
        searchBarWidth={180}
        searchBarMinWidth={180}
        buttonList={[
          {
            btnText: 'Create',
            id: 2,
            size: 'sm',
            colorScheme: 'primary',
            textColor: Colors.secondary['900'],
            variant: '',
            backgroundColor: Colors.secondary['100'],
            borderColor: Colors.secondary['200'],
            leftIcon: <PlusIcon />,
            popoverPosition:'bottomLeft',
            content: (

              getContent()
            ),
            // dropDownType: 'MENU_DROPDOWN',
            btnClick() {
              // btnClick
            },
          },
        ]}
      />
    </View>
  );

  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setOpenModal(false);
  };

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    return AutomationTopBar;
  };

  useEffect(() => {
    const tagsList = tagsTabList();
    tagsList.some((tab: any) => {
      if (location?.pathname?.indexOf(tab.path) != -1) {
        setSelectedTab(tab);
      }
    });
  }, []);

  return (
    <View>
      {/* <View style={styles.titleWrapper} backgroundColor={'#fff'}>
        <TitleSubtitleView titleLabelId="memberManagement" />
      </View> */}
      <View
        style={[
          styles.tabsWrapper,

          commonBodyContentContainer.pageContentContainer
        ]}
      >
        <CustomTabsView
          isAddMaxTab={true}
          contentTopMargin={0}
          minScreenSupportWidth={2000}
          canNavigate={true}
          heading="automation"
          showHeading
          basePath={basePath}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          bottomOffset={150}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
    </View>
  );
};

export default WorkflowAdmin;
