import {Drawer} from 'antd';
import {
  Center,
  FormControl,
  Input,
  Spinner,
  View,
} from 'native-base';
import {useRef, useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, POWER_BI_REPORT_ERROR} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IAddNewPowerBiReportProps, IAddNewPowerBiReportState, IPowerBiCreateReportFormRef, IPowerBiFormError, IPowerBiReportFormKeys} from './interface';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {getUpdatedPowerBiErrorMessages} from './PowerBiUtils';
import {cloneReportFromDefaultReportPowerBiToken} from '../../../../../services/PowerBI/PowerBIService';


const AddNewPowerBiReport = (props: IAddNewPowerBiReportProps) => {
  const powerCreateReportRef = useRef<IPowerBiCreateReportFormRef>({} as any);
  const {width} = Dimensions.get('window');
  const finalWidth = width / 2.5;
  const [stateData, setStateData] = useState<IAddNewPowerBiReportState>({
    isLoading: false,
    errors: {} as any,
  });
  const setErrorOnChange = (formField: IPowerBiFormError) => {
    const keys = Object.keys(formField);
    const singleKey: IPowerBiReportFormKeys = keys[0] as any;
    if (formField[singleKey]) {
      setStateData((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            [singleKey]: POWER_BI_REPORT_ERROR[singleKey],
          },
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            [singleKey]: '',
          },
        };
      });
    }
  };
  const onEmailInboxCreateOrUpdate = () => {
    const errorData = getUpdatedPowerBiErrorMessages(powerCreateReportRef);

    if (errorData?.isErrorPresent) {
      setStateData((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
        };
      });
    } else {
      createNewFromExistingDefaultReport();

    }
  };
  const createNewFromExistingDefaultReport = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const bodyParams = {
      reportName: powerCreateReportRef?.current?.reportName?.value
    };
    const createReportData = await cloneReportFromDefaultReportPowerBiToken(bodyParams).catch(()=> {
      setStateData((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
    if (createReportData?.data?.reportDetails?.embedUrl) {
      props?.onAddNewPowerBiReportActionPerformed(COMMON_ACTION_CODES.NEW_POWER_BI_REPORT_CREATED, createReportData?.data);
      setStateData((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }

  }
  return (
    <Drawer
      visible={true}
      width={finalWidth}
      onClose={() => {
        props.onAddNewPowerBiReportActionPerformed(COMMON_ACTION_CODES.CLOSE);
      }}
      title={
        <ModalActionTitle
          isHeadNotSticky={true}
          title={'createNewReport'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onAddNewPowerBiReportActionPerformed(COMMON_ACTION_CODES.CLOSE);
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'create',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.PRIMARY,
              isDisabled: stateData.isLoading,
              onClick: () => {
                onEmailInboxCreateOrUpdate();
              },
            },
          ]}
        />
      }
    >
      {stateData.isLoading && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      <View>
        <FormControl  isInvalid={stateData?.errors?.reportName}>
          <FormControl.Label isRequired>
            <DisplayText
              textLocalId={'reportName'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
          </FormControl.Label>
          <Input
            style={{
              height: 40,
            }}
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            borderRadius={6}
            placeholderTextColor={Colors.Custom.Gray300}
            fontWeight={500}
            fontSize={14}
            placeholder={'Enter report name'}
            ref={(ref) =>
              (powerCreateReportRef.current['reportName'] = ref as any as any)
            }
            onChangeText={(reportName: string) => {
              setErrorOnChange({
                reportName: reportName ? false : true,
              } as any);
            }}
          />
           <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
            Report Name is required
            </FormControl.ErrorMessage>
        </FormControl>
      </View>
    </Drawer>
  );
};

export default AddNewPowerBiReport;
