import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles';


export const styles = StyleSheet.create({
  subtitleStyle: {
    paddingLeft: 1.5,
  },

  templateSectionCard: {
    padding: 8,
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderWidth: 0.5,
    borderRadius: 8,
    borderColor: Colors.FoldPixel.GRAY150,
    width:'100%'
  },

  templateSectionInput:{
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY200,
    // backgroundColor: 'white'
  },

  templateSectionInputOnFocus: {
    borderColor: Colors.FoldPixel.PRIMARY_FOCUS
  },

  tableContent:{
    fontWeight:'400',
    lineHeight:16.8,
    fontSize:14,
    color:Colors.FoldPixel.GRAY300
  },
  
  accessRoleCardStyle:{
    gap: 4,
    borderRadius: 4 ,
    backgroundColor: Colors.FoldPixel.GRAY50,
    width: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center'
  }
});
