// import {useLazyQuery, useQuery} from '@apollo/client';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Skeleton, Table} from 'antd';
// import {cloneDeep} from 'lodash';
import {Box, useToast, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
// import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {Colors} from '../../../../styles';
import {filterWorkflowUser, getAccountUUID, getUserUUID} from '../../../../utils/commonUtils';
// import AddEditUserScheduleAccess from './AddEditUserScheduleAccess';
import {getColumns} from './TaskPoolConfigurationTableHelper';
import TaskPoolConfigurationTopBar from './TaskPoolConfigurationTopBar';
import {
  IConfigureAccessUserView,
  IConfigureUserResponse,
  IConfigureAccessUser,
  IUserPoolUser,
} from './interfaces';
import {GET_USER_FOR_SCHEDULE_ACCESS} from '../../../../services/User/UserQueries';
import {getMlovId} from '../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GetUsersTaskPoolAccess} from '../../../../services/TaskPool/TaskPoolQueries';
import {cloneDeep} from 'lodash';
import AddEditUserConfigureAccess from './AddEditUserConfigureAccess';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { Dimensions } from 'react-native';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';

export interface ITaskPoolConfigurationView {
  searchString?: string;
  showTableTopBar?: boolean;
}

const TaskPoolConfigurationView = (props: ITaskPoolConfigurationView) => {
  const toast = useToast();
  const accountUUID = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const mlovData = useContext(CommonDataContext);

  const userScheduleAccessMap = new Map<string, IConfigureUserResponse[]>();
  const [accountUserQueryLoading, setUserQueryLoading] = useState(true);
  const [accountUsers, setAccountUsers] = useState<IConfigureAccessUser[]>([]);
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );
  const intl = useIntl();

  const [taskPoolConfigurationState, setTaskPoolConfigurationState] = useState<{
    searchString: string;
    loading: boolean;
    users: IConfigureAccessUserView[];
    originalUserList: IConfigureAccessUserView[];
    selectedItem: IConfigureAccessUserView;
    showDrawer: boolean;
    isRefetch: boolean;
    userScheduleAccessMap: Map<string, IConfigureUserResponse[]>;
  }>({
    searchString: '',
    loading: false,
    users: [],
    originalUserList: [],
    isRefetch: false,
    selectedItem: {} as IConfigureAccessUserView,
    showDrawer: false,
    userScheduleAccessMap: new Map<string, IConfigureUserResponse[]>(),
  });

  useEffect(() => {
    onSearch(props.searchString || '');
  }, [props.searchString]);

  const actionFn = (
    actionCode: string,
    record: IConfigureAccessUserView
  ): any => {
    setTaskPoolConfigurationState((prev) => {
      return {
        ...prev,
        selectedItem: record,
        showDrawer: true,
      };
    });
  };

  const fetchUserList = (userName: string): IConfigureAccessUserView[] => {
    return (taskPoolConfigurationState.originalUserList || []).filter(
      (user: IConfigureAccessUserView) => {
        return user.userName.toLowerCase().includes(userName.toLowerCase());
      }
    );
  };

  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const accountUsers: IConfigureAccessUser[] = [];
      if (data && data?.users && data?.users.length) {
        data.users = filterWorkflowUser(data.users, loggedInUserId);
        (data.users || []).forEach((user: any) => {
          accountUsers.push({
            userName: user.name,
            userId: user.uuid,
            email: user.email || '',
            userRoles: user.userRoles,
          });
        });
      }
      setAccountUsers(accountUsers);
      setUserQueryLoading(false);
    },
    onError: (error: any) => {

      setUserQueryLoading(false);
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.info,
      );
    },
  });

  const getUserTaskPoolAccess = useQuery(GetUsersTaskPoolAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    skip: accountUserQueryLoading,
    variables: {
      userId: accountUsers.map(
        (accountUserItem: IConfigureAccessUser) => accountUserItem.userId
      ),
      userPoolTypeId: accessUserTypeId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const userList: IConfigureAccessUserView[] =
        formatUserScheduleAccessResponse(data?.userPoolAccesses);
      setTaskPoolConfigurationState((prev) => {
        return {
          ...prev,
          loading: false,
          users: userList,
          originalUserList: cloneDeep(userList),
          isRefetch: false,
          userScheduleAccessMap,
        };
      });
    },
    onError: (error: any) => {

      setTaskPoolConfigurationState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.info,
      );
    },
  });

  const getUserByUserId = (userId: string) => {
    return (
      (accountUsers || []).find((user) => {
        return user.userId === userId;
      }) || ({} as IConfigureAccessUser)
    );
  };

  const formatUserResponseForAccessView = (
    scheduleAccessUsers: IConfigureUserResponse[],
    userScheduleAccessUser: IConfigureAccessUser
  ) => {
    const userList: IConfigureAccessUser[] = [];
    let isUserExist = false;

    if (
      scheduleAccessUsers &&
      scheduleAccessUsers.length > 0 &&
      scheduleAccessUsers[0]?.user_pool?.userPoolUsers &&
      scheduleAccessUsers[0]?.user_pool?.userPoolUsers.length > 0
    ) {
      (scheduleAccessUsers[0]?.user_pool?.userPoolUsers || []).forEach(
        (user: IUserPoolUser) => {
          const userDetail = getUserByUserId(user.userId);
          if (user.userId === userScheduleAccessUser.userId) {
            isUserExist = true;
          }
          if (userDetail) {
            userList.push({
              userId: userDetail?.userId,
              email: userDetail?.email,
              userName: userDetail?.userName,
              userRoles: userDetail.userRoles,
            });
          }
        }
      );
    }

    if (!isUserExist) {
      userList.push(userScheduleAccessUser);
    }

    return {
      userId: userScheduleAccessUser.userId,
      userName: userScheduleAccessUser.userName,
      email: userScheduleAccessUser.email,
      scheduleUsers: userList,
    };
  };

  const getUserScheduleAccessUsers = (
    userScheduleAccess: IConfigureUserResponse[],
    userId: string
  ) => {
    return (userScheduleAccess || []).filter((user: IConfigureUserResponse) => {
      return user.userId === userId;
    });
  };

  const formatUserScheduleAccessResponse = (
    userScheduleAccess: IConfigureUserResponse[]
  ): IConfigureAccessUserView[] => {
    const scheduleAccessUserList: IConfigureAccessUserView[] = [];
    (accountUsers || []).find((user: IConfigureAccessUser) => {
      const userId = user?.userId;
      const userScheduleAccessUser: IConfigureAccessUser =
        getUserByUserId(userId);
      const scheduleAccessUsers: IConfigureUserResponse[] =
        getUserScheduleAccessUsers(userScheduleAccess, userId);

      userScheduleAccessMap.set(userId, scheduleAccessUsers);
      if (loggedInUserId === userId) {
        scheduleAccessUserList.unshift(
          formatUserResponseForAccessView(
            scheduleAccessUsers,
            userScheduleAccessUser
          )
        );
      } else {
        scheduleAccessUserList.push(
          formatUserResponseForAccessView(
            scheduleAccessUsers,
            userScheduleAccessUser
          )
        );
      }
    });
    return scheduleAccessUserList;
  };

  useEffect(() => {
    getAccountUsers({
      variables: {
        accountUUID: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
    getUserTaskPoolAccess.refetch();
  }, [taskPoolConfigurationState.isRefetch]);

  const onSearch = (text: string) => {
    if (text.trim().length) {
      const userList: IConfigureAccessUserView[] =
        fetchUserList(text.trim()) || [];
      setTaskPoolConfigurationState((prev) => {
        return {
          ...prev,
          users: userList,
        };
      });
    } else {
      setTaskPoolConfigurationState((prev) => {
        return {
          ...prev,
          users: taskPoolConfigurationState.originalUserList,
        };
      });
    }
  };

  const fetchConfigureAccessUserForDrawer = () => {
    const selectedUserId = taskPoolConfigurationState?.selectedItem?.userId;
    return (accountUsers || []).filter((user) => {
      return user.userId !== selectedUserId;
    });
  };

  const fetchUserIdsForDrawer = () => {
    const selectedUserId = taskPoolConfigurationState?.selectedItem?.userId;
    return (taskPoolConfigurationState?.selectedItem?.scheduleUsers || [])
      .filter((user) => {
        return user.userId !== selectedUserId;
      })
      .map((user) => {
        return user.userId;
      });
  };

  const onAddEditPopClose = (isRefetch: boolean) => {
    setTaskPoolConfigurationState((prev) => {
      return {
        ...prev,
        selectedItem: {} as IConfigureAccessUserView,
        showDrawer: false,
        isRefetch,
      };
    });
  };

  const { height } = Dimensions.get('window');

  return (
    <View>
      <Box
        // overflow={'hidden'}
        marginBottom={4}
        backgroundColor={'#fff'}
       // style={{marginHorizontal: 16, marginTop: 20}}
        bgColor={'white'}
        borderRadius={0}
      >
        {props.showTableTopBar && (
          <TaskPoolConfigurationTopBar
            onSearch={(text: string) => {
              onSearch(text);
            }}
          />
        )}
        {!taskPoolConfigurationState.loading && !accountUserQueryLoading && (
          <Table
            rowKey={(row: any) => row.id || ''}
            columns={getColumns(actionFn)}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            dataSource={taskPoolConfigurationState.users || []}
            pagination={false}
            scroll={{y: height - 260, x: 800}}
            onRow={(data) => ({
              onClick: () => {
                setTaskPoolConfigurationState((prev) => {
                  return {
                    ...prev,
                    selectedItem: data,
                    showDrawer: true,
                  };
                });
              },
            })}
          />
        )}
        {accountUserQueryLoading ||
          (taskPoolConfigurationState.loading && (
            <View margin={4}>
              <Skeleton active />
            </View>
          ))}
      </Box>
      {taskPoolConfigurationState.showDrawer && (
        <AddEditUserConfigureAccess
          userId={taskPoolConfigurationState.selectedItem?.userId}
          userName={taskPoolConfigurationState.selectedItem?.userName}
          email={taskPoolConfigurationState.selectedItem?.email}
          scheduleUsers={fetchConfigureAccessUserForDrawer()}
          userIds={fetchUserIdsForDrawer()}
          showDrawer={taskPoolConfigurationState.showDrawer}
          onClose={onAddEditPopClose}
          userScheduleAccessList={
            taskPoolConfigurationState.userScheduleAccessMap.get(
              taskPoolConfigurationState.selectedItem?.userId
            ) || []
          }
          accessUserTypeId={accessUserTypeId}
        />
      )}
    </View>
  );
};

export default TaskPoolConfigurationView;
