export const WEB_SOCKET_EVENT_CODE = {
  PING: 'ping',
  NOTIFICATION_CREATED: 'notification.created',
  CONVERSATION_CREATED: 'conversation.created',
  CONVERSATION_READ: 'conversation.read',
  CONVERSATION_OPENED: 'conversation.opened',
  CONVERSATION_RESOLVED: 'conversation.resolved',
  CONVERSATION_STATUS_CHANGED: 'conversation.status_changed',
  CONVERSATION_CONTACT_CHANGED: 'conversation.contact_changed',
  ASSIGNEE_CHANGED: 'assignee.changed',
  TEAM_CHANGED: 'team.changed',
  CONVERSATION_TYPING_ON: 'conversation.typing_on',
  CONVERSATION_TYPING_OFF: 'conversation.typing_off',
  CONVERSATION_MENTIONED: 'conversation.mentioned',
  MESSAGE_CREATED: 'message.created',
  MESSAGE_DELETED: 'message.deleted',
  FIRST_REPLY_CREATED: 'first.reply.created',
  MESSAGE_UPDATED: 'message.updated',
  CONTACT_CREATED: 'contact.created',
  NEW_CHAT_CREATED: 'newChat.created',
  CONTACT_UPDATED: 'contact.updated',
  CONTACT_MERGED: 'contact.merged',
  CONTACT_DELETED: 'contact.deleted',
  AGENT_ADDED: 'agent.added',
  AGENT_REMOVED: 'agent.removed',
  PRESENCE_UPDATE: 'presence.update',
  GROUP_CONVERSATION_CREATED: 'GROUP_CONVERSATION_CREATED',
  GROUP_MESSAGE_CREATED: 'GROUP_MESSAGE_CREATED',
  GROUP_INFO_UPDATED: 'GROUP_INFO_UPDATED',
  PROFILE_URL_UPDATE: 'PROFILE_URL_UPDATE',
  MESSAGE_READ: 'MESSAGE_READ',
  MEMBER_ADDED: 'MEMBER_ADDED',
  MEMBER_ADDED_HEADER: 'MEMBER_ADDED_HEADER',
  ORDER_CREATED: 'ORDER_CREATED',
  REPORT_MARKED_AS_REVIEWED: 'REPORT_MARKED_AS_REVIEWED',
  LOCK_SCREEN: 'LOCK_SCREEN',
  LOCK_SCREEN_WARNING: 'LOCK_SCREEN_WARNING',
  SEND_MESSAGE: 'SEND_MESSAGE',
  NEW_GROUP_CREATED: 'NEW_GROUP_CREATED',
  CONVERSATION_CHANGED: 'CONVERSATION_CHANGED',
  CONVERSATION_UNREAD_COUNT: 'CONVERSATION_UNREAD_COUNT',
  CONVERSATION_LAST_SEEN_UPDATE: 'CONVERSATION_LAST_SEEN_UPDATE',
  GROUP_MEMBER_ADDED: 'GROUP_MEMBER_ADDED',
  GROUP_MEMBER_REMOVED: 'GROUP_MEMBER_REMOVED',
  GROUP_NAME_UPDATED: 'GROUP_NAME_UPDATED',
  REFRESH_CALENDAR: 'REFRESH_CALENDAR',
  EMAIL_INBOX_TOKEN_EXPIRY_STATUS: 'EMAIL_INBOX_TOKEN_EXPIRY_STATUS',
  SELECTED_CONVERSATION_READ: 'CONVERSATION_READ',
  TASK_ADDED: 'TASK_ADDED',
  TASK_UPDATED: 'TASK_UPDATED',
  ALERT_TYPE_TASK_ADDED: 'ALERT_TYPE_TASK_ADDED',
  ALERT_TYPE_TASK_UPDATED: 'ALERT_TYPE_TASK_UPDATED',
};

export const COMPONENT_SUPPORTED_EVENT = {
  CONVERSATION_SELECTED: 'CONVERSATION_SELECTED',
  SHOW_PIP_VIEW: 'SHOW_PIP_VIEW',
  CONVERSATION_MORE_ACTIONS: 'CONVERSATION_MORE_ACTIONS',
  CONVERSATION_UPDATED: 'CONVERSATION_UPDATED',
  TWILIO_TOKEN_RECEIVED: 'TWILIO_TOKEN_RECEIVED',
  MESSAGE_READ_FROM_NOTIFICATION: 'MESSAGE_READ_FROM_NOTIFICATION'
};

export const CALL_SUPPORTED_EVENTS = {
  CONFERENCE_CALL_EVENTS: 'CONFERENCE_CALL_EVENTS',
};

export const SUPPORTED_EVENT_CODE = {
  NOTIFICATION_CREATED: WEB_SOCKET_EVENT_CODE.NOTIFICATION_CREATED,
  CONVERSATION_CREATED: WEB_SOCKET_EVENT_CODE.CONVERSATION_CREATED,
  MESSAGE_CREATED: WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED,
  MESSAGE_UPDATED: WEB_SOCKET_EVENT_CODE.MESSAGE_UPDATED,
  MESSAGE_DELETED: WEB_SOCKET_EVENT_CODE.MESSAGE_DELETED,
  NEW_CHAT_CREATED: WEB_SOCKET_EVENT_CODE.NEW_CHAT_CREATED,
  PRESENCE_UPDATE: WEB_SOCKET_EVENT_CODE.PRESENCE_UPDATE,
  CONVERSATION_RESOLVED: WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
  CONVERSATION_OPENED: WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
  TEAM_CHANGED: WEB_SOCKET_EVENT_CODE.TEAM_CHANGED,
  ASSIGNEE_CHANGED: WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
  CONVERSATION_TYPING_ON: WEB_SOCKET_EVENT_CODE.CONVERSATION_TYPING_ON,
  CONVERSATION_TYPING_OFF: WEB_SOCKET_EVENT_CODE.CONVERSATION_TYPING_OFF,
  CONVERSATION_MENTIONED: WEB_SOCKET_EVENT_CODE.CONVERSATION_MENTIONED,
  GROUP_CONVERSATION_CREATED: WEB_SOCKET_EVENT_CODE.GROUP_CONVERSATION_CREATED,
  GROUP_MESSAGE_CREATED: WEB_SOCKET_EVENT_CODE.GROUP_MESSAGE_CREATED,
  GROUP_INFO_UPDATED: WEB_SOCKET_EVENT_CODE.GROUP_INFO_UPDATED,
  MESSAGE_READ: WEB_SOCKET_EVENT_CODE.MESSAGE_READ,
  ORDER_CREATED: WEB_SOCKET_EVENT_CODE.ORDER_CREATED,
  REPORT_MARKED_AS_REVIEWED: WEB_SOCKET_EVENT_CODE.REPORT_MARKED_AS_REVIEWED,
  LOCK_SCREEN: WEB_SOCKET_EVENT_CODE.LOCK_SCREEN,
  LOCK_SCREEN_WARNING: WEB_SOCKET_EVENT_CODE.LOCK_SCREEN_WARNING,
  SEND_MESSAGE: WEB_SOCKET_EVENT_CODE.SEND_MESSAGE,
  NEW_GROUP_CREATED: WEB_SOCKET_EVENT_CODE.NEW_GROUP_CREATED,
  CONVERSATION_STATUS_CHANGED: WEB_SOCKET_EVENT_CODE.CONVERSATION_STATUS_CHANGED,
  CONVERSATION_CHANGED: WEB_SOCKET_EVENT_CODE.CONVERSATION_CHANGED,
  CONVERSATION_UNREAD_COUNT: WEB_SOCKET_EVENT_CODE.CONVERSATION_UNREAD_COUNT,
  CONVERSATION_LAST_SEEN_UPDATE: WEB_SOCKET_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE,
  GROUP_MEMBER_ADDED: WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_ADDED,
  GROUP_MEMBER_REMOVED: WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_REMOVED,
  GROUP_NAME_UPDATED: WEB_SOCKET_EVENT_CODE.GROUP_NAME_UPDATED,
  EMAIL_INBOX_TOKEN_EXPIRY_STATUS: WEB_SOCKET_EVENT_CODE.EMAIL_INBOX_TOKEN_EXPIRY_STATUS,
  SELECTED_CONVERSATION_READ: WEB_SOCKET_EVENT_CODE.SELECTED_CONVERSATION_READ,
};

export const TASK_SUPPORTED_EVENTS = {
  TASK_ADDED: WEB_SOCKET_EVENT_CODE.TASK_ADDED,
  TASK_UPDATED: WEB_SOCKET_EVENT_CODE.TASK_UPDATED,
  ALERT_TYPE_TASK_ADDED: WEB_SOCKET_EVENT_CODE.ALERT_TYPE_TASK_ADDED,
  ALERT_TYPE_TASK_UPDATED: WEB_SOCKET_EVENT_CODE.ALERT_TYPE_TASK_UPDATED,
};


export const SUPPORTED_EVENT_CODE_LIST = [
  ...Object.values(SUPPORTED_EVENT_CODE),
  ...Object.values(COMPONENT_SUPPORTED_EVENT),
  ...Object.values(CALL_SUPPORTED_EVENTS),
  ...Object.values(TASK_SUPPORTED_EVENTS),
];


export const CUSTOM_MESSAGE_EVENT_CODES = {
  NEW_MESSAGE_CREATED: 'NEW_MESSAGE_CREATED',
  MARK_US_UNREAD_MENTION: 'MARK_US_UNREAD_MENTION',
  USER_ONLINE_STATUS: 'USER_ONLINE_STATUS',
  REFRESH_CONVERSATION_ON_USER_ONLINE: 'REFRESH_CONVERSATION_ON_USER_ONLINE',
  JOURNEY_UPDATED: 'JOURNEY_UPDATED',
  SIDECAR_CONVERSATION_UNMOUNT: 'SIDECAR_CONVERSATION_UNMOUNT'
}

export const EVENT_MODULE_CODES = {
  PATIENT_JOURNEY: 'PATIENT_JOURNEY'
}

export const CUSTOM_COMMON_EVENT_CODES = {
  UPDATE_STICKY_NOTES: 'UPDATE_STICKY_NOTES',
  USER_PREFERENCE_UPDATED: 'USER_PREFERENCE_UPDATED',
}


export const CONVERSATION_LOCAL_EVENT_CODES = {
  NEW_GROUP_CREATED: 'NEW_GROUP_CREATED',
  CONVERSATION_CREATED: 'CONVERSATION_CREATED',
  GROUP_CONVERSATION_CREATED: 'GROUP_CONVERSATION_CREATED',
  CONVERSATION_LAST_SEEN_UPDATE: 'CONVERSATION_LAST_SEEN_UPDATE',
  CONVERSATION_UPDATED: 'CONVERSATION_UPDATED',
  NEW_MESSAGE_CREATED: 'NEW_MESSAGE_CREATED',
  MESSAGE_READ_FROM_NOTIFICATION: 'MESSAGE_READ_FROM_NOTIFICATION',
  CONTACT_DETAIL_UPDATE: 'CONTACT_DETAIL_UPDATE',
  MARK_US_UNREAD_MENTION: 'MARK_US_UNREAD_MENTION',
  LOCAL_GROUP_CONVERSATION_CREATED: 'LOCAL_GROUP_CONVERSATION_CREATED',
  LOCAL_CONVERSATION_ARCHIVED: 'LOCAL_CONVERSATION_ARCHIVED',
  LOCAL_CONVERSATION_UN_ARCHIVED: 'LOCAL_CONVERSATION_UN_ARCHIVED',
  LOCAL_CONVERSATION_ASSIGNED: 'LOCAL_CONVERSATION_ASSIGNED',
  LOCAL_CONVERSATION_ASSIGNEE_CHANGED: 'LOCAL_CONVERSATION_ASSIGNEE_CHANGED',
  LOCAL_CONVERSATION_UN_ASSIGNED: 'LOCAL_CONVERSATION_UN_ASSIGNED',
  CONVERSATION_MORE_ACTIONS_FROM_DRAWER: 'CONVERSATION_MORE_ACTIONS_FROM_DRAWER',
  CONVERSATION_MORE_ACTIONS: 'CONVERSATION_MORE_ACTIONS',
  ADD_CONTACT_NOTE: 'ADD_CONTACT_NOTE',
  LOCAL_SCHEDULE_APPOINTMENT: 'LOCAL_SCHEDULE_APPOINTMENT',
  LOCAL_MARK_AS_READ_CONVERSATION: 'LOCAL_MARK_AS_READ_CONVERSATION',
  LOCAL_CHANGE_MESSAGE_USER_PREFERENCE: 'LOCAL_CHANGE_MESSAGE_USER_PREFERENCE',
  LOCAL_CONVERSATION_CONTACT_UPDATE: 'LOCAL_CONVERSATION_CONTACT_UPDATE',
  LOCAL_BULK_ARCHIVE_CONVERSATION: 'LOCAL_BULK_ARCHIVE_CONVERSATION,',
  LOCAL_CONVERSATION_ASSIGNEE_ACTION_BUS: 'LOCAL_CONVERSATION_ASSIGNEE_ACTION_BUS',
  LOCAL_CONVERSATION_STATUS_CHANGED_ACTION_BUS: 'LOCAL_CONVERSATION_STATUS_CHANGED_ACTION_BUS',
  LOCAL_CONVERSATION_BUS_ARCHIVED: 'LOCAL_CONVERSATION_BUS_ARCHIVED',
  LOCAL_CONVERSATION_BUS_UN_ARCHIVED: 'LOCAL_CONVERSATION_BUS_UN_ARCHIVED',
  CONVERSATION_STATUS_CHANGED_EVENT: 'CONVERSATION_STATUS_CHANGED_EVENT',
  LOCAl_UPDATE_SELECTED_CONVERSATION: 'LOCAl_UPDATE_SELECTED_CONVERSATION',
  LOCAL_CONVERSATION_MSG_READ_ACTION_BUS: 'LOCAL_CONVERSATION_MSG_READ_ACTION_BUS',
  LOCAL_CONVERSATION_MSG_READ: 'LOCAL_CONVERSATION_MSG_READ',
  LOCAL_PCP_USER_CHANGE: 'LOCAL_PCP_USER_CHANGE',
}
export const LOCAL_SUPPORTED_EVENT_CODE_LIST = [
  ...Object.values(CONVERSATION_LOCAL_EVENT_CODES),
];

