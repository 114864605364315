import {Drawer} from 'antd';
import {useMediaQuery, View, VStack} from 'native-base';
import React, {useEffect} from 'react';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../constants';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import HintPlanDetailView from '../../../../common/HintPlanDetailView/HintPlanDetailView';
import {IConversationData} from '../interfaces';
import {MessagingContactDetails} from './MessagingContactDetails';

const MessagingContactDetailsDrawer = (props: {
  selectedConversation?: IConversationData;
  isDrawerVisible: boolean;
  onSideBarActionPerformed: (actionCode: string, actionData?: any) => void;
  contactData?: any;
  contactType?: any;
  borderLessView?: boolean;
  isEmployerDrillDown?: boolean
}) => {
  const {
    selectedConversation,
    isDrawerVisible,
    borderLessView,
    onSideBarActionPerformed,
    contactData,
    isEmployerDrillDown
  } = props;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const onClose = () => {
    onSideBarActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
  };

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60%' : '30%';

  return (
    <>
      <>
        <Drawer
          title={''}
          placement={'right'}
          width={drawerWidth}
          onClose={onClose}
          closable={true}
          visible={isDrawerVisible}
          bodyStyle={{padding:0}}
          headerStyle={{display: 'none'}}
        >
          <VStack height="full" overflow={'hidden'}>
            <MessagingContactDetails
              selectedConversation={selectedConversation}
              key={selectedConversation?.id}
              onConversationActionPerformed={(actionCode?: string) => {
                if (actionCode === CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
                  onClose();
                } else if (
                  actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED
                ) {
                  onSideBarActionPerformed(
                    COMMON_ACTION_CODES.ADDED_OR_UPDATED
                  );
                }
              }}
              btnTextVisibility={{
                // showMeetBtn: true,
                // showChatBtn : true,
                showAppointmentBtn: true,
                showCharts: true,
                showViewProfile: true,
                showSmsBtn: true,
                showEmailBtn: true
              }}
              contactId={contactData?.id}
              borderLessView={borderLessView}
              hintExtraDetail={<HintPlanDetailView id={props?.contactData?.id} isEmployerReportDrillDown={false}/>}
              isEmployerDrillDown={isEmployerDrillDown}
              // extra={
              //   <View>
              //     <PersonActionPopover
              //       personData={props?.contactData}
              //       personType={
              //         props?.contactData?.contactType?.contactType?.code as any
              //       }
              //       onActionPerformed={props.onSideBarActionPerformed}
              //       // hintPatientData={{
              //       //   openInHint: openInHint,
              //       //   hintPatientId: hintPatientId,
              //       // }}
              //     />
              //   </View>
              // }
            />
          </VStack>
        </Drawer>
      </>
    </>
  );
};

export default MessagingContactDetailsDrawer;
