import {TCommonActionTypes} from './interface';

export const COMMON_ACTION_CODES: {[index in TCommonActionTypes]: string} = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  ROW_CLICKED: 'ROW_CLICKED',
  SAVE: 'SAVE',
  CANCEL: 'CANCEL',
  EDIT_MODE: 'EDIT_MODE',
  ITEM_SELECT: 'ITEM_SELECT',
  COPY: 'COPY',
  ITEM_CLICKED: 'ITEM_CLICKED',
  LOAD_MORE: 'LOAD_MORE',
  PATIENT_CHANGED: 'PATIENT_CHANGED',
  STATUS_CHANGED: 'STATUS_CHANGED',
  PRIORITY_CHANGED: 'PRIORITY_CHANGED',
  DUE_DATE_CHANGED: 'DUE_DATE_CHANGED',
  RESET_DUE_DATE: 'RESET_DUE_DATE',
  RESET_PRIORITY: 'RESET_PRIORITY',
  RESET_STATUS: 'RESET_STATUS',
  RESET_PATIENT: 'RESET_PATIENT',
  RESET_POOL: 'RESET_POOL',
  CLEAR_ALL: 'CLEAR_ALL',
  SORT_CHANGED: 'SORT_CHANGED',
  DATE_CHANGED: 'DATE_CHANGED',
  DATE_RANGED_CHANGED: 'DATE_RANGED_CHANGED',
  FILTER_CHANGED: 'FILTER_CHANGED',
  RESET_FILTER_TO_DEFAULT: 'RESET_FILTER_TO_DEFAULT',
  IMPORT: 'IMPORT',
  NO_DATA_FOUND: 'NO_DATA_FOUND',
  FILTER_ICON_CLICKED: 'FILTER_ICON_CLICKED',
  RESET: 'RESET',
  ADD: 'ADD',
  MAIL: 'MAIL',
  CALL: 'CALL',
  SMS: 'SMS',
  MERGE: 'MERGE',
  CONVERT: 'CONVERT',
  ADD_RELATION: 'ADD_RELATION',
  SET_TAG: 'SET_TAG',
  CONVERT_TO_CUSTOMER: 'CONVERT_TO_CUSTOMER',
  CONVERT_TO_LEAD: 'CONVERT_TO_LEAD',
  ADD_NOTE: 'ADD_NOTE',
  ASSIGN_USER: 'ASSIGN_USER',
  REMOVE_USER: 'REMOVE_USER',
  EMAIL: 'EMAIL',
  CLONE: 'CLONE',
  BACK: 'BACK',
  PREVIEW: 'PREVIEW',
  RESPONSE: 'RESPONSE',
  UPDATE: 'UPDATE',
  ALL: 'ALL',
  CONTACT_TYPE_SELECTED: 'CONTACT_TYPE_SELECTED',
  COMPLETED: 'COMPLETED',
  ON_USER_PRACTICE_LOCATION_SELECT: 'ON_USER_PRACTICE_LOCATION_SELECT',
  ON_BULK_ACTION: 'ON_BULK_ACTION',
  LIST_VIEW: 'LIST_VIEW',
  CREATE_CHANNEL: 'CREATE_CHANNEL',
  ADDED_OR_UPDATED: 'ADDED_OR_UPDATED',
  EMAIL_SENT: 'EMAIL_SENT',
  MESSAGE_TYPE_CHANGE: 'MESSAGE_TYPE_CHANGE',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_POPUP: 'CLOSE_POPUP',
  ENABLE: 'ENABLE',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  INBOX_TYPE_CHANGE: 'INBOX_TYPE_CHANGE',
  EDIT_EMPLOYER: 'EDIT_EMPLOYER',
  MANAGE_DEAL: 'MANAGE_DEAL',
  QUICK_CREATE: 'QUICK_CREATE',
  CREATE_EMPLOYER: 'CREATE_EMPLOYER',
  EDIT_MEMBER: 'EDIT_MEMBER',
  CREATE_MEMBER: 'CREATE_MEMBER',
  CREATE_OPPORTUNITY: 'CREATE_OPPORTUNITY',
  REQUEST_CONSENT: 'REQUEST_CONSENT',
  BULK_IMPORT: 'BULK_IMPORT',
  ADD_MANUALLY: 'ADD_MANUALLY',
  CONNECT_TO_HINT: 'CONNECT_TO_HINT',
  CONNECT_TO_ELATION: 'CONNECT_TO_ELATION',
  CONNECT_TO_SPRUCE: 'CONNECT_TO_SPRUCE',
  CREATE_AUTOMATION: 'CREATE_AUTOMATION',
  CREATE_GROUP: 'CREATE_GROUP',
  DOCUMENT_CLICK: 'DOCUMENT_CLICK',
  DOWNLOAD_CLICK: 'DOWNLOAD_CLICK',
  DELETED: 'DELETED',
  FLOW_UPDATED: 'FLOW_UPDATED',
  ADD_IVR_FLOW: 'ADD_IVR_FLOW',
  TASK_COMPLETE: 'TASK_COMPLETE',
  TASK_CANCEL: 'TASK_CANCEL',
  DEACTIVATE: 'DEACTIVATE',
  READ_DRAWER_CANCEL: 'READ_DRAWER_CANCEL',
  REFETCH: 'REFETCH',
  SHOW_GRAPH: 'SHOW_GRAPH',
  ADD_TASK: 'ADD_TASK',
  ADD_SCHEDULE: 'ADD_SCHEDULE',
  ON_REFRESH_ACTION: 'ON_REFRESH_ACTION',
  CALL_END: 'CALL_END',
  CALL_REJECT: 'CALL_REJECT',
  CLOUD_CALL: 'CLOUD_CALL',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SEND_FORMS: 'SEND_FORMS',
  CREATE_TASK: 'CREATE_TASK',
  ARCHIVED: 'ARCHIVED',
  ON_NAVIGATE_TO_DETAIL: 'ON_NAVIGATE_TO_DETAIL',
  EDIT_LEAD: 'EDIT_LEAD',
  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  TEXT_MESSAGE: 'TEXT_MESSAGE',
  TOGGLE_SMS_INBOX: 'TOGGLE_SMS_INBOX',
  BULK_EMAIL: 'BULK_EMAIL',
  BULK_SMS: 'BULK_SMS',
  CLEAR_MAIL: 'CLEAR_MAIL',
  ADD_FORM: 'ADD_FORM',
  CONSENT_WARNING: 'CONSENT_WARNING',
  SETUP_NUMBER: 'SETUP_NUMBER',
  AddNoteAction: 'AddNoteAction',
  EditNoteAction: 'EditNoteAction',
  CHANGE_PAGE: 'CHANGE_PAGE',
  SUCCESS: 'SUCCESS',
  SEND_MEMBERSHIP_LINK: 'SEND_MEMBERSHIP_LINK',
  LINK_IP_PHONE: 'LINK_IP_PHONE',
  VIEW_LINKED_IP_PHONES: 'VIEW_LINKED_IP_PHONES',
  CHAT: 'CHAT',
  CHANGE_TABLE_FILTER_SORTER: 'CHANGE_TABLE_FILTER_SORTER',
  PRINT_CLINICAL_PROFILE: 'PRINT_CLINICAL_PROFILE',
  COPY_MESSAGE_LIST: 'COPY_MESSAGE_LIST',
  NO_CONTACT_FOUND: 'NO_CONTACT_FOUND',
  DUPLICATE: 'DUPLICATE',
  FAILED: 'FAILED',
  ON_TABLE_VIEW_CHANGE: 'ON_TABLE_VIEW_CHANGE',
  LOADING: 'LOADING',
  LOADING_COMPLETE: 'LOADING_COMPLETE',
  SHOW_DETAIL_VIEW: 'SHOW_DETAIL_VIEW',
  RESET_LABELS: 'RESET_LABELS',
  UNASSIGN: 'UNASSIGN',
  SHARE: 'SHARE',
  SELECT_GROUP: 'SELECT_GROUP',
  SEARCH: 'SEARCH',
  SELECTED_BOARD_TYPE: 'SELECTED_BOARD_TYPE',
  CLONE_JOURNEY: 'CLONE_JOURNEY',
  DETAIL_VIEW: 'DETAIL_VIEW',
  CLOSE: 'CLOSE',
  UPLOAD: 'UPLOAD',
  DOWNLOAD: 'DOWNLOAD',
  NEXT: 'NEXT',
  CHANGE_ARCHIVE_ON_SEND: 'CHANGE_ARCHIVE_ON_SEND',
  VIEW_ANALYTICS: 'VIEW_ANALYTICS',
  VIEW_TASK: 'VIEW_TASK',
  VIEW_TASK_CLOSE: 'VIEW_TASK_CLOSE',
  TASK_UPDATE: 'TASK_UPDATE',
  MESSAGE_INFO: 'MESSAGE_INFO',
  ASSIGNEE_CHANGE: 'ASSIGNEE_CHANGE',
  SHOW_FILTER_INFO_CHANGE: 'SHOW_FILTER_INFO_CHANGE',
  USER_CHANGE: 'USER_CHANGE',
  USER_REMOVE:'USER_REMOVE',
  CUSTOM_FIELD_ADDED: 'CUSTOM_FIELD_ADDED',
  CUSTOM_FIELD_VALUE_CHANGE: 'CUSTOM_FIELD_VALUE_CHANGE',
  ATTRIBUTE_CHANGE: 'ATTRIBUTE_CHANGE',
  ATTRIBUTE_FILTER_CONDITION_CHANGE: 'ATTRIBUTE_FILTER_CONDITION_CHANGE',
  ATTRIBUTE_FILTER_VALUE_CHANGE: 'ATTRIBUTE_FILTER_VALUE_CHANGE',
  SEND_PROVIDER_REQUEST_FORM: 'SEND_PROVIDER_REQUEST_FORM',
  CARE_PROGRAM_CONSENT_AND_ENROLL: 'CARE_PROGRAM_CONSENT_AND_ENROLL',
  DISMISS_INTRO_CARD: 'DISMISS_INTRO_CARD',
  SELECT_ALL: 'SELECT_ALL',
  ECM_OUTREACH_VIEW: 'ECM_OUTREACH_VIEW',
  PHI_WARNING: 'PHI_WARNING',
  NEW_POWER_BI_REPORT_CREATED: 'NEW_POWER_BI_REPORT_CREATED',
  COMPLETE: 'COMPLETE',
  SEND_EMAIL: 'SEND_EMAIL',
  PREFERENCES: 'PREFERENCES',
  SHOW_CARE_PROGRAM_ASSESSMENT: 'SHOW_CARE_PROGRAM_ASSESSMENT',
  SHOW_CARE_PROGRAM_CONSENT: 'SHOW_CARE_PROGRAM_CONSENT',
  SHOW_CARE_PROGRAM_AWV_VISIT: 'SHOW_CARE_PROGRAM_AWV_VISIT',
  ATTIONABLE_TASK_VIEW: 'ATTIONABLE_TASK_VIEW',
};

export const CONVERSATION_ACTION_CODES = {
  MESSAGE_READ: 'MESSAGE_READ',
  CONTACT_TYPING: 'CONTACT_TYPING',
  CONVERSATION_HEADER_BTN_CLICKED: 'CONVERSATION_HEADER_BTN_CLICKED',
  DRAWER_OPEN: 'DRAWER_OPEN',
  DRAWER_CLOSE: 'DRAWER_CLOSE',
  START_CONVERSATION: 'START_CONVERSATION',
  ON_PATIENT_SELECTION: 'ON_PATIENT_SELECTION',
  SEND_ATTACHMENT: 'SEND_ATTACHMENT',
  MESSAGE_TYPE_CHANGE: 'MESSAGE_TYPE_CHANGE',
  GROUP_CREATE: 'GROUP_CREATE',
  START_GROUP_CHAT: 'START_GROUP_CHAT',
  CHAT_WITH_PATIENT: 'CHAT_WITH_PATIENT',
  CHAT_WITH_INTERNAL_USERS: 'CHAT_WITH_INTERNAL_USERS',
  START_EMAIL: 'START_EMAIL',
  START_SMS: 'START_SMS',
  START_VIDEO_MEET: 'START_VIDEO_MEET',
  GROUP_CONVERSATION_DATA_LOADING: 'GROUP_CONVERSATION_DATA_LOADING',
  CHART: 'CHART',
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  FAVOURITE: 'FAVOURITE',
  IS_DETAILS_CONTAINER_VISIBLE: 'IS_DETAILS_CONTAINER_VISIBLE',
  FILES: 'FILES',
  SCHEDULE_MESSAGE_LIST: 'SCHEDULE_MESSAGE_LIST',
  COPY: 'COPY',
  MESSAGE_READ_FROM_NOTIFICATION: 'MESSAGE_READ_FROM_NOTIFICATION',
  SELECT_CHAT: 'SELECT_CHAT',
  SEARCH_MESSAGE: 'SERACH_MESSAGE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  ASSIGN: 'ASSIGN',
  ASSIGNED: 'ASSIGNED',
  EDIT: 'EDIT',
  CONVERSATION_SELECTION: 'CONVERSATION_SELECTION',
  SELECT_ALL_CONVERSATION: 'SELECT_ALL_CONVERSATION',
  DESELECT_ALL_CONVERSATION: 'DESELECT_ALL_CONVERSATION',
  CONVERSATION_DESELECTION: 'CONVERSATION_DESELECTION',
  CONVERSATION_LIST_LENGTH: 'CONVERSATION_LIST_LENGTH',
  PATIENT_REGARDING_UPDATE: 'PATIENT_REGARDING_UPDATE',
  START_EFAX: 'START_EFAX',
  UPDATE_TO_CONTACT: 'UPDATE_TO_CONTACT',
  SHOW_BULK_ACTION: 'SHOW_BULK_ACTION',
  CONVERSATION_GROUP_MEMBER: 'CONVERSATION_GROUP_MEMBER',
  CONVERSATION_INBOX_MEMBER: 'CONVERSATION_INBOX_MEMBER',
  OPEN_INSTANT_CHAT: 'OPEN_INSTANT_CHAT',
};

export const GROUP_ACTION_CODES = {
  GROUP_CREATE: 'GROUP_CREATE',
  GROUP_HEADER_CLICKED: 'GROUP_HEADER_CLICKED',
  GROUP_HEADER_CLICKED_REDIRECT: 'GROUP_HEADER_CLICKED_REDIRECT',
  ADD_MEMBER_IN_DEAFULT_GROUP: 'ADD_MEMBER_IN_DEAFULT_GROUP'
};

export const CONTACT_ACTION_CODES = {
  ON_USER_PRACTICE_LOCATION_SELECT: 'ON_USER_PRACTICE_LOCATION_SELECT',
};

export const FORM_ACTION_CODES = {
  FORM_RESPONSE_VIEW: 'FORM_RESPONSE_VIEW',
  PENDING_FORM_STATUS_VIEW: 'PENDING_FORM_STATUS_VIEW',
};

export const PATIENT_QUICK_PROFILE_PARENT_CODES = {
  MESSAGING: 'MESSAGING',
  CONTACT_LISTING: 'CONTACT_LISTING',
  CONTACT_OMNI_VIEW: 'CONTACT_OMNI_VIEW',
  CALL_LOG: 'CALL_LOG',
  APPOINTMENT_DETAIL: 'APPOINTMENT_DETAIL',
  TASK_DETAIL: 'TASK_DETAIL',
  ADD_UPDATE_ORDER: 'ADD_UPDATE_ORDER',
};

export const QUICK_ACTION_CODES = {
  ADD_NEW_PATIENT: 'ADD_NEW_PATIENT',
  ADD_NEW_TASK: 'ADD_NEW_TASK',
  ADD_NEW_CAMPAIGN: 'ADD_NEW_CAMPAIGN',
  ADD_NEW_EMPLOYER: 'ADD_NEW_EMPLOYER',
  ADD_PATIENT_GROUP: 'ADD_PATIENT_GROUP',
  CREATE_VIDEO_MEETING: 'CREATE_VIDEO_MEETING',
  CREATE_VOICE_MEETING: 'CREATE_VOICE_MEETING',
  INVITE_PRACTICE_USER: 'INVITE_PRACTICE_USER',
  ADD_CARE_JOURNEY: 'ADD_CARE_JOURNEY',
  CREATE_SMS: 'CREATE_SMS',
  CREATE_EMAIL: 'CREATE_EMAIL',
  CREATE_CHAT: 'CREATE_CHAT',
  CREATE_EFAX: 'CREATE_EFAX',
};

export const MEMBER_SATISFACTION_ACTION_CODE = {
  TOTAL_FORM_SENT: 'TOTAL_FORM_SENT',
  RESPONDED: 'RESPONDED',
  NOT_RESPONDED: 'NOT_RESPONDED',
};

export const NOT_ENGAGED_MEMBER_FILTER = {
  MONTH_3: 'MONTH_3',
  MONTH_6: 'MONTH_6',
  MONTH_12: 'MONTH_12',
};

