import {Spinner, Text, View} from 'native-base';
import {ITaskPoolDropDown, ITaskPools} from './Interfaces';
import {styles} from './style';
import {Checkbox, Input, Popover,Tooltip} from 'antd';
import {Colors} from '../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import {getMlovId} from '../../../../utils/mlovUtils';
import {useContext, useEffect, useState} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getCurrentUserRole, getUserData} from '../../../../utils/commonUtils';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {ILoginUserData, IUserRoleCode} from '../../../../Interfaces';
import {debounce} from 'lodash';
import { Dimensions } from 'react-native';

const TaskPoolDropDown = (props: ITaskPoolDropDown) => {
  const {taskPoolsIds, onTaskPoolChange, isDrillDown} = props;
  const [taskPoolsData, setTaskPoolsData] = useState({
    loading: false,
    taskPoolList: [] as ITaskPools[],
    selectedTaskPoolList: [] as ITaskPools[],
    selectedTaskPools: '',
  });
  const currentUserRoles: IUserRoleCode[] = getCurrentUserRole();
  const {width} = Dimensions.get('window');
  const userData: ILoginUserData = getUserData();
  const isBusinessOwner: boolean = currentUserRoles.some((role: any) => {
    return USER_ROLE_CODES.BUSINESS_OWNER === role?.code;
  });
  const mlovData = useContext(CommonDataContext);
  const taskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );
  const [getTaskPools] = useLazyQuery(TaskPoolQueries.GET_USER_POOLS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const onTaskPoolSearch = async (searchString: string) => {
    try {
      setTaskPoolsData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      let taskPoolParams;
      if (searchString && isBusinessOwner) {
        taskPoolParams = {
          typeId: {_eq: taskPoolTypeId},
          isDeleted: {_eq: false},
          name: {_ilike: `%${searchString}%`},
        };
      } else if (searchString && !isBusinessOwner) {
        taskPoolParams = {
          typeId: {_eq: taskPoolTypeId},
          isDeleted: {_eq: false},
          userPoolUsers: {userId: {_eq: userData?.uuid}},
          name: {_ilike: `%${searchString?.trim()}%`},
        };
      }
      const taskPoolApiResponse = await getTaskPools({
        variables: {whereCondition: taskPoolParams},
      });
      let taskPools = taskPoolApiResponse?.data?.userPools || [];
      if (taskPools?.length === 0) {
        setTaskPoolsData((prev) => {
          return {
            ...prev,
            loading: false,
            taskPoolList: [...prev?.selectedTaskPoolList],
          };
        });
      } else {
        setTaskPoolsData((prev: any) => {
          if (taskPoolsData?.selectedTaskPoolList?.length) {
            taskPools = taskPools.filter((label: any) => {
              return !prev.selectedTaskPoolList.some(
                (selectedLabel: any) => selectedLabel?.name === label?.name
              );
            });
          }
          return {
            ...prev,
            loading: false,
            taskPoolList: prev?.selectedTaskPoolList
              ? [...prev?.selectedTaskPoolList, ...taskPools]
              : [...taskPools],
          };
        });
      }
    } catch (error) {
      setTaskPoolsData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
    }
  };
  const getSelectedTaskPool = async () => {

    if (taskPoolsIds?.length) {
      const taskPoolParams = {
        id: {
          _in: [...taskPoolsIds]
        }
      }
      const taskPoolApiResponse = await getTaskPools({
        variables: {whereCondition: taskPoolParams},
      });
      let taskPools = taskPoolApiResponse?.data?.userPools || [];
      if (taskPoolsData?.selectedTaskPoolList?.length) {
        taskPools = taskPools.filter((label: any) => {
          return taskPoolsData?.selectedTaskPoolList.some(
            (selectedLabel: any) => selectedLabel?.name === label?.name
          );
        });
      }
      let selectedTaskPools = ''
      if (taskPools?.length === 1) {
        selectedTaskPools = `: ${taskPools?.[0]?.name?.toString()}`;
      } else if (taskPools?.length > 1) {
        selectedTaskPools = `: ${taskPools?.[0]?.name?.toString()} • + ${
          taskPools?.length - 1
        }`;
      }
      setTaskPoolsData(prev=> {
        return {
          ...prev,
          selectedTaskPoolList: [...taskPools],
          taskPoolList: [...taskPools],
          selectedTaskPools: selectedTaskPools
        }
      })
    }
  }

  useEffect(()=> {
    if (isDrillDown) {
      getSelectedTaskPool()
    }
  },[])
  useEffect(()=> {
    let selectedTaskPools = ''
    if (taskPoolsIds?.length) {
      if (taskPoolsData.selectedTaskPoolList?.length === 1) {
        selectedTaskPools = `: ${taskPoolsData.selectedTaskPoolList?.[0]?.name?.toString()}`;
      } else if (taskPoolsData.selectedTaskPoolList?.length > 1) {
        selectedTaskPools = `: ${taskPoolsData.selectedTaskPoolList?.[0]?.name?.toString()} • + ${
          taskPoolsData.selectedTaskPoolList?.length - 1
        }`;
      }
    }
      setTaskPoolsData(prev=> {
        return {
          ...prev,
          selectedTaskPools: selectedTaskPools
        }
      })
  },[taskPoolsIds?.length])
  return (
    <View style={styles.labelsDropDown}>
      <Popover
        content={
          <View>
            <Text bold>Search Task Pool</Text>
            <Input
              style={{borderRadius: 4, marginTop: 6}}
              placeholder="Search Task Pools"
              onChange={(text) => {
                debounce((value) => {
                  onTaskPoolSearch(value?.target?.value?.toLowerCase());
                }, 500)(text);
              }}
            ></Input>
            <Checkbox.Group
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              value={taskPoolsIds}
              defaultValue={taskPoolsIds}
              onChange={(values: any) => {
                const selectedTaskPoolList =
                  taskPoolsData?.taskPoolList?.filter((poolList) => {
                    return values.includes(poolList.id);
                  });
                setTaskPoolsData((prev) => {
                  return {
                    ...prev,
                    selectedTaskPoolList: selectedTaskPoolList,
                  };
                });

                onTaskPoolChange(values);
              }}
            >
              {taskPoolsData?.loading ? (
                <Spinner size="sm" marginTop={2} />
              ) : (
                taskPoolsData?.taskPoolList?.map((option: any) => (
                  <Checkbox
                    style={{
                      margin: 5,
                      fontWeight: 500,
                      color: '#3A485F',
                      fontSize: 15,
                    }}
                    key={option?.id}
                    value={option?.id}
                  >
                    {option?.name}
                  </Checkbox>
                ))
              )}
            </Checkbox.Group>
          </View>
        }
        placement="bottomLeft"
        trigger="click"
      >
        <Tooltip title={taskPoolsData?.selectedTaskPools} placement="bottom">
          <Text cursor={'pointer'}>
            Task Pools
            <View style={{maxWidth: props.maxWidth || width / 16}}>
              <Text fontWeight="bold" isTruncated noOfLines={1} maxW="90%">
                {taskPoolsData?.selectedTaskPools}
              </Text>
            </View>
          </Text>
        </Tooltip>
        <AntIcon
          name="down"
          color={Colors.Custom.Gray600}
          size={12}
          style={{marginLeft: 4}}
        />
      </Popover>
    </View>
  );
};

export default TaskPoolDropDown;
