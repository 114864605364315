import CommonService from "../CommonService/CommonService";
import {ICloneReportResp, IPowerBITokenParams, IPowerBITokenResp, IPowerBIWorkspaceResp} from "./interface";

export const getPowerBIWorkspaces = async (): Promise<{data:IPowerBIWorkspaceResp}> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.get('api/v1/power-bi/workspace');
};

export const getPowerBITokenInfoForReport = async (bodyData: IPowerBITokenParams): Promise<{data:IPowerBITokenResp}> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('api/v1/power-bi/token', JSON.stringify(bodyData));
};


export const cloneReportFromDefaultReportPowerBiToken = async (bodyData: {
  reportName: string
}): Promise<{data:ICloneReportResp}> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('api/v1/power-bi/report/clone', JSON.stringify(bodyData));
};
