import {View} from 'native-base';
import React from 'react';
import {ViewStyle} from 'react-native';
import QRCode from 'react-qr-code';

interface QRCodeViewProps {
  containerStyle?: ViewStyle;
  qrCodeStyle?: React.CSSProperties;
  url: string;
}

const QRCodeView = (props: QRCodeViewProps) => {
  const {containerStyle, qrCodeStyle, url} = props;
  return (
    <View style={containerStyle}>
      <QRCode
        size={256}
        style={{...qrCodeStyle}}
        value={url}
        viewBox={`0 0 256 256`}
      />
    </View>
  );
};

export default QRCodeView;
