import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import {IInbox, IInboxOptions} from '../../../../../services/Inbox/interfaces';
import { getCurrentUserRole, isEmployerRole } from '../../../../../utils/commonUtils';
import {ITabsList} from '../../../../common/CustomTabsView/interfaces';
import {CHANNEL_TYPE_CODE} from '../ConversationConst';
import Conversations from '../Conversations';
import {getChannelList} from './ConversationChannelTabConst';
import {ICreateEmailConversationDrawerState} from './CreateEmailConversationDrawer/interface';
import {ICreateSmsConversationDrawerState} from './CreateSmsConversationDrawer/interface';
import {IDisplayChannelList, IUserInboxList} from './interfaces';

export const getInboxTypeTabList = (
  selectedInbox: IInbox,
  conversationFilterQuery: any,
  conversationFilterProps: any,
  onActionPerformed: any,
  allInboxList: IUserInboxList,
  filterModal?: (isFilterDrawerOpen: boolean) => void,
): ITabsList[] => {
  const inboxTypeTabList: ITabsList[] = [] as any;

  getChannelList(isEmployerRole()).forEach(
    (singleChannelData: IDisplayChannelList) => {
      inboxTypeTabList.push({
        key: singleChannelData.key,
        title: singleChannelData.title,
        path: singleChannelData.path,
        backgroundColor: singleChannelData.backgroundColor,
        showLeftBorder: singleChannelData.showLeftBorder,
        tabContainerElem: (): JSX.Element => {
          return (
            <Conversations
              selectedInboxTypeCode={singleChannelData.key}
              selectedInbox={selectedInbox}
              conversationFilterQuery={conversationFilterQuery}
              conversationFilterProps={conversationFilterProps}
              onActionPerformed={onActionPerformed}
              allInboxList={allInboxList}
              filterModal={filterModal}

            />
          );
        },
      });
    }
  );
  return inboxTypeTabList;
};

export const getUpdatedCreateSMSConversationErrorMessages = (
  createSmsConversationDrawerState: ICreateSmsConversationDrawerState, contactData?: any
): any => {
  let patientErrorMsgStr = '';
  let messageErrorStr = '';
  let isErrorPresent = false;

  if (!createSmsConversationDrawerState?.selectedPatient?.id && !contactData?.id) {
    patientErrorMsgStr = 'Please select member or type number';
    isErrorPresent = true;
  }

  if (!createSmsConversationDrawerState.messageStr) {
    messageErrorStr = 'Message is required';
    isErrorPresent = true;
  }
  return {
    errors: {
      patient: patientErrorMsgStr,
      message: messageErrorStr,
    },
    isErrorPresent,
  };
};

export const getUpdatedCreateEmailConversationErrorMessages = (
  createSmsConversationDrawerState: ICreateEmailConversationDrawerState
): any => {
  let patientErrorMsgStr = '';
  let messageErrorStr = '';
  let subjectErrorStr = '';
  let isErrorPresent = false;

  if (!createSmsConversationDrawerState?.selectedPatient?.id) {
    patientErrorMsgStr = 'Please select patient';
    isErrorPresent = true;
  }

  if (!createSmsConversationDrawerState.messageStr) {
    messageErrorStr = 'Message is required';
    isErrorPresent = true;
  }
  if (!createSmsConversationDrawerState.subject) {
    subjectErrorStr = 'Subject is required';
    isErrorPresent = true;
  }
  return {
    errors: {
      patient: patientErrorMsgStr,
      message: messageErrorStr,
      subject: subjectErrorStr,
    },
    isErrorPresent,
  };
};

export const formatInboxDataForSelectOptions = (
  inboxDataList: IInbox[]
): IInboxOptions[] => {
  const inboxOptionsList: any = [...inboxDataList];
  inboxOptionsList.forEach((singleInbox: IInboxOptions) => {
    singleInbox.label = singleInbox?.name;
    if (singleInbox?.channelTwilioSms?.phoneNumber) {
      singleInbox.label +=
        ' (' + singleInbox?.channelTwilioSms?.phoneNumber + ')' || '';
    }
    singleInbox.key = singleInbox.id + '';
    singleInbox.value = singleInbox.id + '';
  });
  return inboxOptionsList;
};
