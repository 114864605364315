import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles';
export const styles = StyleSheet.create({
  container: {
  },
  textContainer: {
    fontWeight: '400',
    fontSize: 24,
    color: Colors.Custom.Gray500,
  },
  left: {
  },
  leftText: {
    fontSize: 18,
    lineHeight: 32,
    fontWeight: '500',
  },
});
