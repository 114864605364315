import {useMutation, useQuery} from '@apollo/client';
import {Table} from 'antd';
import {Skeleton} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions, View} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {MLOV_CATEGORY, RECENT_ITEM_TYPES} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  LeadQueries,
  RecentItemsQueries,
} from '../../../../../../../services/index';
import {
  getAccountId,
  getUserUUID,
} from '../../../../../../../utils/commonUtils';
import {EventBus} from '../../../../../../../utils/EventBus';
import {getContactTypeId, getMlovId} from '../../../../../../../utils/mlovUtils';
import {getContactFiltersObject} from '../../../../ContactsUtils';
import {
  handleSorting,
  onPagination,
} from '../../../../Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import {getCustomerTableViewColumns} from '../Helper/CustomerListViewColumns';
import {ICustomerListViewProps} from '../Interfaces';
import {formateCustomerListData} from './CustomerListHelper';
import './CustomerListView.css';
import { withMiniContactViewHOC } from '../../../../../../MiniContactViewHOC';

const CustomerListView = (props: ICustomerListViewProps): JSX.Element => {
  const mlovData = useContext(CommonDataContext);
  const tagItem: any = props.tagItem;
  const inboxItem = props.inboxItem;
  const searchString = props.searchText;
  const accountId = getAccountId();
  const contacTypeId = getContactTypeId('CUSTOMER');
  const [createRecentpatient] = useMutation(
    RecentItemsQueries.CREATE_RECENT_ITEM
  );
  const navigate = useNavigate();
  const userUUID = getUserUUID();
  const recentPatientType = getMlovId(
    mlovData.MLOV,
    MLOV_CATEGORY.RECENT_ITEM_TYPES,
    RECENT_ITEM_TYPES.PATIENTS
  );
  const filter: any = {
    _or: [],
    contactTagging: {},
  };
  const [stateData, setStateData] = useState({
    searchText: props.searchText,
    searchedColumn: '',
    filteredInfo: null,
    sortedInfo: null,
    isLoading: true,
    leadList: [],
    currentPage: 1,
    offSet: 0,
    pageSize: 10,
    sortD: '',
    order_by: {
      updatedAt: 'desc',
    },
  });
  useEffect(() => {
    setStateData({...stateData, pageSize: 10, offSet: 0, currentPage: 1});
  }, [searchString]);
  const {loading, data} = useQuery(LeadQueries.GetContacts, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      limit: stateData.pageSize || 10,
      offset: stateData.offSet,
      searchString: '%' + searchString + '%',
      sortD: stateData.sortD || undefined,
      order_by: stateData.order_by,
      contact_filters: getContactFiltersObject(
        filter,
        searchString,
        accountId,
        tagItem,
        inboxItem,
        contacTypeId
      ),
    },
  });

  const formattedData = formateCustomerListData(data, mlovData);

  let {sortedInfo}: any = {...stateData};
  sortedInfo = sortedInfo || {};

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setStateData({...stateData, pageSize: pagination.pageSize});
    setStateData({
      ...stateData,
      filteredInfo: filters,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
      offSet: onPagination(pagination.current, pagination.pageSize),
      order_by: handleSorting(sorter, stateData.order_by),
    });
  };

  const onDeleteCustomer = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.DELETE, rowData);
  };

  const onEditCustomer = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.EDIT, rowData);
  };
  const onConvertTo = (rowData: any, actionCode: any) => {
    props?.onActionPerformed(actionCode, rowData);
  };
  const onAddRelationAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.ADD_RELATION, rowData);
  };

  const onSetTag = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.SET_TAG, rowData);
  };
  const onMailAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.MAIL, rowData);
  };

  const onActionPerformed = (actionCode: string, rowData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        onDeleteCustomer(rowData);
        break;
      case COMMON_ACTION_CODES.EDIT:
        onEditCustomer(rowData);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_LEAD:
        onConvertTo(rowData, actionCode);
        break;
      case COMMON_ACTION_CODES.ADD_RELATION:
        onAddRelationAction(rowData);
        break;
      case COMMON_ACTION_CODES.MAIL:
        onMailAction(rowData);
        break;
      case COMMON_ACTION_CODES.SET_TAG:
        onSetTag(rowData);
        break;
    }
  };

  const handleAddRecentPateint = async (contactUUID: string) => {
    const responseData = await createRecentpatient({
      variables: {
        accountId: accountId,
        itemUUID: contactUUID,
        recentItemTypeUUID: recentPatientType,
        userUuid: userUUID,
      },
    })
      if(responseData?.data?.insert_recent_items?.id){
      }
  };
  const onRowClicked = (rowData: any) => {
    const contactUUID = rowData.singleCustomerData.uuid;
    handleAddRecentPateint(contactUUID);
    props?.navigateOrOpenContactIdDrawer?.(rowData.id);

    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent('ADD_RECENT_MEMEBER', rowData.singleCustomerData);
  };
  const {height} = Dimensions.get('window');
  const finalHeight = height - 401;
  return (
    <>
      {loading ? (
        <>
          <View style={{padding: '20px'}}>
            <Skeleton.Text lines={10} />
          </View>
        </>
      ) : (
        <Table

          rowKey={(record) => {
            return record.id;
          }}
          scroll={{x: 800, y: finalHeight}}
          className="lead-list-table-view"
          columns={getCustomerTableViewColumns(sortedInfo, onActionPerformed)}
          dataSource={formattedData}
          onChange={handleChange}
          // rowSelection={{
          //   type: 'checkbox',
          //   ...rowSelection,
          // }}
          onRow={(_record: any) => {
            return {
              onClick: () => {
                onRowClicked(_record);
                // props?.onActionPerformed(
                //   COMMON_ACTION_CODES.ROW_CLICKED,
                //   _record
                // );
              },
            };
          }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: stateData.pageSize,
            total: data?.contactAggregate?.aggregate?.count,
            current: stateData.currentPage,
            onChange: () => {
              // onPagination(page)
            },
          }}
        />
      )}
    </>
  );
};
export default withMiniContactViewHOC(CustomerListView);
