import { Button, HStack, Spinner, Text, VStack, View } from 'native-base';
import { useState, useEffect } from 'react';
import { Pressable } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FlowType } from '../../../../../../context/WorkflowContext';
import { Colors } from '../../../../../../styles';
import { getWorkflowBasePath, getWorkflowUrlFromFlowType } from '../../../workflowUtils';
import WorkflowGraphPopup from '../../WorkflowTableView/Helper/WorkflowGraphPopup';
import { IWorkflowHeader } from './IWorkflowHeader';
import { findIsAllowToRunAutomation } from '../../../../../../utils/commonUtils';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import Feather from 'react-native-vector-icons/Feather';
import { Divider,Tooltip } from 'antd';
import { getWorkflowExecutionDataByWorkflowMasterId } from '../WorkflowApi';
import { TAB_KEYS } from '../../../../constants';

const WorkflowHeader = (props: IWorkflowHeader) => {
  const pathParams = useParams();
  const [workflowMasterId] = useState(props?.workflowMasterId || pathParams.workflowId);
  const setCurrentTab = props.setCurrentTab;
  const tabList = props.tabList;
  const [currentTb, setCurrentTb] = useState(0);
  const navigate = useNavigate();
  const isAllowToRunAutomation = findIsAllowToRunAutomation();
  const [showAutomationAudit, setShowAutomationAudit] = useState(false);
  const workflowName = props?.workflowName;
  const [aggregateCount, setAggregateCount] = useState(0);
  const [statsStatus,setStatsStatus] = useState({ color: Colors.FoldPixel.GRAY300, disabled: true })
  const flowType = props?.flowType

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const defaultParamsOnTabOpen = (tabKey: string, isDrawerOpen: string | undefined) => {
    if (tabKey) {
      const currentParams = new URLSearchParams(searchParams.toString());
      currentParams.set('key', tabKey);
      if (isDrawerOpen === 'true') {
        currentParams.set('isdraweropen', 'true');
      } else {
        currentParams.delete('isdraweropen');
      }
      setSearchParams(currentParams.toString());
    }
  };

  const setParamsOnClose = (params: string[]) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    params.forEach(param => currentParams.delete(param));
    setSearchParams(currentParams.toString());
  };

  useEffect(() => {
    if (searchParams.get('isdraweropen') === 'true') {
      setShowAutomationAudit(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (workflowMasterId) {
      getWorkflowExecutionDataByWorkflowMasterId(workflowMasterId).then((response) => {
        const data = response?.data;
        const formattedData = {
          completed: data.completed.aggregate.count,
          failed: data.failed.aggregate.count,
          incomplete: data.incomplete.aggregate.count,
          queued: data.queued.aggregate.count,
          totalCount: data.total.aggregate.count,
        };

        setAggregateCount(formattedData.totalCount);

        if (formattedData.totalCount > 0) {
          setStatsStatus({ color: Colors.FoldPixel.STATUS_DARK_SUCCESS, disabled: false });
        } else {
          setStatsStatus({ color: Colors.FoldPixel.GRAY300, disabled: true });
        }
      });
    }
  }, [workflowMasterId]);

  return (
    <>
      {workflowMasterId ?
        <WorkflowGraphPopup workflowData={{
          workflowMasterId: workflowMasterId || '',
          flowType: props?.flowType
        }} flowType={props?.flowType} isShow={showAutomationAudit} onClose={() => {
          setShowAutomationAudit(false);
          setParamsOnClose(['isdraweropen', 'key', 'fromDate', 'toDate']);
        }}></WorkflowGraphPopup> : <></>}
      <HStack bgColor={'white'} py={3} px={8} justifyContent={'space-between'} >
        <HStack alignItems={'center'} flex={8} >
          <Tooltip style={{maxWidth: 520}} title={workflowName || ''} placement='bottomLeft'>
            <Text color={Colors.FoldPixel.GRAY400} fontSize={20} fontWeight={600} numberOfLines={1} >{workflowName}</Text>
          </Tooltip>
        </HStack>
        <HStack space={1} alignItems={'center'} paddingX={2} flex={2} justifyContent={'flex-end'}>
          {workflowMasterId && props.flowType !== FlowType.ivr && props.flowType !== FlowType.careJourney ?
            <HStack alignItems={'center'}>
              <Pressable
                onPress={() => {
                  setShowAutomationAudit(true)
                  defaultParamsOnTabOpen(TAB_KEYS.OVERVIEW, 'true')
                }}
                disabled={statsStatus.disabled}
              >
                <View
                  style={{
                    backgroundColor: Colors.FoldPixel.TRANSPARENT,
                    justifyContent: 'center',
                    alignItems: 'center'

                  }}
                >
                <Tooltip title={statsStatus.disabled ? 'No Data Available' : 'View Analytics'} placement={'top'}>
                  <Feather
                    name='bar-chart-2'
                    size={22}
                    color={statsStatus.color}
                  />
                </Tooltip>
                </View>
              </Pressable>
            </HStack>
            : <></>
          }
          {workflowMasterId && props.flowType !== FlowType.ivr && props.flowType !== FlowType.careJourney ?
            <Divider style={{ backgroundColor: Colors.FoldPixel.GRAY150 }} type='vertical'></Divider> : <></>
          }
          {isAllowToRunAutomation && props?.isShowTestAndExecute && props.flowType !== FlowType.ivr && props.flowType !== FlowType.careJourney &&
          <Pressable
            onPress={() => {
              props.setShowGoalReviewModal(true, true);
            }}
          >
            <View
              style={{
                backgroundColor: Colors.FoldPixel.TRANSPARENT,
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 60

              }}
            >
              {
                (props.isSaveLoading && props.isTest) ? <Spinner></Spinner> :
                  <HStack alignItems={'center'} justifyContent={'center'}>
                    <Feather
                      name='play'
                      size={22}
                      color={Colors.FoldPixel.GRAY300}
                    />
                    {
                      <DisplayText
                        textLocalId={'Test'}
                        size={'smBold'}
                        extraStyles={{
                          color: Colors.FoldPixel.GRAY300
                        }}
                      />
                    }
                  </HStack>
              }
            </View>
          </Pressable>}

          {isAllowToRunAutomation && props?.isShowTestAndExecute && props.flowType !== FlowType.ivr && props.flowType !== FlowType.careJourney &&
            <Divider style={{ backgroundColor: Colors.FoldPixel.GRAY150 }} type='vertical'></Divider>
          }
          
          <Pressable
            onPress={() => {
              props.setShowGoalReviewModal(true);
            }}
            disabled={props.isSaveLoading && !props.isTest}
          >
            <View
              style={{
                backgroundColor: Colors.primary[300],
                height: '32px',
                justifyContent: 'center',
                borderRadius: 4,
                minWidth: 60,
                alignItems: 'center'

              }}
            >
              {
                (props.isSaveLoading && !props.isTest) ? <Spinner></Spinner> :
                  <DisplayText
                    textLocalId={'Save'}
                    size={'smBold'}
                    extraStyles={{
                      color: 'white'
                    }}
                  />
              }
            </View>
          </Pressable>
          <Divider style={{ backgroundColor: Colors.FoldPixel.GRAY150 }} type='vertical'></Divider>
          <Pressable
            onPress={() => {
              if (props?.flowType && props?.flowType === FlowType.ivr) {
                const navigationPath = getWorkflowBasePath(props?.flowType);
                return navigate(navigationPath);
              } else {
                const navigationPath = getWorkflowUrlFromFlowType(flowType, true)
                navigate(navigationPath);
              }
            }}
          >
            <View justifyContent={'center'} height={'36px'} >
              <Feather
                name='x'
                size={22}
                color={Colors.FoldPixel.GRAY300}
              />
            </View>
          </Pressable>
        </HStack>
      </HStack>
    </>
  );
};

export default WorkflowHeader;
