import React, {useEffect} from 'react';
import {
  FILTER_MENU_KEYS,
  ITabViewProps,
  ITimelineMap,
} from '../../interfaces';
import {
  getDiagnosticReportByPatientId,
  getMedicationRequestByPatientId,
  getRequestGroupByPatientId,
} from '../../../../../../services/CommonService/OrderService';
import {MedicationRequest, RequestGroup} from 'fhir/r4';
import {getDiagnosticReportResponseFormatted, getReportReferences} from '../../../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import {
  getAllOrdersTimelineData
} from '../../utils';
import OrderTimeLine from '../OrderTimeLine/OrderTimeLine';
import {Spinner, VStack} from 'native-base';
import {IRequestGroup} from '../../../../LeftContainer/RecentActivity/OrdersInterface';
import { useLazyQuery } from '@apollo/client';
import { GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS } from '../../../../../../services/User/UserQueries';
import { OrderType } from '../../../Orders/OrdersAndReports/OrderConstants';
import { isOrderTypeEnabled } from '../../../../../../utils/capabilityUtils';
import { getAuthorId, getRequesterId } from '../../../Orders/OrdersAndReports/OrderUtils';
const AllOrders = (props: ITabViewProps) => {
  const {notesData, personData, searchQuery, activeFilter, handleNoteSelect} =
    props;
  const patientId = personData?.patientId || personData?.patientUuid;
  const accountLocationUuid = personData?.accountLocationUuid || '';
  const isLabEnabled = isOrderTypeEnabled(OrderType.LAB);
  const isRadEnabled = isOrderTypeEnabled(OrderType.RAD);
  const isMedEnabled = isOrderTypeEnabled(OrderType.MED);
  const [componentState, setComponentState] = React.useState({
    loading: false,
    timeLineData: [] as ITimelineMap[],
    prevTimeLineData: [] as ITimelineMap[],
    reports: [],
    reportMap: {},
    orders: [] as IRequestGroup[],
    medList: [] as MedicationRequest[],
  });

  const [getUserList] = useLazyQuery(
    GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.accountUsers.length) {
          const userIdNameMap: any = {};
          data.accountUsers.forEach((item: any) => {
            if (item.user?.uuid) {
              userIdNameMap[item.user.uuid] = item.user.name;
            }
          });
          setComponentState((prev) => {
            const list = prev.medList;
            const orders = prev.orders;
            list.forEach((med) => {
              const userId = getRequesterId(med);
              if (userId && med.requester && userIdNameMap[userId]) {
                med.requester.display = userIdNameMap[userId];
              }
            })
            orders.forEach((order) => {
              const userId = getAuthorId(order as RequestGroup);
              if (userId && order.author && userIdNameMap[userId]) {
                order.author.display = userIdNameMap[userId];
              }
            })
            return {
              ...prev,
              medList: [...list],
              loading: false,
            }
          });
        }
      },
      onError: () => {
        setComponentState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
  );

  const fetchData = () => {
    setComponentState((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    Promise.all([
      isLabEnabled || isRadEnabled ? getRequestGroupByPatientId(patientId || '', accountLocationUuid) : Promise.resolve(),
      isLabEnabled || isRadEnabled ? getDiagnosticReportByPatientId(patientId || '', accountLocationUuid) : Promise.resolve(),
      isMedEnabled ? getMedicationRequestByPatientId(patientId || '', accountLocationUuid) : Promise.resolve(),
    ])
      .then((responses) => {
        const userUUIDs: string[] = [];
        let list: IRequestGroup[] =
          responses?.[0]?.data?.entry?.map((item: any) => item.resource) || [];
          const {reports, reportMap} = getDiagnosticReportResponseFormatted(
            responses?.[1]
          );
        if (isLabEnabled || isRadEnabled) {
          (reports || []).forEach(report => {
            const referenceIds: string[] = getReportReferences(report);
            if (referenceIds?.length) {
              referenceIds.forEach((reference) => {
                list = list.map((order) => {
                  const actions = order?.action?.map((action) => {
                    if (reference.includes(action?.resource?.reference || '')) {
                      action.resource.report = report;
                    }
                    return action;
                  });
                  return {
                    ...order,
                    action: actions,
                  };
                });
              });
            }
          });
        }
        list.forEach((order) => {
          if (!order.author?.display) {
            const userUUID = getAuthorId(order as RequestGroup);
            if (userUUID && !userUUIDs.includes(userUUID)) {
              userUUIDs.push(userUUID);
            }
          }
        })

        const medicationRequests: MedicationRequest[] = [];
        if (isMedEnabled) {
          responses[2]?.data?.entry?.forEach((item: {resource: MedicationRequest}) => {
            const medOrder = item.resource;
            if (medOrder) {
              medicationRequests.push(medOrder);
              const userUUID = getRequesterId(medOrder);
              if (userUUID && !userUUIDs.includes(userUUID)) {
                userUUIDs.push(userUUID);
              }
            }
          });
        }
        if (userUUIDs.length) {
          getUserList({
            variables: {
              userIds: userUUIDs.filter((uuid) => uuid !== '-1'),
              externalUserIds: [],
            }
          });
        }
        let orderType;
        if (!isLabEnabled && isRadEnabled) orderType = 'RADIOLOGY';
        if (isLabEnabled && !isRadEnabled) orderType = 'LAB';
        const completeTimeLineData = getAllOrdersTimelineData(
          list as RequestGroup[],
          medicationRequests,
          notesData,
          { searchQuery: '', activeFilter: {}, orderType },
        );
        const timeLineData = getAllOrdersTimelineData(
          list as RequestGroup[],
          medicationRequests,
          notesData,
          { searchQuery, activeFilter, orderType },
        );
        setComponentState((prev: any) => {
          return {
            ...prev,
            loading: !!userUUIDs.length,
            timeLineData: timeLineData,
            prevTimeLineData: completeTimeLineData,
            reports: reports,
            reportMap: reportMap,
            orders: list,
            medList: medicationRequests,
          };
        });
      })
      .catch((error) => {
        setComponentState((prev: any) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };

  useEffect(() => {
    if (searchQuery === '' && !Object.keys(activeFilter || {}).length) {
      setComponentState((prev) => ({
        ...prev,
        timeLineData: componentState.prevTimeLineData,
      }));
    } else {
      let orderType;
      if (!isLabEnabled && isRadEnabled) orderType = 'RADIOLOGY';
      if (isLabEnabled && !isRadEnabled) orderType = 'LAB';
      const timeLineData = getAllOrdersTimelineData(
        componentState.orders as RequestGroup[],
        componentState.medList,
        notesData,
        { searchQuery, activeFilter, orderType },
      );
      setComponentState((prev) => ({
        ...prev,
        timeLineData: timeLineData,
      }));
    }
  }, [searchQuery, activeFilter]);


  useEffect(() => {
    fetchData();
  }, [props.notesData]);

  return componentState.loading ? (
    <VStack h={500} justifyContent="center">
      <Spinner size="lg" />
    </VStack>
  ) : (
    <OrderTimeLine
      unformmatedContactDetails={props.unformmatedContactDetails}
      personData={personData}
      reports={componentState.reports}
      timeLineMap={componentState.timeLineData}
      orders={componentState.orders}
      handleNoteSelect={handleNoteSelect}
      refreshData={fetchData}
    />
  );
};

export default AllOrders;
