import {Image, Switch} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {HStack, Pressable, Text, View, VStack} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../../../styles';
import {getCommerceSubdomain} from '../../../../../../../utils/commonUtils';
import {capitalizeText} from '../../../../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import PlusIcon from '../../../../../../common/Svg/PlusSvg';
import {ITableTopBarProps} from '../../../../../../common/TableTopBar';
import {ActionsView} from '../ActionsView';
import {ICareJourneyStatusIDs, IProductTableDisplayData} from '../ProductTable/interfaces';
import {TagView} from '../TagView';
import { getCareJourneyStatusTitleById } from './ProductTableUtils';
import { COMMON_ACTION_CODES } from '../../../../../../../constants/ActionConst';
import { JOURNEY_START_TYPES, JOURNEY_START_TYPE_VALUES } from '../../ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { getDateStrFromFormat, getDisplayDateFormatted } from '../../../../../../../utils/DateUtils';
import { DATE_FORMATS, DISPLAY_DATE_FORMAT } from '../../../../../../../constants';
import React, {useState} from 'react';
import {initialJourneyDescriptionLength} from '../../ProductConst';

export const getCareJourneyTableColumns = (
  onActionPerformed: (
    actionCode: string,
    rowData: any,
    checked?: boolean
  ) => void,
  journeyStatusIds: ICareJourneyStatusIDs,
  isCareJourneyTable?: any,
  isProductTable?: any,
): ColumnsType<any> => {
  const [stateData,setStateData] = useState({
    showMoreText: {} as any,
  })
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: true,
      // sorter: (nameOne: any, nameTow: any) => {
      //   nameOne.name.localeCompare(nameTow.name);
      // },
      // ellipsis: true,
      render: (
        text: string,
        selectedRecord: IProductTableDisplayData
      ): JSX.Element => {
        const imagesList = selectedRecord.imageData;
        let imageUrl = '';
        if (
          imagesList &&
          imagesList.length &&
          imagesList[0] &&
          imagesList[0].attributes &&
          imagesList[0].attributes.original_url
        ) {
          imageUrl = `https://${getCommerceSubdomain()}${
            imagesList[0].attributes.original_url
          }`;
        }
        const startDateTime =
          selectedRecord.startType === JOURNEY_START_TYPE_VALUES.FIXED
            ? `(${getDateStrFromFormat(
                selectedRecord.startDateTime || '',
                DISPLAY_DATE_FORMAT
              )})`
            : '';
        const displayText =
          selectedRecord.startType && startDateTime
            ? `${selectedRecord.startType} ${startDateTime}`
            : selectedRecord.startType
            ? selectedRecord.startType
            : JOURNEY_START_TYPE_VALUES.ROLLING;
        return (
          <Pressable onPress={() => onActionPerformed(COMMON_ACTION_CODES.EDIT,selectedRecord)}>
            <Text fontSize={'xs'} color={Colors.Custom.Gray500}>{displayText}</Text>
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {text || '-'}
            </Text>
          </Pressable>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '45%',
      render: (text: string[], selectedRecord: any): JSX.Element => {
        const id = selectedRecord?.id || '';
        return (
          <>
            {text.length <= initialJourneyDescriptionLength && <Text>{text}</Text>}
            {text.length > initialJourneyDescriptionLength && <Text>
              {stateData?.showMoreText?.[id] ? text : text.slice(0,initialJourneyDescriptionLength) + '...'}
            </Text>}
            {text.length > initialJourneyDescriptionLength && <Text color={Colors.Custom.mainPrimaryPurple} fontWeight={600}>
              <Pressable onPress={() => {
                setStateData((prev) => {
                  const showMoreText: any = prev.showMoreText;
                  showMoreText[id] = showMoreText[id] ? false : true;
                  return {
                    ...prev,
                    showMoreText: showMoreText,
                  }
                })
              }}>
                {stateData?.showMoreText[id] ? '  Read less' : '  Read more'}
              </Pressable>
            </Text>}
          </>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="status"
        />
      ),
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (statusId: string,selectedRecord: any): JSX.Element => {
        const isChecked = statusId === journeyStatusIds.published;
        return (
          <Switch
            style={{
              backgroundColor: isChecked
                ? Colors.Custom.SuccessColor
                : Colors.Custom.Gray400,
                width: 90
            }}
            onChange={() =>
              onActionPerformed(
                COMMON_ACTION_CODES.STATUS_CHANGED,
                selectedRecord
              )
            }
            loading={false}
            checkedChildren={getCareJourneyStatusTitleById(
              journeyStatusIds.published,
              journeyStatusIds
            )}
            unCheckedChildren={getCareJourneyStatusTitleById(
              journeyStatusIds.draft,
              journeyStatusIds
            )}
            checked={isChecked}
          />
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="status"
    //     />
    //   ),
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '10%',
    //   sorter: (nameOne: any, nameTow: any) => {
    //     nameOne.name.localeCompare(nameTow.name);
    //   },
    //   ellipsis: true,
    //   render: (statusText: string, selectedRecord: any): JSX.Element => {
    //     let isAvailable = false;
    //     if (statusText === ProductStatus.AVAILABLE) {
    //       isAvailable = true;
    //     }
    //     return (
    //       <ProductActiveSwitch
    //         isAvailable={isAvailable}
    //         onSwitchChange={(checked, event) => {
    //           onActionPerformed(
    //             COMMON_ACTION_CODES.STATUS_CHANGED,
    //             selectedRecord,
    //             checked
    //           );
    //           event.preventDefault();
    //           event.stopPropagation();
    //         }}
    //       />
    //     );
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="price"
    //     />
    //   ),
    //   dataIndex: 'mrpData',
    //   key: 'mrpData',
    //   width: '10%',
    //   render: (mrpData: {mrp: string; currency: string}): JSX.Element => {
    //     return (
    //       <View>
    //         {mrpData.mrp && mrpData.currency ? (
    //           <Text size={'smMedium'} color={Colors.Custom.Gray900}>
    //             {mrpData.mrp}
    //           </Text>
    //         ) : (
    //           <Text color={'gray.400'}>--</Text>
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="sellingPrice"
    //     />
    //   ),
    //   dataIndex: 'sellingPrice',
    //   key: 'sellingPrice',
    //   width: '10%',
    //   sorter: true,
    //   render: (text: string): JSX.Element => {
    //     return (
    //       <View>
    //         {text ? (
    //           <Text size={'smMedium'} color={Colors.Custom.Gray900}>
    //             {text}
    //           </Text>
    //         ) : (
    //           <Text color={'gray.400'} style={{textAlign: 'center'}}>
    //             --
    //           </Text>
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '15%',
      // fixed: 'right',
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            <ActionsView
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord);
              }}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};
export const getProductTableColumns = (
  onActionPerformed: (
    actionCode: string,
    rowData: any,
    checked?: boolean
  ) => void,
  isCareJourneyTable?: any,
  isProductTable?: any
): ColumnsType<any> => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      fixed: 'left',
      sorter: true,
      // sorter: (nameOne: any, nameTow: any) => {
      //   nameOne.name.localeCompare(nameTow.name);
      // },
      // ellipsis: true,
      render: (
        text: string,
        selectedRecord: IProductTableDisplayData
      ): JSX.Element => {
        const imagesList = selectedRecord.imageData;
        let imageUrl = '';
        if (
          imagesList &&
          imagesList.length &&
          imagesList[0] &&
          imagesList[0].attributes &&
          imagesList[0].attributes.original_url
        ) {
          imageUrl = `https://${getCommerceSubdomain()}${
            imagesList[0].attributes.original_url
          }`;
        }
        return (
          <HStack alignItems={'center'} space={2}>
            {imageUrl ? (
              <VStack>
                <Pressable>
                  <Image
                    src={imageUrl}
                    preview={{
                      src: imageUrl,
                    }}
                    height={48}
                    width={48}
                  />
                </Pressable>
              </VStack>
            ) : (
              <VStack>
                <AntIcon
                  name="picture"
                  size={48}
                  color={Colors.Custom.IconColorGray}
                />
              </VStack>
            )}
            <VStack marginRight={4}>
              {text ? (
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {text}
                </Text>
              ) : (
                <Text color={'gray.400'}>-</Text>
              )}
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="categories"
        />
      ),
      dataIndex: 'taxonStrList',
      key: 'taxonStrList',
      width: '40%',
      render: (taxonStrList: string[]): JSX.Element => {
        return <TagView stringList={taxonStrList} />;
      },
      responsive: ['md'],
    },

    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="status"
    //     />
    //   ),
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '10%',
    //   sorter: (nameOne: any, nameTow: any) => {
    //     nameOne.name.localeCompare(nameTow.name);
    //   },
    //   ellipsis: true,
    //   render: (statusText: string, selectedRecord: any): JSX.Element => {
    //     let isAvailable = false;
    //     if (statusText === ProductStatus.AVAILABLE) {
    //       isAvailable = true;
    //     }
    //     return (
    //       <ProductActiveSwitch
    //         isAvailable={isAvailable}
    //         onSwitchChange={(checked, event) => {
    //           onActionPerformed(
    //             COMMON_ACTION_CODES.STATUS_CHANGED,
    //             selectedRecord,
    //             checked
    //           );
    //           event.preventDefault();
    //           event.stopPropagation();
    //         }}
    //       />
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="price"
        />
      ),
      dataIndex: 'mrpData',
      key: 'mrpData',
      width: '10%',
      render: (mrpData: {mrp: string; currency: string}): JSX.Element => {
        return (
          <View>
            {mrpData.mrp && mrpData.currency ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {/* <FormattedNumber
                  value={parseFloat(mrpData.mrp)}
                  style="currency"
                  currency={mrpData.currency}
                /> */}
                {mrpData.mrp}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="sellingPrice"
        />
      ),
      dataIndex: 'sellingPrice',
      key: 'sellingPrice',
      width: '10%',
      sorter: true,
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {text}
              </Text>
            ) : (
              <Text color={'gray.400'} style={{textAlign: 'center'}}>
                --
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '20%',
      fixed: 'right',
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            <ActionsView
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord);
              }}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};

export const getMembershipTableColumns = (
  onActionPerformed: (
    actionCode: string,
    rowData: any,
    checked?: boolean
  ) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (
        text: string,
        selectedRecord: IProductTableDisplayData
      ): JSX.Element => {
        const imagesList = selectedRecord.imageData;
        let imageUrl = '';
        if (
          imagesList &&
          imagesList.length &&
          imagesList[0] &&
          imagesList[0].attributes &&
          imagesList[0].attributes.original_url
        ) {
          imageUrl = `https://${getCommerceSubdomain()}${
            imagesList[0].attributes.original_url
          }`;
        }
        return (
          <HStack alignItems={'center'} space={2}>
            {imageUrl ? (
              <VStack>
                <Image
                  src={imageUrl}
                  preview={{
                    src: imageUrl,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  height={48}
                  width={48}
                />
              </VStack>
            ) : (
              <VStack>
                <AntIcon
                  name="picture"
                  size={48}
                  color={Colors.Custom.IconColorGray}
                />
              </VStack>
            )}
            <VStack marginRight={4}>
              {text ? (
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {text}
                </Text>
              ) : (
                <Text color={'gray.400'}>-</Text>
              )}
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="categories"
        />
      ),
      dataIndex: 'taxonStrList',
      key: 'taxonStrList',
      width: '40%',
      ellipsis: true,
      render: (taxonStrList: string[]): JSX.Element => {
        return <TagView stringList={taxonStrList} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="monthlyPrice"
        />
      ),
      // dataIndex: 'mrpData',
      // key: 'mrpData',
      width: '10%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data?.prices?.monthly;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency} {price}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="quarterlyPrice"
        />
      ),
      // dataIndex: 'mrpData',
      // key: 'mrpData',
      width: '10%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data?.prices?.quarterly;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency} {price}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="annuallyPrice"
        />
      ),
      // dataIndex: 'mrpData',
      // key: 'mrpData',
      width: '10%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data.prices?.yearly;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency} {price}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '10%',
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            <ActionsView
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord);
              }}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};

export const getProductListTopBarButtons = (
  type: string,
  buttonClickFnList: (() => void)[]
): ITableTopBarProps => {
  return {
    title: '',
    buttonList: [
      {
        id: 0,
        btnText: `new`,
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        leftIcon: (
          <PlusIcon />
        ),
        btnClick: () => {
          buttonClickFnList[0]();
        },
      },
      // {
      //   id: 0,
      //   btnText: `createNewWidget`,
      //   size: 'sm',
      //   colorScheme: 'primary',
      //   backgroundColor: Colors.secondary['100'],
      //   borderColor: Colors.secondary['200'],
      //   textColor: Colors.secondary['800'],
      //   variant: '',
      //   leftIcon: (
      //     <AntIcon name={'plus'} size={16} color={Colors.secondary['800']} />
      //   ),
      //   btnClick: () => {
      //     buttonClickFnList[1]();
      //   },
      // },
    ],
  };
};
