import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { APPOINTMENT_STATUS_CODES, CARE_PROGRAM_TYPES, CARE_PROGRAM_TYPE_CODES, CARE_TEAM_MEMBER_TYPE, MLOV_CATEGORY } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER, GET_UNDER_MY_CARE_CONTACT_IDS, GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER } from '../../../../services/CareTeam/CareTeamQueries';
import { GET_CONTACTS_COUNT, GET_CONTACTS_UUIDS_BY_FILTER } from '../../../../services/Contacts/ContactsQueries';
import { getAccountId, getUserUUID } from '../../../../utils/commonUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { AWV_POP_GROUP_CODE, DASHBOARD_TAB_CODE, FUTURE_AWV_API_CODE, PROGRAM_FILTER_CODE, PROGRAM_STEPS_FILTER_CODE, PROGRAM_SUB_FILTER_CODE, STEP_STATUS } from '../constant';
import { GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID, GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_FOR_TCM, GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_TCM, GET_CONTACT_IDS_BY_PROGRAM_IDS, GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID, GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS, GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS_FILTER_CONTACT_IDS } from '../../../../services/ContactCareProgram/ContactCareProgram';
import {
  IContactProgramCountResponse,
  IFilterCategoryApiResponseMap,
  IGetContactFilterParams,
  IPatientCategoryCount,
  IPatientCategoryCountParams,
  IPatientCategoryWiseCountParams,
  IPatientDashboardFilter,
  IPayerProgramWiseProgramResponse,
  IPopGroupResponse,
  IPopGroupResult,
  IStepsLogResponse,
  StepFilter,
} from '../interface';
import useDebounceEvent from './useDebounceEvent';
import { getAwvFilteredContactIds, getContactFilter } from './utils';
import { getProgramContactDataFromPopGroup } from '../../../RightSideContainer/Contacts/CodeGroups/CodeGroupApi';
import { IMlov } from '../../../../Interfaces/CommonInterfaces';
import { Args } from '../../../CustomHooks/useGetBatchedAccountUsers';
import { GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS } from '../../../../services/Appointment/AppointmentQueries';
import { getCurrentDateTimeMoment } from '../../../../utils/DateUtils';
import { cloneDeep, isEqual } from 'lodash';


interface IStepWiseContactIdResponse {
  contactIds: string[];
  categoryCode: string,
  count: number,
};

export const usePatientCountManager = (args: IPatientCategoryCountParams) => {
  const { params, isProgramBasedDashboard } = args;
  const [tabCountLoading, setLoading] = useState<boolean>(false);
  const [dashboardTabCount, setDashboardTabCount] = useState<
    IPatientCategoryCount[]
  >([]);

  const commonContextData = useContext(CommonDataContext);
  const careProgramStepList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP) || [];
  const careProgramStepStatusList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP_STATUS) || [];
  const currentUserId = getUserUUID();
  const accountId = getAccountId();
  const [filterParams, setFilterParams] = useState({} as IPatientDashboardFilter);
  const [firstFetch, setFirstFetch] = useState(false);
  const [loadingCategoryCodes, setLoadingCategoryCodes] = useState<string[]>([]);

  const careProgramTypeList = getMlovListFromCategory(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  ) || [];

  const ccmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.CCM);
  const ecmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.ECM);
  const tcmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.TCM);
  const mlovData = useContext(CommonDataContext);

  const [getContactCount] = useLazyQuery(GET_CONTACTS_COUNT, {
    fetchPolicy: 'no-cache',
  });

  const [getContactIdsByFilter] = useLazyQuery(GET_CONTACTS_UUIDS_BY_FILTER, {
    fetchPolicy: 'no-cache',
  });

  const [getFutureBookedAppointments, { refetch: refetchAppointments }] = useLazyQuery(
    GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getUnderMyCareContactIds] = useLazyQuery(
    GET_UNDER_MY_CARE_CONTACT_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getUnderMyCareContactIdsWithCareTeamMemberTypeUserId] = useLazyQuery(
    GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactByMatchCareTeamMemberType] = useLazyQuery(
    GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getCategoriesWiseContactIds] = useLazyQuery(
    GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getCategoriesWiseFilterContactIds] = useLazyQuery(
    GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS_FILTER_CONTACT_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactIdAndPayerProgramWiseContactCareProgram] = useLazyQuery(
    GET_CONTACT_IDS_BY_PROGRAM_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactIdAndPayerProgramWiseContactCareProgramByTcm] = useLazyQuery(
    GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_TCM,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  )

  const [getContactIdsByPayerCareProgramId] = useLazyQuery(
    GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactIdsByPayerCareProgramIdByTcm] = useLazyQuery(
    GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_FOR_TCM,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const getPatientTabCount = async (param: IPatientCategoryWiseCountParams) => {
    let responseMap: IPatientCategoryCount[] = [];
    let contactIds = [];
    if (params?.filter?.careManagerId) {
      const underMyCareContactResponse = await getContactByMatchCareTeamMemberType({
        variables: {
          memberTypeCode: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
          memberTypeUserId: param?.filter?.formData?.careManagerId
        }
      });
      if (underMyCareContactResponse.data?.careTeams?.length) {
        contactIds = underMyCareContactResponse.data.careTeams.map(
          (result: { contactId: string }) => {
            return result.contactId;
          },
        );
      }
      if (!contactIds?.length) {
        const categoryWiseCount: { categoryCode: string; count: number; }[] = [];
        if (param?.categoryCodes?.length) {
          (param.categoryCodes || []).forEach(categoryCode => {
            categoryWiseCount.push({
              categoryCode: categoryCode,
              count: 0,
            });
          });
        }
        return responseMap;
      }
    }
    if (isProgramBasedDashboard) {
      responseMap = await getCareProgramBasedDashboardCount(param, contactIds);
    } else {
      const countResponse = await getContactIdWiseResponse(contactIds, param);
      responseMap.push({
        tabCode: param.tabCode,
        count: countResponse?.data?.contactAggregate?.aggregate?.count,
      });
    }
    return responseMap;
  };

  const getAppointmentListByContactIds = async (popGroupAwvDueContactIds: string[], popGroupAwvDueThreeMonthsContactIds: string[]) => {
    const appointmentStatusList =
      getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.APPOINTMENT_STATUS
      ) || [];

    const filteredPopGroupAwvDueContactIds = getAwvFilteredContactIds(
      popGroupAwvDueContactIds,
      popGroupAwvDueThreeMonthsContactIds
    );

    const scheduledStatusId = appointmentStatusList?.find(
      (status) => status?.code === APPOINTMENT_STATUS_CODES.BOOKED
    )?.id;

    const appointmentResponse = await getFutureBookedAppointments({
      variables: {
        contactIds: filteredPopGroupAwvDueContactIds,
        startDateTime: getCurrentDateTimeMoment().toISOString(),
        statusId: scheduledStatusId,
      },
    });

    return appointmentResponse?.data?.appointments;
  }

  const getUniqueContactIdsFromResponse = async (filterType: string, popGroupAwvDueContactIds: string[], appointments: any[]) => {
    const appointmentUniqueContactIds = getUniqueContactIds(appointments)
    if (filterType === PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT) {
      return appointmentUniqueContactIds.filter((item:any) => popGroupAwvDueContactIds?.includes(item));
    } else if (filterType === PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED) {
      const filteredPopGroupAwvDueResponseIds = getAwvFilteredContactIds(popGroupAwvDueContactIds, appointmentUniqueContactIds)
      return filteredPopGroupAwvDueResponseIds;
    }
  }

  const getCategoryWiseFilterEnum = (categoryCodes: string[]) => {
    const categoryWiseEnum: StepFilter[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_STEPS_FILTER_CODE.ALL:
          categoryWiseEnum.push(StepFilter.ALL);
          break;
        case PROGRAM_STEPS_FILTER_CODE.ASSESSMENT:
          categoryWiseEnum.push(StepFilter.ASSESSMENT);
          break;
        case PROGRAM_STEPS_FILTER_CODE.CONSENT:
          categoryWiseEnum.push(StepFilter.CONSENT);
          break;
        case PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN:
          categoryWiseEnum.push(StepFilter.PLAN);
          break;
        case PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F:
          categoryWiseEnum.push(StepFilter.SCHEDULE);
          break;
        case PROGRAM_FILTER_CODE.CCM:
          categoryWiseEnum.push(StepFilter.CCM);
          break;
        case PROGRAM_FILTER_CODE.TCM:
          categoryWiseEnum.push(StepFilter.TCM);
          break;
        case PROGRAM_FILTER_CODE.ECM:
          categoryWiseEnum.push(StepFilter.ECM);
          break;
        case PROGRAM_FILTER_CODE.AWV_DUE:
          categoryWiseEnum.push(StepFilter.AWV);
          if (params?.filter.categoryCode && params?.filter.categoryCode === PROGRAM_FILTER_CODE.AWV_DUE) {
            categoryWiseEnum.push(StepFilter.AWV_SCHEDULED);
            categoryWiseEnum.push(StepFilter.NOT_SCHEDULED);
            categoryWiseEnum.push(StepFilter.OTHER_APPOINTMENT);
          }
          break;
        case PROGRAM_FILTER_CODE.CCM_ENROLLED:
          categoryWiseEnum.push(StepFilter.CCM_ENROLLED);
          break;
      }
    }
    return categoryWiseEnum;
  }


  const getStepFilterCategoryIds = (categoryCodes: string[]) => {
    const categoryIds: string[] = [];
    const assessmentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.ASSESSMENT);
    const consentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CONSENT);
    const planCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN);
    const scheduleF2FCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F);
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_STEPS_FILTER_CODE.ASSESSMENT:
          if (assessmentCategory?.id) {
            categoryIds.push(assessmentCategory.id);
          }
          break;
        case PROGRAM_STEPS_FILTER_CODE.CONSENT:
          if (consentCategory?.id) {
            categoryIds.push(consentCategory.id);
          }
          break;
        case PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN:
          if (planCategory?.id) {
            categoryIds.push(planCategory.id);
          }
          break;
        case PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F:
          if (scheduleF2FCategory?.id) {
            categoryIds.push(scheduleF2FCategory.id);
          }
          break;
      }
    }
    return categoryIds;
  }

  const getProgramCodesByCategory = (categoryCodes: string[]) => {

    const programCodes: string[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_FILTER_CODE.AWV_DUE:
          programCodes.push(AWV_POP_GROUP_CODE);
          if (args.params.filter.categoryCode ===  PROGRAM_FILTER_CODE.AWV_DUE) {
              programCodes.push(FUTURE_AWV_API_CODE);
          }

      }
    }
    return programCodes;
  }

  const getProgramIdsByCategory = (categoryCodes: string[]) => {
    const programIds: string[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_FILTER_CODE.CCM:
          programIds.push(ccmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.TCM:
          programIds.push(tcmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.ECM:
          programIds.push(ecmCareProgramTypeId);
          break;
      }
    }
    return programIds;
  }

  const getFilterCategoryMlov = (categoryCode: string): IMlov | undefined => {
    return careProgramStepList.find(careProgramStep => {
      return careProgramStep?.code === categoryCode;
    });
  }

  const getCareProgramContactIdsByStepCategoryId = (categoryId: string, contactCareProgramStepsLogs: IContactProgramCountResponse[], myContactIds: string[]): string[] => {
    const filterContactCarePrograms: IContactProgramCountResponse[] = (contactCareProgramStepsLogs || []).filter(contactCareProgramStepsLog => {
      return contactCareProgramStepsLog?.careProgramStep?.careProgramStepTypeId === categoryId;
    }) || [];
    if (!filterContactCarePrograms?.length) {
      return [];
    }
    let contactIds: string[] = [];
    (filterContactCarePrograms || []).forEach(filterContactCareProgram => {
      if (filterContactCareProgram?.contactCareProgram?.contactId) {
        if (!contactIds.includes(filterContactCareProgram.contactCareProgram.contactId)) {
          contactIds.push(filterContactCareProgram.contactCareProgram.contactId);
        }
      }
    });
    if (params.tabCode == DASHBOARD_TAB_CODE.MY_PATIENT) {
      if (myContactIds?.length) {
        contactIds = (contactIds || []).filter(contactId => {
          return myContactIds.includes(contactId);
        });
      } else {
        contactIds = [];
      }
    }
    return contactIds;
  }

  const getCandidatesOfProgramByRuleCode = (ruleCode: string, programWiseResponse: IPopGroupResult[], contactIds: string[]): string[] => {
    let contactUuids: string[] = [];
    (programWiseResponse || []).some(programResponse => {
      if (programResponse?.ruleCode === ruleCode) {
        contactUuids = programResponse?.ruleResult?.contactUuids || [];
        return true;
      }
    });
    if (params?.tabCode === DASHBOARD_TAB_CODE.MY_PATIENT && !contactIds?.length) {
      return [];
    }
    if (contactIds?.length) {
      contactUuids = (contactUuids || []).filter(contactUuid => {
        return contactIds.includes(contactUuid);
      });
    }
    return contactUuids;
  }

  const getPayerProgramIdWiseContactIds = (payerProgramId: string, programWiseResponse: IPayerProgramWiseProgramResponse[], contactIds: string[]): string[] => {
    let contactUuids: string[] = [];
    (programWiseResponse || []).forEach(programResponse => {
      if (programResponse?.contactCareProgram?.payerCareProgram?.careProgramTypeId === payerProgramId) {
        contactUuids.push(programResponse?.contactCareProgram?.contactId);
      }
    });
    if (params?.tabCode === DASHBOARD_TAB_CODE.MY_PATIENT && !contactIds?.length) {
      return [];
    }
    if (contactIds?.length) {
      contactUuids = (contactUuids || []).filter(contactUuid => {
        return contactIds.includes(contactUuid);
      });
    }
    return contactUuids;
  }

  const addEmptyPromise = () => {
    return new Promise((resolve)=> resolve(undefined))
  }

  const getResponseByRequestObject = async (
    categoryWiseEnum: StepFilter[],
    categoryCodes: string[],
    param: IPatientCategoryWiseCountParams,
    contactIds: string[]
  ) => {
    const responseList: any[] = [];
    try {
      const assessmentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.ASSESSMENT);
      const consentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CONSENT);
      const planCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN);
      const scheduleF2FCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F);
      const filterStatusIds: string[] = [];
      (careProgramStepStatusList || []).map(careProgramStepStatus => {
        if (careProgramStepStatus.code == STEP_STATUS.FAIL || careProgramStepStatus.code == STEP_STATUS.TODO) {
          filterStatusIds.push(careProgramStepStatus.id);
        }
      });
      const categoryIds = getStepFilterCategoryIds(categoryCodes);
      const programIds = getProgramIdsByCategory(categoryCodes);
      const programCodes = getProgramCodesByCategory(categoryCodes);
      const promiseList: any[] = [];
      // get ALL category contactIds
      const filterParams: IGetContactFilterParams = getContactFilterParams(
        contactIds,
        param,
      );
      const filter = getContactFilter(filterParams);
      promiseList.push(getContactCount({ variables: { contact_filters: filter } }));
      // step wise contact ids
      if (categoryIds?.length) {
        if (contactIds?.length) {
          promiseList.push(getCategoriesWiseFilterContactIds({
            variables: {
              contactIds: contactIds,
              filterCategoryIds: categoryIds,
              statusIds: filterStatusIds
            },
          }));
        } else {
          promiseList.push(getCategoriesWiseContactIds({
            variables: {
              filterCategoryIds: categoryIds,
              statusIds: filterStatusIds
            },
          }));
        }
      }
      else {
        promiseList.push(addEmptyPromise())
      }
      // program wise contact ids
      if (programCodes?.length) {
        promiseList.push(getProgramContactDataFromPopGroup(programCodes));
      }
      else {
        promiseList.push(addEmptyPromise())
      }

      // program data by payer program id
      if (programIds?.length) {
        if (contactIds?.length) {
          promiseList.push(getContactIdAndPayerProgramWiseContactCareProgram({
            variables: {
              payerCareProgramIds: programIds?.filter((id) => id !== tcmCareProgramTypeId),
              contactIds: contactIds,
            }
          },
          ),
            getContactIdAndPayerProgramWiseContactCareProgramByTcm({
              variables: {
                payerCareProgramIds: [tcmCareProgramTypeId],
                contactIds: contactIds,
                currentDateTime: new Date().toISOString(),
              }
            })
          );
        } else {
          promiseList.push(getContactIdsByPayerCareProgramId({
            variables: {
              payerCareProgramIds: programIds?.filter((id) => id !== tcmCareProgramTypeId),
            }
          }),
            getContactIdsByPayerCareProgramIdByTcm({
              variables: {
                payerCareProgramIds: [tcmCareProgramTypeId],
                currentDateTime: new Date().toISOString(),
              }
            })
          );
        }
      }
      else {
        promiseList.push(addEmptyPromise())
      }

      let allCategoryResponse: any = null;
      let stepContactCountResponse: any = null;
      let programContactCountResponse: any = null;
      let payerProgramIdWiseCountResponse: any = null;
      let tcmProgramIdWiseCountResponse: any = null;

      if (promiseList?.length) {
        const promiseResponseList = await Promise.all(promiseList);
        allCategoryResponse = promiseResponseList?.[0];
        stepContactCountResponse = promiseResponseList?.[1];
        programContactCountResponse = promiseResponseList?.[2];
        payerProgramIdWiseCountResponse = promiseResponseList?.[3];
        tcmProgramIdWiseCountResponse = promiseResponseList?.[4];

        const contactCareProgramStepsLogs = stepContactCountResponse?.data?.contactCareProgramStepsLogs || [];
        const programCodeWiseResponse = programContactCountResponse?.data?.result || [];
        const programIdWiseResponse = payerProgramIdWiseCountResponse?.data?.contactCareProgramStepsLogs || [];
        const tcmResponse = tcmProgramIdWiseCountResponse?.data?.contactCareProgramStepsLogs || [];

        let appointments;
        const awvPopGroupContacts: string[] = getCandidatesOfProgramByRuleCode(
          AWV_POP_GROUP_CODE,
          programCodeWiseResponse,
          contactIds
        );

        if (args.params.filter.categoryCode === PROGRAM_FILTER_CODE.AWV_DUE) {
          const awvProgramNotScheduledThreeMonthsContactIds =
            getCandidatesOfProgramByRuleCode(
              FUTURE_AWV_API_CODE,
              programCodeWiseResponse,
              contactIds
            );
          appointments = await getAppointmentListByContactIds(
            awvPopGroupContacts,
            awvProgramNotScheduledThreeMonthsContactIds
          );
        }

        for (const enumCode of categoryWiseEnum) {
          switch (enumCode) {
            case StepFilter.ALL:
              const contactCount = allCategoryResponse?.data?.contactAggregate?.aggregate?.count || 0;
              responseList.push({ count: contactCount });
              break;
            case StepFilter.ASSESSMENT:
              const assessmentCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.ASSESSMENT,
                count: 0
              };
              if (contactCareProgramStepsLogs && contactCareProgramStepsLogs?.length) {
                const programContactIds = getCareProgramContactIdsByStepCategoryId(assessmentCategory?.id || '', contactCareProgramStepsLogs, contactIds);
                assessmentCategoryResponse.contactIds = programContactIds;
              }
              responseList.push(assessmentCategoryResponse);
              break;
            case StepFilter.CONSENT:
              const consentCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.CONSENT,
                count: 0
              };
              if (contactCareProgramStepsLogs && contactCareProgramStepsLogs?.length) {
                const programContactIds = getCareProgramContactIdsByStepCategoryId(consentCategory?.id || '', contactCareProgramStepsLogs, contactIds);
                consentCategoryResponse.contactIds = programContactIds || [];
              }
              responseList.push(consentCategoryResponse);
              break;
            case StepFilter.PLAN:
              const planCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN,
                count: 0
              };
              if (contactCareProgramStepsLogs && contactCareProgramStepsLogs?.length) {
                const programContactIds = getCareProgramContactIdsByStepCategoryId(planCategory?.id || '', contactCareProgramStepsLogs, contactIds);
                planCategoryResponse.contactIds = programContactIds || [];
              }
              responseList.push(planCategoryResponse);
              break;
            case StepFilter.SCHEDULE:
              const scheduleF2FCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F,
                count: 0
              };
              if (contactCareProgramStepsLogs && contactCareProgramStepsLogs?.length) {
                const programContactIds = getCareProgramContactIdsByStepCategoryId(scheduleF2FCategory?.id || '', contactCareProgramStepsLogs, contactIds);
                scheduleF2FCategoryResponse.contactIds = programContactIds || [];
              }
              responseList.push(scheduleF2FCategoryResponse);
              break;
            case StepFilter.CCM:
              const ccmProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.CCM,
                count: 0
              };
              const programContactIds = getPayerProgramIdWiseContactIds(ccmCareProgramTypeId, programIdWiseResponse, contactIds);
              ccmProgramResponse.contactIds = programContactIds;
              responseList.push(ccmProgramResponse);
              break;
            case StepFilter.AWV:
              const awvProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.AWV_DUE,
                count: 0
              };
              awvProgramResponse.contactIds = awvPopGroupContacts;
              responseList.push(awvProgramResponse);
              break;
            case StepFilter.TCM:
              const tcmProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.TCM,
                count: 0
              };
              const tcmProgramContactIds = getPayerProgramIdWiseContactIds(tcmCareProgramTypeId, tcmResponse, contactIds);
              tcmProgramResponse.contactIds = tcmProgramContactIds;
              responseList.push(tcmProgramResponse);
              break;
            case StepFilter.ECM:
              const ecmProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.ECM,
                count: 0
              };
              const ecmProgramContactIds = getPayerProgramIdWiseContactIds(ecmCareProgramTypeId, programIdWiseResponse, contactIds);
              ecmProgramResponse.contactIds = ecmProgramContactIds;
              responseList.push(ecmProgramResponse);
              break;
            case StepFilter.CCM_ENROLLED:
              const ccmeEnrolledResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.CCM_ENROLLED,
                count: 0
              };
              if (contactCareProgramStepsLogs && contactCareProgramStepsLogs?.length) {
                const filteredContactCareProgramsStepLogs = contactCareProgramStepsLogs?.filter((contactCareProgramStep : any) => contactCareProgramStep?.contactCareProgram?.payerCareProgram?.careProgramType?.code === CARE_PROGRAM_TYPE_CODES.CCM)
                const programContactIds = getCareProgramContactIdsByStepCategoryId(planCategory?.id || '', filteredContactCareProgramsStepLogs, contactIds);
                ccmeEnrolledResponse.contactIds = programContactIds || [];
              }
              responseList.push(ccmeEnrolledResponse);
              break;
            case StepFilter.AWV_SCHEDULED:
              const awvScheduledProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED,
                count: 0
              };

              const awvScheduledProgramContactIds = getCandidatesOfProgramByRuleCode(ecmCareProgramTypeId, programIdWiseResponse, contactIds);
              awvScheduledProgramResponse.contactIds = awvScheduledProgramContactIds;
              responseList.push(awvScheduledProgramResponse);
              break;
            case StepFilter.NOT_SCHEDULED:
              const awvNotScheduledProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED,
                count: 0
              };

              const notScheduledContactIds = await getUniqueContactIdsFromResponse(PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED, awvPopGroupContacts, appointments)
              awvNotScheduledProgramResponse.contactIds = notScheduledContactIds;
              responseList.push(awvNotScheduledProgramResponse);
              break;
            case StepFilter.OTHER_APPOINTMENT:
              const awvOtherAppointementsResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT,
                count: 0
              };

              const appointmentUniqueContactIds = await getUniqueContactIdsFromResponse(PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT, awvPopGroupContacts, appointments)
              awvOtherAppointementsResponse.contactIds = appointmentUniqueContactIds;
              responseList.push(awvOtherAppointementsResponse);
              break;
          }
        }
      }
    } catch (error) {

    }
    return responseList;
  };

  const getUniqueContactIds = (meetings: any) => {
    const uniqueContactIds = new Set();
    meetings.forEach((meeting: any) => {
      meeting.participants.forEach((participant: any) => {
        uniqueContactIds.add(participant.contactId);
      });
    });
    return Array.from(uniqueContactIds);
  }

  const getFormattedResponseFilter = (response: IStepsLogResponse, categoryCode: string) => {
    const formattedResponse = {
      categoryCode: categoryCode,
      contactIds: [],
      count: 0,
    } as IFilterCategoryApiResponseMap;
    const contactIds: string[] = [];
    if (response?.contactCareProgramStepsLogs?.length) {
      for (const stepResponse of response.contactCareProgramStepsLogs) {
        if (stepResponse?.contactCareProgram?.contactId) {
          contactIds.push(stepResponse.contactCareProgram.contactId);
        }
      }
    }
    formattedResponse.contactIds = contactIds;
    formattedResponse.count = response?.contactCareProgramStepsLogs?.length || 0;
    return formattedResponse;
  }

  const getFormattedResponsePopGroupFilter = (response: IPopGroupResponse, categoryCode: string) => {
    return {
      categoryCode: categoryCode,
      contactIds: response?.result?.[0]?.ruleResult?.contactUuids,
      count: response?.result?.[0]?.ruleResult?.count || 0,
    } as IFilterCategoryApiResponseMap;
  }

  const configureCategoryWiseCountData = async (param: IPatientCategoryWiseCountParams, requestObjects: any[], responseObjects: any[]): Promise<IFilterCategoryApiResponseMap[]> => {
    const categoryWiseCountResponse: IFilterCategoryApiResponseMap[] = [];
    let index = -1;
    const allCategoryContactIds = [];
    let allCategoryContactResponse: string[] = [];
    for (const requestObject of requestObjects) {
      index += 1;
      const allCategoryResponse = responseObjects?.[index];
      switch (requestObject) {
          case StepFilter.ASSESSMENT:
          case StepFilter.CONSENT:
          case StepFilter.PLAN:
          case StepFilter.SCHEDULE:
          case StepFilter.CCM:
          case StepFilter.ECM:
          case StepFilter.AWV:
          case StepFilter.AWV_SCHEDULED:
          case StepFilter.NOT_SCHEDULED:
          case StepFilter.OTHER_APPOINTMENT:
          case StepFilter.TCM:
          case StepFilter.CCM_ENROLLED:
            const categoryResponse = allCategoryResponse;
            if (categoryResponse?.contactIds?.length) {
              allCategoryContactIds.push(...categoryResponse?.contactIds);
            }
            break;
      }
    }
    if (allCategoryContactIds?.length) {
      const filterParams: IGetContactFilterParams = getContactFilterParams(
        [... new Set(allCategoryContactIds)],
        param,
      );
      const filter = getContactFilter(filterParams);
      const contactIdsResponse = await getContactIdsByFilter({variables: { contact_filters: filter }});
      allCategoryContactResponse = contactIdsResponse?.data?.contacts?.map((contact : {uuid: string})=> contact.uuid)
    }

    let categoryIndex = -1;
    for (const requestObject of requestObjects) {
      categoryIndex += 1;
      const categoryResponse = responseObjects?.[categoryIndex];
      switch (requestObject) {
        case StepFilter.ALL:
          categoryWiseCountResponse.push({
            categoryCode: PROGRAM_STEPS_FILTER_CODE.ALL,
            contactIds: [],
            count: categoryResponse?.count || 0
          } as IFilterCategoryApiResponseMap);
        break;
        case StepFilter.ASSESSMENT:
        case StepFilter.CONSENT:
        case StepFilter.PLAN:
        case StepFilter.SCHEDULE:
        case StepFilter.CCM:
        case StepFilter.ECM:
        case StepFilter.AWV:
        case StepFilter.AWV_SCHEDULED:
        case StepFilter.NOT_SCHEDULED:
        case StepFilter.OTHER_APPOINTMENT:
        case StepFilter.TCM:
        case StepFilter.CCM_ENROLLED:
          const programCategoryResponse = categoryResponse;
          if (programCategoryResponse?.contactIds?.length) {
            const uniqueContactIds : string[] = [];
            programCategoryResponse?.contactIds?.forEach((contactId: string)=> {
              if (allCategoryContactResponse?.includes(contactId) && !uniqueContactIds.includes(contactId)){
                uniqueContactIds.push(contactId);
              }
            });
            programCategoryResponse.contactIds = [];
            programCategoryResponse.count = uniqueContactIds?.length || 0;
            categoryWiseCountResponse.push(programCategoryResponse);
          }

          break;
      }
    }
    return categoryWiseCountResponse;
  }

  const filterCategoryCodes = () => {
    const categoryCodes = filterParams?.isCategoryChanged ? [filterParams?.categoryCode || ''] : params?.categoryCodes || [];
    return categoryCodes;
  }

  const getCareProgramBasedDashboardCount = async (
    param: IPatientCategoryWiseCountParams,
    contactIds: string[],
  ) => {
    const responseMap: IPatientCategoryCount[] = [];
    const categoryCodes = filterCategoryCodes();
    const categoryWiseEnum: StepFilter[] = getCategoryWiseFilterEnum(categoryCodes);

    const responseObjects = await getResponseByRequestObject(categoryWiseEnum, categoryCodes, param, contactIds);
    const configureCategoryWiseResponse: IFilterCategoryApiResponseMap[] = await configureCategoryWiseCountData(param, categoryWiseEnum, responseObjects);
    const tabWiseResponseMap: IPatientCategoryCount = {
      categoryWiseCount: [],
      tabCode: param.tabCode,
      count: 0
    }
    let allCategoryCount = filterParams?.isCategoryChanged ? dashboardTabCount?.[0]?.count || 0 : 0;
    for (const categoryResponse of configureCategoryWiseResponse) {
      if (categoryResponse.categoryCode === PROGRAM_STEPS_FILTER_CODE.ALL) {
        allCategoryCount = categoryResponse.count;
      }
      tabWiseResponseMap.categoryWiseCount?.push({
        categoryCode: categoryResponse.categoryCode,
        count: categoryResponse.count,
      });
    }
    tabWiseResponseMap.count = allCategoryCount;
    responseMap.push(tabWiseResponseMap);
    return responseMap;
  };

  const getContactFilterParams = (
    contactIds: string[],
    param: IPatientCategoryWiseCountParams,
  ): IGetContactFilterParams => {
    return {
      filter: param?.filter,
      tabCode: param?.tabCode,
      accountId: accountId,
      commonContextData: commonContextData,
      patientList: contactIds,
    };
  };

  const getContactIdWiseResponse = async (
    contactIds: string[],
    param: IPatientCategoryWiseCountParams,
  ) => {
    const filterParams: IGetContactFilterParams = getContactFilterParams(
      contactIds,
      param,
    );
    const filter = getContactFilter(filterParams);
    const countResponse = await getContactCount({
      variables: { contact_filters: filter },
    });
    return countResponse;
  };

  const getContactIdsByFilterResponse = async (
    contactIds: string[],
    param: IPatientCategoryWiseCountParams,
  ) => {
    const filterParams: IGetContactFilterParams = getContactFilterParams(
      contactIds,
      param,
    );
    const filter = getContactFilter(filterParams);
    const countResponse = await getContactIdsByFilter({
      variables: { contact_filters: filter },
    });
    return countResponse;
  };

  const getMyPatientTabCount = async (
    param: IPatientCategoryWiseCountParams,
  ) => {
    let contactIds: string[] = [];
    let responseMap: IPatientCategoryCount[] = [];
    let underMyCareContactResponse: any = undefined;
    if (params?.filter?.careManagerId) {
      underMyCareContactResponse = await getUnderMyCareContactIdsWithCareTeamMemberTypeUserId({
        variables: {
          userId: currentUserId,
          memberTypeCode: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
          memberTypeUserId: param?.filter?.formData?.careManagerId
        }
      });
    } else {
      underMyCareContactResponse = await getUnderMyCareContactIds({
        variables: {
          userId: currentUserId,
        },
      });
    }
    if (underMyCareContactResponse.data?.careTeams?.length) {
      contactIds = underMyCareContactResponse.data.careTeams.map(
        (result: { contactId: string }) => {
          return result.contactId;
        },
      );
    }
    if (!contactIds?.length) {
      const categoryWiseCount: { categoryCode: string; count: number; }[] = [];
      if (param?.categoryCodes?.length) {
        (param.categoryCodes || []).forEach(categoryCode => {
          categoryWiseCount.push({
            categoryCode: categoryCode,
            count: 0,
          });
        });
      }
      responseMap.push({
        categoryWiseCount: categoryWiseCount,
        tabCode: param.tabCode,
        count: 0,
      });
      return responseMap;
    }
    if (isProgramBasedDashboard) {
      responseMap = await getCareProgramBasedDashboardCount(param, contactIds);
    } else {
      const countResponse = await getContactIdWiseResponse(contactIds, param);
      responseMap.push({
        tabCode: param.tabCode,
        count: countResponse?.data?.contactAggregate?.aggregate?.count,
      });
    }
    return responseMap;
  };

  const getLoadingCategoryTabs = () => {
    const categoryCodes = filterCategoryCodes() || []

    if (
      categoryCodes?.[0] === PROGRAM_FILTER_CODE.AWV_DUE
    ) {
      categoryCodes.push(PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED);
      categoryCodes.push(PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED);
      categoryCodes.push(PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT);
    }
    return categoryCodes;
  }


  const getPatientCountByFilter = async () => {
    setLoading(true);
    setLoadingCategoryCodes(getLoadingCategoryTabs())
    let responseMap: IPatientCategoryCount[] = [];
    switch (params.tabCode) {
      case DASHBOARD_TAB_CODE.MY_PATIENT:
        const countMyPatientResponse = await getMyPatientTabCount(params);
        responseMap = responseMap.concat(countMyPatientResponse);
        break;
      case DASHBOARD_TAB_CODE.PATIENT:
        const countResponseOfPatient = await getPatientTabCount(params);
        responseMap = responseMap.concat(countResponseOfPatient);
        break;
    }
    setFirstFetch(true);
    setDashboardTabCount(responseMap);
    setLoading(false);
    setLoadingCategoryCodes([]);
  };

  // Public methods
  const refreshCount = () => {
    getPatientCountByFilter();
  };

  const { pushEvent } = useDebounceEvent({
    onDebounce: () => {
      refreshCount();
    }
  });

  const updateFilterParamsState = (isCategoryChanged?: boolean) => {
    delete filterParams.isCategoryChanged;
    if (!firstFetch || !isEqual(filterParams, params?.filter)) {
      setFilterParams({
        ...params?.filter,
        isCategoryChanged: isCategoryChanged,
      });
    }
  };

  // Lifecycle methods
  useEffect(() => {
    if (isProgramBasedDashboard) {
      updateFilterParamsState();
    } else {
      setDashboardTabCount([]);
      setLoading(false);
    }
  }, [
    params?.filter?.contactUpdated,
    params?.filter?.formData,
    params?.filter?.inboxItem,
    params?.filter?.loggedInStatus,
    params?.filter?.personType,
    params?.filter?.personTypeUuid,
    params?.filter?.searchText,
    params?.filter?.selectedUserPracticeLocation,
    params?.filter?.tab,
    params?.filter?.tagItem,
    params?.filter?.status,
    params?.filter?.careManagerId,
  ]);

  useEffect(() => {
    if (isProgramBasedDashboard) {
      updateFilterParamsState(!firstFetch ? false : true);
    } else {
      setDashboardTabCount([]);
      setLoading(false);
    }
  }, [params?.filter?.categoryCode]);

  useEffect(()=> {
    if (Object.keys(filterParams)?.length) {
      refreshCount();
    }
  }, [filterParams]);

  return { dashboardTabCount, tabCountLoading, refreshCount, loadingCategoryCodes };
};

export default usePatientCountManager;


