import React from 'react';
import {InputNumber} from 'antd';
import {FormControl, HStack, Select, Text} from 'native-base';
import {useState} from 'react';
import {IInputElement} from '../FlowComponent/StateNodes/FlowNodeInterface';
import {TARGET_PREFIX_TEXT} from '../FlowComponent/StateNodes/FlowNodeHelper';

export interface IActivityValue {
  frequency: number | undefined;
  activityMeasureParam: string | undefined;
  period: string | undefined;
}

export const ActivityUserInputField = (props: IInputElement) => {
  const activityMeasureList = props.possibleActivityMeasureParams || [];
  const periodList = props.possiblePeriodList || [];
  const setDefaultActivityState = () => {
    return {
      frequency: props.value?.frequency || props.value?.value || '',
      activityMeasureParam: props.value?.activityMeasureParam || '',
      period: props.value?.period,
    } as IActivityValue;
  };

  const [activityState, setActivityState] = useState<IActivityValue>(
    setDefaultActivityState()
  );

  const handleCallBack = (
    frequency: number | undefined | any,
    measureParam: string | undefined,
    period: string | undefined
  ) => {
    if (frequency && frequency > 0 && measureParam && period) {
      const selectedMeasureUnit = activityMeasureList.find((measureUnit) => {
        return measureUnit.value === measureParam;
      });
      const selectedPeriodUnit = periodList.find((periodUnit) => {
        return periodUnit.value === period;
      });
      const responseValue = {
        value: frequency,
        activityMeasureParam: measureParam,
        period: period,
        displayValue: `Aim for ${frequency}  ${selectedMeasureUnit?.displayName?.toLowerCase()} every ${selectedPeriodUnit?.displayName?.toLowerCase()}`,
      };
      props.onChange(responseValue);
      return;
    }
    props.onChange(undefined);
  };

  return (
    <HStack space={2} alignItems={'center'}>
      <Text>{TARGET_PREFIX_TEXT}</Text>
      <FormControl flex={1}>
        <InputNumber
          min={0}
          className={
            props.isShowError && !activityState.frequency ? 'field-error' : ''
          }
          value={activityState.frequency}
          placeholder="eg. 1000"
          type="number"
          onChange={(frequency) => {
            setActivityState((prev) => {
              return {
                ...prev,
                frequency: frequency || undefined,
              };
            });
            handleCallBack(
              frequency || undefined,
              activityState.activityMeasureParam,
              activityState.period
            );
          }}
          style={{width: '100%', height: 36}}
          {...props.elementProperty}
        />
      </FormControl>

      <FormControl flex={1}>
        <Select
          selectedValue={activityState.activityMeasureParam}
          height={'36px'}
          fontSize={'13px'}
          onValueChange={(measureParam) => {
            setActivityState((prev) => {
              return {
                ...prev,
                activityMeasureParam: measureParam,
              };
            });
            handleCallBack(
              activityState.frequency,
              measureParam,
              activityState.period
            );
          }}
        >
          {activityMeasureList?.length &&
            activityMeasureList.map((data: any) => {
              return (
                <Select.Item
                  key={data.value}
                  label={data.displayName}
                  value={data.value}
                />
              );
            })}
        </Select>
      </FormControl>

      <Text>{`every`}</Text>
      <FormControl flex={1}>
        <Select
          selectedValue={activityState.period}
          height={'36px'}
          fontSize={'13px'}
          onValueChange={(period) => {
            setActivityState((prev) => {
              return {
                ...prev,
                period: period,
              };
            });
            handleCallBack(
              activityState.frequency,
              activityState.activityMeasureParam,
              period
            );
          }}
        >
          {periodList?.length &&
            periodList.map((data: any) => {
              return (
                <Select.Item
                  key={data.value}
                  label={data.displayName}
                  value={data.value}
                />
              );
            })}
        </Select>
      </FormControl>
    </HStack>
  );
};

export default ActivityUserInputField;
