import {Drawer, Select, Upload, Tooltip, UploadFile, notification} from 'antd';
import {
  useMediaQuery,
  Text,
  FormControl,
  TextArea,
  Center,
  Spinner,
  View,
  useToast,
  InfoOutlineIcon,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Pressable} from 'react-native';
import {IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY} from '../../../../../../constants';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../../styles/Colors';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {useIntl} from 'react-intl';
import {
  ICreateEFaxConversationDrawer,
  ICreateEFaxConversationDrawerState,
  IEFaxInbox,
} from './interface';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import '../../../../../RightSideContainer/EmployerManagement/DrawerHeaderBorderRemove.css';
import {ModalActionInput} from '../../../../../common/ModalActionCommonComponent/ModalActionInput';
import EFaxActionSvg from '../../../../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import {useLazyQuery, useMutation} from '@apollo/client';
import {IContact} from '../../../../../common/CareDashboard/CareDashboardInterfaces';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {getAccountId, getUserId} from '../../../../../../utils/commonUtils';
import PatientSearchAndSelect from '../../../../../common/PatientSearch/PatientSearchAndSelect';
import {
  getBodyData,
  getUpdatedErrorMessageForEFaxDrawer,
  sendEfaxapi,
  uploadEFaxAttachments,
  uploadToPatientProfile,
} from './CreateEFaxConversationDrawerUtils';
import {ToastType, showToast} from '../../../../../../utils/commonViewUtils';
import EmailAttachmentDrawer from '../../../../../common/EmailDrawerCommonV2/EmailAttachmentDrawer';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import CustomAttributeQueries from '../../../../Contacts/CustomField/CustomAttributeQueries';
import {getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import {withMiniContactViewHOC} from '../../../../../MiniContactViewHOC';
import { getCareStudioMlovListByCategoryCode, getContactTypeId, getMlovIdFromCode, getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { EventBus } from '../../../../../../utils/EventBus';
import { CUSTOM_MESSAGE_EVENT_CODES } from '../../../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { useToast as useCustomToast} from '../../../../../../components/Toast/ToastProvider';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { CREATE_MULTIPLE_CONTACT_DOCUMENT } from '../../../../../../services/Document/DocumentQueries';
import * as DocumentQueries from '../../../../../../services/Document/DocumentQueries';
import { DOCUMENT_SUBJECT_TYPE_CODES } from '../../../../../../constants/MlovConst';
import { DOCUMENT_CATEGORY_CODES} from '../ConversationChannelTabConst';

const CreateEFaxConversationDrawer = (props: ICreateEFaxConversationDrawer) => {
  const intl = useIntl();
  const {Option} = Select;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const drawerWidth = isSideCarContext ? '100%' : isIPadMiniScreen || isIPadScreen ? '40vw' : '35%';
  const userId = getUserId();
  const accountId = getAccountId();
  const toast = useToast();

  const mlovData = useContext(CommonDataContext);
  const mlovCategoryId = getCareStudioMlovListByCategoryCode(
    MLOV_CATEGORY.DOCUMENT_TYPE
  )[0]?.categoryId;

  const documentSubjectTypeMlovs =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.DOCUMENT_SUBJECT_TYPE
  ) || [];

const contactDocumentSubjectTypeId = getMlovIdFromCode(
  documentSubjectTypeMlovs,
  DOCUMENT_SUBJECT_TYPE_CODES.CONTACT
);

  const [state, setState] = useState<ICreateEFaxConversationDrawerState>({
    inboxList: [] as IEFaxInbox[],
    receiversList: [] as any,
    selectedReceviers: {} as any,
    selectedInbox: {} as IEFaxInbox,
    regarding: {} as any,
    subject: '',
    messageText: '',
    loading: false,
    isFileListDrawerOpen: false,
    isFromMessagingWindow: false,
    selectedPatient: {} as IContact,
    errors: {} as any,
  });

  const [fileList, setFileList] = React.useState<UploadFile<any>[]>([]);
  const customerContactType = getContactTypeId(
    'CUSTOMER'
  );

  const [getDocumentCategories] = useLazyQuery(
    DocumentQueries.GET_DOCUMENT_CATEGORIES,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updateEFaxMessage] = useMutation(
    ConversationsQueries.UpdateEFaxMessage
  );

  const [createDocumentQuery] = useMutation(CREATE_MULTIPLE_CONTACT_DOCUMENT, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const customToast = useCustomToast();

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    getInitData();
  }, [props?.selectedConversation?.id]);

  const getInitData = async () => {
    const receviersContactData = await getReceviersContact();
    const inboxList = await getInboxList();
    if (props?.selectedConversation?.conversationInbox?.id) {
      const selectedReceviers = receviersContactData?.find(
        (item: any) =>
          item?.id === props?.selectedConversation?.conversationContact?.id
      );
      setState((prev: any) => {
        return {
          ...prev,
          selectedInbox: props?.selectedConversation?.conversationInbox,
          isFromMessagingWindow: true,
          selectedReceviers: selectedReceviers,
          receiversList: receviersContactData,
          inboxList: inboxList,
          loading: false,
        };
      });
    } else {
      setState((prev: any) => {
        return {
          ...prev,
          selectedInbox: undefined,
          selectedReceviers: undefined,
          selectedAttachment: props?.selectedAttachment || undefined,
          selectedPatient: props?.selectedPatient || undefined,
          isFromMessagingWindow: false,
          inboxList: inboxList,
          receiversList: receviersContactData,
          loading: false,
        };
      });
    }
  };

  const [getInboxes] = useLazyQuery(InboxQueries.GetEFaxInboxList, {
    fetchPolicy: 'no-cache',
  });

  const [getCustomAttributeId] = useLazyQuery(
    CustomAttributeQueries.GET_CUSTOM_ATTRIBUTE_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactByCustomAttributeId] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_CUSTOM_ATTRIBUTE_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getEFaxCustomAttributeId = async () => {
    const response = await getCustomAttributeId({
      variables: {
        key: 'e_fax_number',
      },
    });

    return response?.data?.customAttributes?.[0]?.id;
  };

  const getReceviersContact = async () => {
    const customAttributeId = await getEFaxCustomAttributeId();
    const response = await getContactByCustomAttributeId({
      variables: {
        customAttributeId: customAttributeId,
        contactTypeId: customerContactType,
      },
    });
    return response?.data?.contacts;
  };

  const getInboxList = async () => {
    const response = await getInboxes({
      variables: {
        userId: userId,
        accountId: accountId,
      },
    });
    return response?.data?.inboxes;
  };

  const getAttachmentIdsOfPatientProfile = () => {
    if (state?.selectedAttachment && state?.selectedAttachment?.length) {
      const attachmentIds = state?.selectedAttachment?.map((item: any) => {
        return item?.attachment?.id;
      });
      return attachmentIds;
    }
    return [];
  };

  const onSend = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const errorData = getUpdatedErrorMessageForEFaxDrawer(state, fileList);
    if (errorData?.isErrorPresent) {
      setState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
          loading: false,
        };
      });
    } else {
      try {
        let attachmentIds = [] as string[];
        if (props?.isEFaxFromPatientProfile) {
          attachmentIds = getAttachmentIdsOfPatientProfile();
        } else {
          attachmentIds = await uploadEFaxAttachments(fileList);
        }
        const body = getBodyData(
          state,
          attachmentIds,
          props?.selectedConversation?.id,
          props?.isEFaxFromPatientProfile
        );
        const response = await sendEfaxapi(body);
        if (response && props?.onMsgSend) {
          response['sender_type'] = 'user';
          const currentTime = getDateToMomentISOString();
          const responseData = {...response, currentTime};
          props?.onMsgSend(state.messageText, responseData);
        }
        if (state?.selectedPatient?.uuid) {
          await updatePatientRegarding(response, attachmentIds)
        }
        closeModal();
        if (props?.selectedConversation?.uuid || response?.conversation?.uuid) {
          const eventBus = EventBus.getEventBusInstance()
          eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
            conversationData: props?.selectedConversation || response?.conversation,
            eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
          });
          sentAndCloseModal(response?.conversationId)
        }
        if (isSideCarContext) {
          notification.success({
            message: intl.formatMessage({id: 'eFaxSentMessage'}),
            duration: 3.0,
            placement: 'topRight',
          });
        } else {
          showToast(
            toast,
            intl.formatMessage({id: 'eFaxSentMessage'}),
            ToastType.success
          );
        }
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      } catch (e) {
        closeModal();
        if (isSideCarContext) {
          notification.error({
            message: intl.formatMessage({id: 'apiErrorMsg'}),
            duration: 3.0,
            placement: 'topRight',
          });
        } else {
          showToast(
            toast,
            intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error
          );
        }
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    }
  };

  const getDocumentsCategories = async () => {
    try {
      const response = await getDocumentCategories({
        variables: {
          params: {
            categoryId: mlovCategoryId,
          },
        },
      });
      return response?.data?.getDocumentCategories;
    } catch (e) {
      return [];
    }
  };

  const handleUploadToPatientProfile = async (attachmentIds: string[]) => {
    try {
      const documentCategoryList = await getDocumentsCategories();
      const eFaxCategoryCode = documentCategoryList?.find(
        (item: any) => item?.code === DOCUMENT_CATEGORY_CODES.E_FAX
      );

      const documentData: any = [];
      if (attachmentIds && attachmentIds?.length) {
        const uploadedAttachment = await uploadToPatientProfile(attachmentIds);

        if (eFaxCategoryCode && uploadedAttachment?.length) {
          uploadedAttachment?.forEach((item: any) => {
            documentData.push({
              caption: '',
              documentTypeId: eFaxCategoryCode?.id,
              subjectId: state.selectedPatient?.uuid,
              subjectTypeId: contactDocumentSubjectTypeId,
              documentAttachments: {
                data: [
                  {
                    attachmentId: item?.id,
                  },
                ],
              },
            });
          });
        }
      }
      if (documentData?.length) {
        const response = await createDocumentQuery({
          variables: {
            data: documentData,
          },
        });
        return response;
      } else {
        if (isSideCarContext) {
          notification.error({
            message: intl.formatMessage({id: 'apiErrorMsg'}),
            duration: 3.0,
            placement: 'topRight',
          });
        } else {
          showToast(
            toast,
            intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error
          );
        };
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      closeModal();
      if (isSideCarContext) {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    }
  };

  const updatePatientRegarding = async (
    conversationMessage: any,
    attachmentIds: string[]
  ) => {
    try {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const uploadDocument = await handleUploadToPatientProfile(attachmentIds);
      const uploadedDocumentIds =
          uploadDocument?.data?.document?.returning?.map((item: any) => {
            return item?.id;
          });
      const updatedContentAttribute = {
        ...conversationMessage?.contentAttributes,
        documentIds: uploadedDocumentIds,
      };

      const response = await updateEFaxMessage({
        variables: {
          accountId: accountId,
          messageUuid: conversationMessage?.uuid,
          contentAttribute: updatedContentAttribute,
        },
      });
      props?.onActionPerformed(
        CONVERSATION_ACTION_CODES.PATIENT_REGARDING_UPDATE,
        response?.data?.updateMessages?.returning[0]?.contentAttributes
      );
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      closeModal();
      if (isSideCarContext) {
        notification.success({
          message: intl.formatMessage({id: 'addedPatientContext'}),
          duration: 3.0,
          placement: 'topRight',
        });
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'addedPatientContext'}),
          ToastType.success
        );
      }
    } catch (e) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      closeModal();
      if (isSideCarContext) {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    }
  };

  const closeModal = () => {
    props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
  };

  const sentAndCloseModal = (conversationId: number) => {
    props?.onActionPerformed(CONVERSATION_ACTION_CODES.OPEN_INSTANT_CHAT, conversationId);
  }

  const onInboxChange = (inboxId: number) => {
    const curSelectedInbox =
      state?.inboxList.find((inboxData) => {
        return inboxData.id === inboxId;
      }) || ({} as any);

    setState((prev) => {
      return {
        ...prev,
        selectedInbox: curSelectedInbox,
      };
    });
  };

  const onReceviersChange = (id: number) => {
    const currentReceviers = state?.receiversList?.find((item: any) => {
      return item?.id === id;
    });

    setState((prev) => {
      return {
        ...prev,
        selectedReceviers: currentReceviers,
      };
    });
  };

  const getTitleView = (): JSX.Element => {
    return (
      <Stack direction="row" style={{alignItems: 'center'}}>
        <ModalActionTitle title={'sendFax'} titleColor="" />
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
          }}
        >
          <View style={{paddingRight: 20}}>
            <Pressable
              style={{
                backgroundColor: Colors.Custom.mainPrimaryPurple,
                paddingVertical: 4,
                paddingHorizontal: 8,
                borderRadius: 4,
              }}
              onPress={onSend}
            >
              <Text color={'white'} fontSize={'md'}>
                {intl.formatMessage({id: 'send'})}
              </Text>
            </Pressable>
          </View>
          <View
            style={{
              paddingLeft: 10,
              borderLeftWidth: 1,
              borderLeftColor: Colors.Custom.Gray300,
              marginVertical: 5,
            }}
          >
            <Pressable onPress={closeModal}>
              <AntIcon name="close" size={20} color={Colors.Custom.Gray400} />
            </Pressable>
          </View>
        </Stack>
      </Stack>
    );
  };

  const getInboxListOptions = () => {
    return state.inboxList?.map((inbox: any) => {
      return (
        <Option
          searchValue={inbox?.channelEfax?.efaxNumber + inbox?.name}
          key={inbox?.id}
          value={inbox?.id}
        >
          {`${inbox?.name}(${inbox?.channelEfax?.efaxNumber})`}
        </Option>
      );
    });
  };

  const getReceviersOption = () => {
    return state.receiversList?.map((data: any) => {
      return (
        <Option searchValue={data?.name + data?.customAttributeValues?.[0]?.value} key={data?.id} value={data?.id}>
          <Stack direction="row" style={{alignItems: 'center'}}>
            <EFaxActionSvg width={14} height={14} />
            <Text paddingLeft={2} color={Colors.Custom.Gray500}>{`${
              data?.name
            } • ${
              data?.customAttributeValues?.[0]?.value || data?.phoneNumber
            }`}</Text>
          </Stack>
        </Option>
      );
    });
  };

  const getAttachmentText = () => {
    let text = '';
    if (props?.isEFaxFromPatientProfile && state?.selectedAttachment?.length) {
      text =
        state?.selectedAttachment?.length > 1
          ? `${state?.selectedAttachment[0]?.attachment?.name} + ${
              state?.selectedAttachment?.length - 1
            }`
          : `${state?.selectedAttachment[0]?.attachment?.name}`;
    } else {
      text = `${fileList.length} Attachments`;
    }
    return text;
  };

  const getTextAreaElement = () => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: Colors.Custom.Gray200,
          borderRadius: 8,
        }}
      >
        <TextArea
          backgroundColor={'white'}
          placeholder={'Type fax message here'}
          totalLines={6}
          _focus={{
            style: {
              borderWidth: 0,
              borderColor: Colors.Custom.BorderColor,
            },
          }}
          borderWidth={0}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          onChangeText={(value) => {
            setState((prev) => {
              return {
                ...prev,
                messageText: value.trim(),
              };
            });
          }}
        />
        <Stack
          direction="row"
          style={{paddingHorizontal: 10, flex: 1, paddingVertical: 5}}
        >
          <Stack direction="row" style={{flex: 0.2}}>
            {!(props?.isEFaxFromPatientProfile && state.selectedAttachment) && (
              <Tooltip title="Attachments">
                <FormControl.Label isRequired>
                  <Upload
                    style={{background: ''}}
                    multiple={true}
                    beforeUpload={() => false}
                    maxCount={10}
                    fileList={[]}
                    className="msg-attachment"
                    onChange={(info) => {
                      const tempFileList: UploadFile<any>[] = [...fileList];
                      (info.fileList || []).forEach((item) => {
                        tempFileList.push(item);
                      });
                      setFileList(tempFileList);
                    }}
                  >
                    <AntIcon
                      name="paperclip"
                      size={18}
                      color={Colors.Custom.mainPrimaryPurple}
                    />
                    <Text
                      style={{
                        fontSize: 14,
                        color: Colors.Custom.mainPrimaryPurple,
                      }}
                    >
                      {'Attachment'}
                    </Text>
                  </Upload>
                </FormControl.Label>
              </Tooltip>
            )}
          </Stack>

          {(props?.isEFaxFromPatientProfile && state?.selectedAttachment) ||
          fileList.length > 0 ? (
            <Stack
              direction="row"
              style={{
                flex: 0.8,
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Pressable
                disabled={props?.isEFaxFromPatientProfile ? true : false}
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  backgroundColor: Colors.Custom.mainPrimaryPurple,
                  padding: 4,
                  borderRadius: 6,
                }}
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      isFileListDrawerOpen: true,
                    };
                  });
                }}
              >
                <AntIcon name="paperclip" color={'white'} />
                <Text
                  textOverflow={'ellipsis'}
                  style={{
                    color: 'white',
                    marginHorizontal: 4,
                    fontWeight: '500',
                    fontSize: 12,
                    lineHeight: 14.4,
                  }}
                >
                  {getAttachmentText()}
                </Text>
                {!(
                  props?.isEFaxFromPatientProfile && state.selectedAttachment
                ) && (
                  <Pressable
                    onPress={() => {
                      setFileList([]);
                    }}
                  >
                    <AntIcon name="close" color={'white'} />
                  </Pressable>
                )}
              </Pressable>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      </View>
    );
  };

  const getSearchAndSelectView = () => {
    const contactId = state?.selectedPatient?.id?.toString();
    return (
      <PatientSearchAndSelect
        isDisabled={props?.isEFaxFromPatientProfile && props?.selectedPatient}
        label={'patient'}
        value={state?.selectedPatient?.name}
        isProspect={false}
        placeholder="Search Patient"
        showOnlyLoggedInPatients={false}
        labelColor={Colors.Custom.Gray500}
        showPatientProfileLink={state?.selectedPatient?.id}
        onPatientProfileIconClick={() => {
          props?.openContactInWindowOrOpenContactIdDrawer?.(contactId);
        }}
        onChange={(participant: any) => {
          const contactData = participant?.label?.props?.contactData;
          setState((prev) => {
            return {
              ...prev,
              selectedPatient: contactData,
            };
          });
        }}
      />
    );
  };

  const getEFaxConversationBodyElem = (): JSX.Element => {
    return (
      <Stack
        direction="column"
        style={{
          flex: 1,
          paddingHorizontal: 24,
          paddingVertical: 8,
          opacity: state?.loading ? 0.5 : 1,
          backgroundColor: Colors.Custom.White,
        }}
      >
        <FormControl paddingY={2} isInvalid={state?.errors?.selectedInbox}>
          <FormControl.Label isRequired>
            <DisplayText
              size={'smMedium'}
              textLocalId={'from'}
              extraStyles={{color: Colors.Custom.Gray500}}
            />
          </FormControl.Label>
          <Select
            showSearch={true}
            allowClear={true}
            style={{
              verticalAlign: 'center',
            }}
            disabled={state.isFromMessagingWindow}
            className={'chat-inbox-select'}
            placeholder="Select from number"
            maxTagCount={'responsive'}
            filterOption={(input: string, option: any) => {
              return (option?.searchValue || '')
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toString()?.toLowerCase());
            }}
            value={state?.selectedInbox?.id}
            onChange={(inboxId) => {
              onInboxChange(inboxId);
            }}
          >
            {getInboxListOptions()}
          </Select>
          {!state?.selectedInbox && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {state?.errors?.selectedInbox}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <FormControl paddingY={2} isInvalid={state.errors.selectedReceviers}>
          <FormControl.Label isRequired>
            <DisplayText
              size={'smMedium'}
              textLocalId={'to'}
              extraStyles={{color: Colors.Custom.Gray500}}
            />
          </FormControl.Label>
          <Select
            showSearch={true}
            allowClear={true}
            placeholder="Enter or search receviers fax number"
            maxTagCount={'responsive'}
            disabled={state.isFromMessagingWindow}
            value={state?.selectedReceviers?.id}
            className="eFax-select"
            filterOption={(input, option) =>
              (option?.searchValue as unknown as string)
                ?.toLocaleLowerCase()
                ?.includes(input?.toLowerCase())
            }
            onChange={(value) => {
              onReceviersChange(value);
            }}
          >
            {getReceviersOption()}
          </Select>
          {!state?.selectedReceviers && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {state?.errors?.selectedReceviers}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <View style={{paddingVertical: 8}}>{getSearchAndSelectView()}</View>

        <View style={{paddingVertical: 8}}>
          <FormControl
            isInvalid={state?.errors?.subject}
          >
            <ModalActionInput
              fieldIs={'input'}
              label={'Subject'}
              placeholder="Enter subject"
              value={state?.subject}
              onChangeText={(value: any) => {
                setState((prev) => {
                  return {
                    ...prev,
                    subject: value,
                  };
                });
              }}
              extraStyle={{flex: 1}}
            />
            {state?.errors?.subject && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {state?.errors?.subject}
            </FormControl.ErrorMessage>
          )}
          </FormControl>
        </View>

        <FormControl
          paddingY={4}
          isInvalid={
            state?.errors?.attachment ||
            state?.errors?.attachmentLimit ||
            state?.errors?.attachmentSizeLimit
          }
        >
          {getTextAreaElement()}
          <Stack direction="row" style={{paddingTop: 10}}>
            <InfoOutlineIcon
              style={{
                color: Colors.info[700] as string,
                marginTop: '3px',
              }}
              size="xs"
            />
            <DisplayText
              extraStyles={{
                color: Colors.info[700] as string,
                fontSize: '13px',
                paddingLeft: 5,
              }}
              textLocalId="efaxDocumentsUploadSupportAllFiles"
            />
          </Stack>
          {(!(fileList?.length > 0 && fileList?.length < 11) ||
            state?.errors?.attachmentSizeLimit) && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {fileList?.length > 10
                ? state?.errors?.attachmentLimit ||
                  state?.errors?.attachmentSizeLimit
                : state?.errors?.attachment ||
                  state?.errors?.attachmentSizeLimit}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </Stack>
    );
  };

  return (
    <View>
      <Drawer
        className="custom-drawer-styles"
        visible={props?.visible}
        width={drawerWidth}
        mask={isSideCarContext ? false : true}
        style={{paddingLeft: '0', paddingRight: '0'}}
        closable
        onClose={() => closeModal()}
        title={getTitleView()}
      >
        {state.loading && (
          <View
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 10,
              backgroundColor: 'coolGray.50:alpha.70',
            }}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        )}
        {getEFaxConversationBodyElem()}
        {state.isFileListDrawerOpen ? (
          <EmailAttachmentDrawer
            fileList={fileList}
            setFileList={(newFileList) => {
              if (newFileList) {
                setFileList(newFileList);
              }
              if (newFileList.length === 0) {
                setState((prev) => {
                  return {
                    ...prev,
                    isFileListDrawerOpen: false,
                  };
                });
              }
            }}
            isOpen={state.isFileListDrawerOpen}
            onClose={() => {
              setState((prev) => {
                return {
                  ...prev,
                  isFileListDrawerOpen: false,
                };
              });
            }}
          />
        ) : (
          <></>
        )}
      </Drawer>
    </View>
  );
};

export default withMiniContactViewHOC(CreateEFaxConversationDrawer);
