import { Checkbox, Divider, Select, notification } from "antd"
import { useContext, useEffect, useState } from "react"
import { View, Text, Pressable } from "react-native"
import { Colors } from "../../../../../styles"
import AntDesign from "react-native-vector-icons/AntDesign"
import {CheckboxChangeEvent} from "antd/lib/checkbox"
import {formatGetTags} from "../../../Contacts/Tags/Helper/formatTagsData"
import {ICustomToast} from "../../../Contacts/CustomField/interface"
import {useToast} from "../../../../Toast/ToastProvider"
import {CUSTOM_FIELD_SELECT_DATA_LIMIT, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from "../../../Contacts/CustomField/CustomFieldConst"
import {CommonDataContext} from "../../../../../context/CommonDataContext"
import {getMlovListFromCategory} from "../../../../../utils/mlovUtils"
import {MLOV_CATEGORY} from "../../../../../constants"
import {PACKAGE_MLOV} from "./PackagesConst"
import {useLazyQuery} from "@apollo/client"
import TagQueries from "../../../../../services/Tags/TagQueries"
import {ToastType} from "../../../../../utils/commonViewUtils"
import {useIntl} from "react-intl"
import {IJourneyPackagesAddTagsView, IJourneyPackagesAddTagsViewState} from "./interfaces"
import {ITagsTableData} from "../../../Contacts"
import {AddPackagesLabel} from "./AddPackageLabel"
import {COMMON_ACTION_CODES} from "../../../../../constants/ActionConst"
const { Option } = Select;

export const JourneyPackagesAddTagsView = (props: IJourneyPackagesAddTagsView) => {
  const [stateData, setStateData] = useState<IJourneyPackagesAddTagsViewState>({
    labelDataLoading: false,
    labelData: [],
    visible: false,
    selectedLabelData: [],
    hoveredIndex: null,
    searchString: '',
    name: '',
    offset: 0,
    addLabelDrawer: false,
  })

  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PACKAGE_TYPE
  );

  const filterPackageMlov = labels?.filter?.((item) => {
    return item.code === PACKAGE_MLOV;
  });
  const packageMlov = filterPackageMlov?.[0];
  const categoryId = packageMlov?.id;
  const toast = useToast();

  const [GetLabelByFilterPagination] = useLazyQuery(TagQueries.GetLabelByFilterPagination, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
  })

  const setLoading = (loading: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        labelDataLoading: loading
      }
    })
  }

  const onAddLabelDrawerChanges = (visible: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        addLabelDrawer: visible,
        visible: false,
      }
    })
  }

  const onSearch = (value: string) => {
    setStateData((prev)=> {
      return {
        ...prev,
        name: value,
      }
    })
  }

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const onLabelAddUpdateSuccess = (response: ITagsTableData, isEdit?: boolean) => {
    let updatedList = stateData?.labelData;
    if (response?.id) {
      updatedList = [response, ...stateData?.labelData];
    }
    setStateData((prev) => ({
      ...prev,
      labelData: updatedList,
    }));
    notification.success({
      message: intl.formatMessage({id: 'tagAdded'}),
      duration: 2.0,
      placement: 'topRight'
    });
    onAddLabelDrawerChanges(false);
  }

  const onActionPerformed = (
    actionCode: string,
    actionData: ITagsTableData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SUCCESS:
        onLabelAddUpdateSuccess(actionData);
        break;
      default:
        break;
    }
  };

  const getPackagesLabel = async (searchString: string) => {
    setLoading?.(true);
    try {
      const response = await GetLabelByFilterPagination({
        variables: {
          category: categoryId,
          searchString: `%${searchString}%`,
          limit: CUSTOM_FIELD_SELECT_DATA_LIMIT,
          offset: stateData?.offset,
          orderBy: {updatedAt: 'desc'}
        },
      })
      if (response?.data?.labels?.length) {
        const formattedData = formatGetTags(response?.data?.labels);
        setStateData((prev) => {
          return {
            ...prev,
            labelData: formattedData,
            labelDataLoading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            labelData: [],
            labelDataLoading: false
          };
        });
      }
    } catch (error: any) {
      setLoading?.(false);
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const onShowPackageLabelOptionVisibleChanges = (value: boolean) => {
    setStateData((prev)=> {
      return {
        ...prev,
        visible: value,
      }
    })
  }

  const handleMouseEnter = (index: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        hoveredIndex: index
      }
    })
  };

  const handleMouseLeave = () => {
    setStateData((prev) => {
      return {
        ...prev,
        hoveredIndex: null
      }
    })
  };

  const isChecked = (id: number) => {
    return stateData?.selectedLabelData?.find((label) => label?.id === id) ? true : false
  }

  const onChange = (event: CheckboxChangeEvent, field: ITagsTableData) => {
    let tempSelectedLabelData: ITagsTableData[] = stateData?.selectedLabelData;
    if(event?.target?.checked) {
      tempSelectedLabelData = [...tempSelectedLabelData, field];
    } else {
      tempSelectedLabelData = tempSelectedLabelData?.filter?.((tempLabelData) => {
        return field?.id !== tempLabelData?.id
      })
    }
    setStateData((prev) => {
      return {
        ...prev,
        selectedLabelData: tempSelectedLabelData
      }
    });
  }

  const getTextColor = (index: number) => {
    return stateData?.hoveredIndex === index ? Colors.Custom.mainPrimaryPurple : Colors.Custom.Gray500;
  }

  const renderOptions = (
    <>
      {stateData?.labelData?.map?.((field, index: number) => {
          return (
            <>
            <Option key={field?.id} value={field?.title}>
              <Pressable
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginHorizontal: 14,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    borderWidth: '1px',
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                  key={field?.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Checkbox
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                    }}
                    value={field?.id}
                    checked={isChecked(field?.id)}
                    onChange={(event) => {
                      onChange(event, field)
                    }}
                  >
                    <Text style={{
                        marginLeft: 4,
                        color: getTextColor(index),
                        fontSize: 16,
                        alignSelf: 'center',
                      }}
                    >
                      {field?.title}
                    </Text>
                  </Checkbox>
                </div>
              </Pressable>
              {stateData?.labelData?.length -1 > index && <Divider style={{margin: 0}} />}
              </Option>
            </>
          )
        })
      }
    </>
  );

  const renderLabel = (label: ITagsTableData) => {
    return (
      <View
        key={label?.id}
        style={{
          backgroundColor: Colors?.Custom.Gray200,
          paddingHorizontal: '8px',
          paddingVertical: '6px',
          marginRight: 6,
          borderRadius: 20,
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 6,
        }}>
        <Text style={{
          color: Colors?.Custom.Gray700,
          fontSize: 14,
        }}>
          {label?.title}
        </Text>
        <Pressable
          onPress={()=> {
            const updatedSelectedLabelData = stateData?.selectedLabelData?.filter?.((packageLabel)=> {
              return packageLabel?.id !== label?.id
            })
            setStateData((prev)=> {
              return {
                ...prev,
                selectedLabelData: updatedSelectedLabelData
              }
            })
          }
        }>
          <AntDesign style={{
            marginLeft: 3,
            color: Colors.Custom.Gray400
          }}
            size={12}
            name="close"
          />
        </Pressable>
      </View>
    )
  }

  const renderNoDataView = (
    <>
      <Pressable
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 2
        }}
        onPress={()=> {
          onAddLabelDrawerChanges(true);
        }}
      >
        <Text>
          {stateData?.name}
        </Text>
        <Text
          style={{
            color: Colors.Custom.mainPrimaryPurple,
            fontSize: 14,
            fontWeight: '500',
          }}
        >
          {intl.formatMessage({id: 'addPackageTag'})}
        </Text>
      </Pressable>
    </>
  )

  useEffect(() => {
    if (stateData?.searchString) {
        getPackagesLabel(stateData?.searchString);
    } else {
      getPackagesLabel('');
    }
  }, [stateData?.searchString]);

  useEffect(() => {
    props?.onDetailChange(stateData?.selectedLabelData)
  },[stateData?.selectedLabelData])

  useEffect(() => {
    const common=stateData?.labelData?.filter?.(label => props?.productPackageTag?.some?.(tag => label?.id===tag?.tagId));
    setStateData((prev) => {
      return {
        ...prev,
        selectedLabelData: common,
      };
    });
  },[stateData?.labelData])

  return (<>
    <View>
      <Select
        showSearch
        className="select-custom-field"
        popupClassName="select-custom-field"
        style={{
          borderRadius: 6,
          width: '100%',
        }}
        notFoundContent={renderNoDataView}
        loading={stateData?.labelDataLoading}
        open={stateData?.visible}
        onSearch={onSearch}
        placeholder="Select tags"
        onDropdownVisibleChange={(open) => onShowPackageLabelOptionVisibleChanges(open)}
      >
        {renderOptions}
      </Select>
        <View style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: 14,
        }}>
          {stateData?.selectedLabelData?.map?.((label, index: number) => {
            return <>
              {renderLabel(label)}
            </>
          })}
        </View>
    </View>
    {stateData?.addLabelDrawer && <AddPackagesLabel
      name={stateData?.name}
      isDrawerOpen={stateData?.addLabelDrawer}
      onDrawerClose={() => {
        onAddLabelDrawerChanges(false);
      }}
      onActionPerformed={onActionPerformed}
      category={packageMlov}
      setLoading={setLoading}
    />}
  </>)
}
