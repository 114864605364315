import { useLazyQuery } from '@apollo/client';
import { Divider,HStack,Spinner,View,useToast } from 'native-base';
import { useContext,useEffect,useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { CARESTUDIO_APOLLO_CONTEXT, CLOUD_TELEPHONY_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import {
  FlowType,
  IWorkflowContextData,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import CloudTelephonyQueries from '../../../../../services/CloudTelephony/CloudTelephonyQueries';
import { getAccountUUID,getUserActionObjectFromActionCode,getUserUUID } from '../../../../../utils/commonUtils';
import { ToastType,showToast } from '../../../../../utils/commonViewUtils';
import { getMlovIdFromCode } from '../../../../../utils/mlovUtils';
import { useMouseMovementCheck } from '../../../../../utils/useMouseMovementCheck';
import ConfirmOnExitOrReload from '../../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import {
  handleConfigureCareJourneyAction,
  handleCreateOnboardingAutomationAction,
  handleSetupPhoneTreeAction
} from '../../../../common/UsageLevel/UsageLevelHandlers';
import { checkStepFunctionRequestQueueStatus, updateEmailNames, updatePushNotificationNames, updateSMSNames } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { getWorkflowBasePath,getWorkflowListPathByFlowType } from '../../workflowUtils';
import { ExecuteWorkflow } from '../ExecuteWorkflow';
import { WORKFLOW_TAGS } from './../../../../../constants/StringConst';
import { addOrUpdateWorkflowStyes as styles } from './../Styles';
import { LOADING_STATUS,MetaDataFieldType } from './Enums';
import {
  IFrequencyData,
  ILibNode,
  ITemplateIdMap,
  ITriggerTypeData,
  IWorkflowNodeMasterData,
  IWorkflowTab,
  IWorkflowTabAction
} from './IWorkflow';
import { WorkFlowGoalAndReview } from './WorkFlowGoalAndReview';
import {
  getWorkflowById,
  getWorkflowByMasterId,
  getWorkflowNodeMasterData,
  getWorkflowNodeMetaData,
  saveIvrWorkflow,
  saveWorkflow
} from './WorkflowApi';
import { WorkflowFooter } from './WorkflowFooter';
import { WorkflowGoal } from './WorkflowGoalView';
import { WorkflowHeader } from './WorkflowHeader';
import { WorkflowOnSavePopup } from './WorkflowHeader/WorkflowOnSavePopup';
import { WorkflowRender } from './WorkflowRenderView';
import { WorkflowReview } from './WorkflowReviewView';
import { WorkflowTrigger } from './WorkflowTriggerView';
import NewWorkflowTrigger from './WorkflowTriggerView/NewWorkflowTrigger';
import {
  getFormTemplateUuidList,
  getWorkflowDataFromNodeType,
  isOnboardingWorkflow,
  updateFormNames
} from './WorkflowUtils';
import { GET_FORM_NAME_BY_IDS } from '../../../../../services/Forms/FormsQueries';
import { INPUT_FIELD_TYPE } from '../../FlowComponent/StateNodes/NodeInputField';
import CMSService from '../../../../../services/CommonService/CMSService';

const AddOrUpdateWorkflow = (props: {
  height?: number;
  isViewOnly?: boolean;
  customHeader?: JSX.Element;
  onWorkflowNameChange?: any;
}) => {
  const [workflowAlertInfoOnSave, setWorkflowAlertInfoOnSave] = useState({
    isShowPopup: false,
  })
  const { height } = props.height
    ? { height: props.height }
    : Dimensions.get('window');
  const intl = useIntl();
  const toast = useToast();
  const isInactive = useMouseMovementCheck(10 * 1000);
  const [allowedFlowTypeList, setAllowedFlowTypeList] = useState([FlowType.all])
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>();
  const [surveyForm, setSurveyForm] = useState<any>();
  const [isSendSurveyForm, setIsSendSurveyForm] = useState(false);
  const [isTriggerScreenValid, setIsTriggerScreenValid] = useState(false);
  const [isShowErrorColor, setIsShowErrorColor] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pathParams = useParams();
  const [workflowMasterId, setWorkflowMasterId] = useState(pathParams.workflowId);
  const [testWorkflowMasterId, setTestWorkflowMasterId] = useState(pathParams.workflowId);
  const [draftWorkflowMasterId, setDraftWorkflowMasterId] = useState();
  const [libNodeIdList, setLibNodeIdList] = useState<Array<ILibNode>>([]);
  const [tagList, setTagList] = useState<Array<string>>([]);
  const [showGoalReviewModal, setShowGoalReviewModal] = useState<boolean>(false);
  const [finalSubmitReview, setFinalSubmitReview] = useState<boolean>(false);
  const [isDraftSave, setIsDraftSave] = useState<boolean>(false);
  const [showSettingsDrawer, setShowSettingsDrawer] = useState<boolean>(false);

  const [workflowDescription, setWorkflowDescription] = useState('');
  const [triggerTypeMap, setTriggerTypeMap] = useState<{
    [key: string]: ITriggerTypeData;
  }>({});
  const [triggerNodeList, setTriggerNodeList] = useState<
    Array<IWorkflowNodeMasterData>
  >([]);
  const { state }: any = useLocation();
  const [workflowName, setWorkflowName] = useState(state?.name || '');
  const [frequency, setFrequency] = useState('');
  const [weekDay, setWeekDay] = useState('');
  const [date, setDate] = useState('');
  const [timezone, setTimezone] = useState<string | undefined>()
  const [flowType, setFlowType] = useState(searchParams?.get('flowType') || '');
  const [workflowId, setWorkflowId] = useState(searchParams?.get('workflowId') || '');
  const [workflowContext, setWorkflowContext] = useState<IWorkflowContextData>({
    nodeMasterDataMap: {},
    nodeMetaData: undefined,
    flowType: flowType,
    setNodeMasterDataMap: (data) => {
      setWorkflowContext((prev) => ({ ...prev, nodeMasterDataMap: data }));
    },
  });
  const onWorkflowNameChange = (name: string) => {
    props.onWorkflowNameChange ? props.onWorkflowNameChange(name) : '';
    setWorkflowName(name);
  };
  const [isTest, setIsTest] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [time, setTime] = useState('');
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const IvrUsageTypeList = mlovData['IvrUsageType'] || [];
  const inboundIvrTypeId = getMlovIdFromCode(IvrUsageTypeList, 'INCOMING_CALLS');
  const [phoneTreeType, setPhoneTreeType] = useState(inboundIvrTypeId);

  const [triggerType, setTriggerType] = useState('');
  const [triggerCondition, setTriggerCondition] = useState<string>('');
  const [nodes, setNodes] = useState<Array<any>>([]);
  const [edges, setEdges] = useState<Array<any>>([]);
  const [isExecuteWorkflow, setIsExecuteWorkflow] = useState(false);
  const navigate = useNavigate();
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const [triggerFrequencyList, setTriggerFrequencyList] = useState<
    Array<IFrequencyData>
  >([]);
  const [GET_IVR_BY_WORKFLOW_MASTER_ID] = useLazyQuery(
    CloudTelephonyQueries.GET_IVR_BY_WORKFLOW_MASTER_ID,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const getInitTab = () => {
    const tab = searchParams.get('currentTab') || WORKFLOW_TAGS.WORKFLOW;
    const flowType = searchParams.get('flowType') || '';
    if (tab != WORKFLOW_TAGS.TRIGGER && !workflowMasterId) {
      setSearchParams({ currentTab: WORKFLOW_TAGS.TRIGGER, flowType: flowType });
      return WORKFLOW_TAGS.TRIGGER;
    }
    return tab;
  };
  const [currentTab, setCurrentTab] = useState(getInitTab());

  const [triggerInfoLoadingStatus, setTriggerInfoLoadingStatus] = useState(
    LOADING_STATUS.needToLoad
  );
  const [
    workflowNodeMasterDataLoadingStatus,
    setWorkflowNodeMasterDataLoadingStatus,
  ] = useState(LOADING_STATUS.needToLoad);
  const [nodeMetaDataLoadingStatus, setNodeMetaDataLoadingStatus] = useState(
    LOADING_STATUS.needToLoad
  );
  const [saveStatusMachineStatus, setSaveStatusMachineStatus] = useState(
    LOADING_STATUS.needToLoad
  );

  const [getFormNames, { loading: formNamesLoading }] = useLazyQuery<{ forms: { id: string; name: string }[] }>(GET_FORM_NAME_BY_IDS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache'
  });

  // get the state from location
  const contentType = searchParams.get('contentType');
  const isFromCMS =
    contentType == 'sms' ||
    contentType == 'notification' ||
    contentType == 'email';

  const DEFAULT_NODE_TYPE = searchParams.get('DEFAULT_NODE_TYPE');

  const careJourneyActionObject = getUserActionObjectFromActionCode(
    'CONFIGURE_A_CARE_JOURNEY'
  );
  const careJourneyLevel = careJourneyActionObject.level || '';

  const automationActionObject = getUserActionObjectFromActionCode(
    'CREATE_AN_ONBOARDING_AUTOMATION'
  );
  const automationLevel = automationActionObject.level || '';

  const phoneTreeActionObject =
    getUserActionObjectFromActionCode('SETUP_A_PHONE_TREE');
  const phoneTreeLevel = phoneTreeActionObject.level || '';

  useEffect(() => {
    let interval: any;
    if (flowType === 'IVR' && isDraftSave === true) {

      interval = setInterval(() => {
        if (!isInactive && !finalSubmitReview) {
          setShowGoalReviewModal(true)
        }
      }, 10 * 1000);

      return () => {
        clearInterval(interval);
      }
    } else {
      return () => {
        clearInterval(interval);
      }
    }
  }, [isDraftSave, isInactive])

  const onSaveWorkflow = () => {
      setIsSaveLoading(true);
      // setSaveStatusMachineStatus(LOADING_STATUS.loadingValue);
    const nodesCopyWithRequiredField = nodes.map((nodeData) => {
      if (nodeData.data) {
        delete nodeData.data.isEdit;
      }
      const nodeDataCopy = JSON.parse(JSON.stringify(nodeData));
      return nodeDataCopy;
    });
    const edgesCopyWithRequiredField = edges.map((edge) => {
      const edgeCopy = JSON.parse(JSON.stringify(edge));
      return edgeCopy;
    });
    const data: any = {
      flowType: flowType,
      isEnabled: isEnabled,
      workflowName: workflowName,
      tagList: tagList,
      description: workflowDescription,

      triggerInfo: {
        frequency: frequency,
        day: weekDay,
        time: time,
        timezone: timezone,
        date: date,
        triggerCondition: triggerCondition,
        triggerType: triggerType,
      },
      edges: edgesCopyWithRequiredField,
      nodes: nodesCopyWithRequiredField,
      surveyForm: surveyForm,
      isSendSurveyForm: isSendSurveyForm,
      isDraftOnly: flowType === 'IVR' && (finalSubmitReview ? false : true),
    };
    if(flowType ===  FlowType.MICRO_AUTOMATION){
      data.allowedFlowTypeList = allowedFlowTypeList;
    }
    if (workflowMasterId || draftWorkflowMasterId || testWorkflowMasterId) {
      data.id = workflowMasterId || draftWorkflowMasterId || testWorkflowMasterId;
    }
    if (flowType !== 'IVR') {
      callSaveWorkFlow(data);
    } else {
      if (data.nodes.length) {
        callSaveWorkFlow(data);
      }
    }
  };

  const phoneTreeCreatedSuccessfullyToast = ()=> {
    showToast(
      toast,
      intl.formatMessage({id :'phoneTreeCreatedSuccessfully'}),
      ToastType.success,
      4000
    );
  }

  const callSaveWorkFlow = (data: any) => {
    saveWorkflow(
      data,
      (result: any) => {
        if (data.flowType == 'JOURNEYS') {
          handleConfigureCareJourneyAction({
            accountUuid,
            userUuid,
            level: careJourneyLevel,
          });
        }

        if (isOnboardingWorkflow(data)) {
          handleCreateOnboardingAutomationAction({
            accountUuid,
            userUuid,
            level: automationLevel,
          });
        }
        if (result.workflowMasterId && result.workflowMasterId != testWorkflowMasterId) {
          setTestWorkflowMasterId(result.workflowMasterId)
        }
        if (data.flowType === 'IVR') {
          setDraftWorkflowMasterId(result.workflowMasterId)

          if (finalSubmitReview) {
            const isUpdate = data.id ? true : false;
            const ivrInput = {
              workflowMasterId: result.workflowMasterId,
              tenantId: result.tenantId,
              flowName: data.workflowName,
              isUpdate: isUpdate,
              ivrUsageTypeId: phoneTreeType,
            };
            saveIvrWorkflow(
              ivrInput,
              (result: any) => {
                handleSetupPhoneTreeAction({
                  accountUuid,
                  userUuid,
                  level: phoneTreeLevel,
                }).then((r) => {
                  const navigationPath = getWorkflowBasePath(flowType);
                  phoneTreeCreatedSuccessfullyToast();
                  navigate(navigationPath, { state: {} });
                });
              },
              (error: any) => {

                alert('Error in save ivr workflow');
              }
            );
          }
        } else {
          const navigationPath = getWorkflowListPathByFlowType(flowType);
          if (isTest) {
            setIsExecuteWorkflow(true);
            setIsTest(false);
            setIsSaveLoading(false)
          } else {
            setIsSaveLoading(false)
            if (data.flowType == 'JOURNEYS') {
              navigate(navigationPath, {state: {}});
            } else {
              setWorkflowAlertInfoOnSave({
                isShowPopup: true,
              });
            }
          }
        }
      },
      (error: any) => {
        setIsSaveLoading(false);

      }
    );
  }

  const setDefaultZerothElement = (triggerCondition: string, nodeList: any) => {
    if (
      !workflowMasterId &&
      triggerCondition &&
      workflowContext.nodeMasterDataMap
    ) {

      const newNodes = nodes.map((node) => {
        return node;
      });
      const newNode = workflowContext.nodeMasterDataMap[triggerCondition];
      const node = workflowContext.nodeMasterDataMap[newNode.type];

      const zerothElement: any = {
        type: node.awsState,
        metaData: { naturalLanguageParseNodeList: nodeList || [], userInputFieldList: node.userInputFieldList },
        data: {
          isStartNode: true,
          nodeType: node.type,
        },
        title: newNode.displayName,
      };
      newNodes[0] = zerothElement;
      setNodes(newNodes);


    }
  };

  useEffect(() => {
    setFlowType(searchParams?.get('flowType') || '');
    setWorkflowNodeMasterDataLoadingStatus(LOADING_STATUS.loadingValue);
    getWorkflowNodeMasterData(
      searchParams?.get('flowType') || '',
      (nodeMasterData: any) => {
        const libNodeIdList = nodeMasterData.libNodeList;
        const nodeMasterDataMap = nodeMasterData.nodeMap;
        const frequencyList = nodeMasterData.frequencyList;
        setLibNodeIdList(libNodeIdList || []);
        setWorkflowContext((prev) => ({
          ...prev,
          nodeMasterDataMap: nodeMasterDataMap,
        }));
        setTriggerFrequencyList(frequencyList);
        setTriggerTypeMap(nodeMasterData?.triggerTypeMap || {});
        if (nodeMasterDataMap) {
          const triggerNodeListInit: Array<IWorkflowNodeMasterData> = [];
          Object.keys(nodeMasterDataMap).forEach((key: string) => {
            const node: IWorkflowNodeMasterData = JSON.parse(
              JSON.stringify(nodeMasterDataMap[key])
            );
            if (node?.isTriggerNode) {
              triggerNodeListInit.push(node);
            }
          });
          setTriggerNodeList(triggerNodeListInit);
        }
        setWorkflowNodeMasterDataLoadingStatus(LOADING_STATUS.loadedValue);
      },
      (error: any) => {

      }
    );
    setNodeMetaDataLoadingStatus(LOADING_STATUS.loadingValue);
    getWorkflowNodeMetaData(
      flowType,
      MetaDataFieldType.metaData,
      '',
      (data: any) => {
        setWorkflowContext((prev) => ({
          ...prev,
          nodeMetaData: {
            frequencyList: data?.frequencyList || [],
            triggerUnits: data?.triggerUnits || [],
            durationUnits: data?.durationUnits || [],
            reminderUnits: data?.reminderUnits || [],
          },
        }));
        setNodeMetaDataLoadingStatus(LOADING_STATUS.loadedValue);
      },
      (error: any) => {

        setNodeMetaDataLoadingStatus(LOADING_STATUS.loadedValue);
      }
    );
  }, [searchParams?.get('flowType')])

  useEffect(() => {
    setTriggerInfoLoadingStatus(LOADING_STATUS.needToLoad);
  }, [pathParams.workflowId])


  const updateFormInformation = async (nodes: any[]) => {
    const uuids = getFormTemplateUuidList(nodes)
    const formResponse = await getFormNames({
      variables: { formIds: uuids }
    });
    const formIdToNameMap: { [key: string]: string } = {};
    formResponse?.data?.forms?.forEach((form: { id: string; name: string }) => {
      formIdToNameMap[form?.id] = form?.name;
    });

    const formUpdates = updateFormNames(nodes, formIdToNameMap)
    return formUpdates
  }

  const updateWorkflowDataInformation = async (workflowData: any) => {
    const formUpdate = updateFormInformation(workflowData?.graph?.nodes)
    const pushNotificationUpdate = updatePushNotificationNames(workflowData?.graph?.nodes)
    const smsUpdate = updateSMSNames(workflowData?.graph?.nodes)
    const emailUpdate = updateEmailNames(workflowData?.graph?.nodes)
    const update = await Promise.all([formUpdate, pushNotificationUpdate, smsUpdate, emailUpdate])
    return update
  }


  if (
    (pathParams.workflowId || workflowId) &&
    triggerInfoLoadingStatus == LOADING_STATUS.needToLoad &&
    workflowNodeMasterDataLoadingStatus == LOADING_STATUS.loadedValue
  ) {
    setTriggerInfoLoadingStatus(LOADING_STATUS.loadingValue);
    const id = workflowId ? workflowId : pathParams.workflowId;
    const method = workflowId ? getWorkflowById : getWorkflowByMasterId;
    method(
      id || '',
      accountUuid,
      async (workflowData: any) => {
        const updateWorkflowInfo = await updateWorkflowDataInformation(workflowData)

        if (workflowData?.triggerInfo) {
          const triggerInfo = workflowData.triggerInfo || {};
          setDate(triggerInfo.date || '');
          setWeekDay(triggerInfo.day || '');
          setTimezone(triggerInfo?.timezone);
          setFrequency(triggerInfo.frequency || '');
          setTime(triggerInfo.time || '');
          setTriggerType(triggerInfo.triggerType || '');
          setTriggerCondition(triggerInfo.triggerCondition || '');
        }

        if (flowType === FlowType.ivr) {
          const ivrFlowObject = await GET_IVR_BY_WORKFLOW_MASTER_ID({
            context: { service: CLOUD_TELEPHONY_APOLLO_CONTEXT },
            variables: {
              accountId: accountUuid,
              workflowMasterId: workflowMasterId
            },
          });
          setPhoneTreeType(ivrFlowObject.data?.flows[0]?.ivrUsageTypeId || inboundIvrTypeId);
        }
        if (flowType === FlowType.MICRO_AUTOMATION) {
          setAllowedFlowTypeList(
            (workflowData?.workflow?.flowTypeMap || []).map((data: any) => {
              return data?.flowType;
            })
          );
        }
        setIsEnabled(workflowData?.workflow?.isEnabled);
        setWorkflowDescription(flowType === 'IVR' && workflowData?.workflow?.draft?.metadata?.description || workflowData.workflow.description || '');
        onWorkflowNameChange(flowType === 'IVR' && workflowData?.workflow?.draft?.metadata?.workflowName || workflowData?.workflow.name || '');
        setSurveyForm(workflowData?.workflow?.surveyForm);
        setIsSendSurveyForm(workflowData?.workflow?.isSendSurveyForm || false);
        setTagList(workflowData?.workflow?.tagList);
        if (workflowData?.graph) {
          const graph = workflowData.graph;
          const nodes = (graph?.nodes || [])?.map((element: any) => {
            element.data = element.data || {};
            element.data.setData = function (key: any, value: any) {
              if (this.metaData) {
                this.metaData[key] = value;
              }
            };
            return element;
          });
          setNodes(nodes);
          const edges = (graph?.edges || [])?.map((element: any) => {
            element.data = element.data || {};
            element.data.setData = function (key: any, value: any) {
              if (this.metaData) {
                this.metaData[key] = value;
              }
            };
            return element;
          });
          setEdges(edges);
        }
        setTriggerInfoLoadingStatus(LOADING_STATUS.loadedValue);
      },
      (error: any) => {

      }
    );
  } else if (
    isFromCMS &&
    triggerInfoLoadingStatus == LOADING_STATUS.needToLoad &&
    workflowNodeMasterDataLoadingStatus == LOADING_STATUS.loadedValue
  ) {
    const workflowData = getWorkflowDataFromNodeType({
      nodeType: DEFAULT_NODE_TYPE || '',
    });
    setTriggerInfoLoadingStatus(LOADING_STATUS.loadingValue);
    if (workflowData?.triggerInfo) {
      const triggerInfo = workflowData.triggerInfo || {};
      setDate(triggerInfo.date || '');
      setWeekDay(triggerInfo.day || '');
      setFrequency(triggerInfo.frequency || '');
      setTime(triggerInfo.time || '');
      setTriggerType(triggerInfo.triggerType || '');
      setTriggerCondition(triggerInfo.triggerCondition || '');
    }
    setIsEnabled(false);
    setWorkflowDescription('');
    onWorkflowNameChange(state?.name || state?.templateName);
    setIsSendSurveyForm(false);
    setTagList([]);
    if (workflowData?.graph) {
      const graph = workflowData.graph;
      const nodes = (graph?.nodes || [])?.map((element: any) => {
        element.data = element.data || {};
        element.data.setData = function (key: any, value: any) {
          if (this.metaData) {
            this.metaData[key] = value;
          }
        };
        return element;
      });
      setNodes(nodes);
      const edges = (graph?.edges || [])?.map((element: any) => {
        element.data = element.data || {};
        element.data.setData = function (key: any, value: any) {
          if (this.metaData) {
            this.metaData[key] = value;
          }
        };
        return element;
      });
      setEdges(edges);
    }
    setTriggerInfoLoadingStatus(LOADING_STATUS.loadedValue);
  }

  const isValidScreenNavigation = (currentTab: string) => {
    if (
      [
        WORKFLOW_TAGS.GOALS,
        WORKFLOW_TAGS.WORKFLOW,
        WORKFLOW_TAGS.REVIEW,
      ].indexOf(currentTab) != -1 &&
      isTriggerScreenValid
    ) {
      return true;
    } else if (currentTab === WORKFLOW_TAGS.TRIGGER) {
      return true;
    }
    return false;
  };

  const setCurrentTabWithSearchParams = (currentTab: string | null) => {
    setIsShowErrorColor(true);
    if (currentTab && isValidScreenNavigation(currentTab)) {
      const defaultTab = WORKFLOW_TAGS.TRIGGER;
      setSearchParams({ currentTab: currentTab || defaultTab });
      setCurrentTab(currentTab || defaultTab);
    }
  };
  const [showAutomationAudit, setShowAutomationAudit] = useState(false);
  const loading =
    [
      triggerInfoLoadingStatus,
      saveStatusMachineStatus,
      workflowNodeMasterDataLoadingStatus,
      nodeMetaDataLoadingStatus,
    ].indexOf(LOADING_STATUS.loadingValue) != -1;

  if (loading) {
    return (
      <HStack style={styles.loadingHStack}>
        <Spinner style={styles.loadingSpinner} size="lg"></Spinner>
      </HStack>
    );
  }

  const workflowReviewViewActions: Array<IWorkflowTabAction> = [
    {
      onPress: () => {
        onSaveWorkflow();
      },
      actionDisplayName: 'Publish',
    },
  ];

  const reviewTab: IWorkflowTab = {
    tabDisplayName: 'Review',
    tabCode: WORKFLOW_TAGS.REVIEW,
    actions: workflowReviewViewActions,
  };
  const tabList: Array<IWorkflowTab> = [reviewTab];

  const onTriggerConditionChange = (triggerCondition: string, list?: any, isSaveDraft?: boolean) => {
    setTriggerCondition(triggerCondition);
    setIsDraftSave(isSaveDraft || false)
    if (flowType === 'IVR' && isSaveDraft) {
      setShowGoalReviewModal(true);
    }
    if (triggerCondition && !isFromCMS) {
      setDefaultZerothElement(triggerCondition, list);
    }
  };

  const getTriggerElement = () => {
    const isNewWorkflowTrigger = true
    return (
      isNewWorkflowTrigger ?
        <NewWorkflowTrigger
          timezone={timezone}
          setTimezone={setTimezone}
          key={workflowMasterId}
          workflowId={workflowMasterId}
          setTagList={setTagList}
          tagList={tagList}
          triggerFrequencyList={triggerFrequencyList}
          workflowDescription={workflowDescription}
          setWorkflowDescription={setWorkflowDescription}
          isShowErrorColor={isShowErrorColor}
          setIsTriggerScreenValid={setIsTriggerScreenValid}
          triggerNodeList={triggerNodeList}
          triggerTypeMap={triggerTypeMap}
          workflowName={workflowName}
          setStepMachineName={(value: string) => {
            onWorkflowNameChange(value);
          }}
          frequency={frequency}
          setFrequency={(value: string) => {
            setFrequency(value);
          }}
          weekDay={weekDay}
          setWeekDay={(value: string) => {
            setWeekDay(value);
          }}
          date={date}
          setDate={(value: string) => {
            setDate(value);
          }}
          time={time}
          setTime={(value: string) => {
            setTime(value);
          }}
          triggerType={triggerType}
          setTriggerType={(value: string) => {
            setTriggerType(value);
          }}
          triggerCondition={triggerCondition}
          setTriggerCondition={onTriggerConditionChange}
          flowType={workflowContext.flowType}
          phoneTreeType={phoneTreeType}
          setPhoneTreeType={(value: string) => {
            setPhoneTreeType(value);
          }}
        /> :
        <WorkflowTrigger
          timezone={timezone}
          setTimezone={setTimezone}
          key={workflowMasterId}
          workflowId={workflowMasterId}
          setTagList={setTagList}
          tagList={tagList}
          triggerFrequencyList={triggerFrequencyList}
          workflowDescription={workflowDescription}
          setWorkflowDescription={setWorkflowDescription}
          isShowErrorColor={isShowErrorColor}
          setIsTriggerScreenValid={setIsTriggerScreenValid}
          triggerNodeList={triggerNodeList}
          triggerTypeMap={triggerTypeMap}
          workflowName={workflowName}
          setStepMachineName={(value: string) => {
            onWorkflowNameChange(value);
          }}
          frequency={frequency}
          setFrequency={(value: string) => {
            setFrequency(value);
          }}
          weekDay={weekDay}
          setWeekDay={(value: string) => {
            setWeekDay(value);
          }}
          date={date}
          setDate={(value: string) => {
            setDate(value);
          }}
          time={time}
          setTime={(value: string) => {
            setTime(value);
          }}
          triggerType={triggerType}
          setTriggerType={(value: string) => {
            setTriggerType(value);
          }}
          triggerCondition={triggerCondition}
          setTriggerCondition={onTriggerConditionChange}
          flowType={workflowContext.flowType}
          phoneTreeType={phoneTreeType}
          setPhoneTreeType={(value: string) => {
            setPhoneTreeType(value);
          }}
        />

    );
  };


  const getWorkflowGoalElement = () => {
    return (
      <WorkflowGoal
        isSendSurveyForm={isSendSurveyForm}
        surveyForm={surveyForm}
        tabList={tabList}
        setSurveyForm={(surveyForm: any) => {
          setSurveyForm(surveyForm);
        }}
        setIsSendSurveyForm={(value: boolean) => {
          setIsSendSurveyForm(value);
        }}
      />
    );
  };

  const getWrokflowReviewElement = () => {
    return (
      <WorkflowReview
        weekDay={weekDay}
        triggerCondition={triggerCondition}
        triggerType={triggerType}
        triggerTypeMap={triggerTypeMap}
        triggerNodeList={triggerNodeList}
        triggerFrequencyList={triggerFrequencyList}
        frequency={frequency}
        date={date}
      ></WorkflowReview>
    );
  };

  const workflowFooter = () => {
    return (
      <WorkflowFooter
        currentTab={currentTab}
        tabList={tabList}
      ></WorkflowFooter>
    );
  };

  const workflowGoalAndReview = () => {
    onSaveWorkflow();
    setShowGoalReviewModal(false);
    return;
    return (
      <WorkFlowGoalAndReview
        setShowGoalReviewModal={setShowGoalReviewModal}
        getWorkflowGoalElement={getWorkflowGoalElement}
        getWrokflowReviewElement={getWrokflowReviewElement}
        workflowFooter={() => <></>}
      />
    );
  };

  const getTagElements = () => {
    return (
      <WorkflowRender
        setWorkflowContext={setWorkflowContext}
        setAllowedFlowTypeList={(allowedFlowTypeList)=>{
          setAllowedFlowTypeList(allowedFlowTypeList)
        }}
        allowedFlowTypeList= {allowedFlowTypeList}
        key={workflowMasterId}
        workflowMasterId={workflowMasterId}
        showGoalReviewModal={showGoalReviewModal}
        getTriggerElement={
          props.isViewOnly
            ? () => {
              return <></>;
            }
            : getTriggerElement
        }
        workflowGoalAndReview={workflowGoalAndReview}
        flowType={FlowType.workflow}
        isViewOnly={props.isViewOnly}
        triggerCondition={triggerCondition}
        libNodeIdList={libNodeIdList}
        nodes={nodes}
        setNodes={(nodes: any) => {
          // alert(nodes.length)
          setNodes(nodes);
        }}
        edges={edges}
        setEdges={setEdges}
        onNodeDataChange={() => {
        }}
      />
    );
  };

  const checkAutomationExecutionStatus = (stepFunctionRequestQueueId: string, count: number): any => {
    if (stepFunctionRequestQueueId && count <= 5) {
      setIsTest(true);
      setIsSaveLoading(true);
      setTimeout(() => {
        checkStepFunctionRequestQueueStatus(stepFunctionRequestQueueId).then((info: any) => {
          if (info?.stepFunctionRequestQueue?.executionStatus != 'EXECUTED') {
            checkAutomationExecutionStatus(stepFunctionRequestQueueId, count + 1)

          } else {
            setIsTest(false);
            setIsSaveLoading(false);
            setShowAutomationAudit(true)
          }
        })
      }, 5000);
    } else {
      setIsTest(false);
      setIsSaveLoading(false);
      setShowAutomationAudit(true)
    }
  }
  const isShowTestAndExecute = triggerCondition == 'ForAllPatientWithFilter';
  return (
    <ConfirmOnExitOrReload>
      <WorkflowContext.Provider value={workflowContext} key={workflowId}>
        <View style={[styles.mainView, {height: height}]}>
          {props.customHeader ? (
            props.customHeader
          ) : (
            <WorkflowHeader
              workflowName={workflowName}
              isShowTestAndExecute={isShowTestAndExecute}
              showAutomationAudit={showAutomationAudit}
              setShowAutomationAudit={setShowAutomationAudit}
              workflowMasterId={testWorkflowMasterId}
              isSaveLoading={isSaveLoading}
              isTest={isTest}
              setShowGoalReviewModal={(
                isShowGoalReview: boolean,
                isTest: boolean
              ) => {
                setIsTest(isTest);
                setShowGoalReviewModal(isShowGoalReview);
                if (flowType === 'IVR' && isShowGoalReview) {
                  setIsDraftSave(false);
                  setFinalSubmitReview(true);
                }
              }}
              tabList={tabList}
              setCurrentTab={(currentTab: string) => {
                setCurrentTabWithSearchParams(currentTab);
              }}
              currentTab={currentTab}
              flowType={flowType}
            ></WorkflowHeader>
          )}
          <Divider />
          {loading ? (
            <HStack style={styles.loadingHStack}>
              <Spinner style={styles.loadingSpinner} size="lg"></Spinner>
            </HStack>
          ) : (
            <></>
          )}
          {getTagElements()}
        </View>
        {testWorkflowMasterId && isShowTestAndExecute ? (
          <ExecuteWorkflow
            isHideGroup={true}
            isHideEmployer={true}
            isOpen={isExecuteWorkflow}
            workflowData={{workflowMasterId: testWorkflowMasterId || ''}}
            onCompleteAction={(workflowData: any, info: any, response: any) => {
              setIsExecuteWorkflow(false);
              setIsTest(true);
              setIsSaveLoading(false);
              checkAutomationExecutionStatus(
                response[0]?.stepFunctionRequestQueueId,
                0
              );
            }}
          ></ExecuteWorkflow>
        ) : (
          <></>
        )}

        <WorkflowOnSavePopup
          isShowPopup={workflowAlertInfoOnSave.isShowPopup}
          onAction={(action) => {
            if (action == 'LIST_VIEW') {
              const navigationPath = getWorkflowListPathByFlowType(flowType);
              navigate(navigationPath, {state: {}});
            }
            setWorkflowAlertInfoOnSave({isShowPopup: false});
          }}
        ></WorkflowOnSavePopup>
      </WorkflowContext.Provider>
    </ConfirmOnExitOrReload>
  );
};

export default AddOrUpdateWorkflow;
