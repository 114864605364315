import {useLazyQuery} from '@apollo/client';
import {
  Box,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Platform} from 'react-native';
import {PARENT_CODE, RESPONSE_CATEGORY_CODE} from '../../../../../../constants';
import {MLOV_CATEGORY} from '../../../../../../constants';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import ResponseQueries from '../../../../../../services/Responses/ResponseQueries';
import {Colors} from '../../../../../../styles/Colors';
import {getMlovId} from '../../../../../../utils/mlovUtils';
import {NoDataFound} from '../../../../../common/NoDataFound';
import {useKeypress} from '../../../../../CustomHooks/KeyPressHook';
import {styles} from './CannedResponsesStyles';
import {ICannedResponses, ICannedResponsesProps} from './interface';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { testID, TestIdentifiers } from '../../../../../../testUtils';

const CannedResponses = (props: ICannedResponsesProps) => {
  const toast = useToast();
  const intl = useIntl();
  const {
    searchString,
    onCannedResponsesSelection,
    categoryCode,
    parentCode,
    skipAllCategory,
    customJSXWrapper,
  } = props;
  const searchText = searchString.slice(1);
  const mlovData = useContext(CommonDataContext);
  const categoryCodeUuid = getMlovId(
    mlovData.MLOV,
    MLOV_CATEGORY.CANNED_RESPONSE_TYPE,
    categoryCode || ''
  );
  const categoryAllCodeUuid = getMlovId(
    mlovData.MLOV,
    MLOV_CATEGORY.CANNED_RESPONSE_TYPE,
    RESPONSE_CATEGORY_CODE.ALL
  );
  const cannedResponsesMainRef: any = useRef(null);
  const [cannedResponseState, setCannedResponseState] = useState<{
    selectedResponseIndex: number;
    cannedResponseList: ICannedResponses[];
    loading: boolean,
    hoverResponseIndex?: number;
  }>({
    selectedResponseIndex: 0,
    cannedResponseList: [],
    loading: true,
    hoverResponseIndex: undefined,
  });
  const [GetResponsesOnMessageWindow] = useLazyQuery<{
    cannedResponses: ICannedResponses[];
  }>(ResponseQueries.GetResponsesOnMessageWindow, {
    variables: {
      searchString: `%${searchText}%`,
      categoryCodeUuids: skipAllCategory ? [categoryCodeUuid] : [categoryCodeUuid, categoryAllCodeUuid],
    },
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const getFormattedContent = (content: string): string => {
    return content.replace(/\n/g, '');
  };
  const cannedResponseRef: any = useRef(null);
  const getTemplateResponses = async () => {
    try {
      setCannedResponseState((prev)=> {
        return {
          ...prev,
          loading: true,
          cannedResponseList: []
        }
      });
      const templateRes = await GetResponsesOnMessageWindow({
        variables: {
          searchString: `%${searchText}%`,
          categoryCodeUuids: (skipAllCategory ? [categoryCodeUuid] : [categoryCodeUuid, categoryAllCodeUuid]).filter(
            (item) => Boolean(item)
          ),
        },
      });
      if (templateRes?.data?.cannedResponses?.length) {
        setCannedResponseState((prev) => {
          return {
            ...prev,
            loading: false,
            cannedResponseList: templateRes?.data?.cannedResponses || [],
          };
        });
      } else {
        setCannedResponseState((prev) => {
          return {
            ...prev,
            loading: false,
            cannedResponseList: [],
          };
        });
      }
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      setCannedResponseState((prev) => {
        return {
          ...prev,
          loading: false,
          cannedResponseList: [],
        };
      });
    }
  };

  useEffect(()=> {
    if (searchText?.includes('\n')) {
      return
    }
    getTemplateResponses();
  }, [searchText])

  useKeypress(
    'ArrowDown',
    useCallback(() => {
      setCannedResponseState((prev: any) => {
        let selectedResponseIndex = prev.selectedResponseIndex;
        selectedResponseIndex++;
        if (selectedResponseIndex >= prev?.cannedResponseList.length) {
          selectedResponseIndex = prev?.cannedResponseList.length - 1;
        }
        const scrollToElemHeight = selectedResponseIndex * 30;
        if (cannedResponseRef?.current) {
          cannedResponseRef?.current.scrollTo({y: scrollToElemHeight});
        }
        return {
          ...prev,
          selectedResponseIndex: selectedResponseIndex,
        };
      });
    }, [cannedResponseState]),
    []
  );
  useKeypress(
    'ArrowUp',
    useCallback(() => {
      setCannedResponseState((prev) => {
        let selectedResponseIndex = prev.selectedResponseIndex;
        selectedResponseIndex--;
        if (selectedResponseIndex < 0) {
          selectedResponseIndex = 0;
        }
        const scrollToElemHeight = selectedResponseIndex * 30;
        if (cannedResponseRef?.current) {
          cannedResponseRef?.current.scrollTo({y: scrollToElemHeight});
        }
        return {
          ...prev,
          selectedResponseIndex: selectedResponseIndex,
        };
      });
    }, [cannedResponseState]),
    []
  );

  const handleEnter = useCallback(() => {
    setCannedResponseState((prev) => {
      const response =
        prev?.cannedResponseList?.[prev?.selectedResponseIndex];
      if (response) {
        onCannedResponsesSelection(response);
      }
      return {
        ...prev,
      };
    });
  }, [cannedResponseState]);

  useKeypress('Enter', handleEnter, []);

  {
    (isWeb() && parentCode === PARENT_CODE.MESSAGING_WINDOW_FOOTER)
      ? useEffect(() => {
          let cannedResponseTop = '-150px';
          let offsetHeight = 0;
          if (props.isReplyMessage) {
            offsetHeight = 100;
            cannedResponseTop = '-260px';
          }
          if (cannedResponsesMainRef?.current) {
            if (cannedResponsesMainRef.current.offsetHeight < 150) {
              cannedResponseTop =
                '-' +
                (cannedResponsesMainRef.current.offsetHeight + offsetHeight) +
                'px';
            }
            cannedResponsesMainRef.current.style.top = cannedResponseTop;
          }
        }, [cannedResponseState.cannedResponseList?.length, props?.isReplyMessage, parentCode])
      : null;
  }

  const getCannedResponsesList = (customMaxHeight?: number): JSX.Element => {
    const cannedResponses = cannedResponseState.cannedResponseList || [];
    return (
      <VStack
        ref={cannedResponsesMainRef}
        style={[
          isWeb()
            ? styles.cannedResponsesMain
            : styles.cannedResponsesMainNative,
          parentCode === PARENT_CODE.MESSAGING_WINDOW_FOOTER &&
          !cannedResponseState?.cannedResponseList?.length
            ? {top: -40}
            : {},
          customMaxHeight ? {maxHeight: customMaxHeight} : {}
        ]}
      >
        <ScrollView
          ref={cannedResponseRef}
          style={
            parentCode === PARENT_CODE.CREATE_SMS_DRAWER
              ? styles.cannedResponseForSms
              : styles.cannedResponse
          }
          {...testID(TestIdentifiers.scrollListView)}
        >
          {cannedResponses.length > 0 ? (
            cannedResponses.map((cannedResponse, index) => {
              return (
                <Pressable
                  _hover={{bg: Colors.primary[100]}}
                  onHoverIn={() => {
                    setCannedResponseState((prev) => ({...prev, hoverResponseIndex: index }));
                  }}
                  onHoverOut={() => {
                    setCannedResponseState((prev) => ({...prev, hoverResponseIndex: undefined }));
                  }}
                  backgroundColor={
                    cannedResponseState.selectedResponseIndex === index || cannedResponseState.hoverResponseIndex === index
                      ? Colors.primary[100]
                      : ''
                  }
                  onPress={() => {
                    onCannedResponsesSelection(cannedResponse);
                  }}
                  key={cannedResponse.id}
                >
                  <View style={styles.contentListItem}>
                    <View style={{flexDirection: 'row'}}>
                      <Text {...testID(TestIdentifiers.cannedResponseText)} style={styles.contentOverflow}>
                        <Text
                          style={styles.shortCode}
                          color={Colors.primary[400]}
                        >
                          {cannedResponse.shortCode}
                        </Text>
                        <Text> - </Text>
                        <Text style={styles.contentText}>
                          {getFormattedContent(cannedResponse.content)}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </Pressable>
              );
            })
          ) : (
            <NoDataFound isOnMessage={true} />
          )}
        </ScrollView>
      </VStack>
    );
  };


  if (!!customJSXWrapper) {
    return customJSXWrapper(getCannedResponsesList(100), cannedResponseState.loading);
  }

  return (
    <View>
      {cannedResponseState.loading ? (
        <Box style={isWeb() ? {} : styles.nativeBoxView}>
          <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
        </Box>
      ) : (
        <View>{getCannedResponsesList()}</View>
      )}
    </View>
  );
};

export default CannedResponses;
