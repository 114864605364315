import {Tooltip} from 'antd';
import React from 'react';
import './Widgets.css'

export const ToolTipView = (props: any) => {
  return (
    <Tooltip
      overlayClassName="task-tool-tip"
      placement={'left'}
      overlayInnerStyle={{
        backgroundColor: '#fff',
        borderRadius: 8,
        width: '200px',
        padding: 12
      }}
      arrowPointAtCenter={false}
      overlayStyle={{backgroundColor: '#fff', width: '200px'}}
      overlay={props.overlay}
      style={{background: 'white', borderColor: '#fff'}}
    >
      {props.children}
    </Tooltip>
  );
};
