import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const PatientOutlineIcon = (props: ICommonSvgProps) => {
  const {isSelected, width, height} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : props.defaultColor
    ? props.defaultColor
    : '#8A94A8';
  return (
    <svg
      width={width ? width : '16'}
      height={height ? height : '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.666 4.0026C10.666 5.47536 9.47211 6.66927 7.99935 6.66927C6.52659 6.66927 5.33268 5.47536 5.33268 4.0026C5.33268 2.52984 6.52659 1.33594 7.99935 1.33594C9.47211 1.33594 10.666 2.52984 10.666 4.0026Z"
        stroke={strokeColor}
      />
      <path
        d="M13.3327 11.6693C13.3327 13.3261 13.3327 14.6693 7.99935 14.6693C2.66602 14.6693 2.66602 13.3261 2.66602 11.6693C2.66602 10.0124 5.05383 8.66927 7.99935 8.66927C10.9449 8.66927 13.3327 10.0124 13.3327 11.6693Z"
        stroke={strokeColor}
      />
    </svg>
  );
};

export default PatientOutlineIcon;
