import {Collapse} from '@arco-design/web-react';
import {Stack, useFocusIdx} from '@foldhealth/easy-email-editor';
import {
  AttributesPanelWrapper,
  ColorPickerField,
} from '@foldhealth/easy-email-extensions';
import React from 'react';

export function DallePanel() {
  const {focusIdx} = useFocusIdx();
  return (
    <AttributesPanelWrapper>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Item name="1" header="Setting">
          <Stack vertical>
            <ColorPickerField
              label="Background color"
              name={`${focusIdx}.attributes.background-color`}
              inline
            />
          </Stack>
        </Collapse.Item>
      </Collapse>
    </AttributesPanelWrapper>
  );
}
