import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles';

export const styles = StyleSheet.create({
  cancelButton: {
    height: 32,
    borderColor: Colors.Custom.Gray300,
    backgroundColor: Colors.Custom.White,
    borderWidth: 0.5,
    marginRight: 8,
    padding: 8,
    borderRadius: 4,
  },
  saveButton: {
    height: 32,
    backgroundColor: Colors.FoldPixel.PRIMARY300,
    padding: 8,
  }
});
