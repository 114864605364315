import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  shadowBoxUserAccessMainTable: {
    overflow: 'scroll',
    rounded: 'lg',
    // marginLeft: 20,
    // marginRight: 20,
    marginBottom: 8,
  },
});
