import React from 'react';
import { ICommonSvgProps } from './interfaces';

const AutomateWorkflowSvg = (props: ICommonSvgProps) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3904 5.95585C13.4749 3.84229 14.0172 2.78552 13.9343 2.41242C13.8293 1.93934 13.4075 1.62983 12.9249 1.67176C12.5444 1.70483 11.7 2.53995 10.0113 4.21017L6.14281 8.03638C5.10979 9.0581 4.59328 9.56896 4.67518 10.1147C4.75708 10.6605 5.38834 10.9144 6.65087 11.4222L7.9043 11.9264C8.3702 12.1138 8.60315 12.2075 8.74526 12.363C8.92594 12.5608 9.01564 12.8304 8.994 13.1107C8.97697 13.3311 8.85456 13.5696 8.60975 14.0468C7.52524 16.1603 6.98298 17.2171 7.06584 17.5902C7.1709 18.0633 7.59271 18.3728 8.07524 18.3308C8.45578 18.2978 9.30013 17.4627 10.9888 15.7924L14.8574 11.9662C15.8904 10.9445 16.4069 10.4336 16.325 9.88787C16.2431 9.34208 15.6118 9.08817 14.3493 8.58036L13.0959 8.0762C12.63 7.88881 12.397 7.79511 12.2549 7.63956C12.0742 7.44179 11.9845 7.17218 12.0062 6.89193C12.0232 6.67151 12.1456 6.43296 12.3904 5.95585Z" fill="url(#paint0_linear_3911_65835)"/>
      <defs>
      <linearGradient id="paint0_linear_3911_65835" x1="10.5001" y1="-11.4154" x2="-5.76821" y2="-4.22558" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1E9DAE"/>
      <stop offset="1" stop-color="#D478FF"/>
      </linearGradient>
      </defs>
    </svg>
  );
};

export default AutomateWorkflowSvg;