import React from 'react';
import WarningSvg from '../components/common/Svg/WarningSvg';
import { MAIN_MENU_CODES } from '../components/SideMenuBar/SideBarConst';
import { IGlobalWarnings } from './interfaces';
import { EmbeddedLinks } from '../components/common/MainTopbarView/EmbeddedLinks';
import { Pressable, Text, View } from 'react-native';
import Close from '../assets/svg/Close';
import Stack from '../components/common/LayoutComponents/Stack';
import { IToastProps } from 'native-base';
import { Colors } from '../styles';
import { notification } from 'antd';
import { Toast } from '../components/Toast/ToastProvider';
interface IToastViewProps {
  show: (props: IToastProps) => any;
  close: (id: any) => void;
  closeAll: () => void;
  isActive: (id: any) => boolean;
}

export enum ToastType {
  success,
  error,
  info,
}

export const getBackgroundColor = (toastType: ToastType): string => {
  switch (toastType) {
    case ToastType.success:
      return Colors.Custom.SuccessColor;
    case ToastType.error:
      return Colors.Custom.MissedCallIconColor;
    case ToastType.info:
      return Colors.Custom.infoColor;
  }
};

export const showToast = (
  toastFn: IToastViewProps | Toast,
  message: string,
  toastType: ToastType,
  duration?: number,
  useCustomToast = false
) => {
  // if useCustomToast is true then the 'toastFn' must be a function otherwise code will jump to else block treating toastFn as a native-base toast object.
  if (useCustomToast && typeof toastFn === 'function') {
    const toast = toastFn as Toast;
    toast({
      toastType: toastType,
      message: message,
      duration: duration,
      closeAllPrevToast: true,
    })
  } else {
    const toast = toastFn as IToastViewProps;
    toast.closeAll()
    toast.show({
      duration: duration || 2000,
      render: (toastInfo: any) => {
        const closeToast = () => {
          toast.close(toastInfo.id);
        }
        return (
          <Stack
            direction="row"
            style={{
              alignItems: 'center',
              borderRadius: 8,
              backgroundColor: getBackgroundColor(toastType),
              paddingHorizontal: 12,
              paddingVertical: 4
            }}
            >
            <Text style={{ flexShrink: 1, color: 'white', fontSize: 12, fontWeight: '400' }}>
              {message}
            </Text>
            <Pressable onPress={closeToast} style={{width: 18, marginTop: 2}}><Close size={18} /></Pressable>
          </Stack>
        );
      },
      placement: 'top',
    });
  }
};


export const showPhiWarningToast = (
  message: string,
  backgroundColor?: string,
  textColor?: string,
  fontSize?: number
) => {
  return (
    <View
      style={{
        backgroundColor: backgroundColor || 'white',
        borderRadius: 8,
      }}
    >
      <Stack direction="row" space={2} style={{ alignItems: 'flex-start'}}>
        <WarningSvg />
        <Text style={{ fontSize: fontSize || 12, color: textColor || '#ea580c' }}>
          {message}
        </Text>
      </Stack>
    </View>
  );
};

export const GLOBAL_WARNINGS = [
  {
    menuCode: MAIN_MENU_CODES.INBOX,
    warningMessage: (
      <EmbeddedLinks
        textStyle={{
          color: '#000000',
          fontSize: 16,
        }}
        textSnippets={[
          {
            text: 'All businesses must register for A2P 10DLC registration as per recent US telecom rules and regulations. Failing to do so can risk having calls and text messages blocked or marked as spam. Starting July 5, 2023, all unregistered businesses will be blocked from sending outbound SMS/text messages to US numbers. Please register your brand ',
          },
          {
            text: 'here',
            destination: 'https://forms.office.com/r/8JZ02YNEFG',
          },
        ]}
      />
    ),
    isShow: false,
  },
  {menuCode: MAIN_MENU_CODES.CALENDAR, warningMessage: '', isShow: false},
] as IGlobalWarnings[];


export const showPopupNotification = (title: string, message: string, type: string, width?: number) => {
  type === 'success' ?
  notification['success']({
    message: title,
    description: message,
    style: {
      width: width || 300,
      borderRadius: 8
    },
  }):
  notification['error']({
    message: title,
    description: message,
    style: {
      width: width || 300,
      borderRadius: 8
    },
  });
};

export enum CATEGORY_CODE {
  PATIENT_FORM = 'PAT_FORM'
}
