import {useContext} from 'react';
import { IAvailableEhr } from '../components/PersonOmniView/MiddleContainer/interfaces';
import { OrderType } from '../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import { getOrderType } from '../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { EHRName } from '../components/PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {HEALTH_COMPONENT_CAPABILITY_MAP} from '../components/RightSideContainer/Forms/FHFormio/Builder/SupportedComponents';
import {IFormComponentConfig} from '../components/RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { EHR, FHIR_RESOURCE } from '../constants/FhirConstant';
import {CommonDataContext} from '../context/CommonDataContext';
import {
  IAbility,
  IEhrCapability,
  IVitalData,
  PossibleValue,
} from '../Interfaces';
import { Vital, getEnabledVitals } from './VitalUtils';

export const EHR_CAPABILITY_CONST = {
  DEFAULT_GENDER_IDENTITY_LABEL: 'Gender',
  DEFAULT_BIRTH_SEX_LABEL: 'Sex at birth',
};

export enum InputType {
  choice = 'choice',
  openChoice = 'open-choice',
  freeText = 'freetext',
}

export const getCapability = (resourceName: string): IEhrCapability => {
  const commonData = useContext(CommonDataContext);

  for (const capability of commonData.ehrCapabilities || []) {
    if (capability.resourceName == resourceName) {
      return capability;
    }
  }
  return {};
};

export const getResourceAbilities = (resourceName: string): IAbility => {
  return getCapability(resourceName)?.abilities || {};
};

export const canCreate = (ability: any): boolean => {
  return (ability && ability.isEnabled && ability.canCreate) || false;
};

export const isRequiredArgument = (abilities: IAbility, keyName: string) => {
  const ability = abilities?.keyAllowedOperations?.[keyName];
  return (ability && ability.isRequired) || false;
};

export const isKeyEnabled = (abilities: IAbility, keyName: string): boolean => {
  const ability = abilities?.keyAllowedOperations?.[keyName];
  return (ability && ability.isEnabled) || false;
};

export const getMrnNumber = (resource: any) => {
  if (resource && resource.resourceType === FHIR_RESOURCE.PATIENT) {
    for (const identifier of resource.identifier || []) {
      const type = identifier?.type?.coding?.[0]?.code;
      if (type === 'MR') {
        return identifier.value;
      }
    }
  }
  return '';
};

export const getPossibleValuesByOperationName = (
  operationName: string
): PossibleValue[] => {
  const keyOperations = getResourceAbilities(
    FHIR_RESOURCE.PATIENT
  ).keyAllowedOperations;
  if (keyOperations) {
    return keyOperations[operationName]?.possibleValues || [];
  }
  return [];
};

export const getSexAtBirthTransformValues = (
  operationName: string
): PossibleValue[] => {
  const keyOperations = getResourceAbilities(
    FHIR_RESOURCE.PATIENT
  ).keyAllowedOperations;
  if (keyOperations) {
    return keyOperations[operationName]?.transformValues || [];
  }
  return [];
};

export const getEHRUrl = (): string => {
  const resourceAbilities = getResourceAbilities(FHIR_RESOURCE.PATIENT);
  return resourceAbilities.openEhrUrl || '';
};

export const getPatientEHRUrl = (patientId: string): string => {
  const stringToReplace = '#PATIENT_ID#';
  const ehrUrl = getEHRUrl();
  return (patientId && ehrUrl.replace(stringToReplace, patientId)) || '';
};

export const getHintUrl = (): string => {
  const resourceAbilities = getResourceAbilities(FHIR_RESOURCE.HINT);
  return resourceAbilities?.url || '';
};

export const getOpenInHintUrl = (patientId: string) => {
  const hintUrl = getHintUrl();
  const stringToReplace = '#HINT_PATIENT_ID#';
  return (patientId && hintUrl.replace(stringToReplace, patientId)) || '';
};

export const getEHRName = () => {
  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = commonData.ehrCapabilities || [];
  if (ehrCapabilities?.length === 0) {
    return '';
  }
  return ehrCapabilities[0]?.ehrName || '';
};

export const isFoldEhr = (ehrName: string | undefined) => {
  return  ehrName && ehrName === EHR.FOLD || ehrName === EHR.FHIR_STORE;
};

export const getLabelForKey = (resourceName: string, key: string) => {
  return getResourceAbilities(resourceName)?.keyAllowedOperations?.[key]?.label;
};

export const getGenderIdentityLabel = () => {
  return (
    getLabelForKey(FHIR_RESOURCE.PATIENT, 'gender') ||
    EHR_CAPABILITY_CONST.DEFAULT_GENDER_IDENTITY_LABEL
  );
};

export const getBirthSexLabel = () => {
  return (
    getLabelForKey(FHIR_RESOURCE.PATIENT, 'birthSex') ||
    EHR_CAPABILITY_CONST.DEFAULT_BIRTH_SEX_LABEL
  );
};

export const getSexAtBirth = (patientDetail: any) => {
  if (patientDetail?.extension?.length) {
    const birthSex = patientDetail.extension.find((extension: any) => {
      return extension?.url?.includes('us-core-birthsex');
    });
    return birthSex?.valueCode || birthSex?.valueString || '';
  }
  return '';
};

export const isBirthSexPrimary = () => {
  return (
    getResourceAbilities(FHIR_RESOURCE.PATIENT)?.keyAllowedOperations?.birthSex
      ?.isPrimary || false
  );
};

export const getPrimaryGenderLabel = () => {
  const patientCapability = getResourceAbilities(FHIR_RESOURCE.PATIENT);
  const isGenderIdentityHidden =
    patientCapability?.keyAllowedOperations?.gender?.isHidden || false;
  return isGenderIdentityHidden || isBirthSexPrimary()
    ? getBirthSexLabel()
    : getGenderIdentityLabel();
};

export const getVitalListFromCapability = (): IVitalData[] => {
  return (
    getResourceAbilities(FHIR_RESOURCE.OBSERVATION)?.allowedVitalList || []
  );
};

export const getEnabledVitalsFromCapability = (): Vital[] => {
  const capabilities = getCapability(FHIR_RESOURCE.OBSERVATION)
  return getEnabledVitals(
    capabilities,
    undefined
  )
};


export const getEnabledFormComponents = (
  components: IFormComponentConfig[],
  ehrCapabilities: IEhrCapability[]
) => {
  return components.filter((component) => {
    const capabilityKey = HEALTH_COMPONENT_CAPABILITY_MAP[component.key];
    const capabilityData = ehrCapabilities.find(
      (capability) => capability.resourceName === capabilityKey
    );
    return capabilityData?.isEnabled;
  });
};

export const getEHRResourceAbilities = (resourceName: string, ehrCapabilities: IEhrCapability[]): IAbility | undefined => {
  return ehrCapabilities.find(
    (capability) => capability.resourceName === resourceName
  )?.abilities;
};

export const getEHRResourceCapability = (resourceName: string, ehrCapabilities: IEhrCapability[]): IEhrCapability | undefined => {
  return ehrCapabilities.find(
    (capability) => capability.resourceName === resourceName
  );
};

export const getVitalConfigFromCapability = (loinc: string): IVitalData | undefined => {
  const list = getVitalListFromCapability();
  return list.find((data) => data.loinc === loinc);
};

export const isOrderTypeEnabled = (type: OrderType) => {
  const resource = type === OrderType.LAB || type === OrderType.RAD
      ? FHIR_RESOURCE.SERVICE_REQUEST
      : FHIR_RESOURCE.MEDICATION_REQUEST;
  const abilities = getResourceAbilities(resource);
  return abilities?.isEnabled || false;
}

export const isOrderTypeEnabledWithCapability = (type: OrderType, ehrCapabilities: IEhrCapability[]) => {
  const resource = type === OrderType.LAB || type === OrderType.RAD
      ? FHIR_RESOURCE.SERVICE_REQUEST
      : FHIR_RESOURCE.MEDICATION_REQUEST;
  const abilities = ehrCapabilities.find((capability: any) => capability.resourceName === resource)?.abilities;
  return abilities?.isEnabled || false;
}

export const isOrderingEnabled = () => {
  let isEnabled = false;
  [FHIR_RESOURCE.SERVICE_REQUEST, FHIR_RESOURCE.MEDICATION_REQUEST].forEach((resourceName) => {
    const abilities = getResourceAbilities(resourceName);
    isEnabled = isEnabled || (abilities?.isEnabled || false);
  });
  return isEnabled;
}

export const isNewHGFlowEnabled = () => {
  return true;
}

export const getEhrConfig = (): IAvailableEhr => {
  const currentEHR = getEHRName();
  return {
    isAthena: currentEHR === EHRName.ATHENA,
    isElation: currentEHR === EHRName.ELATION,
    isFold: currentEHR === EHRName.FOLD,
    isCanvas: currentEHR === EHRName.CANVAS,
  };
};

export const isDiagnosticReportEnabled = (type: OrderType) => {
  const abilities = getResourceAbilities(FHIR_RESOURCE.DIAGNOSTIC_REPORT);
  if (abilities.enabledTypes?.length) {
    const key = type == OrderType.LAB ? 'LAB' : (type == OrderType.RAD ? 'IMAGING' : undefined);
    return abilities.isEnabled && key && abilities.enabledTypes?.includes(key);
  }
  return abilities.isEnabled;
}

export const isMarkAsReviewEnabled = () => {
  const abilities = getResourceAbilities(FHIR_RESOURCE.DIAGNOSTIC_REPORT);
  return abilities.canReviewResult;
}

export const getOrgAbilites = (resourceName:string) => {
  const resource = getResourceAbilities(
    FHIR_RESOURCE.ORGANIZATION
  )?.capabilities;
  const patientPatientOrgAbilities = resource?.filter(
    (res) => res.resourceName === resourceName
  )?.[0]?.abilities?.allowedOperations;
  return patientPatientOrgAbilities;
}

export const FOLD_MEMBERSHIP_STATUS_LIST = [
  { display: 'Active', code: 'active' },
  { display: 'Inactive', code: 'inactive' }
]

export const getFieldCapabilities = (capabilities: any) => {
  return capabilities?.abilities?.keyAllowedOperations;
};