import { Drawer} from 'antd';
import {FormControl, HStack, Text, useMediaQuery, useToast, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../constants';
import {ModalActionTitle} from '../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../styles/Colors';
import {styles} from './HolidaysStyles';
import {AddOrUpdateHolidaysInterface, IHolidayInput} from './Interfaces';
import {ModalActionInput} from '../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionDatePicker} from '../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionAntSelect} from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {useLazyQuery} from '@apollo/client';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import {useIntl} from 'react-intl';
import {getHolidayLocationValueByKey, validateHolidayform} from './HolidayUtils';
import {
  getDateObjectFromAPIFormatToMyFormat,
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
  getMomentObjectWithDateStringAndFormatInUTC,
  isDayAndDateInPast,
  isSameDate,
} from '../../../utils/DateUtils';
import { addOrUpdateHoliday } from './HolidayApiService';
import { getAccountUUID } from '../../../utils/commonUtils';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import moment from 'moment';
import {HolidayActions} from './HolidayConst';

const AddOrUpdateHolidays = (props: AddOrUpdateHolidaysInterface) => {
  const {isOpen, onClose, selectedItem, isEdit} = props;
  const [stateData, setStateData] = useState({
    loading: false,
    practiceLocations: [] as any[],
    holidayname: selectedItem?.name || '',
    fromDate: selectedItem?.startDate ? selectedItem?.startDate : '' as any,
    toDate: selectedItem?.endDate ? selectedItem?.endDate : '' as any,
    selectedLocationUUid: getHolidayLocationValueByKey('locationId', selectedItem?.holidayLocations || []) ||  [] as string[], //selectedItem?.locationUUid ||
    initialSelectedLocationUUid: getHolidayLocationValueByKey('locationId', selectedItem?.holidayLocations || []),
    autoReplyMsg: selectedItem?.autoReplyMessage || '',
    errors: {} as any,
  });
  const intl = useIntl();
  const toast = useToast();
  const accountUUID = getAccountUUID();
  const [accountPracticeLocationsQuery] = useLazyQuery<any>(
    UserPracticeLocationQueries.GetAccountPracticeLocationsByTenantId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const handleSubmit = async () => {
    const {isValid, errors} = validateHolidayform(stateData);

    setStateData((prev) => {
      return {
        ...prev,
        errors: errors,
        fromDate: errors.fromDate ? '' : stateData.fromDate,
        toDate: errors.toDate ? '' : stateData.toDate
      };
    });
    if (isValid) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      let addedLocationUuid: any = [];
      const removeLocationUuid: any = [];
      stateData.selectedLocationUUid.forEach((item: string) => {
        if (!stateData.initialSelectedLocationUUid.includes(item)) {
          addedLocationUuid.push(item)
        }
      })
      stateData.initialSelectedLocationUUid.forEach((item: string)=> {
        if (!stateData.selectedLocationUUid.includes(item)) {
          removeLocationUuid.push(item)
        }
      })
      if (props.actionCode === HolidayActions.COPY_HOLIDAY) {
        addedLocationUuid = stateData.selectedLocationUUid
      }
      const payloadData = {
        holidays: {
          id: isEdit ? selectedItem?.id : null,
          name: stateData.holidayname,
          startDate: moment
            .utc(stateData.fromDate)
            .startOf('day')
            .format(DATE_FORMATS.UTC_DATE_FORMAT),
          endDate: moment
            .utc(stateData.toDate)
            .endOf('day')
            .format(DATE_FORMATS.UTC_DATE_FORMAT),
          accountUuid: accountUUID,
          holidayLocations: addedLocationUuid,
          removeLocations: removeLocationUuid,
          autoReplyMessage: stateData.autoReplyMsg,
        },
        isUpdateForAllLocation: false,
      } as IHolidayInput;
      const addOrUpdateRes: any = await addOrUpdateHoliday(
        payloadData
      ).catch((err: any) => {
        showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (addOrUpdateRes?.data) {
        showToast(
          toast,
          intl.formatMessage({
            id: `${isEdit ? 'holidayUpdatedMessage' : 'holidayCreatedMessage'}`,
          }),
          ToastType.success
        );
        onClose(true);
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    }
  };

  useEffect(() => {
    getAccountLocations();
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60vw' : '38vw';

  const getAccountLocations = async () => {
    const accountLocationsRes = await accountPracticeLocationsQuery({
      variables: {
        tenantId: accountUUID
      }
    });
    if (accountLocationsRes?.data?.accountLocations?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          practiceLocations: accountLocationsRes.data?.accountLocations || [],
        };
      });
    }
  };

  const filterLocationObject = (locationData: any) => {
    const newData = (locationData || []).map((location: any) => {
      return {
        ...location?.practiceLocation,
        uuid: location.uuid
      }
    });
    return newData;
  };
  return (
    <Drawer
      visible={isOpen}
      onClose={() => {
        onClose();
      }}
      width={drawerWidth}
      title={
        <>
          <ModalActionTitle
            title={selectedItem?.id ? 'updateHoliday' : 'addHoliday'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: selectedItem?.id ? 'update' : 'create',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: stateData.loading,
                // isDisabled: !isValid ,
                onClick: () => {
                  handleSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack style={[styles.container]}>
        <VStack style={[styles.titleContainer]}>
          <Text
            fontStyle={'normal'}
            fontSize={'20px'}
            fontWeight={400}
            color={Colors.Custom.Gray900}
          >
            Holiday Details
          </Text>
          <View
            mt={2}
            width={'100%'}
            background={Colors.Custom.Gray200}
            height={'1px'}
          />
        </VStack>
        <VStack mt={4}>
          <ModalActionInput
            fieldIs={'input'}
            label={'Holiday Name'}
            placeholder="Eg: Memorial Day"
            leftMargin={'0'}
            isRequired={true}
            errors={stateData.errors?.holidayname}
            errorText={stateData.errors?.holidayname}
            value={stateData.holidayname}
            onChangeText={(text: any) => {
              setStateData((prev) => ({
                ...prev,
                holidayname: text,
                errors: {}
              }));
            }}
            extraStyle={{
              flex: 1,
              placeHolderFontWeight: 300,
              placeHolderFontsize: 13,
            }}
          />
          <View>
          <HStack flex={1} mt={3}>
            <View flex={0.5} mr={1}>
              <ModalActionDatePicker
                disabledDate={(current: any) => {
                  if (
                    isSameDate(moment(current).startOf('day').format(), moment().startOf('day').format()) ||
                    isSameDate(moment(current).startOf('day').format(), moment(stateData.toDate).startOf('day').format())
                    ) {
                    return false
                  } else if ((current.isBefore(moment()) && !isSameDate(moment(current).format(), moment().format())) || ( stateData.toDate && (!current.isBefore(moment(stateData.toDate))))) {
                    return true
                  } else {
                    return false
                  }
                }}
                isRequired={true}
                label={'fromDate'}
                disablePicker={!!props.selectedItem?.id && isDayAndDateInPast(stateData.fromDate)}
                format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
                isInvalid={stateData.errors?.fromDate?.length ? true : false}
                errors={stateData.errors?.fromDate?.length ? true : false}
                errorsText={stateData.errors?.fromDate}
                onChange={(data: any, dateString) => {
                  const formattedString = getDateObjectFromAPIFormatToMyFormat(dateString,DATE_FORMATS.CONVERSATION_DATE_PICKER) || ''
                  setStateData((prev) => ({
                    ...prev,
                    fromDate: formattedString ,
                    errors: {}
                  }));
                }}
                value={
                  getMomentObj(stateData.fromDate)?.isValid()
                    ? moment.utc(stateData.fromDate)
                    : undefined
                }
                customStyle={{flex: 1}}
              />
            </View>

            <View flex={0.5} mr={1}>
              <ModalActionDatePicker
                disabledDate={(current: any) => {
                  if (
                     isSameDate(moment(current).startOf('day').format(), moment().startOf('day').format()) ||
                    isSameDate(moment(current).startOf('day').format(), moment(stateData.fromDate).startOf('day').format())
                    ) {
                    return false
                  } else if (current.isBefore(moment()) || (stateData.fromDate && !current.isAfter(moment(stateData.fromDate)))) {
                    return true
                  } else {
                    return false
                  }
                }}
                isRequired={true}
                label={'toDate'}
                isInvalid={stateData.errors?.toDate?.length ? true : false}
                errors={stateData.errors?.toDate?.length ? true : false}
                errorsText={stateData.errors?.toDate}
                format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
                onChange={(data: any, dateString) => {
                  const formattedString = getDateObjectFromAPIFormatToMyFormat(dateString,DATE_FORMATS.CONVERSATION_DATE_PICKER) || ''
                  setStateData((prev) => ({
                    ...prev,
                    toDate: formattedString,
                    errors: {}
                  }));
                }}
                value={
                   getMomentObj(stateData.toDate)?.isValid()
                    ? moment.utc(stateData.toDate)
                    : undefined
                }
                customStyle={{flex: 1}}
              />
            </View>
          </HStack>
            <FormControl
              isInvalid={stateData.errors.isDateRangeIssue}
            >
              {stateData?.errors?.isDateRangeIssue && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {stateData?.errors?.isDateRangeIssue}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </View>

          <ModalActionAntSelect
            //value={userFormData.locationUUid}
            filterOption={(input: string, option: any) => {
              return (option?.children || '').toLowerCase().includes(input.toLowerCase());
            }}
            mode={'multiple'}
            allowClear={true}
            isRequired={false}
            label={'location'}
            onClear={()=> {
              setStateData((prev)=> {
                return {
                  ...prev,
                  selectedLocationUUid: []
                }
              })
            }}
            placeholder="Please select location"
            value={stateData.selectedLocationUUid}
            onChange={(value: any) => {
              setStateData((prev) => ({
                ...prev,
                selectedLocationUUid: value,
              }));
            }}
            data={filterLocationObject(stateData?.practiceLocations)}
            optionProps={{key: 'uuid', value: 'uuid', label: 'name'}}
            extraStyle={{flex: 1}}
            marginTop={2}
          />

          <VStack marginTop={3}>
            <ModalActionInput
              fieldIs={'textArea'}
              label={intl.formatMessage({id: 'setAutoMessageReply'})}
              placeholder={intl.formatMessage({id: 'enterTextHere'})}
              leftMargin={'0'}
              isRequired={false}
              subTitle={'autoReplySubtitle'}
              //isInvalid={value.showErrors && value.title.trim().length === 0}
              value={stateData.autoReplyMsg}
              onChangeText={(text: any) => {
                setStateData((prev) => ({
                  ...prev,
                  autoReplyMsg: text,
                }));
              }}
              extraStyle={{
                flex: 1,
                Height: 100,
                placeHolderFontWeight: 300,
                placeHolderFontsize: 13,
              }}
            />
          </VStack>
        </VStack>
      </VStack>
    </Drawer>
  );
};

export default AddOrUpdateHolidays;
