import React from 'react';
import Draggable from 'react-draggable';
import {Colors} from '../../../styles';
import './PIP.module.css';
import { IPipProps } from './interface';

const PIP = ({children, boxPosition}: IPipProps) => {
  if(!boxPosition) {
    boxPosition = {left: '15%', top: 40}
  }
  return (
    <div>
      <Draggable>
        <div
          style={{
            position: 'fixed',
            backgroundColor: '#fff',
            zIndex: 100,
            boxSizing: 'content-box',
            boxShadow:
              '1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%)',
            borderRadius: 16,
            left: boxPosition?.left || 'auto',
            top: boxPosition?.top || 'auto',
            right: boxPosition?.right || 'auto',
            bottom: boxPosition?.bottom || 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: Colors.Custom.Gray100,
          }}
        >
          {children}
        </div>
      </Draggable>
    </div>
  );
};

export default PIP;
