import {debounce} from 'lodash';
import {Icon, Input, View} from 'native-base';
import React, {createRef, useContext, useEffect, useRef, useState} from 'react';
import {Platform, Pressable, TouchableOpacity} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import {testID, TestIdentifiers} from '../../../testUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ISearchBarProps} from './interface';
import {styles, stylesObj} from './SearchBarStyles';
import { InputStyle } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import { SMS_INBOX_ACTION_CODES } from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import CreateNewSmsSvg from '../Svg/CreateNewSmsSvg';
import { CHANNEL_TYPE_CODE } from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import EmailActionSvg from '../Svg/PersonActionSvgIcons/EmailActionSvg';
import {EmailDrawerCommonV2} from '../EmailDrawerCommonV2';
import { CommonDataContext } from '../../../context/CommonDataContext';
import DialpadSvg from '../Svg/DialpadSvg';
import SearchActionSvg from '../Svg/PersonActionSvgIcons/SearchActionSvg';

export const SearchBar = (props: ISearchBarProps) => {
  const {
    onChange,
    placeholderText,
    height,
    borderRadius,
    borderWidth,
    borderColor,
    minWidth,
    isDisable,
    variant,
    iconColor,
    placeholderTextColor,
    searchText,
    width,
    resetSearchTextCount,
    autoFocusCount,
    conversationTabCode,
  } = props;
  const placeholderStr = placeholderText || 'Search';
  const searchBarRef: any = createRef();

  const searchBarInputStyles = props.searchBarInputStyles || {};
  const [searchInputText, setSearchInput] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const onChangeText = (text: string)=> {
    onChange(text)
    setSearchInput(text)
  }
  let debouncedSearch = React.useRef(
    debounce(onChangeText, 500)
  ).current;
  const [state, setState] = React.useState({
    isEmailDrawerVisible: false,
  });

  const searchInputStyleFinal: any = {
    ...stylesObj.searchInputStyle,
    ...searchBarInputStyles,
  };

  let finalPlaceholderTextColor = InputStyle.placeholderTextColor;
  if (placeholderTextColor) {
    finalPlaceholderTextColor = placeholderTextColor;
  }
  let finalIconColor = Colors.Custom.Gray600;
  if (iconColor) {
    finalIconColor = iconColor;
  }

  let searchBarBorderRadius = isWeb() ? 10 : 50;
  if (borderRadius || borderRadius === 0) {
    searchBarBorderRadius = borderRadius;
  }
  let searchBarBorderColor = Colors.Custom.CardBorderColor;
  if (borderColor) {
    searchBarBorderColor = borderColor;
  }
  let searchBarBorderWidth = 1;
  if (borderWidth || borderWidth === 0) {
    searchBarBorderWidth = borderWidth;
  }


  useEffect(() => {
    if (searchBarRef?.current) {
      searchBarRef?.current?.focus?.()
      searchBarRef.current.value = searchText || '';
    }
    return () => {
      searchBarRef.current = null;
      debouncedSearch?.cancel?.();
      if (debouncedSearch) {
        debouncedSearch = null as any;
      }
    }
  }, []);

  useEffect(() => {
    if (searchBarRef?.current) {
      searchBarRef?.current?.focus?.();
      searchBarRef.current.value = searchText || '';
    }
  }, [conversationTabCode]);



  const onNewSms = () => {
    setIsDrawerVisible(true)
  }

  const handleEmailDrawerVisible = (isOpen: boolean) => {
    setState(prev => {
      return {
        ...prev,
        isEmailDrawerVisible: isOpen
      }
    })
  }

  return (
    <View
      style={[
        {
          backgroundColor: searchInputStyleFinal?.backgroundColor
            ? searchInputStyleFinal?.backgroundColor
            : '#FFFFFF',
        },
        styles.searchBarContainer,
        {
          //minHeight: 44,
          width: width,
          borderRadius: isWeb() ? 0 : searchBarBorderRadius,
          borderWidth: isWeb() ? 0 : searchBarBorderWidth,
          borderColor: searchBarBorderColor,
        },
      ]}
      marginTop={
        searchInputStyleFinal?.marginTop ? searchInputStyleFinal?.marginTop : 0
      }
      marginBottom={
        searchInputStyleFinal?.marginBottom
          ? searchInputStyleFinal?.marginBottom
          : 0
      }
      marginLeft={
        searchInputStyleFinal?.marginLeft
          ? searchInputStyleFinal?.marginLeft
          : 0
      }
      marginRight={
        searchInputStyleFinal?.marginRight
          ? searchInputStyleFinal?.marginRight
          : 0
      }
    >
      <Input
        ref={searchBarRef}
        height={isSideCarContext ? 8 : 'full'}
        //paddingX={15}
        width={'100%'}
        autoCorrect={false}
        borderWidth={isWeb() ? searchBarBorderWidth : 0}
        borderColor={borderColor}
        minWidth={minWidth}
        onChangeText={debouncedSearch}
        variant={variant || ''}
        fontSize={searchInputStyleFinal.fontSize || 14}
        placeholder={placeholderStr}
        size={isSideCarContext ? 'md' : Platform.OS === 'web' ? 'mdNormal' : 'md'}
        //py={searchBarInputStyles?.paddingVertical || 3}
        placeholderTextColor={finalPlaceholderTextColor || 'gray.300'}
        borderRadius={searchBarBorderRadius}
        autoComplete="off"
        backgroundColor={
          searchInputStyleFinal?.backgroundColor
            ? searchInputStyleFinal?.backgroundColor
            : '#FFFFFF'
        }
        style={[searchInputStyleFinal || {}]}
        _focus={{
          borderColor: borderColor ?? Colors.Custom.Gray200,
        }}
        InputLeftElement={
          searchInputStyleFinal?.hideIcon === 'true' ? (
            <View ml={2}/>
          ) : (
            <View ml={2}>
              <SearchActionSvg width={20} height={20} />
            </View>
          )
        }
        InputRightElement={
          searchInputStyleFinal?.hideIcon === 'true' ? (
            <View mr={2}/>
          ) :
          <>
            {conversationTabCode=== CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS &&(
            <Pressable {...testID('CreateNewSms')} style={{marginRight: 10}} onPress={onNewSms}>
              <CreateNewSmsSvg />
            </Pressable>
            )}

            {conversationTabCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL && (
              <Pressable
                style={{
                  marginRight: 10,
                  width: 20,
                  height: 20,
                }}
                onPress={() => {
                  handleEmailDrawerVisible(true);
                }}
                {...testID('CreateNewEmail')}
              >
                <EmailActionSvg customStrokeColor={Colors.Custom.Gray500} />
              </Pressable>
            )}

          {conversationTabCode === CHANNEL_TYPE_CODE.ALL_CALLS && props?.isDialPadVisible && (
            <TouchableOpacity
              onPress={props?.onDialPadClick}
              style={{
                width: 20,
                height: 20}}
              {...testID('DialPadIcon')}
            >
              <DialpadSvg
                customStrokeColor={Colors.Custom.Gray500}
              />
            </TouchableOpacity>
          )}
            {searchInputText?.length ? (
              <Pressable
                onPress={() => {
                  if (searchBarRef?.current) {
                    searchBarRef?.current?.focus?.();
                    searchBarRef.current.value = '';
                  }
                  onChangeText('');
                }}
              >
                <Icon
                  m="2"
                  size={3}
                  color={Colors.Custom.Gray300}
                  as={<AntIcon name="closecircle" />}
                  {...testID('image')}
                />
              </Pressable>
            ) : null}
          </>
        }
        isDisabled={isDisable}
        {...testID(TestIdentifiers.searchBar)}
      />
      {isDrawerVisible && (
        <CreateSmsConversationDrawer
          isDrawerVisible={isDrawerVisible}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            if (actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE) {
              setIsDrawerVisible(false);
            }
          }}
        />
      )}

      {state.isEmailDrawerVisible ? (
        <EmailDrawerCommonV2
          isOpen={state.isEmailDrawerVisible}
          onClose={() => {
            handleEmailDrawerVisible(false);
          }}
          onEmailSent={() => {
            handleEmailDrawerVisible(false);
          }}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default SearchBar;
