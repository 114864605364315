import {Table} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {TableWrapper} from '../../../../common/TableWrapper';
import {ITagsTableData, ITagsTableProps} from '../interfaces';
import {getTagColumns} from './Helper';

const TagsTable = (props: ITagsTableProps) => {
  const {data, onDelete, onEdit, onPagination, pageNo, pageSize} = props;
  const screenHeight = Dimensions.get('window').height;
  const onTagTableActionPerformed = (
    actionCode: string,
    rowRecord: ITagsTableData
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SAVE:
        onEdit(rowRecord);
        break;

      case COMMON_ACTION_CODES.DELETE:
        onDelete(rowRecord);
        break;

      default:
        break;
    }
  };
  return (
    <View flex={1}>
      <TableWrapper
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: props.total,
          onChange(page, pageSize) {
            if (props.onPagination) {
              props.onPagination(page, pageSize);
            }
          },
        }}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getTagColumns(onTagTableActionPerformed)}
          dataSource={data}
          pagination={false}
          rowKey={(record: ITagsTableData) => record.id}
        />
      </TableWrapper>
    </View>
  );
};

export default TagsTable;
