import {Select} from 'antd';
import {Button, FormControl, Input, Modal, Text} from 'native-base';
import {useState} from 'react';
import {ICreateTeamModalProps} from '..';
import {styles} from './TeamMembersModalStyles';

// When the user will click on the + button to create a team. We will open a modal with the following fields.
// 1. Team Name:- required field
// 2. Team Admin:- Search field with single select for now.
// 3. Team Members:- Search field with multi-select.

const {Body, CloseButton, Content, Footer, Header} = Modal;
const {Label} = FormControl;
const {Option} = Select;

const CreateTeamModal = (props: ICreateTeamModalProps) => {
  const {isOpen, onModalClose, onSave, teamMembersList} = props;

  const [teamName, setTeamName] = useState('');
  const [teamAdmin, setTeamAdmin] = useState('');
  const [teamMembers, setTeamMembers] = useState<string[]>([]);

  const selectChildren: JSX.Element[] = [];
  teamMembersList.map((member) =>
    selectChildren.push(<Option key={member.id}>{member.name}</Option>)
  );

  const handleAdminChange = (value: any) => {
    setTeamAdmin(value);
  };

  const handleTeamMemberChange = (value: any) => {
    setTeamMembers(value);
  };

  const handleOnSave = () => {
    onSave({
      id: Math.random(),
      teamName: teamName,
      teamAdmin: teamAdmin,
      teamMembers: teamMembers,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onModalClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <Text style={styles.modalHeaderText}>Create a Team </Text>
        </Header>
        <Body>
          <FormControl style={styles.formControlStyle} isRequired>
            <Label style={styles.labelStyle}>Team Name</Label>
            <Input
            _focus={{borderColor: '#D0D5DD'}}
              isRequired
              style={styles.inputStyle}
              onChangeText={(teamName: string) => {
                setTeamName(teamName);
              }}
            />
          </FormControl>
          <FormControl style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Team Admin</Label>
            <Select
              size="large"
              placeholder="Select an Admin"
              // defaultValue={selectChildren[0]}
              onChange={handleAdminChange}
              style={{overflow: 'hidden'}}
            >
              {selectChildren}
            </Select>
          </FormControl>
          <FormControl style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Team Members</Label>
            {/* <Text>Work in Progress</Text> */}
            <Select
              mode="multiple"
              size="large"
              placeholder="Select Team Members"
              // defaultValue={selectChildren[0]}
              onChange={handleTeamMemberChange}
              style={{overflow: 'hidden'}}
            >
              {selectChildren}
            </Select>
          </FormControl>

          <Footer style={styles.modalFooter}>
            <Button.Group>
              <Button
                onPress={() => {
                  onModalClose();
                  handleOnSave();
                }}
              >
                Create
              </Button>
              <Button colorScheme="dark" onPress={() => onModalClose()}>
                Cancel
              </Button>
            </Button.Group>
          </Footer>
        </Body>
      </Content>
    </Modal>
  );
};

export default CreateTeamModal;
