import { gql } from '@apollo/client';

export const ADD_CARE_JOURNEY = gql`
  mutation AddCareJourney($data: CareJourney_insert_input!) {
    createCareJourney(object: $data) {
      id
    }
  }
`;

export const GET_ALL_CARE_JOURNEYS = gql`
  query GetAllCareJourneys {
    careJourneys(where: {isDeleted: {_eq: false}}) {
      id
      duration
      durationUnitId
      title
    }
  }
`;

export const SEARCH_ALL_CARE_JOURNEYS = gql`
  query GetAllCareJourneys($searchString: String!, $limit: Int) {
    careJourneys(limit: $limit, where: {title: {_ilike: $searchString},isDeleted: {_eq: false}}) {
      id
      duration
      durationUnitId
      title
    }
  }
`;

export const GET_GOALS_BY_CARE_JOURNEY_ID = gql`
query GetPatientCareJourneyGoalByJourneyId($patientCareJourneyId: uuid!) {
    patientCareJourneyGoals(where: {isDeleted: {_eq: false}, patientCareJourneyId: {_eq: $patientCareJourneyId}} ) {
      description
      title
      id
    }
  }
`;

export const GET_CARE_JOURNEY_TITLE = gql`
query GetCareJourneyById($id: uuid!) {
  careJourney(id: $id) {
    id
    title
    description
  }
}
`;

export const GET_CARE_JOURNEY_GOAL_NAME_DESCRIPTION = gql`
  query getWorkflowByMasterId($workflowMasterId: uuid!, $tenantId: uuid!) {
    workflows( where: { workflowMasterId: {_eq: $workflowMasterId} tenantId: {_eq: $tenantId} isDeleted: {_eq: false} }) {
      id
      graph {
        nodes {
          data
          type
        }
      }
    }
  }`;

export const GET_CARE_JOURNEY_BY_ID = gql`
  query GetCareJourneyById($id: uuid!) {
    careJourney(id: $id) {
      id
      businessOutcome
      careJourneyTeam(where: {isDeleted: {_eq: false}}) {
        id
        roleId
      }
      careJourneyTrackingResources {
        id
        isDeleted
        trackingResourceCode
        trackingResourceType
      }
      careJourneyLocations(where: {isDeleted: {_eq: false}}) {
        locationId
        id
      }
      description
      duration
      durationUnitId
      memberOutcome
      productId
      title
      careJourneyRecommendedCareJourneys(where: {isDeleted: {_eq: false}}) {
        id
        recommendedCareJourneyId
        packageId
      }
      startDateTime
      startType {
        code
      }
      startTypeId
      statusId
    }
  }
`;

  export const GET_JOURNEY_COUNT_FOR_NAME = gql`
    query getJourneyCountForName($searchString: String!) {
      aggregateCareJourneys(
        where: {title: {_eq: $searchString}, isDeleted: {_eq: false}}
      ) {
        aggregate {
          count
        }
      }
    }
  `;

export const GET_CARE_JOURNEY_BY_PRODUCT_ID = gql`
  query GetCareJourneyByProductId($id: string!) {
    careJourneys(where: {id: {_eq: $id}}) {
      id
      businessOutcome
      careJourneyTeam(where: {isDeleted: {_eq: false}}) {
        id
        roleId
      }
      description
      duration
      durationUnitId
      memberOutcome
      productId
      title
    }
  }
`;

/* TODO: unused, please remove
export const UPDATE_CARE_JOURNEY = gql`
  mutation UpdateCareJourney(
    $id: uuid!
    $data: CareJourney_set_input!
    $careJourneyTeam: [CareJourneyTeam_insert_input!]!
  ) {
    updateCareJourney(pk_columns: {id: $id}, _set: $data) {
      id
    }
    createCareJourneyTeams(
      objects: $careJourneyTeam
      on_conflict: {
        constraint: care_program_team_pkey
        update_columns: [roleId, isDeleted]
      }
    ) {
      affected_rows
    }
  }
`;
*/

export const SEARCH_ACTIVE_CARE_JOURNEYS = gql`
query GetActiveCareJourneyByTitle($searchString: String!, $limit: Int, $offset: Int, $statusId: uuid, $order_by: [CareJourney_order_by!] = {updatedOn: desc_nulls_last}) {
  careJourneys(order_by: $order_by, limit: $limit, offset: $offset, where: {title: {_ilike: $searchString}, statusId: {_eq: $statusId}, isDeleted: {_eq: false}}) {
    id
    businessOutcome
    careJourneyTeam(where: {isDeleted: {_eq: false}}) {
      id
      roleId
    }
    careJourneyTrackingResources {
      isDeleted
      trackingResourceCode
      trackingResourceType
    }
    description
    duration
    durationUnitId
    memberOutcome
    productId
    title
    statusId
    startDateTime
    careJourneyLocations(where:{isDeleted: {_eq: false}}) {
        locationId
    }
  }
  aggregateCareJourneys(where: {title: {_ilike: $searchString}, isDeleted: {_eq: false}}) {
    aggregate {
      count
    }
  }
}
`

export const GET_CARE_JOURNEYS_BY_TITLE = gql`
  query GetCareJourneyByTitle($searchString: String!, $limit: Int, $offset: Int, $order_by: [CareJourney_order_by!] = {
    updatedOn: desc_nulls_last,
  }) {
    careJourneys(order_by: $order_by, limit: $limit, offset: $offset, where: {title: {_ilike: $searchString}, isDeleted: {_eq: false}}) {
      id
      businessOutcome
      careJourneyTeam(where: {isDeleted: {_eq: false}}) {
        id
        roleId
      }
      description
      duration
      durationUnitId
      memberOutcome
      productId
      title
      startDateTime
      startType {
        value
      }
      statusId
      startDateTime
      careJourneyLocations {
        locationId
      }
    }
    aggregateCareJourneys(where: {title: {_ilike: $searchString}, isDeleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const ASSIGN_PATIENT_CARE_JOURNEY = gql`
  mutation AssignPatientCareJourney($data: AddOrUpdatePatientCareJourneyInput!) {
    addOrUpdatePatientCareJourney(params: $data) {
      id
    }
  }
`;

export const ASSIGN_BULK_PATIENT_CARE_JOURNEY = gql`
  mutation assignBulkPatientCareJourney($params: BulkAssignPatientCareJourneyInput!) {
    bulkAssignPatientCareJourney(params: $params) {
      status
    }
  }
`;
export const GET_CARE_JOURNEY_STATUS_BY_ID = gql`
  query GetCareJourneyById($id: uuid!) {
    careJourney(id: $id) {
      id
      status {
        code
      }
    }
  }
`;
/* TODO: unused, please remove
export const UPDATE_PATIENT_CARE_JOURNEY_BY_ID = gql`
  mutation getPatientLatestCareJourney($id: uuid!, $data: PatientCareJourney_set_input!) {
    updatePatientCareJourney(pk_columns: {id: $id}, _set: $data) {
      id
    }
  }
`;
*/
// export const ASSIGN_PATIENT_CARE_JOURNEY = gql`
//   mutation AssignPatientCareJourney($data: PatientCareJourney_insert_input!) {
//     createPatientCareJourney(object: $data) {
//       id
//     }
//   }
// `;

export const GET_PATIENT_CARE_JOURNEYS = gql`
  query GetPatientCareJourneys($patientId: uuid!, $searchString: String) {
    patientCareJourneys(where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}, careJourney: {title: {_ilike: $searchString}}}, order_by: {startDateTime: desc_nulls_last, createdOn: desc_nulls_last}) {
      id
      careJourneyId
      endDateTime
      statusId
      title
      description
      careJourney {
        title
        memberOutcome
        description
        duration
        durationUnitId
        careJourneyTeam (where: {isDeleted: {_eq: false}}) {
          roleId
        }
      }
      startDateTime
      workflowConfig
      patientCareJourneyTeam (where: {isDeleted: {_eq: false}}) {
        id
        patientCareJourneyId
        userId
        roleId
      }
      memberOutcome
      businessOutcome
      careJourneyVersionId
      workflowId
      pauseDateTime
      stopDateTime
      completedDateTime
      restartDateTime
      duration
      durationUnitId
      goalStatusId
      note,
      statusReasonCode,
      startType {
        value
        code
      }
    }
  }
`;

export const GET_PATIENT_CARE_JOURNEYS_BY_STATUSES = gql`
  query GetPatientCareJourneys($patientId: uuid!, $searchString: String, $statuses: [String!]) {
    patientCareJourneys(where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}, status: {code: {_in: $statuses}}, careJourney: {title: {_ilike: $searchString}}}, order_by: {startDateTime: desc_nulls_last, createdOn: desc_nulls_last}) {
      id
      careJourneyId
      endDateTime
      statusId
      title
      description
      careJourney {
        title
        memberOutcome
        description
        duration
        durationUnitId
        careJourneyTeam (where: {isDeleted: {_eq: false}}) {
          roleId
        }
      }
      startDateTime
      workflowConfig
      patientCareJourneyTeam (where: {isDeleted: {_eq: false}}) {
        id
        patientCareJourneyId
        userId
        roleId
      }
      memberOutcome
      businessOutcome
      careJourneyVersionId
      workflowId
      pauseDateTime
      stopDateTime
      completedDateTime
      restartDateTime
      duration
      durationUnitId
      goalStatusId
      note,
      statusReasonCode,
      startType {
        value
        code
      }
    }
  }
`;

export const GET_PATIENT_CARE_JOURNEYS_WITH_PAGINATION = gql`
  query GetPatientCareJourneys($patientId: uuid!,  $limit: Int, $offset: Int) {
    patientCareJourneys(limit: $limit, offset: $offset, where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}}, order_by: {startDateTime: desc_nulls_last, createdOn: desc_nulls_last}) {
      id
      careJourneyId
      endDateTime
      statusId
      title
      description
      careJourney {
        title
        memberOutcome
        description
        duration
        durationUnitId
        careJourneyTeam (where: {isDeleted: {_eq: false}}) {
          roleId
        }
      }
      startDateTime
      workflowConfig
      patientCareJourneyTeam (where: {isDeleted: {_eq: false}}) {
        id
        patientCareJourneyId
        userId
        roleId
      }
      memberOutcome
      businessOutcome
      careJourneyVersionId
      workflowId
      pauseDateTime
      stopDateTime
      completedDateTime
      restartDateTime
      duration
      durationUnitId
      goalStatusId
      note,
      statusReasonCode,
      startType {
        value
        code
      }
    }
    aggregatePatientCareJourneys(where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CARE_JOURNEY_CONFIGS = gql`
  query GetTasksByAssigneeId($careJourneyId: uuid!, $patientId: uuid!) {
    patientCareJourneyConfigs(
      where: {
        careJourneyId: {_eq: $careJourneyId}
        patientId: {_eq: $patientId}
      }
    ) {
      id
      config
      nodeId
    }
  }
`;

export const ADD_CARE_JOURNEY_CONFIG = gql`
  mutation AddPatientJourneyConfig(
    $data: patient_care_journey_config_insert_input!
  ) {
    createPatientCareJourneyConfig(object: $data) {
      id
    }
  }
`;

/* TODO: unused, please remove
export const UPDATE_CARE_JOURNEY_CONFIG = gql`
  mutation UpdatePatientJourneyConfig($id: uuid!, $config: json!) {
    updatePatientCareJourneyConfig(
      pk_columns: {id: $id}
      _set: {config: $config}
    ) {
      id
    }
  }
`;
*/

export const GET_PATIENT_CARE_JOURNEY_DETAIL_BY_ID = gql`
query GetPatientCareJourneyDetail($id: uuid!) {
  careJourney(id: $id) {
      title
  }
}
`;
// refer query form here

export const GET_JOURNEY_NAME_BY_IDS = gql`
  query GetPatientCareJourneyDetail($journeyIds: [uuid!]) {
    careJourneys(where: {id: {_in: $journeyIds}}) {
      id
      title
    }
  }
`;


export const GET_LATEST_PATIENT_CARE_JOURNEY = gql`
  query getPatientLatestCareJourney($patientId: uuid!) {
    patientCareJourneys(
      limit: 1
      where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}}
      order_by: {createdOn: desc}
    ) {
      createdOn
      title
      careJourney {
        title
      }
      patientCareJourneyTeam(where: {isDeleted: {_eq: false}}) {
        roleId
        userId
      }
    }
  }
`;

export const GET_PATIENT_ALL_CARE_JOURNEY = gql`
  query getPatientLatestCareJourney($patientId: uuid!) {
    patientCareJourneys(
      where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}}
      order_by: {createdOn: desc}
    ) {
      id
      createdOn
      title
      careJourney {
        title
      }
      patientCareJourneyTeam(where: {isDeleted: {_eq: false}}) {
        roleId
        userId
      }
    }
  }
`;

export const GET_NUTRITION = gql`
  query GetTasksByAssigneeId($limit: Int, $offset: Int, $searchString: String) {
    mdmNutritions(limit: $limit, offset: $offset, where: {description: {_ilike: $searchString}}) {
      fdcId
      description
    }
  }
`;

export const GET_PATIENT_CARE_JOURNEY_BY_CARE_JOURNEY_ID = gql`
  query getPatientLatestCareJourney($patientId: uuid!, $careJourneyId: uuid!) {
    patientCareJourneys(
      where: {patientId: {_eq: $patientId}, isDeleted: {_eq: false}, careJourneyId: {_eq: $careJourneyId}}
      order_by: {createdOn: desc}
    ) {
      id
      createdOn
      statusId
      title
      careJourney {
        title
      }
      patientCareJourneyTeam(where: {isDeleted: {_eq: false}}) {
        roleId
        userId
      }
    }
  }
`;

export const GET_PATIENT_CARE_JOURNEY_BY_ID = gql`
  query getPatientCareJourneyById($id: uuid!) {
    patientCareJourney(id: $id) {
      id
      patientId
      careJourneyId
      startDateTime
      endDateTime
      isDeleted
      tenantId
      businessOutcome
      memberOutcome
      statusId
      workflowConfig
      modeId
      careJourneyVersionId
      workflowId
      duration
      durationUnitId
      statusId
      careJourney {
        title
      }
      careJourneyTrackingResources {
        id
        trackingResourceCode
        trackingResourceType
        isDeleted
      }
      patientCareJourneyTeam(where: {isDeleted: {_eq: false}}) {
        id
        roleId
        userId
      }
      patientCareJourneyLocations(where: {isDeleted: {_eq: false}}) {
        locationId
        id
      }
      patientCareJourneyRecommendedCareJourney(where: {isDeleted: {_eq: false}}) {
        id
        packageId
      }
    }
  }
`;

export const GET_PATIENT_CARE_JOURNEY_BY_ID_FOR_SUMMARY_VIEW = gql`
  query getPatientCareJourneyById($id: uuid!) {
    patientCareJourney(id: $id) {
      id
      careJourneyId
      endDateTime
      statusId
      title
      description
      careJourney {
        title
        memberOutcome
        description
        duration
        durationUnitId
        careJourneyTeam (where: {isDeleted: {_eq: false}}) {
          roleId
        }
      }
      startDateTime
      workflowConfig
      patientCareJourneyTeam (where: {isDeleted: {_eq: false}}) {
        id
        patientCareJourneyId
        userId
        roleId
      }
      memberOutcome
      businessOutcome
      careJourneyVersionId
      workflowId
      pauseDateTime
      stopDateTime
      completedDateTime
      restartDateTime
      duration
      durationUnitId
      goalStatusId
      note,
      statusReasonCode,
      startType {
        value
        code
      }
    }
  }
`;

export const UPDATE_PATIENT_CARE_JOURNEY_AND_CARE_TEAM = gql`
  mutation updatePatientCareJourney($data: AddOrUpdatePatientCareJourneyInput!, $patientCareJourneyTeam: [patient_care_journey_team_insert_input!]!) {
    addOrUpdatePatientCareJourney(params: $data) {
      id
    }

    createPatientCareJourneyTeams(
      objects: $patientCareJourneyTeam
      on_conflict: {
        constraint: patient_care_program_team_pkey
        update_columns: [roleId, userId, isDeleted]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_PATIENT_CARE_JOURNEY = gql`
  mutation updatePatientCareJourney($data: AddOrUpdatePatientCareJourneyInput!) {
    addOrUpdatePatientCareJourney(params: $data) {
      id
    }
  }
`;

export const GET_ALL_PATIENT_CARE_JOURNEYS = gql`
  query GetAllPatientCareJourneys($patientIdList: [uuid!]!) {
    patientCareJourneys(
      where: {patientId: {_in: $patientIdList}, isDeleted: {_eq: false}}
    ) {
      id
      patientId
      statusId
      endDateTime
      startDateTime
      duration
      durationUnitId
      title
      description
      careJourney {
        title
        memberOutcome
        description
        duration
        durationUnitId
        careJourneyTeam(where: {isDeleted: {_eq: false}}) {
          roleId
        }
      }
    }
  }
`;

export const DELETE_PATIENT_CARE_JOURNEY = gql`
  mutation deleteCareJourney($id: uuid!, $updatedAt: timestamptz) {
    updateCareJourney(
        pk_columns: {id: $id},
        _set: {isDeleted: true}
      ) {
        id
      }
  }
`;

export const UPDATE_CARE_JOURNEY_STATUS = gql`
mutation updateCareJourneyStatus($id: uuid!, $statusId: uuid!) {
  updateCareJourney(pk_columns: {id: $id}, _set: {statusId: $statusId}) {
    id
  }
}
`;

export const GET_PATIENT_CARE_JOURNEY_GOAL_BY_ID = gql`
  query GetPatientCareJourneyGoalByJourneyId($patientCareJourneyId: uuid!) {
    patientCareJourneyGoals(
      where: {
        isDeleted: { _eq: false }
        patientCareJourneyId: { _eq: $patientCareJourneyId }
      }
    ) {
      description
      title
      id
    }
  }
`;

export const GET_PATIENT_CARE_JOURNEY_DETAIL_BY_JOURNEY_IDS = gql`
query GetPatientCareJourneyDetail($journeyIds: [uuid!]!) {
  patientCareJourneys(where: {id: {_in: $journeyIds}}) {
    id
    startDateTime
    status {
      value
    }
    title
    careJourney {
      title
    }
  }
}
`;

export const GET_DOCUMENT_CATEGORIES_BY_CATEGORY_ID = gql`
  query getDocumentCategoriesByCategoriesId($params: GetDocumentCategoryInput!) {
    getDocumentCategories(params: $params) {
      id
      isDefault
      code
      description
    }
  }
`;

export const GET_CARE_JOURNEY_STATUS_AND_TITLE = gql`
  query GetCareJourneyById($limit: Int!, $offset: Int!, $search: String!, $statusId: uuid) {
    careJourneys(
      limit: $limit
      offset: $offset
      where: {title: {_ilike: $search}, isDeleted: {_eq: false}, statusId: {_eq: $statusId}}
    ) {
      id
      statusId
      title
    }
  }
`;

export const GET_CARE_JOURNEY_BY_IDS_STATUS = gql`
  query GetCareJourneyById($ids: [uuid]!) {
    careJourneys(where: {id: {_in: $ids}, isDeleted: {_eq: false}}) {
      id
      statusId
      title
    }
  }`;

export const CAREJOURNEY_ASSIGNED_TO_MEMBER = gql`
  query getCareJoruneyAssignedCount(
    $careJourneyId: uuid!
    $statusIds: [uuid!]
  ) {
    patientCareJourneys(
      where: {careJourneyId: {_eq: $careJourneyId}, statusId: {_in: $statusIds}}
      limit: 1
    ) {
      id
    }
  }
`;

export const GET_ACTIVE_CARE_JOURNEY_BY_ID = gql`
  query GetActiveCareJourneyById($statusId: uuid!, $id: uuid!) {
    careJourneys(
      where: {
        id: {_eq: $id}
        statusId: {_eq: $statusId}
        isDeleted: {_eq: false}
      }
    ) {
      id
      productId
      title
      startDateTime
      careJourneyLocations(where: {isDeleted: {_eq: false}}) {
        locationId
        isDeleted
      }
    }
  }
`;

export const GET_JOURNEY_ASSIGNED_PATIENTS = gql`
query GetJourneyAssignedPatients($careJourneyId: uuid!, $assignee: [BulkJourneyAssigneeInput!]) {
  validateBulkJourneyAssignee(params: {
    careJourneyId:$careJourneyId,
    assignee: $assignee
  }) {
    journeyAssignee
  }
}
`;


export const SEND_EDUCATION_CONTENT = gql`
  mutation sendEducationContentToContact($params: SendEducationContentToContactInput!) {
    sendEducationContentToContact(params: $params) {
      documentId
      emailResponse
      smsResponse
      taskId
    }
}
`;

export default {
  ADD_CARE_JOURNEY,
  GET_CARE_JOURNEY_BY_ID,
  GET_CARE_JOURNEY_BY_PRODUCT_ID,
  // TODO: unused, please remove UPDATE_CARE_JOURNEY,
  GET_CARE_JOURNEYS_BY_TITLE,
  ASSIGN_PATIENT_CARE_JOURNEY,
  GET_PATIENT_CARE_JOURNEYS,
  GET_CARE_JOURNEY_CONFIGS,
  ADD_CARE_JOURNEY_CONFIG,
  // TODO: unused, please remove UPDATE_CARE_JOURNEY_CONFIG,
  GET_PATIENT_CARE_JOURNEY_DETAIL_BY_ID,
  GET_ALL_CARE_JOURNEYS,
  GET_LATEST_PATIENT_CARE_JOURNEY,
  GET_PATIENT_ALL_CARE_JOURNEY,
  GET_NUTRITION,
  GET_PATIENT_CARE_JOURNEY_BY_CARE_JOURNEY_ID,
  UPDATE_PATIENT_CARE_JOURNEY,
  UPDATE_PATIENT_CARE_JOURNEY_AND_CARE_TEAM,
  GET_ALL_PATIENT_CARE_JOURNEYS,
  GET_PATIENT_CARE_JOURNEY_BY_ID,
  DELETE_PATIENT_CARE_JOURNEY,
  GET_PATIENT_CARE_JOURNEY_GOAL_BY_ID,
  ASSIGN_BULK_PATIENT_CARE_JOURNEY,
  GET_PATIENT_CARE_JOURNEY_DETAIL_BY_JOURNEY_IDS,
  GET_DOCUMENT_CATEGORIES_BY_CATEGORY_ID,
  SEARCH_ALL_CARE_JOURNEYS,
  GET_CARE_JOURNEY_STATUS_AND_TITLE,
  GET_CARE_JOURNEY_BY_IDS_STATUS,
  GET_CARE_JOURNEY_STATUS_BY_ID,
  GET_GOALS_BY_CARE_JOURNEY_ID,
  GET_ACTIVE_CARE_JOURNEY_BY_ID,
  GET_JOURNEY_ASSIGNED_PATIENTS,
  GET_PATIENT_CARE_JOURNEYS_WITH_PAGINATION,
  GET_PATIENT_CARE_JOURNEY_BY_ID_FOR_SUMMARY_VIEW,
  GET_PATIENT_CARE_JOURNEYS_BY_STATUSES,
  SEND_EDUCATION_CONTENT,
};
