import {ITagsTableData} from '../components/RightSideContainer/Contacts';

export const tagsTableData: ITagsTableData[] = [
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Angry',
    description: 'Angry',
    color: '#f70008',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Disappointed',
    description: 'Disappointed',
    color: '#3f1ad6',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Email Sent',
    description: 'Email Sent',
    color: '#b71ed6',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Happy',
    description: 'Happy',
    color: '#0c51ab',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Influencer',
    description: 'Influencer',
    color: '#a18102',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Lose',
    description: 'Lose',
    color: '#b8000f',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Conversation',
    id: Math.random(),
    title: 'Meet personally',
    description: 'Meet personally',
    color: '#0ac71a',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Campaign',
    id: Math.random(),
    title: 'Influencer',
    description: 'Influencer',
    color: '#a18102',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Campaign',
    id: Math.random(),
    title: 'Lose',
    description: 'Lose',
    color: '#b8000f',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Campaign',
    id: Math.random(),
    title: 'Meet personally',
    description: 'Meet personally',
    color: '#0ac71a',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Customer',
    id: Math.random(),
    title: 'Angry',
    description: 'Angry',
    color: '#f70008',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Customer',
    id: Math.random(),
    title: 'Disappointed',
    description: 'Disappointed',
    color: '#3f1ad6',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Customer',
    id: Math.random(),
    title: 'Email Sent',
    description: 'Email Sent',
    color: '#b71ed6',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Customer',
    id: Math.random(),
    title: 'Happy',
    description: 'Happy',
    color: '#0c51ab',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
  {
    category: 'Customer',
    id: Math.random(),
    title: 'Meet personally',
    description: 'Meet personally',
    color: '#0ac71a',
    totalItemsCounts: 0,
    itemCounts: 0,
  },
];
