import {HStack, Pressable, Text, View} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useEffect, useState} from 'react';
import {Colors} from '../../../../../styles';
import {UserData} from '../InviteUserDrawer/interfaces';
import AddAccountRoleView from './AddAccountRoleView';
import {ACCOUNTS_AND_ROLES_ACTION_CONST} from '../../../../../constants';
import {IAccountRole} from './interface';
import {isAddPracticeBtnActive} from './utils';
import {IMlov} from '../../../../../Interfaces';
import {getAssociatedAccounts} from '../../../../../utils/commonUtils';
interface IAssociatedAccountView {
  userData: UserData;
  updateAccountUserRoleList?: (data: IAccountRole[]) => void;
  isDisabled: boolean;
  associatedAccounts?: IAccountRole[];
  userRoles: IMlov[];
  isEditMode?: boolean;
}
const AssociatedAccountView = (props: IAssociatedAccountView) => {
  const {userRoles, updateAccountUserRoleList, isDisabled, associatedAccounts, isEditMode} =
    props;
  const associatedAccountList = getAssociatedAccounts()
  const [count, setCount] = useState(2);
  const [accountUserRoleList, setAccountUserRoleList] = useState([
    {
      id: 1,
      accountUuid: '',
      accountId: 0,
      externalUserId: '',
      rolesToAdd: [] as string[],
      rolesToDelete: [] as string[],
      roles: [] as string[],
      editedRoles: [] as string[],
    },
  ] as IAccountRole[]);

  const addPractice = () => {
    const singlePracticeObj = {
      id: count,
      accountUuid: '',
      accountId: 0,
      externalUserId: '',
      rolesToAdd: [] as string[],
      rolesToDelete: [] as string[],
      roles: [] as string[],
      editedRoles: [] as string[],
    };
    setCount((prv) => prv + 1);
    setAccountUserRoleList([...accountUserRoleList, singlePracticeObj]);
    updateAccountUserRoleList?.([...accountUserRoleList, singlePracticeObj]);
  };

  const removePractice = (id?: number) => {
    const updatedList = accountUserRoleList.filter((item) => item.id !== id);
    setAccountUserRoleList(updatedList);
    updateAccountUserRoleList?.(updatedList);
  };
  const addUpdateFieldValue = (index: number, code: string, value: any) => {
    let selectedUserRoleObj = {...accountUserRoleList[index]};
    switch (code) {
      case ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME:
        selectedUserRoleObj.accountId = value.accountId;
        selectedUserRoleObj.accountUuid = value.accountUuid;
        accountUserRoleList[index] = selectedUserRoleObj;
        setAccountUserRoleList([...accountUserRoleList]);
        updateAccountUserRoleList?.(accountUserRoleList);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER:
        selectedUserRoleObj = {...accountUserRoleList[index]};
        selectedUserRoleObj.externalUserId = value.externalUserId;
        accountUserRoleList[index] = selectedUserRoleObj;
        setAccountUserRoleList([...accountUserRoleList]);
        updateAccountUserRoleList?.(accountUserRoleList);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES:
        selectedUserRoleObj = {...accountUserRoleList[index]};
        selectedUserRoleObj.rolesToAdd = value.rolesToAdd;
        selectedUserRoleObj.editedRoles = value.rolesToAdd;
        accountUserRoleList[index] = selectedUserRoleObj;
        setAccountUserRoleList([...accountUserRoleList]);
        updateAccountUserRoleList?.(accountUserRoleList);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (associatedAccounts?.length) {
      setCount(associatedAccounts?.length + 1);
      setAccountUserRoleList(() => {
        return [...associatedAccounts];
      });
    } else {
      setAccountUserRoleList([
        {
          id: 1,
          accountUuid: '',
          accountId: 0,
          externalUserId: '',
          rolesToAdd: [] as string[],
          rolesToDelete: [] as string[],
          roles: [] as string[],
          editedRoles: [] as string[],
        },
      ]);
      setCount(2);
    }
  }, [associatedAccounts?.length]);
  return (
    <View>
      {accountUserRoleList.map((singleAccountRoleObj: IAccountRole, index) => {
        return (
          <View
            key={`${singleAccountRoleObj.accountUuid}_${index}`}
            style={{position: 'relative'}}
          >
            <Pressable
              onPress={() => {
                removePractice(singleAccountRoleObj?.id);
              }}
              style={{
                position: 'absolute',
                right: 12,
                top: 12,
                zIndex: 99,
                display: !props.isDisabled && accountUserRoleList.length > 1 ? 'flex' : 'none',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: 1.5,
                    backgroundColor: Colors.Custom.Gray200,
                    marginHorizontal: 8,
                    height: 20,
                  }}
                />
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </View>
            </Pressable>
            <AddAccountRoleView
              userRoles={userRoles}
              isEditMode={isEditMode}
              isDisabled={isDisabled}
              accountUserRoleList={accountUserRoleList}
              associatedAccountList={associatedAccountList}
              addUpdateFieldValue={(code: string, data?: any) => {
                addUpdateFieldValue(index, code, data);
              }}
              singleAccountRoleObj={singleAccountRoleObj}
            />
          </View>
        );
      })}
      <Pressable
        onPress={() => {
          addPractice();
        }}
        display={
          isDisabled ||
            accountUserRoleList.length === associatedAccountList.length || !isAddPracticeBtnActive(accountUserRoleList)
            ? 'none'
            : 'flex'
        }
      >
        <HStack
          style={{
            justifyContent: 'flex-start',
          }}
        >
          <AntIcon name="plus" size={15} color={Colors.FoldPixel.PRIMARY300} />
          <Text color={Colors.FoldPixel.PRIMARY300}>{'Add Practice'}</Text>
        </HStack>
      </Pressable>
    </View>
  );
};

export default AssociatedAccountView;
