import {Badge, HStack, Skeleton, Stack, Text, View, VStack} from 'native-base';
import {Pressable} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../../styles';
import {numericStringMask} from '../../../../../../../utils/commonUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {Email} from '../../../../../../common/EmailAndPhone/Email';
import {PhoneNumber} from '../../../../../../common/EmailAndPhone/PhoneNumber';
import MemberInfoListItem from '../../../../../../common/MemberInfoListItem/MemberInfoListItem';
import {TagDataListView} from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import {capitalizeFirstLatter} from '../../../../../ContentManagement/CampaignManagement/Helper';
import {ActionsView} from '../ActionsView';
import {MemberAutomation} from '../MemberAutomation';
import {MemberCampaign} from '../MemberCampaign';
import {MemberCareJourney} from '../MemberCareJourney';
import {MemberGroups} from '../MemberGroups';
import {useIntl} from 'react-intl';
import {IPatientCareJourney} from '../../../interfaces';
import {
  ADDITIONAL_ATTRIBUTE_KEY,
  CONTACT_TABLE_COLUMN_CODE,
  PROGRAM_FILTER_CODE,
  PROGRAM_STEPS_FILTER_CODE,
  PROGRAM_SUB_FILTER_CODE,
} from '../../../../../../common/MemebersView/constant';
import {AttributesView} from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/AttributesView';
import {ICondition} from '../../../../../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {IContactCareProgramResponse, IDashboardColumnProps} from '../../../../../../../services/ContactCareProgram/interface';
import {CareProgramStepWiseView} from '../../../../../../common/MemebersView/CareProgramStepWiseView';
import { getAttributeByKey, getProgramEnrollmentDateAndStatus } from '../../../../../../common/CareProgramPanel/CareProgramUtils';
import { CARE_TEAM_MEMBER_TYPE } from '../../../../../../../constants/MlovConst';
import { MemberHealthPlanView } from '../../../../../../common/MemebersView/MemberHealthPlanView';
import { getDiffInMonths, getMomentObj } from '../../../../../../../utils/DateUtils';
import { MemberAWVStatusView } from '../../../../../../common/MemebersView/MemberAWVStatusView';
import { getDateStrFromFormat } from '../../../../../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../../../../../constants/StringConst';
import PatientListInternalTask from '../../../../../../common/PatientListInternalTask/PatientListInternalTask';


export const MembershipColumn: any = {
  title: (
    <DisplayText
      size={'xsMedium'}
      extraStyles={{color: Colors.primary['700'] || ''}}
      textLocalId="membership"
    />
  ),

  dataIndex: 'subscriptionStatus',
  key: 'subscriptionStatus',
  width: 200,
  render: (text: any): JSX.Element => {
    const bgColor =
      text.status == 'ACTIVE'
        ? Colors.success['100']
        : text.status == 'IN_ACTIVE'
        ? Colors.info['100']
        : Colors.danger['100'];
    const borderColor =
      text.status == 'ACTIVE'
        ? Colors.success['200']
        : text.status == 'IN_ACTIVE'
        ? Colors.info['200']
        : Colors.danger['200'];
    const textColor =
      text.status == 'ACTIVE'
        ? Colors.success['400']
        : text.status == 'IN_ACTIVE'
        ? Colors.info['400']
        : Colors.danger['400'];
    return (
      <>
        {text?.loading ? (
          <Skeleton.Text lines={2}></Skeleton.Text>
        ) : (
          <View
            style={{
              width: 'max-content',
              paddingHorizontal: 8,
              paddingVertical: 3,
              borderRadius: 6,
              backgroundColor: bgColor,
              borderWidth: 1,
              borderColor: borderColor,
            }}>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smMedium'} color={textColor}>
                {text.label}
              </Text>
            )}
          </View>
        )}
      </>
    );
  },
};

export const hintMembershipColumn: any = {
  title: (
    <DisplayText
      size={'xsMedium'}
      extraStyles={{color: Colors.primary['700'] || ''}}
      textLocalId="membershipStatus"
    />
  ),
  dataIndex: 'hintMembershipStatus',
  key: 'hintMembershipStatus',
  width: 240,
  render: (text: any): JSX.Element => {
    return <Text>{capitalizeFirstLatter(text) || '-'}</Text>;
  },
  responsive: ['md'],
};

export const getLeadTableViewColumns = (
  sortedInfo: any,
  onActionPerformed: (actionCode: string, rowData: any) => void,
  contactType?: string,
  canShowMembership?: boolean,
  isHideAction?: boolean,
  hideConvertToPatient?: boolean,
  showHintmembership?: boolean,
  showSelectedText?: boolean,
  selectedCount?: number,
  careJourneyData?: IPatientCareJourney,
  inboxData?: any,
): any[] => {
  const intl = useIntl();

  let headerTitleText =
    contactType == 'CUSTOMER'
      ? intl.formatMessage({id: 'patientName'})
      : 'Prospect name';

  if (showSelectedText && selectedCount) {
    headerTitleText = headerTitleText + ` (${selectedCount} selected)`;
  }

  const primaryColumns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
          textLocalId={headerTitleText}
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 310,
      fixed: 'left',
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'contactData' && sortedInfo.order,
      filterMultiple: false,
      filters: isHideAction
        ? false
        : [
            {
              text: 'Sort by',
            },
            {
              text: 'Name',
              value: 'name',
              children: [
                {
                  text: 'Ascending',
                  value: 'asc_name',
                },
                {
                  text: 'Descending',
                  value: 'desc_name',
                },
              ],
            },
            {
              text: 'Created date',
              value: 'created_at',
              children: [
                {
                  text: 'Ascending',
                  value: 'asc_created',
                },
                {
                  text: 'Descending',
                  value: 'desc_created',
                },
              ],
            },
            {
              text: 'Update date',
              value: 'updated_at',
              children: [
                {
                  text: 'Ascending',
                  value: 'asc_updated',
                },
                {
                  text: 'Descending',
                  value: 'desc_updated',
                },
              ],
            },
          ],
      ellipsis: true,
      render: (text: any, selectedRecord: any): JSX.Element => {
        return (
          <MemberInfoListItem
            onActionPerformed={(actionCode, rowData) => {
              onActionPerformed(actionCode, {
                ...selectedRecord,
              });
            }}
            contactData={text}
            showEmail={false}
            showDateOfBirth={true}
          />
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="contactInfo"
        />
      ),
      dataIndex: 'singleLeadData',
      key: 'mobileNumber',
      width: 250,
      // sorter: (nameOne: any, nameTow: any) => {
      //   return nameOne.name.localeCompare(nameTow.name);
      // },
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      // ellipsis: true,
      render: (singleLeadData: any, selectedRecord: any): JSX.Element => {
        const mask = `(###) ###-####`;
        const maskedPhoneNumber = numericStringMask(
          singleLeadData.phoneNumber,
          mask,
        );

        const email = singleLeadData?.email || '';
        return (
          <VStack>
            <View>
              {singleLeadData.phoneNumber === '' ||
              singleLeadData.phoneNumber === null ? (
                <Text color={'gray.400'}>--</Text>
              ) : (
                <Pressable>
                  <PhoneNumber
                    phoneNumber={maskedPhoneNumber}
                    contactData={singleLeadData}
                    onActionPerformed={actionCode => {
                      if (!selectedRecord.consentLoading) {
                        onActionPerformed(actionCode, {
                          ...selectedRecord,
                          showConsentError: true,
                        });
                      }
                    }}
                    inboxData={inboxData}
                  />
                </Pressable>
              )}
            </View>
            <View>
              {email === '' || email === null ? (
                <Text color={'gray.400'}>--</Text>
              ) : (
                <Pressable>
                  <Email
                    contactData={singleLeadData}
                    onActionPerformed={actionCode => {
                      if (!selectedRecord.consentLoading) {
                        onActionPerformed(actionCode, {
                          ...selectedRecord,
                          showConsentError: true,
                        });
                      }
                    }}
                  />
                </Pressable>
              )}
            </View>
          </VStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="tags"
        />
      ),
      dataIndex: 'tags',
      key: 'tags',
      width: 240,
      render: (text: any): JSX.Element => {
        return (
          <TagDataListView
            taggableType={'Contact'}
            style={{marginBottom: 10}}
            hideAddMoreButton={true}
            tagList={text}
          />
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="attributes"
        />
      ),
      dataIndex: 'customAttributes',
      key: 'customAttributes',
      width: 250,
      render: (attributes: any): JSX.Element => {
        return <AttributesView attributes={attributes} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="groups"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 150,
      render: (contactData: any, selectedRecord: any): JSX.Element => {
        return selectedRecord?.consentLoading ? (
          <Skeleton.Text lines={1} />
        ) : (
          <MemberGroups contactData={contactData} />
        );
      },
      responsive: ['md'],
    },

    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="careJourney"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 150,
      render: (contactData: any): JSX.Element => {
        return (
          <MemberCareJourney contactData={contactData} data={careJourneyData} />
        );
      },
      responsive: ['md'],
      hidden: contactType == 'CUSTOMER' ? false : true,
    },

    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="campaigns"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 150,
      render: (contactData: any): JSX.Element => {
        return <MemberCampaign contactData={contactData} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="automation"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 200,
      render: (contactData: any): JSX.Element => {
        return <MemberAutomation contactData={contactData} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="preferredLocation"
        />
      ),
      dataIndex: 'location',
      key: 'location',
      width: 150,
      render: (text: any): JSX.Element => {
        const result = text?.length > 0 ? text[0].practiceLocation?.name : '--';
        return (
          <View>
            <>
              <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                {result}
              </Text>
            </>
          </View>
        );
      },
      responsive: ['md'],
      hidden: contactType == 'CUSTOMER' ? false : true,
    },
    {
      title: isHideAction ? (
        <></>
      ) : (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || ''}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: 200,
      fixed: 'right',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            {isHideAction ? (
              <></>
            ) : (
              <>
                {selectedRecord?.consentLoading ? (
                  <Skeleton.Text lines={1}></Skeleton.Text>
                ) : (
                  <ActionsView
                    hideConvertToPatient={hideConvertToPatient}
                    record={selectedRecord}
                    onActionClick={(actionCode: string, data: any): any => {
                      // added hardoded true for now :: ABHAY
                      if (!text.consentLoading || true) {
                        if (data === COMMON_ACTION_CODES.CONSENT_WARNING) {
                          onActionPerformed(actionCode, {
                            ...selectedRecord,
                            showConsentError: true,
                          });
                        } else {
                          onActionPerformed(actionCode, selectedRecord);
                        }
                      }
                    }}
                  />
                )}
              </>
            )}
          </>
        );
      },
      responsive: ['md'],
    },
  ].filter(item => !item.hidden);
  if (canShowMembership) {
    primaryColumns.splice(1, 0, MembershipColumn);
  }
  if (showHintmembership) {
    primaryColumns.splice(2, 0, hintMembershipColumn);
  }
  return primaryColumns;
};

const getAttributeByKeyByProgram = (attributeKey: string, contactCareProgram: IContactCareProgramResponse) => {
  let value = getAttributeByKey(attributeKey, contactCareProgram);
  if (value && attributeKey == ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE || attributeKey == ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE) {
    value = getDateStrFromFormat(value, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
  }
  return value;
}

export const renderAdditionalAttributeWithDetail = (attributeKey: string, selectedRecord: any): JSX.Element => {
  if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
    return (<Skeleton.Text lines={1}></Skeleton.Text>);
  }
  if (!selectedRecord?.additionalDataLoading?.careProgramLoading && !selectedRecord?.contactCarePrograms?.length) {
    return (<></>);
  }
  const contactCarePrograms: IContactCareProgramResponse[] = selectedRecord?.contactCarePrograms || [];
  return (
    <>
      <Stack direction={'column'}>
        {(contactCarePrograms || []).map((contactCareProgram, index) => {
          return (
            <Stack direction={'row'} key={`${contactCareProgram?.id}_${index}_${attributeKey}`}>
                <Text
                  wordBreak={'break-word'}
                  color={Colors.Custom.Gray500}
                  size={'smLight'}>
                  {getAttributeByKeyByProgram(attributeKey, contactCareProgram)}
                </Text>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
}

export const renderContactTagsColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="tags"
      />
    ),
    key: 'tags',
    width: 240,
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.tagLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      return (
        <TagDataListView
          taggableType={'Contact'}
          style={{marginBottom: 10}}
          hideAddMoreButton={true}
          tagList={selectedRecord?.tags}
        />
      );
    },
    responsive: ['md'],
  };
};

export const renderInternalTaskColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="Tasks"
      />
    ),
    key: 'patientInternalTask',
    width: 100,
    render: (selectedRecord: any): JSX.Element => (
      <PatientListInternalTask
        contactId={selectedRecord?.contactData?.uuid}
        onTaskClick={() => {
          if (
            columnParams?.onActionPerformed &&
            typeof columnParams.onActionPerformed === 'function'
          ) {
            columnParams?.onActionPerformed(
              COMMON_ACTION_CODES.ATTIONABLE_TASK_VIEW,
              {
                ...selectedRecord,
              }
            );
          }
        }}
      />
    ),
    responsive: ['md'],
  };
}

export const renderMemberNameColumn = (columnParams: IDashboardColumnProps) => {
  let headerTitleText = 'Member Name'
  if (!!columnParams?.selectedItemsCount) {
    headerTitleText =
      headerTitleText + ` (${columnParams?.selectedItemsCount} selected)`;
  }
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
        textLocalId={headerTitleText}
      />
    ),
    dataIndex: 'contactData',
    key: 'contactData',
    fixed: 'left',
    sorter: false,
    width: 310,
    filterMultiple: false,
    ellipsis: true,
    render: (text: any, selectedRecord: any): JSX.Element => {
      return (
        <MemberInfoListItem
          onActionPerformed={(actionCode, rowData) => {
            if (
              columnParams?.onActionPerformed &&
              typeof columnParams.onActionPerformed == 'function'
            ) {
              columnParams.onActionPerformed(actionCode, {
                ...selectedRecord,
              });
            }
          }}
          contactData={text}
          showEmail={false}
          showDateOfBirth={true}
        />
      );
    },
    responsive: ['md'],
  };
};

export const renderContactInfoColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="contactInfo"
      />
    ),
    dataIndex: 'singleLeadData',
    key: 'mobileNumber',
    width: 250,
    render: (singleLeadData: any, selectedRecord: any): JSX.Element => {
      const mask = `(###) ###-####`;
      const maskedPhoneNumber = numericStringMask(
        singleLeadData.phoneNumber,
        mask,
      );
      const email = singleLeadData?.email || '';
      return (
        <VStack>
          <View>
            {singleLeadData.phoneNumber === '' ||
            singleLeadData.phoneNumber === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Pressable>
                <PhoneNumber
                  phoneNumber={maskedPhoneNumber}
                  contactData={singleLeadData}
                  onActionPerformed={actionCode => {
                    if (
                      columnParams?.onActionPerformed &&
                      typeof columnParams?.onActionPerformed == 'function'
                    ) {
                      columnParams.onActionPerformed(actionCode, {
                        ...selectedRecord,
                        showConsentError: true,
                      });
                    }
                  }}
                />
              </Pressable>
            )}
          </View>
          <View>
            {email === '' || email === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Pressable>
                <Email
                  contactData={singleLeadData}
                  onActionPerformed={actionCode => {
                    if (
                      columnParams?.onActionPerformed &&
                      typeof columnParams?.onActionPerformed == 'function'
                    ) {
                      columnParams.onActionPerformed(actionCode, {
                        ...selectedRecord,
                        showConsentError: true,
                      });
                    }
                  }}
                />
              </Pressable>
            )}
          </View>
        </VStack>
      );
    },
    responsive: ['md'],
  };
};

export const renderBillingPractitionerColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="assignedWith"
      />
    ),
    key: 'assignedWith',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careTeamLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      const pcpPractitioner = (selectedRecord?.careTeam?.careTeamMemberType || []).find((careTeamMember: any) => {
        return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP;
      });
      const allCareTeamMembersExcludingPcpAndCareManager = (
        selectedRecord?.careTeam?.careTeamMemberType || []
      ).filter(
        (careTeamMember: any) =>
          careTeamMember?.memberType?.code !== CARE_TEAM_MEMBER_TYPE.PCP &&
          careTeamMember?.memberType?.code !==
            CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
      );

      const billingPractitioner = (selectedRecord?.careTeam?.careTeamMemberType || []).find((careTeamMember: any) => {
        return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER;
      });
      const careManager = (selectedRecord?.careTeam?.careTeamMemberType || []).find((careTeamMember: any) => {
        return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER;
      });

      return (
        <Stack direction={'column'}>
          {pcpPractitioner?.userName && (
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={Colors.FoldPixel.GRAY200}
              fontSize={14}
            >
              {pcpPractitioner.userName} (PCP)
              {(!!careManager?.userName ||
                allCareTeamMembersExcludingPcpAndCareManager?.length > 0) &&
                ','}
            </Text>
          )}
          {careManager?.userName && (
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={Colors.FoldPixel.GRAY200}
              fontSize={14}
            >
              {careManager.userName} (Care Manager)
              {allCareTeamMembersExcludingPcpAndCareManager?.length > 0 && ','}
            </Text>
          )}
          {!!allCareTeamMembersExcludingPcpAndCareManager && (
            <Stack direction={'column'}>
              {allCareTeamMembersExcludingPcpAndCareManager?.map(
                (careTeamMember: any, index: number) => {
                  return (
                    <Text
                      key={careTeamMember?.userId}
                      wordBreak={'break-word'}
                      size={'smLight'}
                      color={Colors.FoldPixel.GRAY200}
                      fontSize={14}
                    >
                      {careTeamMember?.userName}
                      {(index < allCareTeamMembersExcludingPcpAndCareManager?.length -1) && ','}
                    </Text>
                  );
                }
              )}
            </Stack>
          )}
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderChronicConditionColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="conditions"
      />
    ),
    key: 'conditions',
    width: 240,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.chronicConditionLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      const chronicConditions: ICondition[] = selectedRecord?.chronicCondition || [];
      return (
        <>
          <Stack direction={'column'}>
            {(chronicConditions || []).map((condition: ICondition, index: number) => {
              const diffInMonth = condition?.onSetDateTime ? Math.floor(getDiffInMonths(condition?.onSetDateTime, getMomentObj(new Date()))) : 0;
              //(${diffInMonth}m)`} {index < chronicConditions?.length-1 ? ',' : '.';
              let diffStr = diffInMonth > 0 ? `(${diffInMonth}m)` : '';
              diffStr += index < chronicConditions?.length-1 ? ',' : '.';
              return( <Stack direction={'row'}>
                <Text wordBreak={'break-word'} color={Colors.Custom.Gray500} size={'smLight'}>
                    {condition?.name}
                    <Text wordBreak={'break-word'} color={Colors.Custom.Gray400} size={'smLight'}>{diffStr}</Text>
                </Text>
              </Stack>);
            })}
          </Stack>
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderDischargeDateColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="dischargeDate"
      />
    ),
    key: 'dischargeDate',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderAdmissionDateColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="admittedDate"
      />
    ),
    key: 'admittedDate',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderCarePlanStatusColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="carePlanStatus"
      />
    ),
    key: 'carePlanStatus',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.chronicConditionLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      return (<></>)
    },
    responsive: ['md'],
  };
};

export const renderHealthPlanAndCareStatusColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="healthPlan"
      />
    ),
    key: 'healthPlan',
    width: 220,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const patientId = selectedRecord?.contactData?.patient?.patientId || undefined;
      if (!patientId) {
        return (<></>)
      }
      return (<MemberHealthPlanView patientId={patientId}/>)
    },
    responsive: ['md'],
  };
};


export const renderEnrollmentDateAndStatusColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="enrollmentDate"
      />
    ),
    key: 'enrollmentDate',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      if (!selectedRecord?.additionalDataLoading?.careProgramLoading && !selectedRecord?.contactCarePrograms?.length) {
        return (<></>);
      }
      const contactCarePrograms: IContactCareProgramResponse[] = selectedRecord?.contactCarePrograms || [];
      return (
        <>
          <Stack direction={'column'} key={`${selectedRecord?.uuid}_enrollment`}>
            {(contactCarePrograms || []).map((contactCareProgram, index) => {
              return (
                <Stack direction={'row'} key={`${contactCareProgram?.id}_enrollment`}>
                  {contactCareProgram?.enrollmentLog?.enrollmentDateTime && (
                    <Text size={'smMedium'} color={Colors.Custom.Gray500} key={`${contactCareProgram?.id}_enrollmentDate`}>{getDateStrFromFormat(contactCareProgram.enrollmentLog.enrollmentDateTime, DATE_FORMATS.MESSAGE_DATE_FORMAT)}
                    </Text>
                  )}
                  {!contactCareProgram?.enrollmentLog?.enrollmentDateTime && (
                    <Text size={'smMedium'} color={Colors.Custom.Gray500} key={`${contactCareProgram?.id}_enrollmentDateStatus`}>{getProgramEnrollmentDateAndStatus(contactCareProgram, true)} </Text>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderFacilityColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="facility"
      />
    ),
    key: 'facility',
    width: 180,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.FACILITY, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderFacilityTypeColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="facilityType"
      />
    ),
    key: 'facilityType',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.FACILITY_TYPE, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderAWVDateColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="awvDate"
      />
    ),
    key: 'awvDate',
    width: 270,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const patientId = selectedRecord?.contactData?.patient?.patientId || undefined;
      const appointmentData=selectedRecord?.appointmentData
      if (!patientId) {
        return (<></>)
      }
      return (<MemberAWVStatusView patientId={patientId} appointmentData={appointmentData}/>)
    },
    responsive: ['md'],
  };
};

export const renderDischargeToColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="dischargeTo"
      />
    ),
    key: 'dischargeTo',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_TO, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderEligibleProgramColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="eligibleFor"
      />
    ),
    key: 'eligibleFor',
    width: 280,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      if (!selectedRecord?.additionalDataLoading?.careProgramLoading && !selectedRecord?.contactCarePrograms?.length) {
        return (<></>);
      }
      const contactCarePrograms: IContactCareProgramResponse[] = selectedRecord?.contactCarePrograms || [];
      return (
        <>
          <Stack direction={'column'}>
            {(contactCarePrograms || []).map((contactCareProgram, index) => {
              return (
                <>
                  <CareProgramStepWiseView
                    filterCode={columnParams?.filterCode}
                    contactCareProgram={contactCareProgram}
                    onActionPerformed={(actionCode: string, extraData: any) => {
                      if (
                        actionCode === COMMON_ACTION_CODES.SEND_FORMS ||
                        actionCode === COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL ||
                        actionCode === COMMON_ACTION_CODES.ECM_OUTREACH_VIEW ||
                        actionCode === COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT ||
                        actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT ||
                        actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT
                      ) {
                        if (
                          columnParams?.onActionPerformed &&
                          typeof columnParams.onActionPerformed === 'function'
                        ) {
                          columnParams?.onActionPerformed(
                            actionCode,
                            {...selectedRecord, extraData},
                          );
                        }
                      }
                    }}
                  />
                </>
              );
            })}
          </Stack>
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderActionColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'xsMedium'}
        extraStyles={{color: Colors.primary['700'] || ''}}
        textLocalId="actions"
      />
    ),
    key: 'action',
    width: 200,
    fixed: 'right',
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (text: any, selectedRecord: any) => {
      if (selectedRecord?.additionalDataLoading?.consentLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      return (
        <>
          <ActionsView
            hideConvertToPatient={false}
            record={selectedRecord}
            onActionClick={(actionCode: string, data: any): any => {
              if (
                columnParams?.onActionPerformed &&
                typeof columnParams?.onActionPerformed == 'function'
              ) {
                if (data === COMMON_ACTION_CODES.CONSENT_WARNING) {
                  columnParams.onActionPerformed(actionCode, {
                    ...selectedRecord,
                    showConsentError: true,
                  });
                } else {
                  columnParams.onActionPerformed(actionCode, selectedRecord);
                }
              }
            }}
          />
        </>
      );
    },
    responsive: ['md'],
  };
};

export const getTableColumnByFilterCode = (inputFilterCode: string | undefined) => {
  const columnCodes: string[] = [];
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.TAGS);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.INTERNAL_TASK);
  const filterCode = inputFilterCode || PROGRAM_STEPS_FILTER_CODE.ALL;
  switch(filterCode) {
    case PROGRAM_STEPS_FILTER_CODE.ALL:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.AWV_DUE);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CONDITION);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS);
      break;
    case PROGRAM_FILTER_CODE.CCM:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CONDITION);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS);
      break;
    case PROGRAM_FILTER_CODE.TCM:
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_STATUS);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.FACILITY);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT);
      break;
    case PROGRAM_FILTER_CODE.ECM:
      //columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      break;
    case PROGRAM_FILTER_CODE.AWV_DUE:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.AWV_DUE);
      break;
    case PROGRAM_FILTER_CODE.AWV_DUE:
    case PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED:
    case PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED:
    case PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.AWV_DUE);
      break;
  }
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ENROLLMENT);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ACTION);
  return columnCodes;
}

export const getTableViewColumns = (tableColumnParams: IDashboardColumnProps) => {
  const columns: any[] = [];
  const columnsCodes: string[] = getTableColumnByFilterCode(tableColumnParams?.filterCode)
  for (const columnCode of columnsCodes) {
    switch (columnCode) {
      case CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME:
        columns.push(renderMemberNameColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO:
        columns.push(renderContactInfoColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CONDITION:
        columns.push(renderChronicConditionColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE:
        columns.push(renderAdmissionDateColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE:
        columns.push(renderDischargeDateColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO:
        columns.push(renderDischargeToColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FACILITY:
        columns.push(renderFacilityColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE:
        columns.push(renderFacilityTypeColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT:
        break;
      case CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS:
        columns.push(renderCarePlanStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CARE_STATUS:
        columns.push(renderHealthPlanAndCareStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.AWV_DUE:
        columns.push(renderAWVDateColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER:
        columns.push(renderBillingPractitionerColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS:
        columns.push(renderEnrollmentDateAndStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.TAGS:
        columns.push(renderContactTagsColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.INTERNAL_TASK:
        columns.push(renderInternalTaskColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT:
        columns.push(renderEligibleProgramColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ACTION:
        columns.push(renderActionColumn(tableColumnParams));
        break;
    }
  }
  return columns;
};
