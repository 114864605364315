import { HStack, Pressable, Text, View, useToast } from "native-base"
import { Colors } from "../../../../styles"
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useState } from "react";
import { Popover } from "antd";
import { Dimensions} from 'react-native';
import {BoardType, TaskFilterType} from "./interfaces";
import {ToastType, showToast} from "../../../../utils/commonViewUtils";
import {useIntl} from "react-intl";
import Stack from "../../LayoutComponents/Stack";


export const FilterWrapperComponent = (props: {
    label?: string;
    children?: any;
    width?: number | string;
    popoverMinHeight?: number;
    popoverMaxHeight?: number;
    onClose?: () => void;
    value?: any[] | any;
    textWeight?:number;
    maxWidth?: number | string;
    minWidthDropdown?:number;
    dropdownMarginLeft?:number;
    filterType?: string;
    selectDefaultUser?: ()=> void;
    overlayClassName?: string
    marginBottom?: number;
    maxNumberOfAppliedFiltersToshow?:number;
    showFilterAsDropDown?: boolean;
    filterTextStyle?: any;
    filterValueTextStyle?: any;
    contentStyle?: any
    contentMinHeight?: number;
    noJustify?: boolean;
    isMultiSelect?: boolean;
}) => {
    const [showChild, setShowChild] = useState(false);
    const {width} = Dimensions.get('window');
    const toast = useToast();
    const intl = useIntl();

    const checkPopoverCloseAllowed = ()=> {
      switch(props?.filterType) {
        case TaskFilterType?.Assignee:
        case TaskFilterType?.CreatedBy:
        case TaskFilterType?.Mention:
          if (!props?.value?.length) {
            return false;
          } else {
            return true;
          }
        default:
          return true;
      }
    }

    const getPopoverCloseMessage = (action?: string)=> {
      switch(action) {
        case TaskFilterType?.Assignee:
          return intl.formatMessage({
            id: 'assigneeNotSelectedInfo',
          });
        case TaskFilterType?.Mention:
          return intl.formatMessage({
            id: 'mentionNotSelectedInfo',
          });
        case TaskFilterType?.CreatedBy:
          return intl.formatMessage({
            id: 'createdByNotSelectedInfo',
          });
        default:
          return '';
      }
    }

    const showPopoverCloseMessage = ()=> {
      const message = getPopoverCloseMessage(props?.filterType || '')
      showToast(
        toast,
        message,
        ToastType.info,
        2500,
      );
      props?.selectDefaultUser?.();
    }

    const getAppliedFiltersText = () => {
      if (!!props?.maxNumberOfAppliedFiltersToshow) {
        return props?.value
          ?.slice(0, props.maxNumberOfAppliedFiltersToshow)
          ?.map((item: any) => item?.toString())
          ?.join(', ');
      } else return props?.value?.[0]?.toString();
    }

    return (
      <Stack space={4} direction="column" style={props.showFilterAsDropDown ? {flex: 1} : {}}>
      {props.showFilterAsDropDown && props.label ? (
        <Text
          style={{
            fontSize: 14,
            marginRight: 2,
            ...props?.filterTextStyle,
            color: Colors.Custom.Gray400
          }}
        >
          {props.label}
        </Text>
      ) : null}
      <Popover
        content={
          <View
            style={{
              backgroundColor: Colors.Custom.ContainerBGColor,
              position: 'relative',
              minHeight: props?.popoverMinHeight || 50,
              maxHeight: props?.popoverMaxHeight || 'auto',
              overflow: 'scroll',
            }}
          >
            {props.children}
          </View>
        }
        overlayClassName={props?.overlayClassName || ''}
        trigger={'click'}
        placement="bottomLeft"
        open={showChild}
        onOpenChange={(visible: boolean) => {
          const isPopoverCloseAllowed = checkPopoverCloseAllowed();
          if (isPopoverCloseAllowed) {
            setShowChild(visible);
            if (!visible) {
              props?.onClose?.();
            }
          } else {
            showPopoverCloseMessage();
          }
        }}
      >
        <Pressable
          onPress={() => {
            const visible = !showChild;
            setShowChild(visible);
            if (!visible) {
              props?.onClose?.();
            }
          }}
          flexDirection={'row'}
          alignItems="center"
          style={{
            borderWidth: 1,
            borderRadius: 5,
            borderColor: Colors.Custom.Gray200,
            justifyContent: 'space-between',
            backgroundColor: Colors.Custom.ContainerBGColor,
            minHeight: props?.contentMinHeight ?? 40,
            marginLeft:
              props?.dropdownMarginLeft != undefined
                ? props?.dropdownMarginLeft
                : 8,
            paddingHorizontal: 10,
            minWidth: props?.minWidthDropdown || 'auto',
            marginBottom: props.marginBottom,
            ...props?.contentStyle,
            ...(props?.showFilterAsDropDown ? { width: '100%' } : {}),
          }}
        >
          {!props.showFilterAsDropDown && props.label ? (
            <Text
              style={{
                fontSize: 14,
                marginRight: 2,
                ...props?.filterTextStyle,
              }}
            >
              {props.label}
              {props.value?.length ? ': ' : ''}{' '}
            </Text>
          ) : null}
          {props.value?.length ? (

            props.showFilterAsDropDown ? (
              <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row'}}>
                {props.value?.map((item: string, index: number) => {
                  return (
                    <Text
                      key={`Filter_Value_${item}_${index}`}
                      style={props.isMultiSelect ? {
                        backgroundColor: Colors.Custom.Gray100,
                        color: Colors.Custom.Gray500,
                        paddingHorizontal: 6,
                        marginVertical: 4,
                        marginRight: 4,
                        borderRadius: 4,
                      } : {
                        color: Colors.Custom.Gray700,
                      }}>
                      {item}
                    </Text>
                  )
                })}
              </View>
            ) :( <>
              <View
                style={{
                  maxWidth: props.maxWidth || width / 16,
                }}
              >
                <Text
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                  fontWeight={props?.textWeight || 600}
                  style={{
                    fontSize: 14,
                    marginRight: 2,
                    ...props?.filterValueTextStyle,
                  }}
                >
                  {props.value[0]?.toString()}
                </Text>
              </View>
              {props.value?.length > 1 ? (
                <Text
                  fontWeight={props?.textWeight || 600}
                  style={{
                    fontSize: 14,
                    marginRight: 2,
                    ...props?.filterValueTextStyle,
                  }}
                >
                  {'• + ' + (props.value.length - 1)}{' '}
                </Text>
              ) : null}{' '}
            </>)
          ) :
          (props.showFilterAsDropDown && props.label ? (
            <Text
              style={{
                fontSize: 14,
                color: Colors.Custom.Gray400
              }}
            >
              {`Select ${props.label}`}
            </Text>
          ) : null)}
          <AntIcon name="caretdown" color={Colors.Custom.Gray400} size={8} />
        </Pressable>
      </Popover>
      </Stack>
    );
}
