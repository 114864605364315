import {useMutation, useQuery} from '@apollo/client';
import {Skeleton, Table} from 'antd';
import {Box, useDisclose, useToast, View} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {MLOV_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {IUser} from '../../../../Interfaces';
import {ScheduleEventQueries, UserQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import {testID, TestIdentifiers} from '../../../../testUtils';
import {getAccountUUID, isAppointmentTypesGroupEnable} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import FHAlertDialog from '../../../common/FHAlertDialog/FHAlertDialog';
import { getEhrConfig } from '../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {getColumns, getUserIds, setUserData} from './AppointmentTypesHelper';
import AppointmentTypeTopBar, {
  AppointmentTypeAction,
} from './AppointmentTypeTopBar';
import {IAppointmentType, IAppointmentTypeAvailabilityApiResponse, IAppointmentTypesInterface} from './Interfaces';
import AddOrUpdateAppointmentTypes from './AddOrUpdateAppointmentTypes';
import { TABLE_TOP_BAR_ACTION_CODES } from '../../../common/TableTopBar';
import { formatAppointmentTypes } from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';

interface IScheduleEventAPIData {
  appointmentTypes: IAppointmentType[];
}

const AppointmentTypesList = (props: IAppointmentTypesInterface) => {
  const intl = useIntl();
  const toast = useToast();
  const [action, setAction] = useState(AppointmentTypeAction.singleAppointment);
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const mlovData = useContext(CommonDataContext);
  const isGroupAppointmentEnabled = isAppointmentTypesGroupEnable(mlovData.userSettings);
  const ehrCapabilities = mlovData.ehrCapabilities || [];
  const currentEHR = ehrCapabilities?.length ? ehrCapabilities?.[0]?.ehrName : '';
  const [updateData, setUpdateData] = useState<IAppointmentType | undefined>(
    undefined
  );
  const ehrConfig = getEhrConfig(currentEHR);
  const appointmentTypeCategory =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_TYPE_CATEGORY
    ) || [];
  const groupAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.GROUP_APPOINTMENT
  );

  const groupSessionAppointmentCategoryId = getMlovIdFromCode(
    appointmentTypeCategory,
    MLOV_CODES.GROUP_SESSION
  );

  const appointmentFieldTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.APPOINTMENT_CUSTOM_FIELD_TYPE
    ) || [];

  const durationMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.DATE_TIME_DURATION
    ) || [];

  const [appointmentListState, setAppointmentListState] = useState<{
    searchString: string;
    loading: boolean;
    users: IUser[];
    appointmentTypes: IAppointmentType[];
    deleteConfirmationOpen: boolean;
    selectedItem?: any;
    showAddOrUpdateDrawer:boolean;
    action?: AppointmentTypeAction;
    appointmentTypeAvailabilityResponse?: IAppointmentTypeAvailabilityApiResponse[];
  }>({
    searchString: '',
    loading: true,
    users: [],
    appointmentTypes: [],
    deleteConfirmationOpen: false,
    selectedItem: undefined,
    showAddOrUpdateDrawer:false,
    action: undefined,
    appointmentTypeAvailabilityResponse: [],
  });

  useEffect(() => {
    setAppointmentListState((prev) => ({
      ...prev,
      searchString: props?.searchString || '',
      loading: true,
    }));
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, [props.searchString]);

  useEffect(() => {
    if (
      props?.selectedActionCode ===
      TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_APPOINTMENT_TYPE
    ) {
      openAddOrUpdateDrawer(false);
    }  else if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.CLOSE) {
      setAppointmentListState(prev => ({
        ...prev,
        showAddOrUpdateDrawer:false,
        selectedItem: undefined,
        action: undefined,
        appointmentTypeAvailabilityResponse: [],
      }))
    }
  }, [props.selectedActionCode]);

  const openAddOrUpdateDrawer = (
    isEdit: boolean,
    item?: IAppointmentType,
    action?: AppointmentTypeAction
  ) => {
    setAppointmentListState((prev) => ({
      ...prev,
      showAddOrUpdateDrawer: true,
      appointmentTypeAvailabilityResponse: isEdit ? item?.appointmentTypeAvailability || [] : [],
      ...(isEdit
        ? {
            selectedItem: item,
            action: action || undefined,
          }
        : undefined),
    }));
  };

  const closeAddOrUpdateDrawer = () => {
    setAppointmentListState(prev => ({
      ...prev,
      showAddOrUpdateDrawer:false,
      selectedItem: undefined,
      action: undefined,
      appointmentTypeAvailabilityResponse: [],
    }))
    props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE)
  }

  const {refetch} = useQuery<IScheduleEventAPIData>(
    ScheduleEventQueries.GET_APPOINTMENT_TYPES,
    {
      variables: {
        searchString: `%${appointmentListState.searchString}%`,
        categoryCodes: isGroupAppointmentEnabled ? [MLOV_CODES.ONE_ON_ONE_APPOINTMENT, MLOV_CODES.GROUP_SESSION] : [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: IScheduleEventAPIData) => {
        if (data.appointmentTypes && data.appointmentTypes.length > 0) {
          let updatedAppointmentTypes = data.appointmentTypes;
          if (
            appointmentListState.users &&
            appointmentListState.users.length > 0
          ) {
            updatedAppointmentTypes = setUserData(
              data.appointmentTypes,
              appointmentListState.users
            );
          }
          const formattedAppointmentTypes = formatAppointmentTypes(updatedAppointmentTypes, [...durationMlovs, ...appointmentFieldTypeMlovs]);
          setAppointmentListState((prev) => ({
            ...prev,
            appointmentTypes: formattedAppointmentTypes as IAppointmentType[],
            loading: false,
          }));
        } else {
          setAppointmentListState((prev) => ({
            ...prev,
            appointmentTypes: [],
            loading: false,
          }));
        }
      },
      onError: (error) => {

        setAppointmentListState((prev) => ({
          ...prev,
          appointmentTypes: [],
          loading: false,
        }));
      },
    }
  );

  useQuery(UserQueries.GET_USERS_WITH_IDS, {
    skip: appointmentListState.appointmentTypes.length === 0,
    fetchPolicy: 'no-cache',
    variables: {
      userIds: getUserIds(appointmentListState.appointmentTypes),
      accountId: getAccountUUID(),
    },
    onCompleted: (data: any) => {
      if (data && data.users && data.users.length > 0) {
        const updatedAppointmentTypes = setUserData(
          appointmentListState.appointmentTypes,
          data.users
        );
        setAppointmentListState((prev) => ({
          ...prev,
          appointmentTypes: [...updatedAppointmentTypes],
          users: [...data.users],
        }));
      }
      setAppointmentListState((prev) => ({...prev, loading: false}));
    },
    onError: (data: any) => {
      setAppointmentListState((prev) => ({...prev, loading: false}));
    },
  });

  const [addOrUpdateAppointmentType] = useMutation(
    ScheduleEventQueries.ADD_OR_UPDATE_APPOINTMENT_TYPE,
    { context: { service: CARESTUDIO_APOLLO_CONTEXT } }
  );

  const onEdit = (data: IAppointmentType) => {
    openAddOrUpdateDrawer(
      true,
      data,
      data.categoryId === groupAppointmentCategoryId
        ? AppointmentTypeAction.groupAppointment
        : data.categoryId === groupSessionAppointmentCategoryId
        ? AppointmentTypeAction.groupSessionAppointment
        : AppointmentTypeAction.singleAppointment
    );
  };

  const deleteItem = () => {
    if (!appointmentListState.selectedItem) {
      return;
    }
    setAppointmentListState((prev) => ({
      ...prev,
      deleteConfirmationOpen: false,
      selectedForm: undefined,
      selectedItem: undefined,
      loading: true,
    }));
    addOrUpdateAppointmentType({
      variables: {
        data: {
          id: appointmentListState.selectedItem.id,
          duration: appointmentListState?.selectedItem?.duration || 30,
          eventName: appointmentListState?.selectedItem?.eventName,
          isDeleted: true,
        }
      },
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        const timeOutId = setTimeout(() => {
          showToast(
            toast,
            intl.formatMessage({id: 'appointmentTypeDeletedMessage'}),
            ToastType.success
          );
          refetch();
          setAppointmentListState((prev) => ({...prev, loading: false}));
        }, 1000);
        timeOutRefs.current.push((timeOutId))
      },
      onError: () => {
        setAppointmentListState((prev) => ({...prev, loading: false}));
      },
    });
  };

  const onDelete = (data: IAppointmentType) => {
    setAppointmentListState((prev) => ({
      ...prev,
      deleteConfirmationOpen: true,
      selectedItem: data,
    }));
  };
  const {height} = Dimensions.get('window');

  return (
    <View>
      <Box
        overflow={'hidden'}
        marginBottom={0}
        // maxWidth={1200}
        //style={{marginHorizontal: 16, marginTop: 16}}
        bgColor={'white'}
        borderRadius={0}
        backgroundColor={'#fff'}
      >
       {props?.showTableTopBar && <AppointmentTypeTopBar
          onAction={() => {
            openAddOrUpdateDrawer(false);
          }}
          onSearch={(text: string) => {
            setAppointmentListState((prev) => ({...prev, searchString: text, loading: true}));
          }}
        />}
        {/* {appointmentListState.appointmentTypes.length > 0 && */}
          {!appointmentListState.loading && (
            <Table
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              scroll={{  x: 800,y: height - 260}}
              rowKey={(row: IAppointmentType) => row.id || ''}
              columns={getColumns(intl, onEdit, onDelete, mlovData, ehrConfig)}
              dataSource={appointmentListState.appointmentTypes}
              pagination={false}
              onRow={(data) => ({
                onClick: () => onEdit(data),
              })}
            />
          )}
        {appointmentListState.loading && (
          <View margin={4} {...testID(TestIdentifiers.pageLoading)}>
            <Skeleton active />
          </View>
        )}
      </Box>

      {appointmentListState.showAddOrUpdateDrawer && (
        <AddOrUpdateAppointmentTypes
          refetchAppointments={refetch}
          isVisible={appointmentListState.showAddOrUpdateDrawer}
          //type={appointmentListState?.action || AppointmentTypeAction.singleAppointment}
          updateData={appointmentListState.selectedItem}
          onClose={() => {
            closeAddOrUpdateDrawer();
          }}
          appointmentTypeAvailabilityResponse={appointmentListState.appointmentTypeAvailabilityResponse || []}
        />
      )}

      <FHAlertDialog
        isOpen={appointmentListState.deleteConfirmationOpen}
        header={intl.formatMessage({id: 'deleteAppointmentType'})}
        message={intl.formatMessage({id: 'deleteAppointmentTypeAlertMessage'})}
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              setAppointmentListState((prev) => ({
                ...prev,
                deleteConfirmationOpen: false,
                selectedItem: undefined,
                appointmentTypeAvailabilityResponse: [],
              }));
            },
          },
          {
            textLocalId: 'Delete',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              deleteItem();
            },
          },
        ]}
      />
    </View>
  );
};

export default AppointmentTypesList;
