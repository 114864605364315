import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer} from 'antd';
import {Box, Center, HStack, Spinner, Text, useToast, View, VStack} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
// import { IMemberData } from '../common/ZoomView/interfaces';
import {useIntl} from 'react-intl';
import {Dimensions, Platform} from 'react-native';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
  HTTP_ERROR_CODE,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
  WINDOW_EVENT_CODES,
} from '../../constants';
import {COMMON_ACTION_CODES} from '../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import {FHIR_RESOURCE, RESOURCE_BLOCK_MESSAGE_ID} from '../../constants/FhirConstant';
import { USER_ROLE_CODES } from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {ConvertContacts, LeadQueries, PatientQueries} from '../../services';
import {
  CreatePatient,
  createPatientUsingIntegration,
  getPatientDetailsFromHint,
} from '../../services/CommonService/AidBoxService';
import BaseService from '../../services/CommonService/BaseService';
import { GetUsersTaskAccess, GetUsersTaskPoolAccess } from '../../services/TaskPool/TaskPoolQueries';
import { GET_USER_FOR_SCHEDULE_ACCESS } from '../../services/User/UserQueries';
import {Colors} from '../../styles';
import {
  getBirthSexLabel,
  getGenderIdentityLabel,
  getResourceAbilities,
} from '../../utils/capabilityUtils';
import {
  filterWorkflowUser,
  getAccountId,
  getAccountUUID,
  getUserFullName,
  getUserId,
  getUserUUID,
} from '../../utils/commonUtils';
import { EventBus } from '../../utils/EventBus';
import {getContactTypeId, getMlovId} from '../../utils/mlovUtils';
import {AddNoteView} from '../common/AddNoteView';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import {AppointmentType} from '../common/CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import BookingWorkflow from '../common/CalendarWidget/BookingWorkflows/BookingWorkflow';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IAllUserPoolSelect } from '../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import {capitalizeText} from '../common/ContactRelationView/ContactRelationUtils';
import ContactSendForm from '../common/ContactSendForm/ContactSendForm';
import ConvertContactsView from '../common/ConvertContactsView/ConvertContactsView';
import EmailDrawerCommon from '../common/EmailPopupView/EmailDrawerCommon';
// import {IMeeting} from '../common/MeetingView/interface';
import MeetingView from '../common/MeetingView/MeetingView';
import TitleSubtitleView from '../common/TitleSubtitleView/TitleSubtitleView';
import {CONTACT_ACTION_CODES} from '../RightSideContainer/Contacts/ContactsConst';
import {
  checkMandatoryFields,
  getMissingFields,
} from '../RightSideContainer/Contacts/ContactsUtils';
import {ConvertLeadList} from '../RightSideContainer/Contacts/Leads/ConvertLeadList';
import {AddOrUpdateLead} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { getKeysForPersonType } from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {
  getFieldConfig,
  getFormDataFromLeadData,
  sendPatientAppInvite,
} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {ConfirmationModal} from '../RightSideContainer/Contacts/Visitor/ConvertContactsList/ConfirmationModal';
import {ComingSoonModal} from '../RightSideContainer/ContentManagement/EmailTemplates/ComingSoonModal';
import {OverrideModal} from '../RightSideContainer/ContentManagement/OverrideModal';
import {AutomationWorkflowEmployee} from '../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import CreateSmsConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {LeftContainerMainView} from './LeftContainer/LeftContainerMainView';
import {MIDDLE_CONTAINER_TABS, MIDDLE_CONTAINER_TABS_LIST} from './MiddleContainer/MiddleContainerConst';
import {MiddleContainerMainView} from './MiddleContainer/MiddleContainerMainView';
import {IPersonHeaderBar} from './PersonHeaderBar/interfaces';
import {PERSON_ACTION_CODES} from './PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {styles} from './PersonOmniViewStyle';
import {PersonTabBar} from './PersonTabBar';
import {PatientInfoView} from './RightContainer/PatientInfoView';
import { ToastType, showToast } from '../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import { isPatientDetailViewAllowed } from '../RightSideContainer/UserAccess/UserAccessPermission';

export const PersonOmniView = (props: IPersonHeaderBar) => {
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  const intl = useIntl();
  const toast = useToast();
  const accountId = getAccountId();
  const accountUUID = getAccountUUID();
  const userUUID = getUserUUID();
  const userFullName = getUserFullName();
  const userId = getUserId();
  const state = useLocation();
  const isActionCreateChart = state?.state?.actionType == 'CREATE_CHART' ? true : false
  const toJourneys = state?.state?.middleContainerTab
  const [alert, setAlert] = useState('');
  const [convertLeadModal, setConvertLeadModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [patientUuid, setPatientUuid] = useState<string | undefined>('');
  const [actionType, setActionType] = useState('');
  const [comingSoonModal, setComingSoonModal] = useState(true);

  const getDefaultMiddleTab = (): string => {
    if (props?.tabCode) {
      const findTab = (MIDDLE_CONTAINER_TABS_LIST || []).find(tab => {
        return tab?.tabCode === props?.tabCode;
      });
      return findTab?.tabCode || MIDDLE_CONTAINER_TABS.TIMELINE;
    } else if (isActionCreateChart) {
      return MIDDLE_CONTAINER_TABS.NOTES;
    } else {
      if (props.personType === PERSON_TYPES.CUSTOMER) {
        return toJourneys === MIDDLE_CONTAINER_TABS.JOURNEYS ? MIDDLE_CONTAINER_TABS.JOURNEYS :  MIDDLE_CONTAINER_TABS.TIMELINE;
      } else {
        return MIDDLE_CONTAINER_TABS.FORM_RESPONSES;
      }
    }
  }

  const [middleContainerTab, setMiddleContainerTab] = useState(getDefaultMiddleTab());
  const [refetchPatientAppointment, setRefetchPatientAppointment] =
    useState(false);

  const locationData = useLocation();
  const conversationInbox = locationData?.state;

  const navigate = useNavigate();

  const patientDetailsAllowed = isPatientDetailViewAllowed();
  const isGenderAtBirthRequired = getResourceAbilities(FHIR_RESOURCE.PATIENT)
    ?.keyAllowedOperations?.birthSex?.isRequired;
  const genderAtBirthLabel = getBirthSexLabel();
  const genderIdentityLabel = getGenderIdentityLabel();
  const patientResource = FHIR_RESOURCE.PATIENT;
  const fieldKeys = getKeysForPersonType(props.personType);
  const isAddressLine1Required = !!getFieldConfig(fieldKeys?.addressLine1, patientResource)?.isRequired;
  const isMobileNumberRequired = false;//!!getFieldConfig(fieldKeys?.phoneNumber, patientResource)?.isRequired;
  const isPhoneOrEmailRequired = isAccountConfigEnabled(CONFIG_CODES.IS_PHONE_OR_EMAIL_REQUIRED);
  const isPractitionerRequired =
    getResourceAbilities(FHIR_RESOURCE.PATIENT)?.keyAllowedOperations
      ?.generalPractitioner?.isRequired || false;
  const isLocationRequired = getResourceAbilities(FHIR_RESOURCE.PATIENT)?.keyAllowedOperations?.location?.isRequired || false;
    const mlovData = useContext(CommonDataContext);
    const [accountUsers, setAccountUsers] = useState<any[]>([]);
    const [userTaskPoolAccess, setUserTaskPoolAccess] = useState(false);
    const [userPoolSelect, setAllUserPoolSelect] = useState<IAllUserPoolSelect[]>([]);
    const loggedInUserId = getUserUUID();
    const accessUserTypeId = getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      'UserPoolType',
      'access_user_task'
    );

    const accessUserTaskPoolTypeId = getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      'UserPoolType',
      'task_user_pool'
    );

    const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const accountUsers: any[] = [];
        if (data && data?.users && data?.users.length) {
          data.users = filterWorkflowUser(data.users, loggedInUserId);
          (data.users || []).forEach((user: any) => {
            accountUsers.push({
              userName: user.name,
              userId: user.uuid,
              email: user.email || '',
            });
          });
        }
        setAccountUsers(accountUsers);
        getUserTaskPoolAccess();
        updateForcefullyTabCode(middleContainerTab);
      },
      onError: (error: any) => {

      },
    });

    const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    });

    const [ getUserTaskPoolAccess, getUserTaskPoolAccessAPI ] = useLazyQuery(GetUsersTaskPoolAccess, {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      //skip: accountUserQueryLoading,
      variables: {
        // userId: accountUsers.map(
        //   (accountUserItem: any) => accountUserItem.userId
        // ),
        userId: [loggedInUserId],
        userPoolTypeId: accessUserTypeId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        // fetching all account users amd filtering login user data
        const filterUserPoolAccessData: any = data.userPoolAccesses?.filter(
          (dataItem: any) => dataItem.userId === loggedInUserId
        );

        if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
          // logged In user have the other pool user access
          const userPoolAccessUsersData =
            filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
          const loggedInUser = accountUsers.filter(
            (accountItem: any) => accountItem.userId === loggedInUserId
          );
          if (loggedInUser && loggedInUser.length) {
            userPoolAccessUsersData.unshift(loggedInUser[0]);
          }

          // flag to manage dropdown whether there are multiple users to handle or not
          if (userPoolAccessUsersData && userPoolAccessUsersData.length > 1) {
            setUserTaskPoolAccess(true);
          } else {
            setUserTaskPoolAccess(false);
          }
          //userDataPostProcessing(userPoolAccessUsersData);
          fetchAllTaskPoolAccessUserPool(userPoolAccessUsersData);
        } else {
          // logged In user do not have the other pool user access
          setUserTaskPoolAccess(false);
          fetchAllTaskPoolAccessUserPool([
            {
              id: '',
              userId: loggedInUserId,
              isDeleted: false,
            },
          ]);
        }
      },
      onError: (error: any) => {

      },
    });

    const fetchAllTaskPoolAccessUserPool = async (users: any[]) => {
      try {
        const userIds = users.map((userItem: any) => userItem?.userId);
        const taskPoolData = await getAllTaskAccessUserTaskPools({
          variables: {
            userId: userIds,
            userPoolTypeId: accessUserTaskPoolTypeId,
          },
        });
        const newAllUserPool: IAllUserPoolSelect[] = [];
        taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
          const userPoolId = userPoolItem?.userPoolId;
          const userPoolName = userPoolItem?.userPool?.name;
          const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

          const findIndex = newAllUserPool.findIndex(
            (userPoolItem: IAllUserPoolSelect) =>
              userPoolName === userPoolItem.userPoolName
          );
          if (findIndex < 0) {
            newAllUserPool.push({
              userPoolId,
              userPoolName,
              userPoolUserIds: userPoolUsers.map(
                (userPoolUserItem: any) => userPoolUserItem.userId
              ),
            });
          }
        });
        setAllUserPoolSelect(newAllUserPool)

      } catch (error) {
      } finally {
      }
    };

    useEffect(() => {
      getAccountUsers({
        variables: {
          accountUUID: accountUuid,
          roleCode: USER_ROLE_CODES.EMPLOYER
        },
      });
    }, []);

  const baseService = BaseService.getSharedInstance().axios;
  const sendResetPasswordInstructions = async (contactData: any) => {
    const url = `/crm-nest/auth/resetContactPassword`;
    const resetPasswordData = {
      accountUuid: accountUUID,
      contactUuid: contactData?.uuid,
    };
    const headers = {
      accountId: accountId,
    };
    await baseService
      .post(url, resetPasswordData, {headers: headers})
      .then((response) => {
        if (contactData?.email) {
          showToast(
            toast,
            'Password instruction successfully sent on patient email',
            ToastType.success
          );
        } else if (contactData?.phoneNumber) {
          showToast(
            toast,
            'Password instruction successfully sent on patient phone number',
            ToastType.success
          );
        }
      })
      .catch((error) => {
        showToast(
          toast,
          'Something went wrong',
          ToastType.error
        );

      });
  };

  const [resetPasswordModal, setResetPasswordModal] = useState(true);
  const [sendInvite, setSendInvite] = useState(true);
  const accountUuid = getAccountUUID();

  const [selectedConversation, setSelectedConversation] = useState<any>();

  const handleConversationSelect = (actionCode: string, conversation: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ITEM_SELECT:
        setSelectedConversation(conversation);
        break;
      case COMMON_ACTION_CODES.ITEM_CLICKED:
        setSelectedConversation(conversation);
        setMiddleContainerTab(MIDDLE_CONTAINER_TABS.MESSAGES);
        break;
      default:
        setSelectedConversation(conversation);
        break;
    }
  };

  const [UpdateContactTypeByPatientUuid] = useMutation(
    ConvertContacts.UpdateContactTypeByPatientUuid
  );
  const [CreatePatientWithContactId] = useMutation(
    PatientQueries.CreatePatientWithContactId
  );
  const showAppointmentBookingToast = (message: string) => {
    showToast(
      toast,
      message,
      ToastType.success,
    );
  };
  // const [selectedViewCode, setSelectedViewCode] = useState('');
  const [selectedRowData, setSelectedRowData]: any = useState({} as any);
  const [modalOpen, setModalOpen] = useState(false);

  const shouldCreateInCanvas =
    getResourceAbilities(FHIR_RESOURCE.PATIENT)
      ?.createPatientUsingIntegration || false;

  const {id, noteDocumentReferenceId} = useParams();
  const isUpdate =
    props.personType === PERSON_TYPES.LEAD ||
    props.personType === PERSON_TYPES.CUSTOMER
      ? true
      : false;
  const contactId = id;
  const {loading, data, refetch} = useQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
    variables: {
      id: contactId,
    },
  });

  const contactData = data?.contact;

  const [newContactData, setNewContactData]: any = useState({});

  const formattedContactData = getFormDataFromLeadData(data?.contact, mlovData);
  const [selectedViewCode, setSelectedViewCode] = useState({
    code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW,
    invocationCode: '',
  });

  const setViewCode = (code: string) => {
    setSelectedViewCode((prev) => {
      return {
        ...prev,
        code: code,
      };
    });
  };

  const setViewInvocationCode = (type: string) => {
    setSelectedViewCode((prev) => {
      return {
        ...prev,
        invocationCode: type,
      };
    });
  };

  const sendPatientAppInvitation = (formattedData: any) => {
    if (formattedData.contactUuid || formattedData.contactUUID) {
      if (sendInvite) {
        sendPatientAppInvite({
          email: formattedData.email,
          accountId,
          accountUuid,
          contactUuid: formattedData.contactUuid || formattedData.contactUUID,
        })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            showToast(
              toast,
              intl.formatMessage({id: 'patientAppInvitationError'}),
              ToastType.error,
            );
            return err;
          });
      }
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'patientAppInvitationError'}),
        ToastType.error,
      );
    }
  };

  const handleCreatePatientInEHR = (formattedData: any) => {
    const typeId = getContactTypeId(
      PERSON_TYPES.CUSTOMER
    );
    if (shouldCreateInCanvas) {
      createPatientUsingIntegration({
        patientData: {...formattedData, typeId: typeId},
        sendInvite: sendInvite,
        addTagObjectList: [],
        userId: userId,
        userUuid: userUUID,
        successHandler(response) {
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER,
            formattedData
          );
        },
        errorHandler(error) {
          if (error.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED){
            showToast(
              toast,
              intl.formatMessage({id: RESOURCE_BLOCK_MESSAGE_ID}),
              ToastType.error,
            );
          } else {
            showToast(
              toast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          }
        },
      });
    } else {
      CreatePatient(
        formattedData,
        (res: any) => {
          const resId = res?.data?.id;
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
          sendPatientAppInvitation(formattedData);
          handlePatientContactMap(formattedData, resId);
        },
        (error: any) => {
        }
      );
    }
  };

  const navigateToPatientOmniView = (formattedData: any) => {
    const route = `/members/patient/${formattedData.id}`;
    navigate(route, {replace: true});
    location.reload();
  };

  const broadcastPatientUpdateEvent = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, { fromSection: 'PERSON_OMNI_VIEW' });
  }

  const onPersonActionPerformed = (actionCode: string, rawData?: any) => {
    switch (actionCode) {
      case PERSON_ACTION_CODES.EDIT:
      case PERSON_ACTION_CODES.EDIT_LEAD:
        setAlert('');
        setSelectedRowData(contactData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        break;

      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        let singlePersonData: any;
        if (
          selectedViewCode.invocationCode ===
          COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER
        ) {
          setSelectedRowData(rawData.singleLeadData);
          singlePersonData = getFormDataFromLeadData(
            rawData.singleLeadData,
            mlovData
          );
        } else {
          setSelectedRowData(contactData);
          singlePersonData = getFormDataFromLeadData(contactData, mlovData);
        }

        if (
          checkMandatoryFields(singlePersonData, PERSON_TYPES.CUSTOMER, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired: isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          })
        ) {
          if (rawData.singleLeadData) {
            setNewContactData(rawData.singleLeadData);
          } else if (contactData) {
            setNewContactData(contactData);
          }
          setConvertLeadModal(true);
          setActionType(CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER);
        } else {
          const alert = getMissingFields(singlePersonData, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired: isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            genderAtBirthLabel,
            genderIdentityLabel,
            isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          }).message;
          setAlert(alert);
          setViewInvocationCode(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        }
        break;

      case COMMON_ACTION_CODES.CONVERT_TO_LEAD:
        setSelectedRowData(contactData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_LEAD);
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        setSelectedRowData(contactData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE);
        break;
      case COMMON_ACTION_CODES.EMAIL:
        setSelectedRowData(contactData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP);
        break;
      case COMMON_ACTION_CODES.SMS:
        setSelectedRowData(contactData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER);
        break;
      case PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        setModalOpen(false);
        setSelectedRowData(contactData);
        setModalOpen(true);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP);
        break;
      case PERSON_ACTION_CODES.OPEN_IN_EHR:
        setSelectedRowData(contactData);
        setComingSoonModal(true);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_OPEN_IN_EHR);
        break;
      case PERSON_ACTION_CODES.CALL:
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW);
        break;
      case PERSON_ACTION_CODES.CANCEL:
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        setViewInvocationCode('');
        break;
      case PERSON_ACTION_CODES.RESET_PASSWORD:
        setSelectedRowData(contactData);
        setResetPasswordModal(true);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD);
        break;
      case PERSON_ACTION_CODES.SEND_FORMS:
        setSelectedRowData(contactData);
        setResetPasswordModal(true);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM);
        break;
      case PERSON_ACTION_CODES.CREATE_TASK:
        setSelectedRowData(contactData);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK);
        break;
      default:
        broadcastPatientUpdateEvent();
        refetch();
        if (
          rawData?.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER &&
          rawData?.leadFormData
        ) {
          onPersonActionPerformed(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER, {
            singleLeadData: rawData.leadFormData.singleLeadData,
          });
        } else {
          setSelectedRowData({
            singleLeadData: rawData?.leadFormData?.singleLeadData,
          });
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        }
        break;
      case PERSON_ACTION_CODES.CREATE_AUTOMATION:
        const contactDataNew = {
          ...contactData,
          contactData: {id: contactData.id},
        };
        setSelectedRowData(contactDataNew);
        setViewCode(RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW);
        break;
    }
  };

  const handleConvertToCustomer = (
    id: any,
    convertToType: string,
    formattedData?: any
  ) => {
    const typeId = getContactTypeId(convertToType);
    UpdateContactTypeByPatientUuid({
      variables: {
        id: id,
        typeId: typeId,
      },
    }).then((response) => {
      if (shouldCreateInCanvas) {
        navigateToPatientOmniView(formattedData);
      }
    });
  };
  const handlePatientContactMap = (contactData: any, patientUuid: any) => {
    CreatePatientWithContactId({
      variables: {
        accountId: accountId,
        patientUuid: patientUuid,
        contactId: contactData.leadId,
      },
    })
      .then(() => {
        navigateToPatientOmniView(contactData);
      })
      .catch((err) => {
      });
  };

  const getFlexValues = (
    containerSide: 'left' | 'middle' | 'right',
    personType: string
  ) => {
    if (containerSide === 'left') {
      return {
        flex: 0.3,
        visible: true,
      };
    }

    if (containerSide === 'middle') {
      // if (
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.TASKS ||
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.JOURNEYS ||
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.FORM_RESPONSES
      // ) {
      //   return {
      //     flex: 1,
      //     visible: true,
      //   };
      // }

      if (
        personType === PERSON_TYPES.LEAD ||
        personType === PERSON_TYPES.SUPPORTING_MEMBER ||
        personType === PERSON_TYPES.OTHER
        ) {
        return {
          flex: 0.7,
          visible: true,
        };
      } else {
        return {
          flex: 0.45,
          visible: true,
        };
      }
    }

    if (containerSide === 'right') {
      if (
        personType === PERSON_TYPES.LEAD ||
        personType === PERSON_TYPES.SUPPORTING_MEMBER ||
        personType === PERSON_TYPES.OTHER
        ) {
        return {
          flex: 0,
          visible: false,
        };
      }
      return {
        flex: 0.3,
        visible: true,
      };
      // if (
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.JOURNEYS ||
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.TASKS ||
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.NOTES ||
      //   middleContainerTab === MIDDLE_CONTAINER_TABS.FORM_RESPONSES
      // ) {
      //   return {
      //     flex: 0,
      //     visible: false,
      //   };
      // } else {
      //   return {
      //     flex: 0.3,
      //     visible: true,
      //   };
      // }
    }
  };

  const updateForcefullyTabCode = (tabCode: string) => {
    if (middleContainerTab === tabCode) {
      setMiddleContainerTab('');
      setMiddleContainerTab(tabCode);
    }
  };
  const [hintPatientDetails, setHintPatientDetails] = useState({
    plan: '',
    status: '',
    endDate: '',
    loading: false,
  });
  const hintCapability = getResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;

  const getTitleName = () => {
    let name = '';
    if (contactData?.person?.lastName) {
      name = `${contactData.person.lastName}, `;
    }
    if (contactData?.person?.firstName) {
      name += contactData.person.firstName;
    }
    if (contactData?.person?.middleName) {
      name += ` ${contactData.person.middleName}`;
    }
    return name;
  };

  useEffect(() => {
    const prevTitle = document.title;
    document.title = getTitleName() || prevTitle;
    return () => {
      document.title = prevTitle;
    };
  }, [contactData]);

  useEffect(() => {
    // if (openInHint) {
      setHintPatientDetails((prev) => {
        return {
          ...prev,
          loading: true,
              plan: '',
              status: '',
              endDate: '',
        };
      });
      getPatientDetailsFromHint(contactData?.id || id || '')
        .then((response) => {
          const data = response.data;
          let activeMembership = data.memberships.find((item: any) => {
            return item.status === 'active';
          });

          activeMembership = activeMembership
            ? activeMembership
            : data.memberships.length
            ? data.memberships[0]
            : undefined;
          setHintPatientDetails((prev) => {
            return {
              ...prev,
              plan: activeMembership?.plan.name || '',
              status: data.membership_status || '',
              endDate: activeMembership?.end_date || '',
              loading: false,
            };
          });
        })
        .catch((error) => {

          setHintPatientDetails((prev) => {
            return {
              ...prev,
              loading: false,
              plan: '',
              status: '',
              endDate: '',
            };
          });
        });
    // }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(WINDOW_EVENT_CODES.PATIENT_UPDATES, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, [id]);

  useEffect(() => {
    if (noteDocumentReferenceId) {
      setMiddleContainerTab(MIDDLE_CONTAINER_TABS.NOTES);
      updateForcefullyTabCode(MIDDLE_CONTAINER_TABS.NOTES);
    }
  }, [noteDocumentReferenceId]);

  useEffect(() => {
    if (props?.tabCode) {
      const tabCode = getDefaultMiddleTab();
      setMiddleContainerTab(tabCode);
      updateForcefullyTabCode(tabCode);
    }
  }, [props?.tabCode])

  const onEventReceived = useCallback(
    (data) => {
      // Refetch patient data
      if (data?.fromSection !== 'PERSON_OMNI_VIEW') {
        refetch();
      }
    },
    []
  );

  const getPlanDetailByHint =
    hintPatientDetails.status ||
    hintPatientDetails.plan ||
    hintPatientDetails.endDate ? (
      <HStack style={{maxWidth: '90%', flexWrap: 'wrap'}}>
        {hintPatientDetails.status ? (
          <View
            style={{marginRight: 16}}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Text
              style={{
                color: Colors.Custom.Gray500,
              }}
            >
              {'Membership Status: '}
            </Text>
            <Text
              style={{
                color:
                  hintPatientDetails.status == 'active'
                    ? Colors.success[500]
                    : Colors.error[500],
              }}
            >
              {capitalizeText(hintPatientDetails.status)}
            </Text>
          </View>
        ) : (
          <></>
        )}
        {hintPatientDetails.plan ? (
          <Text
            style={{
              color: Colors.Custom.Gray500,
            }}
          >
            {'Plan: '}
            {hintPatientDetails.plan}
          </Text>
        ) : (
          <></>
        )}
        {hintPatientDetails.endDate ? (
          <Text
            style={{
              color:
                hintPatientDetails.status == 'active'
                  ? Colors.success[500]
                  : Colors.error[500],
            }}
          >
            {capitalizeText(hintPatientDetails.status)}
          </Text>
        ) : (
          <></>
        )}
      </HStack>
    ) : null;

  if (!patientDetailsAllowed && props.personType === PERSON_TYPES.CUSTOMER) {
    return (
      <View style={styles.loadingContainer}>
        <Center>
          <Text
            fontWeight="400"
            fontSize="24"
            color={Colors.Custom.Gray400}
          >
            {'You do not have access to view the patient details'}
          </Text>
        </Center>
      </View>
    )
  }
  if (!loading && data && !data.error && data.contact === null) {
    return (
      <View style={styles.loadingContainer}>
        <Center>
          <Text
            fontWeight="400"
            fontSize="24"
            color={Colors.Custom.Gray400}
          >
            {'Member Not found'}
          </Text>
        </Center>
      </View>
    )
  }
  return (
    <>
      {loading ? (
        <>
          <View style={styles.loadingContainer}>
            <Spinner size={'lg'} />
          </View>
        </>
      ) : (
        <VStack flex={1} style={styles.mainContainer}>
          <TitleSubtitleView />
          <HStack flex={1} style={styles.baseContainer}>
            {getFlexValues('left', props.personType)?.visible && (
              <VStack
                flex={getFlexValues('left', props.personType)?.flex}
                style={styles.rightContainer}
              >
                <PatientInfoView
                  hintExtraDetail={getPlanDetailByHint}
                  refetchPatientAppointment={refetchPatientAppointment}
                  contactData={contactData}
                  personType={props.personType}
                  personData={formattedContactData}
                  onConversationSelect={(actionCode, conversation) => {
                    handleConversationSelect(actionCode, conversation);
                  }}
                  onPersonActionPerformed={onPersonActionPerformed}
                  patientHieSyncCall={true}
                />
              </VStack>
            )}

            <VStack
              flex={getFlexValues('middle', props.personType)?.flex}
              style={styles.middleContainer}
            >
              <HStack style={styles.headerContainer}>
                <VStack flex={1} backgroundColor={Colors.Custom.Gray50}>
                  {/* <PersonHeaderBar
                    contactData={contactData}
                    personData={formattedContactData}
                    personType={props.personType}
                    onPersonActionPerformed={onPersonActionPerformed}
                  /> */}
                  <HStack
                    flex={1}
                    justifyContent={'space-between'}
                    style={{
                      backgroundColor: '#fff',
                      borderWidth: 1,
                      borderColor: Colors.Custom.Gray200,
                      borderRadius: 8,
                      paddingBottom: 0,
                    }}
                  >
                    <VStack maxWidth={'100%'}>
                      <PersonTabBar
                        personType={props.personType}
                        selectedTabCode={middleContainerTab}
                        contactId={id}
                        onSelectTab={(selectedTabCode) => {
                          setMiddleContainerTab(selectedTabCode);
                        }}
                      />
                    </VStack>
                    <HStack style={styles.actionBtnContainer}>
                      {/* <PersonActionContainer
                        contactData={contactData}
                        personType={props.personType}
                        personData={formattedContactData}
                        isActionDisabled={props.isActionDisabled}
                        hideActionPopup={props.hideActionPopup}
                        actions={
                          props.actions || [
                            PERSON_ACTION_CODES.EMAIL,
                            PERSON_ACTION_CODES.SMS,
                            PERSON_ACTION_CODES.CALL,
                            PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
                            PERSON_ACTION_CODES.OPEN_IN_EHR,
                            PERSON_ACTION_CODES.CREATE_AUTOMATION,
                            PERSON_ACTION_CODES.PHONE_CALL,
                          ]
                        }
                        onPersonActionPerformed={(
                          actionsCode: any,
                          rawData: any
                        ) => {
                          onPersonActionPerformed(actionsCode, rawData);
                        }}
                      /> */}
                    </HStack>
                  </HStack>
                </VStack>
              </HStack>
              <MiddleContainerMainView
                personType={props.personType}
                personData={formattedContactData}
                unFormattedContactData={contactData}
                middleContainerSelectedTabCode={middleContainerTab}
                conversation={selectedConversation}
                conversationInbox={conversationInbox}
              />
            </VStack>

            {getFlexValues('right', props.personType)?.visible && (
              <VStack
                flex={getFlexValues('right', props.personType)?.flex}
                style={styles.leftContainer}
              >
                <LeftContainerMainView
                  unformmatedContactData={contactData}
                  personType={props.personType}
                  personData={formattedContactData}
                />
              </VStack>
            )}
          </HStack>
        </VStack>
      )}
      {Platform.OS === 'web' &&
        selectedViewCode.code ===
          RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
          <AddOrUpdateLead
            singleLeadData={selectedRowData}
            onFormCompleteAction={(actionCode: string, actionData: any) => {
              onPersonActionPerformed(actionCode, actionData);
            }}
            personType={formattedContactData.contactType.code}
            personTypeUuid={formattedContactData.contactType.id}
            alert={alert}
            invocationCode={selectedViewCode.invocationCode}
            {...selectedRowData}
          />
        )}
      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_LEAD && (
        <ConvertContactsView
          contactdata={selectedRowData}
          isUpdate={isUpdate}
          personType={PERSON_TYPES.CUSTOMER}
          onFormActionPerformed={(actionCode: any) => {
            onPersonActionPerformed(actionCode);
          }}
        />
      )}
      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER &&
        convertLeadModal && (
          <>
            <ConvertLeadList
              isChecked={(checked) => {
                setSendInvite(checked);
              }}
              isOpen={convertLeadModal}
              onClose={() => setConvertLeadModal(false)}
              onSave={(patientUuid) => {
                setConfirmationModalOpen(true);
                setPatientUuid(patientUuid);
                setActionType(CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER);
              }}
              personType={PERSON_TYPES.CUSTOMER}
              contactData={{singleLeadData: newContactData}}
              onCreatePatient={() => {
                setConfirmationModalOpen(true);
                setActionType(CONTACT_ACTION_CODES.CREATE_PATIENT);
              }}
            />
            <ConfirmationModal
              isModalOpen={confirmationModalOpen}
              onModalClose={() => setConfirmationModalOpen(false)}
              btnList={[
                {
                  btnText: 'Confirm',
                  onBtnPress: () => {
                    const formattedData = getFormDataFromLeadData(
                      selectedRowData,
                      mlovData
                    );
                    if (
                      actionType === CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER
                    ) {
                      handlePatientContactMap(formattedData, patientUuid);
                      handleConvertToCustomer(
                        formattedData.personTypeId,
                        PERSON_TYPES.CUSTOMER
                      );
                      navigateToPatientOmniView(formattedData);
                      sendPatientAppInvitation(formattedData);
                    } else if (
                      actionType === CONTACT_ACTION_CODES.CREATE_PATIENT
                    ) {
                      handleCreatePatientInEHR(formattedData);
                    }
                    setConfirmationModalOpen(false);
                    setConvertLeadModal(false);
                  },
                },
                {
                  btnText: 'Cancel',
                  onBtnPress: () => setConfirmationModalOpen(false),
                  btnColorScheme: 'dark',
                  btnTextStyle: {color: 'white'},
                },
              ]}
              actionType={actionType}
            />
          </>
        )}

      {selectedViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE && (
        <AddNoteView
          contactId={id}
          onFormActionPerformed={(actionCode: any) => {
            onPersonActionPerformed(actionCode);
          }}
        />
      )}
      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommon
          onMailCompleteAction={onPersonActionPerformed}
          contactData={contactData}
        />
      )}
      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
            if (actionData?.selectedInboxConversationData?.conversations?.[0]) {
              handleConversationSelect(
                COMMON_ACTION_CODES.ITEM_CLICKED,
                actionData?.selectedInboxConversationData?.conversations?.[0]
              );
            }
          }}
          selectedPatient={contactData}
        />
      )}
      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_OPEN_IN_EHR && (
        <ComingSoonModal
          isOpen={comingSoonModal}
          onModalClose={() => setComingSoonModal(false)}
          headerText="Info"
          bodyText="This will navigate you to underlying EHR in production environment."
        />
      )}
      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
        <MeetingView
          personData={formattedContactData}
          onPersonActionPerformed={onPersonActionPerformed}
        />
      )}
      {/* {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD && (
        <OverrideModal
          isOpen={resetPasswordModal}
          onClose={() => {
            setResetPasswordModal(false);
          }}
          onConfirm={() => {
            sendResetPasswordInstructions(contactData);
            setResetPasswordModal(false);
          }}
          textContent={{
            headerText: 'Confirmation',
            message: 'Do you want to send reset password instructions?',
          }}
        />
      )} */}

      {selectedViewCode.code === RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW && (
        <Drawer
          width={finalWidth}
          visible={true}
          closeIcon={null}
          closable
          onClose={() => {
            setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
          }}
          title={<></>}
          maskClosable={true}
        >
          <AutomationWorkflowEmployee
            type="patient"
            initData={{selectedItems: [selectedRowData]}}
            onModalClose={() => {
              setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
            }}
          />
        </Drawer>
      )}

      {selectedViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP && (
        <BookingWorkflow
          user={{
            uuid: userUUID,
            name: userFullName,
          }}
          isOpen={modalOpen}
          appointmentType={AppointmentType.bookAppointment}
          defaultParticipants={[
            {
              label: contactData.name,
              key: contactData.uuid,
              value: contactData.uuid,
              type: ParticipantType.patient,
            },
          ]}
          onClose={() => {
            showAppointmentBookingToast('Appointment booked successfully!');
            setSelectedViewCode((prev) => {
              return {
                ...prev,
                code: '',
              };
            });
            setModalOpen(!modalOpen);
            setRefetchPatientAppointment(!refetchPatientAppointment);
          }}
          onCancel={() => {
            setSelectedViewCode((prev) => {
              return {
                ...prev,
                code: '',
              };
            });
            setModalOpen(!modalOpen);
          }}
        />
      )}
      <ContactSendForm
        isVisible={
          selectedViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM
        }
        assignmentData={{
          patientId: formattedContactData.patientUuid || '',
          contactId: formattedContactData.contactUUID || '',
          assignedById: userUUID,
          patientEmail: formattedContactData.email || '',
          patientFirstName: formattedContactData.firstName || '',
          patientContactUUID: formattedContactData.contactUUID || '',
          contactTypeCode: formattedContactData?.contactType?.code || '',
        }}
        onActionComplete={() => {
          setViewCode('');
          updateForcefullyTabCode(MIDDLE_CONTAINER_TABS.FORM_RESPONSES);
        }}
        onClose={() => {
          setViewCode('');
        }}
      />
    {selectedViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK && (
      <AddOrUpdateTask
        saveBtnText={'Send'}
        successMessage={'taskAddedMsg'}
        assignee={{
          value: formattedContactData?.contactUUID,
          label: formattedContactData?.patientName,
          key: formattedContactData?.contactUUID,
          details: contactData,
          type:
            formattedContactData?.patientUuid || formattedContactData?.patientId
              ? ParticipantType.patient
              : ParticipantType.leads,
        }}
        isVisible={
          selectedViewCode.code === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK
        }
        onComplete={() => {
          setViewCode('');
          updateForcefullyTabCode(MIDDLE_CONTAINER_TABS.TASKS);
        }}
        onCancel={() => {
          setViewCode('');
        }}
      />
    )}
    </>
  );
};
