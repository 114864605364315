import { IConversationStatusChangedData } from './../components/RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/Interfaces';
import {assignConversationAPI, updateConversationStatusAPI} from '../components/RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationAPIService';
import {assignStatusValue} from '../components/RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {getBodyForAssignUser} from '../components/RightSideContainer/TeamInbox/Conversations/conversationUtils';
import {IConversationData} from '../components/RightSideContainer/TeamInbox/Conversations/interfaces';
import {CONVERSATION_BUS_ACTION_CODE} from '../constants';
import {CallBackArgs} from './interfaces';
import { readMessageEntriesInMessageLastSeen } from '../components/common/ChatUI/MessageBox/MessageReadInfo/MessageReadInfoService';

export class ConversationActionBus {
  private static instance: ConversationActionBus;
  private callback?: (args: {
    conversationActionCode: string;
    actionData: any;
  }) => void;
  private errorCallback?: (errorData: {
    conversationActionCode: string;
    actionData?: any;
    error?: any;
  }) => void;

  constructor() {
    if (ConversationActionBus.instance) {
      throw new Error('Use ConversationActionBus.eventBusInst instead of new.');
    }
    ConversationActionBus.instance = this;
  }
  static getConversationActionInstance(): ConversationActionBus {
    return ConversationActionBus.instance ?? (ConversationActionBus.instance = new ConversationActionBus());
  }

  public registerCallbacks(args: {
    callback?: (args: {
      conversationActionCode: string;
      actionData: any;
    }) => void;
    errorCallback?: (error: any) => void;
  }) {
    const {
      callback,
      errorCallback,
    } = args;
    if (callback) {
      this.callback = callback;
    }
    if (errorCallback) {
      this.errorCallback = errorCallback;
    }
  }

  public async onConversationAssigneeChanged(params: {
    userUuid?: string;
    conversationUuid: string;
    conversation: IConversationData
  }) {
    const body = getBodyForAssignUser(params?.userUuid, params?.conversationUuid)
    const assignedUserRes = await assignConversationAPI({
      body,
    }).catch((err: any) => {
      if (this.errorCallback) {
        this.errorCallback({
          conversationActionCode: CONVERSATION_BUS_ACTION_CODE.ASSIGNEE_CHANGED,
          actionData: {
            assignedUserRes: {},
            conversationData: params?.conversation
          },
          error: err
        });
      }
    });
    this?.callback?.({
      conversationActionCode: CONVERSATION_BUS_ACTION_CODE.ASSIGNEE_CHANGED,
      actionData: {
        assignedUserRes: assignedUserRes?.data,
        conversationData: params?.conversation
      }
    })

  }

  public async onConversationStatusChangedOnEvent(params: {
    status: number;
    conversation: IConversationData
  }) {
    const statusCode = (params?.status === assignStatusValue.ARCHIVED) ? 'RESOLVED' : 'OPEN'
    const updateConversationStatus = await updateConversationStatusAPI(statusCode, params.conversation?.uuid || '')
      .catch((err: any) => {
        if (this.errorCallback) {
          this.errorCallback({
            conversationActionCode: CONVERSATION_BUS_ACTION_CODE.CONVERSATION_STATUS_CHANGED,
            actionData: {
              updateConversationStatus: {},
              conversationData: params?.conversation
            },
            error: err
          });
        }
      });
      if (updateConversationStatus?.data) {
        this?.callback?.({
          conversationActionCode: CONVERSATION_BUS_ACTION_CODE.CONVERSATION_STATUS_CHANGED,
          actionData: {
            updateConversationStatus: updateConversationStatus?.data,
            conversationData: params?.conversation
          }
        });
      }
  }

  public removeCallback(args: {
    callback?: (args: {
      conversationActionCode: string;
      actionData: any;
    }) => void;
    errorCallback?: (error: any) => void;
  }) {
    const {
      callback,
      errorCallback,
    } = args;
    if (callback) {
      this.callback = undefined;
    }
    if (errorCallback) {
      this.errorCallback = undefined;
    }
  }

  public async onConversationReadOnEvent(params: {
    selectedConversation: IConversationData;
    selectedInboxTypeCode: string;
    abortSignal: AbortSignal
  }) {
    const {selectedConversation} = params;
    const conversationData = await readMessageEntriesInMessageLastSeen(
      selectedConversation?.uuid,
      params?.abortSignal
    ).catch((err: any) => {
      if (this.errorCallback) {
        this.errorCallback({
          conversationActionCode:
            CONVERSATION_BUS_ACTION_CODE.CONVERSATION_MSG_READ,
          actionData: {
            selectedInboxTypeCode: params?.selectedInboxTypeCode,
            conversationData: params?.selectedConversation,
          },
          error: err,
        });
      }
    });
    if (conversationData) {
      this?.callback?.({
        conversationActionCode:
          CONVERSATION_BUS_ACTION_CODE.CONVERSATION_MSG_READ,
        actionData: {
          selectedInboxTypeCode: params?.selectedInboxTypeCode,
          conversationData: params?.selectedConversation,
        },
      });
    }
  }
}
