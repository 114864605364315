import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';
export const styles = StyleSheet.create({
  tabsWrapper: {
    // marginHorizontal: 14,
  },
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
    backgroundColor: '#fff',
  },
  centerAlign: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderWidth: 1,
    borderColor: Colors.Custom.BorderColor,
    margin: 12,
    borderRadius: 16,
  },
  topContainer: {
    backgroundColor: 'white',
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  right: {
    justifyContent: 'flex-end',
    marginRight: 30,
  },
  leftSteps: {
    padding: 20,
  },
  rightInbox: {
    marginLeft: 8,
    borderLeftWidth: 1,
    padding: 20,
    borderColor: Colors.Custom.BorderColor,
  },
  titleCancel: {
    justifyContent: 'flex-end',
  },
  titleBar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 15,
  },
  titleBarEnd: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 15,
  },

  headerText: {
    fontSize: 24,
  },

  box: {
    borderRadius: 8,
    width: 200,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
  },

  boxStack: {
    marginTop: '1vh',
    justifyContent: 'center',
    alignSelf: 'center',
  },

  screenTwo: {
    maxWidth: 400,
  },

  screenTwoColor: {
    width: 140,
    marginTop: -10,
    marginBottom: -10,
  },

  emailButton: {
    maxWidth: 175,
  },

  websiteButton: {
    maxWidth: 150,
  },

  agentButton: {
    maxWidth: 100,
  },

  agentBotButton: {
    maxWidth: 140,
  },

  leftPad: {
    paddingLeft: 10,
  },

  leftPadLarge: {
    paddingLeft: 70,
  },

  edit: {
    padding: 10,
    marginTop: 20,
  },

  editSettings: {
    paddingTop: 20,
    paddingBottom: 20,
    padding: 30,
  },

  boxResult: {
    borderRadius: 8,
    backgroundColor: 'gray.100',
    width: 480,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 50,
  },

  boxResultSmall: {
    borderRadius: 8,
    backgroundColor: Colors.muted[300],
    width: 450,
    height: 320,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 20,
  },

  preChat: {
    padding: 30,
    width: '50vw',
  },

  checkBox: {
    marginTop: 20,
  },

  configLeft: {
    width: '40%',
    padding: 20,
  },

  leftBlank: {
    width: '40%',
  },

  boxCodeSmall: {
    borderRadius: 8,
    backgroundColor: Colors.muted[300],
    width: 'auto',
    padding: 20,
    height: 50,
    justifyContent: 'center',
    marginTop: 30,
  },

  checkBoxBottom: {
    justifyContent: 'center',
    padding: 20,
  },

  imapSmtp: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  spaceAbove: {
    marginTop: 15,
  },

  channelBox: {
    borderRightWidth: 0,
    width: 200,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  channelBoxEnd: {
    width: 200,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  channelBoxBottomEnd: {
    width: 200,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  agents: {
    marginTop: 20,
  },
  agentBots: {
    marginTop: 20,
  },

  businessHourButton: {
    maxWidth: 250,
  },

  businessRight: {
    padding: 30,
  },

  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 5,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 5,
  },
});

export const spinnerStype = StyleSheet.create({
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 100011111,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
});
