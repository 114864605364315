import { useLazyQuery } from '@apollo/client';
import { Button, HStack, Pressable, Skeleton, View, Text, Tooltip, Divider } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { NodeTypes } from 'reactflow';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppInfoQueries from '../../../../../services/AppInfo/AppInfoQueries';
import { Colors } from '../../../../../styles';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { ConditionState, GroupState, LambdaState, UserActionState } from '../../FlowComponent/StateNodes';
import AddOrUpdateWorkflow from './AddOrUpdateWorkflow';
import { getNodeLevelResourceLogByBoolExp } from './WorkflowApi';
import WorkflowAuditFilter from './WorkflowAuditFilter';
import Feather from 'react-native-vector-icons/Feather';
const proOption = {
  account: 'paid-pro',
  hideAttribution: true,
}

const nodeTypes: NodeTypes = {
  group: GroupState,
  Task: LambdaState,
  Map: LambdaState,
  Pass: LambdaState,
  If: LambdaState,
  Choice: UserActionState,
  Condition: ConditionState,
  MapSubflow: LambdaState,
  Parallel: LambdaState,
  Option: LambdaState,
};






const WorkflowAuditGraph = () => {
  const navigate = useNavigate();
  const [workflowData, setWorkflowData] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams();

  const [workflowExecutionId] = useState(searchParams.get('workflowExecutionId') || '');
  const [searchString, setSearchString] = useState('')
  const { workflowId } = useParams();
  const [isLoading, setLoading] = useState(false)
  const accountUUID = getAccountUUID();
  const [contactList, setContactList] = useState([])
  const [getContactByContactIdList] = useLazyQuery<any>(
    AppInfoQueries.GET_CONTACT_NAME_BY_CONTACT_ID_LIST
  );

  const isStringInteger = (value: string): boolean => {
    // Use parseInt to attempt to convert the string to an integer
    const intValue = parseInt(value, 10);
  
    // Check if the result is a valid number and if it is the same as the original string
    return !isNaN(intValue) && value === String(intValue);
  }
  
  const updateSearchResult = (isShowLoading:boolean)=>{
    if(isShowLoading){
      setLoading(true)
    }
    const resourceLevelLogBoolExp: any = {
      resourceType: {
        _eq: "CONTACT"
      },
      tenantId: {
        _eq: accountUUID
      }
    }
    if (workflowId) {
      resourceLevelLogBoolExp.workflowExecutionLog = {
        workflowMasterId: {
          _eq: workflowId
        }
      }
    }

    if (workflowExecutionId) {
      resourceLevelLogBoolExp.workflowExecutionId = {
        _eq: workflowExecutionId
      }
    } else {
      resourceLevelLogBoolExp.workflowExecutionId = {
        _is_null: false
      }
    }

    

    getNodeLevelResourceLogByBoolExp(resourceLevelLogBoolExp).then((response: any) => {
      const contactIdList = (response?.resourceLevelLogs || []).map((resourceLevelLog: any) => {
        return resourceLevelLog.resourceId
      }).filter((contactId: any) => {
        return contactId
      });
      getContactByContactIdList({
        variables: {
          contactIdList:  (contactIdList||[]).filter((value:any, index:number, self:any) => {
            return self.indexOf(value) === index &&  isStringInteger(value);
          }),
          searchString: '%'+(searchString||'')+'%'
        }
      }).then((contactResponse) => {
        const contactList = (contactResponse?.data?.contacts.map((contact: any) => {
          return { ...contact, id: JSON.stringify(contact.id) }
        }) || [])
        setContactList(contactList)
        if(isShowLoading){
          setLoading(false)
        }
      })
    })
  }
  
  
  
  useEffect(() => {
    updateSearchResult(true)
  }, [workflowExecutionId, accountUUID, workflowId])


  useEffect(() => {
    updateSearchResult(false)
  }, [searchString])

  

  const customHeader = 
  <View backgroundColor={'white'} paddingTop={2}>
    <TitleSubtitleView  

      titleLabelId={workflowData?.name ? "Workflow Audit | " + (workflowData?.name) : "Workflow Audit"}
      actionContainer={
        <View style={{ marginRight: 25 }}>

        </View>
      }
    />
      <HStack alignItems={'center'} flex={8} justifyContent={'space-between'} paddingX={4} paddingY={2}>
        <HStack>
          <Tooltip label={workflowData?.name || ''} maxWidth={520} placement='bottom left'>
            <Text color={Colors.FoldPixel.GRAY400} fontSize={20} fontWeight={600} numberOfLines={1} >{workflowData?.name}</Text>
          </Tooltip>
        </HStack>
        <HStack justifyContent={'flex-end'} alignItems={'center'}>
          <Pressable
            onPress={() => {
              const window: any = global.window;
              const stackHistory = window?.navigation?.entries();
              if (stackHistory && stackHistory.length > 0) {
                let count = 0;
                for (let index = stackHistory.length - 1; index >= 0; index--) {
                  const url = stackHistory[index].url;
                  if (url?.indexOf('/execution/audit') !== -1) {
                    count++;
                  } else {
                    break;
                  }
                }
                navigate(-count);
              } else {
                navigate('/admin/workflow/all');
              }
            }}
            
          >
            <View justifyContent={'center'} height={'36px'} >
              <Feather
                name='x'
                size={22}
                color={Colors.FoldPixel.GRAY300}
              />
            </View>
          </Pressable>
        </HStack>
      </HStack>
       <Divider></Divider>
    <HStack paddingY={2}>
     
      
      {isLoading ? <Skeleton.Text lines={1} /> :
        <WorkflowAuditFilter setSearchString={setSearchString} isShowSmsStatus={true} isShowEmailStatus={true} contactList={contactList}></WorkflowAuditFilter>
      }
        <HStack justifyContent={'flex-end'} alignItems={'center'} flex={4} paddingX={4} space={4}>
          <Tooltip label={'This indicates that the node is successfully executed'}>
            <HStack space={1} alignItems={'center'}><Pressable borderRadius={'50%'} width={4} height={4} borderWidth={1} borderColor={Colors.FoldPixel.STATUS_DARK_SUCCESS} backgroundColor={Colors.FoldPixel.STATUS_DARK_SUCCESS}></Pressable>Success</HStack>
          </Tooltip>
          <Tooltip label={'This indicates that the node had an error in execution'}>
            <HStack space={1} alignItems={'center'}><Pressable borderRadius={'50%'} width={4} height={4} borderWidth={1} borderColor={Colors.FoldPixel.STATUS_ERROR} backgroundColor={Colors.FoldPixel.STATUS_ERROR}></Pressable>Error</HStack>
          </Tooltip>
          <Tooltip label={'This indicates the node is in progress and not completed yet'}>
            <HStack space={1} alignItems={'center'}><Pressable borderRadius={'50%'} width={4} height={4} borderWidth={1} borderColor={Colors.FoldPixel.STATUS_IN_PROGRESS} backgroundColor={Colors.FoldPixel.STATUS_IN_PROGRESS}></Pressable>In-progress</HStack>
          </Tooltip>
          <Tooltip label={'This indicates that the node is unexecuted, possibly due to unmatched conditions'}>
            <HStack space={1} alignItems={'center'}><Pressable borderRadius={'50%'} width={4} height={4} borderWidth={1} borderColor={Colors.FoldPixel.GRAY200} backgroundColor={Colors.FoldPixel.GRAY200}></Pressable>Unexecuted</HStack>
          </Tooltip>
        </HStack>
    </HStack>
  </View>
  const parentElemRef = useRef<any>(null);
  return (
    <View width="full" flex={1} overflow={'scroll'}>
        <AddOrUpdateWorkflow 
          // height={parentElemRef?.current?.offsetHeight || 750} 
          isViewOnly={true} 
          onWorkflowNameChange={
          (name: string) => {
            setWorkflowData({ name: name })
          }
        } customHeader={customHeader} />
    </View>
  );
};

export default WorkflowAuditGraph;
