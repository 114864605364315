import { Dimensions, View } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { ITaskDisplayConfig, MultiSelectAction, TaskViewType } from '../TaskInterfaces';
import { getDateStrFromFormat } from '../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../constants';
import { getUserFullName, getUserUUID, isEmployerRole } from '../../../utils/commonUtils';
import CareDashboardTopBar, { ITopBarData } from '../../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import { TASK_TABS } from '../../common/CareDashboard/CareDashboardConstants';
import { getCareStudioMlovListByCategoryCode, getMlovId, getMlovListFromCategory, getMlovObjectFromCode, getMlovValue } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ICategoryData, ILabelTask, ITask } from '../../common/CareDashboard/CareDashboardInterfaces';
import { BoardType, ITabsList, TaskFilterType } from '../../common/CareDashboard/CareDashboardTopBar/interfaces';
import { IAllUserPoolSelect, IUserPool, IUserPoolUser, IUserSearch } from '../../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import UserAutoCompleteMultiSelect from '../../common/CalendarWidget/UserAutoComplete/UserAutoCompleteMultiSelect';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { IMlov } from '../../../Interfaces';
import { LABEL_TYPE_CODES, MLOV_CATEGORY, TASK_STATUS } from '../../../constants/MlovConst';
import { useLocation } from 'react-router-dom';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CreateOrUpdateUserPreference, GetUserPreferencesByTypeId } from '../../../services/UserPreferences/UserPreferencesQueries';
import useTaskCountManager from '../../common/CareDashboard/CustomHook/useTaskCountManager';
import { GET_TASK_LABEL_BATCH_COUNT } from '../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { TaskQueries } from '../../../services';
import TaskPoolQueries, { GetUsersTaskPoolAccess } from '../../../services/TaskPool/TaskPoolQueries';
import { getCategoriesFromBoardType, getLabelForAssigneeField, getSkipCallForTaskCount, getTabTitle, getUserTasksDefaultParams } from '../TaskModuleHelper';
import TaskMainView from '../TaskModuleComponents/TaskMainView';
import { getTaskFilteredCategoryData } from '../../common/Tasks/TasksUtils/TasksUtils';
import useGetBatchedAccountUsers from '../../CustomHooks/useGetBatchedAccountUsers';
import { Spinner } from 'native-base';
import { CareDashboardFilterInfo } from '../../common/CareDashboard/CareDashboardFilterInfo';
import MultiSelectView from '../TaskModuleComponents/MultiSelectView/MultiSelectView';
import useMultiTaskManager from '../CustomHooks/useMultiTaskManager';
import Stack from '../../common/LayoutComponents/Stack';
import useGetDefaultUserTaskManagerData from '../CustomHooks/useGetDefaultUserTaskManagerData';
import { cloneDeep } from 'lodash';
// import useMemoryMonitor from '../../CustomHooks/useMemoryMonitor';

interface UserTaskManagerProps {
  shouldNotNavigateToTabsPath?: boolean;
}

const UserTaskManager = (props: UserTaskManagerProps) => {
  // Global constants
  // useMemoryMonitor();
  const commonData = useContext(CommonDataContext);
  const location = useLocation();
  const currentUserUUID = getUserUUID();
  const currentLoggedInUser = {
    key: currentUserUUID,
    value: currentUserUUID,
    label: getUserFullName(),
  };
  const allDueDateUuid = getMlovId(commonData.CARE_STUDIO_MLOV, 'TaskDueDateCategory', 'all');
  const allDueDateValue = getMlovValue(commonData.CARE_STUDIO_MLOV, 'TaskDueDateCategory', 'all');
  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS);
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);
  const userPreferencesTypeId = getMlovId(commonData.CARE_STUDIO_MLOV, 'UserPreferencesType', 'user_task_filters');
  const accessUserTypeId = getMlovId(commonData.CARE_STUDIO_MLOV, 'UserPoolType', 'access_user_task');
  const taskPoolTypeId = getMlovId(commonData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');
  const labelTypeList = getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.LABEL_TYPE);
  const isEmployer = isEmployerRole();
  const pathList = location?.pathname?.split('/') || [];
  const lastPathStr = pathList[pathList?.length - 1];
  const screenHeight = Dimensions.get('window').height;
  const isSidecarContext = commonData.sidecarContext?.isSidecar;

  // States
  const [displayConfig, setDisplayConfig] = useState<ITaskDisplayConfig>({
    boardType: BoardType.Status,
    viewType: TaskViewType.list,
    categories: [],
  });
  const [isShowFilterView, setIsShowFilterView] = useState(false);
  const [userPool, setUserPool] = useState<IUserPool[]>([]);
  const [loading, setLoading] = useState(false);
  const [allUsersPool, setAllUserPool] = useState<IUserPool[]>([]);
  const [masterUsersPoolList, setMasterUsersPoolList] = useState<IUserPool[]>([]);
  // for all user's pool (all users means logged in user and his managed users)
  const [userPoolSelect, setAllUserPoolSelect] = useState<IAllUserPoolSelect[]>([]);
  const [searchString, setSearchString] = useState('');
  const [isUserPoolRefetch, setIsUserPoolRefetch] = useState(false);
  const [taskTabs, setTaskTabs] = useState<ITabsList[]>([
    {
      key: TASK_TABS.ASSIGNED,
      path: TASK_TABS.ASSIGNED,
      title: 'Assigned To Me',
    },
    {
      key: TASK_TABS.MENTIONED,
      title: 'Mentions',
      path: TASK_TABS.MENTIONED,
    },
    ...(!isEmployer
      ? [
          {
            key: TASK_TABS.TASK_POOL,
            title: 'My Task Pool',
            path: TASK_TABS.TASK_POOL,
          },
        ]
      : []),
    {
      key: TASK_TABS.CREATED,
      title: 'Created By Me',
      path: TASK_TABS.CREATED,
    },
  ]);
  const [taskTabsFilters, setTaskTabsFilters] = useState<ICategoryData[]>([]);
  const { taskCount } = useTaskCountManager({
    params: taskTabsFilters,
  });
  const [topBarData, setTopBarData] = useState<ITopBarData>({
    start: '',
    end: '',
    addTask: false,
    addTaskCategoryId: '',
    addTaskTeamName: '',
    addTaskTeamId: '',
    selectedUsers: currentLoggedInUser ? [currentLoggedInUser] : [],
    createdByIds: currentLoggedInUser ? [currentLoggedInUser] : [],
    mentionUserIds: currentLoggedInUser ? [currentLoggedInUser] : [],
    isTableView: true,
    selectedPriorityList: [],
    selectedStatusList: [],
    selectedDueDateUuid: allDueDateUuid ? allDueDateUuid.split(" ") : [],
    selectedDueDateType: allDueDateValue ? [allDueDateValue] : [],
    selectedContactUuid: [],
    selectedPatientLabel: [],
    selectedPatientName: [],
    selectedMentionsSortUuid: 'mentionedDateTime',
    selectedMentionsSortType: 'mentionedDateTime',
    selectedSortUuid: 'endDateTime',
    selectedSortType: 'endDateTime',
    selectedMentionsSortValueAscending: false,
    selectedSortValueAscending: false,
    selectedTab: taskTabs.find((item) => item.path === lastPathStr)?.key || taskTabs[0].key,
    userPreferenceId: '',
    selectedPools: userPool ? userPool : [],
    selectedBoardType: BoardType.Status,
    labels: [] as ILabelTask[],
    showChangeFilterMessage: true,
    showFilterSameAcrossTabMessage: true,
    isPreferenceDataLoading: true,
    taskAnalyticsIntroTooltip: false
  });

  const {
    loading: batchedUserLoading,
    userList,
  } = useGetBatchedAccountUsers();
  const { multiSelectData, handleMultiSelectActions } = useMultiTaskManager({});
  const { loading: defaultDataLoading, allUserPoolsWithAccess } = useGetDefaultUserTaskManagerData({
    shouldFetch: isSidecarContext || false,
    accountUsers: userList,
  });

  // Lifecycle methods
  useEffect(() => {
    const activeTabKey = taskTabs.find((item) => item.path === lastPathStr)?.key || taskTabs[0].key;
    let updatedTopBarDataWithPrevState = topBarData as ITopBarData;
    if (activeTabKey !== topBarData.selectedTab) {
      setTopBarData((prev) => {
        updatedTopBarDataWithPrevState = {
          ...prev,
          selectedTab: activeTabKey
        }
        return updatedTopBarDataWithPrevState;
      });
      updateCategoriesWithFilters(updatedTopBarDataWithPrevState);
    }
  }, [lastPathStr]);

  useEffect(() => {
    const isCurrentUserSelected = topBarData?.selectedUsers?.length === 1 ? currentUserUUID === topBarData?.selectedUsers[0]?.key : false;

    setTaskTabs((prevTabs) => {
      return prevTabs.map((item) => {
        const title = getTabTitle(item.key, isCurrentUserSelected);
        return {
          ...item,
          title,
          count: taskCount.find(countData => item.key === countData.code)?.count || 0
        }
      })
    })
  }, [taskCount]);

  useEffect(() => {
    if (!defaultDataLoading && isSidecarContext) {
      setAllUserPool(allUserPoolsWithAccess);
      updateCategoriesWithFilters(topBarData, allUserPoolsWithAccess);
    }
  }, [defaultDataLoading])

  // APIs
  const [ addUpdateUserFilterPreferenceAPI ] = useMutation(CreateOrUpdateUserPreference,
    { context: {service: CARESTUDIO_APOLLO_CONTEXT} }
  );

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [getUserTaskPoolAccess] = useLazyQuery(GetUsersTaskPoolAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const getTaskPoolQuery = useQuery(TaskPoolQueries.GetTaskPool, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    variables: { params: { userPoolTypeId: taskPoolTypeId } },
    onCompleted: (data: any) => {
      if (data?.getUserPools?.userPools?.length) {
        setMasterUsersPoolList(data?.getUserPools?.userPools);
      }
    },
    onError: (error: any) => {
    },
  });

  useQuery(GetUserPreferencesByTypeId, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    variables: {
      userId: currentUserUUID,
      userPreferencesTypeId: userPreferencesTypeId,
    },
    onCompleted: async (data: any) => {
      if (!data?.userPreferences?.length) {
        return;
      }
      const selectedUserPreference = data.userPreferences[0];
      const selectedFilterPreference = JSON.parse(
        selectedUserPreference['preferencesJson']
      );
      let selectedUsers: IUserSearch[] = selectedFilterPreference?.selectedUsers;
      selectedUsers = await filterSelectedUsers(selectedUsers);
      let taskAnalyticsIntroTooltipValue;
      if (
        selectedFilterPreference.hasOwnProperty('taskAnalyticsIntroTooltip')
      ) {
        taskAnalyticsIntroTooltipValue =
          selectedFilterPreference?.taskAnalyticsIntroTooltip;
      } else {
        taskAnalyticsIntroTooltipValue = true;
      }

      let preferenceData = {
        userPreferenceId: selectedUserPreference.id,
        start: selectedFilterPreference.start,
        end: selectedFilterPreference.end,
        selectedDueDateType: selectedFilterPreference.selectedDueDate,
        selectedDueDateUuid: Array.isArray(selectedFilterPreference.selectedDueDateUuid) ? selectedFilterPreference.selectedDueDateUuid : selectedFilterPreference.selectedDueDateUuid.split(" "),
        selectedPatientName: selectedFilterPreference.selectedPatientName,
        selectedContactUuid: selectedFilterPreference.selectedContactUuid,
        selectedPriorityList: selectedFilterPreference.selectedPriorityList || [],
        selectedStatusList: selectedFilterPreference.selectedStatusList || [],
        selectedPools: selectedFilterPreference.selectedPools || [],
        selectedSortUuid: selectedFilterPreference.selectedSortUuid,
        selectedSortType: selectedFilterPreference.selectedSortType,
        selectedMentionsSortUuid: selectedFilterPreference.selectedMentionsSortUuid,
        selectedMentionsSortType: selectedFilterPreference.selectedMentionsSortType,
        selectedMentionsSortValueAscending: selectedFilterPreference.selectedMentionsSortValueAscending,
        selectedSortValueAscending: selectedFilterPreference.selectedSortValueAscending,
        isTableView: isSidecarContext ? true : (selectedFilterPreference?.isTableView ?? true),
        labels: [] as ILabelTask[],
        selectedBoardType: selectedFilterPreference.selectedBoardType || BoardType.Status,
        selectedUsers: selectedUsers || [currentLoggedInUser],
        showChangeFilterMessage: selectedFilterPreference?.showChangeFilterMessage === false ? false : true,
        showFilterSameAcrossTabMessage: selectedFilterPreference?.showFilterSameAcrossTabMessage === false ? false : true,
        isPreferenceDataLoading: false,
        taskAnalyticsIntroTooltip: taskAnalyticsIntroTooltipValue,
        bulkTaskIntroCardDismissed: selectedFilterPreference?.bulkTaskIntroCardDismissed,
      }
      // fetch label data
      let updatedTopBarDataWithPrevState = {} as ITopBarData;
      if (selectedFilterPreference?.labels?.length) {
        const allLabelsIdLists = selectedFilterPreference?.labels;
        if (allLabelsIdLists?.length) {
          const batchSize = GET_TASK_LABEL_BATCH_COUNT;
          let batches = [] as ILabelTask[];
          for (let i = 0; i < allLabelsIdLists.length; i += batchSize) {
            const batchIds = allLabelsIdLists.slice(i, i + batchSize);
            const getLabelsDataRes = await fetchLabelsForBatch(batchIds);
            batches = [...batches, ...getLabelsDataRes];
          }

          Promise.all(batches)
            .then((results) => {
              preferenceData = {
                ...preferenceData,
                labels: results || ([] as ILabelTask[]),
              };
              setTopBarData((prevState) => {
                updatedTopBarDataWithPrevState = {
                  ...prevState,
                  ...preferenceData,
                }
                return updatedTopBarDataWithPrevState;
              });
            })
            .catch((error) => {

            });
        }
      } else {
        setTopBarData((prevState) => {
          updatedTopBarDataWithPrevState = {
            ...prevState,
            ...preferenceData,
          }
          return updatedTopBarDataWithPrevState;
        });
      }
      return;
    },
    onError: () => {
      setTopBarData((prev)=>{
        return {
          ...prev,
          isPreferenceDataLoading: false
        }
      })
    },
  });


  // Other methods
  const updateCategoriesWithFilters = (
    updatedTopBarData: ITopBarData,
    updatedAllUsersPools?: IUserPool[],
    params?: {
      searchString: string;
    }
  ) => {
    updateTabsWithFilters(updatedTopBarData, updatedAllUsersPools, params);
    updateViewCategoriesWithFilters(updatedTopBarData, updatedAllUsersPools, params);
  }

  const updateTabsWithFilters = (
    updatedTopBarData: ITopBarData,
    updatedAllUsersPools?: IUserPool[],
    metadata?: {
      searchString: string;
    }
  ) => {
    const categoryData: ICategoryData[] = [];

    taskTabs.forEach((tab) => {
      const params = getUserTasksDefaultParams({
        selectedTab: tab.key,
        topBarData: updatedTopBarData,
        taskStatusList: taskStatusMlovs,
        taskPriorityList: taskPriorityMlovs,
        defaultUserId: currentUserUUID,
        allUsersPool: updatedAllUsersPools || allUsersPool,
        searchString: metadata?.searchString ?? searchString,
      });
      categoryData.push({
        key: categoryData.length - 1,
        name: tab.key,
        code: tab.key,
        value: tab.key,
        filters: params,
        skipCall: getSkipCallForTaskCount(tab, params)
      });
    });

    setTaskTabsFilters([...categoryData]);
  }

  const updateViewCategoriesWithFilters = (
    updatedTopBarData: ITopBarData,
    updatedAllUsersPools?: IUserPool[],
    metadata?: {
      searchString: string;
    }
  ) => {
    const currentBoardType = updatedTopBarData.selectedTab === TASK_TABS.TASK_POOL ?
    BoardType.taskPool :
    (updatedTopBarData.selectedTab === TASK_TABS.ASSIGNED ? (updatedTopBarData.selectedBoardType || BoardType.Status) : undefined);
    const currentViewType = ((updatedTopBarData.isTableView ?? true) || [TASK_TABS.TASK_POOL, TASK_TABS.CREATED, TASK_TABS.MENTIONED].includes(updatedTopBarData.selectedTab || '')) ? TaskViewType.list : TaskViewType.kanban;
    const categories = getCategoriesFromBoardType(currentBoardType, updatedTopBarData.selectedTab, updatedAllUsersPools ||allUsersPool);

    const filterCodes = currentBoardType ? getTaskFilteredCategoryData(categories, updatedTopBarData, 'code', currentBoardType) || [] : [];
    const filteredCategories = filterCodes.length > 0 ? categories.filter(category => filterCodes.includes(category.code)) : categories;

    setDisplayConfig((prev) => {
      return {
        ...prev,
        boardType: currentBoardType,
        viewType: isSidecarContext ? TaskViewType.list : currentViewType,
        categories: filteredCategories.map((item) => {
          const params = getUserTasksDefaultParams({
            selectedTab: updatedTopBarData.selectedTab || '',
            topBarData: updatedTopBarData,
            taskStatusList: taskStatusMlovs,
            taskPriorityList: taskPriorityMlovs,
            defaultUserId: currentUserUUID,
            allUsersPool: updatedAllUsersPools || allUsersPool,
            searchString: metadata?.searchString ?? searchString,
            boardType: currentBoardType,
            categoryCode: item.code,
          });
          return {
            key: item.code,
            code: item.code,
            name: item.name,
            filters: params
          }
        })
      }
    })
  }

  async function fetchLabelsForBatch(batchIds: string[]) {
    const filteredResult = labelTypeList.filter((item) => {
      return item.code === LABEL_TYPE_CODES.TASK;
    });
    const mlov = filteredResult?.[0];
    if (!mlov) {
      return;
    }
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: batchIds,
        labelTypeId: mlov.id,
      },
    });
    return getLabelsRes?.data?.labels || [] as ILabelTask[];
  }

  const filterSelectedUsers = async (
    selectedUsers: IUserSearch[] | undefined
  ) => {
    try {
      const userPoolAccessData = await getUserTaskPoolAccess({
        variables: {
          userId: currentUserUUID,
          userPoolTypeId: accessUserTypeId,
        },
      });
      if (userPoolAccessData?.data?.userPoolAccesses?.length) {
        const userPoolAccessUsersData: IUserPoolUser[] =
          userPoolAccessData.data.userPoolAccesses[0].user_pool
            ?.userPoolUsers;
        const userPoolAccessUserIds = userPoolAccessUsersData?.map(
          (user) => user?.userId
        );
        const updatedSelectedUsers = selectedUsers?.filter((user) => {
          return (
            userPoolAccessUserIds.includes(user?.key) ||
            user?.key === currentUserUUID ||
            user.key === 'null'
          );
        });
        selectedUsers = updatedSelectedUsers?.length
          ? updatedSelectedUsers
          : [currentLoggedInUser];
      }
    } catch (error) {
    }

    return selectedUsers || [];
  };

  const getFilterCount = () => {
    let count = 0;
    topBarData.selectedStatusList?.length && count++;
    topBarData.selectedDueDateUuid?.length && count++;
    topBarData.selectedPriorityList?.length && count++;
    topBarData.selectedContactUuid?.length && count++;
    topBarData.selectedPools?.length && count++;
    topBarData.labels?.length && count++;
    return count;
  }

  const getHeightOffset = () => {
    if (!isSidecarContext) {
      return 200;
    }
    return 96;
  }

  const onActionPerformed = (tabCode: string, rawData: any) => {
    if(tabCode == COMMON_ACTION_CODES.SEARCH ){
      if(searchString !=rawData){
        setSearchString(rawData);
        updateCategoriesWithFilters(topBarData, undefined, { searchString: rawData });
      }
      return;
    }
    // setLoading(true);
    let updatedTopBarData = {};
    let needUpdateCategoriesWithFilters = false;
    let allUsersPoolsFromFilters: IUserPool[];

    switch (tabCode) {
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        updatedTopBarData = {
          selectedStatusList: rawData.selectedStatusList,
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.DUE_DATE_CHANGED:
        updatedTopBarData = {
          selectedDueDateUuid: rawData.dueDateTypeUuid,
          selectedDueDateType: rawData.dueDateType,
        };
        needUpdateCategoriesWithFilters = true;
        break;
      case COMMON_ACTION_CODES.PRIORITY_CHANGED:
        updatedTopBarData = {
          selectedPriorityList: rawData.selectedPriorityList,
        };
        needUpdateCategoriesWithFilters = true;
        break;
      case COMMON_ACTION_CODES.SORT_CHANGED:
        if(topBarData?.selectedTab === TASK_TABS.MENTIONED){
          updatedTopBarData = {
            selectedMentionsSortUuid:rawData.sortTypeUuid,
            selectedMentionsSortType: rawData.sortType,
            selectedMentionsSortValueAscending: !topBarData.selectedMentionsSortValueAscending,
          };
        }
        else{
          updatedTopBarData = {
            selectedSortUuid:rawData.sortTypeUuid,
            selectedSortType: rawData.sortType,
            selectedSortValueAscending: !topBarData.selectedSortValueAscending,
          };
          needUpdateCategoriesWithFilters = true;
        }
        break;
      case COMMON_ACTION_CODES.RESET:
        updatedTopBarData = {
          selectedSortUuid: 'none',
          selectedSortType: undefined,
          selectedPriorityList: [],
          selectedStatusList: [],
          selectedDueDateUuid: allDueDateUuid,
          selectedDueDateType: allDueDateValue,
          selectedSortValueAscending: true,
          start: '',
          end: '',
          selectedContactUuid: [],
          selectedPatientName: [],
          selectedPatientLabel: [],
          selectedUsers: [currentLoggedInUser],
          createdByIds: [currentLoggedInUser],
          mentionUserIds: [currentLoggedInUser],
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.CANCEL:
        updatedTopBarData = {
          selectedSortUuid: 'none',
          selectedSortType: undefined,
          selectedPriorityList: [],
          selectedStatusList: [],
          selectedDueDateUuid: allDueDateUuid,
          selectedDueDateType: allDueDateValue,
          selectedSortValueAscending: true,
          start: '',
          end: '',
          selectedContactUuid: [],
          selectedPatientName: [],
          selectedPatientLabel: [],
          labels: [],
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.CLEAR_ALL:
        updatedTopBarData = {
          selectedPriorityList: [],
          selectedStatusList: [],
          selectedDueDateUuid: allDueDateUuid,
          selectedDueDateType: undefined,
          selectedContactUuid: [],
          selectedPatientName: [],
          selectedPatientLabel: [],
          selectedPools: [],
          labels: [],
          selectedUsers: [currentLoggedInUser],
          createdByIds: [currentLoggedInUser],
          mentionUserIds: [currentLoggedInUser],
          start: '',
          end: '',
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.SAVE:
        if (rawData.tempAllUserPool) {
          allUsersPoolsFromFilters = rawData.tempAllUserPool;
          setAllUserPool(rawData.tempAllUserPool);
        }
        if (rawData.tempAllUserPoolSelect) {
          setAllUserPoolSelect(rawData.tempAllUserPoolSelect);
        }
        if (rawData.tempUserPool) {
          setUserPool(rawData.tempUserPool);
        }
        const isCreatedTabSelected = topBarData.selectedTab === TASK_TABS.CREATED;
        const isMentionTabSelected = topBarData.selectedTab === TASK_TABS.MENTIONED;

        updatedTopBarData = {
          ...( isCreatedTabSelected  && { createdByIds: rawData.createdByIds } ),
          ...( isMentionTabSelected  && { mentionUserIds: rawData.mentionUserIds } ),
          selectedPriorityList: rawData.selectedPriorityList,
          selectedStatusList: rawData.selectedStatusList,
          selectedDueDateUuid: rawData.selectedDueDateUuid,
          selectedDueDateType: rawData.selectedDueDate,
          start: rawData.start,
          end: rawData.end,
          selectedContactUuid: rawData.selectedContactUuid,
          selectedPatientName: rawData.selectedPatientName,
          selectedPatientLabel: rawData.selectedPatientLabel,
          selectedPools: rawData.selectedPools,
          labels: rawData?.selectedLabels,
          selectedUsers: rawData?.selectedUsers || topBarData?.selectedUsers,
          showChangeFilterMessage: rawData?.showChangeFilterMessage || topBarData?.showChangeFilterMessage,
          showFilterSameAcrossTabMessage: rawData?.showFilterSameAcrossTabMessage || topBarData?.showFilterSameAcrossTabMessage,
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.CHANGE_TABLE_FILTER_SORTER:
        updatedTopBarData = {};
        const prevStatusCodes = topBarData.selectedStatusList?.map((mlov) => mlov.code) || [];
        const currentStatusCodes = rawData?.filters?.status || [];

        if (JSON.stringify(prevStatusCodes) !== JSON.stringify(currentStatusCodes)) {
          const selectedStatusList: IMlov[] = [];
          currentStatusCodes.forEach((mlovCode: string) => {
            const mlov = getMlovObjectFromCode(mlovCode, taskStatusMlovs);
            if (mlov) {
              if (mlov.code === TASK_STATUS.ACCEPTED) {
                selectedStatusList.push({ ...mlov, value: 'Not Completed'} as IMlov);
              } else {
                selectedStatusList.push(mlov);
              }
            }
          });

          (updatedTopBarData as any).selectedStatusList = selectedStatusList;
        }

        const prevPriorityCodes = topBarData.selectedPriorityList?.map((mlov) => mlov.code) || [];
        const currentPriorityCodes = rawData?.filters?.priority || [];

        if (JSON.stringify(prevPriorityCodes) !== JSON.stringify(currentPriorityCodes)) {
          const selectedPriorityList: IMlov[] = [];
          currentPriorityCodes.forEach((mlovCode: string) => {
            const mlov = getMlovObjectFromCode(mlovCode, taskPriorityMlovs);
            if (mlov) {
              selectedPriorityList.push(mlov);
            }
          });

          (updatedTopBarData as any).selectedPriorityList = selectedPriorityList;
        }

        const sorter = rawData?.sorter || {};
        if (sorter.columnKey && sorter.order) {
          (updatedTopBarData as any).selectedSortUuid = sorter.columnKey;
          (updatedTopBarData as any).selectedSortType = sorter.columnKey;
          (updatedTopBarData as any).selectedSortValueAscending = sorter.order === 'ascend' ? true : false;
        } else {
          (updatedTopBarData as any).selectedSortUuid = 'none';
          (updatedTopBarData as any).selectedSortType = undefined;
        }
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.ON_TABLE_VIEW_CHANGE:
        updatedTopBarData = {
          isTableView: isSidecarContext ? true : rawData?.isTableView
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.SELECTED_BOARD_TYPE:
        updatedTopBarData = {
          selectedBoardType: rawData?.selectedBoardType,
          ...(rawData?.selectedStatusList && { selectedStatusList: rawData?.selectedStatusList }),
          ...(rawData?.selectedPriorityList && { selectedPriorityList: rawData?.selectedPriorityList }),
          ...(rawData?.selectedDueDateType && { selectedDueDateType: rawData?.selectedDueDateType }),
          ...(rawData?.selectedDueDateUuid && { selectedDueDateUuid: rawData?.selectedDueDateUuid }),
        }
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.ASSIGNEE_CHANGE:
        updatedTopBarData = {
          selectedUsers: rawData?.selectedUsers,
        };
        needUpdateCategoriesWithFilters = true;
        break;

      case COMMON_ACTION_CODES.SHOW_FILTER_INFO_CHANGE:
        updatedTopBarData = {
          showChangeFilterMessage: rawData?.showChangeFilterMessage,
          showFilterSameAcrossTabMessage: rawData?.showFilterSameAcrossTabMessage
        };
        break;

      case COMMON_ACTION_CODES.CLOSE_POPUP:
        updatedTopBarData = {
          taskAnalyticsIntroTooltip: rawData,
        };
        break;

      case COMMON_ACTION_CODES.DISMISS_INTRO_CARD:
        updatedTopBarData = {
          bulkTaskIntroCardDismissed: rawData?.bulkTaskIntroCardDismissed,
        };
        break;

      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        updatedTopBarData = {
          bulkActionEnabled: rawData?.bulkActionEnabled,
        };
        break;

      default:
        break;
    }
    let updatedTopBarDataWithPrevState = {} as ITopBarData;
    setTopBarData((prev: any) => {
      updatedTopBarDataWithPrevState = {
        ...prev,
        ...updatedTopBarData,
      };
      addUpdateUserFilterPreference(updatedTopBarDataWithPrevState);
      if (needUpdateCategoriesWithFilters) {
        updateCategoriesWithFilters(updatedTopBarDataWithPrevState, allUsersPoolsFromFilters);
      }
      return updatedTopBarDataWithPrevState;
    });

    // setLoading(false);
  };

  const addUpdateUserFilterPreference = async (topBarData: ITopBarData) => {
    try {
      const updatedFormData = {
        selectedPatientLabel: topBarData.selectedPatientLabel ? topBarData.selectedPatientLabel : [],
        selectedPatientName: topBarData.selectedPatientName ? topBarData.selectedPatientName : [],
        selectedStatusList: topBarData.selectedStatusList?.length ? topBarData.selectedStatusList : [],
        selectedPriorityList: topBarData.selectedPriorityList?.length ? topBarData.selectedPriorityList : [],
        selectedDueDate: topBarData.selectedDueDateType ? topBarData.selectedDueDateType : allDueDateValue,
        selectedDueDateUuid: topBarData.selectedDueDateUuid ? topBarData.selectedDueDateUuid : allDueDateUuid.split(" "),
        selectedSortUuid: topBarData.selectedSortUuid,
        selectedMentionsSortUuid: topBarData.selectedMentionsSortUuid,
        selectedSortType: topBarData.selectedSortType,
        selectedMentionsSortType: topBarData.selectedMentionsSortType,
        selectedMentionsSortValueAscending: topBarData.selectedMentionsSortValueAscending,
        selectedSortValueAscending: topBarData.selectedSortValueAscending,
        selectedPools:topBarData?.selectedPools ?topBarData?.selectedPools:[],
        start: topBarData.start ? getDateStrFromFormat(
          topBarData.start,
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        ) : '',
        end: topBarData.end ? getDateStrFromFormat(
          topBarData.end,
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        ) : '',
        selectedContactUuid: topBarData.selectedContactUuid?.map?.(
          (dataItem: any) => ({
            key: dataItem.key,
            value: dataItem.value,
            label: dataItem?.label?.props?.contactData?.name || dataItem?.label,
            contactData:dataItem?.contactData
          })
        ) || [],
        isTableView: isSidecarContext ? true : topBarData.isTableView,
        labels: topBarData?.labels?.map(item => item?.uuid || '') || [],
        selectedBoardType: topBarData?.selectedBoardType,
        selectedUsers: topBarData?.selectedUsers,
        showChangeFilterMessage: topBarData?.showChangeFilterMessage,
        showFilterSameAcrossTabMessage: topBarData?.showFilterSameAcrossTabMessage,
        taskAnalyticsIntroTooltip: topBarData?.taskAnalyticsIntroTooltip,
        bulkTaskIntroCardDismissed: topBarData?.bulkTaskIntroCardDismissed,
      };
      const addUpdateFilterPreferenceObject: any = {
        userId: currentUserUUID,
        userPreferencesTypeId: userPreferencesTypeId,
        preferencesJson: JSON.stringify(updatedFormData),
        preferencesModelVersion: 'v1',
      };

      if (topBarData.userPreferenceId) {
        addUpdateFilterPreferenceObject.id = topBarData.userPreferenceId;
      }

      await addUpdateUserFilterPreferenceAPI({
        variables: {
          object: addUpdateFilterPreferenceObject,
        }
      });
    } catch (error: any) {
    }
  }

  const shouldShowFilterInfoFirstMessage = ()=> {
    if (
      topBarData.selectedTab &&
      ![TASK_TABS.TASK_POOL, TASK_TABS.CREATED, TASK_TABS.MENTIONED].includes(topBarData.selectedTab) &&
      topBarData?.showChangeFilterMessage
    ) {
      return true;
    }
    return false;
  }
  const addNewTaskPool = (newTaskPool: IUserPool) => {
    setMasterUsersPoolList(prevList => [newTaskPool, ...prevList]);
  };

  const onTaskRemoved = (task: ITask) => {
    handleMultiSelectActions?.(MultiSelectAction.unSelectTask, [task]);
  }

  const showLoading = loading || getTaskPoolQuery.loading || batchedUserLoading || defaultDataLoading;

  return (
    <View>
      <View style={{ backgroundColor: "#ffffff" }}>
        <CareDashboardTopBar
          showAutomationOrSetting={isSidecarContext ? false : true}
          hideTaskAnalytics={isSidecarContext ? true : false}
          topBarData={topBarData}
          onTopBarDataChange={(topBarData: any) => {
            setTopBarData((prevState) => {
              return {...prevState, ...topBarData};
            });
          }}
          shouldNotNavigateToTabsPath={props.shouldNotNavigateToTabsPath}
          onActionPerformed={onActionPerformed}
          selectedStatusList={topBarData.selectedStatusList}
          selectedPriorityList={topBarData.selectedPriorityList}
          selectedPatientName={topBarData.selectedPatientName}
          selectedDueDateType={topBarData.selectedDueDateType}
          selectedSortType={topBarData.selectedSortType}
          selectedMentionsSortType={topBarData.selectedMentionsSortType}
          selectedContactUuid={topBarData.selectedContactUuid}
          selectedPatientLabel={topBarData.selectedPatientLabel}
          selectedDueDateUuid={topBarData.selectedDueDateUuid}
          selectedSortUuid={topBarData.selectedSortUuid}
          selectedMentionsSortUuid={topBarData.selectedMentionsSortUuid}
          selectedMentionsSortValueAscending={topBarData.selectedMentionsSortValueAscending}
          selectedSortValueAscending={topBarData.selectedSortValueAscending}
          isTableView={topBarData.isTableView}
          onValueChange={(value: boolean) => {
            setTopBarData((prevState) => {
              return {
                ...prevState,
                isTableView: isSidecarContext ? true : value
              };
            });
            onActionPerformed(COMMON_ACTION_CODES.ON_TABLE_VIEW_CHANGE,{isTableView: value})
          }}
          selectedTab={topBarData.selectedTab}
          onTabClick={(tabItem: any, index?: number, lastIndexInVisibleList?: number) => {
            setLoading(true);
            let updatedTopBarData = {} as ITopBarData;
            setTopBarData((prevState) => {
              updatedTopBarData = {
                ...prevState,
                selectedTab: tabItem?.key,
              }
              const activeTabKey = taskTabs.find((item) => item.path === lastPathStr)?.key || topBarData.selectedTab;
              if (activeTabKey !== tabItem?.key) {
                updateCategoriesWithFilters(updatedTopBarData);
              }
              return updatedTopBarData;
            });
            if (lastIndexInVisibleList || lastIndexInVisibleList === 0) {
              let tabs = cloneDeep(taskTabs);
              const removedItem = tabs.splice(lastIndexInVisibleList, 1);
              tabs = tabs.filter((item) => item.key !== tabItem.key);
              tabs.splice(lastIndexInVisibleList, 0, tabItem);
              if (removedItem.length) {
                tabs.push(removedItem[0]);
              }
              setTaskTabs(tabs);
            }
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }}
          tagsTabList={taskTabs}
          isOtherFilterApplied={true}
          setIsUserPoolRefetch={(newTaskPool: any) => {
            if (newTaskPool?.createUserPool) {
              const newUserPoolSelect = [...userPoolSelect];
              newUserPoolSelect.push({
                userPoolId: newTaskPool?.createUserPool?.id,
                userPoolName: newTaskPool?.createUserPool?.name,
                userPoolUserIds:
                  newTaskPool?.createUserPool?.userPoolUsers.map(
                    (userItem: any) => userItem.userId
                  ),
              });
              setIsUserPoolRefetch(!isUserPoolRefetch);
              setAllUserPoolSelect(newUserPoolSelect);
              setUserPool((prev: IUserPool[]) => {
                const updatedUserPool: IUserPool[] = [...prev]
                updatedUserPool.push({id: newTaskPool?.createUserPool?.id, name: newTaskPool?.createUserPool?.name, userPoolUsers: newTaskPool?.createUserPool?.userPoolUsers})
                return updatedUserPool
              })
            }
          }}
          userPools = {allUsersPool}
          handleNewTaskPool={(newPool: IUserPool) => addNewTaskPool(newPool)}
          handleChangeFilterView={(isShowFilterView: boolean) => {
            if (!isShowFilterView) {
              setSearchString('');
            }
            setIsShowFilterView(isShowFilterView);
          }}
          isShowFilterView={isShowFilterView}
        >
          <Stack space={16} direction={isSidecarContext ? 'column' : 'row'} style={{ width: '100%' }}>
          {(topBarData.selectedTab === TASK_TABS.CREATED || topBarData.selectedTab === TASK_TABS.MENTIONED) &&
            <UserAutoCompleteMultiSelect
              labelText={getLabelForAssigneeField(topBarData.selectedTab)}
              isMentionOrCreatedSelect={true}
              filterType={topBarData.selectedTab === TASK_TABS.CREATED ? TaskFilterType.CreatedBy : TaskFilterType.Mention}
              onActionPerformed={onActionPerformed}
              selectedValue={topBarData.selectedTab === TASK_TABS.CREATED ? topBarData.createdByIds : topBarData.mentionUserIds}
              onChange={(selectedUser) => {
                const isCreatedTabSelected = topBarData.selectedTab === TASK_TABS.CREATED;
                const isMentionTabSelected = topBarData.selectedTab === TASK_TABS.MENTIONED;
                const updatedTopBarData = {
                  ...( isCreatedTabSelected  && { createdByIds: selectedUser } ),
                  ...( isMentionTabSelected  && { mentionUserIds: selectedUser } ),
                }
                let updatedTopBarDataWithPrevState = {} as ITopBarData;
                setTopBarData((prevState) => {
                  updatedTopBarDataWithPrevState = {
                    ...prevState,
                    ...updatedTopBarData
                  }
                  return updatedTopBarDataWithPrevState;
                });
                if (isCreatedTabSelected || isMentionTabSelected) {
                  updateCategoriesWithFilters(updatedTopBarDataWithPrevState);
                }
              }}
              selectedTab={topBarData?.selectedTab}
            />
          }
          <UserAutoCompleteMultiSelect
            labelText={'Assigned to'}
            onActionPerformed={onActionPerformed}
            selectedValue={topBarData.selectedUsers}
            onChange={(selectedUsers) => {
              setTopBarData((prevState) => {
                return {...prevState, selectedUsers: selectedUsers};
              });
              onActionPerformed(COMMON_ACTION_CODES.ASSIGNEE_CHANGE, {selectedUsers: selectedUsers});
            }}
            onPoolChange={(newUserPool: IUserPool[]) => {
              setUserPool(newUserPool);
            }}
            setAllUserPoolList={(allUsersPools: IUserPool[])=>{
              setAllUserPool(allUsersPools);
              updateCategoriesWithFilters(topBarData, allUsersPools);
            }}
            onAllUserPoolChange={(allUserPool: IAllUserPoolSelect[]) => {
              setAllUserPoolSelect(allUserPool);
            }}
          />
          </Stack>
        </CareDashboardTopBar>
      </View>
      {!topBarData?.isPreferenceDataLoading && <>
        {shouldShowFilterInfoFirstMessage() ? <CareDashboardFilterInfo
          showMessage={topBarData?.showChangeFilterMessage}
          message={'taskFilterInfoOne'}
          selectedTab={topBarData?.selectedTab}
          onActionPerformed={()=>{
            onActionPerformed(
              COMMON_ACTION_CODES.SHOW_FILTER_INFO_CHANGE,
              {
                showChangeFilterMessage: false,
                showFilterSameAcrossTabMessage: topBarData?.showFilterSameAcrossTabMessage
              },
            );
          }}
        /> : <CareDashboardFilterInfo
          showMessage={topBarData?.showFilterSameAcrossTabMessage}
          message={'taskFilterInfoTwo'}
          selectedTab={topBarData?.selectedTab}
          onActionPerformed={()=>{
            onActionPerformed(
              COMMON_ACTION_CODES.SHOW_FILTER_INFO_CHANGE,
              {
                showChangeFilterMessage: topBarData?.showChangeFilterMessage,
                showFilterSameAcrossTabMessage: false
              },
            );
          }}
        />}
      </>}
      {(!showLoading && (!isSidecarContext || !isShowFilterView)) && (
        <View style={{height: screenHeight - getHeightOffset(), overflow: 'scroll', paddingBottom: topBarData.bulkActionEnabled ? (isSidecarContext ? 96 : 80) : 0}}>
          <TaskMainView
            config={displayConfig}
            onActionPerformed={onActionPerformed}
            metaData={{
              boardType: displayConfig.boardType,
              masterUsersList: userList,
              masterPoolList: masterUsersPoolList,
              bulkTaskEnabled: multiSelectData.enabled,
              selectedTasks: multiSelectData.selected,
              onSelectedTaskChange: handleMultiSelectActions,
            }}
          />
        </View>
      )}
      {multiSelectData.enabled && (
        <MultiSelectView
          isVisible={multiSelectData.enabled}
          selectedTasks={multiSelectData.selected}
          onTaskRemoved={onTaskRemoved}
        />
      )}
      {showLoading && (
        <View
          style={{
            margin: 16,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Spinner size={'lg'} />
        </View>
      )}
    </View>
  )
}

export default UserTaskManager
