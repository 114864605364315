import {Collapse} from '@arco-design/web-react';
import {Stack, useFocusIdx} from '@foldhealth/easy-email-editor';
import {
  AttributesPanelWrapper,
  ColorPickerField,
  TextField,
} from '@foldhealth/easy-email-extensions';
import React from 'react';

export function Panel() {
  const {focusIdx} = useFocusIdx();
  return (
    <AttributesPanelWrapper>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Item name="1" header="Setting">
          <Stack vertical>
            <TextField
              label="Title"
              name={`${focusIdx}.data.value.title`}
              inline
            />
            <TextField
              label="Button text"
              name={`${focusIdx}.data.value.buttonText`}
              inline
            />

            <ColorPickerField
              label="text color"
              name={`${focusIdx}.attributes.text-color`}
              inline
            />

            <ColorPickerField
              label="Background color"
              name={`${focusIdx}.attributes.background-color`}
              inline
            />
          </Stack>
        </Collapse.Item>
      </Collapse>
    </AttributesPanelWrapper>
  );
}
