import { gql } from "@apollo/client";

export const GetAccountsData = gql`
query getSingleAccountDetails($id: bigint!)
{  account(id: $id) {  
  websiteLink  
  name 
  instagramLink 
  description
  subtitle
  twitterLink
  facebookLink
  logo
}
}
`;

export default {
    GetAccountsData,
};