import {Button, HStack, Spacer, View, VStack} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import CareJourneySearch from '../../../../common/CareJourneySearch/CareJourneySearch';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';

const AssignCareJourneyPopup = (props: {onComplete: (record: any) => void}) => {
  const [resultData, setResultData] = useState<any>();
  const [showError, setShowError] = useState(false);
  const intl = useIntl();
  const onSubmit = () => {
    setShowError(true);
    if (resultData) {
      props.onComplete(resultData);
    }
  };

  return (
    <View minWidth={300} maxW={400} padding={2}>
      <VStack space={8}>
        <DisplayText
          textType={'Heading'}
          textLocalId={'assignNewJourney'}
          size="xlMedium"
        />
        <VStack space={1}>
          <DisplayText textLocalId={'selectCareJourney'} size="smMedium" />
          <CareJourneySearch
            isShowError={showError}
            value={resultData}
            onChange={(value: any) => {
              setResultData(value);
            }}
          />
        </VStack>
        <HStack>
          <Spacer />
          <Button.Group space={2}>
             <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onSubmit();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'continue',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
          </Button.Group>
        </HStack>
      </VStack>
    </View>
  );
};

export default AssignCareJourneyPopup;
