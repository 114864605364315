//import {IEmailTemplate} from 'easy-email-editor';
import { useContext, useEffect, useState } from 'react';
import { Button, HStack, Text, Tooltip, VStack, View, Pressable } from 'native-base';
import { useIntl } from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { CAMPAIGN_STATUS, DATE_FORMATS, POPULATION_GROUP_TYPES, SORT_TYPE } from '../../../../constants';
import { Colors } from '../../../../styles';
import { getOnFocusButtonStyles, isEmptyArray, isLeadGroupsPage, isPopGroupActivityLogEnable } from '../../../../utils/commonUtils';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import GroupIconOutline from '../../../common/Svg/GroupIconOutline';
import ScheduleRuleIcon from '../../../common/Svg/ScheduleRuleIcon';
import { getGroupDetailById } from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { CampaignTableActions } from './CampaignTableActions';
import {
  AnalyticsGraphData,
  CampaignSegmentTableContext,
  ICampaignData,
  ICampaignExpandedTextState,
  ICampaignTableColumnsArgs
} from './interfaces';
import AutomationSvg from '../../../common/Svg/GlobalActionSvg/AutomationSvg';

import { getDateAndTimeOnLocalFormate, getDateStrFromFormat, getTimeRemaining } from '../../../../utils/DateUtils';
import { ISort } from '../../../../context/interfaces';
import { RULES_EXECUTION_STATUSES } from './constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getFrequencyValue } from './utils';
import { FilterType } from './CampaignSegmentForm/interfaces';
import { INITIAL_CAMPAIGN_DESCRIPTION_LENGTH } from './constants';

const SegmentDetails = (props:{segmentId:string})=>{
  const [data, setData] = useState<any>()
  useEffect(()=>{
    if(props.segmentId){
      getGroupDetailById(props.segmentId).then((resp:any) => {
        setData(resp?.data)
      });
    }
  },[props.segmentId])
  const STATIC_GROUP_TYPE = ['TEMP','STATIC'];
  return <>{ data ? ( STATIC_GROUP_TYPE.indexOf(data?.type) != -1  ? 'Static' : 'Dynamic') : (!props.segmentId ? 'Dynamic' : '')}</>
}

export const getCampaignColumns = (args: ICampaignTableColumnsArgs): any[] => {
  const {actionFn,descriptionData,setDescriptionData} = args;
  const intl = useIntl();

  const columns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="Campaign Name"
        />
      ),
      // dataIndex: 'name',
      width: '30%',
      fixed: 'left',
      key: 'name',
      render: (record: any) => {
        const { name, status } = record;
        return (
          <VStack>
            <Text size={'smMedium'} color={Colors.Custom.Gray900} textOverflow={'ellipsis'}>
              {name}
            </Text>
            {(status == CAMPAIGN_STATUS.ENDED ||
              status == CAMPAIGN_STATUS.ABORTED) &&

              <Text
                size={'smMedium'}
                color={status == CAMPAIGN_STATUS.ENDED ? Colors.Custom.SuccessColor : Colors.Custom.crossIconColor}
                gap={20}
              >
                {CAMPAIGN_STATUS.ABORTED == status ?
                  <Feather
                    name="x-octagon"
                    style={{
                      fontSize: 16,
                      color: Colors.Custom.crossIconColor,
                      marginRight: 4
                    }}
                  /> :
                  <Feather
                    name="check-circle"
                    style={{
                      fontSize: 16,
                      color: Colors.Custom.SuccessColor,
                      marginRight: 4
                    }}
                  />
                }

                {intl.formatMessage({ id: CAMPAIGN_STATUS.ENDED == status ? 'successfullyExecuted' : 'aborted' })}
                {/* {status} */}
              </Text>
            }
          </VStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{ color: Colors.Custom.Gray500 }}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      render: (description: string, record: any) => {
        const id = record?.id || '';
        return (
          <>
            {description?.length <= INITIAL_CAMPAIGN_DESCRIPTION_LENGTH  && <Text>{description}</Text>}
            {description?.length > INITIAL_CAMPAIGN_DESCRIPTION_LENGTH  && <Text>
              {descriptionData?.[id] ? description : description.slice(0, INITIAL_CAMPAIGN_DESCRIPTION_LENGTH ) + '...'}
            </Text>}
            {description?.length > INITIAL_CAMPAIGN_DESCRIPTION_LENGTH  && <Text color={Colors.Custom.mainPrimaryPurple} fontWeight={600}>

              <Pressable onPress={() => {
                setDescriptionData({
                    ...descriptionData,
                    [id]: descriptionData?.[id] ? false : true,
                  }
                )
              }}>
                {descriptionData?.[id] ? '  Read less' : '  Read more'}
              </Pressable>
            </Text>}
          </>
        );
      },
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500}}
    //       textLocalId="status"
    //     />
    //   ),
    //   dataIndex: 'status',
    //   width: '10%',
    //   key: 'status',
    //   render: (status: string) => {
    //     //const status = campaignStatus;
    //     return (
    //       <View
    //         style={{
    //           backgroundColor:
    //             status == 'ACTIVE'
    //               ? '#ecfdf2'
    //               : status == 'COMPLETE'
    //               ? '#e4d1f7'
    //               : '#e6e9ed',
    //           width: 80,
    //           borderRadius: 12,
    //           padding: 1,
    //           alignItems: 'center',
    //         }}
    //       >
    //         <Text
    //           size={'smMedium'}
    //           color={
    //             status == 'ACTIVE'
    //               ? '#1d885d'
    //               : status == 'COMPLETE'
    //               ? '#974ed9'
    //               : Colors.Custom.Gray900
    //           }
    //           justifyContent="center"
    //           alignSelf="center"
    //         >
    //           {status}
    //         </Text>
    //       </View>
    //     );
    //   },
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="Campaign Date"
        />
      ),
      dataIndex: 'startDate',
      //defaultSortOrder: defaultSortOrder,
      key: 'startDate',
      width: '15%',
      sorter: (a: any, b: any) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
      render: (startDate: string) => {
        const date = new Date(startDate).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {startDate ? String(date) : 'Immediate'}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="Audience"
        />
      ),
      key: 'audience',
      dataIndex: 'segmentId',
      width: '10%',
      render: (segmentId:string) => {
        // const status = campaignStatus;
        return (
          <View
            style={{
              backgroundColor: '#eff6ff',
              width: 80,
              borderRadius: 12,
              padding: 1,
              alignItems: 'center',
            }}
          >
            <Text
              size={'smMedium'}
              color="blue.900"
              justifyContent="center"
              alignSelf="center"
            >
              <SegmentDetails segmentId={segmentId}></SegmentDetails>
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="actions"
        />
      ),
      key: 'Actions',
      // width: '15%',
      fixed: 'right',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (record: ICampaignData) => {
        return (
          <>
            <CampaignTableActions
              record={record}
              onActionPerformed={actionFn}
            />
          </>
        );
      },
    },
  ];

  return columns;
};

export const capitalizeText = (str: string) => {
  if (!str) return;
  return str.match('^[a-z]')
    ? str.charAt(0).toUpperCase() + str.substring(1)
    : str;
};

export const capitalizeFirstOtherSmallLetter = (str: string) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1)?.toLowerCase();
};

export const capitalizeFirstLetterOfEachWord = (str: string) => {
  if (!str) return;

  const strArr = str.split(' ');

  for (let i = 0; i < strArr.length; i++) {
    strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substr(1);
  }
  
  return strArr.join(' ');
};

export const getDateStr = (dateStr: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateStr);
  date.getMonth();
  return date.getDate() + ' ' + months[date.getMonth()];
};

export const isEmailChannel = (record: ICampaignData) => {
  const result = record?.channelType == 'EMAIL';
  return result;
};

export const IsNoEmailsSent = (analyticsGraphData: AnalyticsGraphData) => {
  const data = analyticsGraphData.data || [];
  if (isEmptyArray(data)) {
    return true;
  }
  const result = data.every((item) => item.count == 0);
  return result;
};

const getRuleDataByContext = (context:CampaignSegmentTableContext) => {
  switch (context) {
    case CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES:
      return {
        icon: <ScheduleRuleIcon />,
        headerTitle: 'purpose',
      };
    case CampaignSegmentTableContext.POPULATION_GROUP:
      return {
        icon: <GroupIconOutline />,
        headerTitle: 'groupName',
      };
    default:
      return {
        icon: <GroupIconOutline />,
        headerTitle: 'groupName',
      };
  }
};

export const getGroupListColumns = (
  onActionPerformed: (actionCode: string, rowData: any) => void,
  onEdit: (id: string) => void,
  onAddMemberClick:(id: string) => void,
  onDelete: (id: string, message: string) => void,
  onTriggerRule:(id: string) => void,
  onAuditClick: (id: string) => void,
  context: CampaignSegmentTableContext,
  openInHint?: boolean,
  selectedCount?: number,
  sort?: ISort
): any[] => {
  const sortOrder = sort?.order ? sort.order === SORT_TYPE.ASC ? 'ascend' : 'descend' : null;

  const intl = useIntl();
  const {icon, headerTitle} = getRuleDataByContext(context);
  const isScheduleSuggestionRulesContext = context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES;
  const isPopulationGroupContext = context === CampaignSegmentTableContext.POPULATION_GROUP;
  const showRuleTypeColumn = isPopulationGroupContext;
  const showAddMemberAction = isPopulationGroupContext;
  const showCreatedDateColumn = isPopulationGroupContext;
  const showUpdatedDateColumn = isPopulationGroupContext;

  const headerTitleText = intl.formatMessage({id: headerTitle})

  const getExecutionDateTimeText = (rule: any) => {
    if(rule.executionStatus === RULES_EXECUTION_STATUSES.QUEUED){
      return ''
    }
    else if(rule.executionStatus === RULES_EXECUTION_STATUSES.START &&
      rule.rawData?.expectedCompletionTime){
      return `${intl.formatMessage({
        id: 'completesIn',
      })} ${getTimeRemaining(rule.rawData?.expectedCompletionTime)}`
    }   
    else if (rule.nextExecutionDateTime){
      return `${intl.formatMessage({
        id: 'executionDueIn',
      })} ${getTimeRemaining(rule.nextExecutionDateTime)}`
    }
    else if(rule?.executionDateTime){
      return `${intl.formatMessage({
        id: 'lastExecutionCompletedOn',
      })} ${getDateAndTimeOnLocalFormate(
        rule.executionDateTime,
        DATE_FORMATS.MESSAGE_DATE_FORMAT
      )}`
    }
  }

  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowPopGroupActivityLog = isPopGroupActivityLogEnable(userSettings);

  const columns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
          textLocalId={
            selectedCount
              ? `${headerTitleText} (${selectedCount} selected)`
              : headerTitleText
          }
        />
      ),
      key: 'name',
      sortOrder: sort?.columnKey === 'name' && sortOrder ? sortOrder : null,
      sorter: isPopulationGroupContext ? true : false,
      width: showRuleTypeColumn ? '40%' : '70%',
      render: (text: any) => {        
        const name = text?.name || '';
        const count = text?.count || '';
        const description = text?.description || '';
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}} px={0.5}>
            {icon}
            <View style={{marginLeft: 12, width: '85%'}}>
              <Text
                fontSize={'16px'}
                style={{
                  color: Colors.Custom.Gray900,
                  flexWrap: 'wrap',
                }}
              >
                {name}
                {description.length && (
                  <Tooltip label={description} placement="top">
                    <View style={{marginLeft: 10}}>
                      <Feather
                        name="info"
                        style={{
                          fontSize: 18,
                          color: Colors.Custom.Gray600,
                        }}
                      />
                    </View>
                  </Tooltip>
                )}
              </Text>

              {text?.passedCount && (
                <Text size={'xsMedium'} color={Colors.Custom.SuccessColor}>
                  {`${text?.passedCount} ${intl.formatMessage({
                    id: 'passMembersNew',
                  })}`}
                </Text>
              )}
              {text?.failedCount && (
                <Text size={'xsMedium'} color={Colors.Custom.Danger700}>
                  {`${text?.failedCount} ${intl.formatMessage({
                    id: 'failMembersNew',
                  })}`}
                </Text>
              )}
              {text.executionStatus && text.executionStatus === RULES_EXECUTION_STATUSES.QUEUED && (
                <Text fontWeight={"500"} color={Colors.Custom.DeceasedTagColor}>
                 {`${capitalizeFirstOtherSmallLetter(text?.executionStatus?.toLowerCase())}`}
                </Text>
              )}
              {(text?.rawData.type === POPULATION_GROUP_TYPES.DYNAMIC || text?.rawData.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD) && (
                <Text color={Colors.Custom.Gray500}>
                  {getExecutionDateTimeText(text)}
                </Text>
              )}
              {(text?.rawData?.execution_frequency || text?.rawData?.inputRuleDetails?.executionFrequency) &&
                (text?.rawData.type === POPULATION_GROUP_TYPES.DYNAMIC || text?.rawData.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD) && (
                  <Text color={Colors.Custom.Gray500}>
                    Frequency: {getFrequencyValue(text?.rawData)}
                  </Text>
              )}
            </View>
          </View>
        );
      },
      responsive: ['md'],
    },
    isLeadGroupsPage() ? {
      title: (
        <View>
          <DisplayText
            size={'xsMedium'}
            extraStyles={{ color: Colors.primary['700'] || '', alignItems: 'center', textAlign: 'center' }}
            textLocalId={'Count'}
          />
        </View>
      ),
      width: '9%',
      render: (data: any) => {
        return (
          <View style={{alignItems: 'center'}}>
              <Text size={'mdMedium'} color={Colors.Custom.Gray900}>
                {`${data?.count ? `${data?.count}` :  '0'}`}
              </Text>
          </View>
        );
      },
    } : undefined,
    !isLeadGroupsPage() ? {
      title: (
        <View>
          <DisplayText
            size={'xsMedium'}
            extraStyles={{
              color: Colors.primary['700'] || '',
              alignItems: 'center',
              textAlign: 'center',
            }}
            textLocalId={
              openInHint && !isLeadGroupsPage()
                ? 'foldActiveMembers'
                : 'activeMembers'
            }
          />
        </View>
      ),
      width: '9%',
      render: (data: any) => {
        return (
          <View style={{alignItems: 'center'}}>
            <Text size={'mdMedium'} color={Colors.Custom.Gray900}>
              {`${
                data?.count
                  ? `${data?.count}`
                  : data?.nextExecutionDateTime
                  ? '-'
                  : '0'
              }`}
            </Text>
          </View>
        );
      },
    } : undefined,
    !isLeadGroupsPage() ? {
      title: (
        <View textAlign={'center'}>
          <DisplayText
            size={'xsMedium'}
            extraStyles={{
              color: Colors.primary['700'] || '',
              alignItems: 'center',
            }}
            textLocalId={
              openInHint && !isLeadGroupsPage()
                ? 'foldInactiveMembers'
                : 'inactiveMembers'
            }
          />
        </View>
      ),
      width: '9%',
      render: (data: any) => {
        return (
          <View style={{alignItems: 'center'}}>
            <Text size={'mdMedium'} color={Colors.Custom.Gray900}>
              {`${
                data?.inActiveCount
                  ? `${data?.inActiveCount}`
                  : data?.nextExecutionDateTime
                  ? '-'
                  : '0'
              }`}
            </Text>
          </View>
        );
      },
    } : undefined,
    //For future reference
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
    //       textLocalId="groupDescription"
    //     />
    //   ),
    //   width: '20%',
    //   render: (data: any) => {
    //     const description = data?.description || '- -';
    //     return (
    //       <View>
    //         <Text size={'mdMedium'} color={Colors.Custom.Gray700}>
    //           {description.length < 100 || true
    //             ? description
    //             : description.slice(0, 100) + '...'}
    //         </Text>
    //         <View
    //           style={{
    //             flexDirection: 'row',
    //             alignItems: 'center',
    //             maxWidth: 300,
    //             flexWrap: 'wrap',
    //           }}
    //         >
    //           {data?.genderText ? (
    //             <Text size={'smMedium'} color={Colors.Custom.Gray500}>
    //               {data?.genderText} •{' '}
    //             </Text>
    //           ) : (
    //             <></>
    //           )}
    //           {data?.age ? (
    //             <Text size={'smMedium'} color={Colors.Custom.Gray500}>
    //               Age: {data?.age} •{' '}
    //             </Text>
    //           ) : (
    //             <></>
    //           )}
    //         </View>
    //       </View>
    //     );
    //   },
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
    //       textLocalId="campaigns"
    //     />
    //   ),
    //   width: '9%',
    //   render: (data: any) => {
    //     return <Text>-</Text>;
    //   },
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
    //       textLocalId="carejourney"
    //     />
    //   ),
    //   width: '9%',
    //   render: (data: any) => {
    //     return <Text>-</Text>;
    //   },
    // },
    showRuleTypeColumn
      ? {
          title: (
            <DisplayText
              size={'xsMedium'}
              extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
              textLocalId="type"
            />
          ),
          width: '9%',
          render: (data: any) => {
            return (
              <Text>
                {data?.rawData?.type == 'STATIC' ||
                data.rawData.type === POPULATION_GROUP_TYPES.STATIC_LEAD
                  ? 'Static'
                  : 'Dynamic'}
              </Text>
            );
          },
        }
      : undefined,
    showCreatedDateColumn
      ? {
          title: (
            <View textAlign={"center"}>
              <DisplayText
                size={'xsMedium'}
                extraStyles={{
                  color: Colors.primary['700'] || '',
                  marginLeft: 8,
                }}
                textLocalId="createdDate"
              />
            </View>
          ),
          sorter: true,
          sortOrder:
            sort?.columnKey === 'created_on' && sortOrder ? sortOrder : null,
          key: 'created_on',
          width: '9%',
          render: (data: any) => {
            return (
              <Text>
                {getDateStrFromFormat(
                  data?.rawData?.created_on,
                  'Do MMM YYYY'
                )}
              </Text>
            );
          },
        }
      : undefined,
    showUpdatedDateColumn
      ? {
          title: (
            <View textAlign={"center"}>
              <DisplayText
                size={'xsMedium'}
                extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
                textLocalId="updatedDate"
              />
            </View>
          ),
          sorter: true,
          key: 'updated_on',
          sortOrder:
            sort?.columnKey === 'updated_on' && sortOrder ? sortOrder : null,
          width: '9%',
          render: (data: any) => {
            return (
              <Text>
                {getDateStrFromFormat(
                  data?.rawData?.updated_on,
                  'Do MMM YYYY'
                )}
              </Text>
            );
          },
        }
      : undefined,
    {
      align: 'center',
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      render: (data: any) => {
        const campaignId = data?.id || '';

        return (
          <HStack flexDirection={'row'} justifyContent={'right'}>
            {(data?.rawData?.type === POPULATION_GROUP_TYPES.DYNAMIC || data?.rawData?.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD) &&
              <Tooltip label={data.executionStatus === RULES_EXECUTION_STATUSES.QUEUED ||
                data.executionStatus === RULES_EXECUTION_STATUSES.START? "Execution in progress" : "Execute Rule"} placement="top">
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: '5px',
                  }}
                  disabled={data.executionStatus === RULES_EXECUTION_STATUSES.QUEUED ||
                    data.executionStatus === RULES_EXECUTION_STATUSES.START}
                  _focus={{
                    ...getOnFocusButtonStyles(),
                  }}
                  onPress={() => {
                    onTriggerRule(campaignId)
                  }}
                >
                  <AutomationSvg
                    width={17}
                    customStrokeColor={data.executionStatus === RULES_EXECUTION_STATUSES.QUEUED ||
                      data.executionStatus === RULES_EXECUTION_STATUSES.START? Colors.Custom.Gray400 : Colors.Custom.Gray600}
                  />
                </Button>
              </Tooltip>
            }
            {showAddMemberAction &&
            data.accountUuid &&
            data?.rawData?.type == 'STATIC' ? (
              <Tooltip label="Add or Update Member" placement="top">
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: '5px',
                  }}
                  _focus={{
                    ...getOnFocusButtonStyles(),
                  }}
                  onPress={() => {
                    onAddMemberClick(campaignId);
                  }}
                >
                  <Feather
                    name="user-plus"
                    size={17}
                    color={Colors.Custom.Gray600}
                  />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
            {allowPopGroupActivityLog && 
              <Tooltip label="Activity" placement="top">
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: '5px',
                  }}
                  _focus={{
                    ...getOnFocusButtonStyles(),
                  }}
                  onPress={() => {
                    onAuditClick(campaignId);
                  }}
                >
                  <Feather name="clock" size={17} color={Colors.Custom.Gray600} />
                </Button>
              </Tooltip>
            }
            {data.accountUuid && !(data?.rawData?.display_json === null) ? (
              <Tooltip label="Edit" placement="top">
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: '5px',
                  }}
                  _focus={{
                    ...getOnFocusButtonStyles(),
                  }}
                  onPress={() => {
                    onEdit(campaignId);
                  }}
                >
                  <Feather
                    name="edit-3"
                    size={17}
                    color={Colors.Custom.Gray600}
                  />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}

            {
              <Tooltip label="Delete" placement="top">
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: '5px',
                  }}
                  _focus={{
                    ...getOnFocusButtonStyles(),
                  }}
                  onPress={() => {
                    onDelete(
                      campaignId,
                      data?.rawData?.display_json === null
                        ? 'Are you sure you want to delete this imported group? Imported groups can be added again.'
                        : 'Are you sure you want to delete this group?'
                    );
                  }}
                >
                  <Feather
                    name="trash-2"
                    size={17}
                    color={Colors.Custom.Gray600}
                  />
                </Button>
              </Tooltip>
            }
          </HStack>
        );
      },
    },
  ];
  return columns.filter(item => item !== undefined);
};

export const capitalizeFirstLatter = (str: string) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
};

export const isStaticGroup = (selectedFilterType: FilterType) => {
  return selectedFilterType === FilterType.SELECT_BY_PATIENT;
};
