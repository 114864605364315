import { MEMBERSHIP_STATUS_ALL } from '../../../../../constants';
import { useLazyQuery } from '@apollo/client';
import { getEnvVariable } from '../../../../../constants/BaseUrlConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { SUPPORTED_EVENT_CODE } from '../../../../../constants/WebSocketConst';
import { CARE_JOURNEY_DISABLE_NODES_ON_PROD } from '../../../../../constants/WorkflowNodeConst';
import { FlowType } from '../../../../../context/WorkflowContext';
import { UserQueries, WorkflowEntity } from '../../../../../services';
import CampaignQueries from '../../../../../services/Campaign/CampaignQueries';
import BaseService from '../../../../../services/CommonService/BaseService';
import CommonService from '../../../../../services/CommonService/CommonService';
import EmployeeQueries from '../../../../../services/Employee/EmployeeQueries';
import NotificationQueries from '../../../../../services/Notifications/NotificationQueries';
import WorkflowExecutionLogQueries from '../../../../../services/WorkflowExecutionLog/WorkflowExecutionLogQueries';
import { EventBus } from '../../../../../utils/EventBus';
import { CodeSearchType } from '../../../../common/CodeSearch/CodeSearchConstants';
import {
  UserActionCode,
  UserActionStatus
} from '../../../../common/UsageLevel/interfaces';
import { GET_FAILED_IMPORTED_CONTACTS, GET_IMPORT_CONTACT_LOG } from '../../../Contacts/ImportLog/ImportLogQueries';
import {IMultipleMemberGroupResponse} from '../../../Contacts/Leads/interfaces';
import { processQueryBuilderJSON } from '../../../ContentManagement/CampaignManagement/QueryBuilder/QueryBuilderHelper';
import { CampaignSegmentCategories } from '../../../ContentManagement/CampaignManagement/interfaces';
import { ContentTypes } from '../../../ContentManagement/ContentManagementConsts';
import { getTemplateById } from '../../../ContentManagement/ContentManagementUtils';
import { INPUT_FIELD_TYPE } from '../../FlowComponent/StateNodes/NodeInputField';
import { IItemToDelete } from './WorkflowTriggerView/interfaces';
import { GET_FORM_NAME_BY_IDS } from '../../../../../services/Forms/FormsQueries';
import { useEffect, useState } from 'react';
import { updateNodeInformation } from './WorkflowUtils';

const commonService = CommonService.getCommonServiceInstance();
const workflowService = commonService.workflowService;
const cloudTelephonyService = commonService.cloudTelephonyService;

const WORKFLOW_CONCURRENT_REQUEST: any = {
  maxConcurrentRequests : 2,
  requestQueue: [],
  concurrentRequests: 0
}

export const saveWorkflow = (
  data: any,
  successHandler: any,
  errorHandler: any,
  abortSignal?: AbortSignal
) => {
  const body: any = {
    id: data?.id,
    flowType: data?.flowType,
    description: data?.description,
    userId: data?.userId,
    tenantId: data?.tenantId,
    workflowName: data?.workflowName,
    tagList: data?.tagList,
    triggerInfo: data?.triggerInfo,
    graph: data.graph || {nodes: data?.nodes, edges: data?.edges},
    surveyForm: data?.surveyForm,
    isSendSurveyForm: data?.isSendSurveyForm,
    secondaryMasterId: data?.secondaryMasterId,
    isDraftOnly: data?.flowType === 'IVR' && data?.isDraftOnly,
  };
  if (data.isEnabled !== undefined) {
    body.isEnabled = data.isEnabled;
  }
  if(body.flowType == FlowType.MICRO_AUTOMATION){
    body.allowedFlowTypeList = data.allowedFlowTypeList || [];
  }

  body?.graph?.nodes.forEach((node:any)=>{
    if(node?.data?.metaData?.userInputFieldList){
      node.data.metaData.userInputFieldList = node?.data?.metaData?.userInputFieldList?.map((userInputField:any)=>{
        if(userInputField?.key == 'formList'){
          userInputField.value = (userInputField?.value || []).map((value:any)=>{
            if(value.link){
              delete value.link;
            }
            return value;
          });
        }
        if(userInputField?.key == 'showTotalCount'){
          userInputField.value = ""
        }
        return userInputField
      })
    }
  });

  workflowService
    .post(`/workflow/create`, body, {
      signal: abortSignal,
    })
    .then((result) => {
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const saveIvrWorkflow = (
  data: any,
  successHandler: any,
  errorHandler: any
) => {
  cloudTelephonyService
    .post(`/flow/create`, data)
    .then((result) => {
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const updateWorkflowState = (
  id: string,
  isEnabled: boolean,
  successHandler: any,
  errorHandler: any
) => {
  const body = {
    isEnabled: isEnabled,
    id: id,
  };
  workflowService
    .post(`/workflow/updatestate`, body)
    .then((result) => {
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const setWorkflowTag = (id: string, tagList: Array<string>) => {
  const body = {
    tagList: tagList,
    id: id,
  };
  return workflowService.post(`/workflow/tag`, body);
};

export const getAllTenants = () => {
  return workflowService.get(`/workflow/tenants/action?action=fetchTenants`);
};


// export const getWorkflowTags = () => {
//   return workflowService.get('/workflow/tag');
// };

// export const getWorkflowList = (
//   searchText: string,
//   flowType: FlowType,
//   tagList: Array<string>,
//   successHandler: any,
//   errorHandler: any
// ) => {
//   const queryParams: any = {};
//   if (searchText) {
//     queryParams.searchText = searchText;
//   }
//   queryParams.flowType = flowType;
//   if (tagList && tagList.length) {
//     queryParams.tagList = JSON.stringify(tagList);
//   }

//   workflowService
//     .get(`/workflow`, {params: queryParams})
//     .then((result) => {
//       successHandler(result?.data || []);
//     })
//     .catch((error) => {
//       errorHandler(error);
//     });
// };

export const getWorkflowTagList = (searchText: string) => {
  let queryParams = '';
  if (searchText) {
    queryParams = `?searchText=${searchText}`;
  }

  return workflowService.get(`/workflow/tag${queryParams}`);
};

export const deleteWorkflowById = (
  ItemToDeleteIs: IItemToDelete,
  successHandler: any,
  errorHandler: any,
  showToast?: any
) => {
  const body = {
    isDeleted: true,
    id: ItemToDeleteIs.id,
  };
  workflowService
    .post(`/workflow/delete`, body)
    .then((result) => {
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
  const ivrBody = {
    isDeleted: true,
    workflowMasterId: ItemToDeleteIs.workflowMasterId,
  };
  cloudTelephonyService
    .post(`/flow/delete`, ivrBody)
    .then((result) => {
      successHandler(result?.data || {});
      showToast();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getWorkflowExecutionDataByWorkflowMasterId = async (
  workflowMasterId: string
) => {
  const queryString =
    NotificationQueries.GET_WORKFLOW_EXECUTION_DATA_BY_WORKFLOW_MASTER_ID;
  const data = {
    query: queryString,
    variables: {
      workflowMasterId: workflowMasterId,
      masterId: workflowMasterId
    },
  };

  const response = await workflowService.post('/graphql/readonly', data);
  return response?.data || undefined;
};

export const getWorkflowExecutionLogsByWorkflowMasterId = async (
  workflowMasterId: string,
  workflowState: string,
  offset: number,
  limit?:number
) => {
  const contactIds: any = [];
  const taskIds: any = [];
  const queryString = workflowState === 'WORKFLOW_INCOMPLETE' ?
    NotificationQueries.GET_INCOMPLETE_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID:
    NotificationQueries.GET_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID;
    let variables: any = {
      workflowMasterId: workflowMasterId,
      workflowState,
      limit: limit || 10,
      offset,
    };
    if(workflowState === 'WORKFLOW_INCOMPLETE'){
      variables = {
        workflowMasterId: workflowMasterId,
        limit: limit || 10,
        offset,
      }
    }
  const data = {
    query: queryString,
    variables,
  };


  const response = await workflowService.post('/graphql/readonly', data);
  const workflowExecutionLogs = response?.data?.data?.workflowExecutionLogs || [];
  workflowExecutionLogs.forEach((item: any)=> {
    const  flowType = item?.workflow?.flowType;

    const filteredResourceLevelLogs = item?.resourceLevelLog.filter((item: any) => {
      if(item?.resourceType === 'CONTACT'){
        const val = Number(item?.resourceId)
        return !Number.isNaN(val);
      } else {
        return true;
      }
    })
    filteredResourceLevelLogs.forEach((itemVal: any) => {
      if (itemVal?.resourceType === 'TASK' && flowType === FlowType.task) {
        taskIds.push(itemVal?.resourceId);
      } else if (itemVal?.resourceType === 'CONTACT' && flowType !== FlowType.task) {
        contactIds.push(Number(itemVal?.resourceId));
      }
    });
    item.resourceLevelLog = filteredResourceLevelLogs;
  })
  const filterWorkflowExecutionLogs = workflowExecutionLogs.map((item: any) => {
      const flowType = item?.workflow?.flowType;
      if (flowType === FlowType.task) {
        item.resourceLevelLog = (item?.resourceLevelLog || []).filter(
          (itemVal: any) => {
            return (
              (itemVal?.resourceType === 'TASK' && flowType === FlowType.task) ||
              flowType !== FlowType.task
            );
          }
        );
      }
      return item;
  }) || [];

  filterWorkflowExecutionLogs.forEach((workflowLog: any) => {
    workflowLog?.resourceLevelLog?.sort((resourceA: any, resourceB: any) => {
      const dateA = new Date(resourceA.createdOn).getTime();
      const dateB = new Date(resourceB.createdOn).getTime();
      return dateA - dateB
    });
  });

  return { data:  filterWorkflowExecutionLogs || undefined , contactIds, taskIds};
};

export const getWorkflowNodeMasterData = (
  flowType: string,
  successHandler: any,
  errorHandler: any
) => {
  workflowService
    .get(`/workflow/node`, {params: {flowType: flowType}})
    .then((result) => {
      if (
        !getEnvVariable() &&
        flowType === FlowType.careJourney &&
        result?.data?.nodeMap
      ) {
        // disable for prod
        CARE_JOURNEY_DISABLE_NODES_ON_PROD.forEach((nodeType) => {
          if (result.data.nodeMap[nodeType]) {
            result.data.nodeMap[nodeType].isDeprecated = true;
          }
        });
      }

      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getWorkflowNodeMetaData = (
  flowType: string,
  fieldType: string,
  nodeType: string,
  successHandler: any,
  errorHandler: any
) => {
  const params: any = {flowType: flowType, fieldType: fieldType};
  if (nodeType) {
    params['nodeType'] = nodeType;
  }
  workflowService
    .get(`/workflow/node`, {params: params})
    .then((result) => {
      if (
        !getEnvVariable() &&
        flowType === FlowType.careJourney &&
        result?.data?.nodeMap
      ) {
        // disable order nodes for prod
        CARE_JOURNEY_DISABLE_NODES_ON_PROD.forEach((nodeType) => {
          if (result.data.nodeMap[nodeType]) {
            result.data.nodeMap[nodeType].isDeprecated = true;
          }
        });
      }
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getNotificationCount = async (args: {
  userId: string;
  userType: 'USER';
  tenantId: string;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  const queryString = NotificationQueries.GET_NOTIFICATION_COUNT;

  const data = {
    query: queryString,
    variables: args,
  };

  const response = await axios.post('/crm-communication/graphql', data);
  return response.data.data;
};

export const getNotifications = async (args: {
  userId: string;
  userType: 'USER';
  tenantId: string;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  const queryString = NotificationQueries.GET_NOTIFICATIONS;

  const data = {
    query: queryString,
    variables: args,
  };

  const response = await axios.post('/crm-communication/graphql', data);
  return response.data.data;
};

export const getImportContactLog = async (args: {
  tenantId: string;
}) => {
  const queryString = GET_IMPORT_CONTACT_LOG;

  const data = {
    query: queryString,
    variables: args,
  };

  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getFailedContactsInImport = async (args: {
  batchId: string;
  tenantId: string;
}) => {
  const queryString = GET_FAILED_IMPORTED_CONTACTS;

  const data = {
    query: queryString,
    variables: args,
  };

  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getCampaigns = async (tenantId: string) => {
  const queryString = CampaignQueries.GET_CAMPAIGNS;

  const data = {
    query: queryString,
    variables: {
      tenantId: tenantId,
    },
  };
  const response = await workflowService.post('/graphql', data);

  return response.data.data;
};

export const getCampaignsByStatus = async (tenantId: string, statusCodes: string[]) => {
  const queryString = CampaignQueries.GET_CAMPAIGNS_BY_STATUSES;

  const data = {
    query: queryString,
    variables: {
      tenantId: tenantId,
      statusCodes: statusCodes,
    },
  };
  const response = await workflowService.post('/graphql', data);

  return response.data.data;
};

export const deleteCampaign = async (args: {id: string}) => {
  const queryString = CampaignQueries.DELETE_CAMPAIGN;

  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const createCampaign = async (args: {
  name: string;
  description: string;
  isImmediate: boolean;
  startDate: string | null;
  numberOfRetry: number;
  tenantId: string;
  patientFilter: any;
  retryDelayInDays: number;
  emailTemplateId?: number;
  metadata?: any;
}) => {
  const queryString = CampaignQueries.SAVE_CAMPAIGN;

  const data = {
    query: queryString,
    variables: {
      name: args.name,
      description: args.description,
      isImmediate: args.isImmediate,
      startDate: args.startDate,
      numberOfRetry: args.numberOfRetry,
      patientFilter: args.patientFilter,
      retryDelayInDays: args.retryDelayInDays,
      emailTemplateId: args.emailTemplateId,
      metadata: args.metadata,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const createOrUpdateCampaign = async (args: {
  name: string;
  description: string;
  isImmediate: boolean;
  startDate: string | null;
  numberOfRetry: number;
  tenantId: string;
  //campaignSegment:any;
  retryDelayInDays: number;
  emailTemplateId?: number;
  segmentType?: string;
  segmentTypeValue?: string;
  segmentId?: string;
  channelType?: string;
  metadata?: any;
}) => {
  const queryString = CampaignQueries.SAVE_CAMPAIGN;

  const data = {
    query: queryString,
    variables: {
      name: args.name,
      description: args.description,
      isImmediate: args.isImmediate,
      startDate: args.startDate,
      numberOfRetry: args.numberOfRetry,
      retryDelayInDays: args.retryDelayInDays,
      emailTemplateId: args.emailTemplateId,
      segmentId: args.segmentId,
      segmentType: args.segmentType || '',
      segmentTypeValue: args.segmentTypeValue || '',
      channelType: args.channelType,
      metadata: args.metadata,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const updateCampaign = async (args: {
  campaignSegmentId?: string;
  campeignId: string | null;
  campaignSegmentData?: object;
  campeignData: object;
}) => {
  const queryString = args.campaignSegmentId
    ? CampaignQueries.UPDATE_CAMPAIGN
    : CampaignQueries.UPDATE_CAMPAIGN_WITHOUT_SEGMENT;

  const data = {
    query: queryString,
    variables: {...args},
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const updateCampaignStatus = async (args: {
  id: string;
  status: string;
  updatedBy: string;
}) => {
  const queryString = CampaignQueries.UPDATE_CAMPAIGN_STATUS;

  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const updateSingleNotificationReadStatus = async (args: {
  type: string;
  typeId: string;
  userId: string;
  isRead: true;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  const queryString =
    NotificationQueries.UPDATE_SINGLE_NOTIFICATION_READ_STATUS;

  const data = {
    query: queryString,
    variables: args,
  };

  const response = await axios.post('/crm-communication/graphql', data);
  const eventBus = EventBus.getEventBusInstance();
  eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NOTIFICATION_CREATED, {
    data: {hideScreenList: [{code: 'any', isRefresh: true}]},
  });
  return response.data.data;
};

export const updateAllNotificationReadStatus = async (args: {
  isRead: true;
  userType: 'USER';
  userId: string;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  const queryString = NotificationQueries.UPDATE_ALL_NOTIFICATION_READ_STATUS;

  const data = {
    query: queryString,
    variables: args,
  };

  const response = await axios.post('/crm-communication/graphql', data);
  return response.data.data;
};


export const getWorkflowListWithPage = async (
  searchString: string,
  flowType: FlowType,
  accountUUID: string,
  successHandler: any,
  errorHandler: any,
  isNeedNodes:boolean,
  pageInfo:{page:number, pageSize:number}
) => {
  const queryString = isNeedNodes ? NotificationQueries.GET_WORKFLOW_LIST_WITH_PAGE : NotificationQueries.GET_WORKFLOW_LIST_ONLY_WITH_PAGE;
  searchString = searchString || '';
  const variables:any = {
    boolExp: {
          name: {_ilike: `%${searchString}%`},
          isDeleted: {_eq: false},
          tenantId: {_eq: accountUUID},
        },
      offset: pageInfo.page * pageInfo.pageSize,
      limit: pageInfo.pageSize
  };
  if(flowType){
    variables.boolExp.flowType = {_eq: flowType};
  } else {
    variables.boolExp.flowType = {_nin: [FlowType.ivr,FlowType.careJourney]};
  }
  const data = {
    query: queryString,
    variables: variables
  };
  const response = await workflowService.post('/graphql/readonly', data);
  try {
    successHandler(response?.data?.data?.workflows || [], response?.data?.data?.aggregateWorkflow?.aggregate?.count || 0);
  } catch {
    errorHandler(response);
  }
  return response?.data.data?.workflows || [];
};

export const getWorkflowList = async (
  searchString: string,
  flowType: FlowType,
  accountUUID: string,
  successHandler: any,
  errorHandler: any,
  isNeedNodes:boolean,
  triggerCondition?:string,
  isActive?: boolean,
  allowedFlowType?: FlowType
) => {
  const queryString = isNeedNodes ? NotificationQueries.GET_WORKFLOW_LIST : NotificationQueries.GET_WORKFLOW_LIST_ONLY;
  searchString = searchString || '';
  const variables:any = {
    boolExp: {
          name: {_ilike: `%${searchString}%`},
          isDeleted: {_eq: false},
          tenantId: {_eq: accountUUID},
        }
  };
  if(flowType){
    variables.boolExp.flowType = {_eq: flowType};
  } else {
    variables.boolExp.flowType = {_nin: [FlowType.ivr,FlowType.careJourney]};
  }
  if(triggerCondition){
    variables.boolExp.triggerInfo = {triggerCondition:{_eq:triggerCondition}}
  }
  if(isActive != undefined){
    variables.boolExp.isEnabled = {
      _eq: isActive
    }
  }

  if (allowedFlowType) {
    variables.boolExp.flowTypeMap = {
      flowType: {
        _in: [allowedFlowType, FlowType.patients]
      }
    };
  }
  const data = {
    query: queryString,
    variables: variables
  };
  const response = await workflowService.post('/graphql/readonly', data);
  try {
    successHandler(response?.data?.data?.workflows || []);
  } catch {
    errorHandler(response);
  }
  return response?.data.data?.workflows || [];
};

export const getWorkflowById = async (
  workflowId: string,
  accountUUID: string,
  successHandler?: any,
  errorHandler?: any
) => {
  const queryString = NotificationQueries.GET_WORKFLOW_BY_ID;
  const data = {
    query: queryString,
    variables: {
      id: workflowId,
      tenantId: accountUUID,
    },
  };

  const response = await workflowService.post('/graphql/readonly', data);
  try {
    const workflowData = response?.data?.data?.workflows?.length
      ? response?.data?.data?.workflows[0]
      : [];
    const graph = workflowData?.graph || {};
    const triggerInfo = workflowData?.triggerInfo || {};
    delete workflowData.triggerInfo;
    delete workflowData.graph;
    const responseData = {
      workflow: workflowData,
      graph: graph,
      triggerInfo,
    };
    successHandler ? successHandler(responseData) : '';
    return responseData;
  } catch {
    errorHandler(response);
    return response;
  }
  return undefined;
};

export const getWorkflowInfoByMasterId = async (
  workflowMasterId: string,
  accountUUID: string
) => {
  const queryString = NotificationQueries.GET_WORKFLOW_INFO_BY_MASTER_ID;
  const data = {
    query: queryString,
    variables: {
      workflowMasterId: workflowMasterId,
      tenantId: accountUUID,
    },
  };

  const response = await workflowService.post('/graphql/readonly', data);
  try {
    const workflowData = response?.data?.data?.workflows?.length
      ? response?.data?.data?.workflows[0]
      : [];
    return workflowData;
  } catch {
    return response;
  }
};

export const getWorkflowCountByNameAndFlowType = async (
  name: string,
  tenantId: string
) => {
  const queryString = NotificationQueries.GET_WORKFLOW_COUNT_BY_NAME_AND_FLOWTYPE;
  const data = {
    query: queryString,
    variables: {
      name: name,
      tenantId: tenantId
    }
  };

  const response = await workflowService.post('/graphql/readonly', data);
  return response?.data?.data?.aggregateWorkflow?.aggregate;
};

export const getWorkflowByMasterId = async (
  workflowMasterId: string,
  accountUUID: string,
  successHandler: any,
  errorHandler: any
) => {
  const queryString = NotificationQueries.GET_WORKFLOW_BY_MASTER_ID;
  const data = {
    query: queryString,
    variables: {
      workflowMasterId: workflowMasterId,
      tenantId: accountUUID,
    },
  };

  const response = await workflowService.post('/graphql/readonly', data);
  try {
    const workflowData = response?.data?.data?.workflows?.length
      ? response?.data?.data?.workflows[0]
      : [];
    const graph = workflowData?.graph || {};
    const triggerInfo = workflowData?.triggerInfo || {};
    delete workflowData.triggerInfo;
    delete workflowData.graph;
    const responseData = {
      workflow: workflowData,
      graph: graph,
      triggerInfo,
    };
    successHandler ? successHandler(responseData) : '';
    return responseData;
  } catch {
    errorHandler(response);
    return response;
  }
  return undefined;
};


export const getWorkFlowListByEventEntity = async (
  entityEventList: any,
  tenantId: string,
  successHandler: any,
  errorHandler: any
) => {
  const queryString = WorkflowEntity.GET_WORKFLOW_BY_EVENT_ENTITY;
  const data = {
    query: queryString,
    variables: {
      tenantId: tenantId,
      entityEventConditionList: {
        _or: entityEventList.map((data: any) => {
          const condtion = {
            eventType: {
              _eq: data.eventType,
            },
            entity: {
              _eq: data.entity,
            },
          };
          return condtion;
        }),
      },
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  try {
    successHandler(response?.data?.data?.workflows || []);
  } catch {
    errorHandler(response);
  }
  return response?.data.data?.workflows || [];
};

export const getWorkFlowFormListByEventEntity = async (
  workflowMasterId: string,
  entity: string,
  eventType: string,
  successHandler: any,
  errorHandler: any
) => {
  const queryString = WorkflowEntity.GET_WORKFLOW_FORMLIST_BY_EVENT_ENTITY;
  const data = {
    query: queryString,
    variables: {
      entity: entity,
      eventType: eventType,
      entityEventConditionList: [workflowMasterId],
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  try {
    successHandler(response?.data?.data?.workflows || []);
  } catch {
    errorHandler(response);
  }
  return response?.data.data?.workflows || [];
};


export const getEmailAllStatusByTagsGroupByMessageId = async (
  tag: string,
  tagType: string,
  dateFilter?:{fromDate:string, toDate:string},
  eventCode?:string,
  isRequiredContact?:boolean
) => {
  const variables:any = {
    emailAnalyticLogBoolExp: {
        tags: {
            tag: {
                _eq:tag
            },
            tagType: {
                _eq: tagType
            }
        }
    }
  }
  if(eventCode){
    let eventCodeStr = eventCode == 'uniqueClicked' ? 'clicked' : eventCode;
    eventCodeStr = eventCodeStr == 'uniqueOpened' ? 'opened' : eventCodeStr;
    variables.emailAnalyticLogBoolExp.eventCode = {
      _eq: eventCodeStr
    }
  }
  if(dateFilter){
    variables.emailAnalyticLogBoolExp.createdOn = {
      _gte: dateFilter.fromDate,
      _lte: dateFilter.toDate
    }
  }
  const queryString = isRequiredContact ? WorkflowEntity.GET_EMAIL_ALL_STATUS_BY_TAGS_GROUP_BY_MESSAGE_ID_WITH_CONTACT : WorkflowEntity.GET_EMAIL_ALL_STATUS_BY_TAGS_GROUP_BY_MESSAGE_ID;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  const responseData = response?.data?.data;
  const emailAnalyticLogs = eventCode == 'uniqueOpened' || eventCode== 'uniqueClicked' ? [] : responseData?.emailAnalyticLogs || [];
  const emailAnalyticLogsUnique = responseData?.emailAnalyticLogsUnique || [];
  emailAnalyticLogsUnique.forEach((data:any)=>{
    if(data?.eventCode == 'opened' && (!eventCode || eventCode== 'uniqueOpened')){
      emailAnalyticLogs.push({
        ...data,
        eventCode: 'Unique Opened'
      })
    }else if(data?.eventCode == 'clicked' && (!eventCode || eventCode== 'uniqueClicked')){
      emailAnalyticLogs.push({
        ...data,
        eventCode: 'Unique Clicked'
      })
    } else if(data?.eventCode !=  'opened' && data?.eventCode !=  'clicked' ) {
      emailAnalyticLogs.push({
        ...data
      })
    }
  })
  return emailAnalyticLogs;
};



export const getEmailStatusByTags = async (
  tag: string,
  tagType: string
) => {
  const queryString = WorkflowEntity.GET_EMAIL_STATUS_BY_TAGS;
  const data = {
    query: queryString,
    variables: {
      tagId: tag,
      tagType: tagType
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  const responseData = response?.data?.data;
  const emailAnalyticTags = responseData?.emailAnalyticTags || [];
  const parseResponse = {
    opened:0,
    clicked:0
  }
  emailAnalyticTags.forEach((emailAnalyticTag:any)=>{
    const logs = emailAnalyticTag?.logs || [];
    logs.forEach((log:any)=>{
      if(log?.eventCode == 'opened'){
        parseResponse.opened = parseResponse.opened  +1;
      }
      if(log?.eventCode == 'clicked'){
        parseResponse.clicked = parseResponse.clicked  +1;
      }
    });
  })
  return parseResponse
};

export const deleteEmployeeGroup = async (args: {segmentId: any}) => {
  const queryString = EmployeeQueries.deleteEmployeeGroup;
  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response?.data.data;
};

export const updateEmployeeSegment = async (args: {
  segmentId: any;
  name: string;
  metadata: any;
  description?: string;
}) => {
  const queryString = EmployeeQueries.updateEmployeeGroup;
  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response?.data.data;
};

export const getContactList = async (args: {segmentId: any}) => {
  const queryString = EmployeeQueries.getEmployeeListByGroup;
  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response?.data.data;
};

export const getMembersByMetadata = (
  patientFilter: any,
  limit: number,
  offset: number
) => {
  let queryParams = '';
  if (patientFilter) {
    queryParams = `?action=getGroupMembers&patientFilter=${JSON.stringify(
      patientFilter
    )}&limit=${limit}&offset=${offset}`;
  }

  return workflowService.get(`/workflow/api/v2/group${queryParams}`);
};

export const getMemberListBySegmentId = (args: {
  limit?: number;
  offset?: number;
  segmentId: string;
}) => {
  const {limit, offset, segmentId} = args;
  let queryParams = `?action=getGroupMembers&segmentId=${segmentId}`;
  if (limit) {
    queryParams += `&limit=${limit}`;
  }
  if (offset != undefined) {
    queryParams += `&offset=${offset}`;
  }
  return workflowService.get(`/workflow/api/v2/group${queryParams}`);
};

export const getMemberGroupListByContactId = async (args: {
  accountUuid: any;
  contactId: any;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(
    `rule-engine/api/results?contactId=${args.contactId}`,
    {
      headers: {
        accountUuid: args.accountUuid,
      },
    }
  );
  return response?.data || [];
};

export const getMemberGroupListByMultipleContactIds = async (args: {
  accountUuid: string;
  contactIds: string[];
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get<{
    data: IMultipleMemberGroupResponse[];
  }>(`rule-engine/api/results?contactId=${args.contactIds.join(',')}`, {
    headers: {
      accountUuid: args.accountUuid,
    },
  });
  return response?.data?.data || [];
};

export const getMemberMatchingContactList = async (args: {
  userInputData: any;
  filterType: string;
  contactUuidList?: [string]
}) => {
  const contactUuidList = args?.contactUuidList;
  if(contactUuidList && contactUuidList?.length > 1500){
    delete args?.contactUuidList;
  }

  const data = {...args};
  const response = await workflowService.post('/workflow/api/v2/filter', data);
  if(contactUuidList && contactUuidList?.length > 1500){
    response.data.contactList = response.data.contactList.filter((uuid:string)=>{
      return contactUuidList.indexOf(uuid) != -1
    });
  }
  return response.data;
};

export const getWorkflowExecutionData = async (args: {
  workflowMasterId: string;
  limit: number;
  offset: number;
  resourceType?: string;
}) => {
  const {limit, offset, workflowMasterId, resourceType} = args;
  const queryString = WorkflowExecutionLogQueries.GET_WORKFLOW_EXECUTION_LOG;
  const data = {
    query: queryString,
    variables: {
      workflowMasterId,
      limit,
      offset,
      resourceType: resourceType || 'CONTACT'
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const getEmailCommunicationLogData = async (args: {
  workflowExecutionId?: string;
  workflowMasterId?: string;
  logTypeList?: string[];
  campaignId?: string;
  uiNodeId?: string;
  contactId?: string;
  fromDate?: string;
  toDate?: string;
  to?: string;
  limit: number;
  offset: number;
  emailStatus?:string;
  smsStatus?:string;
  isFetchOnlyMetadata?: boolean
  status?:string;
}) => {
  const fromDate = args.fromDate;
  const toDate = args.toDate;
  let dateFilter: any;
  if (fromDate || toDate) {
    dateFilter = {};
    if (fromDate) {
      dateFilter['_gte'] = fromDate;
    }
    if (toDate) {
      dateFilter['_lte'] = toDate;
    }
  }

  const logTypeList =  args?.logTypeList ? args?.logTypeList : ['EMAIL_SEND', 'PUSH_NOTIFICATION_SEND', 'SMS_SEND'];
  const variables: any = {
    serviceLogBoolExp: {
      logType: {
        _in: logTypeList,
      },
    },
  };
  if(!args.isFetchOnlyMetadata){
    variables.status = args.status ? [args.status] : ['COMPLETE', 'ERROR', 'IN_PROGRESS'];
  }

  if(args.limit != undefined){
    variables.limit = args.limit
  }

  if(args.offset != undefined){
    variables.offset = args.offset
  }


  if (dateFilter) {
    variables.serviceLogBoolExp.createdOn = dateFilter;
  }

  if (args.campaignId) {
    variables.serviceLogBoolExp.campaignId = {
      _eq: args.campaignId,
    };
  }
  if (args.workflowExecutionId) {
    variables.serviceLogBoolExp.workflowExecutionId = {
      _eq: args.workflowExecutionId,
    };
  }

  if (args.workflowMasterId || args.uiNodeId || args.contactId) {
    variables.serviceLogBoolExp.nodeLog = {};
    if (dateFilter) {
      variables.serviceLogBoolExp.nodeLog.createdOn = dateFilter;
    }
  }

  if (args.workflowMasterId) {
    variables.serviceLogBoolExp.workflowMasterId = {
      _eq: args.workflowMasterId
    }
  }

  if (args.uiNodeId) {
    variables.serviceLogBoolExp.nodeLog.uiNodeId = {
      _eq: args.uiNodeId?.slice(0, 65),
    };
  }
  if((args.smsStatus == 'failed' || args.smsStatus == 'undelivered') && logTypeList.indexOf('SMS_SEND') != -1 ){
    variables.serviceLogBoolExp.status = {
      _eq: "ERROR"
    }
  } else if(args.smsStatus && logTypeList.indexOf('SMS_SEND') != -1) {
    variables.serviceLogBoolExp.status = {
      _eq: 'COMPLETE'
    }
  }
  if(args.emailStatus == 'failed' && logTypeList.indexOf('EMAIL_SEND') != -1 ){
    variables.serviceLogBoolExp['_or'] = [
        {
          emailAnalyticTag: {
          tagType: {
            _eq: 'communicationLogResponse'
          },
          logs: {
            eventCode: {
              _eq: args.emailStatus,
            },
          },
        }
      },
        {
          _not: {
            emailAnalyticTag: {
              id: {
                _is_null: false
              }
            }

          }
        }

      ]


  } else if (args.emailStatus && logTypeList.indexOf('EMAIL_SEND') != -1) {
    variables.serviceLogBoolExp.emailAnalyticTag = {
      tagType: {
        _eq: 'communicationLogResponse'
      },
      logs: {
        eventCode: {
          _eq: args.emailStatus,
        },
      },
    };

  }
  if (args.contactId) {
    variables.serviceLogBoolExp.nodeLog.resourceLevelLog = {
      resourceType: {
        _eq: 'CONTACT',
      },
      resourceId: {
        _eq: args.contactId,
      },
    };
  }
  const queryString = args.isFetchOnlyMetadata ? WorkflowExecutionLogQueries.GET_EMAIL_COMMUNICATION_LOG_METADATA : WorkflowExecutionLogQueries.GET_EMAIL_COMMUNICATION_LOG_V1;


  const data = {
    query: queryString,
    variables: variables,
  };
  // const response = await workflowService.post('/graphql', data);
  // return response.data.data;

  return new Promise((resolve, reject) => {
    // Add the request to the queue
    WORKFLOW_CONCURRENT_REQUEST.requestQueue.push({
      config: { url: '/graphql/readonly', method: 'post', data },
      resolve,
      reject,
    });

    // Process the request queue
    processRequestQueue();
  });
};

const processRequestQueue = async () => {
  // If there are pending requests and we have not reached the maximum concurrent requests limit
  while (WORKFLOW_CONCURRENT_REQUEST.requestQueue.length > 0 && WORKFLOW_CONCURRENT_REQUEST.concurrentRequests < WORKFLOW_CONCURRENT_REQUEST.maxConcurrentRequests) {
    const { config, resolve, reject } = WORKFLOW_CONCURRENT_REQUEST.requestQueue.shift(); // Dequeue request

    try {
      WORKFLOW_CONCURRENT_REQUEST.concurrentRequests++; // Increment concurrent requests count
      const response = await workflowService.request(config); // Make the request using the workflowService instance
      resolve(response?.data?.data); // Resolve promise with response
    } catch (error) {
      reject(error); // Reject promise with error
    } finally {
      WORKFLOW_CONCURRENT_REQUEST.concurrentRequests--; // Decrement concurrent requests count
    }
  }
};

export const getNodeLevelExecutionStatus = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_NODE_LEVEL_EXECUTION_STATUS;
  delete variables?.nodeLogBoolExp;
  if(variables?.resourceLevelLogBoolExp?.workflowExecutionLog){
    delete variables?.resourceLevelLogBoolExp?.nodeLog?.workflowExecutionLog
  }
  const data = {
    query: queryString,
    variables: variables,
  };
  return new Promise((resolve, reject) => {
    // Add the request to the queue
    WORKFLOW_CONCURRENT_REQUEST.requestQueue.push({
      config: { url: '/graphql/readonly', method: 'post', data },
      resolve,
      reject,
    });

    // Process the request queue
    processRequestQueue();
  });
};

export const getNodeLevelExecutionStatusWithTime = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_NODE_LEVEL_EXECUTION_STATUS_WITH_TIME;

  delete variables?.resourceLevelLogBoolExp;
  if(!variables?.nodeLogBoolExp?.resourceLevelLog?.resourceId){
    delete variables?.nodeLogBoolExp?.resourceLevelLog;
  }
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};


export const checkStepFunctionRequestQueueStatus = async (queueId: any) => {
  const queryString =
    WorkflowExecutionLogQueries.CHECK_STEP_FUNCTION_REQUEST_QUEUE_STATUS;
  const data = {
    query: queryString,
    variables: {
      queueId: queueId
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};


export const getWorkflowExecutionIdByWorkflowId = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_WORKFLOW_EXECUTION_ID_BY_WORKFLOW_ID;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response?.data?.data?.workflowExecutionLogs?.[0]?.workflowExecutionId;
};

export const getNodeLevelExecutionStatusByWorkflowExecutionId = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_EXECUTION_ID;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response?.data?.data?.nodeLogs;
};

export const getNodeLevelExecutionStatusByWorkflowId = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_ID;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response?.data?.data?.nodeLogs;
};

export const getNodeLevelExecutionCount = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_NODE_LEVEL_EXECUTION_COUNT;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const getTotalTimeSavedByWorkflow = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_TOTAL_TIME_SAVED_BY_WORKFLOW;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const getNodeLevelResourceLogByBoolExp = async (
  resourceLevelLogBoolExp: any,
  limit?:number,
  offset?:number
) => {
  const variables:any = {
    resourceLevelLogBoolExp: resourceLevelLogBoolExp,
  }
  if(limit != undefined){
    variables.limit = limit
  }
  if(offset != undefined){
    variables.offset = offset
  }
  const queryString =
    WorkflowExecutionLogQueries.GET_NODE_LEVEL_RESOURCE_LOG_BY_BOOL_EXP;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const getServiceLogs = async (query: any) => {
  const queryString = WorkflowExecutionLogQueries.GET_SERVICE_LOGS;
  const data = {
    query: queryString,
    variables: {
      where: query,
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const getServiceLogsByWorkflowExecutionID = async (workflowExecutionId:string, itemId?:string ) => {
  const variables: {
    workflowExecutionId: any,
    id ?: any
  } = {
    workflowExecutionId: {}
  };
  if(itemId){
    variables.id = {'_eq': itemId};
  }
  variables.workflowExecutionId = {'_eq': workflowExecutionId};
  const queryString = WorkflowExecutionLogQueries.GET_SERVICE_LOGS_BY_WORKFLOW_EXECUTION_ID;

  const data = {
    query: queryString,
    variables: {where: variables},
  }

  const response = await workflowService.post('/graphql/readonly', data);

  return response?.data?.data;
}

export const getUserActionMasters = async (args: {level: string}) => {
  const {level} = args;
  const queryString = UserQueries.GET_USER_ACTION_MASTERS;
  const data = {
    query: queryString,
    variables: {level},
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getAllUserActionMastersData = async (variables: any) => {
  const queryString = UserQueries.GET_ALL_USER_ACTION_MASTERS_DATA;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getSingleUserAction = async (args: {
  userId: string;
  actionCode: string;
}) => {
  const {userId, actionCode} = args;
  const queryString = UserQueries.GET_SINGLE_USER_ACTION_BY_ACTION_CODE;
  const data = {
    query: queryString,
    variables: {
      userId,
      actionCode,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getCampaignByResourceId = async (contactId: string) => {
  const queryString = WorkflowExecutionLogQueries.GET_CAMPAIGN_BY_RESOURCE_ID;
  const data = {
    query: queryString,
    variables: {
      contactId: contactId,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const insertUserAction = async (args: {
  actionCode: UserActionCode;
  level: string;
  status: UserActionStatus;
  userId: string;
  metadata?: any;
}) => {
  const queryString = UserQueries.INSERT_USER_ACTION;
  const variables = {
    actionCode: args.actionCode,
    level: args.level,
    status: args.status,
    userId: args.userId,
    metadata: args.metadata,
  };

  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data;
};

export const getWorkflowMasterIdByContactId = async (contactId: string) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_WORKFLOW_MASTER_ID_BY_CONTACT_ID;
  const data = {
    query: queryString,
    variables: {
      contactId: contactId,
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const getWorkflowByWorkflowMasterId = async (
  workflowMasterIdList: string[]
) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_WORKFLOW_BY_WORKFLOW_MASTER_ID;
  const data = {
    query: queryString,
    variables: {
      workflowMasterIdList: workflowMasterIdList,
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response.data.data;
};

export const aggregateUserAction = async (args: {
  level: string;
  userId: string;
}) => {
  const queryString = UserQueries.AGGREGATE_USER_ACTION;
  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const aggregateUserActionMaster = async (args: {level: string}) => {
  const queryString = UserQueries.AGGREGATE_USER_ACTION_MASTER;
  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

// ====== New Rules engine API Start:: AUTHOR::ABHAY

export const getGroupsList = async (
  accountUuid: string,
  limit: number,
  name: string,
  offset?: number,
  category?: CampaignSegmentCategories,
  contactType?:string,
  ruleIds?: string[],
  sort?: string,
  filter?: string
) => {
  if (!offset) {
    offset = 0;
  }
  if (!limit) {
    limit = 100;
  }
  const baseService = BaseService.getSharedInstance().axios;

  const url = `rule-engine/api/results`;

  return baseService.get(url, {
    headers: {
      accountUuid: accountUuid,
    },
    params: {
      ...(offset ? {offset} : {}),
      ...(limit ? {limit} : {}),
      ...(category ? {category} : {}),
      ...(contactType ? {contactType} : {}),
      ...(ruleIds ? {ruleIds: ruleIds.join(',')} : {}),
      ...(name ? {name} : {}),
      ...(sort ? {sort} : {}),
      ...(filter ? {type: filter} : {}),
    },
  });
};

export const getLeadAndPopulationGroupsForCampaign = (data: {
  accountUuid: string;
  limit?: number;
  name: string;
  offset?: number;
  ruleIds?: string[];
}) => {
  const {accountUuid, name, ruleIds} = data;
  let {limit, offset} = data;
  if (!data.offset) {
    offset = 0;
  }
  if (!limit) {
    limit = 100;
  }
  const baseService = BaseService.getSharedInstance().axios;
  let url = `rule-engine/api/results?offset=${offset}&limit=${limit}`;
  if (name) {
    url = url + `&name=${name}`;
  }
  if (ruleIds && ruleIds?.length > 0) {
    url = url + `&ruleIds=${ruleIds.join(',')}`;
  }
  url = url + `&contactType=LEAD,PATIENT`;
  return baseService.get(url, {
    headers: {
      accountUuid: accountUuid,
    },
  });
};

export const getContactsWithinGroup = (
  id: string,
  isActive: boolean | undefined,
  membershipStatus?: string
) => {
  const baseService = BaseService.getSharedInstance().axios;
  return baseService.get(
    `rule-engine/api/results?ruleId=${id}&sendAll=true${
      isActive != undefined ? '&isActive=' + isActive : ''
    }${membershipStatus ? '&memberStatus=' + membershipStatus : ''}`
  );
};

export const getGroupDetailById = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const groupDetails = await baseService.get(`rule-engine/api/rules/${id}`);
  if (groupDetails?.data?.type === 'TEMP') {
    groupDetails.data.name = '';
  }
  return groupDetails;
};

export const getRuleEngineCodes = async (
  searchType: CodeSearchType
) => {
  const baseService = BaseService.getSharedInstance().axios;
  return baseService.get(`rule-engine/api/codes?searchType=${searchType}`);
};

export const getExecutedWorkflows = async (args: {
  resourceId: string;
  tenantId: string;
  resourceType: string;
}) => {
  const queryString = WorkflowExecutionLogQueries.GET_EXECUTED_WORKFLOWS;
  const data = {
    query: queryString,
    variables: args,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const createEmployeeGroup = async (args: {
  name: string;
  metadata: any;
  description?: string;
  type: string;
  tenantId: any;
  externalId: any;
}) => {
  return createCampaignSegment(args);
};

export const getCampaignSegmentRules = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;

  const response = await baseService.get('rule-engine/api/rules/' + id);

  return response.data;
};

export const deleteCampaignSegmentRules = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;

  const response = await baseService.delete('rule-engine/api/rules/' + id);

  return response.data;
};

export const executeCampaignSegmentRule = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.put('rule-engine/api/rules/execute?ruleId=' + id);
  return response.data;
};

export const createCampaignSegment = async (args: {
  id?: string;
  name: string;
  description?: string;
  tenantId?: string;
  metadata?: {
    patientFilter?: any;
  };
  isScheduleSuggestionRule?: boolean;
  preFilter?: { memberStatus?: string; };
  executionFrequency?: string;
  popupationGroupId?: string;
}) => {
  let body: any = {};
  if (args?.metadata?.patientFilter?.contactIds) {
    body = {
      rule: {
        patientFilter: args?.metadata?.patientFilter,
      },
      type: args.name ? 'STATIC' : 'TEMP',
      name: args.name,
      description: args.name ? args.description || 'None' : undefined,
      displayJson: '',
      displayText: '',
      executionFrequency: !!args.popupationGroupId ? '' : args.executionFrequency,
      ...(args.popupationGroupId && { inputRuleId: args.popupationGroupId }),
    };
  } else {
    const queryBuilderProcessedData = processQueryBuilderJSON(
      args?.metadata?.patientFilter?.jsonTree
    );
    body = {
      preFilter: {
        hintMembershipStatus: {
          operator: "equal",
          value: true,
          params: {
            value: [!!args.popupationGroupId ? MEMBERSHIP_STATUS_ALL : args?.preFilter?.memberStatus]
          }
        }
      },
      rule: {
        conditions: queryBuilderProcessedData.conditions,
        event: {
          type: 'testing',
        },
      },
      type: 'DYNAMIC',
      name: args.name,
      description: args.description || 'None',
      displayJson: queryBuilderProcessedData.jsonTree,
      displayText: '',
      ...(args.isScheduleSuggestionRule && {
        categories: [
          {
            category: 'Appointment',
          },
        ],
      }),
      executionFrequency: !!args.popupationGroupId ? '' : args.executionFrequency,
      ...(args.popupationGroupId && { inputRuleId: args.popupationGroupId }),
    };
  }

  const baseService = BaseService.getSharedInstance().axios;
  const method = args.id ? baseService.put : baseService.post;
  const url = `rule-engine/api/rules${args.id ? '/' + args.id : ''}`;

  const response = await method(url, body);

  return response.data;
};

export const getUserActionMasterData = async () => {
  const queryString = UserQueries.GET_USER_ACTION_MASTER_DATA;
  const data = {
    query: queryString,
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getCampaignByResourceIdList = async (contactIdList: string[]) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_CAMPAIGN_BY_RESOURCE_ID_LIST;
  const data = {
    query: queryString,
    variables: {
      contactIdList: contactIdList,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getAccountLevelUserAction = async (args: {
  tenantId: string;
  actionCode: string;
}) => {
  const {tenantId, actionCode} = args;
  const queryString = UserQueries.GET_ACCOUNT_LEVEL_USER_ACTION;
  const data = {
    query: queryString,
    variables: {
      tenantId,
      actionCode,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const searchCampaigns = async (args: {
  tenantId: string;
  searchString: string;
}) => {
  const {tenantId, searchString} = args;
  const queryString = CampaignQueries.SEARCH_CAMPAIGNS;
  const data = {
    query: queryString,
    variables: {
      tenantId: tenantId,
      searchString: `%${searchString}%`,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};

export const getResourceLevelLogs = async (query: any) => {
  const queryString = WorkflowExecutionLogQueries.GET_RESOURCE_LEVEL_LOGS;
  const data = {
    query: queryString,
    variables: {
      where: query,
    },
  };
  const response = await workflowService.post('/graphql', data);
  return response.data.data;
};


export const exportWorkflowData = async (payload:any) => {
  const data = {
    action: 'exportWorkflow',
    payload: payload
  };
  const response = await workflowService.post('/workflow/tenants/action', data);
  return response.data;
};

export const exportCampaign = async (payload:any) => {
  const data = {
    action: 'exportCampaign',
    payload: payload
  };
  const response = await workflowService.post('/workflow/tenants/action', data);
  return response.data;
};

export const getLeadGroups = (args: {
  accountUuid: string;
  name: string;
  limit?: number;
  offset?: number;
  sort?: string,
  filter?: string
}) => {
  const {accountUuid, name, sort, filter} = args;
  let {limit, offset} = args;
  const baseService = BaseService.getSharedInstance().axios;
  if (!offset) {
    offset = 0;
  }
  if (!limit) {
    limit = 100;
  }

  const url = `rule-engine/api/results`;
  return baseService.get(url, {
    headers: {
      accountUuid: accountUuid,
    },
    params: {
      ...(offset ? {offset} : {}),
      ...(limit ? {limit} : {}),
      ...(name ? {name} : {}),
      ...(sort ? {sort} : {}),
      ...(filter ? {type: filter} : {}),
      contactType: 'LEAD',

    },
  });
};

export const createOrUpdateLeadGroups = async (args: {
  id?: string;
  name: string;
  description?: string;
  tenantId?: string;
  metadata?: {
    patientFilter?: any;
  };
  executionFrequency?: any;
  popupationGroupId?: string;
}) => {
  const body = getRequestBodyForCreateOrUpdateLeadGroups(args)
  const baseService = BaseService.getSharedInstance().axios;
  const method = args.id ? baseService.put : baseService.post;
  const url = `rule-engine/api/rules${args.id ? '/' + args.id : ''}`;
  const response = await method(url, body);
  return response.data;
};

const getRequestBodyForCreateOrUpdateLeadGroups = (args: {
  id?: string;
  name: string;
  description?: string;
  tenantId?: string;
  metadata?: {
    patientFilter?: any;
  };
  executionFrequency?: any;
  popupationGroupId?: string;
}) => {
  let body: any = {};
  if (args?.metadata?.patientFilter?.contactIds) {
    body = {
      rule: {
        patientFilter: args?.metadata?.patientFilter,
      },
      type: args.name ? 'STATIC_LEAD' : 'TEMP_LEAD',
      name: args.name,
      description: args.name ? args.description || 'None' : undefined,
      displayJson: '',
      displayText: '',
      executionFrequency: !!args.popupationGroupId ? '' : args.executionFrequency,
      ...(args.popupationGroupId && { inputRuleId: args.popupationGroupId }),
    };
  } else {
    const queryBuilderProcessedData = processQueryBuilderJSON(
      args?.metadata?.patientFilter?.jsonTree
    );
    body = {
      rule: {
        conditions: queryBuilderProcessedData.conditions,
        event: {
          type: 'testing',
        },
      },
      type: 'DYNAMIC_LEAD',
      name: args.name,
      description: args.description || 'None',
      displayJson: queryBuilderProcessedData.jsonTree,
      displayText: '',
      executionFrequency: !!args.popupationGroupId ? '' : args.executionFrequency,
      ...(args.popupationGroupId && { inputRuleId: args.popupationGroupId }),
    };
  }
  return body;
}
export const getScheduleSuggestions = async () => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(
   `rule-engine/api/results/contacts?category=Appointment`,
   {
    // query URL without using browser cache
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  }
 );
}

export const getSeededRulesList = async (
  accountUuid: string,
  limit: number,
  name: string,
  offset?: number,
) => {
  if (!offset) {
    offset = 0;
  }
  if (!limit) {
    limit = 100;
  }
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(`rule-engine/api/rules?source=global&limit=${limit}&offset=${offset}&name=${name}`,
    {
      headers: {
        accountUuid: accountUuid,
      }
    });
};

export const importSeededRule = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    parentId: id
  }
  const url = `rule-engine/api/rules`;
  const response = await baseService.post(url, body);
  return response.data;
}

export const getWorkflowListForTableView = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_WORKFLOW_LIST_FOR_TABLE_VIEW;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql', data);
  return response;
};

export const getMicroAutomationUsedCountByWorkflowMasterId = async (variables: any) => {
  const queryString =
    WorkflowExecutionLogQueries.GET_MICRO_AUTOMATION_USED_COUNT_BY_WORKFLOW_MASTER_ID;
  const data = {
    query: queryString,
    variables: variables,
  };
  const response = await workflowService.post('/graphql', data);
  return response;
};



// export const getCampaignDataById = async (
//   campaignId: string
// ) => {
//   const queryString =
//     NotificationQueries.GET_WORKFLOW_EXECUTION_DATA_BY_WORKFLOW_MASTER_ID;
//   const data = {
//     query: queryString,
//     variables: {
//       workflowMasterId: workflowMasterId,
//       masterId: workflowMasterId
//     },
//   };

//   const response = await workflowService.post('/graphql', data);
//   return response?.data || undefined;
// };


export const getOpenAiConversation = async (args: {
  userUuid: string;
  tenantId: string;
  offset:number,
  limit:number
}) => {
  const queryString = WorkflowExecutionLogQueries.GET_OPEN_AI_CONVERSATION;
  const data = {
    query: queryString,
    variables: {
      offset:args.offset,
      limit:args.limit,
      object: {
        userUuid: {
          _eq: args.userUuid
        },
        tenantId: {_eq:  args.tenantId},
        isDeleted: {
          _eq: false
        }
      }
    }
  };
  const response = await workflowService.post('/graphql', data);
  return response.data?.data?.openAiConversations;
};


export const sendMessageToOpenAi = (
  message: string
) => {

  return workflowService
  .post(`/workflow/openAi`,  {
    message: message,
  })
};

export const getEnabledWorkflowList = async (
  flowType: FlowType,
  accountUUID: string,
) => {
  const queryString = NotificationQueries.GET_ENABLED_WORKFLOW_LIST;
  const variables: any = {
    boolExp: {
      isDeleted: {_eq: false},
      tenantId: {_eq: accountUUID},
      isEnabled: {_eq: true},
      flowType: {_eq: flowType},
      entity: {_eq: 'userTask'}
    },
  };
  const data = {
    query: queryString,
    variables,
  };
  return workflowService.post('/graphql', data);
};

export const getPushNotificationTemplate = async (templateID?: string) => {
  if (templateID) {
    const response = await getTemplateById(
      ContentTypes.pushNotifications.path,
      templateID?.toString()
    );
    return response?.data
  }
  return
}

export const getSMSTemplate = async (templateID?: string) => {
  if (templateID) {
    const response = await getTemplateById(
      ContentTypes.textMessages.path,
      templateID?.toString()
    );
    return response?.data
  }
  return
}

export const getEmailTemplate = async (templateID?: string) => {
  if(templateID){
    const response = await getTemplateById(
      ContentTypes.emails.path,
      templateID?.toString()
    );
    return response?.data
  }
  return
}
export const getPatientEnrollmentWorkflows = async (
  flowType: FlowType,
  triggerCondition: string,
  successHandler?: any,
  errorHandler?: any
): Promise<any[]> => {
  const queryString = WorkflowEntity.GET_PATIENT_ENROLLMENT_WORKFLOW_LIST;

  const variables: any = {
    boolExp: {
      isDeleted: {_eq: false},
      flowType: {_eq: flowType},
      triggerInfo: {triggerCondition: {_eq: triggerCondition}},
    },
  };

  const data = {
    query: queryString,
    variables: variables,
  };

  try {
    const response = await workflowService.post('/graphql/readonly', data);
    successHandler?.(response?.data?.data?.workflows || []);
    return response?.data?.data?.workflows || [];
  } catch (error) {
    errorHandler?.(error);
    return [];
  }
};

const collectPromises = (
  nodes: any,
  inputFieldType: string,
  getTemplate: (id: string) => Promise<any>
): Promise<any>[] => {
  const promiseList: Promise<any>[] = [];

  nodes?.forEach((node: any) => {
    const metadata = node?.data?.metaData;
    if (metadata?.userInputFieldList?.length > 0) {
      const userInputFieldList = metadata?.userInputFieldList;
      userInputFieldList.forEach((input: any) => {
        if (input?.fieldType === inputFieldType && input?.value?.template?.id) {
          promiseList.push(getTemplate(input?.value?.template?.id));
        }
      });
    }
  });

  return promiseList;
};



export const updateEmailNames = async (nodes: any) => {
  const promiseList = collectPromises(
    nodes,
    INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH,
    getEmailTemplate
  );

  try {
    const responseList = await Promise.all(promiseList);

    updateNodeInformation(
      responseList,
      nodes,
      INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH,
      (input, template) => {
        input.value.template.attributes.name = template?.attributes?.name;
        input.value.subject = template?.attributes?.subject;
      }
    );
    return nodes;
  } catch (error) {
  }
};

export const updateSMSNames = async (nodes: any) => {
  const promiseList = collectPromises(
    nodes,
    INPUT_FIELD_TYPE.GENERAL_SMS_TEMPLATE_SEARCH,
    getSMSTemplate
  );

  try {
    const responseList = await Promise.all(promiseList);

    updateNodeInformation(
      responseList,
      nodes,
      INPUT_FIELD_TYPE.GENERAL_SMS_TEMPLATE_SEARCH,
      (input, template) => {
        input.value.template.attributes.name = template?.attributes?.name;
        input.value.body = template?.attributes?.body;
      }
    );
    return nodes;
  } catch (error) {
  }
};

export const updatePushNotificationNames = async (nodes: any) => {
  const promiseList = collectPromises(
    nodes,
    INPUT_FIELD_TYPE.GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH,
    getPushNotificationTemplate
  );

  try {
    const responseList = await Promise.all(promiseList);

    updateNodeInformation(
      responseList,
      nodes,
      INPUT_FIELD_TYPE.GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH,
      (input, template) => {
        input.value.template.attributes.name = template?.attributes?.name;
        input.value.subject = template?.attributes?.subject;
        input.value.body = template?.attributes?.body;
      }
    );
    return nodes;
  } catch (error) {
  }
};

export const getWorkflowExecutionLogByWorkflowMasterId = async (workflowMasterId: string) => {
  const queryString = WorkflowExecutionLogQueries.GET_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID;
  const data = {
    query: queryString,
    variables: {
      workflowMasterId: workflowMasterId,
    },
  };
  const response = await workflowService.post('/graphql/readonly', data);
  return response?.data || undefined;
};


