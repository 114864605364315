import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
  },

  tabsWrapper: {
   // marginHorizontal: 8,
  },
});
