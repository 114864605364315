import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 12,
  },
  formLabel: {
    marginLeft: 4,
  },
});
