import {Drawer} from "antd"
import {BUTTON_TYPE, categoryAlreadyExistMessage} from "../../../../../constants";
import {FormControl,HStack,Input,Text,VStack, useToast} from "native-base";
import {ModalActionTitle} from "../../../../common/ModalActionTitle/ModalActionTitle";
import {Colors} from "../../../../../styles";
import {useIntl} from "react-intl";
import {styles} from '../../../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/CommonStyles';
import {DisplayText} from "../../../../common/DisplayText/DisplayText";
import {useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import FormsQueries, {GET_FORM_CATEGORY_BY_CODE, GET_FORM_CATEGORY_BY_NAME} from "../../../../../services/Forms/FormsQueries";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../constants/Configs";
import {generateUUID, getAccountUUID} from "../../../../../utils/commonUtils";
import {ToastType, showToast} from "../../../../../utils/commonViewUtils";
import {IAddFormCategoryState} from "../../interfaces";
import {StrCaseConverter} from "../../../../../utils/CaseConverter";

export const AddFormCategories=(props: {onActionPerformed: (actionCode?:string) => void}) => {
  const [stateData,setStateData]=useState<IAddFormCategoryState>({
    categoryName: '',
    categoryNameAlreadyTaken: false,
    description: '',
    isLoading: false,
    templateCode: '',
  })
  const drawerWidth='40%';
  const intl=useIntl()
  const toast = useToast();
  const accountUuid = getAccountUUID();

  const [getTemplateByName] = useLazyQuery(GET_FORM_CATEGORY_BY_NAME, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (data: any) => {
      setStateData((prev) => ({
        ...prev,
        categoryNameAlreadyTaken: data?.formCategories?.length > 0,
        isLoading: false
      }));
    },
    onError: (error) => {
      showToast(
        toast,
        'error in checking duplicate category name api',
        ToastType.error,
        1500,
      );
      setStateData((prev) => ({
        ...prev,
        isLoading: false
      }));
    },
  });

  const [getTemplateByCode] = useLazyQuery(GET_FORM_CATEGORY_BY_CODE);

  const [addNewFormCategory] = useMutation(FormsQueries.ADD_NEW_FORM_CATEGORY);

  const isTemplateNameAlreadyTaken = (tempName: string) => {
    setStateData((prev)=>{
      return {
        ...prev,
        isLoading: true
      }
    })
    const timerId = setTimeout(() => {
      getTemplateByName({
        variables: {
          searchString: tempName,
          tenantId: accountUuid,
        },
      });
    }, 500);
  };

  const isTemplateCodeAlreadyTaken = async (tempCode: string) => {
    const checkTemplateByName = await getTemplateByCode({
      variables: {
        searchString: tempCode,
        tenantId: accountUuid,
      },
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    });
    return checkTemplateByName?.data?.formCategories?.length > 0
  };

  const addFormCategory = async ()=> {
    if (stateData?.categoryName) {
      let templateCode = StrCaseConverter.toConstantCase(stateData?.categoryName);
      const templateCodeAlreadyTaken = await isTemplateCodeAlreadyTaken(templateCode);
      if (templateCodeAlreadyTaken) {
        templateCode  = templateCode + "_" + generateUUID()
      }
      setStateData((prev)=>{
        return {
          ...prev,
          isLoading: true,
          templateCode: templateCode
        }
      })
      const  formCategoryData = {
        code: templateCode,
        description: stateData?.description,
        name: stateData?.categoryName,
      }
      addNewFormCategory({
        variables: {
          data: formCategoryData
        },
        onCompleted: (data) => {
          if (data?.createFormCategory?.id) {
            showToast(
              toast,
              intl.formatMessage({id: 'categoryAddedSuccessfully'}),
              ToastType.success,
              1500,
            );
            intl.formatMessage({id: 'mandatoryFieldError'}),
            setStateData((prev)=>{
              return {
                ...prev,
                isLoading: false
              }
            })
            props?.onActionPerformed('refetch')
          } else {
            showToast(
              toast,
              'error in adding new category',
              ToastType.error,
              1500,
            );
          }
          setStateData((prev)=>{
            return {
              ...prev,
              isLoading: false
            }
          })
        },
        onError(error) {
          showToast(
            toast,
            'error in adding new category',
            ToastType.error,
            1500,
          );
          setStateData((prev)=>{
            return {
              ...prev,
              isLoading: false
            }
          })
        },
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      });
    }
  }

  const checkButtonDisable = ()=> {
    if (stateData?.isLoading || !stateData?.categoryName){
      return true;
    }
    return false;
  }

  return <>
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        props?.onActionPerformed()
      }}
      visible={true}
      closable
      width={drawerWidth}
      style={{
        marginTop: -40
      }}
    >
      <ModalActionTitle
        title="createNewCategories"
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: intl.formatMessage({id: 'cancel'}),
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props?.onActionPerformed()
            },
          },
          {
            show: true,
            id: 2,
            btnText: intl.formatMessage({id: 'add'}),
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              addFormCategory();
            },
            isLoading: stateData?.isLoading,
            isDisabled: checkButtonDisable() || stateData?.categoryNameAlreadyTaken,
          },
        ]}
      />
      <VStack marginTop={12} mr={5} overflow={'hidden'}>
        <FormControl style={styles.formElement} isRequired>
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              textLocalId="categoryName"
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Input
            _focus={{borderColor: '#D0D5DD'}}
            placeholder="Category Name"
            onChangeText={(value: string) => {
              const categoryName=value.trim();
              if (categoryName) {
                isTemplateNameAlreadyTaken(categoryName);
              }
              setStateData((prev) => {
                return {
                  ...prev,
                  categoryName: categoryName,
                };
              });
            }}
          />
          {stateData?.categoryNameAlreadyTaken&&(
            <HStack marginTop={2} space={2}>
              <Text fontSize="xs" color={Colors.danger[500]}>
                {categoryAlreadyExistMessage}
              </Text>
            </HStack>
          )}
        </FormControl>

        <FormControl style={styles.formElement}>
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              textLocalId="description"
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Input
            _focus={{borderColor: '#D0D5DD'}}
            placeholder="Description"
            onChangeText={(value: string) => {
              const description = value.trim();
              setStateData((prev) => {
                return {
                  ...prev,
                  description: description
                };
              });
            }}
          />
        </FormControl>
      </VStack>

    </Drawer>
  </>
}
