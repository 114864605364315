import React from 'react';
import {Center, Text, View} from 'native-base';
import {styles} from './NoRouteMatchStyles';

export const NoRouteMatch = (props: {message?: string}) => {
  return (
    <View>
      <Center>
        <Text style={styles.container}>{props.message || 'Page Not found'}</Text>
      </Center>
    </View>
  );
};

export default NoRouteMatch;
