import { HStack, Skeleton, View, Text, useMediaQuery, useToast, FlatList, Input, VStack, Icon, Divider, ScrollView, Badge } from "native-base";
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { Select } from 'antd';
import { useContext, useEffect, useRef, useState } from "react";
import { APPOINTMENT_SEARCH_TYPE_CODES, APPOINTMENT_SEARCH_TYPE_VALUES, INITIAL_LIMIT } from "./WebinarSearchConstants";
import UserPracticeLocationQueries from "../../../services/Location/UserPracticeLocationQueries";
import { getAccountUUID, isVirtualLocationDisabled, isVirtualLocationEnabledInAvailability } from "../../../utils/commonUtils";
import { useLazyQuery, useQuery } from "@apollo/client";
import { APPOINTMENT_STATUS_CODES, LOCATION_TYPE_CODES, MLOV_CATEGORY, MLOV_CODES, USER_ROLE_CODES } from "../../../constants/MlovConst";
import { FilterWrapperComponent } from '../CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import LocationAutoComplete, { autoCompleteTypeCodes } from "../CalendarWidget/LocationAutoComplete/LocationAutoComplete";
import { IPAD_WIDTH, IPAD_MINI_WIDTH, DATE_FORMATS } from "../../../constants";
import { showToast, ToastType } from "../../../utils/commonViewUtils";
import UserAutoComplete, { userAutoCompleteTypeCodes } from "../CalendarWidget/UserAutoComplete/UserAutoComplete";
import { ILocation, ISearchFieldProps } from "../../../Interfaces";
import { TableWrapper } from "../TableWrapper";
import { AppointmentQueries } from "../../../services";
import { LimitTimerRaceResolvedValues } from "@aws-amplify/datastore";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../constants/Configs";
import { isUUID } from "./Utils";
import { debounce } from "lodash";
import { getAppointmentDateTime } from "../CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper";
import { Colors } from "../../../styles";
import { useContainerDimensions } from "../../CustomHooks/ContainerDimensionHook";
import { NoDataFound } from "../NoDataFound";
import { NoTagsSvg } from "../Svg";
import { Dimensions, Pressable } from "react-native";
import { getMlovListFromCategory } from "../../../utils/mlovUtils";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { getDateStrFromFormat, getDateToMomentISOStringInUtc, getFormattedDate } from "../../../utils/DateUtils";
import ReccuringAppointmentIcon from "../../../assets/Icons/ReccuringAppointmentIcon";
import { processDataRecurrence } from "../Audit/AuditViewHelper";
import { getSummaryString } from "../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/helpers";
import Feather from "react-native-vector-icons/Feather";


const { Option } = Select;

type IAccountUsers = {
  user: {
    name: string;
    uuid: string;
  }
}

type IAccountLocation = {
  uuid: string;
  practiceLocation: ILocation;
}

type ISearchedAppointments = {
  name: string
  id: string;
  startDateTime: string;
  endDateTime: string;
  accountLocationId?: string;
  locationType: {
    value: string;
  }
  seriesId?: string;
  isRecurrentAppointment?: boolean;
  reference?: any;
}

type IComponentState = {
  code?: keyof typeof APPOINTMENT_SEARCH_TYPE_CODES;
  searchValueData?: {
    selectedAccountLocation?: IAccountLocation;
    eventId?: string;
    eventName?: string;
    selectedAccountUser?: { key: string; label: string; value: string; };
    seriesId?: string;
  };
  appointmentData?: {
    seriesId?: string;
    isRecurrentAppointment?: boolean;
    reference?: any;
    eventName?: string;
    appointmentId?: string;
    location?: {
      name?: string;
    };
    date?: string;
    formattedDate?: string;
  }
  accountLocations?: IAccountLocation[];
  searchedAppointments?: ISearchedAppointments[];
}

type ICodeList = {
  key: number;
  id: string;
  value: string;
}

type IPaginationState = {
    loading: boolean;
    page: number;
    limit: number;
    total?: number;
    offset: number;
}

const codeList: ICodeList[] = [
  {
    key: 1,
    id: APPOINTMENT_SEARCH_TYPE_CODES.EVENT_NAME,
    value: APPOINTMENT_SEARCH_TYPE_VALUES.EVENT_NAME
  },
  {
    key: 2,
    id: APPOINTMENT_SEARCH_TYPE_CODES.EVENT_ID,
    value: APPOINTMENT_SEARCH_TYPE_VALUES.EVENT_ID
  },
  {
    key: 3,
    id: APPOINTMENT_SEARCH_TYPE_CODES.LOCATION,
    value: APPOINTMENT_SEARCH_TYPE_VALUES.LOCATION
  },
  {
    key: 4,
    id: APPOINTMENT_SEARCH_TYPE_CODES.PRACTITIONER,
    value: APPOINTMENT_SEARCH_TYPE_VALUES.PRACTITIONER,
  },
  {
    key: 5,
    id: APPOINTMENT_SEARCH_TYPE_CODES.SERIES_ID,
    value: APPOINTMENT_SEARCH_TYPE_VALUES.SERIES_ID,
  }
];


const WebinarSearch = (props: ISearchFieldProps) => {
  const { value, isShowError, onChange } = props;
  const [componentWidth, setComponentWidth] = useState(0);
  const [componentState, setComponentState] = useState<IComponentState>({
    code: props?.value?.code,
    appointmentData: props?.value?.appointmentData,
    searchValueData: (props?.value?.searchValueData?.selectedAccountLocation?.practiceLocation?.uuid || props?.value?.searchValueData?.selectedAccountUser?.value) ?
      props?.value?.searchValueData : undefined
  });
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const { height, width } = Dimensions.get('window');

  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const userSettings = mlovData.userSettings;
  const ehrCapabilities = mlovData.ehrCapabilities || [];
  const disAllowVirtualLocation = isVirtualLocationEnabledInAvailability(userSettings) ? false : isVirtualLocationDisabled(userSettings, ehrCapabilities);
  const appointmentTypeMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_TYPE_CATEGORY
  )
  const APPOINTMENT_TYPE_GROUP_SESSION_CATEGORY_ID = appointmentTypeMlovList?.find((category) => category?.code === MLOV_CODES.GROUP_SESSION)?.id;

  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

    const scheduleLocationTypeList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];
    const virtualLocationDetails = scheduleLocationTypeList?.find((type) => type?.code === LOCATION_TYPE_CODES.VIRTUAL);

  const cancelledStatusId = appointmentStatusList?.find((status) => status?.code === APPOINTMENT_STATUS_CODES.CANCELLED)?.id

  const scheduledStatusId = appointmentStatusList?.find((status) => status?.code === APPOINTMENT_STATUS_CODES.BOOKED)?.id

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const accountUUID = getAccountUUID();

  const handleMouseEnter = (id: string) => {
    setHoveredItem(id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleCallback = (
    code?: keyof typeof APPOINTMENT_SEARCH_TYPE_CODES,
    searchValueData?: string,
    appointmentData?: any
  ) => {
    if (!appointmentData) {
      onChange(undefined);
    }
    else {
      const responseValue = {
        code: componentState?.code,
        searchValueData: { ...componentState?.searchValueData, eventId: '', eventName: '' },
        appointmentData: appointmentData
      };
      onChange(responseValue);
    }
  };

  const getTimezoneOfAccountAndLocationsQuery = useQuery(
    UserPracticeLocationQueries.GetAccountPracticeLocations,
    {
      variables: { tenantId: accountUUID, roleCode: USER_ROLE_CODES.EMPLOYER },
      fetchPolicy: 'no-cache',
      onCompleted: async (res: any) => {
        const accountLocations = !!res?.accountLocations?.length ? res.accountLocations : []
        setComponentState((prev) => {
          return {
            ...prev,
            accountLocations: accountLocations
          };
        });
      },
      onError(err: any) {
        setComponentState((prev) => {
          return {
            ...prev,
            accountLocations: [],
          };
        });
        showToast(
          toast,
          'Error in fetching account locations',
          ToastType.info
        );
      }
    }
  );

  const getVariablesForAppointmentSearch = (args: {eventName?: string, eventId?: string, selectedAccountLocation?: IAccountLocation, seriesId?: string}) => {
    let params: any = {}
    if (args?.eventName) {
      params = {
        name: {
          _ilike: '%' + args?.eventName + '%'
        },
      }
    }
    else if (args?.eventId) {
      params = {
        id: {
          _eq: args?.eventId
        },
      }
    }
    else if (args?.seriesId) {
      params = {
        seriesId: {
          _eq: args?.eventId
        },
      }
    }
    else if (args?.selectedAccountLocation?.practiceLocation?.uuid) {
      if (
        args?.selectedAccountLocation?.practiceLocation?.uuid ===
        virtualLocationDetails?.id
      ) {
        params = {
          locationType: {
            id: {
              _eq: virtualLocationDetails.id
            }
          },
        };
      } else {
        params = {
          accountLocationId: {
            _eq: args?.selectedAccountLocation?.uuid,
          },
        };
      }
    }
    const formattedCurrentDateTime = getDateToMomentISOStringInUtc();
    params.appointmentType = {
      categoryId: {
        _eq: APPOINTMENT_TYPE_GROUP_SESSION_CATEGORY_ID
      }
    }
    params.startDateTime = {
      _gte: formattedCurrentDateTime
    }
    params.statusId = {
      _eq: scheduledStatusId
    }
    params.isDeleted = {
      _eq: false
    }
    return { where: params }
  }

  const filterWebinar = (searchedAppointments: ISearchedAppointments[]): ISearchedAppointments[]  => {
    if (!searchedAppointments || !searchedAppointments?.length) {
      return [];
    }
    const seriesIdList: string[] = [];
    const filterWebinars: ISearchedAppointments[] = [];
    for (const webinar of searchedAppointments) {
      const seriesId = webinar?.seriesId;
      if (seriesId && !seriesIdList.includes(seriesId)) {
        seriesIdList.push(seriesId);
        filterWebinars.push(webinar);
      }
      if (!seriesId) {
        filterWebinars.push(webinar);
      }
    }
    return filterWebinars;
  }
  const [getAppointments, {loading: getAppointmentsQueryloading}] = useLazyQuery(
    AppointmentQueries.GET_APPOINTMENTS_FOR_APPOINTMENT_SEARCH,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (res) => {
        const searchedAppointments: ISearchedAppointments[] = filterWebinar(res?.appointments || []);
        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: searchedAppointments,
          };
        });
      },
      onError(err) {
        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: [],
          };
        });
        showToast(
          toast,
          'Error in fetching appointments',
          ToastType.info
        );
      }
    }
  );

  const [getAppointmentsByPractitioner, {loading: getAppointmentsByPractitionerQuery}] = useLazyQuery(
    AppointmentQueries.GET_APPOINTMENTS_FROM_APPOINTMENT_PARTICIPANT,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: async (res) => {
        const searchedAppointments = res?.appointmentParticipants?.length
          ? res?.appointmentParticipants?.map((appointmentData: any) => appointmentData?.appointment)
          : [];
        const filterAppointments: ISearchedAppointments[] = filterWebinar(searchedAppointments || []);

        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: filterAppointments,
          };
        });
      },
      onError(err) {
        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: [],
          };
        });
        showToast(
          toast,
          'Error in fetching appointments',
          ToastType.info
        );
      }
    }
  );



  const getDataForLocationAutoComplete = () => {
    const mappedAccountLocation = componentState?.accountLocations?.map((location) => location?.practiceLocation) || [];
    if (virtualLocationDetails && !disAllowVirtualLocation) {
      mappedAccountLocation?.unshift(({
        id: virtualLocationDetails?.id,
        name: virtualLocationDetails?.value,
        uuid: virtualLocationDetails?.id
      }))
    }
    return mappedAccountLocation;
  }

  const handleOnChangeOfEventId = (data: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        searchValueData: {
          eventId: data?.nativeEvent?.text
        },
        appointmentData: undefined
      };
    });
    handleCallback(undefined, undefined, undefined)
      if (isUUID(data?.nativeEvent?.text)) {
        const variables = getVariablesForAppointmentSearch({eventId: data?.nativeEvent?.text })
        getAppointments({
          variables: variables
        })
      }
      else {
        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: [],
          };
        });
      }
  }

  const handleOnChangeOfSeriesId = (data: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        searchValueData: {
          seriesId: data?.nativeEvent?.text
        },
        appointmentData: undefined
      };
    });
    handleCallback(undefined, undefined, undefined)
      if (isUUID(data?.nativeEvent?.text)) {
        const variables = getVariablesForAppointmentSearch({seriesId: data?.nativeEvent?.text })
        getAppointments({
          variables: variables
        })
      }
      else {
        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: [],
          };
        });
      }
  }

  const handleOnChangeOfEventName = (data: any) => {
      if (data?.nativeEvent?.text) {
        const variables = getVariablesForAppointmentSearch({eventName: data?.nativeEvent?.text })
        getAppointments({
          variables: variables
        })
      }
      else {
        setComponentState((prev) => {
          return {
            ...prev,
            searchedAppointments: [],
          };
        });
      }
  }

  const getLocationOfAppointment = (appointmentAccountLocationUuid: string) => {
    const locationName = componentState?.accountLocations?.find((location) => location.uuid === appointmentAccountLocationUuid)?.practiceLocation.name
    return locationName;
  }

  const getFormattedAppointmentData = (item: ISearchedAppointments) => {
    let formattedDate = '';
    const seriesStartDate = item?.reference?.recurrenceData?.seriesStartDate;
    if (seriesStartDate) {
      formattedDate = getDateStrFromFormat(seriesStartDate, DATE_FORMATS.CONVERSATION_DATE_PICKER)
    }
    return {
      eventName: item?.name,
      appointmentId: item?.id,
      startDateTime: item?.startDateTime,
      endDateTime: item?.endDateTime,
      seriesId: item?.seriesId,
      isRecurrentAppointment: item?.isRecurrentAppointment,
      reference: item?.reference,
      date: getAppointmentDateTime(item),
      location: {
        name: item?.accountLocationId ? getLocationOfAppointment(item?.accountLocationId) : item?.locationType?.value
      },
      formattedDate: formattedDate,
    }
  }

  const renderRecurringIcon = (isRecurring: boolean | undefined): JSX.Element => {
    if (!isRecurring) {
      return <></>;
    }
    return (
      <Badge
        backgroundColor={
          Colors.Custom.mainPrimaryPurple
        }
        alignSelf="center"
        flexDir={'row'}
        alignItems={'center'}
        variant="solid"
        ml={2}
        rounded={'full'}
      >
        <ReccuringAppointmentIcon />
        <Text
          ml={2}
          color={Colors.Custom.FontColorWhite}
          fontSize={12}
        >
          Recurring
        </Text>
      </Badge>
    );
  }


  useEffect(() => {
    if(props?.value?.appointmentData) {
      if (props?.value?.code === APPOINTMENT_SEARCH_TYPE_CODES.PRACTITIONER) {
        const formattedCurrentDateTime = getDateToMomentISOStringInUtc();
        getAppointmentsByPractitioner({
          variables: {
            userUuid: componentState?.searchValueData?.selectedAccountUser?.value,
            appointmentTypecategoryId: APPOINTMENT_TYPE_GROUP_SESSION_CATEGORY_ID,
            currentDateTime: formattedCurrentDateTime,
            statusId: scheduledStatusId
          }
        })
      }
      else if (props?.value?.searchValueData?.selectedAccountLocation?.practiceLocation?.uuid) {
        const variables = getVariablesForAppointmentSearch({ ...props?.value?.searchValueData });
        getAppointments(
          {
            variables: variables
          }
        )
      }
    }
  }, []);

  const renderItemForFlatList = (data: { item: ISearchedAppointments, index: number }) => {
    const isHovered = data?.item?.id === hoveredItem;
    const isRecurrentAppointment = data?.item?.seriesId && data?.item?.isRecurrentAppointment ? true : false;
    const recurrenceData = data?.item?.reference?.recurrenceData;
    let formattedDate = '';
    if (recurrenceData?.seriesStartDate) {
      formattedDate = getFormattedDate(data?.item?.startDateTime, DATE_FORMATS.CONVERSATION_DATE_PICKER)
    }
    return (
      <Pressable
        onPress={() => {
          const formattedAppointmentData = getFormattedAppointmentData(data?.item)
          setComponentState((prev) => {
            return {
              ...prev,
              appointmentData: formattedAppointmentData
            }
          })
          handleCallback(undefined, undefined, formattedAppointmentData)
        }}
      >
        <div onMouseEnter={() => handleMouseEnter(data?.item?.id)}
          onMouseLeave={handleMouseLeave}>
          <VStack
            padding={3}
            borderRadius={data?.index === 0 ? 8 : 0}
            backgroundColor={(componentState?.appointmentData?.appointmentId === data?.item?.id) ? Colors?.Custom?.Primary50 :
                isHovered ? Colors?.Custom?.Gray50 :
                  Colors?.Custom?.BackgroundColor}
          >
            <HStack space={2}>
            <Text>
              Event name:
            </Text>
            <Text>
              {data?.item.name}
            </Text>
            {renderRecurringIcon(isRecurrentAppointment)}
          </HStack>
          <HStack alignItems="center" space={3}>
            <View marginTop={1}>
              <Icon as={AntIcon} name="clockcircleo" size="4" />
            </View>
            <Text size="xsMedium" marginTop={1}>
              {isRecurrentAppointment && getSummaryString(processDataRecurrence(recurrenceData), { startTime: formattedDate})}
              {!isRecurrentAppointment && getAppointmentDateTime(data?.item)}
            </Text>
          </HStack>
            <HStack space={3} alignItems="center">
              <View marginTop={1}>
                <Icon as={MaterialIcon} name="location-on" size="4" />
              </View>
              <View flex={1}>
                <Text size="xsMedium">{
                  data?.item?.accountLocationId ?
                    getLocationOfAppointment(data?.item?.accountLocationId) :
                    data?.item?.locationType?.value}</Text>
              </View>
            </HStack>
          </VStack>
          {!!componentState?.searchedAppointments?.length &&
            <Divider />
          }
        </div>

      </Pressable>
    )
}

  const componentLoading = getTimezoneOfAccountAndLocationsQuery?.loading;
  return (
    <View maxHeight={'100%'}>
      {componentLoading && (
        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!componentLoading && (
        <>
          <HStack ref={(ref: any) => {
            if (ref) {
              setComponentWidth(ref?.offsetWidth);
            }
          }}>
            <HStack flex={2} alignItems='center'>
              <Text fontWeight={300} fontSize={16} flex={2}>
                {'Search by'}
              </Text>
            </HStack>
            <HStack flex={8}>
              <View marginY={1} flex={1}>
                <Select
                  value={componentState?.code}
                  placeholder={'Select a type to search appointment'}
                  status={props?.isShowError && !componentState?.code ? 'error' : ''}
                  onChange={(itemValue) => {
                    setComponentState((prev) => {
                      return {
                        accountLocations: prev.accountLocations,
                        code: itemValue,
                        appointmentData: undefined
                      }
                    })
                    handleCallback(undefined, undefined, undefined);
                  }}
                >
                  {codeList?.map((data: ICodeList) => {
                    return (
                      <Option value={data.id} title={data.value} key={data.id}>{data.value}</Option>
                    );
                  })}
                </Select>

              </View>
            </HStack>
          </HStack>

          {
            componentState?.code === APPOINTMENT_SEARCH_TYPE_CODES.EVENT_ID && (
                <HStack >
                  <View marginY={1} flex={1}>
                    <Input
                      placeholder={'Enter Event ID'}
                      onChange={handleOnChangeOfEventId}
                      value={componentState?.searchValueData?.eventId}
                      borderColor={(props?.isShowError && !componentState?.searchValueData?.eventId && !componentState?.appointmentData) ? Colors?.Custom?.ErrorColor : Colors?.Custom?.Gray300}
                      />
                  </View>
                </HStack>
            )
          }
          {
            (componentState?.code === APPOINTMENT_SEARCH_TYPE_CODES.EVENT_NAME) && (
                <HStack>
                  <View marginY={1} flex={1}>
                    <Input
                      placeholder={'Enter Event Name'}
                      onChange={(debounce(handleOnChangeOfEventName, 500))}
                      value={componentState?.searchValueData?.eventName}
                      borderColor={(props?.isShowError && !componentState?.searchValueData?.eventName && !componentState?.appointmentData) ? Colors?.Custom?.ErrorColor : Colors?.Custom?.Gray300}
                      onChangeText={(searchString: string) => {
                        handleCallback(undefined, undefined, undefined);
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            searchValueData: {
                              eventName: searchString
                            },
                            appointmentData: undefined
                          };
                        });
                      }}
                      />
                  </View>
                </HStack>
            )
          }
          {
            componentState?.code === APPOINTMENT_SEARCH_TYPE_CODES.LOCATION && (
              <HStack flex={1} marginTop={2}>
                <LocationAutoComplete
                  minWidth={componentWidth}
                  selectedLocationName={componentState?.searchValueData?.selectedAccountLocation?.practiceLocation?.name}
                  selectedLocationUUID={componentState?.searchValueData?.selectedAccountLocation?.uuid}
                  allowClear={false}
                  onChange={(selectedlocation?: { key: string, value: string, label: string }) => {
                    let selectedLocationDetails: IAccountLocation | undefined;
                    if (
                      selectedlocation &&
                      virtualLocationDetails &&
                      selectedlocation?.value === virtualLocationDetails?.id
                    ) {
                      selectedLocationDetails = {
                        uuid: selectedlocation?.value,
                        practiceLocation: {
                          id: virtualLocationDetails?.code,
                          name: virtualLocationDetails?.value,
                          uuid: virtualLocationDetails?.id,
                        },
                      };
                    } else {
                      selectedLocationDetails =
                        componentState?.accountLocations?.find(
                          (location) =>
                            location?.practiceLocation?.uuid ===
                            selectedlocation?.value
                        );
                    }
                    setComponentState((prev) => {
                      return {
                        ...prev,
                        searchValueData: {
                          selectedAccountLocation: selectedLocationDetails
                        },
                        appointmentData: undefined
                      }
                    })
                    handleCallback(undefined, undefined, undefined)
                    const variables = getVariablesForAppointmentSearch({ selectedAccountLocation: selectedLocationDetails });
                    getAppointments(
                      {
                        variables: variables
                      }
                    )
                  }}
                  accountLocations={getDataForLocationAutoComplete() || []}
                  autoCompleteType={
                    autoCompleteTypeCodes.DEFAULT
                  }
                  isInvalid={props?.isShowError && !componentState?.searchValueData?.selectedAccountLocation && !componentState?.appointmentData}
                />
              </HStack>
            )
          }
          {componentState?.code === APPOINTMENT_SEARCH_TYPE_CODES.PRACTITIONER && (
            <HStack marginTop={2}>
            <UserAutoComplete
              minWidth={componentWidth}
              selectedUserUUID={componentState?.searchValueData?.selectedAccountUser?.value}
              userAutoCompleteType={
                userAutoCompleteTypeCodes.DEFAULT
              }
              allowClear={false}
              onChange={(selectedUser?: {key: string, value: string, label: string}) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    searchValueData: {
                      selectedAccountUser: selectedUser
                    },
                    appointmentData: undefined
                  }
                })
                handleCallback(undefined, undefined, undefined);
                const formattedCurrentDateTime = getDateToMomentISOStringInUtc();
                getAppointmentsByPractitioner({
                  variables: {
                      userUuid: selectedUser?.value,
                      appointmentTypecategoryId: APPOINTMENT_TYPE_GROUP_SESSION_CATEGORY_ID,
                      currentDateTime: formattedCurrentDateTime,
                      statusId: scheduledStatusId
                  }
                })
              }}
              backgroundColor={'white'}
              isInvalid={props?.isShowError && !componentState?.searchValueData?.selectedAccountLocation && !componentState?.appointmentData}
            />
            </HStack>
          )
          }
          {
            componentState?.code === APPOINTMENT_SEARCH_TYPE_CODES.SERIES_ID && (
                <HStack >
                  <View marginY={1} flex={1}>
                    <Input
                      placeholder={'Enter Series id'}
                      onChange={handleOnChangeOfEventId}
                      value={componentState?.searchValueData?.seriesId}
                      borderColor={(props?.isShowError && !componentState?.searchValueData?.seriesId && !componentState?.appointmentData) ? Colors?.Custom?.ErrorColor : Colors?.Custom?.Gray300}
                      />
                  </View>
                </HStack>
            )
          }
          {
            !!componentState?.appointmentData && (
              <VStack
                borderColor={Colors?.Custom?.Gray300}
                borderWidth={1}
                marginY={2}
                borderRadius={8}
              >
                <HStack backgroundColor={Colors?.Custom?.Primary50} padding={1} borderTopRadius={8} flex={1}>
                  <HStack flex={0.5} justifyContent={'left'}>
                    <Text fontSize="16" fontWeight={600} marginBottom={1}>
                      Selected Event
                    </Text>
                  </HStack>
                  <HStack flex={0.5} justifyContent={'right'}>
                    <Pressable
                      onPress={() => {
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            appointmentData: undefined
                          };
                        });
                        handleCallback(undefined, undefined, undefined);
                      }}
                    >
                      <View justifyContent={'center'} height={'36px'} >
                        <Feather name='x' size={16} color={Colors.FoldPixel.GRAY300} />
                      </View>
                    </Pressable>
                  </HStack>
                </HStack>
                <View
                  paddingX={4}
                  paddingBottom={4}>
                  <HStack space={2}>
                    <Text>
                      Event name:
                    </Text>
                    <Text>
                      {componentState?.appointmentData?.eventName}
                    </Text>
                    {renderRecurringIcon(componentState?.appointmentData?.isRecurrentAppointment)}
                  </HStack>
                  <HStack alignItems="center" space={3}>
                    <View marginTop={1}>
                      <Icon as={AntIcon} name="clockcircleo" size="4" />
                    </View>
                    <Text size="xsMedium" marginTop={1}>
                      {componentState?.appointmentData?.isRecurrentAppointment && getSummaryString(processDataRecurrence(componentState?.appointmentData?.reference?.recurrenceData), { startTime: componentState?.appointmentData?.formattedDate || ''})}
                      {!componentState?.appointmentData?.isRecurrentAppointment && (componentState?.appointmentData.date)}
                    </Text>
                  </HStack>
                  {!!componentState?.appointmentData?.location?.name &&
                    <HStack space={3} alignItems="center">
                      <View marginTop={1}>
                        <Icon as={MaterialIcon} name="location-on" size="4" />
                      </View>
                      <View flex={1}>
                        <Text size="xsMedium">{componentState?.appointmentData?.location?.name}</Text>
                      </View>
                    </HStack>
                  }
                </View>
              </VStack>
            )
          }

          {!!componentState?.searchValueData &&
            (
              <View
                marginTop={3}
                borderWidth={1}
                borderRadius={8}
                borderColor={(props?.isShowError && !componentState?.appointmentData) ? Colors?.Custom?.ErrorColor : Colors?.Custom?.Gray300}
                paddingBottom={3}
                maxHeight={!!componentState?.appointmentData ? 0.4 * height : 0.5 * height}>
                    {getAppointmentsQueryloading || getAppointmentsByPractitionerQuery && (
                      <Skeleton.Text lines={5} />
                    )}

                    {!getAppointmentsQueryloading && !getAppointmentsByPractitionerQuery &&
                      (!componentState?.searchedAppointments?.length || componentState.searchedAppointments.length === 0) &&
                      (
                        <NoTagsSvg titleId="noEventFound" />
                    )}

                    {!getAppointmentsQueryloading && !getAppointmentsByPractitionerQuery &&
                      (!!componentState?.searchedAppointments?.length && componentState.searchedAppointments.length > 0) &&
                      (
                        <FlatList
                          data={componentState?.searchedAppointments}
                          maxHeight={!!componentState?.appointmentData ? 0.4 * height : 0.5 * height}
                          renderItem={renderItemForFlatList}
                          keyExtractor={(item) => {
                             return item?.id
                          }}
                        />
                      )}
              </View>)
          }
        </>
      )}
    </View>
      );
}

export default WebinarSearch;
