import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../../styles/Colors';

export const styles = StyleSheet.create({
  goalTab: {
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-evenly',
    backgroundColor:"#ffff",
    marginHorizontal:20,
    padding:20,
    marginTop:20,
    height: "auto",
  },
  goalHeaderText: {
    flexDirection: 'row',
    
    height: 100,
  },
  goalSubHeader: {
    marginBottom:20
  },
  goalHeaderTextFont: {
    fontWeight: '500',
    marginBottom:5,
    fontSize: 18,
    color: '#000000',
  },
  exitTheCampaign:{
    fontWeight: '500',
    fontSize: 18,
    padding:20,
    color: '#344054',
  },
  goalTabBox: {},
  defineGoal: {
    fontWeight: '500',
    fontSize: 18,
    color: '#344054',
  },
  goalSubHeaderTextFont: {
    fontWeight: '300',
    fontSize: 15,
    color: Colors.Custom.Gray600,
  },
  recommendedTextFont: {
    fontWeight: '100',
    fontSize: 12,
    color: '#344054',
    marginTop:10
  },
  defineGoalTextFont: {
    fontWeight: '300',
    fontSize: 13,
    color: '#344054',
  },
  exitTheCampaignSubText: {
    fontWeight: '300',
    fontSize: 13,
    color: '#344054',
  },
  exitTheCampaignSubTextMargin: {
    marginLeft:10
  },
  goalTabDiv: {
    flexDirection: 'row',
    alignContent: 'flex-start',
    height: 150,
  },
  goalBtnStyle: { alignItems:"center",justifyContent:"space-between",flexDirection:'row',padding:10,borderRadius:5, borderWidth: 1,  marginBottom:20,paddingVertical:16 }

});