import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const Appointment = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" fill="#FAF5FE" rx="24"></rect>
      <g
        stroke="#825AC7"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1119_139536)"
      >
        <path d="M31.791 17.125h-16.25c-.69 0-1.25.56-1.25 1.25v13.75c0 .69.56 1.25 1.25 1.25h16.25c.69 0 1.25-.56 1.25-1.25v-13.75c0-.69-.56-1.25-1.25-1.25zM14.291 22.125h18.75"></path>
        <path
          strokeLinecap="round"
          d="M19.291 19v-4.375M28.041 19v-4.375M26.791 25.25l-3.75 5-2.5-2.5"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1119_139536">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(13.666 14)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Appointment;
