import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    marginHorizontal: 16,
    overflow: 'hidden',
    padding: 16,
  },
  title: {
    fontSize: 18,
  },
  labelView: {
    flex: 0.3,
  },
  inputView: {
    flex: 0.5,
  },
  inputWrapper: {
    marginVertical: 4,
    flex: 1,
  },
  practiceAvailabilityWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 8,
    paddingVertical: 12,
  },
});
