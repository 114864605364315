import {
  HStack,
  View,
  VStack,
  Text,
  Divider,
  Skeleton,
  Pressable,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocation} from 'react-use';
import {Colors} from '../../../../../../../styles';
import {commonBodyContentContainer} from '../../../../../../../styles/CommonBodyContentStyle';
import {capitalizeText} from '../../../../../../common/ContactRelationView/ContactRelationUtils';
import TitleSubtitleView from '../../../../../../common/TitleSubtitleView/TitleSubtitleView';
import {IStripeCharges} from '../../interfaces';

import {
  getOrdersDetails,
  GetSubscriptionPrevInvoices,
} from '../../../../../../../services/Stripe/StripeServices';
import MessagingContactDetailsDrawer from '../../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {useLazyQuery} from '@apollo/client';
import {LeadQueries} from '../../../../../../../services';
import {ISubscriptionDetail} from './interfaces';
import {ConvertUnixTimestamp} from '../../../../../../../utils/DateUtils';
import {ORDER_SUMMARY_DATE_FROMATED} from '../../../../../../../constants';
import {getPerInterval} from './HelperUtils';
import {Dimensions} from 'react-native';
import {IInvoice} from './inVoiceInterface';
import DownloadSvg from '../../../../../../common/Svg/DownloadSvg';
import ContactProfileName from './ContactProfileName';

const SubscriptionOrderDetailView = () => {
  const navigate = useNavigate();
  const {id}: any = useParams();
  const {state}: any = useLocation();
  const [stateData, setStateData] = useState({
    orderDetails: {} as ISubscriptionDetail,
    loading: false,
    noData: false,
    contactData: {} as any,
    contactType: null,
    contactDataLoading: false,
    invoiceListLoading: false,
    invoiceList: [] as IInvoice[],
  });
  const [ContactData] = useLazyQuery(LeadQueries.GetContact);
  const orderDetails = async () => {
    const orderDetailResp = await getOrdersDetails(id).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          noData: true,
        };
      });
    });
    if (orderDetailResp?.data?.id) {
      setStateData((prev) => {
        return {
          ...prev,
          orderDetails: orderDetailResp?.data,
          loading: false,
        };
      });
    }
  };

  const invoiceList = async () => {
    const invoiceListResponse = await GetSubscriptionPrevInvoices(id).catch(
      (err) => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            invoiceListLoading: false,
          };
        });
      }
    );
    if (invoiceListResponse?.data?.data?.length) {
      const invoiceList = invoiceListResponse?.data?.data;
      setStateData((prev) => {
        return {
          ...prev,
          invoiceListLoading: false,
          invoiceList: invoiceList,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          invoiceListLoading: false,
          invoiceList: [],
        };
      });
    }
  };

  const getCustomerContactById = async (id: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        contactDataLoading: true,
      };
    });
    const contactDataResp = await ContactData({
      variables: {
        id: id,
      },
    });
    if (contactDataResp.data) {
      setStateData((prev) => {
        return {
          ...prev,
          contactData: contactDataResp?.data?.contact,
          contactType: contactDataResp?.data?.contact.contactType.contactType,
          contactDataLoading: false,
        };
      });
    }
  };
  const onActionPerformed = (actionCode: string, data: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ON_NAVIGATE_TO_DETAIL:
        setStateData(prev=> {
          return {
            ...prev,
            contactData: data.contact
          }
        })
        break;
    }
  };
  const couponCodeText = `${
    stateData?.orderDetails?.discount?.coupon?.amount_off
      ? `USD${stateData?.orderDetails?.discount?.coupon?.amount_off / 100}`
      : `%${stateData?.orderDetails?.discount?.coupon?.percent_off}`
  } Off ${stateData?.orderDetails?.discount?.coupon?.duration}`;
  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
        invoiceListLoading: true,
      };
    });
    orderDetails();
    invoiceList();
  }, []);

  const getListItem = (
    key: string,
    value: any,
    isPrimary?: boolean,
    isLink?: boolean,
    callBack?: () => void,
    isProfile?:boolean
  ) => {
    return (
      <HStack flex={1} alignContent={'center'} style={{marginBottom: 12}}>
        <VStack flex={0.25} alignItems={'flex-start'}>
          <Text size={'smSemibold'} color={Colors.Custom.Gray400}>
            {key}
          </Text>
        </VStack>
        <VStack flex={0.75} alignItems={'flex-start'}>
          {value !== 'undefined' && isLink && value?.trim().length ? (
            <Pressable onPress={callBack}>
              <Text
                size={'smBold'}
                color={Colors.primary['400']}
                textDecorationLine={'underline'}
                textDecorationColor={Colors.primary['400']}
              >
                {value || '--'}
              </Text>
            </Pressable>
          ) : isProfile ? (
            <ContactProfileName
              extraStyle={{color: Colors.primary['400']}}
              size={'smBold'}
              onActionPerformed={onActionPerformed}
              id={stateData.orderDetails?.customer?.id}
              name={value}
            />
          ) : (
            <Text
              size={isPrimary ? 'smBold' : 'smNormal'}
              color={isPrimary ? Colors.primary['400'] : Colors.Custom.Gray600}
            >
              {value && value !== 'undefined' ? value : '--'}
            </Text>
          )}
        </VStack>
      </HStack>
    );
  };
  const onHeaderBackClick = () => {
    navigate(-1);
  };
  const {height} = Dimensions.get('window');
  const finalHeight = height - 150;
  return (
    <>
      <View flex={1}>
        {/* <View
          style={{
            backgroundColor: '#fff',
          }}
        >
          <TitleSubtitleView
            title="Detail"
            showBackButton={true}
            onBackClick={onHeaderBackClick}
          />
        </View> */}
        <View
          style={{
            // backgroundColor: Colors.Custom.Gray50,
            marginTop: 28,
            marginHorizontal: 16,
          }}
        >
          <View
            style={{
              backgroundColor: '#fff',
              borderColor: Colors.Custom.BorderColor,
              borderWidth: 1,
              borderRadius: 8,
              padding: 24,
              flex: 1,
              maxHeight: finalHeight,
              overflow: 'scroll',
            }}
          >
            {stateData.loading ? (
              <View padding={3}>
                <Skeleton.Text lines={3} />
              </View>
            ) : (
              <View>
                <HStack
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                >
                  <VStack>
                    <View>
                      <Text size={'xsMedium'} color={Colors.Custom.Gray400}>
                        {`SUBSCRIPTION`}
                      </Text>
                      <View flexDirection={'row'} alignItems={'center'}>
                          <ContactProfileName
                          extraStyle={{color: Colors.Custom.Gray700}}
                          size={'xslBold'}
                            onActionPerformed={onActionPerformed}
                            id={stateData.orderDetails?.customer?.id}
                            name={stateData.orderDetails?.customer?.name}
                          />
                        <Text
                          size={'xlNormal'}
                          style={{marginLeft: 6}}
                          color={Colors.Custom.Gray700}
                        >
                          {'On'} {stateData.orderDetails?.plan?.product?.name}
                        </Text>
                        <Text
                          style={{
                            color:
                              stateData.orderDetails.status == 'active'
                                ? Colors.success[600]
                                : Colors.Custom.Gray400,
                            backgroundColor:
                              stateData.orderDetails.status == 'active'
                                ? Colors.success[100]
                                : Colors.Custom.Gray100,
                            padding: 2,
                            paddingHorizontal: 8,
                            borderRadius: 6,
                            marginLeft: 8,
                          }}
                          size={'xsSemibold'}
                        >
                          {capitalizeText(stateData?.orderDetails?.status)}
                        </Text>
                      </View>
                    </View>
                  </VStack>
                  <VStack>
                    <Text
                      size={'xsBold'}
                      style={{color: Colors.Custom.Gray500}}
                    >
                      {stateData.orderDetails.id}
                    </Text>
                  </VStack>
                </HStack>
                <Divider
                  orientation={'horizontal'}
                  style={{
                    width: '100%',
                    backgroundColor: Colors.Custom.Gray200,
                    marginVertical: 20,
                  }}
                />
                <HStack alignItems={'center'}>
                  <VStack style={{marginRight: 32, paddingVertical: 4}}>
                    <Text
                      size={'smMedium'}
                      style={{marginBottom: 3}}
                      color={Colors.Custom.Gray400}
                    >
                      Started
                    </Text>
                    <Text size={'smBold'} color={Colors.primary['400']}>
                      {ConvertUnixTimestamp(
                        stateData.orderDetails?.created,
                        // 'DD-MMM, LT'
                        'D MMM'
                      )}
                    </Text>
                  </VStack>
                  <Divider
                    orientation={'vertical'}
                    style={{
                      height: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginVertical: 20,
                    }}
                  />
                  <VStack style={{marginHorizontal: 32, paddingVertical: 4}}>
                    <Text
                      size={'smMedium'}
                      style={{marginBottom: 3}}
                      color={Colors.Custom.Gray400}
                    >
                      Next invoice
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text size={'smBold'} color={Colors.Custom.Gray700}>
                        {'USD'}{' '}
                        {stateData?.orderDetails?.upcoming_invoice?.amount_due /
                          100}{' '}
                        {'on '}
                      </Text>
                      <Text size={'smBold'} color={Colors.primary['400']}>
                        {ConvertUnixTimestamp(
                          stateData.orderDetails?.upcoming_invoice?.created,
                          // 'DD-MMM, LT'
                          'D MMM'
                        )}
                      </Text>
                    </View>
                  </VStack>
                </HStack>
                <View style={{marginVertical: 20}}>
                  <View>
                    <Text size={'lgSemibold'}>Subscription details</Text>
                  </View>
                  <Divider
                    orientation={'horizontal'}
                    style={{
                      width: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginVertical: 20,
                    }}
                  />
                  <HStack flex={1}>
                    <VStack flex={0.5}>
                      {getListItem(
                        'Customer',
                        stateData.orderDetails?.customer?.name,
                        false,
                        false,
                        ()=> {},
                        true
                      )}
                      {getListItem(
                        'Created',
                        ConvertUnixTimestamp(
                          stateData.orderDetails?.created,
                          // 'DD-MMM, LT'
                          'D MMM, HH:MM'
                        )
                      )}
                      {getListItem(
                        'Current period',
                        `${ConvertUnixTimestamp(
                          stateData.orderDetails?.current_period_start,
                          // 'DD-MMM, LT'
                          'D MMM, YYYY'
                        )} to ${ConvertUnixTimestamp(
                          stateData.orderDetails?.current_period_end,
                          // 'DD-MMM, LT'
                          'D MMM, YYYY'
                        )} `
                      )}
                      {getListItem('ID', stateData.orderDetails?.id)}
                    </VStack>
                    <VStack flex={0.5}>
                      {getListItem(
                        'Discount',
                        `${
                          stateData?.orderDetails?.discount?.id
                            ? `${couponCodeText}`
                            : 'No coupon applied'
                        }`
                      )}
                      {stateData.orderDetails?.default_payment_method?.card ? (
                        getListItem(
                          'Payment method',
                          `${stateData.orderDetails?.default_payment_method?.card?.brand?.toUpperCase()}   •••• ${
                            stateData.orderDetails?.default_payment_method?.card
                              ?.last4
                          }`
                        )
                      ) : (
                        <></>
                      )}
                    </VStack>
                  </HStack>
                </View>
                <View style={{marginVertical: 20}}>
                  <View>
                    <Text size={'lgSemibold'}>Pricing</Text>
                  </View>
                  <Divider
                    orientation={'horizontal'}
                    style={{
                      width: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginTop: 20,
                      // marginBottom: 10
                    }}
                  />
                  <HStack flex={1}>
                    <View flex={1}>
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          // paddingBottom: 10,
                          marginBottom: 10,
                        }}
                      >
                        <View flex={0.5}>
                          <View>
                            <Text
                              size={'xsMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              PRODUCT
                            </Text>
                          </View>
                        </View>
                        <View
                          flex={0.4}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              SUBSCRIPTION ITEM ID
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              QTY
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              TOTAL
                            </Text>
                          </View>
                        </View>
                      </View>
                      {stateData?.orderDetails?.items?.data?.map((item) => {
                        return (
                          <View
                            flex={1}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <View flex={0.5}>
                              <View>
                                <Text
                                  size={'smMedium'}
                                  color={Colors.Custom.Gray700}
                                >
                                  {item.plan?.product?.name} {' • '}{' '}
                                  {item.price?.id}
                                </Text>
                                <Text
                                  size={'smMedium'}
                                  color={Colors.Custom.Gray400}
                                >{`USD ${
                                  item.plan.amount / 100
                                } / ${getPerInterval(
                                  item.plan.interval,
                                  item.plan.interval_count
                                )}`}</Text>
                              </View>
                            </View>
                            <View
                              flex={0.4}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <View flex={0.3}>
                                <View
                                  style={{
                                    borderWidth: 1,
                                    borderColor: Colors.Custom.Gray200,
                                    borderRadius: 6,
                                    paddingHorizontal: 8,
                                    paddingVertical: 4,
                                    backgroundColor: Colors.Custom.Gray50,
                                  }}
                                >
                                  <Text
                                    color={Colors.Custom.Gray500}
                                    size={'smMedium'}
                                  >
                                    {item.id}
                                  </Text>
                                </View>
                              </View>
                              <View flex={0.3}>
                                <Text
                                  color={Colors.Custom.Gray700}
                                  size={'smMedium'}
                                >
                                  {item.quantity}
                                </Text>
                              </View>
                              <View flex={0.3}>
                                <Text
                                  color={Colors.Custom.Gray700}
                                  size={'smMedium'}
                                >{`USD ${
                                  item.plan.amount / 100
                                } / ${getPerInterval(
                                  item.plan.interval,
                                  item.plan.interval_count
                                )}`}</Text>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                      <Divider
                        orientation={'horizontal'}
                        style={{
                          width: '100%',
                          backgroundColor: Colors.Custom.Gray200,
                          marginTop: 20,
                          // marginBottom: 10
                        }}
                      />
                      {stateData?.orderDetails?.discount?.id ? (
                        <View
                          flex={1}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          style={{
                            borderBottomWidth: 1,
                            borderColor: Colors.Custom.Gray200,
                            // paddingBottom: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View flex={0.5}>
                            <View></View>
                          </View>
                          <View
                            flex={0.4}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <View flex={0.3}></View>
                            <View flex={0.3}>
                              <Text
                                color={Colors.Custom.Gray900}
                                size={'xsBold'}
                              >
                                {
                                  stateData?.orderDetails?.discount?.coupon
                                    ?.name
                                }
                              </Text>
                            </View>
                            <View flex={0.3}>
                              <Text
                                color={Colors.Custom.Gray900}
                                size={'xsBold'}
                              >
                                {couponCodeText}
                              </Text>
                            </View>
                          </View>
                        </View>
                      ) : (
                        <></>
                      )}
                    </View>
                  </HStack>
                </View>
                <View style={{marginVertical: 20}}>
                  <View>
                    <Text size={'lgSemibold'}>Upcoming invoice</Text>
                    <Text
                      size={'xsNormal'}
                      color={Colors.Custom.Gray600}
                    >{`This is a preview of the invoice that will be billed on  ${ConvertUnixTimestamp(
                      stateData.orderDetails?.upcoming_invoice?.created,
                      // 'DD-MMM, LT'
                      'D MMM'
                    )}. It may change if the subscription is updated.`}</Text>
                  </View>
                  <Divider
                    orientation={'horizontal'}
                    style={{
                      width: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginTop: 20,
                      marginBottom: 8,
                    }}
                  />
                  <HStack flex={1}>
                    <View flex={1}>
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          paddingBottom: 8,
                          marginBottom: 10,
                        }}
                      >
                        <View flex={0.5}>
                          <View>
                            <Text
                              size={'xsMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              DESCRIPTION
                            </Text>
                          </View>
                        </View>
                        <View
                          flex={0.3}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              QTY
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              UNIT PRICE
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              AMOUNT
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          paddingBottom: 8,
                          marginBottom: 10,
                        }}
                      >
                        <View flex={0.5}>
                          <View>
                            <Text
                              size={'smMedium'}
                              color={Colors.Custom.Gray400}
                            >
                              {ConvertUnixTimestamp(
                                stateData.orderDetails?.upcoming_invoice
                                  ?.period_start,
                                // 'DD-MMM, LT'
                                'D MMM YYYY'
                              )}{' '}
                              TO{' '}
                              {ConvertUnixTimestamp(
                                stateData.orderDetails?.upcoming_invoice
                                  ?.period_end,
                                // 'DD-MMM, LT'
                                'D MMM YYYY'
                              )}
                            </Text>
                          </View>
                        </View>
                        <View
                          flex={0.3}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}></View>
                          <View flex={0.3}></View>
                          <View flex={0.3}></View>
                        </View>
                      </View>
                      {stateData.orderDetails?.upcoming_invoice?.lines?.data?.map(
                        (item) => {
                          return (
                            <View
                              flex={1}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              style={{
                                borderBottomWidth: 1,
                                borderColor: Colors.Custom.Gray200,
                                paddingBottom: 8,
                                marginBottom: 10,
                              }}
                            >
                              <View flex={0.5}>
                                <View>
                                  <Text
                                    size={'smMedium'}
                                    color={Colors.Custom.Gray900}
                                  >
                                    {
                                      stateData.orderDetails?.plan?.product
                                        ?.name
                                    }
                                  </Text>
                                </View>
                              </View>
                              <View
                                flex={0.3}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <View flex={0.3}>
                                  <Text
                                    size={'smMedium'}
                                    color={Colors.Custom.Gray700}
                                  >
                                    {item.quantity}
                                  </Text>
                                </View>
                                <View flex={0.3}>
                                  <Text
                                    size={'smMedium'}
                                    color={Colors.Custom.Gray700}
                                  >
                                    USD {item.price?.unit_amount / 100}
                                  </Text>
                                </View>
                                <View flex={0.3}>
                                  <Text
                                    size={'smMedium'}
                                    color={Colors.Custom.Gray900}
                                  >
                                    USD {item.amount / 100}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          paddingBottom: 8,
                          marginBottom: 10,
                        }}
                      >
                        <View flex={0.5}></View>
                        <View
                          flex={0.3}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}></View>
                          <View flex={0.3}>
                            <Text size={'smBold'} color={Colors.Custom.Gray900}>
                              Subtotal
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              size={'smMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              USD{' '}
                              {stateData?.orderDetails?.upcoming_invoice
                                ?.subtotal / 100}
                            </Text>
                          </View>
                        </View>
                      </View>
                      {stateData?.orderDetails?.discount?.id ? (
                        <View
                          flex={1}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          style={{
                            borderBottomWidth: 1,
                            borderColor: Colors.Custom.Gray200,
                            paddingBottom: 8,
                            marginBottom: 10,
                          }}
                        >
                          <View flex={0.5}></View>
                          <View
                            flex={0.3}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <View flex={0.3}></View>
                            <View flex={0.3}>
                              <Text
                                size={'smBold'}
                                color={Colors.Custom.Gray500}
                              >
                                {
                                  stateData?.orderDetails?.discount?.coupon
                                    ?.name
                                }
                              </Text>
                            </View>
                            <View flex={0.3}>
                              <Text
                                size={'smMedium'}
                                color={Colors.Custom.Gray900}
                              >
                                - USD{' '}
                                {stateData?.orderDetails?.upcoming_invoice
                                  ?.discount?.coupon?.amount_off / 100}
                              </Text>
                            </View>
                          </View>
                        </View>
                      ) : (
                        <></>
                      )}
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          paddingBottom: 8,
                          marginBottom: 10,
                        }}
                      >
                        <View flex={0.5}></View>
                        <View
                          flex={0.3}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}></View>
                          <View flex={0.3}>
                            <Text size={'smBold'} color={Colors.Custom.Gray900}>
                              Total
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              size={'smMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              USD{' '}
                              {stateData?.orderDetails?.upcoming_invoice
                                ?.total / 100}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          paddingBottom: 8,
                          marginBottom: 10,
                        }}
                      >
                        <View flex={0.5}></View>
                        <View
                          flex={0.3}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}></View>
                          <View flex={0.3}>
                            <Text size={'smBold'} color={Colors.Custom.Gray900}>
                              Total due
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              size={'smMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              USD{' '}
                              {stateData?.orderDetails?.upcoming_invoice
                                ?.amount_due / 100}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </HStack>
                </View>
                <View style={{marginVertical: 20}}>
                  <View>
                    <Text size={'lgSemibold'}>Invoices</Text>
                  </View>
                  <Divider
                    orientation={'horizontal'}
                    style={{
                      width: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginTop: 20,
                      marginBottom: 8,
                    }}
                  />
                  <HStack flex={1}>
                    <View flex={1}>
                      <View
                        flex={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: Colors.Custom.Gray200,
                          paddingBottom: 8,
                          // marginBottom: 10,
                        }}
                      >
                        <View
                          flex={0.6}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.3}>
                            <Text
                              size={'xsMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              AMOUNT
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              size={'xsMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              INVOICE NUMBER
                            </Text>
                          </View>
                          <View flex={0.3}>
                            <Text
                              size={'xsMedium'}
                              color={Colors.Custom.Gray900}
                            >
                              CUSTOMER
                            </Text>
                          </View>
                        </View>
                        <View
                          flex={0.2}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <View flex={0.5}>
                            <Text
                              color={Colors.Custom.Gray900}
                              size={'xsMedium'}
                            >
                              CREATED
                            </Text>
                          </View>
                        </View>
                      </View>
                      {stateData.invoiceListLoading ? (
                        <Skeleton.Text lines={4} />
                      ) : (
                        <></>
                      )}
                      {stateData?.invoiceList?.map((item) => {
                        return (
                          <View
                            flex={1}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            style={{
                              borderBottomWidth: 1,
                              borderColor: Colors.Custom.Gray200,
                              paddingVertical: 8,
                              // marginBottom: 10,
                            }}
                          >
                            <View
                              flex={0.6}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <View flex={0.3}>
                                <Text
                                  size={'xsMedium'}
                                  color={Colors.Custom.Gray900}
                                >
                                  USD {item.total / 100}
                                </Text>
                              </View>
                              <View flex={0.3}>
                                <Text
                                  size={'xsMedium'}
                                  color={Colors.Custom.Gray900}
                                >
                                  {item.number}
                                </Text>
                              </View>
                              <View flex={0.3}>
                                <Text
                                  size={'xsMedium'}
                                  color={Colors.Custom.Gray900}
                                >
                                  {item?.customer?.email || ''}
                                </Text>
                              </View>
                            </View>
                            <View
                              flex={0.2}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <View flex={0.4}>
                                <Text
                                  color={Colors.Custom.Gray900}
                                  size={'xsMedium'}
                                >
                                  {ConvertUnixTimestamp(
                                    item.created,
                                    // 'DD-MMM, LT'
                                    'D MMM, HH:MM'
                                  )}
                                </Text>
                              </View>
                              <View flex={0.2}>
                                <Pressable
                                  onPress={() => {
                                    window.open(item.invoice_pdf, '_blank');
                                  }}
                                >
                                  <DownloadSvg />
                                </Pressable>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </HStack>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
      {stateData?.contactData?.id ? (
        <MessagingContactDetailsDrawer
          contactData={stateData?.contactData}
          contactType={stateData?.contactData?.contactType?.contactType?.code}
          isDrawerVisible={stateData?.contactData ? true : false}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setStateData((prev: any) => {
                return {
                  ...prev,
                  contactData: null,
                  contactType: null,
                };
              });
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SubscriptionOrderDetailView;
