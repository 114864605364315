import { CodeableConcept, Extension, MedicationStatement } from 'fhir/r4';
import {IKeyOperation} from '../../../../../../../Interfaces';
import { getExtensionValue } from '../../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { EXTENSION_URLS } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { MedicationFields } from '../../CustomComponentUtils';
import {
  IAddOrUpdateMedicationParams,
  IMedicationComponentValue,
  IMedicationStatement,
} from '../interfaces';
import { FHIR_CODE_SYSTEMS, FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import { IFormatHieMetaData, IHieInvalidField } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { HieInvalidFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { getEffectivePeriodFromTime, getValidCoding } from '../../../../../Integration/FhirUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';

export const getMedicationCodeObject = (item: any) => {
  return item.medicationCodeableConcept || item.medicationReference
}

export const getRecordListFormatted = (list: any[], fields: IKeyOperation | undefined, ehrName?: string) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries, fields, ehrName);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[], fields: IKeyOperation | undefined, ehrName?: string): IMedicationStatement[] => {
  const tempFormattedList: IMedicationStatement[] = [];
  listData.map((item: any) => {
    const isAthena = ehrName == EHRName.ATHENA;
    const isMedicationStatement = item.resourceType === FHIR_RESOURCE.MEDICATION_STATEMENT;
    const discontinuedDate = getExtensionValue(item, EXTENSION_URLS.discontinuedDate);
    const medicationObject = getMedicationCodeObject(item);
    const statusReason = item.statusReason;
    const sig = item.extension?.find((extension: Extension) => fields?.sig?.extensionUrl && extension.url == fields?.sig?.extensionUrl)?.valueString;

    if (medicationObject) {
      const tempObj: IMedicationStatement = {
        id: item?.id,
        effectivePeriod: item?.effectivePeriod?.start || item?.dispenseRequest?.validityPeriod?.start,
        medicationStatement: medicationObject,
        name: getMedicationName(medicationObject),
        informationSource: getPractitioner(item),
        ...(item?.status && {
          status: {
            code: item?.status,
            display: item?.status,
          },
        }),
        uniqueId: item?.id,
        isFreeTextRecord: !medicationObject?.coding?.length,
        reason: (item?.status === 'stopped' || item?.status === 'completed' || isAthena) && statusReason?.length ? statusReason[statusReason.length - 1] : undefined,
        statusReason: statusReason,
        discontinuedDate,
        showNote: item.note?.[0]?.text?.length > 0,
        sig: sig,
        stopDate: item?.effectivePeriod?.end,
        extension: item?.extension || [],
        ...(item.note?.length > 0 && {
          note: item.note[0].text,
        }),
        isReadOnly: !isMedicationStatement,
        meta: item?.meta
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IMedicationComponentValue => {
  return {
    medicationStatements: [
      {
        id: '1234',
        name: 'Sample Medication',
        effectivePeriod: '2020-12-14',
        medicationStatement: {
          text: 'Sample Medication',
        },
        status: {code: 'active', display: 'Active'},
        informationSource: {
          resourceType: "Practitioner",
          id: 121,
          name:
          [
              {
                  "given":
                  [
                      "Practitioner Test"
                  ],
                  "family": "Doctor"
              }
          ],
      },
        uniqueId: '1234',
        isFreeTextRecord: false
      }
    ]
  }
};

export const sortItems = (records: IMedicationStatement[]) => {
  return records.sort((a, b) => {
    const statusA = a.status?.code || '';
    const statusB = b.status?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(b.effectivePeriod || new Date()).getTime() -
      new Date(a.effectivePeriod || new Date()).getTime()
    );
  })
};

export const getPractitioner = (item: any) => {
  if (item?.informationSource?.reference) {
    const reference: string = item.informationSource.reference;
    const practitionerId = reference.replace('Practitioner/', '');
    if (reference) {
      return {
        id: practitionerId,
      };
    }
  }
};

export const getMedicationName = (item: any) => {
  let name = item?.text || item?.display;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: MedicationFields,
  data: IMedicationStatement,
  submitTriggered: boolean,
  fields?: IKeyOperation,
  isMedOrderEnabled?: boolean,
) => {
  switch (field) {
    case MedicationFields.effectivePeriod:
      if ((fields?.effectivePeriod?.isRequired || false) && submitTriggered) {
        return !data.effectivePeriod;
      }
      return false;

    case MedicationFields.status:
      if ((fields?.status?.isRequired || false) && submitTriggered) {
        return !data.status || !data.status.code;
      }
      return false;

    case MedicationFields.informationSource:
      if ((fields?.informationSource?.isRequired || false) && submitTriggered) {
        return !data.informationSource?.id;
      }
      return false;

    case MedicationFields.statusReason:
      // When status is stopped and med order is enabled then only ask for status reason
      if (fields?.statusReason?.type === 'string' && data.status?.code === 'stopped' && isMedOrderEnabled && submitTriggered) {
        return !data.reason?.text;
      }
      if (fields?.statusReason?.type === 'codeable' && (fields?.status?.isRequired || false) && submitTriggered) {
        return !data.reason || !data.reason.coding?.[0]?.code;
      }
      return false;

    case MedicationFields.stopDate:
      if ((fields?.stopDate?.isRequired || false) && submitTriggered) {
        return !data.stopDate;
      }
      return false;

    case MedicationFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !data.note;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateMedicationParams) => {
  const statusReason: CodeableConcept[] = [];
  if (params.reason) {
    statusReason.push(params.reason);
  }
  return {
    resourceType: 'MedicationStatement',
    ...(params.id && {id: params.id}),
    ...(params.status?.code && {status: params.status.code}),
    medicationCodeableConcept: params.medicationStatement,
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.effectiveDateTime && {
      effectiveDateTime: params.effectiveDateTime,
    }),
    ...(params.effectivePeriod && {
      effectivePeriod: {
        start: params.effectivePeriod,
        ...(params.stopDate && { end: params.stopDate })
      },
    }),
    ...(params.informationSource?.id && {
      informationSource: {
        reference: `Practitioner/${params.informationSource.id}`,
      },
    }),
    ...(params.dateAsserted && {dateAsserted: params.dateAsserted}),
    ...(params.dosage && {
      dosage: [
        {
          text: params.dosage,
        },
      ],
    }),
    ...((params.extension || []).length > 0 && {extension: params.extension}),
    ...(statusReason.length > 0 && { statusReason: statusReason }),
    ...(params.note && {
      note: [
        {
          text: params.note,
        },
      ],
    }),
    ...(params?.meta && { meta: params?.meta })
  };
};



const getHieSurgicalhistoryCodes = (
  resource: MedicationStatement,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.medicationCodeableConcept;
  return getValidCoding(codeObj, allowedCodeSystems);
};

export const formatHieMedicationStatementData = (resource: MedicationStatement, metaData: any) => {
  const medicationStatementResource: MedicationStatement = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.medicationStatement,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);

  const validatedData = validateMedicationStatementHieResource(
    resource,
    fieldCapabilities
  );

  const display = {
    title: getMedicationName(medicationStatementResource.medicationCodeableConcept),
    date: getHieMedicationPerformedDate(medicationStatementResource),
    status: medicationStatementResource.status,
  };

  return {
    display,
    resourceData: validatedData.resource,
    invalidFields: validatedData.invalidFields,
  };
}

const getHieMedicationPerformedDate = (
  medicationStatementResource: MedicationStatement
) => {
  return (
    medicationStatementResource.effectiveDateTime ||
    medicationStatementResource.effectivePeriod?.start
  );
};

export const getMedicationInvalidCodeField = () => {
  return {
    field: MedicationFields.medicationCodeableConcept,
    inputType: HieInvalidFields.code,
    invalid: true,
    label: 'Medications',
    placeholder: 'Search and add new medication',
    extraData: {
      searchType: PAMISearchType.medication,
    },
  }
}

export const validateMedicationStatementHieResource = (
  resource: MedicationStatement,
  fields: any
) => {  
  const invalidFields: IHieInvalidField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field =
      MedicationFields[key as keyof typeof MedicationFields];
    switch (field) {
      case MedicationFields.medicationCodeableConcept:
        const medicationCodes = getHieSurgicalhistoryCodes(
          resource,
          fields?.medicationCodeableConcept?.allowedCodeSystems
        );

        if (!medicationCodes?.length && fields?.medicationCodeableConcept?.isRequired) {
          invalidFields.push(getMedicationInvalidCodeField());
        } else if (medicationCodes?.length) {
          resource.medicationCodeableConcept = {
            text: medicationCodes?.[0]?.display,
            coding: medicationCodes,
          };
        }
        break;

      case MedicationFields.status:
        const status = resource.status;

        if (!status && fields?.status?.isRequired) {
          invalidFields.push({
            field: MedicationFields.status,
            inputType: HieInvalidFields.select,
            invalid: true,
            extraData: {
              statusList: fields?.status?.possibleValues,
            },
          });
        }
        break;
    }
  });

  return {
    invalidFields: invalidFields,
    resource: resource,
  };
};

export const updateHieMedicationStatementMatchedData = (
  resource: MedicationStatement,
  invalidFields: IHieInvalidField[],
  metaData: IFormatHieMetaData
) => {
  invalidFields.map((invalidField) => {
    if (invalidField.value) {
      switch (invalidField.field) {
        case MedicationFields.medicationCodeableConcept:        
          const codeObj = invalidField.value?.coding?.[0];
          if(metaData.ehrConfig?.isAthena){
            codeObj.system = FHIR_CODE_SYSTEMS.ATHENA;
          }
          resource.medicationCodeableConcept = {...invalidField.value,
            coding: [codeObj]
          };
          break;

        case HieInvalidFields.select:
          resource.status = invalidField.value;
          break;
      }
    }
    return invalidField;
  });

  return formatHieMedicationStatementData(resource, metaData);
};

      
export const formatHieMedicationResourceObj = (
  resource: MedicationStatement,
  metaData: IFormatHieMetaData
) => {

  let effectiveDatePeriod = resource.effectivePeriod;

  if (!effectiveDatePeriod) {
    effectiveDatePeriod = getEffectivePeriodFromTime(resource.effectiveDateTime);
  }

  return {
    resourceType: 'MedicationStatement',
    ...(resource.status && {status: resource.status}),
    medicationCodeableConcept: resource.medicationCodeableConcept,
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.effectiveDateTime && {
      effectiveDateTime: resource.effectiveDateTime,
    }),
    ...(effectiveDatePeriod && {
      effectivePeriod: effectiveDatePeriod
      }
    ),
    ...(metaData?.practitioner && {
      informationSource: {
        reference: `Practitioner/${metaData?.practitioner.id}`,
      },
    }),
    ...(resource.dateAsserted && {dateAsserted: resource.dateAsserted}),
    ...(resource.dosage && {
      dosage: resource.dosage,
    }),
    ...((resource.extension || []).length > 0 && {extension: resource.extension}),
    ...(resource.note && {
      note: resource.note,
    }),
    ...(resource?.meta && { meta: resource?.meta })
  };
};
