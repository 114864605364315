import {Column, ColumnConfig} from '@ant-design/charts';
import {debounce} from 'lodash';
import {Text, View, useMediaQuery} from 'native-base';
import React, {memo} from 'react';
import {Dimensions, FlatList} from 'react-native';
import {ToolTipView} from './ToolTipView';
import ClockSvg from '../../../Svg/ClockSvg';
import {
  IAverageTimeResolutionWidgetData,
  IPageSection,
  IRowsByAssignee,
  RESOURCE_LIST
} from '../interface';
import {getAssigneeName, getFormattedDataForColumnChart, getYAxisMaxValue, secondsToHhMm} from './UtilsConst';
import HighSvg from '../../../Svg/HighSvg';
import MediumSvg from '../../../Svg/MediumSvg';
import LowSvg from '../../../Svg/LowSvg';
import LowPriorityIcon from '../../../../../assets/Icons/LowPriorityIcon';
import { SMALL_WINDOW_1700 } from '../../../../../constants';

const AverageTimeResolution = (props: {
  widgetData: IAverageTimeResolutionWidgetData;
  pageSection: IPageSection;
  fetchMoreItems?: () => void;
  loadingView?: JSX.Element;
  isDrillDown?: boolean;
  isLoading?: boolean;
  isEndReached?: boolean;
}) => {
  const {
    widgetData,
    pageSection,
    isDrillDown,
    fetchMoreItems,
    loadingView,
    isLoading,
    isEndReached,
  } = props;
  const height = Dimensions.get('window').height * 0.69;
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1700 },
  ]);
  const isPoolType =
    pageSection.resource.code ===
    RESOURCE_LIST.TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_TASK_POOL;
  const formattedDataForColumnChart = getFormattedDataForColumnChart(
    widgetData?.rowsByPriority
  );
  const yAxisMaxValue = formattedDataForColumnChart?.length > 0 && getYAxisMaxValue(
    formattedDataForColumnChart,
    pageSection?.resource?.code
  );
  const total = parseInt(
    (
      (widgetData?.rowsByPriority?.totalResolutionTimeInSecondsAverage || 0) / 3600
    ).toFixed(0)
  );
  const lineAnnotation: any = {
    type: 'line',
    start: ['min', total],
    end: ['max', total],
    style: {
      stroke: '#F47A3E',
      lineWidth: 1,
      lineDash: [1, 1],
    },
    text: {
      position: 'end',
      content: `Average
${secondsToHhMm(
        widgetData?.rowsByPriority?.totalResolutionTimeInSecondsAverage || 0
      )}s`,
      offsetX: isSmallWindow ? -64 : -72,
      style: {
        fill: '#F47A3E',
        fontSize: 10
      },
    },
  };
  const config: ColumnConfig = {
    data: formattedDataForColumnChart?.length > 0 ? formattedDataForColumnChart : [],
    xField: 'name',
    yField: 'Time',
    color: '#80A4D5',
    annotations: [lineAnnotation],
    tooltip: {
      formatter: (data) => {
        return {
          name: 'Avg. Time',
          value: data?.Time,
        };
      },
    },
    xAxis: {
      title: {
        text: 'Priority',
        style: {
          fill: '#3A485F',
        },
      },
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: '#6F7A90',
        },
      },
      line: {
        style: {
          stroke: '#D0D6E1',
          lineWidth: 0.5,
        },
      },
      grid: {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#E9ECF1',
            lineDash: [1, 1],
            lineWidth: 0.5,
          },
        },
        closed: true,
      },
    },
    yAxis: {
      ...yAxisMaxValue,
      title: {
        text: 'Time (in hrs.)',
        style: {
          fill: '#3A485F',
        },
      },
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: '#6F7A90',
        },
      },
      line: {
        style: {
          stroke: '#D0D6E1',
          lineWidth: 0.5,
        },
      },
      grid: {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#E9ECF1',
            lineWidth: 0.5,
          },
        },
        closed: true,
      },
    },
    columnWidthRatio: 0.4,
    legend: false,
    label: {
      position: 'middle',
      formatter: () => {
        return '';
      },
    },
    height: 200,
    columnStyle(datum) {
      return {
        radius: [8, 8, 0, 0],
        fill: '#80A4D5',
      };
    },
  };
  const toolTipView = (singleRowData: IRowsByAssignee)=> {
    return (
      <View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8}}>
          <Text size={'smSemibold'} color={'#12B76A'}>AVG. RES. TIME</Text>
          <Text size={'smSemibold'} color={'#12B76A'}>{secondsToHhMm(singleRowData.resolutionTimeInSecondsAverage)}</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <HighSvg />
            <Text style={{marginLeft: 8}} size={'xsSemibold'} color={'#6F7A90'}>HIGH</Text>
          </View>
          <Text size={'xsSemibold'} color={'#3A485F'}>{secondsToHhMm(singleRowData.highPriorityResolutionTimeInSecondsAverage)}</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <MediumSvg />
            <Text style={{marginLeft: 8}} size={'xsSemibold'} color={'#6F7A90'}>MEDIUM</Text>
            </View>
          <Text size={'xsSemibold'} color={'#3A485F'}>{secondsToHhMm(singleRowData.mediumPriorityResolutionTimeInSecondsAverage)}</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <LowPriorityIcon />
            <Text style={{marginLeft: 8}} size={'xsSemibold'} color={'#6F7A90'}>LOW</Text>
          </View>
          <Text size={'xsSemibold'} color={'#3A485F'}>{secondsToHhMm(singleRowData.lowPriorityResolutionTimeInSecondsAverage)}</Text>
        </View>
      </View>
    )
  }
  const handleEndReached = debounce(() => {
    if (!isLoading && !isEndReached) {
      fetchMoreItems?.();
    }
  }, 500);
  return (
    <View
      flex={1}
      style={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 6,
      }}
    >
      <View
        flex={0.5}
        style={{paddingRight: 4, display: isDrillDown ? 'none' : 'flex'}}
      >
        <View
          style={{
            width: '100%',
            borderWidth: 0.5,
            borderColor: '#D0D6E1',
            borderStyle: 'solid',
            padding: 16,
            borderRadius: 8,
            marginBottom: 0,
          }}
        >
          <Text size={'xsMedium'} color={'#6F7A90'}>
            TOTAL AVERAGE RESOLUTION TIME
          </Text>
          <View
            style={{marginTop: 8.5, flexDirection: 'row', alignItems: 'center'}}
          >
            <ClockSvg />
            <Text size={'xslBold'} color={'#3A485F'} style={{marginLeft: 11}}>
              {secondsToHhMm(
                widgetData?.rowsByPriority
                  ?.totalResolutionTimeInSecondsAverage || 0
              )}
            </Text>
          </View>
        </View>
        <View style={{paddingHorizontal: 16, paddingTop: 16}}>
          <Column {...config} />
        </View>
      </View>
      <View flex={isDrillDown ? 1 : 0.5} style={{paddingLeft: 4}}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomWidth: 0.5,
            borderColor: '#D0D6E1',
            borderStyle: 'solid',
          }}
        >
          <Text
            size={'xsSemibold'}
            color={'#6F7A90'}
            style={{width: '55%', paddingHorizontal: 12, paddingVertical: 9}}
          >
            {isPoolType ? 'TASK POOL' : 'USERS'}
          </Text>
          <Text
            size={'xsSemibold'}
            color={'#6F7A90'}
            style={{width: '45%', paddingHorizontal: 12, paddingVertical: 9}}
          >
            AVG. RESOLUTION TIME
          </Text>
        </View>
        <FlatList
          style={{
            maxHeight: isDrillDown ? height : 'auto'
          }}
          data={widgetData?.rowsByAssignee || []}
          renderItem={({item}: any) => (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={{
                  width: '55%',
                  paddingHorizontal: 12,
                  paddingVertical: isDrillDown ? 8 : 4,
                }}
                maxWidth={'60%'}
                lineBreakMode="clip"
              >
                {getAssigneeName(item) || '--'}
              </Text>
              <ToolTipView overlay={toolTipView(item)}>
              <Text
                size={'smMedium'}
                color={'#8C5AE2'}
                style={{
                  width: '45%',
                  paddingHorizontal: 12,
                  paddingVertical: 3,
                }}
              >
                {secondsToHhMm(item?.resolutionTimeInSecondsAverage || 0)}
              </Text>
              </ToolTipView>
            </View>
          )}
          keyExtractor={(item: any, index: number) =>
            `${item?.assigneeId || item?.userPoolId}_${index}`
          }
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.5}
          ListFooterComponent={loadingView}
        />
      </View>
    </View>
  );
};

export default memo(AverageTimeResolution);
