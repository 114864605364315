import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { NavigateFunction } from 'react-router-dom';
import { PERSON_TYPES } from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import { getUserActionObjectFromActionCode, navigateToOtherScreen } from '../../../utils/commonUtils';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { FormScreenContext } from '../../RightSideContainer/Forms/FormBuilderWidget/FormBuilderWidget';
import { IAddFormState, IForm } from '../../RightSideContainer/Forms/interfaces';
import { insertUserAction } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { IAllUserPoolSelect } from '../CareDashboard/CareDashboardWidget/UserAutoComplete';
import {
  UsageLevelControllerArgs,
  UsageLevelState,
  UserActionCode,
  UserActionMastersApiResponse
} from './interfaces';
import { USER_ACTION_CODES } from './UsageLevelConsts';

export class UsageLevelController {
  openPatientAddView: boolean;
  openScheduleView: boolean;
  openInviteUserView: boolean;
  openAddTaskView: boolean;
  openChatWithTeammateView: boolean;
  openSendFormToMemberView: boolean;
  openConfigurePhoneNumberView: boolean;
  openAddFormView: boolean;
  openExecuteExistingWorkflowView: boolean;
  openCreateEmployerView: boolean;
  openConnectToHintView: boolean;
  openConnectToSpruceView: boolean;
  openConnectToElationView: boolean;
  openProviderAppQRCodeView: boolean;
  openPatientAppQRCodeView: boolean;

  private state: UsageLevelState;
  private prospectTypeUuid: string;
  private patientTypeUuid: string;
  private accessUserTaskPoolTypeId: string;
  private toast: any;
  private accountUuid: string;
  private userUuid: string
  private checkScreeningGroupLevel: string
  private navigate: NavigateFunction;
  private setState: React.Dispatch<React.SetStateAction<UsageLevelState>>;
  private getUsers: LazyQueryExecFunction<
    any,
    {
      accountId: string;
    }
  >;
  private getUsersTaskDisplayCategory: LazyQueryExecFunction<
    any,
    {
      userId: string;
    }
  >;
  private getAllTaskAccessUserTaskPools: LazyQueryExecFunction<
    any,
    OperationVariables
  >;
  getUsersIvr: LazyQueryExecFunction<any, OperationVariables>;
  getFormCategories: LazyQueryExecFunction<any, OperationVariables>;

  constructor(args: UsageLevelControllerArgs) {
    this.state = args.state;
    this.toast = args.toast;
    this.accountUuid = args.accountUuid;
    this.openPatientAddView =
      this.state.actionViewCode === COMMON_ACTION_CODES.ADD_MANUALLY;
    this.openScheduleView =
      this.state.actionViewCode === COMMON_ACTION_CODES.ADD_SCHEDULE;
    this.openInviteUserView =
      this.state.actionViewCode === COMMON_ACTION_CODES.ADD;
    this.openAddTaskView =
      this.state.actionViewCode === COMMON_ACTION_CODES.ADD_TASK;
    this.openChatWithTeammateView =
      this.state.actionViewCode === COMMON_ACTION_CODES.TEXT_MESSAGE;
    this.openSendFormToMemberView =
      this.state.actionViewCode === COMMON_ACTION_CODES.SEND_FORMS;
    this.openConfigurePhoneNumberView =
      this.state.actionViewCode == COMMON_ACTION_CODES.ADD_IVR_FLOW;
    this.openAddFormView =
      this.state.actionViewCode == COMMON_ACTION_CODES.ADD_FORM;
    this.openExecuteExistingWorkflowView =
      this.state.actionViewCode === COMMON_ACTION_CODES.CREATE_AUTOMATION;
    this.openCreateEmployerView =
      this.state.actionViewCode === COMMON_ACTION_CODES.CREATE_EMPLOYER;
    this.openConnectToHintView =
      this.state.actionViewCode === COMMON_ACTION_CODES.CONNECT_TO_HINT;
    this.openConnectToElationView =
      this.state.actionViewCode === COMMON_ACTION_CODES.CONNECT_TO_ELATION;
    this.openConnectToSpruceView =
      this.state.actionViewCode === COMMON_ACTION_CODES.CONNECT_TO_SPRUCE;
    this.openProviderAppQRCodeView =
      this.state.actionViewCode == COMMON_ACTION_CODES.PREVIEW;
    this.openPatientAppQRCodeView =
      this.state.actionViewCode == COMMON_ACTION_CODES.ROW_CLICKED;

    this.prospectTypeUuid = args.prospectTypeUuid;
    this.patientTypeUuid = args.patientTypeUuid;
    this.accessUserTaskPoolTypeId = args.accessUserTaskPoolTypeId;
    this.userUuid = args.userUuid
    this.checkScreeningGroupLevel = args.checkScreeningGroupLevel

    this.navigate = args.navigate;
    this.setState = args.setState;
    this.getUsers = args.getUsers;
    this.getUsersTaskDisplayCategory = args.getUsersTaskDisplayCategory;
    this.getAllTaskAccessUserTaskPools = args.getAllTaskAccessUserTaskPools;
    this.getUsersIvr = args.getUsersIvr;
    this.getFormCategories = args.getFormCategories;

    // binding
    this.onMount = this.onMount.bind(this);
    this.onItemClickedActionPerformed =
      this.onItemClickedActionPerformed.bind(this);
    // this.getActions = this.getActions.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setActionList = this.setActionList.bind(this);
    this.handleInviteUserAction = this.handleInviteUserAction.bind(this);
    this.handleAddNewPatientAction = this.handleAddNewPatientAction.bind(this);
    this.handleAddNewProspectAction =
      this.handleAddNewProspectAction.bind(this);
    this.handleSchedulePatientAction =
      this.handleSchedulePatientAction.bind(this);
    this.handleAddNewTaskAction = this.handleAddNewTaskAction.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.handlePersonAction = this.handlePersonAction.bind(this);
    this.setUserList = this.setUserList.bind(this);
    this.setUserTaskDisplayCategory =
      this.setUserTaskDisplayCategory.bind(this);
    this.fetchAllTaskPoolAccessUserPool =
      this.fetchAllTaskPoolAccessUserPool.bind(this);
    this.fetchSystemUsers = this.fetchSystemUsers.bind(this);
    this.setAllUserPoolSelect = this.setAllUserPoolSelect.bind(this);
    this.resolvePromises = this.resolvePromises.bind(this);
    this.setLevelViewCode = this.setLevelViewCode.bind(this);
    this.showInProgressToast = this.showInProgressToast.bind(this);
    this.handleChatWithTeammateAction =
      this.handleChatWithTeammateAction.bind(this);
    this.handleSendFormToMemberAction =
      this.handleSendFormToMemberAction.bind(this);
    this.handleConfigureEmailTemplateAction =
      this.handleConfigureEmailTemplateAction.bind(this);
    this.handleCreateMemberGroupAction =
      this.handleCreateMemberGroupAction.bind(this);
    this.getUsersListIVR = this.getUsersListIVR.bind(this);
    this.handleConfigurePhoneNumberAction =
      this.handleConfigurePhoneNumberAction.bind(this);
    this.handleCreateCampaignAction =
      this.handleCreateCampaignAction.bind(this);
    this.handleCreateTaskPoolAction =
      this.handleCreateTaskPoolAction.bind(this);
    this.handleConfigureCareJourneyAction =
      this.handleConfigureCareJourneyAction.bind(this);
    this.handleCreateFormAction = this.handleCreateFormAction.bind(this);
    this.getFormCategoryList = this.getFormCategoryList.bind(this);
    this.onFormComplete = this.onFormComplete.bind(this);
    this.navigateToScreen = this.navigateToScreen.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.handleRunExistingAutomationOnMember =
      this.handleRunExistingAutomationOnMember.bind(this);
    this.handleSetUpPhoneTreeAction =
      this.handleSetUpPhoneTreeAction.bind(this);
    this.handleLoginToProviderAppAction =
      this.handleLoginToProviderAppAction.bind(this);
    this.handleCreateOnboardingAutomationAction =
      this.handleCreateOnboardingAutomationAction.bind(this);
    this.handleCreateEmployerAction =
      this.handleCreateEmployerAction.bind(this);
    this.handleLoginToMemberPortalAction =
      this.handleLoginToMemberPortalAction.bind(this);
    this.handleLeadGenerationWidgetAction =
      this.handleLeadGenerationWidgetAction.bind(this);
    this.handleCheckScreeningGroupAction =
      this.handleCheckScreeningGroupAction.bind(this);
  }

  onMount() {
    this.resolvePromises();
  }

  async resolvePromises() {
    const promiseList = [this.fetchSystemUsers()];
    try {
      await Promise.allSettled(promiseList);
    } catch (error) {

    }
  }

  async fetchSystemUsers() {
    const getUsersResult = await this.getUsers();
    if (getUsersResult.data?.users) this.setUserList(getUsersResult.data.users);
    const userTaskDisplayCategory = await this.getUsersTaskDisplayCategory();

    if (getUsersResult.data?.users) {
      this.setUserList(getUsersResult.data.users);
      this.setUserTaskDisplayCategory(
        userTaskDisplayCategory.data.userTaskDisplayCategories || []
      );
    }
  }

  async fetchAllTaskPoolAccessUserPool(users: any[]) {
    try {
      const userIds = users.map((userItem: any) => userItem?.userId);
      const taskPoolData = await this.getAllTaskAccessUserTaskPools({
        variables: {
          userId: userIds,
          userPoolTypeId: this.accessUserTaskPoolTypeId,
        },
      });
      const newAllUserPool: IAllUserPoolSelect[] = [];
      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

        const findIndex = newAllUserPool.findIndex(
          (userPoolItem: IAllUserPoolSelect) =>
            userPoolName === userPoolItem.userPoolName
        );
        if (findIndex < 0) {
          newAllUserPool.push({
            userPoolId,
            userPoolName,
            userPoolUserIds: userPoolUsers.map(
              (userPoolUserItem: any) => userPoolUserItem.userId
            ),
          });
        }
      });
      this.setAllUserPoolSelect(newAllUserPool);
    } catch (error) {
    } finally {
    }
  }

  onItemClickedActionPerformed(actionCode: string) {
    // add remaining actions here
    switch (actionCode) {
      case USER_ACTION_CODES.INVITE_STAFF_MEMBER:
        this.handleInviteUserAction();
        break;

      case USER_ACTION_CODES.ADD_NEW_PATIENT:
        this.handleAddNewPatientAction();
        break;

      case USER_ACTION_CODES.ADD_NEW_PROSPECT:
        this.handleAddNewProspectAction();
        break;

      case USER_ACTION_CODES.SCHEDULE_PATIENT:
        this.handleSchedulePatientAction();
        break;

      case USER_ACTION_CODES.ADD_NEW_TASK:
        this.handleAddNewTaskAction();
        break;

      case USER_ACTION_CODES.CHAT_WITH_A_TEAM_MATE:
        this.handleChatWithTeammateAction();
        break;

      case USER_ACTION_CODES.SEND_AN_EXISTING_FORM_TO_A_MEMBER:
        this.handleSendFormToMemberAction();
        break;

      case USER_ACTION_CODES.CONFIGURE_AN_EMAIL_TEMPLATE:
        this.handleConfigureEmailTemplateAction();
        break;

      case USER_ACTION_CODES.CREATE_A_MEMBER_GROUP:
        this.handleCreateMemberGroupAction();
        break;

      case USER_ACTION_CODES.CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE:
        this.handleConfigurePhoneNumberAction();
        break;

      case USER_ACTION_CODES.CREATE_A_CAMPAIGN:
        this.handleCreateCampaignAction();
        break;

      case USER_ACTION_CODES.CREATE_A_TASK_POOL:
        this.handleCreateTaskPoolAction();
        break;

      case USER_ACTION_CODES.CONFIGURE_A_CARE_JOURNEY:
        this.handleConfigureCareJourneyAction();
        break;

      case USER_ACTION_CODES.CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT:
        this.handleCreateFormAction();
        break;

      case USER_ACTION_CODES.RUN_EXISTING_AUTOMATION_ON_A_MEMBER:
        this.handleRunExistingAutomationOnMember();
        break;

      case USER_ACTION_CODES.SETUP_A_PHONE_TREE:
        this.handleSetUpPhoneTreeAction();
        break;

      case USER_ACTION_CODES.LOGIN_TO_FOLD_PROVIDER_APP:
        this.handleLoginToProviderAppAction();
        break;

      case USER_ACTION_CODES.CREATE_AN_ONBOARDING_AUTOMATION:
        this.handleCreateOnboardingAutomationAction();
        break;

      case USER_ACTION_CODES.CREATE_AN_EMPLOYER_AND_COUPLE_OF_EMPLOYEES:
        this.handleCreateEmployerAction();
        break;

      case USER_ACTION_CODES.LOGIN_TO_MEMBER_PORTAL:
        this.handleLoginToMemberPortalAction();
        break;
      case USER_ACTION_CODES.HINT:
        this.handleHintAction();
        break;
      case USER_ACTION_CODES.ELATION:
        this.handleELATIONAction();
        break;
      case USER_ACTION_CODES.SPRUCE:
        this.handleSPRUCEAction();
        break;

      case USER_ACTION_CODES.SETUP_A_LEAD_GENERATION_WIDGET:
        this.handleLeadGenerationWidgetAction();
        break;

      case USER_ACTION_CODES.CHECK_SCREENING_GROUPS:
        this.handleCheckScreeningGroupAction();
        break;

      default:
        this.showInProgressToast();
        break;
    }
  }

  private handleInviteUserAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ADD,
      };
    });
  }

  private handleAddNewPatientAction() {
    this.handlePersonAction({
      personType: PERSON_TYPES.CUSTOMER,
      personTypeUuid: this.patientTypeUuid,
    });
  }

  private handleAddNewProspectAction() {
    this.handlePersonAction({
      personType: PERSON_TYPES.LEAD,
      personTypeUuid: this.prospectTypeUuid,
    });
  }

  private handlePersonAction(args: {
    personTypeUuid: string;
    personType: string;
  }) {
    const {personType, personTypeUuid} = args;
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ADD_MANUALLY,
        personType: personType,
        personTypeUuid: personTypeUuid,
      };
    });
  }

  private handleSchedulePatientAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ADD_SCHEDULE,
      };
    });
  }

  private handleAddNewTaskAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ADD_TASK,
      };
    });
  }

  private handleChatWithTeammateAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.TEXT_MESSAGE,
      };
    });
  }

  private handleSendFormToMemberAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.SEND_FORMS,
      };
    });
  }

  private handleConfigureEmailTemplateAction() {
    navigateToOtherScreen(this.navigate, '/admin/contentManagement/emailTemplates/create');
  }

  private handleCreateMemberGroupAction() {
    navigateToOtherScreen(this.navigate, '/members/groups?isWalkThrough=true');
  }

  private handleConfigurePhoneNumberAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ADD_IVR_FLOW,
      };
    });
  }

  private handleCreateCampaignAction() {
    navigateToOtherScreen(this.navigate, '/campaignManagement/active?createNew=true');
  }

  private handleCreateTaskPoolAction() {
    navigateToOtherScreen(this.navigate, '/admin/taskManagement/task_pool?isWalkThrough=true');
  }

  private handleConfigureCareJourneyAction() {
    navigateToOtherScreen(this.navigate, '/admin/commerce/journeys/automation/create?currentTab=TRIGGER&flowType=JOURNEYS');
  }

  private handleCreateFormAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ADD_FORM,
      };
    });
  }

  private handleRunExistingAutomationOnMember() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.CREATE_AUTOMATION,
      };
    });
  }

  private handleSetUpPhoneTreeAction() {
    navigateToOtherScreen(this.navigate, '/admin/communication/ivr/create?currentTab=TRIGGER&flowType=IVR');
  }

  private handleLoginToProviderAppAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.PREVIEW,
      };
    });
  }

  private handleCreateOnboardingAutomationAction() {
    navigateToOtherScreen(this.navigate, '/admin/patients/automation/create?currentTab=TRIGGER&flowType=PATIENTS');
  }

  private handleCreateEmployerAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.CREATE_EMPLOYER,
      };
    });
  }

  private handleHintAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.CONNECT_TO_HINT,
      };
    });
  }

  private handleELATIONAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.CONNECT_TO_ELATION,
      };
    });
  }

  private handleSPRUCEAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.CONNECT_TO_SPRUCE,
      };
    });
  }

  private handleLoginToMemberPortalAction() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: COMMON_ACTION_CODES.ROW_CLICKED,
      };
    });
  }

  private handleLeadGenerationWidgetAction() {
    navigateToOtherScreen(this.navigate, '/admin/webWidgets');
  }

  private handleCheckScreeningGroupAction() {
    insertUserAction({
      actionCode: 'CHECK_SCREENING_GROUPS',
      level: this.checkScreeningGroupLevel,
      status: 'COMPLETED',
      userId: this.userUuid,
    })
      .then((response) => {
        navigateToOtherScreen(this.navigate, '/members/groups');
      })
      .catch((error) => {
        navigateToOtherScreen(this.navigate, '/members/groups');
      });
  }

  private setUserTaskDisplayCategory(list: any) {
    this.setState((prev) => {
      return {
        ...prev,
        userTaskDisplayCategory: list,
      };
    });
  }

  // async getActions() {
  //   this.setLoading(true);
  //   try {
  //     const response = await getUserActionMasters({
  //       level: this.state.levelViewCode,
  //     });
  //     this.setActionList(response);
  //   } catch (error) {
  //
  //     this.setLoading(false);
  //   }
  // }

  private setLoading(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  }

  private setActionList(response: UserActionMastersApiResponse) {
    const data = response.userActionMasters;
    this.setState((prev) => {
      return {
        ...prev,
        list: data,
        loading: false,
      };
    });
  }

  private setUserList(list: any) {
    this.setState((prev) => {
      return {
        ...prev,
        userList: list,
      };
    });
  }

  private setAllUserPoolSelect(list: any) {
    this.setState((prev) => {
      return {
        ...prev,
        userPoolSelect: list,
      };
    });
  }

  setLevelViewCode(code: string) {
    this.setState((prev) => {
      return {
        ...prev,
        levelViewCode: code,
      };
    });
  }

  closeModals() {
    this.setState((prev) => {
      return {
        ...prev,
        actionViewCode: '',
      };
    });
  }

  private showInProgressToast() {
    showToast(this.toast, 'Implementation in Progress', ToastType.info);
  }

  async getUsersListIVR() {
    const responseData = await this.getUsersIvr({
      variables: {
        accountUUID: this.accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
    if (responseData?.data?.users?.length) {
      this.setState((prev) => {
        return {
          ...prev,
          userListForNumbers: responseData?.data?.users,
        };
      });
    } else {
    }
  }

  async getFormCategoryList() {
    try {
      const response = await this.getFormCategories();
      const list = response.data.formCategories || [];
      this.setCategories(list);
    } catch (error) {

      this.setCategories([]);
    }
  }

  private setCategories(list: any[]) {
    this.setState((prev) => {
      return {
        ...prev,
        categoryList: list,
      };
    });
  }

  onFormComplete(formDetail: IAddFormState) {
    {
      const selectedForm = {} as IForm;
      const formState = {
        formName: formDetail.formName,
        formCategoryId: formDetail.formCategoryId,
        formCategoryName: formDetail.formCategoryName,
        isValid: formDetail.isValid,
        categoryList: this.state.categoryList,
      };
      this.navigateToScreen(
        FormScreenContext.addForm,
        selectedForm,
        false,
        formState
      );
    }
  }

  private navigateToScreen(
    screen: string,
    form: IForm,
    isExisting: boolean,
    formState?: IAddFormState
  ) {
    const url =
      screen === FormScreenContext.addForm ? '/admin/contentManagement/forms/create' : '';
    this.navigate(url, {
      state: {
        selectedForm: form,
        addFormDetail: formState,
        isAddExisting: isExisting,
        isUpdate: screen === FormScreenContext.updateForm,
      },
    });
  }

  static getAccountUuidAndUserUuidFromResponse(response: any): {
    accountUuid: string;
    userUuid: string;
  } {
    const data = response.data;
    const userUuid = data.uuid;
    const accountUuid = data.accounts.length > 0 ? data.accounts[0].uuid : '';
    return {
      userUuid,
      accountUuid,
    };
  }

  static getActionLevelFromAction(actionCode: UserActionCode): string {
    const actionObject = getUserActionObjectFromActionCode(actionCode);
    return actionObject.level || '';
  }
}
