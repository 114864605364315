import BaseService from "../../../../../services/CommonService/BaseService";
import LocalStorage from "../../../../../utils/LocalStorage";

const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const userData = JSON.parse(userDataString);
    const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
    const accountUUID =
      userData?.data.accounts.length > 0
        ? userData?.data.accounts[0].uuid || ''
        : '';
    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;
    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
      accountUUID,
    };
    if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
      headersObj['form-widget-access-token'] = formWidgetAccessToken;
    }
    return headersObj;
  };

export const getActionsForPopulationGroup = async (params: URLSearchParams) => {
    const headers = await getHeaders();
    const baseService = BaseService.getSharedInstance().axios;
    const response = await baseService.get(
      `rule-engine/api/audit-rule`,
      {
        headers: {headers},
        params: params
      }
    );
    return response
};