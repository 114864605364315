import React from 'react';
import {View, Text, Pressable} from 'react-native';
import BillingFilterRow from './BillingFilterRow';
import {Colors} from '../../../styles/Colors';
import {useIntl} from 'react-intl';
import InfoSvg from '../../common/Svg/InfoSvg';
import Stack from '../../common/LayoutComponents/Stack';
import {Skeleton, Tooltip} from 'antd';
import CareProgramBillingTable from './CareProgramBillingTable';
import UseBillingTab from './BillingHooks/UseBillingTab';
import {BILLING_FILTER_ACTION_CODES} from './BillingHooks/BillingHooksConstants';
import SendClaimSvg from '../../common/Svg/SendClaimSvg';
import {Spinner} from 'native-base';

interface ICareProgramBilling {
  isBilledView?: boolean;
  serviceStartDateTime?: string;
  serviceEndDateTime?: string;
}

const CareProgramBilling = (props: ICareProgramBilling) => {
  const intl = useIntl();
  const {
    selectedFilters,
    handleActions,
    handleTableActions,
    paginationState,
    hookState,
    accountUserList,
    activitiesDataLoading,
    attributeAndCodesLoading,
    loadingProcessBilling,
    onChangeCustomer,
    contactsLoading
  } = UseBillingTab({
    isBilledView: props?.isBilledView,
    serviceStartDateTime: props?.serviceStartDateTime,
    serviceEndDateTime: props?.serviceEndDateTime,
  });

  const renderUnbilledAmount = (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.Custom.Gray200,
        backgroundColor: Colors.Custom.ContainerBGColor,
        padding: 12,
        width: '30%',
        margin: 12,
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Stack direction={'row'} style={{alignItems: 'center'}}>
        <Text
          style={{
            marginBottom: 4,
            color: Colors.FoldPixel.GRAY200,
            fontWeight: '500',
            fontSize: 16,
            marginRight: 4,
          }}
        >
          {intl.formatMessage({id: 'totalUnbilledAmount'})}
        </Text>
        <Tooltip
          title={intl.formatMessage({id: 'unbilledAmountInfo'})}
          destroyTooltipOnHide={true}
        >
          <View style={{alignItems: 'center'}}>
            <InfoSvg
              customDimension={'15'}
              customColor={Colors.FoldPixel.GRAY200}
            />
          </View>
        </Tooltip>
      </Stack>
      <Text
        style={{
          marginBottom: 4,
          color: Colors.FoldPixel.GRAY400,
          fontWeight: '500',
          fontSize: 22,
        }}
      >
        $ {hookState.totalAmountSum}
      </Text>
    </View>
  );

  const renderSendClaimTOEHrButton = () => {
    return (
      <Stack
        style={{
          borderWidth: 1,
          borderRadius: 8,
          borderColor: Colors.Custom.PrimaryColor200,
          backgroundColor: Colors.Custom.purpleBackgroundContainer,
          padding: 12,
          width: '30%',
          margin: 12,
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        direction={'row'}
        space={30}
      >
        <View>
          <Stack direction={'row'} style={{alignItems: 'center'}}>
            <Text
              style={{
                marginBottom: 4,
                color: Colors.FoldPixel.GRAY200,
                fontWeight: '500',
                fontSize: 16,
                marginRight: 4,
              }}
            >
              {hookState?.selectedBills?.length +
                ' ' +
                intl.formatMessage({id: 'selectedBillableAmount'}) +
                ' '}
            </Text>
          </Stack>
          <Text
            style={{
              marginBottom: 4,
              color: Colors.FoldPixel.GRAY400,
              fontWeight: '500',
              fontSize: 22,
            }}
          >
            {hookState?.selectedBills?.reduce(
              (sum, currentValue) => sum + currentValue.totalAmount,
              0
            )}
          </Text>
        </View>
        <Pressable
          disabled={loadingProcessBilling}
          onPress={() =>
            handleActions(
              BILLING_FILTER_ACTION_CODES.SEND_CLAIM_TO_EHR,
              undefined
            )
          }
          style={{alignSelf: 'center'}}
        >
          <Stack
            direction={'row'}
            style={{
              backgroundColor: Colors.Custom.Primary300,
              alignSelf: 'center',
              alignItems: 'center',
              paddingVertical: 7.5,
              paddingHorizontal: 8,
              borderRadius: 4,
            }}
            space={2}
          >
            {loadingProcessBilling ? (
              <Spinner />
            ) : (
              <>
                <SendClaimSvg />
                <Text
                  style={{
                    fontWeight: '500',
                    color: Colors.Custom.FontColorWhite,
                    fontSize: 14,
                  }}
                >
                  {intl.formatMessage({id: 'sendClaimTOEhr'})}
                </Text>
              </>
            )}
          </Stack>
        </Pressable>
      </Stack>
    );
  };

  return (
    <>
      {!props?.isBilledView && (
        <BillingFilterRow
          hookState={hookState}
          selectedFilters={selectedFilters}
          handleActions={handleActions}
          accountUserList={accountUserList}
          onChangeCustomer={onChangeCustomer}
          contactsLoading={contactsLoading}
        />
      )}
      {activitiesDataLoading && <Skeleton active />}
      {!activitiesDataLoading && (
        <>
          <Stack direction={'row'} style={{justifyContent: 'space-between'}}>
            {!props?.isBilledView && renderUnbilledAmount}
            {!!hookState?.selectedBills?.length &&
              hookState?.selectedBills?.length > 0 &&
              renderSendClaimTOEHrButton()}
          </Stack>
          <CareProgramBillingTable
            onPagination={(page: number, pageSize: number) =>
              handleActions(BILLING_FILTER_ACTION_CODES.PAGINATION, {
                page: page,
                pageSize: pageSize,
              })
            }
            pageNo={paginationState.pageNo}
            pageSize={paginationState.limit}
            total={paginationState.total}
            handleTableActions={handleTableActions}
            hookState={hookState}
            accountUserList={accountUserList}
            attributeAndCodesLoading={attributeAndCodesLoading}
            isBilledView={props?.isBilledView}
            handleActions={handleActions}
          />
        </>
      )}
    </>
  );
};

export default CareProgramBilling;
