import React, {useContext, useState} from 'react';
import {Box, HStack, Text, VStack, View, useToast} from 'native-base';
import {
  getCapability,
  getResourceAbilities,
} from '../../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import {DetailViewCollapse} from '../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {Colors} from '../../../../styles';
import {AllowedOperationKeys} from './interfaces';
import ConfigField from './components/ConfigField';
import {
  IAbility,
  IAllowedOperation,
  IEhrCapability,
} from '../../../../Interfaces';
import {GLOBAL_SWITCH_BY_RESOURCE, getEnabledOperations, getHeaderRightView, getSectionViewConfig} from './EHRConfigHelpers';
import {cloneDeep} from 'lodash';
import {updateEhrCapabilities} from '../../../../services/CommonService/AidBoxService';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CommonDataAction} from '../../../CommonDataProvider/interface';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { getNewCapabilitiesForContext } from '../../WearableDataIngest/WearableDataSyncUtils';

const EHRConfig = () => {
  const commonData = useContext(CommonDataContext);
  const intl = useIntl();
  const toast = useToast();
  const capability = getCapability(FHIR_RESOURCE.ORGANIZATION);
  const resource = getResourceAbilities(FHIR_RESOURCE.ORGANIZATION);
  const [abilityList, setAbilityList] = useState(resource?.capabilities || []);
  const [activePanelKeys, setActivePanelKeys] = useState<Set<string>>(
    new Set()
  );
  const [loading, setLoading] = useState<{
    abilityName: string;
    key: string;
  }>();

  const hidePanel = (key: string) => {
    setActivePanelKeys((prev) => {
      const newSet = new Set(prev);
      newSet.delete(key);
      return newSet;
    });
  };

  const showPanel = (key: string) => {
    setActivePanelKeys((prev) => new Set(prev).add(key));
  };

  const getNewAbilityJSON = (
    key: AllowedOperationKeys,
    value: any,
    abilityName: string
  ) => {
    const globalSwitchActions = GLOBAL_SWITCH_BY_RESOURCE[abilityName];
    if(globalSwitchActions && globalSwitchActions.length > 0) {
      const data:IAllowedOperation = {};
      globalSwitchActions?.map(item => data[item] = value);
      return data;
    }
    return {
      [key]: value,
    };
  };

  const onChangeConfig = async (
    key: AllowedOperationKeys,
    value: any,
    abilityName: string
  ) => {
    setLoading({abilityName, key});
    const tempAbilityList = cloneDeep(abilityList);
    const abilityFoundIndex = tempAbilityList.findIndex(
      (ability) => ability.resourceName === abilityName
    );
    const abilityFound = tempAbilityList[abilityFoundIndex];
    if (abilityFoundIndex !== -1) {
      tempAbilityList[abilityFoundIndex] = {
        ...abilityFound,
        abilities: {
          ...abilityFound.abilities,
          allowedOperations: {
            ...abilityFound.abilities?.allowedOperations,
            ...getNewAbilityJSON(key, value, abilityName),
          },
        },
      };
    }
    const postData: IEhrCapability = {
      ...capability,
      abilities: {...resource, capabilities: tempAbilityList},
    };
    await updateEhrCapabilities(
      postData,
      (res) => {
        setAbilityList(tempAbilityList);
        setLoading(undefined);
        commonData?.dispatch?.({
          type: CommonDataAction.UPDATE_EHR_CAPABILITES,
          payload: getNewCapabilitiesForContext(commonData, postData, res?.data?.id),
        });
      },
      (err) => {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
          1000
        );
        setLoading(undefined);
      }
    );
  };
  return (
    <VStack h="full" flex={1} mx={10} my={2}>
      {abilityList.map((item, index) => {
        const key = item.resourceName + '_' + item.operation + '_' + index;
        const isOpen = activePanelKeys.has(key as string);
        const allowedOperationsJSON = item?.abilities
          ?.allowedOperations as IAllowedOperation;
        const allowedOperationsList = getEnabledOperations(
          item?.resourceName as string,
          allowedOperationsJSON
        );

        const sectionViewConfig = getSectionViewConfig(
          item.resourceName as string
        );

        
        if (!allowedOperationsList?.length && !sectionViewConfig.title) {
          return null;
        }


        return (
          <View my={4} key={key}>
            <DetailViewCollapse
              expandIconPosition="start"
              headerRightView={getHeaderRightView(
                allowedOperationsJSON as IAllowedOperation,
                allowedOperationsList as AllowedOperationKeys[]
              )}
              btnList={[]}
              toggled={isOpen}
              extraPanelStyles={{
                borderColor: Colors.Custom.Gray200,
              }}
              textLocalId={sectionViewConfig?.title as string}
              onToggled={() => {
                if (isOpen) {
                  hidePanel(key);
                } else {
                  showPanel(key);
                }
              }}
              headerIcon={<Box h={4} w={4} mr={2}>{sectionViewConfig.icon}</Box>}
              subHeader={
                <Text color={Colors.Custom.Gray500}>
                  {sectionViewConfig.subtitle}
                </Text>
              }
            >
              <HStack
                p={2}
                px={4}
                flexWrap={'wrap'}
                justifyContent={'flex-start'}
              >
                {allowedOperationsList.map((operation, index) => (
                  <ConfigField
                    resourceName={item?.resourceName || ''}
                    disabled={
                      !!loading?.abilityName.length && !!loading.key.length
                    }
                    loading={
                      operation === loading?.key &&
                      item.resourceName === loading.abilityName
                    }
                    key={
                      operation +
                      allowedOperationsJSON?.[
                        operation as keyof IAllowedOperation
                      ]
                    }
                    value={
                      allowedOperationsJSON?.[
                        operation as keyof IAllowedOperation
                      ]
                    }
                    operation={operation as AllowedOperationKeys}
                    index={index}
                    handleChange={(key, value) =>
                      onChangeConfig(key, value, item.resourceName as string)
                    }
                  />
                ))}
              </HStack>
            </DetailViewCollapse>
          </View>
        );
      })}
    </VStack>
  );
};

export default EHRConfig;
