import {useLazyQuery} from '@apollo/client';
import {Skeleton, Select, Empty} from 'antd';
import {cloneDeep} from 'lodash';
import {
  Button,
  Divider,
  HStack,
  InfoOutlineIcon,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../../constants';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {IUser} from '../../../../../Interfaces/CommonInterfaces';
import {UserQueries} from '../../../../../services';
import {Colors} from '../../../../../styles/Colors';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { ICareTeamRequiredRole } from '../../AddOrUpdateJourney/DetailsAndMembers/Interfaces';
import JourneyDetailTagInput from '../../AddOrUpdateJourney/DetailsAndMembers/JourneyDetailTagInput';

import {
  IPatientCareJourneyTeamState,
  IPatientJourneyUserData,
} from './PatientJourneyInterface';
export interface ICcareTeamRoleSuggestions {
  roles: {
    [nodeId: string]: {
      name: string;
      roleId: string;
    };
  };
}


const PatientCareJourneyTeam = (props: {
  careTeam: string[];
  careTeamRoleSuggestions?: ICcareTeamRoleSuggestions;
  careTeamRequiredRoles?: ICareTeamRequiredRole;
  careTeamUsers: {roleId: string; name: string; uuid: string}[];
  resetCareTeamRoleSuggestions?: () => void;
  onChange: (careTeam: string[], careTeamUser: any[], noUserConfiguredForSomeRole?: boolean) => void;
  startDate?: string;
}) => {
  const toast = useToast();
  const intl = useIntl();
  const commonContextData = useContext(CommonDataContext);
  const accountUUID = getAccountUUID();

  const roleList =
    getMlovListFromCategory(commonContextData.MLOV, MLOV_CATEGORY.USER_ROLES) ||
    [];
  const userRoleMlov = roleList.map(role => {
    return {
      label: role.value,
      value: role.id,
    };
  });

  const getDefaultCareTeamState = () => {
    const suggestedRoleIds: string[] = [];
    Object.values(props?.careTeamRoleSuggestions?.roles || []).forEach(
      (role: any) => {
        const suggestedRole = role?.roleId;
        if (suggestedRole && !(props?.careTeam || []).includes(suggestedRole)) {
          suggestedRoleIds.push(suggestedRole);
        }
      },
    );

    return {
      careTeamRoles: suggestedRoleIds.concat(props?.careTeam || []),
      loading: true,
      accountUsers: [],
      selectedUserRoleMap: new Map<string, string>(),
      roleWiseUserList: new Map<string, IPatientJourneyUserData[]>(),
      hasCareTeamSuggestion: suggestedRoleIds?.length > 0,
    } as IPatientCareJourneyTeamState;
  };

  const [careJourneyTeamState, setCareJourneyTeamState] =
    useState<IPatientCareJourneyTeamState>(getDefaultCareTeamState());

  const [rolesAvailable, setRolesAvailable] = useState<boolean>()

  const isUserSelected = (userId: string, roleId: string) => {
    const matchedData = props.careTeamUsers.filter(
      user => user.uuid === userId && user.roleId === roleId,
    );
    return matchedData.length > 0;
  };

  const isMatchUserRoleWithCareTeamRole = (
    careTeamRoleId: string | undefined,
    user: IUser,
  ): boolean => {
    return (user.userRoles || []).some(userRole => {
      return userRole.userRole?.userRole?.id === careTeamRoleId;
    });
  };

  const fetchRoleWiseUserList = (roleId: string, accountUserList?: IUser[]) => {
    const filteredUsers = (
      accountUserList ||
      careJourneyTeamState.accountUsers ||
      []
    ).filter(user => {
      return isMatchUserRoleWithCareTeamRole(roleId, user);
    });
    const formattedUsers: IPatientJourneyUserData[] = (filteredUsers || []).map(
      user => {
        return {
          name: user.name,
          uuid: user.uuid,
          roleId: roleId,
          isSelected: isUserSelected(user.uuid, roleId),
        } as IPatientJourneyUserData;
      },
    );
    return formattedUsers;
  };

  const getRoleWiseUserListMap = (accountUserList?: IUser[]) => {
    const roleWiseUsersMap: Map<string, IPatientJourneyUserData[]> = new Map<
      string,
      IPatientJourneyUserData[]
    >();
    (roleList || []).forEach(role => {
      const roleWiseUsers: IPatientJourneyUserData[] = fetchRoleWiseUserList(
        role.id,
        accountUserList,
      );
      roleWiseUsersMap.set(role.id, roleWiseUsers);
    });
    return roleWiseUsersMap;
  };

  const [getAccountUserWithRole] = useLazyQuery(
    UserQueries.GET_USERS_FOR_CALENDAR_WITH_LOCATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const accountUsers = cloneDeep(data?.users);
        setCareJourneyTeamState(prev => {
          return {
            ...prev,
            accountUsers,
            roleWiseUserList: getRoleWiseUserListMap(accountUsers),
            loading: false,
          };
        });
        handleCareTeamRoleSelect(careJourneyTeamState.careTeamRoles, getRoleWiseUserListMap(accountUsers));
      },
      onError: error => {
        setCareJourneyTeamState(prev => {
          return {
            ...prev,
            loading: false,
          };
        });

        showErrorToast();
      },
    },
  );

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  useEffect(() => {
    getAccountUserWithRole({
      variables: {
        accountId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
    if(props.careTeam.length === 0 && props?.careTeamRequiredRoles?.roles && (Object.keys(props?.careTeamRequiredRoles?.roles).length === 0)) {
      setRolesAvailable(false)
    }
  }, []);

  const handleOnUserSelect = (user: IPatientJourneyUserData) => {
    setCareJourneyTeamState(prev => {
      const roleId = user?.roleId;
      const roleUserList = careJourneyTeamState.roleWiseUserList.get(roleId);
      (roleUserList || []).forEach(roleUser => {
        roleUser.isSelected = roleUser.uuid === user.uuid;
      });
      if (roleUserList) {
        careJourneyTeamState.roleWiseUserList.set(roleId, roleUserList);
      }
      const selectedUserRoleMap = prev.selectedUserRoleMap;
      selectedUserRoleMap.set(user.roleId, user.uuid);
      return {
        ...prev,
        selectedUserRoleMap,
      };
    });
    const selectedUsers: any[] = [];
    (careJourneyTeamState.careTeamRoles || []).forEach(roleKey => {
      const userList = careJourneyTeamState.roleWiseUserList.get(roleKey);
      (userList || []).forEach(roleUser => {
        if (roleUser.isSelected) {
          selectedUsers.push(roleUser);
        }
      });
    });

    if (props?.onChange) {
      props?.onChange(selectedUsers, careJourneyTeamState.careTeamRoles);
    }
  };

  const handleCareTeamRoleSelect = (careTeamRoles: any[], roleWiseUserList: Map<string, IPatientJourneyUserData[]>) => {
    // const selectedUserRoleMap = new Map<string, string>();
    let noUserConfigguredForSomeRole = false
    const selectedUsers: any[] = [];
    (careTeamRoles || []).forEach(roleKey => {
      const userList = roleWiseUserList.get(roleKey);
      if(userList?.length === 0 || userList === undefined){
        noUserConfigguredForSomeRole = true
      }
      (userList || []).forEach(roleUser => {
        if (roleUser.isSelected) {
          selectedUsers.push(roleUser);
        }
      });
    });
    if (props?.onChange) {
      props?.onChange(selectedUsers, careTeamRoles || careJourneyTeamState.careTeamRoles, noUserConfigguredForSomeRole);
    }
  };

  return (
    <View paddingLeft={8}>
      {careJourneyTeamState.loading && (
        <View width="full" padding={4}>
          <Skeleton active />
        </View>
      )}
      {!careJourneyTeamState.loading &&
        !careJourneyTeamState.accountUsers?.length && (
          <Text>There is no account user</Text>
        )}
      {!careJourneyTeamState.loading &&
        careJourneyTeamState.accountUsers.length &&
        rolesAvailable !== false &&
         (
          <View>
            <VStack margin={2}>
              <JourneyDetailTagInput
                isRequired={false}
                labelId="careTeam"
                descriptionId="journeyCareTeamDescription"
                value={careJourneyTeamState.careTeamRoles}
                careTeamRoleSuggestions={props.careTeamRoleSuggestions}
                careTeamRequiredRoles={props.careTeamRequiredRoles}
                resetCareTeamRoleSuggestions={props.resetCareTeamRoleSuggestions}
                options={userRoleMlov}
                onChange={(value) => {
                  setCareJourneyTeamState((prev) => ({...prev, careTeamRoles: value}));
                  handleCareTeamRoleSelect(value, careJourneyTeamState.roleWiseUserList);
                }}
              />
            </VStack>
          {careJourneyTeamState.careTeamRoles.length !== 0 ? (
            <>
              <HStack margin={2} marginTop={16}>
                <DisplayText
                  size={'mdSemibold'}
                  extraStyles={{
                    fontWeight: 500,
                    fontSize: 18,
                    fontFamily: 'Manrope',
                  }}
                  textLocalId={'careTeamMembers'}
                />
                {props?.startDate && <Text color="error.500">*</Text>}
              </HStack>
              <VStack margin={2} flex={1}>
                {(careJourneyTeamState.careTeamRoles || []).map(
                  (roleKey, index) => {
                    const userList =
                      careJourneyTeamState.roleWiseUserList.get(roleKey);
                    return (
                      <>
                        <HStack alignItems="center" space={2} key={index}>
                          <Text flex={0.15}>
                            {getMlovValueFromId(roleList, roleKey)}
                          </Text>
                          <View
                            flex={0.8}
                            flexDirection="row"
                            flexWrap="wrap"
                            marginTop={2}>
                            {!userList?.length && (
                              <Text>No user is configured for this role.</Text>
                            )}
                            {(userList || []).map(
                              (
                                user: IPatientJourneyUserData,
                                roleIndex: number,
                              ) => {
                                return (
                                  <View
                                    key={'User_' + roleIndex}
                                    marginRight={4}
                                    marginBottom={2}>
                                    <Button
                                      onPress={() => {
                                        handleOnUserSelect(user);
                                      }}
                                      variant={
                                        user.isSelected ? 'solid' : 'outline'
                                      }>
                                      {user.name}
                                    </Button>
                                  </View>
                                );
                              },
                            )}
                          </View>
                        </HStack>
                        {careJourneyTeamState.careTeamRoles?.length >
                          index + 1 && (
                            <Divider margin={2} marginTop={2} width={'100%'} />
                          )}
                      </>
                    );
                  },
                )}
              </VStack>
            </>)
            : (
              <VStack margin={2} marginTop={16}>
                <DisplayText
                  size={'mdSemibold'}
                  extraStyles={{
                    fontWeight: 500,
                    fontSize: 18,
                    fontFamily: 'Manrope',
                  }}
                  textLocalId={'selectRoleToAddMembers'}
                />
              </VStack>
            )
          }
        </View>
        )}
      {
        rolesAvailable === false &&
        (
          <VStack alignItems={'center'} paddingRight={8} flex={1} space={2}>
            <Empty description={<text fontFamily='Manrope, sans-serif' style={{ color: '#D0D5DD' }}>No care team selected</text>} />
            <FoldButton
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Add Care Team',
                }),
              }}
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                backgroundColor: Colors.Custom.BackgroundColor,
                color: Colors.Custom.mainPrimaryPurple,
                onPress: () => {
                  setRolesAvailable(true)
                },
              }}
            />
          </VStack>
        )
      }
    </View>
  );
};

export default PatientCareJourneyTeam;
