import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  formLabel: {
    marginLeft: 4,
  },
});
