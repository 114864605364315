import {DatePicker, Drawer} from 'antd';
import {Divider, FormControl, HStack, Text, useToast} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
} from '../../../../../constants';
import CommonService from '../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../styles';
import {
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {getMomentObj, isFutureDate} from '../../../../../utils/DateUtils';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import SkeletonLoader from '../../../Contacts/TeamMembers/AddEditUser/components/SkeletonLoader';
import {styles} from '../../AccessFormStyles';
import {APILogsDrawerProps} from '../interfaces';
const {Label, ErrorMessage} = FormControl;

const APILogsDrawer = (props: APILogsDrawerProps) => {
  const {isOpen, onClose} = props;
  const userActionObject = getUserActionObjectFromActionCode('HINT');
  const level = userActionObject.level || '';
  const accountUuid = getAccountUUID();
  const userId = getUserUUID();
  const intl = useIntl();
  const commonService = CommonService.getCommonServiceInstance();
  const foldApiService = commonService.foldApiService;
  const [state, setState] = useState({
    submitting: false,
    accessCode: '',
    formError: {} as any,
    error: false,
    fromDate: new Date(),
    toDate: new Date(),
  });

  const toast = useToast();

  const viewLogs = async () => {
    setState((prev) => {
      return {
        ...prev,
        submitting: true,
      };
    });

    foldApiService
      .post(
        '/v1/audit/report',
        {
          accountUuid: accountUuid,
          offset: 0,
          limit: 1000,
          from: state.fromDate,
          to: state.toDate,
          clientId: props.clientId,
        }
      )
      .then((resp: {data: any}) => {
        // text content
        const obj = resp?.data;
        const bytes = new TextEncoder().encode(obj);
        const blob = new Blob([bytes], {
          type: 'application/json;charset=utf-8',
        });

        // anchor link
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = `access-logs-${props.clientId}-${Date.now()}.csv`;

        // simulate link click
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        setState((prev) => {
          return {
            ...prev,
            submitting: false,
          };
        });
      });
  };

  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      width={'33vw'}
      title={
        <>
          <HStack
            style={{
              marginTop: 10,
              marginHorizontal: 0,
              marginBottom: 20,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text size={'xxlBold'} color={Colors.primary[400]}>
              View API logs
            </Text>

            <HStack space={2} alignContent="center" alignItems={'center'}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onClose();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'cancel',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    viewLogs();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'View',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </HStack>
        </>
      }
    >
      {state.submitting ? (
        <SkeletonLoader />
      ) : (
        <>
          <Text size={'smMedium'} color={Colors.Custom.Gray700}>
            Client Id : {props.clientId}
          </Text>
          <FormControl
            style={styles.formContainer}
            isRequired
            isInvalid={state.formError.fromDate}
          >
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                From Date
              </Text>
            </Label>
            <DatePicker
              style={{height: '40px'}}
              allowClear={false}
              defaultValue={getMomentObj(new Date())}
              format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => current && isFutureDate(current)}
              onChange={(date: any) => {
                setState((prevState) => ({
                  ...prevState,
                  fromDate: date,
                }));
              }}
            />
          </FormControl>
          <FormControl
            style={styles.formContainer}
            isRequired
            isInvalid={state.formError.toDate}
          >
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                To Date
              </Text>
            </Label>
            <DatePicker
              style={{height: '40px'}}
              allowClear={false}
              defaultValue={getMomentObj(new Date())}
              format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => current && isFutureDate(current)}
              onChange={(date: any) => {
                setState((prevState) => ({
                  ...prevState,
                  toDate: date,
                }));
              }}
            />
          </FormControl>
          <Divider
            marginTop={5}
            marginBottom={5}
            style={{width: '100%'}}
            bg={Colors.Custom.Gray200}
          />
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                viewLogs();
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'Download API Access Logs',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
        </>
      )}
    </Drawer>
  );
};

export default APILogsDrawer;
