import { useLazyQuery } from '@apollo/client';
import { Drawer, Table } from 'antd';
import { CheckIcon, FormControl, HStack, Input, Spinner, Text, TextArea, useToast, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../../constants';
import { KEY_ALLOWED_OPERATIONS } from '../../../../../constants/FhirConstant';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import { Colors } from '../../../../../styles';
import { getGenderIdentityLabel, getPossibleValuesByOperationName, getSexAtBirthTransformValues } from '../../../../../utils/capabilityUtils';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import PAMISearch, { PAMISearchType } from '../../../../common/PAMISearch/PAMISearch';
import { getCodeSystem } from '../../../../common/PAMISearch/PAMISearchHelper';
import { RichTextEditor, TEXT_ONLY_MODULES } from '../../../../common/RichTextEditor/RichTextEditor';
import VitalSearch from '../../../../common/VitalSearch/VitalSearch';
import { ZipCodeAutoComplete } from '../../../../common/ZipCodeComponent';
import { IFormCommonData } from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { mapGenderDisplayCode } from '../../Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { createOrUpdateCodeGroup, getCategoriesForCodeGroup } from '../CodeGroupApi';
import { CODE_GROUP_CATEGORY } from '../constants';
import { IAddCodeGroupState, IAddCodeGroupModalProps, ISelectedCodeGroupValue } from '../interfaces';
import { addCodeGroupTableColumns } from './AddCodeGroupTableData';

const { Label } = FormControl;

const AddCodeGroupModal = (props: IAddCodeGroupModalProps) => {
  const { isModalOpen, onModalClose } = props;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const genderIdentityLabel = getGenderIdentityLabel();
  const toast = useToast();
  const intl = useIntl()
  const [addCodeGroupState, setAddCodeGroupState] = useState<IAddCodeGroupState>({
    categories: [],
    newCodeGroupName: props?.codeGroupDataForEditOrView?.name || '',
    newCodeGroupDescription: props?.codeGroupDataForEditOrView?.description || '',
    selectedCodeGroupCategory: props?.codeGroupDataForEditOrView?.category ? { code: props?.codeGroupDataForEditOrView?.category, display: props?.codeGroupDataForEditOrView?.category } : {},
    selectedCodeGroupListValues: props?.codeGroupDataForEditOrView?.codes || [],
    loading: false
  })

  const [fetchedData, setFetchedData] = useState({
    category: '',
    data: {}
  })

  const [errors, setErrors] = useState<any>({
    newCodeGroupNameError: '',
    selectedCodeGroupCategoryError: '',
    selectedCodeGroupListValuesError: ''
  });

  const validate = () => {
    setErrors({});
    let validate = true
    if (addCodeGroupState.newCodeGroupName.trim() === '') {
      setErrors((prev: any) => ({ ...prev, newCodeGroupNameError: 'Please enter a name' }));
      validate = false;
    }
    if (!addCodeGroupState.selectedCodeGroupCategory.code) {
      setErrors((prev: any) => ({ ...prev, selectedCodeGroupCategoryError: 'Please select a category' }));
      validate = false;
    }
    if (addCodeGroupState.selectedCodeGroupCategory.code && addCodeGroupState.selectedCodeGroupListValues.length === 0) {
      setErrors((prev: any) => ({ ...prev, selectedCodeGroupListValuesError: `Please select a ${addCodeGroupState?.selectedCodeGroupCategory?.display?.toLocaleLowerCase()}` }));
      validate = false;
    }
    return validate
  };

  useEffect(() => {
    fetchCategories();
  }, [])

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesForCodeGroup();
      if (response?.data) {
        setAddCodeGroupState((prev) => ({ ...prev, categories: response?.data?.expansion?.contains }))
      }
    } catch (error) {
      showToast(toast, 'Error in fetching categories', ToastType.error)
    }
  }

  const handleAddOrUpdateCodeGroup = async () => {
    if (validate()) {
      const newCodeGroup: any = {
        name: addCodeGroupState.newCodeGroupName,
        description: addCodeGroupState.newCodeGroupDescription,
        category: addCodeGroupState.selectedCodeGroupCategory.code,
        codes: addCodeGroupState.selectedCodeGroupListValues,
        ...props?.isEdit && { id: props?.codeGroupDataForEditOrView?.id }
      };
      setAddCodeGroupState((prev) => ({ ...prev, loading: true }))
      try {
        const response = await createOrUpdateCodeGroup(newCodeGroup);
        setAddCodeGroupState((prev) => ({
          ...prev,
          newCodeGroupName: '',
          newCodeGroupDescription: '',
          selectedCodeGroupCategory: {},
          selectedCodeGroupListValues: [],
        }));
        if (response?.data) {
          showToast(toast, props?.isEdit ? 'Code group updated' : 'Code group created', ToastType.success)
          props?.onModalClose()
          props?.onSave()
        }
        setAddCodeGroupState((prev) => ({ ...prev, loading: false }))
        setErrors({});
      } catch (error: any) {
        setAddCodeGroupState((prev) => ({ ...prev, loading: false }))
        if (error?.response?.data?.statusCode === 400) {
          setErrors((prev: any) => ({ ...prev, newCodeGroupNameError: intl.formatMessage({ id: 'codeGroupNameAlreadyExists' }) }));
        }
        else {
          showToast(toast, 'Something went wrong', ToastType.error)
        }
      }
    };
  }
  const finalWidth = 600;

  const getCategorySearchType = (category: string) => {
    switch (category) {
      case CODE_GROUP_CATEGORY.PROBLEM:
        return PAMISearchType.problem;
      case CODE_GROUP_CATEGORY.ALLERGY:
        return PAMISearchType.allergy;
      case CODE_GROUP_CATEGORY.MEDICATION:
        return PAMISearchType.medication;
      case CODE_GROUP_CATEGORY.IMMUNIZATION:
        return PAMISearchType.immunization;
      case CODE_GROUP_CATEGORY.DIAGNOSIS:
        return PAMISearchType.diagnosis;
      default:
        return PAMISearchType.procedure;
    }
  };

  const renderListSearch = () => {
    const category = addCodeGroupState.selectedCodeGroupCategory.code
    const key = category || ''
    switch (category) {
      case CODE_GROUP_CATEGORY.PROBLEM:
      case CODE_GROUP_CATEGORY.ALLERGY:
      case CODE_GROUP_CATEGORY.MEDICATION:
      case CODE_GROUP_CATEGORY.IMMUNIZATION:
      case CODE_GROUP_CATEGORY.PROCEDURE:
      case CODE_GROUP_CATEGORY.DIAGNOSIS:

        return (
          <PAMISearch
            key={key}
            showAdditionalDetails={true}
            addNewOptionEnabled={false}
            clearOnSelection
            searchType={getCategorySearchType(category)}
            additionalHeaders={contextData.headers}
            placeholder={`Search ${(category === CODE_GROUP_CATEGORY.PROBLEM) ? 'problem' : (category === CODE_GROUP_CATEGORY.ALLERGY) ? 'allergy' : (category === CODE_GROUP_CATEGORY.MEDICATION) ? 'medication' : (category === CODE_GROUP_CATEGORY.IMMUNIZATION) ? 'immunization' : (category === CODE_GROUP_CATEGORY.DIAGNOSIS) ? 'diagnosis' : 'procedure'
              }`}
            isShowError={false}
            onChange={(value) => {
              if (value && !addCodeGroupState.selectedCodeGroupListValues.some(item => item.code === value.coding[0].code)) {
                const formattedValue = { code: value.coding[0].code, display: value?.text || value.coding[0].display, additionalInfo: {system: value.coding[0].system} }
                setAddCodeGroupState((prev) => {
                  return {
                    ...prev,
                    selectedCodeGroupListValues: [...prev.selectedCodeGroupListValues, formattedValue]
                  }
                })
                setErrors((prev: any) => ({
                  ...prev,
                  selectedCodeGroupListValuesError: ''
                }))
              }
            }}
          />
        )
      case CODE_GROUP_CATEGORY.ZIP_CODE:
        let triggeredAndCompletedActionOnce = false // onAutoCompleteChange of ZipCodeAutoComplete is triggered twice when selected once. This flag is to handle it.
        return (
          <ZipCodeAutoComplete
            name={''}
            onAutoCompleteChange={(selectedZip) => {
              if (selectedZip && !triggeredAndCompletedActionOnce && !addCodeGroupState.selectedCodeGroupListValues.some(item => item.code === selectedZip.code)) {
                const formattedValue = { code: selectedZip.code, display: selectedZip.code }
                setAddCodeGroupState((prev) => {
                  return {
                    ...prev,
                    selectedCodeGroupListValues: [...prev.selectedCodeGroupListValues, formattedValue]
                  }
                })
                setErrors((prev: any) => ({
                  ...prev,
                  selectedCodeGroupListValuesError: ''
                }))
                triggeredAndCompletedActionOnce = true
              }
            }}
            isInvalid={errors.selectedCodeGroupListValuesError}
            showAdd={false}
          />
        )
      case CODE_GROUP_CATEGORY.VITAL:
        return (
          <VitalSearch
            value={{ "loinc": '' }}
            isShowError={errors.selectedCodeGroupListValuesError}
            onChange={(value) => {
              if (value && !addCodeGroupState.selectedCodeGroupListValues.some(item => item.code === value.ionic)) {
                const formattedValue = { code: value.loinc, display: value.name }
                setAddCodeGroupState((prev) => {
                  return {
                    ...prev,
                    selectedCodeGroupListValues: [...prev.selectedCodeGroupListValues, formattedValue]
                  }
                })
                setErrors((prev: any) => ({
                  ...prev,
                  selectedCodeGroupListValuesError: ''
                }))
              }
            }}
          />
        )
      case CODE_GROUP_CATEGORY.EMPLOYER:
        return (
          <ModalActionAntSelect
            size="large"
            value={addCodeGroupState.selectedCodeGroupListValues.length > 0 ? '' : undefined}
            showSearch
            optionFilterProp="children"
            placeholder="Select Employer"
            className={'select-employer'}
            label={''}
            filterOption={(input: string, option: any) => {
              return (option?.children || '').toLowerCase().includes(input.toLowerCase());
            }}
            onChange={(value: any[], data: any) => {
              if (data && !addCodeGroupState.selectedCodeGroupListValues.some(item => item.code === data.value)) {
                const formattedValue = { code: data.value, display: data.children }
                setAddCodeGroupState((prev) => {
                  return {
                    ...prev,
                    selectedCodeGroupListValues: [...prev.selectedCodeGroupListValues, formattedValue]
                  }
                })
              }
              setErrors((prev: any) => ({
                ...prev,
                selectedCodeGroupListValuesError: ''
              }))
            }}
            extraStyle={{ flex: 1 }}
            data={fetchedData.data}
            optionProps={{
              key: 'id',
              label: 'name',
              value: 'id',
            }}
          />
        )
      case CODE_GROUP_CATEGORY.SEX_AT_BIRTH:
        const sexAtBirthData = getPossibleValuesByOperationName(
          KEY_ALLOWED_OPERATIONS.BIRTH_SEX
        )
        return (
          <ModalActionAntSelect
            value={addCodeGroupState.selectedCodeGroupListValues.length > 0 ? '' : undefined}
            isRequired={true}
            changeBackground={true}
            placeholder={`Select Sex at birth`}
            endIcon={<CheckIcon size="5" />}
            onChange={(value: any, data: any) => {
              if (data && !addCodeGroupState.selectedCodeGroupListValues.some(item => item.code === data.value)) {
                const formattedValue = { code: data.value, display: data.children }
                setAddCodeGroupState((prev) => {
                  return {
                    ...prev,
                    selectedCodeGroupListValues: [...prev.selectedCodeGroupListValues, formattedValue]
                  }
                })
              }
              setErrors((prev: any) => ({
                ...prev,
                selectedCodeGroupListValuesError: ''
              }))
            }}
            data={sexAtBirthData}
            optionProps={{
              key: 'code',
              label: 'display',
              value: 'code',
            }}
            extraStyle={{ flex: 1 }}
          />
        )
      case CODE_GROUP_CATEGORY.GENDER_IDENTITY:
        let personGender = getMlovListFromCategory(
          contextData.MLOV,
          MLOV_CATEGORY.PERSON_GENDER
        );

        const genderTransformValues = getSexAtBirthTransformValues(
          KEY_ALLOWED_OPERATIONS.GENDER
        );

        personGender = mapGenderDisplayCode(personGender, genderTransformValues)
        return (
          <ModalActionAntSelect
            label={genderIdentityLabel}
            isRequired={false}
            value={addCodeGroupState.selectedCodeGroupListValues.length > 0 ? '' : undefined}
            placeholder={`Select ${genderIdentityLabel}`}
            endIcon={<CheckIcon size="5" />}
            onChange={(value: any, data: any) => {
              if (data && !addCodeGroupState.selectedCodeGroupListValues.some(item => item.code === data.value)) {
                const formattedValue = { code: data.value, display: data.children }
                setAddCodeGroupState((prev) => {
                  return {
                    ...prev,
                    selectedCodeGroupListValues: [...prev.selectedCodeGroupListValues, formattedValue]
                  }
                })
              }
              setErrors((prev: any) => ({
                ...prev,
                selectedCodeGroupListValuesError: ''
              }))
            }}
            data={personGender}
            optionProps={{
              key: 'code',
              label: 'value',
              value: 'code',
            }}
            extraStyle={{ flex: 1 }}
          />
        )
      default: break;
    }
  }

  const getDataForTable = () => {
    switch (addCodeGroupState.selectedCodeGroupCategory.code) {
      case CODE_GROUP_CATEGORY.PROBLEM:
      case CODE_GROUP_CATEGORY.ALLERGY:
      case CODE_GROUP_CATEGORY.MEDICATION:
      case CODE_GROUP_CATEGORY.IMMUNIZATION:
      case CODE_GROUP_CATEGORY.PROCEDURE:
      case CODE_GROUP_CATEGORY.DIAGNOSIS:
        return addCodeGroupState.selectedCodeGroupListValues.map(item => ({
          "display": item.display,
          "code": item.code,
          "codeToDisplay": item?.additionalInfo?.system ? `${getCodeSystem(item?.additionalInfo?.system)?.concat('\u00A0\u00A0')} ${item.code}` : item.code,
        }));
      case CODE_GROUP_CATEGORY.ZIP_CODE:
      case CODE_GROUP_CATEGORY.VITAL:
      case CODE_GROUP_CATEGORY.EMPLOYER:
      case CODE_GROUP_CATEGORY.SEX_AT_BIRTH:
      case CODE_GROUP_CATEGORY.GENDER_IDENTITY:
        return addCodeGroupState.selectedCodeGroupListValues
      default: break;
    }
  }

  function setStateAfterDelete(codeToDelete: string) {
    setAddCodeGroupState((prev) => {
      const updatedCodeGroupListValues = prev.selectedCodeGroupListValues.filter((item: ISelectedCodeGroupValue) => item.code !== codeToDelete)
      return {
        ...prev,
        selectedCodeGroupListValues: updatedCodeGroupListValues
      }
    })
  }

  const handleDelete = (record: any) => {
    switch (addCodeGroupState.selectedCodeGroupCategory.code) {
      case CODE_GROUP_CATEGORY.PROBLEM:
      case CODE_GROUP_CATEGORY.ALLERGY:
      case CODE_GROUP_CATEGORY.MEDICATION:
      case CODE_GROUP_CATEGORY.IMMUNIZATION:
      case CODE_GROUP_CATEGORY.ZIP_CODE:
      case CODE_GROUP_CATEGORY.VITAL:
      case CODE_GROUP_CATEGORY.EMPLOYER:
      case CODE_GROUP_CATEGORY.SEX_AT_BIRTH:
      case CODE_GROUP_CATEGORY.GENDER_IDENTITY:
      case CODE_GROUP_CATEGORY.PROCEDURE:
      case CODE_GROUP_CATEGORY.DIAGNOSIS:
        setStateAfterDelete(record.code)
        break;
      default: break;
    }
  };

  const isHideCodecolumnInTable = () => {
    switch (addCodeGroupState.selectedCodeGroupCategory.code) {
      case CODE_GROUP_CATEGORY.ZIP_CODE:
      case CODE_GROUP_CATEGORY.VITAL:
      case CODE_GROUP_CATEGORY.EMPLOYER:
      case CODE_GROUP_CATEGORY.SEX_AT_BIRTH:
      case CODE_GROUP_CATEGORY.GENDER_IDENTITY:
        return true
      default: return false
    }
  }

  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: getAccountUUID(),
      query: '%%',
    },
  });

  return (
    <Drawer
      width={finalWidth}
      visible={isModalOpen}
      onClose={() => {
        setAddCodeGroupState((prev) => ({
          ...prev,
          newCodeGroupName: '',
          newCodeGroupDescription: '',
          selectedCodeGroupCategory: {},
          selectedCodeGroupListValues: []
        }));
        setErrors({});
        onModalClose()
      }}
      title={
        <>
          <ModalActionTitle
            title={props?.isEdit ? 'Update Code Group' : props?.onlyViewList ? `${props?.codeGroupDataForEditOrView?.name}` : 'Add Code Group'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: props?.onlyViewList ? 'close' : 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setAddCodeGroupState((prev) => ({
                    ...prev,
                    newCodeGroupName: '',
                    newCodeGroupDescription: '',
                    selectedCodeGroupCategory: {},
                    selectedCodeGroupListValues: []
                  }));
                  setErrors({});
                  onModalClose();
                },
              },
              {
                show: !props?.onlyViewList,
                id: 2,
                btnText: props.isEdit ? 'update' : 'save',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                leftIcon: addCodeGroupState?.loading && <Spinner />,
                onClick: () => {
                  handleAddOrUpdateCodeGroup();
                },
              },
            ]}
          />
        </>
      }
    >
      <View mx={0}>
        {!props?.onlyViewList && (
          <>
            <FormControl isRequired={true}>
              <Label>
                <DisplayText size={'mdNormal'} textLocalId={'codeGroupName'} />
              </Label>
              <Input
                _focus={{
                  borderColor: Colors.Custom.Gray200
                }}
                size={'mdNormal'}
                value={addCodeGroupState.newCodeGroupName}
                onChangeText={(text: string) => {
                  setAddCodeGroupState((prev) => ({
                    ...prev,
                    newCodeGroupName: text,
                  }))
                  setErrors((prev: any) => ({
                    ...prev,
                    newCodeGroupNameError: ''
                  }))
                }
                }
              />
              {errors?.newCodeGroupNameError && (
                <Text size={'smLight'} color="error.500" marginTop={2} fontSize={12} fontWeight={500}>
                  {errors?.newCodeGroupNameError}
                </Text>
              )
              }
            </FormControl>
            <FormControl isRequired={false} marginTop={3}>
              <HStack alignItems={'center'}>
                <Label>
                  <DisplayText size={'mdNormal'} textLocalId={'codeGroupDescription'} />
                </Label>
              </HStack>
              <TextArea
              onChangeText={(text: any) => {
                setAddCodeGroupState((prev) => ({
                  ...prev,
                  newCodeGroupDescription: text,
                }))
              }
              }>
                {addCodeGroupState.newCodeGroupDescription}
              </TextArea>
            </FormControl>
            <FormControl>
              <ModalActionAntSelect
                allowClear={false}
                leftMargin={'0'}
                showSearch={true}
                filterOption={true}
                value={addCodeGroupState.selectedCodeGroupCategory.display}
                isRequired={true}
                label={'Category'}
                errors={errors.selectedCodeGroupCategoryError ? true : false}
                errorText={errors.selectedCodeGroupCategoryError}
                marginTop={3}
                onChange={(value: any, data: any) => {
                  setAddCodeGroupState((prev) => ({
                    ...prev,
                    selectedCodeGroupCategory: { code: data.value, display: data.children },
                    selectedCodeGroupListValues: []
                  }))
                  if (data.value === CODE_GROUP_CATEGORY.EMPLOYER) {
                    getEmployers().then((response) => {
                      if (response?.data?.employers) { setFetchedData({ category: CODE_GROUP_CATEGORY.EMPLOYER, data: response?.data?.employers }) }
                    })
                      .catch((err) => { })
                  }
                  setErrors((prev: any) => ({
                    ...prev,
                    selectedCodeGroupCategoryError: '',
                    selectedCodeGroupListValuesError: ''
                  }))
                }}
                data={addCodeGroupState.categories.filter((category) => category.code !== CODE_GROUP_CATEGORY.LAB_ORDER)}
                optionProps={{
                  key: 'code',
                  value: 'code',
                  label: 'display',
                }}
                extraStyle={{ flex: 1, fontSize: '16px', fontColor: Colors.Custom.Gray900 }}
                customStyle={{
                  labelSize: 'mdNormal',
                  labelBottomMargin: 0
                }}
              />
            </FormControl>
            {addCodeGroupState.selectedCodeGroupCategory.code && (
              <View marginY={3}>
                <FormControl>
                  {renderListSearch()}
                  {errors?.selectedCodeGroupListValuesError && (
                    <Text size={'smLight'} color="error.500" marginTop={2}>
                      {errors?.selectedCodeGroupListValuesError}
                    </Text>
                  )
                  }
                </FormControl>
              </View>
            )
            }
          </>
        )}
        {
          addCodeGroupState.selectedCodeGroupListValues.length > 0 && (
            <Table
              columns={addCodeGroupTableColumns(handleDelete, isHideCodecolumnInTable() ? true : false, props?.onlyViewList ? true : false)}
              dataSource={getDataForTable()}
              bordered
              pagination={false}
            />
          )
        }

      </View>
    </Drawer>
  );
};

export default AddCodeGroupModal;
