import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Button, HStack, IconButton, Tooltip, View, VStack, Modal} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {BUTTON_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import CreateSmsConversationDrawer from '../../Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {CHANNEL_TYPE} from '../../Conversations/ConversationConst';
import {deleteEmailForwardRoute} from '../IntegrationCreate/EmailInboxCreate/EmailService';
import {deleteInboxSMSUrl} from '../IntegrationCreate/SmsInboxCreate/SmsService';
// import {deleteInboxSMSUrl} from '../IntegrationCreate/SmsInboxCreate/SmsService';
import {IIntegrationsTableActions} from '../interfaces';
import {EmailDrawerCommonV2} from '../../../../common/EmailDrawerCommonV2';
import InstantChatView from '../../../../common/ChatDrawer/InstantChatView';
import {canCreateSMSInboxWithPhoneNumber, getUserId} from '../../../../../utils/commonUtils';
import { EMAIL_PLATFORMS } from '../IntegrationCreate/EmailInboxCreate/EmailInboxConst';
import {INBOX_TYPE} from '../constants';

const {Content, Footer, Header, CloseButton, Body} = Modal;

export const IntegrationsTableActions = (props: IIntegrationsTableActions) => {
  const [showModal, setShowModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState({
    visible: false,
    selectedConversation: {},
  })
  const [deleteInbox] = useMutation(InboxQueries.DeleteInbox);
  const [updateChannelEmail] = useMutation(InboxQueries.UpdateChannelEmail);
  const [updateChannelTwilioSms] = useMutation(
    InboxQueries.updateChannelTwilioSms
  );
  const intl = useIntl();
  const userId = getUserId();
  const onCloseModal = () => {
    setShowModal(false);
  };

  const onDeleteAction = () => {
    setShowModal(false);
    deleteInbox({
      variables: {
        id: props?.record?.singleInboxData?.id,
      },
    }).then((res) => {
      //update channel email
      if (
        props?.record?.singleInboxData?.channelType ===
        CHANNEL_TYPE.CHANNEL_EMAIL
      ) {
        updateChannelEmail({
          variables: {
            id: props?.record?.singleInboxData?.channelId,
            imapEmail: props?.record?.singleInboxData?.channelEmail?.imapEmail + `_deleted_${Date.now()}`,
            email: props?.record?.singleInboxData?.channelEmail?.email + `_deleted_${Date.now()}`,
            imapPassword: props?.record?.singleInboxData?.channelEmail?.imapPassword + `_deleted_${Date.now()}`,
            smtpEmail: props?.record?.singleInboxData?.channelEmail?.smtpEmail + `_deleted_${Date.now()}`,
            smtpPassword: props?.record?.singleInboxData?.channelEmail?.smtpPassword + `_deleted_${Date.now()}`,
          },
        })
      }
      if (
        props?.record?.singleInboxData?.channelType ===
        CHANNEL_TYPE.CHANNEL_TWILIO_SMS
      ) {
        updateChannelTwilioSms({
          variables: {
            id: props?.record?.singleInboxData?.channelId,
          },
        }).then((res) => {
          deleteInboxSMSUrl({
            bodyParams: {
              phoneNumber:
                props?.record?.singleInboxData.channelTwilioSms.phoneNumber,
              smsUrl: '',
            },
          }).then(() => {
            props.onActionPerformed(COMMON_ACTION_CODES.DELETE, props.record);
          });
        });
      } else if (
        props?.record?.singleInboxData?.channelType ===
        CHANNEL_TYPE.CHANNEL_EMAIL && props?.record?.singleInboxData?.channelEmail?.forwardRouteId
      ) {
        deleteEmailForwardRoute(props?.record?.singleInboxData?.channelEmail?.forwardRouteId).then(() => {
          props.onActionPerformed(COMMON_ACTION_CODES.DELETE, props.record);
        });
      } else {
        props.onActionPerformed(COMMON_ACTION_CODES.DELETE, props.record);
      }
    });
  };
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;

  const isSendButtonDisabled = () => {
    const inboxMemberIds =
      props?.record?.singleInboxData?.inboxMembers?.map((item) => {
        return item?.userId;
      }) || [];
    if (!userId || !inboxMemberIds?.includes(userId)) {
      return true;
    }
    if (props?.record?.inbox_type === 'Website') {
      return true;
    }
    if (
      props?.record?.singleInboxData?.channelType !==
      CHANNEL_TYPE.CHANNEL_TWILIO_SMS
    ) {
      return false;
    }
    const isSendSMSAllowed = canCreateSMSInboxWithPhoneNumber(
      props?.record?.singleInboxData?.channelTwilioSms?.phoneNumber || ''
    );
    return !isSendSMSAllowed;
  };

  const getSendButtonTooltipLabel = () => {
    if (
      props?.record?.singleInboxData?.channelType !==
      CHANNEL_TYPE.CHANNEL_TWILIO_SMS
    ) {
      return 'Test Send';
    }
    const isSendSMSAllowed = canCreateSMSInboxWithPhoneNumber(
      props?.record?.singleInboxData?.channelTwilioSms?.phoneNumber || ''
    );
    if (isSendSMSAllowed) {
      return 'Test Send';
    }
    return 'Please note that SMS functionality is not available for toll-free numbers';
  };

  const isDeleteButtonDisable = () => {
    return props?.record?.singleInboxData?.inboxType === EMAIL_PLATFORMS.MAILGUN;
  };
  return (
    <HStack>
      <Tooltip
        label={getSendButtonTooltipLabel()}
        openDelay={200}
        placement="top"
      >
        <IconButton
          icon={
            <Feather
              name="send"
              size={17}
              color={
                isSendButtonDisabled()
                  ? Colors.Custom.Gray300
                  : Colors.Custom.Gray600
              }
            />
          }
          _hover={{background: 'none'}}
          disabled={isSendButtonDisabled()}
          onPress={() => {
            if (props?.record?.inbox_type === INBOX_TYPE.SMS) {
              setShowSmsModal(true);
            } else if (
              props?.record?.inbox_type === INBOX_TYPE.GMAIL ||
              props?.record?.inbox_type === INBOX_TYPE.OUTLOOK ||
              props?.record?.inbox_type === INBOX_TYPE.DEFAULT
            ) {
              setOpenEmailModal(true);
            } else {
            }
          }}
        />
      </Tooltip>
      <Tooltip label={'Edit'} openDelay={200} placement="top">
        <IconButton
          icon={
            <Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />
          }
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.EDIT, props.record);
          }}
        />
      </Tooltip>

      {!isDeleteButtonDisable() ? (
        <Tooltip label={'Delete'} openDelay={200} placement="top">
          <IconButton
            icon={
              <Icon name="delete" size={17} color={Colors.Custom.Gray600} />
            }
            _hover={{background: 'none'}}
            onPress={() => {
              setShowModal(true);
            }}
          />
        </Tooltip>
      ) : (
        <></>
      )}

      <Modal
        isOpen={showModal}
        onClose={() => {
          onCloseModal();
        }}
      >
        <Content>
          <CloseButton />
          <Header>
            <DisplayText textLocalId="deleteInbox" textType="Heading" />
          </Header>
          <Body>
            <DisplayText
              size="mdMedium"
              textLocalId={'deleteInboxDescription'}
            />
          </Body>
          <Footer
            style={{
              backgroundColor: Colors.Custom.BackgroundColor,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button.Group>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onCloseModal();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'cancel'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onDeleteAction();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'delete'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          </Footer>
        </Content>
      </Modal>

      {/* <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => {
          onCloseModal();
        }}
      >
        <ModalActionTitle title={'deleteInbox'} />
        <View style={{marginTop: 60}} mx={6}>
          <VStack space={3}>
            <DisplayText textLocalId={'deleteInboxDescription'} />
          </VStack>
          <Button.Group
            mx={{
              base: 'auto',
              md: 0,
            }}
            style={{marginTop: 22}}
            justifyContent={'flex-end'}
            width={'100%'}
          >
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onCloseModal();
                },
                style: {marginRight: 20},
              }}
              customProps={{
                btnText: intl.formatMessage({id: 'cancel'}),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onDeleteAction();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({id: 'delete'}),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Drawer> */}
      {showSmsModal && (
        <CreateSmsConversationDrawer
          isDrawerVisible={showSmsModal}
          selectedInbox={
            props?.record ? props.record?.singleInboxData : ({} as any)
          }
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            setShowSmsModal(false);
            if (actionData) {
              setShowChatModal((prev) => {
                return {
                  ...prev,
                  visible: true,
                  selectedConversation:
                    actionData?.selectedInboxConversationData
                      ?.conversations?.[0],
                };
              });
            }
          }}
        />
      )}

      {/* {openEmailModal && (
        <CreateEmailConversationDrawer
          isDisable
          isDrawerVisible={openEmailModal}
          selectedInbox={
            props?.record ? props.record?.singleInboxData : ({} as any)
          }
          onCreateEmailConversationActionPerformed={() => {
            setOpenEmailModal(false);
          }}
        />
      )} */}
      {openEmailModal && (
        <EmailDrawerCommonV2
          isOpen={openEmailModal}
          onClose={() => {
            setOpenEmailModal(false);
          }}
          onEmailSent={() => {
            setOpenEmailModal(false);
          }}
        />
      )}
      {showChatModal.visible && (
        <InstantChatView
          selectedConversation={showChatModal.selectedConversation}
          contactData={{} as any}
          isLoading={false}
          isDrawerVisible={showChatModal.visible}
          isInstantChatView={true}
          instantChatFromNotifications={true}
          onActionPerformed={(actionCode: any) => {
            if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
              setShowChatModal((prev) => {
                return {
                  ...prev,
                  visible: false,
                  selectedConversation: {},
                };
              });
            }
          }}
          onEmailSent={() => {
            setOpenEmailModal(false);
          }}
          inboxId={props.record?.singleInboxData?.id}
        />
      )}
    </HStack>
  );
};
