import {AxiosInstance} from 'axios';
import {UserQueries} from '../../../../services';
import {isValidJsonString} from '../../../../utils/commonUtils';
import {InvitedUser, IUser} from './interfaces';
import {UserData} from './InviteUserDrawer/interfaces';
import {getPostRequestBody} from './InviteUserDrawer/InviteUserDrawerUtils';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';

export const getSortedTeamMembers = (args: {
  currentUsers: IUser[];
  invitedUsers: IUser[];
}) => {
  const {currentUsers, invitedUsers} = args;
  const completeData = [...currentUsers, ...invitedUsers];
  if (completeData?.length == 0) {
    return completeData;
  }
  const sortedByName = completeData.sort((a, b) => {
    const check = (a.name || '')?.localeCompare(b.name || '');
    return check;
  });
  return sortedByName;
};

export const getFormattedInvitedUserData = (data: InvitedUser[]): IUser[] => {
  const result: IUser[] = [];
  if (data.length === 0) {
    return result;
  }
  data.forEach((item) => {
    const userData = getUserDataFromInvitedUser(item);
    result.push(userData);
  });
  return result;
};

export const getUserDataFromInvitedUser = (invitedUser: InvitedUser): IUser => {
  const {accountUuid, id, name, roleCode, userName, uuid} = invitedUser;
  const role = getRoleCodeFromRoleObject(roleCode);
  let employerId = '';
  if (role === USER_ROLE_CODES.EMPLOYER) {
    employerId = getEmployerIdFromRoleObject(roleCode);
  }
  return {
    id: id,
    uuid: uuid,
    isInvited: true,
    roleCodeObjectString: roleCode,
    accountUsers: [
      {
        uuid: '',
        isActive: true,
        account: {
          uuid: accountUuid,
        },
        employerId: employerId,
      },
    ],
    avatar_url: '',
    description: '',
    email: userName,
    externalUserId: '',
    name,
    subtitle: '',
    type: '',
    uid: userName,
    userPracticeLocations: [],
    userRoles: [
      {
        id: '',
        userRoleId: '',
        userRole: {
          id: '',
          userRole: {
            code: role,
            id: '',
            value: role,
          },
        },
      },
    ],
  };
};

export const getRoleCodeFromRoleObject = (str: string) => {
  const isValidJson = isValidJsonString(str);
  if (isValidJson) {
    const json = JSON.parse(str);
    return json?.role || '';
  }
  return str;
};

export const getExternalUserIdFromRoleObject = (roleObjectString: string) => {
  const isValidJson = isValidJsonString(roleObjectString);
  if (isValidJson) {
    const json = JSON.parse(roleObjectString);
    return json?.externalUserId || '';
  }
  return '';
};

export const getEmployerIdFromRoleObject = (roleObjectString: string) => {
  const isValidJson = isValidJsonString(roleObjectString);
  if (isValidJson) {
    const json = JSON.parse(roleObjectString);
    return json?.employerId || '';
  }
  return '';
};

export const getPostRequestBodyForInviteUser = (user: IUser): UserData => {
  const {roleCodeObjectString, name, email} = user;
  const employerId = getEmployerIdFromRoleObject(roleCodeObjectString || '');
  const externalUserId = getExternalUserIdFromRoleObject(
    roleCodeObjectString || ''
  );
  const role = getRoleCodeFromRoleObject(roleCodeObjectString || '');
  const {firstName, lastName} = getFirstNameAndLastName(name || '');
  const postRequestBody = getPostRequestBody([
    {
    email: email || '',
    firstName,
    lastName,
    role: role,
    employerId,
    externalUserId,
    ...(employerId && {templateCategory: 'EMPLOYER_INVITATION'}),
  }
]);
  return postRequestBody;
};

export const getFirstNameAndLastName = (
  fullName: string
): {
  firstName: string;
  lastName: string;
} => {
  if (!fullName) {
    return {
      firstName: '',
      lastName: '',
    };
  }
  const split = fullName.split(' ');
  if (split.length == 1) {
    return {
      firstName: fullName,
      lastName: '',
    };
  }
  if (split.length == 2) {
    return {
      firstName: split[0],
      lastName: split[1],
    };
  }
  if (split.length > 2) {
    return {
      firstName: split[0],
      lastName: fullName.replace(split[0], ''),
    };
  }
  return {
    firstName: '',
    lastName: '',
  };
};

export const getInvitedUsers = (args: {
  baseService: AxiosInstance;
  searchString:string,
  limit: number,
  offSet: number 
}) => {
  const {baseService,searchString, limit, offSet} = args;
  const url = `/crm-nest/platform/invited_users?searchString=${searchString}&limit=${limit}&offset=${offSet}`;
  return baseService.get(url);
};

export const cancelInvite = (args: {
  baseService: AxiosInstance;
  accountUuid: string;
  email: string;
}) => {
  const {accountUuid, baseService, email} = args;
  const url = `crm-nest/platform/admin`;
  const data = {
    query: UserQueries.DELETE_USER_RESET_PASSWORD,
    variables: {
      userName: email,
      updatedAt: new Date(),
    },
  };
  return baseService.post(url, data);
};

export const getFinalUserList = (args: {
  currentUsers: IUser[];
  invitedUsers: IUser[];
}) => {
  const {currentUsers} = args;
  let {invitedUsers} = args;
  const emails: string[] = [];
  currentUsers.forEach((item) => {
    if (item.email) {
      emails.push(item.email);
    }
  });
  emails.forEach((item) => {
    invitedUsers = invitedUsers.filter((u) => u.email != item);
  });
  return getSortedTeamMembers({currentUsers, invitedUsers});
};
