import { useLazyQuery } from '@apollo/client';
import { Spin, notification } from 'antd';
import { Badge,HStack,Pressable,ScrollView,Text,View } from 'native-base';
import { useCallback, useContext,useEffect,useRef,useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { useLocation,useNavigate } from 'react-router';
import { ILoginUserData } from '../../../../../Interfaces';
import { MLOV_CATEGORY, PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE,WINDOW_EVENT_CODES,getInActiveContactError } from '../../../../../constants';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import { CUSTOM_COMMON_EVENT_CODES, SUPPORTED_EVENT_CODE } from '../../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { FlowType } from '../../../../../context/WorkflowContext';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import { IInbox } from '../../../../../services/Inbox/interfaces';
import { Colors } from '../../../../../styles/Colors';
import { EventBus } from '../../../../../utils/EventBus';
import LocalStorage from '../../../../../utils/LocalStorage';
import { getAccountId, getSecondaryValue,getUserId,getUserUUID,isActiveContact,isEmployerRole } from '../../../../../utils/commonUtils';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { AutomationSettingBtn } from '../../../../common/AutomationSettingBtn';
import AppointmentBooking from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { ParticipantType } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import CreateChannelView from '../../../../common/CreateChannelView/CreateChannelView';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import MeetingView from '../../../../common/MeetingView/MeetingView';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { AddOrUpdateLead } from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { getFormDataFromLeadData } from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { isMessageSettingAllowed } from '../../../UserAccess/UserAccessPermission';
import { formatInboxDataForSelectOptions } from '../ConversationChannelTabs/ChannelTabsUtils';
import { styles } from '../ConversationChannelTabs/ConversationChannelTabsStyle';
import { getAllUserInboxes } from '../ConversationChannelTabs/ConversationChannelTabsUtils';
import CreateSmsConversationDrawer from '../ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import { IConversationChannelTabState,IInboxChannelType,IUserInboxList } from '../ConversationChannelTabs/interfaces';
import { CHANNEL_TYPE_CODE,CONVERSATION_TAB_CODES,USER_CONVERSATION_FILTER_CODES,USER_CONVERSATION_FILTER_CODES_LIST } from '../ConversationConst';
import ConversationFilter from '../ConversationContainer/ConversationFilters/ConversationFilter';
import Conversations from '../Conversations';
import { createUserConversationFilter,getUserConversationFilter,resetUserConversationFilter,updateUserConversationFilter } from './ConversationAPIService';
import { CONVERSATION_TYPES,DISPLAY_CONVERSATION_SIDEBAR_ICON,DISPLAY_CONVERSATION_SIDEBAR_LIST,INITIAL_UNREAD_MAP_V2,SIDECAR_CONVERSATION_TAB_BAR_LIST,getAllConversationUnreadCountForAllTabsV2,getCodeAndUserConversationFilterData,getCountString,getSearchParams,isUpdateAllCount } from './ConversationSidebarUtils';
import { ConversationSidebarV2Event } from './ConversationSidebarV2Event';
import { IConversationChannelList, IUserPreferenceContactType } from './Interfaces';
import CreateEFaxConversationDrawer from '../ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawer';
import UserQueries from '../../../../../services/User/UserQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { getMlovIdCodeObj, getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { USER_PREFERENCE_CODE } from '../../../../../constants/MlovConst';
import ScrollableAppTabsView from '../../../../common/AppTabsView/ScrollableAppTabsView';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import { SidecarTitle } from '../../../../SideCar/SidecarTitle';
import {EMAIL_INBOX_USER_CODE} from '../../../../common/PreferencesSetting/PreserenceConst';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from '../../../Contacts/CustomField/CustomFieldConst';
import {ICustomToast} from '../../../Contacts/CustomField/interface';
import {useToast} from '../../../../Toast/ToastProvider';
import {INBOX_TYPE_TAB_CODE} from '../../../../../constants/ConstantValues';
import SideCarConversationTabBar, { ISideCarConversationTabList } from '../../../../common/SideCar/SideCarConversationTabBar';
// import useMemoryMonitor from '../../../../CustomHooks/useMemoryMonitor';

const ConversationSidebarV2 = (props: {
  navigateOrOpenContactIdDrawer?: (actionId: any, actionData: any) => void;
}) => {
  // useMemoryMonitor();
  const [getSingleMessageDataById] = useLazyQuery(ConversationsQueries.GetSingleMessageDataById, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  })
  const {height} = Dimensions.get('window');
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const userPreferenceSettings = commonData?.userPreferenceSetting;
  let emailInboxUserPreference = '';
  if (userPreferenceSettings?.userPreferences?.length > 0 && userPreferenceSettings?.userPreferences?.[0]?.preferencesJson) {
    const preferenceData = JSON.parse(userPreferenceSettings?.userPreferences?.[0]?.preferencesJson);
    emailInboxUserPreference = preferenceData?.userPreference?.emailInboxUserView;
  }
  const userUUID = getUserUUID();
  const userId = getUserId();
  const accountId = getAccountId();
  const basePath = '/inbox';
  const navigate = useNavigate();
  const intl = useIntl();
  const location = useLocation();
  const toast = useToast();
  const pathList = location?.pathname?.split('/') || [];
  const lastPathStr = pathList[pathList?.length - 1];
  const isEmployer = isEmployerRole();
  let conversationSideBarList = DISPLAY_CONVERSATION_SIDEBAR_LIST;
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const userUuid = getUserUUID();
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeIdCodeObj = getMlovIdCodeObj(groupTypeList);

  const showPreferencesSetting = isAccountConfigEnabled(CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE);

  if (isEmployer) {
    conversationSideBarList = DISPLAY_CONVERSATION_SIDEBAR_LIST.filter((item) => {
      return item.header == 'Channels'
    })
    const filterMenuList = conversationSideBarList[0].list?.filter((channelObj) => {
      return channelObj.code == 'INTERNAL_CHAT'
    })
    conversationSideBarList[0].list = [...filterMenuList]
  }
  if (!isPatientMessagingEnabled) {
    const filterOutChatSTab = conversationSideBarList[1].list?.filter((channelObj) => {
      return channelObj.code !== CONVERSATION_TYPES.GROUP_CHAT
    })
    conversationSideBarList[1].list = [...filterOutChatSTab]
  }
  if (!isEFaxMessagingEnabled) {
    const filterOutChatSTab = conversationSideBarList[1].list?.filter((channelObj) => {
      return channelObj.code !== CONVERSATION_TYPES.FAX_CHAT
    })
    conversationSideBarList[1].list = [...filterOutChatSTab]
  }

  let selectedPathItem = conversationSideBarList[0].list[0];
  conversationSideBarList.map((menuItem) => {
    menuItem?.list.find((item) => {
      if (item.path === lastPathStr) {
        selectedPathItem = item;
      }
    })
  })

  // this needs to invoke only on flag based if side car flag is enable
  const sideCarInboxTypes = SIDECAR_CONVERSATION_TAB_BAR_LIST;
  const internalChatTabIndex = sideCarInboxTypes.findIndex((conversationTab) => {
    return conversationTab.key === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
  })
  if (showOnlyInternalChatInSidecar) {
    const [internalChatTab] = sideCarInboxTypes.splice(internalChatTabIndex, 1);
    if (internalChatTab) {
      sideCarInboxTypes.splice(0, sideCarInboxTypes.length, internalChatTab);
    }
  }

  const [sideCarTabList, setSideCarTabList] =
    useState<ISideCarConversationTabList[]>(sideCarInboxTypes);
  const [isConversationSelected, setIsConversationSelected] = useState(false);

  const [conversationChannelTabState, setConversationChannelTabState] =
    useState<IConversationChannelTabState>({
      isLoading: false,
      inboxList: [] as IInbox[],
      selectedInboxList: [] as any,
      userInboxList: {} as IUserInboxList,
      displayCreateChatPopup: false,
      openCount: 0,
      isCreateChannelViewDisplay: false,
      selectedTabCode: isSidecarContext ? sideCarInboxTypes[0].key : selectedPathItem.key,
      selectedInbox: {} as IInbox,
      visibleDrawerCode: '',
      filterModal: false as boolean,
      conversationFilterProps: {} as any,
      conversationFilterQuery: {} as any,
      selectedData: {} as any,
      formDataFromLeadData: {} as any,
      userFilterData: {} as any,
      emailInboxUserPreference: emailInboxUserPreference,
      userPreferenceChangeLoading: false,
      sideCarSearchString: '',
    });

  const [unreadCountMap, setUnreadCountMap] = useState(INITIAL_UNREAD_MAP_V2);
  const [allUnreadCount, setAllUnreadCount] = useState(0);
  const currentTabRef = useRef(conversationChannelTabState.selectedTabCode)


  const [getInboxes] = useLazyQuery(InboxQueries.GetInboxes, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
    variables: {
      searchString: `%%`,
      accountId: userData.account_id,
    },
  });


  const getInboxesData = async () => {
    const inboxResp = await getInboxes().catch((error) => {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          inboxList: [],
        };
      });
    });
    if (inboxResp?.data?.inboxes?.length) {
      const userInboxList: any = getAllUserInboxes(
        inboxResp?.data?.inboxes,
        userData
      );

      const selectedChannelType = conversationChannelTabState.selectedTabCode;
      const curSelChannelTypeInboxList: IInbox[] =
        userInboxList[selectedChannelType] || [];
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          inboxList: inboxResp?.data?.inboxes,
          userInboxList: userInboxList,
          selectedInbox: curSelChannelTypeInboxList[0] || {},
        };
      });
    }
  };

  const [getUserPreference] = useLazyQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUUID,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted:(data) => {
      if(data?.userPreferences?.length > 0 && data?.userPreferences?.[0]?.preferencesJson){
       const preferenceData = data?.userPreferences?.[0];
       LocalStorage.setItem(
        `messaging_user_perference_${userUUID}_${accountId}`,
        JSON.stringify(preferenceData)
      );
      }
    }
  });

  useEffect(() => {
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    getUserPreference();
    getInboxesData();
    getConversationCountData();
    getUserConversationFilterDataFromAPI();
    if (selectedChannelType !== CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      const filterByChannelType = 'conversationLastFilterSelected';
      LocalStorage.getItem(filterByChannelType)
        .then((response) => {
          if (response) {
            const responseData = JSON.parse(response);
            if (responseData?.[userUUID]) {
              setConversationChannelTabState((prev) => {
                return {
                  ...prev,
                  conversationFilterProps: responseData?.[userUUID]?.conversationFilterProps,
                  conversationFilterQuery: responseData?.[userUUID]?.conversationFilterQuery,
                };
              });
            }
          }
        });
    }
  }, [conversationChannelTabState?.emailInboxUserPreference]);

useEffect(() => {
  return () => {
    setConversationChannelTabState(() => {
      return {
        isLoading: false,
        inboxList: [] as IInbox[],
        selectedInboxList: [] as any,
        userInboxList: {} as IUserInboxList,
        displayCreateChatPopup: false,
        openCount: 0,
        isCreateChannelViewDisplay: false,
        selectedTabCode: isSidecarContext
          ? sideCarInboxTypes[0].key
          : selectedPathItem.key,
        selectedInbox: {} as IInbox,
        visibleDrawerCode: '',
        filterModal: false as boolean,
        conversationFilterProps: {} as any,
        conversationFilterQuery: {} as any,
        selectedData: {} as any,
        formDataFromLeadData: {} as any,
        userFilterData: {} as any,
      };
    });
  }
}, [])

  useEffect(() => {
    const conversationUuidMasterList: Array<string> = [];
    Object.keys(unreadCountMap).forEach((singleKey: any) => {
      if (isUpdateAllCount({key: singleKey})) {
        const conversationIdList =
          unreadCountMap?.[singleKey]?.conversationIdList || [];
        conversationIdList.forEach((conversationData:any) => {
          if(conversationData?.uuid && conversationUuidMasterList.indexOf(conversationData?.uuid) == -1){
            conversationUuidMasterList.push(conversationData?.uuid)
          }
        });
      }
    });
    setAllUnreadCount(conversationUuidMasterList?.length);
  }, [unreadCountMap]);

  const getConversationCountData = async () => {
    const userPreferenceContactType = {} as IUserPreferenceContactType;
    const emailInboxUserPreference = conversationChannelTabState?.emailInboxUserPreference;
    if ((emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER || emailInboxUserPreference === EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD) && showPreferencesSetting) {
      userPreferenceContactType.contactType = PERSON_TYPES.OTHER;
      userPreferenceContactType.include = emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER ? true :  false;
    }
    try {
      const response = await getAllConversationUnreadCountForAllTabsV2(userPreferenceContactType);
      setUnreadCountMap((prev) => {
        return {
          ...prev,
          ...response,
        };
      });
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          userPreferenceChangeLoading: false,
        }
      })
    } catch (err) {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          userPreferenceChangeLoading: false,
        }
      })
    }
  }

  const getUserConversationFilterDataFromAPI = async () => {
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    const filterResp = await getUserConversationFilter({
      filterCodes: USER_CONVERSATION_FILTER_CODES_LIST
    });
    if (filterResp?.data?.filterList?.length) {
      const filterCodeValueDataObj = getCodeAndUserConversationFilterData(filterResp?.data?.filterList);
      const assignedOtherFilterData = filterCodeValueDataObj[USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER];
      if (assignedOtherFilterData?.id && !assignedOtherFilterData?.isDefault && Object.keys(assignedOtherFilterData?.filterValue)?.length) {
        if (selectedChannelType == CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
          const additionalData = assignedOtherFilterData?.additionalData;
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              conversationFilterProps: {
                ...additionalData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...additionalData.conversationFilterQuery
              },
              userFilterData: {
                ...assignedOtherFilterData
              }
            };
          });
        } else {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              userFilterData: {
                ...assignedOtherFilterData
              }
            };
          });
        }

      } else {
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            conversationFilterProps: {},
            conversationFilterQuery: {},
            userFilterData: {
              ...assignedOtherFilterData
            }
          };
        });
      }
    }
  };

  const navigateToTabPath = (tabItem: IConversationChannelList) => {
    let finalPath = tabItem?.path || '';
    if (basePath) {
      finalPath = basePath + '/' + (tabItem?.path || '');
    }
    navigate(finalPath);
  };

  const showCreateGroupPopup = (e: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        displayCreateChatPopup: e,
        openCount: prev.openCount + 1,
      };
    });
  };

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const onCreateGroupPopupActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    const contactActiveStatus = isActiveContact(actionData);
    const phoneNumber = actionData?.phoneNumber ||
      getSecondaryValue(actionData?.personContact, 'phone') ||
      ''
    const email = actionData?.email ||
      getSecondaryValue(actionData?.personContact, 'email') ||
      ''
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_POPUP: {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NEW_GROUP_CREATED, {data: ''});
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
          };
        });

        break;
      }
      case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            isCreateChannelViewDisplay: true,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
      case COMMON_ACTION_CODES.EDIT:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
            selectedData: actionData
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_SMS:
        if (contactActiveStatus) {
          if (phoneNumber) {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
                selectedData: actionData,
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                selectedData: actionData,
              };
            });
          }
        } else {
          const message = getInActiveContactError(actionData);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (contactActiveStatus) {
          if (email) {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
                selectedData: actionData
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                selectedData: actionData,
              };
            });
          }
        } else {
          const message = getInActiveContactError(actionData);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
            selectedData: actionData,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        const formattedContactData = getFormDataFromLeadData(
          actionData || {},
          commonData
        );
        if (contactActiveStatus) {
          if (phoneNumber || email) {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW,
                selectedData: formattedContactData
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                selectedData: actionData,
              };
            });
          }
        } else {
          const message = getInActiveContactError(actionData);
          notification.error({
            message,
          });
        }
        break;

      case CONVERSATION_TAB_CODES.CHART:
        if (
          actionData?.contactType?.contactType?.code == 'CUSTOMER' ||
          actionData?.contactType?.contactType?.code == 'PATIENT'
        ) {
          props?.navigateOrOpenContactIdDrawer?.(actionData?.id, {
            state: {actionType: 'CREATE_CHART'},
          });
        }
        break;
      case GROUP_ACTION_CODES.GROUP_HEADER_CLICKED:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
            selectedData: actionData
          };
        });
        break;
      // case CHANNEL_TYPE_CODE.CALL_LOGS:
      //   //
      //   break;
    }
  };

  const showCountLoading = (tabCode?: string) => {
    return conversationChannelTabState?.userPreferenceChangeLoading &&
      (tabCode === INBOX_TYPE_TAB_CODE.ALL_CHAT || tabCode === INBOX_TYPE_TAB_CODE.EMAIL_CHAT);
  }

  const showLoadingOnPreferenceChange = () => {
    const currentTab = currentTabRef?.current;
    return conversationChannelTabState?.userPreferenceChangeLoading &&
      (currentTab === CHANNEL_TYPE_CODE.CHANNEL_EMAIL || currentTab === CHANNEL_TYPE_CODE.CHANNEL_ALL)
  }


  useEffect(() => {
    const conversationSidebarV2Event = new ConversationSidebarV2Event({
      userUUID: userUUID,
      getSingleMessageDataById: getSingleMessageDataById,
      userId: userId,
      getSearchParams: getSearchParams,
      getConversationCountData: getConversationCountData,
      setUnreadCountMap: setUnreadCountMap,
      onCreateGroupPopupActionPerformed: onCreateGroupPopupActionPerformed,
      selectedConversation: undefined,
      groupTypeIdCodeObj: groupTypeIdCodeObj
    });
    return conversationSidebarV2Event.addEventListener()
  }, [])

  const getUserPreferenceData = async () => {
    try {
      const userPreference = await getUserPreference();
      if (userPreference?.data?.userPreferences?.length > 0 && userPreference?.data?.userPreferences?.[0]?.preferencesJson) {
        const preferenceData = JSON.parse(userPreference?.data?.userPreferences?.[0]?.preferencesJson);
        const emailInboxUserPreference = preferenceData?.userPreference?.emailInboxUserView;
        if(userPreference?.data?.userPreferences?.length > 0 && userPreference?.data?.userPreferences?.[0]?.preferencesJson){
          const localPreferenceData = userPreference?.data?.userPreferences?.[0];
          LocalStorage.setItem(
           `messaging_user_perference_${userUUID}_${accountId}`,
           JSON.stringify(localPreferenceData)
          );
        }

        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            emailInboxUserPreference: emailInboxUserPreference,
          }
        })
      }
    } catch (error: any) {
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  const onUserPreferenceDataChange = useCallback((data: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        userPreferenceChangeLoading: true,
        emailInboxUserPreference: data?.selectedEmailInboxUserView,
      };
    });
  }, [conversationChannelTabState.selectedTabCode]);

  const setUserPreferenceChangeLoading = () => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        userPreferenceChangeLoading: false,
      }
    })
  }

  useEffect(() => {
    if (!emailInboxUserPreference?.length) {
      getUserPreferenceData();
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_COMMON_EVENT_CODES.USER_PREFERENCE_UPDATED,
      onUserPreferenceDataChange
    );

    return () => {
      eventBus.removeEventListener(onUserPreferenceDataChange);
    };
  },[])

  useEffect(() => {
    currentTabRef.current = conversationChannelTabState?.selectedTabCode;
  }, [conversationChannelTabState?.selectedTabCode]);

  const onCreateChannelActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NEW_GROUP_CREATED, {
          conversations: actionData,
        });
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
    }
  };

  const onCreateSmsConversationActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        if (actionData?.selectedInboxConversationData?.conversations?.[0]?.id) {
        }
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.EMAIL_SENT:
        {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.RESET:
        {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
    }
    if (actionData?.inboxData?.id) {
      onInboxChange(actionData?.inboxData?.id);
    }
  };

  const onInboxChange = (inboxOptionData: any) => {
    const userInboxList: any = conversationChannelTabState.userInboxList;
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    const curSelChannelTypeInboxList: IInbox[] =
      userInboxList[selectedChannelType];
    const curSelectedInbox =
      curSelChannelTypeInboxList.find((inboxData) => {
        return inboxData.id === inboxOptionData.value;
      }) || ({} as any);
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        selectedInbox: curSelectedInbox,
      };
    });
  };

  const onActionPerformed = (action: any) => {
    switch (action) {
      case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: true,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
          };
        });

        break;
      case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: true,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
          };
        });
        break;
      case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: true,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
          };
        });

        break;
      default:
        break;
    }
  };

  const filterModal = (isFilterDrawerOpen: boolean) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        filterModal: isFilterDrawerOpen,
      };
    });
  }

  const isCreateConversationDrawerVisible = (channelCode: string) => {
    return channelCode === conversationChannelTabState.visibleDrawerCode;
  };

  const getCreateChannelTabCode = () => {
    if (
      conversationChannelTabState.selectedTabCode ===
      CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
    ) {
      return CONVERSATION_TAB_CODES.INTERNAL;
    } else {
      return CONVERSATION_TAB_CODES.PRIVATE;
    }
  };

  const conversationActionDrawer = (): JSX.Element => {
    const userInboxList: any = conversationChannelTabState.userInboxList;
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    const curSelChannelTypeInboxList: IInbox[] =
      userInboxList[selectedChannelType] || [];
    const inboxFormattedData = formatInboxDataForSelectOptions(
      curSelChannelTypeInboxList
    );
    return (
      <View style={{justifyContent: 'center'}}>
        <CreateSmsConversationDrawer
          isDrawerVisible={isCreateConversationDrawerVisible(
            CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
          )}
          selectedInbox={{} as any}
          selectedPatient={conversationChannelTabState.selectedData}
          onCreateSmsConversationActionPerformed={
            onCreateSmsConversationActionPerformed
          }
        />
        {isCreateConversationDrawerVisible(CHANNEL_TYPE_CODE.CHANNEL_EMAIL) && (
          <EmailDrawerCommon
            contactData={conversationChannelTabState.selectedData}
            selectedInbox={conversationChannelTabState.selectedInbox}
            onMailCompleteAction={(actionCode: any) => {
              onCreateSmsConversationActionPerformed(actionCode, '');
            }}
          />
        )}

        <CreateChannelView
          isDrawerVisible={isCreateConversationDrawerVisible(
            CONVERSATION_ACTION_CODES.START_GROUP_CHAT
          )}
          onCreateChannelActionPerformed={onCreateChannelActionPerformed}
          selectedTabCode={getCreateChannelTabCode()}
        />
        {conversationChannelTabState.filterModal && (
          <ConversationFilter
            showModal={conversationChannelTabState.filterModal}
            onModalAction={onModalAction}
            selectedTab={conversationChannelTabState.selectedTabCode}
            inboxList={userInboxList}
            selectedData={conversationChannelTabState?.conversationFilterProps}
            userFilterData={conversationChannelTabState?.userFilterData}
            emailInboxUserPreference={
              conversationChannelTabState?.emailInboxUserPreference
            }
            showChooseInbox={isSidecarContext}
          />
        )}
        {conversationChannelTabState.visibleDrawerCode ===
          CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT && (
          <AppointmentBooking
            defaultParticipants={[
              {
                label: conversationChannelTabState.selectedData?.name || '',
                key: conversationChannelTabState.selectedData?.uuid || '',
                value: conversationChannelTabState.selectedData?.uuid || '',
                type: ParticipantType.patient,
              },
            ]}
            isVisible={true}
            onComplete={() => {
              setConversationChannelTabState((prev) => {
                return {
                  ...prev,
                  visibleDrawerCode: '',
                };
              });
            }}
            onCancel={() => {
              setConversationChannelTabState((prev) => {
                return {
                  ...prev,
                  visibleDrawerCode: '',
                };
              });
            }}
          />
        )}
        {isCreateConversationDrawerVisible(
          RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW
        ) && (
          <MeetingView
            personData={conversationChannelTabState.selectedData}
            onPersonActionPerformed={(actionCode: any) => {
              if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                setConversationChannelTabState((prev) => {
                  return {
                    ...prev,
                    visibleDrawerCode: '',
                    selectedData: {},
                  };
                });
              }
            }}
          />
        )}
        {isCreateConversationDrawerVisible(CHANNEL_TYPE_CODE.CHANNEL_E_FAX) && (
          <CreateEFaxConversationDrawer
            visible={isCreateConversationDrawerVisible(
              CHANNEL_TYPE_CODE.CHANNEL_E_FAX
            )}
            onActionPerformed={(actionCode: string, actionData?: any) => {
              onCreateChannelActionPerformed(actionCode);
            }}
          />
        )}
      </View>
    );
  };

  const onTabClick = async (tabItem: IConversationChannelList) => {
    const userInboxList: any = conversationChannelTabState.userInboxList;
    const selectedChannelType = tabItem.key;
    const curSelChannelTypeInboxList: IInbox[] = userInboxList[selectedChannelType];
    if (selectedChannelType === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      const assignedOtherFilterData = conversationChannelTabState?.userFilterData;
      if (assignedOtherFilterData?.id && !assignedOtherFilterData?.isDefault && Object.keys(assignedOtherFilterData?.filterValue)?.length) {
        const additionalData = assignedOtherFilterData?.additionalData;
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            conversationFilterProps: {
              ...additionalData.conversationFilterProps
            },
            conversationFilterQuery: {
              ...additionalData.conversationFilterQuery
            }
          };
        });
      } else {
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            conversationFilterProps: {},
            conversationFilterQuery: {},
          };
        });
      }
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: {},
          conversationFilterQuery: {},
        };
      });
    }
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        selectedTabCode: tabItem.key,
        selectedInbox: curSelChannelTypeInboxList?.[0] || {},
        conversationChannelTabState: {},
        selectedInboxList: curSelChannelTypeInboxList || [],
      };
    });
  };

  const createUserConversationFilterIfNotExistFromAPI = async (actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        filterModal: false,
      };
    });
    if (conversationChannelTabState?.userFilterData?.id) {
      await updateUserConversationFilterFromAPI(
        conversationChannelTabState?.userFilterData?.id,
        actionData
      )
    } else {
      await createUserConversationFilterFromAPI(actionData);
    }

  }

  const updateUserConversationFilterFromAPI = async (id: string, actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        filterModal: false,
        conversationFilterProps: {
          ...actionData.conversationFilterProps
        },
        conversationFilterQuery: {
          ...actionData.conversationFilterQuery
        },
      };
    });
    const respData = await updateUserConversationFilter({
      id: id,
      filterCode: USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER,
      filterValue: actionData?.filterValue || {},
      additionalData: {
        conversationFilterProps: actionData.conversationFilterProps,
        conversationFilterQuery: actionData.conversationFilterQuery,
      }
    });
    if (respData?.data?.count) {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          filterModal: false,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          userFilterData: {
            ...conversationChannelTabState?.userFilterData,
            additionalData: {
              conversationFilterProps: {
                ...actionData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...actionData.conversationFilterQuery
              },
            },
            filterCode: USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER,
            filterValue: actionData?.filterValue || {},
          }
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: actionData.conversationFilterProps,
          conversationFilterQuery: actionData.conversationFilterQuery,
          filterModal: false,
        };
      });
    }
  }

  const resetUserConversationFilterFromAPI = async (id: string, actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        filterModal: false,
        conversationFilterProps: {
          ...actionData.conversationFilterProps
        },
        conversationFilterQuery: {
          ...actionData.conversationFilterQuery
        },
      };
    });
    const respData = await resetUserConversationFilter({
      id: id,
      filterCode: USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER,
    });
    if (respData?.data) {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          filterModal: false,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          userFilterData: {
            ...conversationChannelTabState?.userFilterData,
            additionalData: {
              conversationFilterProps: {
                ...actionData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...actionData.conversationFilterQuery
              },
            },
            filterCode: USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER,
            filterValue: {},
          }
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: actionData.conversationFilterProps,
          conversationFilterQuery: actionData.conversationFilterQuery,
          filterModal: false,
        };
      });
    }
  }

  const createUserConversationFilterFromAPI = async (actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        conversationFilterProps: {
          ...actionData.conversationFilterProps
        },
        conversationFilterQuery: {
          ...actionData.conversationFilterQuery
        },
      };
    });
    if (conversationChannelTabState) {

    }
    const respData = await createUserConversationFilter({
      filterCode: USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER,
      filterValue: actionData?.filterValue || {},
      defaultFilterValue: {},
      additionalData: {
        conversationFilterProps: actionData.conversationFilterProps,
        conversationFilterQuery: actionData.conversationFilterQuery,
      }
    });
    if (respData?.data?.id) {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          filterModal: false,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          userFilterData: {
            id: respData?.data?.id,
            additionalData: {
              conversationFilterProps: {
                ...actionData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...actionData.conversationFilterQuery
              },
            },
            filterCode: USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER,
            filterValue: actionData?.filterValue || {},
            defaultFilterValue: {},
          } as any
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: actionData.conversationFilterProps,
          conversationFilterQuery: actionData.conversationFilterQuery,
          filterModal: false,
        };
      });
    }
  }

  const onModalAction = (actionCode: string, actionData?: any) => {
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    switch (actionCode) {
      case COMMON_ACTION_CODES.FILTER_CHANGED:
        if (
          actionData.conversationFilterState ||
          actionData.conversationFilterQuery ||
          actionData.conversationFilterProps
        ) {
          if (selectedChannelType === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
            createUserConversationFilterIfNotExistFromAPI(actionData);
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                conversationFilterProps: actionData.conversationFilterProps,
                conversationFilterQuery: actionData.conversationFilterQuery,
                filterModal: false,
              };
            });
          }

        } else {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              filterModal: false,
            };
          });
        }

        break;
      case COMMON_ACTION_CODES.RESET_FILTER_TO_DEFAULT:
        if (
          actionData.conversationFilterState ||
          actionData.conversationFilterQuery ||
          actionData.conversationFilterProps
        ) {
          if (selectedChannelType === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
            if (conversationChannelTabState?.userFilterData?.id) {
              resetUserConversationFilterFromAPI(
                conversationChannelTabState?.userFilterData?.id,
                actionData
              )
            } else {
              setConversationChannelTabState((prev) => {
                return {
                  ...prev,
                  conversationFilterProps: actionData.conversationFilterProps,
                  conversationFilterQuery: actionData.conversationFilterQuery,
                  filterModal: false,
                };
              });
            }

          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                conversationFilterProps: actionData.conversationFilterProps,
                conversationFilterQuery: actionData.conversationFilterQuery,
                filterModal: false,
              };
            });
          }

        } else {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              filterModal: false,
            };
          });
        }

        break;
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            filterModal: false,
          };
        });
        break;
    }
  };

  const saveLastTabSelected = (tabKey: string) => {
    const storageValue: any = {};
    storageValue[userUUID] = tabKey;
    LocalStorage.setItem('communicationLastSelectedTab', JSON.stringify(storageValue));
    LocalStorage.getItem('conversationLastFilterSelected')
      .then((response) => {
        if (response) {
          const responseData = JSON.parse(response);
          if (responseData?.[userUUID] && responseData?.[userUUID]?.selectedTab) {
            if (responseData?.[userUUID]?.selectedTab !== tabKey) {
              LocalStorage.removeItem('conversationLastFilterSelected');
            }
          }
        }
      })
  }

  const updateSideCarTabList = (selectedTab: string) => {
    const currentList = sideCarTabList;
    const index = currentList.findIndex(item => item.key === selectedTab);
    const selectedItem = currentList.find(item => item.key === selectedTab);
    if (index && index > 3 && selectedItem) {
      currentList.splice(index, 1);
      // Add the item to the 4th position
      currentList.splice(3, 0, selectedItem);
    }
    setSideCarTabList(currentList);
  }

  return (
    <>
      {showLoadingOnPreferenceChange() && (
        <View
          zIndex={10}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          height={'100%'}
          width={'100%'}
          backgroundColor={Colors?.Custom?.Gray200}
          opacity={0.5}
        >
          <Spin size="large" />
        </View>
      )}
      <View flex={1}>
        {isSidecarContext ? (
          <View height={isConversationSelected ? 0 : 'auto'}>
            <SidecarTitle
              titleId={
                showOnlyInternalChatInSidecar ? 'internalChatsTab' : 'chat'
              }
              onClose={() => {}}
              paddingVertical={6}
              actionButtonId={
                showOnlyInternalChatInSidecar ? 'startInternalChat' : undefined
              }
              actionButtonPress={() => {
                setConversationChannelTabState((prev) => {
                  return {
                    ...prev,
                    displayCreateChatPopup: false,
                    visibleDrawerCode:
                      CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
                  };
                });
              }}
              showCreateNewButton={true}
            />
            {!showOnlyInternalChatInSidecar && (
              <>
                <View>
                  <SideCarConversationTabBar
                    tabsList={sideCarTabList}
                    showMaxTabCount={4}
                    unreadCountList={unreadCountMap}
                    allUnreadCount={allUnreadCount}
                    conversationFilterProps={
                      conversationChannelTabState.conversationFilterProps
                    }
                    filterModal={filterModal}
                    setSearchText={(text) => {
                      setConversationChannelTabState((prev) => {
                        return {
                          ...prev,
                          sideCarSearchString: text,
                        };
                      });
                    }}
                    onTabClick={(tabItem: any, index?: number) => {
                      if (tabItem?.path) {
                        navigateToTabPath(tabItem);
                      }
                      onTabClick(tabItem);
                      saveLastTabSelected(tabItem.key);
                      updateSideCarTabList(tabItem.key);
                    }}
                  />
                </View>
              </>
            )}
          </View>
        ) : (
          <View style={styles.titleWrapper}>
            <TitleSubtitleView
              titleLabelId="messagingTitle"
              subtitleLabelId="messagingSubtitle"
            />
          </View>
        )}
        <View
          style={{
            display: 'flex',
            flexDirection: isSidecarContext ? 'column' : 'row',
            alignItems: isSidecarContext ? 'flex-start' : undefined,
            flex: 1,
          }}
        >
          {isSidecarContext ? (
            <></>
          ) : (
            <View
              style={{
                height: '100%',
                width: '13%',
                backgroundColor: 'white',
                //borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderTopWidth: 0,
                //overflow: 'scroll',
                borderBottomWidth: 0,
                maxHeight: height - 100,
              }}
            >
              <ScrollView flex={1} showsVerticalScrollIndicator={false}>
                <View>
                  {conversationSideBarList.map((menuItem, index) => {
                    return (
                      <View key={menuItem.intlId+"_ConvSidebar_"+index}>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottomColor: Colors.Custom.Gray200,
                            borderBottomWidth: 1,
                            marginBottom: 2,
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.Custom.Gray900,
                              paddingLeft: 16,
                              paddingVertical: 12,
                            }}
                            fontWeight={600}
                            size="mdNormal"
                            fontSize={14}
                            {...testID(menuItem.intlId || '')}
                          >
                            {intl.formatMessage({id: menuItem.intlId})}
                          </Text>
                          <View style={{right: 10}}>
                            {!isEmployerRole() &&
                              menuItem.header.toLocaleUpperCase() ==
                                MAIN_MENU_CODES.INBOX && (
                                <AutomationSettingBtn
                                  hideDivider={true}
                                  flowType={FlowType.communication}
                                  settingsPath={
                                    isMessageSettingAllowed()
                                      ? '/admin/communication/inbox'
                                      : ''
                                  }
                                />
                              )}
                          </View>
                        </View>
                        {menuItem.list.map(
                          (tabItem: IConversationChannelList, index) => {
                            const testId = tabItem.code + '_TAB';
                            return (
                              <Pressable
                                key={tabItem.key+"_ConvSidebar_"+index}
                                onPress={() => {
                                  if (tabItem?.path) {
                                    navigateToTabPath(tabItem);
                                  }

                                  onTabClick(tabItem);
                                  saveLastTabSelected(tabItem.key);
                                }}
                                _hover={{
                                  backgroundColor: Colors.FoldPixel.GRAY50,
                                  color: Colors.FoldPixel.GRAY400
                                }}
                                style={{
                                  marginHorizontal: 4,
                                  marginVertical: 2,
                                  borderRadius: 4,
                                  borderWidth: 0.5,
                                  borderColor:
                                    conversationChannelTabState.selectedTabCode == tabItem.key
                                      ? Colors.Custom.PrimaryColor200
                                      : 'transparent',
                                  paddingLeft: 12,
                                  paddingVertical: 10,
                                  backgroundColor:
                                    conversationChannelTabState.selectedTabCode == tabItem.key
                                      ? '#FCFAFF'
                                      : '',
                                }}
                                size="mdNormal"
                                fontSize={15}
                                fontWeight={500}
                                {...testID(TestIdentifiers.conversationTabClick)}
                              >
                                <HStack flex={1}>
                                  <View
                                    justifyItems={'center'}
                                    alignItems={'center'}
                                    alignSelf={'center'}
                                    justifySelf={'center'}
                                    height={'16px'}
                                    width={'16px'}
                                    {...testID('image')}
                                  >
                                    {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                                      tabItem.key,
                                      conversationChannelTabState.selectedTabCode ==
                                        tabItem.key
                                        ? Colors.Custom.Primary300
                                        : Colors.FoldPixel.GRAY300,
                                    )}
                                  </View>
                                  <Text
                                    flex={0.9}
                                    style={{
                                      color:
                                        conversationChannelTabState.selectedTabCode ==
                                        tabItem.key
                                          ? Colors.Custom.Primary300
                                          : Colors.FoldPixel.GRAY300,
                                      paddingStart: 6,
                                    }}
                                    size="mdNormal"
                                    fontSize={14}
                                    fontWeight={500}
                                    {...testID(testId)}
                                  >
                                    {intl.formatMessage({id: tabItem.intlId})}
                                  </Text>
                                  {showCountLoading(tabItem?.code) && <Spin />}
                                  {tabItem.code &&
                                  tabItem.code != 'ALL_CHAT' &&
                                  !showCountLoading(tabItem?.code) &&
                                  unreadCountMap[tabItem.code]?.count > 0 ? (
                                    <Badge
                                      fontSize={10}
                                      fontWeight={500}
                                      backgroundColor={
                                        Colors.Custom.primary300
                                      }
                                      _text={{
                                        color: 'white',
                                      }}
                                      alignSelf="center"
                                      rounded="6px"
                                      justifyContent={'center'}
                                      paddingX={"4px"}
                                      paddingY={"2px"}
                                      {...testID(`${testId}_COUNT`)}
                                    >
                                      {getCountString({
                                        count:
                                          unreadCountMap[tabItem.code]?.count,
                                      })}
                                    </Badge>
                                  ) : null}
                                  {tabItem.code == 'ALL_CHAT' &&
                                  !showCountLoading(tabItem?.code) &&
                                  allUnreadCount > 0 ? (
                                    <Badge
                                      fontSize={10}
                                      fontWeight={500}
                                      backgroundColor={
                                        Colors.Custom.primary300
                                      }
                                      _text={{
                                        color: 'white',
                                      }}
                                      alignSelf="center"
                                      rounded="6px"
                                      justifyContent={'center'}
                                      paddingX={"4px"}
                                      paddingY={"2px"}
                                      {...testID(`${testId}_COUNT`)}
                                    >
                                      {getCountString({
                                        count: allUnreadCount,
                                      })}
                                    </Badge>
                                  ) : null}
                                </HStack>
                              </Pressable>
                            );
                          }
                        )}
                      </View>
                    );
                  })}
                </View>
              </ScrollView>
            </View>
          )}

          <View
            style={{
              height: '100%',
              ...(isSidecarContext && {width: '100%'}),
            }}
            flex={1}
          >
            <Conversations
              key={conversationChannelTabState.selectedTabCode}
              selectedInboxTypeCode={
                conversationChannelTabState.selectedTabCode as IInboxChannelType
              }
              selectedInbox={conversationChannelTabState.selectedInbox}
              conversationFilterQuery={
                conversationChannelTabState.conversationFilterQuery
              }
              conversationFilterProps={
                conversationChannelTabState.conversationFilterProps
              }
              onActionPerformed={onActionPerformed}
              allInboxList={conversationChannelTabState.userInboxList}
              filterModal={filterModal}
              emailInboxUserPreference={
                conversationChannelTabState?.emailInboxUserPreference
              }
              setUserPreferenceChangeLoading={setUserPreferenceChangeLoading}
              sideCarSearchString={
                isSidecarContext
                  ? conversationChannelTabState.sideCarSearchString
                  : undefined
              }
              isConversationSelectedInSideCar={(isSelected) => {
                setIsConversationSelected(isSelected);
              }}
            />
          </View>
        </View>
        {conversationActionDrawer()}
        {conversationChannelTabState.visibleDrawerCode ===
          RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
          <AddOrUpdateLead
            singleLeadData={conversationChannelTabState.selectedData}
            onFormCompleteAction={(actionCode: string) => {
              const eventBus = EventBus.getEventBusInstance();
              eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, {
                fromSection: 'LEFT_PROFILE_VIEW',
              });

              eventBus.broadcastEvent(
                SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
                COMMON_ACTION_CODES.COMPLETED
              );
              setConversationChannelTabState((prev) => {
                return {
                  ...prev,
                  visibleDrawerCode: '',
                  selectedData: {},
                };
              });
            }}
            personType={
              conversationChannelTabState.selectedData.contactType?.code
            }
            personTypeUuid={
              conversationChannelTabState.selectedData.contactType?.id
            }
          />
        )}
      </View>
    </>
  );
};

export default withMiniContactViewHOC(ConversationSidebarV2);
