import moment from 'moment';
import {
  DISPLAY_DATE_FORMAT,
  MLOV_CATEGORY,
  PERSON_CONTACT_TYPE_CODES,
} from '../../../../../../constants';
import {ICommonData} from '../../../../../../context/CommonDataContext';
import {IMlov, IPersonContact} from '../../../../../../Interfaces';
import {
  getEmailContactObjFromList,
  getPhoneContactObjFromList,
} from '../../../../../../utils/commonUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';

export const getFormDataFromVisitorData = (
  singleVisitorData: any,
  mlovData: ICommonData
): any => {
  const personContactTypeList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_CONTACT_TYPE
  );
  // const personGenderList = getMlovListFromCategory(
  //   mlovData.MLOV,
  //   MLOV_CATEGORY.PERSON_GENDER
  // );
  const emailContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );

  const phoneContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );

  let contactList = singleVisitorData?.personContact || [];
  if (contactList && contactList.length) {
    contactList = updateContactTypeIdIfNotExist(
      contactList,
      personContactTypeList
    );
  }
  const emailObj = getEmailContactObjFromList(
    contactList,
    personContactTypeList
  );
  const phoneObj = getPhoneContactObjFromList(
    contactList,
    personContactTypeList
  );

  let ageInYears;
  let birthDate =
    singleVisitorData?.person?.birthDate &&
    new Date(singleVisitorData?.person?.birthDate);
  if (birthDate) {
    birthDate = moment(birthDate).format(DISPLAY_DATE_FORMAT);
    ageInYears = moment().diff(birthDate, 'years', false);
  }

  const address = singleVisitorData?.personAddress
    ? singleVisitorData?.personAddress[0]
    : {};
  // let genderName = '';
  // if (singleVisitorData?.person?.genderId) {
  //   genderName = getMlovValueFromId(personGenderList, singleVisitorData?.person?.genderId)
  // }
  const firstName = singleVisitorData?.person?.firstName
    ? singleVisitorData?.person?.firstName
    : singleVisitorData?.name
    ? singleVisitorData?.name
    : '';
  const middleName = singleVisitorData?.person?.middleName
    ? ' ' + singleVisitorData?.person?.middleName
    : '';
  const lastName = singleVisitorData?.person?.lastName
    ? ' ' + singleVisitorData?.person?.lastName
    : '';
  const fullName = firstName + middleName + lastName;
  const note =
    singleVisitorData?.notes && singleVisitorData?.notes.length
      ? singleVisitorData?.notes[singleVisitorData?.notes.length - 1]
      : '';
  const formTempData = {
    leadId: singleVisitorData?.id || '',
    firstName: singleVisitorData?.person?.firstName
      ? singleVisitorData?.person?.firstName
      : firstName,
    name: fullName ? fullName : singleVisitorData?.name,
    cityId: address?.cityId || '',
    stateId: address?.stateId || '',
    zipCodeId: address?.zipcodeId || '',
    email: singleVisitorData?.email || '',
    phone: singleVisitorData?.phoneNumber || '',
    emailObj: emailObj || {},
    phoneObj: phoneObj || {},
    emailContactTypeId: emailContactTypeId,
    phoneContactTypeId: phoneContactTypeId,
    personAddressId: address?.id || '',
    cityName: address?.cities?.name || '',
    id: singleVisitorData?.id || '',
    stateName: address?.states?.name || '',
    typeId: address?.typeId || '',
    zipCode: address?.zipcodes?.code || '',
    patientId: singleVisitorData?.patient
      ? singleVisitorData?.patient?.patientId
      : '',
    patientUuid: singleVisitorData?.patient
      ? singleVisitorData?.patient?.patientUuid
      : '',
    // genderId: singleVisitorData?.person?.genderId || '',
    // genderName: genderName,
    // birthDate: birthDate || '',
    // age: ageInYears || '',
    // note: singleVisitorData?.note || '',
    // cityId: address?.cityId || '',
    // cityName: address?.city?.name || '',
    // id: singleVisitorData?.person?.address[0]?.id || '',
    // line1: singleVisitorData?.person?.address[0]?.line1 || '',
    // line2: singleVisitorData?.person?.address[0]?.line2 || '',
    // stateId: address?.stateId || '',
    // stateName: address?.state?.name || '',
    // typeId: singleVisitorData?.person?.address[0]?.typeId || '',
    // zipCodeId: address?.zipCodeId || '',
    // zipCode: address?.zipCode?.code || '',
    personId: singleVisitorData?.person?.id || '',
    lastName: singleVisitorData?.person?.lastName || '',
    middleName: singleVisitorData?.person?.middleName || '',
    genderId: singleVisitorData?.person?.genderId || '',
    genderName: singleVisitorData?.person?.gender?.value || '',
    birthDate: birthDate || '',
    age: ageInYears || '',
    noteId: note.id || '',
    note: note.content || '',
    personTypeId: singleVisitorData?.contactType?.id || '',
    line1: singleVisitorData?.address?.line1 || '',
    line2: singleVisitorData?.address?.line2 || '',
  };
  return formTempData;
};

const updateContactTypeIdIfNotExist = (
  contactList: IPersonContact[],
  personContactTypeList: IMlov[]
): IPersonContact[] => {
  const tempContactList: IPersonContact[] = [];
  const emailContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );
  const phoneContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );
  contactList.forEach((singleContact: IPersonContact) => {
    const tempContactObj = {...singleContact};
    if (!tempContactObj.typeId) {
      if (tempContactObj.value?.includes('@')) {
        tempContactObj.typeId = emailContactTypeId;
      } else {
        tempContactObj.typeId = phoneContactTypeId;
      }
    }
    tempContactList.push({...tempContactObj});
  });
  return tempContactList;
};

export const validates = (leadFormData: any) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errors: any = {};
  let validate = true;

  errors.firstName = !leadFormData.firstName
    ? 'FirstName is required'
    : leadFormData.firstName.length < 3
    ? 'FirstName is too short'
    : '';
  errors.email = !leadFormData.email
    ? 'Email or Phone Required'
    : leadFormData.email && !leadFormData.email.match(emailRegex)
    ? 'Invalid Email'
    : '';
  if (errors.firstName || errors.email) {
    validate = false;
  }
  return {validate: validate, errors: errors};
};
