import {Drawer} from 'antd';
import {
  Checkbox,
  HStack,
  Input,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, { useEffect } from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import { getAccountName } from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import PhoneCallSvg from '../../../common/Svg/PhoneCallSvg';
import {LinkPhoneDrawerProps, PhoneNumber} from './interfaces';
import {styles} from './LinkPhoneDrawerStyles';
import LinkPhoneItem from './LinkPhoneItem';

const LinkPhoneDrawer = (props: LinkPhoneDrawerProps) => {
  const intl = useIntl()
  const {data, open, onClose, actionCode} = props;
  const accountName = getAccountName();
  const [state, setState] = React.useState({
    loading: false,
    domainName: props.data.sipDomain,
    name: '',
    model: '',
    serialNumber: '',
    ringOnIncoming: true,
    showPassword: false,
    linkedPhoneList: [],
    extensionNumber: '',
  });
  const viewLinkedData = {
    sipUrl: 'LexilPPhone1',
    userName: 'LexilPPhone1',
    password: 'password'
  }
  const {name, phoneNumber} = data?.phoneNumber || {} as PhoneNumber;
  const axios = BaseService.getSharedInstance().axios;
  const toast = useToast();

  const getLinkedPhonesDetails = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const res = await axios.get(
      `/cloud-telephony/sip/phone/${data.id}/credential`
    );
    if (res?.data?.length) {
      const response: [] = res?.data?.length ? res?.data : [];
      setState((prev) => {
        return {
          ...prev,
          linkedPhoneList: response,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  useEffect(()=> {
    if (actionCode === COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES) {
      getLinkedPhonesDetails();
    }
  },[])

  const updateRingOnIncoming = async (phoneId: string, value: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const body = {
        ringOnIncoming: value,
      };
      const url = `/cloud-telephony/sip/phone-credential/${phoneId}`;
      const response = await axios.put(url, body);
      showToast(toast, 'Phone number updated successfully', ToastType.success);
      getLinkedPhonesDetails();
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  };

  const onSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const body = {
        domainName: state.domainName,
        phoneName: state.name,
        phoneModel: state.model,
        phoneSerialNumber: state.serialNumber,
        virtualPhoneNumberAssigneeId: data.id,
        ringOnIncoming: state.ringOnIncoming,
        extensionNumber: state.extensionNumber,
      };
      const url = '/cloud-telephony/sip/phone-credential';
      const response = await axios.post(url, body);
      showToast(toast, 'Phone number linked successfully', ToastType.success);
      onClose();
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      onClose();
    }
  };

  const getPasswordVisibleText = (text:string)=> {
    let passwordText̉ = ''
    for(let i = 0; i < text.length ; i++) {
      passwordText̉ = passwordText̉ + '*'
    }
    return passwordText̉
  }

  const getLinkPhoneVIew = ()=> {
    const linkedPhoneList = state.linkedPhoneList;
    let ele = <></>
    switch (actionCode) {
      case COMMON_ACTION_CODES.LINK_IP_PHONE:
         ele =   <VStack
         style={{
           marginTop: 30,
         }}
       >
         <View style={styles.inputViewStyle}>
           <Input
             value={state.name}
             onChangeText={(value) => {
               setState((prev) => {
                 return {
                   ...prev,
                   name: value,
                 };
               });
             }}
             style={styles.inputStyle}
             placeholder="Phone name(e.g front desk, dr. xyz cabin)"
           />
         </View>
         <View style={styles.inputViewStyle}>
           <Input
             value={state.domainName}
             onChangeText={(value) => {
               setState((prev) => {
                 return {
                   ...prev,
                   domainName: value,
                 };
               });
             }}
             style={styles.inputStyle}
             placeholder="Domain"
           />
         </View>
         <View style={styles.inputViewStyle}>
           <Input
             value={state.extensionNumber}
             onChangeText={(value) => {
               setState((prev) => {
                 return {
                   ...prev,
                   extensionNumber: value,
                 };
               });
             }}
             style={styles.inputStyle}
             placeholder="Extension Number"
           />
         </View>
         <View style={styles.inputViewStyle}>
           <Input
             value={state.model}
             onChangeText={(text) => {
               setState((prev) => {
                 return {
                   ...prev,
                   model: text,
                 };
               });
             }}
             style={styles.inputStyle}
             placeholder="Phone model (optional)"
           />
         </View>
         <View style={styles.inputViewStyle}>
           <Input
             value={state.serialNumber}
             onChangeText={(text) => {
               setState((prev) => {
                 return {
                   ...prev,
                   serialNumber: text,
                 };
               });
             }}
             style={styles.inputStyle}
             placeholder="Phone serial number(optional)"
           />
         </View>
         <View style={styles.inputViewStyle}>
           <Checkbox
             value="ringOnIncoming"
             accessibilityLabel="This is a dummy checkbox"
             isChecked={state.ringOnIncoming}
             onChange={(ringOnIncoming) => {
               setState((prev) => {
                 return {
                   ...prev,
                   ringOnIncoming,
                 };
               });
             }}
             _text={{
               color: 'black',
               fontSize: 12,
             }}
           >
             Ring Phone on Incoming call
           </Checkbox>
         </View>
       </VStack>
        break;
        case COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES:
          ele = (
            <>
              {linkedPhoneList.map((linkedPhone: any, index: number) => {
                return (
                  <LinkPhoneItem
                    key={`linkedPhone_${linkedPhone?.phoneName}`}
                    linkedPhone={linkedPhone}
                    updateRingOnIncoming={updateRingOnIncoming}
                    getPasswordVisibleText={getPasswordVisibleText}
                  />
                );
              })}
            </>
          );
        break;
      default:
        break;
    }
    return ele;
  }

  const getButtonList = ()=> {
    const buttonList = [
      {
        show: true,
        id: 1,
        btnText: 'cancel',
        textColor: Colors.danger[800],
        variant: BUTTON_TYPE.SECONDARY,
        isDisabled: false,
        isTransBtn: false,
        isLoading: false,
        onClick: () => {
          onClose();
        },
      }];

    if (actionCode === COMMON_ACTION_CODES.LINK_IP_PHONE) {
      buttonList.push({
        show: true,
        id: 2,
        btnText: 'Save',
        isDisabled: state.name.length === 0,
        textColor: Colors.secondary[800],
        isLoading: state.loading,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          onSubmit();
        },
      });
    }

    return buttonList;
  }
  const {width} = Dimensions.get('window')
  return (
    <Drawer
      open={open}
      width={'33vw'}
      onClose={onClose}
      title={
        <ModalActionTitle
          title={'Link New IP Phones'}
          titleColor={''}
          buttonList={getButtonList()}
        />
      }
    >
      <HStack style={[styles.phoneNumberWrapper]}>
        <VStack style={styles.iconWrapper}>
          <PhoneCallSvg
            isEnabled
            customStrokeColor={'black'}
            customBgColor={'white'}
          />
        </VStack>
        <VStack style={styles.phoneNumberView}>
          <View>
            <Text
              style={{
                fontWeight: '600',
              }}
            >
              {phoneNumber}
            </Text>
          </View>
          <View>
            <Text>{name}</Text>
          </View>
        </VStack>
      </HStack>

      {getLinkPhoneVIew()}
    </Drawer>
  );
};

export default LinkPhoneDrawer;
