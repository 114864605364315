import {useQuery} from '@apollo/client';
import {Dropdown} from 'antd';
import {Button, HStack, Text, VStack, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Icon from 'react-native-vector-icons/AntDesign';
import {useNavigate, useParams} from 'react-router-dom';
import {MLOV_CATEGORY, RESPONSE_VIEW_CODE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {USER_ROLE_CODES} from '../../../constants/MlovConst';
import {TELEPHONY_VIEW} from '../../../constants/TelephonyConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {FlowType} from '../../../context/WorkflowContext';
import {UserQueries} from '../../../services';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {Colors} from '../../../styles';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import {getAccountId, getAccountUUID, isOutlookEnabled} from '../../../utils/commonUtils';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import PlusIcon from '../../common/Svg/PlusSvg';
import {
  TABLE_TOP_BAR_ACTION_CODES,
  TableTopBar,
} from '../../common/TableTopBar';
import PurchaseNumberView from '../CloudTelephony/PurchaseNumberView/PurchaseNumberView';
import AutomationTopBar from '../Contacts/PatientsAdminPage/AutomationTopBar';
import {getWorkflowListTopBarButtons} from '../Workflow/Workflow/WorkflowTableView/Helper/TopBarButtons';
import DefaultCommunicationGroup from './DefaultCommunicationGroup/DefaultCommunicationGroup';
import EmailInboxDrawer from './Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxDrawer';
import {SMS_INBOX_ACTION_CODES} from './Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import SmsInboxDrawer from './Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxDrawer';
import {IntegrationLanding} from './Integrations/IntegrationLanding/IntegrationLanding';
import {INBOX_CHANNEL_TYPE} from './Integrations/constants';
import {Responses} from './Responses';
import {addResponseButton} from './Responses/Helper/ResponseTableTopBarButtons';
import {styles} from './styles';
import {EMAIL_INBOX_ACTION_CODES, EMAIL_PLATFORMS} from './Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxConst';
import { Pressable } from 'react-native';
import EFaxInboxSettings from './EFax Settings/EFaxInboxSettings';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import CustomDropdownButton from '../../common/Buttons/CustomDropdownButton';

const AdminCommunication = () => {
  const [searchString, setSearchString] = useState('');
  const commonData = useContext(CommonDataContext);
  const tagsMlovs = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentModal, setCurrentModal] = useState('');
  const [addEFaxInboxVisible, setAddEFaxInboxVisible] = useState(false);
  const intl = useIntl();
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const [mlov, setMlov] = useState({value: ''});
  const onAddResponse = () => {
    setCurrentModal(RESPONSE_VIEW_CODE.ADD_RESPONSE);
    setModalVisible(!modalVisible);
  };
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isOutlookEnabledForAccount = isOutlookEnabled();
  const [state, setState] = React.useState({
    inboxViewCode: '',
  });

  const {data: accountUserData} = useQuery(InboxQueries.GetAgents, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      accountId: accountId,
      roleCode: USER_ROLE_CODES.EMPLOYER,
    },
  });

  const {data: userListResp} = useQuery(UserQueries.Get_Users_IVR, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUUID: accountUuid,
      roleCode: USER_ROLE_CODES.EMPLOYER,
    },
  });

  const tagsTabList = (): ITabsList[] => {
    const filteredResult = tagsMlovs?.filter((item) => {
      return item.code === 'CONVERSATION';
    });
    const mlov = filteredResult[0];
    // setMlov(mlov);
    const array: ITabsList[] = [
      // {
      //   key: 'automation',
      //   path: 'automation',
      //   title: 'Automations',
      //   tabContainerElem: () => {
      //     return (
      //       <View
      //         key={FlowType.communication}
      //         bgColor={'white'}
      //         borderWidth={1}
      //         borderColor={Colors.Custom.Gray200}
      //         borderRadius={8}
      //         style={{marginHorizontal: 16}}
      //       >
      //         <WorkflowView
      //           searchText={searchString}
      //           showTableTopBar={false}
      //           flowType={FlowType.communication}
      //         />
      //       </View>
      //     );
      //   },
      // },
      {
        key: 'widget',
        title: 'Inboxes',
        path: 'inbox',
        tabContainerElem: () => {
          return (
            <IntegrationLanding
              searchString={searchString}
              showTableTopBar={false}
              inboxViewCode={state.inboxViewCode}
            />
          );
        },
      },
      {
        key: 'cannedResponse',
        title: 'Template Responses',
        path: 'cannedResponse',
        tabContainerElem: () => {
          return (
            <Responses
              searchString={searchString}
              currentModal={currentModal}
              modalVisible={modalVisible}
              showTableTopBar={false}
              onChangeViewCallBack={(text: string) => {
                setCurrentModal(text);
              }}
            />
          );
        },
      },
      {
        key: 'defaultGroup',
        title: 'Chat Settings',
        path: 'default-group',
        tabContainerElem: () => {
          return (
            <DefaultCommunicationGroup
              selectedCode={selectedTab.selectedDrawerCode || ''}
              onActionPerformed={(actionCode: string) => {
                // onActionPerformed
              }}
            />
          );
        },
      },
      ...(isEFaxMessagingEnabled) ? [{
        key: 'efaxSettings',
        title: 'eFax',
        path: 'efax-settings',
        tabContainerElem: () => {
          return (
            <EFaxInboxSettings addEFaxInboxDrawer={addEFaxInboxVisible} onActionPerformed={() => {
              setAddEFaxInboxVisible(false)
            }}/>
          );
        },
      }]:[],
      // {
      //   key: 'tags',
      //   title: 'Tags',
      //   path: 'tags',
      //   tabContainerElem: () => {
      //     return (
      //       <TagView
      //         searchString={searchString}
      //         openModal={openModal}
      //         category={mlov}
      //         showTableTopBar={false}
      //       />
      //     );
      //   },
      // },
      // {
      //   key: 'autoReply',
      //   title: 'Auto Reply',
      //   path: 'auto-reply',
      //   tabContainerElem() {
      //     return <AutoReplyView />;
      //   },
      // },
    ];
    return array;
  };
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setOpenModal(false);
  };
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const getSearch = (actionCode: string, actionData: any) => {
    setSearchString(actionData.searchString);
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };
  const {type} = useParams();
  const tabType = {
    products: 'products',
    services: 'services',
    journeys: 'journeys',
  };

  const pageTitle = () => {
    switch (type) {
      case tabType.products:
        return {
          title: 'Products Management',
          automationType: FlowType.products,
        };
        break;
      case tabType.services:
        return {
          title: 'Services Management',
          automationType: FlowType.services,
        };
        break;
      case tabType.journeys:
        return {
          title: 'Care Journeys Management',
          automationType: FlowType.journeys,
        };
        break;
    }
  };

  const options = [
    {
      key: INBOX_CHANNEL_TYPE.SMS,
      label: 'SMS',
    },
    ...(isOutlookEnabledForAccount
      ? [
          {
            key: INBOX_CHANNEL_TYPE.OUTLOOK,
            label: 'Outlook',
          },
        ]
      : []),
    {
      key: INBOX_CHANNEL_TYPE.GMAIL,
      label: 'Gmail',
    },
  ];

  const onOptionSelect = (code: string) => {
    switch (code) {
      case INBOX_CHANNEL_TYPE.GMAIL:
        setState((prev) => {
          return {
            ...prev,
            inboxViewCode: code,
          };
        });
        break;

      case INBOX_CHANNEL_TYPE.SMS:
        setState((prev) => {
          return {
            ...prev,
            inboxViewCode: code,
          };
        });
        break;

      case INBOX_CHANNEL_TYPE.OUTLOOK:
        setState((prev) => {
          return {
            ...prev,
            inboxViewCode: code,
          };
        });
        break;

      default:
        break;
    }
  };

  const WidgetTopBar: JSX.Element = (
    <HStack justifyContent={'end'} alignItems={'center'} space={2}>
      <View>
        <TableTopBar
          isDividerNotVisible={true}
          alignSearchBarFlexEnd={true}
          title=""
          onActionPerformed={getSearch}
          buttonList={[]}
        />
      </View>
      <View>
        <Dropdown
          menu={{
            items: options,
            onClick: (info) => {
              onOptionSelect(info.key);
            },
          }}
          placement="bottomRight"
        >
          <Button h={8} variant="transparent">
            <CustomDropdownButton title={intl.formatMessage({id: 'newInbox'})} />
          </Button>
        </Dropdown>
      </View>
    </HStack>
  );

  const ResponseTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        onActionPerformed={getSearch}
        title=""
        buttonList={addResponseButton(onAddResponse)}
      />
    </>
  );
  const flowType = FlowType.communication || '';
  const flowTypeIvr = FlowType.ivr || '';
  const workflowUrl = `create?currentTag=TRIGGER&flowType=${flowType}`;
  const workflowUrlIvr = `create?currentTag=TRIGGER&flowType=${flowTypeIvr}`;

  const onPurchaseNumber = (): any => {
    setSelectedTab((prev) => {
      return {
        ...prev,
        selectedDrawerCode: TELEPHONY_VIEW.PURCHASE_NEW_NUMBER,
      };
    });
  };

  const TagTopBar: JSX.Element = (
    <>
      <>
        <TableTopBar
          isDividerNotVisible={true}
          title={``}
          onActionPerformed={getSearch}
          buttonList={[
            {
              btnText: 'tagAdd',
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              textColor: Colors.secondary['800'],
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <Icon name="plus" color={Colors.secondary['800']} />,
              btnClick: () => setOpenModal(true),
            },
          ]}
        />
      </>
    </>
  );

  const ivrTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getWorkflowListTopBarButtons(
          [
            () => {
              navigate(workflowUrlIvr, {});
            },
          ],
          workflowUrlIvr,
          'New IVR'
        )}
      />
    </>
  );

  const messagingGroupsTopBar: JSX.Element = (
    <TableTopBar
      isDividerNotVisible={true}
      title={``}
      alignSearchBarFlexEnd={true}
      hideSearchBar={true}
    />
  );

  const NumberTopBar: JSX.Element = (
    <>
      <TableTopBar
        isDividerNotVisible={true}
        title={``}
        alignSearchBarFlexEnd={true}
        buttonList={[
          {
            btnText: 'purchaseNewNumber',
            backgroundColor: Colors.secondary['100'],
            borderColor: Colors.secondary['200'],
            textColor: '',
            variant: 'PRIMARY',
            size: 'sm',
            leftIcon: <PlusIcon />,
            btnClick: onPurchaseNumber,
          },
        ]}
      />
    </>
  );

  //  ** this code changes required for get Efax number work flow
  // const efaxSettingTopBar: JSX.Element = (
  //   <Pressable
  //     onPress={() => {
  //       setAddEFaxInboxVisible(true);
  //     }}
  //   >
  //     <PlusIcon defaultColor={Colors.Custom.Gray400} />
  //   </Pressable>
  // );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab.key === 'widget') {
      ele = WidgetTopBar;
    } else if (selectedTab.key === 'cannedResponse') {
      ele = ResponseTopBar;
    } else if (selectedTab.key === 'tags') {
      ele = TagTopBar;
    } else if (selectedTab.key === 'automation') {
      ele = (
        <AutomationTopBar
          workflowUrl={workflowUrl}
          onTableTopBarActionPerformed={onTableTopBarActionPerformed}
        ></AutomationTopBar>
      );
    } else if (selectedTab.key === 'number') {
      ele = NumberTopBar;
    } else if (selectedTab.key === 'ivr') {
      ele = ivrTopBar;
    } else if (selectedTab.key === 'defaultGroup') {
      ele = messagingGroupsTopBar;
    // } else if (selectedTab.key === 'efaxSettings') {
    //   ele = efaxSettingTopBar;
    }
    return ele;
  };

  useEffect(() => {
    const filteredResult = tagsMlovs?.filter((item) => {
      return item.code === 'CONVERSATION';
    });
    const mlov = filteredResult[0];
    setMlov(mlov);

    setSelectedTab(tagsTabList()[0]);
  }, []);

  return (
    <View>
      {/* <View style={styles.titleWrapper}>
        <TitleSubtitleView
          titleLabelId="communication"
          subtitleLabelId="communicationSubtitle"
        />
      </View> */}
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          contentTopMargin={0}
          heading="communicationSettingTitle"
          extraBigRightView
          showHeading
          basePath={'/admin/communication'}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          rightView={(activeTab) => {
            return getSelectedTopBar(activeTab);
          }}
          bottomOffset={150}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>

      {state.inboxViewCode === TELEPHONY_VIEW.PURCHASE_NEW_NUMBER && (
        <PurchaseNumberView
          onFormCompleteAction={(actionCode: string) => {
            setState((prev) => {
              return {
                ...prev,
                inboxViewCode: '',
              };
            });
          }}
          userList={userListResp?.users || []}
        />
      )}

      {(state.inboxViewCode === INBOX_CHANNEL_TYPE.GMAIL ||
        state.inboxViewCode === INBOX_CHANNEL_TYPE.OUTLOOK) && (
        <EmailInboxDrawer
          isDrawerVisible={true}
          selectedInboxData={{} as any}
          selectedPlatform={
            state.inboxViewCode === INBOX_CHANNEL_TYPE.GMAIL
              ? EMAIL_PLATFORMS.GMAIL
              : EMAIL_PLATFORMS.OUTLOOK
          }
          onEmailInboxActionPerformed={(actionCode: string) => {
            if (actionCode === EMAIL_INBOX_ACTION_CODES.ON_INBOX_SAVE) {
              setState((prev) => {
                return {
                  ...prev,
                  inboxViewCode: EMAIL_INBOX_ACTION_CODES.ON_INBOX_SAVE,
                };
              });
            } else {
              setState((prev) => {
                return {
                  ...prev,
                  inboxViewCode: '',
                };
              });
            }
          }}
        />
      )}

      {state.inboxViewCode === INBOX_CHANNEL_TYPE.SMS && (
        <SmsInboxDrawer
          isDrawerVisible={true}
          allUserList={accountUserData?.accountUsers || []}
          selectedInboxData={{} as any}
          onSmsInboxActionPerformed={(actionCode: string) => {
            if (
              actionCode === SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  inboxViewCode: TELEPHONY_VIEW.PURCHASE_NEW_NUMBER,
                };
              });
            } else {
              setState((prev) => {
                return {
                  ...prev,
                  inboxViewCode: '',
                };
              });
            }
          }}
        />
      )}
    </View>
  );
};

export default AdminCommunication;
