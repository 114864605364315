import {ICustomFieldFormKeys} from "./interface";

export enum ENTITY_TYPE  {
  CONTACT = 'CONTACT'
}

export enum CUSTOM_ATTRIBUTE_FILTERS {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  LESS_THEN = 'LESS_THEN',
  BETWEEN = 'BETWEEN',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT_LIKE'
}

export enum CUSTOM_ATTRIBUTE_FILTERS_VALUE {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  GREATER_THAN = '>',
  GREATER_THAN_EQUAL = '>=',
  LESS_THAN_EQUAL = '<=',
  LESS_THEN = '<',
  BETWEEN = 'BETWEEN',
  LIKE = 'Includes',
  NOT_LIKE = 'Not includes'
}

export enum CUSTOM_ATTRIBUTE_FILTERS_CONDITION {
  EQUALS = '_eq',
  NOT_EQUALS = '_neq',
  GREATER_THAN = '_gt',
  GREATER_THAN_EQUAL = '_gte',
  LESS_THAN_EQUAL = '_lte',
  LESS_THEN = '_lt',
  BETWEEN = 'BETWEEN',
  LIKE = '_ilike',
  NOT_LIKE = '_nilike'
}

export const BOOLEAN  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  }
]

export const TEXT  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LIKE
  },
  {
    key: 2,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  },
  {
    key: 3,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_EQUALS
  },
  {
    key: 4,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_LIKE
  }
]

export const NUMBER  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  },
  {
    key: 2,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_EQUALS
  },
  {
    key: 3,
    value: CUSTOM_ATTRIBUTE_FILTERS.GREATER_THAN,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.GREATER_THAN,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.GREATER_THAN
  },
  {
    key: 4,
    value: CUSTOM_ATTRIBUTE_FILTERS.GREATER_THAN_EQUAL,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.GREATER_THAN_EQUAL,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.GREATER_THAN_EQUAL
  },
  {
    key: 5,
    value: CUSTOM_ATTRIBUTE_FILTERS.LESS_THEN,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LESS_THEN,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LESS_THEN
  },
  {
    key: 6,
    value: CUSTOM_ATTRIBUTE_FILTERS.LESS_THAN_EQUAL,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LESS_THAN_EQUAL,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LESS_THAN_EQUAL
  },
  {
    key: 7,
    value: CUSTOM_ATTRIBUTE_FILTERS.BETWEEN,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.BETWEEN,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.BETWEEN
  },
]

export const DATE  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  },
  {
    key: 2,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_EQUALS
  },
  {
    key: 3,
    value: CUSTOM_ATTRIBUTE_FILTERS.GREATER_THAN,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.GREATER_THAN,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.GREATER_THAN
  },
  {
    key: 4,
    value: CUSTOM_ATTRIBUTE_FILTERS.GREATER_THAN_EQUAL,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.GREATER_THAN_EQUAL,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.GREATER_THAN_EQUAL
  },
  {
    key: 5,
    value: CUSTOM_ATTRIBUTE_FILTERS.LESS_THEN,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LESS_THEN,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LESS_THEN
  },
  {
    key: 6,
    value: CUSTOM_ATTRIBUTE_FILTERS.LESS_THAN_EQUAL,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LESS_THAN_EQUAL,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LESS_THAN_EQUAL
  },
  {
    key: 7,
    value: CUSTOM_ATTRIBUTE_FILTERS.BETWEEN,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.BETWEEN,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.BETWEEN
  },
]

export const EMAIL  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LIKE
  },
  {
    key: 2,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  },
  {
    key: 3,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_EQUALS
  },
  {
    key: 4,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_LIKE
  }
]

export const PHONE  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LIKE
  },
  {
    key: 2,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  },
  {
    key: 3,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_EQUALS
  },
  {
    key: 4,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_LIKE
  }
]

export const NPI  = [
  {
    key: 1,
    value: CUSTOM_ATTRIBUTE_FILTERS.LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LIKE
  },
  {
    key: 2,
    value: CUSTOM_ATTRIBUTE_FILTERS.EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.EQUALS
  },
  {
    key: 3,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_EQUALS,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_EQUALS,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_EQUALS
  },
  {
    key: 4,
    value: CUSTOM_ATTRIBUTE_FILTERS.NOT_LIKE,
    label: CUSTOM_ATTRIBUTE_FILTERS_VALUE.NOT_LIKE,
    condition: CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_LIKE
  }
]


export const DATA_TYPES_OPERATION = {
  BOOLEAN,
  TEXT,
  NUMBER,
  DATE,
  EMAIL,
  PHONE,
  NPI,
}

export enum CUSTOM_ATTRIBUTE_DATA_SOURCE {
  MANUAL_IMPORT = 'MANUAL_IMPORT',
  FOLD_APP = 'FOLD_APP'
}

export enum CUSTOM_ATTRIBUTE_DATA_TYPES {
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  NPI = 'NPI',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN'
}

export enum CUSTOM_ATTRIBUTE_VALUE_COLUMN {
  DATE_VALUE = 'dateValue',
  NUMBER_VALUE = 'numberValue',
  TEXT_VALUE = 'textValue',
  BOOLEAN_VALUE = 'booleanValue'
}

export enum SHOW_DISABLED {
  YES = 'yes',
  NO = 'no'
}

export enum CONTACT_TYPES_VALUE {
  CUSTOMER = 'Customer',
  PATIENT = 'Patient'
}

export enum CUSTOM_ATTRIBUTE_SELECT_TYPE {
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT'
}

export const CUSTOM_FIELD_GET_DATA_LIMIT = 10;
export const CUSTOM_FIELD_SELECT_DATA_LIMIT = 100;

export const ATTRIBUTE_TYPE = [
  {
    key: '1',
    value: 'TEXT',
    label: 'Text',
  },
  {
    key: '2',
    value: 'NUMBER',
    label: 'Number',
  },
  {
    key: '3',
    value: 'DATE',
    label: 'Date',
  },
  {
    key: '4',
    value: 'BOOLEAN',
    label: 'Boolean (Yes/No)',
  },
  {
    key: '5',
    value: 'EMAIL',
    label: 'Email',
  },
  {
    key: '6',
    value: 'PHONE',
    label: 'Phone',
  },
  {
    key: '7',
    value: 'NPI',
    label: 'NPI',
  },
]

export const BOOLEAN_OPTION = [
  {
    key: '1',
    value: 'true',
    label: 'Yes',
  },
  {
    key: '2',
    value: 'false',
    label: 'No',
  },
]

export const CUSTOM_FIELD_REF_KEYS: Array<ICustomFieldFormKeys> = [
  'title',
  'description',
];

export const MEMBER_FILTER_DRAWER_WIDTH = 750;
export const CUSTOM_FIELD_TOAST_ERROR_DURATION = 2000;
export const CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG = 4000;

export const CUSTOM_FIELD_NAME_MAX_CHAR_LIMIT = 80;
export const CUSTOM_FIELD_DESCRIPTION_MAX_CHAR_LIMIT = 400;

export const ALL_CONTACT_TYPE  = {
  id: 'All',
  value: 'All (All custom contact types)'
}

export const SHOW_CUSTOM_CONTACT_TYPES = 'yes'
export const CUSTOM_ATTRIBUTE_TITLE_MAX_DISPLAY_CHAR = 15;
export const CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR = 15;
export const INVALID_DATE = 'Invalid date';
