import React from 'react';
import {Button, Divider, HStack, Pressable, VStack, Text} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {View} from 'native-base';
import {styles} from './ActionsViewStyles';
import {Popover} from 'antd';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {getQuickActionObjListByPersonType} from '../../../../ContactsUtils';
// import {PERSON_TYPES} from '../../../../../../../constants';
import {isCurrentMenuLast} from '../../../../../../PersonOmniView/LeftContainer/PamiDetail/PamiDetailUtils';
import Feather from 'react-native-vector-icons/Feather';
interface IActionsViewProps {
  onActionClick: (actionCode: string) => void;
  record?: any;
}
const ActionsView = (props: IActionsViewProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const contactType = props?.record?.contactType?.code
  const quickActionList = getQuickActionObjListByPersonType(
    // PERSON_TYPES.VISITOR
    contactType
  );
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {quickActionList.map((action: any, index: any) => {
        return (
          <Pressable
            onPress={() => {
              props.onActionClick(action.actionCode);
              setIsOpen(!isOpen);
            }}
          >
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
              <Text style={styles.mainMenuTxt}>
              <DisplayText size={'smMedium'} textLocalId={action.displayString} />
              </Text>
            </HStack>
            {isCurrentMenuLast( index,quickActionList) && (
              <Divider my={1} style={styles.dividerStyle} />
            )}
          </Pressable>
        );
      })}
    </VStack>
  );
  return (
    <View style={styles.iconView}>
      <Button
        _hover={{bg: Colors.Custom.HoverBgColor}}
        onPress={() => {
          props.onActionClick('MAIL');
        }}
        // disabled={true}
        leftIcon={<Icon name="mail" style={[styles.icons]} />}
        style={styles.btn}
      />
      <Button
        onPress={() => {
          props.onActionClick('DELETE');
        }}
        leftIcon={
          <Icon name="delete" size={17} style={[styles.icons]} />
        }
        style={styles.btn}
      />
      <Button
        onPress={() => {
          props.onActionClick('EDIT');
        }}
        leftIcon={<Feather name="edit-2" size={17} style={[styles.icons]} />}
        style={styles.btn}
      />

      <Popover
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'left'}
        trigger="click"
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Button
          onPress={() => {
            setIsOpen(true);
          }}
          style={styles.btn}
        >
          <Icon
            name="bars"
            style={[styles.icons,
              // {color: Colors.Custom.PrimaryBtnColor}
            ]}
          />
        </Button>
      </Popover>
    </View>
  );
};

export default ActionsView;
