import {Divider, HStack, Spacer, Text, View, VStack} from 'native-base';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {SearchBar} from '../../../common/SearchBar';

export const TaskPoolConfigurationTopBar = (props: {
  onSearch: (text: string) => void;
}) => {
  return (
    <View>
      <View paddingY={2}>
        <HStack style={{paddingHorizontal: 16}}>
          <VStack flex={1} justifyContent={'center'}>
            <View>
              <Text wordBreak={'keep-all'}>
                Configure which user can see task for other team members
              </Text>
            </View>
          </VStack>
          <Spacer />
          {false &&
          <VStack flex={1}>
            <HStack justifyContent={'flex-end'} alignItems="center">
              <VStack justifyContent={'flex-end'}>
                <View marginRight={5}>
                  <SearchBar
                    onChange={(value: string) => {
                      props.onSearch(value);
                    }}
                  />
                </View>
              </VStack>
            </HStack>
          </VStack>
          }
        </HStack>
      </View>
      <Divider />
    </View>
  );
};

export default TaskPoolConfigurationTopBar;
