import { useLazyQuery } from '@apollo/client';
import { Select as AntSelect, Drawer, Modal, Popover, Spin, Tooltip } from 'antd';
import {
  Button,
  Checkbox,
  Divider,
  HStack,
  Pressable,
  Radio,
  Skeleton,
  Text,
  VStack,
  View,
  useToast
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BUTTON_TYPE, MEMBER_STATUS, POPULATION_GROUP_TYPES } from '../../../../../constants';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { FlowType } from '../../../../../context/WorkflowContext';
import ContactsQueries from '../../../../../services/Contacts/ContactsQueries';
import { Colors } from '../../../../../styles';
import { getResourceAbilities } from '../../../../../utils/capabilityUtils';
import {
  findIsAllowToRunAutomation,
  getAccountUUID,
  isLeadGroupsPage,
  splitArrayIntoChunks
} from '../../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { CodeSearchType } from '../../../../common/CodeSearch/CodeSearchConstants';
import { styles } from '../../../../common/FilterDrawerView/FilterDrawerViewStyle';
import FilterNewIconSvg from '../../../../common/Svg/FilterNewIconSvg';
import ArroyLeft from '../../../../common/Svg/SideMenu/ArrowLeft';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import AssignCareJourneyPopup from '../../../Journeys/JourneysOfCare/AssignCareJourneyPopup/AssignCareJourneyPopup';
import { AutomationWorkflowEmployee } from '../../../MembersManagement/AutomationWorkflowEmployee';
import {
  getDifferenceWithResourceLogs,
  getResourceLogsQueryObject
} from '../../../MembersManagement/Helper';
import { IMembersDatas } from '../../../MembersManagement/RulesInterface';
import { Campaign, IContactsPracticeLocationsData, IMembersGroupData, Workflow } from '../../../MembersManagement/interfaces';
import { getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import {
  createCampaignSegment,
  getContactsWithinGroup,
  getGroupDetailById,
  getResourceLevelLogs,
  getRuleEngineCodes,
  getWorkflowList,
  searchCampaigns
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import AddOrUpdateCampaign from '../AddOrUpdateCampaign/AddOrUpdateCampaign';
import AssignJourneyListView from '../AssignJourneyListView/AssignJourneyListView';
import { FetchContactsBy } from '../AssignJourneyListView/interface';
import { CampaignSegmentTableContext } from '../interfaces';
import ContactSendForm from './ContactSendForm';
import GroupContactList from './GroupContactList/GroupContactList';
import GroupDetailSideView from './GroupDetailSideView/GroupDetailSideView';
import { AppliedGroupFilters, IContactInGroup } from './interface';

enum MemberStatus {
  ALL= 'ALL',
  ACTIVE= 'ACTIVE',
  INACTIVE= 'INACTIVE'
}

const GroupFilter = (props: {
  memberStatus: string;
  onClose: any;
  onApply: any;
  onReset: any;
  selectedCampaign?: Campaign;
  selectedWorkflow?: Workflow;
  hintStatus?: string[];
  openInHint?: boolean;
  groupType?: string;
}) => {
  const toast = useToast();
  const [memberStatus, setMemberStatus] = useState(props.memberStatus);
  const [allHintStatus, setAllHintStatus] = useState<{title: string, value: string}[]>();
  const [hintStatus, setHintStatus] = useState<string[] | undefined>(props?.hintStatus);
  const [loadingHintStatus, setLoadingHintStatus] = useState(false)
  const accountUuid = getAccountUUID();
  const skeletonLines = 4;
  const intl = useIntl()
  const [state, setState] = React.useState({
    campaigns: [] as Campaign[],
    workflows: [] as Workflow[],
    selectedCampaign: {} as Campaign,
    selectedWorkflow: {} as Workflow,
    workflowLoading: false,
    campaignLoading: false,
    searchedCampaigns: [] as Campaign[],
    searchedWorkflows: [] as Workflow[],
    campaignSearching: false,
    workflowSearching: false,
  });
  const getHintMemberStatusCode = async() => {
    setLoadingHintStatus(true);
    const codes = await getRuleEngineCodes(CodeSearchType.memberStatus).catch(
      () => {
        showToast(
          toast,
          intl.formatMessage({ id: 'errorInFetchingHintStatus' }),
          ToastType.error
        );
      }).finally(() => { setLoadingHintStatus(false) });
    const values: any = [];
    codes?.data?.expansion?.contains?.forEach((item: any) => {
      values.push({
        title: item.display,
        value: item.code,
      });
    });
    setAllHintStatus(values)
  }
  useEffect(() => {
    if (props?.openInHint && !isLeadGroupsPage() && props?.groupType === POPULATION_GROUP_TYPES.DYNAMIC) {
      getHintMemberStatusCode()
    }
  }, [])

  const onSelectCampaign = (value: string) => {
    const selectedCampaign = state.searchedCampaigns.find(
      (item) => item.id === value
    );
    setState((prev) => {
      return {
        ...prev,
        selectedCampaign: selectedCampaign || ({} as Campaign),
      };
    });
  };

  const onSelectWorkflow = (value: string) => {
    const selectedWorkflow = state.searchedWorkflows.find(
      (item) => item.workflowMasterId == value
    );
    setState((prev) => {
      return {
        ...prev,
        selectedWorkflow: selectedWorkflow || ({} as Workflow),
      };
    });
  };

  const onSearchCampaign = (searchString: string) => {
    if (searchString) {
      setState((prev) => {
        return {
          ...prev,
          campaignSearching: true,
        };
      });
      searchCampaigns({tenantId: accountUuid, searchString: searchString})
        .then((response) => {
          const result = response.campaigns;
          setState((prev) => {
            return {
              ...prev,
              searchedCampaigns: result,
              campaignSearching: false,
            };
          });
        })
        .catch((error) => {

        });
    } else {
      setState((prev) => {
        return {
          ...prev,
          searchedCampaigns: prev.campaigns,
        };
      });
    }
  };

  const onSearchWorkflows = (searchString: string) => {
    if (searchString) {
      setState((prev) => {
        return {
          ...prev,
          workflowSearching: true,
        };
      });
      getWorkflowList(
        searchString,
        FlowType.patients,
        accountUuid,
        (datalist: any[]) => {
          setState((prev) => {
            return {
              ...prev,
              searchedWorkflows: datalist,
              workflowSearching: false,
            };
          });
        },
        (error: any) => {

        },
        false
      );
    } else {
      setState((prev) => {
        return {
          ...prev,
          searchedWorkflows: prev.workflows,
        };
      });
    }
  };

  React.useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        campaignLoading: true,
        workflowLoading: true,
        selectedCampaign: props.selectedCampaign || ({} as Campaign),
        selectedWorkflow: props.selectedWorkflow || ({} as Workflow),
      };
    });
    searchCampaigns({
      tenantId: accountUuid,
      searchString: props.selectedCampaign?.name || '',
    })
      .then((response) => {
        setState((prev) => {
          return {
            ...prev,
            campaigns: response.campaigns,
            searchedCampaigns: response.campaigns,
            campaignLoading: false,
          };
        });
      })
      .catch((error) => {
        setState((prev) => {
          return {
            ...prev,
            campaignLoading: true,
            campaigns: [],
          };
        });
      });

    getWorkflowList(
      props.selectedWorkflow?.name || '',
      FlowType.patients,
      accountUuid,
      (dataList: any) => {
        setState((prev) => {
          return {
            ...prev,
            workflows: dataList,
            searchedWorkflows: dataList,
            workflowLoading: false,
          };
        });
      },
      (error: any) => {
        setState((prev) => {
          return {
            ...prev,
            workflowLoading: true,
            workflows: [],
          };
        });
      },
      false
    );
  }, []);

  return (
    <>
      <HStack alignItems={'center'} justifyContent={'center'} marginBottom={5}>
        <TitleSubtitleView
          isTitleSubtitleOldView={true}
          isHideCommonButton={true}
          containerStyle={{marginLeft: 0, marginBottom: 5}}
          titleLabelId={'Filter'}
          subtitleLabelId=""
        />
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
             <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  setState((prev) => {
                    return {
                      ...prev,
                      selectedCampaign: {} as Campaign,
                      selectedWorkflow: {} as Workflow,
                    };
                  });
                  props.onReset();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Reset',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  const currentMemberStatus = memberStatus || MemberStatus.ALL;
                  const currentHintStatus = hintStatus || undefined;
                  const result: any = {memberStatus: currentMemberStatus, ...(currentHintStatus?.length && {hintStatus: currentHintStatus})};
                  if (state.selectedCampaign.id) {
                    result['campaign'] = state.selectedCampaign;
                  }
                  if (state.selectedWorkflow.workflowMasterId) {
                    result['workflow'] = state.selectedWorkflow;
                  }
                  if (isLeadGroupsPage()) {
                    delete result.memberStatus;
                  }
                  props.onApply(result);
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Apply',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <VStack>
        {!isLeadGroupsPage() && (
          <VStack style={{marginTop: 12}}>
            <View style={[styles.filterTitle]}>
              <Text size={'lgSemibold'} color={'#000'}>
                Fold Status
              </Text>
            </View>
            <VStack style={{marginTop: 24}}>
              {
                <>
                  <Radio.Group
                    style={{paddingLeft: 16}}
                    // colorScheme={'secondary'}
                    name="contactsType"
                    value={memberStatus ? memberStatus : MEMBER_STATUS[2].value}
                    onChange={(nextValue: any) => {
                      setMemberStatus(nextValue);
                    }}
                  >
                    <HStack space={6}>
                      {MEMBER_STATUS.map((contactType: any, index: number) => {
                        return (
                          <Radio
                            colorScheme=""
                            isHovered={false}
                            isFocusVisible={false}
                            isFocused={false}
                            key={index}
                            value={contactType.value}
                            style={{marginVertical: 10}}
                          >
                            <Text
                              size={'smMedium'}
                              color={Colors.Custom.Gray700}
                              style={{marginLeft: 8}}
                            >
                              {contactType.label}
                            </Text>
                          </Radio>
                        );
                      })}
                    </HStack>
                  </Radio.Group>
                </>
              }
            </VStack>
          </VStack>
        )}
          {
            props?.openInHint && !isLeadGroupsPage() && props?.groupType === POPULATION_GROUP_TYPES.DYNAMIC &&
            <VStack style={{ marginTop: 12 }}>
              <View style={[styles.filterTitle]}>
                <Text size={'lgSemibold'} color={'#000'}>
                  Hint Status
                </Text>
              </View>
              <VStack style={{ marginTop: 24 }}>
                <HStack space={6} flexWrap={'wrap'}>
                  {
                    loadingHintStatus ? <Skeleton.Text lines={skeletonLines} /> :
                      allHintStatus?.map((status) => {
                        return (
                          <Checkbox
                            key={status?.value}
                            value={status?.value}
                            style={{ marginVertical: 10 }}
                            alignItems={'center'}
                            isChecked={hintStatus?.includes(status?.value)}
                            onChange={(isChecked) => {
                              setHintStatus(prevHintStatus => {
                                if (isChecked) {
                                  if (!prevHintStatus?.includes(status?.value)) {
                                    return prevHintStatus?.length ? [...prevHintStatus, status?.value] : [status?.value];
                                  }
                                } else {
                                  return prevHintStatus?.filter(item => item !== status?.value);
                                }
                                return prevHintStatus;
                              });
                            }}
                          >
                            <Text
                              size={'smMedium'}
                              color={Colors.Custom.Gray700}
                              style={{ marginLeft: 8 }}
                            >
                              {status?.title}
                            </Text>
                          </Checkbox>
                        )
                      })}
                </HStack>
              </VStack>
            </VStack>
          }
        <VStack style={{marginTop: 12}}>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              Not Part of Campaign
            </Text>
          </View>
          <VStack style={{marginTop: 24}}>
            {
              <>
                <View>
                  {state.campaignLoading ? (
                    <Skeleton.Text lines={skeletonLines} />
                  ) : (
                    <AntSelect
                      size="large"
                      showSearch
                      allowClear
                      filterOption={false}
                      value={state.selectedCampaign.id}
                      onClear={() => {
                        onSelectCampaign('');
                      }}
                      onSearch={onSearchCampaign}
                      onChange={(value) => {
                        onSelectCampaign(value);
                      }}
                      placeholder="Select Campaign"
                      notFoundContent={
                        state.campaignSearching && <Spin size="small" />
                      }
                    >
                      {state.searchedCampaigns.map((item) => {
                        return (
                          <AntSelect.Option key={item.id} value={item.id}>
                            {item.name}
                          </AntSelect.Option>
                        );
                      })}
                    </AntSelect>
                  )}
                </View>
              </>
            }
          </VStack>
        </VStack>
        <VStack style={{marginTop: 12}}>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              Not Part of Automation
            </Text>
          </View>
          <VStack style={{marginTop: 24}}>
            {
              <>
                <View>
                  {state.workflowLoading ? (
                    <Skeleton.Text lines={skeletonLines} />
                  ) : (
                    <AntSelect
                      size="large"
                      showSearch
                      allowClear
                      filterOption={false}
                      value={state.selectedWorkflow.workflowMasterId}
                      onClear={() => {
                        onSelectWorkflow('');
                      }}
                      onSearch={onSearchWorkflows}
                      onChange={(value) => {
                        onSelectWorkflow(value);
                      }}
                      notFoundContent={
                        state.workflowSearching && <Spin size="small" />
                      }
                      placeholder="Select Automation"
                    >
                      {state.searchedWorkflows.map((item) => {
                        return (
                          <AntSelect.Option
                            key={item.workflowMasterId}
                            value={item.workflowMasterId}
                          >
                            {item.name}
                          </AntSelect.Option>
                        );
                      })}
                    </AntSelect>
                  )}
                </View>
              </>
            }
          </VStack>
        </VStack>
      </VStack>
    </>
  );
};

const GroupDetailMainView = (props: any) => {
  const toast = useToast();
  const intl = useIntl()
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: '',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const memberStatus = searchParams.get('memberStatus') || MemberStatus.ACTIVE;
  const hintStatus = searchParams.getAll('hintStatus');
  const [appliedFilters, setAppliedFilters] = useState<any>(
    isLeadGroupsPage()
      ? []
      : memberStatus && memberStatus != MemberStatus.ALL
      ? [memberStatus]
      : []
  );
  const {id, context} = useParams();
  const hintCapability = getResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const navigate = useNavigate();
  const accountUuid = getAccountUUID();
  const {height} = Dimensions.get('window');
  const createCampaignUrl = '/#/campaignManagement/create';
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [stateData, setStateData] = useState({
    contactList: [] as IContactInGroup[],
    allContactList: [] as number[],
    total: 0,
    activeTotal: 0,
    inactiveTotal: 0,
    passedTotal: 0,
    failedTotal: 0,
    isVisibleFilter: false,
    page: DEFAULT_PAGE,
    memberStatus: memberStatus,
    hintStatus: (hintStatus  || undefined) as string[] | undefined ,
    pageSize: DEFAULT_PAGE_SIZE,
    showButtonList: false,
    automationOpen: false,
    sendFormsModalOpen: false,
    formattedContactList: [] as any[],
    loading: false,
    selectedCampaign: {} as Campaign,
    selectedWorkflow: {} as Workflow,
    serviceLogsQuery: {},
    contactsDictionary: {} as Record<string, string>,
    groupDetaildata: {} as IMembersDatas,
    isCareJourneyInactiveMemberAlertOpen: false,
    assignCareJourneyData: {} as { record: any, contactIds: string[], showListDrawer :boolean, contactsPracticeLocationsData: IContactsPracticeLocationsData[]},

  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const callServiceLogsApi =
    Object.keys(stateData.selectedCampaign || {}).length > 0 ||
    Object.keys(stateData.selectedWorkflow || {}).length > 0;

  const getContactIdsByRuleId = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const page = stateData.page;
    const pageSize = stateData.pageSize;
    let allContactList: any;
    let passedCount = stateData.passedTotal;
    let failedCount = stateData.failedTotal;
    let activeCount = stateData.activeTotal;
    let inactiveCount = stateData.inactiveTotal;
    const contactsDictionary = stateData.contactsDictionary;
    if (!stateData.allContactList.length) {
      let isActive;
      if (stateData.memberStatus == MemberStatus.ACTIVE) {
        isActive = true;
      }
      if (stateData.memberStatus == MemberStatus.INACTIVE) {
        isActive = false;
      }
      setSelectedRowKeys([]);
      const contactListResp = await getContactsWithinGroup(
        id || '',
        isActive,
        stateData.hintStatus?.join()
      ).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      allContactList = contactListResp?.data?.data || [];
      activeCount = contactListResp?.data?.count || 0;
      inactiveCount = contactListResp?.data?.inActiveCount || 0;
      passedCount = contactListResp?.data?.passedCount || 0;
      failedCount = contactListResp?.data?.failedCount || 0;
      contactListResp?.data?.contacts?.forEach((contact: {
        contactId: string;
        contactUuid: string;
        memberStatus?: string;
      }) => {
        contactsDictionary[contact.contactUuid] = contact.memberStatus || '-';
      });
    } else {
      allContactList = stateData?.allContactList || [];
    }
    if (callServiceLogsApi) {
      const res = await getResourceLevelLogs(stateData.serviceLogsQuery);
      const newList = getDifferenceWithResourceLogs({
        resourceLevelLogs: res.resourceLevelLogs,
        memberList: allContactList,
      });
      allContactList = newList;
    }

    if (allContactList?.length) {
      const length = allContactList?.length;
      setStateData((prev) => {
        return {
          ...prev,
          allContactList: allContactList,
          contactList: allContactList
            ?.map((contactId: number) => {
              return {contactId};
            })
            .filter((data: any, index: number) => {
              const offset = (page - 1) * pageSize;
              const lastIndex = (page - 1) * pageSize + pageSize;
              if (index >= offset && index < lastIndex) {
                return true;
              }
              return false;
            }),
          total: length || 0,
          activeTotal: activeCount || 0,
          inactiveTotal: inactiveCount || 0,
          passedTotal: passedCount || 0,
          failedTotal: failedCount || 0,
          loading: false,
          contactsDictionary: contactsDictionary
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          allContactList: [],
          contactList: [],
          total: 0,
          activeTotal: activeCount || 0,
          inactiveTotal: inactiveCount || 0,
          passedTotal: passedCount || 0,
          failedTotal: failedCount || 0,
          loading: false,
        };
      });
    }
  };

  const getGroupDetail = async()=> {
    const response = await getGroupDetailById(id ||'')
    return response
  }

  const [getContactsByIds, contactDetailsQuery] = useLazyQuery(
    ContactsQueries.GET_ACTIVE_CONTACTS_BY_CONTACTIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );

  const getActiveContactsByChunk = async () => {
    const chunkForGetContacts = splitArrayIntoChunks(selectedRowKeys, 100)
    let contacts: any[] = []
    for (const chunk of chunkForGetContacts) {
      const contactsData = await getContactsByIds({
        variables: {
          contactIdList: chunk,
        },
      })
      if (contactsData?.data?.contacts?.length) {
        contacts = contacts.concat(contactsData?.data?.contacts);
      }
    }

    return contacts;
  }

  const onViewActionPerformed = (actionCode: string, data?: any) => {
    switch (actionCode) {
      case 'SHOW_BUTTON':
        if (data?.showActionBtn) {
          setStateData((prev) => {
            return {
              ...prev,
              showButtonList: data?.showActionBtn,
              formattedContactList: data?.contactList,
            };
          });
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              showButtonList: data?.showActionBtn,
              formattedContactList: [],
            };
          });
        }

        break;

      default:
        break;
    }
  };

  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );
  const showCareJourneyTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CARE_JOURNEY
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState({
    selectedId: 0,
    isOpen: false,
    zIndex: 1,
  });

  const handleVisibleChange = (e: any) => {
    setIsPopoverOpen(e);
  };

  const buttonList = [
    {
      show: !!(
        stateData?.allContactList?.length &&
        stateData.allContactList.length !== selectedRowKeys.length
      ),
      id: 1,
      btnText: 'Select All',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        const contactIds = stateData?.allContactList?.map((contact: any) => {
          return parseInt(contact?.contactId || contact);
        });
        setSelectedRowKeys(contactIds);
      },
    },
    {
      show: !!selectedRowKeys?.length,
      id: 5,
      btnText: 'Deselect All',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        setSelectedRowKeys([]);
      },
    },
    {
      show: showAutomationTab,
      id: 2,
      btnText: 'sendForm',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        if (selectedRowKeys?.length > 0) {
          setStateData((prev) => {
            return {
              ...prev,
              sendFormsModalOpen: true,
            };
          });
        }
        else {
          showToast(
            toast,
            intl.formatMessage({id: 'pleaseSelectMemberToSendForm'}),
            ToastType.error
          );
        }
      },
    },
    {
      show: showCareJourneyTab && !isLeadGroupsPage()  && selectedRowKeys?.length,
      id: 'assignJourneyPopGroups',
      btnText: 'assignJourney',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      content: (
        <AssignCareJourneyPopup
          onComplete={(record: any) => {
            if (selectedRowKeys?.length > 0) {
              setStateData((prev) => ({
                ...prev,
                assignCareJourneyData: {
                  ...prev.assignCareJourneyData,
                  record: record,
                  showListDrawer: true,
                },
              }));
            }
            else {
              showToast(
                toast,
                intl.formatMessage({id: 'pleaseSelectMemberToAssignJourney'}),
                ToastType.error
              );
            }
          }}
        />
      ),
      onClick: () => {
        //
      },
    },
    {
      show: showAutomationTab && findIsAllowToRunAutomation(),
      id: 3,
      btnText: 'runAutomation',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        setStateData((prev) => {
          return {
            ...prev,
            automationOpen: true,
          };
        });
      },
    },
    {
      show: true,
      id: 4,
      btnText: 'Create Campaign',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        if (selectedRowKeys?.length) {
          createCampaignSegment({
            name: '',
            tenantId: accountUuid,
            metadata: {
              patientFilter: {
                contactIds: selectedRowKeys,
              },
            },
          }).then((response) => {
            if (response?.id) {
              setCampaignDrawerState({
                isVisible: true,
                segmentId: response?.id,
              });
            }
          });
        } else {
          setCampaignDrawerState({
            isVisible: true,
            segmentId: id || '',
          });
        }
      },
    },
  ];

  async function onClickAssignCareJourney(record: any, unassignedContactsUuids: string[]) {
    const contacts = await getActiveContactsByChunk();
    const selectedRowKeysUuid = contacts?.map?.(contact => contact?.uuid)?.filter(cond => !!cond) || [];
    if (!selectedRowKeysUuid?.length) {
      showToast(
        toast,
        intl.formatMessage({id: 'pleaseSelectMemberToAssignJourney'}),
        ToastType.error
      );
    }
    const contactsPracticeLocationsData: IContactsPracticeLocationsData[] = contacts?.map((contact)=>{
      return {
        contactPracticeLocations: contact?.contactPracticeLocations|| [],
        contactStateId: contact?.personAddress?.[0]?.stateId || '',
      }
    })

    if (selectedRowKeysUuid?.length === selectedRowKeys?.length) {
      assignCareJourney(record, unassignedContactsUuids, contactsPracticeLocationsData);
    } else {
      setStateData((prev:any) => {
        return {
          ...prev,
          isCareJourneyInactiveMemberAlertOpen: true,
          assignCareJourneyData: {
            record: record,
            contactIds: unassignedContactsUuids,
            contactsPracticeLocationsData: contactsPracticeLocationsData,
          }
        };
      });
    }
  }

  function assignCareJourney(record: any, contactIds: string[], contactsPracticeLocationsData: IContactsPracticeLocationsData[]) {
    if (!record || !contactIds?.length) {
      showToast(toast, 'Something went wrong!', ToastType.error);
      return;
    }
    navigate(
      `/members/patient/${-1}/product/${record?.productId}/assignJourney/${record?.id}`,
      {
        state: {
          personData: {},
          isFromBulkAssign: true,
          bulkApiData: {
            groups: [{ groupId: id, contactIds: contactIds,contactsPracticeLocationsData}],
          },
        },
      },
    );
  }

  useEffect(() => {
    getContactIdsByRuleId();
  }, [
    stateData.page,
    stateData.pageSize,
    stateData.memberStatus,
    stateData.hintStatus,
    stateData.selectedCampaign.id,
    stateData.selectedWorkflow.workflowMasterId,
  ]);

  useEffect(() => {
    getGroupDetail().then((response: any) => {
      if (response?.data?.id) {
        setStateData((prev) => {
          return {
            ...prev,
            groupDetaildata: response?.data || {},
          };
        });
      }
    })
  }, [])

  const getGoBackAction = () => {
    switch(context){
      case CampaignSegmentTableContext.POPULATION_GROUP:
        return {
         route: '/members/groups',
         label: 'backToGroups'
        }
      case CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES:
        return {
          route : '/admin/schedule/suggestionRules',
          label:'backToRules'
        }
      default:
       return {
         route: '/members/groups',
         label: 'backToGroups',
       };
    }
  }

  const goBackAction = getGoBackAction();

  return (
    <>
      <View flex={1}>
        <View style={{backgroundColor: '#fff'}}>
          <TitleSubtitleView />
        </View>
        <View
          style={{
            paddingHorizontal: 24,
            paddingTop: 16,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button.Group>
             <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  !!context?.length ? navigate(goBackAction.route) : navigate(-1);
                },
                leftIcon: <ArroyLeft customStrokeColor={Colors.Custom.mainPrimaryPurple} />
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: goBackAction.label,
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>

          <HStack justifyContent={'right'} alignItems="center">
            {stateData.contactList.length ? (
              <Button.Group>
                {buttonList.map((item, index) => {
                  return item.show ? (
                    item.content ?
                    <Popover
                      key={item.id || index}
                      overlayInnerStyle={{
                        bottom: 3,
                        borderRadius: 16,
                        padding: 0,
                      }}
                      content={item.content || <></>}
                      title=""
                      trigger="click"
                      visible={
                        isPopoverOpen.selectedId ===
                        item.id as any
                          ? isPopoverOpen.isOpen
                          : false
                      }
                      onVisibleChange={handleVisibleChange}
                      placement={'bottom'}
                    >
                      <FoldButton
                        key={item.id}
                        nativeProps={{
                          variant: item.btnStype,
                          onPress: () => {
                            setIsPopoverOpen({
                              ...isPopoverOpen,
                              selectedId: item.id as any,
                              isOpen: !isPopoverOpen.isOpen,
                            });
                          },
                        }}
                        customProps={{
                          btnText: intl.formatMessage({
                            id: item.btnText,
                          }),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                    </Popover> :
                    <FoldButton
                      key={item.id}
                      nativeProps={{
                        variant: item.btnStype,
                        onPress: () => {
                          item.onClick();
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: item.btnText,
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  ) : (
                    <></>
                  );
                })}
              </Button.Group>
            ) : (
              <></>
            )}
            <HStack marginLeft={1}>
              <Tooltip
                title={''}
                arrowPointAtCenter={false}
                overlayStyle={{whiteSpace: 'pre-line'}}
              >
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {...prev, isVisibleFilter: true};
                    });
                  }}
                  style={[
                    {
                      width: 32,
                      height: 32,
                      justifyContent: 'center',
                      marginHorizontal: 2,
                    },
                  ]}
                >
                  {appliedFilters?.length && !(appliedFilters?.length === 1 && memberStatus === MemberStatus.ALL ) ? (
                    <View
                      style={{
                        position: 'absolute',
                        top: -7.5,
                        right: -7.5,
                        backgroundColor: Colors.danger[500],
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text style={{color: '#ffffff'}}>
                        {memberStatus === MemberStatus.ALL ? appliedFilters?.length - 1 : appliedFilters?.length}
                      </Text>
                    </View>
                  ) : null}
                 <FilterNewIconSvg
                     isEnabled={true}
                    />
                </Pressable>
              </Tooltip>
            </HStack>
          </HStack>
        </View>
        <View
          flex={1}
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            marginTop: 14
          }}
        >
          <View flex={0.2} style={{
            height: height - 160,
            borderRightWidth: 1,
            borderRightColor: Colors.Custom.Gray200
            }}>
            <GroupDetailSideView
              onUpdate={() => {
                getContactIdsByRuleId();
              }}
              loading={stateData.loading}
              activeTotal={stateData.activeTotal}
              inactiveTotal={stateData.inactiveTotal}
              passedTotal={stateData.passedTotal}
              failedTotal={stateData.failedTotal}
              getGroupDetail={getGroupDetail}
              groupDetailData={stateData?.groupDetaildata}
            />
          </View>
          <View flex={0.8}>
            <GroupContactList
              key={
                (stateData.memberStatus || '') +
                (stateData.total || '') +
                (id || '')
              }
              page={stateData.page}
              pageSize={stateData.pageSize}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              onPaginationChange={(page: number, pageSize: number) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    page,
                    pageSize,
                  };
                });
              }}
              total={stateData.total}
              onActionPerformed={onViewActionPerformed}
              contactList={stateData?.contactList}
              contactsDictionary={stateData?.contactsDictionary}
              id={id || ''}
              loading={stateData.loading || contactDetailsQuery.loading}
              groupType={stateData?.groupDetaildata?.type}
            />
          </View>
        </View>
      </View>
      {campaignDrawerState?.isVisible ? (
        <Drawer
          visible={campaignDrawerState?.isVisible}
          width={Dimensions.get('window').width * 0.7}
          onClose={() => {
            setCampaignDrawerState({
              isVisible: false,
              segmentId: null,
            });
          }}
          headerStyle={{
            display: 'none',
          }}
        >
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={(status) => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
              if (status != 'cancel') {
                showToast(
                  toast,
                  status == 'created'
                    ? 'Campaign created successfully.'
                    : 'Campaign updated successfully.',
                  ToastType.success,
                );
              }
            }}
          />
        </Drawer>
      ) : null}

      {stateData.isVisibleFilter && (
        <Drawer
          visible={stateData.isVisibleFilter}
          width={Dimensions.get('window').width * 0.4}
          onClose={() => {
            setStateData((prev) => {
              return {...prev, isVisibleFilter: false};
            });
          }}
          headerStyle={{
            display: 'none',
          }}
        >
          <GroupFilter
            selectedCampaign={stateData.selectedCampaign}
            selectedWorkflow={stateData.selectedWorkflow}
            groupType={stateData?.groupDetaildata?.type}
            onReset={() => {
              setStateData((prev) => {
                searchParams.delete('memberStatus');
                searchParams.delete('hintStatus');
                if (searchParams.has('campaign')) {
                  searchParams.delete('campaign');
                }
                if (searchParams.has('workflow')) {
                  searchParams.delete('workflow');
                }
                setSearchParams(searchParams);
                setAppliedFilters([]);
                return {
                  ...prev,
                  allContactList: [],
                  page: 1,
                  memberStatus: MemberStatus.ALL,
                  hintStatus: undefined,
                  isVisibleFilter: false,
                  selectedCampaign: {} as Campaign,
                  selectedWorkflow: {} as Workflow,
                };
              });
            }}
            openInHint={openInHint}
            onApply={(filter: AppliedGroupFilters) => {
              setStateData((prev) => {
                if (filter.memberStatus) {
                  searchParams.set('memberStatus', filter.memberStatus);
                }
                filter?.hintStatus?.forEach((status) => {
                  searchParams.append('hintStatus', status)
                })
                if (filter.campaign?.id) {
                  searchParams.set('campaign', filter.campaign.id);
                } else {
                  deleteParamIfExists({searchParams, param: 'campaign'});
                }
                if (filter.workflow?.workflowMasterId) {
                  searchParams.set(
                    'workflow',
                    filter.workflow.workflowMasterId
                  );
                } else {
                  deleteParamIfExists({searchParams, param: 'workflow'});
                }
                const query = getResourceLogsQueryObject({
                  workflow: filter.workflow,
                  campaign: filter.campaign,
                  resourceIds: stateData.allContactList.map((item) =>
                    item?.toString()
                  ),
                });
                setSearchParams(searchParams);
                setAppliedFilters(Object.keys(filter));
                return {
                  ...prev,
                  allContactList: [],
                  page: 1,
                  memberStatus: filter.memberStatus,
                  hintStatus: filter.hintStatus,
                  isVisibleFilter: false,
                  selectedCampaign: filter.campaign || ({} as Campaign),
                  selectedWorkflow: filter.workflow || ({} as Workflow),
                  serviceLogsQuery: query?.where,
                };
              });
            }}
            memberStatus={stateData.memberStatus}
            hintStatus={stateData.hintStatus}
            onClose={() => {
              setStateData((prev) => {
                return {...prev, isVisibleFilter: false};
              });
            }}
          ></GroupFilter>
        </Drawer>
      )}

      {stateData.automationOpen && (
        <Drawer
          visible={stateData.automationOpen}
          width={700}
          onClose={() =>
            setStateData((prev) => {
              return {
                ...prev,
                automationOpen: false,
              };
            })
          }
          closable={true}
          title={<></>}
        >
          <AutomationWorkflowEmployee
            ruleId={id}
            tenantId={accountUuid}
            cancelBtnTxt={'Back'}
            initData={{
              selectedItems: selectedRowKeys.map((id) => {
                return {id};
              }),
            }}
            onModalClose={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  automationOpen: false,
                };
              });
            }}
          />
        </Drawer>
      )}
      {stateData.assignCareJourneyData.showListDrawer && (
        <AssignJourneyListView
          fetchContactsBy={FetchContactsBy.CONTACT_IDS}
          onAssign={(record: IMembersGroupData, unassignedContactsUuids: string[]) =>
            onClickAssignCareJourney(stateData.assignCareJourneyData.record, unassignedContactsUuids)
          }
          selectedJourney={stateData.assignCareJourneyData.record}
          selectedContactIds={selectedRowKeys as string[]}
          visible={stateData.assignCareJourneyData.showListDrawer}
          ruleData={stateData?.groupDetaildata}
          onClose={() =>
            setStateData((prev) => ({
              ...prev,
              assignCareJourneyData: {
                ...prev.assignCareJourneyData,
                showListDrawer: false,
                record: {}
              },
            }))
          }
        />
      )}

      {stateData.sendFormsModalOpen && (
        <ContactSendForm
          isVisible={
            stateData.sendFormsModalOpen
          }
          assignmentData={{
            assignedById: accountUuid,
          }}
          contactIds={selectedRowKeys}
          selectedGroupId={id}
          onActionComplete={() => {
            setStateData((prev) => {
              return {
                ...prev,
                sendFormsModalOpen: false,
              };
            });
          }}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                sendFormsModalOpen: false,
              };
            });
          }}
        />
      )}
      {
        stateData.isCareJourneyInactiveMemberAlertOpen &&
        <Modal
          open={stateData.isCareJourneyInactiveMemberAlertOpen}
          onCancel={() => {
            setStateData((prev) => ({ ...prev, isCareJourneyInactiveMemberAlertOpen: false, assignCareJourneyData: undefined as any }));
          }}
          footer={null}
          width="35%"
          bodyStyle={{
            backgroundColor: Colors.Custom.BackgroundColor,
            padding: 0
          }}
          title={'Assign Journey?'}
          closable={false}
          className="popGroupFormNotificationConfirmation"
        >
          <Divider></Divider>
          <div className="modal-body">
            <Text>{intl.formatMessage({ id: 'popGroupCareJourneyAssignInactiveMemberNotification' })}</Text>
          </div>
          <div className="modal-footer" style={{ border: 'none' }}>
            <Button.Group>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    setStateData((prev) => ({ ...prev, isCareJourneyInactiveMemberAlertOpen: false, assignCareJourneyData: undefined as any }));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({ id: 'No' }),
                  withRightBorder: false,
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    assignCareJourney(stateData.assignCareJourneyData?.record, stateData.assignCareJourneyData?.contactIds, stateData?.assignCareJourneyData?.contactsPracticeLocationsData);
                    setStateData((prev) => ({ ...prev, isCareJourneyInactiveMemberAlertOpen: false, assignCareJourneyData: undefined as any }));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({ id: 'Yes' }),
                  withRightBorder: false,
                }}
              />
            </Button.Group>
          </div>
        </Modal>
      }
    </>
  );
};

export default GroupDetailMainView;

export const deleteParamIfExists = (args: {
  searchParams: URLSearchParams;
  param: string;
}) => {
  const {param, searchParams} = args;
  if (searchParams.has(param)) {
    searchParams.delete(param);
  }
};
