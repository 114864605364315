
import { Skeleton, Spacer, View } from 'native-base';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TestIdentifiers, testID } from '../../../../../../testUtils';
import { GraphMemberList } from '../../../../../common/AppDashboard/GraphMemberList';
import { BarGraph } from '../../../../../common/Graph/BarGraph';

import { useLazyQuery } from '@apollo/client';
import SmsQueries from '../../../../../../services/SmsTags/SmsQueries';
import { Colors } from '../../../../../../styles';
import { ISmsGraphByTag } from '../Interfaces';



export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};




export function SmsGraphByTag(props: ISmsGraphByTag) {
  const [searchParams] = useSearchParams();

  const [getSmsTagsWithContact] = useLazyQuery(
    SmsQueries.GET_SMS_TAGS_WITH_CONTACT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getSmsTagsWithoutContact] = useLazyQuery(
    SmsQueries.GET_SMS_TAGS_WITHOUT_CONTACT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getSmsAllStatusByTagsGroupByMessageId = async (
    tag: string,
    tagType: string,
    dateFilter?: { fromDate: string, toDate: string },
    eventCode?: string,
    isRequiredContact?: boolean
  ) => {

    const variables: any = {
      smsTagsBoolExp: {
        tag: {
          _eq: tag
        },
        tagType: {
          _eq: tagType
        }
      }
    }

    if (eventCode) {
      variables.smsTagsBoolExp.smsStatus = variables.smsTagsBoolExp.smsStatus || {}
      variables.smsTagsBoolExp.smsStatus.status = {
        _eq: eventCode
      }
    }
    if (dateFilter) {
      variables.smsTagsBoolExp.smsStatus = variables.smsTagsBoolExp.smsStatus || {}
      variables.smsTagsBoolExp.smsStatus.createdAt = {
        _gte: dateFilter.fromDate,
        _lte: dateFilter.toDate
      }
    }
    variables.smsStatusBoolExp = variables.smsTagsBoolExp?.smsStatus || {};
    const callMethod = isRequiredContact ? getSmsTagsWithContact : getSmsTagsWithoutContact;
    const response = await callMethod({ variables: variables });
    const responseData = response?.data?.smsTags;
    return responseData;
  };

  enum LOADING_STATUS {
    loadingValue,
    loadedValue,
    needToLoad,
  }
  const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS.needToLoad);
  const [data, setData] = useState<any>();
  const capitlizeFirst = (str: string) => {
    // checks for null, undefined and empty string
    if (!str) return;
    return str.match('^[a-z]')
      ? str.charAt(0).toUpperCase() + str.substring(1)
      : str;
  };

  const getDateStr = (dateStr: string) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const date = new Date(dateStr);
    date.getMonth();
    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
  };
  const [fetchCount, setFetchCount] = useState(0);
  const constructChartData = (results: any) => {

    const eventLogList: any = [];
    (results || [])?.forEach((result: any) => {
      (result?.smsStatus || []).forEach((smsStatus: any) => {
        eventLogList.push({
          eventCode: smsStatus?.status,
          createdOn: smsStatus?.createdOn
        });
      });
    });
    const datasets: Array<any> = [];

    eventLogList.sort(function (a: any, b: any) {
      const firstDate: any = new Date(b.createdOn);
      const secondDate: any = new Date(a.createdOn)
      const diff: any = secondDate - firstDate;
      return diff;
    });

    const statusCountMap: any = {}
    eventLogList.forEach((data: any) => {
      if (!data?.eventCode) {
        return;
      }
      if (!statusCountMap[data.eventCode]) {
        statusCountMap[data.eventCode] = 0;
      }
      statusCountMap[data.eventCode] = statusCountMap[data.eventCode] + 1;
    })

    eventLogList.forEach((log: any) => {
      const statusCount = statusCountMap[log.eventCode] || '';
      const status = capitlizeFirst(log.eventCode)
      datasets.push({
        status: statusCount ? status + ' (' + statusCount + ')' : status,
        date: getDateStr(log.createdOn),
        eventCode: log.eventCode,
        createdOn: log.createdOn,
        count: 1
      });
    });


    const newData = {
      data: datasets,
      style: {
        height: 200
      },
      label: {
        type: 'inner',
        content: '',
      },
      xField: 'date',
      yField: 'count',
      seriesField: 'eventCode',
    }
    setData(() => {
      return newData
    });
    setFetchCount(fetchCount + 1);
    props.setIsHideSmsGraph(!datasets.length);
    setLoadingStatus(LOADING_STATUS.loadedValue);
  };

  const fetchData = (tag: string) => {
    const fromDate = (searchParams.get('fromDate') ? searchParams.get('fromDate') : undefined);
    const toDate = (searchParams.get('toDate') ? searchParams.get('toDate') : undefined)
    const eventCode = (searchParams.get('smsStatus') ? searchParams.get('smsStatus') : undefined)
    if ((!fromDate || !toDate) && props.isDateMandatory) {
      return
    }
    let dateFilter: any = undefined;
    if (fromDate) {
      dateFilter = {};
      dateFilter.fromDate = fromDate
    }
    if (toDate) {
      dateFilter = dateFilter || {}
      dateFilter.toDate = toDate
    }
    getSmsAllStatusByTagsGroupByMessageId(props?.tagData?.tag, props?.tagData?.tagType, dateFilter, eventCode || '').then((results: any) => {
      constructChartData(results);
      setLoadingStatus(LOADING_STATUS.loadedValue);
    })
      .catch((err: any) => {

        setLoadingStatus(LOADING_STATUS.loadedValue);
      });
  };
  if (loadingStatus === LOADING_STATUS.needToLoad) {
    setLoadingStatus(LOADING_STATUS.loadingValue);
    if (props?.tagData?.tag) {
      fetchData(props?.tagData?.tag);
    }
  }

  function toCamelCase(input: string): string {
    const words = input.split(' ');
    const camelWords = words.map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });
    return camelWords.join('');
  }

  function getStartOfDay(date: Date): string {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay.toISOString();
  }

  function getEndOfDay(date: Date): string {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay.toISOString();;
  }
  function isIntegerString(input: string): boolean {
    return /^[+-]?\d+$/.test(input);
  }
  const [contactPopupData, setContactPopupData] = useState<any>({
    contactUuIdList: [],
    isShowContactList: false,
    pageSize: 10,
    currentPage: 1,
    eventCode: ''
  });





  const fetchClickedData = (clickedData: any) => {
    if (!clickedData) {
      return;
    }
    const tag = props?.tagData?.tag
    const fromDate = getStartOfDay(clickedData?.createdOn)
    const toDate = getEndOfDay(clickedData?.createdOn)
    const eventCode = toCamelCase(clickedData.eventCode)
    if (!fromDate || !toDate) {
      return
    }
    let dateFilter: any = undefined;
    if (fromDate) {
      dateFilter = {};
      dateFilter.fromDate = fromDate
    }
    if (toDate) {
      dateFilter = dateFilter || {}
      dateFilter.toDate = toDate
    }
    getSmsAllStatusByTagsGroupByMessageId(tag, props?.tagData?.tagType, dateFilter, eventCode || '', true).then((results: any) => {
      const contactUuidListInResult: any = [];
      (results || []).forEach((result: any) => {
        const contactUuid = result?.contactSmsLog?.contactUuid;
        if (contactUuid) {
          contactUuidListInResult.push(contactUuid)
        }

      })
      setContactPopupData({ ...contactPopupData, eventCode, currentPage: 1, contactUuIdList: contactUuidListInResult, isShowContactList: true })
      setLoadingStatus(LOADING_STATUS.loadedValue);
    })
      .catch((err: any) => {

        setLoadingStatus(LOADING_STATUS.loadedValue);
      });
  };
  if (loadingStatus === LOADING_STATUS.needToLoad) {
    setLoadingStatus(LOADING_STATUS.loadingValue);
    if (props?.tagData?.tag) {
      fetchData(props?.tagData?.tag);
    }
  }

  function paginateArray(inputArray: string[], pageSize: number, pageNumber: number): string[] {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return inputArray.slice(startIndex, endIndex);
  }

  function camelToPascalWithSpaces(input: string): string {
    const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(/[\s_-]+/);
    const pascalCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return pascalCaseWords.join(' ');
  }

  useEffect(() => {
    fetchData(props?.tagData?.tag)
  }, [searchParams.get('fromDate'), searchParams.get('toDate'), searchParams.get('smsStatus'), props?.tagData?.tag])
  return data ? (
    <View flex={1} width='full'>
      {/* <Text size='smMedium' color={Colors.Custom.Gray500} >SMS Record Graph</Text> */}
      <Spacer flex={1}></Spacer>
      <View flex={10} justifyContent={'center'} alignItems={'center'} padding={5}>
        <BarGraph key={fetchCount}  {...data} onClick={(clickData: any) => {
          fetchClickedData(clickData)
        }} color={(data:any)=>{
          const  colorMap:any = {
            sent: Colors.info[300],
            delivered: Colors.tertiary[300],
            undelivered: Colors.danger[300],
          }
          return colorMap[data?.eventCode] || Colors.primary[300]
        }} />
      </View>
      <GraphMemberList
        title={camelToPascalWithSpaces(contactPopupData?.eventCode || '')}
        page={contactPopupData.currentPage}
        pageSize={contactPopupData.pageSize}
        isFromNewEmployer={false}
        isFromDashboard={false}
        onClose={() => {
          setContactPopupData({ ...contactPopupData, currentPage: 1, contactUuIdList: [], isShowContactList: false })
        }}
        onPaginationChange={(page: number, pageSize: number) => {
          setContactPopupData({ ...contactPopupData, currentPage: page, pageSize: pageSize })
        }}
        total={contactPopupData?.contactUuIdList?.length}
        isOpen={contactPopupData?.isShowContactList}
        contactUuidList={paginateArray(contactPopupData?.contactUuIdList, contactPopupData?.pageSize, contactPopupData?.currentPage)}
        onCompleteAction={() => {
          setContactPopupData({ ...contactPopupData, currentPage: 1, contactUuIdList: [], isShowContactList: false })
        }}
      />
    </View >
  ) : (
    <View {...testID(TestIdentifiers.pageLoading)} width={'full'} height='full'>
      <Skeleton size={'full'}></Skeleton>
    </View>

  );
}
