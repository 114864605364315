export const NODE_TYPE = {
    WORKFLOW_ADD_TASK_V2: 'WorkflowAddTaskV2',
    BITWISE_AND: 'BitwiseAND',
    BITWISE_OR: 'BitwiseOR',
    ON_PATIENT_TASK_ADD_OR_UPDATE: 'OnPatientTaskAddOrUpdate'
};

export const CONDITION_FOR_SEND_EMAIL_TO_FIELD = {
    MATCHED_MEMBER: 'MATCHED_MEMBER',
    MEMBER_IN_APPOINTMENT: 'MEMBER_IN_APPOINTMENT',
    PARTICIPANT_IN_APPOINTMENT: 'PARTICIPANT_IN_APPOINTMENT',
    MEMBER_IN_CONVERSATION: 'MEMBER_IN_CONVERSATION',
};