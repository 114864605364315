import {useLazyQuery} from '@apollo/client';
import {
  Divider,
  HStack,
  Pressable,
  Skeleton,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocation} from 'react-use';
import {CONVERSATION_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {LeadQueries} from '../../../../../../../services';
import {
  getOrdersDetails,
  getOrdersDetailsOneTime,
} from '../../../../../../../services/Stripe/StripeServices';
import {Colors} from '../../../../../../../styles';
import {capitalizeText} from '../../../../../../common/ContactRelationView/ContactRelationUtils';
import TitleSubtitleView from '../../../../../../common/TitleSubtitleView/TitleSubtitleView';
import MessagingContactDetailsDrawer from '../../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {IStripeCharges} from '../../interfaces';

const MembershipOrderDetailView = () => {
  const navigate = useNavigate();
  const {id}: any = useParams();
  const {state}: any = useLocation();
  const [stateData, setStateData] = useState({
    orderDetails: {} as IStripeCharges,
    loading: false,
    noData: false,
    contactData: null,
    contactType: null,
    contactDataLoading: false,
  });

  const contact = state?.usr?.orderDetail?.contact?.Contacts;

  const fetchOrderDetail = async () => {
    if (state?.usr?.isOneTime) {
      const orderDetailResp = await getOrdersDetailsOneTime(id);
      return orderDetailResp;
    } else {
      const orderDetailResp = await getOrdersDetails(id);
      return orderDetailResp;
    }
  };

  const [ContactData] = useLazyQuery(LeadQueries.GetContact);
  const orderDetails = async () => {
    const orderDetailResp = await fetchOrderDetail();

    if (orderDetailResp?.data?.id) {
      setStateData((prev) => {
        return {
          ...prev,
          orderDetails: orderDetailResp?.data || state?.usr?.orderDetail,
          loading: false,
        };
      });
    } else if (state?.usr?.orderDetail) {
      setStateData((prev) => {
        return {
          ...prev,
          orderDetails: orderDetailResp?.data || state?.usr?.orderDetail,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          orderDetails: {} as IStripeCharges,
          loading: false,
        };
      });
    }
  };

  const getCustomerContactById = async (id: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        contactDataLoading: true,
      };
    });
    const contactDataResp = await ContactData({
      variables: {
        id: id,
      },
    });

    if (contactDataResp.data) {
      setStateData((prev) => {
        return {
          ...prev,
          contactData: contactDataResp?.data?.contact,
          contactType: contactDataResp?.data?.contact.contactType.contactType,
          contactDataLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    orderDetails();
  }, []);

  const getListItem = (
    key: string,
    value: any,
    isPrimary?: boolean,
    isLink?: boolean,
    callBack?: () => void
  ) => {
    return (
      <HStack flex={1} alignContent={'center'} style={{marginBottom: 12}}>
        <VStack flex={0.25} alignItems={'flex-start'}>
          <Text size={'smSemibold'} color={Colors.Custom.Gray400}>
            {key}
          </Text>
        </VStack>
        <VStack flex={0.75} alignItems={'flex-start'}>
          {value !== 'undefined' && isLink && value?.trim().length ? (
            <Pressable onPress={callBack}>
              <Text
                size={'smBold'}
                color={Colors.primary['400']}
                textDecorationLine={'underline'}
                textDecorationColor={Colors.primary['400']}
              >
                {value || '--'}
              </Text>
            </Pressable>
          ) : (
            <Text
              size={isPrimary ? 'smBold' : 'smNormal'}
              color={isPrimary ? Colors.primary['400'] : Colors.Custom.Gray600}
            >
              {value && value !== 'undefined' ? value : '--'}
            </Text>
          )}
        </VStack>
      </HStack>
    );
  };
  const onHeaderBackClick = () => {
    navigate(-1);
  };

  const getCustomerRedirectLink = () => {

    return (
      'https://dashboard.stripe.com/customers/' +
        stateData?.orderDetails?.contact?.stripeCustomerId ||
      stateData?.orderDetails?.customer
    );
  };

  return (
    <>
      <View flex={1}>
        <View
          style={{
            backgroundColor: '#fff',
          }}
        >
          <TitleSubtitleView
            title="Detail"
            showBackButton={true}
            onBackClick={onHeaderBackClick}
          />
        </View>
        <View
          style={{
            backgroundColor: Colors.Custom.Gray50,
            marginTop: 28,
            marginHorizontal: 16,
          }}
        >
          <View
            style={{
              backgroundColor: '#fff',
              borderColor: Colors.Custom.BorderColor,
              borderWidth: 1,
              borderRadius: 8,
              overflow: 'hidden',
              padding: 24,
              flex: 1,
            }}
          >
            {stateData.loading ? (
              <View padding={3}>
                <Skeleton.Text lines={3} />
              </View>
            ) : (
              <View>
                <HStack
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                >
                  <VStack>
                    <View>
                      <Text size={'xsMedium'} color={Colors.Custom.Gray400}>
                        {`PAYMENT`}
                      </Text>
                      <View flexDirection={'row'} alignItems={'center'}>
                        <Text size={'xslBold'} color={Colors.Custom.Gray700}>
                          {stateData.orderDetails.amount / 100}
                        </Text>
                        <Text
                          size={'xslNormal'}
                          style={{marginLeft: 6}}
                          color={Colors.Custom.Gray700}
                        >
                          {stateData.orderDetails?.currency?.toUpperCase()}
                        </Text>
                        <Text
                          style={{
                            color:
                              stateData.orderDetails.status == 'succeeded'
                                ? Colors.success[600]
                                : Colors.Custom.Gray400,
                            backgroundColor:
                              stateData.orderDetails.status == 'succeeded'
                                ? Colors.success[100]
                                : Colors.Custom.Gray100,
                            padding: 2,
                            paddingHorizontal: 8,
                            borderRadius: 6,
                            marginLeft: 8,
                          }}
                          size={'xsSemibold'}
                        >
                          {capitalizeText(stateData.orderDetails.status)}
                        </Text>
                      </View>
                    </View>
                  </VStack>
                  <VStack>
                    <VStack>
                      {
                        <Pressable
                          onPress={() => {
                            window.open(getCustomerRedirectLink(), '_blank');
                          }}
                        >
                          <Text size={'smBold'} color={Colors.primary['400']}>
                            {'Customer Profile'}
                          </Text>
                        </Pressable>
                      }
                    </VStack>
                    <Text
                      size={'xsBold'}
                      style={{color: Colors.Custom.Gray500}}
                    >
                      {stateData.orderDetails.payment_intent}
                    </Text>
                  </VStack>
                </HStack>
                <Divider
                  orientation={'horizontal'}
                  style={{
                    width: '100%',
                    backgroundColor: Colors.Custom.Gray200,
                    marginVertical: 20,
                  }}
                />
                <HStack alignItems={'center'}>
                  <VStack style={{marginRight: 32, paddingVertical: 4}}>
                    <Text
                      size={'smMedium'}
                      style={{marginBottom: 3}}
                      color={Colors.Custom.Gray400}
                    >
                      Customer
                    </Text>
                    <Pressable
                      onPress={() => {
                        if (
                          stateData?.orderDetails?.contact?.id ||
                          contact?.id
                        ) {
                          getCustomerContactById(
                            stateData.orderDetails?.contact?.Contacts?.id ||
                              contact?.id
                          );
                        }
                      }}
                    >
                      <Text size={'smBold'} color={Colors.primary['400']}>
                        {contact?.name ||
                            stateData.orderDetails?.billing_details?.name ||
                            stateData.orderDetails?.billing_details?.email ||
                            stateData.orderDetails?.receipt_email}
                      </Text>
                    </Pressable>
                  </VStack>
                  <Divider
                    orientation={'vertical'}
                    style={{
                      height: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginVertical: 20,
                    }}
                  />
                  <VStack style={{marginHorizontal: 32, paddingVertical: 4}}>
                    <Text
                      size={'smMedium'}
                      style={{marginBottom: 3}}
                      color={Colors.Custom.Gray400}
                    >
                      Payment method
                    </Text>
                    <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                      {`•••• ${stateData?.orderDetails.payment_method_details?.card?.last4}`}
                    </Text>
                  </VStack>
                  <Divider
                    orientation={'vertical'}
                    style={{
                      height: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginVertical: 20,
                    }}
                  />
                  <VStack style={{marginHorizontal: 32, paddingVertical: 4}}>
                    <Text
                      size={'smMedium'}
                      style={{marginBottom: 3}}
                      color={Colors.Custom.Gray400}
                    >
                      Risk evaluation
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <View
                        style={{
                          width: 28,
                          height: 28,
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: Colors.success[100],
                          padding: 2,
                          borderRadius: 150,
                          marginRight: 6,
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.success[600],
                          }}
                        >
                          {stateData?.orderDetails?.outcome?.risk_score}
                        </Text>
                      </View>
                      <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                        {capitalizeText(
                          stateData.orderDetails?.outcome?.risk_level
                        )}
                      </Text>
                    </View>
                  </VStack>
                </HStack>
                <View style={{marginVertical: 20}}>
                  <View>
                    <Text size={'lgSemibold'}>Payment method</Text>
                  </View>
                  <Divider
                    orientation={'horizontal'}
                    style={{
                      width: '100%',
                      backgroundColor: Colors.Custom.Gray200,
                      marginVertical: 20,
                    }}
                  />
                  <HStack flex={1}>
                    <VStack flex={0.5}>
                      {getListItem(
                        'ID',
                        stateData.orderDetails?.payment_method
                      )}
                      {getListItem(
                        'Number',
                        `•••• ${stateData.orderDetails?.payment_method_details?.card?.last4}`
                      )}
                      {getListItem(
                        'Expires',
                        `${stateData.orderDetails?.payment_method_details?.card?.exp_month}/${stateData.orderDetails?.payment_method_details?.card?.exp_year}`
                      )}
                      {getListItem(
                        'Type',
                        `${stateData.orderDetails?.payment_method_details?.card?.brand} ${stateData.orderDetails?.payment_method_details?.type}`
                      )}
                      {getListItem('Issuer', `Stripe Payments UK Limited`)}
                    </VStack>
                    <VStack flex={0.5}>
                      {getListItem(
                        'Owner',
                        stateData.orderDetails.billing_details?.name ||
                          contact?.name ||
                          ''
                      )}
                      {getListItem(
                        'Owner email',
                        stateData.orderDetails.billing_details?.email ||
                          contact?.email ||
                          ''
                      )}
                      {getListItem(
                        'Address',
                        `${
                          stateData.orderDetails.billing_details?.address
                            .line1 || ''
                        }${
                          stateData.orderDetails.billing_details?.address
                            .line2 || ''
                        }, ${
                          stateData.orderDetails.billing_details?.address
                            .city || ''
                        },  ${
                          stateData.orderDetails.billing_details?.address
                            .state || ''
                        }  ${
                          stateData.orderDetails.billing_details?.address
                            .country || ''
                        }  ${
                          stateData.orderDetails.billing_details?.address
                            .postal_code || ''
                        }
                  ` || ''
                      )}
                      {getListItem(
                        'Origin',
                        stateData.orderDetails.billing_details?.address
                          .country == 'US'
                          ? 'United States'
                          : ''
                      )}
                      {getListItem(
                        'CVC check',
                        capitalizeText(
                          stateData.orderDetails?.payment_method_details?.card
                            ?.checks?.cvc_check
                        ) || '--'
                      )}
                      {getListItem(
                        'Street check',
                        capitalizeText(
                          stateData.orderDetails?.payment_method_details?.card
                            ?.checks?.address_line1_check
                        ) || '--'
                      )}
                      {getListItem(
                        'Zip check',
                        capitalizeText(
                          stateData.orderDetails?.payment_method_details?.card
                            ?.checks?.address_postal_code_check
                        ) || '--'
                      )}
                      {getListItem(
                        'Set up for future use',
                        stateData.orderDetails?.payment_intent || '--',
                        true
                      )}
                    </VStack>
                  </HStack>
                </View>
                <View
                  flex={1}
                  style={{
                    marginVertical: 20,
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <View flex={1}>
                    <View>
                      <Text size={'lgSemibold'}>Payment details</Text>
                    </View>
                    <Divider
                      orientation={'horizontal'}
                      style={{
                        width: '100%',
                        backgroundColor: Colors.Custom.Gray200,
                        marginVertical: 20,
                      }}
                    />
                    {getListItem(
                      'subscription amount',
                      `${stateData.orderDetails?.currency?.toUpperCase()} ${
                        stateData.orderDetails?.amount / 100
                      }` || '--'
                    )}
                    {getListItem(
                      'status',
                      `${capitalizeText(
                        stateData.orderDetails?.status || ''
                      )}` || '--'
                    )}
                    {getListItem(
                      'Description',
                      `${stateData.orderDetails?.description}` || '--'
                    )}
                    {getListItem(
                      'Invoice',
                      `${stateData.orderDetails?.invoice}` || '--',
                      true,
                      true,
                      () => {
                        window.open(stateData.orderDetails?.receipt_url);
                      }
                    )}
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
        <MessagingContactDetailsDrawer
          contactData={stateData?.contactData}
          isDrawerVisible={stateData?.contactData ? true : false}
          contactType={stateData?.contactType}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setStateData((prev) => {
                return {
                  ...prev,
                  contactData: null,
                  contactType: null,
                };
              });
            }
          }}
        />
      </View>
    </>
  );
};

export default MembershipOrderDetailView;
