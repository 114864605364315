import {View} from 'native-base';
import React from 'react';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import {styles} from '../ContentManagement/ContentManagementStyle';
import ElationConfig from './ElationConfig/ElationConfig';
import FoldApisConfig from './FoldApisConfig/FoldApisConfig';
import {HintConfig} from './HintConfig';
import SpruceConfig from './SpruceConfig/SpruceConfig';
import EHRConfig from './EHRConfig/EHRConfig';
import { getCurrentUserRole } from '../../../utils/commonUtils';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';

const IntegrationConfig = () => {
  const currentUserRoles = getCurrentUserRole();
  const isBusinessOwner = currentUserRoles.some((role) => {
    return USER_ROLE_CODES.BUSINESS_OWNER === role.code;
  });
  const showHintTab = true;
  const showSpruceTab = true;
  const showElationTab = true;
  const showFoldApisTab = false;
  const showConfig = isBusinessOwner;

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      // {
      //   key: 'availability',
      //   title: 'Patient Diff',
      //   path: 'patientreconcile',
      //   tabContainerElem: () => {
      //     return <PatientReconcile />;
      //   },
      // },
      ...(showFoldApisTab
        ? [
            {
              key: 'foldapis',
              title: 'Fold APIs',
              path: 'foldapis',
              tabContainerElem() {
                return <FoldApisConfig />;
              },
            },
          ]
        : []),
      ...(showHintTab
        ? [
            {
              key: 'hint',
              title: 'Hint',
              path: 'hint',
              tabContainerElem() {
                return <HintConfig />;
              },
            },
          ]
        : []),
      ...(showSpruceTab
        ? [
            {
              key: 'spruce',
              title: 'Spruce',
              path: 'spruce',
              tabContainerElem() {
                return <SpruceConfig />;
              },
            },
          ]
        : []),
      ...(showElationTab
        ? [
            {
              key: 'elation',
              title: 'Elation',
              path: 'settings',
              tabContainerElem() {
                return <ElationConfig />;
              },
            },
          ]
        : []),
      ...(showConfig
        ? [
            {
              key: 'config',
              title: 'Configurations',
              path: 'access',
              tabContainerElem() {
                return <EHRConfig />;
              },
            },
          ]
        : []),

    ];
    return array;
  };

  return (
    <View>
      {/* <View style={styles.titleWrapper}>
        <TitleSubtitleView titleLabelId="Integration" />
      </View> */}
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          basePath={'/admin/integration/config'}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          contentTopMargin={0}
          canNavigate={true}
          showHeading
          bottomOffset={150}
          heading="integrationTitle"
        />
      </View>
    </View>
  );
};
export default IntegrationConfig;
