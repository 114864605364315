import  React,{useState} from 'react';
import {Modal} from 'antd';
import {Button, Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';

interface IDuplicateUsersInfoModal {
  isOpen: boolean;
  onClose: ()=> void;
  dataList: {
    name: string;
    email: string;
  }[]
}

const DuplicateUsersInfoModal = (props: IDuplicateUsersInfoModal) => {
  const { onClose, dataList, isOpen} = props;
  const intl = useIntl();
  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          onClose();
        }}
        closable={false}
        footer={null}
      >
        <View>
          <View>
            <Text size={'lgBold'}>
              {intl.formatMessage({
                id: 'usersExistInAccount',
              })}
            </Text>
            <Text style={{marginTop: 4}} size={'smNormal'} >
              {intl.formatMessage({
                id: 'existingUserInfo',
              })}
            </Text>
          </View>
          <View style={{marginTop: 24}}>
            {dataList?.map((item) => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 8,
                    flex: 1,
                    width: '100%'
                  }}
                >
                  <Text
                    size="smBold"
                    style={{minWidth: '50%'}}
                  >
                    {item.name}
                  </Text>
                  <Text
                    flex={0.5}
                    size="smMedium"
                    style={{minWidth: '50%', color: Colors.Custom.Gray500}}
                  >
                    {item.email}
                  </Text>
                </View>
              );
            })}
          </View>
          <Button.Group justifyContent={'flex-end'} style={{marginTop: 16}}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'okGotIt',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>
    </>
  );
};

export default DuplicateUsersInfoModal;
