import {getFormDataFromVisitorData} from '../../AddOrUpdateVisitor/AddOrUpdateVisitorUtils';

export const formateVisitorListData = (dataList: any, mlovData: any): any => {
  const leadList: any = [];
  dataList?.visitorContactView?.forEach((singleVisitorData: any) => {
    const singleVisitorTempData = getFormDataFromVisitorData(
      singleVisitorData,
      mlovData
    );
    // const fullName =
    //   singleVisitorTempData.firstName +
    //   ' ' +
    //   singleVisitorTempData.lastName;
    leadList.push({
      name: singleVisitorTempData?.name,
      nameData: {
        name: singleVisitorTempData?.name,
        email: singleVisitorTempData?.email || '',
      },
      email: singleVisitorTempData?.email || '',
      mobileNumber: singleVisitorTempData?.phone || '',
      age: singleVisitorTempData.age ? singleVisitorTempData.age + 'yr' : '',
      zipCode: singleVisitorTempData.zipCode || '',
      contactType: singleVisitorData?.contactType?.contactType || '',
      singleVisitorData: singleVisitorData,
      id: singleVisitorTempData.id,
    });
  });
  return leadList;
};
export const formateSupportingMemberData = (
  dataList: any,
  mlovData: any
): any => {
  const leadList: any = [];
  dataList?.contactRelations?.forEach((singleVisitorData: any) => {
    if (singleVisitorData.realatedContact) {
      const singleVisitorTempData = getFormDataFromVisitorData(
        singleVisitorData.realatedContact,
        mlovData
      );
      // const fullName =
      //   singleVisitorTempData.firstName +
      //   ' ' +
      //   singleVisitorTempData.lastName;
      leadList.push({
        name: singleVisitorTempData?.name,
        nameData: {
          name: singleVisitorTempData?.name,
          email: singleVisitorTempData?.email || '',
        },
        email: singleVisitorTempData?.email || '',
        mobileNumber: singleVisitorTempData?.phone || '',
        age: singleVisitorTempData.age ? singleVisitorTempData.age + 'yr' : '',
        zipCode: singleVisitorTempData.zipCode || '',
        contactType: singleVisitorData?.realatedContact?.contactType?.contactType || '',
        realatedContact: singleVisitorData?.realatedContact,
        id: singleVisitorTempData.id,
      });
    }
  });
  return leadList;
};

export const getEmailAddressFromPerson = (
  contactList: any,
  separator = ', '
): string => {
  let emailList: string[] = [];
  emailList = contactList.map((singleContactObj: any) => {
    return singleContactObj.value;
  });
  return emailList.join(separator);
};
