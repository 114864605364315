import {debounce} from 'lodash';
import {Text, View} from 'native-base';
import React, {memo, useEffect} from 'react';
import {Dimensions, FlatList} from 'react-native';
import {ITaskDistributionTable, ITaskDistributionWidget} from '../interface';
import {ToolTipView} from './ToolTipView';
import {getFormattedDataForTable, secondsToHM} from './UtilsConst';

const TaskDistributionWidget = (props: {
  widgetData: ITaskDistributionWidget;
  fetchMoreItems?: ()=> void,
  loadingView?: JSX.Element,
  isDrillDown?: boolean;
  isLoading?: boolean;
  isEndReached?: boolean;
}) => {
  const {widgetData, fetchMoreItems, isDrillDown, loadingView, isLoading, isEndReached} = props;
  const formattedData = getFormattedDataForTable(widgetData);
  const height = Dimensions.get('window').height * 0.69;

  const toolTipView = (singleRowData: ITaskDistributionTable)=> {
    return (
      <View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8}}>
          <Text size={'smSemibold'} color={'#12B76A'}>COMPLETED</Text>
          <Text size={'smSemibold'} color={'#12B76A'}>{singleRowData.completedTask}</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>ON TIME</Text>
          <Text size={'xsSemibold'} color={'#3A485F'}>{singleRowData.onTimeCompletedTask}</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>AFTER DUE DATE</Text>
          <Text size={'xsSemibold'} color={'#3A485F'}>{singleRowData.afterDueDateCompletedTask}</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>AVG. DELAY</Text>
          <Text size={'xsSemibold'} color={'#3A485F'}>{secondsToHM(singleRowData.completedTaskTime)}</Text>
        </View>
      </View>
    )
  }
  const handleEndReached = debounce(() => {
    if (!isLoading && !isEndReached) {
      fetchMoreItems?.();
    }
  }, 500);
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 0.5,
          borderColor: '#D0D6E1',
          borderStyle: 'solid',
          width: '100%',
        }}
      >
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '18%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          USERS
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '40%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          TASK COMPLETION
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '11%', paddingHorizontal: 12, paddingVertical: 9, paddingLeft: 20}}
        >
          TOTAL TASKS
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '9%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          PENDING
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '9%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          MISSED
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={{width: '12%', paddingHorizontal: 12, paddingVertical: 9}}
        >
          COMPLETED
        </Text>
      </View>
      <FlatList
          style={{
            maxHeight: isDrillDown ? height : 'auto'
          }}
          data={formattedData || []}
          renderItem={({item}: any) => (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                size={'smMedium'}
                style={{
                  width: '18%',
                  paddingHorizontal: 12,
                  paddingVertical: isDrillDown ? 8 : 4,
                }}
                lineBreakMode="clip"
              >
                {item?.name || '--'}
              </Text>
              <View style={{flexDirection: 'row', alignItems: 'center',width: '40%',paddingRight: 8}}>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={{
                  width: '24%',
                  paddingHorizontal: 12,
                  paddingVertical: 3,
                }}
              >
                {item.taskCompletedPercentage}%
              </Text>
              <View style={{width: '76%'}}>
                <div
                  style={{
                    height: 8,
                    borderRadius: 25,
                    backgroundColor: '#F6F7F8',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <div
                    style={{
                      height: 8,
                      backgroundColor: '#8DE3D4',
                      width: `${((item.completedTask/item.totalTask)*100).toFixed(2) || 0}%`,
                    }}
                  ></div>
                  <div
                    style={{
                      height: 8,
                      backgroundColor: '#BFD7F6',
                      width: `${((item.missedTask/item.totalTask)*100).toFixed(2)}%`,
                    }}
                  ></div>
                  <div
                    style={{
                      height: 8,
                      backgroundColor: '#80A4D5',
                      width: `${((item.pendingTask/item.totalTask)*100).toFixed(2)}%`,
                    }}
                  ></div>
                </div>
              </View>
              </View>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={{
                  width: '11%',
                  paddingHorizontal: 12,
                  paddingLeft: 20,
                  paddingVertical: 3,
                }}
              >
                {item.totalTask}
              </Text>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={{
                  width: '9%',
                  paddingHorizontal: 12,
                  paddingVertical: 3,
                }}
              >
                {item.pendingTask}
              </Text>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={{
                  width: '9%',
                  paddingHorizontal: 12,
                  paddingVertical: 3,
                }}
              >
                {item.missedTask}
              </Text>
              <ToolTipView overlay={toolTipView(item)}>
              <Text
                size={'smMedium'}
                color={'#8C5AE2'}
                style={{
                  width: '12%',
                  paddingHorizontal: 12,
                  paddingVertical: 3,
                }}
              >
                {item.completedTask}
              </Text>
              </ToolTipView>
            </View>
          )}
          keyExtractor={(item: any, index: number) =>
            `${item?.id}_item_${item.name || ''}_${index}`
          }
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.5}
          ListFooterComponent={loadingView}
        />
    </View>
  );
};

export default memo(TaskDistributionWidget);
