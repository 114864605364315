import { StyleSheet } from 'react-native';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  workflowGraphPopup: { margin: 0},
  titleWrapper: {
    marginHorizontal: 22,
    marginVertical: 12,
  },
  workflowListView: {
    width: 'full',
  },
  container: {},
  tableContainer: {
    ...baseMargin,
    overflow: 'hidden',
    display: 'flex',
  },
  shadow: {
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10,
  },
  shadowBoxWorkflowMainTable: {
    overflow: 'hidden',
    // marginLeft: 20,
    // marginRight: 20,
  },
  loadingView: {
    ...baseMargin,
  },
});

export const workflowListViewStyle = StyleSheet.create({
  loadingSpinner: {
    position: 'absolute',
    marginTop: '200px',
    marginLeft: '650px',
    zIndex: 1000,
  },
});

export const workFlowFilterTagStyle = StyleSheet.create({
  fiterBtnContainer: {
    borderRadius: 18,
    width: 140,
    justifyContent: 'flex-start',
    marginLeft: 16,
    alignItems: 'center',
    padding: 8,
  },
  selectedTagList: {
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 2,
    marginHorizontal: 2,
    marginVertical: 2,
    justifyContent: 'space-between',
  },
});

export const addOrUpdateWorkflowStyes = StyleSheet.create({
  loadingHStack: {
    space: 6,
    justifyContent: 'center',
  },
  loadingSpinner: {
    position: 'absolute',
    marginTop: '250px',
    marginLeft: '-50px',
    color: 'red',
    zIndex: 10000,
  },
  mainView: {
    backgroundColor: 'rgb(249,247,247)',
    width: 'full',
    height: 900,
  },
});

export const workflowFooterStyle = StyleSheet.create({
  footerFlex: {
    backgroundColor: '#ffff',
  },
  button: {
    marginTop: 10,
    marginBottom: 6,
  },
});

export const workflowHeaderStyle = StyleSheet.create({
  mainView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: '60px',
    alignItems: 'center',
    backgroundColor: '#ffff',
  },
  tab: {
    flexDirection: 'row',
    fontWeight: 'bold',
    width: 'full',
  },
  iconButton: {
    width: '6',
    height: '6',
    variant: 'PRIMARY',
    borderRadius: 50,
  },
  icon: {
    size: 'xs',
    color: 'white',
  },
  text: {
    marginLeft: '10px',
  },
});

export const workflowTriggerStyle = StyleSheet.create({
  conditionView: {
    height: 'container',
    marginTop: '5px',
    width: '100%'
  },
  mainView: {
    margin: '20px',
    backgroundColor: '#ffff',
    marginHorizontal: '100',
  },
  pressable: {
    borderColor: '#ddd5d5',
    marginHorizontal: '15px',
  },
  justifyCenter: {
    justifyContent: 'center',
    width: '100%'
  },
  workflowNameTextLabel: {
    // marginHorizontal: 15,
    // marginBottom: 5,
    // fontSize: 18,
    // fontWeight: 'bold',
    color: '#000000',
    fontSize: 20,
    fontWeight: '500',
    margin:'2%'
  },
  workflowNameInput: {
    // marginHorizontal: '15px',
    width:'100%'

  },
  workflowNameFlex: {
    alignItems: 'flex-start',
    marginTop: 15,
    width:'100%'
  },
  mainFlex: {
    height: 'container',
    // marginTop: 15,
    //alignItems: 'center',
    //justifyContent: 'center',
  },
  workflowSubtitle:{
    fontSize: 14,
    fontWeight: '400',
    color: '#000000',
    opacity: 0.3,
    lineHeight: 16.8,
    fontStyle: 'normal',
  }
});
