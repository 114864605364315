import {useLazyQuery} from '@apollo/client';
import {Skeleton, Table} from 'antd';
import {Spinner, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {getPracticeLocationDetails} from '../../../../../services/CommonService/AidBoxService';
import TeamQueries from '../../../../../services/Team/TeamQueries';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../../common/TableTopBar';
import {TableWrapper} from '../../../../common/TableWrapper';
import AddNewPracticeLocation from '../AddEditUser/AddNewPracticeLocation';
import {LocationDetails} from '../AddEditUser/interfaces';
import {TEAM_MEMBERS_VIEW_CODE} from '../AddEditUser/TeamMemberConst';
import {accountLocationColumn} from './helper';
import {IAccountLocations, ILocationData} from './interface';
import {getFormattedAccountLocationList} from './utils';

const AccountLocations = (props: IAccountLocations) => {
  const {onClose, searchString, selectedActionCode} = props;
  const accountUuid = getAccountUUID();
  const {height} = Dimensions.get('window');
  const finalHeight = height - 260;
  const [stateData, setStateData] = useState({
    locationsData: [] as ILocationData[],
    currentPage: 1,
    loading: false,
    total: 0,
    pageSize: 10,
    offset: 0,
    selectedViewCode: '',
    selectedLocationData: {} as any,
  });
  const [GetLocations, { loading: locationsLoading }] = useLazyQuery(TeamQueries.getLocationByAccount, {
    fetchPolicy: 'no-cache',
  });
  const fetchMappedLocations = async () => {
    try {
      const response = await getPracticeLocationDetails();
      const list = response?.data as LocationDetails[];
      setStateData((prev) => {
        return {
          ...prev,
          externalLocations: list,
        };
      });
    } catch (error) {
    }
  };
  const onActionPerformedByCode = (code: string, data?: any) => {
    switch (code) {
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
        onClose(TABLE_TOP_BAR_ACTION_CODES.CLOSE);
        setStateData({
          ...stateData,
          selectedViewCode: '',
          selectedLocationData: {},
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.EDIT_LOCATION:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: code,
            selectedLocationData: data,
          };
        });
        break;
      case COMMON_ACTION_CODES.ADD:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: TEAM_MEMBERS_VIEW_CODE.ADD_NEW_PRACTICE_LOCATION,
            selectedLocationData: {},
          };
        });
        break;
      case COMMON_ACTION_CODES.REFETCH:
        fetchMappedLocations();
        setStateData({
          ...stateData,
          selectedViewCode: '',
          selectedLocationData: {},
        });
        getLocationDataByAccount();
        break;
      default:
        break;
    }
  };

  const handlePageChange = (pageNo: number, pageSize: number) => {
    const offset = pageSize * (pageNo - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: pageNo,
        pageSize: pageSize,
        offset: offset,
      };
    });
  };

  const getLocationDataByAccount = async (offSet?: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const locationData = await GetLocations({
      variables: {
        accountUuid: accountUuid,
        limit: stateData.pageSize,
        offset: offSet == 0 ? offSet : stateData.offset,
        searchString: `%${searchString || ''}%`,
      },
    });
    if (locationData?.data?.accountLocations?.length) {
      const formattedData = getFormattedAccountLocationList(
        locationData?.data?.accountLocations
      );
      const total =
        locationData?.data?.accountLocationsAggregate?.aggregate?.count;
      props.updateContactCount?.(total);
      setStateData((prev) => {
        return {
          ...prev,
          locationsData: formattedData,
          total: total,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          locationsData: [],
          loading: false,
          total: 0,
        };
      });
      props.updateContactCount?.(0);
    }
  };

  useEffect(() => {
    getLocationDataByAccount();
  }, [stateData.currentPage, stateData.pageSize]);
  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offset: 0,
        };
      });
    } else {
      getLocationDataByAccount(0);
    }
  }, [searchString]);
  useEffect(() => {
    if (selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.NEW) {
      onActionPerformedByCode(COMMON_ACTION_CODES.ADD, '');
    } else {
      setStateData({
        ...stateData,
        selectedViewCode: '',
        selectedLocationData: {},
      });
    }
  }, [selectedActionCode]);
  return (
    <>
      <PageBodyContainer>
        <TableWrapper
          wrapperStyle={{alignItems: 'center'}}
          hideOnSinglePage={false}
          pagination={{
            current: stateData.currentPage,
            pageSize: stateData.pageSize,
            total: stateData.total,
            onChange: (currentPage, currentPageSize) => {
              handlePageChange(currentPage, currentPageSize);
            },
          }}
        >
          <Table
            scroll={{x: 700, y: finalHeight}}
            columns={accountLocationColumn(onActionPerformedByCode)}
            rowKey={(row: any) => `${row?.id}_saving_template`}
            loading={{
              spinning: stateData.loading || locationsLoading,
              indicator: (
                <VStack justifyContent="center" height={'100%'}>
                  <Spinner size={'lg'} />
                </VStack>
              ),
            }}
            dataSource={stateData.locationsData}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            pagination={false}
            locale={{
              emptyText:  stateData.loading && <Skeleton />,
            }}
          />
        </TableWrapper>
      </PageBodyContainer>
      {stateData.selectedViewCode ===
        TEAM_MEMBERS_VIEW_CODE.ADD_NEW_PRACTICE_LOCATION && (
          <AddNewPracticeLocation
            showDrawer={
              stateData.selectedViewCode ===
              TEAM_MEMBERS_VIEW_CODE.ADD_NEW_PRACTICE_LOCATION
            }
            onClose={() => {
              onActionPerformedByCode(COMMON_ACTION_CODES.REFETCH, {});
              onActionPerformedByCode(TABLE_TOP_BAR_ACTION_CODES.CLOSE);
            }}
          />
        )}
      {stateData.selectedViewCode ===
        TABLE_TOP_BAR_ACTION_CODES.EDIT_LOCATION && (
          <AddNewPracticeLocation
            isEdit
            showDrawer={stateData.selectedLocationData}
            selectedLocation={stateData.selectedLocationData || {}}
            onClose={() => {
              onActionPerformedByCode(COMMON_ACTION_CODES.REFETCH, {});
            }}
          />
        )}
    </>
  );
};

export default AccountLocations;
