import {StyleSheet} from 'react-native';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  loadingView: {
    ...baseMargin,
  },
  tablewrapper: {},
  container: {
    padding: 12,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});
