import {useQuery} from '@apollo/client';
import 'antd/dist/antd.variable.css';
import {Spinner, View} from 'native-base';

import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BodyContainer} from '../../components/BodyContainer';
import CommonDataProvider from '../../components/CommonDataProvider/CommonDataProvider';
import {setUserRoleSideBarCodesPermissionWise} from '../../components/RightSideContainer/UserAccess/UserAccessPermission';
import {getUserActionMasterData} from '../../components/RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getDefaultPath} from '../../components/SideMenuBar/SideBarHelper';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CLOUD_TELEPHONY_APOLLO_CONTEXT,
} from '../../constants/Configs';
import {CommonDataContext, ICommonData} from '../../context/CommonDataContext';
import {
  IAgent,
  IUserRoleCategoryResp,
  UserActionMasterData,
} from '../../Interfaces';
import {getEhrCapabilities} from '../../services/CommonService/AidBoxService';
import {MlovQueries, UserQueries} from '../../services/index';
import {IUserSettingsByCode} from '../../services/UserSettings/interfaces';
import * as UserSettingQueries from '../../services/UserSettings/UserSettingQueries';
import '../../styles/CSS/globalStyles.css';
import {ICTMlovRespData} from '../../utils/interfaces';
import {
  getCloudTelePhonyMlovByCategory,
  getMlovByCategory,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../utils/mlovUtils';
import {CommonWebSocket, initializeWebSocketConnection} from '../../utils/WebSocketUtils';
import {styles} from './Styles';
import {FilterDataContextProvider} from '../../context/FilterDataContext';
import {getAllAgentsList} from '../../services/ProfileImage/ProfileImage';
import {EventBus} from '../../utils/EventBus';
import {CUSTOM_MESSAGE_EVENT_CODES} from '../../constants/WebSocketConst';
import {MLOV_CATEGORY, ONLINE_STATUS} from '../../constants';
import {getUserUUID} from '../../utils/commonUtils';
import {USER_PREFERENCE_CODE} from '../../constants/MlovConst';

const BusinessStudio = () => {
  const [capabilities, setCapabilities] = useState<{
    loading: boolean;
    data: any[];
  }>({loading: true, data: []});
  const [userActionData, setUserActionData] = useState<UserActionMasterData[]>(
    []
  );
  const [state, setState] = React.useState({
    agents: [] as IAgent[],
    loading: false,
  });

  const userUuid = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const navigateToBusiness = useNavigate();
  const [isUserPermissionApply, setIsUserPermissionApply] = useState(false);
  const crmMlovData = useQuery(MlovQueries.GetAllMlovsWithCategory, {
    fetchPolicy: 'no-cache',
  });
  const careStudioMlovData = useQuery(MlovQueries.GetAllCareMlovsWithCategory, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const defaultContactTypes = useQuery(MlovQueries.GetAllDefaultContactTypes, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: commonData.userData?.accounts?.[0]?.uuid,
    },
  });
  const userSettingsApi = useQuery(UserSettingQueries.GetUserSettings, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      tenantId: commonData.userData?.accounts?.[0]?.uuid,
      userId: commonData.userData?.uuid,
    },
  });
  const cloudTelePhonyMlovs = useQuery<ICTMlovRespData>(
    MlovQueries.GetAllCloudTelePhonyMlovsWithCategory,
    {
      fetchPolicy: 'no-cache',
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
    }
  );

  const userRoleCategory = useQuery<IUserRoleCategoryResp>(
    UserQueries.GET_USER_ROLE_CATEGORIES,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const currentUserRoleApi = useQuery<AnimationPlaybackEvent>(
    UserQueries.CURRENT_USER_ROLE,
    {
      variables: {
        userId: commonData.userData?.id,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const userPermissionsApi = useQuery<any>(UserQueries.USER_PERMISSIONS, {
    variables: {
      accountUUID: commonData.userData?.accounts[0].uuid,
    },
    fetchPolicy: 'no-cache',
  });

  const formatUserRole = (currentUserRoleData: any) => {
    const currentUserRoles: any = [];
    if (currentUserRoleData) {
      currentUserRoleData.userRoles.forEach((data: any) => {
        currentUserRoles.push({
          code: data?.userRole?.userRole?.code,
          value: data?.userRole?.userRole?.value,
        });
      });
    }
    return currentUserRoles;
  };

  const formattedMlovData = getMlovByCategory(crmMlovData.data);
  const formattedCareStudioMlovData = getMlovByCategory(
    careStudioMlovData.data,
    commonData.userData?.accounts?.[0]?.uuid,
  );

  const formatedCtMlovData = getCloudTelePhonyMlovByCategory(
    cloudTelePhonyMlovs?.data || ({} as any)
  );
  const formattedCurrentUserRole = formatUserRole(currentUserRoleApi.data);
  const userPreferenceList = getMlovListFromCategory(
    formattedCareStudioMlovData,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );

  const getEhrCapability = () => {
    getEhrCapabilities(
      (response) => {
        setCapabilities({loading: false, data: response.data});
      },
      (error) => {

        setCapabilities({loading: false, data: []});
      }
    );
  };

  const getUserActionData = async () => {
    try {
      const response = await getUserActionMasterData();
      setUserActionData(response?.userActionMasters || []);
    } catch (error) {
      setUserActionData([]);
    }
  };

  const userSettings: IUserSettingsByCode = {};

  (userSettingsApi?.data?.defaultUserSettings || []).forEach(
    (defaultUserSetting: any) => {
      if (!defaultUserSetting) return;

      const id: string = defaultUserSetting.id;
      const code: string = defaultUserSetting.code;
      let value: string = defaultUserSetting.value;

      if (defaultUserSetting.userSettings?.[0]) {
        value = defaultUserSetting.userSettings[0].value;
      }

      userSettings[code] = {id, code, value};
    }
  );

  const getUserPreference = useQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUuid,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const allCommonData: ICommonData = {
    ...commonData,
    userSettings,
    userPermissions: userPermissionsApi?.data?.userPermissions,
    MLOV: formattedMlovData,
    currentUserRoles: formattedCurrentUserRole,
    CARE_STUDIO_MLOV: formattedCareStudioMlovData,
    CLOUD_TELEPHONY_MLOV: formatedCtMlovData,
    userRoleCategories: userRoleCategory.data?.userRoleCategories || [],
    ehrCapabilities: capabilities.data,
    userActionMasterData: userActionData,
    defaultContactTypes: defaultContactTypes.data,
    agents: state.agents || [],
    userPreferenceSetting: getUserPreference?.data
  };
  if (
    !userPermissionsApi.loading &&
    !currentUserRoleApi.loading &&
    !isUserPermissionApply
  ) {
    setUserRoleSideBarCodesPermissionWise(
      formattedCurrentUserRole,
      userPermissionsApi?.data?.userPermissions
    );
    setIsUserPermissionApply(true);
    navigateToBusiness(getDefaultPath(commonData.userData?.uuid || ''), {replace: true});
  }

  const getAgentList = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const accountId = commonData.userData?.accounts?.[0]?.id;
    try {
      const response = await getAllAgentsList(accountId);
      const agents = response.data || [];
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          agents: agents,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          agents: [],
        };
      });
    }
  };



  useEffect(() => {
    initializeWebSocketConnection(allCommonData.userData);
    getEhrCapability();
    getUserActionData();
    getAgentList();

  }, []);

  return (
    <View>
      {crmMlovData.loading ||
        careStudioMlovData.loading ||
        userRoleCategory.loading ||
        userPermissionsApi.loading ||
        currentUserRoleApi.loading ||
        capabilities.loading ||
        state.loading ||
        userSettingsApi.loading ||
        !isUserPermissionApply ? (
        <View style={styles.loaderContainer}>
          <Spinner size={'lg'} />
        </View>
      ) : (
        <View>
          <View style={styles.bodyContainer}>
            <CommonDataProvider allCommonData={allCommonData}>
              <FilterDataContextProvider>
                <BodyContainer />
              </FilterDataContextProvider>
            </CommonDataProvider>
          </View>
        </View>
      )}
    </View>
  );
};

export default BusinessStudio;
