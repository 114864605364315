import React from 'react';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {getUserData} from '../../../../../../utils/commonUtils';
import {Text, TouchableOpacity, View} from 'react-native';
import {Colors} from '../../../../../../styles';
import {DATE_FORMATS} from '../../../../../../constants/StringConst';
import moment from 'moment';
import {ISingleMessageThread} from './interface';
import MsgHighlighter from '../../ConversationContainer/ConversationList/MsgHighlighter';
import {
  getMessageContent,
  getMessageSenderName,
  getMsgBoxColor,
} from './SearchMessageThreadUtils';
import {SEARCH_MSG_CONST} from '../MsgConst';

const SingleMessageThread = (props: ISingleMessageThread) => {
  const currentUserData = getUserData();
  const messageType = props?.singleMsgData?.messageType;
  const messageBoxColor = getMsgBoxColor(
    props?.singleMsgData?.private,
    messageType
  );

  const getMsgHeader = (msgData: any) => {
    return (
      <Stack
        direction="row"
        style={{
          borderBottomWidth: 1,
          borderBottomColor: Colors.Custom.Gray200,
          flex: 1,
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            flex: 0.56,
            fontSize: 14,
            color: messageType === 1 ? 'white' : Colors.Custom.Gray700,
            fontWeight: '700',
            paddingRight: 4,
            paddingBottom: 4,
          }}
        >
          {getMessageSenderName(
            msgData,
            currentUserData?.uuid,
            currentUserData?.id
          )}
        </Text>
        <Text
          style={{
            flex: 0.44,
            fontSize: 12,
            color: messageType === 1 ? 'white' : Colors.Custom.Gray400,
            fontWeight: '400',
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            justifyContent: 'flex-end',
            paddingBottom: 4,
          }}
        >
          {moment(msgData?.updatedAt || msgData?.createdAt).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)}
        </Text>
      </Stack>
    );
  };

  return (
    <View style={{paddingHorizontal: 16}}>
      <TouchableOpacity
        onPress={() => {
          props?.onActionPerformed(props?.singleMsgData);
        }}
      >
        <Stack
          direction="column"
          style={{
            marginVertical: 8,
            borderWidth: 1,
            borderColor: messageType === 1 ? 'white' : Colors.Custom.Gray200,
            borderRadius: 8,
            paddingHorizontal: 16,
            paddingVertical: 10,
            backgroundColor: messageBoxColor,
          }}
        >
          <Stack direction="column">{getMsgHeader(props?.singleMsgData)}</Stack>
          <Stack
            direction="column"
            style={{paddingHorizontal: 16, paddingVertical: 10}}
          >
            <Text
              style={{
                color: messageType === 1 ? 'white' : Colors.Custom.Gray900,
              }}
            >
              <MsgHighlighter
                searchString={props?.searchString}
                contentString={getMessageContent(
                  SEARCH_MSG_CONST.SEARCH_MSG_LENGTH,
                  props?.searchString,
                  props?.singleMsgData?.content
                )}
              />
            </Text>
          </Stack>
        </Stack>
      </TouchableOpacity>
    </View>
  );
};

export default SingleMessageThread;
