import { MENTION_READ_STATUS } from "../../../../../constants";
import { CHANNEL_TYPE_CODE } from "../ConversationConst";

/* eslint-disable @typescript-eslint/no-var-requires */
export const inboxType = [
  {
    code: 'All',
    value: 'All',
  },
  {
    code: 'Chats',
    value: 'Chats',
  },
  {
    code: 'SMS',
    value: 'SMS',
  },
  {
    code: 'Emails',
    value: 'Emails',
  },
];

export const CONTACT_TYPE_CODES = {
  PATIENT: 'PATIENT',
  PROSPECT: 'PROSPECT',
};

export const contactType = [
  {
    code: 'ALL',
    value: 'ALL',
    label: 'All',
    isSelected: true,
    isDefault: true,
  },
  {
    code: CONTACT_TYPE_CODES.PATIENT,
    value: 'PATIENT',
    label: 'Patient',
    isSelected: false,
  },
  {
    code: CONTACT_TYPE_CODES.PROSPECT,
    value: 'PROSPECT',
    label: 'Prospect',
    isSelected: false,
  },
];

export const readType = [
  {
    code: 'ALL',
    value: 'ALL',
    label: 'All',
    isSelected: true,
    isDefault: true,
  },
  {
    code: 'UNREAD',
    value: 'UNREAD',
    label: 'Unread',
    isSelected: false,
  },
  {
    code: 'READ',
    value: 'READ',
    label: 'Read',
    isSelected: false,
  },
];

export const dateType = [
  {
    code: 'ALL',
    value: 'ALL',
    label: 'All',
    isSelected: true,
    isDefault: true,
  },
  {
    code: 'TODAY',
    value: 'TODAY',
    label: 'Today',
    isSelected: false,
  },
  {
    code: 'YESTERDAY',
    value: 'YESTERDAY',
    label: 'Yesterday',
    isSelected: false,
  },
  {
    code: 'LAST_WEEK',
    value: 'LAST_WEEK',
    label: 'Last Week',
    isSelected: false,
  },
  {
    code: 'LAST_MONTH',
    value: 'LAST_MONTH',
    label: 'Last Month',
    isSelected: false,
  },
];

export const mentionReadStatusType = [
  {
    code: MENTION_READ_STATUS.UNREAD,
    value: 'Unread',
    label: 'Unread',
    isSelected: false,
  },
  {
    code: MENTION_READ_STATUS.READ,
    value: 'Read',
    label: 'Read',
    isSelected: false,
  },
  {
    code: MENTION_READ_STATUS.ALL,
    value: 'all',
    label: 'All',
    isDefault: true,
    isSelected: true,
  },
];

export const mentionData = [
  {
    code: 'Mentions',
    value: 'Mentions',
    label: '@Mentions',
    isSelected: false,
  },
];

export const assignmentData = [
  {
    code: 'ALL',
    value: 'ALL',
    label: 'All',
    isSelected: true,
    isDefault: true,
  },
  {code: 'MINE', value: 'MINE', label: 'Assigned to Me', isSelected: false},
  {
    code: 'UN_ASSIGNED',
    value: 'UN_ASSIGNED',
    label: 'Unassigned',
    isSelected: false,
  },
];

export const statusData = [
  {
    code: 'All',
    value: 'All',
    label: 'All',
  },
  {
    code: 'Open Chat',
    value: 'Open Chat',
    label: 'Open Chat',
  },
  {code: 'Close Chat', value: 'Close Chat', label: 'Close Chat'},
  {
    code: 'Pending',
    value: 'Pending',
    label: 'Pending',
  },
];

export const MessageType = [
  {
    code: 'Personal',
    value: 'Personal',
    label: 'Personal',
  },
  {
    code: 'Group',
    value: 'Group',
    label: 'Group',
  },

  {
    code: 'Broadcast Group',
    value: 'Broadcast Group',
    label: 'Broadcast Group',
  },
];

export const directions = [
  {
    code: 'inbound',
    value: 'inbound',
    label: 'Incoming',
    isSelected: false,
  },
  {
    code: 'Outgoing Dial',
    value: 'Outgoing Dial',
    label: 'Outgoing',
    isSelected: false,
  },
];
export const outOfOfficeHours = [
  {
    code: 'false',
    value: 'false',
    label: 'In Office Hours',
    isSelected: false,
  },
  {
    code: 'true',
    value: 'true',
    label: 'Out Of Office Hours',
    isSelected: false,
  },
];
export const callStatus = [
  {
    code: 'no-answer',
    value: 'no-answer',
    label: 'Missed Call',
    isSelected: false,
  },
];
export const PHONE_TYPE_CONST = {
  MY_PHONE_NUMBER: 'myPhoneNumber',
  SOFT_PHONE_NUMBER: 'softPhoneNumber',
};

export const phoneTypes = [
  {
    code: PHONE_TYPE_CONST.MY_PHONE_NUMBER,
    value: '',
    label: 'My Phone Number',
    isSelected: false,
  },
  {
    code: PHONE_TYPE_CONST.SOFT_PHONE_NUMBER,
    value: '',
    label: 'My Soft Phone',
    isSelected: false,
  },
];

export const TimeType= {
  timeAgoFullDate: 'TimeAgoFullDate',
}

export const assignStatusValue = {
  ARCHIVED: 1,
  OPEN: 0,
  PENDING: 2,
};

export const CONVERSATION_STORAGE_KEY = {
  SMS_INBOX_LOCAL_KEY: 'SMS_INBOX_LOCAL_KEY',
  EMAIL_INBOX_LOCAL_KEY: 'EMAIL_INBOX_LOCAL_KEY',
};

export const assignmentStatusData = [
  {
    code: 'ALL',
    value: 'All',
    label: 'All',
    isSelected: false,
  },
  {
    code: 'OPEN',
    value: assignStatusValue.OPEN,
    label: 'Open',
    isSelected: true,
    isDefault: true,
  },
  // {
  //   code: 'PENDING',
  //   value: 2,
  //   label: 'Pending',
  //   isSelected: false
  // },
  {
    code: 'RESOLVED',
    value: assignStatusValue.ARCHIVED,
    label: 'Archived',
    isSelected: false,
  },
];

export const patientNotLoggedInError = 'Patient doesn’t seem to have downloaded or logged in to patient app. May not be able to see your messages.'

export const inbox = require('../../../../../assets/images/inbox.png');
export const contact = require('../../../../../assets/images/contact.png');
export const date = require('../../../../../assets/images/date.png');
export const mention = require('../../../../../assets/images/mention.png');
export const status = require('../../../../../assets/images/status.png');
export const message = require('../../../../../assets/images/chat.png');
export const assignment = require('../../../../../assets/images/assignment.png');

export const INBOX_CHANNEL_LIST : string[] = [
  CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
  CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
];
