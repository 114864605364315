import { Pressable, Text, View } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getBusinessStudioRoutes } from '../../../routes/BusinessStudioRoutes';
import { isChildAccount, isMasterAccount, IS_FEATURE_FLAG_ENABLED, isEnableCareProgram } from '../../../utils/commonUtils';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import AccountTabsView from '../AccountTabsView/AccountTabsView';
import PatientsAdmin from '../Contacts/PatientsAdminPage/PatientsAdmin';
import WorkflowAdmin from '../Contacts/WorkflowAdminPage/WorkflowAdmin';
import { ContentManagement } from '../ContentManagement';
import { styles } from '../ContentManagement/ContentManagementStyle';
import { AddOrUpdateEmailComponent } from '../ContentManagement/EmailComponents/AddOrUpdateEmailComponent';
import {
  EmailTemplateCreator,
  EmailTemplateEditor
} from '../ContentManagement/EmailTemplates/CreateOrEditTemplate';
import { EmailHtmlEditorMainView } from '../ContentManagement/EmailTemplates/EmailHtmlEditor';
import { PatientEducation } from '../ContentManagement/PatientEducation';
import FormAnalytics from '../Forms/Analytics';
import AddOrUpdateForm from '../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateForm';
import FormResponsesList from '../Forms/FormBuilderWidget/FormResponses/FormResponsesList';
import PendingForms from '../Forms/FormBuilderWidget/PendingForms/PendingForms';

import { IntegrationConfig } from '../Integration';
import AddOrUpdateJourney from '../Journeys/AddOrUpdateJourney/AddOrUpdateJourney';
import PatientPortalView from '../PatientPortal/PatientPortalView';
import { Invoices } from '../Sales/Invoices/Invoices';
import MembershipAndSubscriptions from '../Sales/MembershipAndSubscriptions/MembershipAndSubscriptions';
import MembershipProductDetailMainView from '../Sales/MembershipAndSubscriptions/MembershipProducts/MembershipProductDetailMainView';
import TaxonomiesDeatailView from '../Sales/ProductsAndServices/Categories/TaxonomiesDetailView/TaxonomiesDeatailView';
import SubscriptionOrderDetailView from '../Sales/ProductsAndServices/Orders/OrderDetailView/MembershipOrderDetailView/SubscriptionOrderDetailView';
import ProductDetailMainView from '../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailMainView';
import ProductWidgetView from '../Sales/ProductsAndServices/Products/ProductWidgetView/ProductWidgetView';
import ProductsAndServices from '../Sales/ProductsAndServices/ProductsAndServices';
import SchedulingConfig from '../Scheduling/SchedulingConfig';
import TaskManagementView from '../TaskManagment/TaskManagementView';
import AdminCallSettings from '../TeamInbox/AdminCallSettings';
import AdminCommunication from '../TeamInbox/AdminCommunication';
import {JourneyAnalytics} from '../Journeys/Analytics';
import { IntegrationCreate } from '../TeamInbox/Integrations/IntegrationCreate/IntegrationCreate';
import { IntegrationEditWidget } from '../TeamInbox/Integrations/IntegrationEdit/IntegrationEditWidget';
import {
  USER_ACCESS_PERMISSION,
  getUserPermissionByEntityAndActionCode
} from '../UserAccess/UserAccessPermission';
import { SettingMenuObj } from './interfaces';
import CostTemplatesMainView from '../Analytics/EmployerAnalytics/CostTemplates/CostTemplatesMainView';
import TemplateDetailView from '../Analytics/EmployerAnalytics/CostTemplates/TemplateDetailView/TemplateDetailView';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import CareProgramBillingTabsView from '../CareManagementBilling/CareProgramBillingTabsView';
import WearablesSettingsView from '../WearableDataIngest/WearablesSettingsView';

const Setting = () => {
  const {height} = Dimensions.get('window');
  const intl = useIntl();
  const [selectedMenu, setSelectedMenu] = React.useState(0);
  const [sideComponent, setSideComponent] = useState(<></>);
  const [searchString, setSearchString] = useState('');
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const currentUserRoles = mlovData?.currentUserRoles
  const isBusinessOwner = currentUserRoles.some((role) => {
    return USER_ROLE_CODES.BUSINESS_OWNER === role.code;
  });
  const isMasterAccountFlag = isMasterAccount();
  const isChildAccountFlag = isChildAccount();
  const location = useLocation();
  const navigation = useNavigate();

  const useCurrentPath = () => {
    const [{route}]: any = matchRoutes(getBusinessStudioRoutes(), location);

    return route.path;
  };

  const pathPattern: string = useCurrentPath();
  const showMembershipTab =
    IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP') &&
    getUserPermissionByEntityAndActionCode(
      USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      MAIN_MENU_CODES.MEMBERSHIPS
    );
  const showCostTemplateTab = !isAccountConfigEnabled(CONFIG_CODES.DISABLE_ANALYTICS) &&
                              isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API)

  const showPatientsTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.ADMIN_PATIENTS
  );

  const showCalendarTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.SCHEDULE
  );

  const showTasksTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.TASK_MANAGEMENT
  );

  const showMessagesTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.COMMUNICATION
  );

  const showCallsTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );

  const showLeadsTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.WEB_WIDGETS
  );
  const showIntegrationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.INTEGRATION_CONFIG
  );
  const showTemplatesTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CONTENT_MANAGEMENT
  );
  const showFormsTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.FORMS
  );
  const showJourneyTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CARE_JOURNEY
  );
  const showAccountsTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.ACCOUNT_SETTINGS
  );
  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );
  const showBillingTab = isCareProgramEnabled;
  const showWearablesTab = isBusinessOwner;

  const settingMenu: SettingMenuObj[] = isMasterAccountFlag ?
          [
            ...(showCostTemplateTab ?[{
              title: 'costTemplate',
              defaultPath: '/admin/costTemplate/visitSaving',
              routes: [
                {
                  pathPattern: '/admin/costTemplate/:tabName',
                  component: <CostTemplatesMainView />,
                },
                {
                  pathPattern:
                    '/admin/costTemplate/:tabName/:costType/detail/:id',
                  component: <TemplateDetailView />,
                },
              ],
            }] : []),
            {
              title: 'account',
              defaultPath :'/admin/account/teamMembers',
              routes:[{
                pathPattern: '/admin/account/teamMembers',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/accessControl',
                component: <AccountTabsView />,
              }]
            },
          ]  : [
    ...(showPatientsTab
      ? [
          {
            title: 'patientsLeads',
            defaultPath: '/admin/patients/tags',
            routes: [
              {
                pathPattern: '/admin/patients/:tabName',
                component: <PatientsAdmin />,
              },
            ],
          },
        ]
      : []),
    ...(showCalendarTab
      ? [
          {
            title: 'calendar',
            defaultPath: '/admin/schedule/availability',
            routes: [
              {
                pathPattern: '/admin/schedule/:tabName',
                component: <SchedulingConfig />,
              },
            ],
          },
        ]
      : []),
    ...(showTasksTab
      ? [
          {
            title: 'tasks',
            defaultPath: '/admin/taskManagement/task_pool',
            routes: [
              {
                pathPattern: '/admin/taskManagement/:tabName',
                component: <TaskManagementView />,
              },
            ],
          },
        ]
      : []),
    ...(showMessagesTab
      ? [
          {
            title: 'messages',
            defaultPath: '/admin/communication/inbox',
            routes: [
              {
                pathPattern: '/admin/communication/:tabName',
                component: <AdminCommunication />,
              },
              {
                pathPattern: '/admin/communication/inbox/create',
                component: <IntegrationCreate />,
              },
              {
                pathPattern: '/admin/communication/inbox/edit/:channel_id/:id',
                component: <IntegrationEditWidget />,
              },
            ],
          },
        ]
      : []),
    ...(showCallsTab
      ? [
          {
            title: 'call',
            defaultPath: '/admin/communication/number',
            routes: [
              {
                pathPattern: '/admin/communication/ivr',
                component: <AdminCallSettings />,
              },
              {
                pathPattern: '/admin/communication/number',
                component: <AdminCallSettings />,
              },
              {
                pathPattern: '/admin/communication/communication_types',
                component: <AdminCallSettings />,
              },
              {
                pathPattern: '/admin/communication/video_call',
                component: <AdminCallSettings />,
              },
            ],
          },
        ]
      : []),
    ...(showLeadsTab
      ? [
          {
            title: 'CRM Widgets',
            defaultPath: '/admin/webWidgets',
            routes: [
              {
                pathPattern: '/admin/webWidgets',
                component: <PatientPortalView />,
              },
            ],
          },
        ]
      : []),
    ...(showIntegrationTab
      ? [
          {
            title: 'integrations',
            defaultPath: '/admin/integration/config',
            routes: [
              {
                pathPattern: '/admin/integration/config/:tabName',
                component: <IntegrationConfig />,
              },
              {
                pathPattern: '/admin/integration/config',
                component: <IntegrationConfig />,
              },
            ],
          },
        ]
      : []),
      ...(showWearablesTab
        ? [
            {
              title: 'wearables',
              defaultPath: '/admin/wearables/accessConfiguration',
              routes: [
                {
                  pathPattern: '/admin/wearables/accessConfiguration',
                  component: <WearablesSettingsView />,
                },
              ],
            },
          ]
        : []),
    ...(showTemplatesTab
      ? [
          {
            title: 'content',
            defaultPath: '/admin/contentManagement',
            routes: [
              {
                pathPattern: '/admin/contentManagement',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/emailTemplates',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/emailComponent',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/mediaLibrary',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/patientEducation',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/pushNotifications',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/smsTemplates',
                component: <ContentManagement />,
              },
              {
                pathPattern:
                  '/admin/contentManagement/emailTemplates/editor/:id',
                component: <EmailTemplateEditor shouldHideTitle={true} />,
              },
              {
                pathPattern: '/admin/contentManagement/emailComponent/edit/:id',
                component: <AddOrUpdateEmailComponent />,
              },
              {
                pathPattern: '/admin/contentManagement/emailComponent/create',
                component: <AddOrUpdateEmailComponent />,
              },
              {
                pathPattern: '/admin/contentManagement/emailTemplates/create',
                component: <EmailTemplateCreator shouldHideTitle={true} />,
              },
              {
                pathPattern: '/admin/contentManagement/emailTemplates/html',
                component: <EmailHtmlEditorMainView />,
              },
              {
                pathPattern: '/admin/contentManagement/emailTemplates/html/:id',
                component: <EmailHtmlEditorMainView />,
              },
              {
                pathPattern: '/admin/contentManagement/patientEducation/create',
                component: <PatientEducation isHideTopTab={true} />,
              },
              {
                pathPattern: '/admin/contentManagement/patientEducation/:id',
                component: <PatientEducation isHideTopTab={true} />,
              },
              {
                pathPattern: '/admin/contentManagement/forms',
                component: <ContentManagement />,
              },
              {
                pathPattern: '/admin/contentManagement/forms/create',
                component: <AddOrUpdateForm />,
              },
              {
                pathPattern: '/admin/contentManagement/forms/update',
                component: <AddOrUpdateForm />,
              },
              {
                pathPattern: '/admin/contentManagement/forms/responses',
                component: <FormResponsesList />,
              },
              {
                pathPattern: '/admin/contentManagement/forms/pending',
                component: <PendingForms />
              },
              {
                pathPattern: '/admin/contentManagement/forms/analytics',
                component: <FormAnalytics />
              },
              {
                pathPattern: '/admin/contentManagement/cannedResponse',
                component: <ContentManagement />
              },
              {
                pathPattern: '/admin/contentManagement/llmTemplates',
                component: <ContentManagement />
              },
            ],
          },
        ]
      : []),

    // ...(showFormsTab
    //   ? [
    //       {
    //         title: 'forms',
    //         defaultPath: '/admin/forms/list',
    //         routes: [
    //           {
    //             pathPattern: '/admin/forms/:tabName',
    //             component: <FormsScreen />,
    //           },
    //           {
    //             pathPattern: '/admin/forms/create',
    //             component: <AddOrUpdateForm />,
    //           },
    //           {
    //             pathPattern: '/admin/forms/update',
    //             component: <AddOrUpdateForm />,
    //           },
    //           {
    //             pathPattern: '/admin/forms/responses',
    //             component: <FormResponsesList />,
    //           },
    //         ],
    //       },
    //     ]
    //   : []),

    ...(showJourneyTab
      ? [
          {
            title: 'journey',
            defaultPath: '/admin/commerce/journeys/journeys',
            routes: [
              {
                pathPattern: '/admin/commerce/:type',
                component: <ProductsAndServices />,
              },
              // {
              //   pathPattern: '/admin/commerce/:type/order/:id',
              //   component: <SubscriptionOrderDetailView />,
              // },
              {
                pathPattern: '/admin/commerce/:type/:tabName',
                component: <ProductsAndServices />,
              },
              // {
              //   pathPattern: '/admin/commerce/:type/orders/payment/:number',
              //   component: <OrderDetailView />,
              // },
              {
                pathPattern: '/admin/commerce/:type/product/:id',
                component: <ProductDetailMainView />,
              },
              {
                pathPattern: '/admin/commerce/:type/product/createWidget',
                component: <ProductWidgetView />,
              },
              {
                pathPattern: '/admin/commerce/:type/taxonomy/:taxonmoyId',
                component: <TaxonomiesDeatailView />,
              },
              {
                pathPattern: '/admin/commerce/:type/product/:id/journey/create',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern:
                  '/admin/commerce/:type/product/:id/journey/:careJourneyId/clone/:clone',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern:
                  '/admin/commerce/:type/product/:id/journey/:careJourneyId',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern: '/admin/commerce/:type/product/:id/journey/create',
                component: <AddOrUpdateJourney />,
              },
              {
                pathPattern:
                  '/admin/commerce/:type/product/:id/journey/:careJourneyId',
                component: <AddOrUpdateJourney />,
              },

            ],
          },
        ]
      : []),

    ...(showAutomationTab
      ? [
          {
            title: 'Automations',
            defaultPath: '/admin/workflow/all',
            routes: [
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/all',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/patients',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/communication',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/other',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/microAutomation',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/schedule',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/forms',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/journey',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/labs',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/order',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/databaseTrigger'
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/note',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/tasks',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/imaging-report',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/patient-referral',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/patient-case',
              },
              {
                component: <WorkflowAdmin />,
                pathPattern: '/admin/workflow/care-plan',
              },
            ],
          },
        ]
      : []),
      ...(showCostTemplateTab && !isChildAccountFlag ? [
            {
              title: 'costTemplate',
              defaultPath: '/admin/costTemplate/visitSaving',
              routes: [
                {
                  pathPattern: '/admin/costTemplate/:tabName',
                  component: <CostTemplatesMainView />,
                },
                {
                  pathPattern:
                    '/admin/costTemplate/:tabName/:costType/detail/:id',
                  component: <TemplateDetailView />,
                },
              ],
            },
          ]
        : []),
    ...(showMembershipTab
      ? [
          {
            title: 'memberships',
            defaultPath: '/admin/membership/orders',
            routes: [
              {
                pathPattern: '/admin/membership',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/orders',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/products',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/paymentSetup',
                component: <MembershipAndSubscriptions />,
              },
              {
                pathPattern: '/admin/membership/products/:id',
                component: <MembershipProductDetailMainView />,
              },
              {
                pathPattern: '/admin/membership/order/:id',
                component: <SubscriptionOrderDetailView />,
              },
            ],
          },
        ]
      : []),

    // {
    //   title: 'billing',
    //   defaultPath: '/admin/billing',
    //   routes: [
    //     {
    //       pathPattern: '/admin/billing',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/orders',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/plans',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/products',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/paymentSetup',
    //       component: <Billing />,
    //     },
    //     {
    //       pathPattern: '/admin/billing/order/:id',
    //       component: <BillingOrderDetailView />,
    //     },
    //   ],
    // },
    ...(showAccountsTab
      ? [
          {
            title: 'account',
            defaultPath: !isChildAccountFlag ?
            '/admin/account/teamMembers' :
            '/admin/account/locations' ,
            routes: [
              ...(!isChildAccountFlag
                ? [{
                  pathPattern: '/admin/account/teamMembers',
                  component: <AccountTabsView />,
                },
                {
                  pathPattern: '/admin/account/accessControl',
                  component: <AccountTabsView />,
                },
                ]
              : []),
              {
                pathPattern: '/admin/account/locations',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/logs',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/holidays',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/mergedOrDelayed',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/phoneWhiteList',
                component: <AccountTabsView />,
              },
              {
                pathPattern: '/admin/account/emailWhiteList',
                component: <AccountTabsView />,
              },

            ],
          },
        ]
      : []),
    ...(showBillingTab ? [
      {
        title: 'CareProgramBilling',
        defaultPath: '/admin/CareProgramBilling/billable',
        routes: [
          {
            pathPattern: '/admin/CareProgramBilling/billable',
            component: <CareProgramBillingTabsView />,
          },
          {
            pathPattern: '/admin/CareProgramBilling/billingHistory',
            component: <CareProgramBillingTabsView />,
          }
        ],
      },
    ]
    : []),

      // ...(isBusinessOwner
      //   ? [
      //       {
      //         title: 'billing',
      //         defaultPath: '/admin/invoice',
      //         routes: [
      //           {
      //             pathPattern: '/admin/invoice',
      //             component: <Invoices />,
      //           },
      //         ],
      //       },
      //     ]
      //   : []),
  ];

  useEffect(() => {
    let sideComponent = settingMenu[0].routes[0].component;
    let selectedMenuIndex = 0;

    settingMenu.forEach((menu, index) => {
      menu.routes.forEach((route) => {
        if (route.pathPattern == pathPattern) {
          sideComponent = route.component;
          selectedMenuIndex = index;
        }
      });
    });

    setSelectedMenu(selectedMenuIndex);
    setSideComponent(sideComponent);
  }, [location.pathname]);

  return (
    <View>
      <View style={styles.titleWrapper}>
        <TitleSubtitleView titleLabelId="Integration" />
      </View>
      <View
        style={{
          // padding: 16,
          display: 'flex',
          flexDirection: 'row',
          height: height - 60,
        }}
      >
        <View
          style={{
            width: 200,
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#D6D6D6',
            borderTopWidth: 0,
            overflow: 'scroll',
            borderBottomWidth: 0,
            height: height - 60,
          }}
        >
          {/* <View style={{padding: 10}}>
            <SearchBar
              onChange={(value: string) => {
                const searchString = value || '';
                setSearchString(searchString);
              }}
            />
          </View> */}
          {settingMenu
            ?.filter((menuItem) => {
              if (!searchString || !searchString?.trim?.()) {
                return true;
              }
              if (
                intl
                  .formatMessage({id: menuItem.title})
                  ?.toLowerCase()
                  ?.includes(searchString?.toLowerCase().trim())
              ) {
                return true;
              } else {
                return false;
              }
            })
            ?.map((menuItem, index) => {
              return (
                <Pressable
                  onPress={() => {
                    navigation(menuItem.defaultPath);
                  }}
                  style={{
                    borderLeftWidth: selectedMenu == index ? 5 : 0,
                    borderLeftColor: '#7F56D9',
                    borderStyle: 'solid',
                    borderTopWidth: !index ? 1 : 0,
                    borderBottomWidth: 1,
                    borderTopColor: '#EEEEEE',
                    borderBottomColor: '#EEEEEE',
                    paddingLeft: 25,
                    paddingVertical: 15,
                    backgroundColor: selectedMenu == index ? '#7F56D920' : '',
                  }}
                  _hover={{
                    bg: '#7F56D920',
                  }}
                >
                  <Text
                    style={{
                      color: selectedMenu == index ? '#7F56D9' : '#000000',
                    }}
                    size="mdNormal"
                  >
                    {intl.formatMessage({id: menuItem.title})}
                  </Text>
                </Pressable>
              );
            })}
        </View>
        <View
          style={{
            height: height - 90,
            flex: 1,
            paddingVertical: 2,
            // marginLeft: 16,
            // backgroundColor: '#ffffff',
            // borderWidth: 1,
            // borderStyle: 'solid',
            // borderColor: '#D6D6D6',
            // borderRadius: 10,
            // overflow: 'scroll',
          }}
        >
          {sideComponent}
        </View>
      </View>
    </View>
  );
};
export default Setting;
