import {TimePicker} from 'antd';
import {FormControl, View} from 'native-base';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import './ModalActionStyle.css'

export const ModalActionTimePicker = (props: any) => {
  const {
    value,
    format,
    onChange,
    customStyle,
    isRequired,
    errors,
    isInvalid,
    label,
    errorsText,
    isHideOkButton,
    onSelect,
    minuteStep
  } = props;
  const getWidth = () => {
    if (customStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };
  return (
    <View flex={customStyle?.flex}>
      <FormControl isRequired={isRequired} isInvalid={errors || isInvalid}>
        {label && (
          <FormControl.Label marginLeft={3}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
              }}
              textLocalId={label}
            />
          </FormControl.Label>
        )}
        <TimePicker
          value={value}
          format={format}
          popupClassName={isHideOkButton ? 'hide-ok-button' : undefined}
          onChange={(date: any) => {
            typeof onChange === 'function' && onChange(date);
          }}
          onSelect={(value) => {
            typeof onSelect === 'function' && onSelect(value);
          }}
          style={{
            height: customStyle?.height ? customStyle.height : 44,
            width: customStyle?.width ? customStyle.width : getWidth(),
            borderRadius: customStyle?.borderRadius
              ? customStyle.borderRadiu
              : 8,
            borderColor:
              errors || isInvalid
                ? Colors.Custom.ErrorColor
                : Colors.Custom.BorderColor,
            marginRight: customStyle?.marginRight
              ? customStyle?.marginRight
              : 0,
            marginTop: customStyle?.marginTop
              ? customStyle?.marginTop
              : 0,  
          }}
          {...(minuteStep !== undefined ? {minuteStep: minuteStep} : undefined)}
        />
        {errors && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {errorsText}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </View>
  );
};
