import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../constants/Configs';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {CloudTelephonyQueries, UserQueries} from '../../../services';
import {getAccountId, getAccountUUID} from '../../../utils/commonUtils';
import {getVirtualNumberFormattedList} from '../../RightSideContainer/CloudTelephony/CloudTelephonyUtils';
import {IVirtualPhoneNumberResp} from '../../RightSideContainer/CloudTelephony/interfaces';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import {IIVirtualNumberSearch} from './interface';

const VirtualPhoneListSearch = (props:IIVirtualNumberSearch)=> {
  const {onSelection} = props;
  const accountId = getAccountId();
  const accountUuid = getAccountUUID()
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const assigneesTypeList = mlovData['VirtualNumberAssignee'] || [];
  const [stateData, setStateData] = useState({
    tableListData: [] as any,
    loading: false,
    selectedValue: ''  as any
  });
  const getValueOfDropdown = (value: any) => {
    if (value) {
      const isNumberPresent = stateData.tableListData.filter((number: any) => {
        return number?.phoneNumber?.phoneNumber === value;
      })
      if (!isNumberPresent?.length) {
        return value + '(Deleted)';
      } else {
        return value;
      }
    }
  }

  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT] = useLazyQuery(
    CloudTelephonyQueries.GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });
  const getNumbersList = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const responses = await GET_VIRTUAL_PHONE_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        searchString: '%%',
      },
    });
    const userDataList = await getUsersData();
    const allDefaultForAccount = await GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        tenantId: accountUuid
      },
    });
    const virtualNumberList = getVirtualNumberFormattedList(
      responses?.data?.virtualPhoneNumberAssignees || [],
      assigneesTypeList,
      userDataList.data?.users || [],
      [],
      allDefaultForAccount?.data?.numberDefaultCommunications || [],
      CommunicationType
    );
    virtualNumberList.forEach((virtualNumber: any) => {
      const label = `${virtualNumber.phoneNumber?.name} (${virtualNumber.phoneNumber?.phoneNumber})`;
      virtualNumber.label = label;
    });
    setStateData((prev) => {
      return {
        ...prev,
        tableListData: virtualNumberList || [],
        loading: false,
      };
    });
  };
  useEffect(()=> {
    getNumbersList()
  },[])
  const modifiedValue = getValueOfDropdown(props.value);
  return (
    <Select
      dropdownMatchSelectWidth={3}
      size="large"
      allowClear
      showSearch={true}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      value={modifiedValue ? modifiedValue : undefined}
      onChange={(value: any[], data: any) => {
        onSelection({key:data?.key, value: data?.value})
      }}
      placeholder="Search number"
      // loading={componentState.loading}
      notFoundContent={stateData.loading && <Spin size="small" />}
      style={{height: '36px'}}
      loading={stateData.loading}
    >
      {stateData.tableListData.map((number: any) => {
        return (
          <Select.Option
            key={`${number.id}`}
            value={number?.phoneNumber?.phoneNumber}
          >
            {number.label}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default React.memo(VirtualPhoneListSearch)
