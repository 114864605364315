import {Stack, useFocusIdx} from '@foldhealth/easy-email-editor';
import {
  AttributesPanelWrapper,
  ColorPickerField,
} from '@foldhealth/easy-email-extensions';

const FormsPanel = () => {
  const {focusIdx} = useFocusIdx();
  return (
    <AttributesPanelWrapper style={{padding: '20px'}}>
      <Stack vertical>
        <ColorPickerField
          label="Caption Color"
          name={`${focusIdx}.attributes.captionColor`}
        />
        <ColorPickerField
          label="Background Color"
          name={`${focusIdx}.attributes.backgroundColor`}
        />
        <ColorPickerField
          label="Button Color"
          name={`${focusIdx}.attributes.buttonColor`}
        />
        <ColorPickerField
          label="Button Text Color"
          name={`${focusIdx}.attributes.buttonTextColor`}
        />
      </Stack>
    </AttributesPanelWrapper>
  );
};

export default FormsPanel;
