import {Badge, Divider, HStack, Pressable, Stack, Text, View} from 'native-base';
import {
  IContactCareProgramResponse,
  IContactCareProgramSteps,
  IStepsLog,
} from '../../../services/ContactCareProgram/interface';
import {Colors} from '../../../styles/Colors';
import {CARE_PROGRAM_STEP_TYPE, TOOLTIP_STATUS_TEXT} from './constant';
import Feather from 'react-native-vector-icons/Feather';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import AWVSvg from '../Svg/CareProgramSteps/AWVSvg';
import AssessmentSvg from '../Svg/CareProgramSteps/AssessmentSvg';
import ConsentSvg from '../Svg/CareProgramSteps/ConsentSvg';
import { CARE_PROGRAM_STEP_STATUS_CODES } from '../../../constants/MlovConst';
import EnrollSvg from '../Svg/CareProgramSteps/EnrollSvg';
import EcmOutreachSvg from '../Svg/CareProgramSteps/EcmOutreachSvg';
import { getDiffInHours } from '../../../utils/DateUtils';
import TestIdentifiers from '../../../testUtils/TestIdentifiers';
import { testID } from '../../../testUtils/Utils';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { Tooltip } from 'antd';
import {useCareProgramDeclinedInfo} from './customHook/useCareProgramDeclinedInfo';

export const CareProgramStepWiseView = (props: {
  filterCode: string | undefined,
  contactCareProgram: IContactCareProgramResponse;
  onActionPerformed: (actionCode: string, extraData?: any) => void;
  config?: {
    hideCareProgramName: boolean,
  }
}) => {
  const {contactCareProgram, onActionPerformed, filterCode} = props;
  const {isDeclined, declinedAtStep} = useCareProgramDeclinedInfo(contactCareProgram);
  const getStepLogByContactStepId = (steps: IContactCareProgramSteps): IStepsLog | undefined  => {
    const findStep: IStepsLog | undefined = (contactCareProgram?.stepsLog || []).find(stepLogElem => {
      return stepLogElem.careProgramStepId === steps?.id;
    });
    return findStep;
  }

  const formatRemainingTime = (hours: number) => {
    if (hours >= 24) {
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        if (remainingHours === 0) {
            return `${days} day${days > 1 ? 's' : ''}`;
        } else {
            return `${days} day${days > 1 ? 's' : ''} ${remainingHours} hour${remainingHours > 1 ? 's' : ''}`;
        }
    } else if (hours >= 1) {
        const remainingMinutes = Math.floor((hours - Math.floor(hours)) * 60);
        if (remainingMinutes === 0) {
            return `${Math.floor(hours)} hour${hours > 1 ? 's' : ''}`;
        } else {
            return `${Math.floor(hours)} hour${Math.floor(hours) > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
        }
    } else {
        const minutes = Math.floor(hours * 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
}

  const getTimerElement = (expiresOn: Date, toolTipText: string): JSX.Element => {
    let timerText = '';
    const remainingHours = Math.floor(getDiffInHours(new Date(), expiresOn));
    const {icon, background} = getStepColor(CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE);
    if (remainingHours <= 0) {
      return (
        <View style={{backgroundColor: background, padding: 4, borderRadius: 4, overflow: 'hidden'}}>
          <Tooltip title={toolTipText}>
            <EcmOutreachSvg customStrokeColor={icon} size={16} />
          </Tooltip>
        </View>
      );
    }

    if (remainingHours > 48) {
      const days: number = Math.floor(remainingHours / 24);
      const dayWiseHours: number = remainingHours % 24;
      timerText = `${days}d ${dayWiseHours} hrs`;
    } else {
      timerText = `${remainingHours} hrs`;
    }
    return (
      <Badge
        backgroundColor={Colors.Custom.Red100}
        borderRadius={8}
      >
        <Tooltip title={toolTipText}>
          <HStack flex={1}>
            <FeatherIcon
                  name="clock"
                  size={18}
                  color={Colors.Custom.overdueTaskIconColor}
                  style={{
                    marginRight: 2
                  }}
                  {...testID(TestIdentifiers.deleteBtn)}
                />
            <Text color={Colors.Custom.MissedTaskFontColor} fontWeight={'400'} fontSize={14}>
              {timerText}
            </Text>
          </HStack>
        </Tooltip>
      </Badge>
    )
  }

  const getStepIcon = (steps: IContactCareProgramSteps) => {
    const stepCode = getStepStatus(steps) || '';
    const {icon, background, tooltipStatusText} = getStepColor(stepCode);
    let element: JSX.Element | undefined = undefined;
    let isTimerStep = false;
    let timer = new Date();
    let iconTooltipText = '';
    const tooltipStatusTextToConcat = tooltipStatusText || '';
    const stepTypeCode = steps.careProgramStepType?.code;
    switch (stepTypeCode) {
      case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
        element = <AWVSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = tooltipStatusTextToConcat + ' Appointment'
        break;

      case CARE_PROGRAM_STEP_TYPE.ENROLLMENT:
        element = <EnrollSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = tooltipStatusTextToConcat + ' Enrollment'
        break;

      case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
        element = <AssessmentSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = tooltipStatusTextToConcat + ' Assessment'
        break;

      case CARE_PROGRAM_STEP_TYPE.CONSENT:
        element = <ConsentSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = tooltipStatusTextToConcat + ' Consent'
        break;

      case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
        element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = tooltipStatusTextToConcat + ' Interactive contact'
        break;

      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
        const stepName =
          steps.careProgramStepType?.code ===
          CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
            ? ' Interactive contact'
            : ' Face-to-face appointment';
        iconTooltipText = tooltipStatusTextToConcat + stepName;
        if (checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.DONE)) {
          element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
        } else {
          if (checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE)) {
            element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
          } else {
            const findStep: IStepsLog | undefined = getStepLogByContactStepId(steps);
            if (findStep?.expiresOn && stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY) {
              isTimerStep = true;
              timer = findStep?.expiresOn;
              element = getTimerElement(findStep?.expiresOn, iconTooltipText);
            } else {
              element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
            }
          }
        }
        break;
    }
    if (isTimerStep) {
      return (
        <>
          {getTimerElement(timer, iconTooltipText)}
        </>
      )
    }
    if (element) {
      return (
        <View style={{backgroundColor: background, padding: 4, borderRadius: 4, overflow: 'hidden'}}>
          <Tooltip title={iconTooltipText}>
            <View>
            {element}
            </View>
          </Tooltip>
        </View>
      );
    }
    return <></>;
  };

  const handleStepSelection = (steps: IContactCareProgramSteps) => {
    const isStepStatusInToDo = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.TO_DO);
    const isStepStatusInFailed = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.FAIL);
    const isStepStatusInDone = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.DONE);
    const isStepStatusInProgress = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.IN_PROGRESS);
    const isStepStatusInReject = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.REJECT);
    const allowToEditAfterComplete: string[] = [
      CARE_PROGRAM_STEP_TYPE.CONSENT,
      CARE_PROGRAM_STEP_TYPE.ASSESSMENT,
      CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT,
      CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
    ];
    const allowToEditInProgress: string[] = [
      CARE_PROGRAM_STEP_TYPE.CONSENT,
      CARE_PROGRAM_STEP_TYPE.ASSESSMENT,
      // REMOVE
      CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
    ];
    const allowToEditAfterReject: string[] = [
      CARE_PROGRAM_STEP_TYPE.CONSENT,
      CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
    ];
    const allowToEditInProgressStep = isStepStatusInProgress && allowToEditInProgress.includes(steps?.careProgramStepType?.code);
    const allowToEditCompletedStep = isStepStatusInDone && allowToEditAfterComplete.includes(steps?.careProgramStepType?.code);
    const allowToEditRejectedStep = isStepStatusInReject && allowToEditAfterReject.includes(steps?.careProgramStepType?.code);

    const allowToTakeAction = allowToEditInProgressStep || allowToEditCompletedStep || isStepStatusInToDo || isStepStatusInFailed || allowToEditRejectedStep;

    if (allowToTakeAction) {
      const stepCode = steps?.careProgramStepType?.code;
      switch (stepCode) {
        case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              isStepStatusInDone ? COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT  : COMMON_ACTION_CODES.SEND_FORMS,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.CONSENT:
        case CARE_PROGRAM_STEP_TYPE.ENROLLMENT:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              isStepStatusInDone ? COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT  : COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
                careProgram: contactCareProgram,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
        case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
        case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              COMMON_ACTION_CODES.ECM_OUTREACH_VIEW,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
              onActionPerformed(
                isStepStatusInDone ? COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT  : COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
                {
                  stepId: steps.id,
                  careProgramId: contactCareProgram?.id,
              });
          }
          break;
      }
    }
  };

  const checkStepStatus = (step: IContactCareProgramSteps, stepCode: string) => {
    const stepId = step?.id;
    return (contactCareProgram?.stepsLog || []).some(stepLog => {
      return (
        stepLog?.careProgramStepId == stepId &&
        stepLog?.careProgramStepStatus?.code == stepCode
      );
    });
  };

  const getStepStatus = (step: IContactCareProgramSteps) => {
    const stepId = step?.id;
    return (contactCareProgram?.stepsLog || []).find(item => item.careProgramStepId === stepId)?.careProgramStepStatus?.code
  };

  const getStepColor = (stepCode: string): {icon: string, background: string, tooltipStatusText?: string} => {
    switch (stepCode) {
      case CARE_PROGRAM_STEP_STATUS_CODES.TO_DO:
        return { icon: Colors.Custom.Gray500, background: Colors.Custom.Gray100, tooltipStatusText: TOOLTIP_STATUS_TEXT.PENDING };

      case CARE_PROGRAM_STEP_STATUS_CODES.SKIP:
        return { icon: Colors.Custom.PendingTaskFontColor, background: Colors.Custom.PendingTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.FAIL:
        return { icon: Colors.Custom.MissedTaskFontColor, background: Colors.Custom.MissedTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.DONE:
        return { icon: Colors.Custom.CompletedTaskFontColor, background: Colors.Custom.CompletedTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE:
        return { icon: Colors.Custom.MissedTaskFontColor, background: Colors.Custom.MissedTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.IN_PROGRESS:
          return { icon: Colors.Custom.PendingTaskFontColor, background: Colors.Custom.PendingTaskBackgroundColor, tooltipStatusText: TOOLTIP_STATUS_TEXT.PENDING };

      default:
        return { icon: Colors.Custom.Gray300, background: Colors.Custom.Gray100 };
    }
  }

  const renderSteps = (steps: IContactCareProgramSteps[]): JSX.Element => {
    return (
      <>
        {(steps || []).map(step => {
          return (
            <Pressable onPress={() => handleStepSelection(step)}>
              {getStepIcon(step)}
            </Pressable>
          );
        })}
      </>
    );
  };

  const renderCareProgramName = (isDisabled: boolean, prefixIcon?: JSX.Element) => {
    return (
      <View
        padding={1}
        backgroundColor={
          isDisabled ? Colors.Custom.Red100 : Colors.FoldPixel.GRAY100
        }
        borderRadius={4}
      >
        <Text
          wordBreak={'break-word'}
          color={isDisabled ? Colors.FoldPixel.STATUS_ERROR : Colors.Custom.Gray500}
          size={'smLight'}
        >
          {prefixIcon}
          {contactCareProgram?.payerCareProgram?.careProgramType?.value}
        </Text>
      </View>
    );
  }
  

  return (
    <>
      <Stack
        direction={'row'}
        key={contactCareProgram?.id}
        style={{alignItems: 'center', marginTop: 4}}
      >
        {!props?.config?.hideCareProgramName && (
          <>
            {isDeclined ? (
              <Tooltip title="Patient declined">
                <Pressable
                  onPress={
                    !!declinedAtStep
                      ? () => handleStepSelection(declinedAtStep)
                      : undefined
                  }
                >
                  {renderCareProgramName(isDeclined, <Feather name='slash' size={14} style={{marginRight: 2}} />)}
                </Pressable>
              </Tooltip>
            ) : (
              renderCareProgramName(false)
            )}
          </>
        )}
        {!isDeclined && (
          <Stack
            direction={'row'}
            space={2}
            marginLeft={2}
            style={{alignItems: 'center'}}
          >
            {renderSteps(contactCareProgram?.contactCareProgramSteps)}
          </Stack>
        )}
      </Stack>
    </>
  );
};
