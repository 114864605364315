import { Drawer, Tooltip } from 'antd';
import { Text, useToast, View, Button } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { BUTTON_TYPE, GROUP_MEMBER_TYPE } from '../../../../../../constants';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import { Colors } from '../../../../../../styles';
import { getEhrConfig, getResourceAbilities } from '../../../../../../utils/capabilityUtils';
import { isLeadGroupsPage, isPopGroupActivityLogEnable } from '../../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { DisplayCardAvatar } from '../../../../../common/DisplayCard/DisplayCardAvatar';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import InfoSvg from '../../../../../common/Svg/InfoSvg';
import { getPreFilterMemberStatus, patientFilterForCreateSegment } from '../../../../MembersManagement/Helper';
import { IMembersDatas } from '../../../../MembersManagement/RulesInterface';
import { createCampaignSegment, getCampaignSegmentRules, getGroupDetailById } from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { CampaignSegmentForm } from '../../CampaignSegmentForm';
import { ICampaignSegmentFormState } from '../../interfaces';
import AnalyticsSvg from '../../../../../common/Svg/SideMenu/Analytics';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { Pressable } from 'react-native';
import GroupDetailsDrawer from '../GroupDetailsDrawer'
import Feather from 'react-native-vector-icons/Feather';
import { CONVERSATION_ACTION_CODES } from '../../../../../../constants/ActionConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import {getFrequencyValue} from '../../utils';
import { formatDate } from '../../../../../common/CustomFilterView/OneViewFilter/HelperUtils';

const GroupDetailSideView = (props: any) => {
  const {id} = useParams();
  const toast = useToast();
  const intl = useIntl();
  const hintCapability = getResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const [isShowEditGroup, setIsShowEditGroup] = useState<boolean>(false);
  const [stateData, setStateData] = useState({
    groupData: props?.groupDetailData,
    showAuditDrawer: false,
  });

  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowPopGroupActivityLog = isPopGroupActivityLogEnable(userSettings);

  const onCancel = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showAuditDrawer: false,
      };
    });
  }

  const updateGroup = (
    campaignSegmentFormData: ICampaignSegmentFormState
  ) => {
    createCampaignSegment({
      id: stateData?.groupData?.id,
      name: campaignSegmentFormData?.audienceName || '',
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      ...!campaignSegmentFormData.patientList?.length && (getPreFilterMemberStatus(campaignSegmentFormData))
    }).then(async (resp: any) => {
      if (resp?.id) {
        let popGroupResponse = resp;
        if (resp?.inputRuleId) {
          popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
        }
        if(props.onUpdate){
          props.onUpdate(resp);
        }
        props?.getGroupDetail()?.then((response: any) => {
          if(response?.data?.id){
            setStateData((prev) => {
              return {
                ...prev,
                groupData: response?.data || {},
              };
            });
          }
        });
        setIsShowEditGroup(false);
        showToast(toast,  resp.type == 'DYNAMIC'
        ? `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
        : intl.formatMessage({id: 'createGroupSuccessMessage'}), ToastType.success);
      }
    }).catch((error) => {
      showToast(toast, (error?.response?.data?.message || 'Something went wrong'), ToastType.error, undefined);
    });
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        groupData: props?.groupDetailData || {},
      };
    });
  }, [props?.groupDetailData])

  return (
    <>
    { stateData?.showAuditDrawer && (
      <GroupDetailsDrawer
        onCancel={onCancel}
        ruleId={props?.groupDetailData?.id}
      />)}
    <View
      style={{
        height: '100%',
      }}
    >
      <View
        style={{
          backgroundColor: '#fff',
          padding: 16,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomWidth: 1,
          borderBottomColor: Colors.Custom.Gray200,
        }}
      >
        <View
          flexDirection={'row'}
          justifyContent={allowPopGroupActivityLog ? 'space-between' : 'center'}
          alignItems={'center'}
        >
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '16',
            }}
            isLetterAvatarShow={true}
            userData={{
              userId: '',
              userType: GROUP_MEMBER_TYPE.CONTACT,
              genderCode: {} as any,
              userName: stateData?.groupData?.name
            }}
          />
          {allowPopGroupActivityLog &&
            <View
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Tooltip title="Activity">
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        showAuditDrawer: true,
                      };
                    });
                  }}
                >
                  <Feather
                    name="clock"
                    size={20}
                    color={Colors.Custom.Gray600}
                    />
                </Pressable>
              </Tooltip>
            </View>
          }
        </View>
        <Text
          size={'lgBold'}
          style={{
            color: Colors.Custom.Gray900,
            maxWidth: 250,
            flexWrap: 'wrap',
            marginTop: 20,
          }}
        >
          {stateData?.groupData?.name}
        </Text>
        <Text size={'smMedium'} color={Colors.Custom.Gray500}>
          {openInHint && !isLeadGroupsPage() ? `Fold Active members: ${props.activeTotal}` : `Active members: ${props.activeTotal}`}
        </Text>
        {
          isLeadGroupsPage() ? (<></>): (
            <Text size={'smMedium'} color={Colors.Custom.Gray500}>
              {openInHint && !isLeadGroupsPage() ? `Fold Inactive members: ${props.inactiveTotal}` : `Inactive members: ${props.inactiveTotal}`}
            </Text>
          )
        }
        {props?.passedTotal > 0 && stateData?.groupData?.ruleExecutions?.[0]?.startDateTime && (
            <Text size={'xsMedium'} color={Colors.Custom.SuccessColor}>
              {`${props?.passedTotal} ${intl.formatMessage({id: 'passMembersNew'})} ${formatDate(stateData?.groupData?.ruleExecutions?.[0]?.startDateTime)}`}
            </Text>
          )}
            {props?.failedTotal > 0 && stateData?.groupData?.ruleExecutions?.[0]?.startDateTime && (
              <Text size={'xsMedium'} color={Colors.Custom.Danger700}>
              {`${props?.failedTotal} ${intl.formatMessage({id: 'failMembersNew'})} ${formatDate(stateData?.groupData?.ruleExecutions?.[0]?.startDateTime)}`}
            </Text>
           )}
        {stateData?.groupData.account_uuid && (
          <Text
            size={'smNormal'}
            fontWeight={500}
            noOfLines={1}
            style={{
              color: '#825AC7',
              marginTop: 5,
              backgroundColor: '#FDFAFF',
              paddingVertical: 4,
              width: 100,
              borderWidth: 1,
              borderRadius: 14,
              borderColor: '#EFE0FC',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              setIsShowEditGroup(true);
            }}
          >
            Edit Group
          </Text>
        )}
      </View>
      <View style={{paddingHorizontal: 16, backgroundColor: '#fff'}}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 10,
          }}
        >
          <View style={{marginRight: 10}}>
            <InfoSvg />
          </View>
          <DisplayText
            size={'lgBold'}
            extraStyles={{color: Colors.primary['700'] || ''}}
            textLocalId={'description'}
          />
        </View>
        <View
          style={{
            padding: 16,
            borderRadius: 8,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
          }}
        >
          <Text size={'mdNormal'} style={{color: Colors.Custom.Gray700}}>
            {stateData.groupData.description}
          </Text>
        </View>
      </View>
      {isShowEditGroup && (
        <Drawer
          width={600}
          visible={isShowEditGroup}
          closeIcon={null}
          closable
          // title={onDrawerHeader(openModal?.type)}
          maskClosable={true}
          destroyOnClose
          onClose={() => {
            setIsShowEditGroup(false);
          }}
        >
          <CampaignSegmentForm
            initData={{
              audienceName: stateData?.groupData?.name || '',
              jsonTree: stateData?.groupData?.displayJson || {},
              patientList:
                stateData?.groupData?.rule?.patientFilter?.contactIds || [],

              description: stateData?.groupData?.description,
              executionFrequency: stateData?.groupData?.executionFrequency,
              memberStatusCodeFromResponseWhenEditingGroup: stateData?.groupData?.preFilter?.hintMembershipStatus?.params?.value
            }}
            onModalClose={() => {
              setIsShowEditGroup(false);
            }}
            onSubmitForm={(
              campaignSegmentFormData: ICampaignSegmentFormState
            ) => {
              updateGroup(campaignSegmentFormData);
            }}
          />
        </Drawer>
      )}
    </View>
    </>
  );
};

export default GroupDetailSideView;
