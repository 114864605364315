import {Skeleton, View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import CommonService from '../../../../services/CommonService/CommonService';
import {
  getAccountId,
  getAccountName,
  getAccountUUID,
} from '../../../../utils/commonUtils';
import LocalStorage from '../../../../utils/LocalStorage';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import AccessForm from './AccessForm';
import APICredView from './APICredListView/APICredView';
import {FoldApiViewCodes} from './FoldApisConfigConst';

const FoldApisConfig = () => {
  const [foldApiConfigState, setFoldApiConfigState] = useState({
    viewCode: FoldApiViewCodes.ACCESS_FORM,
    //attachmentData: [] as AttachmentDetailApiResponse[],
    accessToken: '',
    clientApp: {clientId: ''},
    postmanCollection: {},
    loading: true,
    isViewLogsOpen: false,
  });

  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const accountName = getAccountName();

  const commonService = CommonService.getCommonServiceInstance();
  const foldApiService = commonService.foldApiService;

  const {height} = Dimensions.get('window');

  const handleCreate = async () => {
    setFoldApiConfigState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    foldApiService
      .post('/v1/client/create', {
        oauthflowtype: 'client_credentials',
        clientName: `${accountName}-${Date.now()}`,
        allowedOauthScopes: [],
        tokenValidity: 8,
        tokenValidityUnits: 'hours',
        supportedIdentityProviders: ['COGNITO'],
        usagePlan: 'BASIC',
        generateSecret: true,
        tenantUuid: accountUuid,
        tenantId: accountId,
      })
      .then((resp: {data: any}) => {

        setFoldApiConfigState((prev) => {
          return {
            ...prev,
            viewCode: FoldApiViewCodes.VIEW_API_CREDS,
            clientApp: resp.data,
            //  postmanCollection: JSON.parse(resp?.data?.postMan),
            loading: false,
          };
        });
      });
  };

  const downloadPostmanCollection = async (obj: any) => {
    // text content
    const bytes = new TextEncoder().encode(obj);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    });

    // anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = 'foldapi-postman-collection' + Date.now() + '.json';

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleDelete = async (clientId: any, clientName: any) => {
    setFoldApiConfigState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    foldApiService
      .post('/v1/client/delete', {
        clientId: clientId,
        clientName: clientName,
        tenantUuid: accountUuid,
      })
      .then((resp: {data: any}) => {
        if (resp?.data?.length === 0) {
          setFoldApiConfigState((prev) => {
            return {
              ...prev,
              viewCode: FoldApiViewCodes.ACCESS_FORM,
              loading: false,
            };
          });
        } else {
          setFoldApiConfigState((prev) => {
            return {
              ...prev,
              viewCode: FoldApiViewCodes.VIEW_API_CREDS,
              clientApp: resp.data,
              loading: false,
            };
          });
        }
      });
  };

  const fetchApiToken = async () => {
    setFoldApiConfigState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);

    const accountUUID =
      userData?.data.accounts.length > 0
        ? userData?.data.accounts[0].uuid || ''
        : '';
    try {
      foldApiService
        .post('/v1/client/read', {
          tenantUuid: accountUUID,
        })
        .then((resp: {data: any}) => {
          if (resp?.data?.client != 'NA') {
            setFoldApiConfigState((prev) => {
              return {
                ...prev,
                viewCode: FoldApiViewCodes.VIEW_API_CREDS,
                //  postmanCollection: JSON.parse(resp?.data?.postMan),
                clientApp: resp.data,
                loading: false,
              };
            });
          } else {
            setFoldApiConfigState((prev) => {
              return {
                ...prev,
                viewCode: FoldApiViewCodes.ACCESS_FORM,
                loading: false,
              };
            });
          }
        });
    } catch (error) {

      setFoldApiConfigState((prev) => {
        return {
          ...prev,
          viewCode: FoldApiViewCodes.ACCESS_FORM,
          loading: false,
        };
      });
    }
  };

  const getViewFromSelectedCode = () => {
    switch (foldApiConfigState.viewCode) {
      case FoldApiViewCodes.ACCESS_FORM:
      case FoldApiViewCodes.VIEW_API_CREDS:
        return (
          <View height={height - 180}>
            <PageBodyContainer>
              {foldApiConfigState.loading ? (
                <Skeleton.Text lines={4} />
              ) : (
                <>
                  {foldApiConfigState.viewCode ===
                    FoldApiViewCodes.ACCESS_FORM && (
                    <AccessForm
                      onCreateAction={() => {
                        handleCreate();
                      }}
                    />
                  )}

                  {foldApiConfigState.viewCode ===
                    FoldApiViewCodes.VIEW_API_CREDS && (
                    <APICredView
                      accessToken={foldApiConfigState.accessToken}
                      onDownloadPostmanCollection={(obj: any) => {
                        downloadPostmanCollection(obj);
                      }}
                      onDeleteClient={(clientId: any, clientName: string) => {
                        handleDelete(clientId, clientName);
                      }}
                      onCreateAction={() => {
                        handleCreate();
                      }}
                      clientApp={foldApiConfigState.clientApp}
                    />
                  )}
                </>
              )}
            </PageBodyContainer>
          </View>
        );
    }
  };

  useEffect(() => {
    fetchApiToken();
  }, []);

  return <>{getViewFromSelectedCode()}</>;
};

export default FoldApisConfig;
