import { gql } from '@apollo/client';

export const GET_SMS_TAGS_WITH_CONTACT = gql`
query getSmsTags($smsStatusBoolExp: smsStatus_bool_exp, $smsTagsBoolExp: smsTags_bool_exp) {
  smsTags(distinct_on: [smsSid], where: $smsTagsBoolExp) {
    smsSid
    createdOn: createdAt
    contactSmsLog {
      contactUuid
      toPhoneNumber
    }
    smsStatus(where: $smsStatusBoolExp) {
      status
      createdOn: createdAt
    }
  }
}
`;

export const GET_SMS_TAGS_WITHOUT_CONTACT = gql`
query getSmsTags($smsStatusBoolExp: smsStatus_bool_exp, $smsTagsBoolExp: smsTags_bool_exp) {
  smsTags(distinct_on: [smsSid], where: $smsTagsBoolExp) {
    smsSid
    createdOn: createdAt
    smsStatus(where: $smsStatusBoolExp) {
      status
      createdOn: createdAt
    }
  }
}
`;


export default {
  GET_SMS_TAGS_WITH_CONTACT,
  GET_SMS_TAGS_WITHOUT_CONTACT
};
