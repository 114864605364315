import {Icon, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {NoteActions} from '../interfaces';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../../../constants';

interface IDropdownButtonProps {
  onPress: (string: NoteActions) => void;
}

const CustomAddNoteButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  return (
    <View
      margin={1}
      style={{
        height: 42,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FoldButton
        customProps={{
          btnText: intl.formatMessage({id: 'addNote'}),
        }}
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          backgroundColor:Colors.Custom.BackgroundColor,
          leftIcon: (
            <Icon
              as={AntIcon}
              name={'plus'}
              size="4"
              color={Colors.Custom.mainPrimaryPurple}
            />
          ),
          onPress: () => {
            props.onPress(NoteActions.CREATE_DEFAULT_NOTE);
          },
        }}
      />
    </View>
  );
};

export default CustomAddNoteButton;
