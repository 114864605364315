import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {useEffect, useRef, useState} from 'react';
// import {ISearchFieldProps} from '../../../Interfaces';
import {
  UserQueries,
} from '../../../services';
import {getAccountId} from '../../../utils/commonUtils';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import UserInfoListItem from './UserInfoListItem';
// import {IVirtualNumberSearch} from './interface';

export interface ISearchFieldProps {
  value?: any;
  userInputFieldList?: any;
  defaultTemplateCategory?: string;
  isHideDefaultInput?: boolean;
  isHideTemplateCategory?: boolean;
  isShowError: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (value: any) => void;
  isReturnCategory?: boolean;
  allowUserMultiSelect?: boolean;
  userList?: any;
}

const UserSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [componentState, setComponentState] = useState({
    virtualPhoneData: props.userList,
    loading: true,
  });

  const inputSelectRef = useRef<any>(null);

  useEffect(() => {
    // getNumberList();
  }, []);

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });

  // const getNumberList = async () => {
  //   const userDataList = await getUsersData();
  //   let users = userDataList.data?.users || [];
  //   const excludeList = props.excludeList || [];
  //   users = users.filter((user) => {
  //     return excludeList.includes(user.uuid) ? false : true;
  //   })
  //   // const updatedUserList = [];
  //   // users.forEach(user => {
  //   //   if ([].includes('a')) {

  //   //   }
  //   //   updatedUserList.push(user);
  //   // });
  //   setComponentState((prev: any) => {
  //     return {
  //       ...prev,
  //       virtualPhoneData: users,
  //     };
  //   });
  // };

  const getDataFromId = (uuid: any) => {
    const matchedData = componentState.virtualPhoneData.filter((item: any) => {
      return item.uuid === uuid;
    });
    if (matchedData.length > 0) {
      const virtualPhoneObj = matchedData[0];
      return virtualPhoneObj;
    }
  };

  return (
      <Select
        // dropdownMatchSelectWidth={10}
        size="large"
        ref={inputSelectRef}
        allowClear
        showSearch={true}
        autoClearSearchValue={false}
        filterOption={(input: string, option: any) => {

          return (option!.key as unknown as string)
            .split('/')?.[0]
            .toLowerCase()
            .includes(input.toLowerCase())
        }}
        onChange={(value: any[], data: any) => {
          if (data && data.value) {
            onChange(getDataFromId(data.value));
          } else {
            onChange(undefined);
          }
        }}
        onFocus={()=>{
          props?.onFocus && props?.onFocus();
        }}
        onBlur={()=>{
          props?.onBlur && props?.onBlur();
        }}        
        onSelect={()=>{
          if(inputSelectRef.current) {
            inputSelectRef.current.blur();
          }
        }}
        value={[]}
        defaultValue={[]}

        placeholder="Search users to add"
        // loading={componentState.loading}
        notFoundContent={componentState.loading && <Spin size="small" />}
        style={{height: '36px', width: '100%'}}
        className={isShowError && !value ? 'field-error' : ''}
      >
        {props.userList?.map((virtualNumberObject: any) => {
          return (
            <Select.Option

              key={`${virtualNumberObject.name}/${virtualNumberObject.uuid}`}
              value={virtualNumberObject.uuid}
            >
              <UserInfoListItem 
                user={virtualNumberObject}
              ></UserInfoListItem>
            </Select.Option>
          );
        })}

      </Select>
  );
};

export default UserSearch;
