import {IconButton, Tooltip, View} from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {IPushNotificationTableActionsProps} from '../interfaces';
import {styles} from './PushNotificationTableActionStyles';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  getUserPermissionByEntityAndActionCode,
  USER_ACCESS_PERMISSION,
} from '../../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';

const PushNotificationTableActions = (
  props: IPushNotificationTableActionsProps
) => {
  const {onActionPerformed, record} = props;

  const showAutomationOption = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );

  return (
    <View style={styles.actionContainer}>
      <Tooltip label="Update" placement="top">
        <IconButton
          onPress={() => onActionPerformed(COMMON_ACTION_CODES.EDIT, record)}
          color={Colors.Custom.Gray600}
          icon={<FeatherIcon size={17} name="edit-2" />}
        />
      </Tooltip>

      <Tooltip label="Delete" placement="top">
        <IconButton
          onPress={() => onActionPerformed(COMMON_ACTION_CODES.DELETE, record)}
          color={Colors.Custom.Gray600}
          icon={<Icon size={17} name="delete" />}
        />
      </Tooltip>

      <Tooltip label="Create Campaign" placement="top">
        <IconButton
          onPress={() =>
            onActionPerformed(COMMON_ACTION_CODES.CREATE_CAMPAIGN, record)
          }
          color={Colors.Custom.Gray600}
          icon={<Icon size={17} name="notification" />}
        />
      </Tooltip>

      {showAutomationOption && (
        <Tooltip label="Create Automation" placement="top">
          <IconButton
            onPress={() =>
              onActionPerformed(COMMON_ACTION_CODES.CREATE_AUTOMATION, record)
            }
            color={Colors.Custom.Gray600}
            icon={<MaterialCommunityIcon size={17} name="lightning-bolt" />}
          />
        </Tooltip>
      )}
    </View>
  );
};

export default PushNotificationTableActions;
