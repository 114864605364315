import { Pie, PieConfig } from '@ant-design/plots';
import { graphColorList } from "../../Analytics/EmployerAnalytics/graphColors";
import { useIntl } from 'react-intl';
import { Colors } from '../../../../styles/Colors';
import { VStack, View, Text } from 'native-base';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';


const FormPieChart = (props: {
    data: { type: string, value: number }[],
    legendPosition?: 'right' | 'left',
    height?: number,
    width?: number,
    hideLegend?: boolean
}) => {
    const intl = useIntl();

    const config: PieConfig = {
        // appendPadding: 10,
        data: props.data,
        angleField: 'value',
        colorField: 'type',
        appendPadding: 10,
        radius: 1,
        innerRadius: 0.6,
        color: graphColorList,
        label: false,
        height: props.height || 275,
        width: props.width || undefined,

        // label: {
        //   type: 'spider',
        //   // offset: '-30%',
        //   content: (data) => {
        //     const percent = data.percent
        //     const value = data.value
        //     return `${value} ( ${(percent * 100).toFixed(0)}% )`
        //   },
        //   style: {
        //     fontSize: 12,
        //     textAlign: 'center',
        //   },
        // },
        tooltip: {
            formatter: (data) => {
                return {
                    name: intl.formatMessage({ id: data.type }),
                    value: parseFloat(data.value?.toFixed?.(2))
                }
            },
        },

        legend:
            props?.hideLegend ? false : {
                itemSpacing: 20,
                position: props.legendPosition || 'right',
                layout: 'vertical',
                offsetX: props.legendPosition == 'left' ? 0 : 0,
                itemMarginBottom: 20,
                itemName: {
                    style: {
                        fontSize: 14,
                        // stroke: Colors.Custom.Gray500
                    },
                    formatter(text: any, item: any, index: any) {
                        return `${intl.formatMessage({ id: text })} (${props?.data?.[index]?.value || 0})`
                    },
                },
                // itemValue: {
                //     alignRight: true,
                //     style: {
                //         fontWeight: 'normal',
                //         fontSize: 14
                //     },
                //     formatter(text, item, index) {
                //         return `(${props.data[index].value})`
                //     },
                // },
                marker: {
                    symbol: 'circle',
                    style(style) {
                        return {
                            ...style,
                            r: 4
                        }
                    },
                }
            },
        statistic: {
            title: undefined,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    };
    if (props.data.find((item) => item.value != 0)) {
        return (<View style={{ marginVertical: 10 }}><Pie {...config} /></View>)
    }
    return <View style={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 24
    }}>  <NodataViewTypeOne viewCode="TEXT_ICON" /> </View>;
}


export default FormPieChart;
