
import { Pressable, Spinner, Text } from 'native-base';
import React from 'react';
import { Colors } from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';

interface IFoldButtonV2Props {
  label: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonType: 'primary' | 'secondary';
  onPress?: () => void;
}

const FoldButtonV2 = (props: IFoldButtonV2Props) => {
  const { onPress, buttonType, label, isDisabled, isLoading } = props;
  return (
    <Pressable
      px={2}
      py={1}
      rounded={'sm'}
      borderWidth={0.5}
      mx={1}
      onPress={onPress}
      isDisabled={isDisabled}
      borderColor={
        buttonType === 'primary'
          ? Colors.FoldPixel.PRIMARY300
          : Colors.FoldPixel.GRAY300
      }
      backgroundColor={
        isDisabled ? (buttonType === 'primary' ? Colors.FoldPixel.PRIMARY100 : Colors.Custom.White) :(buttonType === 'primary' ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.White)
      }
    >
      <Stack direction='row' space={4}>
        {isLoading && <Spinner size={'sm'} color={Colors.FoldPixel.GRAY300} />}
        <Text
          color={
            isDisabled ? (buttonType === 'primary' ? Colors.Custom.White : Colors.FoldPixel.GRAY200) : (buttonType === 'primary' ? Colors.Custom.White : Colors.FoldPixel.GRAY300)
          }
        >
          {label}
        </Text>
      </Stack>
    </Pressable>
  );
};

export default FoldButtonV2;
