import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Popover, Tooltip, notification} from 'antd';
import moment from 'moment';
import {
  Box,
  Divider,
  HStack,
  Pressable,
  Skeleton,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import React, {memo, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  CHAT_DELIVERY_STATUS,
  CONSENT_ERROR,
  CONVERSATION_STATUS_TYPES,
  COPY_MESSAGE_CODE,
  DATE_FORMATS,
  getInActiveContactError,
  GROUP_MEMBER_TYPE,
  LAST_SEEN_UPDATE_TIMEOUT,
  MLOV_CATEGORY,
  MODULE_PAGINATION_COUNT,
  NOTE_TYPE,
  ONLINE_STATUS,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
  EVENT_NAMES,
  WINDOW_EVENT_CODES,
  SMALL_WINDOW,
  CONVERSATION_STATUS,
  CONVERSATION_STATUS_STRING
} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES
} from '../../../../../constants/ActionConst';
import {
  COMPONENT_SUPPORTED_EVENT,
  CONVERSATION_LOCAL_EVENT_CODES,
  CUSTOM_MESSAGE_EVENT_CODES,
  SUPPORTED_EVENT_CODE,
} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../Interfaces';
import { IGroupMessageCreatedData} from '../../../../../Interfaces/GroupMessageInterface';
import {IMessageCreated} from '../../../../../Interfaces/WebSocketEventInterfaces';
import CommonService from '../../../../../services/CommonService/CommonService';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {IMessageData} from '../../../../../services/Conversations/interfaces';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {sendConversationNotificationNoMessageEvent} from '../../../../../services/SendNotification';
import {Colors} from '../../../../../styles';
import {
  getAccountId, getAccountUUID,
  getConfigDataFromCode,
  getFeatureFlag,
  getFormattedToNumber,
  getUserId,
  getUserUUID,
  isActiveContact,
  isChannelEmail,
  isChannelEmailOrSms,
  isChannelTwillioSms,
  isConsentRequiredAndGiven,
  isContactConsentGiven,
  isContactConsentRequired,
  showInfoOnMessageHeader,
  stripEmojis,
  isArchivedChannel,
  isEFaxChannelInboxType,
  isMasterAccount,
  isValidEFaxContactInboxSourceIdFromString,
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getDateStrFromFormat, getDateToMomentISOString} from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {CallBackArgs, IMentionObject} from '../../../../../utils/interfaces';
import LocalStorage from '../../../../../utils/LocalStorage';
import {MessageBus} from '../../../../../utils/MessageBus';
import {
  getMlovCodeIdObj,
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../../../utils/mlovUtils';
import {IConversationInboxs, IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import {
  MessageActionID,
  MessageActionViewModal
} from '../../../../common/MessageAction/MessageActionView';
import CreateNew from '../../../../common/Svg/CreateNew';
import TypingOn from '../../../../common/TypingOn/TypingOn';
import {PERSON_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {getFormDataFromLeadData} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {setSearchParamsForSelectedConversation} from '../ConversationChannelNew/ConversationSidebarUtils';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, ERROR_CODE, SCHEDULE_MESSAGE_STATUS} from '../ConversationConst';
import {
  CreateNoteType,
  IConversationData,
  IConversationMessage,
  IConversationMessageData,
  IConversationMessageMentionPrevResponse,
  IConversationMessageResponse,
  IConversationResponse,
  IMessageBoxData,
  IMessagingWindowProps,
  ISearchMessageData,
  ISelectedConversationResponse,
  IUnreadMessages
} from '../interfaces';
import {getUserIdListFromMsgContent} from './ConversationMentions/ConversationMentions';
import {DeleteMessageModal} from './DeleteMessageModal';
import {
  ICopiedMessageItem,
  IMessageRespData,
  IUserMsgSettings,
  IUserPreferences,
} from './interface';
import {
  addCommunicationTypesByMessageUuids,
  getContactDataAndType,
  getCreateChannelTabCode,
  getEmail,
  getFormattedMessagingList,
  getMentionObjects,
  getMessageBoxDataObj,
  getMessageBoxDataObjForGroup,
  isMessageCopied,
  getMessageUuidsFromCopyMessageList,
  getPhoneNumber,
  getUpdatedConversationResponse,
  handleMarkMentionAsUnread,
  isArchiveOnSend,
  isBroadCastAndPrivateGroup,
  isBroadCastGroup,
  isCommunicationTypeAssignedToSelectedMessages,
  isGroupMember,
  isInboxMember,
  isInternalChat,
  isPrivateGroup,
  isSMSNotSent,
  isSendMessageAllowed,
  messageDataKeysConverter,
  showPracticeMessageOnRightSide,
  sortMessages,
  getBlockNumberActionMessage,
  getUpdatedSenderUserData,
  handleDeletedContact,
  getUpdatedTaskCountData,
  getLastNonInternalMessageInList,
  checkAnyMessageSenderDeleted,
  isGroupConversation,
  getMessageDataFromId,
  checkIsLoggedInUserConversationMember,
  getInitialMessageValueIfSaved,
  getFormatedChatConversationData,
  getContactPCPData,
  checkIsPatientNumberChanged,
} from './MessagingUtils';
import GetMessagingListElem from './MessagingWindowCommon';
import MessagingWindowFooter from './MessagingWindowFooter';
import {MessagingWindowHeader} from './MessagingWindowHeader';
import {styles} from './MessagingWindowStyles';
import MsgReplyDrawer from './MsgReplyDrawer/MsgReplyDrawer';
import {MentionBar} from './MentionBar';
import CreateNoteFromMessage from './CreateNoteFromMessage/CreateNoteFromMessage';
import {getCurrentEHR} from '../../../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {EHRName} from '../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import ActionMessageView from '../../../../common/ActionMessageView/ActionMessageView';
import { CONVERSATION_TASK_CODE, ON_PRESS_ENTER_ACTION_CODES} from './MsgConst';
import {ACTION_MESSAGE_CODE} from '../../../../common/ActionMessageView/ActionMessageViewConst';
import {AddNoteView} from '../../../../common/AddNoteView';
import {getEhrConfig} from '../../../../../utils/capabilityUtils';
import {LeadQueries, UserQueries} from '../../../../../services';
import {ICommunicationType} from '../CommunicationTypes/interfaces';
import {AssignMessageTypeButton} from '../../../../AssignMessageTypeButton';
import AssignCommunicationTypeDrawer from '../CommunicationTypes/AssignCommunicationTypeDrawer';
import {useIntl} from 'react-intl';
import {withMiniContactViewHOC} from '../../../../MiniContactViewHOC';
import { ITask } from '../../../../common/CareDashboard/CareDashboardInterfaces';
import MessageBoxDrawerAction from '../../../../common/ChatUI/MessageBox/MessageBoxDrawerAction';
import { unAssignConversationAPI, updateConversationLastseenAt, updateConversationStatusAPI } from '../ConversationChannelNew/ConversationAPIService';
import { isArchiveOnSendEnable } from '../../../Sales/ProductsAndServices/JourneyPackages/PackagesUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { FeatureKey } from '../../../../common/CalendarWidget/BookingWorkflows/ScheduleSuggestor/interface';
import { CARE_TEAM, CARE_TEAM_MEMBER_TYPE, GROUP_TYPE_CODES, USER_PREFERENCE_CODE } from '../../../../../constants/MlovConst';
import ConversationTaskResourcesQueries from '../../../../../services/Conversations/ConversationTaskResourcesQueries';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {IMessagingWindowLocalEventData} from '../ConversationContainer/ConversationList/ConversationListEventHook/interface';
import {localBroadcastEvent} from '../../../../../utils/CustomEventHandler';
import MessageBoxActionViewDrawer from '../../../../common/ChatUI/MessageBox/MessageBoxActionViewDrawer';
import NewMessageReceived from './NewMessageReceived/NewMessageReceived';
import EmployeeQueries from '../../../../../services/Employee/EmployeeQueries';
import {goToMessages} from '../../../../common/ChatUI/MessageBox/MessageBoxUtils';
import { EmailInboxFooter } from './EmailInboxFooter';
import { EmailDrawerCommonV2 } from '../../../../common/EmailDrawerCommonV2';
import {EMAIL_REPLY_ACTIONS, MESSAGE_DRAFT_TYPES} from '../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Const';
import {IEmailReplyAction} from '../../../../common/EmailDrawerCommonV2/interfaces';
import MessagingWindowHeaderAction from './MessagingWindowHeaderAction';
import EFaxMessagingWindowFooter from './EFaxMessagingWindowFooter';
import {readMessageEntriesInMessageLastSeen} from '../../../../common/ChatUI/MessageBox/MessageReadInfo/MessageReadInfoService';
import StickyNoteMessageView from '../../../../common/ActionMessageView/StickyNoteMessageView';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import { useToast as useCustomToast } from '../../../../Toast/ToastProvider';
import { Dimensions } from 'react-native';
import ConversationsQueriesV2 from '../../../../../services/Conversations/V2/ConversationsQueriesV2';
import useMessagingAdditionalData from './useMessagingAdditionalData';
import { uniqBy } from 'lodash';
import { GET_CARE_TEAM_MEMBER_TYPE } from '../../../../../services/CareTeam/CareTeamQueries';
import { ICareTeamMemberType } from '../../../../common/MemebersView/interface';
import { IUser } from '../../../Contacts/TeamMembers/interfaces';
import { GET_USER_BY_USER_ID } from '../../../../../services/User/UserQueries';
import { MessagingEventQueue } from '../MessagingEventQueue/MessagingEventQueue';
import SelectChatActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SelectChatActionSvg';
import CreateTaskSvg from '../../../../common/Svg/CreateTaskSvg';
import CreateNoteSvg from '../../../../common/Svg/CreateNoteSvg';
import CopyActionSvg from '../../../../common/Svg/PersonActionSvgIcons/CopyActionSvg';
import {IStickyNoteData} from '../../../../common/ActionMessageView/interfaces';

const MessagingWindow = (props: IMessagingWindowProps) => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const isComponentMounted = useRef(false);
  const eventQueueRef = useRef(new MessagingEventQueue({ moduleCode: props.moduleCode || 'MessagingWindow' }));
  const eventQueue = eventQueueRef?.current;

  const isRefreshConversationAfterOfflineOnlineEnabled = isAccountConfigEnabled(CONFIG_CODES.REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE);
  const isDisableConversationOnPhoneNumberMissMatch = isAccountConfigEnabled(CONFIG_CODES.DISABLE_CONVERSATION_ON_PHONE_NUMBER_MISS_MATCH);
  const {
    isDisplayHeader,
    selectedConversation,
    onConversationActionPerformed,
    selectedTabCode,
    selectedInboxTypeCode,
    conversationInbox,
    showInfoIconInHeader,
    isDetailsContainerVisible,
    isInstantChatView,
    parentCode,
    isSeachMsgContainerVisible,
    searchMessage,
  } = props;
  const currentEHR = getCurrentEHR();
  const isAthena = currentEHR === EHRName.ATHENA;
  const archiveOnSendConfig = getConfigDataFromCode(CONFIG_CODES.ARCHIVE_CONVERSATION_ON_SEND_MESSAGE);
  const isArchiveEnable = isArchiveOnSendEnable(archiveOnSendConfig) && !isInternalChat(selectedConversation);
  const [messagingWindowState, setMessagingWindowState] = useState<{
    messagingWindowLoading: boolean;
    selectedConversationData: IConversationData;
    stickyNoteData: IStickyNoteData;
    isLoggedInUserGroupMember: boolean;
    conversationMembers: any[];
    conversationDraftMessage: string;
    conversationDraftMessageLoading: boolean;
    totalScheduleMessage: number;
    totalScheduleFailedMessage: number;
    pcpUserData: IUser;
    groupMemberLoading: boolean;
    footerLoading: boolean;
  }>({
    messagingWindowLoading: false,
    selectedConversationData: selectedConversation,
    stickyNoteData: {} as IStickyNoteData,
    isLoggedInUserGroupMember: false,
    conversationMembers: [] as any,
    conversationDraftMessage: '',
    conversationDraftMessageLoading: false,
    totalScheduleMessage: 0,
    totalScheduleFailedMessage: 0,
    pcpUserData: {} as IUser,
    groupMemberLoading: true,
    footerLoading: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER === props.selectedTabCode || selectedConversation?.inboxId > 1 ? true : false
  })
  const [selectedActionView, setSelectedActionView] = useState('');
  const [showModal, setShowModal] = useState({
    taskModal: false as boolean,
    noteModal: false as boolean,
    messageReadInfo: false as boolean,
    messageData: {} as Record<string, any>,
    assignTypeDrawer: false,
    actionCode: '',
  });

  const [taskDetails, setTaskDetails] = useState({
    actionCode: '',
    taskModal: false,
    viewTaskModal: false,
    assignTypeDrawer: false,
    newTaskData: {} as any,
    taskList: [] as ITask[],
    task: {} as ITask | undefined,
    messageUuid: '',
  });

  const [emailDrawerState, setEmailDrawerState] = React.useState({
    isSendEmailDrawerOpen: false,
    isReplyAll: false,
    isInternalMessage: false,
    forwardMessageId: '',
    replyToMessageId: '',
    contactIds: [] as number[],
  });

  const messageBus = MessageBus.getMessageBusInstance();
  const intl = useIntl()

  const showConversationTaskCount = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_TASK_COUNT_ON_MESSAGE
  );
  const [offset, setOffset] = useState({
    mentionPrevOffset: 0,
    mentionNextOffset: 0,
    otherChatOffset: 0,
  })
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const userId = getUserId();
  const userUuid = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const showInfoOnHeaderData = showInfoOnMessageHeader();
  const enableShowAccountNameOnlyToPatient =
    commonData?.userSettings[
      'mask_sender_user_with_account_in_patient_app_incoming_messages'
    ]?.value === 'True' || false;
  const mlovData = commonData.MLOV;
  const userData = commonData?.userData || ({} as ILoginUserData);
  const toast = useToast();
  const customToast = useCustomToast();
  const accountId = getAccountId();
  const accountuuid = getAccountUUID();
  const showPracticeMessageTogether = !isInternalChat(selectedConversation) && getFeatureFlag(CONFIG_CODES.SHOW_PRACTICE_MESSAGE_TOGETHER, commonData);
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const ehrConfig = getEhrConfig();
  const isFold = ehrConfig.isFold;
  const isElation = ehrConfig.isElation;
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );

  const careTeamMlovList =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];

  const primaryCareTeamTypeId = getMlovIdFromCode(
    careTeamMlovList,
    CARE_TEAM.CODE
  );

  const isEFaxChannel= isEFaxChannelInboxType(selectedConversation?.conversationInbox?.channelType);
  const isValidEFaxContactInboxSourceId= isValidEFaxContactInboxSourceIdFromString(selectedConversation?.contactInbox?.sourceId);
  const {height} = Dimensions.get('window');
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW },
  ]);
  const isSmallScreen = isSmallWindow;
  const heightMaxPercent = isSmallScreen ? .65 : .725;
  const [msgWindowHeight, setMsgWindowHeight] = useState(height * heightMaxPercent);

  const [msgData, setMsgData] = useState<{
    displayData: IMessageBoxData[];
    apiData: IConversationMessage[];
    unreadMsgIdList: IUnreadMessages[];
    loadingMsgs: boolean;
    fetchMoreMsgs: boolean;
    isDrawerVisible: boolean;
    groupUpdatedCount: number;
    showEditView: boolean;
    memberPupsubToken: string[];
    selectedMessage: any;
    selectedReplyMsg: IReplyMessageObject;
    messageAttachments: any;
    conversationInbox: IConversationInboxs;
    mentionMessage: string;
    lastConversationMsgUuid: string;
    isExternalUser?: boolean;
    userIdForGetAccountUsersById?: string;
    loadingLoadMore?: boolean;
    archiveOnSendData?: IUserPreferences;
    isNewLineOnEnter?: IUserPreferences;
    newMessageUuids: string[];
    loadingLatestMessages: boolean;
    clickedMessageUuid: string;
    clickEventId: string;
    areMessagesRemaining: boolean,
    showPhiWarning: boolean,
  }>({
    displayData: [],
    apiData: [],
    unreadMsgIdList: [],
    loadingMsgs: true,
    fetchMoreMsgs: true,
    isDrawerVisible: false,
    groupUpdatedCount: 0,
    memberPupsubToken: [],
    selectedReplyMsg: {} as IReplyMessageObject,
    showEditView: false,
    selectedMessage: null,
    messageAttachments: [],
    conversationInbox: {} as IConversationInboxs,
    mentionMessage: '',
    lastConversationMsgUuid: '',
    isExternalUser: false,
    userIdForGetAccountUsersById: '',
    loadingLoadMore: false,
    newMessageUuids: [],
    loadingLatestMessages: false,
    clickedMessageUuid: '',
    clickEventId: '',
    areMessagesRemaining: true,
    showPhiWarning: false,
  });

  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const headerContainerRef: any = useRef();
  const mentionContainerRef: any = useRef();

  const copyMessageRef = useRef<NodeJS.Timeout>();
  const updateLastSeenRef = useRef<NodeJS.Timeout>();
  const refetchRef = useRef<NodeJS.Timeout>();

  const [stateData, setStateData] = useState({
    isShowFooter: true,
    isBlockNumber: false,
    isConsentGiven: true,
    redirectToMention: true,
    isAnyMsgSenderDeleted: false,
    actionMessageCode: '',
    actionMessageLoading: true,
  })
  const isMasterAccountFlag = isMasterAccount();
  const [deleteMessageModal, setDeleteMessageModal] = useState<any>(false);
  const getMessageActionList = () => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
      const internalChannelAction = [
        MessageActionID.reply,
        MessageActionID.edit,
        MessageActionID.delete,
        MessageActionID.info,
        MessageActionID.markAsReadUnread,
      ];
      if (!isMasterAccountFlag) {
        internalChannelAction.splice(4, 0, MessageActionID.createTask);
      }
      return internalChannelAction
    } else {
      const channelAction = [
        MessageActionID.reply,
        MessageActionID.edit,
        MessageActionID.delete,
        // MessageActionID.createNote, // kept for future use
        MessageActionID.info,
        MessageActionID.markAsReadUnread,
        MessageActionID.assignType,
      ];
      if (!isMasterAccountFlag) {
        channelAction.splice(3, 0, MessageActionID.createTask);
      }
      return channelAction
    }
  };
  const [GetAccountUsersByUserUuid, {loading: isGetAccountUsersByUserUuidLoading, called: isisGetAccountUsersByUserUuidCalled}] = useLazyQuery(UserQueries.GetAccountUsersByUserUuid, {
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
    onCompleted: async (data: any) => {
      if (data?.accountUsers.length) {
        const accountUsersRespObj = data?.accountUsers[0]
        const isExternalUser = accountUsersRespObj?.externalUserId ? true : false;
        if (isComponentMounted?.current) {
          setMsgData(prev => {
            return {
              ...prev,
              isExternalUser: isExternalUser
            }
          });
        }
      }
    }
  })


  const [getMessageByMentionId] = useLazyQuery(ConversationsQueries.GetMessageByMentionId,{
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
  });



  const [deleteMessageQuery] = useMutation<any>(InboxQueries.deleteMessage, {
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
  });
  const [checkNumberIsBlocked] = useLazyQuery(InboxQueries.CheckBlockNumber, {
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
  });
  const [CreateConversationTask] = useMutation(
    ConversationTaskResourcesQueries.CREATE_CONVERSATION_TAGGED_ITEM,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [addOrUpdatePreferences] = useMutation(
    UserQueries.ADD_OR_UPDATE_USER_PREFERENCE,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );
  const [GetMentionsConversationsByMessageAndConversationId] = useLazyQuery(ConversationsQueries.GetMentionsConversationsByMessageAndConversationId, {
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
  });

  const [GetContact] = useLazyQuery(LeadQueries.GetContactAndSourceId, {
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
  });

  const [getEmployeeConsent] = useLazyQuery(
    EmployeeQueries.getEmployeeConsent,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );

  const [getMessageByMessageUuid] = useLazyQuery(
    ConversationsQueries.GetMessageByMessageUuid,
    {
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );

  const [getUserPreference] = useLazyQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUuid,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
    onCompleted: (data) => {
      if (
        data?.userPreferences?.length > 0 &&
        data?.userPreferences?.[0]?.preferencesJson
      ) {
        const preferenceData = data?.userPreferences?.[0];
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              archiveOnSendData: preferenceData,
              isNewLineOnEnter: preferenceData,
            };
          });
        }
        LocalStorage.setItem(
          `messaging_user_perference_${userUuid}_${accountId}`,
          JSON.stringify(preferenceData)
        );
      }
    },
  });

  const [GetMessageDataForSelectedChat] =
    useLazyQuery<ISelectedConversationResponse>(
      ConversationsQueriesV2.GetMessageDataForSelectedChat,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal
          }
        },
      }
    );

    const [GetMessageDataForSelectedInternalChat] =
    useLazyQuery<ISelectedConversationResponse>(
      ConversationsQueriesV2.GetMessageDataForSelectedInternalChat,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal
          }
        },
      }
    );

  const [GetMessageDataForSelectedConversationInbox] =
    useLazyQuery<ISelectedConversationResponse>(
      ConversationsQueriesV2.GetMessageDataForSelectedConversationInbox,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal
          }
        },
      }
    );
  // const [GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS] = useLazyQuery(ConversationsQueries.GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS, {
  //   fetchPolicy: 'no-cache',
  // })


  const [GetGroupMembersByConversationUuid] = useLazyQuery(
    ConversationsQueriesV2.GetGroupMembersByConversationUuid,
    {
      variables: {
        conversationUuid: selectedConversation?.uuid,
      },
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );

  const getUserPreferenceData = async() => {
    const preferenceDataString = await LocalStorage.getItem(`messaging_user_perference_${userUuid}_${accountId}`);
    const preferenceData = JSON.parse(preferenceDataString);
    if (preferenceData?.id) {
      if (isComponentMounted?.current) {
        setMsgData(prev => {
          return {
            ...prev,
            archiveOnSendData: preferenceData,
            isNewLineOnEnter: preferenceData,
          }
        })
      }
    } else {
      getUserPreference()
    }
  }

  const setConversationSavedText = async () => {
    try {
      if (isComponentMounted?.current) {
        setMessagingWindowState((prev) => {
          return {
            ...prev,
            conversationDraftMessageLoading: true,
          }
        });
      }
      const savedMsgData = await getInitialMessageValueIfSaved(messagingWindowState?.selectedConversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT, abortControllerRef?.current?.signal);
      if (savedMsgData?.content) {
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              conversationDraftMessage: savedMsgData?.content,
              conversationDraftMessageLoading: false,
            }
          });
        }
      } else {
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              conversationDraftMessage: '',
              conversationDraftMessageLoading: false,
            }
          });
        }
      }
    } catch (error) {
      if (isComponentMounted?.current) {
        setMessagingWindowState((prev) => {
          return {
            ...prev,
            conversationDraftMessage: '',
            conversationDraftMessageLoading: false,
          }
        });
      }
    }
  }

  const getPCPDetailsV2 = async () => {
    if (contactData?.uuid) {
      try {
        const pcpUserData = await getContactPCPData({
          contactUuid: contactData?.uuid,
          careTeamTypeId: primaryCareTeamTypeId,
        }, abortControllerRef?.current?.signal);
        if (pcpUserData?.id) {
          if (isComponentMounted?.current) {
            setMessagingWindowState((prev) => {
              return {
                ...prev,
                pcpUserData: pcpUserData,
              };
            });
          }
        }

      } catch (e) {
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              pcpUserData: {} as IUser,
            };
          });
        }
      }
    }
  };

  const getGroupMemberData = async () => {
    if (isComponentMounted?.current) {
      setMessagingWindowState((prev) => {
        return {
          ...prev,
          groupMemberLoading: true,
        };
      });
    }
    // This call due to read receipt
    try {
      const groupMemberData = await GetGroupMembersByConversationUuid();
      if (
        groupMemberData?.data?.groupConversations?.[0]?.groupMembers?.length
      ) {
        const groupMembers = groupMemberData?.data?.groupConversations?.[0]?.groupMembers;
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev: any) => {
            const conversationData = prev?.selectedConversationData;
            const updatedConversation = {
              ...conversationData,
              groupConversation: {
                ...conversationData?.groupConversation,
                groupMembers: groupMembers,
              },
            };
            return {
              ...prev,
              selectedConversationData: updatedConversation,
              groupMemberLoading: false,
            };
          });
        }
      }
    } catch (e) {
      if (isComponentMounted?.current) {
        setMessagingWindowState((prev) => {
          return {
            ...prev,
            groupMemberLoading: false,
          };
        });
      }
    }
  };

  useEffect(() => {
    isComponentMounted.current = true;
    getUserPreferenceData()
  }, [isArchiveEnable]);

  const updateViewedFeatureList = async (isArchive: boolean, isNewLineOnEnter?: boolean) => {
    const prevJSON: IUserMsgSettings = msgData?.archiveOnSendData?.preferencesJson
      ? JSON.parse(msgData?.archiveOnSendData?.preferencesJson)
      : {};
    const newJSON: IUserMsgSettings = {
      ...prevJSON,
      userMessages: {
        [FeatureKey.isArchiveConversationOnMessageSend]: isArchive,
        [FeatureKey.isNewLineOnEnter]: isNewLineOnEnter,
      },
    };
    const data = {
      object: {
        userId: userUuid,
        userPreferencesTypeId: userPreferenceId,
        preferencesJson: JSON.stringify(newJSON),
        id: msgData?.archiveOnSendData?.id || msgData?.isNewLineOnEnter?.id || undefined,
      },
    };
    const updatePref = await addOrUpdatePreferences({variables: data});
    if (updatePref?.data?.createUserPreference?.id) {
      getUserPreference();
    }
  }


  const checkIsLoggedInUserGroupMember = (conversationInbox?: any, groupMembersData?: any) => {
    if (isComponentMounted?.current) {
      setMessagingWindowState((prev) => {
        let isLoggedInUserGroupMember = prev?.isLoggedInUserGroupMember;
        const groupMembers = groupMembersData || prev?.selectedConversationData?.groupConversation?.groupMembers || [];
        const inboxMembers = conversationInbox?.inboxMembers || prev?.selectedConversationData?.conversationInbox?.inboxMembers || []
        if (selectedConversation?.groupConversation?.id && groupMembersData) {
          isLoggedInUserGroupMember = isGroupMember(userUuid, groupMembers)
        } else if (selectedConversation?.conversationInbox?.id) {
          isLoggedInUserGroupMember = isInboxMember(
            userUuid,
            inboxMembers
          );
        }
        return {
          ...prev,
          isLoggedInUserGroupMember: isLoggedInUserGroupMember,
        }
      });
    }
  }

  const updateData = (data: any, replyObject: any) => {
    if (data?.isAttachment) {
      const newAttachment = data?.msgAttachment.filter((attachment: any) => {
        return attachment?.id === data?.selectedAttachment;
      });
      delete data?.attachments;
      data = {...data, attachments: newAttachment};
      return data;
    } else return replyObject;
  };

  const onSelectedAction = (
    action: any,
    item?: any,
    replyObject?: IReplyMessageObject,
    extraData?: any
  ) => {
    switch (action) {
      case MessageActionID.reply:
        const updatedData = updateData(item, replyObject);
        if (isComponentMounted?.current) {
          setMsgData((prev: any) => {
            return {
              ...prev,
              selectedReplyMsg: updatedData,
            };
          });
        }
        break;
      case MessageActionID.createTask:
        const displayContent = isChannelEmail(
          selectedConversation?.conversationInbox?.channelType
        )
          ? selectedConversation?.additionalAttributes?.mail_subject || ''
          : item.displayContent;
        const newTaskData = {...item, displayContent: displayContent};
        setTaskDetails((prev) => {
          return {
            ...prev,
            actionCode: COMMON_ACTION_CODES.CREATE_TASK,
            viewTaskModal: true,
            newTaskData: newTaskData,
            messageUuid: item?.uuid,
          };
        });
        break;
      case MessageActionID.createNote:
        setShowModal({
          ...showModal,
          noteModal: true,
          messageData: item,
        });
        break;
      case MessageActionID.info:
        setShowModal((prev) => {
          return {
            ...prev,
            actionCode: COMMON_ACTION_CODES.MESSAGE_INFO,
            messageReadInfo: true,
            messageData: item,
          }
        })
        break;
      case MessageActionID.edit:
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              showEditView: true,
              selectedMessage: item,
            };
          });
        }
        break;
      case MessageActionID.delete:
        setDeleteMessageModal(item);
        break;
      case MessageActionID.share:
        break;
      case MessageActionID.assignType:
        setShowModal((prev) => {
          return {
            ...prev,
            assignTypeDrawer: true,
            messageData: item,
          };
        });
        break
      case MessageActionID.confirmedDelete:
        handleDeleteMessage(item)
        break

      case MessageActionID.emailReply:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            isReplyAll: false,
            replyToMessageId: item.uuid,
            forwardMessageId: '',
            isSendEmailDrawerOpen: true,
            contactIds: [props.selectedConversation.conversationContact.id || props.selectedConversation?.contactId],
          };
        });
        break;

      case MessageActionID.forward:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            replyToMessageId: '',
            isReplyAll: false,
            forwardMessageId: item.uuid,
            isSendEmailDrawerOpen: true,
            contactIds: [],
          };
        });
        break;

      case MessageActionID.replyAll:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            isReplyAll: true,
            replyToMessageId: item.uuid,
            forwardMessageId: '',
            isSendEmailDrawerOpen: true,
            contactIds: [props.selectedConversation.conversationContact.id || props.selectedConversation?.contactId],
          };
        });
        break;

      case MessageActionID.markAsReadUnread:
        handleMarkAsUnreadForEmail(item)
        break;

      default:
        return <View />;
        break;
    }
  };

  const handleMarkAsUnreadForEmail = async (item: Record<string, any>) => {
    if (
      !isChannelEmail(props.selectedConversation?.conversationInbox?.channelType)
    ) {
      return;
    }
    const conversationLastActivity =
      new Date(
        item?.updatedDateStr ||
          item?.dateStr ||
          item?.messageData?.updatedAt ||
          new Date(getDateToMomentISOString())
      ).getTime() - 2;
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );
    const updateLastSeenRes: any = await updateConversationLastseenAt({
      accountId: accountuuid,
      conversationId: props.selectedConversation.id,
      conversationUuid: props.selectedConversation.uuid,
      lastseenById: userUuid,
      lastseenByTypeId: userTypeId,
      updatedAt: new Date(conversationLastActivity).toISOString().toString(),
    });
    if (updateLastSeenRes?.data?.count) {
      showToast(
        toast,
        intl.formatMessage({
          id: 'markAsUnreadSuccess',
        }),
        ToastType.success
      );
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(
        SUPPORTED_EVENT_CODE.CONVERSATION_UNREAD_COUNT,
        {}
      );
      const eventConversationData = {
        ...props?.selectedConversation,
        eventCode: SUPPORTED_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE
      }
      eventBus.broadcastEvent(
        SUPPORTED_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE,
        eventConversationData
      );
    }
  };

  const [messageAction, setMessageAction] = useState({
    show: false,
    actionModel: {
      actionList: getMessageActionList(),
      selectedAction: (action: any, item?: any) => {
        onSelectedAction(action, item);
      },
    } as MessageActionViewModal,
  });
  const contactInfo = getContactDataAndType(messagingWindowState?.selectedConversationData);
  let contactData: any = contactInfo.contactData;
  const contactType: any = contactInfo.contactType;
  const formattedContactData = getFormDataFromLeadData(
    contactData || {},
    commonData
  );
  const isRequiredContactConsent = isContactConsentRequired();
  const isContact =
  contactData?.id;

  const isViewStickyNote =
  showInfoOnHeaderData?.ADD_STICKY_NOTE &&
  isContact && !isBroadCastGroup(messagingWindowState?.selectedConversationData as IConversationData) &&
  !isInternalChat(messagingWindowState?.selectedConversationData as any);


  const [getConversationMessages] = useLazyQuery<IConversationMessageResponse>(
    ConversationsQueriesV2.GetConversationMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
      variables: {
        id: props?.selectedConversation?.id,
        loginUserId: userId,
        loginUserUuid: userUuid,
        limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
        offset: 0,
      },
    }
  );

  const [GetMentionConversationMessages] = useLazyQuery<IConversationMessageResponse>(
    ConversationsQueriesV2.GetMentionConversationMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
      variables: {
        id: props?.selectedConversation?.id,
        loginUserId: userId,
        loginUserUuid: userUuid,
        limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
        offset: 0,
      },
    }
  );

  const [getLastConversationMessages] = useLazyQuery<IConversationMessageMentionPrevResponse>(
    ConversationsQueries.GetConversationLastMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
      variables: {
        id: props?.selectedConversation?.id,
        limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
      },
    }
  );

  // created different function due to function overrides data of two different calls.
  const [getLastFiveConversationMessages] = useLazyQuery<IConversationMessageMentionPrevResponse>(
    ConversationsQueries.GetConversationLastMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
      variables: {
        id: props?.selectedConversation?.id,
        limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
      },
    }
  );

  const [getPrevConversationMentionMessages] = useLazyQuery<IConversationMessageMentionPrevResponse>(
    ConversationsQueries.GetPrevConversationMentionMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );

  const [getNextConversationMentionMessages] = useLazyQuery<IConversationMessageMentionPrevResponse>(
    ConversationsQueries.GetNextConversationMentionMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );

  const [getGroupConversationByUuid] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetGroupConversationByUuid,
      {
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal
          }
        },
      }
    );

  const [getInboxConversationByUuid] = useLazyQuery<IConversationResponse>(
    ConversationsQueriesV2.GetInboxConversationByUuid,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  );

  const [getConversationContactByUuid] = useLazyQuery<IConversationResponse>(
    ConversationsQueriesV2.GetConversationContactByUuid,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal
        }
      },
    }
  )

  const getMessagesAdditionalData = async(
    apiMsgResp: IConversationMessageData,
    isFirstBatch: boolean,
    isOlderMsgFetch?: string,
    loadingLatestMessages?: boolean,
  ) => {
    const conversationDisplayId = selectedConversation.displayId;
    const isAnySenderDeleted = checkAnyMessageSenderDeleted(apiMsgResp?.conversationMessages);
    if (isAnySenderDeleted && !stateData.isAnyMsgSenderDeleted) {
      const deletedSenderDetails = await handleDeletedContact(apiMsgResp?.conversationMessages, selectedConversation, isAnySenderDeleted, abortControllerRef?.current?.signal);
      setStateData((prev) => {
        return {
          ...prev,
          isAnyMsgSenderDeleted: true,
        }
      })
      apiMsgResp.conversationMessages = getUpdatedSenderUserData(apiMsgResp?.conversationMessages, deletedSenderDetails)
    }
    const apiRespMsgList = apiMsgResp?.conversationMessages || [];
    const unreadMessages = apiMsgResp.unreadMessages || [];

    if (apiRespMsgList && apiRespMsgList.length) {
      const displayMsgList = getFormattedMessagingList(
        apiRespMsgList,
        [],
        userData,
        showPracticeMessageTogether
      );
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          const sortedDisplayData = sortMessages(
            isFirstBatch
              ? displayMsgList
              : isOlderMsgFetch
              ? isOlderMsgFetch === 'isPrev'
                ? [...prev?.displayData, ...displayMsgList]
                : [...displayMsgList, ...prev?.displayData]
              : [...prev?.displayData, ...displayMsgList]
          )
          return {
            ...prev,
            displayData: sortedDisplayData,
            apiData: isFirstBatch
              ? apiRespMsgList
              : msgData?.apiData.concat(apiRespMsgList),
            unreadMsgIdList: isFirstBatch
              ? unreadMessages
              : msgData?.unreadMsgIdList.concat(unreadMessages),
            loadingMsgs: false,
            loadingLoadMore: false,
            fetchMoreMsgs: true,
            conversationInbox:
              messagingWindowState?.selectedConversationData?.conversationInbox || ({} as IConversationInboxs),
            loadingLatestMessages: false,
          };
        });

        setOffset((prev: any) => {
          return {
            ...prev,
            otherChatOffset: isFirstBatch
              ? apiRespMsgList.length - 1
              : apiRespMsgList.length + offset.otherChatOffset - 1,
          };
        });
      }
      checkIsLoggedInUserGroupMember(apiMsgResp?.conversationInbox)
      updateLastSeenAt(displayMsgList);
      if (
        loadingLatestMessages &&
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
      ) {
        if (displayMsgList?.length && displayMsgList?.[0]?.messageData?.uuid) {
          goToMessages(displayMsgList?.[0]?.messageData?.uuid);
        }
      }
      getAdditionalMessageData(apiMsgResp, isFirstBatch, isOlderMsgFetch)
      const eventBus = EventBus.getEventBusInstance();
      setSearchParamsForSelectedConversation({
        selectedConversation: messagingWindowState?.selectedConversationData,
      }).then(() => {
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.CONVERSATION_CHANGED, {});
      });
    } else {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingMsgs: false,
            fetchMoreMsgs: false,
            displayData: msgData?.displayData,
            loadingLoadMore: false,
            apiData: msgData?.apiData,
            unreadMsgIdList: msgData?.unreadMsgIdList,
            conversationInbox:
              messagingWindowState?.selectedConversationData?.conversationInbox ||
              ({} as IConversationInboxs),
            loadingLatestMessages: false,
          };
        });
      }
      checkIsLoggedInUserGroupMember(apiMsgResp?.conversationInbox)
      updateLastSeenAt(msgData?.displayData);
    }
  }

  const getAdditionalMessageData = async (
    apiResponse: IConversationMessageData,
    isFirstBatch: boolean,
    isOlderMsgFetch?: string
  ) => {
    const updatedMessageList = await getAdditionalDataForMessages({
      apiResponse,
      conversationDisplayId: selectedConversation?.displayId,
      showPracticeMessageTogether,
      conversationUuid: selectedConversation?.uuid
    }, abortControllerRef?.current?.signal);
    const updatedMessages = updatedMessageList?.messageData;
    const sortedDisplayData = sortMessages(
      isFirstBatch
        ? updatedMessages
        : isOlderMsgFetch
        ? isOlderMsgFetch === 'isPrev'
          ? [...msgData?.displayData, ...updatedMessages]
          : [...updatedMessages, ...msgData?.displayData]
        : [...msgData?.displayData, ...updatedMessages]
    );
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          displayData: sortedDisplayData,
        };
      });
    }
  };

  const updateUserLastSeenAtMessageAndConversation = async (displayMsgList?: IMessageBoxData[]) => {
    const groupMemberTypeList =
      getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );
    const readConversationResponse = await readMessageEntriesInMessageLastSeen(selectedConversation.uuid, abortControllerRef?.current?.signal)
    if (readConversationResponse?.id) {
      updateMentionsWhenNotOnMentionTab({displayMsgList: displayMsgList || []})
      updateMentionWhenOnMentionTab();
      onConversationActionPerformed(
        CONVERSATION_ACTION_CODES.MESSAGE_READ,
        messagingWindowState?.selectedConversationData
      );
      sendConversationNotificationNoMessageEvent({
        conversationUuid: selectedConversation.uuid,
        eventCode: SUPPORTED_EVENT_CODE.MESSAGE_READ,
        eventData: {
          conversationUuid: selectedConversation?.uuid,
          userUuid: userUuid,
        }
      });
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            unreadMsgIdList: [],
          };
        });
      }
    }
  };

  const updateLastSeenAt = (displayMsgList?: IMessageBoxData[]) => {
    if (selectedConversation && selectedConversation.id) {
      const lastSeenTimeOut = setTimeout(() => {
        if(isInstantChatView) {
          updateUserLastSeenAtMessageAndConversation(displayMsgList);
        }
      }, LAST_SEEN_UPDATE_TIMEOUT);
      if (updateLastSeenRef?.current) {
        updateLastSeenRef.current = lastSeenTimeOut;
      }
    }
  };
  const getAndSetActionMessage = async (isLoggedInUser: boolean, conversationData?: any) => {
    const selectedConversationData = conversationData ? conversationData : selectedConversation
    let actionMessageCode = '';
    const isLoggedInUserGroupMember = isLoggedInUser;
    const isConversationChatOnly = isPrivateGroup(selectedConversationData as any);
    actionMessageCode = !isSendMessageAllowed(
      selectedConversationData|| ({} as any),
      userUuid,
      isLoggedInUserGroupMember,
      selectedConversationData?.conversationInbox || ({} as any)
    )
      ? ACTION_MESSAGE_CODE.IS_EXIST_IN_CHAT
      : isConversationChatOnly
        ? ACTION_MESSAGE_CODE.IS_ONLINE_CHECK
        : '';
    if (selectedConversation?.conversationInbox?.id) {
      const inboxId = selectedConversation?.conversationInbox?.id;
      const channelType =
        selectedConversation?.conversationInbox?.channelType ==
          CHANNEL_TYPE.CHANNEL_EMAIL ||
          selectedConversation?.conversationInbox?.channelType ==
          CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          ? true
          : false;
      if (channelType) {
        if (selectedConversationData?.conversationInbox?.isDeleted) {
          actionMessageCode = ACTION_MESSAGE_CODE.INBOX_DELETED;
          if (isComponentMounted?.current) {
            setMsgData(prev => {
              return {
                ...prev,
                isShowFooter: false
              }
            })
          }
        }
      }
    }
    if (
      isChannelEmailOrSms(selectedConversation?.conversationInbox?.channelType)
    ) {
      const consentCheck = isRequiredContactConsent
        ? isContactConsentGiven(
            selectedConversationData?.conversationContact
          ) && isRequiredContactConsent
        : true;
      if (
        !selectedConversationData?.conversationContact?.hasOwnProperty(
          'contactConsents'
        )
      ) {
        await checkForConsent();
      } else if (isRequiredContactConsent && !consentCheck) {
        if (isComponentMounted?.current) {
          setStateData((prev) => {
            return {
              ...prev,
              isShowFooter: false,
              isConsentGiven: false,
            };
          });
        }
      }
    }

    if (isChannelTwillioSms(selectedConversation?.conversationInbox?.channelType) && isDisableConversationOnPhoneNumberMissMatch) {
      const isNumberChanged = checkIsPatientNumberChanged(
        selectedConversationData?.contactInbox?.sourceId,
        selectedConversationData?.conversationContact?.phoneNumber
      );
      if (isNumberChanged) {
        actionMessageCode = ACTION_MESSAGE_CODE.PATIENT_NUMBER_CHANGED;
        if (isComponentMounted?.current) {
          setStateData((prev) => {
            return {
              ...prev,
              isShowFooter: false,
            };
          });
        }
      }
    }
    if (isComponentMounted?.current) {
      setStateData((prev) => {
        return {
          ...prev,
          actionMessageCode: actionMessageCode,
          actionMessageLoading: false
        };
      });
      setMessagingWindowState((prev) => {
        return {
          ...prev,
          messagingWindowLoading: false,
        }
      });
    }
  };

  useEffect(() => {
    isComponentMounted.current = true;
    setMsgWindowHeight(height * heightMaxPercent);
    if (props?.selectedConversation?.id && selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      getConversationDataByUuid();
      getSelectedConversationMessageDataWithLastMessage();
      setConversationSavedText();
      // getScheduleMessagesCount();
    }

  }, [props?.selectedConversation?.id, msgData.groupUpdatedCount]);

  useEffect(() => {
    isComponentMounted.current = true;
    if (msgData.userIdForGetAccountUsersById) {
      GetAccountUsersByUserUuid({
        variables: {
          userUuid: msgData.userIdForGetAccountUsersById
        },
      });
    }
  }, [msgData.userIdForGetAccountUsersById]);

  useEffect(() => {
    isComponentMounted.current = true;
    if (selectedConversation?.conversationMentionId && selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      setConversationSavedText();
      // getScheduleMessagesCount();
      getConversationDataByUuid();
      getLastMessage();
      resetAndFetchFirstBatch();
    }
    getMentionMessageByMentionId();
  }, [selectedConversation.conversationMentionId, selectedInboxTypeCode]);


  useEffect(() => {
    isComponentMounted.current = true;
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(WINDOW_EVENT_CODES.PATIENT_UPDATES, onEventReceived);
    return () => {
      isComponentMounted.current = false;
      abortControllerRef?.current?.abort();
      eventBus.removeEventListener(onEventReceived);
      eventQueue.removeAllEventListener();
      eventQueue.removeEventQueue();
      if (copyMessageRef?.current) {
        clearTimeout(copyMessageRef?.current);
      }
      if (updateLastSeenRef?.current) {
        clearTimeout(updateLastSeenRef?.current);
      }
      if (refetchRef?.current) {
        clearTimeout(refetchRef?.current);
      }
      setMsgData(() => {
        return {
          displayData: [],
          apiData: [],
          unreadMsgIdList: [],
          loadingMsgs: true,
          fetchMoreMsgs: true,
          isDrawerVisible: false,
          groupUpdatedCount: 0,
          memberPupsubToken: [],
          selectedReplyMsg: {} as IReplyMessageObject,
          showEditView: false,
          selectedMessage: null,
          messageAttachments: [],
          conversationInbox: {} as IConversationInboxs,
          mentionMessage: '',
          lastConversationMsgUuid: '',
          isExternalUser: false,
          userIdForGetAccountUsersById: '',
          loadingLoadMore: false,
          newMessageUuids: [],
          loadingLatestMessages: false,
          clickedMessageUuid: '',
          clickEventId: '',
          areMessagesRemaining: true,
          showPhiWarning: false,
        };
      });
    };
  }, []);

  useEffect(() => {
    isComponentMounted.current = true;
    const isLoading = msgData.loadingMsgs || msgData.loadingLoadMore || false;
    eventQueue.updateLoadingStatus({ isLoading: isLoading });
  }, [ msgData.loadingMsgs, msgData.loadingLoadMore ]);

  useEffect(()=> {
    setStateData((prev)=> {
      return {
        ...prev,
        redirectToMention: true,
      }
    })
  },[selectedConversation?.conversationMentionId])

  const onEventReceived = useCallback(
    (data) => {
      if (data?.fromSection !== 'MESSAGES_VIEW' && isChannelEmailOrSms(selectedConversation?.conversationInbox?.channelType)) {
        getContactData();
      }
    },
    []
  );

  useEffect(() => {
    isComponentMounted.current = true;
    if(searchMessage?.uuid) {
      if (isComponentMounted?.current) {
        setMsgData(prev => {
          return {
            ...prev,
            clickEventId: searchMessage.clickEventId || '',
            newMessageUuids: [],
          }
        });
      }
      getMsgDataOfNextAndPrevMessages(searchMessage)
    }
  },[searchMessage?.clickEventId])

  const getConversationDataByUuid = async() => {
    try{
      let conversationData = selectedConversation
      if (selectedConversation?.inboxId === -1) {
        const conversationResponse = await getGroupConversationByUuid({
          variables:{
            conversationUuid: selectedConversation?.uuid,
            loginUserUuid: userUuid,
            loginUserId: userId
          }
        })
        if (conversationResponse?.data?.conversations?.[0]) {
          if (selectedConversation?.groupConversation?.groupType?.code === GROUP_TYPE_CODES.INTERNAL) {
            const groupConversation = conversationResponse?.data?.conversations?.[0]?.groupConversation
            const groupMembers =  [
              ...groupConversation?.groupMembers,
              ...(selectedConversation?.groupConversation?.groupMembers || [])
            ]
            const uniqueGroupMember= uniqBy(groupMembers,(item) =>{
              return item?.groupUserId;
            })
            const updatedGroupConversation = {
              ...groupConversation,
              groupMembers: uniqueGroupMember
            }
            conversationData = {
              ...selectedConversation,
              conversationContact: conversationResponse?.data?.conversations?.[0]?.conversationContact,
              groupConversation: updatedGroupConversation,
              unreadMessages: conversationResponse?.data?.conversations?.[0]?.unreadMessages
            }
          } else {
            conversationData = {
              ...selectedConversation,
              conversationContact: conversationResponse?.data?.conversations?.[0]?.conversationContact,
              groupConversation: conversationResponse?.data?.conversations?.[0]?.groupConversation,
              unreadMessages: conversationResponse?.data?.conversations?.[0]?.unreadMessages
            }
          }
        }
      } else {
        const conversationResponse = await getInboxConversationByUuid({
          variables:{
            conversationUuid: selectedConversation?.uuid,
            loginUserUuid: userUuid,
            loginUserId: userId
          }
        })
        if (conversationResponse?.data?.conversations?.[0]) {
          conversationData = {
            ...selectedConversation,
            conversationContact: conversationResponse?.data?.conversations?.[0]?.conversationContact,
            conversationInbox: conversationResponse?.data?.conversations?.[0]?.conversationInbox,
            unreadMessages: conversationResponse?.data?.conversations?.[0]?.unreadMessages,
            contactInbox: conversationResponse?.data?.conversations?.[0]?.contactInbox
          }
          if (isChannelTwillioSms(selectedConversation?.conversationInbox?.channelType)) {
            await checkInboxNumberIsBlocked(conversationData)
          }
        }
      }
      const isLoggedInUserGroupMember = checkIsLoggedInUserConversationMember(conversationData, userUuid)
      if (isLoggedInUserGroupMember) {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ,
          {
            selectedConversation: messagingWindowState?.selectedConversationData,
            eventCode: CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ,
            contactId: messagingWindowState?.selectedConversationData?.contactId,
            accountId: accountId,
          }
        )
      }



      if (showInfoOnHeaderData?.SHOW_PCP) {
        getPCPDetailsV2();
      }
      if (selectedConversation?.inboxId === -1) {
        // This call due to read receipt CRM-8067
        getGroupMemberData();
      }
      if (conversationData?.id) {
        getAndSetActionMessage(isLoggedInUserGroupMember, conversationData);
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              selectedConversationData: conversationData,
              isLoggedInUserGroupMember: isLoggedInUserGroupMember,
              footerLoading: false,
            }
          });
        }
      } else {
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              messagingWindowLoading: false,
            }
          });
        }
      }

    } catch(e) {
      if (isComponentMounted?.current) {
        setMessagingWindowState((prev) => {
          return {
            ...prev,
            footerLoading: false,
          };
        });
      }
    }
  }

  const getGroupMessageData = async () => {
    if (isInternalChat(messagingWindowState?.selectedConversationData)) {
      const internalChatConversationResponseWithOtherData =
        await GetMessageDataForSelectedInternalChat({
          variables: {
            limit: 30,
            offset: 0,
            lastLimit: 1,
            loginUserId: userId,
            loginUserUuid: userUuid,
            conversationId: selectedConversation?.id,
            conversationUuid: selectedConversation?.uuid,
          },
        });
      return internalChatConversationResponseWithOtherData;
    } else if (
      messagingWindowState?.selectedConversationData?.groupConversation
        ?.groupType?.code === GROUP_TYPE_CODES.PRIVATE
    ) {
      const groupChatConversationResponseWithOtherData =
        await GetMessageDataForSelectedChat({
          variables: {
            limit: 30,
            offset: 0,
            lastLimit: 1,
            loginUserId: userId,
            loginUserUuid: userUuid,
            conversationId: selectedConversation?.id,
            conversationUuid: selectedConversation?.uuid,
            contactUuid: selectedConversation?.conversationContact?.uuid,
          },
        });
      return groupChatConversationResponseWithOtherData;
    }
  };

  const getSelectedConversationMessageDataWithLastMessage = async () => {
    try {
      if (selectedConversation?.id) {
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              loadingMsgs: true,
              fetchMoreMsgs: true,
              apiData: [],
              displayData: [],
              loadingLoadMore: false,
              unreadMsgIdList: [],
              groupUpdatedCount: msgData.groupUpdatedCount,
            };
          });
          setOffset((prev: any) => {
            return {
              ...prev,
              otherChatOffset: 0,
            };
          });
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              messagingWindowLoading: true,
            };
          });
        }
        let contactStickyNote = {};
        if (selectedConversation?.inboxId === -1) {
          const groupChatConversationResponseWithOtherData = await getGroupMessageData();
          const lastConversationMsgUuid =
            groupChatConversationResponseWithOtherData?.data?.lastMessage?.lastMsg?.[0]?.uuid || '';
          if (groupChatConversationResponseWithOtherData?.data?.stickyNotes?.length && groupChatConversationResponseWithOtherData?.data?.stickyNotes[0]?.noteUuid) {
            contactStickyNote = groupChatConversationResponseWithOtherData?.data?.stickyNotes[0];
          }
          if (groupChatConversationResponseWithOtherData?.data?.conversationMessagesData) {
            getSelectedConversationMessageData(
              groupChatConversationResponseWithOtherData?.data?.conversationMessagesData,
              lastConversationMsgUuid
            );
          }
        } else {
          const channelConversationResponseWithOtherData = await GetMessageDataForSelectedConversationInbox({
            variables: {
              limit: 30,
              offset: 0,
              lastLimit: 1,
              loginUserId: userId,
              loginUserUuid: userUuid,
              conversationId: selectedConversation?.id,
              conversationUuid: selectedConversation?.uuid,
              contactUuid: selectedConversation?.conversationContact?.uuid,
            },
          });
          const lastConversationMsgUuid = channelConversationResponseWithOtherData?.data?.lastMessage?.lastMsg?.[0]?.uuid || '';
          if (channelConversationResponseWithOtherData?.data?.stickyNotes?.length && channelConversationResponseWithOtherData?.data?.stickyNotes[0]?.noteUuid) {
            contactStickyNote = channelConversationResponseWithOtherData?.data?.stickyNotes[0];
          }
          if (channelConversationResponseWithOtherData?.data?.conversationMessagesData) {
            getSelectedConversationMessageData(
              channelConversationResponseWithOtherData?.data?.conversationMessagesData,
              lastConversationMsgUuid
            );
          }
        }

        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              stickyNoteData: contactStickyNote as any,
              messagingWindowLoading: false,
            };
          });
        }
      }
    } catch (e) {
      if (isComponentMounted?.current) {
        setMessagingWindowState((prev) => {
          return {
            ...prev,
            messagingWindowLoading: false,
          };
        });
      }
    }
  };

  const getSelectedConversationMessageData = async (
    conversationMessageData: IConversationMessageData,
    lastConversationMsgUuid: string
  ) => {
    if (
      conversationMessageData &&
      conversationMessageData?.conversationMessages.length <
        MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE &&
      lastConversationMsgUuid
    ) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            areMessagesRemaining: false,
            lastConversationMsgUuid: lastConversationMsgUuid,
          };
        });
      }
    } else if (lastConversationMsgUuid) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingMsgs: false,
            fetchMoreMsgs: false,
            loadingLatestMessages: false,
            lastConversationMsgUuid: lastConversationMsgUuid,
          };
        });
      }
    } else {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingMsgs: false,
            fetchMoreMsgs: false,
            loadingLatestMessages: false,
          };
        });
      }
    }
    const listOfParentId = conversationMessageData?.conversationMessages
      ?.filter((messageObj) => {
        return messageObj?.parentMessage?.id;
      })
      .map((messageObj) => {
        return messageObj?.parentMessage?.id;
      });
    if (listOfParentId.length) {
      const response = await getMessagesByMessageIds({
        variables: {
          messageIds: listOfParentId,
          accountId: accountId,
          conversationId: selectedConversation.id,
        },
      });
      const parentMessageList = response.data?.messages?.length
        ? response.data?.messages
        : [];
      conversationMessageData = getUpdatedConversationResponse(
        conversationMessageData,
        parentMessageList
      );
    }
    if (conversationMessageData) {
      getMessagesAdditionalData(conversationMessageData, true, undefined);
    }
  };

  const checkInboxNumberIsBlocked = async (conversationData: IConversationData) => {
    if (
      conversationData?.conversationInbox?.channelTwilioSms?.phoneNumber &&
      conversationData?.conversationContact?.phoneNumber
    ) {
      const numberBlockRes = await checkNumberIsBlocked({
        variables: {
          fromNumber: getFormattedToNumber(
            conversationData?.conversationInbox?.channelTwilioSms.phoneNumber?.toString()
          ),
          toNumber: getFormattedToNumber(
            conversationData?.conversationContact?.phoneNumber
          ),
        },
      });
      if (numberBlockRes?.data?.blockNumbers?.length) {
        if (numberBlockRes.data.blockNumbers[0]?.id) {
          setStateData((prev) => {
            return {
              ...prev,
              isBlockNumber: true,
            };
          });
        }
      }
    }
  };

  const updateMentionsWhenNotOnMentionTab = (args: {
    displayMsgList: IMessageBoxData[];
  }) => {
    if (selectedInboxTypeCode == CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      return;
    }
    const {displayMsgList} = args;
    const mentionObjects = getMentionObjects({
      conversationId: selectedConversation.id,
      messageBoxData: displayMsgList,
    });
    messageBus.updateMentionStatus({mentionObjects: mentionObjects});
  };

  const updateMentionWhenOnMentionTab = () => {
    if (selectedConversation.isRead) {
      return;
    }
    if (!selectedConversation.conversationMentionId) {
      return;
    }
    if (selectedInboxTypeCode != CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      return;
    }
    if (!selectedConversation.conversationMessages?.length) {
      return;
    }
    const mentionObject: IMentionObject = {
      conversationId: selectedConversation.id,
      messageId: selectedConversation.conversationMessages?.[0]?.id || 0,
      mentionUuid: selectedConversation.conversationMentionId || '',
      updateOtherCounts: true
    };
    messageBus.updateMentionStatus({mentionObjects: [mentionObject]});
  }

  const [getMessagesByMessageIds] = useLazyQuery(ConversationsQueries.getMessagesByMessageIds, {
    context: {
      fetchOptions: {
        signal: abortControllerRef?.current.signal
      }
    },
  })
  const getPrevConversationMessages = async (limit: number, offset: number, dateTime: string) => {
    const msgResp = await getPrevConversationMentionMessages({
      variables: {
        id: props?.selectedConversation?.id,
        limit: limit,
        offset: offset,
        dateTime: dateTime,
        //getDateStrFromFormat(dateTime, DATE_FORMATS.NEXT_PREV_MESSAGES_DATE_FORMAT),
      },
    });
    if (msgResp?.data?.conversation) {
      const prevMsgData = msgResp?.data?.conversation?.prevMsg || []
      return prevMsgData;
    }
  }

  const getNextConversationMessages = async (limit: number, offset: number, dateTime: string) => {
    const msgResp = await getNextConversationMentionMessages({
      variables: {
        id: props?.selectedConversation?.id,
        limit: limit,
        offset: offset,
        dateTime: dateTime,
      },
    });
    if (msgResp?.data?.conversation) {
      return msgResp?.data?.conversation?.nextMsg || []
    }
  }

  const resetAndFetchFirstBatch = async () => {
    if (props?.selectedConversation?.id) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingMsgs: true,
            fetchMoreMsgs: true,
            apiData: [],
            displayData: [],
            loadingLoadMore: false,
            unreadMsgIdList: [],
            groupUpdatedCount: msgData.groupUpdatedCount,
          };
        });
        setOffset((prev: any) => {
          return {
            ...prev,
            otherChatOffset: 0,
          };
        });
      }

      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        let apiMsgResp = {} as IConversationMessageData;
        Promise.all([
          getPrevConversationMessages(MODULE_PAGINATION_COUNT.MENTION_MESSAGES_PAGE_SIZE, 0, selectedConversation?.conversationMessages[0].createdAt || ''),
          getMessageByMentionId({
            variables: {
              mentionId: selectedConversation.conversationMentionId,
            },
          }),
          getNextConversationMessages(5, 0, selectedConversation?.conversationMessages[0].createdAt || ''),
        ]).then((responses) => {
          const prevMentionResp = responses?.[0]
          const singleMentionMsgResp = responses?.[1]?.data?.messages || [];
          const nextMentionResp = responses?.[2]

          if (prevMentionResp?.length || singleMentionMsgResp?.length || nextMentionResp?.length) {
            const allMsgData = [...prevMentionResp?.reverse() || [], ...singleMentionMsgResp, ...nextMentionResp || []];
            apiMsgResp = {
              conversationMessages: allMsgData.reverse(),
              unreadMessages: []
            }
            setOffset((prev: any) => {
              return {
                ...prev,
                mentionPrevOffset: (prevMentionResp?.length),
                mentionNextOffset: (nextMentionResp?.length)
              };
            });
          }
          if (apiMsgResp) {
            getMessagesAdditionalData(apiMsgResp, true);
          }
        })

      } else {
        getMessages(0, true)
      }
    }
  };

  const onInternalEmailMessageSend = (
    msgText: string,
    msgTempData: IMessageRespData,
    parentMessage?: IReplyMessageObject
  ) => {
    setEmailDrawerState((prev) => {
      return {
        ...prev,
        isInternalMessage: false,
      };
    });
    onMessageSend(msgText, msgTempData, parentMessage);
  };

  const resetClickedMessageUuid = () => {
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          clickedMessageUuid: '',
          clickEventId: ''
        };
      });
    }
  };


  const handleOnScrollToMessageFail = async (msg: ISearchMessageData) => {
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          loadingMsgs: true,
          fetchMoreMsgs: true,
          apiData: [],
          displayData: [],
          unreadMsgIdList: [],
          clickedMessageUuid: msg.uuid || '',
        };
      });
    }
    // setOffset(0);
    let apiMsgResp = {} as IConversationMessageData;
    const responses = await Promise.all([
      getPrevConversationMessages(
        MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE,
        0,
        msg?.createdAt || ''
      ),
      getMessageByMessageUuid({
        variables: {
          messageUuid: msg.uuid,
        },
      }),
      getNextConversationMessages(5, 0, msg?.createdAt || ''),
    ]);
    const prevResp = responses?.[0];
    const singleMessageResp = responses?.[1]?.data?.messages || [];
    const nextResp = responses?.[2];
    if (
      prevResp?.length ||
      singleMessageResp?.length ||
      nextResp?.length
    ) {
      const allMsgData = [
        ...(prevResp?.reverse() || []),
        ...singleMessageResp,
        ...(nextResp || []),
      ];
      apiMsgResp = {
        conversationMessages: allMsgData.reverse(),
        unreadMessages: [],
      };
    }
    if (apiMsgResp) {
      getMessagesAdditionalData(apiMsgResp, true);
    }
  };


  const getMsgDataOfNextAndPrevMessages = (msg: ISearchMessageData) => {
    const index = msgData?.displayData?.findIndex(
      (item) => item?.uuid === msg?.uuid
    );
    if (index === -1) {
      handleOnScrollToMessageFail(msg);
    }
  };

  const onMessageSend = async (
    msgText: string,
    msgTempData: IMessageRespData,
    parentMessage?: IReplyMessageObject
  ) => {
    const msgAttachments = msgTempData?.attachments || [];
    const newMsgObj = getMessageBoxDataObj(
      msgText,
      'right',
      msgTempData.id || Math.random(),
      {
        ...msgTempData,
        sender: {name: userData.name, id: userData.id},
      } as any,
      msgAttachments,
      msgTempData?.currentTime
    );
    if (parentMessage?.id) {
      newMsgObj['parentMessage'] = {
        id: parentMessage.id,
        displayContent: parentMessage.text,
        dateStr: getDateStrFromFormat(parentMessage.dateStr || ''),
        position: 'left',
        senderFullName: parentMessage.senderFullName || 'You',
        messageType: parentMessage.messageType || -1,
        msgAttachment: parentMessage.attachments || [],
        private: parentMessage.private || false,
        messageData: parentMessage,
        senderType: '',
        uuid: parentMessage.uuid,
      };
    }
    const newMsgList = [newMsgObj];
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          displayData: sortMessages([...newMsgList, ...prev.displayData]),
          selectedReplyMsg: {} as IReplyMessageObject,
        }
      });
    }
    //if user cursor not in bottom last message
    const lastMessageId: string = await getLastMessage() || '';
    let isLastMessageExist = false
    if (lastMessageId.trim().length > 0) {
      const lastMessageExist = msgData.displayData.find((singleDisplayData) => {
        return singleDisplayData.uuid === lastMessageId
      })
      if (lastMessageExist?.id) {
        isLastMessageExist = true
      }
    }
    if (!isLastMessageExist) {
      resetAndFetchAllMessages();
    }
    updateUserLastSeenAtMessageAndConversation();
    if (
      isArchiveEnable &&
      isArchiveOnSend(msgData?.archiveOnSendData) &&
      messagingWindowState?.selectedConversationData?.status ===
        CONVERSATION_STATUS.OPEN &&
      (isArchivedChannel(props?.selectedInboxTypeCode || '') ||
        isInstantChatView)
    ) {
      handleArchiveConversationInChannelArchived();
    }
    if (
      isArchiveEnable &&
      isArchiveOnSend(msgData?.archiveOnSendData) &&
      selectedConversation?.status !== CONVERSATION_STATUS.RESOLVED &&
      !isArchivedChannel(props?.selectedInboxTypeCode || '')
    ) {
      handleArchiveConversation();
    }
    if (
      (props?.isInDrawerView || props?.isInstantChatView) &&
      selectedConversation?.status === CONVERSATION_STATUS.RESOLVED &&
      !isArchiveOnSend(msgData?.archiveOnSendData)
    ) {
      showToast(
        customToast,
        intl.formatMessage({id: 'conversationUnarchived'}),
        ToastType.success,
        2000,
        true
      );
    }
  };


  const handleArchiveConversation = async () => {
    const statusCode = CONVERSATION_STATUS_TYPES.CLOSED;
    const updateConversationStatus: any = await updateConversationStatusAPI(statusCode, selectedConversation?.uuid || '')
      .catch((err: any) => {
        if (err?.response?.data?.code === ERROR_CODE.CONVERSATION_ALREADY_RESOLVED) {
          const eventBus = EventBus.getEventBusInstance();
          if (props?.isInDrawerView) {
            onConversationActionPerformed(
              CONVERSATION_ACTION_CODES.DRAWER_CLOSE,
              selectedConversation
            );
          }
        }
      });
    if (updateConversationStatus?.data) {
      if (updateConversationStatus?.data?.conversationData) {
        if (updateConversationStatus?.data?.messageData) {
          localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ARCHIVED, {
            conversation: selectedConversation,
            messageData: updateConversationStatus?.data?.messageData
          });
        }
      }
      showToast(
        toast,
        intl.formatMessage({id: 'archivedSuccessfully'}),
        ToastType.success
      );

      const body = {
        conversationUuid: selectedConversation?.uuid || '',
      };
      const unAssignedResp = await unAssignConversationAPI({
        body,
      });
      if (unAssignedResp?.data?.messageData) {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED, {
          conversation: selectedConversation,
          messageData: unAssignedResp?.data?.messageData
        });
      }

      if (props?.isInDrawerView) {
        onConversationActionPerformed(
          CONVERSATION_ACTION_CODES.DRAWER_CLOSE,
          selectedConversation
        );
      }
    }
  }
  const handleArchiveConversationInChannelArchived = async () => {
    const statusCode = CONVERSATION_STATUS_TYPES.CLOSED;
    const updateConversationStatus: any = await updateConversationStatusAPI(
      statusCode,
      selectedConversation?.uuid || ''
    ).catch((err: any) => {
      if (
        err?.response?.data?.code === ERROR_CODE.CONVERSATION_ALREADY_RESOLVED
      ) {
        if (props?.isInDrawerView || props?.isInstantChatView) {
          onConversationActionPerformed(
            CONVERSATION_ACTION_CODES.DRAWER_CLOSE,
            selectedConversation
          );
        }
      }
    });
    showToast(
      toast,
      intl.formatMessage({id: 'archivedSuccessfully'}),
      ToastType.success
    );
    if (props?.isInDrawerView || props?.isInstantChatView) {
      onConversationActionPerformed(
        CONVERSATION_ACTION_CODES.DRAWER_CLOSE,
        selectedConversation
      );
    }
  };

  const resetAndFetchAllMessages = () => {
    const refetchTimeout = setTimeout(() => {
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION || searchMessage?.uuid) {
        if (msgData.lastConversationMsgUuid !== msgData.displayData?.[1]?.uuid || msgData.lastConversationMsgUuid !== msgData.displayData?.[0]?.uuid) {
          if (isComponentMounted?.current) {
            setMsgData((prev) => {
              return {
                ...prev,
                loadingLatestMessages: true,
                fetchMoreMsgs: true,
              };
            });
          }
          getMessages(0, true);
        }
      }
    }, 100);
    if (refetchRef?.current) {
      refetchRef.current = refetchTimeout;
    }
  };


  const onMessageDelete = (id: any) => {
    if (isComponentMounted?.current) {
      setMsgData((oldData) => {
        const newMsgData = oldData.displayData.filter((item) => item.id !== id);
        newMsgData.forEach((item)=>{
          if (item?.parentMessage?.id && item?.parentMessage?.id === id) {
            if (!item?.parentMessageUuid) {
              item.parentMessageUuid = item?.parentMessage?.uuid
            }
            item.parentMessage = undefined;
          }
        })
        return {
          ...oldData,
          displayData: [...newMsgData],
          showEditView: false,
        }
      });
    }
    updateUserLastSeenAtMessageAndConversation();
  };

  const onAttachmentDelete = (data: any) => {
    if (isComponentMounted?.current) {
      setMsgData((oldData) => {
        const newMsgData = oldData.displayData.map((item) => {
          if (item.id === data?.id) {
            const updatedAttachments = item?.msgAttachment?.filter(
              (attachment: any) => {
                return attachment.id !== data?.selectedAttachment;
              }
            );
            delete item?.msgAttachment;
            item = {...item, msgAttachment: updatedAttachments};
            return item;
          } else {
            return item;
          }
        });
        newMsgData.forEach((item)=>{
          if (item?.parentMessage?.id === data?.id) {
            if (!item?.parentMessageUuid) {
              item.parentMessageUuid = item?.parentMessage?.uuid
            }
            item.parentMessage = undefined;
          }
        })
        return {
          ...oldData,
          displayData: [...newMsgData],
          showEditView: false,
        }
      });
    }

    updateUserLastSeenAtMessageAndConversation();
  };

  const handleSMSDeliveryStatusUpdate = (data: any) => {
    if (!isSMSNotSent(data.status)) {
      return;
    }
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        const id = data.id;
        const status = data.status || '';
        const displayData = [...prev.displayData];
        const idx = displayData.findIndex((item) => item.id === id);
        const messageObj = {...displayData[idx]};
        messageObj.status = status;
        displayData[idx] = messageObj;
        return {
          ...prev,
          displayData: displayData,
        };
      });
    }
  }

  const onMessageUpdate = async (
    data: IMessageCreated
  ) => {
    const msgText = data?.content;
    const id = data?.id;
    if((data?.conversationUuid === selectedConversation?.uuid || data?.conversation_uuid === selectedConversation?.uuid)){
      if (msgText || data?.attachments?.length) {
        if (isComponentMounted?.current) {
          setMsgData((oldData: any) => {
            const newMsgData = oldData.displayData.map((value: IMessageBoxData) => {
              if (value.id == id) {
                value['displayContent'] = msgText;
              }
              if (value.id == id && data?.attachments?.length) {
                value.msgAttachment = data?.attachments;
              }
              if (value.id == id && data?.updatedAt) {
                value.updatedDateStr = data?.updatedAt
              }
              if (value.id == id && value?.messageData) {
                value.messageData.updatedAt = data?.updatedAt
              }
              return value;
            });
            const newMsgList = [];
            if (data?.channel === CHANNEL_TYPE.CHANNEL_TWILIO_SMS && (data?.content || data?.attachments)) {
              const newMsgObj = getMessageBoxDataObj(
                data.content,
                showPracticeMessageOnRightSide(userData, data, showPracticeMessageTogether) ? 'right' : 'left',
                data.id,
                data as any,
                data.attachments || [],
                (data?.message_created_at)
              );
              newMsgList.push(newMsgObj);
            }

            return {
              ...oldData,
              displayData: sortMessages([...newMsgList, ...newMsgData]),
              showEditView: false,
              selectedMessage: null,
            }
          });
        }
        updateUserLastSeenAtMessageAndConversation();
        return;
      }
    }
  };

  const onMessageUpdateAfterEditMessageByCurrentUser = async (
    data: IMessageCreated,
    shouldSendNotification?: boolean
  ) => {
    const msgText = data?.content;
    const id = data?.id;
    if((data?.conversationUuid === selectedConversation?.uuid || data?.conversation_uuid === selectedConversation?.uuid)){
      if (msgText || data?.attachments?.length) {
        if (isComponentMounted?.current) {
          setMsgData((oldData: any) => {
            const newMsgDataList = oldData.displayData.map((value: IMessageBoxData) => {
              if (value.id == id) {
                value['displayContent'] = msgText;
              }
              if (value.id == id && data?.attachments?.length) {
                value.msgAttachment = data?.attachments;
              }
              if (value.id == id && data?.updatedDateStr) {
                value.updatedDateStr = data?.updatedDateStr
              }
              return value;
            });
            return {
              ...oldData,
              displayData: sortMessages([...newMsgDataList]),
              showEditView: false,
              selectedMessage: null,
            }
          });
        }

        updateUserLastSeenAtMessageAndConversation();
        if (shouldSendNotification) {
          try {
            sendConversationNotificationNoMessageEvent({
              conversationUuid: selectedConversation.uuid,
              eventCode: SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
              eventData: {
                id: id,
                uuid: data?.uuid,
                message: msgText,
                content: msgText,
                message_created_at: data?.dateStr,
                createdAt: data?.dateStr,
                updatedAt: data?.updatedDateStr
              }
            });
          } catch (e) {

          }
        }
        return;
      }
    }
  };

  const getPrevNextMentionMessages = async (isPrev: boolean) => {
    //let apiMsgResp = {} as IConversationMessageData;
    //let isOlderMsgFetch= '';
    if (isPrev) {
      if (isComponentMounted.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingLoadMore: true,
          };
        });
      }
      // isOlderMsgFetch= 'isPrev';
      const prevMentionResp = await getPrevConversationMessages(
        MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
        0,
        msgData.displayData?.[msgData.displayData?.length - 1].date || '');

      //concate data
      if (prevMentionResp?.length) {
        const allMsgData = [...prevMentionResp || []];
        const apiMsgResp = {
          conversationMessages: allMsgData,
          unreadMessages: []
        }
        getMessagesAdditionalData(apiMsgResp, false, 'isPrev');
        if (isComponentMounted.current) {
          setOffset((prev: any) => {
            return {
              ...prev,
              mentionPrevOffset: ((prevMentionResp?.length || 0) + offset.mentionPrevOffset),
            };
          });
        }
      } else {
        if (isComponentMounted.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              loadingMsgs: false,
              fetchMoreMsgs: false,
              displayData: msgData?.displayData,
              loadingLoadMore: false,
              apiData: msgData?.apiData,
              unreadMsgIdList: msgData?.unreadMsgIdList,
            };
          });
        }
      }

    } else {
      if (isComponentMounted.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingLoadMore: true,
          };
        });
      }
      //isOlderMsgFetch= 'isNext';
      const nextMentionResp = await getNextConversationMessages(
        MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE,
        0,
        msgData.displayData?.[0].date || '');

      //concate data
      if (nextMentionResp?.length) {
        const allMsgData = [...nextMentionResp.reverse() || []];
        const apiMsgResp = {
          conversationMessages: allMsgData,
          unreadMessages: []
        }
        getMessagesAdditionalData(apiMsgResp, false, 'isNext');
        setOffset((prev: any) => {
          return {
            ...prev,
            mentionNextOffset: ((nextMentionResp?.length || 0) + offset.mentionNextOffset),
          };
        });
      } else {
        if (isComponentMounted.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              loadingMsgs: false,
              fetchMoreMsgs: false,
              displayData: msgData?.displayData,
              loadingLoadMore: false,
              apiData: msgData?.apiData,
              unreadMsgIdList: msgData?.unreadMsgIdList,
            };
          });
        }
      }
    }
  }

  const getLastMessage = async () => {
    const msgResp = await getLastConversationMessages({
      variables: {
        id: props?.selectedConversation?.id,
        limit: 1,
      },
    });
    if (msgResp?.data?.conversation) {
      const lastUUID = msgResp?.data?.conversation?.lastMsg?.[0]?.uuid;
      if (isComponentMounted.current) {
        setMsgData((prev: any) => {
          return {
            ...prev,
            lastConversationMsgUuid: lastUUID,
          };
        });
      }
      return lastUUID
    }
    return ''
  }

  const getMessages = async (offset: number, isFirstBatch: boolean, loadingLatestMessages?: boolean) => {
    let apiMsgResp = {} as IConversationMessageData;
    let msgResp = {} as any;
    if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      msgResp = await getConversationMessages({
        variables: {
          id: props?.selectedConversation?.id,
          loginUserId: userId,
          loginUserIntId: userId,
          limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
          offset: offset,
        },
      });
    } else {
      msgResp = await GetMentionConversationMessages({
        variables: {
          id: props?.selectedConversation?.id,
          loginUserId: userId,
          loginUserIntId: userId,
          limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
          offset: offset,
        },
      });
    }
    if (
      msgResp?.data?.conversation &&
      msgResp?.data?.conversation?.conversationMessages.length <
        MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE
    ) {
      apiMsgResp = msgResp?.data?.conversation;
      if (isComponentMounted.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            areMessagesRemaining: false,
          };
        });
      }
    } else if (msgResp?.data?.conversation) {
      apiMsgResp = msgResp?.data?.conversation;
      if (messagingWindowState?.selectedConversationData?.unreadMsgCount > 0 && apiMsgResp?.unreadMessages ) {
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              selectedConversationData: {
                ...prev.selectedConversationData,
                unreadMessages: apiMsgResp?.unreadMessages || [],
              }
            }
          });
        }
      }
    } else {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            loadingMsgs: false,
            fetchMoreMsgs: false,
            loadingLatestMessages: false
          };
        });
      }
    }
    const listOfParentId = apiMsgResp?.conversationMessages?.filter((messageObj) => {
      return messageObj?.parentMessage?.id;
    }).map((messageObj) => {
      return messageObj?.parentMessage?.id
    })
    if (listOfParentId.length) {
      const response = await getMessagesByMessageIds({
        variables: {
          messageIds: listOfParentId,
          accountId: accountId,
          conversationId: selectedConversation.id
        }
      })
      const parentMessageList = response.data?.messages?.length ? response.data?.messages : [];
      apiMsgResp = getUpdatedConversationResponse(apiMsgResp, parentMessageList)
    }
    if (apiMsgResp) {
      getMessagesAdditionalData(apiMsgResp, isFirstBatch, undefined, loadingLatestMessages);
    }
  };

  const onMsgUpdatedListenerFn = useCallback(
    (data: IMessageCreated) => {
      data = Object.keys(data?.data || {}).length > 0
        ? messageDataKeysConverter(data.data)
        : data;
      if((data?.conversationUuid === selectedConversation?.uuid || data?.conversation_uuid === selectedConversation?.uuid)){
        if (data?.id) {
          if (data?.sid) {
            handleSMSDeliveryStatusUpdate(data);
          } else {
            onMessageUpdate(data);
          }
        }
      }

    },
    [selectedConversation?.id, msgData?.displayData]
  );

  const onMsgDeletedListenerFn = useCallback(
    (data: any) => {
      data =
      Object.keys(data?.data || {}).length > 0
        ? messageDataKeysConverter(data.data)
        : data;
      if((data?.conversationUuid === selectedConversation?.uuid || data?.conversation_uuid === selectedConversation?.uuid)){
        if (data?.isAttachment) {
          onAttachmentDelete(data);
        } else {
          onMessageDelete(data?.id);
        }
      }
    },
    [selectedConversation?.id, msgData?.displayData]
  );

  const shouldSetMessageData = (data: IMessageCreated) => {
    if (data?.content_attributes?.isScheduleMessage) {
      return true;
    }
    return (data?.sender_id !== userId)
  }

  const updateReadStatusOfMentionOnMsgReceived = async (data: IMessageCreated) =>{
    const userUuids = getUserIdListFromMsgContent(data?.content, groupMemberCodeIdObj);
    const conversationUuid = data?.conversationUuid || data?.conversation_uuid;
    if (
        selectedInboxTypeCode == CHANNEL_TYPE_CODE.CHANNEL_MENTION
        && selectedConversation?.uuid === conversationUuid
        && userUuids?.includes(userUuid)
        && data?.uuid
        && conversationUuid) {
      const conversationMentionIdsResp = await GetMentionsConversationsByMessageAndConversationId({
        variables: {
          conversationUuid: conversationUuid,
          userId: userUuid,
          messageUuid: data?.uuid,
        }
      });
      if (conversationMentionIdsResp?.data?.conversationMentions?.length) {
        const conversationMentionIds: any[] = [];
        conversationMentionIdsResp?.data?.conversationMentions?.forEach((singleMentionData: any) => {
          if (singleMentionData?.id) {
            conversationMentionIds.push(singleMentionData?.id);
          }
        });
        if (conversationMentionIds?.length) {
          try {
            await handleMarkMentionAsUnread([...conversationMentionIds], true);
          } catch (error) {
            showToast(toast, 'Something went wrong.', ToastType.error);
          }
        }
      }
    }
  }

  const onMsgReceivedListenerFn = useCallback(
    (data: IMessageCreated) => {
      data =
        Object.keys(data?.data || {}).length > 0
          ? messageDataKeysConverter(data.data)
          : data;
      const messageType = data.message_type || data?.messageType;
      let condition = false;
      if ((data?.conversationUuid || data?.conversation_uuid) &&
          (data?.conversationUuid === selectedConversation?.uuid || data?.conversation_uuid === selectedConversation?.uuid)) {
          condition = true;
      }
      if (condition) {
        if ((data?.content || data?.attachments?.length || data?.channel === CHANNEL_TYPE.CHANNEL_EFAX)) {
          calculateDiffAndUpdateMessageInList(data);
        }
        if ((selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED || isInstantChatView) && !data?.private) {
          let conversationStatus = 0;
          const conversationStatusStr = data?.conversation?.status as any;

          if (!isNaN(data?.conversationStatus as any)) {
            conversationStatus = data?.conversationStatus as any;
          } else if (conversationStatusStr && conversationStatusStr === CONVERSATION_STATUS_STRING.OPEN) {
            conversationStatus = 0;
          }  else if (conversationStatusStr && conversationStatusStr === CONVERSATION_STATUS_STRING.RESOLVED) {
            conversationStatus = 1;
          }
          if (isComponentMounted?.current) {
            setMessagingWindowState((prev) => {
              return {
                ...prev,
                selectedConversationData: {
                  ...prev.selectedConversationData,
                  status: conversationStatus,
                }
              }
            });
          }
        }
      } else {
        updateMessageIfGroupConversation(data as any);
      }
    },
    [selectedConversation?.id, msgData?.displayData]
  );

  const calculateDiffAndUpdateMessageInList = async (receivedMessageData: IMessageCreated) => {
    const msgResp = await getLastFiveConversationMessages({
      variables: {
        id: props?.selectedConversation?.id,
        limit: 5,
      },
    });
    if (msgResp?.data?.conversation) {
      const lastMessagesUuid = msgResp?.data?.conversation?.lastMsg?.map((item) => {return item?.uuid})
      if (lastMessagesUuid?.includes(msgData?.displayData?.[0]?.uuid)) {
        if (isComponentMounted.current) {
          setMsgData((oldData) => {
            const existingMessageData = getMessageDataFromId(oldData.displayData, receivedMessageData?.id);
            const newMsgObj = getMessageBoxDataObj(
              receivedMessageData.content,
              showPracticeMessageOnRightSide(userData, receivedMessageData, showPracticeMessageTogether) ? 'right' : 'left',
              receivedMessageData.id,
              receivedMessageData as any,
              receivedMessageData.attachments || existingMessageData?.msgAttachment || []
            );
            const newMsgList = [newMsgObj];
            return {
              ...oldData,
              displayData: sortMessages([...newMsgList, ...oldData.displayData]),
            }
          })
        }
        getLastMessage();
        updateUserLastSeenAtMessageAndConversation();
        updateReadStatusOfMentionOnMsgReceived(receivedMessageData);
      } else {
        if (shouldSetMessageData(receivedMessageData)) {
          if (isComponentMounted.current) {
            setMsgData((prev) => {
              const previousNewMsgIds = prev.newMessageUuids;
              if ( (receivedMessageData?.messageType === 2
                && receivedMessageData?.message_type === 2) &&
                receivedMessageData?.contentAttributes?.assigneeByUserData?.id !== userId &&
                receivedMessageData?.contentAttributes?.performedBy?.id !== userId
              ) {
                previousNewMsgIds.push(receivedMessageData?.uuid);
              } else if (receivedMessageData?.messageType !== 2 &&  receivedMessageData?.message_type !== 2) {
                previousNewMsgIds.push(receivedMessageData?.uuid);
              }
              return {
                ...prev,
                newMessageUuids: [...previousNewMsgIds]
              }
            });
          }
        }
      }
    }
  }

  const goToNewMessages = () => {
    if (isComponentMounted.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          loadingLatestMessages: true,
          fetchMoreMsgs: true,
          newMessageUuids: [],
        };
      });
    }
    getMessages(0, true, true);
  };

  const onLocalConversationActionEvent = useCallback(
    (data: IMessagingWindowLocalEventData) => {
      onMsgReceivedListenerFn(data?.messageData);
    },
    [selectedConversation?.id, msgData]
  );

  const onLocalContactDetailUpdatedListenerFn = useCallback(
    async (data: any) => {
      const updatedContactUuid = data.leadFormData?.contactUUID;
      if (
        updatedContactUuid === selectedConversation?.conversationContact?.uuid
      ) {
        const updatedConversationContact = await getConversationContactByUuid({
          variables: {
            conversationUuid: selectedConversation?.uuid,
          },
        });
        if (
          updatedConversationContact?.data?.conversations?.[0]
            ?.conversationContact
        ) {
          if (isComponentMounted?.current) {
            setMessagingWindowState((prev) => {
              const conversationData = prev?.selectedConversationData;
              const updatedConversation = {
                ...conversationData,
                conversationContact:
                  updatedConversationContact?.data?.conversations?.[0]
                    ?.conversationContact ||
                  conversationData?.conversationContact,
              };
              return {
                ...prev,
                selectedConversationData: updatedConversation,
              };
            });
          }
        }
      }
    },
    [selectedConversation?.id]
  );

  const onLocalPCPUserUpdateListenerFn = useCallback(
    async (data: any) => {
      if (
        data?.contactUUID === selectedConversation?.conversationContact?.uuid
      ) {
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev) => {
            return {
              ...prev,
              pcpUserData: data?.markAsPCP ? data?.userData : ({} as IUser),
            };
          });
        }
      }
    },
    [selectedConversation?.id]
  );

  const updateMessageIfGroupConversation = (data: IGroupMessageCreatedData) => {
    if (
      data?.conversations?.groupConversations?.[0].id &&
      data?.conversations?.id + '' === '' + selectedConversation?.id
    ) {
      const messageData = data;
      const newMsgObj = getMessageBoxDataObjForGroup(
        messageData.content,
        'left',
        messageData.id,
        messageData as any,
        [],
        messageData?.createdAt,
        messageData?.uuid,
        messageData?.parentMessageUuid
      );
      const newMsgList = [newMsgObj];
      if (isComponentMounted.current) {
        setMsgData((prev) => ({
          ...prev,
          displayData: sortMessages([...newMsgList, ...prev.displayData]),
        }));
      }
      updateUserLastSeenAtMessageAndConversation();
    }
  };

  const callbackAfterMessageSent = async (args: CallBackArgs) => {
    const {msgTempData, msgText, parentMessage} = args;
    if (isComponentMounted.current) {
      setMsgData((prev) => {
        const echoId = msgTempData?.content_attributes?.echo_id;
        const displayData = [...prev.displayData] || [];
        const msgIndex = displayData.findIndex(
          (item) => item.echoId && item.echoId == echoId
        );
        const currentTime =
          msgTempData?.currentTime || getDateToMomentISOString();
        const newMsgObj = getMessageBoxDataObj(
          msgText,
          'right',
          msgTempData.id,
          {
            ...msgTempData,
            echo_id: echoId,
            sender: {name: userData.name, type: msgTempData?.sender?.type, id: userData.id},
          } as any,
          [],
          currentTime
        );
        if (parentMessage?.id) {
          newMsgObj['parentMessage'] = {
            id: parentMessage.id,
            displayContent: parentMessage.text,
            dateStr: getDateStrFromFormat(parentMessage.dateStr || ''),
            position: 'left',
            senderFullName: parentMessage.senderFullName || 'You',
            messageType: parentMessage.messageType || -1,
            msgAttachment: parentMessage.attachments || [],
            private: parentMessage.private || false,
            messageData: parentMessage,
            senderType: '',
            uuid: parentMessage.uuid,
          };
        }
        if (msgIndex === -1) {
          displayData.unshift(newMsgObj);
        } else {
          displayData[msgIndex] = newMsgObj;
        }
        return {
          ...prev,
          displayData: sortMessages([...displayData]),
        };
      });
    }
  }

  const onUserOnlineStatusChanged =  useCallback(
    (eventData: any) => {
    if (eventData?.onlineStatus === ONLINE_STATUS.ONLINE) {
      if (props?.selectedConversation?.id && selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        getLastMessage();
        resetAndFetchFirstBatch();
      }
    } else if (selectedConversation?.conversationMentionId && selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      getLastMessage();
      resetAndFetchFirstBatch();
    }
  }, [msgData?.displayData[0]?.id]);

  if (isRefreshConversationAfterOfflineOnlineEnabled) {
    useEffect(() => {
      isComponentMounted.current = true;
      const eventBus = EventBus.getEventBusInstance();
      eventBus.addEventListener(CUSTOM_MESSAGE_EVENT_CODES.REFRESH_CONVERSATION_ON_USER_ONLINE, onUserOnlineStatusChanged);
      return () => {
        eventBus.removeEventListener(onUserOnlineStatusChanged);
      };
    }, [msgData?.displayData[0]?.id]);
  }

  useEffect(() => {
    //check consent status
    isComponentMounted.current = true;
    const eventBus = EventBus.getEventBusInstance();
    const messageBus = MessageBus.getMessageBusInstance();
    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_DELETED,
      onMsgDeletedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
      onMsgUpdatedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    ///////
    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_OPENED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_RESOLVED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MEMBER_ADDED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MEMBER_REMOVED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_NAME_UPDATED,
      onMsgReceivedListenerFn
    );
    ///////

    // Local Call Events Start
    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ARCHIVED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONTACT_DETAIL_UPDATE,
      onLocalContactDetailUpdatedListenerFn
    )

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_PCP_USER_CHANGE,
      onLocalPCPUserUpdateListenerFn
    );

    const callbackAfterMessageFails = (data: any) => {
      if (isComponentMounted.current) {
        setMsgData((prev) => {
          const echoId = data.echoId;
          const status = CHAT_DELIVERY_STATUS.API_FAILED;
          const displayData = [...prev.displayData];
          const idx = displayData.findIndex((item) => item.echoId === echoId);
          const messageObj = {...displayData[idx]};
          messageObj.status = status;
          displayData[idx] = messageObj;
          return {
            ...prev,
            displayData: displayData,
          };
        });
      }
    };

    messageBus.registerCallbacks({
      callback(args) {
        callbackAfterMessageSent(args);
      },
      errorCallback(data) {
        callbackAfterMessageFails(data);
      },
    });

    return () => {
      eventBus.removeEventListener(onMsgReceivedListenerFn);
      eventBus.removeEventListener(onMsgUpdatedListenerFn);
      eventBus.removeEventListener(onMsgDeletedListenerFn);
      eventBus.removeEventListener(onMsgDeletedListenerFn);
      eventBus.removeEventListener(onLocalContactDetailUpdatedListenerFn);
      eventBus.removeEventListener(onLocalPCPUserUpdateListenerFn);
      eventBus.removeEventListener(onLocalConversationActionEvent);
      messageBus.removeCallback({callback: callbackAfterMessageSent});
    };
  }, [msgData?.displayData[0]?.id]);

  const onActionPerformed = (actionCode: string | undefined) => {
    setShowModal({
      ...showModal,
      taskModal: false,
      noteModal: false,
      messageReadInfo: false,
    });
  };
  const [copyMessageState, setCopyMessageState] = useState({
    code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
    copyMessageList: [] as ICopiedMessageItem[]
  })

  const [showCreateNoteOptionsPopover, setShowCreateNoteOptionsPopover] = useState(false)



  const handleShowCreateNoteOptionsPopover = () => {
    setShowCreateNoteOptionsPopover(prev => !prev)
  }

  const [createNoteModalState, setCreateNoteModalState] = useState<{
    modalVisible: boolean;
    copiedData: string;
    code: CreateNoteType | '',
  }>({
    code: '',
    modalVisible: false,
    copiedData: '',
  });


  const handleCreatNoteDrawerOpen = (data: string, code: CreateNoteType) => {
    let copiedMessage = data;
    // Remove emojis from string
    if (code === CreateNoteType.CLINICAL) {
      copiedMessage = stripEmojis(copiedMessage);
    }
    setCreateNoteModalState({
      modalVisible: true,
      copiedData: copiedMessage,
      code: code,
    });
  };

  const handleCreatNoteDrawerClose = () => {
    // clear copied message list from browser clipboard
    navigator.clipboard.writeText('');
    setCreateNoteModalState({
      modalVisible: false,
      copiedData: '',
      code: '',
    });
    setCopyMessageState((prev) => {
      return {
        ...prev,
        code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
        copyMessageList: [],
      };
    });

  };

  const handleContactNoteCreated = () => {
    handleCreatNoteDrawerClose();
    showToast(
      customToast,
      intl.formatMessage({id: 'noteCreatedSuccessfully'}),
      ToastType.success,
      1000,
      true
    );
  }

  const handleContactNoteFailed = () => {
    handleCreatNoteDrawerClose();
    showToast(
      toast,
      intl.formatMessage({id: 'errorMsg'}),
      ToastType.error,
      1000,
    );
  }

  const copyMsgView = (): JSX.Element => {
    const messageActionView = () => {
      if (copyMessageState.code !== COPY_MESSAGE_CODE.COPY_MODE_COPIED) {
        const isMessageCopy = isMessageCopied(
          copyMessageState.copyMessageList,
          copyMessageState.code
        );
        return (
          <Tooltip title={'Copy Message'}>
            <Pressable
              disabled={!isMessageCopy}
              onPress={() => {
                handleCopyToClipBoard();
              }}
              {...testID('CopyMessageClick')}
            >
              <VStack alignItems={'center'}>
                <CopyActionSvg
                  customStrokeColor={
                    isMessageCopy
                      ? Colors.FoldPixel.GRAY300
                      : Colors.Custom.Gray300
                  }
                  width={20}
                  height={20}
                />
              </VStack>
            </Pressable>
          </Tooltip>
        );
      } else if (copyMessageState.code === COPY_MESSAGE_CODE.COPY_MODE_COPIED) {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <CreateNew customColor={Colors.Custom.SuccessColor} />
            <Text
              style={{marginLeft: 8}}
              size={'smSemibold'}
              color={Colors.Custom.SuccessColor}
              {...testID('Copied')}
            >
              Copied
            </Text>
          </View>
        );
      }
    };
    return <HStack>{messageActionView()}</HStack>;
  };
  const renderSelectedMessageView = () => {
    return (
      <>
        {[
          COPY_MESSAGE_CODE.COPY_MODE_ON,
          COPY_MESSAGE_CODE.COPY_MODE_COUNT,
        ].includes(copyMessageState.code) &&
          copyMessageState.copyMessageList.length > 0 && (
            <HStack
              px={4}
              py={1}
              borderTopWidth={1}
              borderBottomWidth={1}
              justifyContent={'flex-end'}
              borderColor={Colors.Custom.BorderColor}
              bg={Colors.Custom.Gray50}
              {...testID('SelectedMessageView')}
            >
              <Text color={Colors.Custom.Gray500} {...testID('MessageSelected')}>
                {copyMessageState.copyMessageList.length} message selected
              </Text>
              <Pressable
                ml={2}
                onPress={() =>
                  setCopyMessageState((prev) => {
                    return {
                      ...prev,
                      code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
                      copyMessageList: [],
                    };
                  })
                }
                {...testID(TestIdentifiers.closeBtn)}
              >
                <Feather name="x" size={20} color={Colors.Custom.Gray500}  {...testID('image')}/>
              </Pressable>
            </HStack>
          )}
      </>
    );
  };
  const handleCreateNoteClick = (code?: CreateNoteType) => {
    // enable copy mode if not enabled
    if (copyMessageState.code === COPY_MESSAGE_CODE.COPY_MODE_OFF) {
      setCopyMessageState((prev) => {
        return {
          ...prev,
          code:
            prev.code == COPY_MESSAGE_CODE.COPY_MODE_OFF
              ? COPY_MESSAGE_CODE.COPY_MODE_ON
              : COPY_MESSAGE_CODE.COPY_MODE_OFF,
        };
      });
    } else {
      // if copy mode is enabled and no message is selected show toast message
      if (copyMessageState.copyMessageList.length === 0) {
        showToast(
          toast,
          intl.formatMessage({id: 'selectMsgErrorCreateNote'}),
          ToastType.error,
          1000,
        );
      } else {
        const textToCopy = copyMessageState.copyMessageList
          .map(
            (message) =>
              `[${message.name}]:[${moment(message.date).format(
                DATE_FORMATS.MESSAGE_DATE_FORMAT
              )}] \n${message.text} \n`
          )
          .join('\n');
        const isClinicalNote = code === CreateNoteType.CLINICAL;
        navigator.clipboard.writeText(textToCopy).then(() => {
          showToast(
            customToast,
            isClinicalNote ? intl.formatMessage({id: 'messageCopiedToClipboardForClinicalNote'}) : intl.formatMessage({id: 'messageCopiedToClipboard'}),
            ToastType.success,
            isClinicalNote ? 4000 : 1500,
            true
          );

          code && handleCreatNoteDrawerOpen(textToCopy, code);
        });
      }
    }
  }
  const createNoteOptionActionList = [
    {
      label: 'Clinical Note',
      code: NOTE_TYPE.CLINICAL_NOTE,
      action: () => handleCreateNoteClick(CreateNoteType.CLINICAL)
    },
    {
      label: "Contact Note",
      code: NOTE_TYPE.CONTACT_NOTE,
      action: () => handleCreateNoteClick(CreateNoteType.CONTACT)
    }
  ]

  const renderCreateNoteViewButton = (isSaveNote: boolean) => {
    return (
      <Tooltip title={'Create Note'}>
        <VStack alignItems="center">
          <CreateNoteSvg
            customStrokeColor={
              isSaveNote ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray300
            }
            height={20} width={20}
          />
        </VStack>
      </Tooltip>
    );
  };

  const createNoteView = (): JSX.Element => {
    if (contactType?.code !== PERSON_TYPES.CUSTOMER) {
      return <></>;
    }

    // copy mode is enabled
    const isCopyMode = [
      COPY_MESSAGE_CODE.COPY_MODE_ON,
      COPY_MESSAGE_CODE.COPY_MODE_COUNT,
    ].includes(copyMessageState.code);
    // atleast one message is selected in copy mode
    const isMessagesSelected = copyMessageState.copyMessageList.length > 0 && isCopyMode;

    if (isMessagesSelected && msgData.userIdForGetAccountUsersById !== userUuid) {
      if (isComponentMounted.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            userIdForGetAccountUsersById: userUuid,
          };
        });
      }
    }

    const allowClinicalNote = isFold || isElation;

    return (
      <HStack>
        <Box alignItems="center">
          {isMessagesSelected ? (
            <Popover
              onOpenChange={handleShowCreateNoteOptionsPopover}
              open={showCreateNoteOptionsPopover}
              overlayInnerStyle={{
                bottom: 3,
                borderRadius: 16,
                padding: 0,
              }}
              trigger="click"
              placement={'bottomRight'}
              content={
                <VStack>
                  {
                    isGetAccountUsersByUserUuidLoading &&
                    <Skeleton.Text width={100} {...testID(TestIdentifiers.lazyLoading)}/>
                  }
                  {!isGetAccountUsersByUserUuidLoading && createNoteOptionActionList.map((option, index: number) => {
                    if (
                      !allowClinicalNote &&
                      option.code === NOTE_TYPE.CLINICAL_NOTE
                    ) {
                      return <></>;
                    }
                    return (
                      <>
                        <Pressable
                          key={option.label}
                          onPress={() => {
                            option?.action();
                            setShowCreateNoteOptionsPopover(false);
                          }}
                        >
                          <Text
                            paddingY={4}
                            paddingX={2}
                            size={'smSemibold'}
                            color={Colors.Custom.Gray700}
                            noOfLines={1}
                            {...testID(option.label)}
                          >
                            {option.label}
                          </Text>
                        </Pressable>
                        {index !== createNoteOptionActionList.length - 1 && (
                          <Divider bg={Colors.Custom.Gray200} />
                        )}
                      </>
                    )
                  })}
                </VStack>
              }
            >
              <Pressable
                disabled={!isMessagesSelected && !isInstantChatView}
                onPress={handleShowCreateNoteOptionsPopover}
              >
                {renderCreateNoteViewButton(isMessagesSelected)}
              </Pressable>
            </Popover>
          ) : (
            <Pressable
              disabled={!isMessagesSelected && !isInstantChatView}
              onPress={() => handleCreateNoteClick()}
            >
              {renderCreateNoteViewButton(isMessagesSelected)}
            </Pressable>
          )}
        </Box>
      </HStack>
    );
  };

  const onAddCommunicationType = (data: {
    messageUuid: string;
    communicationTypes: ICommunicationType[];
  }) => {
    const {communicationTypes, messageUuid} = data;
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        const displayData = [...prev.displayData] || [];
        const idx = displayData.findIndex((item) => item.uuid === messageUuid);
        const messageObject = displayData[idx];
        messageObject.communicationTypes = communicationTypes;
        displayData[idx] = messageObject;
        return {
          ...prev,
          displayData: displayData,
        };
      });
    }
  };

  const handleCopyToClipBoard = () => {
    const textToCopy = copyMessageState.copyMessageList
      .map(
        (message) =>
          `[${message.name}]:[${moment(message.date).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)}] \n${message.text
          } \n`
      )
      .join('\n');
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopyMessageState((prev) => {
        return {
          ...prev,
          code: COPY_MESSAGE_CODE.COPY_MODE_COPIED,
        };
      });
      const copyMsgTimeout = setTimeout(() => {
        setCopyMessageState((prev) => {
          return {
            ...prev,
            code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
            copyMessageList: []
          };
        });
      }, 2000);
      if (copyMessageRef?.current) {
        copyMessageRef.current = copyMsgTimeout;
      }
    });
  };
  const onViewTaskAction = (messageUuid: string) => {
    if (messageUuid) {
      setTaskDetails((prev) => {
        return {
          ...prev,
          actionCode: COMMON_ACTION_CODES.VIEW_TASK,
          taskModal: true,
          messageUuid: messageUuid,
        };
      });
    }
  };

  const getMessagingListElem = (): JSX.Element => {
    return (
      <GetMessagingListElem
        clickMessageUuid={msgData.clickedMessageUuid}
        resetClickedMessageUuid={resetClickedMessageUuid}
        isCopyModeStateOnCode={copyMessageState.code}
        msgData={msgData}
        selectedConversation={messagingWindowState?.selectedConversationData}
        selectedInboxTypeCode={selectedInboxTypeCode}
        messageAction={messageAction}
        onAddCommunicationType={(data) => {
          onAddCommunicationType({
            messageUuid: data.messageUuid,
            communicationTypes: data.types,
          });
        }}
        onFetchMoreIfAvailable={() => {
          if (
            selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION ||
            (isSeachMsgContainerVisible && searchMessage?.uuid)
          ) {
            getPrevNextMentionMessages(true);
          } else {
            getMessages(offset.otherChatOffset, false);
          }
        }}
        onFetchMoreRecentIfAvailable={() => {
          getPrevNextMentionMessages(false);
        }}
        showModal={showModal}
        onActionPerformed={(actionCode: string | undefined, data: any) => {
          if (actionCode === COMMON_ACTION_CODES.COPY_MESSAGE_LIST) {
            onViewChangeActionPerformed(actionCode, data)
          } else {
            onActionPerformed(actionCode);
          }
        }}
        onMessageAction={onSelectedAction}
        loadingLoadMore={msgData.loadingLoadMore}
        onViewTaskAction={(messageUuid) => onViewTaskAction(messageUuid)}
        isSeachMsgContainerVisible={isSeachMsgContainerVisible}
        searchMessage={searchMessage}
        onScrollToMessageFail={(msg) => {
          getMsgDataOfNextAndPrevMessages(msg)
        }}
        onRedirectToMentionChange={()=> {
          setStateData((prev)=> {
            return {
              ...prev,
              redirectToMention: false
            }
          })
        }}
        redirectToMention={stateData?.redirectToMention}
        groupMemberDataLoading={messagingWindowState.groupMemberLoading}
      />
    );
  };

  const broadcastPatientUpdateEvent = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, {fromSection: 'MESSAGES_VIEW'});
  }

  const getContactData = async () => {
    if (!contactData?.id) {
      return;
    }
    const responseData = await GetContact({
      variables: {
        id: contactData?.id,
        conversationId: selectedConversation?.id
      },
    });
    if (responseData?.data?.contact?.id) {
      contactData = responseData?.data?.contact;
      const isGiven = isContactConsentGiven(responseData?.data?.contact);
      if (isDisableConversationOnPhoneNumberMissMatch) {
        const isNumberChanged = checkIsPatientNumberChanged(
          contactData?.contactInboxes?.[0]?.sourceId,
          contactData?.phoneNumber
        );
        if (isNumberChanged) {
          const actionMessageCode = ACTION_MESSAGE_CODE.PATIENT_NUMBER_CHANGED;
          setStateData((prev) => {
            return {
              ...prev,
              isShowFooter: false,
              actionMessageCode: actionMessageCode,
            };
          });
          return;
        }
      }
      setStateData(prev => {
        return {
          ...prev,
          isShowFooter: !isRequiredContactConsent ? true : isGiven && isRequiredContactConsent,
          isConsentGiven: !isRequiredContactConsent ? true : isGiven && isRequiredContactConsent,
        }
      })
    }
  };

  const checkForConsent = async () => {
    if (!isRequiredContactConsent) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => ({
          ...prev,
          isConsentGiven: true,
          isShowFooter: true,
        }));
      }
      return;
    }
    if (!contactData?.uuid) {
      return;
    }

    const checkPatientConsent = await getEmployeeConsent({
      variables: {
        contactUuid: contactData?.uuid,
      },
    });
    if (!checkPatientConsent?.data?.contactConsents.length) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            isConsentGiven: false,
            isShowFooter: false,
          };
        });
      }
    } else {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            isConsentGiven: true,
            isShowFooter: true,
          };
        });
      }
    }
  };

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    const email = getEmail(rowData);
    const phoneNumber = getPhoneNumber(rowData);
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.COPY:
        setCopyMessageState((prev) => {
          return {
            ...prev,
            code:
              prev.code == COPY_MESSAGE_CODE.COPY_MODE_OFF
                ? COPY_MESSAGE_CODE.COPY_MODE_ON
                : COPY_MESSAGE_CODE.COPY_MODE_OFF,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.EDIT:
        setSelectedRowData(rowData);
        setSelectedActionView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        break;
      case CONVERSATION_ACTION_CODES.START_SMS:
        getSMSHeaderAction(rowData, phoneNumber);
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        getEmailHeaderAction(rowData, email);
        break;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        getVideoMeetHeaderAction(rowData, phoneNumber, email)
        break;
      case CONVERSATION_ACTION_CODES.CHART:
        getChartHeaderAction(rowData)
          break;
      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT:
        getUpdateToContactAction(rowData);
        break;
      case CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT:
        getAppointmentHeaderAction(rowData)
        break;
      case PERSON_ACTION_CODES.CANCEL:
        setSelectedActionView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        break;
      case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
      case GROUP_ACTION_CODES.GROUP_HEADER_CLICKED:
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              isDrawerVisible: true,
            };
          });
        }
        break;

      case GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT:
        const currentStatus = isActiveContact(contactData);
        if (!currentStatus) {
          const message = getInActiveContactError(contactData);
          notification.error({
            message,
          });
          return;
        }
        props?.navigateOrOpenContactIdDrawer?.((rowData.contactId || rowData.id), {
          state: selectedConversation?.conversationInbox,
        });
        break;
      case CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE:
        onConversationActionPerformed(
          CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
          rowData
        );
        break;
      case CONVERSATION_ACTION_CODES.SEARCH_MESSAGE:
        onConversationActionPerformed(
          CONVERSATION_ACTION_CODES.SEARCH_MESSAGE
        );
        break;
      case COMMON_ACTION_CODES.COPY_MESSAGE_LIST:
        setCopyMessageState((prev) => {
          return {
            ...prev,
            copyMessageList: rowData,
            code: rowData.length
              ? COPY_MESSAGE_CODE.COPY_MODE_COUNT
              : COPY_MESSAGE_CODE.COPY_MODE_ON,
          };
        });
        break;
      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
        if (isChannelEmailOrSms(selectedConversation?.conversationInbox?.channelType)) {
          getContactData();
          broadcastPatientUpdateEvent();
          setSelectedActionView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        }
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        onConversationActionPerformed(
          CONVERSATION_ACTION_CODES.DRAWER_CLOSE,
          messagingWindowState?.selectedConversationData
        );
        break;

      case CONVERSATION_ACTION_CODES.CONVERSATION_GROUP_MEMBER:
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev: any) => {
            const conversationData = prev?.selectedConversationData
            const updatedConversation = {
              ...conversationData,
              groupConversation: {
                ...conversationData?.groupConversation,
                groupMembers: rowData
              }
            }
            return {
              ...prev,
              selectedConversationData: updatedConversation
            }
          });
        }
        break;

      case CONVERSATION_ACTION_CODES.CONVERSATION_INBOX_MEMBER:
        if (isComponentMounted?.current) {
          setMessagingWindowState((prev: any) => {
            const conversationData = prev?.selectedConversationData
            const updatedConversation = {
              ...conversationData,
              conversationInbox:{
                ...conversationData?.conversationInbox,
                inboxMembers: rowData
              }
            }
            return {
              ...prev,
              selectedConversationData: updatedConversation
            }
          });
        }
        break;

      default:
        setSelectedRowData(rowData);
        setSelectedActionView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
        break;
    }
  };
  const getSMSHeaderAction = (rowData: any, phoneNumber: string) => {
    const contactActiveStatus = isActiveContact(rowData);
    if (contactActiveStatus) {
        setSelectedRowData(rowData);
        setSelectedActionView(
          phoneNumber
            ? CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
            : RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW
        );
    } else {
      const message = getInActiveContactError(rowData);
      notification.error({message});
    }
  };

  const getEmailHeaderAction = (rowData: any, email: string) => {
    const contactActiveStatus = isActiveContact(rowData);
    if (contactActiveStatus) {
        setSelectedRowData(rowData);
        setSelectedActionView(
          email
          ? CONVERSATION_ACTION_CODES.START_EMAIL
          : RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW
        );
    } else {
      const message = getInActiveContactError(rowData);
      notification.error({message});
    }
  };

  const getChartHeaderAction = (rowData: any) => {
    if (
      rowData?.contactType?.contactType?.code == 'CUSTOMER' ||
      rowData?.contactType?.contactType?.code == 'PATIENT'
    ) {
      props?.navigateOrOpenContactIdDrawer?.(rowData?.id, {
        state: {actionType: 'CREATE_CHART'},
      });
    }
  };

  const getAppointmentHeaderAction = (rowData: any) => {
    setSelectedRowData(rowData);
    setSelectedActionView(CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT);
  };

  const getUpdateToContactAction = (rowData: any) => {
    setSelectedRowData(rowData);
    setSelectedActionView(CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT);
  };

  const getVideoMeetHeaderAction = (rowData: any, phoneNumber: string, email: string) => {
    const formattedContactData = getFormDataFromLeadData(
      rowData || {},
      commonData
    );
    const contactActiveStatus = isActiveContact(rowData);
    if (contactActiveStatus) {
        setSelectedRowData(formattedContactData);
        setSelectedActionView(
          (phoneNumber || email)
          ? RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW
          : RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW
        );
    } else {
      const message = getInActiveContactError(rowData);
      notification.error({message});
    }
  };


  const onCreateChannelActionPerformed = (
    actionCode?: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              isDrawerVisible: false,
              areMessagesRemaining: true,
            };
          });
        }
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        if (isGroupConversation(selectedConversation)) {
          onConversationActionPerformed(
            COMMON_ACTION_CODES.ITEM_CLICKED,
            actionData
          );
        }
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              areMessagesRemaining: true,
              isDrawerVisible: false,
            };
          });
        }
        break;
      default:
        break;
    }
  };

  const onActionMsgFooterPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ITEM_SELECT:
        {
          if (actionData.isInternalMessage) {
            setMsgWindowHeight(height * heightMaxPercent);
          } else {
            setMsgWindowHeight(height * heightMaxPercent);
          }
        }
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        if (isComponentMounted?.current) {
          setMsgData((prev) => {
            return {
              ...prev,
              showEditView: false,
            };
          });
          setEmailDrawerState(prev => {
            return {
              ...prev,
              isInternalMessage: false,
            }
          });
        }
        break;
      case COMMON_ACTION_CODES.RESET:
        if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
          const mentionContainerHeight = mentionContainerRef?.current
            ?.offsetHeight
            ? mentionContainerRef?.current?.offsetHeight
            : 0;
          (height * heightMaxPercent - mentionContainerHeight);
        } else {
          setMsgWindowHeight(height * heightMaxPercent);
        }
        break;
      case COMMON_ACTION_CODES.UPDATE:
        setMsgWindowHeight(prev => prev - actionData)
        break;
      case COMMON_ACTION_CODES.CHANGE_ARCHIVE_ON_SEND:
      case ON_PRESS_ENTER_ACTION_CODES.NEW_LINE:
      case ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE:
        updateViewedFeatureList(
          actionData?.isArchiveSendChecked,
          actionData?.isNewLineOnEnter
        );
        localBroadcastEvent(
          CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CHANGE_MESSAGE_USER_PREFERENCE,
          {
            isNewLineOnEnter: actionData?.isNewLineOnEnter,
            parentCode: parentCode,
          }
        );
        break;
      case COMMON_ACTION_CODES.PHI_WARNING:
        if (isComponentMounted?.current) {
          setMsgData(prev => {
            return {
              ...prev,
              showPhiWarning: actionData?.showPhiWarning
            }
          })
        }
        break;
    }
  };


  const handleDeleteMessage = (data: any) => {
    if (data?.isAttachment) {
      deleteAttachment(data);
    } else {
      deleteMessage(data?.id);
    }
  };
  const [deleteMessageAttachment] = useMutation<any>(
    ConversationsQueries.DeleteMessageAttachment,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const deleteAttachment = (data: any) => {
    deleteMessageAttachment({
      variables: {
        id: data?.selectedAttachment || -1,
        messageId: data?.id || -1,
        accountId: accountId,
      },
    }).then(async (resp) => {
      if (resp && resp?.data && resp?.data?.updateAttachments?.returning) {
        onAttachmentDelete(data);
        setDeleteMessageModal(false);
        try {
          sendConversationNotificationNoMessageEvent({
            conversationUuid: selectedConversation.uuid,
            eventCode: SUPPORTED_EVENT_CODE.MESSAGE_DELETED,
            eventData: {
              id: data?.id,
              conversationUuid: selectedConversation?.uuid,
            }
          })
            .then((resp) => {
            })
            .catch((e) => {

            })
            .finally(() => {
              setDeleteMessageModal(false);
            });
        } catch (e) {
          setDeleteMessageModal(false);
        }
      }
    });
  };

  const deleteMessage = (messageId: any) => {
    deleteMessageQuery({
      variables: {
        messageId: messageId,
        isDeleted: true,
      },
    }).then(async (resp) => {
      if (resp && resp?.data && resp?.data?.updateMessage) {
        onMessageDelete(resp?.data?.updateMessage?.id);
        setDeleteMessageModal(false);
        try {
          sendConversationNotificationNoMessageEvent({
            conversationUuid: selectedConversation.uuid,
            eventCode: SUPPORTED_EVENT_CODE.MESSAGE_DELETED,
            eventData: {
              id: messageId,
              conversationUuid: selectedConversation?.uuid,
            }
          })
            .then((resp) => {
            })
            .catch((e) => {

            })
            .finally(() => {
              setDeleteMessageModal(false);
            });
        } catch (e) {

          setDeleteMessageModal(false);
        }
      }
    });
  };

  const getMentionMessageByMentionId = async () => {
    if (!selectedConversation.conversationMentionId) {
      return;
    }
    if (selectedInboxTypeCode != CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            mentionMessage: '',
          };
        });
      }
      return;
    }
    try {
      const response = await getMessageByMentionId({
        variables: {
          mentionId: selectedConversation.conversationMentionId,
        },
      });
      const messageContent = response?.data?.messages?.[0]?.content || '';
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            mentionMessage: messageContent,
          };
        });
      }
    } catch (error) {
      if (isComponentMounted?.current) {
        setMsgData((prev) => {
          return {
            ...prev,
            mentionMessage: '',
          };
        });
      }
    }
  };

  const handleAssignMessageTypeButton = () => {
    if (copyMessageState.code === COPY_MESSAGE_CODE.COPY_MODE_OFF) {
      setCopyMessageState((prev) => {
        return {
          ...prev,
          code:
            prev.code == COPY_MESSAGE_CODE.COPY_MODE_OFF
              ? COPY_MESSAGE_CODE.COPY_MODE_ON
              : COPY_MESSAGE_CODE.COPY_MODE_OFF,
        };
      });
      return;
    }
    if (copyMessageState.copyMessageList.length === 0) {
      showToast(
        toast,
        intl.formatMessage({id: 'assignTypeAlert'}),
        ToastType.error
      );
      return;
    }
    const uuids = getMessageUuidsFromCopyMessageList({
      copyMessageList: copyMessageState.copyMessageList,
    });
    const isCommunicationTypeAssigned =
      isCommunicationTypeAssignedToSelectedMessages({
        uuids: uuids,
        displayData: msgData.displayData,
      });
    if (isCommunicationTypeAssigned) {
      showToast(
        toast,
        intl.formatMessage({id: 'assignTypeAssociatedAlert'}),
        ToastType.error
      );
      return;
    }
    setShowModal((prev) => {
      return {
        ...prev,
        assignTypeDrawer: true,
      };
    });
  }
  const handleCommunicationTypeSave = async () => {
    setShowModal((prev) => {
      return {
        ...prev,
        assignTypeDrawer: false,
        messageData: {}
      };
    });
    const messageUuids = getMessageUuidsForAssignType();
    const displayData = await addCommunicationTypesByMessageUuids({
      messageUuids,
      displayMsgList: msgData.displayData,
    });
    if (isComponentMounted?.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          displayData: [...displayData],
        };
      });
      setCopyMessageState((prev) => {
        return {
          ...prev,
          code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
          copyMessageList: [],
        };
      });
    }
  }

  const {loading: msgAdditionalData, getAdditionalDataForMessages} = useMessagingAdditionalData()

  const handleSelectChat = () => {
      setCopyMessageState((prev) => {
        return {
          ...prev,
          code:
            prev.code == COPY_MESSAGE_CODE.COPY_MODE_OFF
              ? COPY_MESSAGE_CODE.COPY_MODE_ON
              : COPY_MESSAGE_CODE.COPY_MODE_OFF,
        };
      });
      return;
  };

  const getSelectChatElement = () => {
    const isEmailChannel = isChannelEmail(
      selectedConversation?.conversationInbox?.channelType
    );
    if (isEmailChannel) {
      return <></>;
    }
    return (
      <Tooltip title={'Select Chats'}>
        <Pressable {...testID('SelectChatsClick')} onPress={() => handleSelectChat()}>
          <VStack alignItems={'center'}>
            <SelectChatActionSvg customStrokeColor={Colors.FoldPixel.GRAY300} width={20} height={20}/>
          </VStack>
        </Pressable>
      </Tooltip>
    );
  };

  const getSelectChatForCreateTask = () => {
    let newDescription = '';
    const messageUuidList = [] as string[];
    copyMessageState.copyMessageList.forEach((item) => {
      if (item?.uuid) {
        messageUuidList.push(item?.uuid);
      }
      newDescription =
        newDescription +
        `[${item.name}]:` +
        `[${moment(item.date).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)}]` +
        '<br/>' +
        item.text +
        '<br/>';
    });
    const newMsgData = {
      messageList: messageUuidList,
      displayContent: newDescription,
      dateStr: copyMessageState.copyMessageList[0].date,
      senderName: copyMessageState.copyMessageList[0].name,
      userID: copyMessageState.copyMessageList[0].userId,
    };
    setCopyMessageState((prev) => {
      return {
        ...prev,
        code:
          prev.code == COPY_MESSAGE_CODE.COPY_MODE_OFF
            ? COPY_MESSAGE_CODE.COPY_MODE_ON
            : COPY_MESSAGE_CODE.COPY_MODE_OFF,
      };
    });
    return newMsgData;
  };

  const onFormActionPerformed = (
    actionCode: string,
    actionData?: ITask,
    messageList?: string[]
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.TASK_COMPLETE:
        handleCreateConversationTask(actionData?.id || '', messageList);
        setTaskDetails((prev) => {
          return {
            ...prev,
            actionCode: '',
            taskModal: false,
            viewTaskModal: false,
            newTaskData: {},
            task: undefined,
          };
        });
        break;
      case COMMON_ACTION_CODES.TASK_UPDATE:
        setTaskDetails((prev) => {
          return {
            ...prev,
            actionCode: COMMON_ACTION_CODES.VIEW_TASK,
            taskModal: true,
            viewTaskModal: false,
            newTaskData: {},
            task: undefined,
          };
        });
        break;
      case COMMON_ACTION_CODES.TASK_CANCEL:
        setTaskDetails((prev) => {
          return {
            ...prev,
            actionCode: COMMON_ACTION_CODES.VIEW_TASK,
            viewTaskModal: false,
            newTaskData: {},
            task: undefined,
          };
        });
        break;
      case COMMON_ACTION_CODES.VIEW_TASK:
        setTaskDetails((prev) => {
          return {
            ...prev,
            actionCode: COMMON_ACTION_CODES.CREATE_TASK,
            taskModal: true,
            viewTaskModal: true,
            task: actionData,
          };
        });
        break;
      case COMMON_ACTION_CODES.VIEW_TASK_CLOSE:
        setTaskDetails((prev) => {
          return {
            ...prev,
            actionCode: '',
            taskModal: false,
            viewTaskModal: false,
            task: undefined,
          };
        });
        break;
    }
  };

  const handleCreateConversationTask = async (
    taskId: string,
    messageList?: string[]
  ) => {
    const eventBus = EventBus.getEventBusInstance();
    if (messageList && messageList?.length > 0) {
      const finalVariables: any = [];
      messageList?.forEach((msgUuid: string) => {
        finalVariables.push({
          resourceId: taskId,
          resourceTypeCode: CONVERSATION_TASK_CODE.TASK,
          sourceId: msgUuid,
          sourceTypeCode: CONVERSATION_TASK_CODE.MESSAGE,
        });
      });
      try {
        const response = await CreateConversationTask({
          variables: {
            data: finalVariables,
          },
        });
        if (response?.data) {
          eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {});
          if (isComponentMounted?.current) {
            setMsgData((prev) => {
              const displayData = [...prev.displayData] || [];
              messageList?.forEach((msgUuid: string) => {
                const index = displayData.findIndex(
                  (item) => item.uuid === msgUuid
                );
                if (showConversationTaskCount && index >= 0) {
                  displayData[index] = getUpdatedTaskCountData(displayData, index);
                }
              });
              return {
                ...prev,
                displayData: displayData,
              };
            });
          }
        } else {
          showApiErrorMessage();
        }
      } catch (error) {
        showApiErrorMessage();
      }
    } else {
      try {
        const finalData = {
          resourceId: taskId,
          resourceTypeCode: CONVERSATION_TASK_CODE.TASK,
          sourceId: taskDetails?.newTaskData.uuid,
          sourceTypeCode: CONVERSATION_TASK_CODE.MESSAGE,
        }
        const response = await CreateConversationTask({
          variables: {
            data: finalData,
          },
        });
        if (response?.data) {
          eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {});
          if (isComponentMounted?.current) {
            setMsgData((prev) => {
              const displayData = [...prev.displayData] || [];
              const index = displayData.findIndex(
                (item) => item.uuid === taskDetails?.newTaskData?.uuid
              );
              if (showConversationTaskCount && index >= 0) {
                displayData[index] = getUpdatedTaskCountData(displayData, index);
              }
              return {
                ...prev,
                displayData: displayData,
              };
            });
          }
        } else {
          showApiErrorMessage();
        }
      } catch (error) {
        showApiErrorMessage();
      }
    }
  };

  const getCreateTaskElement = () => {
    const isMessageCopy = isMessageCopied(
      copyMessageState.copyMessageList,
      copyMessageState.code
    );
    return (
      <Tooltip title={'Create Task'}>
        <Pressable
          disabled={!isMessageCopy}
          onPress={() => {
            const newData = getSelectChatForCreateTask();
            setTaskDetails((prev) => {
              return {
                ...prev,
                actionCode: COMMON_ACTION_CODES.CREATE_TASK,
                viewTaskModal: true,
                newTaskData: newData,
              };
            });
            setCopyMessageState((prev) => {
              return {
                ...prev,
                code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
                copyMessageList: [],
              };
            });
          }}
          {...testID('CreateTaskClick')}
        >
          <VStack alignItems={'center'}>
            <CreateTaskSvg
              height={18}
              width={18}
              customStrokeColor={
                isMessageCopy ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray300
              }
            />
          </VStack>
        </Pressable>
      </Tooltip>
    );
  };

  const getCancelSelectElement = () => {
    return (
      <Pressable
        onPress={() => {
          setCopyMessageState((prev) => {
            return {
              ...prev,
              code: COPY_MESSAGE_CODE.COPY_MODE_OFF,
              copyMessageList: [],
            };
          });
        }}
        {...testID(TestIdentifiers.cancelBtn)}
      >
        <HStack>
          <Feather
            name="x"
            style={{
              fontWeight: '500',
              fontSize: 18,
              color: Colors.Custom.mainPrimaryPurple,
              marginTop: 2,
            }}
            {...testID('image')}
          />
          <Text
            paddingLeft={1}
            color={Colors.Custom.mainPrimaryPurple}
            fontSize={14}
            fontWeight={500}
            {...testID('Cancel')}
          >
            Cancel
          </Text>
        </HStack>
      </Pressable>
    );
  };
  const isNewMessageReceivedDisplay = () => {
    return msgData.newMessageUuids?.length > 0;
  }

  const showApiErrorMessage = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
  }

  const onEmailInboxFooterActionPerformed = (actionCode: IEmailReplyAction) => {
    const lastNonInternalMessageData = getLastNonInternalMessageInList(
      msgData.displayData
    );
    switch (actionCode) {
      case EMAIL_REPLY_ACTIONS.REPLY:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            isReplyAll: false,
            replyToMessageId:
              lastNonInternalMessageData && lastNonInternalMessageData.uuid
                ? lastNonInternalMessageData.uuid
                : '',
            forwardMessageId: '',
            isSendEmailDrawerOpen: true,
            contactIds: [props.selectedConversation.conversationContact.id || props.selectedConversation?.contactId],
          };
        });
        break;

      case EMAIL_REPLY_ACTIONS.FORWARD:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            isReplyAll: false,
            replyToMessageId: '',
            forwardMessageId:
              lastNonInternalMessageData && lastNonInternalMessageData.uuid
                ? lastNonInternalMessageData.uuid
                : '',
            isSendEmailDrawerOpen: true,
            contactIds: [],
          };
        });
        break;

      case EMAIL_REPLY_ACTIONS.REPLY_ALL:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            isReplyAll: true,
            replyToMessageId:
              lastNonInternalMessageData && lastNonInternalMessageData.uuid
                ? lastNonInternalMessageData.uuid
                : '',
            forwardMessageId: '',
            isSendEmailDrawerOpen: true,
            contactIds: [props.selectedConversation.conversationContact.id || props.selectedConversation?.contactId],
          };
        });
        break;

      case EMAIL_REPLY_ACTIONS.INTERNAL:
        setEmailDrawerState((prev) => {
          return {
            ...prev,
            isInternalMessage: true,
          };
        });
        break;

      default:
        break;
    }
  };

  const getDrawerViewListHeight = () => {
    if (props?.isInDrawerView) {
      return msgWindowHeight;
    }
    return undefined;
  };

  const getMessageUuidsForAssignType = () => {
    const copiedMessageUuids = getMessageUuidsFromCopyMessageList({
      copyMessageList: copyMessageState.copyMessageList,
    });
    if (copiedMessageUuids && copiedMessageUuids.length > 0) {
      return copiedMessageUuids;
    }
    if (showModal.assignTypeDrawer && showModal.messageData?.uuid) {
      return [showModal.messageData.uuid];
    }
    return [];
  };

  const onMessageSendFromFooter = ( msgText: string, msgData?: any, parentMessage?: IReplyMessageObject) => {
    onMessageSend(msgText, msgData, parentMessage);
    if (isComponentMounted.current) {
      setMsgData((prev) => {
        return {
          ...prev,
          selectedReplyMsg: {} as IReplyMessageObject,
        };
      });
    }
  }

  const getFooterView = () => {
    return (
      <VStack
        style={{
          backgroundColor: '#fff',
        }}
      >
        {!msgData.loadingMsgs && (
          <TypingOn
            selectedConversation={selectedConversation}
            selectedInboxTypeCode={props?.selectedInboxTypeCode || ''}
            isNewMessageShown={isNewMessageReceivedDisplay()}
          />
        )}

        {!msgData.loadingMsgs &&
          (props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION ||
            (msgData?.newMessageUuids &&
              msgData?.newMessageUuids?.length > 0)) && (
            <NewMessageReceived
              newMessageUuids={msgData.newMessageUuids}
              goToNewMessages={goToNewMessages}
            />
          )}
        {!msgData.loadingMsgs &&
          selectedInboxTypeCode == CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
          msgData.mentionMessage && (
            <View
              ref={mentionContainerRef}
              style={{
                width: '100%',
              }}
            >
              <MentionBar
                text={msgData.mentionMessage}
                handleClose={() => {
                  if (isComponentMounted.current) {
                    setMsgData((prev) => {
                      return {
                        ...prev,
                        mentionMessage: '',
                      };
                    });
                  }
                }}
                selectedConversationMentionId={
                  selectedConversation.conversationMentionId || ''
                }
              />
            </View>
          )}
        {
        messagingWindowState.conversationDraftMessageLoading ||
        messagingWindowState.footerLoading ? (
          <Skeleton.Text mx={1} mt={7} lines={4} width={'100%'}></Skeleton.Text>
        ) : !stateData.isBlockNumber &&
          isSendMessageAllowed(
            messagingWindowState?.selectedConversationData ||
              selectedConversation,
            userUuid,
            messagingWindowState?.isLoggedInUserGroupMember,
            messagingWindowState?.selectedConversationData?.conversationInbox ||
              selectedConversation?.conversationInbox
          ) &&
          stateData.isShowFooter ? (
          <>
            {messagingWindowState.footerLoading ? (
              <Skeleton.Text
                mx={1}
                mt={7}
                lines={4}
                width={'100%'}
              ></Skeleton.Text>
            ) : isChannelEmail(
                selectedConversation?.conversationInbox?.channelType
              ) ? (
              <View paddingBottom={3}>
                <EmailInboxFooter
                  selectedConversation={
                    messagingWindowState?.selectedConversationData
                  }
                  onMsgSend={(msgText, msgData) => {
                    onMessageSend(msgText, msgData);
                  }}
                  onActionPerformed={(actionCode) => {
                    onEmailInboxFooterActionPerformed(actionCode);
                  }}
                />
              </View>
            ) : isEFaxChannel ? (
              <>
                {
                   isValidEFaxContactInboxSourceId ?
                   (<View paddingBottom={3}>
                     <EFaxMessagingWindowFooter
                       selectedConversation={
                         messagingWindowState?.selectedConversationData
                       }
                       onActionMsgFooterPerformed={onActionMsgFooterPerformed}
                       onMsgSend={(
                         msgText: string,
                         msgData?: any,
                         parentMessage?: IReplyMessageObject
                       ) => {
                         onMessageSend(msgText, msgData, parentMessage);
                         if (isComponentMounted.current) {
                           setMsgData((prev) => {
                             return {
                               ...prev,
                               selectedReplyMsg: {} as IReplyMessageObject,
                             };
                           });
                         }
                       }}
                     />
                   </View>)
                   : <></>
                }
              </>

            ) : (
              <View paddingBottom={3}>
                <MessagingWindowFooter
                  enableShowAccountNameOnlyToPatient={
                    enableShowAccountNameOnlyToPatient
                  }
                  onCallBackEditorInputHeight={onActionMsgFooterPerformed}
                  msgQueue={props.msgQueue}
                  onAddMsgToQueue={props.onAddMsgToQueue}
                  isInDrawerView={props.isInDrawerView}
                  selectedReplyMsg={msgData.selectedReplyMsg}
                  conversationData={
                    messagingWindowState?.selectedConversationData
                  }
                  onMsgSend={onMessageSendFromFooter}
                  selectedTabCode={selectedTabCode}
                  selectedInboxTypeCode={selectedInboxTypeCode}
                  onActionMsgFooterPerformed={onActionMsgFooterPerformed}
                  conversationInbox={conversationInbox}
                  contactData={contactData}
                  isDetailsContainerVisible={isDetailsContainerVisible}
                  isInstantChatView={isInstantChatView}
                  parentCode={parentCode}
                  onMsgChangeText={props?.onMsgChangeText}
                  archiveOnSendData={msgData.archiveOnSendData}
                  isNewLineOnEnter={msgData.isNewLineOnEnter}
                  isSeachMsgContainerVisible={isSeachMsgContainerVisible}
                  onMentionActionPerformed={onViewChangeActionPerformed}
                  conversationDraftMessage={
                    messagingWindowState.conversationDraftMessage
                  }
                  totalScheduleMessage={
                    messagingWindowState.totalScheduleMessage
                  }
                  totalScheduleFailedMessage={
                    messagingWindowState.totalScheduleFailedMessage
                  }
                />
              </View>
            )}
          </>
        ) : stateData?.isBlockNumber &&
          messagingWindowState?.selectedConversationData?.conversationInbox
            ?.channelTwilioSms?.phoneNumber ? (
          <View>
            <ActionMessageView
              actionCode={ACTION_MESSAGE_CODE.INBOX_UNSUBSCRIBE}
              conversationId={selectedConversation?.uuid}
              message={getBlockNumberActionMessage(
                selectedConversation?.conversationContact?.name,
                messagingWindowState?.selectedConversationData?.conversationInbox?.channelTwilioSms?.phoneNumber?.toString(),
                intl
              )}
              customStyle={{
                backgroundColor: Colors.Custom.Gray100,
                textColor: Colors.Custom.Gray500,
                height: 60,
              }}
            />
          </View>
        ) : isSendMessageAllowed(
            messagingWindowState?.selectedConversationData,
            userUuid,
            messagingWindowState?.isLoggedInUserGroupMember,
            messagingWindowState?.selectedConversationData?.conversationInbox
          ) && !stateData.isConsentGiven ? (
          <View>
            <ActionMessageView
              actionCode={ACTION_MESSAGE_CODE.CONSENT_NOT_CHECK}
              conversationId={selectedConversation?.uuid}
              message={`${CONSENT_ERROR}`}
              customStyle={{
                backgroundColor: Colors.Custom.Gray100,
                textColor: Colors.Custom.Gray500,
                height: 53,
              }}
              onClickAction={() => {
                const newContactData = {
                  ...contactData,
                  showConsentError: true,
                };
                setSelectedRowData(newContactData);
                setSelectedActionView(
                  RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW
                );
              }}
            />
          </View>
        ) : (
          stateData.actionMessageCode &&
          !msgData?.loadingMsgs && (
            <View>
              <ActionMessageView
                actionCode={stateData.actionMessageCode}
                contactData={contactData}
                conversationId={selectedConversation?.uuid || ''}
                customStyle={{
                  backgroundColor: Colors.Custom.Gray100,
                  textColor: Colors.Custom.Gray500,
                  height:
                    stateData.actionMessageCode ===
                    ACTION_MESSAGE_CODE.PATIENT_NUMBER_CHANGED
                      ? 60
                      : 53,
                }}
              />
            </View>
          )
        )}
      </VStack>
    );
  };

  return (
    <VStack ref={headerContainerRef} flex={1}>
      <VStack flex={1}>
        {isDisplayHeader ? (
          <MessagingWindowHeader
            moduleCode={props.moduleCode || 'MessagingWindow'}
            isSideDetailVisible={isDetailsContainerVisible || isSeachMsgContainerVisible}
            createNoteView={isAthena ? undefined : createNoteView()}
            assignMessageTypeView={
              selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL ||
              isBroadCastAndPrivateGroup(messagingWindowState?.selectedConversationData) ? (
                <></>
              ) : (
                <AssignMessageTypeButton
                  onClick={handleAssignMessageTypeButton}
                  copyMessageList={copyMessageState.copyMessageList}
                  copyCode={copyMessageState.code}
                />
              )
            }
            copyMsgView={copyMsgView()}
            selectedInboxTypeCode={selectedInboxTypeCode}
            conversation={messagingWindowState?.selectedConversationData || {}}
            contactData={contactData || {}}
            onActionPerformed={onViewChangeActionPerformed}
            isDisplayActions={true}
            showInfoIconInHeader={showInfoIconInHeader}
            isDetailsContainerVisible={isDetailsContainerVisible || isSidecarContext}
            headerContainerRef={headerContainerRef}
            isInstantChatView={isInstantChatView}
            selectChatView={getSelectChatElement()}
            handleSelectChat={handleSelectChat}
            isSelectChatOn={
              copyMessageState?.code !== COPY_MESSAGE_CODE.COPY_MODE_OFF
            }
            onSelectChatAction={
              copyMessageState?.code !== COPY_MESSAGE_CODE.COPY_MODE_OFF &&
              copyMessageState.copyMessageList.length > 0
                ? true
                : false
            }
            createTaskView={getCreateTaskElement()}
            cancelView={getCancelSelectElement()}
            isSeachMsgContainerVisible={isSeachMsgContainerVisible}
            parentCode={parentCode}
            messagingWindowHeaderLoading={messagingWindowState?.footerLoading}
            showNavigationBack={props.showNavigationBack}
            navigationBackPressed={props.navigationBackPressed}
            pcpUserData={messagingWindowState.pcpUserData}
          />
        ) : (
          <></>
        )}

        {msgData.loadingMsgs ? (
          <View flex={1} style={[styles.scrollViewStyle]} padding={4} {...testID(TestIdentifiers.pageLoading)}>
            <Skeleton.Text lines={5} />
          </View>
        ) : (
          <View flex={1}>
            {isViewStickyNote ? (
              <StickyNoteMessageView
                contactData={contactData}
                conversationId={selectedConversation?.uuid || ''}
                headerContainerRef={headerContainerRef}
                isSideDetailVisible={
                  isDetailsContainerVisible || isSeachMsgContainerVisible
                }
                stickyNoteData={messagingWindowState?.stickyNoteData}
              />
            ) : (
              <></>
            )}
            <View w="100%">{renderSelectedMessageView()}</View>
            <View
              flex={!props?.isInDrawerView ? 1 : undefined}
              justifyContent={'flex-end'}
              height={getDrawerViewListHeight()}
            >
              {getMessagingListElem()}
            </View>
            <View>
              {msgData.loadingLatestMessages && (
                <Spinner
                  size="sm"
                  style={styles.spinnerStyle}
                  marginTop={msgData.mentionMessage ? -10 : -12}
                  {...testID(TestIdentifiers.lazyLoading)}
                />
              )}
              {getFooterView()}
            </View>
          </View>
        )}
      </VStack>

      {deleteMessageModal && (
        <DeleteMessageModal
          titleMessage="deleteMessageConfirmation"
          heading="Delete"
          onConfirm={() => {
            handleDeleteMessage(deleteMessageModal);
          }}
          onClose={() => {
            setDeleteMessageModal(false);
          }}
          isOpen={deleteMessageModal ? true : false}
        />
      )}
      {createNoteModalState.modalVisible &&
        createNoteModalState.code === CreateNoteType.CONTACT && (
          <AddNoteView
            selectedData={{content: createNoteModalState.copiedData}}
            contactId={contactData?.id}
            onFormActionPerformed={(actionCode: string) => {
              switch (actionCode) {
                case COMMON_ACTION_CODES.CANCEL:
                  handleCreatNoteDrawerClose();
                  break;
                case COMMON_ACTION_CODES.COMPLETED:
                  handleContactNoteCreated();
                  break;
                case COMMON_ACTION_CODES.FAILED:
                  handleContactNoteFailed();
                  break;
              }
            }}
          />
        )}
      {createNoteModalState.modalVisible &&
        createNoteModalState.code === CreateNoteType.CLINICAL && (
          <CreateNoteFromMessage
            conversationUUID={selectedConversation?.uuid}
            messageIds={copyMessageState?.copyMessageList?.map(
              (msg) => msg?.uuid
            )}
            isVisible={createNoteModalState.modalVisible}
            copiedMessages={createNoteModalState.copiedData}
            onClose={handleCreatNoteDrawerClose}
            contactInfo={contactInfo}
          />
        )}
      <MessagingWindowHeaderAction
        selectedRowData={selectedRowData}
        selectedActionView={
          msgData.isDrawerVisible
            ? GROUP_ACTION_CODES.GROUP_HEADER_CLICKED
            : selectedActionView
        }
        selectedConversation={messagingWindowState?.selectedConversationData || selectedConversation}
        formattedContactData={formattedContactData}
        contactType={contactType}
        selectedTabCode={getCreateChannelTabCode(selectedTabCode || '')}
        onComplete={(
          actionCode?: string,
          actionData?: any,
          selectedActionView?: string
        ) => {
          if (selectedActionView !== GROUP_ACTION_CODES.GROUP_HEADER_CLICKED) {
            setSelectedActionView('');
          } else {
            onCreateChannelActionPerformed(actionCode, actionData);
            if (isGroupConversation(selectedConversation)) {
              if (isComponentMounted.current) {
                setMsgData((prev) => {
                  return {
                    ...prev,
                    groupUpdatedCount: prev.groupUpdatedCount + 1,
                  };
                });
              }
            }
          }

          if (actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT) {
            setSelectedActionView('');
            if (isComponentMounted.current) {
              setMsgData((prev) => {
                return {
                  ...prev,
                  groupUpdatedCount: prev.groupUpdatedCount + 1,
                };
              });
            }
            if (actionData) {
              onConversationActionPerformed(actionCode, actionData)
            }
          }
        }}
      />
      {msgData?.showEditView && (
        <MsgReplyDrawer
          conversationData={messagingWindowState?.selectedConversationData}
          onMsgSend={(msgText: string, replyMessageData?: any) => {
            if (msgText) {
              if (replyMessageData?.id === msgData?.selectedMessage?.id) {
                onMessageUpdateAfterEditMessageByCurrentUser(
                  replyMessageData,
                  true
                );
                if (isComponentMounted.current) {
                  setMsgData((prev) => {
                    return {
                      ...prev,
                      showEditView: false,
                    };
                  });
                }
              } else if (msgData?.selectedMessage?.id) {
                onMessageSend(msgText, replyMessageData);
                if (isComponentMounted.current) {
                  setMsgData((prev) => {
                    return {
                      ...prev,
                      selectedReplyMsg: {} as IReplyMessageObject,
                      showEditView: false,
                    };
                  });
                }
              }
            }
          }}
          showMessageTypeTab={false}
          isUpdateMode={true}
          selectedMessage={msgData?.selectedMessage}
          selectedTabCode={selectedTabCode}
          selectedInboxTypeCode={selectedInboxTypeCode}
          onActionMsgFooterPerformed={onActionMsgFooterPerformed}
          isDrawerVisible={msgData?.showEditView}
          onMsgReplyActionPerformed={onActionMsgFooterPerformed}
          onMentionActionPerformed={onViewChangeActionPerformed}
        />
      )}

      <MessageBoxDrawerAction
        actionCode={taskDetails.actionCode}
        actionData={taskDetails}
        onFormActionPerformed={(
          actionCode: string,
          actionData?: ITask,
          messageList?: string[]
        ) => {
          onFormActionPerformed(actionCode, actionData, messageList);
        }}
        contactData={contactData}
      />

      {showModal.assignTypeDrawer ? (
        <AssignCommunicationTypeDrawer
          isOpen={showModal.assignTypeDrawer}
          messageUuids={getMessageUuidsForAssignType()}
          contactUuid={contactInfo.contactData.uuid || ''}
          onClose={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                assignTypeDrawer: false,
                messageData: {},
              };
            });
          }}
          onSave={() => {
            handleCommunicationTypeSave();
          }}
        />
      ) : (
        <></>
      )}
      {(showModal.taskModal || showModal.messageReadInfo) && (
        <MessageBoxActionViewDrawer
          actionCode={showModal.actionCode}
          actionData={showModal?.messageData}
          conversationUuid={selectedConversation?.uuid}
          groupConversationId={selectedConversation?.groupConversation?.id}
          onActionPerformed={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                taskModal: false,
                noteModal: false,
                messageReadInfo: false,
                actionCode: '',
              };
            });
          }}
          contactData={contactData}
        />
      )}

      {emailDrawerState.isInternalMessage ? (
        <MsgReplyDrawer
          isInternalNote
          conversationData={messagingWindowState?.selectedConversationData}
          conversationInbox={messagingWindowState?.selectedConversationData?.conversationInbox}
          showMessageTypeTab={false}
          selectedTabCode={props.selectedTabCode}
          messageType="internalMessage"
          selectedInboxTypeCode={selectedInboxTypeCode}
          isDrawerVisible={emailDrawerState.isInternalMessage}
          onMsgReplyActionPerformed={onActionMsgFooterPerformed}
          onMsgSend={(msgText, msgData, parentMessage) => {
            onInternalEmailMessageSend(msgText, msgData, parentMessage);
          }}
          onActionMsgFooterPerformed={onActionMsgFooterPerformed}
          onMentionActionPerformed={onViewChangeActionPerformed}
        />
      ) : (
        <></>
      )}

      {emailDrawerState.isSendEmailDrawerOpen ? (
        <EmailDrawerCommonV2
          conversationUuid={props.selectedConversation.uuid}
          isOpen={emailDrawerState.isSendEmailDrawerOpen}
          conversationId={props.selectedConversation.id}
          conversationDisplayId={props.selectedConversation.displayId}
          inboxId={props.selectedConversation.inboxId}
          subject={
            props.selectedConversation.additionalAttributes.mail_subject || ''
          }
          contactIds={emailDrawerState.contactIds}
          forwardMessageId={emailDrawerState.forwardMessageId}
          replyToMessageId={emailDrawerState.replyToMessageId}
          isReplyAll={emailDrawerState.isReplyAll}
          onClose={() => {
            setEmailDrawerState((prev) => {
              return {
                ...prev,
                isSendEmailDrawerOpen: false,
                forwardMessageId: '',
                replyToMessageId: '',
              };
            });
          }}
          onEmailSent={(data) => {
            onMessageSend(data.msgText, data.msgData);
          }}
        />
      ) : (
        <></>
      )}
    </VStack>
  );
};

export default memo(withMiniContactViewHOC(MessagingWindow));
