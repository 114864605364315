import {Text, useMediaQuery, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {styles} from '../AccessFormStyles';
const {width} = Dimensions.get('window');

const AccessForm = (props: any) => {
  const [accessFormState, setAccessFormState] = useState({
    loading: true,
  });
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const finalWidth = isIPadScreen || isIPadMiniScreen ? width / 1.5 : width / 3;

  return (
    <View style={styles.container}>
      <View
        style={{
          marginVertical: 20,
        }}
      >
        <Text>Your Practice does not have access to Fold APIs</Text>
      </View>

      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          onPress: () => {
            props.onCreateAction();
          },
        }}
        customProps={{
          btnText: intl.formatMessage({
            id: 'Connect to Fold via APIs',
          }),
          withRightBorder: false,
        }}
      ></FoldButton>
    </View>
  );
};

export default AccessForm;
