import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  conversationListItem: {
    //padding: 16,
    paddingVertical: 12,
  },
  conversationListItemNative: {
    paddingHorizontal: 16,
    paddingVertical: 16
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.BorderColor,
  },

  topBar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 55,
  },

  flexStart: {
    alignItems: 'center',
    // paddingLeft: 10,
  },

  flexEnd: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 5,
  },

  xsFontSize: {
    color: 'gray',
  },

  sFontSize: {
    fontSize: 13,
  },

  xsFontSizeBlack: {
    fontSize: 12.5,
  },
  inboxStyle: {
    alignContent: 'center',
    alignItems: 'center',
  },
  conversationSearchBar: {
    paddingVertical: 12,
  },
  msgTextOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    // marginRight: 16,
    paddingRight: 16,
  },
  icons: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.IconColor,
  },
  btn: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  dividerStyle: {
    width: '100%',
  },
  lastMenu: {
    paddingBottom: 15,
  },
  mainMenuTxt: {
    marginLeft: 10,
  },
  mainMenuContent: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    cursor: 'pointer',
  },
  firstMenu: {
    paddingTop: 10,
  },
  personActionsPopoverBody: {
    minWidth: 170,
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 1px',
    backgroundColor: '#FFF',
    paddingTop: 3,
  },
  tabStyles: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    height: 36,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 500,
  },
  tabsContainer: {
    marginVertical: 12,
  },
  emailSubtabStyles: {
    padding: 4,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: Colors.Custom.shadeGray,
  },
});
