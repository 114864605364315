import React from 'react';
import {INoteRiskScore} from '../../interfaces';
import {HStack, useDisclose, Text, View, Pressable, FlatList, VStack, Divider} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';

interface INoteRiskScoreDetailsProps {
  riskScoreList: INoteRiskScore[];
}

const NoteRiskScoreDetails = (props: INoteRiskScoreDetailsProps) => {
  const {riskScoreList} = props;
  const {isOpen: expanded, onToggle: toggleExpand} = useDisclose();
  const intl = useIntl();

  const renderSingleColumnField = (title: string, value: string,flex:number) => {
    return (
      <VStack flex={flex} paddingX={2}>
        <Text color={Colors.Custom.Gray400}>{title}</Text>
        <Text>{value}</Text>
      </VStack>
    );
  };
  const renderItem = ({item, index}: {item: INoteRiskScore; index: number}) => {
    return (
      <>
        <HStack>
          {renderSingleColumnField(
            '',
            item.name,
            4
          )}
          {renderSingleColumnField(
            '',
            item.state,
            4
          )}
          {renderSingleColumnField(
            '',
            item.score,
            2
          )}
        </HStack>
      </>
    );
  };

  return (
    <View w={'100%'}>
      <Pressable flexDir={'row'} alignItems={'center'} onPress={toggleExpand}>
        <Text
          selectable={false}
          color={Colors.Custom.mainPrimaryPurple}
        >{`${riskScoreList.length} Linked Score Groups`}</Text>
        <Feather
          color={Colors.Custom.mainPrimaryPurple}
          size={20}
          name={!expanded ? 'chevron-down' : 'chevron-up'}
        />
      </Pressable>
      {expanded && (
        <>
          <HStack>
            {renderSingleColumnField(
              intl.formatMessage({id: 'scoreGroupLabel'}),
              '',
              4
            )}
            {renderSingleColumnField(
              intl.formatMessage({id: 'scoreInterpretationLabel'}),
              '',
              4
            )}
            {renderSingleColumnField(
              intl.formatMessage({id: 'scoreLabel'}),
              '',
              2
            )}
          </HStack>
          <Divider my={0.5} />
          <FlatList
            renderItem={renderItem}
            data={riskScoreList}
            ItemSeparatorComponent={() => <Divider my={0.5} />}
            keyExtractor={(item, index) =>
              `${item.name}${item.operation}${index}`
            }
          />
        </>
      )}
    </View>
  );
};

export default NoteRiskScoreDetails;
