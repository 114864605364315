import {AxiosInstance} from 'axios';
import {Button, HStack, Text, View} from 'native-base';
import React, { } from 'react';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {IUser} from '../interfaces';
import {TeamMemberProfile} from './TeamMemberProfile';
import TeamActionView from './TeamActionView';
import {Tooltip} from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {getOnFocusButtonStyles} from '../../../../../utils/commonUtils';
import {Pressable} from 'react-native';
import {PractitionerData} from '../InviteUserDrawer/interfaces';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import TagsColumnView from './TagsColumnView';


export const getTeamMemberColumns = (
  actionFn: (actionCode: string, record: IUser) => {},
  externalUserList: PractitionerData[]
) => {

  const getExternalUserName = (record: any) => {
    let displayName = ''
    if (record?.externalUserIds?.length) {
      const displayNames = record.externalUserIds
      .map((externalUserId: string) => {
        const externalUser = externalUserList?.find(item => item.id === externalUserId);
        return externalUser?.displayName;
      })
      .filter(Boolean); 

       displayName = displayNames.join(', ')
    }

    if (record.externalUserId && !displayName) {
    const externalUser = externalUserList?.find(
      (item) => item.id == record.externalUserId
    );

      return externalUser?.displayName
    }
      return displayName;
  }

  const getUserLocations = (user: IUser) => {
    return user.userPracticeLocations?.map((item) => {
      return (
        !item.isDeleted &&
        item.accountLocations.map((accountLocation) => {
          if (
            !accountLocation?.practiceLocation?.isDeleted &&
            accountLocation?.practiceLocation?.name
          ) {
            return (
              <>
                <View mt={1} borderRadius={16} px={3} bgColor={'#F9F5FF'} mr={1}>
                  <Text color={'#6941C6'}>
                    {accountLocation?.practiceLocation?.name}
                  </Text>
                </View>
              </>
            );
          }
        })
      );
    });
  };

  const getRoles = (user: IUser) => {
    const roleNameList: string[] = []
    user?.userRoles?.forEach((role) => {
      if (role?.userRole?.userRole?.value && !roleNameList.includes(role?.userRole?.userRole?.value)) {
        roleNameList.push(role?.userRole?.userRole?.value)
      }
    })
    return roleNameList?.map((roleName) => {
      return (
          <View mt={1} borderRadius={16} px={3} bgColor={'#EFF6FF'} mr={1}>
            <Text color={'blue.700'}>{roleName}</Text>
          </View>
      );
    });
  };
  const getAccountName = (user: IUser) => {
    const accountNameList: string[] = []
    user?.accountUsers?.forEach((accountUser) => {
      if (accountUser?.account?.name) {
        accountNameList.push(accountUser?.account?.name)
      }
    })
    return accountNameList?.map((accountName) => {
      return (
        <>
          <View mt={1} borderRadius={16} px={3} bgColor={'#EFF6FF'} mr={1}>
            <Text color={'blue.700'}>{accountName}</Text>
          </View>
        </>
      );
    });
  };

  const handleProfileClick = (record: any) => {
    if (record.isInvited) {
      return;
    }
    actionFn('EDIT', record);
  }

  const columns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (name: string, record: IUser) => {
        return (
          <Pressable onPress={() => {
            handleProfileClick(record);
          }}>
            <TeamMemberProfile
              singleUserData={record}
              fullName={name || ''}
              showActiveStatus
              showInvitedStatus
              onProfileClick={() => {
                handleProfileClick(record);
              }}
              externalUserName={getExternalUserName(record)}
              profileUrl={
                record?.agent?.thumbnail.includes('404')
                  ? ''
                  : record?.agent?.thumbnail
              }
              width={'auto'}
            />
          </Pressable>

        );
      },
    },

    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="roles"
        />
      ),
      key: 'roles',
      width: '18%',
      render: (record: IUser) => {
        const roleNameList: string[] = []
        record?.userRoles?.forEach((role) => {
          if (role?.userRole?.userRole?.value && !roleNameList.includes(role?.userRole?.userRole?.value)) {
            roleNameList.push(role?.userRole?.userRole?.value)
          }
        })
        return (
          <TagsColumnView
            color={'blue.700'}
            bgColor={'#EFF6FF'}
            labelData={roleNameList}
            />
        )
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="practiceLocations"
        />
      ),
      key: 'practiceLocations',
      width: '18%',
      render: (record: IUser) => {
        const locationNameList: string[] = []
        record?.userPracticeLocations?.forEach((item) => {
          if (!item.isDeleted && item.accountLocations?.length) {
            item.accountLocations.forEach((accountLocation) => {
              if (!accountLocation?.practiceLocation?.isDeleted &&
                accountLocation?.practiceLocation?.name) {
                locationNameList.push(accountLocation?.practiceLocation?.name)
              }
            })
          }
        })
        return(
        <TagsColumnView
          color={'#6941C6'}
          bgColor={'#F9F5FF'}
          labelData={locationNameList}
          />
      )},
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="account"
        />
      ),
      key: 'account',
      width: '18%',
      render: (record: IUser) => (
        <>
          <View flexWrap={'wrap'} mt={1} flexDir={'row'}>
            {getAccountName(record)}
          </View>
        </>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '15%',
      render: (record: IUser) => {
        return (
          <TeamActionView
            record={record}
            actionFn={actionFn}
          />
        )
      },
    },
  ];
  return columns;
};

export const resetUserPassword = (args: {
  userId: number;
  userUuid: string;
  email: string;
  role: string;
  baseService: AxiosInstance;
  name: string;
}) => {
  const {userId, email, role, userUuid, baseService, name} = args;
  const url = `/crm-nest/resetUserPassword`;
  const data = {
    email,
    userId,
    role,
    userUuid,
    name,
  };

  return baseService.post(url, data);
};

export const deactivateUser = (args: {
  userId: number;
  email: string;
  baseService: AxiosInstance;
  isActive: boolean;
  selectedUserAccountId: number;
  selectedUserAccountUuid: string;
}) => {
  const {userId, email, baseService, isActive, selectedUserAccountId,selectedUserAccountUuid} = args;
  const url = `/crm-nest/deactivate_user?accountId=${selectedUserAccountId}&accountUuid=${selectedUserAccountUuid}`;
  const data = {
    email,
    userId,
    isActive,
  };

  return baseService.post(url, data);
};

export const getTextConfigsByStatus = (isActive?: boolean) => {
  if (isActive) {
    return {
      icon: 'deleteuser',
      tooltipLabel: 'Deactivate User',
    };
  }
  return {
    icon: 'adduser',
    tooltipLabel: 'Activate User',
  };
};

export const getActionElementByInvitedStatus = (args: {
  record: IUser;
  actionFn: (actionCode: string, record: IUser) => {};
}): JSX.Element => {
  const {record, actionFn} = args;
  const isActive = record.accountUsers?.[0]?.isActive;
  const {icon, tooltipLabel} = getTextConfigsByStatus(isActive);
  const isInvited = record.isInvited;
  if (isInvited) {
    return (
      <Button.Group>
        <Button
          onPress={() => {
            actionFn('RESEND_INVITE', record || ({} as IUser));
          }}
          _focus={{...getOnFocusButtonStyles()}}
          leftIcon={
            <Tooltip title="Resend Invite">
              <Feather
                name="mail"
                size={17}
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  fontSize: 14.29,
                  color: Colors.Custom.Gray600,
                }}
              />
            </Tooltip>
          }
          style={{backgroundColor: 'transparent', borderWidth: 0}}
        />
        <Button
          onPress={() => {
            actionFn('CANCEL_INVITE', record || ({} as IUser));
          }}
          _focus={{...getOnFocusButtonStyles()}}
          leftIcon={
            <Tooltip title="Cancel Invite">
              <MaterialCommunityIcon
                name="account-cancel-outline"
                size={17}
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  fontSize: 14.29,
                  color: Colors.Custom.Gray600,
                }}
              />
            </Tooltip>
          }
          style={{backgroundColor: 'transparent', borderWidth: 0}}
        />
      </Button.Group>
    );
  } else {
    return (
      <Button.Group>
        <Button
          onPress={() => {
            actionFn(COMMON_ACTION_CODES.EDIT, record || ({} as IUser));
          }}
          _focus={{...getOnFocusButtonStyles()}}
          leftIcon={
            <Tooltip title="Edit">
              <Feather
                name="edit-2"
                size={17}
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  fontSize: 14.29,
                  color: Colors.Custom.Gray600,
                }}
              />
            </Tooltip>
          }
          style={{backgroundColor: 'transparent', borderWidth: 0}}
        />
        <Button
          onPress={() => {
            actionFn('password', record || ({} as IUser));
          }}
          _focus={{...getOnFocusButtonStyles()}}
          leftIcon={
            <Tooltip title="Reset Password">
              <Feather
                name="user"
                size={17}
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  fontSize: 14.29,
                  color: Colors.Custom.Gray600,
                }}
              />
            </Tooltip>
          }
          style={{backgroundColor: 'transparent', borderWidth: 0}}
        />

        <Button
          onPress={() => {
            actionFn('deactivate', record || ({} as IUser));
          }}
          _focus={{...getOnFocusButtonStyles()}}
          leftIcon={
            <Tooltip title={tooltipLabel}>
              <AntIcon
                name={icon}
                size={17}
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  fontSize: 14.29,
                  color: Colors.Custom.Gray600,
                }}
              />
            </Tooltip>
          }
          style={{backgroundColor: 'transparent', borderWidth: 0}}
        />
      </Button.Group>
    );
  }
};
