import {IUser} from '../interfaces';
import {ehrCodeDisplay, PractitionerData} from '../InviteUserDrawer/interfaces';
import {IAccountRole} from './interface';

export const getEhrPracticeNameName = (
  userData: any,
  practitionerData: PractitionerData[],
  ehrList: ehrCodeDisplay[],
  currentCodeEHR: string
) => {
  if (!userData.externalUserId) {
    return '';
  }
  const selectedUser = practitionerData.find((item) => {
    return item.id === userData.externalUserId;
  });
  return `${selectedUser?.displayName} ${getEhrDisplayName(ehrList, currentCodeEHR)}`;
};

export const getEhrDisplayName = (
  ehrList: ehrCodeDisplay[],
  currentCodeEHR: string
) => {
  return (
    (ehrList || []).find((ehr) => ehr.code === (currentCodeEHR))
      ?.display || ''
  );
};


export const isAddPracticeBtnActive = (accountUserRoleList: IAccountRole[]) => {
  let isAddPracticeActive = true;
  accountUserRoleList.forEach((accountRole) => {
    if (!accountRole.accountUuid || !accountRole.rolesToAdd.length) {
      isAddPracticeActive = false
    }
  })
  return isAddPracticeActive
}

export const getAssociatedAccountList = (user: IUser) => {
  const accountRoleList: IAccountRole[] = []
  if (user) {
    const {accountUsers, userRoles} = user
    accountUsers?.forEach((accountUser, index) => {
      const userRoleList = userRoles?.filter((userRole) => {
        return userRole?.accountId === accountUser?.account?.id
      })?.map((userRole) => userRole?.userRole?.userRole?.id) || []
      const obj: IAccountRole = {
        accountUuid: accountUser?.account?.uuid,
        accountId: accountUser?.account?.id || -1,
        userId: user.id || -1,
        rolesToAdd: userRoleList,
        roles: userRoleList,
        editedRoles: userRoleList,
        externalUserId: accountUser?.externalUserId || '',
        id: index
      }
      accountRoleList.push(obj)
    })
  }
  return accountRoleList
}
