import { useLazyQuery } from '@apollo/client';
import { Drawer, Select as MultiSelect } from 'antd';
import { Checkbox, Divider, FormControl, HStack, Text, View, VStack } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT, MLOV_CATEGORY } from '../../../../../constants';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { IEhrCapability, IMlov } from '../../../../../Interfaces';
import { UserQueries } from '../../../../../services';
import { Colors } from '../../../../../styles';
import { getAccountUUID, getUserUUID } from '../../../../../utils/commonUtils';
import { getDateStrFromMomentObj, getMomentObj, isFutureDay, getCurrentTimeZone, getStartOfDate, getEndOfDate } from '../../../../../utils/DateUtils';
import { getCareStudioMlovListByCategoryCode } from '../../../../../utils/mlovUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { ModalActionDatePicker } from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { NoteFormTemplateSearch } from '../../../../common/NoteFormTemplateSearch/NoteFormTemplateSearch';
import PAMISearch, { PAMISearchType } from '../../../../common/PAMISearch/PAMISearch';
import ReasonForVisitSearch from '../../../../common/ReasonForVisitSearch/ReasonForVisitSearch';
import ReasonForVisitFreeText from '../../../../PublicPages/AppointmentBookingWidget/ReasonForVisitFreeText';
import { IUser } from '../../../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { ICodeableParam } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import UserWithRoleItem from '../../../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/UserWithRoleItem';
import { IFormListState } from './DocumentationView';
import { STATUS_FOR_FILTER } from '../constants';
import { DocStatus } from '../interfaces';
import { getEhrConfig } from '../../../../../utils/capabilityUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { CodeGroupCategory } from '../../../../RightSideContainer/ContentManagement/CampaignManagement/constants';

export interface IPatientNotesSelectedFilters {
  createdByUserIds?: string[];
  createdByUsers?: IUser[];

  signedByUserIds?: string[];
  signedByUsers?: IUser[];

  lastAmendedAndSignedByUserIds?: string[];
  lastAmendedAndSignedByUsers?: IUser[];

  createdOnStartDate?: string;
  signedOnStartDate?: string;
  lastAmendedAndSignedOnStartDate?: string;

  createdOnEndDate?: string;
  signedOnEndDate?: string;
  lastAmendedAndSignedOnEndDate?: string;

  chiefComplaint?: {
    code: string;
    displayName: string;
  };
  diagnosis?: ICodeableParam;
  form?: IFormListState;
  selectedStatusCodes?: string[];
  selectedStatusCodeValue?: typeof STATUS_FOR_FILTER
}

interface IProps {
  isVisible?: boolean;
  selectedFilters?: IPatientNotesSelectedFilters,

  onClose?: () => void;
  onApply: (data?: IPatientNotesSelectedFilters) => void;
}

interface IComponentState {
  selectedFilters: IPatientNotesSelectedFilters;
  usersData?: IUser[];
}

export function FilterPatientNotesViewDrawer(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    selectedFilters: (props.selectedFilters || {}),
    usersData: [],
  });
  const isOnlySignedStatusSelected =
    props?.selectedFilters?.selectedStatusCodes?.length === 1 &&
    props?.selectedFilters?.selectedStatusCodes?.[0] === DocStatus.FINAL;
  const [ hideField, setHideField ] = useState({
    lastAmendedAndSignedByUser: isOnlySignedStatusSelected,
    lastAmendedAndSignedByDate: isOnlySignedStatusSelected,
  })
  const contextData = useContext(CommonDataContext);
  const ehrCapabilities: IEhrCapability[] = contextData.ehrCapabilities || [];
  const isDiagnosisEnabled = ehrCapabilities.find(
    (capability) => capability.resourceName === CodeGroupCategory.diagnosis
  )?.isEnabled;

  const accountUuid = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const intl = useIntl();
  const ehrConfig = getEhrConfig();
  /**
   * Get all users
   */
  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR_WITHOUT_EMPLOYER, {
    variables: {
      accountId: accountUuid,
      roleCode: USER_ROLE_CODES.EMPLOYER
    },
  });

  const getUsersData = async () => {
    const getUsersResult = await getUsers();
    let users:  IUser[] = JSON.parse(JSON.stringify(getUsersResult?.data?.users));
    users = users?.sort?.((user1: IUser, user2: IUser) => {
      if (user1?.uuid === loggedInUserId) {
        return -1;
      }
      if (user2?.uuid === loggedInUserId) {
        return 1;
      }
      const userName1 = user1?.name?.toLowerCase() || '';
      const userName2 = user2?.name?.toLowerCase() || '';
      if (userName1 < userName2) return -1;
      if (userName2 < userName1) return 1;
      return 0;
    });
    if (users?.[0]?.uuid === loggedInUserId && users?.[0]?.name) {
      users[0].name = `${users?.[0]?.name} (You)`;
    }
    setComponentState(prev => ({
      ...prev,
      usersData: users,
    }));
  };

  const getUsersName = (userIds: any[], userList?: IUser[]) => {
    const userNames = [] as any;
    userIds.map((id) => {
      userList?.map((agentRecord: any) => {
        if (id === (agentRecord?.uuid)) {
          userNames.push(agentRecord?.name);
        }
      })
    })
    return userNames;
  }

  function renderDateElement(labelId: string, placeholderId: string, selectedFilterKey: string, isStartDateFilter: boolean) {
    return (
      <ModalActionDatePicker
        label={labelId}
        value={(componentState.selectedFilters as any)?.[selectedFilterKey] ? getMomentObj((componentState.selectedFilters as any)[selectedFilterKey]) : null}
        format={DISPLAY_DATE_FORMAT}
        // placeholder={intl.formatMessage({ id: placeholderId })}
        placeholder={DISPLAY_DATE_FORMAT}
        labelStyles={{marginLeft: 6}}
        onChange={(date) => {
          setComponentState((prev) => {
            const selectedDate = date && (isStartDateFilter ? getStartOfDate(date) : getEndOfDate(date));
            return {
              ...prev,
              selectedFilters: {
                ...prev.selectedFilters,
                [selectedFilterKey]: selectedDate ? selectedDate.toISOString() : null,
              },
            };
          });
        }}
        disabledDate={(current: any) => {
          return (
            current &&
            isFutureDay(current, getCurrentTimeZone())
          );
        }}
        customStyle={{flex: 1}}
      />
    );
  }

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <Drawer
      open={props.isVisible || false}
      width={700}
      onClose={props.onClose}
      closable={false}
      title={
        <>
          <ModalActionTitle
            titleNode={
              <VStack>
              <Text
                flexWrap={'wrap'}
                size={'xxlBold'}
                color={Colors.primary[400]}
              >
                {intl.formatMessage({id: 'filterPatientNotesDrawerTitle'})}
              </Text>
              {ehrConfig.isAthena && (
                <Text
                  color={Colors?.Custom?.Gray400}
                  fontWeight={400}
                  fontSize={'14'}
                >
                  {intl.formatMessage({id: 'encounterNotesFilterMessage'})}
                </Text>
              )}
            </VStack>
            }
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onClose?.();
                },
              },
              {
                show: true,
                id: 1,
                btnText: 'clearAllFiltersBtnLabel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      selectedFilters: {},
                    };
                  });
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'Apply',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  props.onApply?.(componentState.selectedFilters);
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack space={4}>
        <Text fontWeight={'600'} fontSize={18}>
          {intl.formatMessage({id: 'filterByNoteStatus'})}
        </Text>
        <FormControl>
          <View style={{paddingLeft: 4, marginVertical: -8}}>
            {STATUS_FOR_FILTER?.map((filter) => {
              return (
                <View key={filter.code} style={{flexDirection: 'row', alignItems: 'center', marginVertical: 10}}>
                <Checkbox
                  key={filter.code}
                  value={filter.value}
                  alignItems={'center'}
                  isChecked={
                    componentState?.selectedFilters?.selectedStatusCodes?.includes(
                      filter.code
                    ) || false
                  }
                  onChange={(isChecked) => {
                    let isOnlySignedStatusSelected = false;
                    setComponentState((prev) => {
                      const selectedStatusCodes =
                        prev?.selectedFilters?.selectedStatusCodes || [];
                      if (isChecked) {
                        if (!selectedStatusCodes.includes(filter.code)) {
                          selectedStatusCodes.push(filter.code);
                        }
                      } else {
                        if (selectedStatusCodes.includes(filter.code)) {
                          selectedStatusCodes.splice(
                            selectedStatusCodes.indexOf(filter.code),
                            1
                          );
                        }
                      }
                      const selectedStatusCodeValue: typeof STATUS_FOR_FILTER =
                        [];
                      selectedStatusCodes?.length &&
                        STATUS_FOR_FILTER?.forEach?.((status) => {
                          if (
                            selectedStatusCodes.indexOf?.(status?.code) !== -1
                          ) {
                            selectedStatusCodeValue?.push(status);
                          }
                        });
                      isOnlySignedStatusSelected =
                        selectedStatusCodes?.length === 1 &&
                        selectedStatusCodes[0] === DocStatus.FINAL;
                      return {
                        ...prev,
                        selectedFilters: {
                          ...prev.selectedFilters,
                          selectedStatusCodeValue: selectedStatusCodeValue,
                          selectedStatusCodes: selectedStatusCodes,
                          ...(isOnlySignedStatusSelected && {
                            lastAmendedAndSignedByUserIds: undefined,
                            lastAmendedAndSignedByUsers: undefined,
                            lastAmendedAndSignedOnEndDate: undefined,
                            lastAmendedAndSignedOnStartDate: undefined,
                          }),
                        },
                      };
                    });
                    setHideField((prev) => {
                      return {
                        ...prev,
                        lastAmendedAndSignedByUser: isOnlySignedStatusSelected,
                        lastAmendedAndSignedByDate: isOnlySignedStatusSelected,
                      };
                    });
                  }}
                />
                  <Text
                    size={'smMedium'}
                    color={Colors.Custom.Gray700}
                    style={{marginLeft: 8}}
                  >
                    {filter.value}
                  </Text>
                </View>
              );
            })}
          </View>
        </FormControl>

        <Text fontWeight={'600'} fontSize={18}>
          {intl.formatMessage({id: 'filterByUser'})}
        </Text>
        <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, marginLeft: 6}}
              textLocalId={'filterPatientNotesDrawerCreatedByTitle'}
            />
          </FormControl.Label>
          <MultiSelect
            mode="multiple"
            size="large"
            allowClear
            value={getUsersName([...(componentState.selectedFilters?.createdByUserIds || [])], componentState.usersData)}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerCreatedByPlaceholder' })}
            onChange={(values: string[], selectedData: any[]) => {
              const userIds: string[] = selectedData?.map((item: any) => item.userId) || [];
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    createdByUserIds: (userIds || []),
                    createdByUsers: selectedData,
                  },
                };
              });
            }}
            maxTagCount={'responsive'}
          >
            {componentState.usersData?.map?.((agentRecord: any) => {
              return (
                <MultiSelect.Option
                  key={agentRecord?.uuid}
                  userId={agentRecord?.uuid}
                  value={agentRecord?.name}
                >
                  <UserWithRoleItem
                    agentRecord={agentRecord}
                  />
                </MultiSelect.Option>
              );
            })}
          </MultiSelect>
        </FormControl>

        <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, marginLeft: 6}}
              textLocalId={'filterPatientNotesDrawerSignedByTitle'}
            />
          </FormControl.Label>
          <MultiSelect
            mode="multiple"
            size="large"
            allowClear
            value={getUsersName([...(componentState.selectedFilters?.signedByUserIds || [])], componentState.usersData)}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerSignedByPlaceholder' })}
            onChange={(values: string[], selectedData: any[]) => {
              const userIds: string[] = selectedData?.map((item: any) => item.userId) || [];
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    signedByUserIds: (userIds || []),
                    signedByUsers: selectedData,
                  },
                };
              });
            }}
            maxTagCount={'responsive'}
          >
            {componentState.usersData?.map?.((agentRecord: any) => {
              return (
                <MultiSelect.Option
                  key={agentRecord?.uuid}
                  userId={agentRecord?.uuid}
                  value={agentRecord?.name}
                >
                  <UserWithRoleItem
                    agentRecord={agentRecord}
                  />
                </MultiSelect.Option>
              );
            })}
          </MultiSelect>
        </FormControl>

        {!hideField?.lastAmendedAndSignedByUser && (
          <FormControl>
            <FormControl.Label>
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500, marginLeft: 6}}
                textLocalId={'filterPatientNotesDrawerLastAmendedAndSignedByTitle'}
              />
            </FormControl.Label>
            <MultiSelect
              mode="multiple"
              size="large"
              allowClear
              value={getUsersName(
                [
                  ...(componentState.selectedFilters
                    ?.lastAmendedAndSignedByUserIds || []),
                ],
                componentState.usersData
              )}
              placeholder={intl.formatMessage({
                id: 'filterPatientNotesDrawerLastAmendedAndSignedByPlaceholder',
              })}
              onChange={(values: string[], selectedData: any[]) => {
                const userIds: string[] =
                  selectedData?.map((item: any) => item.userId) || [];
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selectedFilters: {
                      ...prev.selectedFilters,
                      lastAmendedAndSignedByUserIds: userIds || [],
                      lastAmendedAndSignedByUsers: selectedData,
                    },
                  };
                });
              }}
              maxTagCount={'responsive'}
            >
              {componentState.usersData?.map?.((agentRecord: any) => {
                return (
                  <MultiSelect.Option
                    key={agentRecord?.uuid}
                    userId={agentRecord?.uuid}
                    value={agentRecord?.name}
                  >
                    <UserWithRoleItem agentRecord={agentRecord} />
                  </MultiSelect.Option>
                );
              })}
            </MultiSelect>
          </FormControl>
        )}

        <Text fontWeight={'600'} fontSize={18}>
          {intl.formatMessage({id: 'filterByNoteDate'})}
        </Text>

        <HStack space={2} alignItems={'center'}>
          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerCreatedOnStartDateTitle', 'filterPatientNotesDrawerCreatedOnStartDatePlaceholder', 'createdOnStartDate', true)}
          </View>

          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerCreatedOnEndDateTitle', 'filterPatientNotesDrawerCreatedOnEndDatePlaceholder', 'createdOnEndDate', false)}
          </View>
        </HStack>

        <HStack space={2} alignItems={'center'}>
          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerSignedOnStartDateTitle', 'filterPatientNotesDrawerSignedOnStartDatePlaceholder', 'signedOnStartDate', true)}
          </View>

          <View flex={1}>
            {renderDateElement('filterPatientNotesDrawerSignedOnEndDateTitle', 'filterPatientNotesDrawerSignedOnEndDatePlaceholder', 'signedOnEndDate', false)}
          </View>
        </HStack>

        {!hideField?.lastAmendedAndSignedByDate && (
          <HStack space={2} alignItems={'center'}>
            <View flex={1}>
              {renderDateElement(
                'filterPatientNotesDrawerLastAmendedAndSignedOnStartDateTitle',
                'filterPatientNotesDrawerLastAmendedAndSignedOnStartDatePlaceholder',
                'lastAmendedAndSignedOnStartDate',
                true
              )}
            </View>

            <View flex={1}>
              {renderDateElement(
                'filterPatientNotesDrawerLastAmendedAndSignedOnEndDateTitle',
                'filterPatientNotesDrawerLastAmendedAndSignedOnEndDatePlaceholder',
                'lastAmendedAndSignedOnEndDate',
                false
              )}
            </View>
          </HStack>
        )}

        <Text fontWeight={'600'} fontSize={18}>
          {intl.formatMessage({id: 'filterByNoteFields'})}
        </Text>

        {isDiagnosisEnabled && (
          <FormControl>
            <FormControl.Label>
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500, marginLeft: 6}}
                textLocalId={'filterPatientNotesDrawerDiagnosisTitle'}
            />
            </FormControl.Label>
            <PAMISearch
              value={componentState.selectedFilters?.diagnosis}
              showAdditionalDetails={true}
              allowUserMultiSelect={true}
              enablePaginationOnScroll
              addNewOptionEnabled={false}
              clearOnSelection={false}
              searchType={PAMISearchType.diagnosis}
              // additionalHeaders={contextData.headers}
              placeholder={intl.formatMessage({
                id: 'filterPatientNotesDrawerDiagnosisPlaceholder',
              })}
              isShowError={false}
              onChange={(value) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selectedFilters: {
                      ...prev.selectedFilters,
                      diagnosis: value,
                    },
                  };
                });
              }}
            />
          </FormControl>
        )}

          <ReasonForVisitFreeText
            showLabel
            customLabel={intl.formatMessage({ id: 'filterPatientNotesDrawerChiefComplaintTitle' })}
            isRequired={false}
            value={componentState.selectedFilters?.chiefComplaint?.displayName}
            customPlaceHolder={intl.formatMessage({ id: 'filterPatientNotesDrawerChiefComplaintPlaceholder' })}
            isShowError={false}
            numberOfLines={1}
            onChange={(reasonForVisit) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    chiefComplaint: reasonForVisit,
                  },
                };
              });
            }}
          />

        <FormControl>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray500, marginLeft: 6}}
              textLocalId={'filterPatientNotesDrawerNoteTemplateTitle'}
            />
          </FormControl.Label>
          <NoteFormTemplateSearch
            allowClear={true}
            value={componentState.selectedFilters?.form}
            placeholder={intl.formatMessage({ id: 'filterPatientNotesDrawerNoteTemplatePlaceholder' })}
            onChange={(form) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedFilters: {
                    ...prev.selectedFilters,
                    form: form,
                  },
                };
              });
            }}
          />
        </FormControl>

      </VStack>
    </Drawer>
  );
}
