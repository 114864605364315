import {Box, Spinner, useToast, View} from 'native-base';
import {useEffect, useState} from 'react';
import HolidayTopBar from './HolidayTopBar';
import {HolidayActions, HolidayFilterAction} from './HolidayConst';
import {HolidayDataInterface, HolidayFilterInterface, IHolidayInterface} from './Interfaces';
import AddOrUpdateHolidays from './AddOrUpdateHolidays';
import {Skeleton, Table} from 'antd';
import {TestIdentifiers, testID} from '../../../testUtils';
import {Dimensions} from 'react-native';
import {addOrUpdateHoliday, getAllHolidays} from './HolidayApiService';
import { getColumns } from './HolidaysHelper';
import { useIntl } from 'react-intl';
import { FHAlertDialog } from '../../common/FHAlertDialog';
import { BUTTON_TYPE } from '../../../constants';
import {getAccountUUID} from '../../../utils/commonUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {styles} from './HolidaysStyles';
import {useLazyQuery} from '@apollo/client';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import {getDateStrFromFormat, isDayAndDateInPast} from '../../../utils/DateUtils';
import { TABLE_TOP_BAR_ACTION_CODES } from '../../common/TableTopBar';

const HolidaysList = (props: IHolidayInterface) => {
  const intl = useIntl();
  const [holidayListState, setHolidayListState] = useState<{
    searchString: string;
    holidayFilter: HolidayFilterInterface;
    loading: boolean;
    deleteConfirmationOpen: boolean;
    selectedItem: HolidayDataInterface;
    actionCode: string;
    holidaysList: HolidayDataInterface[];
    isEdit: boolean;
    spinnerLoading: boolean;
    practiceLocations:any[];
  }>({
    searchString: '',
    holidayFilter: HolidayFilterAction[0],
    loading: false,
    deleteConfirmationOpen: false,
    selectedItem: {} as HolidayDataInterface,
    actionCode: '',
    holidaysList: [] as HolidayDataInterface[],
    isEdit: false,
    spinnerLoading: false,
    practiceLocations: [] as any[],
  });

  const {height} = Dimensions.get('window');
  const accountUuid = getAccountUUID();
  const toast = useToast();
  useEffect(() => {
    getAllHolidaysList();
  }, [holidayListState.holidayFilter, holidayListState.searchString]);

  const getAllHolidaysList = async () => {
    setHolidayListState((prev) => ({
      ...prev,
      loading: true,
    }));
    const holidaysRes = await getAllHolidays(
      holidayListState.searchString,
      holidayListState.holidayFilter?.key
    ).catch(() => {
      setHolidayListState((prev) => ({
        ...prev,
        loading: false,
      }));
    });
    if (holidaysRes?.data?.length) {
      setHolidayListState((prev) => ({
        ...prev,
        loading: false,
        holidaysList: holidaysRes?.data as HolidayDataInterface[],
      }));
    } else {
      setHolidayListState((prev) => ({
        ...prev,
        loading: false,
        holidaysList: [] as HolidayDataInterface[],
      }));
    }
  };
  const [accountPracticeLocationsQuery] = useLazyQuery<any>(
    UserPracticeLocationQueries.GetAccountPracticeLocationsByTenantId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const getAccountLocations = async () => {
    const accountLocationsRes = await accountPracticeLocationsQuery({
      variables: {
        tenantId: accountUuid
      }
    });
    if (accountLocationsRes?.data?.accountLocations?.length) {
      setHolidayListState((prev) => {
        return {
          ...prev,
          practiceLocations: accountLocationsRes.data?.accountLocations || [],
        };
      });
    }
  };
  const onActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case HolidayActions.ADD_NEW:
        setHolidayListState((prev) => ({
          ...prev,
          actionCode: HolidayActions.ADD_OR_EDIT,
          isEdit: false,
        }));
        break;
      case HolidayActions.APPLY_FILTER:
        setHolidayListState((prev) => ({
          ...prev,
          actionCode: actionCode,
          holidayFilter: actionData,
          loading: true,
        }));
        break;
        case HolidayActions.EDIT_HOLIDAY:
        setHolidayListState((prev) => ({
          ...prev,
          actionCode: HolidayActions.ADD_OR_EDIT,
          selectedItem: actionData,
          isEdit: true,
        }));
        break;
        case HolidayActions.DELETE_HOLIDAY:
        setHolidayListState((prev) => {
          return {
            ...prev,
            actionCode: actionCode,
            selectedItem: actionData,
          }
        });
        break;
        case HolidayActions.COPY_HOLIDAY:
        setHolidayListState((prev) => ({
          ...prev,
          actionCode: HolidayActions.COPY_HOLIDAY,
          selectedItem: actionData,
          isEdit: false,
        }));
        break;
      default:
        break;
    }
  };
  const onDeleteHoliday = async () => {
    setHolidayListState(prev=> {
      return {
        ...prev,
        spinnerLoading: true
      }
    })
    const payloadData = {
      holidays: {
        id: holidayListState.selectedItem?.id,
        accountUuid: accountUuid,
        isDeleted: true
      },
      isUpdateForAllLocation: false,
    } as any;
    const addOrUpdateRes: any = await addOrUpdateHoliday(
      payloadData
    ).catch((err: any) => {
      showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
      setHolidayListState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (addOrUpdateRes?.data) {
      showToast(
        toast,
        intl.formatMessage({
          id: 'holidayDeleteMessage',
        }),
        ToastType.success
      );
      getAllHolidaysList();
      setHolidayListState((prev) => ({
        ...prev,
        actionCode: '',
        selectedItem: {} as HolidayDataInterface,
        spinnerLoading:  false
      }));
    }
  }
  useEffect(()=> {
    getAccountLocations()
  },[])

  useEffect(() => {
    setHolidayListState((prev) => ({
      ...prev,
      searchString: props?.searchString || '',
      loading: true,
    }));
  }, [props.searchString]);

  useEffect(() => {
    if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.NEW) {
      onActionPerformed(HolidayActions.ADD_NEW, '');
    } else if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.CLOSE) {
      setHolidayListState((prev) => ({
        ...prev,
        actionCode: '',
        selectedItem: {} as HolidayDataInterface,
      }));
    }
  }, [props?.selectedActionCode]);

  return (
    <View>
      <Box
        overflow={'hidden'}
        marginBottom={0}
        bgColor={'white'}
        borderRadius={0}
        backgroundColor={'white'}
      >
        <HolidayTopBar
          filterValue={holidayListState.holidayFilter}
          showTableTopBar={props?.showTableTopBar}
          onAction={(actionCode: string, actionData: any) => {
            onActionPerformed(actionCode, actionData);
          }}
          onSearch={(text: string) => {
            if (text?.length > 2) {
              setHolidayListState((prev) => ({
                ...prev,
                searchString: text,
                loading: true,
              }));
            }
          }}
        />
        {!holidayListState.loading && (
          <Table
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            scroll={{x: 800, y: height - 260}}
            rowKey={(row: HolidayDataInterface) => row.id || ''}
            columns={getColumns(intl, onActionPerformed, holidayListState.practiceLocations)}
            dataSource={holidayListState.holidaysList}
            pagination={false}
            onRow={(data) => ({
              onClick: () => {
                const isPastHoliDay = isDayAndDateInPast(getDateStrFromFormat(data.startDate));
                if (!isPastHoliDay) {
                  onActionPerformed(HolidayActions.EDIT_HOLIDAY, data)
                }
              }
            })}
          />
        )}
        <>
        {holidayListState.loading && (
          <View margin={4} {...testID(TestIdentifiers.pageLoading)}>
            <Skeleton active />
          </View>
        )}
        </>
      </Box>
      {(holidayListState.actionCode === HolidayActions.ADD_OR_EDIT
      || holidayListState.actionCode === HolidayActions.COPY_HOLIDAY) && (
        <AddOrUpdateHolidays
          isOpen={true}
          actionCode={holidayListState.actionCode}
          selectedItem={holidayListState.selectedItem}
          isEdit={holidayListState.isEdit}
          onClose={(isRefresh?: boolean) => {
            setHolidayListState((prev) => ({
              ...prev,
              actionCode: '',
              selectedItem: {} as HolidayDataInterface,
            }));
            if (isRefresh) {
              getAllHolidaysList();
            }
            props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE)
          }}
        />
      )}
      <FHAlertDialog
        spinnerView={holidayListState.spinnerLoading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        isOpen={holidayListState.actionCode === HolidayActions.DELETE_HOLIDAY}
        header={intl.formatMessage({id: 'deleteHoliday'})}
        message={intl.formatMessage({id: 'deleteHolidayAlertMessage'})}
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              setHolidayListState((prev) => ({
                ...prev,
                actionCode: '',
                selectedItem: {} as HolidayDataInterface,
              }));
            },
          },
          {
            textLocalId: 'Delete',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY
            },
            onPress: () => {
              onDeleteHoliday();
            },
          },
        ]}
      />
    </View>
  );
};

export default HolidaysList;
