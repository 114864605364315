import {useLazyQuery} from '@apollo/client';
import moment from 'moment';
import {
  Divider,
  FlatList,
  HStack,
  Icon,
  Image,
  ScrollView,
  Skeleton,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, Pressable} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  CONVERSATION_CONST,
  GROUP_MEMBER_TYPE,
  GROUP_TYPES,
} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {COMMUNICATION_APP_BASE_URL} from '../../../../../constants/BaseUrlConst';
import {GROUP_TYPE_CODES} from '../../../../../constants/MlovConst';
import {WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import BaseService from '../../../../../services/CommonService/BaseService';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../../styles';
import {getAccountId, getAccountName, getAccountUUID, getBirthSexPrimaryGenderCode, getGenderTextByGenderCode, getUserId, getUserUUID, isZoomEnabled} from '../../../../../utils/commonUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import InstantChat from '../../../../common/ChatDrawer/InstantChat';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import {IAccount, IHostInfo, IMeeting, IUserList} from '../../../../common/MeetingView/interface';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {VideoCallSvg} from '../../../../common/Svg';
import {scheduleHMSMeet} from '../../../../common/ZoomView/ZoomServices';
import {IUser} from '../../../Contacts/TeamMembers/interfaces';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {styles} from '../../Conversations/ConversationsStyles';
import {IConversationData} from '../interfaces';
import {GroupAddPatient} from './GroupAddPatient';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {UserQueries} from '../../../../../services';
import {getAvatarNameAndType, isInternalChat} from '../MessagingWindow/MessagingUtils';
import {Tooltip} from 'antd';
import { isBirthSexPrimary } from '../../../../../utils/capabilityUtils';
import RoleDataListView from '../ConversationActionView/RoleDataListView';

export const MessagingGroupDetail = (props: {
  selectedConversation: IConversationData;
  agentsData: any;
}) => {
  const {height} = Dimensions.get('window');
  const groupDetailRef:any = useRef()
  const isSizeLess = groupDetailRef?.current?.clientWidth < 400 ? true : false
  const intl = useIntl();
  const groupId = props?.selectedConversation?.groupConversation?.id;
  const userUuid = getUserUUID()
  const isInternalGroup = isInternalChat(props?.selectedConversation);
  const accountId = getAccountId();
  const isBirthSexPrimaryValue = isBirthSexPrimary();
  const {selectedConversation, agentsData} = props;
  const [stateData, setStateData] = useState({
    membersCount: 0 as number,
    groupName: '' as string,
    groupMembers: [] as any,
    groupType:
      props?.selectedConversation?.groupConversation?.groupType?.code || '',
    isDrawerVisible: false as boolean,
    deleteMemberLoading: false as boolean,
    loading: false as boolean,
    currentMemberSelected: {} as any,
    instantChatModal: false,
    selectedUser: {} as any
  });
  const currentUrl = window.location.href;
  const accountUUID = getAccountUUID();
  const accountName = getAccountName();
  const mlovData = useContext(CommonDataContext);
  const userData = mlovData?.userData;
  const [meetingObj, setMeetingObj] = useState({
    meetingName: `Meeting : ${userData?.name} <> ${stateData?.selectedUser?.name}`,
    patientName: stateData?.selectedUser?.name,
    sendEmail: true,
    error: '',
    inviteeList: [] as IUserList[],
    selectedInvitee: [] as number[],
    account: {
      accountId,
      accountUUID,
      accountName,
    } as IAccount,
    hosInfo: {
      name: userData?.name,
      email: userData?.email,
      uuid: userData?.uuid,
    } as IHostInfo,
  } as IMeeting);
  const groupCode =
    props?.selectedConversation?.groupConversation?.groupType?.code;
  const userId = getUserId()

  const [getGroupMembersByGroupId] = useLazyQuery(
    ConversationsQueries.GetGroupMembersByGroupId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [getUserCustomAttr] = useLazyQuery(UserQueries.GET_USER_CUSTOM_ATTR)
  const [getAccountUsers] = useLazyQuery(InboxQueries.GetAgents);
  const [userList, setUserList] = useState([] as IUserList[]);

  const memberIs = (member: any) => {
    if (member?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT) {
      return 'Patient';
    } else if (member?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
      return 'User';
    }
  };

  const getMemberName = (member: any) => {
    if (member.contact) {
      return member?.contact?.name || '';
    } else if (member.user) {
      return member?.user?.name;
    }
    return '';
  };

  const getGender = (member: any) => {
    if (member.contact) {
      const genderValue = getGenderTextByGenderCode(member?.contact);
      return genderValue ? ` | ${genderValue}` : ''
    }
    return '';
  }
  const onGroupAddPatientChange = (action: string) => {
    switch (action) {
      case 'closeModal':
        return setStateData({...stateData, isDrawerVisible: false});
      case 'patientAdded': {
        getGroupMembers();
        break;
      }
    }
  };

  const axios = BaseService.getSharedInstance().axios;

  const handleRemoveMember = (member: any) => {
    setStateData({
      ...stateData,
      currentMemberSelected: member?.groupUserId,
      deleteMemberLoading: true,
    });
    const body = {
      groupId: groupId,
      groupUserId: member?.groupUserId,
      groupUserTypeId: member?.groupUserTypeId,
    };
    axios
      .delete(`crm-nest/account/${accountId}/group/${groupId}/member`, {
        data: body,
      })
      .then(() => {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          WEB_SOCKET_EVENT_CODE.MEMBER_ADDED,
          selectedConversation
        );
        getGroupMembers();
      })
      .catch(() => {
        setStateData({...stateData, deleteMemberLoading: false});
      });
  };

  const ageInYear = (member: any) => {
    const ageInYears = member?.contact?.person?.birthDate
      ? ` | ${moment().diff(
        member?.contact?.person.birthDate,
        'years',
        false
      )} yrs`
      : '';
    return ageInYears;
  };

  const getAvatarUrl = (userId: any) => {
    let profileImg = '';
    agentsData.map((item: any) => {
      if (item.id === userId) {
        profileImg = item.thumbnail.includes('404') ? '' : item.thumbnail;
      }
    })
    return profileImg;
  }

  const getRoles = (memberRecord: any) => {
    return (memberRecord?.user?.userRoles)?.map((role: any, index: number) => {
      return (
        <Tooltip title={role?.userRole?.userRole?.value} placement="top">
          <Text numberOfLines={1} textOverflow={'ellipsis'} mr={1} fontSize={12} fontWeight={400} color={'gray.500'}>
            {role?.userRole?.userRole?.value}
            {index < (memberRecord?.user?.userRoles?.length - 1) ? ',' : ''}
          </Text>
        </Tooltip>
      );
    });
  };
  const RenderList = (data: any) => {
    const member = data?.item || {};
    const userImg = member?.user?.id ? getAvatarUrl(member?.user?.id) : '';
    return (
      <HStack mb={4} flex={1} alignItems={'center'} justifyContent={'space-between'}>
        <HStack flex={isSizeLess ? .7 : 'auto'} space={6}>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
            }}
            isLetterAvatarShow={true}
            userData={{
              userId: member?.contact?.id || member?.user?.id || '',
              userType: member?.user?.id
                ? GROUP_MEMBER_TYPE.USER
                : GROUP_MEMBER_TYPE.CONTACT,
              genderCode: getBirthSexPrimaryGenderCode(member?.contact, isBirthSexPrimaryValue) || 'male',
              imgSrc: userImg || '',
              userName: member?.contact?.name || member?.user?.name
            }}
            groupType={groupCode as any}
          />
          <VStack flex={isSizeLess ? .8 : .84}
            alignItems={!member?.user?.userRoles?.length ? 'flex-start' : undefined}
            justifyContent={!member?.user?.userRoles?.length ? 'center' : undefined}
          >
            <Tooltip title={getMemberName(member)} placement="top">
              <Text numberOfLines={1} textOverflow={'ellipsis'} fontSize={16} fontWeight={500}>
                {getMemberName(member)}
              </Text>
            </Tooltip>
            {member?.user?.userRoles?.length &&
            <View>
              <RoleDataListView
                userRoles={member?.user?.userRoles}
                isPopupView={false}
              />
            </View>
            }
          </VStack>
        </HStack>
        <HStack style={{justifyContent: 'flex-end'}} flex={isSizeLess ? .3 : 'auto'}>
          {selectedConversation?.groupConversation?.ownerId !==
            member?.groupUserId && (
              <HStack space={1}>
                {groupCode == GROUP_TYPES.INTERNAL
                  ? <Pressable
                    onPress={() => {
                      if (member?.user?.email || member?.user?.phoneNumber) {
                        handleInstantMeet(member);
                      }
                    }}
                  >
                    <View style={{width: 36, height: 36}}>
                      <VideoCallSvg
                        isEnabled={member?.user?.email || member?.user?.phoneNumber}
                      />
                    </View>
                  </Pressable>
                  : <></>
                }
                <Pressable onPress={() => handleRemoveMember(member)}>
                  {stateData?.deleteMemberLoading &&
                    member?.groupUserId === stateData.currentMemberSelected ? (
                    <Spinner />
                  ) : (
                    <Image
                      cursor={'pointer'}
                      resizeMode="cover"
                      style={{
                        width: 32,
                        height: 32,
                      }}
                      source={require('../../../../../assets/images/deleteBtn.png')}
                      alt='image'
                    />
                  )}
                </Pressable>
              </HStack>
            )}
        </HStack>
      </HStack>
    );
  };

  const getPatients = (groupMembers: any) => {
    if (groupMembers.length) {
      const patient = groupMembers.filter((member: any) => {
        return (
          member?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
          member?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT ||
          member?.groupUserType?.code === GROUP_MEMBER_TYPE.USER
        );
      });

      return patient;
    } else {
      return [];
    }
  };

  const getGroupMembers = () => {
    setStateData({...stateData, loading: true});
    getGroupMembersByGroupId({
      variables: {
        groupId: groupId,
      },
    })
      .then((conversationRespData) => {
        if (conversationRespData?.data?.groupMembers?.length) {
          setStateData({
            ...stateData,
            membersCount: conversationRespData?.data?.groupMembers?.length || 0,
            groupName:
              props.selectedConversation?.groupConversation?.name || getAvatarNameAndType(userUuid, props.selectedConversation)?.name || CONVERSATION_CONST.GROUP_CHAT,
            groupMembers: [...conversationRespData?.data?.groupMembers] || [],
            deleteMemberLoading: false,
            loading: false,
            isDrawerVisible: false,
          });
        } else {
          setStateData({
            ...stateData,
            membersCount: 0,
            groupName:
              props.selectedConversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT,
            groupMembers:
              props.selectedConversation?.groupConversation?.groupMembers,
            deleteMemberLoading: false,
            loading: false,
            isDrawerVisible: false,
          });
        }
      })
      .catch((err) => {
        setStateData({
          ...stateData,
          deleteMemberLoading: false,
          loading: false,
          isDrawerVisible: false,
        });
      });
  };

  const memberToDisplay = () => {
    const groupCode =
      props?.selectedConversation?.groupConversation?.groupType?.code;
    if (groupCode === GROUP_TYPES.PATIENT_BROADCAST) {
      const member = getPatients(stateData.groupMembers);
      return member.sort((a: any, b: any) => (a?.user?.name > b?.user?.name) ? 1 : ((b?.user?.name > a?.user?.name) ? -1 : 0))
    } else {
      return stateData.groupMembers?.sort((a: any, b: any) => (a?.user?.name > b?.user?.name) ? 1 : ((b?.user?.name > a?.user?.name) ? -1 : 0))
    }
  };

  const isGroupBroadcast = () => {
    return (
      groupCode === GROUP_TYPES.PATIENT_BROADCAST ||
      groupCode === GROUP_TYPES.USER_BROADCAST
    );
  };

  const updateMembersList = (data: any) => {
    if (data?.id === selectedConversation?.id) {
      getGroupMembers();
    }
  };

  const getAgentsData = async () => {
    const responseData = await getAccountUsers({
      variables: {accountId: accountId, roleCode: '',},
    });
    if (responseData?.data?.accountUsers?.length) {
      responseData?.data?.accountUsers.forEach((user: IUserList) => {
        if (user.userId == userData?.id) {
          setMeetingObj((prev: any) => {
            return {
              ...prev,
              selectedInvitee: [user.userId],
              inviteeList: [user],
            };
          });
        }
      });
      setUserList(responseData?.data?.accountUsers);
    } else {
    }
  };

  const getUserSubtitle = async () => {
    try {
      const response = await getUserCustomAttr({
        variables: {
          userId: userId
        }
      })
      const subtitle = response?.data?.user?.subtitle || ''
      setMeetingObj(prev => {
        return {
          ...prev,
          hosInfo: {
            ...prev.hosInfo,
            subtitle
          }
        }
      })
    } catch (error) {

    }
  }

  const handleInstantMeet = (teamRecord: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUser: teamRecord?.user,
      };
    });
    const selectedInvites = userList.filter((user: IUserList) => {
      return [teamRecord.id].includes(user.userId);
    });
    setMeetingObj((prev) => {
      return {
        ...prev,
        inviteeList: selectedInvites,
      };
    });
    //meet api call
    handleStartMeeting(teamRecord);
  };

  const isZoomEnabledAccount = isZoomEnabled();
  const handleStartMeeting = async (teamRecord: any) => {
    meetingObj.formattedContactData = {
      email: teamRecord?.user?.email,
      phoneObj: {
        value: ''
      },
      contactUUID: teamRecord?.user?.uuid,
      type: 'USER',
      firstName: teamRecord?.user?.name,
      lastName: '',
    }
    meetingObj.leaveUrl = currentUrl;
    const scheduleMeetRes = await scheduleHMSMeet(
      accountId,
      accountUUID,
      accountName,
      meetingObj,
      [meetingObj.formattedContactData],
      meetingObj.hosInfo || ({} as IHostInfo),
      isZoomEnabledAccount
    );
    if (isZoomEnabledAccount) {
      window.open(scheduleMeetRes.data.link, '_blank');
      return;
    }
    const meeting: any = scheduleMeetRes?.data?.data;

    const joinMeetingObj = {
      userName:
        meeting.meetingData.hostInfo.first_name +
        ' ' +
        meeting.meetingData.hostInfo.last_name || '',
      authToken: meeting.meetingData.clientToken,
      meetingUrl: meeting?.meetingData?.externalMeetingData?.meetingUrl,
      settings: {
        isAudioMuted: false,
        isVideoMuted: true,
      },
      accountId: accountId,
      accountUUID: accountUUID,
    };
    const stringRep = JSON.stringify(joinMeetingObj);
    const encoded = window.btoa(stringRep);
    // window.open(`http://localhost:3000?token=${encoded}`, '_blank');
    window.open(`${COMMUNICATION_APP_BASE_URL()}?token=${encoded}`, '_blank');
  };

  useEffect(() => {
    getGroupMembers();
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MEMBER_ADDED_HEADER,
      updateMembersList
    );
  }, [groupId]);

  useEffect(() => {
    getAgentsData();
    getUserSubtitle();
  }, []);

  return (
    <VStack
      flex={0.25}
      style={styles.membersWindow}
      bgColor={'white'}
      ref={groupDetailRef}
    >
      <View mx={2}>
        {!stateData.loading ? (
          <HStack ml={0} mt={6}>
            <View
              style={{
                width: isWeb() ? 90 : 16,
                height: isWeb() ? 90 : 16,
              }}
            >
              <DisplayCardAvatar
                isLetterAvatarShow
                avatarStyle={{
                  avatarSize: '16',
                }}
                userData={{
                  userId: '',
                  userType: isInternalGroup
                    ? GROUP_MEMBER_TYPE.USER
                    : GROUP_MEMBER_TYPE.CONTACT,
                  userName: stateData?.groupName,
                }}
                groupType={groupCode as any}
              />
            </View>
            <View>
              <View>
                <Text fontSize={24} fontWeight={700}>
                  {stateData?.groupName}
                </Text>
              </View>

              <View>
                <HStack mb={2}>
                  <Text fontSize={14} fontWeight={400} color={'gray.500'}>
                    {groupCode === GROUP_TYPES.INTERNAL
                      ? 'Internal Group'
                      : 'Broadcast Group'}
                    {' | '}
                  </Text>
                  <Text fontSize={14} fontWeight={400} color={'gray.500'}>
                    {stateData.membersCount} Members
                  </Text>
                </HStack>
              </View>
            </View>
          </HStack>
        ) : (
          <Skeleton h={20} rounded={'sm'} mt={2}/>
        )}
        <Divider marginY={2} />

        <HStack
          marginTop={4}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text fontSize={18} fontWeight={500}>
            Group Members
          </Text>
          {stateData.groupType === GROUP_TYPES.INTERNAL ? (
            <></>
          ) : (
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setStateData({...stateData, isDrawerVisible: true});
                },
                leftIcon: <PlusIcon />,
              }}
              customProps={{
                btnText: intl.formatMessage({id: 'add'}),
              }}
            ></FoldButton>
          )}
        </HStack>
        {stateData.loading ? (
          <View height={height - 440}>
            <Skeleton.Text lines={5} />
          </View>
        ) : (
          <VStack mt={4} marginBottom={1}>
              {stateData?.groupMembers &&
                stateData?.groupMembers?.length &&
                memberToDisplay().length ? (
                  <FlatList
                    onEndReachedThreshold={0.2}
                    initialNumToRender={15}
                    height={height - 250}
                    contentContainerStyle={{ flexGrow: 1, overflow: 'scroll' }}
                    data={memberToDisplay()}
                    keyExtractor={(item: any) => {
                      return '_' + item?.id;
                    }}
                    renderItem={RenderList}
                  />
              ) : (
                <View
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={height - 440}
                >
                  <Image
                    resizeMode="cover"
                    style={{marginTop: 20, width: 90, height: 90}}
                    source={require('../../../../../assets/images/noMember.png')}
                    alt='image'
                  />
                  <Text
                    textAlign={'center'}
                    color={'gray.300'}
                    fontWeight={400}
                    fontSize={18}
                  >
                    {'No Patient Added'} {'\n'} {'in this group'}
                  </Text>
                </View>
              )}
            <GroupAddPatient
              groupMembers={stateData.groupMembers}
              isDrawerVisible={stateData.isDrawerVisible}
              onGroupAddPatientChange={onGroupAddPatientChange}
              groupId={groupId}
              selectedConversation={props?.selectedConversation}
            />
          </VStack>
        )}
      </View>
    </VStack>
  );
};
