import {Drawer} from 'antd';
import {Center, Pressable, Spinner, View, Text, Divider} from 'native-base';
import MessagingWindow from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindow';

import {CHANNEL_TYPE_CODE} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES, GROUP_ACTION_CODES} from '../../../constants/ActionConst';
import { InstantChatProps } from './interface';
import { PARENT_CODE, PERSON_TYPES } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { EventBus } from '../../../utils/EventBus';
import OverrideAntdModal from '../../RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal';
import { useContext, useState } from 'react';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
import { isChannelEmail, isEFaxChannelInboxType } from '../../../utils/commonUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import NoConversationSvg from '../Svg/ConversationAllSvg/NoConversationSvg';
import Stack from '../LayoutComponents/Stack';
import { Colors } from '../../../styles';
import { useIntl } from 'react-intl';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import AntIcon from 'react-native-vector-icons/AntDesign';
import './styles.scss';


const InstantChatView = (props: InstantChatProps) => {
  const {
    contactData,
    selectedConversation,
    isDrawerVisible,
    onActionPerformed,
    isLoading,
    isInstantChatView,
    instantChatFromNotifications,
    selectedInboxTypeCode,
    openConfirmationView,
    instantChatFromDetailPanel
  } = props;
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [msgChangeText,setMsgChangeText] = useState('')
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const intl = useIntl();
  const isPatientOneTwoOneChatEnabled = isAccountConfigEnabled(CONFIG_CODES.ALLOW_PATIENT_ONE_TWO_ONE_CHAT);

  const onConversationActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.MESSAGE_READ: {
        onActionPerformed &&
          onActionPerformed(CONVERSATION_ACTION_CODES.MESSAGE_READ, actionData);
        if (!instantChatFromDetailPanel) {
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(
            CONVERSATION_ACTION_CODES.MESSAGE_READ_FROM_NOTIFICATION,
            {
              conversation: actionData?.uuid,
            }
          )
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT:
      case COMMON_ACTION_CODES.ITEM_CLICKED:
        onActionPerformed && onActionPerformed(actionCode, actionData);
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
      break;
    }
  };

  const handleShowTabs = () => {
    if (isSideCarContext) {
      return false;
    }
    const canShowTabs = !(
      isChannelEmail(selectedConversation?.conversationInbox?.channelType) ||
      isEFaxChannelInboxType(
        selectedConversation?.conversationInbox?.channelType
      )
    );
    return canShowTabs;
  };

  const getConfirmationElement = () => {
    const infoMessage = isPatientOneTwoOneChatEnabled
      ? `You're not part of the default conversation group chat with ${contactData?.name}`
      : `No conversation found with ${contactData?.name}`;
    return (
      <View height={'full'} width={'full'}>
        <View
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          marginTop={6}
          flex={1}
        >
          <Pressable
            onPress={() => {
              onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
            }}
          >
            <AntIcon name="close" size={24} color={Colors.Custom.Gray500} />
          </Pressable>
        </View>
        <View
          height={'full'}
          width={'full'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <NoConversationSvg />
          <Text
            marginY={2}
            fontSize={'md'}
            color={Colors.Custom.Gray500}
            textAlign={'center'}
          >
            {infoMessage}
          </Text>
          <Stack direction="row" style={{alignItems: 'center'}}>
            <Pressable
              onPress={() => {
                if (onActionPerformed) {
                  onActionPerformed(CONVERSATION_ACTION_CODES.START_GROUP_CHAT);
                }
              }}
            >
              <Stack
                direction="row"
                style={{alignItems: 'center', justifyContent: 'center'}}
              >
                <Text
                  padding={1}
                  fontSize={'sm'}
                  color={Colors.Custom.mainPrimaryPurple}
                >
                  {intl.formatMessage({id: 'startNewChat'})}
                </Text>
              </Stack>
            </Pressable>
            {isPatientOneTwoOneChatEnabled && (
              <>
                <Divider
                  orientation="vertical"
                  backgroundColor={Colors.Custom.Gray400}
                  height={4}
                  marginX={1}
                />
                <Pressable
                  onPress={() => {
                    if (onActionPerformed) {
                      onActionPerformed(
                        GROUP_ACTION_CODES.ADD_MEMBER_IN_DEAFULT_GROUP
                      );
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    style={{alignItems: 'center', justifyContent: 'center'}}
                  >
                    <Text
                      padding={1}
                      fontSize={'sm'}
                      color={Colors.Custom.mainPrimaryPurple}
                    >
                      {intl.formatMessage({id: 'joinDefaultGroup'})}
                    </Text>
                  </Stack>
                </Pressable>
              </>
            )}
          </Stack>
        </View>
      </View>
    );
  };

  return (
    <View>
      <Drawer
        className='create-instant-chat'
        headerStyle={{padding: 0}}
        style={{padding: 0}}
        visible={isDrawerVisible}
        width={
          isSideCarContext
            ? '100%'
            : isChannelEmail(
                selectedConversation?.conversationInbox?.channelType
              )
            ? '50%'
            : '40%'
        }
        mask={isSideCarContext ? false : true}
        onClose={() => {
          if (msgChangeText) {
            setOpenConfirmationModal(true);
          } else {
            onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
          }
        }}
      >
        {isLoading ? (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size="lg" />
            </Center>
          </View>
        ) : openConfirmationView ? (
          getConfirmationElement()
        ) : selectedConversation?.id ? (
          <View flex={1}>
            <MessagingWindow
              moduleCode={
                props?.moduleCode
                  ? props?.moduleCode
                  : `MessagingWindow/InstantChatView`
              }
              isInDrawerView={true}
              isDisplayHeader={true}
              selectedConversation={selectedConversation}
              onConversationActionPerformed={onConversationActionPerformed}
              selectedInboxTypeCode={
                selectedInboxTypeCode || CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET
              }
              showTabs={handleShowTabs()}
              isInstantChatView={isInstantChatView}
              msgQueue={[]}
              onAddMsgToQueue={() => {
                //
              }}
              onMsgChangeText={(richText?: any) => {
                setMsgChangeText(richText);
              }}
              parentCode={PARENT_CODE.MESSAGING_DETAIL_PANEL}
              showNavigationBack={isSideCarContext}
              navigationBackPressed={() => {
                if (msgChangeText) {
                  setOpenConfirmationModal(true);
                } else {
                  onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
                }
              }}
            />
          </View>
        ) : (
          <></>
        )}
      </Drawer>

      {openConfirmationModal && msgChangeText && (
        <OverrideAntdModal
          isOpen={openConfirmationModal}
          onClose={() => {
            setOpenConfirmationModal(false);
          }}
          onConfirm={() => {
            setOpenConfirmationModal(false);
            onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
          }}
          closeButtonText="cancel"
          textContent={{
            headerText: 'Confirmation',
            message: `Do you want to close the conversation?`,
          }}
        />
      )}
    </View>
  );
};
export default withMiniContactViewHOC(InstantChatView);
