import {Popover} from 'antd';
import {Button, Divider, HStack, Pressable, VStack} from 'native-base';
import React, {useState} from 'react';
import {Text} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../styles';
import { getOnFocusButtonStyles } from '../../../../../../utils/commonUtils';
import EmailDrawerCommon from '../../../../../common/EmailPopupView/EmailDrawerCommon';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import {
  getUserPermissionByEntityAndActionCode,
  USER_ACCESS_PERMISSION,
} from '../../../../UserAccess/UserAccessPermission';
import {EmailActionsPopoverProps} from '../../interfaces';
import {EmailDrawerCommonV2} from '../../../../../common/EmailDrawerCommonV2';

const EmailActionsPopover = (props: EmailActionsPopoverProps) => {
  const {onActionPerformed, record} = props;
  const [state, setState] = useState({
    isOpen: false,
  });

  const [openEmailDrawerModal, setOpenEmailDrawerModal] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        isOpen: visible,
      };
    });
  };

  const showAutomationOption = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );

  const content: JSX.Element = (
    <VStack
      style={{
        padding: 0,
        borderRadius: 16,
      }}
    >
      <Pressable
        onPress={() => {
          setOpenEmailDrawerModal(true);
          handleVisibleChange(false);
        }}
      >
        <HStack
          style={{
            alignItems: 'center',
            paddingVertical: 3,
            paddingLeft: 2,
          }}
        >
          <Feather
            name="send"
            style={{width: 16, height: 16}}
            color={Colors.Custom.Gray600}
          />
          <Text
            style={{
              marginLeft: 10,
            }}
          >
            Send Email
          </Text>
        </HStack>
      </Pressable>
      <Divider my={1} style={{width: '100%'}} />

      <Pressable
        onPress={() => {
          onActionPerformed(COMMON_ACTION_CODES.CREATE_CAMPAIGN, record);
          handleVisibleChange(false);
        }}
      >
        <HStack
          style={{
            alignItems: 'center',
            paddingVertical: 3,
            paddingLeft: 2,
          }}
        >
          <AntIcon
            name="notification"
            style={{width: 16, height: 16}}
            color={Colors.Custom.Gray600}
          />
          <Text
            style={{
              marginLeft: 10,
            }}
          >
            Create Campaign
          </Text>
        </HStack>
      </Pressable>

      {showAutomationOption && <Divider my={1} style={{width: '100%'}} /> }
      {showAutomationOption && (
        <Pressable
          onPress={() => {
            onActionPerformed(COMMON_ACTION_CODES.CREATE_AUTOMATION, record);
            handleVisibleChange(false);
          }}
        >
          <HStack
            style={{
              alignItems: 'center',
              paddingVertical: 3,
              paddingLeft: 2,
            }}
          >
            <MaterialCommunityIcon
              name="lightning-bolt"
              style={{width: 16, height: 16}}
              color={Colors.Custom.Gray600}
            />
            <Text
              style={{
                marginLeft: 10,
              }}
            >
              Create Automation
            </Text>
          </HStack>
        </Pressable>
      )}
    </VStack>
  );

  return (
    <>
      <Popover
        key={props?.record?.id}
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'left'}
        trigger="click"
        visible={state.isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Button
          onPress={() => {
            handleVisibleChange(true);
          }}
          style={{
            backgroundColor: 'transparent',
            borderWidth: 0,
          }}
          _focus={getOnFocusButtonStyles()}
        >
          <Feather
            name="more-vertical"
            style={[
              {
                marginLeft: 2,
                marginRight: 2,
                fontSize: 14.29,
                color: Colors.Custom.Gray600,
              },
            ]}
          />
        </Button>
      </Popover>
      {openEmailDrawerModal && (
        <EmailDrawerCommonV2
          isOpen={openEmailDrawerModal}
          onClose={() => {
            setOpenEmailDrawerModal(false);
          }}
          onEmailSent={() => {
            setOpenEmailDrawerModal(false);
          }}
          templateId={record.id}
        />
      )}
    </>
  );
};

export default EmailActionsPopover;
