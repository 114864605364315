import {Select} from 'antd';
import {Button, FormControl, Input, Modal, Text, TextArea} from 'native-base';
import {useState} from 'react';
import {ICreateBranchModalProps} from '../interfaces';
import {styles} from './TeamMembersModalStyles';

// - When the user will click on the + button to create a branch. We will open a modal with the following fields.
// 1. Branch Name:- required filed
// 2. ADDRESS:- required filed
// 3. PHONE NUMBER:- phone validation
// 4. EMAIL:- email validation
// 5. TEAM MEMBERS:- Search field with multi-select.

const {Body, CloseButton, Content, Footer, Header} = Modal;
const {Label} = FormControl;
const {Option} = Select;
const CreateBranchModal = (props: ICreateBranchModalProps) => {
  const {isOpen, onModalClose, onSave, teamMembersList} = props;

  const [branchName, setBranchName] = useState('');
  const [branchAddress, setBranchAddress] = useState('');
  const [branchPhoneNumber, setBranchPhoneNumber] = useState('');
  const [branchEmail, setBranchEmail] = useState('');
  const [branchTeamMembers, setBranchTeamMembers] = useState<string[]>([]);
  const [errors, setErrors]: any = useState({});

  const validateEmail = (): boolean => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!branchEmail.match(emailRegex)) {
      setErrors({
        ...errors,
        email: 'Enter a valid email address',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        email: '',
      });
    }
    return true;
  };

  const validatePhoneNumber = (): boolean => {
    // validation for phone number
    const phoneNumberRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!branchPhoneNumber.match(phoneNumberRegex)) {
      setErrors({
        ...errors,
        phoneNumber: 'Enter a valid phone number',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        phoneNumber: '',
      });
    }
    return true;
  };

  const selectChildren: JSX.Element[] = [];
  teamMembersList.forEach((member) =>
    selectChildren.push(<Option key={member.id}>{member.name}</Option>)
  );

  const handleTeamMemberChange = (value: any) => {
    setBranchTeamMembers(value);
  };

  const handleOnSave = () => {
    if (validateEmail() && validatePhoneNumber()) {
      onSave({
        id: Math.random(),
        branchName: branchName,
        branchAddress: branchAddress,
        branchEmail: branchEmail,
        branchPhone: branchPhoneNumber,
        branchTeamMembers: branchTeamMembers,
      });
      onModalClose();
    } else {
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onModalClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />

        <Header>
          <Text style={styles.modalHeaderText}>Create a Branch</Text>
        </Header>
        <Body>
          <FormControl style={styles.formControlStyle} isRequired>
            <Label>Branch Name</Label>
            <Input
              style={styles.inputStyle}
              onChangeText={(branchName: string) => setBranchName(branchName)}
            />
          </FormControl>

          <FormControl style={styles.formControlStyle} isRequired>
            <Label>Branch Address</Label>
            <TextArea
              onChangeText={(branchAddress: string) =>
                setBranchAddress(branchAddress)
              }
            />
          </FormControl>

          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Phone</Label>
            <Input
              type="phone"
              style={styles.inputStyle}
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              onChangeText={(phoneNumber) => {
                setBranchPhoneNumber(phoneNumber);
                validatePhoneNumber();
              }}
            />
            {errors.phoneNumber && (
              <Text style={styles.errorText}>{errors.phoneNumber}</Text>
            )}
          </FormControl>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Email</Label>
            <Input
              type="email"
              style={styles.inputStyle}
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              onChangeText={(email) => {
                setBranchEmail(email);
                validateEmail();
              }}
            />
            {errors.email && (
              <Text style={styles.errorText}>{errors.email}</Text>
            )}
          </FormControl>

          <FormControl>
            <Label style={styles.labelStyle}>Team Members</Label>
            {/* <Text>Work in Progress</Text> */}
            <Select
              mode="multiple"
              size="large"
              placeholder="Select Team Members"
              // defaultValue={selectChildren[0]}
              onChange={handleTeamMemberChange}
              style={{overflow: 'hidden'}}
            >
              {selectChildren}
            </Select>
          </FormControl>

          <Footer style={styles.modalFooter}>
            <Button.Group>
              <Button
                onPress={() => {
                  handleOnSave();
                }}
              >
                Create
              </Button>
              <Button colorScheme="dark" onPress={() => onModalClose()}>
                Cancel
              </Button>
            </Button.Group>
          </Footer>
        </Body>
      </Content>
    </Modal>
  );
};

export default CreateBranchModal;
