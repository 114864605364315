import React from 'react';
import {AllowedOperationKeys, IConfigField} from '../interfaces';
import {HStack, Spacer, Spinner, Text} from 'native-base';
import {Colors} from '../../../../../styles';
import {getOperationTitle, renderJSXForKey} from '../EHRConfigHelpers';

const ConfigField = (props: IConfigField) => {
  const {index, value, operation, loading, disabled, resourceName, handleChange} = props;
  return (
    <HStack
      my={2}
      px={4}
      py={2}
      rounded={'sm'}
      key={operation + index}
      borderWidth={1}
      borderColor={Colors?.Custom.Gray200}
      width={'20%'}
      mx={1}
      alignItems={'center'}
    >
      <Text fontSize={16}>
        {getOperationTitle(operation as AllowedOperationKeys, resourceName)}
      </Text>
      <Spacer />
      {loading ? (
        <Spinner size={'sm'} />
      ) : (
        renderJSXForKey(operation as AllowedOperationKeys, value, disabled, handleChange)
      )}
    </HStack>
  );
};

export default ConfigField;
