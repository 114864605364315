import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const ContentManagementSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0416 7.5H3.95831C3.61313 7.5 3.33331 7.77982 3.33331 8.125V9.79167C3.33331 10.1368 3.61313 10.4167 3.95831 10.4167H16.0416C16.3868 10.4167 16.6666 10.1368 16.6666 9.79167V8.125C16.6666 7.77982 16.3868 7.5 16.0416 7.5Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.87498 12.917H3.95831C3.61313 12.917 3.33331 13.1968 3.33331 13.542V15.2087C3.33331 15.5538 3.61313 15.8337 3.95831 15.8337H6.87498C7.22016 15.8337 7.49998 15.5538 7.49998 15.2087V13.542C7.49998 13.1968 7.22016 12.917 6.87498 12.917Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 12.917H16.6667"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 15.834H16.6667"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.125 1.66699H1.875C1.18464 1.66699 0.625 2.22664 0.625 2.91699V17.0837C0.625 17.774 1.18464 18.3337 1.875 18.3337H18.125C18.8154 18.3337 19.375 17.774 19.375 17.0837V2.91699C19.375 2.22664 18.8154 1.66699 18.125 1.66699Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.625 5.41699H19.375"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ContentManagementSvg;
