import {POWER_BI_REPORT_ERROR} from "../../../../../constants";
import {IPowerBiCreateReportFormRef, IPowerBiReportFormKeys} from "./interface";

export const getUpdatedPowerBiErrorMessages = (powerBiCreateFormRef: React.MutableRefObject<IPowerBiCreateReportFormRef>): any => {
  let isErrorPresent = false;
  const errorsObj: any = {
  };

  if (powerBiCreateFormRef?.current && Object.keys(powerBiCreateFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(powerBiCreateFormRef?.current);
    formElemKeys.forEach((singleKey: IPowerBiReportFormKeys) => {
      const formFieldRef = powerBiCreateFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        errorsObj[singleKey] = '';
      } else {
        isErrorPresent = true;
        errorsObj[singleKey] = POWER_BI_REPORT_ERROR[singleKey];
      }
    });
  }
  return {
    errors: {
      ...errorsObj,
    },
    isErrorPresent,
  };
};
