import React, { useContext, useEffect, useMemo, useState } from 'react'
import { IBatchedAccountUsersInterface } from '../../CustomHooks/useGetBatchedAccountUsers';
import { getUserUUID, isLoginUserBusinessOwner } from '../../../utils/commonUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovId } from '../../../utils/mlovUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { useLazyQuery } from '@apollo/client';
import { GetAllAccountTaskPools, GetUsersTaskAccess, GetUsersTaskPoolAccessWithActiveUsers } from '../../../services/TaskPool/TaskPoolQueries';
import { IUserPool, IUserSearch } from '../../common/CareDashboard/CareDashboardWidget/UserAutoComplete';

interface IUseGetDefaultUserTaskManagerData {
  accountUsers: IBatchedAccountUsersInterface[];
  shouldFetch: boolean;
}

const useGetDefaultUserTaskManagerData = (args: IUseGetDefaultUserTaskManagerData) => {
  // Constants
  
  const { accountUsers: accountUsersArgs, shouldFetch } = args;
  const isCurrentUserBusinessOwner = useMemo(() => {
    return isLoginUserBusinessOwner();
  }, []);
  const [ accountUsers, setAccountUsers ] = useState<IBatchedAccountUsersInterface[]>([]);
  const loggedInUserId = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );
  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  useEffect(() => {
    const formattedAccountUsers = (accountUsersArgs || []).map((user) => {
      return {
        ...user,
        userName: user.name,
        userId: user.uuid,
        email: user.email || '',
        userRoles: user.userRoles,
      }
    });
    setAccountUsers(formattedAccountUsers);
  }, [ accountUsersArgs ]);

  // States
  const [hookState, setHookState] = useState<{
    loading: boolean;
    allUserPoolsWithAccess: IUserPool[];
  }>({
    loading: false,
    allUserPoolsWithAccess: [],
  });
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState<IUserSearch>({
    label: '',
    key: '',
    value: '',
    userRoles: []
  });

  // Lifecycle methods
  useEffect(() => {
    if (accountUsers.length && shouldFetch) {
      setLoggedInUser();
    }
  }, [accountUsers]);

  useEffect(() => {
    if (accountUsers.length && shouldFetch && currentLoggedInUser?.key) {
      fetchDefaultData();
    }
  }, [accountUsers, currentLoggedInUser?.key]);

  // APIs
  const [getUserTaskPoolAccess] = useLazyQuery(GetUsersTaskPoolAccessWithActiveUsers, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  

  const [getAllAccountUserTaskPools] = useLazyQuery(GetAllAccountTaskPools, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  // Private methods
  const fetchDefaultData = async () => {
    fetchPoolData();
  }

  const fetchPoolData = async () => {
    try {
      setHookState((prev) => ({ ...prev, loading: true }));
      const applicableUsersResponse = await getUserTaskPoolAccess({
        variables: {
          userId: [loggedInUserId],
          userPoolTypeId: accessUserTypeId,
        },
      });
      const filterUserPoolAccessData = applicableUsersResponse.data?.userPoolAccesses?.filter(
        (dataItem: any) => dataItem.userId === loggedInUserId
      );
      if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
        // logged In user have the other pool user access
        const userPoolAccessUsersData =
          filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
        const loggedInUser = accountUsers.filter(
          (accountItem: any) => accountItem.userId === loggedInUserId
        );
        if (loggedInUser && loggedInUser.length) {
          userPoolAccessUsersData.unshift(loggedInUser[0]);
        }
        const allUserPoolsWithAccess = await userDataPostProcessing(userPoolAccessUsersData);
        setHookState((prev) => ({...prev, allUserPoolsWithAccess }));
      } else {
        if (currentLoggedInUser?.key) { 
          const userPoolAccessUsersData = [{...currentLoggedInUser, userId: currentLoggedInUser?.key}];
          const allUserPoolsWithAccess = await userDataPostProcessing(userPoolAccessUsersData);
          setHookState((prev) => ({...prev, allUserPoolsWithAccess }));
        }
      }
      setHookState((prev) => ({...prev, loading: false }));
    } catch {
      setHookState((prev) => ({ ...prev, loading: false }));
    }
  }

  const userDataPostProcessing = (users: IUserSearch[]) => {
    let toTransform: IUserSearch[] = users;
    const updatedAccountUsers = accountUsers.map((account) => account.uuid);
    // Filter out inactive users
    toTransform = toTransform.filter((user: any) => updatedAccountUsers.includes(user.userId));
    const transformedUsers = toTransform.map((user: any): IUserSearch => {
      return {
        label: accountUsers.filter(
          (accountUserItem) => accountUserItem.uuid === user.userId
        )?.[0]?.name,
        value: user.userId,
        key: user.userId,
        userRoles: accountUsers.filter(
          (accountUserItem) => accountUserItem.uuid === user.userId
        )?.[0]?.userRoles,
      };
    });
    return getAllTaskPoolAndFilter(transformedUsers);
  }

  const getUserIds = (selectedValue: IUserSearch[])=> {
    if (selectedValue) {
      return selectedValue?.filter((user)=>{
        return (user?.key !== 'null' && user?.key)
      }).map((user)=> user?.key) || [];
    } else {
      return [loggedInUserId];
    }
  }

  const getAllTaskPoolAndFilter = async (users: IUserSearch[]) => {
    const taskPoolData =  await getUserTaskPools({
      variables: {
        userId: getUserIds(users),
        userPoolTypeId: accessUserTaskPoolTypeId,
      },
    });
    const newUserPool: IUserPool[] = [];
    taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
      const isUserPoolAlreadyPresent = newUserPool.some(userPool => userPool.id === userPoolItem?.userPoolId);
      if (!isUserPoolAlreadyPresent) {
        newUserPool.push({
          ...userPoolItem.userPool,
          id: userPoolItem?.userPoolId,
        });
      }
    });
    return newUserPool;
  }

  const setLoggedInUser = () => {
    const loggedInUserData = accountUsers.find(item => item.uuid === loggedInUserId);
    if (loggedInUserData) {
      setCurrentLoggedInUser({
        label: loggedInUserData?.name,
        value: loggedInUserData?.uuid,
        key: loggedInUserData?.uuid,
        userRoles: loggedInUserData?.userRoles,
      });
    }
  }


  return {
    loading: hookState.loading,
    allUserPoolsWithAccess: hookState.allUserPoolsWithAccess,
  }
}

export default useGetDefaultUserTaskManagerData;
