import {Modal as ModalAntd} from 'antd';
import {Text, View, Pressable, HStack, Button} from 'native-base';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../../styles';
import {useState} from 'react';
import {BackgroundColor, TextAlign} from '@foldhealth/easy-email-extensions';
import {background} from 'native-base/lib/typescript/theme/styled-system';
import {Modal} from 'native-base';

export const WorkflowOnSavePopup = (props: {
  isShowPopup: boolean;
  onAction: (action: string) => void;
}) => {
  const title = 'Automation saved successfully!';
  const message =
    'Please note that this action does not activate automation. To enable automation, navigate to the automation screen and toggle it on.';

  return (
    <ModalAntd
      className="wokflowOnSave"
      title={
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            lineHeight: 19.2,
            textAlign: 'center',
          }}
        >
          {title}
        </Text>
      }
      width={320}
      footer={[
        <HStack alignItems={'center'} justifyContent={'center'} space={2}>
          <Button
            style={{
              borderStyle: 'solid',
              borderColor: Colors.FoldPixel.GRAY300,
              borderWidth: 0.5,
              backgroundColor: Colors.Custom.MonochromeWhite,
              height: '32px',
              justifyContent: 'center',
              borderRadius: 4,
              alignItems: 'center',
              padding: '8px',
              width: '136px',
            }}
            onPress={() => {
              props.onAction('LIST_VIEW');
            }}
          >
            <DisplayText
              textLocalId={'Go to List'}
              size={'smBold'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY300,
              }}
            />
          </Button>
          <Button
            style={{
              backgroundColor: Colors.primary[300],
              height: '32px',
              justifyContent: 'center',
              borderRadius: 4,
              alignItems: 'center',
              padding: '8px',
              width: '136px',
              borderWidth: 0.5,
              borderColor: Colors.FoldPixel.GRAY100
            }}
            onPress={() => {
              props.onAction('EDIT_VIEW');
            }}
          >

            <DisplayText
              textLocalId={'Continue Editing'}
              size={'smBold'}
              extraStyles={{
                color: '#FFFFFF',
              }}
            />
          </Button>
        </HStack>,
      ]}
      closable={false}
      open={props.isShowPopup}
    >
      <View>
        <Text
          style={{
            fontWeight: '400',
            fontSize: 14,
            lineHeight: 16.8,
            color: Colors.FoldPixel.GRAY200,
            textAlign: 'center',
          }}
        >
          {message}
        </Text>
      </View>
    </ModalAntd>
  );
};
