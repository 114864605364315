import {
  AdvancedType,
  BasicType,
  createCustomBlock,
  getPageIdx,
  IBlockData,
  JsonToMjml,
} from '@foldhealth/easy-email-core';
import {merge} from 'lodash';
import mjml from 'mjml-browser';
import {HtmlStringToReactNodes} from '../../../../EmailComponents/EmailComponentsListDrawer/EmailComponentsListDrawerUtils';

export type IProductRecommendation = IBlockData<
  {
    'background-color': string;
    'button-color': string;
    'button-text-color': string;
    'description-color': string;
    'title-color': string;
  },
  any
>;

export const UserCreated = createCustomBlock<IProductRecommendation>({
  name: 'User Created Block',
  type: 'wrapper',
  validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
  create: (payload) => {
    const json = payload;
    if (json?.type) {
      json.type = 'wrapper';
    }
    return merge(json);
  },
  render: (params: {
    data?: any;
    idx?: any;
    mode?: any;
    context?: any;
    dataSource?: any;
  }) => {
    const json = params?.data.children?.[0] || {};
    try {
      const mjmlString = JsonToMjml({
        data: json,
        idx: getPageIdx(),
        context: json,
        mode: 'testing',
      });
      const html = mjml(mjmlString).html;
      const reactNode = HtmlStringToReactNodes(html, {
        enabledMergeTagsBadge: false,
      });
      return reactNode;
    } catch (error) {

      return <></>;
    }
  },
});
