import { v4 as uuidv4 } from 'uuid';
import { FlowType } from '../../../context/WorkflowContext';
export const getWorkflowBasePath = (flowType?: any) => {
  const path = window.location + '';
  const hashPosition = path.indexOf('#') + 1;
  let automation = flowType && flowType === FlowType.ivr ? 'ivr' : 'automation';

  if (path.indexOf(automation) < 0 && flowType && flowType === FlowType.ivr) {
    automation = 'number';
  }
  const navigationPath = path.substr(
    hashPosition,
    path.indexOf(automation) + automation.length - hashPosition
  );
  return navigationPath;
};

export const getWorkflowUrlFromFlowType = (
  flowType: FlowType | '',
  isListView?: boolean
): string => {
  let url = '';
  switch (flowType) {
    case FlowType.patients:
      url = isListView ? '/admin/workflow/patients' : '/admin/patients/automation';
      break;

    case FlowType.communication:
      url = isListView ? '/admin/workflow/communication' : '/admin/communication/automation';
      break;

    case FlowType.scheduling:
      url = isListView ? '/admin/workflow/schedule' : '/admin/schedule/automation';
      break;

    case FlowType.task:
      url = isListView ? '/admin/workflow/tasks' : '/admin/taskManagement/automation';
      break;

    case FlowType.lab:
      url = isListView ? '/admin/workflow/labs' : '/admin/labs/automation';
      break;

    case FlowType.form:
      url = isListView ? '/admin/workflow/forms' : '/admin/forms/automation';
      break;

    case FlowType.careJourney:
    case FlowType.journeys:
      url = isListView ? '/admin/workflow/journey' : '/admin/commerce/journeys/automation';
      break;

    case FlowType.order:
      url = isListView ? '/admin/workflow/order' : '/admin/order/automation';
      break;

    case FlowType.DB_TRIGGER:
      url = isListView ? '/admin/workflow/databaseTrigger' : '/admin/databaseTrigger/automation'
      break;

    case FlowType.note:
      url = isListView ? '/admin/workflow/note' : '/admin/note/automation';
      break;

    case FlowType.MICRO_AUTOMATION:
      url = isListView ? '/admin/workflow/MicroAutomation' : '/admin/patients/automation';
      break;

    default:
      url = isListView ? '/admin/workflow/all/' : '/admin/patients/automation';
      break;
  }

  return url;
};

export const getCreateWorkflowUrlFromFlowType = (
  flowType: FlowType | ''
): string => {
  const url = getWorkflowUrlFromFlowType(flowType)+'/create?currentTab=TRIGGER&flowType=' + flowType;
  return url;
};

export const getElementsConnectedToRootElement = (
  elements: any[],
  rootElement?: any
) => {
  if (!elements || !elements.length) {
    return elements;
  }

  const elementMap: any = {};
  elements.forEach((element) => {
    if (element.type != 'bezier') {
      elementMap[element.id] = element;
    }
  });

  const startElement = rootElement || elements[0];
  const connectedNodeList = [startElement.id];
  const filterElements = [startElement];
  const processedList: Array<string> = [];
  do {
    const id = connectedNodeList.pop();
    processedList.push(id);
    elements.forEach((element) => {
      if (element.type == 'bezier') {
        if (element.source == id || element.target == id) {
          if (
            processedList.indexOf(element.source) == -1 &&
            connectedNodeList.indexOf(element.source) == -1
          ) {
            connectedNodeList.push(element.source);
            filterElements.push(elementMap[element.source]);
            if (processedList.indexOf(element.id) == -1) {
              processedList.push(element.id);
              filterElements.push(element);
            }
          }
          if (
            processedList.indexOf(element.target) == -1 &&
            connectedNodeList.indexOf(element.target) == -1
          ) {
            connectedNodeList.push(element.target);
            filterElements.push(elementMap[element.target]);
            if (processedList.indexOf(element.id) == -1) {
              processedList.push(element.id);
              filterElements.push(element);
            }
          }
        }
      }
    });
  } while (connectedNodeList.length);
  return filterElements;
};

export const getMatchingNode = (
  parseResult: any,
  nodeList: any,
  keyList?: string[]
) => {
  keyList = keyList || ['entity', 'intent'];
  let highScoreTriggerNode: any = null;
  keyList.some((key) => {
    const startEntity = parseResult[key] || '';
    const startEntityTokenList = startEntity
      .toLowerCase()
      .trim()
      .split(/\s+/)
      .map((key: string) => {
        if (key == 'form') {
          return 'forms';
        }
        return key;
      });

    let highScore = 0;
    nodeList.forEach((node: any) => {
      if(node.isDeprecated){
        return;
      }
      const displayName = node?.displayName || '';
      const displayNameList = displayName.toLowerCase().trim().split(/\s+/);
      let matchCount = 0;

      startEntityTokenList.forEach((text2: string) => {
        if (displayNameList.indexOf(text2) != -1) {
          matchCount = matchCount + 1;
        }
      });
      if (matchCount > highScore) {
        highScore = matchCount;
        highScoreTriggerNode = node;
      }
      if (highScore > 0) {
        return true;
      }
    });
  });

  return highScoreTriggerNode ? [highScoreTriggerNode] : [];
};

export const getLeafElementList = (elements: Array<any>) => {
  if (!elements || !elements.length) {
    elements;
  }
  const outputElementMap: any = {};
  const rootConnectedElements = getElementsConnectedToRootElement(elements);

  rootConnectedElements.forEach((element) => {
    if (element.type != 'bezier') {
      outputElementMap[element.id] = [];
    }
  });

  rootConnectedElements.forEach((element) => {
    if (element.type == 'bezier' && outputElementMap[element.source]) {
      outputElementMap[element.source].push(element.target);
    }
  });

  const leafElement = rootConnectedElements.filter((element) => {
    if (element && element.type != 'bezier') {
      return (
        outputElementMap[element.id] && !outputElementMap[element.id].length
      );
    }
    return false;
  });
  return leafElement;
};

export const changeWorkflowIdForImport = (workflowData: any) => {
  const newWorkflowData: any = {};
  newWorkflowData.graph = {
    ...JSON.parse(JSON.stringify(workflowData.graph)),
  };
  newWorkflowData.triggerInfo = {
    ...JSON.parse(JSON.stringify(workflowData.triggerInfo)),
  };
  newWorkflowData.workflow = {
    ...JSON.parse(JSON.stringify(workflowData.workflow)),
  };
  delete newWorkflowData?.graph?.id;
  delete newWorkflowData?.triggerInfo?.id;
  delete newWorkflowData?.graph?.userId;
  delete newWorkflowData?.graph?.createdOn;
  delete newWorkflowData?.graph?.tenantId;
  delete newWorkflowData?.workflow?.tenantId;
  delete newWorkflowData?.workflow?.id;
  delete newWorkflowData?.workflow?.workflowMasterId;
  delete newWorkflowData?.workflow?.secondaryMasterId;
  delete newWorkflowData?.workflow?.resourceExecutionId;
  delete newWorkflowData?.workflow?.userId;
  delete newWorkflowData?.workflow?.graphId;
  delete newWorkflowData?.workflow?.workflowArnList;
  delete newWorkflowData?.workflow?.asyncNodeList;
  if (newWorkflowData?.workflow?.name) {
    newWorkflowData.workflow.workflowName = newWorkflowData?.workflow?.name;
  }
  (newWorkflowData.graph?.nodes || []).forEach((node: any) => {
    const oldNodeId = node?.id;
    const newNodeId = `${uuidv4()}`;
    if (node?.data?.metaData?.optionMap) {
      Object.keys(node?.data?.metaData?.optionMap).forEach((key) => {
        node.data.metaData.optionMap[key].id = `${uuidv4()}`;
      });
    }
    node.id = newNodeId;
    (newWorkflowData.graph?.edges || []).forEach((edge: any) => {
      const oldEdgeId = edge?.id;
      const newEdgeId = `${uuidv4()}`;
      edge.id = newEdgeId;
      if (edge?.source === oldNodeId) {
        edge.source = newNodeId;
      }
      if (edge?.target === oldNodeId) {
        edge.target = newNodeId;
      }
    });
  });

  return newWorkflowData;
};

export const getNextNodeDependOnNaturalLanguage = (
  naturalLanguageParseNodeList: any,
  sourceHandle: string,
  outputNodeList: string[],
  nodeMasterDataMap: any
) => {
  if (!naturalLanguageParseNodeList || !naturalLanguageParseNodeList.length) {
    return;
  }
  let nextNodeData;
  const naturalLanguageParseNode = naturalLanguageParseNodeList[0];
  if (naturalLanguageParseNode?.delay) {
    const delayInHours = naturalLanguageParseNode?.delay;
    naturalLanguageParseNodeList[0].delay = 0;
    const userInputFieldList = JSON.parse(
      JSON.stringify(
        nodeMasterDataMap['TriggerAfterSomeTime']?.userInputFieldList || []
      )
    );
    userInputFieldList.forEach((userInputField: any) => {
      if (userInputField?.key == 'delay') {
        userInputField.value = {
          unit: 'Hours',
          value: delayInHours,
        };
      }
    });
    nextNodeData = {
      sourceHandle: sourceHandle,
      node: 'TriggerAfterSomeTime',
      metaData: {
        isAddOptionNode:true,
        userInputFieldList: userInputFieldList,
        naturalLanguageParseNodeList: JSON.parse(
          JSON.stringify(naturalLanguageParseNodeList)
        ),
      },
    };
  } else {
    let processIndex = 0;
    let targetNodeData: any;
    naturalLanguageParseNodeList.some((languageData: any, index: any) => {
      const result = getMatchingNode(
        languageData,
        outputNodeList
          .map((key) => {
            return nodeMasterDataMap[key];
          })
          .filter((data) => {
            return !!data;
          }),
        ['intent', 'entity']
      );
      processIndex = index;
      if (result.length) {
        targetNodeData = result[0];
        return true;
      }
    });
    if (targetNodeData) {
      nextNodeData = {
        sourceHandle: sourceHandle,
        node: targetNodeData.type,
        metaData: {
          isAddOptionNode:true,
          naturalLanguageParseNodeList: naturalLanguageParseNodeList.filter(
            (data: any, index: any) => {
              return index > processIndex;
            }
          ),
        },
      };
    }
  }
  return nextNodeData;
}
export const getWorkflowListPathByFlowType = (flowType: string): string => {
  let url = '/admin/workflow/';
  switch (flowType) {
    case FlowType.patients:
      url += 'patients';
      break;
    case FlowType.communication:
      url += 'communication';
      break;
    case FlowType.scheduling:
      url += 'schedule';
      break;
    case FlowType.form:
      url += 'forms';
      break;
    case FlowType.journeys:
      url += 'journey';
      break;
    case FlowType.task:
      url += 'tasks';
      break;
    case FlowType.lab:
      url += 'labs';
      break;
    case FlowType.order:
      url += 'order';
      break;
    case FlowType.note:
      url += 'note';
      break;
    default:
      url += 'all';
      break;
  }


  return url;
};

export const getErrorMessageFromMetadata = (metadata: any) => {
  const unknownErrorMessage = 'Unknown Error';
  if (!metadata?.error) {
    return unknownErrorMessage;
  }
  try {
    const errorObject = JSON.parse(metadata?.error || '') || {};
    if (errorObject.statusCode === 500) {
      return unknownErrorMessage;
    }
    return errorObject.message;
  } catch (error) {
    return unknownErrorMessage;
  }
};
