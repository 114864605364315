import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  iconView: {
    flex: 0.8,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  badge: {
    flex: 2.8,
    flexDirection: 'row',
    borderRadius: 12
  },
});
