import {
  ICalendarSingleCard,
  ICalenderData,
  IGroupedData,
  ISchedule,
  IScheduleCard,
} from './ScheduleSelector';
import {v4 as uuidv4} from 'uuid';
import {weekdays} from 'moment';
import {IAccountLocation, IPracticeLocations} from '../../Contacts/TeamMembers/interfaces';
import {includes} from 'lodash';
import {getDayNameByNumber} from '../../../../utils/DateUtils';
import { IUser } from '../../../../Interfaces';
export function getData() {
  /**/
}
export const getGroupedData = (responseSchedule: ISchedule[], userId?: string, isPracticeScheduleApplied?: boolean) => {
  const daysOfWeek: IGroupedData[] = [];

  const schedule: ISchedule[] = responseSchedule.map((item) => {
    return {
      ...item,
      localId: item.id && item.id?.length > 0 ? item.id : uuidv4(),
    };
  });
  const weekdays = [
    ...new Set(
      schedule.map((item) =>
        (item?.daysOfWeek) ? JSON.stringify(JSON.parse((item?.daysOfWeek) as string)) : ''
      )
    ),
  ];

  weekdays.map((week) => {
    if (week) {
      daysOfWeek.push({
        daysOfWeek: week,
        schedules: [],
        id: uuidv4(),
        isPracticeScheduleApplied,
        userId,
        uniqueDaysOfWeek: week,
      });
    }
    return;
  });

  daysOfWeek.map((week) =>
    schedule.forEach((item) => {
      if (!item.daysOfWeek) {
        return;
      }

      if (week.uniqueDaysOfWeek === JSON.stringify(JSON.parse(item.daysOfWeek))) {
        week?.schedules?.push(item);
      }
    })
  );

  return daysOfWeek;
};

export const getScheduleCalendarData = (
  schedule: ISchedule[],
  practiceLocations: IPracticeLocations[],
  accountUsers: IUser[],
  isUserSchedule: boolean,
): ICalenderData[] => {
  const practiceSchedules = schedule.filter((item) => {
    return !item.userId;
  });
  if (isUserSchedule) {
    return getScheduleCalendarDataByUsers(schedule, accountUsers, practiceLocations);
  } else {
    return getScheduleCalendarDataByLocation(practiceSchedules, practiceLocations);
  }
};

const getScheduleCalendarDataByLocation = (
  practiceSchedules: ISchedule[],
  practiceLocations: IPracticeLocations[],
) => {
  // fetch unique location
  // const uniqueLocation = [
  //   ...new Set(practiceSchedules.map((item) => item.locationId)),
  // ].map((item) => ({
  //   locationId: item,
  //   schedules: [],
  // }));

  const scheduleCalendarData: ICalenderData[]  = practiceLocations.map((location) => {
    return {
      locationId: location.uuid,
      name: location.practiceLocation.name,
      schedules: [],
    };
  });

    scheduleCalendarData.unshift({
      locationId: 'VIRTUAL_LOCATION',
      name: 'Virtual',
      schedules: [],
    })

  // add corresponding schedules
  scheduleCalendarData.map((item) => {
    practiceSchedules.forEach((scheduleItem) => {
      if (item.locationId === scheduleItem.locationId
        || (item.locationId === 'VIRTUAL_LOCATION' && !scheduleItem.locationId && scheduleItem.isVirtualLocation)
      ) {
        scheduleItem &&
          item?.schedules?.push({
            ...scheduleItem,
            startTime: scheduleItem?.startTime || '',
            endTime: scheduleItem?.endTime || '',
            weekDayCodes:
              (scheduleItem?.daysOfWeek &&
                JSON.parse(scheduleItem?.daysOfWeek).map((item: number) =>
                  getDayNameByNumber(item)
                )) ||
              '',
            id: scheduleItem?.id || '',
          });
      }
    });
  });

  return scheduleCalendarData;
}

const getScheduleCalendarDataByUsers = (
  allSchedules: ISchedule[],
  accountUsers: IUser[],
  practiceLocations: IPracticeLocations[],
) => {
  const scheduleCalendarData: ICalenderData[] = [];

  const practiceLocationsById: any = {};
  practiceLocations.forEach((practiceLocation) => {
    practiceLocationsById[practiceLocation.uuid] = practiceLocation.practiceLocation.name;
  });

  const practiceSchedules = allSchedules.filter((schedule) => {
    if (schedule.locationId) {
      schedule.locationText = practiceLocationsById[schedule.locationId]
    } else {
      schedule.locationText = 'Virtual';
    }
    return !schedule.userId;
  });

  (accountUsers || []).forEach((accountUser) => {
    const userId = accountUser.uuid;
    const object: ICalenderData = {
      userId,
      name: accountUser.name,
      isPracticeScheduleApplied: true,
      userData: accountUser,
      schedules: [],
    };

    let userSchedules = allSchedules.filter((schedule) => {
      return schedule.userId && schedule.userId === userId;
    });

    if (!userSchedules?.length) {
      userSchedules = practiceSchedules;
    } else {
      object.isPracticeScheduleApplied = false;
    }

    object.schedules = userSchedules.map((schedule) => {
      return {
        ...schedule,
        startTime: schedule?.startTime || '',
        endTime: schedule?.endTime || '',
        weekDayCodes:
          (schedule?.daysOfWeek &&
            JSON.parse(schedule?.daysOfWeek).map((item: number) =>
              getDayNameByNumber(item)
            )) ||
          '',
        id: schedule?.id || '',
        userId: userId,
      }
    });

    scheduleCalendarData.push(object);
  });

  return scheduleCalendarData;
}
