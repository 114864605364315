import {getDateStrFromFormat} from '../../../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../../../constants';
import {IFormResponse} from './interfaces';
import {getAnonationIndex} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import {FORM_STATUS_CODE} from '../../../../../../constants/MlovConst';
import {FORM_SCORE_OPERATIONS} from '../../../../../SideCar/constants';

export const formatFormsResponse = (forms: any[], ccmDate?: string) => {
  const formResponses: IFormResponse[] = [];

  forms
    ?.filter((form) => form.formLog?.length > 0)
    ?.map((form) => {
      const graphData: any[] = [];
      let lastFormSentOn = '';
      let lastFormScoreCount = 0;
      let lastSubmittedOn = '';
      let totalSubmittedCount = 0;
      let totalSubmittedScore = 0;
      let lastFormState = '';

      form?.formLog?.map((formLog: any) => {
        lastFormSentOn = formLog?.createdOn;
        if (
          formLog?.formResponse?.createdOn &&
          formLog?.status?.code === FORM_STATUS_CODE.SUBMITTED
        ) {
          lastSubmittedOn = formLog?.formResponse?.createdOn;

          formLog?.formResponse?.metadataScore?.map((metadataScore: any) => {
            if (
              metadataScore?.metadata?.operation ===
                FORM_SCORE_OPERATIONS.SUM &&
              !metadataScore?.groupId &&
              metadataScore?.metadata?.score !== undefined
            ) {
              totalSubmittedCount += 1;
              totalSubmittedScore += metadataScore?.metadata?.score;
              lastFormScoreCount = metadataScore?.metadata?.score;
              lastFormState = metadataScore?.metadata?.state;
              graphData.push({
                x: getDateStrFromFormat(
                  formLog?.formResponse?.createdOn,
                  DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
                ),
                y: metadataScore?.metadata?.score,
                dateStr: formLog?.formResponse?.createdOn,
                tooltipText: `Score: ${metadataScore?.metadata?.score} \n ${getDateStrFromFormat(
                  formLog?.formResponse?.createdOn,
                  DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
                )}`,
              });
            }
          });
        }
      });

      const uniqueDates = new Set(graphData.map((item) => item.x));

      const dataWithUniqueDates = Array.from(uniqueDates).map((date) => {
        const matchedData = graphData.filter((item) => item.x === date);
        return {
          x: date || '',
          y: matchedData?.[0]?.y || '',
          dateStr: matchedData?.[0]?.dateStr,
        };
      });

      const formResponse = {
        title: form.name,
        id: form.id,
        lastFormSentOn: lastFormSentOn,
        graphData: graphData,
        recentFormScore: lastFormScoreCount,
        lastSubmittedOn: lastSubmittedOn,
        annontationIndex:
          graphData.length && ccmDate
            ? getAnonationIndex(ccmDate, dataWithUniqueDates)
            : undefined,
        averageFormScore: totalSubmittedScore
          ? (totalSubmittedScore / totalSubmittedCount).toFixed(2)
          : '-',
        lastFormState: lastFormState
      };

      formResponses.push(formResponse);
    });

  return formResponses?.sort((a, b) => {
    if (!b.lastSubmittedOn) {
      return -1;
    }
    return new Date(a.lastSubmittedOn) > new Date(b.lastSubmittedOn) ? -1 : 1;
  });
};

export const getTrimmedDataForTrendView = (form: IFormResponse) => {
  const maxTrendCount = 7;
  if (form?.graphData?.length > maxTrendCount) {
    form.graphData = form?.graphData?.slice(
      form?.graphData?.length - maxTrendCount,
      form?.graphData?.length
    );
  } 
  return form;
}