import React from "react";

const ClockSvg = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 9v5l3.125 3.125M26.5 14c0 6.904-5.596 12.5-12.5 12.5S1.5 20.904 1.5 14 7.096 1.5 14 1.5 26.5 7.096 26.5 14z"
      ></path>
    </svg>
  );
}

export default ClockSvg;
