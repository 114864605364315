import { getFormDataFromCustomerData } from "../../AddOrUpdateCustomer/AddOrUpdateCustomerUtils";

export const formateCustomerListData = (dataList: any, mlovData: any): any => {
  const leadList: any = [];
  dataList?.contacts?.forEach((singleCustomerData: any) => {
    const singleCustomerTempData = getFormDataFromCustomerData(singleCustomerData, mlovData);
    // const fullName =
    //   singleCustomerTempData.firstName +
    //   ' ' +
    //   singleCustomerTempData.lastName;
    leadList.push({
      name: singleCustomerData?.name,
      nameData: {
        name: singleCustomerData?.name,
        email: singleCustomerTempData?.email || ''
      },
      email: singleCustomerTempData?.email || '',
      mobileNumber: singleCustomerTempData?.phone || '',
      age: singleCustomerTempData.age ? singleCustomerTempData.age + 'yr' : '',
      zipCode: singleCustomerTempData.zipCode || '',
      contactType: singleCustomerData?.contactType?.contactType || '' ,
      singleCustomerData: singleCustomerData,
      id: singleCustomerTempData.leadId,
    });
  });
  return leadList;
};

export const getEmailAddressFromPerson = (
  contactList: any,
  separator = ', '
): string => {
  let emailList: string[] = [];
  emailList = contactList.map((singleContactObj: any) => {
    return singleContactObj.value;
  });
  return emailList.join(separator);
};
