import {
  View,
  Text,
  VStack,
  HStack,
  useMediaQuery,
  Divider,
  useToast,
  Box,
} from 'native-base';
import {Pressable} from 'react-native';
import {
  IAccountPracticeLocationList,
  IGetProduct,
  IGetProductList,
  IJourneyPackagesInterface,
  IStripeProductSearchQuery,
} from './interfaces';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../../common/TableTopBar';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {Colors} from '../../../../../styles/Colors';
import {useEffect, useState} from 'react';
import PackagesEmptySvg from '../../../../common/Svg/PackagesEmptySvg';
import AddOrUpdatePackages from './AddOrUpdatePackages';
import {useIntl} from 'react-intl';
import {
  createCopyLinkPackageApi,
  getStripePackageApi,
  updatePackageStatusApi,
} from './PackageServices';
import {filterLocationObject, formattingGetPackageData, isExpiredDate} from './PackagesUtils';
import JourneyPackagesTable from './JourneyPackagesTable';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import FilterNewIconSvg from '../../../../common/Svg/FilterNewIconSvg';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../constants';
import {
  FILTER_PRICE,
  PRODUCT_PACKAGE_STATUS,
  PRODUCT_PRICING_TYPE,
} from './PackagesConst';
import ClearActionIcon from '../../../../common/Svg/ClearActionIcon';
import {useLazyQuery} from '@apollo/client';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {ToastType, getBackgroundColor, showToast} from '../../../../../utils/commonViewUtils';
import PackagesNoDataView from './PackageNoDataView';
import {Modal} from 'antd';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {CareJourneyQueries} from '../../../../../services';
import {CARE_PROGRAM_STATUS_CODES} from '../../../../../constants/MlovConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {JourneyPackageTagsFilter} from './JourneyPackageTagsFilter';
import {ITagsTableData} from '../../../Contacts';

const JourneyPackagesView = (props: IJourneyPackagesInterface) => {
  const intl = useIntl();
  const accountUUID = getAccountUUID();
  const toast = useToast();
  const [stateData, setStateData] = useState({
    openCreateDrawer: props.openCreateDrawer,
    loading: true,
    packagesList: {} as IGetProductList,
    selectedPackageData: {} as IGetProduct,
    limit: 10,
    offSet: 0,
    practiceLocations: [] as IAccountPracticeLocationList[],
    selectedLocationData: [] as IAccountPracticeLocationList[],
    filterPriceValue: FILTER_PRICE?.[0]?.value,
    unableToDraft: false,
    unableToDraftReason: '',
    currentPage: 1,
    selectedProductPackageTag: [] as ITagsTableData[],
  });

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const [accountPracticeLocationsQuery] = useLazyQuery<any>(
    UserPracticeLocationQueries.GetAccountPracticeLocationsByTenantId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GET_CARE_JOURNEY_STATUS_BY_ID] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEY_STATUS_BY_ID, {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  )

  useEffect(() => {
    setStateData((prev: any) => {
      return {
        ...prev,
        limit: stateData.limit || 10,
        offSet: 0,
        currentPage: 1,
      };
    });
    getStripepackageData(stateData.limit || 10,  0);
  }, [props?.searchString]);

  useEffect(() => {
    getStripepackageData(stateData.limit, stateData.offSet);
  }, [
    stateData.filterPriceValue,
    stateData.offSet,
    stateData.currentPage,
    stateData.limit,
    stateData?.selectedProductPackageTag
  ]);

  useEffect(() => {
    setStateData((prev: any) => {
      return {
        ...prev,
        openCreateDrawer: props.openCreateDrawer,
        selectedPackageData: {},
      };
    });
  }, [props.openCreateDrawer]);

  const getStripepackageData = async (limit?: number, offset?: number) => {
    setStateData((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const packageLabels = stateData?.selectedProductPackageTag?.map((tag: ITagsTableData) => tag?.id) || [];
    const getQuery: IStripeProductSearchQuery = {
      limit: limit || stateData.limit,
      offSet: offset === 0 ? 0 : offset || stateData.offSet,
      searchString: props?.searchString || '',
      pricingType:
        stateData.filterPriceValue === 'ALL'
          ? undefined
          : stateData.filterPriceValue,
      labels: packageLabels
    };
    const promiseList = [getStripePackageApi(getQuery), getAccountLocations()];
    const apiResponse = await Promise.all(promiseList);
    const packageData = apiResponse?.[0];
    const locationData = apiResponse?.[1];
    if (packageData?.products?.length) {
      setStateData((prev: any) => {
        return {
          ...prev,
          loading: false,
          packagesList: formattingGetPackageData(packageData, locationData),
        };
      });
    } else {
      setStateData((prev: any) => {
        return {
          ...prev,
          loading: false,
          packagesList: {},
        };
      });
    }
  };

  const getAccountLocations = async () => {
    const accountLocationsRes = await accountPracticeLocationsQuery({
      variables: {
        tenantId: accountUUID,
      },
    });
    if (accountLocationsRes?.data?.accountLocations?.length) {
      return filterLocationObject(
        accountLocationsRes.data?.accountLocations || []
      );
    }
    return [];
  };

  const createCopyLinkForPackage = async (packageId: string) => {
    setStateData((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const res = await createCopyLinkPackageApi(packageId).catch((err) => {
      setStateData((prev: any) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    });
    if (res?.link) {
      navigator.clipboard.writeText(res?.link);
      const toastId = showToast(
        toast,
        intl.formatMessage({id: 'linkCopied'}),
        ToastType.success,
        2000
      );
      setTimeout(() => {
        toast?.close(toastId);
      }, 3000);
    }
    setStateData((prev: any) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  const updatePackageStatus = async (selectedRecord: IGetProduct) => {
    setStateData((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const updatedStatus =
      selectedRecord?.status === PRODUCT_PACKAGE_STATUS.PUBLISHED
        ? PRODUCT_PACKAGE_STATUS.DRAFT
        : PRODUCT_PACKAGE_STATUS.PUBLISHED;
    if (updatedStatus === PRODUCT_PACKAGE_STATUS.PUBLISHED) {
      const careJourneyStatusResp = await GET_CARE_JOURNEY_STATUS_BY_ID({
        variables: {
          id: selectedRecord?.productPackage?.journeyId
        }
      })
      if (careJourneyStatusResp.data?.careJourney?.status?.code !== CARE_PROGRAM_STATUS_CODES.ACTIVE) {
        setStateData(prev=> {
          return {
            ...prev,
            unableToDraft: true,
            loading: false
          }
        })
        return
      }
    }
    const updateVariables = {
      status: updatedStatus,
    };
    const res = await updatePackageStatusApi(
      selectedRecord?.id,
      updateVariables
    ).catch((err) => {
      setStateData((prev: any) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    });
    if (res?.data?.id) {
      const updatedList = [...stateData.packagesList?.products];
      const updatedObject = updatedList.find(
        (obj) => obj.id === selectedRecord?.id
      );
      if (updatedObject) {
        updatedObject.status = updatedStatus;
        setStateData((prev: any) => {
          return {
            ...prev,
            loading: false,
            packagesList: {
              total: stateData.packagesList.total,
              products: updatedList,
            },
          };
        });
       const toastId = showToast(
        toast,
        intl.formatMessage({id: 'packageStatusUpdated'}),
        ToastType.success,
        2000
      );
        setTimeout(() => {
          toast?.close(toastId);
        }, 3000);
      }
    }
    setStateData((prev: any) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  const onActionPerformed = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev: any) => {
          return {
            ...prev,
            openCreateDrawer: true,
            selectedPackageData: rowData,
          };
        });
        break;
      case COMMON_ACTION_CODES.COPY:
        if (
          rowData?.status === PRODUCT_PACKAGE_STATUS.DRAFT ||
          (rowData?.expiryDate && isExpiredDate(new Date(rowData?.expiryDate)))
        ) {
          const toastId = showToast(
            toast,
            intl.formatMessage({id: 'linkCopyOnlyPublished'}),
            ToastType.success,
            2000
          );
          setTimeout(() => {
            toast?.closeAll();
          }, 3000);
        } else {
          createCopyLinkForPackage(rowData?.id);
        }
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        updatePackageStatus(rowData);
        break;
      case COMMON_ACTION_CODES.LOAD_MORE:
        const currentOffset = (rowData.currentPage - 1) * stateData.limit;
        setStateData((prev: any) => {
          return {
            ...prev,
            offSet: currentOffset,
            limit: rowData?.currentPageSize || 10,
            currentPage: rowData?.currentPage || 1,
          };
        });
        break;
    }
  };

  const noDataView = (
    <PackagesNoDataView
      searchString={props?.searchString}
      openCreateDrawer={() => {
        props?.onActionPerformed?.(TABLE_TOP_BAR_ACTION_CODES.NEW_PACKAGE);
        setStateData((prev: any) => {
          return {
            ...prev,
            openCreateDrawer: true,
            selectedPackageData: {},
          };
        });
      }}
    />
  );

  return (
    <View
      flex={1}
      style={{
        overflow: 'hidden',
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
      }}
    >
      {props.selectedActionCode ==
        TABLE_TOP_BAR_ACTION_CODES.PACKAGE_FILTER && (
        <>
          <VStack mt={2} mb={2}>
            <HStack alignItems={'center'}>
              <FilterNewIconSvg isEnabled={true} />
              <Divider orientation="vertical" height={'20px'} />
              <HStack flex={0.9} space={2}>
                <HStack ml={3} alignItems={'center'}>
                  <Text>Price: </Text>
                  <ModalActionAntSelect
                    allowClear={false}
                    showSearch={false}
                    value={stateData.filterPriceValue}
                    onChange={(type: any) => {
                      setStateData((prev: any) => {
                        return {
                          ...prev,
                          filterPriceValue: type,
                          offSet: 0,
                          currentPage: 1,
                        };
                      });
                    }}
                    data={FILTER_PRICE}
                    optionProps={{key: 'key', value: 'value', label: 'label'}}
                    customStyle={{
                      fontSize: 15,
                      fontWeight: 400,
                      width: isSmallScreen ? 150 : 120,
                      borderColor: Colors.Custom.Gray300,
                    }}
                  />
                </HStack>
                <HStack ml={3} alignItems={'center'}>
                  <Text>Tags: </Text>
                  <JourneyPackageTagsFilter
                    label={intl.formatMessage({id: 'tags'})}
                    tags={stateData?.selectedProductPackageTag}
                    onClose={(selectedProductPackageTag)=> {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          selectedProductPackageTag: selectedProductPackageTag,
                          offSet: 0,
                          currentPage: 1,
                        }
                      })
                    }}
                  />
                </HStack>
              </HStack>
              <HStack flex={0.1} marginRight={2}>
                <Pressable
                  onPress={() => {
                    setStateData((prev: any) => {
                      return {
                        ...prev,
                        filterPriceValue: FILTER_PRICE?.[0].value,
                        selectedProductPackageTag: [],
                        offSet: 0,
                        currentPage: 1,
                      };
                    });
                  }}
                  style={{
                    marginRight: 12,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <ClearActionIcon />
                  <Text
                    color={Colors.Custom.mainPrimaryPurple}
                    style={{marginLeft: 6}}
                  >
                    Clear All
                  </Text>
                </Pressable>
              </HStack>
            </HStack>
          </VStack>
          <Divider orientation="horizontal" width={'100%'} />
        </>
      )}

      <View>
        <JourneyPackagesTable
          packagesData={stateData.packagesList}
          limit={stateData.limit}
          offSet={stateData.offSet}
          currentPage={stateData.currentPage}
          onActionPerformed={onActionPerformed}
          isListLoading={stateData.loading}
          noDataPreview={noDataView}
          isFilterOpen={props.selectedActionCode ==
            TABLE_TOP_BAR_ACTION_CODES.PACKAGE_FILTER}
        />
      </View>

      {stateData.openCreateDrawer && (
        <AddOrUpdatePackages
          visibleDrawer={stateData.openCreateDrawer ? true : false}
          selectedPackageData={stateData.selectedPackageData}
          onFormCompleteAction={(actionCode: string, actionData?: any) => {
            props.onActionPerformed(actionCode, actionData);
            if (actionCode === TABLE_TOP_BAR_ACTION_CODES.CLOSE) {
              getStripepackageData();
            }
            setStateData((prev: any) => {
              return {
                ...prev,
                openCreateDrawer: false,
                selectedPackageData: {},
              };
            });
          }}
        />
      )}
      <Modal
        closable={false}
        open={stateData.unableToDraft}
        onCancel={() => {
          setStateData((prev) => ({
            ...prev,
            unableToDraft: false,
          }));
        }}
        footer={null}
      >
        <VStack>
          <Text
            my={1}
            fontSize={'md'}
          >{`Package cannot be published since its Care Journey is in Draft state. Please publish the Care Journey first to be able to publish this Package`}</Text>
          <HStack mt={5} justifyContent={'flex-end'}>
            <FoldButton
              customProps={{
                btnText: 'Ok',
              }}
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setStateData((prev) => ({
                    ...prev,
                    unableToDraft: false,
                    unableToDraftReason: ''
                  }));
                },
              }}
            />
          </HStack>
        </VStack>
      </Modal>
    </View>
  );
};

export default JourneyPackagesView;
