import {evnName, getEnvVariable} from './BaseUrlConst';
//On sandbox
export const BASE_URL = `https://${evnName()}foldhealth.io`;

const CHAT_WOOT_BASE_URL = `${evnName()}foldhealth.io/chatwoot`;
const WEB_SOCKET_URL = `wss://crmchat.${evnName()}foldhealth.io/cable`;

export const CRM_BASE_URL = BASE_URL + '/crm-nest';

export const BILLING_BASE_URL = BASE_URL + '/billing';
export const CRM_PUBLIC_BASE_URL = CRM_BASE_URL + '/public';
export const RAILS_SERVER_URL = `https://${CHAT_WOOT_BASE_URL}`;
export const FOLD_APIS_URL = `https://api.${evnName()}foldhealth.io/staging`;

// export const WORKFLOW_SERVICE_URL = 'http://localhost:9091/';
export const WORKFLOW_SERVICE_URL = BASE_URL + '/workflow-nest/';
export const CLOUD_TELEPHONY_SERVICE_URL = BASE_URL + '/cloud-telephony/';
// export const CLOUD_TELEPHONY_SERVICE_URL =
//   'http://localhost:3000/cloud-telephony/';
// export const WORKFLOW_SERVICE_URL = 'https://g3ktroxf62.execute-api.us-east-1.amazonaws.com/Prod/'; // releasevr env

export const CONTEXT = 'account';
export const CONTEXT_PLATFORM = 'platform';
export const CRM_GRAPHQL_URL = `${CRM_BASE_URL}/${CONTEXT_PLATFORM}`;
export const GRAPHQL_PLATFORM_URL = `${CRM_BASE_URL}/${CONTEXT_PLATFORM}`;

export const WEB_SOCKET_CONFIG = {
  BASE_URL: WEB_SOCKET_URL,
  sendPresenceItInMS: 20 * 1000,
};
//LLM
export const LLM_SERVICE_URL = BASE_URL + '/llm';

// Cloud Telephony
export const CLOUD_TELEPHONY_GRAPHQL_URL =
  BASE_URL + '/cloud-telephony/service/graphql';
export const CLOUD_TELEPHONY_APOLLO_CONTEXT = 'cloudTelephony';

// fhir
export const FHIR_APOLLO_CONTEXT = `fhir`;

// Feed

export const FEED_SERVICE_URL = `${BASE_URL}/feed-service/v1/graphql`;
// export const FEED_SERVICE_URL = `http://localhost:4000/graphql`;
export const FEED_SERVICE_APOLLO_CONTEXT = 'feedService';
export const PATIENT_EDUCATION_URL = `${BASE_URL}/viewer/articles/`;

// Care Studio
export const CARESTUDIO_APOLLO_CONTEXT = 'carestudio';
export const CARESTUDIO_PROXY_TO_CRM_CONTEXT = 'care_studio_proxy_to_crm';
// -- AWS carestudio config --
// export const CARE_STUDIO_NEST_URL = `http://localhost:9090`;
export const CARE_STUDIO_NEST_URL = `${BASE_URL}/carestudio-nest`;
export const CARE_STUDIO_MIDDLEWARE_URL = `${CARE_STUDIO_NEST_URL}/graphql`;
export const CARE_STUDIO_PROXY_TO_CRM_URL = `${CARE_STUDIO_NEST_URL}/proxy/crm-nest/platform`;
// export const CARE_STUDIO_PROXY_TO_CRM_URL = `http://localhost:9090/proxy/crm-nest/platform`;
export const FORM_FILE_S3_URL = {
  UPLOAD: `${CARE_STUDIO_NEST_URL}/attachment/upload`,
  FILE_GET: `${CARE_STUDIO_NEST_URL}/attachment/#ATTACHMENT_ID/file`,
  FILE_DETAIL_GET: `${CARE_STUDIO_NEST_URL}/attachment/#ATTACHMENT_ID`,
};

export const TwilioRecordingUrlPrefix = 'https://api.twilio.com/';

// -- Local carestudio config --
// export const CARE_STUDIO_MIDDLEWARE_URL = 'http://localhost:9090/graphql';
//export const FORM_FILE_UPLOAD_URL = 'http://localhost:9090/form/file';
export const HASURA_SECRET = 'asdf1234';

export const SENTRY_DSN_MOBILE = 'https://4f73e11128b852d635678c590ecda1b0@o4507048237924352.ingest.us.sentry.io/4507417309347840'
export const SENTRY_DSN_WEB = 'https://8f75619bec5d5fad8615f381c618f3a7@o4507048237924352.ingest.us.sentry.io/4507417303515136'
export const SENTRY_DSN_WEB_SANDBOX = 'https://b41ba08adcfed9a900ddbc2279d91014@o4507048237924352.ingest.us.sentry.io/4507468928319488';

export const getSentryDNSFromENV = () => {
  const envNameStr = getEnvVariable();
  if (!envNameStr) {
    return SENTRY_DSN_WEB;
  } else if (envNameStr === 'sandbox' && !window.location.host.includes('localhost'))  {
    return SENTRY_DSN_WEB_SANDBOX;
  }
}
export default {
  BASE_URL,
  CONTEXT,
  CRM_GRAPHQL_URL,
  CARE_STUDIO_MIDDLEWARE_URL,
  GRAPHQL_PLATFORM_URL,
  FORM_FILE_S3_URL,
  CARESTUDIO_APOLLO_CONTEXT,
  WEB_SOCKET_CONFIG,
  HASURA_SECRET,
  PATIENT_EDUCATION_URL,
  CARE_STUDIO_NEST_URL,
  FHIR_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
  CARE_STUDIO_PROXY_TO_CRM_URL,
  CLOUD_TELEPHONY_GRAPHQL_URL,
  CLOUD_TELEPHONY_APOLLO_CONTEXT,
};
