import { View, Text, Skeleton } from "native-base"
import { Colors } from "../../../../styles/Colors"
import { ITableCellType, ITableCell } from "./types"
import MemberInfoListItem from "../../../common/MemberInfoListItem/MemberInfoListItem"
import { IContact } from "../../TeamInbox/Conversations/interfaces"
import { tableData } from "./raw"


export const FormTable = (props: {
    contactData: Record<string, IContact>,
    data: ITableCell[][],
}) => {


    const renderTableCell = (type?: ITableCellType, value?: any, width?: number, isLastCell?: boolean) => {

        switch (type) {
            case 'header':
                return (
                    <View style={{
                        backgroundColor: Colors.Custom.Gray200,
                        paddingHorizontal: 12,
                        paddingVertical: 8,
                        borderColor: Colors.Custom.Gray200,
                        borderBottomWidth: 1,
                        marginBottom: -1,
                        borderTopWidth: 1, width
                    }}>

                        <Text style={{
                            color: Colors.Custom.Gray500,
                            fontSize: 14
                        }} size={'smRegular'} >
                            {value}
                        </Text>
                    </View>
                )
            case 'points':
                return (<View style={{
                    padding: 12,
                    borderColor: Colors.Custom.Gray200,
                    borderBottomWidth: 1,
                    borderTopWidth: 1,
                    marginBottom: -1,
                    backgroundColor: '#fff',
                    flexDirection: 'column',
                    width,
                }}>
                    {
                        value?.map?.((item: any) => {
                            return (
                                <Text style={{
                                    fontSize: 14
                                }} size={'smRegular'} >
                                    {`• ${item}`}
                                </Text>
                            )
                        })
                    }


                </View>)
            case 'array':
                return (
                    <div style={{
                        display:"grid",
                        gridTemplateColumns:"auto",
                        gridAutoRows: "1fr",
                    }}>
                        {
                            value?.map((item: any) => {
                                return renderTableCell(item?.type, item?.value, item?.width)
                            })
                        }
                    </div>
                )
            case 'contact':
                return renderContactInfoCell(value, width, isLastCell);
            case 'string':
                return <View style={{
                    padding: 12,
                    borderColor: Colors.Custom.Gray200,
                    borderBottomWidth: 1,
                    borderTopWidth: 1,
                    marginBottom: -1,
                    backgroundColor: '#fff',
                    width,
                }}>
                    <Text style={{
                        fontSize: 14
                    }} size={'smRegular'} >
                        {value}
                    </Text>

                </View>
            default:
                return <View style={{
                    padding: 12,
                    borderColor: Colors.Custom.Gray200,
                    borderBottomWidth: 1,
                    backgroundColor: '#fff',
                    width,
                }}>
                    <Text style={{
                        fontSize: 14
                    }} size={'smRegular'} >
                        {'N/A'}
                    </Text>

                </View>

        }

    }


    const renderContactInfoCell = (contactId?: string, width?: number, isLastCell?: boolean) => {
        const contact = contactId && props?.contactData?.[contactId];
        return (
            <View style={{
                padding: 12,
                borderColor: Colors.Custom.Gray200,
                borderBottomWidth: isLastCell ? 0 : 1,
                borderRightWidth: 1,
                backgroundColor: '#fff',

                width,

            }}>
                <View style={{
                    height: 50
                }}>
                    {contact ? <MemberInfoListItem contactData={contact} showDateOfBirth={true}/> : <Skeleton.Text lines={1} />}

                </View>

            </View>
        )
    }

    return (
        <View style={{
            width: '100%',
            position: 'relative'
        }}>
            <View overflowX={'scroll'} overflowY={'clip'} style={{
                maxWidth: '100%',
                width: 'fit-content',
                borderColor: Colors.Custom.Gray200,
                borderWidth: 1,
                borderRadius: 8,
            }}>

                <View style={{
                    flexDirection: 'column',

                    width: 'fit-content'
                }}>
                    {
                        props?.data?.map((item, index) => {
                            return (
                                <View style={{
                                    flexDirection: 'row'
                                }} key={index + "table"}>
                                    {
                                        item.map((subitem) => {
                                            return renderTableCell(subitem?.type, subitem?.value, subitem?.width, ((index + 1) == props?.data.length))
                                        })
                                    }
                                </View>
                            )
                        })
                    }
                </View>
            </View>

        </View>
    )
}
