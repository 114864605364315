import {ApolloError, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Table, Popover, Skeleton, notification} from 'antd';
import {
  HStack,
  Icon,
  ScrollView,
  useToast,
  View,
  VStack,
  Button,
  Tooltip,
  Text,
  Divider,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import CareJourneyQueries from '../../../../../services/CareJourney/CareJourneyQueries';
import {Colors} from '../../../../../styles/Colors';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {SearchBar} from '../../../../common/SearchBar';
import {capitalizeText} from '../../../ContentManagement/CampaignManagement/Helper';
import {
  getPatientCareJourneyTableColumns,
  JOURNEYS_STATUS,
  JOURNEYS_STATUS_CODE,
} from './PatientCareJourneyHelper';
import {
  IPatientCareJourney,
  IPatientJourneyActionProps,
} from './PatientJourneyInterface';
import { GET_ALL_USERS_FOR_CARETEAM, GET_USERS_WITH_IDS } from '../../../../../services/User/UserQueries';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {
  getMlovCodeFromId,
  getMlovId,
  getMlovListFromCategory,
  getMlovValueFromCode,
  getMlovValueFromId,
} from '../../../../../utils/mlovUtils';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT, GOAL_STATUS_CODES, MLOV_CATEGORY} from '../../../../../constants';
import CareTeamFlow from '../../../../PersonOmniView/LeftContainer/CareTeamDetails/CareTeamFlow';
import {PATIENT_JOURNEY_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import AssignCareJourneyPopup from '../AssignCareJourneyPopup/AssignCareJourneyPopup';
import PatientCareJourneyDetail from '../PatientCareJourneyDetail/PatientCareJourneyDetail';
import StopCareJourneyDrawer from './StopCareJourneyDrawer';
import RestartCareJourneyDrawer from './RestartCareJourneyDrawer';
import PauseCareJourneyDrawer from './PauseCareJourneyDrawer';
import DeleteCareJourneyDrawer from './DeleteCareJourneyDrawer';
import StartCareJourneyDrawer from './StartCareJourneyDrawer';
import {useNavigate} from 'react-router-dom';
import Feather from 'react-native-vector-icons/Feather';
import { ICareTeam } from '../Table/ProgramsTable/ProgramsTable';
import { GET_CARE_TEAM } from '../../../../../services/CareTeam/CareTeamQueries';
import { PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../../constants/MlovConst';
import { PATIENT_CARE_JOURNEY_OPERATION_CODES } from '../../../../../constants/ConstantValues';
import { getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { duration } from 'moment';
import GoalsCareJourneyDrawer from './GoalsCareJourneyDrawer';
import { IMlov } from '../../../../../Interfaces';
import CompleteOrChangeGoalStatusCareJourneyDrawer from './CompleteOrChangeGoalStatusCareJourneyDrawer';
import { debounce } from 'lodash';
import useGetBatchedAccountUsers from '../../../../CustomHooks/useGetBatchedAccountUsers';
import { EventBus } from '../../../../../utils/EventBus';
import { CUSTOM_MESSAGE_EVENT_CODES } from '../../../../../constants/WebSocketConst';
import { getDateStrFromFormat, getFormattedDate, getMomentObj, isTodayDate } from '../../../../../utils/DateUtils';
import { redirectToNewTab } from '../../../../SideCar/SidecarUtils';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import Stack from '../../../../common/LayoutComponents/Stack';
import { JourneysIonSvg } from '../../../../SideCar/assets/images/JourneysIconSvg';

const PatientCareJourneyListView = (props: any) => {
  const intl = useIntl();
  const toast = useToast();
  const accountUuid = getAccountUUID();
  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
  } = useGetBatchedAccountUsers({
    onError: () => {
      showToast(
        toast,
        'Error in fetching account users',
        ToastType.error,
        4000
      );
    },
    usersQueryName: GET_ALL_USERS_FOR_CARETEAM,
  });
  const navigate = useNavigate();
  const [careTeam, setCareTeam] = useState<ICareTeam>({
    members: [],
    primaryCarePhysician: '',
  });

  const {height} = Dimensions.get('window');
  const removeHeight = parseInt((24 / 100) * height + '');
  const finalListHeight = height - removeHeight;

  const showJourneyTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CARE_JOURNEY,
  );

  const [journeyTableState, setJourneyTableState] =
    useState<IPatientJourneyActionProps>({
      searchString: '',
      page: 1,
      pageSize: 20,
      dataSource: [],
      showCareTeam: false,
      showAssigneePopover: false,
      showActivityTab: false,
      showJourneyTab: false,
      showRestartDrawer: false,
      showStopDrawer: false,
      showPauseDrawer: false,
      showDeleteDrawer: false,
      showStartDrawer: false,
      showGoalsDrawer: false,
      completeOrChangeGoalStatusCareJourneyDrawer: {
        showDrawer: false,
        changeGoalStatus: false,
        intialGoalState: undefined,
      }
    });

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const commonContext = useContext(CommonDataContext);
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = commonContext.sidecarContext?.isSidecar;

  const goalStatusMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.PatientCareJourneyGoalStatus,
  );
  const durationMlovList =
    getMlovListFromCategory(
      commonContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION,
    ) || [];

  const patientJourneyStatusList =
    getMlovListFromCategory(
      commonContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS,
    ) || [];

  const [getCareJourneyById, getCareJourneyByIdQuery] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEY_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updatePatientCareJourneyAPI, { loading: isUpdatePatientCareJourneyAPILoading}] = useMutation(
    CareJourneyQueries.UPDATE_PATIENT_CARE_JOURNEY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const [getUserDetailData, userData] = useLazyQuery(GET_USERS_WITH_IDS);
  const {loading: careTeamDataLoading} = useQuery(GET_CARE_TEAM, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      contactId: props.personData?.contactUUID,
    },
    onCompleted: (careT) => {
      getUserDetailData({
        variables: {
          userIds:
            careT?.careTeams[0]?.userPool?.userPoolUsers?.map(
              (user: any) => user.userId
            ) || [],
          accountId: accountUuid,
        },
        onCompleted: (data) => {
          setCareTeam({
            ...careTeam,
            members: data?.users,
            primaryCarePhysician:
              careT?.careTeams[0]?.primaryCarePhysicianUserId,
          });
        },
      });
    },
  });

  function getJourneyEventDateTime(dateTime: Date | string) {
    if (!dateTime) {
      return undefined;
    }
    let eventDateTime = getMomentObj(dateTime);

    if (isTodayDate(eventDateTime)) {
      eventDateTime = getMomentObj(new Date());
    } else {
      eventDateTime = eventDateTime.startOf('day');
    }

    return eventDateTime.toISOString();
  }

  async function updatePatientCareJourney(patientCareJourneyId: string, statusCode: string, data?: { restartDateTime?: any, stopDateTime?: any, completedDateTime?: any, note?: string, goalStatusId?: string }) {
    const statusId = getMlovId(commonContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS, (statusCode === PATIENT_CARE_JOURNEY_STATUS_CODE.GOAL_STATUS_CHANGE) ? journeyTableState?.selectedJourney?.statusCode || '' : statusCode);

    let operationCode = '';
    const statusReasonCode = null;

    switch (statusCode) {
      case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.ACTIVATE;
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.PAUSE;
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.RESTART:
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.RESTART;
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED:
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.STOP;
        break;
      case 'DELETE':
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.DELETE;
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE:
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.COMPLETE;
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.GOAL_STATUS_CHANGE:
        operationCode = PATIENT_CARE_JOURNEY_OPERATION_CODES.GOAL_STATUS_CHANGE;
        break;
    }

    if (data?.restartDateTime) {
      data.restartDateTime = getJourneyEventDateTime(data.restartDateTime);
    }
    if (data?.completedDateTime) {
      data.completedDateTime = getJourneyEventDateTime(data.completedDateTime);
    }
    if (data?.stopDateTime) {
      data.stopDateTime = getJourneyEventDateTime(data.stopDateTime);
    }

    await updatePatientCareJourneyAPI({
      variables: {
        data: {
          id: patientCareJourneyId,
          operationCode: operationCode,
          data: {
            ...(data || {}),
            statusId,
            statusReasonCode
          }
        }
      }
    });

    if (operationCode === PATIENT_CARE_JOURNEY_OPERATION_CODES.STOP) {
      const stopDate = getFormattedDate(data?.stopDateTime, DISPLAY_DATE_FORMAT);
      showToast(
        toast,
        data?.stopDateTime
          ? `Patient care journey will stop on ${stopDate}`
          : 'Patient care journey stopped successfully.',
        ToastType.success,
      );
    }

    setJourneyTableState(prev => {
      return {
        ...prev,
        updatedJourneyId: patientCareJourneyId,
        selectedJourney: undefined,
        showCareTeam: false,
        showAssigneePopover: false,
        showActivityTab: false,
        showJourneyTab: false,
        showRestartDrawer: false,
        showStopDrawer: false,
        showPauseDrawer: false,
        showDeleteDrawer: false,
        showStartDrawer: false,
        completeOrChangeGoalStatusCareJourneyDrawer: {
          showDrawer: false,
          changeGoalStatus: false,
          intialGoalState: undefined,
        }
      };
    });

    fetchPatientJourneys(journeyTableState.searchString || '');
  }

  const formatCareJourneyResponse = (
    patientCareJourneys: IPatientCareJourney[],
  ) => {
    return (patientCareJourneys || []).filter(patientJourney => {
      let journeyStatus = undefined;
      let statusCode = undefined;
      if (patientJourney?.statusId) {
        statusCode = getMlovCodeFromId(
          patientJourneyStatusList,
          patientJourney?.statusId,
        );
        journeyStatus = getMlovValueFromId(
          patientJourneyStatusList,
          patientJourney?.statusId,
        );
      } else {
        statusCode = JOURNEYS_STATUS_CODE.ACTIVE;
        journeyStatus = getMlovValueFromCode(
          patientJourneyStatusList,
          JOURNEYS_STATUS_CODE.ACTIVE,
        );
      }
      patientJourney.status = journeyStatus;
      patientJourney.statusCode = statusCode;
      if (patientJourney?.goalStatusId) {
        patientJourney.goalStatusCode = goalStatusMlov.find((goal: IMlov) => goal?.id === patientJourney?.goalStatusId)?.code
      }
      return patientJourney;
    });
  };

  const [getPatientJourneys, careJourneyAPI] = useLazyQuery(
    props.filterStatuses?.length > 0 ? CareJourneyQueries.GET_PATIENT_CARE_JOURNEYS_BY_STATUSES : CareJourneyQueries.GET_PATIENT_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: response => {
        if (
          response &&
          response.patientCareJourneys &&
          response.patientCareJourneys.length > 0
        ) {
          const patientJourneyList = formatCareJourneyResponse(
            response.patientCareJourneys
          );

          if (journeyTableState.updatedJourneyId) {
            callPatientSummaryEvent(patientJourneyList);
          }

          setJourneyTableState((prev) => ({
            ...prev,
            dataSource: patientJourneyList,
            updatedJourneyId: undefined,
          }));
        } else {
          if (journeyTableState.updatedJourneyId) {
            callPatientSummaryEvent([]);
          }

          setJourneyTableState((prev) => ({
            ...prev,
            dataSource: [],
            updatedJourneyId: undefined,
          }));
        }
      },
      onError: (error: any) => {

        showErrorToast();
      },
    },
  );

  const callPatientSummaryEvent = (
    patientJourneyList: IPatientCareJourney[]
  ) => {
    const selectedJourney = journeyTableState.dataSource?.find(
      (journey) => journey.id === journeyTableState.updatedJourneyId
    );
    const updatedJourney = patientJourneyList?.find(
      (journey) => journey.id === journeyTableState.updatedJourneyId
    );

    const isDeleted = selectedJourney && !updatedJourney; //if journey deleted
    const journeyStartDateChanged =
      selectedJourney &&
      updatedJourney &&
      selectedJourney?.startDateTime !== updatedJourney?.startDateTime; //if journey start date changed

    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.JOURNEY_UPDATED, {
      id: journeyTableState.updatedJourneyId,
      journeyStartDateChanged: journeyStartDateChanged,
      isDeleted: isDeleted,
    });
  };

  const getInitialGoalState = (selectedJourney: IPatientCareJourney, changeGoalStatus?: boolean, code?: string) => {
    let initialGoalStatus = goalStatusMlov?.find((goalStatus) => goalStatus?.code === GOAL_STATUS_CODES.ACHIEVED)
    if (selectedJourney?.goalStatusId) {
      if (changeGoalStatus) {
        initialGoalStatus = goalStatusMlov?.find((goalStatus) => goalStatus?.id !== selectedJourney?.goalStatusId)
      }
      else {
        initialGoalStatus = goalStatusMlov?.find((goalStatus) => goalStatus?.id === selectedJourney?.goalStatusId)
      }
    }
    else {
      isJourneyStopped(selectedJourney) && (initialGoalStatus = goalStatusMlov?.find((goalStatus) => goalStatus?.code === code))
    }
    return initialGoalStatus
  }

  const onActionPerformed = (
    actionCode: string,
    selectedJourney: IPatientCareJourney,
  ) => {
    switch (actionCode) {
      case PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showCareTeam: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showJourneyTab: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.START:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showStartDrawer: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.STOP:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showStopDrawer: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.PAUSE:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showPauseDrawer: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.RESTART:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showRestartDrawer: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.DELETE:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showDeleteDrawer: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.EDIT:
        // setJourneyTableState(prev => {
        //   return {
        //     ...prev,
        //     selectedJourney,
        //     showActivityTab: true,
        //   };
        // });
        onEditCareJourney(selectedJourney);
        break;
      case PATIENT_JOURNEY_ACTION_CODES.SHOW_GOALS:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            showGoalsDrawer: true,
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_ACHIEVED:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            completeOrChangeGoalStatusCareJourneyDrawer: {
              showDrawer: true,
              intialGoalState: getInitialGoalState(selectedJourney, true, GOAL_STATUS_CODES.ACHIEVED),
              changeGoalStatus: true,
            }
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            completeOrChangeGoalStatusCareJourneyDrawer: {
              showDrawer: true,
              intialGoalState: getInitialGoalState(selectedJourney, true, GOAL_STATUS_CODES.NOT_ACHIEVED),
              changeGoalStatus: true,
            }
          };
        });
        break;
      case PATIENT_JOURNEY_ACTION_CODES.COMPLETE:
        setJourneyTableState(prev => {
          return {
            ...prev,
            selectedJourney,
            completeOrChangeGoalStatusCareJourneyDrawer: {
              showDrawer: true,
              changeGoalStatus: false,
              intialGoalState: getInitialGoalState(selectedJourney, false),
            }
          };
        });
        break;
    }
  };

  useEffect(() => {
    fetchPatientJourneys(journeyTableState.searchString.trim());
  }, []);

  const fetchPatientJourneys = (searchText: string) => {
    if(props.filterStatuses?.length > 0){
      getPatientJourneys({
        variables: {
          patientId: props.personData.patientUuid,
          searchString: `%${searchText}%`,
          statuses: props.filterStatuses
        },
      });
    }
    else {
      getPatientJourneys({
        variables: {
          patientId: props.personData.patientUuid,
          searchString: `%${searchText}%`,
        },
      });
    }
  }

  const onSearch = (searchText: string) => {
    setJourneyTableState(prev => {
      return {
        ...prev,
        searchString: searchText,
      };
    });
    fetchPatientJourneys(searchText.trim());
  }

  const isJourneyAlreadyAssigned = (careJourneyId: string): boolean => {
    const journeyList =  (journeyTableState.dataSource || []).filter(journey => {
      return journey.careJourneyId === careJourneyId;
    });
    if (!journeyList?.length) {
      return false;
    }
    const isAllStopOrCompletedJourney = (journeyList || []).every((journey: any) => {
      const patientCareJourneyStatusId = journey?.statusId;
      const code = getMlovCodeFromId(patientJourneyStatusList, patientCareJourneyStatusId);
      return PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED === code ||  PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE === code;
    });
    return isAllStopOrCompletedJourney ? false : true;
  };

  const isJourneyStopped = (selectedJourney: IPatientCareJourney) => {
    const patientCareJourneyStatusId = selectedJourney?.statusId;
    if(patientCareJourneyStatusId) {
    const code = getMlovCodeFromId(patientJourneyStatusList, patientCareJourneyStatusId);
    return PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED === code;
    }
    return false
  }

  function onEditCareJourney(patientCareJourney: IPatientCareJourney) {
    if (!patientCareJourney?.careJourneyId || !patientCareJourney?.id) {
      return showErrorToast();
    }
    getCareJourneyById({
      variables: {
        id: patientCareJourney?.careJourneyId,
      },
    }).then((response: any) => {
      if (response && response.data && response.data.careJourney) {
        const journeyData = response.data.careJourney;
        const productId = journeyData?.productId || -1
        navigate(
          `/members/patient/${props.personData.id}/product/${productId}/assignJourney/${patientCareJourney.careJourneyId}`,
          {
            state: {
              patientCareJourneyStatusId: patientCareJourney.statusId,
              personData: props.personData,
              patientCareJourneyId: patientCareJourney.id,
              workflowId: patientCareJourney.workflowId,
              workflowMasterId: patientCareJourney.careJourneyVersionId,
            },
          },
        );
      } else {
        showErrorToast();
      }
    })
    .catch((error: ApolloError) => {

      showErrorToast();
    });
  }

  const loading = careJourneyAPI.loading || userData.loading || accountUserLoading || careTeamDataLoading || getCareJourneyByIdQuery.loading;

  return (
    <>
      <VStack style={{height: isSidecarContext ? undefined : finalListHeight}}>
        <View>
          <HStack
            flex={1}
            space={1}
            style={{
              marginBottom: isSidecarContext ? 10 : 16,
              height: 100,
              alignItems: 'center',
            }}>
            <HStack flex={0.3} mt={4}>
              {/* <DisplayText
                size={'lgMedium'}
                extraStyles={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                textLocalId={
                  `${capitalizeText(props.personData.firstName.trim())}'s  Journeys`
                }
              /> */}
              {isSidecarContext && (
                <Stack direction='row' style={{alignItems:'center', marginLeft: 18}}>
                  <JourneysIonSvg/>
                  <Text
                    color={'#0e0333'}
                    fontSize={20}
                    fontWeight={600}
                    fontFamily={'Manrope-Bold'}
                    marginLeft={'12px'}
                  >
                  {intl.formatMessage({id: 'activeCareJourneys'})}
                  </Text>
              </Stack>
              )}
            </HStack>
            <HStack flex={0.7} mt={4} justifyContent={'flex-end'}>
              {journeyTableState.dataSource?.length > 0 && !isSidecarContext && (
                <SearchBar
                  width={180}
                  minWidth={'auto'}
                  borderColor={Colors.Custom.Gray300}
                  searchBarInputStyles={{backgroundColor: 'white', paddingVertical: 4}}
                  placeholderText={'Search Journey'}
                  onChange={debounce(onSearch, 500)}
                />
              )}
              {journeyTableState.dataSource?.length > 0 && (
                <Tooltip label="Show Care Team">
                  <Stack direction='row' style={{alignItems:'center'}}>

                  <Button
                    marginLeft={4}
                    marginTop={1}
                    onPress={() => {
                      setJourneyTableState(prev => {
                        return {
                          ...prev,
                          selectedJourney: undefined,
                          showCareTeam: true,
                        };
                      });
                    }}
                    leftIcon={
                      <Feather name="users" style={{color: Colors.Custom.Gray500}} size={20} />
                    }
                    style={[
                      [
                        {
                          width: 36,
                          height: 36,
                          marginRight: 12
                        },
                      ],
                    ]}
                    backgroundColor={'transparent'}
                  />
                  {!isSidecarContext && <Divider orientation='vertical' height={5} marginRight={5}/>}
                  </Stack>

                </Tooltip>
              )}

              {
                showJourneyTab && !isSidecarContext &&
                <Popover
                  key={'assignee_popup'}
                  overlayInnerStyle={{
                    bottom: 3,
                    borderRadius: 16,
                    padding: 0,
                  }}
                  content={
                    <AssignCareJourneyPopup
                      onComplete={(record: any) => {
                        if (isJourneyAlreadyAssigned(record?.id)) {
                          showToast(
                            toast,
                            intl.formatMessage({id: 'journeyAlreadyAssignMsg'}),
                            ToastType.info,
                            1000
                          );
                          return;
                        }
                        const productId = record?.productId || -1
                        navigate(
                          `/members/patient/${props.personData.id}/product/${productId}/assignJourney/${record?.id}`,
                          {
                            state: {
                              personData: props.personData,
                            },
                          },
                        );
                    }
                    }
                    />
                  }
                  title=""
                  trigger="click"
                  visible={journeyTableState.showAssigneePopover}
                  onVisibleChange={() => {
                    setJourneyTableState(prev => {
                      return {
                        ...prev,
                        showAssigneePopover: !prev.showAssigneePopover,
                      };
                    });
                  }}
                  placement={'bottom'}>
                    <FoldButton
                      nativeProps={{
                        isDisabled: props.isDisabled,
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          const contactId = props.personData?.id || props.personData?.leadId || commonContext.sidecarContext?.contactId;
                          if(isSidecarContext){
                            redirectToNewTab(`/members/patient/${contactId}/journeys`)
                          }
                          else {
                            setJourneyTableState(prev => {
                              return {
                                ...prev,
                                showAssigneePopover: !prev.showAssigneePopover,
                              };
                            });
                          }
                        },
                        leftIcon: <PlusIcon />,
                        style: {
                          minWidth: 'auto',
                          marginRight: isSidecarContext ? 12 : 0,
                          backgroundColor:'white'},
                        }
                      }
                      customProps={{
                        btnText: intl.formatMessage({id: 'assignJourney'}),
                        withRightBorder: false,
                        isDisableOnPress: true,
                      }}
                    />
                </Popover>
              }
            </HStack>
          </HStack>
          {loading && (
            <View width="full" padding={4}>
              <Skeleton active />
            </View>
          )}
          {!loading && (
          <PageBodyContainer
            style={{
              marginHorizontal: 0,
              ...(isSidecarContext
                ? {
                  borderWidth: 0,
                  borderColor: Colors.FoldPixel.GRAY100,
                  }
                : {
                  borderColor: Colors.Custom.Gray200,
                  borderWidth: 1
                }),
            }}>
              <ScrollView>
              {isSidecarContext && <Divider marginTop={2}/>}
              <View>
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 == 0 && !isSidecarContext ? 'table-row-light' : ''
                    }
                    rowKey={(record: IPatientCareJourney, index) => {
                      return record.id;
                    }}
                    size={isSidecarContext ? 'middle' : undefined}
                    columns={getPatientCareJourneyTableColumns({
                      durationMlovList,
                      onActionPerformed,
                      isSidecarContext
                    })}
                    loading={loading}
                    dataSource={journeyTableState.dataSource}
                    onRow={(_record: any) => {
                      return {
                        onClick: () => {
                          onActionPerformed(PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY, _record);
                        },
                      };
                    }}
                    pagination={false}
                  />
                </View>
              </ScrollView>
          </PageBodyContainer>
          )}
        </View>
      </VStack>
      {journeyTableState.showCareTeam && (
        <CareTeamFlow
          careTeam={careTeam}
          onClose={() => {
            setJourneyTableState(prev => ({
              ...prev,
              showCareTeam: !prev.showCareTeam,
            }));
          }}
          personData={props.personData}
          userList={accountUserList || []}
          careJourneyId={journeyTableState?.selectedJourney?.careJourneyId}
          drawerWidth={
            isSidecarContext? '100%' : journeyTableState?.selectedJourney?.careJourneyId ? '50%' : '85%'
          }
          journeyTitle={journeyTableState?.selectedJourney?.title || journeyTableState?.selectedJourney?.careJourney?.title}
        />
      )}
      <PatientCareJourneyDetail
        visible={
          journeyTableState.showActivityTab || journeyTableState.showJourneyTab
        }
        patientCareJourney={journeyTableState.selectedJourney}
        showJourneyTab={journeyTableState.showJourneyTab}
        showActivityTab={journeyTableState.showActivityTab}
        onClose={() => {
          setJourneyTableState(prev => {
            return {
              ...prev,
              selectedJourney: undefined,
              showJourneyTab: false,
              showActivityTab: false,
            };
          });
        }}
      />
      <StopCareJourneyDrawer
        enabledDrawer={journeyTableState.showStopDrawer}
        isLoading={isUpdatePatientCareJourneyAPILoading}
        selectedJourney={
          journeyTableState.selectedJourney || ({} as IPatientCareJourney)
        }
        onActionPerformed={(
          additionalData: any,
          selectedJourney: IPatientCareJourney,
        ) => {
          updatePatientCareJourney(selectedJourney.id, PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED, { stopDateTime: additionalData?.stopDateTime, note: additionalData?.note, goalStatusId: additionalData?.goalStatusId });
        }}
        onCancel={() => {
          setJourneyTableState(prev => {
            return {
              ...prev,
              selectedJourney: undefined,
              showStopDrawer: false,
            };
          });
        }}
      />
      <RestartCareJourneyDrawer
        enabledDrawer={journeyTableState.showRestartDrawer}
        isLoading={isUpdatePatientCareJourneyAPILoading}
        selectedJourney={
          journeyTableState.selectedJourney || ({} as IPatientCareJourney)
        }
        onActionPerformed={(
          additionalData: any,
          selectedJourney: IPatientCareJourney,
        ) => {
          updatePatientCareJourney(selectedJourney.id, PATIENT_CARE_JOURNEY_STATUS_CODE.RESTART, { restartDateTime: additionalData?.restartDateTime, note: additionalData?.note });
        }}
        onCancel={() => {
          setJourneyTableState(prev => {
            return {
              ...prev,
              selectedJourney: undefined,
              showRestartDrawer: false,
            };
          });
        }}
      />
      <PauseCareJourneyDrawer
        enabledDrawer={journeyTableState.showPauseDrawer}
        isLoading={isUpdatePatientCareJourneyAPILoading}
        selectedJourney={
          journeyTableState.selectedJourney || ({} as IPatientCareJourney)
        }
        onActionPerformed={(
          additionalData: any,
          selectedJourney: IPatientCareJourney,
        ) => {
          updatePatientCareJourney(selectedJourney.id, PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED, { restartDateTime: additionalData?.restartDateTime, note: additionalData?.note });
        }}
        onCancel={() => {
          setJourneyTableState(prev => {
            return {
              ...prev,
              selectedJourney: undefined,
              showPauseDrawer: false,
            };
          });
        }}
      />
      <DeleteCareJourneyDrawer
        enabledDrawer={journeyTableState.showDeleteDrawer}
        isLoading={isUpdatePatientCareJourneyAPILoading}
        selectedJourney={
          journeyTableState.selectedJourney || ({} as IPatientCareJourney)
        }
        onActionPerformed={(
          additionalData: any,
          selectedJourney: IPatientCareJourney,
        ) => {
          updatePatientCareJourney(selectedJourney.id, 'DELETE');
        }}
        onCancel={() => {
          setJourneyTableState(prev => {
            return {
              ...prev,
              selectedJourney: undefined,
              showDeleteDrawer: false,
            };
          });
        }}
      />
      {journeyTableState.showStartDrawer && (
        <StartCareJourneyDrawer
          enabledDrawer={journeyTableState.showStartDrawer}
          isLoading={isUpdatePatientCareJourneyAPILoading}
          selectedJourney={
            journeyTableState.selectedJourney || ({} as IPatientCareJourney)
          }
          onActionPerformed={(
            additionalData: any,
            selectedJourney: IPatientCareJourney,
          ) => {
            setJourneyTableState(prev => {
              return {
                ...prev,
                selectedJourney: undefined,
                showStartDrawer: false,
                updatedJourneyId: selectedJourney.id,
              };
            });
            fetchPatientJourneys(journeyTableState.searchString.trim());
          }}
          onCancel={() => {
            setJourneyTableState(prev => {
              return {
                ...prev,
                selectedJourney: undefined,
                showStartDrawer: false,
              };
            });
          }}
        />
      )}
      {journeyTableState.showGoalsDrawer && (
        <GoalsCareJourneyDrawer
          onClose={() => {
            setJourneyTableState(prev => {
              return {
                ...prev,
                selectedJourney: undefined,
                showGoalsDrawer: false,
              };
            });
          }}
          enabledDrawer={journeyTableState.showGoalsDrawer}
          selectedJourney={
            journeyTableState.selectedJourney || ({} as IPatientCareJourney)
          }
        />
      )
      }
      <CompleteOrChangeGoalStatusCareJourneyDrawer
        enabledDrawer={journeyTableState.completeOrChangeGoalStatusCareJourneyDrawer.showDrawer}
        changeGoalStatus={journeyTableState.completeOrChangeGoalStatusCareJourneyDrawer.changeGoalStatus}
        initialGoalState={journeyTableState.completeOrChangeGoalStatusCareJourneyDrawer.intialGoalState}
        isLoading={isUpdatePatientCareJourneyAPILoading}
        selectedJourney={
          journeyTableState.selectedJourney || ({} as IPatientCareJourney)
        }
        onActionPerformed={(
          additionalData: any,
          selectedJourney: IPatientCareJourney,
        ) => {
          updatePatientCareJourney(selectedJourney.id, additionalData?.changeGoalStatus ? PATIENT_CARE_JOURNEY_STATUS_CODE.GOAL_STATUS_CHANGE : PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE, { completedDateTime: additionalData?.completionDateTime, note: additionalData?.note, goalStatusId: additionalData?.goalStatusId });
        }}
        onCancel={() => {
          setJourneyTableState(prev => {
            return {
              ...prev,
              selectedJourney: undefined,
              completeOrChangeGoalStatusCareJourneyDrawer: {
                showDrawer: false,
                changeGoalStatus: false,
                intialGoalState: undefined
              }
            };
          });
        }}
      />
    </>
  );
};

export default PatientCareJourneyListView;
