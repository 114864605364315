import {Tooltip} from 'antd';
import {Box, Image, Pressable, Text, View} from 'native-base';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Colors} from '../../../styles';
import {getOnFocusButtonStyles} from '../../../utils/commonUtils';
import {
  getUserPermissionByEntityAndActionCode,
  USER_ACCESS_PERMISSION,
} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import SettingSvg from '../Svg/SideMenu/SettingSvg';
import {WorkflowDrawer} from '../WorkflowDrawer';
import {AutomationSettingBtnProps} from './interfaces';
import { TestIdentifiers, testID } from '../../../testUtils';
import AutomateWorkflowSvg from '../Svg/AutomateWorkflowSvg';

const AutomationSettingBtn = (props: AutomationSettingBtnProps) => {
  const navigate = useNavigate();
  const [workflowDrawerOpen, setWorkflowDrawerOpen] = React.useState(false);

  const hasSettingsTabsPermission = getUserPermissionByEntityAndActionCode(
    'DASHBOARD_WINDOW',
    'SETTING'
  );

  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );

  const showDivider =
    ((props.flowType && showAutomationTab) ||
    (props.settingsPath && hasSettingsTabsPermission)) && !props.hideDivider;

  return (
    <View flexDirection={'row'} alignItems={'center'}>
      {props.flowType && showAutomationTab && hasSettingsTabsPermission && (
        <>
        <View
          style={{
            width: 1,
            height: 20,
            backgroundColor: Colors.Custom.Gray200,
            marginHorizontal: 16,
          }}
        ></View>
        <View>
          <Tooltip
            placement="bottomRight"
            color="linear-gradient(180deg, #1E9DAE -64.81%, #D478FF 118.52%)"
            overlayInnerStyle={{}}
            title={
              <Box
                style={{
                  borderRadius: 6,
                  paddingVertical: 10,
                  paddingHorizontal: 12,
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 16,
                    lineHeight: 17,
                    color: 'white',
                  }}
                  {...testID('Automate Your Workflows')}
                >
                  {`Automate Your Workflows`}
                </Text>
              </Box>
            }
          >
            <Pressable
              onPress={() => {
                setWorkflowDrawerOpen(true);
              }}
              _focus={{
                ...getOnFocusButtonStyles({
                  borderColor: 'blue',
                }),
                borderWidth: 1,
              }}
              {...testID(TestIdentifiers.automation)}
            >
              <AutomateWorkflowSvg />
            </Pressable>
          </Tooltip>
        </View>
        </>
      )}

      <View
        style={{
          width: 0.5,
          height: 16,
          backgroundColor: Colors.FoldPixel.GRAY150,
          marginHorizontal: 8,
        }}
      ></View>

      {props.settingsPath && hasSettingsTabsPermission && (
        <Pressable
          alignItems={'center'}
          justifyContent="center"
          w={5}
          _focus={{
            ...getOnFocusButtonStyles({
              borderColor: 'blue',
            }),
            borderWidth: 1,
          }}
          onPress={() => {
            const settingsPath = props.settingsPath || '/admin/setting';
            navigate(settingsPath);
          }}
          cursor="pointer"
          {...testID(TestIdentifiers.settingsBtn)}
        >
          <SettingSvg height={20} width={20} isPrimary={true} />
        </Pressable>
      )}

      {workflowDrawerOpen && (
        <WorkflowDrawer
          isOpen={workflowDrawerOpen}
          onClose={() => {
            setWorkflowDrawerOpen(false);
          }}
          flowType={props.flowType}
        />
      )}
    </View>
  );
};

export default AutomationSettingBtn;
