import {gql} from '@apollo/client';

const GetLastCallActivityConversations = gql`
  query GetLastCallAtActivityConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [call_conversation_activities_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    callConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: {conversation: $whereCondition}
    ) {
      lastCallMessage {
        contentType
        messageType
        content
        private
        createdAt
        updatedAt
      }
      conversation {
        contactId
        id
        uuid
        accountId
        displayId
        inboxId
        status
        assigneeId
        createdAt
        lastActivityAt
        lastSmsAt
        conversationInbox {
          id
          channelType
          isDeleted
        }
        conversationContact {
          id
          uuid
          isDeleted
          name
          phoneNumber
          email
        }
        unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
        assignedUser {
          id
          uuid
          name
        }
      }
    }
  }
`;

const SearchLastCallActivityConversations = gql`
  query GetLastCallAtActivityConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [call_conversation_activities_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
  ) {
    callConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: {conversation: $whereCondition}
    ) {
      lastCallMessage {
        contentType
        messageType
        content
        private
        createdAt
        updatedAt
      }
      conversation {
        contactId
        id
        uuid
        accountId
        displayId
        inboxId
        status
        assigneeId
        createdAt
        lastActivityAt
        lastSmsAt
        conversationInbox {
          id
          channelType
          isDeleted
        }
        conversationContact {
          id
          uuid
          isDeleted
          name
          phoneNumber
          email
        }
        assignedUser {
          id
          uuid
          name
        }
      }
    }
  }
`;

const GetLastCallActivityConversationsMobile = gql`
  query GetLastCallAtActivityConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [call_conversation_activities_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    callConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: {conversation: $whereCondition}
    ) {
      lastCallMessage {
        contentType
        messageType
        content
        private
        createdAt
        updatedAt
      }
      conversation {
        contactId
        id
        uuid
        accountId
        displayId
        inboxId
        status
        assigneeId
        createdAt
        lastActivityAt
        lastSmsAt
        conversationInbox {
          id
          name
          channelType
          isDeleted
          channelEmail {
            forwardToEmail
            forwardRouteId
            smtpAddress
          }
          channelTwilioSms {
            phoneNumber
          }
          channelEfax {
            id
            efaxNumber
          }
          inboxMembers {
            user {
              uuid
              id
              name
            }
          }
        }
        conversationContact {
          isDeleted
          isActive
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          additionalAttributes
          isActive
          accountId
          uuid
          source
          phoneNumber
          pubsubToken
          updatedAt
          notes {
            contactId
            content
            userId
            id
          }
          name
          email
          id
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          contactConsents {
            contactUuid
          }
          personContact {
            personContactId
            rank
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
          }
          lastActivityAt
          identifier
        }
        unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
        assignedUser {
          id
          uuid
          name
        }
        groupConversation {
          id
          name
          ownerId
          ownerTypeId
          groupType {
            code
            description
            value
          }
        }
        contactInbox {
          sourceId
        }
      }
    }
  }
`;

const SearchLastCallActivityConversationsMobile = gql`
  query GetLastCallAtActivityConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [call_conversation_activities_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
  ) {
    callConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: {conversation: $whereCondition}
    ) {
      lastCallMessage {
        contentType
        messageType
        content
        private
        createdAt
        updatedAt
      }
      conversation {
        contactId
        id
        uuid
        accountId
        displayId
        inboxId
        status
        assigneeId
        createdAt
        lastActivityAt
        lastSmsAt
        conversationInbox {
          id
          name
          channelType
          isDeleted
          channelEmail {
            forwardToEmail
            forwardRouteId
            smtpAddress
          }
          channelTwilioSms {
            phoneNumber
          }
          channelEfax {
            id
            efaxNumber
          }
          inboxMembers {
            user {
              uuid
              id
              name
            }
          }
        }
        conversationContact {
          isDeleted
          isActive
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          additionalAttributes
          isActive
          accountId
          uuid
          source
          phoneNumber
          pubsubToken
          updatedAt
          notes {
            contactId
            content
            userId
            id
          }
          name
          email
          id
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          contactConsents {
            contactUuid
          }
          personContact {
            personContactId
            rank
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
          }
          lastActivityAt
          identifier
        }
        assignedUser {
          id
          uuid
          name
        }
        groupConversation {
          id
          name
          ownerId
          ownerTypeId
          groupType {
            code
            description
            value
          }
        }
        contactInbox {
          sourceId
        }
      }
    }
  }
`;

export default {
  GetLastCallActivityConversations,
  SearchLastCallActivityConversations,
  GetLastCallActivityConversationsMobile,
  SearchLastCallActivityConversationsMobile,
};
