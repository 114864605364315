import React from "react";
import { Path, Svg } from "react-native-svg";

function MediumPriorityIcon() {
  return (
    <Svg
      width='13'
      height='7'
      fill='none'
      viewBox='0 0 13 7'
      id='MediumPriorityIcon'
    >
      <Path
        id='MediumPriorityIcon_Path_0'
        stroke='#F79009'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M1 1h11.2M1 5.8h11.2'
      ></Path>
    </Svg>
  );
}

export default MediumPriorityIcon;
