import React, {useContext, useEffect, useState} from 'react';
import {Drawer, Popover, Tooltip} from 'antd';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../styles';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  EVENT_NAMES,
  MLOV_CATEGORY,
  PERSON_TYPES,
} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {useApolloClient, useLazyQuery} from '@apollo/client';
import {LeadQueries, TaskQueries} from '../../../services';
import {ITask} from '../CareDashboard/CareDashboardInterfaces';
import {
  Badge,
  Box,
  FlatList,
  HStack,
  Icon,
  Pressable,
  Skeleton,
  Spinner,
  Text,
  VStack,
  View,
} from 'native-base';
import {
  BottomViewAction,
} from '../../TaskCard/TaskCardHelper';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  getMlovId,
  getMlovListByCategory,
  getMlovListFromCategory,
  getMlovObjectFromCode,
  getMlovValue,
} from '../../../utils/mlovUtils';
import {
  IPropertyDetails,
  PropertyType,
  getCompletedTaskStatusId,
} from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {useIntl} from 'react-intl';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import {EventBus} from '../../../utils/EventBus';
import InfoSection from '../CareProgramStepOutreach/components/InfoSection';
import {ContactRelationCommunication} from '../CareProgramStepOutreach/components/Communication';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {
  getAccountUUID,
  getUserFullName,
  getUserUUID,
} from '../../../utils/commonUtils';
import TaskListItemV2 from './components/TaskListItemV2';
import {ToastType} from '../../../utils/commonViewUtils';
import {useToast} from '../../Toast/ToastProvider';
import useTaskActionManager from '../CareDashboard/CustomHook/useTaskActionManager';
import {ClinicalSectionFilterTabs} from '../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {cloneDeep} from 'lodash';
import useGetBatchedAccountUsers from '../../CustomHooks/useGetBatchedAccountUsers';
import Feather from 'react-native-vector-icons/Feather';
import {getFormDataFromLeadData} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {ParticipantType} from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {ILoginUserData} from '../../../Interfaces';
import WriteInfoBackBanner from '../../PersonOmniView/MiddleContainer/CareTimeline/components/WriteInfoBackBanner';
import SortByTaskSortType from '../CommonFilters/SortByTaskSortType';
import SortTaskIcon from '../../../assets/Icons/SortTask';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {ITopBarData} from '../CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import FilterDrawer from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/FilterDrawer';
import {TASK_STATUS, TASK_STATUS_CODES} from '../../../constants/MlovConst';
import {getPatientTasksDefaultParams} from '../../TaskModule/TaskModuleHelper';
import {TaskPanelType} from '../../TaskCard/TaskEnum';
import {Dimensions} from 'react-native';
import NoTaskSvg from '../Svg/NoTaskSvg';
import { IFilter } from './PatientListInternalTaskInterfaces';

interface IInternalTaskDrawerProps {
  onClose: (syncParent: boolean) => void;
  contactId: string;
}

const InternalTaskDrawer = (props: IInternalTaskDrawerProps) => {
  // Constants and variables
  const {onClose, contactId} = props;
  const pageSize = 20;
  const height = Dimensions.get('window').height * 0.7;
  const contextData = useContext(CommonDataContext);
  const intl = useIntl();
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const accountUuid = getAccountUUID();
  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
    userMap: accountUserMap,
  } = useGetBatchedAccountUsers({
    useMap: true,
  });
  const client = useApolloClient();
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const [contactDataLoading, setContactDataLoading] =
    React.useState<boolean>(true);
  const currentUserUUID = getUserUUID();
  const userData = mlovData.userData || ({} as ILoginUserData);

  const initialTaskTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];
  const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];

  const taskStatusMlovs = getMlovListByCategory(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS.ACCEPTED,
    taskStatusMlovs
  );
  const missedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS_CODES.MISSED,
    taskStatusMlovs
  );

  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  enum TaskTab {
    pending = 'pending',
    completed = 'completed',
  }

  // States
  const [componentState, setComponentState] = React.useState<{
    fetchLoading: boolean;
    taskListLoading: boolean;
    paginationLoading: boolean;
    taskList: ITask[];
    offset: number;
    totalTask: number;
    pendingTaskCount?: number;
    completedTaskCount?: number;
    selectedTask: ITask | null;
    isSomeThingChanged: boolean;
    currentTab: string;
    contactData: IContact | undefined;
    consentData?: any[];
    isShowAddTaskDrawer?: boolean;
    filterCount?: number | null;
    formattedContactData?: any
  }>({
    fetchLoading: false,
    taskListLoading: false,
    paginationLoading: false,
    taskList: [],
    offset: 0,
    totalTask: 0,
    pendingTaskCount: 0,
    completedTaskCount: 0,
    selectedTask: null,
    isSomeThingChanged: false,
    currentTab: TaskTab.pending,
    contactData: undefined,
    consentData: [],
    isShowAddTaskDrawer: false,
    filterCount: 0,
    formattedContactData: {}
  });

  const [sortData, setSortData] = useState<ITopBarData>({
    start: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    selectedSortUuid: 'endDateTime',
    selectedSortType: 'endDateTime',
    selectedSortValueAscending: true,
    addTask: false,
    hideActions: true,
    panelType: TaskPanelType.INTERNAL,
  });
  const [showSortPopover, setShowSortPopover] = useState(false);
  const [isShowFilterDrawer, setIsShowFilterDrawer] = useState<boolean>(false);
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>({
    propertyCodes: TASK_STATUS_CODES,
    propertyType: PropertyType.status,
  });
  const [loadingData, setLoadingData] = React.useState<{
    loading: boolean;
    taskIdWithAction?: any;
  }>({
    loading: false,
  });

  const [filter, setFilter] = useState<IFilter>({
    priorityTypeList: initialPriorityType,
    taskTypeList: initialTaskTypes,
    selectedStatusCodes: [acceptedStatusMlov?.code || '', missedStatusMlov?.code || ''],
    formData: {
      selectedDueDate: [allStatusValue],
      start: getDateStrFromFormat(
        new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    },
  });

  // API
  const [getSingleTasks] = useLazyQuery(TaskQueries.GET_TASKS_BY_IDS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getContactConsentByIds] = useLazyQuery(
    LeadQueries.GetConsentsByContactIds,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  // Life cycle methods
  useEffect(() => {
    fetchData();
  }, [contactId]);

  // Other methods
  const onShowToast = (
    message: string,
    type: ToastType,
    additionalData?: any
  ) => {
    toast({
      message,
      duration: additionalData?.duration || 3000,
      toastType: type,
    });
  };
  const {handleTaskActions} = useTaskActionManager({showToast: onShowToast});

  const getQueryObject = (params: {
    offset: number;
    filterData: any;
    sortData: ITopBarData;
    currentTab?: string;
    formattedContactData?: any;
  }) => {
    const {offset, sortData, filterData, currentTab, formattedContactData} = params;
    const formattedContactDatas= formattedContactData || componentState.formattedContactData;
    const queryParams = getPatientTasksDefaultParams({
      topBarData: sortData,
      taskStatusMlovList: taskStatusMlovs,
      filters: filterData,
      personData: formattedContactDatas,
    });

    const currentselectedTab = currentTab
      ? currentTab
      : componentState.currentTab;
    return {
      ...queryParams,
      limit: pageSize,
      offset: offset,
      statusIdsNotIn:
        currentselectedTab === TaskTab.pending ? [completedStatusId] : undefined,
      statusIds:
        currentselectedTab === TaskTab.completed ? [completedStatusId] : undefined,
    };
  };

  const fetchContactData = async () => {
    setContactDataLoading(true);
    try {
      const promiseList: Promise<any>[] = [];
      promiseList.push(
        getContactsFromIds({
          variables: {
            contactIds: [contactId],
          },
        })
      );

      const variables = {
        accountUuid: accountUuid,
        contactIds: {
          _in: [contactId],
        },
      };

      promiseList.push(
        getContactConsentByIds({
          variables: variables,
        })
      );

      const [contactDataResponse, consentResponseObj] = await Promise.all(
        promiseList
      );

      const consentData = consentResponseObj?.data?.contactConsents;

      const contactInfo = contactDataResponse?.data?.contacts?.[0];

      const formattedContactData = getFormDataFromLeadData(
        contactInfo,
        mlovData
      );

      setComponentState((prev) => ({
        ...prev,
        contactData: contactInfo,
        consentData: consentData,
        formattedContactData: formattedContactData
      }));
      return formattedContactData;
    } catch (error) {
    } finally {
      setContactDataLoading(false);
    }
  };

  const fetchTasks = async (params: {
    offset: number;
    filterData: any;
    sortData: ITopBarData;
    currentTab?: string;
    formattedContactData?: any
  }) => {
    const queryParams = getQueryObject(params);
    const response = await client.query({
      query: TaskQueries.GET_CARE_DASHBOARD_TASKS,
      variables: cloneDeep(queryParams),
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });
    const taskList = response?.data?.getTasks?.tasks || [];
    const totalTask = response?.data?.getTasks?.aggregate?.total || 0;

    setComponentState((prev) => {
      return {
        ...prev,
        taskList:
          params.offset === 0 ? taskList : [...prev.taskList, ...taskList],
        paginationLoading: false,
        fetchLoading: false,
        taskListLoading: false,
        totalTask: totalTask,
      };
    });
  };

  const fetchData = async () => {
    setComponentState((prev) => ({
      ...prev,
      fetchLoading: true,
    }));
    try {
      const formattedContactData = await fetchContactData();
      const promiseList: Promise<any>[] = [];
      promiseList.push(
        fetchTasks({
          offset: 0,
          filterData: filter,
          sortData: sortData,
          formattedContactData: formattedContactData
        })
      );

      promiseList.push(
        updateTaskCounts({formattedContactData: formattedContactData})
      );
      const result = await Promise.all(promiseList);
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        fetchLoading: false,
      }));
      onShowToast(intl.formatMessage({id: 'fetchDataError'}), ToastType.error);
    }
  };

  const updateTaskCounts = async (params: {
    filterData?: any;
    sortingData?: ITopBarData;
    formattedContactData?: any;
  }) => {
    const promiseList: Promise<any>[] = [];
    const {filterData, sortingData, formattedContactData} = params || {};
    const filterparam = filterData || filter;
    const sortingparam = sortingData || sortData;
    const formattedContactDataValue =
      formattedContactData || componentState.formattedContactData;
    const pendingParam = getQueryObject({
      offset: 0,
      filterData: filterparam,
      sortData: sortingparam,
      currentTab: TaskTab.pending,
      formattedContactData: formattedContactDataValue
    });
    promiseList.push(
      client.query({
        query: TaskQueries.GET_AGGREGATE_TASK_COUNT,
        variables: {
          params: pendingParam,
        },
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      })
    );
    const completedParam = getQueryObject({
      offset: 0,
      filterData: filterparam,
      sortData: sortingparam,
      currentTab: TaskTab.completed,
      formattedContactData: formattedContactDataValue
    });
    promiseList.push(
      client.query({
        query: TaskQueries.GET_AGGREGATE_TASK_COUNT,
        variables: {
          params: completedParam,
        },
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      })
    );

    const [pendingTaskData, completedTaskData] = await Promise.all(promiseList);

    const pendingTaskCount = pendingTaskData.data?.getTasks?.aggregate?.total;
    const completedTaskCount = completedTaskData.data?.getTasks?.aggregate?.total;
    setComponentState((prev: any) => {
      return {
        ...prev,
        pendingTaskCount: pendingTaskCount,
        completedTaskCount: completedTaskCount,
      };
    });
  };

  const handleEndReached = () => {
    if (
      componentState.paginationLoading ||
      componentState.totalTask < pageSize
    ) {
      return;
    }
    if (componentState.offset < componentState.totalTask) {
      setComponentState((prev) => {
        const newOffset = componentState.offset + pageSize;
        fetchTasks({
          offset: newOffset,
          filterData: filter,
          sortData: sortData,
        });
        return {
          ...prev,
          paginationLoading: true,
          offset: newOffset,
        };
      });
    }
  };

  const handleBottomViewActions = async (
    task: ITask,
    action: BottomViewAction
  ) => {
    setLoadingData((prev) => ({
      ...prev,
      taskIdWithAction: task.id,
      loading: true,
    }));
    switch (action) {
      case BottomViewAction.markAsComplete:
        try {
          setLoadingData({
            taskIdWithAction: task.id,
            loading: true,
          });
          const response = await handleTaskActions(task, action) as any;
          if(response && response?.success) {
            setComponentState((prev) => ({
              ...prev,
              taskList: prev.taskList.filter((t) => t.id !== task.id),
              isSomeThingChanged: true,
              totalTask: prev.totalTask - 1,
              selectedTask: null,
            }));
            updateTaskCounts({});
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
          }
        } catch (error) {
          setLoadingData({
            taskIdWithAction: task.id,
            loading: false,
          });
          onShowToast(
            intl.formatMessage({id: 'completeTaskError'}),
            ToastType.error
          );
        }
        break;

      case BottomViewAction.markAsIncomplete:
        try {
          setLoadingData({
            taskIdWithAction: task.id,
            loading: true,
          });
          const response = await handleTaskActions(task, action) as any;
          if (response && response?.success) {
            setComponentState((prev) => ({
              ...prev,
              taskList: prev.taskList.filter((t) => t.id !== task.id),
              isSomeThingChanged: true,
              totalTask: prev.totalTask - 1,
              selectedTask: null,
            }));
            updateTaskCounts({});
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
          }
        } catch (error) {
          setLoadingData({
            taskIdWithAction: task.id,
            loading: false,
          });
          onShowToast(
            intl.formatMessage({id: 'incompleteTaskError'}),
            ToastType.error
          );
        }
        break;

        case BottomViewAction.markAsMissed:
          try {
            setLoadingData({
              taskIdWithAction: task.id,
              loading: true,
            });
            const response = await handleTaskActions(task, action) as any;
            if (response && response?.success) {
              setComponentState((prev) => ({
                ...prev,
                taskList: prev.taskList.filter((t) => t.id !== task.id),
                isSomeThingChanged: true,
                totalTask: prev.totalTask - 1,
                selectedTask: null,
              }));
              updateTaskCounts({});
              const eventBus = EventBus.getEventBusInstance();
              const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
              eventBus.broadcastEvent(code, {});
            }
          } catch (error) {
            setLoadingData({
              taskIdWithAction: task.id,
              loading: false,
            });
            onShowToast(
              intl.formatMessage({id: 'missedTaskError'}),
              ToastType.error
            );
          }
        break;

      case BottomViewAction.viewTask:
        setComponentState((prev) => ({
          ...prev,
          selectedTask: {
            ...task,
            isCompleted: task.status?.code === TASK_STATUS_CODES.COMPLETED
          },
        }));
        break;

      default:
        break;
    }
    setLoadingData({
      taskIdWithAction: undefined,
      loading: false,
    });
  };

  const fetchUpdatedTask = async (uuid?: string) => {
    if (!uuid) {
      return;
    }
    const response = await getSingleTasks({
      variables: {
        ids: [uuid],
      },
    });
    const updatedTask = response?.data?.getTasks?.tasks?.[0];
    setComponentState((prev) => ({
      ...prev,
      taskList: prev.taskList.map((task) =>
        task.id === updatedTask.id ? updatedTask : task
      ),
    }));
  };

  const getNewTaskMember = () => {
    return {
      value: componentState.formattedContactData?.contactUUID,
      label: componentState.formattedContactData?.name,
      key: componentState.formattedContactData?.contactUUID,
      type:
        componentState.formattedContactData.contactType?.code ===
        PERSON_TYPES.LEAD
          ? ParticipantType.leads
          : ParticipantType.patient,
      contactData: componentState.formattedContactData?.contactData,
      data: componentState.formattedContactData?.contactData,
    };
  };

  const getNewTaskAssignee = () => {
    return {
      value: currentUserUUID,
      label: getUserFullName(),
      key: currentUserUUID,
      type: ParticipantType.staff,
      details: userData,
    };
  };

  const handleReturnFilterItems = (actionCode: string, rawData: any) => {
    let sortData: any = {};
    setSortData((prev) => {
      sortData = {
        ...prev,
        selectedSortType: rawData?.sortType,
        selectedSortUuid: rawData?.sortTypeUuid,
        selectedSortValueAscending: !prev.selectedSortValueAscending,
      };
      return sortData;
    });
    setShowSortPopover(!showSortPopover);
    setComponentState((prev) => {
      return {
        ...prev,
        taskListLoading: true,
        offset: 0,
      };
    });
    fetchTasks({
      offset: 0,
      filterData: filter,
      sortData: sortData,
    });
  };

  const handleTabClick = (code: string) => {
    if (!componentState.taskListLoading) {
      setComponentState((prev) => ({
        ...prev,
        currentTab: code,
        taskListLoading: true,
        offset: 0,
      }));
      fetchTasks({
        offset: 0,
        filterData: filter,
        sortData: sortData,
        currentTab: code,
      });
      updateTaskCounts({});
    }
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      className="custom-drawer-styles"
      open={true}
      closable
      width={'32%'}
      onClose={() => {
        onClose(true);
      }}
      title={
        <>
          <ModalActionTitle
            title={'Internal Tasks'}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  if (
                    componentState.isSomeThingChanged ||
                    !!componentState.selectedTask
                  ) {
                    const eventBus = EventBus.getEventBusInstance();
                    const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
                    eventBus.broadcastEvent(code, {});
                  }
                  onClose(false);
                },
              },
              {
                show: true,
                id: 3,
                btnText: 'addTask',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    isShowAddTaskDrawer: true,
                  }));
                },
              },
            ]}
          />
        </>
      }
    >
      {componentState.fetchLoading || accountUserLoading ? (
        <View mx={6}>
          <Skeleton my={2} />
          <Skeleton my={2} />
          <Skeleton my={2} />
          <Skeleton my={2} />
        </View>
      ) : (
        <View mx={6}>
          <View my={2}>
            {contactDataLoading ? (
              <Skeleton w="100%" />
            ) : (
              <>
                <InfoSection
                  contactData={componentState.contactData}
                  infoItems={[]}
                  actionView={
                    <View>
                      <ContactRelationCommunication
                        handleActions={() => null}
                        contact={
                          {
                            ...componentState.contactData,
                            contactConsents: componentState.consentData,
                          } as IContact
                        }
                      />
                    </View>
                  }
                />
                <View my={2} />
                <HStack
                  space={2}
                  ml={'10px'}
                  flex={1}
                  alignItems={'center'}
                  mr={'10px'}
                  justifyContent={'space-between'}
                >
                  <Box
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                    flexDir={'row'}
                  >
                    <Popover
                      overlayInnerStyle={{
                        bottom: 3,
                        borderRadius: 16,
                        padding: 0,
                      }}
                      content={
                        <SortByTaskSortType
                          selectedSortType={sortData?.selectedSortType}
                          returnFilterItems={(actionCode: string, rawData: any) => {
                            handleReturnFilterItems(actionCode, rawData);
                          }}
                          selectedSortValueAscending={
                            sortData?.selectedSortValueAscending
                          }
                        />
                      }
                      title=""
                      trigger="click"
                      open={showSortPopover}
                      onOpenChange={(status: boolean) =>
                        setShowSortPopover(status)
                      }
                      placement={'bottom'}
                    >
                      <Tooltip title="Sort">
                        <Pressable
                          onPress={() => {
                            setShowSortPopover(!showSortPopover);
                          }}
                        >
                          <Icon
                            color={Colors.Custom.PurpleColor}
                            as={
                              <SortTaskIcon
                                color={
                                  sortData?.selectedSortType !== 'None'
                                    ? Colors.Custom.PurpleColor
                                    : ''
                                }
                              />
                            }
                            name={'sort'}
                            size="4"
                            onPress={() => setShowSortPopover(!showSortPopover)}
                          />
                        </Pressable>
                      </Tooltip>
                    </Popover>
                    <Text alignItems={'center'} flexDirection="row">
                      <Text ml={1}>
                        {intl.formatMessage({id: 'sortBywithcolon'})}
                      </Text>
                      <Text fontWeight={'bold'} pl={1}>
                        {intl.formatMessage({id: sortData.selectedSortType})}{' '}
                        {sortData.selectedSortType !== 'None' && (
                          <>
                            ({intl.formatMessage({
                              id: sortData.selectedSortValueAscending
                                ? 'ascending'
                                : 'descending',
                            })})
                          </>
                        )}
                      </Text>
                    </Text>
                  </Box>

                  <Box alignSelf={'center'}>
                    <Pressable
                      onPress={() => {
                        setIsShowFilterDrawer(true);
                      }}
                      marginTop={0.5}
                    >
                      <HStack
                        space={1}
                        paddingRight={componentState.filterCount ? 1 : 0}
                        borderRadius={'6px'}
                        alignItems={'center'}
                      >
                        <Tooltip title="Filter">
                          <Icon
                            color={Colors.Custom.Gray500}
                            as={Feather}
                            name={'filter'}
                            size="4"
                          />
                        </Tooltip>
                        {componentState.filterCount ? (
                          <Text color={Colors.Custom.PurpleColor}>
                            {componentState.filterCount
                              ? componentState.filterCount
                              : ''}
                          </Text>
                        ) : null}
                      </HStack>
                    </Pressable>
                  </Box>
                </HStack>
                <View my={2} />
                <ClinicalSectionFilterTabs
                  tabs={[
                    {
                      code: TaskTab.pending,
                      label: 'Pending',
                      showIconToRight: true,
                      icon: (
                        <>
                          <Badge
                            fontSize={12}
                            fontWeight={500}
                            _text={{
                              color: Colors.Custom.Gray700,
                            }}
                            borderWidth={1}
                            borderColor={Colors.Custom.Gray400}
                            alignSelf="center"
                            rounded="md"
                            justifyContent={'center'}
                            paddingX={2}
                            paddingY={1}
                            ml={1}
                          >
                            {componentState.pendingTaskCount}
                          </Badge>
                        </>
                      ),
                    },
                    {
                      code: TaskTab.completed,
                      label: 'Completed',
                      showIconToRight: true,
                      icon: (
                        <>
                          <Badge
                            fontSize={12}
                            fontWeight={500}
                            _text={{
                              color: Colors.Custom.Gray700,
                            }}
                            borderWidth={1}
                            borderColor={Colors.Custom.Gray400}
                            alignSelf="center"
                            rounded="md"
                            justifyContent={'center'}
                            paddingX={2}
                            paddingY={1}
                            ml={1}
                          >
                            {componentState.completedTaskCount}
                          </Badge>
                        </>
                      ),
                    },
                  ]}
                  selectedTabCode={componentState.currentTab}
                  onTabClick={(code: string) => {
                    handleTabClick(code);
                  }}
                  useEvenlyDistributedTabs
                />
                <View marginTop={1}>
                  <WriteInfoBackBanner
                    message={intl.formatMessage({
                      id: 'pendingTaskMsg',
                    })}
                  />
                </View>
              </>
            )}
          </View>
          {componentState.taskListLoading ? (
            <View width={'100%'}>
              <Skeleton my={2} />
              <Skeleton my={2} />
              <Skeleton my={2} />
              <Skeleton my={2} />
            </View>
          ) : (
            <>
              <FlatList
                data={componentState.taskList}
                keyExtractor={(item) => item.id}
                ListEmptyComponent={
                  <VStack
                    justifyContent={'center'}
                    height={height - 310}
                    alignItems={'center'}
                    padding={5}
                  >
                    <NoTaskSvg width={150} height={150} />
                    <Text
                      ml={2}
                      mt={4}
                      color={Colors.Custom.Gray500}
                      fontWeight={'bold'}
                    >
                      {intl.formatMessage({id: 'noTaskFound'})}
                    </Text>
                  </VStack>
                }
                height={height}
                renderItem={({item, index}) => {
                  const task = item;
                  return (
                    <Pressable
                      _hover={{
                        bg: Colors.FoldPixel.GRAY50,
                        borderRadius: 4,
                      }}
                      onPress={() =>
                        handleBottomViewActions(task, BottomViewAction.viewTask)
                      }
                      px={0.5}
                      flex={1}
                    >
                      <TaskListItemV2
                        isLoading={loadingData.taskIdWithAction === task.id}
                        task={task}
                        onAction={handleBottomViewActions}
                        getUserName={(uuid) =>
                          accountUserMap?.[uuid]?.name || ''
                        }
                      />
                    </Pressable>
                  );
                }}
                onEndReached={(data) => {
                  if (data?.distanceFromEnd < 70) {
                    handleEndReached();
                  }
                }}
                onEndReachedThreshold={0.1}
                ListFooterComponent={
                  componentState.paginationLoading ? (
                    <Spinner size={'lg'} my={2} />
                  ) : (
                    <></>
                  )
                }
                maxHeight={'100%'}
              />
            </>
          )}
        </View>
      )}
      {componentState.selectedTask && (
        <AddOrUpdateTask
          task={componentState.selectedTask}
          isVisible
          onMarkAsComplete={handleBottomViewActions}
          onComplete={() => {
            fetchUpdatedTask(componentState?.selectedTask?.id);
            setComponentState((prev) => ({
              ...prev,
              selectedTask: null,
            }));
            fetchTasks({
              offset: 0,
              filterData: filter,
              sortData: sortData,
            });
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
            updateTaskCounts({});
          }}
          onCancel={() => {
            setComponentState((prev) => ({
              ...prev,
              selectedTask: null,
            }));
          }}
          fetchAllTypeTask={() => {
            setComponentState((prev) => ({
              ...prev,
              taskListLoading: true,
            }));
            fetchTasks({
              offset: 0,
              filterData: filter,
              sortData: sortData,
            });
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
            updateTaskCounts({});
          }}
        />
      )}
      {componentState.isShowAddTaskDrawer && (
        <AddOrUpdateTask
          member={getNewTaskMember() as any}
          assignee={getNewTaskAssignee() as any}
          isVisible={componentState.isShowAddTaskDrawer}
          onComplete={(data) => {
            setComponentState((prev) => ({
              ...prev,
              isShowAddTaskDrawer: false,
              taskListLoading: true,
              offset: 0,
            }));
            fetchTasks({
              offset: 0,
              filterData: filter,
              sortData: sortData,
            });
            const eventBus = EventBus.getEventBusInstance();
            const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
            eventBus.broadcastEvent(code, {});
            updateTaskCounts({});
          }}
          onCancel={() => {
            setComponentState((prev) => ({
              ...prev,
              isShowAddTaskDrawer: false,
            }));
          }}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
          onNoteRedirect={() => {
            setComponentState((prev) => ({
              ...prev,
              isShowAddTaskDrawer: false,
            }));
          }}
        />
      )}
      {isShowFilterDrawer && (
        <FilterDrawer
          isShowFilterDrawer={isShowFilterDrawer}
          handleShowFilterDrawer={(isClearAll = false) => {
            setIsShowFilterDrawer(false);
            if (isClearAll) {
              const resetFilters:IFilter = {
                formData: {
                  selectedDueDate: [allStatusValue],
                  selectedDueDateUuid: [allStatusUuid],
                }
              }
              setFilter(resetFilters);
              setComponentState((prev) => {
                return {
                  ...prev,
                  filterCount: 0,
                  taskListLoading: true,
                  offset: 0
                };
              });
              fetchTasks({
                offset: 0,
                filterData: resetFilters,
                sortData: sortData,
              });
              updateTaskCounts({filterData: resetFilters});
            }
          }}
          handleSubmit={(filterData: any, totalCount: number | null) => {
            setFilter(filterData);
            setComponentState((prev) => ({
              ...prev,
              filterCount: totalCount,
              taskListLoading: true,
              offset: 0
            }));
            fetchTasks({
              offset: 0,
              filterData: filterData,
              sortData: sortData,
            });
            setIsShowFilterDrawer(false);
            updateTaskCounts({filterData: filterData});
          }}
          propertyDetails={propertyDetails}
          filterData={cloneDeep(filter)}
          showTaskStatus={false}
          showTaskEntityTypes={false}
        />
      )}
    </Drawer>
  );
};

export default InternalTaskDrawer;
