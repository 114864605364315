import {Popover} from 'antd';
import {
  Button,
  Divider,
  HStack,
  Image,
  Pressable,
  Text,
  Tooltip,
  View,
  VStack,
} from 'native-base';
import React, { useContext } from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {getInActiveContactError, PERSON_TYPES, PREFERRED_CHANNEL_CODE} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../../styles';
import {canCommunicateWithInactivePatient, getCurrentUserRoleCodes, isActiveContact, isConsentRequiredAndGiven, isMasterAccount} from '../../../../../../../utils/commonUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import ChatActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/ChatActionSvg';
import EmailActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import StartPreferredicon from '../../../../../../common/Svg/PersonActionSvgIcons/StartPreferredIcon';
import {isCurrentMenuLast} from '../../../../../../PersonOmniView/LeftContainer/PamiDetail/PamiDetailUtils';
import {PERSON_ACTION_CODES} from '../../../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {getActionObjListByPersonType} from '../../../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/PersonActionUtils';
import { MAIN_MENU_CODES, USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE } from '../../../../../../SideMenuBar/SideBarConst';
import {patientNotLoggedInError} from '../../../../../TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION} from '../../../../../UserAccess/UserAccessPermission';
import {CONTACT_ACTION_CODES} from '../../../../ContactsConst';
import {getQuickActionObjListByPersonType} from '../../../../ContactsUtils';
// import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import {styles} from './ActionsViewStyles';
import {getActionTypeImgSrc} from './ActionViewConst';
import { getOrgAbilites } from '../../../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import {isAccountConfigEnabled} from '../../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../../constants/AccountConfigConst';
import { TEAM_MEMBERS_ROLES_CODE } from '../../../../TeamMembers/AddEditUser/TeamMemberConst';
import { Context } from 'react-intl/src/components/injectIntl';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { isSendProviderRequestFormAllowed } from '../../../../../Forms/FormsUtils';
interface IActionsViewProps {
  onActionClick: (actionCode: string, data?: any) => void;
  record?: any;
  hideConvertToPatient?: boolean;
}
const ActionsView = (props: IActionsViewProps) => {
  // const initialFocusRef = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const appContext = useContext(Context);
  const context = useContext(CommonDataContext);
  const contactType = props?.record?.contactType?.code;
  const contactActiveStatus = isActiveContact(props?.record?.singleLeadData);
  const patientOrgAbilites = getOrgAbilites(FHIR_RESOURCE.PATIENT);
  const loggedInUserRole = getCurrentUserRoleCodes();
  const isMasterAccountFlag = isMasterAccount();
  const userSettings = context.userSettings;
  let quickActionList = getQuickActionObjListByPersonType(contactType,patientOrgAbilites);
  quickActionList = quickActionList.filter((list) => {
    return list.actionCode !== 'EDIT';
  });
  const isHideChat = contactType !== PERSON_TYPES.CUSTOMER;
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED) &&
                                        !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) &&
                                        !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS);;
  const shouldShowChat = () => {
    if (!isPatientMessagingEnabled) {
      return false
    } else if (!isHideChat && USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
      MAIN_MENU_CODES.INBOX
    )) {
      return true;
    } else {
      return false;
    }
  }

  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );

  if (props.hideConvertToPatient) {
    quickActionList = quickActionList.filter(
      (item) => item.actionCode !== PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  }
  const sendProviderRequestFormAllowed = !props?.record?.contactType?.isDefault && isSendProviderRequestFormAllowed(userSettings);

  let additionalList = getActionObjListByPersonType(contactType, patientOrgAbilites, [], sendProviderRequestFormAllowed);
  additionalList = additionalList.filter((list) => {
    return (
      list?.actionCode !== PERSON_ACTION_CODES.RESET_PASSWORD &&
      list?.actionCode !== PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  });
  quickActionList = quickActionList.concat(additionalList);


  if (!showAutomationTab) {
    quickActionList = quickActionList.filter(
      (item) => item.actionCode !== COMMON_ACTION_CODES.CREATE_AUTOMATION
    );
  }
  if (isMasterAccountFlag) {
    quickActionList = quickActionList.filter((list) => {
      return ![
        COMMON_ACTION_CODES.CREATE_TASK,
        COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
      ].includes(list?.actionCode);
    });
  }
  const isPatientLoggedIn = props?.record?.contactData?.additionalAttributes?.loggedInTime ?  true : false
  const consentCheck = isConsentRequiredAndGiven(props?.record?.contactData)

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const isEmailBtnDisabled = () => {
    return !props.record?.contactData?.email;
  };

  const isNumberBtnDisabled = () => {
    return !props.record?.contactData?.phoneNumber;
  };
  const getPreferredChannel = () => {
    return props.record?.contactData?.contactPreferredCommunicationChannel[0]
      ?.preferredCommunicationModeMlov?.code;
  };
  // eslint-disable-next-line @typescript-eslint/no-var-requires

  const currentStatus = props?.record?.contactData?.isActive;
  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {quickActionList.map((action: any, index: any) => {
        let actionCode = action?.actionCode;
        if (action.actionCode == CONTACT_ACTION_CODES.DEACTIVATE) {
          actionCode = currentStatus
            ? CONTACT_ACTION_CODES.DEACTIVATE
            : CONTACT_ACTION_CODES.ACTIVATE;
        }
        const iconType = getActionTypeImgSrc(actionCode);
        let displayString = action.displayString;
        if (action.actionCode == CONTACT_ACTION_CODES.DEACTIVATE) {
          displayString = currentStatus ? 'Set Inactive' : 'Activate';
        }

        return (
          <Pressable
            key={index}
            onPress={() => {
              props.onActionClick(action.actionCode);
              setIsOpen(!isOpen);
            }}
          >
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>

              {action?.actionCode ===  COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE && (
                <Feather name="printer" size={18} color={Colors.Custom.Gray500} />
              )}
              {action.actionCode !==  COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE && (
                <Image
                  mt={1}
                  resizeMode="cover"
                  style={{width: 16, height: 16}}
                  source={iconType}
                  tintColor={'black'}
                  alt='image'
                />
              )}
              <Text style={styles.mainMenuTxt}>
                <DisplayText size={'smMedium'} textLocalId={displayString} />
              </Text>
            </HStack>
            {isCurrentMenuLast(index, quickActionList) && (
              <Divider my={1} style={styles.dividerStyle} />
            )}
          </Pressable>
        );
      })}
    </VStack>
  );
  return (
    <View style={styles.iconView}>
      <Tooltip
        label={
          !canCommunicateWithInactivePatient(props?.record?.contactData) ? getInActiveContactError(props?.record)
          :
          isEmailBtnDisabled() ? 'Please Add Email Address' : 'Send Email'
        }
        placement="top"
      >
        <Button
          _hover={{bg: Colors.Custom.HoverBgColor}}
          _focus={{
            borderColor: 'black',
            backgroundColor: 'white'
          }}
          onPress={() => {
            if(canCommunicateWithInactivePatient(props?.record?.contactData)){
              // done this change intentionally on prod for palmetto consent warning issue
              // time: 1st March 2023

            if (consentCheck) {
              if (isEmailBtnDisabled()) {
                props.onActionClick('EDIT');
              } else {
                props.onActionClick('MAIL');
              }
            } else {
              if(contactType === PERSON_TYPES.CUSTOMER && !patientOrgAbilites?.canUpdate){
                return;
              }
              props.onActionClick('EDIT', COMMON_ACTION_CODES.CONSENT_WARNING);
            }
           }
          }}
          // disabled={isEmailBtnDisabled()}
          leftIcon={
            <View>
              <View style={[styles.svgIcons]}>
                <EmailActionSvg
                  isActive={isEmailBtnDisabled() ? false : true}
                />
              </View>
              {getPreferredChannel() === PREFERRED_CHANNEL_CODE.EMAIL && (
                <View style={[styles.preferredIcon]}>
                  <StartPreferredicon />
                </View>
              )}
            </View>
          }
          style={styles.btn}
        />
      </Tooltip>
      {/* <Button
        onPress={() => {
          props.onActionClick('DELETE');
        }}
        leftIcon={<Icon name="delete" size={17} style={[styles.icons]} />}
        style={styles.btn}
      /> */}

      {shouldShowChat() && <Tooltip label= {!contactActiveStatus ? getInActiveContactError(props?.record) : isPatientLoggedIn ? 'Chat' : patientNotLoggedInError} placement="top">
        <Button
          onPress={() => {
            if (contactActiveStatus) {
              if (isPatientLoggedIn) {
                props.onActionClick('CHAT');
              }
            }
          }}
          leftIcon={
            <View>
              <View style={[styles.svgIcons]}>
                <ChatActionSvg isActive={isPatientLoggedIn} />
              </View>
              {/* {getPreferredChannel() === PREFERRED_CHANNEL_CODE. && (
                <View style={[styles.preferredIcon]}>
                  <StartPreferredicon />
                </View>
              )} */}
            </View>
        }
          style={styles.btn}
        />
      </Tooltip>}

      <Tooltip
        label={
          !canCommunicateWithInactivePatient(props?.record?.contactData) ? getInActiveContactError(props?.record)
          :
          isNumberBtnDisabled() ? 'Please Add Number' : 'Send SMS'
        }
        placement="top"
      >
        <Button
          onPress={() => {
            if(canCommunicateWithInactivePatient(props?.record?.contactData)){
              // done this change intentionally on prod for palmetto consent warning issue
              // time: 1st March 2023
            if (consentCheck) {
              if (isNumberBtnDisabled()) {
                props.onActionClick('EDIT');
              } else {
                props.onActionClick('SMS');
              }
            } else {
              if(contactType === PERSON_TYPES.CUSTOMER && !patientOrgAbilites?.canUpdate){
                return;
              }
              props.onActionClick('EDIT', COMMON_ACTION_CODES.CONSENT_WARNING);
            }
           }
          }}
          // disabled={isNumberBtnDisabled()}
          leftIcon={
            <View>
              <View style={[styles.svgIcons]}>
              <SmsActionSvg
                  customStrokeColor={
                    isNumberBtnDisabled()
                      ? Colors.Custom.GrayDisable
                      : Colors.Custom.foldIconColor
                  }
                  isActive={isNumberBtnDisabled() ? false : true}
                />
              </View>
              {getPreferredChannel() === PREFERRED_CHANNEL_CODE.SMS && (
                <View style={[styles.preferredIcon]}>
                  <StartPreferredicon />
                </View>
              )}
            </View>
          }
          style={styles.btn}
          _focus={{
            backgroundColor: 'white',
            borderColor: 'black',
          }}
        />
      </Tooltip>

      <Popover
        key={props?.record?.id}
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'left'}
        trigger="click"
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Button
          onPress={() => {
            setIsOpen(true);
          }}
          style={styles.btn}
          _focus={{
            backgroundColor: 'white',
            borderColor: 'black',
          }}
        >
          <Feather
            name="more-vertical"
            style={[
              styles.icons,
              // {color: Colors.Custom.PrimaryBtnColor}
            ]}
          />
        </Button>
      </Popover>
    </View>
  );
};

export default ActionsView;
