import {Button, View, Text, Pressable,HStack,VStack} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import {MLOV_CATEGORY} from '../../../../constants';
import {Colors} from '../../../../styles';
import {getCareStudioMlovListByCategoryCode} from '../../../../utils/mlovUtils';
import DateRangePicker from '../../../RightSideContainer/Analytics/CommonComponents/DateRangePicker';
import EmployerDropDown from '../../../RightSideContainer/Analytics/CommonComponents/EmployerDropDown';
import {DateRange} from '../../../RightSideContainer/Analytics/CommonComponents/Interfaces';
import PatientContextDropDown from '../../../RightSideContainer/Analytics/CommonComponents/PatientContextDropDown';
import TaskPriorityDropDown from '../../../RightSideContainer/Analytics/CommonComponents/TaskPriorityDropDown';
import {styles} from '../../../RightSideContainer/Analytics/CommonComponents/style';
import {Employer} from '../../../RightSideContainer/BrazilCodeMR/pages/analytics/AnalyticsController';
import {FILTER_TYPE_CODES, PATIENT_CONTEXT_VALUE} from './constants';
import {ITaskAnalyticsFilterView} from './interface';
import LabelsDropDown from '../../../RightSideContainer/Analytics/CommonComponents/LabelsDropDown';
import TaskPoolDropDown from '../../../RightSideContainer/Analytics/CommonComponents/TaskPoolDropDown';
import LeftArrowClear from '../../Svg/LeftArrowClear';
import {getCheckToShowClearAll} from './commonUtils';
import { TimeUnitValues } from '../../../RightSideContainer/Analytics/CommonComponents/constants';
import SyncSvg from '../../Svg/SyncSvg';
import {useIntl} from 'react-intl';

const TaskAnalyticsFilterView = (props: ITaskAnalyticsFilterView) => {
  const {
    code,
    employerData,
    dateRange,
    selectedTaskPriorityList,
    taskLabelsIds,
    taskPoolsIds,
    patientContext,
    onFilterChange,
    isDrillDown
  } = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(
    MLOV_CATEGORY.TASK_PRIORITY
  )?.filter((mlovObj) => {
    return mlovObj.value !== 'STAT';
  });
  const showClearAll = getCheckToShowClearAll(props)
  return (
    <View justifyContent={'space-between'} style={[styles.headerContainer]}>
      <VStack
        flexDirection={'column'}
        style={
          props.isDrillDown
            ? {flexWrap: 'wrap', width: '100%', paddingLeft: 0}
            : {}
        }
      >
      <HStack flexWrap="wrap">
        <View
          style={{
            marginHorizontal: 12,
            display: props.isDrillDown ? 'none' : 'flex',
          }}
        >
          <Button
            backgroundColor={'white'}
            _hover={{bg: Colors.primary['100'] + '7a' || ''}}
            style={[styles.backButtonStyle, {marginLeft: 4}]}
            onPress={() => {
              navigate('/tasks/:tabName');
            }}
          >
            <AntIcon name="arrowleft" size={18} />
          </Button>
        </View>
        <EmployerDropDown
          employerData={employerData}
          onEmployerChange={(employer: Employer) =>
            onFilterChange(FILTER_TYPE_CODES.EMPLOYER, employer)
          }
          customStyles={{marginRight: 12, marginTop: 12}}
        ></EmployerDropDown>
        <DateRangePicker
          dateRange={dateRange}
          onDateRangeChange={(dateRange: DateRange) => {
            onFilterChange(FILTER_TYPE_CODES.DATE_RANGE, dateRange);
          }}
          isDateRangeRestricted={true}
          dateComponents={{
            dateRange: 1,
            timeUnit: TimeUnitValues.year,
          }}
        ></DateRangePicker>
        <TaskPriorityDropDown
          taskPriorityMlovs={taskPriorityMlovs}
          onPriorityChange={(selectedTaskPriorityList: string[]) => {
            onFilterChange(
              FILTER_TYPE_CODES.PRIORITY,
              selectedTaskPriorityList
            );
          }}
          selectedTaskPriorityList={selectedTaskPriorityList}
        ></TaskPriorityDropDown>
        <TaskPoolDropDown
          isDrillDown={isDrillDown}
          taskPoolsIds={taskPoolsIds}
          onTaskPoolChange={(taskPoolsIds: string[]) => {
            onFilterChange(FILTER_TYPE_CODES.TASK_POOL, taskPoolsIds);
          }}
        ></TaskPoolDropDown>
        <LabelsDropDown
          isDrillDown={isDrillDown}
          taskLabelsIds={taskLabelsIds}
          onTaskLabelsChange={(taskLabelsIds: string[]) => {
            onFilterChange(FILTER_TYPE_CODES.LABELS, taskLabelsIds);
          }}
        ></LabelsDropDown>
        <PatientContextDropDown
          patientContext={patientContext}
          onPatientContextChange={(
            patientContextValue: boolean | undefined
          ) => {
            onFilterChange(
              FILTER_TYPE_CODES.PATIENT_CONTEXT,
              patientContextValue
            );
          }}
          patientContextValues={PATIENT_CONTEXT_VALUE}
        ></PatientContextDropDown>
      {showClearAll && !props.isDrillDown && (
        <Pressable
          style={{
            marginTop: 12,
            marginRight: 12,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={() => {
            onFilterChange(FILTER_TYPE_CODES.RESET, []);
          }}
        >
          <LeftArrowClear />
          <Text color={'#8C5AE2'} style={{marginLeft: 4}}>
            Clear All
          </Text>
        </Pressable>
      )}
      </HStack>
        {isDrillDown ? (
          <></>
        ) : (
          <HStack style={{alignItems: 'center', marginLeft: 20, marginTop: 10}}>
            <SyncSvg />
            <Text
              style={{
                color: Colors.Custom.Gray400,
                marginLeft: 12
              }}
            > 
            {intl.formatMessage({id: 'analyticsSyncMessage'})}
            </Text>
          </HStack>
        )}
      </VStack>
    </View>
  );
};

export default TaskAnalyticsFilterView;
