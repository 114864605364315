import {HStack, Text, Tooltip} from 'native-base';
import React, {useState} from 'react';
import {Pressable} from 'react-native';
import {PERSON_TYPES, getInActiveContactError} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {canCommunicateWithInactivePatient, getUserId, getUserUUID, isActiveContact, isConsentRequiredAndGiven, isContactConsentGiven, isContactVerified, isMasterAccount} from '../../../utils/commonUtils';
import {ContactVerified} from '../ContactVerfied/ContactVerified';
import EmailDrawerCommon from '../EmailPopupView/EmailDrawerCommon';
import { EmailDrawerCommonV2 } from '../EmailDrawerCommonV2';
import InstantChatView from '../ChatDrawer/InstantChatView';
import {INewConversationResponse} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {useLazyQuery} from '@apollo/client';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';

export const Email = (props: {
  contactData: any;
  onActionPerformed: (actionCode: string) => void;
}) => {
  const [showEmailDrawer, setShowEmailDrawer] = useState(false);
  const [state, setState] = React.useState({
    selectedConversationData: {} as any,
  });
  const userId = getUserId();
  const userUuid = getUserUUID();
  const isMasterAccountFlag = isMasterAccount()

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );
  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: userUuid,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setState((prev) => {
          return {
            ...prev,
            selectedConversationData: selectedConversationData,
          };
        });
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  const contactActiveStatus = canCommunicateWithInactivePatient(props?.contactData);
  const consentCheck = isConsentRequiredAndGiven(props.contactData)

  return (
    <>
      <Tooltip
        placement="top"
        label={
          !contactActiveStatus
            ? getInActiveContactError(props?.contactData)
            : 'Email'
        }
      >
        <Pressable
          onPress={(event) => {
            if (isMasterAccountFlag) {
              return
            }
            if (contactActiveStatus) {
              event.stopPropagation();
              if (consentCheck) {
                setShowEmailDrawer(true);
              } else {
                props?.onActionPerformed(COMMON_ACTION_CODES.EDIT);
              }
            }
          }}
        >
          <HStack space={1} alignItems={'center'}>
            <Text
              size={'smMedium'}
              color={Colors.secondary['500']}
              style={{maxWidth: 200, flexWrap: 'wrap'}}
            >
              {props?.contactData?.email}
            </Text>
            {isContactVerified(
              props?.contactData?.personContact || [],
              'email'
            ) && (
              <>
                <ContactVerified label="Verified Email" />
              </>
            )}
          </HStack>
        </Pressable>
      </Tooltip>
      {showEmailDrawer && (
        <EmailDrawerCommonV2
          isOpen={showEmailDrawer}
          onClose={() => {
            setShowEmailDrawer(false);
          }}
          onEmailSent={({msgData}) => {
            handleOnCreateNewEmailConversation(msgData?.conversationId);
          }}
          contactIds={[props.contactData.id]}
        />
      )}

      {state?.selectedConversationData?.id && (
        <InstantChatView
          selectedConversation={state?.selectedConversationData}
          contactData={{} as any}
          isLoading={false}
          isDrawerVisible={true}
          isInstantChatView={true}
          instantChatFromNotifications={true}
          onActionPerformed={(actionCode: any, actionData?: any) => {
            if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
              setState((prev) => {
                return {
                  ...prev,
                  selectedConversationData: {} as any,
                };
              });
            } else if (actionCode === COMMON_ACTION_CODES.ITEM_CLICKED) {
              setState((prev) => {
                return {
                  ...prev,
                  selectedConversationData: actionData,
                };
              });
            }
          }}
        />
      )}
    </>
  );
};
