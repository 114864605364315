import { View, Text, Pressable } from 'react-native'
import React, { useState } from 'react'
import { Colors } from '../../../../styles'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces'
import Stack from '../../../common/LayoutComponents/Stack';
import { getPriorityIcon, getStatusBadge } from '../../../TaskCard/TaskCardHelper';
import { DisplayCardAvatar } from '../../../common/DisplayCard/DisplayCardAvatar';
import { GROUP_MEMBER_TYPE } from '../../../../constants';
import Close from '../../../../assets/svg/Close';
import { getActionAndReasonFromConflict } from '../../TaskModuleHelper';
import { IBulkOperationData, IConflict } from '../../TaskInterfaces';
import { Tooltip } from 'antd';
import WarningSvg from '../../../common/Svg/WarningSvg';

export interface IBulkTaskCardListViewProps {
  taskList: {task: ITask, conflicts: IConflict[]}[];
  operations?: IBulkOperationData;
  showConflictWithoutAction?: boolean;
  onRemove?: (task: ITask) => void;
}

const BulkTaskCardListView = (props: IBulkTaskCardListViewProps) => {
  const { taskList, operations, showConflictWithoutAction, onRemove } = props;

  const [tooltipId, setTooltipId] = useState<string>('');

  return (
    <Stack direction="column">
      {taskList.map((taskData) => {
        const {task, conflicts} = taskData;
        const priorityIcon = getPriorityIcon(task.priority, false, 0);
        const statusBadge = getStatusBadge(task.status);
        const conflictDisplayList = getActionAndReasonFromConflict(conflicts, task, operations);

        return (
          <Stack direction="column" space={2} style={{ marginBottom: 12, }}>
            <Stack
              key={`BulkTaskCardListView_${task.id}`}
              direction="column"
              space={8}
              style={{
                backgroundColor: Colors.Custom.Gray100,
                borderColor: showConflictWithoutAction && conflictDisplayList.length ? Colors.Custom.overdueTaskIconColor : Colors.Custom.Gray300,
                borderWidth: 0.5,
                borderRadius: 8,
                overflow: 'hidden',
              }}>
              <Stack direction="row"
                style={
                  conflictDisplayList.length > 0 ? {
                    borderBottomColor: Colors.Custom.Gray300,
                    borderBottomWidth: 0.5,
                    alignItems: 'center',
                    backgroundColor: Colors.Custom.White,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                  } : {
                    alignItems: 'center',
                    backgroundColor: Colors.Custom.White,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                  }
                }>
                <View style={{paddingRight: 1, flex: 0.5}}>
                  {priorityIcon}
                </View>
                <View style={{paddingRight: 1, flex: 7.5, flexGrow: 1}}>
                  <Text style={{color: Colors.Custom.Gray500, fontWeight: '400'}}>{task.title}</Text>
                </View>
                <View style={{paddingRight: 1, flex: 1.5}}>
                  <View style={{alignItems: 'center'}}>{statusBadge}</View>
                </View>
                <View style={{paddingRight: 1, flex: 1}}>
                  <Tooltip title={task.assigneeUser?.name} open={task.id === tooltipId}>
                    <div onMouseOver={() => setTooltipId(task.id)} onMouseLeave={() => setTooltipId('')} >
                      <DisplayCardAvatar
                        avatarStyle={{
                          avatarSize: '12',
                          height: 32,
                          width: 32,
                          textStyle: {
                            fontSize: 16
                          }
                        }}
                        isLetterAvatarShow
                        userData={{
                          userName: task.assigneeUser?.name,
                          userId: task.assigneeId || '',
                          userType: GROUP_MEMBER_TYPE.USER,
                        }}
                      />
                    </div>
                  </Tooltip>
                </View>
                <View style={{paddingRight: 1}}>
                  <Pressable onPress={() => {
                      onRemove?.(task);
                    }}>
                    <Close fill={Colors.Custom.Gray500} size={20} />
                  </Pressable>
                </View>
              </Stack>
              {!showConflictWithoutAction && conflictDisplayList.length > 0 && (
                <Stack direction="column" style={{ marginHorizontal: 8, marginBottom: 8,
                  paddingVertical: 8, borderColor: Colors.Custom.Gray300, borderWidth: 0.5, backgroundColor: Colors.Custom.White, borderRadius: 8 }}>
                  <Stack direction="row" style={{ borderBottomColor: Colors.Custom.Gray300, borderBottomWidth: 0.5, paddingHorizontal: 16, paddingVertical: 8 }}>
                    <View style={{flex: 4}}>
                      <Text style={{ fontWeight: '600', fontSize: 14, color: Colors.Custom.Gray600 }}>{'ACTION'}</Text>
                    </View>
                    <View style={{flex: 6}}>
                      <Text style={{ fontWeight: '600', fontSize: 14, color: Colors.Custom.Gray600 }}>{'CONFLICT'}</Text>
                    </View>
                  </Stack>
                  {conflictDisplayList.map((conflict, index) => {
                    const {action, reason} = conflict;
                    return (
                      <Stack
                        key={`BulkTaskConflict_${action}_${reason}_${task.id}`}
                        direction="row"
                        style={index === conflictDisplayList.length - 1 ? { paddingHorizontal: 16, paddingVertical: 8 } : { borderBottomColor: Colors.Custom.Gray300, borderBottomWidth: 0.5, paddingHorizontal: 16, paddingVertical: 8 }}>
                        <View style={{flex: 4}}>
                          <Text style={{ fontWeight: '400', fontSize: 14, color: Colors.Custom.Gray500 }}>{action}</Text>
                        </View>
                        <View style={{flex: 6}}>
                          <Text style={{ fontWeight: '400', fontSize: 14, color: Colors.Custom.Gray500 }}>{reason}</Text>
                        </View>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Stack>
            {showConflictWithoutAction && conflictDisplayList.length > 0 && (
              <Stack direction="column" style={{marginTop: 4}}>
                {conflictDisplayList.map((conflict, index) => {
                  const {action, reason} = conflict;
                  if (index > 0) {
                    return <></>;
                  }
                  return (
                    <Stack
                      key={`BulkTaskConflict_${action}_${reason}_${task.id}`}
                      direction="row"
                      space={8}
                      style={{ alignItems: 'center' }}>
                      <View>
                        <WarningSvg customStrokeColor={Colors.Custom.crossIconColor} strokeWidth={16} />
                      </View>
                      <View style={{flex: 1}}>
                        <Text style={{ fontWeight: '400', fontSize: 12, color: Colors.Custom.crossIconColor }}>{reason}</Text>
                      </View>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  )
}

export default BulkTaskCardListView
