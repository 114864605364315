import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles/Colors';
export const styles = StyleSheet.create({
  sideBarcontainer: {
    width: '20%',
  },
  container: {
    width: '88vw',
  },
  tagContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
    paddingRight: 7,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    margin: 4
  },
});

export const InputStyle = {
  placeHolderFontsize: 14,
  placeHolderFontWeight: 500,
  placeholderTextColor: Colors.Custom.PlaceholderColor,
}