import { Select, Spin, Tooltip } from 'antd';
import { forEach, includes, some } from 'lodash';
import { Button, FormControl, HStack, Icon, Pressable, Text, View, VStack } from 'native-base';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../styles';
import {
    IParticipantSearch
} from '../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import { DisplayText } from '../DisplayText/DisplayText';
import { poolData, poolInfo } from './interfaces';
const { Option, OptGroup } = Select;

interface ITaskPoolSearchAndSelect {
    userPools?: poolInfo[];
    otherPools?: poolInfo[];
    loading?: boolean;
    filterOption?: any;
    assigneeId?: string;
    defaultValue?: IParticipantSearch[];
    placeholder?: string;
    isDisabled?: boolean;
    showError?: boolean;
    isInvalid?: boolean;
    isRequired?: boolean;
    label?: string;
    onChange: (data?: any, isAssigneeinPool?: boolean) => void;
    rightLabelTitle?: string;
    onRightLabelClick?: () => void;
    value?: any;
    addNewLabel?: string;
    border?: boolean;
    customStyle?: any;
    onFocus?: any;
    onBlur?: any;
    allowClear?: boolean;
    showCurrentUserAtTop?: boolean;
    labelInfo?: string;
    labelColor?: string;
    errors?: any;
    isSidecarTaskLabelSelect?: boolean;
    className?: any;
}

const TaskPoolSearchAndSelect = (props: ITaskPoolSearchAndSelect) => {
    const { userPools, otherPools, placeholder, defaultValue, allowClear, isDisabled, label, customStyle } = props;
    const intl = useIntl();
    const [searchText, setSearchText] = useState('')

    const getOptions = (): any[] => {
        const options: any[] = [];
        if (userPools?.length) {
            options.push({
                label: (
                    <Text fontSize="xs" color="gray.400">
                        My task pools
                    </Text>
                ),
                key: 'user pools',
                options: userPools,
            });
        }
        if (otherPools?.length) {
            options.push({
                label: (
                    <Text fontSize="xs" color="gray.400">
                        Other task pools
                    </Text>
                ),
                key: 'other pools',
                options: otherPools,
            });
        }
        return options;
    };

    return (
        <>
            <View>
                <FormControl
                    isRequired={props.isRequired}
                    isInvalid={props.showError || props.isInvalid}
                    isDisabled={isDisabled}
                    marginBottom={customStyle?.marginBottom || 0}
                >
                    {label && (
                        <HStack
                            space={1}
                            marginBottom={customStyle?.marginBottom || 0}
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControl.Label
                                marginTop={props.rightLabelTitle ? 2 : 0}
                                marginLeft={0}
                                marginBottom={0}
                            >
                                <HStack>
                                    <DisplayText
                                        size={'smMedium'}
                                        extraStyles={{
                                            color: props.labelColor || Colors.Custom.Gray700,
                                            fontWeight: 500,
                                            fontSize: 14,
                                        }}
                                        textLocalId={label || ''}
                                    />
                                    {props?.labelInfo && props?.labelInfo?.length > 0 ? (
                                        <Tooltip
                                            title={props?.labelInfo}
                                            placement={'top'}
                                            destroyTooltipOnHide={true}
                                        >
                                            <Icon
                                                mt={1}
                                                mx={2}
                                                as={AntIcon}
                                                color={Colors.Custom.Gray500}
                                                name="infocirlceo"
                                                size="smMedium"
                                            />
                                        </Tooltip>
                                    ) : null}
                                </HStack>
                            </FormControl.Label>
                        </HStack>
                    )}

                    <Select
                        className={props?.className}
                        style={{ 
                          borderRadius: customStyle?.borderRadius || 8, 
                          height: customStyle?.height || 40,
                          backgroundColor: customStyle?.backgroundColor || 'none',
                         }}
                        bordered={props?.border}
                        dropdownStyle={{
                            borderRadius: customStyle?.borderRadius || 8,
                        }}
                        showSearch={true}
                        size={'large'}
                        allowClear={allowClear ?? true}
                        labelInValue={true}
                        filterOption={props?.filterOption}
                        disabled={isDisabled}
                        value={props.value ? props.value : defaultValue}
                        defaultValue={defaultValue}
                        onChange={(value, data: any) => {
                          const isAssigneeInPool = data?.userPoolUsers?.some((user: any) => {
                            return user.userId === props?.assigneeId
                          });
                          props.onChange(data, isAssigneeInPool)
                        }}
                        placeholder={placeholder}
                        onSearch={(searchText: string) => { setSearchText(searchText) }}
                        notFoundContent={
                          <View>
                            {
                              userPools?.length === 0 && otherPools?.length === 0 ? (
                                <Text fontSize={12} fontWeight={400} color={Colors.Custom.Gray600}>{intl.formatMessage({ id: 'noPoolsExist' })}</Text>
                              ) :
                              searchText && (
                                <Text fontSize={12} fontWeight={400} color={Colors.Custom.Gray600}>{intl.formatMessage({ id: 'noMatchToSearch' })}</Text>
                              )
                            }
                                </View>
                        }
                    >
                        {getOptions().map((group) => (
                            <OptGroup key={group.key} label={group.label}>
                                {group.options.map((option: any, index: any) => (
                                    <Option
                                        key={option?.id}
                                        value={option?.name}
                                        label={option?.name}
                                        userPoolUsers={option?.userPoolUsers}
                                        type={option?.type}
                                    > {option?.name}
                                    </Option>

                                ))}
                            </OptGroup>
                        ))}
                    </Select>
                    <View>
                        {props?.errors?.patient && (
                            <Text
                                color={'error.500'}
                                fontWeight='500'
                                fontSize={'xs'}
                            >
                                {props?.errors?.patient}
                            </Text>
                        )}
                    </View>
                </FormControl>
            </View>
        </>
    );
};

export default TaskPoolSearchAndSelect;
