import { useQuery } from '@apollo/client';
import { Select as AntSelect, Checkbox, DatePicker, notification } from 'antd';
import moment, { Moment } from 'moment';
import {
  Flex,
  HStack, Input,
  Modal,
  Pressable,
  Select,
  Text,
  VStack,
  View,
  useMediaQuery
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../../../constants';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { FlowType } from '../../../../../../context/WorkflowContext';
import { getNaturalLanguageParse } from '../../../../../../services/Billing/BillingServices';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import { ITimezone } from '../../../../../../services/Location/interfaces';
import { Colors } from '../../../../../../styles';
import { getAccountUUID, isStringArrayElementDiff } from '../../../../../../utils/commonUtils';
import { getMlovIdFromCode } from '../../../../../../utils/mlovUtils';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import CircleIconView, {
  NodeCategory
} from '../../../../../common/CircleIconView/CircleIconView';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { TimezoneSelect } from '../../../../../common/TimezoneSelect/TimezoneSelect';
import TitleSubtitleView from '../../../../../common/TitleSubtitleView/TitleSubtitleView';
import { getMatchingNode, getWorkflowBasePath } from '../../../workflowUtils';
import { WorkflowTag } from '../../AddOrUpdateWorkflow/WorkflowTag';
import { phoneTreeAutoSaveInDraft } from '../WorkflowUtils';
import { workflowTriggerStyle as styles } from './../../Styles';
import './../../Styles.css';
import { IFrequencyData } from './../IWorkflow';
import { IWorkflowTrigger } from './interfaces';
const { Option } = AntSelect;
const { width, height } = Dimensions.get('window');
const window: any = global.window;
const SpeechRecognition = window?.SpeechRecognition || window?.webkitSpeechRecognition;
const microphone = SpeechRecognition ? new SpeechRecognition() : { isNotSupported: true };

microphone.continuous = true;
microphone.interimResults = true;
microphone.lang = "en-US";

const WorkflowTrigger = (props: IWorkflowTrigger) => {
  const [showAlertData, setShowAlertData] = useState({ isShow: false, message: 'Error' });
  const accountUUID = getAccountUUID();
  const [defaultTimezone, setDefaultTimezone] = useState<string | undefined>(props.timezone)
  const getTimezoneOfAccountAndLocationsQuery = useQuery(
    UserPracticeLocationQueries.GetAccountLevelTimezone,
    {
      variables: {
        accountUuid: accountUUID
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        let defaultTimezone = props.timezone || 'America/New_York'
        if (res?.account_timezones?.length && res?.account_timezones[0]?.timezone?.timezone) {
          defaultTimezone = (res?.account_timezones[0]?.timezone?.timezone)
        }
        setDefaultTimezone(defaultTimezone)
      },
    }
  );
  const [isTagListEdited, setIsTagListEdited] = useState(false);
  const setIsTriggerScreenValid = props.setIsTriggerScreenValid;
  const frequency = props.frequency;
  const triggerTypeMap = props.triggerTypeMap;
  const triggerNodeList = props.triggerNodeList || [];
  const setFrequency = props.setFrequency;
  const weekDay = props.weekDay;
  const setWeekDay = props.setWeekDay;
  const date = props.date;
  const setDate = props.setDate;
  const timeFormat = 'HH:mm';
  const time = props.time
    ? moment(props.time, timeFormat)
    : moment('06:00', timeFormat);
  const setTime = props.setTime;
  const setTimezone = props.setTimezone;
  const timezone = props.timezone;
  const triggerType = props.triggerType;
  const setTriggerType = props.setTriggerType;
  const workflowName = props.workflowName;
  const setStepMachineName = props.setStepMachineName;
  const [isShowErrorColor, setIsShowErrorColor] = useState(props.isShowErrorColor);
  const triggerFrequencyList = props.triggerFrequencyList;
  const [showModal, setShowModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const flowType = props?.flowType || '';
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const IvrUsageTypeList = mlovData['IvrUsageType'] || [];
  const inboundIvrTypeId = getMlovIdFromCode(IvrUsageTypeList, 'INCOMING_CALLS');
  const [isSaved, setIsSaved] = useState(false);
  const triggerFrequencyCode: { [key: string]: IFrequencyData } = {};
  const navigate = useNavigate();
  const intl = useIntl()
  triggerFrequencyList.forEach((frequencyData) => {
    triggerFrequencyCode[frequencyData.value] = frequencyData;
  });

  // triggerType="A_SPECIFIC_TIME"
  const filterConditionList = triggerNodeList.filter((node) => {
    if (node.isDeprecated && props.triggerCondition !== node.type) {
      return false;
    }
    return node.triggerType;
  });
  const conditionList = filterConditionList.map((node) => {
    return {
      key: node.type + triggerType,
      value: node.type,
      label: node.displayName,
      iconInfo: node?.iconInfo,
      description: node.description,
      allowedFrequencyList: node.allowedFrequencyList,
    };
  });
  const validExistingCondition = conditionList.find((node) => {
    return node.value === props.triggerCondition;
  });
  const [triggerCondition, setTriggerCondition] = useState(
    validExistingCondition ? validExistingCondition : undefined
  );


  const currentTriggerFrequencyList =
    (triggerCondition && triggerCondition?.allowedFrequencyList?.length)
      ? triggerFrequencyList?.filter((frequency) => (triggerCondition.allowedFrequencyList?.indexOf(frequency.value) !== -1))
      : triggerFrequencyList;

  const [componentState, setComponentState] = useState({
    isNaturalLanguage: false,
    naturalLanguage: '',
    isRecording: false,
    isSaveDraft: phoneTreeAutoSaveInDraft,
  });



  const getTriggerFormValidation = () => {
    if (workflowName && componentState.isNaturalLanguage && props.workflowDescription) {
      return true;
    }
    if (!triggerCondition?.value) {
      return false;
    }
    if (
      (triggerTypeMap[triggerType].isFrequencyAllowed && !frequency) ||
      !workflowName
    ) {
      return false;
    }
    if (triggerTypeMap[triggerType].isFrequencyAllowed) {
      const frequencyData = triggerFrequencyCode[frequency];
      if (frequencyData.isDateRequired && !date) {
        return false;
      }
      if (frequencyData.isTimeRequired && !time) {
        return false;
      }
      if (frequencyData.isDayRequired && !weekDay) {
        return false;
      }
    }
    return true;
  };
  const [searchText, setSearchText] = useState('')
  const isValid = getTriggerFormValidation();
  setIsTriggerScreenValid(isValid);
  const pageSize = 1000;
  const totalPage = Math.ceil(conditionList.length / pageSize);
  const getConditionSelectBox = () => {

    return (
      <>
        <HStack justifyItems={'center'} margin={5}>
          <Text
            style={[
              styles.workflowNameTextLabel,
              {
                textAlign: 'left',
                width: '100%',
                marginVertical: 10,
                marginTop: 10,
              },
            ]}
          >
            {flowType === FlowType.ivr ? 'On call first action' : 'Triggers'}
          </Text>
          <Input value={searchText} onChangeText={(text) => {
            setSearchText(text)
          }} collapsable={true} marginLeft={5} width={'50%'} type={'text'} placeholder='Search Triggers'></Input>
        </HStack>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            overflow: 'scroll',
            width: '100%',
            height: '500px'
          }}
        >
          {conditionList
            .filter((d, index) => {
              if (searchText) {
                return (d.label || '').toLowerCase()?.indexOf(searchText.toLowerCase()) != -1
              }
              return true
            })
            .map((data, index) => {
              return (
                <Pressable
                  onPress={() => {
                    setTriggerCondition(data || '');
                    const triggerTypeIs = filterConditionList.filter(
                      (el) => el.type === data?.value
                    );
                    setTriggerType(triggerTypeIs[0].triggerType);
                    if (triggerTypeIs[0].defaultTriggerTime != undefined) {
                      setTime(triggerTypeIs[0].defaultTriggerTime)
                    }
                    if (triggerTypeIs[0].defaultTriggerFrequency != undefined) {
                      setFrequency(triggerTypeIs[0].defaultTriggerFrequency)
                    }
                  }}
                  style={{
                    padding: 10,
                    borderWidth: 2,
                    borderColor: Colors.Custom.Gray200,
                    backgroundColor:
                      data.value == triggerCondition?.value
                        ? Colors.primary[500]
                        : '#ffffff',
                    borderRadius: 5,
                    width: '31%',
                    margin: '1%',
                    maxHeight: '35%'
                  }}
                  key={index + 'triggers'}
                >
                  <View
                    style={{
                      backgroundColor: Colors.primary[100],
                      width: 25,
                      height: 25,
                      borderRadius: 25,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircleIconView
                      iconInfo={data?.iconInfo}
                      nodeCategory={NodeCategory.moment}
                      borderColor={Colors.primary[100]}
                      backgroundColor={Colors.primary[100]}
                    // backgroundColor={Colors.primary[300]}
                    ></CircleIconView>
                  </View>
                  <View style={{ marginTop: 20 }}>
                    <Text
                      size={'smMedium'}
                      style={{ fontSize: 15 }}
                      color={
                        data.value == triggerCondition?.value ? '#ffffff' : '#000000'
                      }
                    >
                      {data.label}
                    </Text>
                    <Text
                      size={'smNormal'}
                      color={
                        data.value == triggerCondition?.value ? '#ffffff' : '#000000'
                      }
                    >
                      {data?.description || ' '}
                    </Text>
                  </View>
                </Pressable>
              );
            })}
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 15,
          }}
        >
          <Pressable disabled={currentPage <= 0} onPress={() => {
            setCurrentPage(currentPage - 1);
          }}><AntIcon name="left" size={12} color={currentPage > 0 ? Colors.primary[500] : Colors.primary[100]} />
          </Pressable>
          {Array.from(Array(totalPage)).map(
            (d, index) => {
              return (
                <Pressable
                  style={{
                    marginLeft: 20,
                    backgroundColor: currentPage == index ? Colors.primary[500] : Colors.primary[100],
                    padding: 5,
                    width: 32,
                    height: 32,
                    borderRadius: 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    setCurrentPage(index);
                  }}
                >
                  <Text color={'#ffffff'} size="smRegular">
                    {index + 1}
                  </Text>
                </Pressable>
              );
            }
          )}
          <Pressable disabled={currentPage >= (totalPage - 1)} onPress={() => {
            setCurrentPage(currentPage + 1);
          }}>
            <AntIcon
              name="right"
              style={{ marginLeft: 20 }}
              size={12}
              color={currentPage < (totalPage - 1) ? Colors.primary[500] : Colors.primary[100]}
            />
          </Pressable>
        </View>
      </>
    );
    return (
      <Flex style={styles.conditionView}>
        {/* <span>{triggerTypeMap[triggerType].dropDownLable}</span> */}
        <Select
          isDisabled={!!props.workflowId}
          borderColor={
            isShowErrorColor && !triggerCondition?.value ? 'red.600' : '#ddd5d5'
          }
          key={triggerCondition?.value}
          minWidth="340"
          maxWidth="340"
          selectedValue={triggerCondition?.value}
          marginTop={1}
          onValueChange={(value) => {
            const validExistingCondition = conditionList.find((node) => {
              return node.value === value;
            });
            setTriggerCondition(validExistingCondition);
            const triggerTypeIs = filterConditionList.filter(
              (el) => el.type === value
            );
            setTriggerType(triggerTypeIs[0].triggerType);
          }}
          accessibilityLabel="Select condition"
          placeholder="Select condition"
        >
          {conditionList.map((data) => {
            return (
              <Select.Item
                key={data.key}
                label={data.label}
                value={data.value}
              />
            );
          })}
        </Select>
      </Flex>
    );
  };

  // const getTriggerTabElement = () => {
  //   return <Flex style={styles.conditionView} >
  //     {/* <span>Select Meet Condition</span> */}
  //     <Select
  //       isDisabled={true}
  //       borderColor={isShowErrorColor && !triggerCondition ? 'red.600' : '#ddd5d5'}
  //       key={triggerCondition}
  //       minWidth='340'
  //       maxWidth='340'
  //       selectedValue={triggerType}
  //       marginTop={1}
  //       onValueChange={(value) => {
  //         setTriggerType(value);
  //       }}
  //       accessibilityLabel='Select Meet Condition'
  //       placeholder='Select condition'>
  //       {Object.keys(triggerTypeMap).map((typeCode) => {
  //         return (
  //           <Select.Item
  //             // key={typeCode}
  //             label={typeCode.split("_").join(" ")}
  //             value={typeCode}
  //           />
  //         );
  //       })}
  //     </Select>
  //   </Flex>
  // }

  const getStepMachineNameElement = () => {
    return (
      <VStack style={styles.justifyCenter}>
        <Text style={styles.workflowNameTextLabel}>Name</Text>
        <Input
          _focus={{ borderColor: '#D0D5DD' }}
          style={styles.workflowNameInput}
          borderColor={
            isShowErrorColor && !workflowName ? 'red.600' : '#ddd5d5'
          }
          placeholder="Workflow Name"
          value={workflowName}
          onChangeText={(value) => {
            setStepMachineName(value || '');
          }}
        />
      </VStack>
    );
  };

  const convertAndSetDate = (value: moment.Moment | null | undefined) => {
    const dateFormat = triggerFrequencyCode[frequency]?.dateFormat;
    let newDate = '';
    if (value) {
      newDate = value.format(dateFormat);
    }
    if (newDate != props.date) {
      setDate(newDate || '');
    }
  };



  const getDateElement = () => {
    const frequencyData = triggerFrequencyCode[frequency];
    if (!frequencyData || !frequencyData.isDateRequired) {
      return;
    }
    const dateFormat = triggerFrequencyCode[frequency].dateFormat;
    let currentDate: Moment | undefined = moment(date, dateFormat);
    if (currentDate) {
      currentDate = currentDate.isValid() ? currentDate : undefined;
    }
    convertAndSetDate(currentDate);
    const borderColor =
      isShowErrorColor && !currentDate ? 'red.600' : '#ddd5d5';
    return (
      <Flex mt={15} height="container" margin="0">
        {/* <span>Date</span> */}
        <Text style={styles.workflowNameTextLabel}>Start Date</Text>
        <View width={340} borderColor={borderColor} borderWidth="1">
          <DatePicker
            style={{
              color: borderColor,
            }}
            defaultValue={currentDate}
            disabled={!frequency}
            format={dateFormat}
            className="widht-500"
            onChange={(value) => {
              convertAndSetDate(value);
            }}
          ></DatePicker>
        </View>
      </Flex>
    );
  };
  const convertAndSetTime = (value: moment.Moment | null | undefined) => {
    let newTime = '';
    if (value) {
      newTime = value.format(timeFormat);
    }
    if (newTime != props.time) {
      setTime(newTime || '');
    }
  };

  const getDateTimeElement = () => {
    const frequencyData = triggerFrequencyCode[frequency];
    if (!frequencyData || !frequencyData.isTimeRequired) {
      return;
    }
    let currentTime = time ? moment(time, timeFormat) : undefined;
    if (currentTime) {
      currentTime = currentTime.isValid() ? currentTime : undefined;
    }
    convertAndSetTime(currentTime);
    const borderColor =
      isShowErrorColor && !currentTime ? 'red.600' : '#ddd5d5';
    return (
      <Flex mt={15} style={{ width: '100%' }} height="container" mb="5">
        {/* <span>Time</span> */}
        <HStack flex={1}>
          <VStack flex={1}>
            <Text style={styles.workflowNameTextLabel}>Start Time</Text>
            <View borderColor={borderColor} borderWidth="0">
              <DatePicker.TimePicker
                format={timeFormat}
                defaultValue={currentTime}
                disabled={!frequency}
                className="widht-100"
                onChange={(value) => {
                  convertAndSetTime(value);
                }}
              ></DatePicker.TimePicker>

            </View>
          </VStack>
          {defaultTimezone &&
            <VStack flex={1} marginLeft={5}>
              <Text style={styles.workflowNameTextLabel}>Timezone</Text>
              <TimezoneSelect
                selectedTimezoneValue={timezone || defaultTimezone}
                className="appointment-timezone"
                showLabel={false}
                showSelectBorder={true}
                selectColor="transparent"
                isRequired={true}
                onChange={(timezone?: ITimezone) => {
                  setTimezone(timezone?.timezone);
                }}
              />
            </VStack>}
        </HStack>
      </Flex>
    );
  };

  const setDefaultWeekDay = () => {
    setWeekDay('Monday');
    return weekDay;
  };

  const getDayElement = () => {
    const frequencyData = triggerFrequencyCode[frequency];
    if (!frequencyData || !frequencyData.isDayRequired) {
      return <></>;
    }
    return (
      <Flex
        height="container"
        margin="0"
        style={{ width: '100%', marginTop: 15 }}
      >
        {/* <span>Day</span> */}
        <Text style={styles.workflowNameTextLabel}>Day</Text>
        <AntSelect
          allowClear={false}
          onClear={() => {
            setWeekDay('');
          }}
          value={weekDay ? weekDay : setDefaultWeekDay()}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            setWeekDay(itemValue || '');
          }}
        >
          {[
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ].map((value: any) => {
            return (
              <Option value={value} key={value} title={value}>{value}</Option>
            );
          })}

        </AntSelect>
        {/* <Select
          borderColor={isShowErrorColor && !weekDay ? 'red.600' : '#ddd5d5'}
          minWidth="340"
          selectedValue={weekDay ? weekDay : setDefaultWeekDay()}
          marginTop={1}
          onValueChange={(value) => {
            setWeekDay(value || '');
          }}
          accessibilityLabel="Select Day"
          placeholder="Select Day"
        >
          {[
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ].map((value) => {
            return <Select.Item key={value} label={value} value={value} />;
          })}
        </Select> */}
      </Flex>
    );
  };

  const getTagListElement = () => {
    return <></>;
    let tagList: Array<string> =
      isTagListEdited || props.workflowId
        ? props.tagList.map((value) => {
          return value;
        })
        : [];
    if (props.workflowId) {
      tagList = props.tagList;
    } else if (!isTagListEdited) {
      tagList = [];
      if (triggerCondition) {
        let triggerConditionValue = '';
        conditionList.some((data) => {
          if (data.value === triggerCondition?.value) {
            triggerConditionValue = data.label;
          }
        });
        tagList.push(triggerConditionValue);
      }
      if (props.triggerType) {
        tagList.push(triggerTypeMap[props.triggerType].displayName);
      }
    }
    if (isStringArrayElementDiff(tagList, props.tagList)) {
      props.setTagList(tagList);
    }
    return (
      <Flex style={styles.conditionView} width={340}>
        <span>Tags</span>
        <WorkflowTag
          isAddNew={true}
          placeholder="Add Tag"
          tagList={tagList}
          setTagList={(newTagList: Array<string>) => {
            setIsTagListEdited(true);
            props.setTagList(newTagList);
          }}
        ></WorkflowTag>
      </Flex>
    );
  };

  const getDefaultFrequency = () => {
    let frequency = '';
    currentTriggerFrequencyList?.map((data) => {
      if (data.value === 'ON_NEED') {
        frequency = data.value;
        setFrequency(data.value || '');
      }
    });
    if (!frequency) {
      frequency = currentTriggerFrequencyList?.[0]?.value;
      setFrequency(frequency || '');
    }
    return frequency;
  };

  const getFrequencyElement = () => {
    return (
      <Flex
        style={[styles.conditionView, { marginTop: 15 }]}
        mx={0}
        height="container"
      >
        {/* <span>Frequency</span> */}
        <Text style={styles.workflowNameTextLabel}>Frequency</Text>
        <AntSelect
          allowClear={false}
          onClear={() => {
            setFrequency('');
          }}
          value={frequency ? frequency : getDefaultFrequency()}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            setFrequency(itemValue || '');
          }}
        >
          {currentTriggerFrequencyList.map((data: any) => {
            return (
              <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
            );
          })}

        </AntSelect>

        {/* <Select
          minWidth="340"
          borderColor={isShowErrorColor && !frequency ? 'red.600' : '#ddd5d5'}
          selectedValue={frequency ? frequency : getDefaultFrequency()}
          marginTop={1}
          onValueChange={(itemValue) => {
            setFrequency(itemValue || '');
          }}
          accessibilityLabel="Select Frequency"
          placeholder="Select Frequency"
        >
          {triggerFrequencyList.map((data) => {
            return (
              <Select.Item
                key={data.value}
                label={data.displayName}
                value={data.value}
              />
            );
          })}
        </Select> */}
      </Flex>
    );
  };



  const getTriggerNodeFromParseResult = (parseResultList: any) => {
    if (!parseResultList || !parseResultList.length) {
      return []
    }
    return getMatchingNode(parseResultList[0], triggerNodeList, ['entity'])
  }

  const [isLoading, setIsLoading] = useState(false)
  const handleSave = () => {
    if (componentState.isNaturalLanguage) {
      setIsLoading(true);
      getNaturalLanguageParse(props.workflowDescription || '').then((resultList: any) => {
        resultList = resultList || [];
        const nodeList = getTriggerNodeFromParseResult(resultList)
        const naturalLanguageParseNodeList = resultList.filter((data: any, index: any) => { return !!index })
        if (nodeList && nodeList.length > 0) {
          setTriggerType(nodeList[0].triggerType);
          setTriggerCondition(nodeList[0]?.type || '');
          props.setTriggerCondition(nodeList[0]?.type, naturalLanguageParseNodeList, componentState.isSaveDraft);
          setShowModal(false);
          setIsSaved(true);
        } else {
          notification.error({
            message: 'Please use manual trigger to create your automation or try again later with natural language'
          });

          setComponentState((prev: any) => {
            return { ...prev, isNaturalLanguage: false };
          })
        }
      }).finally(() => {
        setIsLoading(false);
      }).catch(() => {
        notification.error({
          message: 'Please use manual trigger to create your automation or try again later with natural language'
        });
      })
    } else {
      isValid ? setShowModal(false) : null;
      isValid ? setIsSaved(true) : null;
      if (isValid) {
        props.setTriggerCondition(triggerCondition?.value, [], componentState.isSaveDraft);
      }
    }
    setIsShowErrorColor(true)
  };
  const startRecordController = (isRecording: boolean) => {
    if (isRecording && !microphone.isNotSupported) {
      microphone.start();
      microphone.onend = () => {
        microphone.start();
      };
    } else {
      microphone.stop();
      microphone.onend = () => {
        // microphone.onend
      };
    }

    microphone.onstart = () => {
      // microphone.onstart
    };

    microphone.onresult = (event: any) => {
      const recordingResult = Array.from(event.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join("");

      // setNote(recordingResult);
      props.setWorkflowDescription(props.workflowDescription + ' ' + recordingResult);
      microphone.onerror = (event: any) => {
        // microphone.onerror
      };
    };
  };

  useEffect(() => {
    startRecordController(componentState.isRecording && showModal);
    return () => {
      startRecordController(false)
    }
  }, [componentState.isRecording, showModal]);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const getCloseAndContinue = () => {
    return (
      <VStack my={8} flexDirection={'row-reverse'}>
        <HStack space={3}>
          {!isSafari && <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: () => {
                const navigationPath = getWorkflowBasePath(flowType);
                navigate(-1);
              },
            }}
            customProps={{
              btnText: (
                <DisplayText
                  textLocalId={'cancel'}
                  size={'smBold'}
                  extraStyles={{
                    color: Colors.Custom.mainSecondaryBrown,
                  }}
                />
              ),
              withRightBorder: false,
            }}
          ></FoldButton>}
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                handleSave();
              },
            }}
            customProps={{
              btnText: (
                <DisplayText
                  textLocalId={'Next'}
                  size={'smBold'}
                  extraStyles={{
                    color: Colors.Custom.mainPrimaryPurple,
                  }}
                />
              ),
              withRightBorder: false,
            }}
          ></FoldButton>
        </HStack>
      </VStack>
    );
  };

  const renderTabs = () => {
    return (
      <HStack
        style={{
          width: '100%',
          marginTop: 30,
          borderBottomWidth: 1,
          borderBottomColor: Colors.Custom.Gray200,
        }}
      >
        <View
          style={{
            borderBottomWidth: 2,
            borderBottomColor: Colors.secondary[500],
            marginRight: 10,
            paddingVertical: 10,
          }}
        >
          <Text size={'smMedium'} color={Colors.primary[500]}>
            Automation Library
          </Text>
        </View>
        <View>
          <Text
            size={'smNormal'}
            style={{ marginHorizontal: 0, paddingVertical: 10 }}
            color={Colors.primary[500]}
          >
            Saved Workflows
          </Text>
        </View>
      </HStack>
    );
  };

  const renderTabTypes = () => {
    return (
      <View style={{ marginTop: 20, flexDirection: 'row', flexWrap: 'wrap' }}>
        {[
          'Basic Information',
          'Medical History',
          'Medical History',
          'Basic Information',
          'Personal Details',
          'Medical History',
          'Personal Details',
          'Medical History',
        ].map((type) => {
          return (
            <View
              style={{
                marginRight: 10,
                marginVertical: 5,
                borderRadius: 15,
                padding: 5,
                paddingHorizontal: 10,
                borderWidth: 1,
                borderColor: Colors.secondary[500],
              }}
            >
              <Text size={'smNormal'} color={Colors.secondary[500]}>
                {type}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  const createTriggerView = () => {
    return (
      <VStack
        style={{ marginHorizontal: 15, overflow: 'scroll', height: height }}
      >
        <HStack
          style={{ marginHorizontal: 0, width: '100%' }}
          alignSelf={'center'}
          justifyContent={'space-between'}
        >
          <TitleSubtitleView
            isTitleSubtitleOldView={true}
            containerStyle={{ marginHorizontal: 0, marginTop: 0 }}
            titleLabelId={flowType === FlowType.ivr ? 'Phone Tree' : 'createWorkflow'}
            subtitleLabelId=""
            isHideCommonButton={true}
          />
          {getCloseAndContinue()}
        </HStack>
        <Flex style={styles.workflowNameFlex}>
          {getStepMachineNameElement()}
        </Flex>
        <VStack style={[styles.workflowNameFlex, { width: '100%' }]}>
          <HStack justifyContent={'center'} alignItems={'center'} padding={2}>
            <Text style={styles.workflowNameTextLabel} marginRight={5}>
              Description
            </Text>
            {false ? <Checkbox
              checked={componentState.isNaturalLanguage}
              onChange={() => {
                setComponentState((prev: any) => {
                  return { ...prev, isNaturalLanguage: !prev.isNaturalLanguage };
                });
              }}
            >
              Try Natural Language
            </Checkbox> : <></> }

            {false ? <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setComponentState((prev: any) => {
                    return { ...prev, isRecording: !prev.isRecording };
                  });
                },
                leftIcon: (
                  <MaterialCommunityIcon
                    name={
                      componentState.isRecording
                        ? 'text-to-speech-off'
                        : 'text-to-speech'
                    }
                    size={18}
                    color={Colors.Custom.mainPrimaryPurple}
                  />
                ),
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: componentState.isRecording
                    ? 'Stop Speech To Text'
                    : 'Start Speech To Text'
                }),
                withRightBorder: false,
              }}
            ></FoldButton> : <></> }
            {/* <Button
              style={{
                height: 25,
                width: 25,
                borderRadius: 18,
                borderWidth: 1,
                borderColor: componentState.isRecording ? Colors.error[200] : Colors.success[200],
                backgroundColor: componentState.isRecording ? Colors.error[100] : Colors.success[100],
              }}
              onPress={() => {
                setComponentState((prev: any) => {
                  return { ...prev, isRecording: !prev.isRecording };
                })
              }}
            >
              <MaterialCommunityIcon
                name={componentState.isRecording ? 'text-to-speech-off' : "text-to-speech"}
                size={18}
                color={componentState.isRecording ? Colors.error[800] : Colors.success[800]}
              />
            </Button> */}
          </HStack>
          <Input
            _focus={{ borderColor: '#D0D5DD' }}
            style={styles.workflowNameInput}
            borderColor={
              isShowErrorColor && !props.workflowDescription
                ? 'red.600'
                : '#ddd5d5'
            }
            isFullWidth={true}
            placeholder="Description"
            value={props.workflowDescription}
            lineHeight={4}
            numberOfLines={5}
            multiline
            onChangeText={(newValue: string) => {
              if (componentState.isRecording) {
                setComponentState((prev: any) => {
                  return { ...prev, isRecording: false };
                });
              }
              props.setWorkflowDescription((oldValue: string) => {
                return newValue;
              });
            }}
          />
        </VStack>
        {props.flowType === FlowType.ivr && (
          <VStack mb={3}>
            <HStack marginY={4} width={'50%'}>
              <HStack marginY={2.4}>
                <Text style={styles.workflowNameTextLabel}>
                  Phone Tree Type :{' '}
                </Text>
              </HStack>
              <View marginLeft={2} flex={1} width={'50%'}>
                <Select
                  backgroundColor={'#fff'}
                  borderRadius={8}
                  borderWidth={1}
                  placeholder={'Select Phone Tree Type'}
                  borderColor={Colors.Custom.Gray300}
                  selectedValue={props.phoneTreeType || inboundIvrTypeId}
                  onValueChange={(itemValue) => {
                    props.setPhoneTreeType(itemValue);
                  }}
                  style={{ borderRadius: 12 }}
                >
                  {IvrUsageTypeList?.map((flow: any) => {
                    return (
                      <Select.Item
                        key={flow.id}
                        label={flow.value || ''}
                        value={flow.id}
                      />
                    );
                  })}
                </Select>
              </View>
            </HStack>
            {phoneTreeAutoSaveInDraft &&
              <Checkbox
                defaultChecked={true}
                onChange={() => {
                  setComponentState((prev: any) => {
                    return { ...prev, isSaveDraft: !prev.isSaveDraft };
                  });
                }}
              >
                Auto save in draft
              </Checkbox>
            }
          </VStack>
        )}
        {/* {renderTabs()}
        {renderTabTypes()} */}

        {componentState.isNaturalLanguage ? (
          <></>
        ) : (
          <Flex style={styles.mainFlex}>
            {/* { triggerType ?
      getTriggerTabElement() :  null
    } */}
            {triggerTypeMap[triggerType]?.isFrequencyAllowed ? (
              <>
                {getFrequencyElement()}
                {frequency ? (
                  <>
                    {getDayElement()}
                    {getDateElement()}
                    {getDateTimeElement()}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {isValid && getTagListElement()}
            {getConditionSelectBox()}
          </Flex>
        )}
      </VStack>
    );
  };
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (isSaved) {
      setShowModal(false)

    }
    if (!showModal) {
      searchParams.set('triggerState', 'saved')
      setSearchParams(searchParams);
    }
  }, [isSaved, showModal]);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const drawerWidth =
    isIPadMiniScreen || isIPadScreen ? width * 0.7 : width * 0.4;

  return (
    <>
      <View>
        <Modal
          isOpen={showModal}
          onClose={() => (isValid ? setShowModal(false) : null)}
        >
          <Modal.Content>
            <View position={'fixed'} right={0} top={0}>
              {/* {isValid ? <Modal.CloseButton /> : null} */}
              <VStack
                position={'absolute'}
                right={0}
                px={4}
                width={drawerWidth}
                height={'100vh'}
                bgColor={'white'}
              >
                {createTriggerView()}
              </VStack>
            </View>
          </Modal.Content>
        </Modal>
      </View>
    </>
  );
};

export default WorkflowTrigger;
