import { InputNumber, Select } from 'antd';
import { HStack, Text, View } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AppointmentTypeSearch from '../AppointmentTypeSearch/AppointmentTypeSearch';
import MultipleFormSearch from '../MultipleFormSearch/MultipleFormSearch';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Colors } from '../../../styles';
const { Option } = Select;

const FormListConfigField = (props: { isAppointmentTypeUserInputFieldPresent?: boolean, isAppointmentLinkTagPresent?: boolean, isFormListUserInputFieldPresent?: boolean, templateType: string, isShowError: boolean, onChange: any, isFormListTagPresent?: boolean, value?: any, isAutomationView?:boolean }) => {
  const MULTIPLE_PATIENT_FORMS = 'MULTIPLE_PATIENT_FORMS';
  const APPOINTMENT_BOOKING_LINK = 'APPOINTMENT_BOOKING_LINK';
  const [value, setValue] = useState<any>(props.value);
  const [message, setMessage] = useState('');
  const isShowMultipleFormField = useCallback(() => {
    const isShow = props.isFormListTagPresent || MULTIPLE_PATIENT_FORMS === props.value?.category?.code || (props.value?.body || '').search('.*{%( )+for( )+form( )+in( )+formList( )*%}.*') != -1;
    return isShow
  }, [props.value?.body, props.value?.category?.code, props.isFormListTagPresent])


  const isAppointmentBookingLinkTagPresent = useCallback(() => {
    const isShow = (props.isAppointmentLinkTagPresent || APPOINTMENT_BOOKING_LINK === props.value?.category?.code || (props.value?.body || '').search('.*{{( )*appointment.link( )*}}.*') != -1) && !props.isAppointmentTypeUserInputFieldPresent;
    return isShow
  }, [props.value?.body, props.value?.category?.code, props.isAppointmentLinkTagPresent])


  const possibleValueList: any = [{ "value": 'Minutes', "displayName": "Minutes" },
  { "value": 'Hours', "displayName": "Hours" },
  { "value": 'Days', "displayName": "Days" },
  { "value": 'Weeks', "displayName": "Weeks" },
  { "value": 'Months', "displayName": "Months" },
  { "value": 'Years', "displayName": "Years" }]
  const intl = useIntl();

  const possibleValueListBoolean: any = [
    { "value": 'YES', "displayName": "Yes" },
    { "value": 'NO', "displayName": "No" },
  ]
  const isShowList = isShowMultipleFormField();
  if (!isShowList) {
    if (props.value && (props?.value?.formList || props?.value?.formList)) {
      // const newValue = { ...(props.value || {}) };
      // delete newValue.formList;
      // delete newValue.formFrequency;
      // props.onChange(newValue);
    }
  }

  useEffect(() => {
    const val = props?.value?.formFrequency?.count 
    let unit = props?.value?.formFrequency?.unit?.code
   if (val && unit) {
      let freqMessage = '';
       unit = unit?.toLowerCase();
      if (val === 1) {
        freqMessage = `Form would only be triggered once in 1 ${unit?.slice(0, -1)}`;
      } else {
        freqMessage = `Form would only be triggered once in ${val} ${unit}`;
      }
      setMessage(freqMessage);
    } else {
      setMessage('');
    }
  }, [props?.value?.formFrequency?.count, props?.value?.formFrequency?.unit?.code]);

  return <>

    {isAppointmentBookingLinkTagPresent() ? <>

      <View style={{ marginTop: 16 }}>
        <HStack>
          <View marginY={1} flex={1}>
            <AppointmentTypeSearch
              allowClearAppointmentType={true}
              isShowAllAppointmentType={true}
              isHideInternalLabel={false}
              isHideUser={false}
              optional={true}
              value={props?.value?.appointmentType}
              allowUserMultiSelect={true}
              isShowError={false}
              onChange={(appointmentType: any) => {
                setValue((value: any) => {
                  const newValue = props.value || {}
                  props.onChange({ ...newValue, appointmentType });
                })
              }}
            />
          </View>
        </HStack>
      </View>

    </> : <></>}
    {(isShowList) ? (
      <>
        {!props.isFormListUserInputFieldPresent ? <View style={{ marginTop: 16 }}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Forms'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7}>
              <View marginY={1} flex={1}>
                <MultipleFormSearch
                  value={props.value?.formList}
                  isShowError={props.isShowError}
                  infoMessage={
                    value?.formList?.some((item: any) => item.isHealthComponentIncluded) ?
                    intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' }) :
                    undefined
                  }
                  onChange={(formList) => {
                    setValue((value: any) => {
                      const newValue = { ...(props.value || {}), formList };
                      props.onChange(newValue);
                      return newValue;
                    });
                  }}
                />
              </View>
            </HStack>
          </HStack>
        </View> : <></>}
        <View style={{ marginTop: 16 }}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Form Frequency'}
              </Text>
            </HStack>
            <View flex={7}>
            <HStack>
              <View marginY={1} flex={1}>
                <HStack flex={1}>
                  <View paddingRight={1}>

                    <InputNumber

                      min={0}
                      style={{ height: 36, fontSize: 13, borderRadius: '10px' }}
                      value={props.value?.formFrequency?.count}
                      type="number"
                      placeholder='Number'
                      className={''}
                      onChange={(count) => {
                        const formFrequency = (props.value || {})?.formFrequency || {}

                        if (count == undefined) {
                          formFrequency.count = undefined
                          setValue({ ...(props.value || {}), formFrequency });
                        } else {
                          formFrequency.count = count;
                          setValue({ ...(props.value || {}), formFrequency });
                        }
                      }}
                      onBlur={() => {
                        props.onChange(value);
                      }}
                    />
                  </View>
                  <View flex={5} paddingRight={1}>
                    <Select
                      suffixIcon={props.isAutomationView ?
                        <CaretDownOutlined
                          style={{
                            color: Colors.FoldPixel.GRAY300
                          }}
                        /> :
                        <DownOutlined />
                      }
                      status={''}
                      allowClear={true}
                      onClear={() => {
                        const newValue = { ...value };
                        delete newValue?.formFrequency
                        props.onChange(newValue)
                      }}
                      value={props?.value?.formFrequency?.unit?.code}
                      showSearch={true}
                      filterOption={(input, option: any) => {
                        return (
                          option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      placeholder={'Select Unit'}
                      onChange={(itemValue) => {
                        let displayValue = itemValue;
                        possibleValueList.some((unit: any) => {
                          if (unit.value === itemValue) {
                            displayValue = unit.displayName;
                          }
                        });
                        const formFrequency = props?.value?.formFrequency || {}
                        const newFormFrequency = { ...formFrequency, unit: { code: itemValue, displayValue } };
                        const newValue = { ...(props.value || {}), formFrequency: newFormFrequency }
                        setValue(newValue);
                        props.onChange(newValue);
                      }}
                    >
                      {possibleValueList.map((data: any) => {
                        return (
                          <Option value={data.value} title={data.displayName} key={data.value}>{data.displayName}</Option>
                        );
                      })}

                    </Select>
                  </View>
                </HStack>
              </View>
            </HStack>
            {message ? <Text marginTop={2}>{message}</Text> : null}
            </View>
          </HStack>
        </View>
        <View style={{ marginTop: 16 }}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Skip ' + props.templateType + ' if form list empty'}
              </Text>
            </HStack>
            <HStack flex={7}>
              <View marginY={1} flex={1}>
                <Select
                  suffixIcon={props.isAutomationView ?
                    <CaretDownOutlined
                      style={{
                        color: Colors.FoldPixel.GRAY300
                      }}
                    /> :
                    <DownOutlined />
                  }
                  status={''}
                  allowClear={true}
                  placeholder={'Select Option'}
                  onClear={() => {
                    const newValue = { ...value };
                    delete newValue?.isSkipOnEmptyFormList
                    props.onChange(newValue)
                  }}
                  value={props?.value?.isSkipOnEmptyFormList?.code}
                  showSearch={true}
                  filterOption={(input, option: any) => {
                    return (
                      option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(itemValue) => {
                    let displayValue = itemValue;
                    possibleValueListBoolean.some((unit: any) => {
                      if (unit.value === itemValue) {
                        displayValue = unit.displayName;
                      }
                    });
                    const isSkipOnEmptyFormList = {
                      code: itemValue,
                      displayName: displayValue
                    }
                    const newValue = { ...(props.value || {}), isSkipOnEmptyFormList: isSkipOnEmptyFormList }
                    setValue(newValue);
                    props.onChange(newValue);
                  }}
                >
                  {possibleValueListBoolean.map((data: any) => {
                    return (
                      <Option value={data.value} title={data.displayName} key={data.value}>{data.displayName}</Option>
                    );
                  })}

                </Select>
              </View>
            </HStack>
          </HStack>
        </View>
      </>) : <></>}
  </>

};

export default FormListConfigField;
