import {View,Text,Pressable} from "react-native";
import {Colors} from "../../../../styles";
import {IAttributes, ICustomAttribute} from "../../../RightSideContainer/Contacts/CustomField/interface";
import {Tooltip,Popover} from 'antd'
import {useState} from 'react'
import { BOOLEAN_OPTION, CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR} from "../../../RightSideContainer/Contacts/CustomField/CustomFieldConst";
import {filterNullAttributes} from "../../../RightSideContainer/Contacts/CustomField/CustomFieldUtils";
import {getDateStrFromFormat} from "../../../../utils/DateUtils";
import {DATE_FORMATS} from "../../../../constants";

export const AttributesView = (props: {attributes: IAttributes[]}) => {
  const [stateData, setStateData] = useState({
    showMore: false,
    maxTagToShow: 2,
  })

  const getValue = (attribute: IAttributes) => {
    if (attribute?.dateValue) {
      return getDateStrFromFormat(attribute?.value, DATE_FORMATS.CONVERSATION_DATE_PICKER)
    } else if (attribute?.booleanValue !== null) {
      return attribute?.booleanValue ? BOOLEAN_OPTION?.[0]?.label :  BOOLEAN_OPTION?.[1]?.label;
    }
    return attribute?.value;
  }

  const renderTags = (attribute: IAttributes) => {
    return (
      <View style={{
        marginRight: 8,
        marginBottom: 4,
        maxWidth: 234,
      }}>
        <Text
          style={{
            backgroundColor: Colors.Custom.Gray100,
            paddingHorizontal: 8,
            paddingVertical: 3,
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 16,
          }}>
          {attribute?.label}
          <Tooltip
            title={attribute?.value?.length>CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? attribute?.value : ''}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
            {": "} {attribute?.value?.length > CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? `${attribute?.value.slice(0,CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR)}...` : getValue(attribute)}
          </Tooltip>
        </Text>
      </View>
    )
  }
  return <>
    <View style={{flexDirection: 'row',flexWrap: 'wrap'}}>
      {filterNullAttributes(props?.attributes)?.map?.((attribute: IAttributes, index: number) => {
        if (index < stateData?.maxTagToShow && attribute?.value) {
          return (renderTags(attribute))
        }
      })}
      {(filterNullAttributes(props?.attributes)?.length - stateData?.maxTagToShow) > 0 && <Popover
        overlayInnerStyle={{
          padding: 0,
          borderRadius: 12
        }}
        overlayClassName='patient-search-select-styles'
        className='patient-search-select-styles'
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{
          padding: 0,
          borderRadius: 12,
        }}
        placement={'bottomLeft'}
        trigger={'click'}
        visible={stateData?.showMore}
        onVisibleChange={() => {
          setStateData((prev) => {
            return {
              ...prev,
              showMore: false
            }
          })
        }}
        content={<View style={{flexDirection: 'row',flexWrap: 'wrap', maxWidth: 240}}>
          {filterNullAttributes(props?.attributes)?.map?.((attribute: IAttributes) => {
            return (renderTags(attribute))
          })}
        </View>
        }
      >
        <Pressable
          onPress={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showMore: true
              }
            })
          }}
        >
          <Text style={{
            fontWeight: '500',
            marginTop: 1,
            color: Colors.Custom.PurpleColor,
            paddingVertical: 3,
            marginLeft: 1,
          }}>
            {`+${filterNullAttributes(props?.attributes)?.length - stateData?.maxTagToShow} more`}
          </Text>
        </Pressable>
    </Popover>}
    </View>
  </>
}