import {useMutation} from '@apollo/client';
import {Drawer, Select} from 'antd';
import {
  Center,
  Divider,
  FormControl,
  Input,
  Spinner,
  Text,
  useToast,
  View,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, CONTACT_CLAIMS_CONST, MLOV_CATEGORY, SECTION_LIST} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {EmployerDashboard} from '../../../../../services';
import {getCptOrRevenueCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getMlovId} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  PRIMARY_VALUE,
  TEMP_CPT_CODE,
  TEMP_REVENUE_CODE,
  X_AXIS_DIMENSIONS,
  X_AXIS_METRICS,
  Y_AXIS_METRICS,
} from '../tempDataFile';
import {IAddWidgetDrawer, IPageSections, IDashboardSection, IPrimaryValueCodesList} from './interface';

const AddWidgetDrawer = (props: IAddWidgetDrawer) => {
  const {onClose, dashboardData, onComplete} = props;
  const toast = useToast();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const widgetTypeMlovId = getMlovId(
    commonData.MLOV,
    MLOV_CATEGORY.SectionType,
    'WIDGET'
  );
  const claimsResourceId = 'f274a5a1-92b1-430a-9912-4f34a7b7ec49';
  const [showModal, setShowModal] = useState(true);
  const {width} = Dimensions.get('window');
  const finalWidth = width / 2.5;
  const primaryValue = PRIMARY_VALUE;
  const revenueCodes = TEMP_REVENUE_CODE;
  const xAxisMetrics = X_AXIS_METRICS;
  const yAxisMetrics = Y_AXIS_METRICS;
  const xAxisDimension = X_AXIS_DIMENSIONS;
  const [widgetDrawerStateData, setWidgetDrawerStateData] = useState({
    purpose: '',
    primaryValue: CONTACT_CLAIMS_CONST.CPT_CODE,
    primaryValueCodesValues: [] as string[],
    xAxisMetricValue: xAxisMetrics[0].code,
    yAxisMetricValue: yAxisMetrics[0].code,
    xAxisDimensions: [] as string[],
    selectedSection: {} as IDashboardSection,
    widgetSectionData: {} as IPageSections,
    loading: false,
    primaryValueCodesList: [] as IPrimaryValueCodesList[],
    subHeading: ''
  });
  const [CreateWidget] = useMutation(EmployerDashboard.CreatePageSections);
  function checkObjectValues() {
    const mutableObject: any = {...widgetDrawerStateData};
    for (const key in mutableObject) {
      if (key === 'loading' || key === 'subHeading') {
        return true
      }
      if (!mutableObject[key]) {
        return false;
      }
      if (Array.isArray(mutableObject[key]) && !mutableObject[key]?.length) {
        return false
      }
    }
    return true;
  }
  const getPrimaryValueByType = async () => {
    const responseObject = await getCptOrRevenueCode(widgetDrawerStateData.primaryValue, '');
    const cptOrRevenueCodes = responseObject?.data || [];
    if (responseObject?.data?.length || true) {
      const primaryValueCodesList = [] as IPrimaryValueCodesList[]
      if (widgetDrawerStateData.primaryValue === CONTACT_CLAIMS_CONST.CPT_CODE) {
        cptOrRevenueCodes?.forEach((item: any) => {
          if(!item.cptCode) {
            return
          }
          const data = {
            id: item.id,
            value: item.cptCode
          }
          primaryValueCodesList.push(data)
        });
        setWidgetDrawerStateData(prev=> {
          return {
            ...prev,
            primaryValueCodesList: primaryValueCodesList
          }
        })
      } else  if (widgetDrawerStateData.primaryValue === CONTACT_CLAIMS_CONST.REVENUE_CODE) {
        cptOrRevenueCodes?.forEach((item: any) => {
          if(!item.revenueCode) {
            return
          }
          const data = {
            id: item.id,
            value: item.revenueCode
          }
          primaryValueCodesList.push(data)
        });
        setWidgetDrawerStateData(prev=> {
          return {
            ...prev,
            primaryValueCodesList: primaryValueCodesList
          }
        })
      }
    }
  }
  const handledOnCreateWidget = async () => {
    setWidgetDrawerStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const widgetSectionData = {...widgetDrawerStateData.widgetSectionData}
    widgetSectionData.heading = widgetDrawerStateData.purpose;
    widgetSectionData.subHeading = widgetDrawerStateData.subHeading;
    if (widgetDrawerStateData.primaryValue === CONTACT_CLAIMS_CONST.CPT_CODE) {
      widgetSectionData.appliedFilter = {
        CPT_CODES: widgetDrawerStateData.primaryValueCodesValues
      }
    } else if (widgetDrawerStateData.primaryValue === CONTACT_CLAIMS_CONST.REVENUE_CODE) {
      widgetSectionData.appliedFilter = {
        REVENUE_CODES: widgetDrawerStateData.primaryValueCodesValues
      }
    }
    const createWidgetObjList = [widgetSectionData];
    const createWidgetResp = await CreateWidget({
      variables: {
        pageSections: createWidgetObjList,
      },
    }).catch((err) => {
      showToast(
        toast,
        intl.formatMessage({id: 'createWidgetError'}),
        ToastType.error
      );
      setWidgetDrawerStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      return;
    });
    if (createWidgetResp?.data?.createPageSections?.returning?.length) {
      showToast(
        toast,
        intl.formatMessage({id: 'createWidgetSuccess'}),
        ToastType.success
      );
      setWidgetDrawerStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      props.onComplete?.();
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'createWidgetError'}),
        ToastType.error
      );
      setWidgetDrawerStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  useEffect(()=> {
    getPrimaryValueByType();
  },[widgetDrawerStateData.primaryValue])
  useEffect(()=> {
    const selectedObj = dashboardData.find((item) => {
      return item?.sectionCategory?.code === SECTION_LIST.SAVINGS;
    });
    const sequence = selectedObj?.childSections?.length
      ? selectedObj?.childSections?.length
      : 0;
    if (selectedObj) {
      const widgetSectionData: IPageSections = {
        pageId: selectedObj?.pageId,
        sectionCategoryId: selectedObj?.sectionCategoryId,
        parentSectionId: selectedObj?.id,
        sequence: sequence,
        type: widgetTypeMlovId,
        resourceId: claimsResourceId,
        isVisible: true,
        heading: widgetDrawerStateData.purpose,
      };
      setWidgetDrawerStateData((prev) => {
        return {
          ...prev,
          selectedSection: selectedObj,
          widgetSectionData: widgetSectionData,
        };
      });
    }
  },[])
  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={() => {
        onClose?.();
        setShowModal(false);
      }}
      title={
        <ModalActionTitle
          isHeadNotSticky={true}
          title={'addWidget'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                setShowModal(false);
                onClose()
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'create',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.PRIMARY,
              isDisabled: widgetDrawerStateData.loading || !checkObjectValues(),
              onClick: () => {
                handledOnCreateWidget();
              },
            },
          ]}
        />
      }
    >
      {widgetDrawerStateData.loading && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      <View>
        <FormControl>
          <FormControl.Label>
            <DisplayText
              textLocalId={'widgetName'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
          </FormControl.Label>
          <Input
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            placeholderTextColor={Colors.Custom.Gray300}
            style={{
              height: 40,
            }}
            borderRadius={6}
            placeholder={'Enter widget name'}
            fontWeight={500}
            fontSize={14}
            value={widgetDrawerStateData.purpose}
            onChangeText={(value) => {
              setWidgetDrawerStateData((prev) => {
                return {
                  ...prev,
                  purpose: value,
                };
              });
            }}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>
            <DisplayText
              textLocalId={'description'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
          </FormControl.Label>
          <Input
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            placeholderTextColor={Colors.Custom.Gray300}
            style={{
              height: 40,
            }}
            borderRadius={6}
            placeholder={'Add description here'}
            fontWeight={500}
            fontSize={14}
            value={widgetDrawerStateData.subHeading}
            onChangeText={(value) => {
              setWidgetDrawerStateData((prev) => {
                return {
                  ...prev,
                  subHeading: value,
                };
              });
            }}
          />
        </FormControl>
        <FormControl style={{marginTop: 8}}>
          <FormControl.Label>
            <DisplayText
              textLocalId={'sectionLabel'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
          </FormControl.Label>
          <Select
            placeholder="Select Parent Section"
            showSearch={false}
            disabled={true}
            onChange={(value, option) => {
              const selectedObj = dashboardData.find((item) => {
                return item.id === value;
              });
              const sequence = selectedObj?.childSections?.length
                ? selectedObj?.childSections?.length
                : 0;
              if (selectedObj) {
                const widgetSectionData: IPageSections = {
                  pageId: selectedObj?.pageId,
                  sectionCategoryId: selectedObj?.sectionCategoryId,
                  parentSectionId: selectedObj?.id,
                  sequence: sequence,
                  type: widgetTypeMlovId,
                  resourceId: claimsResourceId,
                  isVisible: true,
                  heading: widgetDrawerStateData.purpose,
                };
                setWidgetDrawerStateData((prev) => {
                  return {
                    ...prev,
                    selectedSection: selectedObj,
                    widgetSectionData: widgetSectionData,
                  };
                });
              }
            }}
            value={widgetDrawerStateData.selectedSection?.id}
            style={{
              width: '100%',
              height: 40,
              minHeight: 40,
            }}
          >
            {dashboardData?.map((item) => (
              <Select.Option key={item.id}>{item.heading}</Select.Option>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{marginTop: 8}}>
          <FormControl.Label>
            <DisplayText
              textLocalId={'primaryValue'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
          </FormControl.Label>
          <Select
            placeholder="Select primary value"
            showSearch={false}
            onChange={(value, option) => {
              setWidgetDrawerStateData((prev) => {
                return {
                  ...prev,
                  primaryValue: value,
                  primaryValueCodesValues: []
                };
              });
            }}
            value={widgetDrawerStateData.primaryValue}
            style={{
              width: '100%',
              height: 40,
              minHeight: 40,
            }}
          >
            {primaryValue.map((item) => (
              <Select.Option key={item.code}>{item.value}</Select.Option>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{marginTop: 16}}>
          <Select
            mode="multiple"
            placeholder="Select"
            showSearch={false}
            onChange={(value, option) => {
              setWidgetDrawerStateData((prev) => {
                return {
                  ...prev,
                  primaryValueCodesValues: value,
                };
              });
            }}
            value={widgetDrawerStateData.primaryValueCodesValues}
            style={{
              width: '100%',
              height: 40,
              minHeight: 40,
            }}
          >
            {widgetDrawerStateData.primaryValueCodesList.map((item) => (
              <Select.Option key={item.value}>{item.value}</Select.Option>
            ))}
          </Select>
        </FormControl>
        <View style={{marginTop: 32}}>
          <Text
            size="xsBold"
            style={{paddingLeft: 4}}
            fontWeight="700"
            color={Colors.Custom.Gray400}
          >
            Select X-Axis
          </Text>
          <FormControl style={{marginTop: 8}}>
            <Select
              onChange={(value, option) => {
                setWidgetDrawerStateData((prev) => {
                  return {
                    ...prev,
                    xAxisMetricValue: value,
                  };
                });
              }}
              value={widgetDrawerStateData.xAxisMetricValue}
              placeholder="Select metrics value"
              style={{
                width: '100%',
                height: 40,
                minHeight: 40,
              }}
            >
              {xAxisMetrics.map((item) => (
                <Select.Option key={item.code}>{item.value}</Select.Option>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{marginTop: 8}}>
            <FormControl.Label>
              <DisplayText
                textLocalId={'dimensionsForXAxis'}
                extraStyles={{
                  color: Colors.Custom.Gray500,
                  fontWeight: 500,
                  fontSize: 14,
                }}
              />
            </FormControl.Label>
            <Select
              placeholder="Select dimensions value"
              mode="multiple"
              onChange={(value, option) => {
                setWidgetDrawerStateData((prev) => {
                  return {
                    ...prev,
                    xAxisDimensions: value,
                  };
                });
              }}
              value={widgetDrawerStateData.xAxisDimensions}
              style={{
                width: '100%',
                height: 40,
                minHeight: 40,
              }}
            >
              {xAxisDimension.map((item) => (
                <Select.Option key={item.id}>{item.value}</Select.Option>
              ))}
            </Select>
          </FormControl>
        </View>
        <View style={{marginTop: 32}}>
          <Text
            size="xsBold"
            style={{paddingLeft: 4}}
            fontWeight="700"
            color={Colors.Custom.Gray400}
          >
            Select Y-Axis
          </Text>
          <FormControl style={{marginTop: 8}}>
            <Select
              onChange={(value, option) => {
                setWidgetDrawerStateData((prev) => {
                  return {
                    ...prev,
                    yAxisMetricValue: value,
                  };
                });
              }}
              value={widgetDrawerStateData.yAxisMetricValue}
              placeholder="Select metrics value"
              style={{
                width: '100%',
                height: 40,
                minHeight: 40,
              }}
            >
              {yAxisMetrics.map((item) => (
                <Select.Option key={item.code}>{item.value}</Select.Option>
              ))}
            </Select>
          </FormControl>
        </View>
      </View>
    </Drawer>
  );
};

export default AddWidgetDrawer;
