import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {EMAIL_INBOX_USER_CODE} from '../../../../../common/PreferencesSetting/PreserenceConst';
import { IChannelTypes } from '../../ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {MESSAGE_CUSTOM_CARD_TYPE} from '../../MessagingWindow/MessagingCustomCard/CustomCardConst';
import {assignStatusValue} from '../ConversationConst';
import {getChannelTypeFilter} from './ConversationFilterUtils';
import {IAdditionFilterData} from './interface';

export const getConversationChannelTabFilter = (
  selectedInboxTypeCode: string,
  additionFilterData: IAdditionFilterData,
  isEFaxMessagingEnabled?: boolean,
  isPatientMessagingEnabled?: boolean,
  emailInboxUserPreference?: string,
  otherContactTypeId?: string,
  isEmailUserPreferenceEnabled?: boolean,
) => {
  switch (selectedInboxTypeCode) {
    case CHANNEL_TYPE_CODE.CHANNEL_ALL:
      return getAllConversationTabFilter({...additionFilterData, isPatientMessagingEnabled: isPatientMessagingEnabled, isEFaxMessagingEnabled: isEFaxMessagingEnabled, emailInboxUserPreference: emailInboxUserPreference, otherContactTypeId, isEmailUserPreferenceEnabled});
    case CHANNEL_TYPE_CODE.CHANNEL_INTERNAL:
      return getInternalConversationTabFilter(
        additionFilterData.groupTypeCodeIdObj,
        additionFilterData.userUuid
      );
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
      return getChatConversationTabFilter(
        selectedInboxTypeCode,
        additionFilterData
      );
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
    case CHANNEL_TYPE_CODE.CHANNEL_WHATSAPP:
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
      return getCommonConversationTabFilter(
        selectedInboxTypeCode,
        additionFilterData.accountId,
        additionFilterData,
        emailInboxUserPreference,
        otherContactTypeId,
        isEmailUserPreferenceEnabled
      );
    case CHANNEL_TYPE_CODE.CHANNEL_MENTION:
      return getAllConversationMentionFilter({...additionFilterData, isEFaxMessagingEnabled: isEFaxMessagingEnabled});
    case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED:
      return getSmsEmailCHATArchivedFilter({...additionFilterData, isEFaxMessagingEnabled: isEFaxMessagingEnabled});
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME:
      return getSmsEmailAssignMeFilter({...additionFilterData, isEFaxMessagingEnabled: isEFaxMessagingEnabled});
    case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS:
      return getMisscedcallConversationFilter(
        additionFilterData.accountId,
        additionFilterData);
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER:
      return getConversationAssignedOtherFilter({...additionFilterData, isEFaxMessagingEnabled: isEFaxMessagingEnabled});
    case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED:
      return getConversationUnAssignedFilter({...additionFilterData, isEFaxMessagingEnabled: isEFaxMessagingEnabled});
    case CHANNEL_TYPE_CODE.ALL_CALLS:
      return getCallConversationActivityFilter(
        additionFilterData.accountId,
        additionFilterData);
    }
};

const getAllConversationMentionFilter = (
  additionFilterData: IAdditionFilterData
) => {
  const {accountId, userUuid, groupTypeCodeIdObj,isEFaxMessagingEnabled} = additionFilterData;
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
    CHANNEL_TYPE.CHANNEL_WEB_WIDGET,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  return {
    userId: {
      _eq: userUuid
    },
    // isRead: {
    //   _eq: true
    // },
    conversation: {
      accountId: {
        _eq: accountId,
      },
      _or: [
        {
          groupConversation: {
            groupMembers: {
              groupUserId: {
                _eq: userUuid,
              },
            },
          },
        },
        {
          conversationInbox: {
            channelType: {
              _in: channelType
            },
            inboxMembers: {
              userId: {
                _eq: additionFilterData?.id
              }
            }
          }
        }
      ],
      conversationMentions: {
        userType: {
          code: {
            _eq: 'USER',
          },
        },
        userId: {
          _eq: additionFilterData.userUuid,
        },
        message: {
          isDeleted: {
            _eq: false
          }
        }
      },
    }
  };
};

const getAllConversationTabFilter = (
  additionFilterData: IAdditionFilterData
) => {
  const {accountId, userUuid, groupTypeCodeIdObj, isPatientMessagingEnabled, isEFaxMessagingEnabled, emailInboxUserPreference, otherContactTypeId, isEmailUserPreferenceEnabled} =
    additionFilterData;

  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
    CHANNEL_TYPE.CHANNEL_WEB_WIDGET,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  const filterQueryObject: any = {
    accountId: {
      _eq: accountId,
    },
    _or: [
      {
        conversationInbox: {
          channelType: {
            _in: channelType
          },
          inboxMembers: {
            userId: {
              _eq: additionFilterData?.id
            }
          }
        },
        ...(isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER && otherContactTypeId ? {
          contactInbox: {
            _or: [
              {
                inbox: {
                  channelType: {
                    _eq: CHANNEL_TYPE.CHANNEL_EMAIL
                  }
                },
                contact: {
                  contactType: {
                    contactType: {
                      id: {
                        _in: [otherContactTypeId]
                      }
                    }
                  }
                }
              },
              {
                inbox: {
                  channelType: {
                    _in: channelType?.filter((type: string) => {
                      return type !== CHANNEL_TYPE.CHANNEL_EMAIL;
                    })
                  }
                }
              }
            ]
          }
        } : {}),
        ...(isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD && otherContactTypeId ? {
          contactInbox : {
            _or: [
              {
                inbox: {
                  channelType: {
                    _eq: CHANNEL_TYPE.CHANNEL_EMAIL
                  }
                },
                contact: {
                  contactType: {
                    contactType: {
                      id: {
                        _nin: [otherContactTypeId]
                      }
                    }
                  }
                }
              },
              {
                inbox: {
                  channelType: {
                    _in: channelType?.filter((type: string) => {
                      return type !== CHANNEL_TYPE.CHANNEL_EMAIL;
                    })
                  }
                }
              }
            ]
          }
        } : {})
      }
    ],
    status: {_neq: assignStatusValue.ARCHIVED}
  };
  if (isPatientMessagingEnabled) {
    filterQueryObject._or.push({
      groupConversation: {
        groupMembers: {
          groupUserId: {
            _eq: userUuid,
          },
        },
        _or: [
          {
            groupTypeId: {
              _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
            },
          },
          {
            groupTypeId: {
              _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PATIENT_BROADCAST],
            },
          },
        ],
      },
    })
  }
  return filterQueryObject
};

const getInternalConversationTabFilter = (
  groupTypeCodeIdObj: {[index: string]: string},
  userUuid: string
) => {
  return {
    groupConversation: {
      groupMembers: {
        groupUserId: {
          _eq: userUuid,
        },
      },
      _or: [
        {
          groupTypeId: {
            _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.INTERNAL],
          },
        },
        {
          groupTypeId: {
            _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.USER_BROADCAST],
          },
        },
      ],
    },
    status: {_neq: assignStatusValue.ARCHIVED}
  };
};

const getChatConversationTabFilter = (
  selectedInboxTypeCode: string,
  additionFilterData: IAdditionFilterData
) => {
  const channelTypeObj: any = getChannelTypeFilter(selectedInboxTypeCode, additionFilterData as any) || {};
  const {accountId, userUuid, groupTypeCodeIdObj} = additionFilterData;
  return {
    accountId: {_eq: accountId},
    _or: [
      {
        inboxId: {
          _neq: -1,
        },
        ...channelTypeObj,
      },
      {
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
          _or: [
            {
              groupTypeId: {
                _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
              },
            },
            {
              groupTypeId: {
                _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PATIENT_BROADCAST],
              },
            },
          ],
        },
      },
    ],
    status: {_neq: assignStatusValue.ARCHIVED}
  };
};

const getCommonConversationTabFilter = (
  selectedInboxTypeCode: string,
  accountId: number,
  additionFilterData: any,
  emailInboxUserPreference?: string,
  otherContactTypeId?: string,
  isEmailUserPreferenceEnabled?: boolean,
) => {
  const channelTypeObj: any = getChannelTypeFilter(selectedInboxTypeCode, additionFilterData as any, emailInboxUserPreference, otherContactTypeId, isEmailUserPreferenceEnabled) || {};
  return {
    accountId: {_eq: accountId},
    inboxId: {_neq: -1},
    ...channelTypeObj,
  };
};

const getSmsEmailCHATArchivedFilter = (
  additionFilterData: any
) => {
  const {accountId, userUuid, groupTypeCodeIdObj, isEFaxMessagingEnabled} = additionFilterData;
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
    CHANNEL_TYPE.CHANNEL_WEB_WIDGET,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  return {
    accountId: {
      _eq: accountId,
    },
    _or: [
      {
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
          _or: [
            {
              groupTypeId: {
                _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
              },
            },
            {
              groupTypeId: {
                _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PATIENT_BROADCAST],
              },
            },
          ],
        },
      },
      {
        conversationInbox: {
          channelType: {
            _in: channelType
          },
          inboxMembers: {
            userId: {
              _eq: additionFilterData?.id
            }
          }
        }
      }
    ],
    status: {_eq: assignStatusValue.ARCHIVED}
  };
};

const getSmsEmailAssignMeFilter = (
  additionFilterData: any
) => {
  const {accountId, userUuid, isEFaxMessagingEnabled} = additionFilterData;
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  return {
    accountId: {_eq: accountId},
    _or: [
      {
        inboxId: {_neq: -1},
        conversationInbox: {
          channelType: {
            _in: channelType,
          },
          inboxMembers: {
            userId: {
              _eq: additionFilterData.id
            }
          }
        }
      },
      {
        groupConversation: {
          groupType: {
            code: {
              _in: 'PRIVATE'
            }
          },
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
        }
      },
    ],
    assigneeId: {_eq: additionFilterData.id},
    status: {_neq: assignStatusValue.ARCHIVED},
  };
};

const getConversationAssignedOtherFilter = (
  additionFilterData: any
) => {
  const {accountId, userUuid, isEFaxMessagingEnabled} = additionFilterData;
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  return {
    accountId: {_eq: accountId},
    _or: [
      {
        inboxId: {_neq: -1},
        conversationInbox: {
          channelType: {
            _in: channelType,
          },
        }
      },
      {
        groupConversation: {
          groupType: {
            code: {
              _in: 'PRIVATE'
            }
          },
        }
      },
    ],
    assigneeId: {_neq: additionFilterData.id},
    status: {_neq: assignStatusValue.ARCHIVED},
  };
};

const getConversationUnAssignedFilter = (
  additionFilterData: any
) => {
  const {accountId, userUuid, isEFaxMessagingEnabled} = additionFilterData;
  const channelType: IChannelTypes[] = [
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
    CHANNEL_TYPE.CHANNEL_EMAIL,
  ];
  if (isEFaxMessagingEnabled) {
    channelType.push(CHANNEL_TYPE.CHANNEL_EFAX);
  }
  return {
    accountId: {_eq: accountId},
    _or: [
      {
        inboxId: {_neq: -1},
        conversationInbox: {
          channelType: {
            _in: channelType,
          },
          inboxMembers: {
            userId: {
              _eq: additionFilterData.id
            }
          }
        }
      },
      {
        groupConversation: {
          groupType: {
            code: {
              _in: 'PRIVATE'
            }
          },
          groupMembers: {
            groupUserId: {
              _eq: userUuid,
            },
          },
        }
      },
    ],
    assigneeId: {_is_null: true},
    status: {_neq: assignStatusValue.ARCHIVED},
  };
};

const getMisscedcallConversationFilter = (
  accountId: number,
  additionFilterData: any
) => {
  return {
    accountId: {_eq: accountId},
    inboxId: {_neq: -1},
    conversationInbox: {
      inboxMembers: {
        userId: {
          _eq: additionFilterData.id
        }
      }
    },
    conversationMessages: {
      messageType: {
        _eq: MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD
      }
    },
    lastMissCallAt: {
      _is_null: false,
    },
    status: {_neq: assignStatusValue.ARCHIVED},
  };
};

const getCallConversationActivityFilter = (
  accountId: number,
  additionFilterData: any
) => {
  return {
    accountId: {_eq: accountId},
    inboxId: {_neq: -1},
    conversationInbox: {
      channelType: {
        _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
      },
      inboxMembers: {
        userId: {
          _eq: additionFilterData.id
        }
      }
    },
    status: {_neq: assignStatusValue.ARCHIVED},
  };
};
