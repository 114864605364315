import {gql} from '@apollo/client';

export const GET_PERMISSION_BY_ENTITY = gql`
  query GetPermission($entityCode: String) {
    userPermissions(
      where: {entityCode: {_eq: $entityCode}, isDeleted: {_eq: false}}
    ) {
      id
      accountUUID
      entityCode
      userRoleCode
      permissionCode
      actionCode
      createdAt
    }
  }
`;

export const CREATE_NEW_PERMISSION = gql`
  mutation create(
    $permissionCode: String
    $userRoleCode: String
    $accountUUID: uuid!
    $entityCode: String
    $actionCode: String
  ) {
    createUserPermission(
      object: {
        actionCode: $actionCode
        permissionCode: $permissionCode
        userRoleCode: $userRoleCode
        accountUUID: $accountUUID
        entityCode: $entityCode
      }
    ) {
      accountUUID
      entityCode
      userRoleCode
      permissionCode
      id
    }
  }
`;

export const UPDATE_PERMISSION = gql`
  mutation updatePermission($id: uuid, $permissionCode: String) {
    updateUserPermissions(
      where: {id: {_eq: $id}}
      _set: {permissionCode: $permissionCode}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_PERMISSION_BY_ENTITY_CODE_AND_ACTION_CODE = gql`
  query getPermissionByEntityCodeAndActionCode(
    $entityCode: String
    $userRoleCode: String
    $actionCode: String
  ) {
    userPermissions(
      where: {
        userRoleCode: {_eq: $userRoleCode}
        actionCode: {_eq: $actionCode}
        entityCode: {_eq: $entityCode}
        isDeleted: {_eq: false}
      }
    ) {
      actionCode
      entityCode
      id
      userRoleCode
      permissionCode
      createdAt
    }
  }
`;

export default {
  GET_PERMISSION_BY_ENTITY,
  CREATE_NEW_PERMISSION,
  UPDATE_PERMISSION,
  GET_PERMISSION_BY_ENTITY_CODE_AND_ACTION_CODE,
};
