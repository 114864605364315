export const TELEPHONY_VIEW = {
  PURCHASE_NEW_NUMBER: 'PURCHASE_NEW_NUMBER',
  ADD_NEW_IVR_FLOW: 'ADD_NEW_IVR_FLOW',
  SETUP_NUMBER: 'SETUP_NUMBER'
};

export const TELEPHONY_CONST = {
  SUBMIT: 'SUBMIT',
  CLOSE: 'CLOSE',
};
