import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {SEARCH_TASK_POOL_BY_TEXT, SEARCH_TASK_POOL_BY_IDS} from '../../../../services/TaskPool/TaskPoolQueries';
import {getMlovId} from '../../../../utils/mlovUtils';
import {Text} from 'native-base'

const TaskPoolSearch = (props: {
  defaultValue?: any;
  isShowError?: boolean;
  onChange: (actionData?: any, extraData?: any) => void;
}) => {
  const {defaultValue} = props;
  const [poolList, setPoolList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const mlovData = useContext(CommonDataContext);
  const taskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool',
  );

  const [searchTaskPool] = useLazyQuery(SEARCH_TASK_POOL_BY_TEXT, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: data => {
      setLoading(false);
      setPoolList(data?.userPools || []);
    },
    onError: error => {
      setLoading(false);
    },
  });

  const [searchTaskPoolById] = useLazyQuery(SEARCH_TASK_POOL_BY_IDS, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: data => {
      setLoading(false);
      setPoolList(data?.userPools || []);
    },
    onError: error => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (defaultValue && defaultValue?.length) {
      onGetTaskPoolByIds(defaultValue);
    } else {
      onSearch('');
    }
  }, [defaultValue]);

  const onSearch = (searchString: string) => {
    setLoading(true);
    searchTaskPool({
      variables: {
        userPoolTypeId: taskPoolTypeId,
        searchString: `%${searchString}%`,
      },
    });
  };

  const onGetTaskPoolByIds = (poolIds: string[]) => {
    setLoading(true);
    searchTaskPoolById({
      variables: {
        userPoolTypeId: taskPoolTypeId,
        poolIds,
      },
    });
  };

  return (
    <Select
      size="large"
      mode="multiple"
      showSearch
      status={
        props.isShowError &&
        !(defaultValue && defaultValue?.length)
          ? 'error'
          : ''
      }
      allowClear
      filterOption={false}
      value={!isLoading ? defaultValue || undefined : []}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any) => {
        if (value && value.length > 0) {
          const taskPoolName = value?.map((poolId: any) => {
            const poolName = poolList?.find(pool => {
              return pool?.id == poolId;
            });
            return poolName?.name;
          });
          props.onChange(value, taskPoolName);
        } else {
          props.onChange(undefined);
        }
      }}
      placeholder="Select Task Pool"
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>}
      style={{width: '100%'}}>
      {poolList?.map((item: any) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default TaskPoolSearch;
