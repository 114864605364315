import {useLazyQuery} from '@apollo/client';
import {Skeleton, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {EmployeeCountProps} from '../interfaces';

const EmployeeCount = (props: EmployeeCountProps) => {
  const {employerId, count} = props;
  const [state, setState] = useState({
    count: 0,
    loading: true,
  });
  const accountUuid = getAccountUUID();

  const [getEmployeeCount] = useLazyQuery(EmployerQueries.GetEmployeeCount);

  const getCount = async () => {
    try {
      const response = await getEmployeeCount({
        variables: {
          employerId,
          accountUuid,
          currentDate: new Date()
        },
      });
      const count = response.data?.employeeAggregate?.aggregate?.count || 0;
      setState((prev) => {
        return {
          ...prev,
          count: count,
          loading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          count: 0,
          loading: false,
        };
      });


    }
  };

  useEffect(() => {
    if (count) {
      setState((prev) => {
        return {
          ...prev,
          count: count,
          loading: false,
        };
      });
    } else {
      getCount();
    }
  }, []);

  return (
    <View>
      {state.loading ? <Skeleton.Text lines={2} /> : <Text>{state.count}</Text>}
    </View>
  );
};

export default EmployeeCount;
