import {useLazyQuery, useMutation} from '@apollo/client';
import {Table} from 'antd';
import {Checkbox, HStack, View, VStack, Text} from 'native-base';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import { Dimensions } from 'react-native';
import {USER_ROLE_CODES} from '../../../constants/MlovConst';
import {Colors} from '../../../styles';
import {getAccountUUID, isMasterAccount} from '../../../utils/commonUtils';
import {getMlovListByCategory} from '../../../utils/mlovUtils';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import PageBodyContainer from '../../common/PageBodyContainer/PageBodyContainer';
import {TableTopBar} from '../../common/TableTopBar';
import {
  EMPLOYER_ACCESS_CONTROL,
  MAIN_MENU_CODES,
  PROFILE_MENU_CONST,
  PROFILE_SIDE_BAR_TAB,
  SIDE_MENU_CONST,
  USER_ROLE_SIDE_BAR_CODES,
} from '../../SideMenuBar/SideBarConst';
import permissionQueries from './permissionQueries';
import {styles} from './Styles';
import {
  addOrUpdateAllSettingTabPermissions,
  getAllPermissionCode,
  getDefaultPermissionCode,
  getNonePermissionCode,
  isAllPermission,
  USER_ACCESS_PERMISSION,
} from './UserAccessPermission';
import { USER_PERMISSIONS_CONST } from './UserPermissionConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import {addOrUpdateUsersRolePermissions} from '../../../services/User/UserPermission';
const AppTabPermissionsTable = (props: {
  parentTabCode: string;
  userRoles: Array<any>;
  currentPermissions: Array<any>;
  onSave: any;
  showToast: any;
}) => {
  const intl = useIntl();
  const {height} = Dimensions.get('window');
  const contextData = useContext(CommonDataContext);
  const isMasterAccountConfig = isMasterAccount()
  const [currentPermissions, setCurrentPermissions] = useState(
    props.currentPermissions
  );
  const [createNewPermission] = useMutation(
    permissionQueries.CREATE_NEW_PERMISSION
  );
  const [updatePermission] = useMutation(permissionQueries.UPDATE_PERMISSION);
  const [getPermissionByEntityCodeAndActionCode] = useLazyQuery(
    permissionQueries.GET_PERMISSION_BY_ENTITY_CODE_AND_ACTION_CODE
  );
  const tabMap: any = {};
  const [userRoles, setUserRoles] = useState(props?.userRoles ||
    JSON.parse(JSON.stringify(getMlovListByCategory('UserRoles')))?.sort(
      (a: any, b: any) => a?.value.localeCompare(b?.value)
    )
  );
  const accountUUID = getAccountUUID();
  const [columns, setColumns] = useState<any>([]);
  const [employerColumns, setEmployerColumns] = useState<any>([]);
  const [isInit, setIsInit] = useState(true);

  const init = () => {
    let TABS =
      props.parentTabCode == USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
        ? USER_ROLE_SIDE_BAR_CODES.USER
        : USER_ROLE_SIDE_BAR_CODES.ADMIN;

        if(props.parentTabCode == USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code &&
          USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW){
            TABS.push(PROFILE_SIDE_BAR_TAB)
        }
      if (props.parentTabCode == USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code) {
        TABS.push(MAIN_MENU_CODES.EMPLOYER)//patient side menu -> employer tab permission
        TABS.push(MAIN_MENU_CODES.PATIENT_DETAILS) //frontend added permission for patient details
        TABS.push(MAIN_MENU_CODES.SIGN_AMEND_EHR_NOTE); // permission for signing EHR note
      }


      TABS = TABS.filter((tabCode: any) => tabCode !== MAIN_MENU_CODES.ANALYTICS_EMPLOYER);
      TABS.forEach((tabCode) => {
      tabMap[tabCode] = {
        code: tabCode,
      };
    });
    const SIDE_BAR_MENU_CONST = SIDE_MENU_CONST;
    if (props.parentTabCode == USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code &&
      USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW) {
      SIDE_BAR_MENU_CONST.push(PROFILE_MENU_CONST)
    }
    SIDE_BAR_MENU_CONST.forEach((sideMenuData) => {
      if (tabMap[sideMenuData.menuCode]) {
        tabMap[sideMenuData.menuCode].menuName = sideMenuData.menuName;
        tabMap[sideMenuData.menuCode].path = sideMenuData.path;
      }
    });
    const columns: any = [];
    const employerColumns: any = [];
    const tabCodeList = Object.keys(tabMap);
    tabCodeList.forEach((code) => {
      const tabData = tabMap[code];
      const employerAccessControl = EMPLOYER_ACCESS_CONTROL;
      userRoles.forEach((userData: any) => {
        if (!userData.tabData) {
          userData.tabData = {};
        }
        userData.tabData[code] = JSON.parse(JSON.stringify(tabData));
        if(userData.code !== MAIN_MENU_CODES.EMPLOYER && tabData.code !== MAIN_MENU_CODES.PROFILE){
          userData.tabData[code].permissionCode = getDefaultPermissionCode()
        }else if(userData.code === MAIN_MENU_CODES.EMPLOYER){
          employerAccessControl.forEach((tabCode) => {
            if(tabData.code === tabCode){
              if(tabCode === MAIN_MENU_CODES.PROFILE && USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW){
                userData.tabData[code].permissionCode = getDefaultPermissionCode();
              }else{
                userData.tabData[code].permissionCode = getDefaultPermissionCode();
              }
            }else{
              userData.tabData[code].permissionCode = getNonePermissionCode();
            }
          });
        }else{
          userData.tabData[code].permissionCode = getNonePermissionCode();
        }
      });

      if (
        (!tabData.code || !tabData.menuName || !tabData.path) &&
        //condition fo custom options
        ![
          MAIN_MENU_CODES.PATIENT_DETAILS,
          MAIN_MENU_CODES.SIGN_AMEND_EHR_NOTE,
        ].includes(tabData.code)
      ) {
        return;
      }
      // hiding products option for now
      if(tabData.code === 'PRODUCTS'){
        return;
      }
      const permissionColumns = {
        code: tabData.code,
        title: (
          <View alignItems={'center'} justifyContent={'center'}>
            <Text>{tabData.menuName ? intl.formatMessage({id: tabData.menuName}) : intl.formatMessage({id: tabData.code})}</Text>
          </View>
        ),
        // width: '20%',
        render: (record: any) => {
          return (
            <View alignItems={'center'} justifyContent="center" key={tabData.code + record}>
              <Checkbox
                isDisabled={
                  record.code === USER_ROLE_CODES.BUSINESS_OWNER &&
                  (code === MAIN_MENU_CODES.USER_ACCESS ||
                    code === MAIN_MENU_CODES.DESIGNER)
                }
                defaultIsChecked={isAllPermission(
                  record.tabData[code].permissionCode
                )}
                marginTop={2}
                value={'true' + '_' + record.code + '_' + code}
                onChange={async(isChecked: boolean) => {
                  const permissionCode = isChecked
                    ? getAllPermissionCode()
                    : getNonePermissionCode();
                  currentUserRoleMap[record.code].tabData[code].permissionCode =
                    permissionCode;
                    addOrUpdateAllSettingTabPermissions({
                    accountUuid: accountUUID,
                    actionCode: tabData.code,
                    permissionCode: permissionCode,
                    roleCode: record.code,
                  });
                  if (isMasterAccountConfig) {
                    await addOrUpdateUsersRolePermissions({
                      actionCode: tabData.code,
                      permissionCode: permissionCode,
                      userRoleCode: record.code,
                      entityCode: props.parentTabCode
                    }).then((err)=> {
                      props.showToast();
                    })
                    return
                  }
                  const currentId =
                    currentUserRoleMap[record.code].tabData[code].id;
                  record.tabData[code].permissionCode = permissionCode;
                  const dateStr = new Date().toISOString();

                  if (currentId) {
                    updatePermission({
                      variables: {
                        id: currentId,
                        permissionCode: permissionCode,
                      },
                      onCompleted: () => {
                        props.showToast();
                      },
                    });
                  } else {
                    getPermissionByEntityCodeAndActionCode({
                      variables: {
                        userRoleCode: record.code,
                        entityCode: props.parentTabCode,
                        actionCode: code,
                        accountUUID: accountUUID,
                      },
                    }).then((response: any) => {
                      const id = response.userPermissions?.length
                        ? response.userPermissions[0].id
                        : undefined;
                      if (id) {
                        updatePermission({
                          variables: {
                            id: currentId,
                            permissionCode: permissionCode,
                          },
                          onCompleted: () => {
                            props.showToast();
                          },
                        });
                      } else {
                        const variables = {
                          userRoleCode: record.code,
                          entityCode: props.parentTabCode,
                          actionCode: code,
                          permissionCode: permissionCode,
                          createdAt: dateStr,
                          updatedAt: dateStr,
                          accountUUID: accountUUID,
                        };
                        createNewPermission({
                          variables: variables,
                          onCompleted: (response) => {
                            const newCurrentPermission = [
                              ...currentPermissions,
                              {
                                ...variables,
                                id: response.createUserPermission.id,
                              },
                            ];
                            record.tabData[code].id =
                              response.createUserPermission.id;
                            currentUserRoleMap[record.code].tabData[code].id =
                              response.createUserPermission.id;
                            setCurrentPermissions(newCurrentPermission);
                            props.showToast();
                          },
                        });
                      }
                    });
                  }
                }}
              />
            </View>
          );
        },
      }
      if(permissionColumns.code !== MAIN_MENU_CODES.PROFILE){
        columns.push(permissionColumns);
      }

        employerAccessControl.forEach((tabCode)=>{
          if(permissionColumns.code === tabCode){
            if(tabCode === MAIN_MENU_CODES.CONSUMER){
              const consumerColumns = {
                code: permissionColumns.code,
                title: (
                  <View alignItems={'center'} justifyContent={'center'}>
                    <Text>{tabData.menuName ? intl.formatMessage({id: 'employees'}) : ''}</Text>
                  </View>
                ),
                render: permissionColumns.render,
              }
              employerColumns.push(consumerColumns);
            }else{
              employerColumns.push(permissionColumns);
            }
          }
        })
    });
    const currentUserRoleMap: any = {};
    userRoles.forEach((userRoleData: any) => {
      currentUserRoleMap[userRoleData.code] = userRoleData;
    });

    currentPermissions.forEach((data: any) => {
      if (!currentUserRoleMap[data.userRoleCode]) {
        currentUserRoleMap[data.userRoleCode] = {
          tabData: {
            [data.actionCode]: {},
          },
        };
      }
      if (!currentUserRoleMap[data.userRoleCode].tabData[data.actionCode]) {
        currentUserRoleMap[data.userRoleCode].tabData[data.actionCode] = {};
      }
      currentUserRoleMap[data.userRoleCode].tabData[
        data.actionCode
      ].permissionCode = data.permissionCode;
      currentUserRoleMap[data.userRoleCode].tabData[data.actionCode].id =
        data.id;
    });

    setEmployerColumns(employerColumns);
    setColumns(columns);
    setUserRoles(userRoles);
  };
  if (isInit) {
    init();
    setIsInit(false);
  }
  return (
    <View borderRadius={0} >
      <View style={[styles.shadowBoxUserAccessMainTable,{height:height-125}]}>
        <PageBodyContainer style={{marginHorizontal: 0}}>
          <HStack  flexWrap="wrap" justifyItems={'center'}>
            {userRoles.map((role: any, index: number) => {
              if(role.code === 'EMPLOYER' && props.parentTabCode === 'ADMIN_PANEL_WINDOW'){
                return;
              }
              return (
                <View
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 10,
                    padding: 10,
                    paddingHorizontal:15,
                    margin: 10,
                    width:250
                  }}
                >
                  <View style={{
                    height:100,
                    justifyContent:'center'
                  }}>
                  <DisplayText
                    textLocalId={role.value || ''}
                    extraStyles={{fontSize: 20}}
                    size="lgMedium"
                  />
                  </View>

                  <VStack>
                  {role.code === MAIN_MENU_CODES.EMPLOYER ?
                   employerColumns.map((record: any) => {
                    return (
                      <>
                        {record.code !== MAIN_MENU_CODES.PROFILE ? (
                          <HStack alignItems={"center"} justifyContent="space-between" style={{
                            marginVertical: 5
                          }}>
                            {record?.title}
                            {record?.render(role)}
                          </HStack>
                        )
                        :
                        (USER_PERMISSIONS_CONST.IS_EMPLOYER_PROFILE_TAB_SHOW) &&
                        <HStack alignItems={"center"} justifyContent="space-between" style={{
                          marginVertical: 5
                        }}>
                          {record?.title}
                          {record?.render(role)}
                        </HStack>
                        }
                      </>
                    );
                  })
                  :
                  columns.map((record: any) => {
                    return (
                      <>
                        {record.code !== MAIN_MENU_CODES.PROFILE && (
                          <HStack alignItems={"center"} justifyContent="space-between" style={{
                            marginVertical: 5
                          }}>
                            {record?.title}
                            {record?.render(role)}
                          </HStack>
                        )
                        }
                      </>
                    );
                  })
                  }
                  </VStack>
                </View>
              );
            })}
          </HStack>
          {/* <VStack>
            <TableTopBar
              hideSearchBar={true}
              title={'User Access'}
              headerElement={<></>}
              onActionPerformed={(actionCode, searchString) => {
                alert(JSON.stringify(searchString));
              }}
              // buttonList={[{
              //   btnClick: () => {
              //     createNewPermission({
              //       variables: {}, onCompleted: (response) => {
              //         alert(response)
              //       }
              //     })

              //   },
              //   btnText: 'save',
              //   colorScheme: 'primary',
              //   textColor: 'white',
              //   size: 'sm',
              //   variant: 'PRIMARY',
              //   leftIcon: <Icon name="save" color={'white'} size={16} />,
              // }]}
            />
          </VStack> */}
          {/* <Table
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              rowKey={'code'}
              sortDirections={['ascend']}
              showSorterTooltip={true}
              indentSize={1}
              scroll={{x: 1800, y: 575}}
              pagination={{
                pageSize: 9,
                position: ['bottomCenter'],
              }}
              dataSource={userRoles}
              columns={columns}
            /> */}
        </PageBodyContainer>
      </View>
    </View>
  );
};

export default AppTabPermissionsTable;
