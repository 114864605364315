import { Text, View } from 'native-base';
import { styles } from "./style";
import { Colors } from '../../../../styles/Colors';
import Feather from 'react-native-vector-icons/Feather';
import Stack from '../../../common/LayoutComponents/Stack';
import { Tooltip } from 'antd';

const FormCountCard = (props: {
    header: string,
    count: string,
    countSuffix?: string,
    bottomLinkText?: string,
    tooltipText?: string
}) => {

    const {  count, countSuffix, header, bottomLinkText, tooltipText } = props;

    return (
        <View  style={styles.countWithTextContainer}>
            <Stack direction='row' style={{alignItems:'center'}}>
                <Text color={Colors.Custom.IATextColor} fontWeight={500} fontSize={14} marginRight={1}>
                    {header}
                </Text>
                {tooltipText && (
                    <Tooltip title={tooltipText} placement="top">
                    <Feather
                        name="info"
                        style={{
                        fontSize: 16,
                        color: Colors.Custom.Gray500,
                        }}
                    />
                    </Tooltip>
                )}
            </Stack>
            
            <View flexDirection={'row'} alignItems={'center'}>
                <Text size={'mdBold'} fontSize={30}>
                    {count}
                </Text>
                {countSuffix && <Text style={{
                    marginLeft: 4
                }} fontWeight={500} color={Colors.Custom.IATextColor} fontSize={16}>
                    {countSuffix}
                </Text>}
            </View>
            {bottomLinkText && <Text style={{
                position: 'absolute',
                bottom: 10,
                right: 10
            }} fontWeight={500} color={Colors.primary[300]} fontSize={`14`}>
                {bottomLinkText}
            </Text>}

        </View>
    )
}

export default FormCountCard;
