import { USER_PERMISSIONS_CONST } from '../../../UserAccess/UserPermissionConst';
import { CHANNEL_TYPE_CODE, EMAIL_CONVERSATION_SUBTAB_CODES } from '../ConversationConst';
import { IDisplayChannelList, IDisplayEmailSubtabList } from './interfaces';

export const DISPLAY_CHANNEL_LIST: IDisplayChannelList[] = [
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_ALL,
    title: 'All Conversations',
    path: 'allConversations',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
    title: 'Chats',
    path: 'conversations',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
    title: 'SMS',
    path: 'sms',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
    title: 'Email',
    path: 'email',
  },
  // {
  //   key: CHANNEL_TYPE_CODE.CALL_LOGS,
  //   title: 'Calls',
  //   path: 'call-logs',
  // },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
    backgroundColor: '#F37232',
    showLeftBorder: true,
    title: 'Internal Chats',
    path: 'internal',
  },
];

export const getChannelList =((isEmployer: boolean)=> {
  if(isEmployer && USER_PERMISSIONS_CONST.IS_EMPLOYER_OWNER_SHOW_ONLY_INTERNAL){
    const internalChat= DISPLAY_CHANNEL_LIST[DISPLAY_CHANNEL_LIST.length - 1]
      internalChat.title = 'Chats'
    return [internalChat];
  }else{
    return DISPLAY_CHANNEL_LIST;
  }
})

export const EFAX_SOURCE = {
  PATIENT_PROFILE: 'PATIENT_PROFILE',
}
export const KEY_PRESS_CODES = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ENTER: 'Enter',
}

export const EFAX_SUBJECT_CHAR_LIMIT = 250;

export const BULK_ARCHIVE_INBOX_CODE : string[]= [
  CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
  CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
  CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
  CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS,
  CHANNEL_TYPE_CODE.CHANNEL_ALL,
  CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
  CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
  CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
  CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
];

export const BULK_CONVERSATION_READ_INBOX_CODE : string[] = [
  CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
  CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
  CHANNEL_TYPE_CODE.CHANNEL_MENTION,
  CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS,
  CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
  CHANNEL_TYPE_CODE.CHANNEL_ALL,
  CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
  CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
  CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
  CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
  CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
];

export const DOCUMENT_CATEGORY_CODES = {
  E_FAX: 'e_fax'
}
export const EMAIL_CONVERSATION_LIST_SUBTAB : IDisplayEmailSubtabList[] = [
  {
    key: EMAIL_CONVERSATION_SUBTAB_CODES.INBOX,
    code: EMAIL_CONVERSATION_SUBTAB_CODES.INBOX,
    title: 'Inbox',
  },
  {
    key: EMAIL_CONVERSATION_SUBTAB_CODES.SENT,
    code: EMAIL_CONVERSATION_SUBTAB_CODES.SENT,
    title: 'Sent',
  },
]
