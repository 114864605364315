import {IAccountLocation} from "./interface";

export const getFormattedAccountLocationList = (accountLocations: IAccountLocation[]) => {
  return accountLocations?.map((location) => {
    return {
      uuid: location.uuid,
      id: location.id,
      name: location.practiceLocation?.name || '',
      zipcode: location?.practiceLocation?.practiceZipcode?.code,
      address1: location?.practiceLocation?.addressLine1 || '',
      address2: location?.practiceLocation?.addressLine2 || '',
      communicationNumber: '',
      locationUuid: location?.locationUuid || '',
      rawData: location
    }
  })
}
