import {InputNumber, Select as AntSelect} from 'antd';
import {FormControl, HStack, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {IInputElement} from '../../FlowComponent/StateNodes/FlowNodeInterface';
const { Option } = AntSelect;


const ValueAndUnitField = (props: IInputElement) => {
  const [value, setValue] = useState(props.value || {});
  const unitList = props.userInputField?.possibleValueList || [];

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  const isInvalidValue = () => {
    return !(value.value >= 0);
  };

  return (
    <HStack space={2} alignItems="center">
      <FormControl isInvalid={props.isShowError && isInvalidValue()} flex={1}>
        <InputNumber
          placeholder='Enter Value'
          value={value.value}
          type="number"
          className={props.isShowError && isInvalidValue() ? 'field-error' : ''}
          onChange={(text) => {
            setValue((prev: any) => ({...prev, value: text}));
          }}
          style={{width: '100%'}}
        />
      </FormControl>
      <FormControl isInvalid={props.isShowError && !value.unit} flex={1}>
        <AntSelect
          value={value.unit}
          onChange={(itemValue) => {
            setValue((prev: any) => ({ ...prev, unit: itemValue }));
          }}
        >
          {unitList.map((data: any) => (
            <AntSelect.Option key={data.value} label={data.displayName} value={data.value}>
              {data.displayName}
            </AntSelect.Option>
          ))}
        </AntSelect>
      </FormControl>
    </HStack>
  );
};

export default ValueAndUnitField;

export const ValueAndUnitFieldForEmailFrequency = (props: IInputElement) => {
  const [value, setValue] = useState(props.value || {});
  const unitList = props.userInputField?.possibleValueList || [];
  const [message, setMessage] = useState('');

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  const isInvalidValue = () => {
    return !(value.value >= 0);
  };

  useEffect(() => {
    if (value?.value && value?.unit) {
      let freqMessage = '';
      const unit = value?.unit?.toLowerCase();
      if (value?.value === 1) {
        freqMessage = `Email would only be triggered once in 1 ${unit?.slice(0, -1)}`;
      } else {
        freqMessage = `Email would only be triggered once in ${value?.value} ${unit}`;
      }
      setMessage(freqMessage);
    } else {
      setMessage('');
    }
  }, [value]);

    return (
      <View style={{ marginTop: 16 }}>
        <HStack justifyContent='center'>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {props?.displayName}
            </Text>
          </HStack>
          <View flex={7}>
          <HStack>
            <View flex={1}>
              <HStack flex={1}>
                <View paddingRight={1}>
                  <FormControl isInvalid={props?.isShowError && isInvalidValue()}>
                    <InputNumber
                      min={0}
                      style={{ height: 36, fontSize: 13, borderRadius: '10px' }}
                      value={value?.value}
                      type='number'
                      placeholder='Number'
                      className={props?.isShowError && isInvalidValue() ? 'field-error' : ''}
                      onChange={(text) => {
                        setValue((prev: any) => ({ ...prev, value: text }));
                      }}
                    />
                  </FormControl>
                </View>
                <View flex={5} paddingRight={1}>
                  <FormControl isInvalid={props?.isShowError && !value?.unit}>
                    <AntSelect
                      status={''}
                      allowClear={true}
                      value={value?.unit}
                      showSearch={true}
                      onChange={(itemValue) => {
                        setValue((prev: any) => ({ ...prev, unit: itemValue }));
                      }}
                    >
                      {unitList.map((data: any) => (
                        <Option value={data?.value} title={data?.displayName} key={data?.value}>
                          {data?.displayName}
                        </Option>
                      ))}
                    </AntSelect>
                  </FormControl>
                </View>
              </HStack>
            </View>
          </HStack>
          {message ? <Text marginTop={2}>{message}</Text> : null}
          </View>
        </HStack>
      </View>
    );
};