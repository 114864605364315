import React, {useRef} from 'react';
import {Colors} from '../../../styles/Colors';
import ReactMarkdown from 'react-markdown';
import {Collapse, Typography, Select as AntSelect} from 'antd';
import './Styles.css';
import { useIntl } from 'react-intl';
import { Box, HStack, Text, View, Pressable, VStack, useToast, Spacer, Input, TextArea , Tooltip, Stack, Spinner } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import CommonService from '../../../services/CommonService/CommonService';
import HeartPulseSvg from '../../common/Svg/HeartPulseSvg';
import {FeedbackReasonItem, FeedbackReasonState, SelectedTemplate, Template} from './interfaces';
import UnitySvg from '../../common/Svg/UnitySvg';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import { LLMSkeletonLoader } from './LLMSkeletonLoader';
import CopySvg from '../../common/Svg/CopySvg';
import { getMlovId } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../constants';
import { USER_PREFERENCES_CODES } from '../../../constants/MlovConst';
import { useMutation, useQuery } from '@apollo/client';
import { CreateOrUpdateUserPreference, GetUserPreferencesByTypeId } from '../../../services/UserPreferences/UserPreferencesQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { getUserUUID } from '../../../utils/commonUtils';
import { debounce } from 'lodash';
import NoDataFoundWithCustomIcon from '../../common/NoDataFound/NoDataFoundWithCustomIcon';
import InfoCircleSvg from '../../common/Svg/InfoCircleSvg';
import AntIcon from 'react-native-vector-icons/AntDesign';
import DislikeSvg from '../../common/Svg/DislikeSvg';
import LikeSvg from '../../common/Svg/LikeSvg';
import {useContainerDimensions} from '../../CustomHooks/ContainerDimensionHook';
import CheckSvg from '../../common/Svg/CheckSvg';
import CloseSvg from '../../common/Svg/CloseSvg';
import { formatTimestamp } from '../../../utils/llmUtils';
const {Option} = AntSelect;
const {Panel} = Collapse;

export const LLMView = (props: { contactUUID: string | undefined }) => {
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const intl = useIntl();
  const userUUID = getUserUUID();
  const [searchQuery, setSearchQuery] = useState('')
  const [loadingDisabledFlag, setLoadingDisabledFlag] = useState(true);
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>({ templateName: '', id: '' });
  const [isActive, setIsActive] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [preferredTemplateId, setPreferredTemplateId] = useState('');
  const [loadingTemplateById, setIsloadingTemplateById] = useState(false);
  const [loadingSearchQuery, setLoadingSearchQuery] = useState(false);
  const [lastSync, setLastSync] = useState<string>('');
  const [defaultStateData, setDefaultStateData] = useState<{
    data: string;
    loading: boolean;
  }>({
    data: '',
    loading: true,
  });
  const [isTemplateListEmpty, setisTemplateListEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const toast = useToast();
  const componentRef = useRef();
  const {width, resetDimension} = useContainerDimensions(componentRef);
  const [preferenceState, setPreferenceState] = useState({
    userTemplatePreferenceId: '',
    preferredTemplateId: '',
  });
  const [reasonCode, setReasonsCode] = useState<FeedbackReasonState>({
    all: [],
    thumpsUp: [],
    thumpsDown: [],
  });

  const [showFeedbackModal, setShowFeedbackModal] = useState<{
    loading :  boolean,
     show : boolean
  }>({
    loading : false,
    show : false
  });

  const [feedback, setFeedback] = useState<{
    score: any;
    comment: string;
  }>({
    score: undefined,
    comment: '',
  });

  const [selectedTag, setSelectedTag] = useState<{
    index: string | number;
    selected: boolean;
    shortCode: string;
    textInputReason: string;
  }>({
    index: -1,
    selected: false,
    shortCode: '',
    textInputReason: '',
  });
  const lastSyncMessage = `Last Synced: ${lastSync}`
  const renderSelectElem = (
    item: any,
    isSelectedValueRender: boolean
  ) => {
    return (
      <View
        alignItems={'center'}
        my={1.5}
        maxW={isSelectedValueRender ? '100%' : undefined}
      >
        <Text
          fontSize={13}
          fontWeight={'light'}
          fontFamily={'Manrope-Regular'}
          {...(isSelectedValueRender ? {maxW: '100%', isTruncated: true} : {})}
        >
          {item?.templateName}
        </Text>
     </View>
    );
  };
  const selectedTemplateID =isTemplateListEmpty? undefined : templateList?.length && !loadingTemplateById ? preferenceState?.preferredTemplateId ? preferenceState.preferredTemplateId : selectedTemplate.id : undefined;
  const mlovData = useContext(CommonDataContext);
  const userPreferencesTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCES_TYPE,
    USER_PREFERENCES_CODES.USER_PATIENT_SYNOPSIS_DEFAULT_TEMPLATE
  );

  const getUserPreferenceFilterQuery = useQuery<{
    userPreferences: {
      id: string;
      preferencesJson: string;
      preferencesModelVersion: string;
    }[];
  }>(GetUserPreferencesByTypeId, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUUID,
      userPreferencesTypeId: userPreferencesTypeId,
    },
    onCompleted: (data) => {
      if (data && data.userPreferences && data.userPreferences.length) {
        const preferenceData = data.userPreferences?.[0];
        const userPreferredTemplateId =
          JSON.parse(preferenceData['preferencesJson'])?.templateId || '';
        const userPreferenceSynopsisTemplateId =
          JSON.parse(preferenceData['preferencesJson'])?.id || '';

        setPreferenceState((prev) => {
          return {
            ...prev,
            userTemplatePreferenceId: userPreferenceSynopsisTemplateId,
            preferredTemplateId: userPreferredTemplateId,
          };
        });

        fetchTemplateById(userPreferredTemplateId);
      }
    },
    onError: (err: any) => {
      showToast(
        toast,
        intl.formatMessage({id: 'userPreferenceFetchFailed'}),
        ToastType.error
      );
    },
  });

  const [
    addUpdatePreferenceQuery,
    {loading: isAddUpdatePreferenceQueryLoading},
  ] = useMutation(CreateOrUpdateUserPreference, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [stateData, setStateData] = useState<{
    title ?: string;
    description ?: string
  }[]>([{ title: '', description: '' }])

  const [defaultTemplate, setDefaultTemplate] = useState<{
    templateId: string
    templateName: string;
  }>({
    templateId: '',
    templateName: '',
  });
  const [resultID, setResultID]= useState('');

  useEffect(()=>{
    getFeedBackStatus(resultID,userUUID)

  }, [resultID,reasonCode.all])

  const fetchTemplateById = async (userPreferredTemplateId: string) => {
    if (userPreferredTemplateId) setIsloadingTemplateById(true);
    llmflowService
      .get(
        'summary/template/'+userPreferredTemplateId,
        {}
      )
      .then((result:any)=> {
        setTemplateList((prev) => {
          const newTemplateId = result.data.template.id;
          const isAlreadyPresent = prev.some(template => template.id === newTemplateId);
          if (!isAlreadyPresent) {
            return [
              ...prev,
              {
                templateName: result.data.template.templateName,
                id: newTemplateId
              }
            ];
          }
          return prev;
        });
        fetchData(userPreferredTemplateId, true)
        setIsloadingTemplateById(false)
        setIsError(false);
      }).catch((err:any)=>{
        setIsloadingTemplateById(false)
      })
  }

  const fetchTemplates = async () => {
    setLoadingSearchQuery(true);
    llmflowService
      .get('/summary/template/name?searchQuery=' + searchQuery, {})
      .then((result: any) => {
        setTemplateList((prev) => {
          const newTemplates = result?.data?.templateList || [];
          const uniqueNewTemplates = newTemplates.filter(
            (newTemplate: Template) =>
              !prev.some((template) => template.id === newTemplate.id)
          );
          return [...prev, ...uniqueNewTemplates];
        });
        setLoadingSearchQuery(false);
        setIsError(false);
      })
      .catch((error: any) => {
        setLoadingDisabledFlag(false);
        setIsTemplateLoading(false);
        setIsError(true);
        setStateData([
          {
            title: intl.formatMessage({id: 'errorInFetchingData'}),
            description: '',
          },
        ]);
        setLoadingSearchQuery(false);
      });
  };

  const initialFetchTemplates = async () => {
    setLoadingSearchQuery(true)
    llmflowService
      .get(
        '/summary/template/name?searchQuery=' + searchQuery,
        {}
      )
      .then((result: any) => {

        if(result?.data?.templateList.length == 0){
          setisTemplateListEmpty(true)
          fetchDefaultTemplateSummary()
        }else{

        setTemplateList((prev) => {
          const newTemplates = result?.data?.templateList || [];
          const uniqueNewTemplates = newTemplates.filter((newTemplate: Template) => !prev.some((template) => template.id === newTemplate.id));
          return [...prev, ...uniqueNewTemplates];
        });
          if (preferenceState?.preferredTemplateId) {
            fetchTemplateById(preferenceState?.preferredTemplateId)
          } else {
          setSelectedTemplate((prev: SelectedTemplate) => {
            return {
              ...prev,
              templateName: result?.data?.templateList?.[0]?.templateName,
              id: result?.data?.templateList?.[0]?.id,

            };
          });
          fetchData(
            result?.data?.templateList?.[0]?.id,
            true,
          );
        }
          setDefaultTemplate({
          templateName: result?.data?.templateList?.[0]?.templateName,
          templateId: result?.data?.templateList?.[0]?.id,
        });
      }
        setLoadingSearchQuery(false)
        setIsError(false);
        }
      )
      .catch((error: any) => {
        setLoadingDisabledFlag(false);
        setIsTemplateLoading(false)
        setIsError(true);
        setStateData([{
          title: intl.formatMessage({ id: 'errorInFetchingData' }),
          description: ''
        }])
        setLoadingSearchQuery(false)
      });
  };


  const fetchData = (
    templateId?: string,
    isCached?: boolean,
  ) => {
    llmflowService
      .post(`/v3/summary`, {
        contactUuid: props?.contactUUID,
        templateId: templateId,
        isCatched: isCached,
      })
      .then((result: any) => {
        setIsActive(false);
        setLastSync(formatTimestamp(result?.data?.syncDate))
        setStateData(result?.data?.response);
        setIsTemplateLoading(false);
        setLoadingDisabledFlag(false);
        setIsError(false);
        setResultID(result?.data?.id);
        getFeedBackStatus(result?.data?.id, userUUID);
        setShowFeedbackModal( (prev)=>({...prev, show:false}));
        setSelectedTag({
          index: -1,
          selected: false,
          shortCode: '',
          textInputReason: '',
        });
      })
      .catch((error: any) => {
        setIsActive(false);
        setLoadingDisabledFlag(false);
        setIsTemplateLoading(false);
        setIsError(true);
        setStateData([
          {
            title: intl.formatMessage({id: 'errorInFetchingData'}),
            description: '',
          },
        ]);
      });
  };

  const fetchDefaultTemplateSummary = async () => {
    llmflowService
      .post(`/summary`, {
        contactUuid: props?.contactUUID,
        templateStr: '',
        isCatched: true,
      })
      .then((result: any) => {
        setIsActive(false);
        setStateData([
          {
            title: 'Default',
            description: result?.data?.response,
          },
        ]);
        setIsTemplateLoading(false);
        setLoadingDisabledFlag(false);
        setIsError(false);
      })
      .catch((error: any) => {
        setIsActive(false);
        setLoadingDisabledFlag(false);
        setIsError(true);
        setDefaultStateData({
          data: intl.formatMessage({id: 'errorInFetchingData'}),
          loading: false,
        });
      });
  };

  const getHashtags = (data: string[]) => {
    return data?.map((item) => {
      return (
        <>
          {item ? (
            <View
              mt={1}
              borderRadius={4}
              px={1}
              bgColor={Colors.FoldPixel.GRAY50}
              mr={1}
              borderWidth={0.1}
              borderColor={Colors.FoldPixel.GRAY200}
            >
              <Text color={Colors.FoldPixel.GRAY300}>{item}</Text>
            </View>
          ) : null}
        </>
      );
    });
  };

  const extractHashTags = (description: string) => {
    const regex = /#[^\s#]*/g;
    const hashtags = description.match(regex);
    if (!hashtags) {
      return [];
    }
    return hashtags.map((tag) => tag.substring(1));
  };

  const getSummaryText = (text: string) => {
    const hashtagIndex = text?.indexOf('#');
    const newText = hashtagIndex !== -1 ? text.slice(0, hashtagIndex) : text;
    return newText;
  };

  const findSpaceIndex = (text: string, limit: any) => {
    if (text.length <= limit) {
      return text.length;
    }
    let index = limit;
    while (index >= 0 && text[index] !== ' ') {
      index--;
    }
    return index;
  };

  const fetchReasonCodes = () => {
    llmflowService
      .get(`/v1/feedback/reason_code`)
      .then((result: any) => {
        const filteredReasonCodeThumbDown = result?.data?.reason_codes?.filter((reason: any) => !reason.reaction_code || reason.reaction_code==null);
        const filteredReasonCodeThumbup = result?.data?.reason_codes?.filter((reason: any) => reason.reaction_code || reason.reaction_code==null);
        setReasonsCode({ all: result?.data?.reason_codes,thumpsUp :filteredReasonCodeThumbup ,thumpsDown:filteredReasonCodeThumbDown});
      })
      .catch((error: any) => {
      });
  };

  const loaderTemeplateValues = [
    intl.formatMessage({id: 'analyzingMedications'}),
    intl.formatMessage({id: 'analyzingProcedures'}),
    intl.formatMessage({id: 'synthesizingLearnings'}),
    intl.formatMessage({id: 'curatingSynopsis'}),
  ];

  useEffect(() => {
    initialFetchTemplates();
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [searchQuery]);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const getTotalSummary = () => {
    const concatenatedText = stateData?.map(data => `${data?.title}: ${data?.description}`).join('\n\n');
    return concatenatedText;
  }

  const callAddUpdateSynopsisTemplatePreference = async (templateId: string) => {
    const updateSynopsisTemplatePreferenceObject: any = {
      userId: userUUID,
      userPreferencesTypeId: userPreferencesTypeId,
      preferencesJson: JSON.stringify({ templateId: templateId }),
      preferencesModelVersion: 'v1',
    }


    if (userPreferencesTypeId) {
      updateSynopsisTemplatePreferenceObject.id = userPreferencesTypeId
    }

    await addUpdatePreferenceQuery({
      variables: {
        object: updateSynopsisTemplatePreferenceObject,
      },
      onCompleted: (response) => {
        const preferenceData = response?.createUserPreference;
        const userPreferredTemplateId = JSON.parse(preferenceData['preferencesJson'])?.templateId || '';
        const userTemplatePreferenceId = JSON.parse(preferenceData['preferencesJson'])?.id || '';
        setPreferredTemplateId(userPreferredTemplateId)

        setPreferenceState((prev) => {
          return {
            ...prev,
            userTemplatePreferenceId: userTemplatePreferenceId,
            preferredTemplateId: userPreferredTemplateId
          }
        })
      },
      onError: (error: any) => {
      }
    });
  }

  const sliceSynopsisData = defaultStateData.data.slice(
    0,
    findSpaceIndex(defaultStateData.data, 600)
  );

  const postFeedback = () => {
    llmflowService
      .post(`/v1/feedback`, {
        user_id: userUUID,
        result_id: resultID,
        score: feedback.score,
        comment:
          selectedTag.shortCode === 'Other'
            ? selectedTag.textInputReason
            : selectedTag.shortCode,
      })
      .then((result: any) => {
        setShowFeedbackModal({loading : false,show :false});
      })
      .catch((error: any) => {
        setShowFeedbackModal({loading : false,show :false});
        showToast(
          toast,
          'Unable to save the Feedback',
          ToastType.error,
          1000
        );
        getFeedBackStatus(resultID,userUUID)
      });
  };

  const getFeedBackStatus =( resultID : any , userID : any)=>{
     resultID  &&
    (llmflowService.get(`v1/feedback/status`, {
      params: {
        result_id: resultID,
        user_id : userID
      },
    })
    .then((result: any) => {
      setFeedback({
        comment: result?.data?.data?.comment,
        score:result?.data?.data?.score,
      })
      if (result?.data?.data?.score !== -1 && reasonCode.all.length) {
        const isShortCodePresent = reasonCode.all.some(
          (item) => item.short_code === result?.data?.data?.comment
        );
        if (isShortCodePresent) {
          setSelectedTag((prev) => ({
            ...prev,
            index: result?.data?.data?.comment,
            selected: true,
            shortCode: result?.data?.data?.comment,
            textInputReason: '',
          }));
        } else if (result?.data?.data?.comment) {
            setSelectedTag((prev) => ({
              ...prev,
              index: 'Other',
              selected: true,
              shortCode: 'Other',
              textInputReason: result?.data?.data?.comment,
            }));
        }
      } else {
        setSelectedTag({
          index: -1,
          selected: false,
          shortCode: '',
          textInputReason: '',
        });
      }
    })
    .catch((error: any) => {
    }));
  };

  useEffect(() => {
    fetchReasonCodes();
  }, []);

  const renderTags = (reasonCode: FeedbackReasonItem, index: string) => {
    const isSelected = selectedTag.index === index && selectedTag.selected;

    return (
          <Pressable
            key={reasonCode.id}
            onPress={() => {
              setSelectedTag((prev) => {
                return {
                  ...prev,
                  index: index,
                  selected: !isSelected,
                  shortCode: !isSelected? reasonCode.short_code :'',
                };
              });
            }}
          >
            <View
              style={{
                marginTop: 4,
                borderRadius: 4,
                paddingHorizontal: 4,
                backgroundColor: isSelected
                  ? Colors.FoldPixel.PRIMARY100
                  : Colors.FoldPixel.GRAY50,
                marginRight: 4,
                borderWidth: 0.1,
                borderColor: Colors.FoldPixel.GRAY50,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  color:isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500,
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                {reasonCode.short_code}
              </Text>
              {isSelected && (
                <Pressable
                  onPress={() => {
                    setSelectedTag((prev) => {
                      return {
                        ...prev,
                        previndex:undefined,
                        selected: false,
                        shortCode: '',
                      };
                    });
                  }}
                >
                  <AntIcon color={isSelected ? Colors.FoldPixel.PRIMARY300 :  Colors.Custom.Gray500} name='close' />
                </Pressable>
              )}
            </View>
          </Pressable>
     );
  };

  return (
    <div>
      <Box
        mb={5}
        shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
        borderRadius={8}
      >
        <Collapse
          className='llm-collapse'
          defaultActiveKey={[1]}
          collapsible='header'
          style={{
            borderWidth: 0.5,
            borderColor: Colors.Custom.ContainerBGColor,
          }}
          expandIconPosition='end'
        >
          <Panel
            header={
              <HStack alignItems={'center'} space={3}>
                <HeartPulseSvg />
                <VStack>
                  <Text
                    color={Colors.Custom.ProfileViewTextColor}
                    fontSize={20}
                    fontWeight={600}
                  >
                    {intl.formatMessage({ id: 'patientSynopsis' })}
                  </Text>
                </VStack>
              </HStack>
            }
            extra={
              <AntSelect
                placement='bottomRight'
                placeholder= {isTemplateListEmpty && 'Default'  }
                style={{ width: 150, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}
                loading={ isTemplateListEmpty ? false : loadingDisabledFlag || loadingTemplateById || loadingSearchQuery }
                allowClear={false}
                value={{
                  key: selectedTemplateID,
                  label: renderSelectElem(
                    templateList.filter(
                      (item: any) => item?.id === selectedTemplateID
                    )?.[0],
                    true
                  ),
                  value: selectedTemplateID,
                }}
                disabled={ isTemplateListEmpty ? true : loadingDisabledFlag}
                showSearch
                onSearch={debounce((inputValue: string) => {
                  setSearchQuery(inputValue)
                }, 500)}
                filterOption={(inputValue: string, option: any) => {
                  return (option?.title || '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                }}
                onChange={(templateId: any) => {
                  setLastSync('');
                  setShowMore(false);
                  setLoadingDisabledFlag(true);
                  setIsTemplateLoading(true)
                  const selectedTemplate = templateList.find((template) => template?.id == templateId);
                  if (selectedTemplate) {
                    setSelectedTemplate({
                      id: selectedTemplate?.id || '',
                      templateName: selectedTemplate?.templateName || '',
                    });
                    fetchData(templateId, true);
                    callAddUpdateSynopsisTemplatePreference(selectedTemplate.id ? selectedTemplate.id : preferredTemplateId)
                  }
                  setSelectedTag({
                    index: -1,
                    selected: false,
                    shortCode: '',
                    textInputReason: '',
                  });
                }}
              >
                !isTemplateListEmpty ?
                {templateList.map((template: any) => {
                  return (
                    <Option
                      value={template?.id}
                      key={template?.id}
                      title={template?.templateName}
                    >
                      {template.templateName}
                    </Option>
                  );
                })} : null;
              </AntSelect>
            }
            key={'1'}
          >
            <View padding={3} gap={2}>
              {   isTemplateLoading ?
                <View padding={4} height={200} justifyContent={'center'}>
                  <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'llmview' + 'skeleton'}/>
                </View> :
                ((  Array.isArray(stateData)  &&  stateData?.length)  ?
                <View>
                  <View gap={3} maxHeight={expanded ? '50%' : 200} overflow={'clip'}>
                    {stateData.map((data: any, index: number) => (
                      <VStack
                        key={index}
                        borderColor={Colors.Custom.Gray200}
                        borderWidth={'1'}
                        borderRadius={12}
                      >
                        <View
                          borderBottomWidth={1}
                          borderColor={Colors.Custom.Gray200}
                          paddingY={2}
                          paddingX={3}
                        >
                          <Text fontWeight={500} fontSize={12} lineHeight={14.4} color={Colors.FoldPixel.GRAY300}>{data.title}</Text>
                        </View>
                        <View
                          paddingTop={3}
                          paddingX={3}
                        >
                          <Text fontSize={14} fontWeight={'400'} lineHeight={16.8} color={Colors.FoldPixel.GRAY400}>
                            <ReactMarkdown>{getSummaryText(data.description)}</ReactMarkdown>
                          </Text>
                        </View>

                        <View>
                          {extractHashTags(data?.description).length > 0 ? (
                            <View flexWrap={'wrap'} flexDir={'row'} paddingX={4} paddingY={2}>
                              {getHashtags(extractHashTags(data?.description))}
                            </View>
                          ) : null}
                        </View>
                      </VStack>
                    ))}
                  </View>
                  <View position={'relative'}>
                    <div className={expanded ? '' : 'custom-overflow'} />
                    {stateData.length > 0 && (
                      <Text fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.Custom.Primary300} onPress={toggleExpand} paddingX={3} paddingTop={expanded ? 2 : 0}>
                        {expanded ? 'Show Less' : 'Show More'}
                      </Text>
                    )}
                  </View>
                </View> :
                <NoDataFoundWithCustomIcon
                icon={<InfoCircleSvg></InfoCircleSvg>}
                displayString={intl.formatMessage({id: 'noDataFound'})}
              ></NoDataFoundWithCustomIcon>

                )
              }
              <HStack
                space={2}
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingVertical: 5,
                  borderBottomLeftRadius: 9,
                  borderBottomRightRadius: 9,
                }}
              >
                <View
                  style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
                >
                  <UnitySvg iconKey={'llmview' + 'UnitySvg'}/>
                  <Text
                    marginLeft={2}
                    size={'xsNormal'}
                    fontWeight={600}
                    noOfLines={1}
                    style={{
                      // @ts-ignore: Unreachable code error
                      backgroundImage:
                        'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      textAlign: 'center',
                    }}
                  >
                    {intl.formatMessage({ id: 'poweredByUnity' })}
                  </Text>

                  <Text
                    marginLeft={2}
                    size={'xsNormal'}
                    fontWeight={550}
                    noOfLines={1}
                    style={{
                      color: Colors.FoldPixel.GRAY200,
                      fontFamily: 'Courier',
                      textAlign: 'center',
                    }}
                  >
                    {intl.formatMessage({ id: 'beta' })}
                  </Text>
                </View>
                {(!isTemplateLoading) && stateData?.length ? (
                  <>
                  <Tooltip label={intl.formatMessage({id: 'dislike'})} placement='top' hasArrow>
                    <Pressable
                      flexDirection={'row'}
                      alignItems={'center'}
                      onPress={() => {
                        setFeedback({score: 0, comment: ''});
                        setShowFeedbackModal( (prev)=>({...prev, show:true}));
                      }}
                    >
                        <DislikeSvg
                          isActive={feedback.score === 0 ? true : false}
                          keyy={'llmview'}
                        />
                    </Pressable>
                    </Tooltip>
                    <Tooltip label={intl.formatMessage({id: 'like'})} placement='top' hasArrow>
                    <Pressable
                      flexDirection={'row'}
                      alignItems={'center'}
                      onPress={() => {
                        setFeedback({score: 1, comment: ''});
                        setShowFeedbackModal( (prev)=>({...prev, show:true}));
                      }}
                    >
                        <LikeSvg
                          isActive={feedback.score === 1 ? true : false}
                          keyy={'llmview'}
                        />
                    </Pressable>
                    </Tooltip>
                  </>
                ) : null}
                { !isTemplateLoading && Array.isArray(stateData) &&
                  <View paddingBottom={1}  >
                    <Typography.Text
                      copyable={{
                        text: Array.isArray(stateData) ? getTotalSummary() : '',
                        icon: <CopySvg />,
                      }}
                    ></Typography.Text>
                  </View>
                }
                <Tooltip isDisabled={!lastSync} label={lastSyncMessage}  placement='top right' hasArrow>
                <Pressable
                  marginLeft={2}
                  flexDirection={'row'}
                  alignItems={'center'}
                  onPress={() => {
                    setIsTemplateLoading(true);
                    setIsActive(true);
                    setLoadingDisabledFlag(true);
                    const data = {
                      templateId:
                        selectedTemplate?.id || defaultTemplate?.templateId,
                      isCatched: false,
                    };
                    fetchData(data.templateId, data.isCatched);
                    setStateData([
                      {
                        title: '',
                        description: '',
                      },
                    ]);
                    setSelectedTemplate((prev) => {
                      return {
                        ...prev,
                        templateName: selectedTemplate?.templateName,
                        id: selectedTemplate?.id,
                      };
                    });
                  }}
                >
                  <Tooltip label='Refresh'>
                    <HardRefreshIconSvg isActive={isActive} iconKey={'llmview'}/>
                  </Tooltip>
                </Pressable>
                </Tooltip>
              </HStack>
              {!isTemplateLoading && showFeedbackModal.show && reasonCode.all && (
                <VStack
                  borderColor={Colors.Custom.Gray200}
                  borderWidth={'1'}
                  borderRadius={12}
                  flex={1}
                >
                  <HStack padding={2} flex={1}>
                    <Text
                      fontWeight={500}
                      fontSize={14}
                      lineHeight={14.4}
                      color={Colors.FoldPixel.GRAY300}
                      flex={2}
                    >
                      {feedback.score === 0 ? 'Help us to Improve.' : null}
                      {feedback.score === 1 ? 'Thanks! Any more feedback?' : null}
                    </Text>
                    <Pressable
                      marginLeft={2}
                      onPress={() => {
                        postFeedback();
                        setShowFeedbackModal( (prev)=>({...prev, loading:true}));
                      }}
                      flex={0.1}
                    >
                      {showFeedbackModal.loading ?
                        <Spinner></Spinner> :
                        <CheckSvg></CheckSvg>}
                    </Pressable>
                    <Pressable
                      marginLeft={2}
                      flex={0.1}
                      onPress={() => {
                        setShowFeedbackModal( (prev)=>({...prev, show:false}));
                        setFeedback({score: undefined, comment: ''});
                        getFeedBackStatus(resultID,userUUID)
                      }}
                    >
                      <CloseSvg></CloseSvg>
                    </Pressable>
                  </HStack>
                  <View
                    flexWrap={'wrap'}
                    flexDir={'row'}
                    paddingX={2}
                    paddingBottom={selectedTag.shortCode === 'Other' ? 0 : 2}
                  >
                    <>
                      {feedback.score == 0 &&
                        reasonCode.thumpsDown.map((name: FeedbackReasonItem, index: number) =>
                          renderTags(name, name.short_code )
                        )}
                      {feedback.score == 1 &&
                        reasonCode.thumpsUp.map((name: FeedbackReasonItem, index: number) =>
                          renderTags(name, name.short_code)
                        )}
                    </>
                  </View>

                  {selectedTag.shortCode === 'Other'&& selectedTag.index && (
                    <HStack flex={1} padding={2}>
                      <TextArea
                       value={selectedTag.textInputReason}
                      _focus={{
                        borderColor: Colors.Custom.Gray300,
                      }}
                      borderColor={Colors.Custom.Gray300}
                      placeholderTextColor={Colors.Custom.Gray300}
                      width={'100%'}
                      numberOfLines={5}
                      onChangeText={(input) => {
                        setSelectedTag((prev: any) => {
                          return {
                            ...prev,
                            textInputReason: input,
                          };
                        });
                      }}
                      placeholder='Provide Additional Feedback'
                    />
                    </HStack>
                  )}
                </VStack>
              )}
            </View>
          </Panel>
        </Collapse>
      </Box>
    </div>
  );
};
