import { DATE_FORMATS } from "../../../constants";
import { IContactCareProgramResponse, IContactCareProgramSteps, IStepsLog } from "../../../services/ContactCareProgram/interface";
import { getDateStrFromFormat } from "../../../utils/DateUtils";
import { CARE_PROGRAM_STEP_TYPE } from "../MemebersView/constant";


export const getAttributeByKey = (attributeKey: string, contactCarePrograms: IContactCareProgramResponse): string | undefined => {
  if (!contactCarePrograms?.additionalAttributes?.length) {
    return undefined;
  }
  const findAttribute = (contactCarePrograms.additionalAttributes).find(additionalAttribute => {
    return additionalAttribute?.attributeKey === attributeKey;
  });
  if (!findAttribute) {
    return undefined;
  }
  const attributeDateTypeCode = findAttribute.attributeValueDataTypeCode;
  let attributeValue: any = undefined;
  switch (attributeDateTypeCode) {
    case 'string':
      attributeValue = findAttribute?.attributeValue?.valueString || '';
      break;
    case 'date':
      attributeValue = getDateStrFromFormat(findAttribute?.attributeValue?.value, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
      break;
  }
  return attributeValue;
};

const getEnrollmentStepId = (contactCarePrograms: IContactCareProgramResponse): string | undefined => {
  const findContactCareProgramStep: IContactCareProgramSteps | undefined = (contactCarePrograms?.contactCareProgramSteps || []).find(contactCareProgramStep => {
    return contactCareProgramStep?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.ENROLLMENT;
  });
  return findContactCareProgramStep?.id;
}


export const getProgramEnrollmentDateAndStatus = (contactCarePrograms: IContactCareProgramResponse, fetchStatus?: boolean) => {
  let resultString = ``;
  if (contactCarePrograms?.enrollmentLog?.enrollmentDateTime) {
    resultString = getDateStrFromFormat(contactCarePrograms.enrollmentLog.enrollmentDateTime, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
  }
  if (!fetchStatus) {
    return resultString;
  }
  const enrollmentStepId: string | undefined = getEnrollmentStepId(contactCarePrograms);
  if (fetchStatus && enrollmentStepId) {
    const findStepLog: IStepsLog | undefined = (contactCarePrograms?.stepsLog || []).find(currentStepsLog => {
      return currentStepsLog?.careProgramStepId === enrollmentStepId;
    });
    if (!findStepLog?.careProgramStepId && findStepLog?.subStep?.careProgramSubStepType?.value) {
      resultString = `${findStepLog.subStep.careProgramSubStepType.value}`;
    }
  }
  return resultString;
}
