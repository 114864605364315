import {Badge, Text} from 'native-base';
import UserSvg from '../../../common/Svg/UserSvg';
import {Colors} from '../../../../styles';
import Stack from '../../../common/LayoutComponents/Stack';
import {IEFaxInboxMembers, IGetInboxMemberBadge} from './interface';

export const GetInboxMemberBadge = (props: IGetInboxMemberBadge) => {
  return (
    <Stack direction="row" style={{flexWrap: 'wrap'}}>
      {props?.inboxMembers?.map((item: IEFaxInboxMembers, index: any) => {
        return (
          <Badge
            style={{marginVertical: 4, marginHorizontal: 4}}
            key={index}
            background={Colors.Custom.Gray200}
            alignSelf="center"
            variant="outline"
            borderWidth={0}
            borderRadius={10}
          >
            <Stack direction="row" style={{alignItems: 'center'}}>
              <UserSvg width={15} height={15} />
              <Text paddingLeft={1}>{item?.user?.name}</Text>
            </Stack>
          </Badge>
        );
      })}
    </Stack>
  );
};
