import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ISearchFieldProps} from '../../../Interfaces';
import {CareJourneyQueries} from '../../../services';
import { SEARCH_ACTIVE_CARE_JOURNEYS } from '../../../services/CareJourney/CareJourneyQueries';
import { ICareJourneyStatusIDs } from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../constants';
import { CARE_PROGRAM_STATUS_CODES } from '../../../constants/MlovConst';

const CareJourneySearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [careJourneyData, setCareJourneyData] = useState<any[]>([]);
  const mlovData = useContext(CommonDataContext);
  const careJourneyStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STATUS
    ) || [];

  const careJourneyStatusIds: ICareJourneyStatusIDs = {
    draft: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.DRAFT
    ),
    published: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.ACTIVE
    ),
  };
  const [searchCareJourneys] = useLazyQuery(
    SEARCH_ACTIVE_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  useEffect(() => {
    if (value && value.id && careJourneyData.length === 0) {
      setCareJourneyData([
        {
          id: value.id,
          title: value.title,
          productId: value.productId,
        },
      ]);
    }
    searchCareJourney('', false);
  }, []);

  const searchCareJourney = (searchString: string, checkLength?: boolean) => {
    setCareJourneyData([]);
    setLoading(true);
    searchCareJourneys({
      variables: {
        searchString: `%${searchString}%`,
        statusId: careJourneyStatusIds.published
      },
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.careJourneys &&
          response.data.careJourneys.length > 0
        ) {
          setCareJourneyData(response.data.careJourneys);
        }
        setLoading(false);
      })
      .catch((error: any) => {

        setLoading(false);
      });
  };

  const getDataFromId = (id: any) => {
    const matchedData = careJourneyData.filter((careJourney) => {
      return careJourney.id === id;
    });
    if (matchedData.length > 0) {
      return {
        id: matchedData[0].id,
        title: matchedData[0].title,
        productId: matchedData[0].productId,
        startDateTime: matchedData[0]?.startDateTime,
        careJourneyLocations: matchedData[0]?.careJourneyLocations,
      };
    }
  };

  return (
    <Select
      size="large"
      showSearch
      allowClear
      filterOption={false}
      disabled={props?.isDisabled}
      value={value && !loading ? value.id : undefined}
      onSearch={debounce(searchCareJourney, 500)}
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
          searchCareJourney('', false);
        }
      }}
      placeholder="Search Care Journey"
      loading={loading}
      notFoundContent={loading && <Spin size="small" />}
      style={{height: '40px'}}
      className={isShowError && !value ? 'field-error' : ''}
    >
      {careJourneyData.map((careJourney, index) => {
        return (
          <Select.Option
            key={`${careJourney.id}_${index}`}
            value={careJourney.id}
          >
            {careJourney.title}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CareJourneySearch;
