// Views
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { Button, IconButton, Text, Tooltip, View, Pressable, HStack, VStack, Spacer } from 'native-base';
import { IntlShape } from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';

import moment from 'moment';
import { Colors } from '../../../../../styles';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import { getOnFocusButtonStyles } from '../../../../../utils/commonUtils';
import { IFormCategory } from '../../interfaces';
import { FormReportScreen } from '../../Analytics';
import { getDateStrFromFormat } from '../../../../../utils/DateUtils';
import IsShownToPatientIcon from '../../../../../assets/Icons/IsShownToPatientIcon';
import { checkCarePlanCategoryForm, checkNoteCategoryForm, checkProviderRequestForm, isDisallowedSendForm, isDisallowedViewAnalytics } from './FormListUtils';
import { DISALLOWED_SEND_FORM_CATEGORIES } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';

export const getColumns = (
  intl: IntlShape,
  onEdit: (form: any) => void,
  onDelete: (form: any) => void,
  onPrint: (form: any) => void,
  onFormResponse: (form: any) => void,
  onFormPending: (form: any) => void,
  onDefaultFormChange: (isSelected: boolean, form: any) => void,
  categoryList: IFormCategory[],
  onAddFormClone: (form: any) => void,
  onSendFormEmail: (form: any) => void,
  config: {
    filterIds: string[],
    sortBy: string,
    sortOrder: SortOrder,
    isFormAnalyticsEnabled: boolean,
  },
  onGraphClick?: (form: any, type?: string) => void
): any[] => {
  const { filterIds, sortBy, sortOrder, isFormAnalyticsEnabled } = config;

  const columns: any[] = [
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        >
          {intl.formatMessage({ id: 'name' })}
        </Text>
      ),
      key: 'name',
      dataIndex: 'name',
      width: isFormAnalyticsEnabled ? '36%' : '25%',
      fixed: 'left',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortBy === 'name' ? sortOrder : null,
      render: (name: string, form: any) => {
        return (
        <HStack alignItems={"center"}>
            <VStack space={1} maxW={"100%"}>
            <Text
              fontSize={14}
              onPress={() => onEdit(form)}
              color={Colors.Custom.Gray900}
              flexWrap={'wrap'}
            >
              {name}
            </Text>
            {!!form.createdOn && (
              <Text
                fontSize={12}
                color={Colors.Custom.Gray500}
                onPress={() => onEdit(form)}
              >
                {`Created on ${getDateStrFromFormat(form.createdOn, 'MMM DD, YYYY')}`}
              </Text>
            )}
          </VStack>
          <Spacer />
          {form?.isShownToPatient && (
              <Tooltip
                label={intl.formatMessage({id: 'isShownToPatientTooltip'})}
              >
                <IsShownToPatientIcon />
              </Tooltip>
            )}
        </HStack>
        );
      },
    },
    // There is backend dependency for this one
    // {
    //   title: (
    //     <Text
    //       size={'xsMedium'}
    //       style={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //     >
    //       {intl.formatMessage({id: 'sentForms'})}
    //     </Text>
    //   ),
    //   key: 'sentForms',
    //   width: '15%',
    //   render: (form: any) => {
    //     const count = form?.formLog_aggregate?.aggregate?.count;
    //     return (
    //       <View flexDirection="row">
    //         {!count ? (
    //           <Text size={'smMedium'} color={Colors.Custom.Gray900}>
    //             No Forms Sent
    //           </Text>
    //         ) : (
    //           <Text
    //             size={'smMedium'}
    //             color={Colors.Custom.Gray900}
    //             onPress={() => onFormResponse(form)}
    //           >
    //             {count + ' ' + 'responses'}
    //           </Text>
    //         )}
    //       </View>
    //     );
    //   },
    // },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'responses'})}
        </Text>
      ),
      // sorter: (a: any, b: any) => a?.count - b?.count,
      // sortDirections: ['ascend', 'descend'],
      // key: 'responses',
      width: isFormAnalyticsEnabled ? 0 : '15%',
      key: 'submittedCount',
      dataIndex: 'submittedCount',
      // sorter: (a: any, b: any) => {
      //   const firstParam = a?.submittedCount || 0;
      //   const secondParam = b?.submittedCount || 0;
      //   if (sortOrder == 'ascend') {
      //     return firstParam < secondParam ? -1 : 1;
      // }
      // // if descending, highest sorts first
      // return secondParam < firstParam ? 1 : -1;
      // },
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: sortBy === 'submittedCount' ? sortOrder : null,
      render: (submittedCount: number | any, form: any) => {
        const count = submittedCount || form?.submittedCount;
        return (
          <View flexDirection="row">
            {!count ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray400}>
                No response
              </Text>
            ) : (
              <Pressable
                onPress={() => {
                  onFormResponse(form);
                }}>
                  <HStack flex={1} space={2} alignItems={'center'} justifyContent={'center'}>
                    {/* <Badge
                      rounded={'full'}
                      bgColor={Colors.Custom.brandAccentColor}>
                        <Text color={'white'} fontWeight={600} fontSize={12}>
                        {count}
                        </Text>
                    </Badge> */}
                    <Text fontWeight={600} color={Colors.Custom.mainPrimaryPurple} fontSize={14}  >
                      {count} { count > 1 ? 'Responses' : 'Response'}
                    </Text>
                  </HStack>
              </Pressable>
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'pending'})}
        </Text>
      ),
      // sorter: (a: any, b: any) => a?.count - b?.count,
      // sortDirections: ['ascend', 'descend'],
      // key: 'responses',
      width: isFormAnalyticsEnabled ? 0 : '15%',
      key: 'subcount',
      dataIndex: 'pendingCount',
      // sorter: (a: any, b: any) => {
      //   const firstParam = a?.pendingCount || 0;
      //   const secondParam = b?.pendingCount || 0;
      //   if (sortOrder == 'ascend') {
      //     return firstParam < secondParam ? -1 : 1;
      // }
      // // if descending, highest sorts first
      // return secondParam < firstParam ? 1 : -1;
      // },
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: sortBy === 'pendingCount' ? sortOrder : null,
      render: (pendingCount: number | any, form: any) => {
        const count = pendingCount || form?.pendingCount;
        return (
          <View flexDirection="row">
            {
            !count ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray400}>
                No pending
              </Text>
            ) :
            (
              <Pressable
                onPress={() => {
                  onFormPending(form);
                }}>
                  <HStack flex={1} space={2} alignItems={'center'} justifyContent={'center'}>
                    <Text fontWeight={600} color={Colors.Custom.mainPrimaryPurple} fontSize={14}  >
                      {count} {'Pending'}
                    </Text>
                  </HStack>
              </Pressable>
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        >
          {intl.formatMessage({ id: 'formCategory' })}
        </Text>
      ),
      filterIcon: (filtered: boolean) => (
        <Feather name="filter" color={filterIds.length ? Colors.Custom.PrimaryColor : Colors.Custom.IATextColor} />
      ),
      filters: categoryList.map((item) => ({
        text: item.name,
        value: item.id,
      })),
      key: 'categoryId',
      dataIndex: 'categoryId',
      filteredValue: filterIds,
      width: '20%',
      render: (categoryId: string, form: any) => {
        return (
          <View flexDirection="row">
            <Text
              onPress={() => onEdit(form)}
              fontSize={14}
              color={Colors.Custom.Gray500}
            >
              {form?.formCategory?.name}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        >
          {intl.formatMessage({ id: 'responses' })}
        </Text>
      ),
      // sorter: (a: any, b: any) => a?.count - b?.count,
      // sortDirections: ['ascend', 'descend'],
      // key: 'responses',
      width: isFormAnalyticsEnabled ? '33%' : 0,
      key: 'allResponses',
      // sorter: (a: any, b: any) => {
      //   const firstParam = a?.submittedCount || 0;
      //   const secondParam = b?.submittedCount || 0;
      //   if (sortOrder == 'ascend') {
      //     return firstParam < secondParam ? -1 : 1;
      //   }
      //   // if descending, highest sorts first
      //   return secondParam < firstParam ? 1 : -1;
      // },
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: sortBy === 'submittedCount' ? sortOrder : null,
      render: (submittedCount: number | any, form: any) => {
        const isNoteCategoryForm = checkNoteCategoryForm(form?.formCategory);

        const hideResponses = checkCarePlanCategoryForm(form?.formCategory);

        if (hideResponses) {
          return <></>;
        }

        return (
          <View flexDirection="row">
            <View flexDirection="row" style={{
              backgroundColor: Colors.Custom.Gray100,
              borderRadius: 20,
              paddingLeft: 12,
              paddingRight: 0,
            }}>
             {!isNoteCategoryForm  && (
              <Tooltip label={"Sent: " + form?.formLogCount} placement="top">
                  <Pressable onPress={() => onGraphClick?.(form)} flexDirection="row" alignItems={'center'} style={{
                    paddingVertical: 8,
                    borderRightColor: Colors.Custom.Gray200,
                    borderRightWidth: 1,
                    width: 70
                  }}>

                    <Feather size={16} name="send" color={Colors.Custom.Gray400} style={{
                      marginHorizontal: 6
                    }} />

                    <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} style={{
                      marginRight: 6
                    }} size={'smMedium'} color={Colors.Custom.Gray500}>
                      {form?.formLogCount || 0}
                    </Text>
                  </Pressable>
                </Tooltip>
              )}
              <Tooltip label={`${intl.formatMessage({id: isNoteCategoryForm ? 'signedOrAmmended' : 'responded'})}: ` + form?.submittedCount} placement="top">
                <Pressable onPress={() => { onGraphClick?.(form, FormReportScreen.RESPONDED) }} flexDirection="row" alignItems={'center'} style={{
                  paddingVertical: 8,
                  borderRightColor: Colors.Custom.Gray200,
                  borderRightWidth: 1,
                  width: 70
                }}>

                  <Ionicons size={20} name="checkmark-done-outline" color={Colors.Custom.SuccessColor} style={{
                    marginHorizontal: 6
                  }} />

                  <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} style={{
                    marginRight: 6
                  }} size={'smMedium'} color={Colors.Custom.Gray500}>
                    {form?.submittedCount || 0}
                  </Text>
                </Pressable>
              </Tooltip>
              <Tooltip label={"In Progress: " + form?.draftCount} placement="top">

                <Pressable onPress={() => onGraphClick?.(form)} flexDirection="row" alignItems={'center'} style={{
                  paddingVertical: 8,
                  borderRightColor: Colors.Custom.Gray200,
                  borderRightWidth: !isNoteCategoryForm ? 1 : 0,
                  width: 70
                }}>
                  <Feather size={16} name="clock" color={Colors.warning[400]} style={{
                    marginHorizontal: 6
                  }} />
                  <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} style={{
                    marginRight: 6,
                  }} size={'smMedium'} color={Colors.Custom.Gray500}>
                    {form?.draftCount || 0}
                  </Text>
                </Pressable>
              </Tooltip>
              {!isNoteCategoryForm  && (
                <Tooltip label={"Not Started: " + form?.pendingCount} placement="top">

                  <Pressable onPress={() => onGraphClick?.(form, FormReportScreen.PENDING)} flexDirection="row" alignItems={'center'} style={{
                    paddingVertical: 8,
                    width: 70
                  }}>
                    <Feather size={16} name="eye-off" color={Colors.Custom.Gray400} style={{
                      marginHorizontal: 6
                    }} />


                    <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} size={'smMedium'} color={Colors.Custom.Gray500}>
                      {form?.pendingCount || 0}
                    </Text>
                  </Pressable>
                </Tooltip>
              )}
            </View>

          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        >
          {intl.formatMessage({ id: 'lastUpdated' })}
        </Text>
      ),
      // sorter: (a: any, b: any) => a?.count - b?.count,
      // sortDirections: ['ascend', 'descend'],
      // key: 'responses',
      width: isFormAnalyticsEnabled ? '20%' : 0,
      key: 'updatedOn',
      dataIndex: 'updatedOn',
      sorter: (a: any, b: any) => {
        const firstParam = new Date(a?.updatedOn) || 0;
        const secondParam = new Date(b?.updatedOn) || 0;
        if (sortOrder == 'ascend') {
          return firstParam < secondParam ? -1 : 1;
        }
        // if descending, highest sorts first
        return secondParam < firstParam ? 1 : -1;
      },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortBy === 'updatedOn' ? sortOrder : null,
      render: (updatedOn: number | any, form: any) => {
        const updatedOnData = updatedOn || form?.updatedOn;
        return (
          <View flexDirection="row">
            <Text size={'smMedium'} color={Colors.Custom.Gray500} fontSize={14}>
              {moment(updatedOnData).format('DD/MM/yyyy, hh:mm a')}
            </Text>
          </View>
        );
      },
    },

    {
      title: (
        <Text
          size={'xsMedium'}
          style={{
            color: Colors.Custom.Gray500,
            marginLeft: 8,
            flex: 1,
          }}
        >
          {intl.formatMessage({ id: 'actions' })}
        </Text>
      ),
      key: 'action',
      width: isFormAnalyticsEnabled ? '22%' : '20%',
      align: 'center',
      fixed: 'right',
      render: (form: any) => {
        const count = form?.submittedCount;
        const hideFormAnalytics = isDisallowedViewAnalytics(form.formCategory);
        const hideSendButton = isDisallowedSendForm(form.formCategory);

        return (
          <View flexDirection="row" justifyContent={'center'}>
            {isFormAnalyticsEnabled && !hideFormAnalytics && (
              <Tooltip label="View Analytics" placement="top">
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: '5px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                  _focus={{
                    ...getOnFocusButtonStyles(),
                  }}
                >
                  <IconButton
                    colorScheme="gray"
                    tintColor="gray.500"
                    variant="ghost"
                    {...testID(TestIdentifiers.editBtn)}
                    icon={
                      <Feather
                        name="bar-chart"
                        size={17}
                        color={Colors.Custom.Gray600}
                      />
                    }
                    onPress={() => {
                      onGraphClick?.(form);
                    }}
                  />
                </Button>
              </Tooltip>
            )}
            <Tooltip label="Edit Form" placement="top">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  marginBottom: '5px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
                _focus={{
                  ...getOnFocusButtonStyles(),
                }}
              >
                <IconButton
                  colorScheme="gray"
                  tintColor="gray.500"
                  variant="ghost"
                  {...testID(TestIdentifiers.editBtn)}
                  icon={
                    <Feather
                      name="edit-3"
                      size={17}
                      color={Colors.Custom.Gray600}
                    />
                  }
                  onPress={() => {
                    onEdit(form);
                  }}
                />
              </Button>
            </Tooltip>

            <Tooltip label="Delete Form" placement="top">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  marginBottom: '5px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
                _focus={{
                  ...getOnFocusButtonStyles(),
                }}
              >
                <IconButton
                  colorScheme="gray"
                  tintColor="gray.500"
                  variant="ghost"
                  {...testID(TestIdentifiers.deleteBtn)}
                  icon={
                    <Feather
                      name="trash-2"
                      size={17}
                      color={Colors.Custom.Gray600}
                    />
                  }
                  onPress={() => {
                    onDelete(form);
                  }}
                />
              </Button>
            </Tooltip>

            {!hideSendButton && (
                <Tooltip label="Send" placement="top">
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      marginBottom: '5px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }}
                    _focus={{
                      ...getOnFocusButtonStyles(),
                    }}
                  >
                    <IconButton
                      colorScheme="gray"
                      tintColor="gray.500"
                      variant="ghost"
                      icon={
                        <Feather
                          name="send"
                          size={17}
                          color={Colors.Custom.Gray600}
                        />
                      }
                      onPress={() => {
                        onSendFormEmail(form);
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            <Tooltip label="Print Form" placement="top">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  marginBottom: '5px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
                _focus={{
                  ...getOnFocusButtonStyles(),
                }}
              >
                <IconButton
                  colorScheme="gray"
                  tintColor="gray.500"
                  variant="ghost"
                  icon={
                    <Feather
                      name="printer"
                      size={17}
                      color={Colors.Custom.Gray600}
                    />
                  }
                  onPress={() => {
                    onPrint(form);
                  }}
                />
              </Button>
            </Tooltip>
            <Tooltip label="Duplicate" placement="top">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  marginBottom: '5px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
                _focus={{
                  ...getOnFocusButtonStyles(),
                }}
              >
                <IconButton
                  colorScheme="gray"
                  tintColor="gray.500"
                  variant="ghost"
                  icon={
                    <Feather
                      name="copy"
                      size={17}
                      color={Colors.Custom.Gray600}
                    />
                  }
                  onPress={() => {
                    onAddFormClone(form);
                  }}
                />
              </Button>
            </Tooltip>
          </View>
        );
      },
    },
  ].filter((column) => {
    switch (column.key) {
      case 'updatedOn':
      case 'allResponses':
        return isFormAnalyticsEnabled;
      case 'subcount':
      case 'submittedCount':
        return !isFormAnalyticsEnabled;
      default: return true;
    }
  });
  return columns;
};
