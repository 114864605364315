import { useLazyQuery, useMutation } from '@apollo/client';
import { Modal, Table } from 'antd';
import { cloneDeep, isArray } from 'lodash';
import { Box, HStack, Spinner, Text, VStack, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BUTTON_TYPE,
  CARE_JOURNEY_TABLE_SORTS,
  CARE_JOURNEY_TABLE_SORT_TYPES,
  DEFAULT_PAGE_SIZE_OPTIONS,
  MLOV_CATEGORY,
  MODULE_PAGINATION_COUNT,
  PRODUCT_SERVICES_FLOW_TYPE_CODE,
  SORT_TYPE,
} from '../../../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { CARE_PROGRAM_STATUS_CODES, PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import CareJourneyQueries, { CAREJOURNEY_ASSIGNED_TO_MEMBER, UPDATE_CARE_JOURNEY_STATUS } from '../../../../../../../services/CareJourney/CareJourneyQueries';
import {
  addOrUpdateProduct,
  getProductList,
} from '../../../../../../../services/ProductAndServices/ProductAndServicesAPI';
import { getMomentObj } from '../../../../../../../utils/DateUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../../../utils/mlovUtils';
import { FoldButton } from '../../../../../../CommonComponents/FoldButton/FoldButton';
import { capitalizeText } from '../../../../../../common/ContactRelationView/ContactRelationUtils';
import { FHAlertDialog } from '../../../../../../common/FHAlertDialog';
import PageBodyContainer from '../../../../../../common/PageBodyContainer/PageBodyContainer';
import {
  TABLE_TOP_BAR_ACTION_CODES,
  TableTopBar,
} from '../../../../../../common/TableTopBar';
import { TableWrapper } from '../../../../../../common/TableWrapper';
import { getStripePackageApi } from '../../../JourneyPackages/PackageServices';
import { PRODUCT_PACKAGE_STATUS } from '../../../JourneyPackages/PackagesConst';
import { ISingleProductApiRespData } from '../../ProductDetailView/interface';
import {
  getCareJourneyTableColumns,
  getProductListTopBarButtons,
  getProductTableColumns,
} from '../ProductUtils/ProductTableColumn';
import {
  formatJourneysData,
  formateProductListData,
  getDeleteAlertHeaderAndMessageId,
} from '../ProductUtils/ProductTableUtils';
import {
  ICareJourneyStatusIDs,
  IProductApiData,
  IProductApiMetaData,
  IProductTableDisplayData,
  IProductsProps,
} from './interfaces';
import { ToastType, showToast } from '../../../../../../../utils/commonViewUtils';

const ProductTable = (props: IProductsProps) => {
  const getDescendSort = (sort: string) => `-${sort}`;
  const intl = useIntl();
  const toast = useToast();
  const {onRowClicked} = props;
  const {type} = useParams();
  const navigate = useNavigate();

  const [productTableState, setProductTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedProduct: {} as any,
    searchString: props.searchText,
    productList: [] as IProductApiData[],
    productDisplayList: [] as IProductTableDisplayData[],
    productMeta: {} as IProductApiMetaData,
    page: 1,
    pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
    deleteConfirmationOpen: false,
    selectedProductForDelete: undefined as IProductTableDisplayData | undefined,
    cloneConfirmation: false,
    updateStatusLoading: false as boolean,
    unableToDraft: false as boolean,
    unableToDraftReason : '',
    unableToDelete: false as boolean,
    unableToDeleteReason : ''
  });
  const [sortData, setSortData] = useState<string[]>([
    getDescendSort(CARE_JOURNEY_TABLE_SORTS.BY_UPDATED_AT),
  ]);

  const mlovData = useContext(CommonDataContext);
  const careJourneyStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STATUS
    ) || [];

  const patientJourneyStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS,
    ) || [];
  const patientCareJourneyStatusIds = {
    active: getMlovIdFromCode(
      patientJourneyStatusList,
      PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE
    ),
    restarted: getMlovIdFromCode(
      patientJourneyStatusList,
      PATIENT_CARE_JOURNEY_STATUS_CODE.RESTART
    ),
    paused: getMlovIdFromCode(
      patientJourneyStatusList,
      PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED
    ),
    assigned: getMlovIdFromCode(
      patientJourneyStatusList,
      PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED
    )
  };

  const careJourneyStatusIds: ICareJourneyStatusIDs = {
    draft: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.DRAFT
    ),
    published: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.ACTIVE
    ),
  };
  const [journeySortData, setJourneySortData] = useState<any>({})
  const [getCareJourneys] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEYS_BY_TITLE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [careJourneyAssignedQuery] = useLazyQuery(
    CAREJOURNEY_ASSIGNED_TO_MEMBER,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updateCareJouneyStatus, updateCareJouneyStatusQuery] = useMutation(
    UPDATE_CARE_JOURNEY_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [deleteCareJourney] = useMutation(
    CareJourneyQueries.DELETE_PATIENT_CARE_JOURNEY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  async function fetchProducts() {
    setProductTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    getProductList(
      {
        page: productTableState.page,
        per_page: productTableState.pageSize,
        sort: sortData.length > 1 ? sortData.join(',') : sortData[0],
        'filter[search_by_name]': productTableState.searchString || '',
        'filter[taxons_id_eq]': props.tabCategoryId,
        'filter[not_discontinued]': 1,
      },
      (responseMsg: any) => {
        if (responseMsg?.data && responseMsg?.data.length) {
          const tempProductList = responseMsg?.data;
          const tempIncludedList = responseMsg?.included;
          const tempProductDisplayList = formateProductListData(
            tempProductList,
            tempIncludedList
          );
          setProductTableState((prev) => {
            return {
              ...prev,
              productList: [...tempProductList],
              productDisplayList: [...tempProductDisplayList],
              productMeta: responseMsg.meta,
              isLoading: false,
            };
          });
        } else {
          setProductTableState((prev) => {
            return {
              ...prev,
              productList: [],
              productDisplayList: [],
              productMeta: responseMsg.meta,
              isLoading: false,
              isNoDataFound: true,
            };
          });
        }
      },
      () => {
        setProductTableState((prev) => {
          return {
            ...prev,
            isLoading: false,
            isNoDataFound: true,
          };
        });
      },
      type || ''
    );
  }

  async function fetchJourneys() {
    setProductTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const params: any = cloneDeep({
      searchString: `%${productTableState.searchString}%`,
        limit: productTableState.pageSize,
        offset: (productTableState.page - 1) * productTableState.pageSize,
        order_by: Object.keys(journeySortData).length > 0 ? journeySortData : undefined,
    });
    getCareJourneys({
      fetchPolicy: 'no-cache',
      variables: params,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.careJourneys &&
          response.data.careJourneys.length > 0
        ) {
          const tempProductDisplayList = formatJourneysData(
            response.data.careJourneys,
          );
          const productMetaData: IProductApiMetaData = {
            total_count: response.data.aggregateCareJourneys?.aggregate?.count || 0,
          }
          setProductTableState((prev) => {
            return {
              ...prev,
              productDisplayList: [...tempProductDisplayList],
              productMeta: productMetaData,
              isLoading: false,
              isNoDataFound: false,
            };
          });
        } else {
          setProductTableState((prev) => {
            return {
              ...prev,
              productDisplayList: [],
              isLoading: false,
              isNoDataFound: true,
            };
          });
        }
      })
      .catch((error: any) => {

        setProductTableState((prev) => {
          return {
            ...prev,
            isLoading: false,
            isNoDataFound: true,
          };
        });
      });
  }

  useEffect(() => {
    if (props.type && props.type === PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS) {
      fetchJourneys()
    } else {
      fetchProducts();
    }
  }, [
    props.tabCategoryId,
    productTableState.page,
    productTableState.pageSize,
    productTableState.searchString,
    journeySortData,
  ]);

  const onSort = (sorter: any) => {
    switch (sorter.field) {
      case CARE_JOURNEY_TABLE_SORTS.BY_NAME:
        if (sorter.order === SORT_TYPE.ASC || sorter.order === SORT_TYPE.DESC) {
          return {
            title: sorter.order === SORT_TYPE.ASC ? CARE_JOURNEY_TABLE_SORT_TYPES.ASC: sorter.order === SORT_TYPE.DESC ? CARE_JOURNEY_TABLE_SORT_TYPES.DESC: null,
          }
        }
        break;
    }
  };


  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    let sortObject: any = {}
    if (Array.isArray(sorter)) {
      sorter.forEach((sorter) => {
        const tempSortObject = onSort(sorter);
        if (tempSortObject) {
          sortObject = {
            ...sortObject,
            ...tempSortObject
          }
        }
      });
    } else {
      const tempSortObject = onSort(sorter);
      if (tempSortObject) {
        sortObject = tempSortObject
      }
    }
    setJourneySortData(sortObject);
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setProductTableState((prev) => {
          return {
            ...prev,
            page: 1,
            searchString: actionData?.searchString || '',
          };
        });
        break;
    }
  };

  const onDeleteProduct = (rowData: IProductTableDisplayData) => {

    setProductTableState((prev) => ({
      ...prev,
      isLoading: true,
      deleteConfirmationOpen: false,
      selectedProductForDelete: undefined,
    }));

    const discontinuedOn = getMomentObj(new Date())
      .add(-1, 'day')
      .toISOString();

    addOrUpdateProduct(
      {product: {discontinue_on: discontinuedOn,available_on: null}},
      (responseMsg: ISingleProductApiRespData) => {
        fetchProducts();
      },
      (error: any) => {

        setProductTableState((prev) => ({...prev, isLoading: false}));
      },
      rowData.id
    );
  };

  const onDeleteJourney = (rowData: IProductTableDisplayData) => {
    setProductTableState((prev) => ({
      ...prev,
      isLoading: true,
      deleteConfirmationOpen: false,
      selectedProductForDelete: undefined,
    }));

    deleteCareJourney({
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      variables: {
        id: rowData.id,
      },
      onCompleted: () => {
        fetchJourneys()
      },
      onError: error => {

        setProductTableState((prev) => ({...prev, isLoading: false}));
      },
    });
  }

  const onEditProduct = (productData: IProductTableDisplayData) => {
    onRowClicked(productData);
  };

  const onProductClone = (rowData: IProductTableDisplayData) => {
    // onProductClone
  };

  const checkIfJourneyIsAssignedToMembers = async (
    journeyId: string
  ): Promise<boolean> => {
    const careJourneyAssignedQueryResponse = await careJourneyAssignedQuery({
      variables: {
        statusIds: [
          patientCareJourneyStatusIds.active,
          patientCareJourneyStatusIds.assigned,
          patientCareJourneyStatusIds.paused,
          patientCareJourneyStatusIds.restarted,
        ],
        careJourneyId: journeyId,
      },
    });

    if (careJourneyAssignedQueryResponse?.error) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
      setProductTableState((prev) => ({...prev, updateStatusLoading: false}));
      return true;
    }

    if (
      isArray(careJourneyAssignedQueryResponse?.data?.patientCareJourneys) &&
      careJourneyAssignedQueryResponse?.data?.patientCareJourneys.length >= 1
    ) {
      setProductTableState((prev) => ({
        ...prev,
        updateStatusLoading: false,
        unableToDelete: true,
      }));
      return true;
    }
    return false;
  };

  const onJourneyClone = (productData: IProductTableDisplayData) => {
    navigate(
      `/admin/commerce/${type}/product/${productData.productId ? productData.productId: -1}/journey/create`,
        { state: { clone: true,id: -1,careJourneyId: productData.id} }
    );
  };
  const isJourneyAlreadyAssignedToPatient = (careJourneysResponse:any) => {
    return isArray(careJourneysResponse?.data?.patientCareJourneys) &&
    careJourneysResponse?.data?.patientCareJourneys.length >= 1
  }

  const isJourneyAlreadyAddedInPackage = (packageResponse:any) => {
    return packageResponse?.total?._count > 0;
  }

  const journeyDraftReason = {
    PATIENT: 'assigned to one or more patients',
    PACKAGE : 'associated with one or more published packages',
  }


  const onChangeStatus = async (rowData:IProductTableDisplayData) => {
    const newStatusId =
      rowData.status === careJourneyStatusIds.draft
        ? careJourneyStatusIds.published
        : careJourneyStatusIds.draft;

    setProductTableState((prev) => ({...prev, updateStatusLoading: true}));

    let isJourneyAlreadyAssigned = false;
    let msg = ''
    // moving the joruney to draft
    if (newStatusId === careJourneyStatusIds.draft) {
      const promisList = [];
      promisList.push(
        careJourneyAssignedQuery({
          variables: {
            statusIds: [
              patientCareJourneyStatusIds.active,
              patientCareJourneyStatusIds.assigned,
              patientCareJourneyStatusIds.paused,
              patientCareJourneyStatusIds.restarted,
            ],
            careJourneyId: rowData?.id,
          },
        })
      );
      promisList.push(
        getStripePackageApi({
          careJourneyId: rowData?.id as string,
          limit: 1,
          offSet: 0,
          status: PRODUCT_PACKAGE_STATUS.PUBLISHED,
        })
      );
      try {
        const response = await Promise.all(promisList);
        const careJourneysResponse = response[0];
        const packageResponse = response[1];
        // Journey assigned to patient or there is a published package wiht this journey
        isJourneyAlreadyAssigned =
          isJourneyAlreadyAddedInPackage(packageResponse) ||
          isJourneyAlreadyAssignedToPatient(careJourneysResponse);

       // exist in pkg
       if (isJourneyAlreadyAddedInPackage(packageResponse)) {
         msg = journeyDraftReason.PACKAGE;
       }
       // assigned to patient
       if (isJourneyAlreadyAssignedToPatient(careJourneysResponse)) {
         msg = journeyDraftReason.PATIENT;
       }
       // both
       if (
         isJourneyAlreadyAssignedToPatient(careJourneysResponse) &&
         isJourneyAlreadyAddedInPackage(packageResponse)
       ) {
        msg = journeyDraftReason.PACKAGE
       }

      } catch (error) {
        setProductTableState((prev) => ({...prev, updateStatusLoading: false}));
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
        );
      }
    } else {
      updateStatus(rowData, newStatusId);
    }
    if (!isJourneyAlreadyAssigned) {
      updateStatus(rowData, newStatusId);
    } else {
      if (!msg) {
        setProductTableState((prev) => ({...prev, updateStatusLoading: false}));
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
        );
        return;
      }
      // Show modal
      setProductTableState((prev) => ({
        ...prev,
        updateStatusLoading: false,
        unableToDraft: true,
        unableToDraftReason: msg,
      }));
    }
  }

  const updateStatus = async (
    rowData: IProductTableDisplayData,
    newStatusId: string
  ) => {
    const respone = await updateCareJouneyStatus({
      variables: {
        statusId: newStatusId,
        id: rowData?.id,
      },
    });
    if (respone.data?.updateCareJourney?.id) {
      const newList = productTableState.productDisplayList.map((item) =>
        item?.id === respone.data?.updateCareJourney?.id
          ? {...item, status: newStatusId}
          : item
      );
      setProductTableState((prev) => ({
        ...prev,
        productDisplayList: newList,
        updateStatusLoading: false,
      }));
    } else {
      setProductTableState((prev) => ({...prev, updateStatusLoading: false}));
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    }
  };

  const handleJourneyDelete = async (rowData: IProductTableDisplayData) => {
    setProductTableState((prev) => ({...prev, updateStatusLoading: true}));
    const promisList = [];
    let isJourneyAlreadyAssigned = false;
    let msg = '';
    promisList.push(
      careJourneyAssignedQuery({
        variables: {
          statusIds: [
            patientCareJourneyStatusIds.active,
            patientCareJourneyStatusIds.assigned,
            patientCareJourneyStatusIds.paused,
            patientCareJourneyStatusIds.restarted,
          ],
          careJourneyId: rowData?.id,
        },
      })
    );
    promisList.push(
      getStripePackageApi({
        careJourneyId: rowData?.id as string,
        limit: 1,
        offSet: 0,
        status: PRODUCT_PACKAGE_STATUS.PUBLISHED,
      })
    );
    try {
      const response = await Promise.all(promisList);
      const careJourneysResponse = response[0];
      const packageResponse = response[1];
      // Journey assigned to patient or there is a published package wiht this journey
      isJourneyAlreadyAssigned =
        isJourneyAlreadyAddedInPackage(packageResponse) ||
        isJourneyAlreadyAssignedToPatient(careJourneysResponse);

      // exist in pkg
      if (isJourneyAlreadyAddedInPackage(packageResponse)) {
        msg = journeyDraftReason.PACKAGE;
      }
      // assigned to patient
      if (isJourneyAlreadyAssignedToPatient(careJourneysResponse)) {
        msg = journeyDraftReason.PATIENT;
      }
      // both
      if (
        isJourneyAlreadyAssignedToPatient(careJourneysResponse) &&
        isJourneyAlreadyAddedInPackage(packageResponse)
      ) {
        msg = journeyDraftReason.PACKAGE;
      }
    } catch (error) {
      setProductTableState((prev) => ({...prev, updateStatusLoading: false}));
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    }
    if (!isJourneyAlreadyAssigned) {
      setProductTableState((prev) => ({
        ...prev,
        deleteConfirmationOpen: true,
        selectedProductForDelete: rowData,
        updateStatusLoading: false,
      }));
    } else {
      if (!msg) {
        setProductTableState((prev) => ({...prev, updateStatusLoading: false}));
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
        );
        return;
      }
      // Show modal
      setProductTableState((prev) => ({
        ...prev,
        updateStatusLoading: false,
        unableToDelete: true,
        unableToDeleteReason: msg,
      }));
    }
  }

  const onActionPerformed = (
    actionCode: string,
    rowData: IProductTableDisplayData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onEditProduct(rowData);
        break;
      case COMMON_ACTION_CODES.CLONE_JOURNEY:
        setProductTableState((prev) => ({
          ...prev,
          cloneConfirmation: true,
          selectedProductForDelete: rowData,
        }));
        break;
      case COMMON_ACTION_CODES.CLONE:
        onProductClone(rowData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        handleJourneyDelete(rowData);
        break;
      case COMMON_ACTION_CODES.VIEW_ANALYTICS:
        navigate(`/admin/commerce/journeys/${rowData.id}/analytics`)
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        onChangeStatus(rowData);
        break;
    }
  };

  useEffect(() => {
    setProductTableState((prev) => {
      return {
        ...prev,
        searchString: props.searchText,
      };
    });
  }, [props.searchText]);

  const {height} = Dimensions.get('window');
  const finalHeight = height - 250;

  const alert = getDeleteAlertHeaderAndMessageId(type);
  const isCareJourneyTable = PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS === type;
  const isProductTable = PRODUCT_SERVICES_FLOW_TYPE_CODE.PRODUCTS === type;
  return (
    <>
      <PageBodyContainer style={{marginHorizontal: 0}}>
        {false && (
          <TableTopBar
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            {...getProductListTopBarButtons(type || '', [
              () => {
                onRowClicked({tabType: type} as any);
              },
            ])}
            title={capitalizeText(type || '')}
          />
        )}
        <TableWrapper
          hidePagination={productTableState.isNoDataFound}
          hideOnSinglePage={false}
          pagination={{
            current: productTableState.page,
            pageSize: productTableState.pageSize,
            pageSizeOptions: [...DEFAULT_PAGE_SIZE_OPTIONS, MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE].sort((a, b) => a - b),
            total: productTableState.productMeta.total_count,
            onChange: (currentPage, currentPageSize) => {
              setProductTableState((prev) => {
                return {
                  ...prev,
                  page: currentPage,
                  pageSize: currentPageSize,
                };
              });
            },
          }}
        >
          <Table
            scroll={{x: 700, y: finalHeight}}
            columns={
              isProductTable
                ? getProductTableColumns(
                    onActionPerformed,
                    isCareJourneyTable,
                    isProductTable
                  )
                : getCareJourneyTableColumns(
                    onActionPerformed,
                    careJourneyStatusIds,
                    isCareJourneyTable,
                    isProductTable
                  )
            }
            rowKey={(row) => row.id}
            loading={{
              spinning:
                productTableState.isLoading ||
                productTableState?.updateStatusLoading,
              indicator: (
                <VStack justifyContent="center" height={'100%'}>
                  <Spinner size={'lg'} />
                </VStack>
              ),
            }}
            dataSource={productTableState.productDisplayList}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            onChange={onTableChange}
            pagination={false}
          />
        </TableWrapper>
      </PageBodyContainer>
      <FHAlertDialog
        isOpen={productTableState.deleteConfirmationOpen}
        header={intl.formatMessage({
          id:
            PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS == type
              ? 'deleteCareJourneyProduct'
              : 'deleteProduct',
        })}
        message={intl.formatMessage({
          id:
            PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS == type
              ? 'deleteCareJourneyProductAlertMessage'
              : 'deleteProductAlertMessage',
        })}
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
            },
            onPress: () => {
              setProductTableState((prev) => ({
                ...prev,
                deleteConfirmationOpen: false,
                selectedProductForDelete: undefined,
              }));
            },
          },
          {
            textLocalId: 'Delete',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
            },
            onPress: () => {
              if (productTableState.selectedProductForDelete)
                // onDeleteProduct(productTableState.selectedProductForDelete);
                onDeleteJourney(productTableState.selectedProductForDelete);
            },
          },
        ]}
      />
      <FHAlertDialog
        isOpen={productTableState?.cloneConfirmation}
        header={intl.formatMessage({
          id:'cloneJourney'
        })}
        message={intl.formatMessage({
          id:'cloneJourneyAlertMessage'
        })}
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
            },
            onPress: () => {
              setProductTableState((prev) => ({
                ...prev,
                cloneConfirmation: false,
                selectedProductForDelete: undefined,
              }));
            },
          },
          {
            textLocalId: 'clone',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
            },
            onPress: () => {
              if (productTableState.selectedProductForDelete)
                onJourneyClone(productTableState.selectedProductForDelete);
            },
          },
        ]}
      />

      {productTableState.unableToDraft && (
        <FHAlertDialog
          key={'daft-status-modal'}
          isOpen={productTableState.unableToDraft}
          header={intl.formatMessage({
            id: 'Draft Journey',
          })}
          message={`Care journey cannot be moved to draft status as it is currently ${productTableState.unableToDraftReason}`}
          buttonActions={[
            {
              textLocalId: 'Cancel',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
              },
              onPress: () => {
                setProductTableState((prev) => ({
                  ...prev,
                  unableToDraft: false,
                  unableToDraftReason: '',
                }));
              },
            },
          ]}
        />
      )}
      {productTableState.unableToDelete && (
        <FHAlertDialog
          key={'delete-status-modal'}
          isOpen={productTableState.unableToDelete}
          header={intl.formatMessage({
            id: 'Delete Journey',
          })}
          message={`Care journey cannot be deleted as it is currently ${productTableState.unableToDeleteReason}`}
          buttonActions={[
            {
              textLocalId: 'Cancel',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
              },
              onPress: () => {
                setProductTableState((prev) => ({
                  ...prev,
                  unableToDelete: false,
                  unableToDeleteReason: '',
                }));
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default ProductTable;
