import { IAuditLogData } from "./Hooks/interface";
interface ISingleData {
  [key: string]: IAuditLogData[];
}

export const groupAuditDataByTimeStamp = (feedDataList: IAuditLogData[]) => {
    return feedDataList?.reduce((singleData: ISingleData, singleFeedDataObj) => {
      const date = singleFeedDataObj.createdOn || '';
      const key = new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      });
      if (!singleData[key]) {
        singleData[key] = [];
      }
      singleData[key].push(singleFeedDataObj);
      return singleData;
    }, {});
  };


  export const RESOURCE_TYPE_CODES = {
    ALL: 'ALL',
    FORM: 'FORM',
    JOURNEY: 'JOURNEY',
  }

  export const parentResourceCodeFilters = [
    {
      code: RESOURCE_TYPE_CODES.ALL,
      value: 'All'
    },
    {
      code: RESOURCE_TYPE_CODES.FORM,
      value: 'Form'
    },
    {
      code: RESOURCE_TYPE_CODES.JOURNEY,
      value: 'Journey'
    }
  ]