import {BlockManager} from '@foldhealth/easy-email-core';
import {
  BlockAttributeConfigurationManager,
  BlockMarketManager,
  BlockMaskWrapper,
} from '@foldhealth/easy-email-extensions';
import React from 'react';
import {CustomBlocksType} from './constants';
import {DallePanel, DalleSuggestions} from './DalleSuggestions';
import {MedlineSuggestions, Panel} from './MedlineSuggestions';
import {OpenaiPanel, OpenaiSuggestions} from './OpenAiSuggestions';
import {UserCreated} from './UserCreatedBlocks';
import {FormsBlock, FormsPanel} from './FormsBlock';

BlockManager.registerBlocks({
  [CustomBlocksType.MEDLINE_SUGGESTIONS]: MedlineSuggestions,
  [CustomBlocksType.OPENAI_SUGGESTIONS]: OpenaiSuggestions,
  [CustomBlocksType.DALLE_SUGGESTIONS]: DalleSuggestions,
  [CustomBlocksType.USER_CREATED]: UserCreated,
  [CustomBlocksType.FORMS_BLOCK]: FormsBlock,
});

BlockAttributeConfigurationManager.add({
  [CustomBlocksType.MEDLINE_SUGGESTIONS]: Panel,
  [CustomBlocksType.OPENAI_SUGGESTIONS]: OpenaiPanel,
  [CustomBlocksType.DALLE_SUGGESTIONS]: DallePanel,
  [CustomBlocksType.FORMS_BLOCK]: FormsPanel,
});

BlockMarketManager.addCategories([
  {
    title: 'Custom',
    name: 'Custom',
    blocks: [
      {
        type: CustomBlocksType.MEDLINE_SUGGESTIONS,
        title: 'Educational Content',
        description:
          'Suggestions for including relevant educational content for group emails',
        component: () => (
          <BlockMaskWrapper
            type={CustomBlocksType.MEDLINE_SUGGESTIONS as any}
            payload={{}}
          >
            <div style={{position: 'relative', alignItems: 'flex-start'}}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                  alignItems: 'flex-start',
                }}
              />
              <b> Educational Articles </b>
            </div>

            <div style={{position: 'relative', alignItems: 'flex-start'}}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                  alignItems: 'flex-start',
                }}
              />
              Educational Article 1
            </div>
          </BlockMaskWrapper>
        ),
        thumbnail:
          'https://assets.maocanhua.cn/5631c12e-5788-40fd-92fe-23930a5985d7-image.png',
      },
    ],
  },
]);
