// Views
import {Table} from 'antd';
import {HStack, Skeleton, useToast, View} from 'native-base';
// import {useEffect, useState} from 'react';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {getColumns} from './TaskManagementTableHelper';
import {styles} from '../TaskManagementStyle';
import {TABLE_TOP_BAR_ACTION_CODES, TableTopBar} from '../../../common/TableTopBar';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import Icon from 'react-native-vector-icons/AntDesign';
import AddPoolModal from '../TaskManagementModal/AddPoolModal';
import {UserQueries} from '../../../../services';
import {
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserUUID,
} from '../../../../utils/commonUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {
  ITaskManagementInterface,
  ITaskPoolTableData,
  TASK_ACTIONS_TYPE,
} from '../TaskInterfaces/TaskInterface';
import {Colors} from '../../../../styles';
import {getMlovId} from '../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {useSearchParams} from 'react-router-dom';
import {handleCreateTaskPoolAction} from '../../../common/UsageLevel/UsageLevelHandlers';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { BUTTON_TYPE } from '../../../../constants';
import { TableWrapper } from '../../../common/TableWrapper';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';

const TaskManagementTableList = (props: ITaskManagementInterface) => {
  const toast = useToast();
  const intl = useIntl();
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const [taskTableListState, setTaskTableListState] = useState<{
    searchString: string;
    page: number;
    pageSize: number;
    offset: number;
    taskPoolData: any;
    taskPoolDataLength: number;
  }>({
    searchString: '',
    page: 1,
    pageSize: 10,
    offset: 0,
    taskPoolData: [],
    taskPoolDataLength: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [selectedTaskData, setSelectedTaskData] = useState<any>(null);
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );
  const taskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  const [searchParams] = useSearchParams();
  const isWalkThrough = searchParams.get('isWalkThrough') || false;
  const userActionObject =
    getUserActionObjectFromActionCode('CREATE_A_TASK_POOL');
  const level = userActionObject.level || '';

  /**
   * Get all users
   */
  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR_WITHOUT_EMPLOYER, {
    variables: {
      accountId: accountUuid,
      roleCode: USER_ROLE_CODES.EMPLOYER
    },
  });

  /**
   * Get task pool query
   */
  const {loading, refetch} = useQuery(TaskPoolQueries.GetTaskPool, {
    variables: {
      params: {
        offset: taskTableListState.offset,
        limit: taskTableListState.pageSize,
        userPoolTypeId: taskPoolTypeId,
        ...(taskTableListState.searchString && { name: taskTableListState.searchString }),
      },
    },
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    onCompleted: (data) => {
      // Filter non active users from pool
      const userPools = data?.getUserPools?.userPools || [];
      userPools.forEach((userPool: any) => {
        userPool.userPoolUsers = (userPool.userPoolUsers || []).filter((userPoolUser: any) => {
          return userPoolUser?.user?.accountUsers?.some((item: any) => item.isActive);
        })
      });
      setTaskTableListState((prev) => {
        return {
          ...prev,
          taskPoolData: userPools,
          taskPoolDataLength: data?.getUserPools?.aggregate?.total,
        };
      });
    },
  });

  /**
   * Create Task Pool Query
   */
  const [CreateTaskPool] = useMutation(TaskPoolQueries.CreateTaskPool, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  /**
   * Delete Task Pool Mutation
   */
  const [DeleteTaskPool] = useMutation(TaskPoolQueries.DeleteTaskPool, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  /**
   * Edit Task Pool Mutation
   */
  const [EditTaskPool] = useMutation(TaskPoolQueries.EditTaskPoolName, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  /**
   * Edit Task Pool Users Mutation
   */
  const [EditTaskPoolUsers] = useMutation(TaskPoolQueries.EditTaskPoolUsers, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  /**
   * @description Submit action for new pool
   * @param newTaskPool ITaskPoolTableData
   */
  const handleAddTaskPool = async (newTaskPool: ITaskPoolTableData) => {
    try {
      await CreateTaskPool({
        variables: {
          data: {
            name: newTaskPool.newPoolName,
            userPoolUsers: {
              data: newTaskPool.newPoolUsers.map((userItem) => ({
                userId: userItem,
                isDefault: newTaskPool.isDefault === userItem,
              })),
            },
            typeId: taskPoolTypeId,
          },
        },
      });
      await handleCreateTaskPoolAction({accountUuid, userUuid, level});
      showToast(toast, `New task pool created successfully`, ToastType.success, 2000);
      refetch();
    } catch (error) {
      showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error, 2000);
    }
  };

  /**
   * @description Submit action for edit pool
   * @param newTaskPool ITaskPoolTableData
   */
  const handleEditTaskPool = async (newTaskPool: ITaskPoolTableData) => {
    try {
      const prevDefaultAssignee = (newTaskPool.poolUsers as any[] || []).find((poolUserItem: any) => {
        if (poolUserItem.isDefault) {
          return true;
        }
      });

      let prevDeletedDefaultAssignee: any = undefined;

      if (prevDefaultAssignee && (prevDefaultAssignee.userId !== newTaskPool.isDefault)) {
        prevDeletedDefaultAssignee = prevDefaultAssignee;
      }

      if (prevDeletedDefaultAssignee) {
        prevDeletedDefaultAssignee.isDefault = false;
        prevDeletedDefaultAssignee.isDeleted = false;
      }

      const deletedUsers: any[] = !newTaskPool.poolUsers
        ? []
        : newTaskPool.poolUsers
            ?.filter(
              (poolUserItem: any) =>
                newTaskPool.newPoolUsers.findIndex(
                  (dataItem) => dataItem === poolUserItem?.userId
                ) < 0
            )
            .map((poolUserItem: any) => {
              if (prevDeletedDefaultAssignee && prevDeletedDefaultAssignee.userId === poolUserItem.userId) {
                prevDeletedDefaultAssignee = undefined;
              }
              return {
                id: poolUserItem.id,
                isDeleted: true,
                isDefault: poolUserItem.isDefault || false,
                userId: poolUserItem.userId,
                userPoolId: newTaskPool.poolId,
              };
            });
      const newUsers: any[] = newTaskPool.newPoolUsers
        .filter((poolUserItem: any) =>
          newTaskPool.poolUsers
            ? newTaskPool.poolUsers.findIndex(
                (dataItem: any) => dataItem?.userId === poolUserItem
              ) < 0
            : false
        )
        .map((newPoolUserItem) => ({
          userId: newPoolUserItem,
          isDefault: newTaskPool.isDefault === newPoolUserItem,
          userPoolId: newTaskPool.poolId,
        }));


      const updatedUserPoolUsers = deletedUsers?.concat(newUsers);

      if (prevDeletedDefaultAssignee) {
        updatedUserPoolUsers.push({
          id: prevDeletedDefaultAssignee.id,
          isDeleted: prevDeletedDefaultAssignee.isDeleted,
          isDefault: prevDeletedDefaultAssignee.isDefault || false,
          userId: prevDeletedDefaultAssignee.userId,
          userPoolId: newTaskPool.poolId,
        });
      }

      const newDefaultAssignee = (newTaskPool.poolUsers as any[] || []).find((poolUserItem: any) => {
        if (!poolUserItem.isDefault && poolUserItem.userId === newTaskPool.isDefault) {
          return true;
        }
      });

      if (newDefaultAssignee) {
        updatedUserPoolUsers.push({
          id: newDefaultAssignee.id,
          isDeleted: newDefaultAssignee.isDeleted,
          isDefault: true,
          userId: newDefaultAssignee.userId,
          userPoolId: newTaskPool.poolId,
        });
      }

      await EditTaskPool({
        variables: {
          name: newTaskPool.newPoolName,
          id: newTaskPool.poolId,
        },
      });
      await EditTaskPoolUsers({
        variables: {
          data: updatedUserPoolUsers,
        },
      });
      showToast(toast, `Task pool updated successfully`, ToastType.success, 2000);
      refetch();
    } catch (error) {
      showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error, 2000);
    }
  };

  /**
   * Fetching users Data
   */
  const getUsersData = async () => {
    const getUsersResult = await getUsers();
    setUsersData(getUsersResult?.data?.users);
  };

  useEffect(() => {
    refetch();
    getUsersData().then((r) => {
      if (isWalkThrough) {
        setOpenModal(true);
      }
    });
  }, []);

  useEffect(() => {
    setTaskTableListState((prev) => ({
      ...prev,
      searchString: props?.searchString || '',
      loading: true,
    }));
  }, [props.searchString]);

  useEffect(() => {
    if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.NEW) {
      setOpenModal(true);
    } else if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.CLOSE) {
      setOpenModal(false);
    }
  }, [props?.selectedActionCode]);

  const onBottomViewAction = async (task: any, action: any) => {
    switch (action) {
      case TASK_ACTIONS_TYPE.EDIT:
        setSelectedTaskData(task);
        setOpenEditModal(true);
        break;
      case TASK_ACTIONS_TYPE.DELETE:
        await DeleteTaskPool({
          variables: {
            id: task.id,
          },
        });
        showToast(toast, `Task pool deleted successfully`, ToastType.success, 2000);
        refetch();
        break;
    }
  };

  const viewHeight = window.innerHeight - 220;

  return (
    <View flex={1}>
      <View flex={1} bgColor={'white'}>
        {props?.showTableTopBar &&
        <TableTopBar
          title={`TaskPool`}
          buttonList={[
            {
              btnText: 'new',
              colorScheme: 'secondary',
              textColor: Colors.secondary['800'],
              backgroundColor: Colors.secondary['100'],
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <PlusIcon />,
              btnClick: () => setOpenModal(true),
              isTransBtn: true,
            },
          ]}
          buttonCustomStyles={{
            paddingTop: 8,
            paddingBottom: 8,
          }}
          onActionPerformed={(
            actionCode: string,
            value: {searchString: string}
          ) => {
            const {searchString} = value;
            setTaskTableListState((prevState) => {
              return {...prevState, searchString};
            });
          }}
        />
        }

        {/* For Add Pool */}
        <AddPoolModal
          title={`Add Task Pool`}
          isModalOpen={openModal}
          onModalClose={() => {
            setOpenModal(false);
            props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE)
          }}
          onSave={(newPool: ITaskPoolTableData) => handleAddTaskPool(newPool)}
          usersData={usersData}
          isEdit={false}
        />

        {/* For Edit Pool */}
        {selectedTaskData && (
          <AddPoolModal
            title={`Edit Task Pool`}
            isModalOpen={openEditModal}
            onModalClose={() => setOpenEditModal(false)}
            onSave={(newPool: ITaskPoolTableData) =>
              handleEditTaskPool(newPool)
            }
            usersData={usersData}
            selectedTaskData={{...selectedTaskData}}
            isEdit={true}
          />
        )}

        {loading ? (
          <View padding={3}>
            <Skeleton.Text lines={6} />
          </View>
        ) : (
          <TableWrapper
            wrapperStyle={{
              minHeight: viewHeight - 200,
            }}
            pagination={{
              current: taskTableListState.page,
              pageSize: taskTableListState.pageSize,
              total: taskTableListState.taskPoolDataLength,
              onChange: (currentPage, currentPageSize) => {
                setTaskTableListState((prev) => ({
                  ...prev,
                  page: currentPage,
                  pageSize: currentPageSize,
                  offset: (currentPage - 1) * currentPageSize,
                }));
              },
            }}
          >
            <Table
              columns={getColumns(intl, onBottomViewAction)}
              rowKey={(row) => row.id}
              onRow={(row) => {
                return {
                  onClick: () => {
                    onBottomViewAction(row, TASK_ACTIONS_TYPE.EDIT);
                  },
                };
              }}
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              dataSource={taskTableListState.taskPoolData}
              scroll={{x: 800, y: viewHeight - 120}}
              pagination={false}
            />
          </TableWrapper>
        )}
      </View>
    </View>
  );
};

export default TaskManagementTableList;
