import {HStack, Pressable, ScrollView, Text, VStack} from 'native-base';
import { Navigate, useNavigate } from 'react-router-dom';
import {Colors} from '../../../styles';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {
  IMiddleContainerTab,
  IPersonTabBarProps,
} from '../MiddleContainer/interfaces';
import {getMiddleContainerTabsByPersonType} from '../MiddleContainer/MiddleContainerUtils';
import {styles} from './PersonTabBarStyles';
import {isEnableCareProgram} from '../../../utils/commonUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {useContext} from 'react';

const PersonTabBar = (props: IPersonTabBarProps) => {
  const {selectedTabCode, onSelectTab, contactId} = props;
  const mlovData = useContext(CommonDataContext);
  const enableCareProgram = isEnableCareProgram(mlovData.userSettings);
  const tablistByPersonType = getMiddleContainerTabsByPersonType(
    props.personType,
    enableCareProgram
  );
  const navigate = useNavigate();

  return (
    <ScrollView horizontal>
    <HStack style={[styles.container]}>
      {tablistByPersonType?.map((item: IMiddleContainerTab, index: number) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              if (contactId) {
                navigate(`/members/patient/${contactId}/${item.tabTitle}`);
                onSelectTab(item.tabCode)
              } else {
                onSelectTab(item.tabCode)
              }
            }}>
            <VStack
              style={[styles.tabContainer, {borderRadius: 20}]}
              backgroundColor={
                selectedTabCode === item.tabCode
                  ? Colors.primary['300'] || ''
                  : ''
              }
            >
              <Text
                style={styles.tabText}
                color={
                  selectedTabCode === item.tabCode
                    ? '#fff' || ''
                    : Colors.Custom.Gray500
                }
              >
                <DisplayText textLocalId={item.tabTitle} />
              </Text>
            </VStack>
          </Pressable>
        );
      })}
    </HStack>
    </ScrollView>
  );
};

export default PersonTabBar;
