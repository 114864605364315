import React, {useEffect} from 'react';
import {FILTER_MENU_KEYS, ITabViewProps, ITimelineMap} from '../../interfaces';
import {
  getDiagnosticReportByPatientId,
  getRequestGroupByPatientId,
} from '../../../../../../services/CommonService/OrderService';
import {IRequestGroup} from '../../../../LeftContainer/RecentActivity/OrdersInterface';
import {getDiagnosticReportResponseFormatted, getReportReferences} from '../../../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import {
  getAllOrdersTimelineData,
} from '../../utils';
import {Spinner, VStack} from 'native-base';
import OrderTimeLine from '../OrderTimeLine/OrderTimeLine';
import { RequestGroup } from 'fhir/r4';
import { getAuthorId } from '../../../Orders/OrdersAndReports/OrderUtils';
import { GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS } from '../../../../../../services/User/UserQueries';
import { useLazyQuery } from '@apollo/client';

const ImagingOrders = (props: ITabViewProps) => {
  const {notesData, personData, searchQuery, activeFilter, handleNoteSelect} =
    props;
  const patientId = personData?.patientId || personData?.patientUuid;
  const [componentState, setComponentState] = React.useState({
    loading: false,
    timeLineData: [] as ITimelineMap[],
    prevTimeLineData: [] as ITimelineMap[],
    reports: [],
    reportMap: {},
    orders: [] as IRequestGroup[],
  });

  const [getUserList] = useLazyQuery(
    GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.accountUsers.length) {
          const userIdNameMap: any = {};
          data.accountUsers.forEach((item: any) => {
            if (item.user?.uuid) {
              userIdNameMap[item.user.uuid] = item.user.name;
            }
          });
          setComponentState((prev) => {
            const orders = prev.orders;
            orders.forEach((order) => {
              const userId = getAuthorId(order as RequestGroup);
              if (userId && order.author && userIdNameMap[userId]) {
                order.author.display = userIdNameMap[userId];
              }
            })
            return {
              ...prev,
              orders: [...orders],
              loading: false,
            }
          });
        }
      },
      onError: () => {
        setComponentState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
  );

  const fetchData = () => {
    setComponentState((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    Promise.all([
      getRequestGroupByPatientId(patientId || ''),
      getDiagnosticReportByPatientId(patientId || ''),
    ])
      .then((responses) => {
        const userUUIDs: string[] = [];
        let list: IRequestGroup[] =
          responses?.[0]?.data?.entry?.map((item: any) => item.resource) || [];
        const {reports, reportMap} = getDiagnosticReportResponseFormatted(
          responses?.[1]
        );
        (reports || []).forEach(report => {
          const referenceIds: string[] = getReportReferences(report);
          if (referenceIds?.length) {
            referenceIds.forEach((reference) => {
              list = list.map((order) => {
                const actions = order?.action?.map((action) => {
                  if (reference.includes(action?.resource?.reference || '')) {
                    action.resource.report = report;
                  }
                  return action;
                });
                return {
                  ...order,
                  action: actions,
                };
              });
            });
          }
        });
        list.forEach((order) => {
          if (!order.author?.display) {
            const userUUID = getAuthorId(order as RequestGroup);
            if (userUUID && !userUUIDs.includes(userUUID)) {
              userUUIDs.push(userUUID);
            }
          }
        })
        if (userUUIDs.length) {
          getUserList({
            variables: {
              userIds: userUUIDs.filter((uuid) => uuid !== '-1'),
              externalUserIds: [],
            }
          });
        }

        const completeTimeLineData = getAllOrdersTimelineData(
          list as RequestGroup[],
          [],
          notesData,
          { searchQuery: '', activeFilter: {}, orderType: 'RADIOLOGY' },
        );
        const timeLineData = getAllOrdersTimelineData(
          list as RequestGroup[],
          [],
          notesData,
          { searchQuery, activeFilter, orderType: 'RADIOLOGY' },
        );
        setComponentState((prev: any) => {
          return {
            ...prev,
            loading: false,
            timeLineData: timeLineData,
            prevTimeLineData: completeTimeLineData,
            reports: reports,
            reportMap: reportMap,
            orders: list,
          };
        });
      })
      .catch((error) => {
        setComponentState((prev: any) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };
  useEffect(() => {
    fetchData();
  }, [props.notesData]);

  useEffect(() => {
    if (searchQuery === '' && !Object.keys(activeFilter || {}).length) {
      setComponentState((prev) => ({
        ...prev,
        timeLineData: componentState.prevTimeLineData,
      }));
    } else {
      const timeLineData = getAllOrdersTimelineData(
        componentState.orders as RequestGroup[],
        [],
        notesData,
        { searchQuery, activeFilter, orderType: 'RADIOLOGY' },
      );
      setComponentState((prev) => ({
        ...prev,
        timeLineData: timeLineData,
      }));
    }
  }, [searchQuery, activeFilter]);

  return componentState.loading ? (
    <VStack h={500} justifyContent="center">
      <Spinner size="lg" />
    </VStack>
  ) : (
    <OrderTimeLine
      unformmatedContactDetails={props.unformmatedContactDetails}
      personData={personData}
      reports={componentState.reports}
      timeLineMap={componentState.timeLineData}
      orders={componentState.orders}
      handleNoteSelect={handleNoteSelect}
      refreshData={fetchData}
    />
  );
};

export default ImagingOrders;
