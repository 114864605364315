import {useQuery} from '@apollo/client';
import {Table} from 'antd';
import {Skeleton, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {PERSON_TYPES} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../../../../services/index';
import {getAccountId} from '../../../../../../../utils/commonUtils';
import {
  // getContactFiltersObject,
  getSupportingMemberFiltersObject,
} from '../../../../ContactsUtils';
import {
  handleSorting,
  onPagination,
} from '../../../../Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import {getVisitorTableViewColumns} from '../Helper/VisitorListViewColumns';
import {IVisitorListViewProps} from '../Interfaces';
import {
  formateSupportingMemberData,
  // formateVisitorListData
} from './VisitorListHelper';
import './VisitorListView.css';
import { withMiniContactViewHOC } from '../../../../../../MiniContactViewHOC';

const VisitorListView = (props: IVisitorListViewProps): JSX.Element => {
  const mlovData = useContext(CommonDataContext);
  const tagItem: any = props.tagItem;
  const inboxItem = props.inboxItem;
  const searchString = props.searchText;
  const accountId = getAccountId();
  const navigate = useNavigate();
  const filter: any = {
    _or: [],
    realatedContact: {contactTagging: {}},
  };
  const [stateData, setStateData] = useState({
    searchText: props.searchText,
    searchedColumn: '',
    filteredInfo: null,
    sortedInfo: null,
    isLoading: true,
    leadList: [],
    currentPage: 1,
    offSet: 0,
    pageSize: 10,
    sortD: '',
    order_by: {
      updatedAt: 'desc',
    },
  });
  useEffect(() => {
    setStateData({...stateData, pageSize: 10, offSet: 0, currentPage: 1});
  }, [searchString]);
  // const {loading, data, refetch} = useQuery(LeadQueries.GetVisitorContacts, {
  //   fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  //   variables: {
  //     limit: stateData.pageSize,
  //     offset: stateData.offSet,
  //     searchString: '%' + searchString + '%',
  //     sortD: stateData.sortD || undefined,
  //     order_by: stateData.order_by,
  //     // contact_filters: getContactFiltersObject(),
  //     visitor_contact_filters: getContactFiltersObject(
  //       filter,
  //       searchString,
  //       accountId,
  //       tagItem,
  //       inboxItem,
  //       ''
  //     ),
  //   },
  // });
  const {loading, data} = useQuery(LeadQueries.GetSupportingMemeber, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      limit: stateData.pageSize,
      offset: stateData.offSet,
      searchString: '%' + searchString + '%',
      sortD: stateData.sortD || undefined,
      order_by: stateData.order_by,
      // contact_filters: getContactFiltersObject(),
      whereObj: getSupportingMemberFiltersObject(
        filter,
        searchString,
        accountId,
        tagItem,
        inboxItem
      ),
    },
  });

  const formattedData = formateSupportingMemberData(data || [], mlovData);
  let {sortedInfo}: any = {...stateData};
  sortedInfo = sortedInfo || {};

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setStateData({...stateData, pageSize: pagination.pageSize});
    setStateData({
      ...stateData,
      filteredInfo: filters,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
      offSet: onPagination(pagination.current, pagination.pageSize),
      order_by: handleSorting(sorter, stateData.order_by),
    });
  };

  const onDeleteVisitor = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.DELETE, rowData);
  };

  const onEditVisitor = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.EDIT, rowData);
  };

  const onSetTag = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.SET_TAG, rowData);
  };

  const onConvertTo = (rowData: any, actionCode: any) => {
    props?.onActionPerformed(actionCode, rowData);
  };
  const onAddRelationAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.ADD_RELATION, rowData);
  };
  const onMailAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.MAIL, rowData);
  };
  const onActionPerformed = (actionCode: string, rowData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        onDeleteVisitor(rowData);
        break;
      case COMMON_ACTION_CODES.EDIT:
        onEditVisitor(rowData);
        break;
      case COMMON_ACTION_CODES.SET_TAG:
        onSetTag(rowData);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        onConvertTo(rowData, actionCode);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_LEAD:
        onConvertTo(rowData, actionCode);
        break;
      case COMMON_ACTION_CODES.MAIL:
        onMailAction(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_RELATION:
        onAddRelationAction(rowData);
        break;
    }
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //   },
  //   getCheckboxProps: (record: any) => ({
  //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const onRowClicked = (rowData: any) => {
    const patientId =
      rowData.realatedContact.patient &&
      (rowData.realatedContact.patient.patientId ||
        rowData.realatedContact.patient.patientUuid)
        ? true
        : false;
    if (patientId || true) {
      if (rowData?.contactType?.code == PERSON_TYPES.CUSTOMER) {
        props?.navigateOrOpenContactIdDrawer?.(rowData.id);

      } else if (rowData?.contactType?.code == PERSON_TYPES.LEAD) {
        navigate(`/members/prospect/${rowData.id}`);
      } else {
        navigate(`/members/supporting-memeber/${rowData.id}`);
      }
    }
  };
  const {height} = Dimensions.get('window');
  const finalHeight = height - 401;
  return (
    <>
      {loading ? (
        <>
          <View style={{padding: '20px'}}>
            <Skeleton.Text lines={10} />
          </View>
        </>
      ) : (
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          scroll={{x: 800, y: finalHeight}}
          className="lead-list-table-view"
          columns={getVisitorTableViewColumns(sortedInfo, onActionPerformed)}
          dataSource={formattedData}
          onChange={handleChange}
          // rowSelection={{
          //   type: 'checkbox',
          //   ...rowSelection,
          // }}
          onRow={(_record: any) => {
            return {
              onClick: () => {
                onRowClicked(_record);
                // props?.onActionPerformed(
                //   COMMON_ACTION_CODES.ROW_CLICKED,
                //   _record
                // );
              },
            };
          }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: stateData.pageSize,
            total: data?.contactRelationsAggregate?.aggregate?.count,
            current: stateData.currentPage,
            onChange: () => {
              // setStateData({...stateData, currentPage: page});
            },
          }}
        />
      )}
    </>
  );
};
export default withMiniContactViewHOC(VisitorListView);
