export const PhoneCallingConst = {
  isTransferEnable: true,
  isConferenceEnable: true,
};

export enum AppStatus {
  background,
  foreground
}

export const CallNoteMessaage = {
  OutgoingCallNote : 'Transfer, conference, and hold features are unavailable for outbound calls.',
  IncomingConferenceNote: 'During incoming transferred or conference calls, the only available options are mute and speaker.'
}