import {useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import {Skeleton, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import { POPULATION_GROUP_TYPES } from '../../../../../../constants';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../../../services';
import {Colors} from '../../../../../../styles';
import { getEhrConfig, getResourceAbilities } from '../../../../../../utils/capabilityUtils';
import { isLeadGroupsPage } from '../../../../../../utils/commonUtils';
import {formateLeadListData} from '../../../../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import MessagingContactDetailsDrawer from '../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {IContactByIdsQuery, IGroupContactList} from '../interface';
import {getLeadTableViewColumns} from './../../../../Contacts/Leads/LeadView/LeadTableView/Helper/LeadListViewColumns';
import { GET_ALL_PATIENT_CARE_JOURNEYS } from '../../../../../../services/CareJourney/CareJourneyQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { IPatientCareJourney, IPatientCareJourneyMap } from '../../../../Contacts/Leads/interfaces';

const GroupContactList = (props: IGroupContactList) => {
  const mlovData = useContext(CommonDataContext);
  const hintCapability = getResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const [stateData, setStateData] = useState({
    contactList: [] as any,
    loading: props.loading,
  });
  const [careJourneyData, setCareJourneyData] = useState<IPatientCareJourney>({
    loading: false,
    map: new Map(),
  });

  let {sortedInfo}: any = {...stateData};
  sortedInfo = sortedInfo || {};

  const [getPatientCareJourneys] = useLazyQuery(GET_ALL_PATIENT_CARE_JOURNEYS, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [Get_Contact_By_Uuid] = useLazyQuery(LeadQueries.Get_Contact_By_Uuid);
  const getContacts = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    if (props.contactList?.length) {
      const contactIds: IContactByIdsQuery[] = [];
      props.contactList?.forEach((element: any) => {
        const temp: any = {
          id: {
            _eq:
              element?.contactId?.contactId != undefined
                ? element?.contactId?.contactId
                : element?.contactId,
          },
        };
        contactIds.push(temp);
      });
      const contactListResponse = await Get_Contact_By_Uuid({
        variables: {
          _or: contactIds,
        },
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (contactListResponse?.data?.contacts?.length) {
        const contactList = contactListResponse?.data || [];
        const formattedGroupMemberList = formateLeadListData(
          contactList,
          mlovData,
          props?.contactsDictionary
        );
        fetchCareJourneys(
          formattedGroupMemberList.map(
            (user: any) => user?.contactData?.patient?.patientUuid
          )
        );
        setStateData((prev) => {
          return {
            ...prev,
            contactList: formattedGroupMemberList,
            loading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const fetchCareJourneys = async (patientIds: string[]) => {

    if (!patientIds.length) {
      return;
    }

    setCareJourneyData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    try {
      const response = await getPatientCareJourneys({
        variables: {
          patientIdList: patientIds,
        },
      });
      const map: IPatientCareJourneyMap = new Map();
      patientIds.forEach((patientId) => {
        map.set(
          patientId,
          response?.data?.patientCareJourneys.filter(
            (item: any) => item.patientId === patientId
          )
        );
      });
      setCareJourneyData((prev) => {
        return {
          ...prev,
          loading: false,
          map,
        };
      });
    } catch (error) {
      setCareJourneyData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
}

  const onChangeSelection = (item: any, isSelected: boolean) => {
    onAllSelection(isSelected, [item]);
  };

  const onAllSelection = (isSelected: boolean, changeRows: any) => {
    let selectedRowKeys = [...props.selectedRowKeys];
    changeRows.forEach((item: any) => {
      const key = item.id;
      const uuid = item.uuid;
      if (isSelected) {
        if (selectedRowKeys.indexOf(key) == -1) {
          selectedRowKeys.push(key);
        }
      } else {
        selectedRowKeys = selectedRowKeys.filter((selectedKey) => {
          return selectedKey != key;
        });
      }
    });
    props.setSelectedRowKeys(selectedRowKeys);
  };

  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    getContacts();
  }, [props.contactList]);
  const {height} = Dimensions.get('window');
  return (
    <View
      style={{
        backgroundColor: '#fff',
      }}
    >
      {props.loading && !stateData.contactList.length ? (
        <View style={{padding: '20px'}}>
          <Skeleton.Text lines={10} />
        </View>
      ) : (
        <Table
          dataSource={stateData.contactList}
          loading={props.loading}
          columns={getLeadTableViewColumns(
            sortedInfo,
            props.onActionPerformed,
            'CUSTOMER',
            false,
            true,
            undefined,
            openInHint && !isLeadGroupsPage() && props?.groupType === POPULATION_GROUP_TYPES?.DYNAMIC ? true : undefined,
            undefined,
            undefined,
            careJourneyData
          )}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          className="lead-list-table-view patient-list-view"
          style={{minHeight: height - 200}}
          scroll={{y: height - 277}}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: props.selectedRowKeys,
            onSelect: (item: any, isSelected: boolean) => {
              onChangeSelection(item, isSelected);
            },
            onSelectAll(selected, selectedRows, changeRows) {
              onAllSelection(selected, changeRows);
            },
            hideSelectAll: false,
          }}
          // onChange={(pagination, filters, sorter: any) => {
          // }}
          rowKey={(row) => row.id as string}
          onRow={(_record: any) => {
            return {
              onClick: () => {
                setSelectedRow(_record);
              },
            };
          }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: props.pageSize,
            current: props.page,
            total: props.total,
            onChange(page, pageSize) {
              if (props.onPaginationChange) {
                props.onPaginationChange(page, pageSize);
              }
            },
          }}
        />
      )}
      {selectedRow && (
        <MessagingContactDetailsDrawer
          contactData={selectedRow?.contactData}
          isDrawerVisible={selectedRow ? true : false}
          contactType={selectedRow?.contactType}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setSelectedRow(null);
            }
          }}
          borderLessView={true}
        />
      )}
    </View>
  );
};
export default GroupContactList;
