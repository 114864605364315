export { default as Configs } from './Configs';
export * from './EntityAndEventsConst';
export {
  MLOV_CATEGORY,
  PERSON_CONTACT_TYPE_CODES
} from './MlovConst';
export {
  OMNI_VIEW_CLIN_SECTION_BTNS,
  OMNI_VIEW_PERSON_SECTION_BTNS, OMNI_VIEW_TOP_BAR_BTN_CONST
} from './OmniViewConsts';
export * from './StringConst';
export {
  NETWORK_EVENTS,
  NEWWORK_EVENT_CODES
} from './VisitHistoryConst';
export { months } from './dateConst';

export const TRACKING_RESOURCE_TYPE = {
  VITALS: 'VITALS',
  LAB_TEST: 'LAB_TEST'
}