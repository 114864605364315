import {View, Text} from 'react-native';
import React from 'react';
import {Modal} from 'antd';
import {Button} from 'native-base';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../constants';
import {useIntl} from 'react-intl';
import {IExpiredInboxAlertProps} from './interfaces';

const ExpiredInboxAlert = (props: IExpiredInboxAlertProps) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({id: 'expiredInboxAlertTitle'})}
      open={props.isOpen}
      onCancel={() => {
        props.onClose();
      }}
      footer={null}
    >
      <View>
        <Text>{intl.formatMessage({id: 'expiredInboxAlertText'})}</Text>
      </View>

      <Button.Group style={{marginTop: 12}} justifyContent={'flex-end'}>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              props.onConfirm();
            },
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'checkNow',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.SECONDARY,
            onPress: () => {
              props.onClose();
            },
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'doItLater',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      </Button.Group>
    </Modal>
  );
};

export default ExpiredInboxAlert;
