
import { AddOrUpdateCampaignTabs } from '../AddOrUpdateCampaignTabs';

const AddOrUpdateCampaign = (props: {
  shouldShowEdit: boolean;
  initData?: any;
  close?:(status: string)=>void;
  segmentId?:any;
  isDuplicate?:boolean;
  channel?:any;
  templateId?:any;
  showLeadAndPatient?: boolean;
}) => {
  return (
    <AddOrUpdateCampaignTabs
      {
        ...props
      }
    />
  );
};

export default AddOrUpdateCampaign;
