import {
  AdvancedType,
  BasicType,
  components,
  createCustomBlock,
  getPreviewClassName,
  IBlockData,
} from '@foldhealth/easy-email-core';
import {merge} from 'lodash';
import React from 'react';
import {CustomBlocksType} from '../constants';

const {Column, Section, Wrapper, Text, Button, Image, Group, Raw} = components;

export type IDalle = IBlockData<
  {
    'background-color': string;
    'button-color': string;
    'button-text-color': string;
    'description-color': string;
    'title-color': string;
  },
  {
    suggestions: any;
  }
>;
const suggestionPlaceholder = {
  title: 'Happy Birthday',
  description: 'Wish you a very Happy Birthday',
};
export const DalleSuggestions = createCustomBlock<IDalle>({
  name: 'Suggested Content',
  type: CustomBlocksType.DALLE_SUGGESTIONS,
  validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
  create: (payload) => {
    const defaultData: IDalle = {
      type: CustomBlocksType.DALLE_SUGGESTIONS,
      data: {
        value: {
          suggestions: [
            {
              title: 'Suggested Content',
              url: 'https://google.com',
            },
          ],
        },
      },
      attributes: {
        'background-color': '#ffffff',
        'button-text-color': '#ffffff',
        'button-color': '#414141',
        'title-color': '#222222',
        'description-color': '#222222',
      },
      children: [],
    };
    return merge(defaultData, payload);
  },
  render: (params: {
    data?: any;
    idx?: any;
    mode?: any;
    context?: any;
    dataSource?: any;
  }) => {
    const {suggestions} = params.data.data.value;
    const attributes = params.data.attributes;

    const perWidth = '100%';

    return (
      <Wrapper
        // add class name when testing preview
        css-class={
          params.mode === 'testing'
            ? getPreviewClassName(params.idx, params.data.type)
            : ''
        }
        padding="20px 0px 20px 0px"
        border="none"
        direction="ltr"
        text-align="center"
        background-color={attributes['background-color']}
      >
        <Section padding="20px">
          <Group vertical-align="top" direction="ltr">
            {suggestions.map((item: any, index: any) => (
              <Column
                width={perWidth}
                padding="0px"
                border="none"
                vertical-align="top"
              >
                <Image
                  align="center"
                  height="auto"
                  padding="10px"
                  width="auto"
                  src={item.url}
                  alt={item.title}
                />
              </Column>
            ))}
          </Group>
        </Section>
      </Wrapper>
    );
  },
});

export {DallePanel} from './Panel';
