import {ColumnsType} from 'antd/lib/table';
import {Pressable, Text, View} from 'native-base';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import {capitalizeText} from '../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import CallForwardSvg from '../../../common/Svg/CallForwardSvg';
import IvrFlowSvg from '../../../common/Svg/IvrFlowSvg';
import PhoneRingSvg from '../../../common/Svg/PhoneRingSvg';
import Required from '../../../common/Svg/Required';
import {IIvrList} from '../CloudTelephonyView/interface';
import ActionsView from './ActionsView';
import SmsInboxToggle from './SmsInboxToggle';

export const getActiveNumbersTableColumns = (
  onActionPerformed: (actionCode: string, rowData: any, value?: any) => void,
  ivrList: IIvrList[],
  inboxes?: any[]
): any => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="phoneNumberAndName"
        />
      ),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '20%',
      fixed: 'left',
      render: (text: any): JSX.Element => {
        return (
          <View flexDirection={'row'} alignItems={'center'}>
            <View style={{padding: 10}}>
              <PhoneRingSvg />
            </View>
            <View style={{marginLeft: 12}}>
              <Text size={'smSemibold'} color={Colors.Custom.Gray900}>
                {text.phoneNumber}
              </Text>
              <Text
                style={{marginTop: 4}}
                size={'smMedium'}
                color={Colors.Custom.Gray500}
              >
                {text.name}
              </Text>
            </View>
          </View>
        );
      },
      responsive: ['md'],
    },

    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="assignedFor"
        />
      ),
      // dataIndex: 'assignedTypes',
      // key: 'assignedTypes',
      width: '20%',

      render: (text: any): JSX.Element => {
        const flowType = text.flowType;
        let name = flowType.value;
        if (flowType.flowTypeCode == 'IVR') {
         const flowTypeIVRName = ivrList?.find((item: any) => {
            return item?.uuid == flowType.value;
          });
          name = flowTypeIVRName?.name ;
        }
        const assignedFor = text?.assignedTypeCode == 'USER' ? 'User' : 'Clinic';
        return (
          <>
            <Text size={'smMedium'} color={Colors.Custom.Gray500}>
              {assignedFor}
            </Text>
          </>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="smsInbox"
    //     />
    //   ),
    //   key: 'actionSmsToggle',
    //   width: '10%',
    //   render: (selectedRecord: any) => {
    //     const selectedInbox = inboxes?.find(
    //       (element) =>
    //         element?.phoneNumber == selectedRecord?.phoneNumber?.phoneNumber
    //     );
    //     return (
    //       <>
    //         <SmsInboxToggle
    //           selectedRecord={selectedRecord}
    //           onActionClick={(actionCode: string, selectedRecord: any): any => {
    //             onActionPerformed(actionCode, selectedRecord, selectedInbox);
    //           }}
    //           isSmsInbox={selectedInbox && !selectedInbox?.isDeleted ? true : false}
    //         />
    //       </>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="flowType"
    //     />
    //   ),
    //   key: 'flowType',
    //   width: '19%',

    //   render: (record: any): JSX.Element => {
    //     const text = record.flowType;
    //     let name = text.value;
    //     if (text.flowTypeCode === 'IVR') {
    //       const ivrItem = ivrList?.find((item) => {
    //         return item.uuid === text.value;
    //       });
    //       name = ivrItem?.name || '';
    //     }
    //     return (
    //       <View flexDirection={'row'} alignItems={'center'}>
    //         <View style={{padding: 11}}>
    //           {text.flowTypeCode == 'NUMBER_FORWARD' ? (
    //             <CallForwardSvg />
    //           ) : text.flowTypeCode == 'IVR' ? (
    //             <IvrFlowSvg />
    //           ) : (
    //             <Required />
    //           )}
    //         </View>
    //         <View style={{marginLeft: 12}}>
    //           {text.flowTypeCode == 'IVR' ||
    //             text.flowTypeCode == 'NUMBER_FORWARD' ? (
    //             <>
    //               <Text size={'smSemibold'} color={Colors.Custom.Gray900}>
    //                 {text.flowTypeCode == 'IVR'
    //                   ? 'Set to IVR'
    //                   : text.flowTypeCode == 'NUMBER_FORWARD'
    //                     ? 'Forwarded to'
    //                     : ''}
    //               </Text>
    //               {name || text.friendlyName ? (
    //                 <Text size={'smMedium'} color={Colors.Custom.Gray500}>
    //                   {name || text.friendlyName}
    //                 </Text>
    //               ) : (
    //                 <></>
    //               )}
    //             </>
    //           ) : (
    //             <Pressable
    //               onPress={() => {
    //                 onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
    //               }}
    //             >
    //               <Text size={'smSemibold'} color={Colors.secondary['500']}>
    //                 Set IVR/Forward
    //               </Text>
    //             </Pressable>
    //           )}
    //         </View>
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="lastActivityOn"
    //     />
    //   ),
    //   dataIndex: 'activityOn',
    //   key: 'activityOn',
    //   width: '14%',

    //   render: (text: any): JSX.Element => {
    //     return (
    //       <Text size={'smMedium'} color={Colors.Custom.Gray500}>
    //         {text}
    //       </Text>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="status"
    //     />
    //   ),
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '15%',
    //   render: (text: any): JSX.Element => {
    //     return <View>{text.status ? 'active' : 'off'}</View>;
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="callLog"
    //     />
    //   ),
    //   key: 'callLog',
    //   width: '10%',
    //   render: (selectedRecord: any) => {
    //     return (
    //       <>
    //         <ActionsView2
    //           selectedRecord={selectedRecord}
    //           onActionClick={(actionCode: string): any => {
    //             onActionPerformed(actionCode, selectedRecord);
    //           }}
    //         />
    //       </>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="defaultPreference"
        />
      ),
      key: 'action',
      width: '20%',
      fixed: 'right',
      onCell: ()=> {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (selectedRecord: any) => {
        const selectedInbox = inboxes?.find(
          (element) =>
            element?.phoneNumber == selectedRecord?.phoneNumber?.phoneNumber
        );
        return (
          <>
            <ActionsView
              isShowDefaultSetAction={true}
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord, selectedInbox);
              }}
              isSmsInbox={selectedInbox && !selectedInbox?.isDeleted ? true : false}
            />
          </>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '20%',
      fixed: 'right',
      render: (selectedRecord: any) => {
        const selectedInbox = inboxes?.find(
          (element) =>
            element?.phoneNumber == selectedRecord?.phoneNumber?.phoneNumber
        );
        return (
          <>
            <ActionsView
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord, selectedInbox);
              }}
              isSmsInbox={selectedInbox && !selectedInbox?.isDeleted ? true : false}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};
