import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  inputViewStyle: {
    marginVertical: 5,
  },
  inputStyle: {
    height: 44,
    borderRadius: 8,
  },
  phoneNumberWrapper: {
    flex: 1,
  },
  iconWrapper: {
    flex: 0.1,
    marginRight: 10,
  },
  phoneNumberView: {
    flex: 0.9,
  },
});
