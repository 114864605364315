import {Table} from 'antd';
import {Dimensions} from 'react-native';
import {IPackagesTable} from './interfaces';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {getPackagesTableColumns} from './PackagesTableColumn';
import {Spinner, VStack} from 'native-base';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import { TableWrapper } from '../../../../common/TableWrapper';
import './styles.css'

const JourneyPackagesTable = (props: IPackagesTable) => {
  const {height} = Dimensions.get('window');
  const finalHeight = height - (props?.isFilterOpen ? 336 : 250);
  const handlePageChange = (page: number, currentPageSize: number) => {
    props.onActionPerformed(COMMON_ACTION_CODES.LOAD_MORE, {currentPage: page, currentPageSize});
  };

  return (
    <>
      <PageBodyContainer>
        <TableWrapper
          wrapperStyle={{flex: 1}}
          pagination={{
            current: props?.currentPage,
            pageSize: props.limit,
            total: props.packagesData?.total?._count || 0,
            onChange: (currentPage, currentPageSize) => {
              handlePageChange(currentPage, currentPageSize)
            },
          }}
        >
        <Table
          scroll={{x: 700, y: finalHeight}}
          columns={getPackagesTableColumns(props.onActionPerformed)}
          className="package-list-table-view"
          rowKey={(row) => row.id}
          loading={{
            spinning: props.isListLoading,
            indicator: (
              <VStack justifyContent="center" height={'100%'}>
                <Spinner size={'lg'} />
              </VStack>
            ),
          }}
          dataSource={props.packagesData.products}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          onRow={(data) => {
            return {
              onClick: () => {
                props.onActionPerformed?.(COMMON_ACTION_CODES.EDIT, data);
              },
            };
          }}
          pagination={false}
          locale={{
            emptyText: !props.isListLoading && props?.noDataPreview,
          }}
        />
        </TableWrapper>
      </PageBodyContainer>
    </>
  );
};

export default JourneyPackagesTable;
