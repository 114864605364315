import {Popover} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {cloneDeep} from 'lodash';
import {HStack, Icon, InfoOutlineIcon, Text, View, VStack} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {DATE_FORMATS, DISPLAY_DATE_FORMAT} from '../../../../../constants';
import {IMlov} from '../../../../../Interfaces';
import {Colors} from '../../../../../styles';
import {
  addTimeToDate,
  getCurrentTimeZone,
  getCurrentTimeZoneAbbr,
  getDateStrFromFormat,
  getDateStrFromFormatWithTimezone,
  getDiffInMinutes,
} from '../../../../../utils/DateUtils';
import {getMlovValueFromId} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {IProductDetailForm} from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {getDisplayValue, getDisplayValueComponent} from '../../../Workflow/FlowComponent/StateNodes/FlowNodeHelper';
import {getElementsConnectedToRootElement} from '../../../Workflow/workflowUtils';
import { ICareJourneyTeam } from '../../JourneysOfCare/PatientCareJourney/PatientJourneyInterface';
import {JOURNEY_TRIGGER_EVENTS} from '../JourneyTabs';
import {IActivityDisplay, IActivityGroup} from './interface';

export const ACTIVITY_TYPES = [
  'CareJourneyAssessment',
  'CareJourneyContent',
  'CareJourneyContentV2',
  'CareJourneyExercise',
  'CareJourneyImmunization',
  'CareJourneyInstruction',
  'CareJourneyLabTest',
  'CareJourneyLabTest1',
  'CareJourneyMedication',
  'CareJourneyNutrition',
  'CareJourneyVisit',
  'CareJourneyVital',
  'CareJourneyWebinarNode',
];

export const getActivitiesFromElements = (
  elements: any[],
  nodeMasterDataMap: any,
  nodeMetaData: any,
  startDate?: Date,
  endDate?: Date
): IActivityGroup[] => {
  const connectedElements = getElementsConnectedToRootElement(elements);
  const activities = connectedElements.filter((element) => {
    return (
      element?.data?.nodeType &&
      nodeMasterDataMap &&
      nodeMasterDataMap[element.data.nodeType] &&
      element.data?.metaData?.userInputFieldList &&
      ACTIVITY_TYPES.includes(element.data.nodeType)
    );
  });
  // const updatedActivities = activities.map((element) => {
  //   const nodeData = cloneDeep(nodeMasterDataMap[element.data.nodeType]);
  //   nodeData.hasWorkflow = doesNodeContainWorkflow(
  //     element,
  //     elements,
  //     nodeMasterDataMap
  //   );
  //   nodeData.userInputFieldList = element.data.metaData.userInputFieldList;
  //   return nodeData;
  // });

  return getGroupedActivityForDisplay(
    activities,
    nodeMetaData,
    nodeMasterDataMap,
    elements,
    startDate,
    endDate
  );
};

// Here we will check, whether immediate next node of activity is other than entity
// export const doesNodeContainWorkflow = (
//   node: any,
//   elements: any[],
//   nodeMasterDataMap: any
// ) => {
//   let hasWorkflow = false;
//   const nextConnectedNodeIds = elements
//     .filter((element: any) => element.source === node.id)
//     .map((arrow) => arrow.target);
//   if (nextConnectedNodeIds.length > 0) {
//     elements.forEach((element: any) => {
//       if (
//         nextConnectedNodeIds.includes(element.id) &&
//         nodeMasterDataMap &&
//         nodeMasterDataMap[element.data.nodeType]
//       ) {
//         if (
//           nodeMasterDataMap[element.data.nodeType].category?.code !== 'ENTITY'
//         ) {
//           hasWorkflow = true;
//         }
//       }
//     });
//   }
//   return hasWorkflow;
// };

export const getGroupedActivityForDisplay = (
  activities: any[],
  nodeMetaData: any,
  nodeMasterDataMap: any,
  elements: any[],
  startDate?: Date,
  endDate?: Date
): IActivityGroup[] => {
  const groups: IActivityGroup[] = [];
  activities.forEach((activity: any) => {
    const displayData = getActivityDisplayData(
      activity,
      nodeMetaData,
      nodeMasterDataMap,
      elements,
      startDate,
      endDate
    );
    const nodeData = cloneDeep(nodeMasterDataMap[activity.data.nodeType]);

    const entityType =
      nodeData.passData && nodeData.passData.entityType
        ? nodeData.passData.entityType
        : 'ACTIVITY';

    const matchedData = groups.filter(
      (group) => group.entityType === entityType
    );
    if (matchedData.length > 0) {
      matchedData[0].list.push(displayData);
    } else {
      groups.push({
        title: nodeData.displayName,
        entityType: entityType,
        list: [displayData],
      });
    }
  });
  return groups;
};

export const getActivityDisplayData = (
  activity: any,
  nodeMetaData: any,
  nodeMasterDataMap: any,
  elements: any[],
  startDate?: Date,
  endDate?: Date
): IActivityDisplay => {
  const inputList = activity.data.metaData.userInputFieldList;
  const hasWorkflow = false;
  // doesNodeContainWorkflow(activity,elements,nodeMasterDataMap);

  const displayData: IActivityDisplay = {
    id: activity.id,
    name: '',
    frequency: '-',
    duration: '-',
    start: '-',
    end: '-',
    note: '-',
    hasWorkflow: hasWorkflow,
    dataObject: activity,
  };

  let isFrequencyNever = false;

  const activityRange: any = {
    trigger: undefined,
    duration: undefined,
  };
  inputList.forEach((input: any) => {
    if (
      input.key === 'instruction' ||
      input.key === 'educationContent' ||
      (input.key === 'entity' && input.fieldType !== 'EXERCISE_SEARCH') ||
      (input.key === 'type' &&
        activity.data &&
        activity.data.nodeType === 'CareJourneyVisit') ||
        input.fieldType === 'ACTIVITY_TARGET'
    ) {
      displayData.name = getDisplayValueComponent(input, nodeMetaData);
    }
    if (input.key === 'note') {
      displayData.note = getDisplayValue(input, nodeMetaData);
    }
    if (input.key === 'frequency') {
      displayData.frequency = getDisplayValue(input, nodeMetaData);
    }
    if (input.key === 'frequencyObject') {
      if (input.displayValue) {
        displayData.frequency = input.displayValue;
      }
      if (input.value?.code === 'NEVER') {
        isFrequencyNever = true;
      }
    }
    if (input.key === 'trigger') {
      activityRange.trigger = input;
    }
    if (input.key === 'duration') {
      activityRange.duration = input;
      displayData.duration = getDisplayValue(input, nodeMetaData);
    }
    if (input.key == 'groupAppointmentWebinar') {
      const appointmentData = input?.value?.appointmentData || {};
      let eventName = appointmentData?.eventName || '';

      const recurrenceData = appointmentData?.reference?.recurrenceData;
      let inputStartDateTime = appointmentData?.startDateTime;
      let inputEndDateTime = appointmentData?.startDateTime;
      if (recurrenceData && recurrenceData?.endDate && recurrenceData?.seriesStartDate) {
        inputStartDateTime = getDateStrFromFormat(recurrenceData?.seriesStartDate, DATE_FORMATS.CONVERSATION_DATE_PICKER)
        inputEndDateTime = getDateStrFromFormat(recurrenceData?.endDate, DATE_FORMATS.CONVERSATION_DATE_PICKER)
      }
      if (eventName && eventName?.trim()?.length) {
        eventName = eventName.length >= 30 ? `${eventName.trim().slice(0, 30)}...` : eventName;
      }
      displayData.name = eventName;
      const defaultTimeZone = getCurrentTimeZone();
      const timezoneAbbr = getCurrentTimeZoneAbbr();
      const startDateTime = getDateStrFromFormatWithTimezone(inputStartDateTime, defaultTimeZone,  DISPLAY_DATE_FORMAT);
      const endDateTime = getDateStrFromFormatWithTimezone(inputEndDateTime, defaultTimeZone, DISPLAY_DATE_FORMAT);
      displayData.frequency = 'Never';
      if (startDateTime && endDateTime) {
        displayData.start = `${startDateTime} (${timezoneAbbr})`;
        displayData.end = `${endDateTime} (${timezoneAbbr})`;
        const duration = getDiffInMinutes(
          appointmentData.startDateTime,
          appointmentData.endDateTime
        );
        displayData.duration = `${duration} mins`;
      }
      isFrequencyNever = false;
    }

  });

  if (activityRange.trigger && activityRange.trigger.value) {
    let activityStartDate = new Date();
    if (startDate) {
      // calculate activity start date
      const careJourneyStartDate = startDate;
      const unit = getDurationUnitFromWorkflowUnits(
        activityRange.trigger.value.unit
      );
      const offsetValue = activityRange.trigger.value.value;
      activityStartDate = addTimeToDate(
        careJourneyStartDate,
        offsetValue,
        unit
      );
      displayData.start = getDateStrFromFormat(
        activityStartDate,
        DISPLAY_DATE_FORMAT
      );
    } else {
      displayData.start = getDisplayValue(activityRange.trigger, nodeMetaData);
    }
    if (activityRange.duration && activityRange.duration.value) {
      if (startDate) {
        // calculate activity end date
        const unit = getDurationUnitFromWorkflowUnits(
          activityRange.duration.value.unit
        );
        let activityEndDate = new Date();
        if (unit === 'END' && endDate) {
          activityEndDate = endDate;
        } else {
          const offsetValue = activityRange.duration.value.value;
          activityEndDate = addTimeToDate(activityStartDate, offsetValue, unit);
          activityEndDate = addTimeToDate(activityEndDate, -1, 'DAY');
        }

        displayData.end = getDateStrFromFormat(
          activityEndDate,
          DISPLAY_DATE_FORMAT
        );
      } else {
        displayData.end = getDisplayValue(activityRange.duration, nodeMetaData);
      }
    }
  }

  if (isFrequencyNever) {
    displayData.duration = '-';
    displayData.end = '-';
    activityRange.end = '-';
    activityRange.duration = undefined;
  }
  return displayData;
};

export const getDurationUnitFromWorkflowUnits = (unit: string) => {
  switch (unit) {
    case 'Hours':
      return 'HOUR';
    case 'Days':
      return 'DAY';
    case 'Weeks':
      return 'WEEK';
    case 'Months':
      return 'MONTH';
    case 'End':
      return 'END';
    case 'Years':
      return 'YEAR';
    default:
      return '';
  }
};

export const getJourneyStart = (
  journeyDetails: IProductDetailForm,
  isAssignWorkflow: boolean
): string => {
  if (isAssignWorkflow) {
    return (
      journeyDetails.startDate ||
      'Journey Assigned'
    );
  } else {
    return journeyDetails.triggerEvents
      .map((trigger) => {
        const matchedValue = JOURNEY_TRIGGER_EVENTS.filter(
          (event) => event.value === trigger
        );
        if (matchedValue.length > 0) {
          return matchedValue[0].label;
        }
        return '';
      })
      .join(', ');
  }
};

export const getCareTeam = (
  journeyDetails: IProductDetailForm,
  isAssignWorkflow: boolean,
  userRoles: IMlov[],
  hasCareTeamRoleSuggestions?: boolean,
): string | JSX.Element => {
  if (isAssignWorkflow) {
    return (
      journeyDetails.careTeamUsers?.map((user) => user.name).join(', ') || ''
    );
  } else {
    const careTeamDisplayText = journeyDetails.careTeam?.map((roleId) => getMlovValueFromId(userRoles, roleId)).join(', ') || '';

    return <VStack>
      <Text>{careTeamDisplayText || '-'}</Text>
      {
        hasCareTeamRoleSuggestions &&
        <HStack alignItems={'center'} space={1} fontSize={"xs"}>
          <InfoOutlineIcon style={{ color: Colors.info[700] as string }} size="xs" />
          <DisplayText extraStyles={{ color: Colors.info[700] as string }} textLocalId="journeyCareTeamRoleSuggestion" />
        </HStack>
      }
    </VStack>;
  }
};

export const getCareJourneyEndDate = (
  date: Date,
  journeyDetails: IProductDetailForm | any
): Date => {
  const duration = journeyDetails.duration?.value || 0;
  const durationUnit = journeyDetails.duration?.unit;
  let durationUnitCode = 'MONTH';
  const matchedData =
    journeyDetails.duration?.unitList.filter(
      (duration: any) => duration.id === durationUnit
    ) || [];
  if (matchedData.length > 0) {
    durationUnitCode = matchedData[0].code;
  }
  const endDate = addTimeToDate(date, duration, durationUnitCode);
  return endDate;
};

export const getActivityColumns = (config: {
  showAssignee: boolean;
  showActions: boolean;
}): ColumnsType<IActivityDisplay> => {
  const columns: ColumnsType<IActivityDisplay> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (name: string) => <Text>{name}</Text>,
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="frequency"
        />
      ),
      dataIndex: 'frequency',
      key: 'frequency',
      width: '15%',
      render: (frequency: string) => <Text>{frequency}</Text>,
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="duration"
        />
      ),
      dataIndex: 'duration',
      key: 'duration',
      width: '15%',
      render: (duration: string) => <Text>{duration}</Text>,
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="start"
        />
      ),
      dataIndex: 'start',
      key: 'start',
      width: '10%',
      render: (start: string) => <Text>{start}</Text>,
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="end"
        />
      ),
      dataIndex: 'end',
      key: 'end',
      width: '10%',
      render: (end: string) => <Text>{end}</Text>,
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="notes"
        />
      ),
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      render: (note: string) => {
        return (
          <Popover
            overlayInnerStyle={{
              bottom: 3,
              borderRadius: 16,
              padding: 0,
            }}
            content={
              <View maxWidth={400}>
                <Text>{note}</Text>
              </View>
            }
            title=""
            // trigger="hover"
            placement={'bottom'}
          >
            <Text numberOfLines={1}>{note}</Text>
          </Popover>
        );
      },
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="hasWorkflow"
    //     />
    //   ),
    //   key: 'end',
    //   width: '10%',
    //   render: (record: IActivityDisplay) => (
    //     <Text>{record.hasWorkflow ? 'Yes' : 'No'}</Text>
    //   ),
    // },
  ];

  if (config.showAssignee) {
    columns.push({
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="assignee"
        />
      ),
      key: 'assignee',
      width: '10%',
      render: () => <Text>Patient</Text>,
    });
  }

  if (config.showActions) {
    columns.push({
      key: 'actions',
      width: '5%',
      render: () => (
        <View>
          <Icon as={AntIcon} name="edit" size="4" color="gray.400" />
        </View>
      ),
    });
  }

  return columns;
};

export const getPatientJourneyTeamRole = (patientCareJourneyTeam: ICareJourneyTeam[]) => {
   return (patientCareJourneyTeam || []).map(journeyTeam => {
      return {roleId: journeyTeam.roleId};
   });
};
