import {Line, LineConfig} from '@ant-design/charts';
import {View} from 'native-base';
import React, {memo} from 'react';
import {graphColorList} from '../../../../RightSideContainer/Analytics/EmployerAnalytics/graphColors';
import {ITaskCompletedLineChart, IPageSection} from '../interface';
import {getTaskCompletedLineChart, getYAxisMaxValue} from './UtilsConst';
import { DateRange } from '../../../../RightSideContainer/Analytics/CommonComponents/Interfaces';
import { getDiffInMonths, getMomentObj } from '../../../../../utils/DateUtils';
import { handleResponseDataAddMonth } from '../../../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/WidgetUtils';

const TaskCompletedLineChart = (props: {
  widgetData: ITaskCompletedLineChart;
  dateRange: DateRange;
  pageSection: IPageSection;
}) => {
  const {widgetData, dateRange, pageSection} = props;
  const fromDate = getMomentObj(dateRange?.from);
  const toDate = getMomentObj(dateRange?.to);
  const diffInMonths = getDiffInMonths(fromDate, toDate);
  const taskCompletedLineChart = getTaskCompletedLineChart(widgetData);
  const yAxisMaxValue = getYAxisMaxValue(
    taskCompletedLineChart,
    pageSection?.resource?.code
  );
  const lineConfig: LineConfig = {
    data: taskCompletedLineChart as any,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    height: 280,
    yAxis: {
      ...(yAxisMaxValue),
      title: {
        text: 'No. of Tasks',
        style: {
          fill: '#3A485F'
        }
      },
      line: {
        style: {
          stroke: '#D0D6E1',
          lineWidth: .5
        },
      },
      grid: {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#E9ECF1',
            lineWidth: .5
          },
        },
        closed: true,
      },
    },
    xAxis: {
      title: {
        text: diffInMonths <= 2 ? 'Weeks' : 'Months',
        style: {
          fill: '#3A485F'
        },
      },
      label: {
        formatter(text: any) {
          const formattedDate = text.split(" - ")
          const startDate = formattedDate[0]
          const endDate = formattedDate[1]
          if(startDate && endDate && diffInMonths <= 2) {
            return `  ${startDate} -
${endDate}` 
          } else {
            return text
          }
        },
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: '#6F7A90'
        },
      },
      line: {
        style: {
          stroke: '#D0D6E1',
          lineWidth: .5
        },
      },
      grid:  {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#E9ECF1',
            lineDash: [1,1],
            lineWidth: .5
          },
        },
        closed: true,
      },
    },
    color: graphColorList,
    legend: {
      itemSpacing: 3,
      itemName: {
        formatter(text) {
          const legendText = text
            ?.replace(
              /^[a-z]|[A-Z]/g,
              (c: any, i: any) => (i ? ' ' : '') + c.toUpperCase()
            )
            .replace('Amount', '');
          return `${legendText}`.toLocaleUpperCase()
        },
      },
      position: 'top-right',
      layout: 'horizontal',
      marker: {
        symbol: 'circle',
        style(style) {
          return {
            ...style,
            fill: style.stroke,
            stroke: null,
            r: 4
          }
        },
      },
    },
    lineStyle: {
      lineWidth: 1,
    },
    point: {
      shape: ({type}) => {
        return 'circle';
      },
    },

  };
  return (
    <View style={{justifyContent: 'center', height: 300, marginTop: 16}}>
      <Line {...lineConfig} />
    </View>
  )
}

export default memo(TaskCompletedLineChart)
