import { Select as AntSelect } from 'antd';
import { HStack, Link, Spinner, Text } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { WorkflowContext } from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles';
import { GraphMemberList } from '../../../../common/AppDashboard/GraphMemberList';
import { getMemberMatchingContactList } from '../../Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { INPUT_FIELD_TYPE } from './NodeInputField';
import { blue200 } from 'react-native-paper/lib/typescript/styles/colors';
const { Option } = AntSelect;
//import './NodeInputFieldStyles.css';

const ShowFilterTotalCountInputField = (props: {sourceHandle?:string,setUserInputFieldList?:any,getPreviousImmediateNode?:any, prevImmediateNode?:any, value?:any, onChange?:any, userInputFieldList: any , widthHeightRatio?:number}) => {
  const widthHeightRatio = props?.widthHeightRatio;
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const [data, setData] = useState({ contactUuidList: [], isLoading: false, isOpenDrawer: false })
  const getUserInputFieldObj = (userInputFieldList: any) => {
    const userInputData: any = {}
    if (!userInputFieldList || userInputFieldList.length == 0) {
      return userInputData
    }
    userInputFieldList.forEach((userInputField: any) => {
      userInputData[userInputField.key] = userInputField
    })
    return userInputData
  }

  const [parentContactUuidList, setParentContactUuidList] = useState([]);


  const getPreviousNodeContactUuidList = (prevImmediateNode:any, getPreviousImmediateNode:any, sourceHandle:string): any=>{

    let contactUuidList;
      if(!prevImmediateNode ){
        return contactUuidList
      }

    let prevUserInputFieldList = prevImmediateNode?.data?.metaData?.userInputFieldList || [];
    let nodeType = prevImmediateNode?.data?.nodeType;
     if(prevImmediateNode?.type == 'Condition'){
        prevUserInputFieldList = prevImmediateNode?.data?.metaData?.optionMap?.[sourceHandle || 'out']?.userInputFieldList || []
        nodeType = prevImmediateNode?.data?.metaData?.optionMap?.[sourceHandle || 'out']?.nodeType;
     }
     if(prevImmediateNode && (nodeMasterDataMap[nodeType]?.isMemberFilterNode)){
        (prevUserInputFieldList).some((userInputField:any)=>{
          if(userInputField.fieldType === INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT && userInputField?.value){
            contactUuidList = userInputField?.value?.contactUuidList
            return true;
          }
        })
     } else {
      contactUuidList = getPreviousNodeContactUuidList(getPreviousImmediateNode(prevImmediateNode?.id), getPreviousImmediateNode, prevImmediateNode?.data?.sourceHandle)
     }
     return contactUuidList
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [userInputFieldListStr,setUserInputFieldListStr] = useState('')
  useEffect(()=>{
    if(props.prevImmediateNode){
      const currentStr = JSON.stringify(props.userInputFieldList);
      const list = getPreviousNodeContactUuidList(props.prevImmediateNode, props.getPreviousImmediateNode,props?.sourceHandle || 'out')
      if((userInputFieldListStr!= currentStr || list?.length != parentContactUuidList?.length) && !data.isLoading && props.prevImmediateNode){
        setUserInputFieldListStr(currentStr);
        setParentContactUuidList(list || [])
        setIsLoaded(false)
        loadMemberMatchingContactList(props.userInputFieldList, list)
      }
    }
  },[props.prevImmediateNode, getPreviousNodeContactUuidList(props.prevImmediateNode, props.getPreviousImmediateNode,props?.sourceHandle || 'out')])

  const loadMemberMatchingContactList = async (userInputFieldList: any, parentContactUuidList:any) => {
    if(!parentContactUuidList?.length && props.prevImmediateNode ) {
      return
    }
    const userInputData = getUserInputFieldObj(userInputFieldList)
    setData({ ...data, isLoading: true })
    const params:any = {
      userInputData: userInputData,
      filterType: 'contact'
    }

    if(parentContactUuidList && props.prevImmediateNode){
      params.contactUuidList = parentContactUuidList;
    }
    const response = await getMemberMatchingContactList(params);
    setData({ contactUuidList: response.contactList || [], isLoading: false, isOpenDrawer:false })
    if(response?.contactList?.length != props?.value?.contactUuidList?.length && props.onChange){
      props.onChange({contactUuidList: response.contactList})
    }
    if(props.setUserInputFieldList && !props?.onChange){
      const userInputFieldList = JSON.parse(JSON.stringify(props?.userInputFieldList))

      const userInputFieldFilterList = ( userInputFieldList || [])?.filter((userInputField:any)=>{
        return userInputField.fieldType === INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT
      })
      if(userInputFieldFilterList && userInputFieldFilterList?.length && userInputFieldFilterList[0]?.value?.contactUuidList?.length != response.contactList?.length ){
        const updatedList = ( userInputFieldList || [])?.map((userInputField:any)=>{
          if( userInputField.fieldType === INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT){
            userInputField.value = {contactUuidList: response.contactList};
          }
          return userInputField;
        })
        props.setUserInputFieldList(updatedList);
      }

    }
  }

  useEffect(() => {
    if(!data?.isLoading && ( userInputFieldListStr != JSON.stringify(props.userInputFieldList) )){
      setUserInputFieldListStr(JSON.stringify(props.userInputFieldList))
      setIsLoaded(false);
      const list = getPreviousNodeContactUuidList(props.prevImmediateNode, props.getPreviousImmediateNode, props.sourceHandle || 'out')
    loadMemberMatchingContactList(props.userInputFieldList, list)
    }
  }, [props.userInputFieldList])

  const [state, setState] = useState({
    page:1,
    pageSize:10,
    total: data?.contactUuidList.length || 0
  })

  const getPageContactUuidlist = (page:number, pageSize:number)=>{
      const list = (data?.contactUuidList|| []).slice((page-1)*(pageSize), ((page-1)*pageSize)+ pageSize)
      return list
  }

  return <HStack>{ data.isLoading ? <Spinner color={Colors.primary[100]} /> : <Link onPress={()=>{
    setData({...data, isOpenDrawer:true});
  }} alignItems={'left'} justifyContent='left'><Text color={Colors.Custom.Primary300} underline={true} fontSize={widthHeightRatio? widthHeightRatio*12 : 16}>{data.contactUuidList.length + ''}</Text></Link>}


  { data?.isOpenDrawer ? <GraphMemberList
          title={'Members'}
          page={state.page}
          pageSize={state.pageSize}
          isFromNewEmployer={false}
          isFromDashboard={false}
          onClose={()=>{
            setData({...data, isOpenDrawer:false})
            setState({page:1, pageSize:10, total:data?.contactUuidList?.length })
          }}
          onPaginationChange={(page: number, pageSize: number) => {

            setState((prev) => {
              return {
                ...prev,
                page,
                pageSize,
              }
            });
          }}
          total={data?.contactUuidList?.length}
          isOpen={!!data?.isOpenDrawer}
          contactUuidList={getPageContactUuidlist(state.page, state.pageSize)}
          onCompleteAction={() => {
            setData({...data, isOpenDrawer:false})
            setState({page:1, pageSize:10, total:data?.contactUuidList?.length })
          }}
        /> : <></>}
  </HStack>;
};


export default ShowFilterTotalCountInputField;
