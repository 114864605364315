import {DebounceSelect} from '../../../../../../../src/components/common/DebounceSelect';
import {Tag} from 'antd';
import {getWorkflowTagList} from '../WorkflowApi';
import {IWorkflowTag} from '../IWorkflow';
export function WorkflowTag(props: IWorkflowTag) {
  const getDebounceTagList = () => {
    return props.tagList.map((value: string) => {
      return {
        value: value,
        label: value,
        key: value,
      };
    });
  };

  const onTagListUpdate = (list: any) => {
    const tagList = list.map((data: any) => {
      return data.value;
    });
    props.setTagList(tagList);
  };

  const tagRender = (props: any) => {
    const {label, closable, onClose} = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={'green'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{marginRight: 3}}
      >
        {label}
      </Tag>
    );
  };

  const fetchTagList = async (searchText: string) => {
    const result = await getWorkflowTagList(searchText);
    let tagList = result?.data || [];
    tagList = tagList.map((data: any) => {
      return {
        value: data['value'],
        label: data['value'],
      };
    });
    return tagList;
  };
  return (
    <DebounceSelect
      key={JSON.stringify(props.tagList)}
      mode="multiple"
      isAddNew={props?.isAddNew || false}
      tagRender={tagRender}
      value={getDebounceTagList()}
      placeholder={props.placeholder || 'Search by tags'}
      fetchOptions={fetchTagList}
      onChange={(newValue: string) => {
        if (onTagListUpdate) {
          onTagListUpdate(newValue);
        }
      }}
      style={{
        width: '100%',
      }}
    />
  );
} // Usage of WorkflowTag

export default WorkflowTag;
