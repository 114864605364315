import { DatePicker } from 'antd';
import { Button, Center, Spinner, View } from 'native-base';
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from 'react';
import "./powerbi.css";
import { IPowerBIProps } from './interface';
import { IPowerBITokenResp } from '../../../../../services/PowerBI/interface';
import { getPowerBITokenInfoForReport, getPowerBIWorkspaces} from '../../../../../services/PowerBI/PowerBIService';
import { NoDataFound } from '../../../../common/NoDataFound';
import { Dimensions } from 'react-native';
import { PowerBiReportMode } from '../../../BrazilCodeMR/pages/analytics/AnalyticsController';



const PowerBiView = (props: IPowerBIProps) => {
  const { height } = Dimensions.get('window');
  const [powerBiViewState, setPowerBiViewState] = useState({
    tokenInfo: {} as IPowerBITokenResp,
    isLoading: true,
    isError: false,
  });
  const getPowerBiEmbedTokenInfoFromAPI = async () => {
    setPowerBiViewState((prev) => {
      return {
        ...prev,
        isLoading: true,
        isError: false,
        tokenInfo: {} as any
      }
    });
    const powerBiReport = props?.selectedPowerBiWorkspaceReport;
    const embedTokenInfo = await getPowerBITokenInfoForReport({
      reportId: powerBiReport?.powerBiReportId,
      workspaceId: powerBiReport?.powerBiWorkspaceId
    }).catch(() => {
      setPowerBiViewState((prev) => {
        return {
          ...prev,
          isError: true,
          isLoading: false
        }
      });
    });
    if (embedTokenInfo?.data?.accessToken && embedTokenInfo?.data?.embedUrl) {
      setPowerBiViewState((prev) => {
        return {
          ...prev,
          isLoading: false,
          tokenInfo: embedTokenInfo?.data
        }
      });
    }
  };

  const updatePowerBIPageFilter = async () => {
    const filter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "DateTable",
        column: "Date"
      },
      filterType: 0,
      logicalOperator: "And",
      conditions: [
        {
          operator: "GreaterThanOrEqual",
          value: props?.filters?.dateRange?.fromDate?.toISOString()
        },
        {
          operator: "LessThanOrEqual",
          value: props?.filters?.dateRange?.toDate?.toISOString()
        }
      ]
    };

    // Retrieve the page collection and then replace filters with the same target data field for the active page.
    try {
      const pages = await (window as unknown as { report: any }).report.getPages();
      // Retrieve the active page.
      const page = pages.filter(function (page: { isActive: boolean }) {
        return page.isActive
      })[0];

      await page.updateFilters(models.FiltersOperations.Replace, [filter]);
    }
    catch (errors) {
    }
  }

  React.useEffect(() => {
    getPowerBiEmbedTokenInfoFromAPI();
  }, [props?.filters?.dateRange?.fromDate, props?.filters?.dateRange?.toDate])

  React.useEffect(() => {
    getPowerBiEmbedTokenInfoFromAPI();
  }, [props?.selectedPowerBiWorkspaceReport?.id])

  React.useEffect(() => {
    if (props?.powerBiReportMode === PowerBiReportMode.EDIT) {
      (window as unknown as { report: any }).report?.switchMode("edit");
    } else if (props?.powerBiReportMode === PowerBiReportMode.VIEW) {
      (window as unknown as { report: any }).report?.switchMode("view");
    }
  }, [props?.powerBiReportMode])

  return (
    <View nativeID="empMainSection">
      {powerBiViewState.isLoading && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {!powerBiViewState.isLoading && powerBiViewState.isError && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <NoDataFound />
          </Center>
        </View>
      )}
      <View style={{
        height: height - 140
      }}>
        {!powerBiViewState.isLoading && powerBiViewState?.tokenInfo?.embedUrl && powerBiViewState?.tokenInfo?.accessToken && (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, and qna.
              id: powerBiViewState?.tokenInfo.reportId,
              embedUrl: powerBiViewState?.tokenInfo.embedUrl,
              accessToken: powerBiViewState?.tokenInfo.accessToken,
              tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
              viewMode: props?.powerBiReportMode === PowerBiReportMode?.EDIT ? 1 : 0,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: true,
                  },
                  pageNavigation: {
                    visible: false
                  },
                },

              },
              permissions: models.Permissions.ReadWrite
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  async function () {
                    updatePowerBIPageFilter();
                  },
                ],
                [
                  "rendered",
                  function (test, test2) {
                  },
                ],
                [
                  "dataSelected",
                  function (test) {
                  },
                ],
                [
                  "visualClicked",
                  function (test) {
                  },
                ],
                [
                  "visualRendered",
                  function (test) {
                  },
                ],
                [
                  "error",
                  function (event) {
                  },
                ],
              ])
            }
            cssClassName={"embed-container"}
            getEmbeddedComponent={(embeddedReport) => {
              (window as unknown as { report: any }).report = embeddedReport;
            }}
          />
        )}
      </View>
    </View>

  )
}
export default PowerBiView;
