import {useLazyQuery} from '@apollo/client';
import moment from 'moment';
import {HStack, Pressable, Text, View, VStack} from 'native-base';
import {CONVERSATION_CONST, DATE_FORMATS, GROUP_MEMBER_TYPE} from '../../../../constants';
import {getDateStrFromFormat} from '../../../../utils/DateUtils';
import {MESSAGE_CUSTOM_CARD_TYPE} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {getMessagingHeaderInfo} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {getTimeDifferent, getTimeSince} from '../../CommonUtils/CommonUtils';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import {styles} from './TaskDueTodayStyle';
import { getEhrConfig } from '../../../../utils/capabilityUtils';

const TaskDueItemInnerPart = (props: any) => {
  const {itemData, onActionPerformed} = props;
  const ehrConfig = getEhrConfig()
  const getTitle = () => {
    if (itemData?.type === 'task') {
      return 'Due Task' + ' • ' + itemData?.taskList?.status?.value;
    } else if (itemData?.type === 'appointment') {
      const contactName = itemData?.taskList?.participants
        ?.map((item: any) => item?.contact?.name)
        .filter((item: any) => !!item)
        .join(',') + ' • ' + getDateStrFromFormat(
          itemData?.taskList?.startDateTime,
          DATE_FORMATS.DISPLAY_TIME_FORMAT
        ) + getTimeCount(itemData)
      return contactName;
    } else if (itemData?.type === 'conversation') {
      let dateStr: any = itemData?.taskList?.conversationMessages[0]?.updatedAt || itemData?.taskList?.conversationMessages[0]?.createdAt || '';
      if (dateStr) {
        dateStr = dateStr + 'Z'
      }
      const date = new Date(moment(dateStr).format());
      return (
        (getMessagingHeaderInfo(itemData?.taskList,ehrConfig)?.headerString || CONVERSATION_CONST.GROUP_CHAT) + ' • ' + getTimeSince(date)
      );
    } else if (itemData?.type === 'form') {
      return itemData?.taskList?.contactName + ' • ' + getTimeSince(new Date(itemData?.taskList?.updateOn || itemData?.taskList?.createdOn));
    }
    return '';
  };

  const getTimeCount = (itemData: any) => {
    let countStr = '';
    if (itemData?.taskList?.startDateTime && itemData?.taskList?.endDateTime) {
      countStr = ' (' + getTimeDifferent(new Date(itemData?.taskList?.startDateTime), new Date(itemData?.taskList?.endDateTime)) + ')';
    }
    return countStr;
  }

  const personName = ()=> {
    if (itemData?.taskList?.contact?.name) {
      return {
        userType: GROUP_MEMBER_TYPE.CONTACT,
        name: itemData?.taskList?.contact?.name
      }
    } else if (itemData?.taskList?.participants?.length) {
      const contactParticipant:any  = itemData?.taskList?.participants?.filter((person:any)=> {
        return person?.contact?.id ? true : false
      })
      if (contactParticipant[0]?.contact?.id) {
        return {
          userType: GROUP_MEMBER_TYPE.CONTACT,
          name: contactParticipant[0]?.contact?.name
        }
      } else {
        return {
          userType: GROUP_MEMBER_TYPE.USER,
          name: itemData?.taskList?.participants[0]?.user?.name
        }
      }
    } else if (itemData?.taskList?.contactName) {
      return {
        userType: GROUP_MEMBER_TYPE.CONTACT,
        name: itemData?.taskList?.contactName
      }
    } else if (itemData?.taskList?.assigneeUser?.name) {
      return {
        userType: GROUP_MEMBER_TYPE.USER,
        name: itemData?.taskList?.assigneeUser?.name
      }
    }
    return {}
  }

  const getDescription = () => {
    if (itemData?.type === 'task') {
      return itemData?.taskList?.title;
    } else if (itemData?.type === 'appointment') {
      return itemData?.taskList?.appointmentType?.eventName;
    } else if (itemData?.type === 'conversation') {
      return itemData?.taskList?.conversationMessages[0]?.contentType === MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ? 'Custom Card' : itemData?.taskList?.conversationMessages[0]?.content;
    } else if (itemData?.type === 'form') {
      return 'Last day Submitted form ' + itemData?.taskList?.name;
    }
    return '';
  };

  return (
    <View padding={2}>
      <HStack mt={2} mb={2} backgroundColor={'white'}>
        <VStack justifyContent={'center'} alignItems={'center'}>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: 10,
              textStyle: {
                fontSize: 18,
              },
            }}
            isLetterAvatarShow
            userData={{
              userType:
                personName()?.userType === GROUP_MEMBER_TYPE.USER
                  ? GROUP_MEMBER_TYPE.USER
                  : GROUP_MEMBER_TYPE.CONTACT,
              userId: '',
              userName: personName()?.name ? personName()?.name : '',
            }}
            //groupType={props?.groupType as any}
          />
        </VStack>
        <HStack flex={1}>
          <VStack
            flex={0.7}
            alignContent={'center'}
            ml={2}
            justifyContent={'center'}
          >
            <HStack alignItems={'center'}>
              <Text
                fontSize={14}
                fontWeight={400}
                noOfLines={1}
                style={{color: 'black'}}
              >
                {getTitle()}
              </Text>
              <View></View>
            </HStack>
            <Text
              fontSize={12}
              fontWeight={400}
              noOfLines={1}
              style={{color: '#A7A7A7'}}
              paddingTop={1}
            >
              {getDescription()}
            </Text>
          </VStack>

          {true && (
            <HStack
              flex={0.3}
              justifyContent={'flex-end'}
              alignItems={'center'}
              mr={0}
            >
              {itemData?.type === 'task' && (
                <Pressable
                  onPress={() => {
                    onActionPerformed(itemData);
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={600}
                    style={styles.textButton}
                  >
                    {'View'}
                  </Text>
                </Pressable>
              )}

              {itemData?.type === 'conversation' && (
                <Pressable
                  onPress={() => {
                    onActionPerformed(itemData);
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={600}
                    style={styles.textButton}
                  >
                    {'Reply'}
                  </Text>
                </Pressable>
              )}

              {itemData?.type === 'appointment' && (
                <Pressable
                  onPress={() => {
                    onActionPerformed(itemData);
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={600}
                    style={styles.textButton}
                  >
                    {'View'}
                  </Text>
                </Pressable>
              )}

              {itemData?.type === 'form' && (
                <Pressable
                  onPress={() => {
                    onActionPerformed(itemData);
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={600}
                    style={styles.textButton}
                  >
                    {'View'}
                  </Text>
                </Pressable>
              )}
              {/* {false &&
                        <Pressable mr={2}>
                            <Text
                                fontSize={14}
                                fontWeight={600}
                                style={styles.textButton}
                            >
                                {'Accept'}
                            </Text>
                        </Pressable>
                        <Pressable>
                            <Text
                                fontSize={14}
                                fontWeight={600}
                                style={styles.textButton}
                            >
                                {'Suggest new time'}
                            </Text>
                        </Pressable>
                            } */}
            </HStack>
          )}
        </HStack>
      </HStack>
    </View>
  );
};

export default TaskDueItemInnerPart;
