import {useLazyQuery, useMutation} from '@apollo/client';

import {Content} from 'antd/lib/layout/layout';
import {Select as AntSelect} from 'antd'
import {
  FormControl,
  HStack,
  Input,
  Pressable,
  Radio,
  Text,
  useToast,
  View,
  VStack,
  Badge,
  Image,
  Divider,
  Checkbox,
  Skeleton
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  apiError,
  BUTTON_TYPE,
  changeNumberFromDefaultLocation,
  removeDefaultMessageForLocation,
  setDefaultMessageForLocation,
} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {TELEPHONY_CONST} from '../../../../constants/TelephonyConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {FlowType} from '../../../../context/WorkflowContext';
import {CloudTelephonyQueries} from '../../../../services';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {
  canCreateSMSInboxWithPhoneNumber,
  getAccountId,
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserUUID,
  numericStringMask,
} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {ICTMlov} from '../../../../utils/interfaces';
import {getMlovIdFromCode} from '../../../../utils/mlovUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import OTPInputComponent from '../../../common/OtpView/OTPInputComponent';
import DeleteSvg from '../../../common/Svg/DeleteSvg';
import EmptyIvrState from '../../../common/Svg/EmptyIvrState';
import RefreshIconSvg from '../../../common/Svg/RefreshIconSvg';
import SuccessCheck from '../../../common/Svg/SuccessCheck';
import {handleConfigurePhoneNumberAction} from '../../../common/UsageLevel/UsageLevelHandlers';
import ForwardToIvrFlowAlert from '../Alerts/ForwardToIvrFlowAlert';
import ResetForwardNumberAlert from '../Alerts/ResetForwardNumberAlert';
import {getFormattedIvrData, getIvrListByType, getStdCodeAndNumber} from '../CloudTelephonyUtils';
import {IAssignPurchaseView, IFLOWS, IUser} from '../interfaces';
import './RadioButtonCustomStyle.css';
import {styles} from './PurchaseNumberStyles';
import {CRM_URLs} from '../../../../constants/BaseUrlConst';
import {useIntl} from 'react-intl';
import {SMS_INBOX_ACTION_CODES} from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {IInbox, IInboxMember} from '../../../../services/Inbox/interfaces';
import {CHANNEL_TYPE} from '../../TeamInbox/Conversations/ConversationConst';
import UserPracticeLocationQueries from '../../../../services/Location/UserPracticeLocationQueries';
import {IAccountPracticeLocationResp} from '../../../../services/Location/interfaces';
import {IAccountLocation} from '../../Contacts/TeamMembers/interfaces';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {
  addOrUpdateDefaultNumberForLocation,
  deleteDefaultNumberForLocation,
  getDefaultPhoneNumberByLocation,
} from '../../../../services/CloudTelephony/CloudTelephonyQueries';
import ConfirmChangeDefaultLocation from './ConfirmChangeDefaultLocation';
import {PERSON_ACTION_CODES} from '../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {LOCATION_TYPE_CODES} from '../../../../constants/MlovConst';
import { SMSAutoResponseView } from '../../AutoReplyView';
import {addOrUpdateInboxMembersAPI, createInboxMembersAPI} from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsService';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import { IInboxMembers } from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/interfaces';

const NumberSetupView = (props: IAssignPurchaseView) => {
  const toast = useToast();
  const smsInboxToast = useToast();
  const intl = useIntl();
  const axios = BaseService.getSharedInstance().axios;
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const accountUUID = getAccountUUID();
  const accountId: number = getAccountId() || 0
  const currentUserUUID = getUserUUID();
  const [counter, setCounter] = useState(0);
  const assigneesList = mlovData['VirtualNumberAssignee'] || [];
  const tempFlowType = mlovData['FlowType'] || [];
  const userAssigneeType = getMlovIdFromCode(assigneesList, 'USER');
  const otherAssigneeType = getMlovIdFromCode(assigneesList, 'OTHER');
  const clinicAssigneeType = getMlovIdFromCode(assigneesList, 'CLINIC');
  const ivrList = mlovData['IvrUsageType'] || [];
  const phoneNumber = props.selectedData?.phoneNumber?.phoneNumber || props.number;
  const virtualPhoneNumberAssigneeId = props.selectedData?.id;
  const enableChatHistoryRead = isAccountConfigEnabled(CONFIG_CODES.ENABLE_CHAT_HISTORY_READ)
  const selectedInbox = props.inboxes?.find(
    (element) =>
      element?.phoneNumber == phoneNumber
  );
  const selectedIds = selectedInbox?.singleInboxData?.inboxMembers?.filter((item: any) => !item.isDeleted)
    .map((item: any) => {
      return item.userId
    });
  const flowTypeMlov = [
    ...tempFlowType,
  ];
  const assigneeTypeMlov = [
    {
      id: userAssigneeType,
      code: 'USER',
      value: 'User',
    },
    {
      id: clinicAssigneeType,
      code: 'CLINIC',
      value: 'Location',
    }
  ];
  const mask = `(###) ###-####`;
  const flowType = FlowType.ivr || '';
  const workflowUrl = `create?currentTag=TRIGGER&flowType=${flowType}`;
  const navigate = useNavigate();
  const [alertState, setAlertState] = useState({
    resetConfirmFN: false,
    forWardToIvrConfirm: false,
  });
  const [getAllNonDeletedInboxesMembers] = useLazyQuery<{
    inboxMembers: IInboxMember[]
  }>(InboxQueries.GetAllNonDeletedInboxesMembers, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [updateInboxName] = useMutation(InboxQueries.updateSMSInboxName);
  const [deleteInbox] = useMutation(InboxQueries.DeleteInbox);
  const [deleteInboxesMembers] = useMutation(InboxQueries.deleteInboxesMembers);
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);
  const [getInboxesDataByPhoneNumber] = useLazyQuery<{inboxes: IInbox[]}>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getAccountPracticeLocations] = useLazyQuery<IAccountPracticeLocationResp>(
    UserPracticeLocationQueries.GetPracticeLocationsByTenantId,
    {
      variables: {tenantId: accountUUID},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  userAssigneeType
  const [stateData, setStateData] = useState({
    selectedUser: '',
    error: '',
    assigneeType: userAssigneeType,
    assigneeTypeCode: assigneesList[0].code,
    selectedFlowType: '',
    selectedFlowTypeCode: 'IVR',
    forwardNumberValue: '',
    oldForwardNumberValue: '',
    selectedIvrFlow: '',
    selectedUnavailabilityFlowId: '',
    selectedHolidayHoursFlowId: '',
    clinicName: '',
    othersName: '',
    otpView: 'OTP_SEND',
    resendOtp: true,
    otpValue: '',
    otpInputDisabled: false,
    selectedUserName: '',
    stdCode: '+1',
    name: '',
    selectedUsersIds: [] as number[],
    selectedPhoneNumber: '',
    errors: {} as any,
    isEnableSmsInbox: false,
    smsLoading: false,
    selectedInbox: selectedInbox,
    ivrFlowList: {} as any,
    outOfOfficeIvrList: {} as any,
    accountPracticeLocations: [] as IAccountLocation[],
    selectedAccountLocation: {} as any,
    virtualPhoneNumberAssigneeId: virtualPhoneNumberAssigneeId,
    enableAutoAssignment: false,
    holidayHoursIvrList: {} as any,
    previousAccountLocation: {} as any,
    showDefaultLocationNumberConfirmModal: false,
    isDefaultForLocationExist: false,
    defaultNumberLocationIds: [] as string[],
    accountLocationLoading: false,
  });

  const [CreateVirtualNumber] = useMutation(
    CloudTelephonyQueries.CREATE_VIRTUAL_PHONE_NUMBER
  );
  const [UPDATE_FLOW_FLOW] = useMutation(
    CloudTelephonyQueries.UPDATE_FLOW_FLOW
  );

  const userActionObject = getUserActionObjectFromActionCode(
    'CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE'
  );
  const level = userActionObject.level || '';

  const handleSendOtp = () => {
    props.formActionPerformed('', {loading: true});
    const transformedNumb =
      stateData.stdCode + stateData.forwardNumberValue.replace(/[- )(]/g, '');

    const bodyStr = JSON.stringify({
      phoneNumber: transformedNumb,
    });

    axios
      .post(`/cloud-telephony/sms/otp`, bodyStr)
      .then((res) => {
        props.formActionPerformed('', {loading: false});
        if (res.status == 201) {
          setCounter(59);
          setStateData((prev) => {
            return {
              ...prev,
              otpView: 'OTP_ENTER',
              resendOtp: false,
            };
          });
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              otpView: 'OTP_SEND',
            };
          });
        }

        showToast(
          toast,
          `OTP  Successfully Send to ${transformedNumb}`,
          ToastType.success
        );
      })
      .catch((err) => {

        showToast(toast, `${err} : ${transformedNumb}`, ToastType.error);
        props.formActionPerformed('', {loading: false});
        setTimeout(() => {
          setStateData((prev) => {
            return {
              ...prev,
              otpView: 'OTP_SEND',
            };
          });
        }, 2000);
      });
  };
  const handleOtpValidation = (otp: string) => {
    props.formActionPerformed('', {loading: true});
    createOrDeleteSmsInbox();
    const transformedNumb =
      stateData.stdCode + stateData.forwardNumberValue.replace(/[- )(]/g, '');
    const bodyStr = JSON.stringify({
      to: transformedNumb,
      from: props.selectedData?.phoneNumber?.phoneNumber,
      otp: otp,
    });
    axios
      .post(`/cloud-telephony/phone-numbers/forward-flow`, bodyStr)
      .then((res) => {
        if (res.status == 201) {
          setStateData((prev) => {
            return {
              ...prev,
              otpInputDisabled: false,
              otpView: 'OTP_SUCCESS',
            };
          });
          updateCreateFlow();
          props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
            loading: false,
          });
          showToast(
            toast,
            ` ${props.selectedData?.phoneNumber?.phoneNumber}  Successfully Forwarded to ${transformedNumb}`,
            ToastType.success
          );
        }
      })
      .catch((err) => {
        setStateData((prev) => {
          return {
            ...prev,
            otpInputDisabled: false,
            resendOtp: true,
          };
        });
        props.formActionPerformed('', {loading: false});
        showToast(
          toast,
          ` Error: In Forwarding to ${transformedNumb}`,
          ToastType.error
        );
      });
  };
  const createNumberWithFlow = async () => {
    createOrDeleteSmsInbox();
    let createVNObject = {
      accountUuid: accountUUID,
      virtualPhoneNumber: props.number,
    } as any;

    if (stateData.assigneeTypeCode == 'USER') {
      createVNObject = {...createVNObject, assigneeId: stateData.selectedUser};
      createVNObject = {...createVNObject, assigneeTypeId: userAssigneeType};
      createVNObject = {...createVNObject, assignee: stateData.name};
    } else if (stateData.assigneeTypeCode == 'CLINIC') {
      createVNObject = {...createVNObject, assigneeId: stateData.selectedAccountLocation.uuid};
      createVNObject = {...createVNObject, assigneeTypeId: clinicAssigneeType};
      createVNObject = {...createVNObject, assignee: stateData.name};
    } else {
      createVNObject = {...createVNObject, assigneeId: null};
      createVNObject = {...createVNObject, assigneeTypeId: otherAssigneeType};
      createVNObject = {...createVNObject, assignee: stateData.name};
    }
    const responseData: any = await CreateVirtualNumber({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        createVNObject: createVNObject,
      },
    });
    const data = responseData?.data?.createVirtualPhoneNumberAssignee || {};
    showToast(
      toast,
      ` ${props.number} Number Successfully Purchased and assigned`,
      ToastType.success
    );
    handleConfigurePhoneNumberAction({
      accountUuid: accountUUID,
      userUuid: currentUserUUID,
      level,
    })
      .then((r) => {
        props.formActionPerformed(COMMON_ACTION_CODES.COMPLETED, {
          loading: false,
          data: data,
        });
      })
      .catch((e) => {

        props.formActionPerformed(COMMON_ACTION_CODES.COMPLETED, {
          loading: false,
          data: data,
        });
      });
    return data.id ? data.id : ''
  }
  const handleBuyNumber = async () => {
    props.formActionPerformed('', {loading: true});
    let body = {} as any;
    if (stateData.assigneeTypeCode == 'USER') {
      body = JSON.stringify({
        userUUID: stateData.selectedUser,
        name: stateData.selectedUserName,
        accountId: accountUUID,
        phoneNumber: props.number,
      });
    } else {
      body = JSON.stringify({
        userUUID: stateData.name.replace(/\s+/g, ''),
        name: stateData.name,
        accountId: accountUUID,
        phoneNumber: props.number,
      });
    }
    if (!stateData.name) {
      setStateData((prev) => {
        return {
          ...prev,
          error: 'Please select Users/Name',
        };
      });
      return;
    }

    const bodyStr = body;
    axios
      .post(`/cloud-telephony/phone-numbers/purchase`, bodyStr)
      .then(async () => {
        if (stateData.assigneeTypeCode === 'USER' || stateData.assigneeTypeCode === 'CLINIC') {
          const createdId = await createNumberWithFlow();
        } else {
          const createdId = await createNumberWithFlow();
          setIvrFlow(createdId || '')
        }


      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          showToast(
            toast,
            'Number is sold out. Please search again',
            ToastType.info
          );
        } else {
          showToast(toast, `Error Purchasing ${props.number}`, ToastType.error);
        }
        props.formActionPerformed('', {loading: false});
      });
  };

  const resetForwardNumber = () => {
    props.formActionPerformed('', {loading: true});
    axios
      .delete(
        `/cloud-telephony/phone-numbers/forward-number/${props.selectedData?.phoneNumber?.phoneNumber}`
      )
      .then((res) => {
        updateCreateFlow(true);
        showToast(
          toast,
          ` Reset Forward Number for ${props.selectedData?.phoneNumber?.phoneNumber}`,
          ToastType.success
        );
        props.formActionPerformed(COMMON_ACTION_CODES.COMPLETED, {
          loading: false,
        });
        setStateData((prev) => {
          return {
            ...prev,
            otpView: 'OTP_SEND',
            forwardNumberValue: '',
            oldForwardNumberValue: '',
          };
        });
      })
      .catch((err) => {

        showToast(
          toast,
          `Error In Resetting Forward Number for ${props.selectedData?.phoneNumber?.phoneNumber}`,
          ToastType.error
        );
        props.formActionPerformed(COMMON_ACTION_CODES.COMPLETED, {
          loading: false,
        });
        return;
      });
  };

  const setIvrFlow = (id?: string) => {
    props.formActionPerformed('', {loading: true});
    createOrDeleteSmsInbox();
    const bodyStr = JSON.stringify({
      flowId: stateData.selectedIvrFlow,
      phoneNumber: props.selectedData?.phoneNumber?.phoneNumber || props.number,
    });
    axios
      .put(`/cloud-telephony/phone-numbers/update`, bodyStr)
      .then((res) => {
        showToast(
          toast,
          `IVR Set to ${props.selectedData?.phoneNumber?.phoneNumber || props.number}`,
          ToastType.success
        );
        updateCreateFlow(false, id);
        props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
          loading: false,
        });
      })
      .catch((err) => {
        showToast(
          toast,
          ` Error In Setting IVR  to ${props.selectedData?.phoneNumber?.phoneNumber || props.number}`,
          ToastType.error
        );
        props.formActionPerformed('', {
          loading: false,
        });
      });
  };

  const setOutOfOfficeIvr = async (id: string, isReset?: boolean) => {
    props.formActionPerformed('', {loading: true});
    const updateObject: any = {
      unavailabilityFlowId: isReset ? null : stateData.selectedUnavailabilityFlowId,
    };

    const responseData: any = await UPDATE_FLOW_FLOW({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        id: id ? id : props.selectedData.id,
        updateObject: updateObject,
      },
    }).catch(e => {
      showToast(
        toast,
        `Error in setting Out of office Phone tree`,
        ToastType.error
      );
      props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
        loading: false,
      });
    });
    if (!responseData.data) {
      showToast(
        toast,
        `Error in setting Out of office Phone tree`,
        ToastType.error
      );
      props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
        loading: false,
      });
    } else {
      showToast(
        toast,
        `Out of office Ivr is set`,
        ToastType.success
      );
      setStateData((prev) => {
        return {
          ...prev,
          selectedUnavailabilityFlowId: updateObject.unavailabilityFlowId,
        };
      });
      props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
        loading: false,
      });
    }
  };
  const handleAddDefaultForLocation = async (locationId: any) => {
    const body = [{
      accountUuid: accountUUID,
      locationId: locationId,
      virtualNumberId: props.selectedData?.id,
      communicationTypeCode: PERSON_ACTION_CODES.CALL
    },
    {
      accountUuid: accountUUID,
      locationId: locationId,
      virtualNumberId: props.selectedData?.id,
      communicationTypeCode: PERSON_ACTION_CODES.SMS
    }
    ];
    const addOrUpdateDefaultForLocationObj: any =
      await addOrUpdateDefaultNumberForLocation(body).catch((err) => {
        showToast(toast, apiError, ToastType.error, 3000);
        return;
      });
    if (addOrUpdateDefaultForLocationObj?.data?.length) {
      showToast(toast, setDefaultMessageForLocation, ToastType.success, 3000);
    }
  };
  const handleDeleteDefaultNumberByLocation = async (
    prevDefaultNumbIds: string[],
    newLocationId: string
  ) => {
    const removeDefaultNumberResObj: any = await deleteDefaultNumberForLocation(
      {
        ids: prevDefaultNumbIds
      }
    ).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
      return;
    });
    if (removeDefaultNumberResObj?.data?.length) {
      showToast(
        toast,
        removeDefaultMessageForLocation,
        ToastType.success,
        3000
      );
      handleAddDefaultForLocation(newLocationId);
    } else {
      showToast(toast, apiError, ToastType.error, 3000);
    }
  };

  const changeDefaultLocationNumberIfExist = async (prevLocationId: string) => {
    const defaultNumberForLocation: any = await getDefaultPhoneNumberByLocation({
      accountUuid: accountUUID,
      locationId: prevLocationId,
    }).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
    });
    return defaultNumberForLocation
  };
  const changeNumberAssignee = async (
    id: string,
    name: string,
    locationObj?: IAccountLocation,
    isConfirmToChangeDefaultLocationNumber?: boolean
  ) => {
    const defaultNumberLocationIds: string[] = [];
    if (
      stateData.assigneeTypeCode ===  LOCATION_TYPE_CODES.AT_CLINIC &&
      !isConfirmToChangeDefaultLocationNumber
    ) {
    const defaultNumberForLocation = await changeDefaultLocationNumberIfExist(
        stateData?.previousAccountLocation?.uuid || locationObj?.uuid
      );
      if (defaultNumberForLocation?.data?.length) {
        defaultNumberForLocation?.data?.forEach((numbersObj: any) => {
          if(numbersObj?.id) {
            defaultNumberLocationIds.push(numbersObj?.id)
          }
        })
        setStateData((prev) => {
          return {
            ...prev,
            showDefaultLocationNumberConfirmModal: true,
            isDefaultForLocationExist: true,
            defaultNumberLocationIds: defaultNumberLocationIds
          };
        });
        return;
      }
    }
    props.formActionPerformed('', {loading: true});
    const bodyStr = JSON.stringify({
      userUUID: id,
      name: name,
      phoneNumber: props.selectedData?.phoneNumber?.phoneNumber || props.number,
      id: stateData.virtualPhoneNumberAssigneeId,
      assigneeTypeId: stateData.assigneeType,
    });
    axios
      .put(`/cloud-telephony/phone-numbers/update`, bodyStr)
      .then((res) => {
        showToast(
          toast,
          `Phone number is assigned to user ${name}`,
          ToastType.success
        );
        if (
          stateData.assigneeTypeCode === LOCATION_TYPE_CODES.AT_CLINIC &&
          id &&
          stateData?.isDefaultForLocationExist
        ) {
          handleDeleteDefaultNumberByLocation(
            stateData.defaultNumberLocationIds,
            id
          );
        }
        setStateData((prev) => {
          return {
            ...prev,
            previousAccountLocation: locationObj,
            isDefaultForLocationExist: false,
          };
        });
        props.formActionPerformed(COMMON_ACTION_CODES.ASSIGN_USER, {
          loading: false,
        });
      })
      .catch((err) => {
        showToast(
          toast,
          ` Error in assigning phone number to ${name}`,
          ToastType.error
        );
        props.formActionPerformed('', {
          loading: false,
        });
      });
  };

  const setHolidayHoursIvr = async (id: string, isReset?: boolean) => {
    props.formActionPerformed('', {loading: true});
    const updateObject: any = {
      holidayPhoneTreeId: isReset ? null : stateData.selectedHolidayHoursFlowId,
    };

    const responseData: any = await UPDATE_FLOW_FLOW({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        id: id ? id : props.selectedData.id,
        updateObject: updateObject,
      },
    }).catch(e => {
      showToast(
        toast,
        `Error in setting Holiday Hours Phone tree`,
        ToastType.error
      );
      props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
        loading: false,
      });
    });
    if (!responseData.data) {
      showToast(
        toast,
        `Error in setting Holiday Hours Phone tree`,
        ToastType.error
      );
      props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
        loading: false,
      });
    } else {
      showToast(
        toast,
        isReset ? `Holiday Hours Phone tree has been successfully reset.`: `Holiday Hours Phone tree has been successfully configured.`,
        ToastType.success
      );
      setStateData((prev) => {
        return {
          ...prev,
          selectedHolidayHoursFlowId: updateObject.selectedHolidayHoursFlowId,
        };
      });
      props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
        loading: false,
      });
    }
  };

  const resetIvrFromNumber = (id: string, name: string, locationId: string) => {
    // props.formActionPerformed(COMMON_ACTION_CODES.COMPLETED, {loading: true});
    createOrDeleteSmsInbox();
    const bodyStr = JSON.stringify({
      isResetIvr: true,
      userUUID: id || locationId,
      name: name,
      phoneNumber: props.selectedData?.phoneNumber?.phoneNumber || props.number,
      id: stateData.virtualPhoneNumberAssigneeId,
      assigneeTypeId: stateData.assigneeType,
    });
    axios
      .put(`/cloud-telephony/phone-numbers/update`, bodyStr)
      .then((res) => {
        showToast(
          toast,
          `Phone tree is reset from number`,
          ToastType.success
        );
        setStateData((prev: any) => {
          return {
            ...prev,
            selectedFlowType: null,
            selectedFlowTypeCode: null,
            selectedIvrFlow: null
          };
        });
        props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
          loading: false,
        });
      })
      .catch((err) => {
        showToast(
          toast,
          ` Error in resetting phone tree`,
          ToastType.error
        );
        props.formActionPerformed('', {
          loading: false,
        });
      });
  };

  const updateCreateFlow = async (isReset?: boolean, id?: string) => {
    props.formActionPerformed('', {loading: true});
    const flowTypeValue = stateData.selectedIvrFlow;
    const flowId = flowTypeValue;
    // if (stateData.selectedFlowTypeCode == 'IVR') {
      // flowTypeValue = stateData.selectedIvrFlow;
      // flowId = flowTypeValue;
    // }

    // else if (stateData.selectedFlowTypeCode == 'NUMBER_FORWARD') {
    //   flowTypeValue = stateData.stdCode + stateData.forwardNumberValue;
    // }
    const selectedCode: any = flowTypeMlov.find((item) => {
      return item.code === 'IVR';
    });
    let updateObject: any = {
      flowTypeId: selectedCode.id,
      flowType: flowTypeValue,
      flowId: flowId,
      // assigneeId: null,
      // assigneeTypeId: otherAssigneeType,
      assignee: stateData.name,
    };
    if (isReset) {
      updateObject = {
        flowTypeId: null,
        flowType: null,
      };
    }

    const responseData: any = await UPDATE_FLOW_FLOW({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        id: id ? id : props.selectedData.id,
        updateObject: updateObject,
      },
    });
    props.formActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, {
      loading: false,
    });
  };

  const updateSmsInbox = async () => {
    props.formActionPerformed('', {
      loading: true,
    });
    const phoneInboxData = await getInboxesDataByPhoneNumber({
      variables: {
        whereCondition: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          channelTwilioSms: {
            phoneNumber: {
              _eq: phoneNumber
            }
          },
        }
      }
    });
    const singleInbox = phoneInboxData?.data?.inboxes[0];
    if (singleInbox?.id) {
      let inboxName = '';
      if (singleInbox?.name) {
        inboxName = singleInbox?.name;
      }
      const updatedInboxData = await updateInboxName({
        variables: {
          id: singleInbox?.id,
          name: inboxName,
          enableAutoAssignment: stateData.enableAutoAssignment,
          inboxType: null,
        },
      });
      updateInboxUser(updatedInboxData?.data?.updateInbox?.id, false);
    } else {
      createSmsInbox()
    }
    props.formActionPerformed('', {
      loading: false,
    });
  };
  const createSmsInbox = async () => {
    const baseService = BaseService.getSharedInstance().axios;
    const createSmsInboxUrl = `/cloud-telephony/inbox/twillio`;
    const inboxName = stateData.name

    const bodyData = {
      twilio_channel: {
        name: inboxName,
        medium: 'sms',
        phone_number: phoneNumber,
      },
      smsCallBackUrl: CRM_URLs.CHATWOOT + '/twilio/callback',
      accountId: accountId,
    };
    baseService
      .post(createSmsInboxUrl, JSON.stringify(bodyData))
      .then((resp) => {
        updateInboxUser(resp.data.id, true);
      })
      .catch((error: any) => {

        const errMsg =
          error?.response?.data?.error ||
          intl.formatMessage({id: 'apiErrorMsg'});
        showToast(smsInboxToast, errMsg, ToastType.error);
        props.formActionPerformed('', {loading: false});
      });


  };
  const getInboxUserIds = (inboxMembers: any[]) => {
    const inboxMemberUserIds: any = [];
    inboxMembers.forEach((singleMember) => {
      if (singleMember.userId) {
        inboxMemberUserIds.push(singleMember.userId)
      }
    });
    return inboxMemberUserIds;
  }

  const updateInboxUser = async (inboxId: number, isCreate: boolean) => {
    const smsCreateOrUpdateMsg = isCreate
      ? 'smsInboxCreateSuccess'
      : 'smsInboxUpdatedSuccess';
    const selectedUserIds = stateData?.selectedUsersIds || [];
    const finaleInboxId = inboxId
    const allInboxMembers = await getAllNonDeletedInboxesMembers({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        inboxId: finaleInboxId,
      }
    });
    if (allInboxMembers?.data?.inboxMembers.length) {
      const deleteUserList: any = [];
      const userObjectList: IInboxMembers[] = [];
      allInboxMembers?.data?.inboxMembers.forEach((singleMember) => {
        if (!selectedUserIds.includes(singleMember.userId)) {
          deleteUserList.push(singleMember.userId);
          userObjectList.push({
            userId: singleMember.userId,
            inboxId: inboxId,
            isDeleted: true,
          });
        }
      });

      const addNewUserList: any = [];
      const inboxMemberUserIds = getInboxUserIds(allInboxMembers?.data?.inboxMembers);
      selectedUserIds.forEach((singleUserId) => {
        if (!inboxMemberUserIds.includes(singleUserId)) {
          addNewUserList.push({
            userId: singleUserId,
            inboxId: inboxId,
            isDeleted: false
          });
        }
      });

      userObjectList.push(...addNewUserList);
      if (enableChatHistoryRead) {
        const deleteMemberData = await deleteInboxesMembers({
          variables: {
            inboxId,
            userIds: deleteUserList
          }
        });
        const addedMemberData = createInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
      } else {
        const addedMemberData = await addOrUpdateInboxMembersAPI({memberList: userObjectList, inboxId: inboxId});
        // const addedMemberData = await createInboxMembers({
        //   variables: {
        //     objects: addNewUserList,
        //   }
        // });
      }
      showToast(
        smsInboxToast,
        intl.formatMessage({id: smsCreateOrUpdateMsg}),
        ToastType.success
      );
    } else {
      const addNewUserList: any = [];
      const inboxMemberUserIds = getInboxUserIds([]);
      selectedUserIds.forEach((singleUserId) => {
        if (!inboxMemberUserIds.includes(singleUserId)) {
          addNewUserList.push({
            userId: singleUserId,
            inboxId: inboxId,
            isDeleted: false
          });
        }
      });
      let addedMemberData = {} as any
      if (enableChatHistoryRead) {
         addedMemberData = createInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
      } else {
        addedMemberData = await addOrUpdateInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
        //  addedMemberData = await createInboxMembers({
        //   variables: {
        //     objects: addNewUserList,
        //   }
        // });
      }
      if (addedMemberData) {
        showToast(
          smsInboxToast,
          intl.formatMessage({id: smsCreateOrUpdateMsg}),
          ToastType.success
        );
      }
    }
    props.formActionPerformed('', {loading: false});
    props.formActionPerformed(SMS_INBOX_ACTION_CODES.ON_INBOX_SAVE);
  };
  const onUserSelect = (userIds: number[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedUsersIds: userIds,
        selectedPhoneNumber: props.number || ''
      };
    });
  };

  const onDeleteInboxAction = async () => {
    const phoneInboxData = await getInboxesDataByPhoneNumber({
      variables: {
        whereCondition: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          channelTwilioSms: {
            phoneNumber: {
              _eq: phoneNumber
            }
          },
        }
      }
    });
    const singleInboxData = phoneInboxData?.data?.inboxes[0];
    const deletedInboxData = await deleteInbox({
      variables: {
        id: singleInboxData?.id,
      },
    });
    props.formActionPerformed('', {loading: false});
  };

  const createOrDeleteSmsInbox = () => {
    props.formActionPerformed('', {loading: true});
    if (stateData.isEnableSmsInbox) {
      updateSmsInbox();
    } else {
      onDeleteInboxAction();
    }
  }

  const getForwardedNumberExist = () => {
    return props.selectedData?.flowType?.flowTypeCode == 'NUMBER_FORWARD' && (props.selectedData?.flowType?.value || stateData.oldForwardNumberValue) ? true : false
  }
  const getIvrFlowList = async () => {
    const res = await axios.get(
      `/cloud-telephony/flow?accountUUID=${accountUUID}`
    );
    if (res?.data?.length) {
      const response: IFLOWS[] = res?.data?.length ? res?.data : [];
      const formattedIvrData = getIvrListByType(response, ivrList);
      setStateData((prev) => {
        return {
          ...prev,
          ivrFlowList: formattedIvrData.inboundIvrList,
          outOfOfficeIvrList: formattedIvrData.outOfOfficeIvrList,
          holidayHoursIvrList: formattedIvrData.holidayHoursIvrList,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  useEffect(() => {
    if (props.selectedData) {
      getInboxData();
      const user: any = props.userList?.find((user) => {
        return user?.uuid == props.selectedData?.userUuid;
      });
      setStateData((prev) => {
        return {
          ...prev,
          name: props.selectedData.phoneNumber.name,
          selectedUser: props.selectedData.assignedTypeCode === 'USER' ? props.selectedData?.userUuid : '',
          error: '',
          assigneeType: props.selectedData?.assignTypeUuid || '',
          assigneeTypeCode: props.selectedData.assignedTypeCode || '',
          selectedFlowType:
            props.selectedData?.flowType?.flowTypeUuid || '',
          selectedFlowTypeCode:
            props.selectedData?.flowType?.flowTypeCode || '',
          forwardNumberValue:
            props.selectedData?.flowType?.flowTypeCode == 'NUMBER_FORWARD'
              ? getStdCodeAndNumber(props.selectedData?.flowType?.value)
                .number || props.selectedData?.flowType?.value
              : '',
          oldForwardNumberValue:
            props.selectedData?.flowType?.flowTypeCode == 'NUMBER_FORWARD'
              ? getStdCodeAndNumber(props.selectedData?.flowType?.value)
                .number || props.selectedData?.flowType?.value
              : '',
          selectedIvrFlow:
            props.selectedData?.flowType?.flowTypeCode == 'IVR'
              ? props.selectedData?.flowType?.value
              : '',
          selectedHolidayHoursFlowId: props.selectedData?.holidayPhoneTreeId || '',
          clinicName:
            props.selectedData.assignedTypeCode == 'CLINIC' &&
              props.selectedData.phoneNumber.name
              ? props.selectedData.phoneNumber.name
              : '',
          othersName:
            props.selectedData.assignedTypeCode == 'OTHER' &&
              props.selectedData.phoneNumber.name
              ? props.selectedData.phoneNumber.name
              : '',
          stdCode:
            getStdCodeAndNumber(props.selectedData?.flowType?.value).stdCode ||
            '+1',
          isEnableSmsInbox: selectedInbox ? true : false,
          selectedUsersIds: selectedIds?.length > 0 ? selectedIds : (user?.id ? [user?.id] : []) || [],
          selectedInbox: selectedInbox,
          selectedAccountLocation: props.selectedData.assignedTypeCode === 'CLINIC' ? {
            uuid: props.selectedData?.rawData?.assigneeId,
            practiceLocation: {
              name: props.selectedData?.rawData?.assignee
            }
          } : {},
          selectedUnavailabilityFlowId: props.selectedData?.rawData?.unavailabilityFlowId,
        };
      });
    }
  }, [props.selectedData]);
  useEffect(() => {
    if (props.updateCallBack) {
      props.updateCallBack(stateData);
    }
  }, [stateData]);
  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter == 0) {
      setStateData((prev) => {
        return {
          ...prev,
          resendOtp: true,
        };
      });
    }
    return () => clearInterval(timer);
  }, [counter]);

  const getAccountPracticeLocationsData = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
        accountLocationLoading: true,
      };
    });
    const accountPracticeLocationResp = await getAccountPracticeLocations().catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          accountLocationLoading: false,
        };
      });
    });
    if (accountPracticeLocationResp?.data?.accountLocations?.length) {
      let currentSelectedAccountLocationData: any = {}
      if (props.selectedData.assignedTypeCode === LOCATION_TYPE_CODES.AT_CLINIC) {
         currentSelectedAccountLocationData = accountPracticeLocationResp?.data?.accountLocations?.find(
          (accountLocation) => {
            return (
              accountLocation.uuid ==
              props.selectedData?.rawData?.assigneeId
            );
          }
        );
      }
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          accountPracticeLocations: accountPracticeLocationResp?.data?.accountLocations || [] as IAccountLocation[],
          previousAccountLocation: currentSelectedAccountLocationData,
          accountLocationLoading: false,
        };
      });
    }
  }

  const getInboxData=async ()=> {
    const phoneInboxData = await getInboxesDataByPhoneNumber({
      variables: {
        whereCondition: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          channelTwilioSms: {
            phoneNumber: {
              _eq: phoneNumber
            }
          },
        }
      }
    });
    const singleInbox = phoneInboxData?.data?.inboxes[0];
    if (singleInbox?.id) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          enableAutoAssignment: singleInbox?.enableAutoAssignment
        };
      });
    }
  }

  useEffect(() => {
    getIvrFlowList();
    getAccountPracticeLocationsData()
  }, [])
  return (
    <Content className="purchase_number">
      <View
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          padding: props.isEditMode ? 24 : 24,
          borderRadius: props.isEditMode ? 16 : 16,
          borderColor: Colors.Custom.Gray200,
          borderWidth: 1,
        }}
      >
        <HStack justifyContent={'space-between'}>
          <HStack flex={1} space={1}>
            <Badge
              rounded="full"
              backgroundColor={Colors.primary[300]}
              alignSelf="center"
              variant="solid"
            >
              1
            </Badge>
            <DisplayText
              textLocalId={
                props?.isFirstTimeSetup ? 'assignNumberTo' : 'reAssignNumberTo'
              }
              size={'mdMedium'}
              extraStyles={{
                color: Colors.primary[300],
              }}
            />
          </HStack>
          <VStack>
            <View>
              <Image
                resizeMode="contain"
                size={'20px'}
                source={require('../../../../assets/images/PNGImages/number_reassign.png')}
                alt="image"
              />
            </View>
          </VStack>
        </HStack>
        <HStack>
          <FormControl style={styles.formElement}>
            <FormControl.Label style={{margin: 0}}></FormControl.Label>
            <Radio.Group
              accessibilityLabel="flow type"
              name="assigneeType"
              value={stateData.assigneeType}
              onChange={(value: string) => {
                const selectedCode: any = assigneeTypeMlov.find((item) => {
                  return item.id === value;
                });
                if (selectedCode.code === 'USER') {
                  setStateData((prev: any) => {
                    return {
                      ...prev,
                      assigneeTypeCode: 'USER',
                      assigneeType: selectedCode?.id,
                    };
                  });
                  return;
                }
                if (selectedCode.code === 'CLINIC') {
                  setStateData((prev: any) => {
                    return {
                      ...prev,
                      assigneeTypeCode: 'CLINIC',
                      assigneeType: selectedCode?.id,
                    };
                  });
                  return;
                }
              }}
              style={{flexDirection: 'row'}}
            >
              {assigneeTypeMlov?.map((mlovData: ICTMlov) => {
                return (
                  <Radio
                    value={mlovData.id}
                    marginY={1}
                    style={{
                      width: 16,
                      height: 16,
                    }}
                  >
                    <Text
                      color={Colors.Custom.Gray700}
                      style={{marginLeft: 12, marginRight: 16}}
                      fontSize={'sm'}
                    >
                      {mlovData.value}
                    </Text>
                  </Radio>
                );
              })}
            </Radio.Group>
          </FormControl>
        </HStack>

        <HStack>
          <View flex={1}>
            {stateData.assigneeTypeCode == 'USER' && (
              <FormControl style={styles.formElement}>
                <FormControl.Label style={{margin: 0}}></FormControl.Label>
                <AntSelect
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={
                    stateData.selectedUser.length > 0
                      ? stateData.selectedUser
                      : undefined
                  }
                  onChange={(itemValue) => {
                    const user: any = props.userList?.find((user) => {
                      return user?.uuid == itemValue;
                    });
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedUser: user?.uuid || null,
                        selectedUserName: user?.name || null,
                        error: '',
                        name: user?.name || null,
                      };
                    });
                    changeNumberAssignee(user?.uuid, user?.name || '');
                  }}
                  placeholder={'Select User'}
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 32,
                    borderWidth: 1,
                    borderColor: Colors.Custom.Gray300,
                  }}
                >
                  {props.userList?.map((user: IUser) => {
                    return (
                      <AntSelect.Option key={user?.uuid} value={user?.uuid}>
                        {user?.name || ''}
                      </AntSelect.Option>
                    );
                  })}
                </AntSelect>
                {!props.selectedData?.phoneNumber?.phoneNumber ? (
                  <HStack style={{marginTop: 16}}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          handleBuyNumber();
                        },
                        style: {marginLeft: 12},
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'buyNow',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </HStack>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
            {stateData.assigneeTypeCode == 'CLINIC' && (
              <FormControl style={styles.formElement}>
                <FormControl.Label style={{margin: 0}}></FormControl.Label>
                {stateData.accountLocationLoading ? (
                  <Skeleton.Text lines={2}></Skeleton.Text>
                ) : (
                  <AntSelect
                    value={
                      stateData.selectedAccountLocation.uuid
                        ? stateData.selectedAccountLocation.uuid
                        : undefined
                    }
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(currentSelectedAccountLocationUuid) => {
                      const currentSelectedAccountLocationData: any =
                        stateData.accountPracticeLocations?.find(
                          (accountLocation) => {
                            return (
                              accountLocation.uuid ==
                              currentSelectedAccountLocationUuid
                            );
                          }
                        );
                      setStateData((prev) => {
                        return {
                          ...prev,
                          selectedAccountLocation:
                            currentSelectedAccountLocationData,
                          name:
                            currentSelectedAccountLocationData?.practiceLocation
                              ?.name || null,
                        };
                      });
                      changeNumberAssignee(
                        currentSelectedAccountLocationData?.uuid,
                        currentSelectedAccountLocationData?.practiceLocation
                          ?.name,
                        currentSelectedAccountLocationData,
                        false
                      );
                    }}
                    placeholder={'Select Location'}
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: 32,
                      borderWidth: 1,
                      borderColor: Colors.Custom.Gray300,
                    }}
                  >
                    {stateData.accountPracticeLocations?.map(
                      (singleAccountLocation: IAccountLocation) => {
                        return (
                          <AntSelect.Option
                            key={singleAccountLocation?.uuid}
                            value={singleAccountLocation?.uuid}
                          >
                            {singleAccountLocation?.practiceLocation?.name ||
                              ''}
                          </AntSelect.Option>
                        );
                      }
                    )}
                  </AntSelect>
                )}
                {!props.selectedData?.phoneNumber?.phoneNumber ? (
                  <HStack style={{marginTop: 16}}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          handleBuyNumber();
                        },
                        style: {marginLeft: 12},
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'buyNow',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </HStack>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          </View>
        </HStack>
      </View>
      <Divider
        backgroundColor={Colors.primary[300]}
        ml={6}
        orientation="vertical"
        width={'1.8'}
        height={4}
      />
      {/* This is Start */}
      <View
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          padding: props.isEditMode ? 24 : 24,
          borderRadius: props.isEditMode ? 16 : 16,
          borderColor: Colors.Custom.Gray200,
          borderWidth: 1,
        }}
      >
        <VStack justifyContent={'space-between'}>
          <HStack flex={1} space={1}>
            <Badge
              rounded="full"
              backgroundColor={Colors.primary[300]}
              alignSelf="center"
              variant="solid"
            >
              2
            </Badge>
            <DisplayText
              textLocalId="officeHoursPhoneTree"
              size={'mdMedium'}
              extraStyles={{
                color: Colors.primary[300],
              }}
            />
          </HStack>
          <View style={{marginVertical: 8}}>
            <Text size={'smMedium'} color={'gray.400'}>
              Selected assignee's business hours will be set automatically
            </Text>
          </View>
        </VStack>
        <HStack></HStack>

        <HStack>
          <View flex={1}>
            {
              <>
                {stateData?.ivrFlowList?.length > 0 ? (
                  <FormControl style={styles.formElement}>
                    <AntSelect
                      allowClear={true}
                      placeholder={'Select Phone Tree'}
                      value={
                        stateData?.selectedIvrFlow?.length > 0
                          ? stateData.selectedIvrFlow
                          : undefined
                      }
                      showSearch={true}
                      onChange={(itemValue) => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            selectedIvrFlow: itemValue,
                            error: '',
                          };
                        });
                      }}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {stateData?.ivrFlowList?.map((flow: any) => {
                        return (
                          <AntSelect.Option key={flow.uuid} value={flow.uuid}>
                            {flow.name || ''}
                          </AntSelect.Option>
                        );
                      })}
                    </AntSelect>
                    {stateData.error && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                      >
                        {stateData.error}
                      </FormControl.ErrorMessage>
                    )}
                    {stateData.selectedIvrFlow &&
                    props.selectedData?.phoneNumber?.phoneNumber ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 12,
                        }}
                      >
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.PRIMARY,
                            onPress: () => {
                              setIvrFlow(
                                stateData.virtualPhoneNumberAssigneeId
                              );
                            },
                          }}
                          customProps={{
                            btnText: intl.formatMessage({
                              id: 'setIvrFlow',
                            }),
                            withRightBorder: false,
                          }}
                        ></FoldButton>
                        {props.selectedData?.flowType?.flowTypeUuid && (
                          <HStack>
                            <FoldButton
                              nativeProps={{
                                variant: BUTTON_TYPE.PRIMARY,
                                onPress: () => {
                                  resetIvrFromNumber(
                                    stateData.selectedUser,
                                    stateData.selectedUserName,
                                    stateData.selectedAccountLocation?.uuid
                                  );
                                },
                                style: {marginLeft: 12},
                              }}
                              customProps={{
                                btnText: intl.formatMessage({
                                  id: 'reset',
                                }),
                                withRightBorder: false,
                              }}
                            ></FoldButton>
                          </HStack>
                        )}
                      </View>
                    ) : (
                      <HStack style={{marginTop: 16}}>
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.PRIMARY,
                            onPress: () => {
                              handleBuyNumber();
                            },
                            style: {marginLeft: 12},
                          }}
                          customProps={{
                            btnText: intl.formatMessage({
                              id: 'setIvrFlow',
                            }),
                            withRightBorder: false,
                          }}
                        ></FoldButton>
                      </HStack>
                    )}
                  </FormControl>
                ) : (
                  <View>
                    <View
                      style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <EmptyIvrState />
                      <Text
                        style={{
                          color: Colors.Custom.Gray500,
                          marginTop: 30,
                          textAlign: 'center',
                        }}
                        size={'smSemibold'}
                      >
                        No Phone Tree Available
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 24,
                      }}
                    >
                      <FoldButton
                        nativeProps={{
                          variant: BUTTON_TYPE.PRIMARY,
                          onPress: () => {
                            navigate(workflowUrl, {
                              replace: false,
                            });
                          },
                          style: {marginLeft: 12},
                        }}
                        customProps={{
                          btnText: intl.formatMessage({
                            id: 'createNewIVR',
                          }),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                    </View>
                  </View>
                )}
              </>
            }
            <ResetForwardNumberAlert
              isOpen={alertState.resetConfirmFN}
              onActionPerformed={(actionCode: string) => {
                if (actionCode == TELEPHONY_CONST.CLOSE) {
                  setAlertState((prev) => {
                    return {
                      ...prev,
                      resetConfirmFN: false,
                    };
                  });
                } else if (actionCode == TELEPHONY_CONST.SUBMIT) {
                  setAlertState((prev) => {
                    return {
                      ...prev,
                      resetConfirmFN: false,
                    };
                  });
                  resetForwardNumber();
                }
              }}
            />
            <ForwardToIvrFlowAlert
              number={stateData.forwardNumberValue}
              isOpen={alertState.forWardToIvrConfirm}
              onActionPerformed={(actionCode: string) => {
                if (actionCode == TELEPHONY_CONST.CLOSE) {
                  setAlertState((prev) => {
                    return {
                      ...prev,
                      forWardToIvrConfirm: false,
                    };
                  });
                } else if (actionCode == TELEPHONY_CONST.SUBMIT) {
                  setAlertState((prev) => {
                    return {
                      ...prev,
                      forWardToIvrConfirm: false,
                    };
                  });
                  resetForwardNumber();
                }
              }}
            />
          </View>
        </HStack>
      </View>
      <Divider
        backgroundColor={Colors.primary[300]}
        ml={6}
        orientation="vertical"
        width={'1.8'}
        height={4}
      />
      <View
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          padding: props.isEditMode ? 24 : 24,
          borderRadius: props.isEditMode ? 16 : 16,
          borderColor: Colors.Custom.Gray200,
          borderWidth: 1,
        }}
      >
        <HStack justifyContent={'space-between'}>
          <HStack flex={1} space={1}>
            <Badge
              rounded="full"
              backgroundColor={Colors.primary[300]}
              alignSelf="center"
              variant="solid"
            >
              3
            </Badge>
            <DisplayText
              textLocalId="outOfOfficePhoneTree"
              size={'mdMedium'}
              extraStyles={{
                color: Colors.primary[300],
              }}
            />
          </HStack>
        </HStack>
        <HStack>
          <View flex={1}>
            {stateData?.outOfOfficeIvrList?.length > 0 ? (
              <FormControl style={styles.formElement}>
                <View style={{marginBottom: 16}}>
                  <Text size={'smMedium'} color={'gray.400'}>
                    OOO phone tree respects to selected user/location business hours
                  </Text>
                </View>
                <AntSelect
                  allowClear={true}
                  placeholder={'Select Phone Tree'}
                  value={
                    stateData?.selectedUnavailabilityFlowId?.length > 0
                      ? stateData.selectedUnavailabilityFlowId
                      : undefined
                  }
                  showSearch={true}
                  onChange={(itemValue) => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedUnavailabilityFlowId: itemValue,
                        error: '',
                      };
                    });
                  }}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {stateData?.outOfOfficeIvrList?.map((flow: any) => {
                    return (
                      <AntSelect.Option key={flow.uuid} value={flow.uuid}>
                        {flow.name || ''}
                      </AntSelect.Option>
                    );
                  })}
                </AntSelect>
                {stateData.error && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {stateData.error}
                  </FormControl.ErrorMessage>
                )}
                {stateData.selectedUnavailabilityFlowId &&
                props.selectedData?.phoneNumber?.phoneNumber ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 20,
                    }}
                  >
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          setOutOfOfficeIvr(
                            stateData.virtualPhoneNumberAssigneeId
                          );
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'setIvrFlow',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                    {props.selectedData?.rawData?.unavailabilityFlowId && (
                      <HStack>
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.PRIMARY,
                            onPress: () => {
                              setOutOfOfficeIvr(
                                stateData.virtualPhoneNumberAssigneeId,
                                true
                              );
                            },
                            style: {marginLeft: 12},
                          }}
                          customProps={{
                            btnText: intl.formatMessage({
                              id: 'reset',
                            }),
                            withRightBorder: false,
                          }}
                        ></FoldButton>
                      </HStack>
                    )}
                  </View>
                ) : (
                  <HStack style={{marginTop: 16}}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          handleBuyNumber();
                        },
                        style: {marginLeft: 12},
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'setIvrFlow',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </HStack>
                )}
              </FormControl>
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <EmptyIvrState height={80} width={80} />
                  <Text
                    style={{
                      color: Colors.Custom.Gray500,
                      marginTop: 30,
                      textAlign: 'center',
                    }}
                    size={'smSemibold'}
                  >
                    No Phone Tree Available
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 24,
                  }}
                >
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      onPress: () => {
                        navigate(workflowUrl, {
                          replace: false,
                        });
                      },
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: 'createNewIVR',
                      }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                </View>
              </View>
            )}
          </View>
        </HStack>
      </View>
      <Divider
        backgroundColor={Colors.primary[300]}
        ml={6}
        orientation="vertical"
        width={'1.8'}
        height={4}
      />
      <View
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          padding: props.isEditMode ? 24 : 24,
          borderRadius: props.isEditMode ? 16 : 16,
          borderColor: Colors.Custom.Gray200,
          borderWidth: 1,
        }}
      >
        <VStack justifyContent={'space-between'}>
          <HStack flex={1} space={1}>
            <Badge
              rounded="full"
              backgroundColor={Colors.primary[300]}
              alignSelf="center"
              variant="solid"
            >
              4
            </Badge>
            <DisplayText
              textLocalId="holidayHoursPhoneTree"
              size={'mdMedium'}
              extraStyles={{
                color: Colors.primary[300],
              }}
            />
          </HStack>
          <View style={{marginVertical: 4}}>
            <Text size={'smMedium'} color={'gray.400'}>
              Holiday phone tree respects to the set holiday list (from accounts
              settings)
            </Text>
          </View>
        </VStack>
        <HStack>
          <View flex={1}>
            {stateData?.holidayHoursIvrList?.length > 0 ? (
              <FormControl style={styles.formElement}>
                <AntSelect
                  allowClear={true}
                  placeholder={'Select Phone Tree'}
                  value={
                    stateData?.selectedHolidayHoursFlowId?.length > 0
                      ? stateData.selectedHolidayHoursFlowId
                      : undefined
                  }
                  showSearch={true}
                  onChange={(itemValue) => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedHolidayHoursFlowId: itemValue,
                        error: '',
                      };
                    });
                  }}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {stateData?.holidayHoursIvrList?.map((flow: any) => {
                    return (
                      <AntSelect.Option key={flow.uuid} value={flow.uuid}>
                        {flow.name || ''}
                      </AntSelect.Option>
                    );
                  })}
                </AntSelect>
                {stateData.error && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {stateData.error}
                  </FormControl.ErrorMessage>
                )}
                {stateData.selectedHolidayHoursFlowId &&
                props.selectedData?.phoneNumber?.phoneNumber ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 20,
                    }}
                  >
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          setHolidayHoursIvr(
                            stateData.virtualPhoneNumberAssigneeId
                          );
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'setIvrFlow',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                    {props.selectedData?.rawData?.holidayPhoneTreeId && (
                      <HStack>
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.PRIMARY,
                            onPress: () => {
                              setHolidayHoursIvr(
                                stateData.virtualPhoneNumberAssigneeId,
                                true
                              );
                            },
                            style: {marginLeft: 12},
                          }}
                          customProps={{
                            btnText: intl.formatMessage({
                              id: 'reset',
                            }),
                            withRightBorder: false,
                          }}
                        ></FoldButton>
                      </HStack>
                    )}
                  </View>
                ) : (
                  <HStack style={{marginTop: 16}}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          handleBuyNumber();
                        },
                        style: {marginLeft: 12},
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'setIvrFlow',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </HStack>
                )}
              </FormControl>
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <EmptyIvrState height={80} width={80} />
                  <Text
                    style={{
                      color: Colors.Custom.Gray500,
                      marginTop: 16,
                      textAlign: 'center',
                    }}
                    size={'smSemibold'}
                  >
                    No Phone Tree Available
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 16,
                  }}
                >
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      onPress: () => {
                        navigate(workflowUrl, {
                          replace: false,
                        });
                      },
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: 'createNewIVR',
                      }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                </View>
              </View>
            )}
          </View>
        </HStack>
      </View>

      <Divider
        backgroundColor={Colors.primary[300]}
        ml={6}
        orientation="vertical"
        width={'1.8'}
        height={4}
      />
      <View
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          padding: props.isEditMode ? 24 : 24,
          borderRadius: props.isEditMode ? 16 : 16,
          borderColor: Colors.Custom.Gray200,
          borderWidth: 1,
        }}
      >
        <HStack justifyContent={'space-between'}>
          <HStack flex={1} space={1}>
            <Badge
              rounded="full"
              backgroundColor={Colors.primary[300]}
              alignSelf="center"
              variant="solid"
            >
              5
            </Badge>
            <DisplayText
              textLocalId="chooseSmsManagers"
              size={'mdMedium'}
              extraStyles={{
                color: Colors.primary[300],
              }}
            />
          </HStack>
        </HStack>
        <HStack>
          <FormControl
            style={styles.formElement}
            isInvalid={stateData?.errors?.users}
          >
            <View style={{marginBottom: 16}}>
              <Text size={'smMedium'} color={'gray.400'}>
                Choose team members who should have access to sms inbox of this
                number
              </Text>
            </View>
            <AntSelect
              showSearch={true}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              mode="multiple"
              allowClear
              value={[...stateData.selectedUsersIds]}
              placeholder="Search"
              onChange={onUserSelect}
              maxTagCount={'responsive'}
            >
              {props?.userList?.map((agentRecord: any) => {
                return (
                  <AntSelect.Option
                    key={agentRecord.userId}
                    value={agentRecord.userId || agentRecord.id}
                  >
                    {agentRecord?.user?.name || agentRecord?.name}
                  </AntSelect.Option>
                );
              })}
            </AntSelect>
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {stateData.errors.users}
            </FormControl.ErrorMessage>
          </FormControl>
        </HStack>
        <HStack paddingBottom={3}>
          <Checkbox
            defaultIsChecked={stateData.enableAutoAssignment}
            isChecked={stateData.enableAutoAssignment}
            onChange={(isSelected) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  enableAutoAssignment: isSelected,
                };
              });
            }}
            value={'true'}
          >
            <Text
              size={'smMedium'}
              color={Colors.Custom.Gray700}
              style={{marginLeft: 4, marginRight: 6}}
            >
              {intl.formatMessage({id: 'enableRotationalAssignment'})}
            </Text>
          </Checkbox>
        </HStack>
        <HStack>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                updateSmsInbox();
              },
              isDisabled: !stateData.selectedUsersIds?.length,
              style: {marginLeft: 10},
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'Save',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
        </HStack>
      </View>

      {canCreateSMSInboxWithPhoneNumber(phoneNumber) ? (
        <>
          <Divider
            backgroundColor={Colors.primary[300]}
            ml={6}
            orientation="vertical"
            width={'1.8'}
            height={4}
          />
          <SMSAutoResponseView
            phoneNumber={phoneNumber}
            assigneeTypeCode={stateData.assigneeTypeCode}
            userUuid={stateData.selectedUser}
            locationUuid={stateData?.selectedAccountLocation?.uuid}
          />
        </>
      ) : (
        <></>
      )}

      <ResetForwardNumberAlert
        isOpen={alertState.resetConfirmFN}
        onActionPerformed={(actionCode: string) => {
          if (actionCode == TELEPHONY_CONST.CLOSE) {
            setAlertState((prev) => {
              return {
                ...prev,
                resetConfirmFN: false,
              };
            });
          } else if (actionCode == TELEPHONY_CONST.SUBMIT) {
            setAlertState((prev) => {
              return {
                ...prev,
                resetConfirmFN: false,
              };
            });
            resetForwardNumber();
          }
        }}
      />
      <ForwardToIvrFlowAlert
        number={stateData.forwardNumberValue}
        isOpen={alertState.forWardToIvrConfirm}
        onActionPerformed={(actionCode: string) => {
          if (actionCode == TELEPHONY_CONST.CLOSE) {
            setAlertState((prev) => {
              return {
                ...prev,
                forWardToIvrConfirm: false,
              };
            });
          } else if (actionCode == TELEPHONY_CONST.SUBMIT) {
            setAlertState((prev) => {
              return {
                ...prev,
                forWardToIvrConfirm: false,
              };
            });
            resetForwardNumber();
          }
        }}
      />
      {stateData?.showDefaultLocationNumberConfirmModal ? (
        <ConfirmChangeDefaultLocation
          onConfirm={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showDefaultLocationNumberConfirmModal: false,
              };
            });
            changeNumberAssignee(
              stateData?.selectedAccountLocation?.uuid,
              stateData?.selectedAccountLocation?.practiceLocation?.name,
              stateData?.selectedAccountLocation,
              true
            );
          }}
          titleMessage={changeNumberFromDefaultLocation}
          heading="Confirmation"
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showDefaultLocationNumberConfirmModal: false,
                selectedAccountLocation: prev.previousAccountLocation,
                name:
                  prev.previousAccountLocation?.practiceLocation?.name || null,
              };
            });
          }}
          isOpen={stateData?.showDefaultLocationNumberConfirmModal}
        />
      ) : (
        <></>
      )}
    </Content>
  );
};

export default NumberSetupView;
