import {
  AdvancedType,
  BasicType,
  components,
  createCustomBlock,
  getPreviewClassName,
  IBlockData,
} from '@foldhealth/easy-email-core';
import {getContentEditableClassName} from '@foldhealth/easy-email-editor';
import {merge} from 'lodash';
import React from 'react';
import {CustomBlocksType} from '../constants';

const {Column, Section, Wrapper, Text, Button, Image, Group, Raw} = components;

export type IProductRecommendation = IBlockData<
  {
    'background-color': string;
    'button-color': string;
    'button-text-color': string;
    'description-color': string;
    'title-color': string;
  },
  {
    title: string;
    buttonText: string;
    suggestions: any;
  }
>;
const suggestionPlaceholder = {
  title: 'Educational Article 1',
  description: 'Educational Article Content 1',
  url: 'https://google.com',
};
export const MedlineSuggestions = createCustomBlock<IProductRecommendation>({
  name: 'Educational content',
  type: CustomBlocksType.MEDLINE_SUGGESTIONS,
  validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
  create: (payload) => {
    const defaultData: IProductRecommendation = {
      type: CustomBlocksType.MEDLINE_SUGGESTIONS,
      data: {
        value: {
          title: 'Suggested Reading',
          buttonText: 'View More',
          suggestions: [
            {
              title: 'Suggested Content',
              description: 'View More',
            },
          ],
        },
      },
      attributes: {
        'background-color': '#ffffff',
        'button-text-color': '#ffffff',
        'button-color': '#414141',
        'title-color': '#222222',
        'description-color': '#222222',
      },
      children: [],
    };
    return merge(defaultData, payload);
  },
  render: (params: {
    data?: any;
    idx?: any;
    mode?: any;
    context?: any;
    dataSource?: any;
  }) => {
    const {title, buttonText, suggestions} = params.data.data.value;
    const attributes = params.data.attributes;

    const perWidth = '100%';

    return (
      <Wrapper
        // add class name when testing preview
        css-class={
          params.mode === 'testing'
            ? getPreviewClassName(params.idx, params.data.type)
            : ''
        }
        padding="20px 0px 20px 0px"
        border="none"
        direction="ltr"
        text-align="center"
        background-color={attributes['background-color']}
      >
        <Section padding="20px">
          <Column padding="0px" border="none" vertical-align="top">
            <Text
              font-size="18px"
              padding="10px 25px 10px 25px"
              line-height="1"
              align="center"
              font-weight="bold"
              color={attributes['title-color']}
              css-class={getContentEditableClassName(
                BasicType.TEXT,
                `${params.idx}.data.value.title`
              ).join(' ')}
            >
              {title}
            </Text>
          </Column>
        </Section>

        <Section padding="20px">
          <Group vertical-align="top" direction="ltr">
            {suggestions.map((item: any, index: any) => (
              <Column
                width={perWidth}
                padding="0px"
                border="none"
                vertical-align="top"
              >
                <Text
                  font-size="16px"
                  padding="10px 0px 10px 0px "
                  line-height="1"
                  align="left"
                  color={attributes['title-color']}
                  css-class={getContentEditableClassName(
                    BasicType.TEXT,
                    `${params.idx}.data.value.suggestions.${index}.title`
                  ).join(' ')}
                >
                  {item.title}
                </Text>
                <Text
                  font-size="12px"
                  padding="0px"
                  line-height="1"
                  align="left"
                  color={attributes['description-color']}
                  css-class={getContentEditableClassName(
                    BasicType.TEXT,
                    `${params.idx}.data.value.suggestions.${index}.description`
                  ).join(' ')}
                >
                  {item.description}
                </Text>
                <Button
                  align="center"
                  padding="15px 0px"
                  background-color={attributes['button-color']}
                  color={attributes['button-text-color']}
                  target="_blank"
                  vertical-align="middle"
                  border="none"
                  text-align="center"
                  href={item.url}
                  css-class={getContentEditableClassName(
                    BasicType.TEXT,
                    `${params.idx}.data.value.buttonText`
                  ).join(' ')}
                >
                  {buttonText}
                </Button>
              </Column>
            ))}
          </Group>
        </Section>
      </Wrapper>
    );
  },
});

export {Panel} from './Panel';
