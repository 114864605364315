import {IconButton, Text, Tooltip, View} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  COMMON_ACTION_CODES,
  FORM_ACTION_CODES,
} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import {
  FormResponseData,
  IContactFormResult,
  IFormattedFormResponse,
} from './interfaces';
import {Pressable} from 'react-native';

export const getFormResponsesTableColumns = (
  actionFn: (actionData: any, actionCode: string) => void
) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '50%',
      render: (name: string) => {
        return (
          <View>
            <Text>{name}</Text>
          </View>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdOn',
      sorter: dateSorter,
      width: '20%',
      render: (createdOn: string) => {
        return (
          <View>
            <Text>{getUSLocaleDate(createdOn)}</Text>
          </View>
        );
      },
    },
    {
      title: 'Actions',
      width: '10%',
      render: (record: any) => {
        return (
          <View>
            <Tooltip label="View" placement="top">
              <IconButton
                style={{width: 'fit-content'}}
                _hover={{
                  backgroundColor: 'transparent',
                }}
                onPress={() => {
                  actionFn(record, COMMON_ACTION_CODES.PREVIEW);
                }}
                icon={
                  <AntIcon
                    name="eyeo"
                    size={17}
                    color={Colors.Custom.Gray600}
                  />
                }
              />
            </Tooltip>
          </View>
        );
      },
    },
  ];
};

export const getFormattedSubmittedFormList = (
  data: FormResponseData
): IFormattedFormResponse[] => {
  const formattedFormResponse: IFormattedFormResponse[] = [];
  data?.formResponses.map((item) => {
    formattedFormResponse.push({
      id: item?.id,
      name: item?.form?.name,
      code: item?.form?.code,
      updatedOn: item?.updatedOn,
      components: item.formResponse.components,
      createdOn: item.createdOn,
      metadataScore: item.metadataScore,
    });
  });

  return formattedFormResponse;
};

export const getUSLocaleDate = (dateString: string) => {
  const result = new Date(dateString).toLocaleDateString('en-US', {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
  });

  if (result === 'Invalid Date') {
    return dateString;
  }
  return result;
};

export const dateSorter = (
  a: IFormattedFormResponse,
  b: IFormattedFormResponse
) => {
  return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
};

export const getPendingFormResponsesTableColumns = (args?: {hideFormName?: boolean}) => {
  const columns =  [
    {
      title: 'Sent Date',
      dataIndex: 'createdOn',
      sorter: dateSorter,
      width: '20%',
      render: (createdOn: string) => {
        return (
          <View>
            <Text>{getUSLocaleDate(createdOn)}</Text>
          </View>
        );
      },
    },
    {
      title: 'Sent By',
      dataIndex: 'sentBy',
      width: '20%',
      render: (name: string) => {
        return (
          <View>
            <Text>{name}</Text>
          </View>
        );
      },
    },
  ];
  if (!args?.hideFormName) {
    columns.unshift(
      {
        title: 'Form Name',
        dataIndex: 'name',
        width: '40%',
        render: (name: string) => {
          return (
            <View>
              <Text>{name}</Text>
            </View>
          );
        },
      },
    )
  }
  return columns
};

export const getPatientFormResponsesTableColumns = (
  actionFn: (actionData: any, actionCode: string) => void
) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '40%',
      render: (name: string) => {
        return (
          <View>
            <Text>{name}</Text>
          </View>
        );
      },
    },
    {
      title: 'Is Digitally Signed?',
      dataIndex: 'isFormSignedByPatientText',
      width: '20%',
      render: (isFormSignedByPatientText: string) => {
        return (
          <View>
            <Text>{isFormSignedByPatientText}</Text>
          </View>
        );
      },
    },
    {
      title: 'Pending Response',
      width: '20%',
      render: (record: IContactFormResult) => {
        return record.pendingCount === 0 ? (
          <View>
            <Text>0 pending</Text>
          </View>
        ) : (
          <View>
            <Pressable
              onPress={() => {
                actionFn(record, FORM_ACTION_CODES.PENDING_FORM_STATUS_VIEW);
              }}
            >
              <Text color={Colors.Custom.PrimaryColor} fontWeight="bold">
                {record.pendingCount} pending
              </Text>
            </Pressable>
          </View>
        );
      },
    },
    {
      title: 'Submitted Response',
      width: '20%',
      render: (record: IContactFormResult) => {
        return record.submittedCount === 0 ? (
          <View>
            <Text>0 submitted</Text>
          </View>
        ) : (
          <View>
            <Pressable
              onPress={() => {
                actionFn(record, FORM_ACTION_CODES.FORM_RESPONSE_VIEW);
              }}
            >
              <Text color={Colors.Custom.PrimaryColor} fontWeight="bold">
                {record.submittedCount} submitted
              </Text>
            </Pressable>
          </View>
        );
      },
    },
  ];
};
