import {HStack, Pressable, Text, View} from 'native-base';
import {Colors} from '../../../styles';
import { styles } from '../GlobalActions/GlobalActionStyles';

interface ICustomMenuItemProps {
  label: string;
  onClick: () => void;
  icon: JSX.Element;
}

export const CustomMenuItem: React.FC<ICustomMenuItemProps> = ({
  label,
  onClick,
  icon
}) => {
  return (
    <Pressable
      key={label}
      onPress={() => {
        if (onClick && typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      <HStack style={[styles.mainMenuContent]}>
        <View size={'20px'}>{icon}</View>
        <Text
          fontSize={'16px'}
          fontWeight={500}
          style={[styles.mainMenuTxt, {color: Colors.Custom.Gray500}]}
        >
          {label}
        </Text>
      </HStack>
    </Pressable>
  );
};
