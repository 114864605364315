import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { FlowType } from '../../../../../context/WorkflowContext';
import {
  getCampaigns,
  getWorkflowList
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {
  Campaign,
  GroupMemberFilterState,
  GroupMemberListFilterControllerArgs,
  Workflow
} from '../../interfaces';

export class GroupMemberListFilterController {
  isOpen: boolean;
  state: GroupMemberFilterState;
  accountUuid: string;
  showCampaignList: boolean;
  showWorkflowList: boolean;
  skeletonLines = 2;
  selectedCampaign?: Campaign;
  selectedWorkflow?: Workflow;
  setState: React.Dispatch<React.SetStateAction<GroupMemberFilterState>>;
  onCompleteAction: (actionCode: string, actionData: any) => void;
  onSubmit: (selectedCampaign?: Campaign, selectedWorkflow?: Workflow) => void;

  constructor(args: GroupMemberListFilterControllerArgs) {
    this.isOpen = args.isOpen;
    this.accountUuid = args.accountUuid;
    this.state = args.state;
    this.showCampaignList = this.state.campaigns.length > 0;
    this.showWorkflowList = this.state.workflows.length > 0;
    this.selectedCampaign = args.selectedCampaign;
    this.selectedWorkflow = args.selectedWorkflow;
    this.setState = args.setState;
    this.onCompleteAction = args.onCompleteAction;
    this.onSubmit = args.onSubmit;

    // binding
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.onMount = this.onMount.bind(this);
    this.getCampaignErrorHandler = this.getCampaignErrorHandler.bind(this);
    this.getWorkflowListErrorHandler =
      this.getWorkflowListErrorHandler.bind(this);
    this.onSelectCampaign = this.onSelectCampaign.bind(this);
    this.onSelectWorkflow = this.onSelectWorkflow.bind(this);
  }

  handleOnClose() {
    this.onCompleteAction(COMMON_ACTION_CODES.CANCEL, []);
  }

  handleOnSubmit() {
    this.onSubmit(this.state.selectedCampaign, this.state.selectedWorkflow);
  }

  handleReset() {
    this.setState((prev) => {
      return {
        ...prev,
        selectedCampaign: {} as Campaign,
        selectedWorkflow: {} as Workflow,
      };
    });
  }

  onMount() {
    this.setState((prev) => {
      return {
        ...prev,
        workflowLoading: true,
        campaignLoading: true,
        selectedCampaign: this.selectedCampaign
          ? this.selectedCampaign
          : ({} as Campaign),
        selectedWorkflow: this.selectedWorkflow
          ? this.selectedWorkflow
          : ({} as Workflow),
      };
    });

    getCampaigns(this.accountUuid)
      .then((response) => {
        this.setState((prev) => {
          return {
            ...prev,
            campaigns: response.campaigns,
            campaignLoading: false,
          };
        });
      })
      .catch((error) => {
        this.getCampaignErrorHandler(error);
      });

    getWorkflowList(
      '',
      FlowType.patients,
      this.accountUuid,
      (dataList: any) => {
        this.setState((prev) => {
          return {
            ...prev,
            workflows: dataList,
            workflowLoading: false,
          };
        });
      },
      (error: any) => {
        this.getWorkflowListErrorHandler(error);
      },false
    );
  }

  getCampaignErrorHandler(error: any) {
    this.setState((prev) => {
      return {
        ...prev,
        campaignLoading: false,
      };
    });

  }

  getWorkflowListErrorHandler(error: any) {
    this.setState((prev) => {
      return {
        ...prev,
        workflowLoading: false,
      };
    });

  }

  onSelectCampaign(value: string) {
    const selectedCampaign = this.state.campaigns.find(
      (item) => item.id === value
    );
    this.setState((prev) => {
      return {
        ...prev,
        selectedCampaign: selectedCampaign || ({} as Campaign),
      };
    });
  }

  onSelectWorkflow(value: string) {
    const selectedWorkflow = this.state.workflows.find(
      (item) => item.workflowMasterId == value
    );
    this.setState((prev) => {
      return {
        ...prev,
        selectedWorkflow: selectedWorkflow || ({} as Workflow),
      };
    });
  }
}
