import {useLazyQuery, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import {Box, Toast, VStack, useToast} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../styles';
import {getAccountUUID} from '../../../utils/commonUtils';
import {getMlovListByCategory} from '../../../utils/mlovUtils';
import AppTabPermissionsTable from './AppTabPermissionsTable';
import permissionQueries from './permissionQueries';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
const AppTabPermissions = (props: {parentTabCode: string}) => {
  const intl = useIntl();
  const toast = useToast();
  const showToastMessage = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'userAccessUpdatedMsg'}),
      ToastType.info,
    );
  };
  const userRoles = JSON.parse(
    JSON.stringify(getMlovListByCategory('UserRoles'))
  )?.sort((a: any, b: any) => a?.value.localeCompare(b?.value));

  const [currentPermissions, setCurrentPermissions] = useState([]);
  const variables = {
    entityCode: props.parentTabCode,
    accountUUID: getAccountUUID(),
  };
  const [isSetAllData, setIsSetAllData] = useState(false);
  const {loading} = useQuery(permissionQueries.GET_PERMISSION_BY_ENTITY, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: variables,
    onCompleted: (response: any) => {
      response.userPermissions = response.userPermissions;
      setCurrentPermissions(response.userPermissions);
      setIsSetAllData(true);
    },
  });

  const [getPermissionByEntity] = useLazyQuery(
    permissionQueries.GET_PERMISSION_BY_ENTITY,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      onCompleted: (response: any) => {
        response.userPermissions = response.userPermissions;
        setCurrentPermissions(response.userPermissions);
        setIsSetAllData(true);
      },
      onError: () => {
        alert('Error');
      },
    }
  );

  return (
    <VStack backgroundColor={'#fff'} height={'full'}>
      {loading || !isSetAllData ? (
        <Skeleton></Skeleton>
      ) : (
        <AppTabPermissionsTable
          showToast={showToastMessage}
          userRoles={userRoles}
          parentTabCode={props.parentTabCode}
          currentPermissions={currentPermissions}
          onSave={() => {
            setIsSetAllData(false);
            getPermissionByEntity({variables: {...variables}});
          }}
        ></AppTabPermissionsTable>
      )}
    </VStack>
  );
};

export default AppTabPermissions;
