import { CaretDownOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Select, Space, Spin } from 'antd';
import { debounce } from 'lodash';
import { HStack, Pressable, Text, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { searchMediaContent } from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { PatientEducation } from '../../RightSideContainer/ContentManagement/PatientEducation';
import { Colors } from '../../../styles';
import { CommonDataContext } from '../../../context/CommonDataContext';
export enum ContentType {
  email = 'email',
  patientEducation = 'patientEducation',
}
export interface IContentSearchFieldProps {
  value?: any;
  isShowError: boolean;
  onChange: (value: any) => void;
  type: ContentType;
  isAutomationView?:boolean;
}

const ContentSearch = (props: IContentSearchFieldProps) => {
  const { value, isShowError, onChange } = props;
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = !!commonData?.sidecarContext?.isSidecar;
  const [loading, setLoading] = useState(false);
  const [contentData, setContentData] = useState<any>([]);

  useEffect(() => {
    getPatientEducations('');
    if (value && value.id && contentData.length === 0) {
      setContentData([
        {
          id: value.id,
          attributes: {
            title: value.title,
            description: value.description,
            slug: value.slug,
          },
        },
      ]);
    }
  }, []);

  const getPatientEducations = (searchText: string) => {
    setLoading(true);
    setContentData([]);
    searchMediaContent('patient-education-articles?title=' + searchText)
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          setContentData(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {

        setLoading(false);
      });
  };

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      getPatientEducations(searchString);
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = contentData.filter((item: any) => {
      return item.id === id;
    });
    if (matchedData.length > 0) {
      return {
        id: matchedData[0].id,
        title: matchedData[0].attributes.title,
        description: matchedData[0].attributes.description,
        slug: matchedData[0].attributes.slug,
      };
    }
  };
  const [isEditViewVisible, setIsEditViewVisible] = useState(false);
  const [shouldAddContent, setShouldAddContent] = useState(false);
  return (
  <HStack>
   {!shouldAddContent && <Select
      suffixIcon={ props.isAutomationView?
        <CaretDownOutlined
          style={{
            color: Colors.FoldPixel.GRAY300
          }}
        /> :
        <DownOutlined/>
      }
      showSearch
      allowClear
      filterOption={false}
      value={value ? value.id : undefined}
      dropdownRender={(menu) => (
        <>
          {menu}
          {!isSideCarContext && (
            <>
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Button type="text" icon={<PlusOutlined />} onClick={() => {
                  setShouldAddContent(true);
                }}>
                  Add new content
                </Button>
              </Space>
            </>
          )
          }
        </>
      )}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
        }
      }}
      placeholder="Search Content"
      loading={loading}
      notFoundContent={loading && <Spin size="small" />}
      style={{ fontSize:'14px',height: '40px', width: (value?.id ? '90%' : '100%') }}
      className={isShowError && !value ? 'field-error' : ''}
    >
      {contentData.map((item: any) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            {item.attributes.title || 'Untitled'}
            {item.attributes.description && false && (
              <Text fontSize="xs" color="gray.500">
{'  '}
                {item.attributes.description}
              </Text>
            )}
        </Select.Option>
        );
      })}
    </Select>}
    {!isSideCarContext && value?.id && <><View
      marginLeft={5}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'

      }}
    >
      <Pressable
        style={{
          backgroundColor: '#FFECD5',
          borderRadius: 50,
          padding: 5
        }}
        onPress={() => {
          setIsEditViewVisible(true);
        }}
      >
        <AntDesign
          name="edit"
          color={'#8C210F'}
          size={20}
        />
      </Pressable>
    </View>

    </>
    }
    {(isEditViewVisible || shouldAddContent) && <Drawer
      visible={true}
      width={Dimensions.get('window').width * 0.7}
      onClose={() => {
        setIsEditViewVisible(false)
      }}
      headerStyle={{
        display: 'none',
      }}
    >
      <PatientEducation isHideTopTab={true} id={shouldAddContent ? '' : (value?.id || '')} onClose={() => {
        shouldAddContent ? setShouldAddContent(false) : setIsEditViewVisible(false);
      }} onUpdate={(response: any) => {
        const data = shouldAddContent ? response?.data?.data : response?.data;
        let newContentData: any = [...contentData]
        if (!data?.id) {
          return;
        }
        if (shouldAddContent) {

          newContentData = [...newContentData, data];

        } else {
          newContentData.some((item: any) => {
            if (item.id === data?.id) {
              item.attributes.title = data?.attributes?.title || '';
              item.attributes.description = data?.attributes?.description || '';
              item.attributes.slug = data?.attributes?.slug || '';
              return true;
            }
          });
        }
        onChange({
          id: data?.id,
          title: data?.attributes?.title || '',
          description: data?.attributes?.description || '',
          slug: data?.attributes?.slug || '',
        });
        setContentData(newContentData);
        shouldAddContent ? setShouldAddContent(false) : setIsEditViewVisible(false);
      }}></PatientEducation>
    </Drawer>
    }
  </HStack>
  );
};

export default ContentSearch;
