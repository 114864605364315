import {
  AdvancedType,
  BasicType,
  getNodeIdxFromClassName,
} from '@foldhealth/easy-email-core';
import {camelCase} from 'lodash';
import React from 'react';
import {getNodeTypeFromClassName} from '@foldhealth/easy-email-core';
const domParser = new DOMParser();

export function getChildSelector(selector: string, index: number) {
  return `${selector}-${index}`;
}

export interface HtmlStringToReactNodesOptions {
  enabledMergeTagsBadge: boolean;
}

export function HtmlStringToReactNodes(
  content: string,
  option: HtmlStringToReactNodesOptions
) {
  const doc = domParser.parseFromString(content, 'text/html'); // The average time is about 1.4 ms
  const aTags = Array.from(doc.getElementsByTagName('a'));
  aTags.forEach((node) => {
    node.setAttribute('tabIndex', '-1');
  });
  const reactNode = (
    <RenderReactNode selector={'0'} node={doc.documentElement} index={0} />
  );
  return reactNode;
}

const RenderReactNode = React.memo(function ({
  node,
  index,
  selector,
}: {
  node: HTMLElement;
  index: number;
  selector: string;
}): React.ReactElement {
  const attributes: {[key: string]: string} = {
    'data-selector': selector,
  };
  node.getAttributeNames?.().forEach((att) => {
    if (att) {
      attributes[att] = node.getAttribute(att) || '';
    }
  });

  if (node.nodeType === Node.COMMENT_NODE) return <></>;

  if (node.nodeType === Node.TEXT_NODE) {
    return <>{node.textContent}</>;
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    const tagName = node.tagName.toLowerCase();
    if (tagName === 'meta') return <></>;

    if (tagName === 'style') {
      return createElement(tagName, {
        key: index,
        ...attributes,
        dangerouslySetInnerHTML: {__html: node.textContent},
      });
    }

    const blockType = getNodeTypeFromClassName(node.classList);
    const idx = getNodeIdxFromClassName(node.classList);
    if (blockType) {
      if (idx) {
        makeStandardContentEditable(node, blockType, idx);
      }
      makeBlockNodeContentEditable(node);
    }

    if (attributes['contenteditable'] === 'true') {
      return createElement(tagName, {
        key: performance.now(),
        ...attributes,
        style: getStyle(node.getAttribute('style')),
        dangerouslySetInnerHTML: {__html: node.innerHTML},
      });
    }
    const childNodes = Array.from(node.childNodes);
    const reactNode = createElement(tagName, {
      key: index,
      ...attributes,
      style: getStyle(node.getAttribute('style')),
      children:
        node.childNodes.length === 0
          ? null
          : childNodes.map((n, i) => (
              <RenderReactNode
                selector={getChildSelector(selector, i)}
                key={i}
                node={n as any}
                index={i}
              />
            )),
    });

    return <>{reactNode}</>;
  }

  return <></>;
});

function getStyle(styleText: string | null) {
  if (!styleText) return undefined;
  return styleText.split(';').reduceRight((a: any, b: any) => {
    const arr = b.split(/\:(?!\/)/);
    if (arr.length < 2) return a;
    a[camelCase(arr[0])] = arr[1];
    return a;
  }, {});
}

function createElement(
  type: string,
  props?: React.ClassAttributes<Element> & {
    style?: {} | undefined;
    children?: JSX.Element[] | null;
    key: string | number;
    tabIndex?: string;
    class?: string;
    role?: string;
    src?: string;
    dangerouslySetInnerHTML?: any;
  }
) {
  if (props?.class && props.class.includes('email-block')) {
    const blockType = getNodeTypeFromClassName(props.class);
    if (![BasicType.TEXT].includes(blockType as any)) {
      props.role = 'tab';
      props.tabIndex = '0';
    }
    props.key = props.key + props.class;
  }

  return React.createElement(type, props);
}

function makeBlockNodeContentEditable(node: ChildNode) {
  if (!(node instanceof Element)) return;
  const type = getContentEditableTypeFromClassName(node.classList);
  const idx = getContentEditableIdxFromClassName(node.classList);

  if (isTextBlock(type)) {
    const editNode = node.querySelector('div');
    if (editNode) {
      editNode.setAttribute('contentEditable', 'true');
      editNode.setAttribute(
        DATA_CONTENT_EDITABLE_TYPE,
        ContentEditableType.RichText
      );
      editNode.setAttribute(DATA_CONTENT_EDITABLE_IDX, idx);
    }
  } else if (isButtonBlock(type)) {
    const editNode = node.querySelector('a') || node.querySelector('p');
    if (editNode) {
      editNode.setAttribute('contentEditable', 'true');
      editNode.setAttribute(
        DATA_CONTENT_EDITABLE_TYPE,
        ContentEditableType.Text
      );
      editNode.setAttribute(DATA_CONTENT_EDITABLE_IDX, idx);
    }
  } else if (isNavbarBlock(type)) {
    node.setAttribute('contentEditable', 'true');
    node.setAttribute(DATA_CONTENT_EDITABLE_TYPE, ContentEditableType.Text);
    node.setAttribute(DATA_CONTENT_EDITABLE_IDX, idx);
  }

  node.childNodes.forEach(makeBlockNodeContentEditable);
}

function makeStandardContentEditable(
  node: HTMLElement,
  blockType: string,
  idx: string
) {
  if (isTextBlock(blockType) || isButtonBlock(blockType)) {
    node.classList.add(
      ...getContentEditableClassName(blockType, `${idx}.data.value.content`)
    );
  }
  if (isNavbarBlock(blockType)) {
    node.querySelectorAll('.mj-link').forEach((anchor, index) => {
      anchor.classList.add(
        ...getContentEditableClassName(
          blockType,
          `${idx}.data.value.links.${index}.content`
        )
      );
    });
  }
}

import {isString} from 'lodash';
import {
  ContentEditableType,
  DATA_CONTENT_EDITABLE_IDX,
  DATA_CONTENT_EDITABLE_TYPE,
  getContentEditableClassName,
} from '@foldhealth/easy-email-editor';

export function getContentEditableType(type: string) {
  return `node-contenteditable-type-${type}`;
}

export function getContentEditableTypeFromClassName(
  classList: DOMTokenList | string
) {
  const arr = Array.from(
    isString(classList) ? classList.split(' ') : classList
  );
  return (
    arr
      .find((item) => item.includes('node-contenteditable-type-'))
      ?.replace('node-contenteditable-type-', '') || ''
  );
}

export function getContentEditableIdx(idx: string) {
  return `node-contenteditable-idx-${idx}`;
}

export function getContentEditableIdxFromClassName(
  classList: DOMTokenList | string
) {
  const arr = Array.from(
    isString(classList) ? classList.split(' ') : classList
  );
  return (
    arr
      .find((item) => item.includes('node-contenteditable-idx-'))
      ?.replace('node-contenteditable-idx-', '') || ''
  );
}

export function isTextBlock(blockType: any) {
  return blockType === BasicType.TEXT || blockType === AdvancedType.TEXT;
}

export function isButtonBlock(blockType: any) {
  return blockType === BasicType.BUTTON || blockType === AdvancedType.BUTTON;
}

export function isNavbarBlock(blockType: any) {
  return blockType === BasicType.NAVBAR || blockType === AdvancedType.NAVBAR;
}
