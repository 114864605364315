import {useLazyQuery} from '@apollo/client';
import {Drawer, notification, Popover, Table} from 'antd';
import {
  HStack,
  Pressable,
  Select,
  Skeleton,
  useMediaQuery,
  useToast,
  VStack
} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions, View} from 'react-native';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {ANALYTICS_RESOURCE, BUTTON_TYPE, CLAIMS_MEMBER_STATUS, DATE_FORMATS, EMPLOYER_REPORT_FILTER_CODES} from '../../../../../constants';
import {getAccountId, getAccountUUID, isMasterAccount} from '../../../../../utils/commonUtils';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import AddOrUpdateCampaign from '../../../../RightSideContainer/ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import {AutomationWorkflowEmployee} from '../../../../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import {GroupMemberListFilter} from '../../../../RightSideContainer/MembersManagement/GroupMemberListDrawer/GroupMemberListFilter';
import {getServiceLogsQueryObject} from '../../../../RightSideContainer/MembersManagement/Helper';
import {IContact} from '../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import MessagingContactDetailsDrawer from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION} from '../../../../RightSideContainer/UserAccess/UserAccessPermission';
import {createCampaignSegment} from '../../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';
import {DrillDownMemberListController} from './DrillDownMemberListController';
import {
  ContactResponseObject,
  DrillDownMemberListProps,
  DrillDownMemberListState
} from './interfaces';
import LeadQueries from '../../../../../services/Lead/LeadQueries';
import {Colors} from '../../../../../styles/Colors';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import React from 'react';
import {SMSDrawer} from '../../../../common/SMSDrawer';
import DrillDownRowDataView from './DrillDownRowDataView';
import {getDrillDownColumnForFirstView, getDrillDownDataForFirstView} from './DrillDownColumnUtils';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import './DrillDownMemberListStyle.css';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {OFFICE_HOURS_CODE_DISPLAY_TEXT, OFFICE_HOURS_CONST, OUT_OF_OFFICE_HOURS_CODE_DISPLAY_TEXT} from '../EmployerUtils/EmployerConst';
import {getCommunicationTypes, getCommunicationTypeSavingsDataByTypeId, getDateRangeFromFilterData} from '../../../TeamInbox/Conversations/CommunicationTypes/CommunicationTypesUtils';
import DrillDownFilters from './DrillDownFilters';
import ChronicConditionsMultiselect from './FilterView/Filters/ChronicConditionsMultiselect';
import DrillDownMainFilterView from './FilterView/DrillDownMainFilterView';
import {getDrillDownDisplayFilterMetaData} from './DrillDownDisplayFilterMetaUtils';
import {DRILL_DOWN_FILTER_CONSTANT} from './FilterView/DrillDownFilterConstant';
import {getFilterOfficeOrOutOfficeHours, getSubtitleTextByResourceCode} from '../SectionAndWidget/utils';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import { getContactWithConsent } from '../../../../common/AppDashboard/GraphMemberList/GraphMemberListUtils';
import { EmailDrawerCommonV2 } from '../../../../common/EmailDrawerCommonV2';
const DrillDownMemberList = (props: DrillDownMemberListProps) => {
  const {
    isOpen,
    title,
    onCompleteAction,
    isFromNewEmployer,
    contactDrillDownData,
    isNonMemberDrillDown,
    responseData,
    data
  } = props;
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const [visible, setVisible] = useState(true)
  const [campaignDrawerState, setCampaignDrawerState] = useState({
    isVisible: false,
    segmentId: null,
  });
  const [state, setState] = useState<DrillDownMemberListState>({
    loading: false,
    contactList: [],
    page: props.page,
    pageSize: props.pageSize,
    total: props.total,
    popoverOpen: false,
    contactResponse: [] as ContactResponseObject[],
    filterOpen: false,
    campaign: {},
    workflow: {},
    query: {},
    contactUuidMap: {},
    automationOpen: false,
    smsDrawerOpen: false,
    emailDrawerOpen: false,
    selectedRowKeys: [],
    selectedContacts: [],
    contactPageMap: {},
    isOpenDetailView: false,
    selectedDrillDownRowData: {} as any,
    selectedContact: {} as IContact,
    contactDrillDownColumnData: [] as any,
    selectedDropDownValue: props.data?.selectedGraphData?.[0]?.code || '',
    contactSavingsMap: {} as any,
    communicationTypes: [],
    selectedCommunicationType: '',
    subTitleText: '',
  });

  const contactUuidList = props.contactUuidList || [];
  const previousDrillDownColumnList = contactDrillDownData?.previousColumnList?.length
    ? contactDrillDownData?.previousColumnList
    : [];
  const {height} = Dimensions.get('window');
  const tableHeight = height - (props.data.selectedGraphData ? 350 : 300);

  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactsByUuid);

  const {
    onMount,
    handleOnClose,
    handleVisibleChange,
    getSelectedContacts,
    handleSelectedContacts,
  } = new DrillDownMemberListController({
    state,
    accountId,
    accountUuid,
    responseData,
    contactUuidList,
    contactDrillDownData,
    setState,
    getContactsByUuid,
    onCompleteAction,
  });

  useEffect(() => {
    onMount(contactUuidList);
  }, [props.contactUuidList, props?.contactUuidList?.length, props?.contactUuidList?.length === 0,]);

  const isEmailAndSMSAllowed = getUserPermissionByEntityAndActionCode(
    'ADMIN_PANEL_WINDOW',
    'CONTENT_MANAGEMENT'
  );
  const isMasterAccountFlag = isMasterAccount();
  const isCreateCampaignAllowed = getUserPermissionByEntityAndActionCode(
    'DASHBOARD_WINDOW',
    'CAMPAIGN_MANAGER'
  );
  const isCreateAutomationAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );
  const [selectedContactData, setSelectedContactData] = useState<any>();
  const showButtonList =
    (isEmailAndSMSAllowed ||
      isCreateAutomationAllowed ||
      isCreateCampaignAllowed) &&
    state.selectedRowKeys.length > 0;

  const showSelectMemberToastMessage = () => {
    notification.error({
      message: 'Please Select Member First'
    });
  };

  const [isSmallScreen] = useMediaQuery([
    {maxWidth: 1300},
  ]);

  const onRunAutomationClick = () => {
    if (state?.selectedRowKeys?.length > 0) {
      setState((prev) => {
        return {
          ...prev,
          automationOpen: true,
          popoverOpen: false,
        };
      });
    } else {
      showSelectMemberToastMessage();
    }
  };

  const onCreateCampaignClick = () => {
    if (state.selectedRowKeys.length > 0) {
      const selectedRowKeys = state.selectedRowKeys;
      const contactUuidMap = state.contactUuidMap;
      createCampaignSegment({
        name: '',
        tenantId: accountUuid,
        metadata: {
          patientFilter: {
            contactIds: selectedRowKeys.map((uuid) => {
              return contactUuidMap[uuid].id;
            }),
          },
        },
      }).then((response) => {
        if (response?.id) {
          setCampaignDrawerState({
            isVisible: true,
            segmentId: response?.id,
          });
        }
      });
    } else {
      showSelectMemberToastMessage();
    }
  }

  const runAutomationBtnText = 'runAutomation';
  const createCampaignText = 'Create Campaign';
  const sendEmailTxt = 'Send Email';
  const buttonList = [
    ...(props?.onClose
      ? [
        {
          show: true,
          id: 2,
          btnText: 'Close',
          textColor: Colors.Custom.mainPrimaryPurple,
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: BUTTON_TYPE.PRIMARY,
          isTransBtn: false,
          onClick: () => {
            if (props?.onClose) {
              props?.onClose?.();
              setVisible(false)
            }
          },
        },
      ]
      : []),
    ...(isCreateAutomationAllowed && !isSmallScreen && !isNonMemberDrillDown && !isMasterAccountFlag
      ? [
        {
          show: true,
          id: 2,
          btnText: runAutomationBtnText,
          textColor: Colors.Custom.mainPrimaryPurple,
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: BUTTON_TYPE.PRIMARY,
          isTransBtn: false,
          onClick: () => {
            onRunAutomationClick();
          },
        },
      ]
      : []),
    ...(isCreateCampaignAllowed && !isSmallScreen && !isNonMemberDrillDown && !isMasterAccountFlag
      ? [
        {
          show: true,
          id: 3,
          btnText: createCampaignText,
          textColor: Colors.Custom.mainPrimaryPurple,
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: BUTTON_TYPE.PRIMARY,
          isTransBtn: false,
          onClick: () => {
            onCreateCampaignClick();
          },
        },
      ]
      : []),
  ];

  const popOverButtonList = [
    ...(isCreateAutomationAllowed && isSmallScreen && !isNonMemberDrillDown
      ? [
        {
          btnText: runAutomationBtnText,
          size: 'sm',
          textColor: Colors.secondary['800'],
          borderColor: Colors.secondary['200'],
          backgroundColor: Colors.secondary['100'],
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: '',
          isTransBtn: true,
          onClick: () => {
            onRunAutomationClick();
          },
        },
      ]
      : []),
    ...(isCreateCampaignAllowed && isSmallScreen && !isNonMemberDrillDown
      ? [
        {
          btnText: createCampaignText,
          size: 'sm',
          textColor: Colors.secondary['800'],
          borderColor: Colors.secondary['200'],
          backgroundColor: Colors.secondary['100'],
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: '',
          isTransBtn: true,
          onClick: () => {
            onCreateCampaignClick();
          },
        },
      ]
      : []),
    ...(isEmailAndSMSAllowed && !isNonMemberDrillDown
      ? [
        {
          btnText: sendEmailTxt,
          size: 'sm',
          textColor: Colors.secondary['800'],
          borderColor: Colors.secondary['200'],
          backgroundColor: Colors.secondary['100'],
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: '',
          onClick: () => {
            if (state.selectedRowKeys.length > 0) {
              const selectedNumberUUids = getSelectedMemberForEmail()
              if (selectedNumberUUids.length) {
                setState((prev) => {
                  return {
                    ...prev,
                    emailDrawerOpen: true,
                    popoverOpen: false,
                  };
                });
              } else {
                notification.error({
                  message: 'Please select the member having valid email address'
                });
              }
            } else {
              showSelectMemberToastMessage();
            }
          },
        },
      ]
      : []),
    ...(isEmailAndSMSAllowed && !isNonMemberDrillDown
      ? [
        {
          btnText: 'sendSms',
          size: 'sm',
          textColor: Colors.secondary['800'],
          borderColor: Colors.secondary['200'],
          backgroundColor: Colors.secondary['100'],
          btnStype: BUTTON_TYPE.PRIMARY,
          variant: '',
          isTransBtn: true,
          onClick: () => {
            if (state.selectedRowKeys.length > 0) {
              const selectedNumberUUids = getSelectedMemberForSMS()
              if (selectedNumberUUids.length) {
                setState((prev) => {
                  return {
                    ...prev,
                    smsDrawerOpen: true,
                    popoverOpen: false,
                  };
                });
              } else {
                notification.error({
                  message: 'Please select the member having phone number for sending sms'
                });
              }
            } else {
              showSelectMemberToastMessage();
            }
          },
        },
      ]
      : []),
  ];

  const getSelectedMemberForEmail = () => {
    return state.selectedRowKeys.filter((uuid) => {
      return state.contactUuidMap?.[uuid]?.email ?? false;
    })
  }

  const getSelectedMemberForSMS = () => {
    return state.selectedRowKeys.filter((uuid) => {
      return state.contactUuidMap?.[uuid]?.phoneNumber ?? false;
    })
  }
  const onViewDetailsClick = (selectedContact: IContact, isViewDetails: boolean) => {
    if (isMasterAccountFlag && !isViewDetails) {
      return
    }
    if (isViewDetails && selectedContact) {
      let contactUuid: any = '';
      if (selectedContact?.claimsMemberStatus === CLAIMS_MEMBER_STATUS.NON_MEMBER) {
        contactUuid = selectedContact?.tempDummyContactUuid;
      } else {
        contactUuid = selectedContact?.uuid;
      }

      let selectedRowData = {} as any;
      if (props?.isNonMemberDrillDown && selectedContact?.claimsMemberStatus === CLAIMS_MEMBER_STATUS.NON_MEMBER) {
        const contactDrillDownDataList = props.contactDrillDownData?.contactDrillDownDataList || [];
        selectedRowData = contactDrillDownDataList.find((item) => {
          return item.tempDummyContactUuid === contactUuid
        })
      } else {
        const contactDrillDownDataList = props.contactDrillDownData?.contactDrillDownDataList || [];
        selectedRowData = contactDrillDownDataList.find(item => {
          return item.contactUuid === contactUuid
        });
      }
      if (selectedRowData) {
        setState(prev => {
          return {
            ...prev,
            isOpenDetailView: true,
            selectedDrillDownRowData: selectedRowData,
            selectedContact: selectedContact
          }
        })
      }
    } else if (selectedContact) {
      setSelectedContactData(selectedContact);
    }

  }

  const onColumnActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.LOADING:
        setState((prev) => {
          return {
            ...prev,
            loading: true,
          }
        });
        break;
      case COMMON_ACTION_CODES.LOADING_COMPLETE:
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          }
        });
        break;
      case COMMON_ACTION_CODES.SHOW_DETAIL_VIEW:
        if (actionData?.selectedDrillDownRowData && actionData?.selectedContact && !isMasterAccountFlag) {
          setState(prev => {
            return {
              ...prev,
              isOpenDetailView: true,
              selectedDrillDownRowData: actionData?.selectedDrillDownRowData,
              selectedContact: actionData?.selectedContact,
              loading: false,
            }
          })
        } else {
          setState((prev) => {
            return {
              ...prev,
              loading: false,
            }
          });
        }

        break;
    }
  };
  let subtitle;
  if(props?.resourceCode === ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS) {
    subtitle = props?.data?.type ? `${props?.data?.type.replace(/_/g, ' ').charAt(0).toUpperCase() + props?.data?.type.replace(/_/g, ' ').slice(1).toLowerCase()} care journey was prescribed ${props?.data?.count} time to ${props?.responseData?.totalCount} patients` : ''
  } else {
    subtitle = props?.data?.type ? `${props?.data?.type.replace(/_/g, ' ').charAt(0).toUpperCase() + props?.data?.type.replace(/_/g, ' ').slice(1).toLowerCase()} - ${props?.data?.count}  ${props?.data?.date ? '• ' + getDateStrFromFormat(props?.data?.date, 'MMM YY') : ''}` : ''
  }

  useEffect(() => {
    const subtitle = getSubtitleTextByResourceCode(
      props.data,
      props.resourceCode,
      state.selectedDropDownValue || props.data?.selectedGraphData?.[0]?.code,
      props?.responseData?.totalCount
    )
    setState(prev => {
      return {
        ...prev,
        subTitleText: subtitle
      }
    })
  },[props?.responseData?.totalCount])

  const officeOurOutOfficeOutList = () => {
    const dropdownList =
      props?.data?.type === OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
        ? OUT_OF_OFFICE_HOURS_CODE_DISPLAY_TEXT
        : OFFICE_HOURS_CODE_DISPLAY_TEXT;
    const filterList = getFilterOfficeOrOutOfficeHours(dropdownList, props.data?.selectedGraphData)
    return <View style={{width: isSmallScreen ? 170 : 300}}>
      <ModalActionAntSelect
        size="large"
        showSearch
        optionFilterProp="children"
        placeholder="Select Payer"
        className={'select-payer'}
        label={''}
        value={state.selectedDropDownValue}
        filterOption={(input: string, option: any) => {
          return (option?.children || '').toLowerCase().includes(input.toLowerCase());
        }}
        onChange={(value: any[], data: any) => {
          if (props.onPaginationChange) {
            setState(prev => {
              return {
                ...prev,
                selectedDropDownValue: data.value,
              }
            })
            if (props.isFromNewEmployer) {
              setState((prev) => {
                return {
                  ...prev,
                  loading: true
                }
              })
            }
            props.onPaginationChange(1, props.pageSize, {isDropDownCodeChange: true, filterValue: data?.value});
          }
        }}
        style={{height: 40}}
        customStyle={{height: 40, width: 350}}
        data={filterList || []}
        optionProps={{
          key: 'code',
          label: 'displayText',
          value: 'code',
        }}
      />
    </View>
  }
  const onFilterApplied = (filterCode: string, filterValue: any) => {
    let finalFilterValue = filterValue;
    if (props.onPaginationChange) {
      switch (filterCode) {
        case EMPLOYER_REPORT_FILTER_CODES?.CHRONIC_CONDITION: {
          const finalFilterValueList: any[] = [];
          if (typeof filterValue === 'object' && filterValue?.length) {
            finalFilterValue?.forEach((singleValue: string) => {
              const tempFilterValueList = singleValue?.split('-');
              if (tempFilterValueList?.length >= 1) {
                const tempCCValue = tempFilterValueList?.[0]?.trim();
                finalFilterValueList.push(tempCCValue);
              }
            });
            finalFilterValue = finalFilterValueList;
          }

        }
      }
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      props.onPaginationChange(1, props.pageSize, {
        filterCode: filterCode,
        filterValue: finalFilterValue,
        isFilterApplied: true,
      });
    }
  }
  return (
    <>
      <Drawer
        open={isFromNewEmployer ? visible : isOpen}
        onClose={() => {
          if (isFromNewEmployer) {
            props?.onClose?.();
            setVisible(false);
          } else {
            handleOnClose();
          }
        }}
        width={isSmallScreen ? '85vw' : '70vw'}
        title={
          <VStack>
            <HStack
              justifyContent={'space-between'}
              alignItems="center"
              flex={1}
            >
              <VStack>
                <HStack alignItems={'center'}>
                  <TitleSubtitleView
                    subtitle={state.subTitleText}
                    containerStyle={{marginLeft: 0, marginBottom: 5}}
                    isHideCommonButton
                    title={title || 'Member List'}
                    titleStyle={{
                      maxWidth: 400,
                      flexWrap: 'wrap',
                      color: Colors.primary['400'],
                    }}
                  />
                </HStack>
                <View style={{marginTop: 20}}>
                  {
                    props.data?.selectedGraphData?.length > 0 && officeOurOutOfficeOutList()
                  }
                </View>
                {/* <DrillDownMainFilterView
                  onFilterApplied={onFilterApplied}
                  displayFilterMetaData={getDrillDownDisplayFilterMetaData(props?.resourceCode)}
                /> */}
              </VStack>
              <HStack alignItems={'center'}>
                <HStack style={{marginHorizontal: 8}} space={'2'}>
                  {buttonList.map((item) => {
                    return (
                      <FoldButton
                        key={item.id}
                        nativeProps={{
                          variant: item.btnStype,
                          onPress: () => {
                            item.onClick();
                          },
                        }}
                        customProps={{
                          hideButton: item.show === false,
                          btnText: (
                            <DisplayText
                              textLocalId={item.btnText}
                              size={'smBold'}
                              extraStyles={{
                                color: Colors.Custom.mainPrimaryPurple,
                              }}
                            />
                          ),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                    );
                  })}
                  {popOverButtonList.length && !isNonMemberDrillDown && !isMasterAccountFlag ? (
                    <Popover
                      content={
                        <VStack>
                          {popOverButtonList.map((item: any) => {
                            return (
                              <Pressable
                                key={item.id || ''}
                                onPress={() => {
                                  item.onClick();
                                }}
                              >
                                <View
                                  style={{
                                    marginVertical: 8,
                                  }}
                                >
                                  <DisplayText textLocalId={item.btnText} />
                                </View>
                              </Pressable>
                            );
                          })}
                        </VStack>
                      }
                      trigger="click"
                      overlayInnerStyle={{padding: 0, borderRadius: 16}}
                      overlayStyle={{padding: 0}}
                      overlay={{padding: 0}}
                      style={{padding: 0, borderRadius: 16}}
                      placement={'bottomRight'}
                      visible={state.popoverOpen}
                      onVisibleChange={handleVisibleChange}
                    >
                      <Pressable
                        style={{
                          height: 32,
                          width: 32,
                          borderRadius: 18,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          setState((prev) => {
                            return {
                              ...prev,
                              popoverOpen: true,
                            };
                          });
                        }}
                      >
                        <Fontisto
                          name="more-v-a"
                          size={18}
                          color={Colors.Custom.foldIconColor}
                        />
                      </Pressable>
                    </Popover>
                  ) : (
                    <></>
                  )}
                </HStack>
                {/* <VStack
              style={{
                width: 32,
                height: 32,
              }}
            >
              <Pressable
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      filterOpen: true,
                    };
                  });
                }}
              >
                <FilterIconSvg
                  isEnabled={true}
                  customStrokeColor={Colors.secondary['800']}
                  customBgColor={Colors.secondary['100']}
                />
              </Pressable>
            </VStack> */}
              </HStack>
            </HStack>
          </VStack>
        }
      >
        {state.loading && !contactUuidList.length ? (
          <Skeleton.Text lines={4} />
        ) : (
          <VStack>
            <DrillDownFilters
              resourceCode={props.data?.type}
              onValueChange={(data) => {
                if (props.onPaginationChange) {
                  props.onPaginationChange(1, props.pageSize, {
                    filterCode: data.filterCode,
                    filterValue: {value: data.filterValue},
                    isDropDownCodeChange: true,
                  });
                }
              }}
            />

            <Table
              loading={state.loading}
              scroll={{y: tableHeight}}
              dataSource={state.contactDrillDownColumnData}
              className="employer-drill-down-table"
              showHeader={true}
              onRow={(_record: any) => {
                return {
                  onClick: () => {
                    if (props?.isNonMemberDrillDown && !isMasterAccountFlag) {
                      setSelectedContactData(_record.contact);
                    }
                  },
                };
              }}
              rowKey={(record) => record?.contact?.uuid}
              rowSelection={ isMasterAccountFlag ? undefined : {
                onSelectAll(selected, selectedRows, changeRows) {
                  let selectedRowKeys = [...state.selectedRowKeys];
                  const contactUuidMap = {...state.contactUuidMap};
                  const uuidList = changeRows.map((record) => {
                    contactUuidMap[record?.contact?.uuid] = record.contact;
                    return record?.contact?.uuid;
                  });

                  if (selected) {
                    uuidList.forEach((uuid) => {
                      if (selectedRowKeys.indexOf(uuid) == -1) {
                        selectedRowKeys.push(uuid);
                      }
                    });
                  } else {
                    uuidList.forEach((uuid) => {
                      selectedRowKeys = selectedRowKeys.filter(
                        (selectedUuid) => {
                          return selectedUuid !== uuid;
                        }
                      );
                    });
                  }
                  setState((prev) => {
                    return {...prev, selectedRowKeys, contactUuidMap};
                  });
                },
                onSelect(contactResponse: any, isSelected) {
                  const contactUuidMap = {...state.contactUuidMap};
                  const record = contactResponse?.contact || {};
                  let selectedRowKeys = [...state.selectedRowKeys];
                  if (
                    isSelected &&
                    selectedRowKeys.indexOf(record.uuid) == -1
                  ) {
                    selectedRowKeys.push(record.uuid);
                  } else {
                    selectedRowKeys = selectedRowKeys.filter((uuid) => {
                      return uuid != record.uuid;
                    });
                  }
                  contactUuidMap[record.uuid] = record;
                  setState((prev) => {
                    return {
                      ...prev,
                      selectedRowKeys: selectedRowKeys,
                      contactUuidMap,
                    };
                  });
                },
                selectedRowKeys: state.selectedRowKeys,
              }}
              columns={getDrillDownColumnForFirstView({
                contactDrillDownData: props.contactDrillDownData,
                onViewDetailsClick,
                onColumnActionPerformed,
              },props?.resourceCode)}
              pagination={{
                position: ['bottomCenter'],
                pageSize: props.pageSize,
                pageSizeOptions: [5, 7, 10, 15, 20, 50],
                current: props.page,
                total: props.total,
                onChange(page, pageSize) {
                  if (props.onPaginationChange) {
                    if (
                      !props?.isNonMemberDrillDown &&
                      props.isFromNewEmployer
                    ) {
                      setState((prev) => {
                        return {
                          ...prev,
                          loading: true,
                        };
                      });
                    }
                    props.onPaginationChange(page, pageSize, {
                      isDropDownCodeChange: false,
                    });
                  }
                },
              }}
            />
          </VStack>
        )}

        {state.filterOpen && (
          <GroupMemberListFilter
            isOpen={state.filterOpen}
            selectedCampaign={state.campaign}
            selectedWorkflow={state.workflow}
            onSubmit={(campaign, workflow) => {
              const query = getServiceLogsQueryObject(campaign, workflow);
              setState((prev) => {
                return {
                  ...prev,
                  filterOpen: false,
                  campaign: campaign,
                  workflow: workflow,
                  query: query,
                };
              });
            }}
            onCompleteAction={() => {
              setState((prev) => {
                return {
                  ...prev,
                  filterOpen: false,
                };
              });
            }}
          />
        )}

        {state.automationOpen && (
          <Drawer
            visible={state.automationOpen}
            width={700}
            onClose={() =>
              setState((prev) => {
                return {
                  ...prev,
                  automationOpen: false,
                };
              })
            }
            closable={true}
            title={<></>}
          >
            <AutomationWorkflowEmployee
              tenantId={accountUuid}
              cancelBtnTxt={'Back'}
              initData={{
                selectedItems: state.selectedRowKeys.map((uuid) => {
                  return state.contactUuidMap[uuid];
                }),
              }}
              onModalClose={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    automationOpen: false,
                  };
                });
              }}
            />
          </Drawer>
        )}
        {campaignDrawerState?.isVisible ? (
          <Drawer
            visible={campaignDrawerState?.isVisible}
            width={Dimensions.get('window').width * 0.7}
            onClose={() => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
            }}
            headerStyle={{
              display: 'none',
            }}
          >
            <AddOrUpdateCampaign
              shouldShowEdit={true}
              initData={undefined}
              segmentId={campaignDrawerState?.segmentId}
              close={(status) => {
                setCampaignDrawerState({
                  isVisible: false,
                  segmentId: null,
                });
                if (status != 'cancel') {
                  notification.success({
                    message:
                      status == 'created'
                        ? 'Campaign created successfully.'
                        : 'Campaign updated successfully.',
                  });
                }
              }}
            />
          </Drawer>
        ) : null}
        {selectedContactData && (
          <MessagingContactDetailsDrawer
            contactData={selectedContactData}
            isDrawerVisible={selectedContactData ? true : false}
            contactType={selectedContactData?.contactType}
            onSideBarActionPerformed={(action?: any) => {
              setSelectedContactData('');
            }}
            isEmployerDrillDown={true}
          />
        )}

        {state.smsDrawerOpen && !isNonMemberDrillDown && (
          <SMSDrawer
            isOpen={state.smsDrawerOpen}
            data={getSelectedMemberForSMS().map((uuid) => {
              const item = state.contactUuidMap[uuid] || {};
              return {
                contactUuid: item.uuid,
                id: item.id,
                name: item.name,
                phoneNumber: item.phoneNumber,
                contactConsents: item?.contactConsents
              };
            })}
            onCompleteAction={() => {
              setState((prev) => {
                return {
                  ...prev,
                  smsDrawerOpen: false,
                };
              });
            }}
          />
        )}

        {state.emailDrawerOpen && !isNonMemberDrillDown && (
          <EmailDrawerCommonV2
            isOpen={state.emailDrawerOpen}
            onClose={() => {
              setState((prev) => {
                return {
                  ...prev,
                  emailDrawerOpen: false,
                };
              });
            }}
            onEmailSent={() => {
              setState((prev) => {
                return {
                  ...prev,
                  emailDrawerOpen: false,
                };
              });
            }}
            contactIds={getContactWithConsent(
              state.selectedRowKeys,
              state.contactUuidMap
            ).map((item) => item.id) || []}
          />
        )}
      </Drawer>
      {state.isOpenDetailView ? (
        <DrillDownRowDataView
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                isOpenDetailView: false,
                selectedDrillDownRowData: null,
                selectedContact: {} as IContact,
              };
            });
          }}
          columnList={previousDrillDownColumnList}
          selectedContact={state.selectedContact}
          selectedDrillDownRowData={state.selectedDrillDownRowData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(DrillDownMemberList);
