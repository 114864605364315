import React from 'react';
import {
  AdvancedType,
  BasicType,
  IBlockData,
  createCustomBlock,
  getPreviewClassName,
  mergeBlock,
  components,
} from '@foldhealth/easy-email-core';
import {CustomBlocksType} from '../constants';
import {getContentEditableClassName} from '@foldhealth/easy-email-editor';
const {Column, Section, Wrapper, Text, Button} = components;

export type IFormsData = IBlockData<
  {
    captionColor: string;
    backgroundColor: string;
    buttonColor: string;
    buttonTextColor: string;
  },
  {
    caption: string;
    formList: [{name: string; link: string}];
  }
>;
const FormsBlock = createCustomBlock({
  name: 'Forms',
  type: CustomBlocksType.FORMS_BLOCK,
  validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
  create: (payload: any) => {
    const defaultData: IFormsData = {
      type: CustomBlocksType.FORMS_BLOCK,
      data: {
        value: {
          caption:
            'Please fill below forms  to help us provide better care to you!',
          formList: [{name: 'Intake', link: 'https://google.com'}],
        },
      },
      attributes: {
        captionColor: 'black',
        backgroundColor: 'white',
        buttonColor: 'black',
        buttonTextColor: 'white',
      },
      children: [],
    };
    return mergeBlock(defaultData, payload);
  },
  render: ({data, idx, mode, context, dataSource}) => {
    const inputData: IFormsData = data;
    const caption = inputData.data.value.caption;
    const formList = inputData.data.value.formList;
    const attributes = inputData.attributes;

    return (
      <Wrapper
        css-class={
          mode === 'testing' ? getPreviewClassName(idx || '', data.type) : ''
        }
        padding="20px 0px 20px 0px"
        border="none"
        direction="ltr"
        text-align="center"
        background-color={attributes.backgroundColor}
      >
        <Section padding="0px">
          <Column padding="0px" border="none" vertical-align="top">
            <Text
              font-size="16px"
              padding="10px 25px 10px 25px"
              line-height="1"
              align="center"
              font-weight="bold"
              color={attributes.captionColor}
              css-class={getContentEditableClassName(
                BasicType.TEXT,
                `${idx}.data.value.caption`
              ).join(' ')}
            >
              {caption}
            </Text>
          </Column>
        </Section>

        <Section padding="0px">
          <Column padding="0px" border="none" vertical-align="top">
            <Text
              font-size="12px"
              line-height="1"
              align="center"
              font-weight="normal"
              color={'black'}
              css-class={getContentEditableClassName(BasicType.TEXT, ``).join(
                ' '
              )}
            >
              {'{% for form in formList %}'}
            </Text>
            <Button
              align="center"
              padding="15px 0px"
              background-color={attributes.buttonColor}
              color={attributes.buttonTextColor}
              target="_blank"
              vertical-align="middle"
              border="none"
              text-align="center"
              css-class={getContentEditableClassName(BasicType.BUTTON, ``).join(
                ' '
              )}
            >{`{{form.name}}`}</Button>
            <Text
              font-size="12px"
              padding="10px 25px 10px 25px"
              line-height="1"
              align="center"
              font-weight="normal"
              color={'black'}
              css-class={getContentEditableClassName(BasicType.TEXT, ``).join(
                ' '
              )}
            >{`{% endfor %}`}</Text>
          </Column>
        </Section>
      </Wrapper>
    );
  },
});
export default FormsBlock;
