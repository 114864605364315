import {
  CARE_PROGRAM_STEP_STATUS_CODES,
} from '../../../../constants/MlovConst';
import {
  IContactCareProgramResponse,
  IContactCareProgramSteps,
  IStepsLog,
} from '../../../../services/ContactCareProgram/interface';
import {CARE_PROGRAM_STEP_TYPE} from '../constant';

export const useCareProgramDeclinedInfo = (
  careProgram: IContactCareProgramResponse
): {
  isDeclined: boolean;
  declinedAtStepCode: string;
  declinedAtStep?: IContactCareProgramSteps;
} => {
  const careProgramSteps = careProgram?.contactCareProgramSteps;
  const careProgramStepLogs = careProgram?.stepsLog;
  let declinedStepLog: IStepsLog | undefined = undefined;
  declinedStepLog = careProgramStepLogs.find(
    (stepLog) =>
      stepLog.careProgramStepStatus.code ===
      CARE_PROGRAM_STEP_STATUS_CODES.REJECT
  );

  const declinedStep = careProgramSteps.find(
    (step) => step.id === declinedStepLog?.careProgramStepId
  );

  if (
    declinedStep &&
    declinedStep.careProgramStepType.code === CARE_PROGRAM_STEP_TYPE.CONSENT
  ) {
    return {
      isDeclined: true,
      declinedAtStepCode: declinedStep.careProgramStepType.code,
      declinedAtStep: declinedStep,
    };
  }
 
  const careProgramStepLogsWithoutConsent = careProgramStepLogs.filter(
    (stepLog) =>
      [
        CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
        CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY,
        CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      ].includes(stepLog.careProgramStep.careProgramStepType.code)
  );
  

  declinedStepLog = careProgramStepLogsWithoutConsent.find((stepLog) =>
    stepLog.careProgramStep.careProgramStepOutreaches.some(
      (item) => {
        return !!item?.stepOutreachLog?.declineDate;
      }
    )
  );

  if (declinedStepLog) {
    const declinedStep = careProgramSteps.find(
      (step) => step.id === declinedStepLog?.careProgramStepId
    );

    if (declinedStep) {
      return {
        isDeclined: true,
        declinedAtStepCode: declinedStep.careProgramStepType.code,
        declinedAtStep: declinedStep,
      };
    }
  }

  return {
    isDeclined: false,
    declinedAtStepCode: '',
  };
};
