import { HStack,Skeleton,Spacer,Text,VStack,View } from 'native-base';
import { useEffect,useState } from 'react';
import { Colors } from '../../../../styles/Colors';

import { useQuery } from '@apollo/client';
import { Collapse,Switch } from 'antd';
import { Pressable } from 'react-native';
import { TRACKING_RESOURCE_TYPE } from '../../../../constants';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { GetPatientIdAndLocationId } from '../../../../services/ConvertContacts/ConvertContacts';
import { getCalendarDateTitle } from '../../../../utils/DateUtils';
import {
  getEhrConfig,
  getResourceAbilities,
  getVitalListFromCapability,
} from '../../../../utils/capabilityUtils';
import { ReportResultView } from '../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportResultView';
import {
  REPORT_TYPE,
  getReportResultForDisplay,
  isAbnormalValue,
} from '../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { getLabOrders } from '../../../PersonOmniView/LeftContainer/RecentActivity/helper';
import CareJourneyAnalyticVitalsGraphView from '../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/CareJourneyAnalyticVitalsGraphView';
import { CalendarView } from '../../../common/CalendarWidget/CalendarWidgetEnums';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import './VitalsAndLabTracking.css';

interface DateRange {
  startDate: Date;
  endDate: Date;
}
const SeparatorLine = () => {
  return (
    <HStack>
      <View
        style={{marginLeft: -24, height: 1, borderRadius: 10, marginTop: 12}}
        width={'full'}
        backgroundColor={Colors.Custom.Gray200}
      />
      <View
        style={{height: 1, borderRadius: 10, marginTop: 12}}
        width={12}
        backgroundColor={Colors.Custom.Gray200}
      />
    </HStack>
  );
};

const VitalsAndLabTracking = (props: {
  endDate: string;
  startDate: string;
  patientId: string;
  trackingResourceList: [
    {trackingResourceType: string; trackingResourceCode: string}
  ];
}) => {
  const TABS = {
    ALL: {
      code: 'All',
      display: 'All',
    },
    THREE_MONTH: {
      code: 'threeMonth',
      display: '3 Months',
    },
    SIX_MONTH: {
      code: 'sixMonth',
      display: '6 Months',
    },
    NINE_MONTH: {
      code: 'nineMonth',
      display: '9 Months',
    },
  };

  const getDateRange = (code: string): DateRange | null => {
    const currentDate = new Date();

    switch (code) {
      case TABS.THREE_MONTH.code:
        return {
          startDate: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 2,
            currentDate.getDate()
          ),
          endDate: currentDate,
        };
      case TABS.SIX_MONTH.code:
        return {
          startDate: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 5,
            currentDate.getDate()
          ),
          endDate: currentDate,
        };
      case TABS.NINE_MONTH.code:
        return {
          startDate: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 8,
            currentDate.getDate()
          ),
          endDate: currentDate,
        };
      default:
        return null;
    }
  };

  const [vitalsAndLabTrackingDetails, setVitalsAndLabTrackingDetails] =
    useState({
      isExpandAll: false,
      patientFhirId: '',
      locationId: '',
      selectedTab: TABS.ALL.code,
    });

  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    if (vitalsAndLabTrackingDetails.selectedTab == TABS.ALL.code) {
      setDateFilter({startDate: '', endDate: ''});
    } else {
      const dateRange = getDateRange(vitalsAndLabTrackingDetails.selectedTab);
      if (dateRange) {
        setDateFilter({
          startDate: dateRange?.startDate?.toISOString(),
          endDate: dateRange?.endDate?.toISOString(),
        });
      }
    }
  }, [vitalsAndLabTrackingDetails.selectedTab]);

  const [isLoading, setIsLoading] = useState(true);

  useQuery(GetPatientIdAndLocationId, {
    //skip: accountUserQueryLoading,
    variables: {
      // userId: accountUsers.map(
      //   (accountUserItem: any) => accountUserItem.userId
      // ),
      patientUuid: props.patientId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (response: any) => {
      const patientDetails = response.contactPatients[0];
      setVitalsAndLabTrackingDetails((prev: any) => {
        return {
          ...prev,
          patientFhirId: patientDetails?.patientId,
          locationId:
            patientDetails?.contact?.contactPracticeLocations?.[0]
              ?.accountLocation?.uuid,
        };
      });
      setIsLoading(false);
    },
    onError: (error: any) => {

      setIsLoading(false);
    },
  });
  const patientId = props.patientId;
  const careJourneyStartDate = props.startDate;
  const careJourneyEndDate = props.endDate;

  const trackingResourceList = props.trackingResourceList || [];
  const [focusElement, setFocusElement] = useState({
    [TABS.ALL.code]: false,
    [TABS.THREE_MONTH.code]: false,
    [TABS.SIX_MONTH.code]: false,
    [TABS.NINE_MONTH.code]: false,
  });
  const setFocusElementOnPressHover = (key: string, isFocus: boolean) => {
    setFocusElement((prev) => {
      return {...prev, [key]: isFocus};
    });
  };
  const vitalMasterList = getVitalListFromCapability() || [];
  const [allowedVitalList] = useState(
    vitalMasterList.filter((vital) => {
      return props.trackingResourceList.some((allowedVital: any) => {
        return (
          vital.loinc === allowedVital.trackingResourceCode &&
          allowedVital?.trackingResourceType === TRACKING_RESOURCE_TYPE.VITALS
        );
      });
    })
  );

  const [allowedLabResultList] = useState(
    props.trackingResourceList.filter((resource) => {
      return resource.trackingResourceType == TRACKING_RESOURCE_TYPE.LAB_TEST;
    })
  );

  const [vitalDisplayInfo, setVitalDisplayInfo] = useState({
    numberOfRowDisplayed: 0,
    isLoading: false,
  });

  const [labDisplayInfo, setLabDisplayInfo] = useState({
    numberOfRowDisplayed: 0,
    isLoading: false,
  });

  return (
    <VStack>
      <VStack>
        <View>
          <Text
            style={{
              color: Colors.Custom.Gray700,
              fontSize: 18,
            }}
            size={'smBold'}
          >
            Tracking Vitals and Lab Analyte Recordings
          </Text>
          <HStack>
            <Text
              style={{
                color: Colors.Custom.Gray400,
                fontSize: 14,
              }}
            >
              {getCalendarDateTitle(
                CalendarView.month,
                new Date(careJourneyStartDate)
              )}{' '}
              -
            </Text>

            <Text
              style={{
                color: Colors.Custom.Gray400,
                fontSize: 14,
              }}
            >
              {getCalendarDateTitle(CalendarView.month, new Date())}
            </Text>
          </HStack>
        </View>
        <SeparatorLine />

        {props.trackingResourceList?.length ? (
          <HStack height={'44px'} paddingY={'8px'} alignItems={'center'}>
            <HStack alignItems={'center'}>
              <Switch
                size="default"
                checked={vitalsAndLabTrackingDetails.isExpandAll}
                style={{
                  backgroundColor: vitalsAndLabTrackingDetails.isExpandAll
                    ? Colors.primary[300]
                    : Colors.muted[500],
                }}
                onChange={(checked) => {
                  setVitalsAndLabTrackingDetails((prev) => {
                    return {...prev, isExpandAll: checked};
                  });
                }}
              />
              <Text
                style={{
                  color: Colors.Custom.Gray500,
                  fontSize: 14,
                  padding: 5,
                }}
              >
                Expand All
              </Text>
            </HStack>
            <Spacer></Spacer>
            <HStack
              justifyContent={'end'}
              style={{borderRadius: 4, height: 30}}
              space={2}
              backgroundColor={Colors.Custom.Gray50}
              alignItems={'center'}
            >
              {Object.keys(TABS).map((key: string) => {
                const tabs: any = TABS;
                return (
                  <div
                    className={
                      vitalsAndLabTrackingDetails.selectedTab == tabs[key].code
                        ? 'lab-vital-tracking-tab selected '
                        : 'lab-vital-tracking-tab'
                    }
                  >
                    <Pressable
                      onPress={() => {
                        setVitalsAndLabTrackingDetails((prev) => {
                          return {
                            ...prev,
                            selectedTab: tabs[key].code,
                          };
                        });
                      }}
                      onPressIn={() => {
                        setFocusElementOnPressHover(tabs[key].code, true);
                      }}
                      onPressOut={() => {
                        setFocusElementOnPressHover(tabs[key].code, false);
                      }}
                      style={{padding: 5}}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                          color:
                            focusElement[tabs[key].code] ||
                            vitalsAndLabTrackingDetails.selectedTab ==
                              tabs[key].code
                              ? Colors.Custom.Gray500
                              : Colors.Custom.Gray400,
                        }}
                      >
                        {tabs[key].display}
                      </Text>
                    </Pressable>
                  </div>
                );
              })}
            </HStack>
          </HStack>
        ) : (
          <></>
        )}
      </VStack>

      {!props.trackingResourceList?.length ||
      (!vitalDisplayInfo?.numberOfRowDisplayed &&
        !vitalDisplayInfo.isLoading &&
        !labDisplayInfo?.numberOfRowDisplayed &&
        !labDisplayInfo.isLoading) ? (
        <NodataViewTypeOne viewCode={'TEXT_ICON'} />
      ) : (
        <></>
      )}

      {patientId && allowedVitalList?.length ? (
        isLoading ? (
          <></>
        ) : (
          <CareJourneyAnalyticVitalsGraphView
            setVitalDisplayInfo={setVitalDisplayInfo}
            carePlanStartDate={careJourneyStartDate}
            key={dateFilter.startDate + dateFilter.endDate}
            isExpandAll={vitalsAndLabTrackingDetails.isExpandAll}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
            vitalList={allowedVitalList}
            isCheckInVitalList={true}
            patientId={vitalsAndLabTrackingDetails.patientFhirId || patientId}
            accountLocationUuid={vitalsAndLabTrackingDetails.locationId}
          ></CareJourneyAnalyticVitalsGraphView>
        )
      ) : (
        <></>
      )}

      {isLoading ? (
        <></>
      ) : allowedLabResultList?.length ? (
        <ShowLabTestResultGraph
          carePlanStartDate={careJourneyStartDate}
          setLabDisplayInfo={setLabDisplayInfo}
          locationId={vitalsAndLabTrackingDetails.locationId}
          patientFhirId={vitalsAndLabTrackingDetails.patientFhirId}
          setIsExpandAll={(isExpandAll: boolean) => {
            setVitalsAndLabTrackingDetails((prev) => {
              return {
                ...prev,
                isExpandAll,
              };
            });
          }}
          resourceList={allowedLabResultList}
          patientId={patientId}
          isExpandAll={vitalsAndLabTrackingDetails.isExpandAll}
          startDate={dateFilter.startDate}
          endDate={dateFilter.endDate}
        ></ShowLabTestResultGraph>
      ) : (
        <></>
      )}
    </VStack>
  );
};

const ShowLabTestResultGraph = (props: {
  patientId: string;
  resourceList: {trackingResourceType: string; trackingResourceCode: string}[];
  setLabDisplayInfo(input: {
    numberOfRowDisplayed: number;
    isLoading: boolean;
  }): void;
  startDate: string;
  endDate: string;
  carePlanStartDate: string;
  isExpandAll: boolean;
  setIsExpandAll: any;
  patientFhirId: string;
  locationId: string;
}) => {
  const allowedLoincCodeList = props.resourceList
    .filter((resource) => {
      return resource.trackingResourceType == TRACKING_RESOURCE_TYPE.LAB_TEST;
    })
    .map((resource) => {
      return resource.trackingResourceCode;
    });
  const [patientDetails, setPatientDetails] = useState({
    patientFhirId: props.patientFhirId,
    locationId: props.locationId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const labOrderAbilitity = getResourceAbilities(
    FHIR_RESOURCE.DIAGNOSTIC_REPORT
  );

  const ehrConfig = getEhrConfig();
  const [observationList, setObservationList] = useState<any>([]);
  const [filterObservationList, setFilterObservationList] = useState<any>([]);

  const isDateInRange = (
    dateToCheck: string,
    startDateStr: string,
    endDateStr: string
  ): boolean => {
    if (!startDateStr || !endDateStr) {
      return true;
    }
    const inputDate = new Date(dateToCheck);
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    return inputDate >= startDate && inputDate <= endDate;
  };
  const fetchLab = async () => {
    setIsLoading(true);
    const labOrders = await getLabOrders(
      patientDetails.patientFhirId,
      patientDetails.locationId,
      labOrderAbilitity.usePagination,
      false
    );
    if (labOrders && labOrders?.length) {
      const promiseList: any = [];
      labOrders.forEach(async (labOrder) => {
        const promise = getReportResultForDisplay(
          labOrder,
          REPORT_TYPE.RESULT,
          // Sending Empty array as this component is not used
          [],
          {
            ehrConfig,
            patientId: patientDetails.patientFhirId,
            locationId: patientDetails.locationId,
          }
        );
        promiseList.push(promise);
      });
      Promise.all(promiseList).then((promiseResults) => {
        const newList: any = [];
        promiseResults.forEach((promiseResult) => {
          const {observationList} = promiseResult;
          const newObservationList = observationList.filter(
            (observation: any) => {
              return allowedLoincCodeList.includes(
                observation?.results[0]?.loincCode
              );
            }
          );

          newObservationList.forEach((newObservation: any) => {
            const isExisting = newList.some((older: any) => {
              if (
                older.display == newObservation.display &&
                newObservation?.results[0]?.loincCode ===
                  older?.results[0]?.loincCode
              ) {
                older.results = [...older?.results, ...newObservation?.results];
                return true;
              }
            });
            if (!isExisting) {
              newList.push(newObservation);
            }
          });
        });

        newList.forEach((data: any) => {
          data.results = (data?.results || []).filter((result: any) => {
            return isDateInRange(
              result.issued || result.effectiveDateTime,
              props.startDate,
              props.endDate
            );
          });
        });

        setObservationList((prev: any) => {
          return newList.filter((data: any) => {
            return data?.results?.length;
          });
        });
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let filterObservationList = JSON.parse(JSON.stringify(observationList));
    filterObservationList.forEach((data: any) => {
      data.results = (data?.results || []).filter((result: any) => {
        return isDateInRange(
          result.issued || result.effectiveDateTime,
          props.startDate,
          props.endDate
        );
      });
    });
    filterObservationList = filterObservationList.filter((data: any) => {
      return data?.results?.length;
    });

    setFilterObservationList(filterObservationList);
    props.setLabDisplayInfo({
      isLoading: isLoading,
      numberOfRowDisplayed: filterObservationList?.length,
    });
  }, [observationList?.length, isLoading, props.startDate, props.endDate]);

  useEffect(() => {
    if (patientDetails.patientFhirId) {
      fetchLab();
    }
  }, [patientDetails.patientFhirId]);

  const [selectedIndexList, setSelectedIndexList] = useState<Array<number>>([]);
  useEffect(() => {
    if (props.isExpandAll) {
      const list = observationList.map((item: any, index: number) => {
        return index;
      });
      setSelectedIndexList(list);
    } else {
      setSelectedIndexList([]);
    }
  }, [props.isExpandAll]);

  const carePlanStartDate = new Date(props.carePlanStartDate);
  return filterObservationList?.length ? (
    <div key={filterObservationList.length + ''}>
      {filterObservationList.map((observation: any, index: any) => {
        let beforeCarePlanResult: any;
        let afterCarePlanResult: any;
        let lastSelectedDateForBeforeCarePlan: any;
        let lastSelectedDateForAfterCarePlan: any;
        observation?.results.forEach((result: any) => {
          if (result?.issued) {
            const issuedDate = new Date(result?.issued);
            if (
              carePlanStartDate > issuedDate &&
              (!lastSelectedDateForBeforeCarePlan ||
                lastSelectedDateForBeforeCarePlan < issuedDate)
            ) {
              lastSelectedDateForBeforeCarePlan = issuedDate;
              beforeCarePlanResult = result;
            }
            if (
              !lastSelectedDateForAfterCarePlan ||
              lastSelectedDateForAfterCarePlan < issuedDate
            ) {
              lastSelectedDateForAfterCarePlan = issuedDate;
              afterCarePlanResult = result;
            }
          }
        });
        return (
          <div className="custom-lab-track-collapse">
            <Collapse
              key={selectedIndexList.length + observation?.observationId}
              defaultActiveKey={
                selectedIndexList.indexOf(index) !== -1 ? ['1'] : []
              }
              style={{marginTop: 10}}
            >
              <Collapse.Panel
                style={{padding: '5px'}}
                header={
                  <HStack height={'full'} alignItems={'center'}>
                    <HStack height={'full'} alignItems={'center'}>
                      <Text
                        style={{
                          color: Colors.Custom.Gray600,
                          fontSize: 16,
                          fontWeight: '600',
                          lineHeight: 14.4,
                        }}
                      >
                        {observation?.display}
                      </Text>
                    </HStack>
                    <Spacer></Spacer>
                    <HStack space={4}>
                      <VStack>
                        <Text
                          style={{fontSize: 14, color: Colors.Custom.Gray400}}
                        >
                          Before Care Journey
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: beforeCarePlanResult
                              ? isAbnormalValue(
                                  beforeCarePlanResult?.referenceRange,
                                  beforeCarePlanResult,
                                  ehrConfig
                                )
                                ? Colors.Custom.ErrorColor
                                : Colors.Custom.SuccessColor
                              : Colors.Custom.Gray300,
                          }}
                        >
                          {(beforeCarePlanResult?.value || '-') +
                            ' ' +
                            (beforeCarePlanResult?.unit || '')}
                        </Text>
                      </VStack>
                      <VStack>
                        <Text
                          style={{fontSize: 14, color: Colors.Custom.Gray400}}
                        >
                          Last Measured
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: afterCarePlanResult
                              ? isAbnormalValue(
                                  afterCarePlanResult?.referenceRange,
                                  afterCarePlanResult,
                                  ehrConfig
                                )
                                ? Colors.Custom.ErrorColor
                                : Colors.Custom.SuccessColor
                              : Colors.Custom.Gray300,
                          }}
                        >
                          {(afterCarePlanResult?.value || '-') +
                            ' ' +
                            (afterCarePlanResult?.unit || '')}
                        </Text>
                      </VStack>
                    </HStack>
                  </HStack>
                }
                key="1"
              >
                <HStack
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'full'}
                >
                  <ReportResultView
                    isHideGraphLabel={false}
                    labelFontSize='12px'
                    isShowGraphOnly={true}
                    contactData={{}}
                    showModal={true}
                    reportName={''}
                    observations={[observation]}
                    resources={[]}
                    onMarkedAsReviewed={() => {
                    }}
                    onClose={() => {
                    }}
                  />
                </HStack>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      })}
    </div>
  ) : isLoading ? (
    <Skeleton.Text lines={5} />
  ) : (
    <></>
  );
};

export default VitalsAndLabTracking;
