import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles/Colors';

export const searchMsgThreadStyle = StyleSheet.create({
  webMsgThreadContainer: {
    paddingHorizontal: 16,
    marginTop: 16,
    borderBottomWidth: 1,
    paddingBottom: 16,
    borderBottomColor: Colors.Custom.Gray200,
  },
  appMsgThreadContainer: {
    height: 70,
    paddingHorizontal: 8,
    marginTop: 12,
    borderBottomWidth: 1,
    paddingBottom: 16,
    borderBottomColor: Colors.Custom.Gray200,
  },
});
