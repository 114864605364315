import {ITabDataProps} from '../../../common/TabList/interface';
import {IInboxChannelType} from './ConversationChannelTabs/interfaces';

export const CONVERSATION_TAB_CODES = {
  MINE: 'MINE',
  UN_ASSIGNED: 'UN_ASSIGNED',
  ALL: 'ALL',
  RECENT: 'RECENT',
  PATIENTS: 'PATIENTS',
  PROSPECTS: 'PROSPECTS',
  INTERNAL: 'INTERNAL',
  REPLY: 'REPLY',
  PRIVATE: 'PRIVATE',
  CHART: 'CHART'
};

export const CHANNEL_TYPE_CODE: {
  [key in IInboxChannelType]: IInboxChannelType;
} = {
  CHANNEL_WEB_WIDGET: 'CHANNEL_WEB_WIDGET',
  CHANNEL_TWILIO_SMS: 'CHANNEL_TWILIO_SMS',
  CHANNEL_EMAIL: 'CHANNEL_EMAIL',
  CHANNEL_WHATSAPP: 'CHANNEL_WHATSAPP',
  CHANNEL_API: 'CHANNEL_API',
  CHANNEL_INTERNAL: 'CHANNEL_INTERNAL',
  CHANNEL_ALL: 'CHANNEL_ALL',
  CALL_LOGS: 'CALL_LOGS',
  CHANNEL_MENTION: 'CHANNEL_MENTION',
  CHANNEL_ASSIGNED_ME: 'CHANNEL_ASSIGNED_ME',
  CHANNEL_STARRED: 'CHANNEL_STARRED',
  CHANNEL_ARCHIVED: 'CHANNEL_ARCHIVED',
  CHANNEL_MISSED_CALLS: 'CHANNEL_MISSED_CALLS',
  CHANNEL_ASSIGNED_OTHER: 'CHANNEL_ASSIGNED_OTHER',
  CHANNEL_UNASSIGNED: 'CHANNEL_UNASSIGNED',
  CHANNEL_E_FAX: 'CHANNEL_E_FAX',
  ALL_CALLS: 'ALL_CALLS',
};

export const CONVERSATION_TAB_FILTER_LIST: ITabDataProps[] = [
  {
    tabCode: CONVERSATION_TAB_CODES.ALL,
    title: 'All',
    code: 'all',
  },
  {
    tabCode: CONVERSATION_TAB_CODES.MINE,
    title: 'Mine',
    code: 'mine',
  },
  {
    tabCode: CONVERSATION_TAB_CODES.UN_ASSIGNED,
    title: 'Unassigned',
    code: 'unassigned',
  },
];

export const CONVERSATION_TAB_LIST: ITabDataProps[] = [
  {
    tabCode: CONVERSATION_TAB_CODES.RECENT,
    title: 'Recent',
    code: 'recent',
  },
  {
    tabCode: CONVERSATION_TAB_CODES.PATIENTS,
    title: 'Patients',
    code: 'patients',
  },
  {
    tabCode: CONVERSATION_TAB_CODES.PROSPECTS,
    title: 'Prospects',
    code: 'prospects',
  },
  {
    tabCode: CONVERSATION_TAB_CODES.INTERNAL,
    title: 'Internal',
    code: 'internal',
  },
];

export const CONVERSATION_TABS = {
  [CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET]: [
    CONVERSATION_TAB_CODES.RECENT,
    CONVERSATION_TAB_CODES.PATIENTS,
    CONVERSATION_TAB_CODES.PROSPECTS,
    CONVERSATION_TAB_CODES.INTERNAL,
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]: [
    CONVERSATION_TAB_CODES.RECENT,
    CONVERSATION_TAB_CODES.PATIENTS,
    CONVERSATION_TAB_CODES.PROSPECTS,
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_EMAIL]: [
    CONVERSATION_TAB_CODES.RECENT,
    CONVERSATION_TAB_CODES.PATIENTS,
    CONVERSATION_TAB_CODES.PROSPECTS,
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_WHATSAPP]: [
    CONVERSATION_TAB_CODES.RECENT,
    CONVERSATION_TAB_CODES.PATIENTS,
    CONVERSATION_TAB_CODES.PROSPECTS,
  ],
};

export const DEFAULT_CONVERSATION_TABS = [
  CONVERSATION_TAB_CODES.RECENT,
  CONVERSATION_TAB_CODES.PATIENTS,
  CONVERSATION_TAB_CODES.PROSPECTS,
];

const REPLY_MESSAGE_TYPE = {
  MESSAGE_TYPE_REPLY: 'MESSAGE_TYPE_REPLY',
  MESSAGE_TYPE_INTERNAL: 'MESSAGE_TYPE_INTERNAL'
}
export const MESSAGE_TYPE_CODE = [
  {
    tabCode: CONVERSATION_TAB_CODES.REPLY,
    title: 'Reply',
    code: REPLY_MESSAGE_TYPE.MESSAGE_TYPE_REPLY
  },
  {
    tabCode: CONVERSATION_TAB_CODES.PRIVATE,
    title: 'Internal',
    code: REPLY_MESSAGE_TYPE.MESSAGE_TYPE_INTERNAL
  },
];

export const CHANNEL_TYPE = {
  CHANNEL_WEB_WIDGET: 'Channel::WebWidget',
  CHANNEL_TWILIO_SMS: 'Channel::TwilioSms',
  CHANNEL_EMAIL: 'Channel::Email',
  CHANNEL_WHATSAPP: 'Channel::Whatsapp',
  CHANNEL_EFAX: 'Channel::EFax',
};

export const ERROR_CODE = {
  CONVERSATION_ALREADY_RESOLVED: 'CONVERSATION_ALREADY_RESOLVED',
  CONVERSATION_ALREADY_ASSIGN_TO_SAME_USER: 'CONVERSATION_ALREADY_ASSIGN_TO_SAME_USER',
  CONVERSATION_ASSIGNMENT_REQUIRED_DATA_MISSING: 'CONVERSATION_ASSIGNMENT_REQUIRED_DATA_MISSING',
  ASSIGNEE_NOT_EXIST_IN_INBOX: 'ASSIGNEE_NOT_EXIST_IN_INBOX',
  ASSIGNEE_NOT_EXIST_IN_SAME_ACCOUNT: 'ASSIGNEE_NOT_EXIST_IN_SAME_ACCOUNT',
  ASSIGNEE_USER_NOT_FOUND: 'ASSIGNEE_USER_NOT_FOUND',
  ACCOUNT_USER_NOT_FOUND: 'ACCOUNT_USER_NOT_FOUND',
  CONVERSATION_NOT_FOUND: 'CONVERSATION_NOT_FOUND',
  CONVERSATION_INBOX_NOT_FOUND: 'CONVERSATION_INBOX_NOT_FOUND',
  ASSIGNEE_USER_NOT_FOUND_IN_INBOX: 'ASSIGNEE_USER_NOT_FOUND_IN_INBOX',
  ASSIGNEE_USER_NOT_FOUND_IN_GROUP: 'ASSIGNEE_USER_NOT_FOUND_IN_GROUP',
  ASSIGNEE_VALIDATION_ERROR: 'ASSIGNEE_VALIDATION_ERROR',
}

export const SCHEDULE_MESSAGE_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  SCHEDULED: 'scheduled'
}

export const SCHEDULE_OPTIONS_CONST = {
  SEND: 'Send',
  RESCHEDULE: 'Reschedule',
  DELETE: 'Delete',
  EDIT_LOG: 'Edit Log',
  EDIT: 'Edit'
}

export const SCHEDULE_OPTIONS_LIST = [
  SCHEDULE_OPTIONS_CONST.SEND,
  SCHEDULE_OPTIONS_CONST.RESCHEDULE,
  SCHEDULE_OPTIONS_CONST.EDIT,
  SCHEDULE_OPTIONS_CONST.DELETE,
]

export const TEMPLATES_CONST = {
  GENERAL: 'GENERAL',
  MULTIPLE_PATIENT_FORMS: 'MULTIPLE_PATIENT_FORMS',
  PATIENT_FORM: 'PAT_FORM',
  SHARE_PACKAGES: 'SHARE_PACKAGES',
}

export const USER_CONVERSATION_FILTER_CODES = {
  CHANNEL_ASSIGNED_OTHER: 'CHANNEL_ASSIGNED_OTHER',
};

export const USER_CONVERSATION_FILTER_CODES_LIST = [
  USER_CONVERSATION_FILTER_CODES.CHANNEL_ASSIGNED_OTHER
];

export const ALLOWED_TEMPLATES = [
  TEMPLATES_CONST.GENERAL,
  TEMPLATES_CONST.MULTIPLE_PATIENT_FORMS,
  TEMPLATES_CONST.PATIENT_FORM,
  TEMPLATES_CONST.SHARE_PACKAGES,
];
export const ALLOWED_TEMPLATES_NATIVE = [TEMPLATES_CONST.GENERAL];

export const TOTAL_SCHEDULE_MESSAGE_STATUS = [
  SCHEDULE_MESSAGE_STATUS.SCHEDULED,
];
export const TOTAL_FAILED_MESSAGE_STATUS = [SCHEDULE_MESSAGE_STATUS.FAILED];

export const ASSIGNED_CODE = {
  UN_ASSIGNED: 'UN_ASSIGNED',
  ASSIGNED: 'ASSIGNED',
}

export const GROUP_CONVERSATION_CHANNEL = 'GROUP_CONVERSATION';

export const PATIENT_PROFILE_COMMUNICATION_TAB_CODES = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  AUTOMATED: 'Automated',
  OLD: 'Old',
  JAN_1970: 'Jan 1970',
};

export const EMAIL_CONVERSATION_SUBTAB_CODES = {
  INBOX: 'INBOX',
  SENT: 'SENT',
};