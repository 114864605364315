import {Button} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {MODULE_PAGINATION_COUNT} from '../../../../../../constants';
import {ConversationItemLoading} from '../../../../../common/Loader/ConversationItemLoading';
import {IConversationData} from '../../interfaces';
import { testID } from '../../../../../../testUtils';

export const renderConversationFooter = (
  showFetchMore: boolean,
  isFetchMore: boolean,
  conversationListLength: number,
  loadMoreConversations: () => void,
  alwaysShowFetchMore: boolean,
  pageSize?: number,
  loadMoreTextId?: string
): JSX.Element => {
  const intl = useIntl();
  let footerElem = <></>;
  if (
    alwaysShowFetchMore ||
    (showFetchMore &&
      conversationListLength >=
        (pageSize ?? MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE))
  ) {
    if (isFetchMore) {
      footerElem = <ConversationItemLoading />;
    } else {
      footerElem = (
        <Button
          size="md"
          variant="link"
          onPress={() => {
            loadMoreConversations();
          }}
          marginBottom={6}
          {...testID('loadMoreConversations')}
        >
          {intl.formatMessage({
            id: loadMoreTextId ?? 'loadMoreConversations',
          })}
        </Button>
      );
    }
  }
  return footerElem;
};
