import {IEmailInboxFormKeys, IEmailInboxRef} from './interfaces';

export const EMAIL_INBOX_ACTION_CODES = {
  DRAWER_CLOSE: 'DRAWER_CLOSE',
  DRAWER_OPEN: 'DRAWER_OPEN',
  MODAL_CLOSE: 'DRAWER_CLOSE',
  ON_INBOX_SAVE: 'ON_INBOX_SAVE',
};

export const EMAIL_INBOX_ERROR = {
  inboxName: 'Please give inbox name',
  email: 'Please give valid email address',
  imapAddress: 'Please give Imap address name',
  imapPort: 'Please give Imap port name',
  imapEmail: 'Please give valid Imap email name',
  imapPassword: 'Please give Imap password name',
  smtpAddress: 'Please give SMTP address name',
  smtpPort: 'Please give SMTP port name',
  smtpEmail: 'Please give valid SMTP email name',
  smtpPassword: 'Please give SMTP password name',
};

export const EMAIL_SETTING = {
  FOLD_POWERED: 'FOLD_POWERED',
  CUSTOM: 'CUSTOM',
};

export const EMAIL_REF_KEYS: Array<IEmailInboxFormKeys> = [
  'email',
  'imapAddress',
  'imapPort',
  'imapEmail',
  'imapPassword',
  'smtpAddress',
  'smtpPort',
  'smtpEmail',
  'smtpPassword',
];

export const EMAIL_PLATFORMS = {
  OUTLOOK: 'OUTLOOK',
  GMAIL: 'GMAIL',
  MAILGUN: 'MAILGUN',
} as const;

export const EMAIL_PLATFORM_OPTIONS = [
  {
    code: EMAIL_PLATFORMS.OUTLOOK,
    value: 'Outlook',
  },
  {
    code: EMAIL_PLATFORMS.GMAIL,
    value: 'Gmail',
  },
  {
    code: EMAIL_PLATFORMS.MAILGUN,
    value: 'Mailgun',
  }
];

export const GOOGLE_SMTP_INFO_LINK = 'https://foldhealth-prod-cms-uploads.s3.amazonaws.com/Email+Inbox+IMAP+and+SMTP+Settings.pdf';