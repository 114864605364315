import { DiagnosticReport, Extension, Observation, Reference, RequestGroup } from 'fhir/r4';
import { cloneDeep } from 'lodash';
import { constants } from 'perf_hooks';
import {getDiagnosticReportById, getObservations} from '../../../../../services/CommonService/AidBoxService';
import CareStudioService from '../../../../../services/CommonService/CareStudioService';
import { getOrderFromReport, ReportCategory } from '../../../MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { EXTENSION_URLS } from '../../../MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import {
  IContainedResource,
  IDiagnosticReportFhirResponse,
  IDiagnosticReportResource,
  IExtension,
  IMediaRecord,
  IObservationFhirResponse,
  IObservationResource,
  IObservationResult,
  IObservationResultTable,
  IReportAndResourceMap,
  IReportContained,
  IReportResult,
  IRequestGroup,
} from '../OrdersInterface';
import { IAvailableEhr } from '../../../MiddleContainer/interfaces';
import { RcFile } from 'antd/lib/upload';
import { getCurrentTimeZone, getDateStrFromFormatWithTimezone } from '../../../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../../../constants';

const DOCUMENT_DATE = 'document-date';
const CHART_DATE = 'chart-date';

export const REPORT_TYPE = {
  MEDIA_REPORT: 'MEDIA',
  RESULT: 'RESULT',
  BOTH: 'BOTH',
  FOLD_MEDIA_REPORT: 'FOLD_MEDIA_ATTACHMENT',
};

export const SUPPORTED_IMAGE_URLS = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
export const SUPPORTED_PROFILE_IMAGE_URLS = "image/jpeg,image/png,image/gif,image/jpg"
export const SUPPORTED_PRESENTED_FORM = [...SUPPORTED_IMAGE_URLS, 'application/pdf'];

export enum ObservationInterpretation {
  normal = 'normal',
  abnormal = 'abnormal',
  low = 'low',
  high = 'high',
  criticalLow = 'critical low',
  criticalAbnormal = 'critical abnormal',
  criticalHigh = 'critical high',
  significantChangeUp = 'significant change up',
  significantChangeDown = 'significant change down',
  better = 'better',
  worse = 'worse',
  offScaleLow = 'off-scale low',
  offScaleHigh = 'off-scale high',
  susceptible = 'susceptible',
  resistant = 'resistant',
  intermediate = 'intermediate',
  moderatelySusceptible = 'moderately susceptible',
  verySusceptible = 'very susceptible',
  positive = 'positive',
  negative = 'negative',
  delta = 'delta',
  deltaLow = 'delta low',
  deltaHigh = 'delta high',
}

export const HIGH_INTERPRETATIONS = [
  ObservationInterpretation.high,
  ObservationInterpretation.criticalHigh,
  ObservationInterpretation.significantChangeUp,
  ObservationInterpretation.offScaleHigh,
  ObservationInterpretation.deltaHigh,
];

export const LOW_INTERPRETATIONS = [
  ObservationInterpretation.low,
  ObservationInterpretation.criticalLow,
  ObservationInterpretation.significantChangeDown,
  ObservationInterpretation.offScaleLow,
  ObservationInterpretation.deltaLow,
];

export const filterReviewedByReport = (diagnosticResponse: IDiagnosticReportFhirResponse) => {
  const filterDiagnosticResponse: IDiagnosticReportFhirResponse = cloneDeep(diagnosticResponse);
  const entry = (diagnosticResponse?.entry || []).filter(entryResource => {
    const extensionList = entryResource?.resource?.extension || [];
    const reviewedByExtension: Extension = getExtensionByUrl(extensionList || [], EXTENSION_URLS.reviewedBy);
    const reviewedDate: Extension = getExtensionByUrl(extensionList || [], EXTENSION_URLS.reviewedDate);
    return !(reviewedDate?.url && reviewedByExtension?.url);
  });
  filterDiagnosticResponse.entry = entry;
  return filterDiagnosticResponse;
};

export const getDiagnosticReportResponseFormatted = (response: any, isApplyReviewedByFilter?: boolean, groupReports?: boolean) => {
  if (!response) {
    return {
      reports: [],
      reportMap: new Map(),
    }
  }
  const diagnosticResponse: IDiagnosticReportFhirResponse = response?.data;
  const reportMap = configureReportWiseDetailFromResponse(diagnosticResponse, groupReports || false);
  const diagnosticReportList: IReportAndResourceMap[] = [];
  for (const [key, value] of reportMap) {
    const mapValue: IReportAndResourceMap = value;
    if (isApplyReviewedByFilter && isAllReportViewedBy(mapValue)) {
    } else {
      (mapValue.resources || []).sort((a, b) => {
        return (
          new Date(getEffectiveDateOfReport(b)).getTime() -
          new Date(getEffectiveDateOfReport(a)).getTime()
        );
      });
      const latestResource = mapValue?.resources?.[0];
      mapValue.type = getTypeOfReport(mapValue?.resources);
      mapValue.effectiveDateTime = getEffectiveDateOfReport(latestResource);
      diagnosticReportList.push(mapValue);
    }
  }
  return {
    reports: diagnosticReportList,
    reportMap: reportMap,
  };
};

export const getReportResultForDisplay = async (
  selectedReport: IReportAndResourceMap,
  reportType: string,
  orders: RequestGroup[],
  supportingParams: {
    patientId: string,
    ehrConfig: IAvailableEhr,
    locationId?: string,
  },
) => {
  if (reportType === REPORT_TYPE.RESULT) {
    const observationIds = getObservationIds(selectedReport, orders);
    const dataAvailable = hasDataInContained(selectedReport);
    // If there is only result key then we call API to get all observations in references
    if (observationIds && observationIds?.length && !dataAvailable) {
      return await getObservationFromIntegration(observationIds, selectedReport);
    // Elation and health gorilla use case
    } else {
      return getWithoutObservationsReportResult(selectedReport, orders, supportingParams.ehrConfig); // handle here
    }
  } else if (reportType === REPORT_TYPE.MEDIA_REPORT || reportType === REPORT_TYPE.FOLD_MEDIA_REPORT) {
    return await getMediaReportInDetails(selectedReport, orders, supportingParams);
  }
  return {
    reportName: '',
    observationList: [],
  };;
};

const hasDataInContained = (selectedReport: IReportAndResourceMap) => {
  return selectedReport.resources.some((report) => !!report.contained?.length);
}

export const getDocumentDescription = (extensions?: Extension[]) => {
  const documentDescription = extensions?.find((ext) => ext.url === EXTENSION_URLS.documentDescription);
  return documentDescription?.valueString || '';
}

export const getReportObservationDateTime = (extensions?: Extension[]) => {
  const observationDateTime = extensions?.find((ext) => ext.url === EXTENSION_URLS.observationDateTime);
  return observationDateTime?.valueDateTime || '';
}

export const getInternalNote = (extensions?: Extension[]) => {
  const internalNote = extensions?.find((ext) => ext.url === EXTENSION_URLS.internalNote);
  return internalNote?.valueString || '';
}

export const getPatientNote = (extensions?: Extension[]) => {
  const patientNote = extensions?.find((ext) => ext.url === EXTENSION_URLS.patientNote);
  return patientNote?.valueString || '';
}

export const getDocumentData = (extensions?: Extension[]) => {
  const patientNote = extensions?.find((ext) => ext.url === EXTENSION_URLS.documentData);
  return patientNote?.valueString || '';
}

const configureReportWiseDetailFromResponse = (
  diagnosticResponse: IDiagnosticReportFhirResponse,
  groupReports: boolean,
) => {
  const reportMap: Map<string, IReportAndResourceMap> = new Map<
    string,
    IReportAndResourceMap
  >();
  for (const resourceEntry of (diagnosticResponse.entry || [])) {
    const reportResource = resourceEntry?.resource as DiagnosticReport;
    if (reportResource && reportResource?.code) {
      let code = '';
      let display = '';
      let resourceIdentifier: string | undefined = '';
      const resourceCode = reportResource.code;
      if (resourceCode?.coding?.length) {
        code = resourceCode.coding?.[0]?.code || '';
        resourceIdentifier = code;
        display = resourceCode.coding?.[0]?.display || getDocumentDescription(reportResource?.extension) || '';
      } else if (resourceCode?.text) {
        resourceIdentifier = undefined;
        code = resourceCode?.text;
        display = resourceCode?.text;
      }
      const skipGrouping = hasFoldAttachment(reportResource) || !groupReports;
      if (skipGrouping || !code) {
        code = reportResource.id || '';
      }
      if (reportMap.has(code)) {
        const reportObj = reportMap.get(code) || ({} as IReportAndResourceMap);
        const resourceArray = reportObj.resources || [];
        resourceArray.push(reportResource);
      } else {
        reportMap.set(code, {
          code: code,
          resourceCode: resourceIdentifier,
          display: display,
          effectiveDateTime: getEffectiveDateOfReport(reportResource),
          issued: getIssueDateOfReport(reportResource),
          resources: [reportResource],
          orderType: getReportOrderType(reportResource),
          showGraph: false,
        });
      }
    }
  }
  return reportMap;
};

const getReportOrderType = (report: DiagnosticReport) => {
  const code = report.category?.[0]?.coding?.[0]?.code;
  return code == 'RAD' || code == 'RADIOLOGY' || code === 'IMG' || code === 'IMAGING' ? ReportCategory.RAD : ReportCategory.LAB;
}

const isPresentFormInResponse = (resource: DiagnosticReport) => {
  if (resource?.presentedForm?.length) {
    return (resource?.presentedForm || []).some(form => {
      return form?.contentType && SUPPORTED_PRESENTED_FORM.includes(form?.contentType);
    })
  }
  return false;
}

const getTypeOfReport = (resources: DiagnosticReport[]) => {
  let isMediaReport = false;
  let isResultTypeReport = false;
  let isFoldAttachment = false;
  (resources || []).some((resource) => {
    const containedList = resource?.contained?.filter((item) => !['Organization'].includes(item.resourceType))
    if (resource?.media?.[0]?.link?.type == 'FoldAttachment') {
      isFoldAttachment = true;
    }
    const reportAttachment = resource.extension?.find(item => item.url === EXTENSION_URLS.attachmentExists)?.valueBoolean;
    if ((resource?.media?.length && resource?.media[0]?.link) || isPresentFormInResponse(resource) || reportAttachment) {
      isMediaReport = true;
    }
    if (resource?.result?.length || containedList?.length) {
      isResultTypeReport = true;
    }

    if (!isMediaReport && containedList?.length) {
      isMediaReport = (resource.contained || []).some(reportData => {
        return reportData?.resourceType === 'Media';
      });
    }
    if (isMediaReport && isResultTypeReport) {
      return true;
    }
  });
  if (isFoldAttachment) {
    return REPORT_TYPE.FOLD_MEDIA_REPORT;
  }
  if (isMediaReport && isResultTypeReport) {
    return REPORT_TYPE.BOTH;
  } else if (isMediaReport) {
    return REPORT_TYPE.MEDIA_REPORT;
  } else if (isResultTypeReport) {
    return REPORT_TYPE.RESULT;
  }
};

export const getEffectiveDateOfReport = (resource: DiagnosticReport) => {
  let reportDate = '';
  if (resource?.issued) {
    reportDate = resource.issued;
  } else if (resource?.meta) {
    reportDate = resource?.meta?.lastUpdated || '';
  } else if (resource?.extension?.length) {
    (resource.extension || []).some((extension) => {
      if (extension?.url && extension?.url?.includes(DOCUMENT_DATE)) {
        reportDate = extension?.valueString || '';
        return true;
      }
    });
  }
  if (!reportDate && resource?.effectiveDateTime) {
    reportDate = resource?.effectiveDateTime;
  }
  if (!reportDate && resource?.meta?.lastUpdated) {
    reportDate = resource?.meta?.lastUpdated;
  }
  if (!reportDate && resource?.contained?.length) {
    const item: Observation | undefined = resource.contained.find((item) => item.resourceType === 'Observation' && item.effectiveDateTime) as (Observation | undefined);
    reportDate = item?.effectiveDateTime || '';
  }
  return reportDate;
};

const getIssueDateOfReport = (resource: DiagnosticReport) => {
  let reportDate = '';
  if (resource?.extension?.length) {
    (resource.extension || []).some((extension) => {
      if (extension?.url && extension?.url?.includes(CHART_DATE)) {
        reportDate = extension?.valueString || '';
        return true;
      }
    });
  } else if (resource?.meta) {
    reportDate = resource?.meta?.lastUpdated || '';
  }
  if (!reportDate && resource?.issued) {
    reportDate = resource?.issued;
  }
  return reportDate;
};

const getObservationIds = (selectedReport: IReportAndResourceMap, orders: RequestGroup[]) => {
  const observationIds: {observationId: string, selectedOrder?: RequestGroup, selectedReport?: DiagnosticReport}[] = [];
  (selectedReport.resources || []).forEach((resource) => {
    const selectedReport = resource;
    const selectedOrder = getOrderFromReport(resource, orders);
    (resource.result || []).forEach((result) => {
      if (result?.reference && result?.reference.includes('Observation/')) {
        const referenceArray = result?.reference.split('Observation/');
        if (referenceArray?.length > 1) {
          observationIds.push({
            observationId: referenceArray[1],
            selectedOrder: selectedOrder,
            selectedReport: selectedReport
          });
        }
      }
    });
  });
  return observationIds;
};

const getObservationFromIntegration = async (
  observationIds: {observationId: string, selectedOrder?: RequestGroup, selectedReport?: DiagnosticReport}[],
  selectedReport: IReportAndResourceMap,
) => {
  const response = await getObservations(observationIds.map(item => item.observationId).join(','));
  if (response?.data) {
    const observationResponse: IObservationFhirResponse = response.data;
    const observationFormattedResult: IReportResult[] = getFormattedObservationResult(observationResponse, observationIds);
    return {
      reportName: buildReportName(selectedReport),
      observationList: observationFormattedResult,
    }
  }
  return {
    reportName: '',
    observationList: [],
  }
};

const getWithoutObservationsReportResult = (
  selectedReport: IReportAndResourceMap,
  orders: RequestGroup[],
  ehrConfig?: IAvailableEhr
) => {
  const observationFormattedResult: IReportResult[] = [];
  (selectedReport.resources || []).forEach((resource) => {
    const selectedOrder = getOrderFromReport(resource, orders)
    if (resource.result?.length && resource.contained?.length) {
      // hasMember use case (Health gorilla)
      resource.result.forEach((resultRef) => {
        if (resultRef.reference) {
          updateObservationForHasMember(resultRef, observationFormattedResult, resource, selectedOrder);
        }
      })
    } else {
      // composite use case, contained within contained (Elation)
      (resource?.contained || []).forEach((reportContained: any) => {
        getContainedTypeResult(
          reportContained,
          observationFormattedResult,
          resource,
          selectedOrder,
        );
      });
    }
  });
  return {
    reportName: buildReportName(selectedReport, ehrConfig),
    observationList: observationFormattedResult,
  }
};

const getFormattedObservationResult = (
  observationResponse: IObservationFhirResponse,
  observationIds: {observationId: string, selectedOrder?: RequestGroup, selectedReport?: DiagnosticReport}[],
) => {
  const observationFormattedResult: IReportResult[] = [];
  (observationResponse.entry || []).forEach((entry) => {
    const matchedData = observationIds.find(item => item.observationId == entry?.resource?.id);
    const displayName = getResourceDisplayName(entry.resource);
    const formattedResult: IReportResult = getObservationResultByName(
      observationFormattedResult,
      displayName
    );
    const observationResult: IObservationResult = buildObservationValue(entry);
    observationResult.selectedOrder = matchedData?.selectedOrder;
    observationResult.selectedReport = matchedData?.selectedReport;
    if (formattedResult?.display) {
      const results: IObservationResult[] = formattedResult.results || [];
      results.push(observationResult);
      formattedResult.results = results;
    } else {
      formattedResult.display = displayName;
      formattedResult.observationId = entry?.resource?.id;
      formattedResult.isResultUrl = false;
      formattedResult.showGraph = observationResult.showGraph;
      formattedResult.results = [observationResult];
      observationFormattedResult.push(formattedResult);
    }
  });
  return observationFormattedResult;
};

const buildReportName = (selectedReport: IReportAndResourceMap, ehrConfig?: IAvailableEhr) => {
  let reportName = '';
  if (selectedReport.resourceCode && selectedReport.resourceCode !== selectedReport.display) {
    reportName = `${selectedReport.resourceCode} `;
  }
  else if (ehrConfig?.isElation && !selectedReport?.display) {
    return selectedReport?.resources?.[0]?.category?.[0]?.text + ' ' + getDateStrFromFormatWithTimezone(
      selectedReport.effectiveDateTime || '',
      getCurrentTimeZone(),
      DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
    )
  }
  return reportName.concat(`${selectedReport.display}`);
};

const getObservationResultByName = (
  observationResult: IReportResult[],
  displayName: string
): IReportResult => {
  return (
    (observationResult || []).find((result) => {
      return result.display === displayName;
    }) || ({} as IReportResult)
  );
};

const getResourceDisplayName = (
  selectedResource: IDiagnosticReportResource | any
) => {
  const resource = selectedResource;
  let displayName = '';
  if (resource?.code?.coding?.length) {
    displayName = resource?.code?.coding[0]?.display;
  }
  if (!displayName) {
    displayName = resource?.code?.text;
  }
  return displayName == 'TEXT' ? 'Observation' : displayName;
};

const buildObservationValue = (observationResource: IObservationResource) => {
  const observationResult: IObservationResult = {} as IObservationResult;
  const resource = observationResource?.resource;
  observationResult.effectiveDateTime = resource?.effectiveDateTime;
  observationResult.issued = resource?.issued;
  if (resource && resource?.valueQuantity) {
    observationResult.unit = resource?.valueQuantity?.unit;
    if (
      resource?.valueQuantity?.value &&
      typeof resource?.valueQuantity.value == 'number'
    ) {
      observationResult.value = resource?.valueQuantity.value.toFixed(2);
      observationResult.showGraph = true;
      observationResult.isResultUrl = false;
    }
  }
  if (resource && resource?.valueCodeableConcept) {
    observationResult.showGraph = false;
    observationResult.isResultUrl = false;
    observationResult.value = resource.valueCodeableConcept.text;
  }
  if (
    resource &&
    resource?.referenceRange &&
    resource.referenceRange?.low?.value &&
    resource.referenceRange?.high?.value
  ) {
    observationResult.referenceRange = {
      minValue: resource.referenceRange?.low?.value,
      minValueUnit: resource.referenceRange?.low?.unit,
      maxValue: resource.referenceRange?.high?.value,
      maxValueUnit: resource.referenceRange?.high?.unit,
    };
  }
  return observationResult;
};

const getLoincCodeFromObservation = (observationResource: Observation)=>{
  const extensions = observationResource?.code?.extension || [];
  let loincCode;
  extensions.some((extension)=>{
    if(extension?.url?.includes('/loinc')){
      loincCode = extension?.valueString;
      return loincCode;
    }
  })
  return loincCode;
}

const buildWithoutObservationValue = (
  observationResource: Observation,
  selectedResource: DiagnosticReport
) => {

  const observationResult: IObservationResult = {} as IObservationResult;
  observationResult.loincCode = getLoincCodeFromObservation(observationResource);
  observationResult.effectiveDateTime =
    getEffectiveDateOfReport(selectedResource);
  observationResult.issued = getIssueDateOfReport(selectedResource);
  observationResult.isResultUrl = false;
  if (observationResource?.valueQuantity) {
    const observationValue = observationResource.valueQuantity?.value;
    observationResult.unit = observationResource?.valueQuantity?.unit;
    observationResult.showGraph = true;
    if (observationValue && typeof observationValue == 'number') {
      observationResult.value = observationValue.toFixed(2);
    } else {
      observationResult.value = observationValue;
    }
    if (observationResource.note?.length) {
      observationResult.notes = observationResource.note.map(item => item.text).join('\n');
    }
  } else { // no valueQuantity in the resource
    observationResult.value = observationResource?.note?.map((note) => note.text).join('\n');
  }
  if (observationResource && observationResource?.valueCodeableConcept) {
    observationResult.showGraph = false;
    observationResult.value = observationResource.valueCodeableConcept.text;
  }
  if (observationResource && observationResource?.valueString) {
    observationResult.showGraph = false;
    observationResult.value = observationResource.valueString;
  }
  if (observationResource && observationResource?.referenceRange?.length) {
    observationResult.referenceRange = {
      minValue: observationResource.referenceRange[0]?.low?.value,
      minValueUnit: observationResource.referenceRange[0]?.low?.unit,
      maxValue: observationResource.referenceRange[0]?.high?.value,
      maxValueUnit: observationResource.referenceRange[0]?.high?.unit,
    };
  }
  // FHIR R4 has interpretation as CodeableConcept[] but from caliber for athena we receive string, thus added any
  if (observationResource.interpretation) {
    observationResult.interpretation = observationResource.interpretation as any;
  }

  // if (observationResource && observationResource?.valueAttachment?.contentType) {
  //   observationResult.valueAttachment = observationResource?.valueAttachment;
  // }
  return observationResult;
};

const setAdditionalAttributeForMediaReport = (observationResult: IObservationResult, lastUpdatedReportDate: string, issuedDate: string, type: string, links: string[]) => {
  observationResult.value = links.join(',');
  observationResult.unit = '';
  observationResult.isResultUrl = true;
  observationResult.effectiveDateTime = lastUpdatedReportDate;
  observationResult.issued = issuedDate;
  observationResult.resultType = type;
}

const buildMediaResourceResult = async (
  diagnosticReport: DiagnosticReport,
  selectedOrder: RequestGroup | undefined,
  supportingParams: {
    patientId: string,
    ehrConfig: IAvailableEhr,
    locationId?: string,
  },
  reportCategory?: ReportCategory,
) => {
  let resource = diagnosticReport;
  const { ehrConfig, patientId, locationId } =  supportingParams;
  const attachmentExists = resource.extension?.find(item => item.url === EXTENSION_URLS.attachmentExists)?.valueBoolean;
  const shouldFetchDiagnosticReport =
    (ehrConfig?.isAthena && attachmentExists) ||
    (reportCategory === ReportCategory.IMAGING);
  if (resource.id && shouldFetchDiagnosticReport) {
    const response = await getDiagnosticReportById(resource.id, patientId, locationId, reportCategory);
    if (response?.data?.id) {
      resource = response.data;
    }
  }
  const observationResult: IObservationResult = {} as IObservationResult;
  observationResult.selectedOrder = selectedOrder;
  observationResult.selectedReport = resource;
  const links: string[] = [];
  if (resource?.media?.length) {
    const resultList: {resultUrl: string; resultType: string; title: string, base64Data: string, s3Url?: string}[] = [];
    let index = 0;
    for (const media of (resource.media || [])) {
       // Additional handling for FOLD report
      if (media?.link?.display) {
        links.push(media.link.display);
        const resultUrl = media.link.display;
        resultList.push({
          resultUrl: resultUrl,
          resultType: 'application/pdf',
          title:  `Click here to open report ${index + 1}`,
          base64Data:  '',
          ...(ehrConfig?.isElation && { isFetchMediaFromIntegration: true})
        });
      } else if (media?.link?.type === 'FoldAttachment' && media?.link?.id) {
        const attachmentId = media.link.id;
        try {
          const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
          const service = careStudioInstance.careStudioAxiosService;
          const response = await service.get(`/attachment/${attachmentId}?isPreview=true`);
          if (response.status == 200) {
            const fileData = response.data;
            resultList.push({
              resultUrl: fileData.url,
              s3Url: fileData.url,
              resultType: fileData.type,
              title:  fileData.name,
              base64Data:  '',
            });
          }
        } catch (error) {

        }
      }
      index++;
      if (ehrConfig?.isElation && resultList?.length) {
        break;
      }
    }
    if (resultList?.length) {
      observationResult.isMultiReportResult = resource.media?.length > 1;
      observationResult.resultList = resultList;
    }
    if (resultList?.length || links?.length) {
      const updatedDate = resource?.meta?.lastUpdated || '';
      setAdditionalAttributeForMediaReport(observationResult, updatedDate, updatedDate, 'application/pdf', links);
    }
  }

  if (resource?.contained?.length) {
    (resource.contained || []).forEach((containedResource: any) => {
      if (containedResource?.content?.contentType && SUPPORTED_PRESENTED_FORM.includes(containedResource?.content?.contentType)) {
        links.push(containedResource.content?.data);
      }
    });
    if (links?.length) {
      setAdditionalAttributeForMediaReport(observationResult, resource?.effectiveDateTime || '', resource?.issued || '', 'image/jpeg', links);
    }
  }

  if (resource?.presentedForm?.length) {
    const resultList: {resultUrl: string; resultType: string; title: string, base64Data: string, isFetchMediaFromIntegration: boolean}[] = [];
    (resource.presentedForm || []).forEach((form, index) => {
      if (form?.contentType && SUPPORTED_PRESENTED_FORM.includes(form?.contentType)) {
        const resultUrl = form?.url || form?.data || '';
        links.push(resultUrl);
        resultList.push({
          resultUrl: resultUrl,
          resultType: form.contentType,
          title: form?.title || `View Report ${index + 1}`,
          base64Data: '',
          isFetchMediaFromIntegration: true,
        });
      }
    });
    if (resultList?.length) {
      observationResult.isMultiReportResult = resource?.presentedForm?.length > 1;
      observationResult.resultList = resultList;
    }
    if (resultList?.length || links?.length) {
      setAdditionalAttributeForMediaReport(observationResult, resource?.effectiveDateTime || '', resource?.issued || '', 'application/pdf', links);
    }
  }
  return observationResult;
};

const getMediaReportInDetails = async (
  selectedReport: IReportAndResourceMap,
  orders: RequestGroup[],
  supportingParams: {
    patientId: string,
    ehrConfig: IAvailableEhr,
    locationId?: string,
  },
) => {
  const observationFormattedResult: IReportResult[] = [];
  for (const resource of (selectedReport.resources || [])) {
    const selectedOrder = getOrderFromReport(resource, orders);
    const displayName = getResourceDisplayName(resource);
    const formattedResult: IReportResult = getObservationResultByName(
      observationFormattedResult,
      displayName
    );
    formattedResult.isResultUrl = true;
    formattedResult.showGraph = false;
    if (formattedResult?.display) {
      const existResult = formattedResult.results || [];
      const resultData = await buildMediaResourceResult(resource, selectedOrder, supportingParams, selectedReport.orderType);
      existResult.push(resultData);
      formattedResult.results = existResult;
    } else {
      formattedResult.display = displayName;
      formattedResult.observationId = resource?.id;
      const resultData = await buildMediaResourceResult(resource, selectedOrder, supportingParams, selectedReport.orderType);
      formattedResult.results = [resultData];
      // Check impact
      observationFormattedResult.push(formattedResult);
    }
  };
  return  {
    reportName: buildReportName(selectedReport, supportingParams.ehrConfig),
    observationList: observationFormattedResult,
  }
};

const getContainedTypeResult = (
  reportContained: IReportContained,
  observationFormattedResult: IReportResult[],
  resource: DiagnosticReport,
  selectedOrder: RequestGroup | undefined,
) => {
  (reportContained?.contained || []).forEach((result: any) => {
    formatObservationData(result, observationFormattedResult, resource, selectedOrder);
  });
};

const updateObservationForHasMember = (
  reportContained: Reference,
  observationFormattedResult: IReportResult[],
  resource: DiagnosticReport,
  selectedOrder: RequestGroup | undefined,
) => {
  const refId = reportContained.reference?.replace('Observation/', '');
  const containedItem = resource.contained?.find((containedReport) => containedReport.id == refId) as any;
  if (containedItem) {
    if (containedItem.hasMember?.length) {
      containedItem.hasMember.forEach((member: any) => {
        const refId = member.reference.replace('Observation/', '');
        const observationItem = resource.contained?.find((containedReport) => containedReport.id == refId) as Observation;
        if (observationItem) {
          formatObservationData(observationItem, observationFormattedResult, containedItem, selectedOrder, resource);
        }
      })
    } else {
      formatObservationData(containedItem, observationFormattedResult, resource, selectedOrder);
    }
  }
};

const formatObservationData = (
  result: Observation,
  observationFormattedResult: IReportResult[],
  resource: any,
  selectedOrder: RequestGroup | undefined,
  selectedReport?: DiagnosticReport
) => {
  const displayName = getResourceDisplayName(result);
  const formattedResult: IReportResult = getObservationResultByName(
    observationFormattedResult,
    displayName
  );
  formattedResult.isResultUrl = false;
  const observationResult: IObservationResult = buildWithoutObservationValue(
    result,
    resource,
  );
  observationResult.selectedOrder = selectedOrder;
  observationResult.selectedReport = selectedReport || resource;
  if (formattedResult?.display) {
    const results: IObservationResult[] = formattedResult.results || [];
    results.push(observationResult);
    formattedResult.results = results;
  } else {
    formattedResult.display = displayName;
    formattedResult.observationId = resource?.id;
    formattedResult.showGraph = observationResult.showGraph;
    formattedResult.results = [observationResult];
    observationFormattedResult.push(formattedResult);
  }
}

export const getExtensionByUrl = (extensions: Extension[], url: string): Extension => {
  return (extensions || []).find(extension => {
    return extension?.url && extension?.url === url;
  }) || {} as Extension;
};

export const isReportReviewedByUser = (resource: DiagnosticReport): boolean => {
  const reviewedByExtension: Extension = getExtensionByUrl(resource?.extension || [], EXTENSION_URLS.reviewedBy);
  const reviewedDate: Extension = getExtensionByUrl(resource?.extension || [], EXTENSION_URLS.reviewedDate);
  return reviewedByExtension?.url !== undefined && reviewedDate?.url !== undefined;
}

export const isAllReportViewedBy = (selectedReport: IReportAndResourceMap): boolean => {
  let allReportReviewed = true;
  if (selectedReport?.resources?.length) {
    (selectedReport?.resources || []).some(resource => {
      if (!isReportReviewedByUser(resource)) {
        allReportReviewed = false;
        return true;
      }
    })
  }
  return allReportReviewed;
}

export const getReviewedByReportDetails = (selectedReport: IReportAndResourceMap)  => {
  const reviewedByDetail = {reviewedDate: '', reviewedByUserName: ''};
  if (selectedReport?.resources?.length) {
    (selectedReport?.resources || []).some(resource => {
      if (isReportReviewedByUser(resource)) {
        const reviewedByExtension: Extension = getExtensionByUrl(resource?.extension || [], EXTENSION_URLS.reviewedBy);
        const reviewedDate: Extension = getExtensionByUrl(resource?.extension || [], EXTENSION_URLS.reviewedDate);
        reviewedByDetail.reviewedByUserName = reviewedByExtension?.valueString || '';
        reviewedByDetail.reviewedDate = reviewedDate?.valueString || '';
        return true;
      }
    })
  }
  return reviewedByDetail;
}

export const getBaseOnFieldReference = (resource: DiagnosticReport, references: string[]) => {
  (resource?.basedOn || []).forEach(baseOn => {
     if (baseOn?.reference) {
      references.push(baseOn?.reference);
     }
  });
  return references;
}

export const getReportReferences = (report: IReportAndResourceMap) => {
  const references: string[] = [];
  (report?.resources || []).forEach(resource => {
    getBaseOnFieldReference(resource, references);
  });
  return references;
};

// output e.g. ["ServiceRequest/12sa3123-453fr245-2as345345", "ServiceRequest/123ded123-453fr245-234sws5345"]
export const getServiceRequestsFromOrder = (order: RequestGroup) => {
  const serviceRequests: string[] = [];
  (order.action || []).forEach((item) => {
    if (item.resource?.reference) {
      serviceRequests.push(item.resource?.reference);
    }
  })
  return serviceRequests;
}

export const getDiagnosticReportsForServiceRequest = (serviceReqReferences: string[], reports: IReportAndResourceMap[]) => {
  const reportListMatched: DiagnosticReport[] = [];
  const allReports: DiagnosticReport[] = [];
  reports.forEach((report) => allReports.push(...report.resources));
  serviceReqReferences.forEach((serviceReqReference) => {
    const matchedReport = allReports.find((report) => {
      return report.basedOn?.[0]?.reference == serviceReqReference;
    });
    if (matchedReport) {
      reportListMatched.push(matchedReport);
    }
  })
  return reportListMatched;
}

export const isAllFoldMediaReportReviewed = (reports: DiagnosticReport[]): boolean => {
  let allReportReviewed = false;
  reports.some(resource => {
    if (!isReportReviewedByUser(resource)) {
      allReportReviewed = false;
      return true;
    }
  })
  return allReportReviewed;
}

export const hasFoldAttachment = (report: DiagnosticReport) => {
  return report.media?.some((media) => media.link.type === 'FoldAttachment');
}

export const isObservationValueHigher = (observation: any, value: any) => {
  return (value || value == 0) && !isNaN(value) && (observation?.maxValue || observation?.maxValue == 0) && !isNaN(observation?.maxValue) &&
    parseFloat(value) > parseFloat(observation?.maxValue);
}

export const isObservationValueLower = (observation: any, value: any) => {
  return (value || value == 0) && !isNaN(value) && (observation?.minValue || observation?.minValue == 0) && !isNaN(observation?.minValue) &&
    parseFloat(value) < parseFloat(observation?.minValue);
}

export const isAbnormalValue = (selectedRecord: IObservationResultTable, record: IMediaRecord | undefined, ehrConfig: IAvailableEhr) => {
  if (ehrConfig.isAthena) {
    return record?.interpretation && record?.interpretation !== ObservationInterpretation.normal;
  }
  return isObservationValueHigher(selectedRecord, record?.value) ||
    isObservationValueLower(selectedRecord, record?.value)
}

export const hasReferenceRange = (selectedRecord: IObservationResultTable) => {
  return (selectedRecord?.maxValue || selectedRecord?.maxValue == 0) &&
  (selectedRecord?.minValue || selectedRecord?.minValue == 0);
}

export const canShowUpArrow = (selectedRecord: IObservationResultTable, record: IMediaRecord | undefined, ehrConfig: IAvailableEhr) => {
  // For athena abnormal is decided based on abnormal flag (interpretation), range check is not used
  if (ehrConfig.isAthena) {
    return record?.interpretation && HIGH_INTERPRETATIONS.includes(record.interpretation as ObservationInterpretation);
  }
  return !selectedRecord?.isResultUrl && isObservationValueHigher(selectedRecord, record?.value);
}

export const canShowDownArrow = (selectedRecord: IObservationResultTable, record: IMediaRecord | undefined, ehrConfig: IAvailableEhr) => {
  // For athena abnormal is decided based on abnormal flag (interpretation), range check is not used
  if (ehrConfig.isAthena) {
    return record?.interpretation && LOW_INTERPRETATIONS.includes(record.interpretation as ObservationInterpretation);
  }
  return !selectedRecord?.isResultUrl && isObservationValueLower(selectedRecord, record?.value);
}

export const getInterpretationDisplay = (interpretation?: string) => {
  if (!interpretation) return;
  switch (interpretation) {
    case ObservationInterpretation.normal: return 'Normal';
    case ObservationInterpretation.abnormal: return 'Abnormal';
    case ObservationInterpretation.low: return 'Low';
    case ObservationInterpretation.high: return 'High';
    case ObservationInterpretation.criticalLow: return 'Critical Low';
    case ObservationInterpretation.criticalAbnormal: return 'Critical Abnormal';
    case ObservationInterpretation.criticalHigh: return 'Critical High';
    case ObservationInterpretation.significantChangeUp: return 'Significant Change Up';
    case ObservationInterpretation.significantChangeDown: return 'Significant Change Down';
    case ObservationInterpretation.better: return 'Better';
    case ObservationInterpretation.worse: return 'Worse';
    case ObservationInterpretation.offScaleLow: return 'Off-scale Low';
    case ObservationInterpretation.offScaleHigh: return 'Off-scale High';
    case ObservationInterpretation.susceptible: return 'Susceptible';
    case ObservationInterpretation.resistant: return 'Resistant';
    case ObservationInterpretation.intermediate: return 'Intermediate';
    case ObservationInterpretation.moderatelySusceptible: return 'Moderately Susceptible';
    case ObservationInterpretation.verySusceptible: return 'Very Susceptible';
    case ObservationInterpretation.positive: return 'Positive';
    case ObservationInterpretation.negative: return 'Negative';
    case ObservationInterpretation.delta: return 'Delta';
    case ObservationInterpretation.deltaLow: return 'Delta Low';
    case ObservationInterpretation.deltaHigh: return 'Delta High';
  }
}

export function getBase64(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const frequencyUnitCodes = {
  LAST_MONTH: 'lastMonth',
  LAST_THREE_MONTHS: 'lastThreeMonths',
  LAST_SIX_MONTHS: 'lastSixMonths',
  ALL: 'All'
};

export const getGraphFilterListForLabReports = (intl:any)=>{
  return [
    {
      id: frequencyUnitCodes.ALL,
      value: intl.formatMessage({
        id: frequencyUnitCodes.ALL,
      }),
    },
    {
      id: frequencyUnitCodes.LAST_MONTH,
      value: intl.formatMessage({id: frequencyUnitCodes.LAST_MONTH}),
    },
    {
      id: frequencyUnitCodes.LAST_THREE_MONTHS,
      value: intl.formatMessage({
        id: frequencyUnitCodes.LAST_THREE_MONTHS,
      }),
    },
    {
      id: frequencyUnitCodes.LAST_SIX_MONTHS,
      value: intl.formatMessage({
        id: frequencyUnitCodes.LAST_SIX_MONTHS,
      }),
    },
  ]
}
