import { IConversationTypes } from '../components/RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import {
  CHANNEL_TYPE,
  CHANNEL_TYPE_CODE,
} from '../components/RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {IConversationData} from '../components/RightSideContainer/TeamInbox/Conversations/interfaces';
import {MAIN_MENU_CODES} from '../components/SideMenuBar/SideBarConst';
import {IVideoData} from '../screens/Home/interface';
import {isChannelEmail} from '../utils/commonUtils';
import {GROUP_TYPES} from './StringConst';

export const horizontalMenus = [
  {
    id: '1',
    title: 'Schedule',
    code: MAIN_MENU_CODES.CALENDAR,
    src: {
      size: '12',
      name: 'CreateAppointment',
      img: require('../assets/images/PNGImages/TabsPngIcons/view_schedules.png'),
    },
    backgroundColor: '#EDF4E5',
    textColor: '#5A8629',
  },

  {
    id: '3',
    title: 'Your tasks',
    code: MAIN_MENU_CODES.TASKS,
    src: {
      size: '6',
      name: 'CreateIntakeForms',
      img: require('../assets/images/PNGImages/TabsPngIcons/your_tasks.png'),
    },
    backgroundColor: '#E8E3FF',
    textColor: '#6941C7',
  },
];

export const staticVideoResources: IVideoData[] = [
  {
    id: 1,
    uri: 'https://generalfolddocs.s3.us-east-2.amazonaws.com/User+Workspace.mov',
    thumbnail: 'https://source.unsplash.com/user/c_v_r',
  },
  {
    id: 2,
    uri: 'https://generalfolddocs.s3.us-east-2.amazonaws.com/Admin+Panel.mov',
    thumbnail: 'https://source.unsplash.com/user/c_v_r/1900x800',
  },
];

export const ATTACHMENT_ITEMS_CODE = {
  ATTACHMENTS: 'ATTACHMENTS',
  FORMS: 'FORMS',
  ARTICLES: 'ARTICLES',
  APPOINTMENT_LINK: 'APPOINTMENT_LINK',
  BROWSE_CONTACT: 'BROWSE_CONTACT',
  MEDIA_ATTACHMENTS: 'MEDIA_ATTACHMENTS',
};
export const ATTACHMENT_OPTIONS = [
  {
    id: 1,
    label: 'Browse Attachments',
    code: ATTACHMENT_ITEMS_CODE.ATTACHMENTS,
  },
  {
    id: 2,
    label: 'Browse Forms',
    code: ATTACHMENT_ITEMS_CODE.FORMS,
  },
  {
    id: 3,
    label: 'Browse Articles',
    code: ATTACHMENT_ITEMS_CODE.ARTICLES,
  },
  {
    id: 4,
    label: 'Send Appointment Link',
    code: ATTACHMENT_ITEMS_CODE.APPOINTMENT_LINK,
  },
  {
    id: 5,
    label: 'Browse Media Attachments',
    code: ATTACHMENT_ITEMS_CODE.MEDIA_ATTACHMENTS,
  },
];

export const INTERNAL_ATTACHMENT_OPTIONS = [
  {
    id: 1,
    label: 'Browse Attachments',
    code: ATTACHMENT_ITEMS_CODE.ATTACHMENTS,
  },
  {
    id: 5,
    label: 'Browse Contacts',
    code: ATTACHMENT_ITEMS_CODE.BROWSE_CONTACT,
  },
  {
    id: 6,
    label: 'Browse Media Attachments',
    code: ATTACHMENT_ITEMS_CODE.MEDIA_ATTACHMENTS,
  },
];

export const getAttachmentOption = (conversationData: IConversationData) => {
  const groupTypeCode = conversationData?.groupConversation?.groupType?.code;
  const isEmailInbox = isChannelEmail(
    conversationData?.conversationInbox?.channelType
  );
  if (
    conversationData?.conversationInbox?.channelType ===
    CHANNEL_TYPE.CHANNEL_TWILIO_SMS
  ) {
    const FILTERED_ATTACHMENT_OPTIONS = ATTACHMENT_OPTIONS.filter(
      (conversation) => {
        return conversation.id !== 1;
      }
    );
    return FILTERED_ATTACHMENT_OPTIONS;
  } else if (groupTypeCode === GROUP_TYPES.INTERNAL) {
    return INTERNAL_ATTACHMENT_OPTIONS;
  } else if (isEmailInbox) {
    return ATTACHMENT_OPTIONS.filter(
      (item) => item.code === ATTACHMENT_ITEMS_CODE.ATTACHMENTS
    );
  } else {
    return ATTACHMENT_OPTIONS;
  }
};

export const HEADER_TITLE_TYPES = {
  SINGLE_MAIN_TITLE: 'SINGLE_MAIN_TITLE',
  SINGLE_TITLE: 'SINGLE_TITLE',
  TITLE: 'TITLE',
  SUBTITLE: 'SUBTITLE',
};
export const INBOX_TYPES: InboxType[] = [
  {
    title: 'All',
    tabCode: 'ALL_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_ALL,
    imagePath: require('../assets/images/Conversations/allConversation.png'),
    index: 0,
  },
  {
    title: 'Assigned to me',
    tabCode: 'ASSIGN_TO_ME_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
    imagePath: require('../assets/images/Conversations/assigned_to_me.png'),
    index: 1,
  },
  {
    title: 'Assigned to others',
    tabCode: 'ASSIGN_TO_OTHER_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
    imagePath: require('../assets/images/Conversations/assigned_to_me.png'),
    index: 2,
  },
  {
    title: 'Unassigned',
    tabCode: 'UNASSIGN_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
    imagePath: require('../assets/images/MemberActions/unassign.png'),
    index: 3,
  },
  {
    title: 'Mentions',
    tabCode: 'MENTION_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_MENTION,
    imagePath: require('../assets/images/Conversations/mentions.png'),
    index: 4,
  },
  {
    title: 'Missed Calls',
    tabCode: 'MISSED_CALL_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS,
    imagePath: require('../assets/images/Conversations/missed_call.png'),
    index: 5,
  },
  {
    title: 'Chat',
    tabCode: 'GROUP_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
    imagePath: require('../assets/images/Conversations/messages.png'),
    index: 6,
  },
  {
    title: 'SMS',
    tabCode: 'SMS_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
    imagePath: require('../assets/images/Conversations/sms.png'),
    index: 7,
  },
  {
    title: 'Email',
    tabCode: 'EMAIL_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
    imagePath: require('../assets/images/Conversations/email.png'),
    index: 8,
  },
  {
    title: 'eFax',
    tabCode: 'FAX_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
    imagePath: require('../assets/images/Conversations/efax.png'),
    index: 8,
  },
  // {
  //   title: 'Calls',
  //   tabCode: 'ALL_CALLS',
  //   code: CHANNEL_TYPE_CODE.CALL_LOGS,
  //   imagePath: require('../assets/images/Conversations/call.png'),
  //   index: 9,
  // },
  {
    title: 'Calls',
    tabCode: 'ALL_CALLS',
    code: CHANNEL_TYPE_CODE.ALL_CALLS,
    imagePath: require('../assets/images/Conversations/call.png'),
    index: 9,
  },
  {
    title: 'Archived',
    tabCode: 'ARCHIVED_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
    imagePath: require('../assets/images/Conversations/archived.png'),
    index: 10,
  },
  {
    title: 'Internal Chats',
    tabCode: 'INTERNAL_CHAT',
    code: CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
    imagePath: require('../assets/images/Conversations/internal.png'),
    index: 11,
  },
];

export interface InboxType {
  tabCode?: IConversationTypes;
  title: string;
  code: string;
  imagePath?: string;
  index?: number;
  count?: number
}

export const countryListArr = [ 'US', 'America' ];

export const memberConsent = 'memberConsent';

export const PATIENT_CARE_JOURNEY_OPERATION_CODES = {
  CREATE: 'CREATE',
  ACTIVATE: 'ACTIVATE',
  PAUSE: 'PAUSE',
  STOP: 'STOP',
  RESTART: 'RESTART',
  UPDATE_DATA: 'UPDATE_DATA',
  DELETE: 'DELETE',
  COMPLETE: 'COMPLETE',
  GOAL_STATUS_CHANGE: 'GOAL_STATUS_CHANGE'
};

export const INBOX_TYPE_TAB_CODE = {
  ALL_CHAT: 'ALL_CHAT',
  EMAIL_CHAT: "EMAIL_CHAT"
}
