import { useLazyQuery, useQuery } from "@apollo/client";
import { Divider, HStack, Text, useToast, View, VStack } from "native-base";
import React ,{ useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PHONE_NUMBER_MASK } from "../../../../constants";
import { CONFIG_CODES } from "../../../../constants/AccountConfigConst";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import { MLOV_CATEGORY, USER_PREFERENCE_CODE, USER_ROLE_CODES } from "../../../../constants/MlovConst";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import FormsQueries, { GET_FORM_BY_CODE } from "../../../../services/Forms/FormsQueries";
import { GET_USERS_FOR_CALENDAR_WITH_LOCATION_AND_CONTACT_DETAILS } from "../../../../services/User/UserQueries";
import { getAccountUUID, getConfigDataFromCode, getUserUUID, isLoggedInUserHealthCoach, isPhysicianRole, numericStringMask } from "../../../../utils/commonUtils";
import { showToast, ToastType } from "../../../../utils/commonViewUtils";
import { getMlovIdFromCode, getMlovListFromCategory } from "../../../../utils/mlovUtils";
import { IThemeAttachments } from "../../../BodyContainer/interface";
import BrandingLogoWrapper from "../../../common/BrandingLogoWrapper/BrandingLogoWrapper";
import TimeLineSkeletonLoader from "../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader";
import { getAdminAppUrl, getPatientAppUrl } from "../../../RightSideContainer/ContentManagement/ContentManagementUtils";
import { getDefaultComponent } from "../../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils";
import { IDefaultFormTheme } from "../../../RightSideContainer/Forms/interfaces";
import { ICareTimelineProps, ICareTimelineViewProps, IPatientNoteCardProps, IPatientNoteOperationProps } from "../interfaces";
import NoNotes from "../PatientNotes/components/NoNotes";
import { SOAP_NOTE_FORM_CODE } from "../PatientNotes/constants";
import { getEhrConfig, getEhrWiseCareTimeCategory, getEhrWiseNoteDefaultConfig, getUserDetailByUserUuid } from "./CareTimelineUtils";
import { CareTimelineView } from "./CareTimelineView";
import { PatientDemographicPrintView } from "./PatientDemographicPrintView";
import { getResourceAbilities } from "../../../../utils/capabilityUtils";
import { FHIR_RESOURCE } from "../../../../constants/FhirConstant";


export const CareTimeline = (props: ICareTimelineProps) => {
  const {personData, unFormattedContactData, orderTimelineNoteData, goBackHandler} = props;
  const toast = useToast();
  const userId = getUserUUID();
  const accountUUID = getAccountUUID();
  const isPhysician = isPhysicianRole();
  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = commonData.ehrCapabilities || [];
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  );
  const currentEHR = ehrCapabilities?.length ? ehrCapabilities?.[0]?.ehrName : '';
  const ehrConfig = getEhrConfig(currentEHR);
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.DEFAULT_FORM
  );
  const [careTimelineState, setCareTimelineDetail] = useState<ICareTimelineViewProps>({} as ICareTimelineViewProps);
  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined  = {} as IDefaultFormTheme;

  // Account Detail
  const accountData: any = commonData.accountSubdomain || {};
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const tempThemeAdditionalData: string =
    accountThemeConfig.additionalAttributes || '{}';
  const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
    tempThemeAdditionalData
  );
  const subdomainMap: any = commonData?.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  const logo = finalThemeAdditionalAttributes?.attachment?.logo?.url;
  const accountName = accountData.accountToSubdomain?.name;

  if (defaultThemeConfig?.accountConfigurations && defaultThemeConfig?.accountConfigurations?.length) {
    configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue){
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const renderPatientDemographics = () => {
    return (
      <PatientDemographicPrintView
        personData={personData}
        unFormattedContactData={unFormattedContactData}
      />
    );
  };

  const intl = useIntl();

  const renderPatientDemographicsHeader = () => {
    return (
      <VStack>
        <Divider my={2} backgroundColor={'black'} />
        <Text fontWeight={600} size={'lgMedium'}>
          {personData.name}
        </Text>
        <HStack flex={1}>
          <VStack flex={5} alignItems="flex-start">
            {personData?.phone && (
              <Text fontSize={16}>{`${intl.formatMessage({id: 'phone'})} : ${
                personData?.phone &&
                numericStringMask(personData?.phone || '', PHONE_NUMBER_MASK)
              }`}</Text>
            )}
            {personData?.email && (
              <Text fontSize={16}>{`${intl.formatMessage({id: 'email'})} : ${
                personData?.email
              }`}</Text>
            )}
          </VStack>
          {/* <VStack flex={5} alignItems="flex-end">
            <Text fontSize={16}>{`${personData?.line1}${
              personData?.line2 && ','
            }`}</Text>
            <Text fontSize={16}>{`${personData?.line2}`}</Text>
          </VStack> */}
        </HStack>
        <Divider my={2} backgroundColor={'black'} />
      </VStack>
    );
  };

  const [getAccountUserLocation] = useLazyQuery(
    GET_USERS_FOR_CALENDAR_WITH_LOCATION_AND_CONTACT_DETAILS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async (data: any) => {
        const accountUserList = data?.users || [];
        const currentUserData = getUserDetailByUserUuid(data?.users || [], userId);
        const noteConfig = {
          flag: getEhrWiseNoteDefaultConfig(ehrConfig,{
            foldVisitNoteWithEncountersEnabled,
            contextData: commonData
          }, currentUserData?.externalUserId),
        };
        const timelineCategories = getEhrWiseCareTimeCategory(ehrConfig);
        const headerResponse = await getDefaultComponent({type: 'HEADER'});
        let headerElem = headerResponse.data.length > 0 ? headerResponse.data[0]?.attributes?.bodyHtml : undefined;
        const footerElem = renderPatientDemographics();
        if (!headerElem) {
          headerElem = renderDefaultThemeHeader();
        } else {
          if (headerElem.includes('{{global')) {
            headerElem = headerElem.replace('{{global.accountLogo}}', logo);
            headerElem = headerElem.replace('{{global.accountName}}', accountName);
            headerElem = headerElem.replace('{{global.clinicName}}', accountName);
            headerElem = headerElem.replace('{{global.accountAdminUrl}}', getAdminAppUrl(subdomainName));
            headerElem = headerElem.replace('{{global.accountPatientAppUrl}}', getPatientAppUrl(subdomainName));
          }
        }

        headerElem = [ headerElem || <></> ];

        headerElem.push(renderPatientDemographicsHeader());

        setCareTimelineDetail(prev => {
          return {
            ...prev,
            loading: false,
            accountUserList,
            ehrConfig,
            ehrCapabilities,
            currentUserData,
            noteConfig,
            timelineCategories,
            header: headerElem,
            footer: footerElem,
          }
        });
      },
      onError: (error) => {
        setCareTimelineDetail(prev => {
          return {
            ...prev,
            loading: false,
          }
        });
        showToast(toast, 'Facing issue while getting user detail. Please check and try again.', ToastType.error);
      },
    }
  );


  const {loading: soapNoteLoading} =  useQuery(GET_FORM_BY_CODE, {
    // HERE we dont need to make this call if tenant is elation as we dont provide this feature to elation
    skip: ehrConfig?.isElation,
    variables: {
      code: SOAP_NOTE_FORM_CODE,
    },
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (data) => {
      if (data?.form?.id) {
        setCareTimelineDetail(prev => {
          return {
            ...prev,
            soapNote: {
              id: data.form.id,
              name: data.form.name
            }
          };
        });
      }
    },
    onError: (error) => {

      showToast(toast, 'Facing issue while getting soap note detail. Please try again.', ToastType.error, 2000);
      setCareTimelineDetail((prev) => ({
        ...prev,
        loading: false,
      }));
    },
  });

  const {loading: defaultNotLoading} = useQuery(
    FormsQueries.GET_DEFAULT_FORM,
    {
      // HERE we dont need to make this call if tenant is elation as we dont provide this feature to elation
      skip: soapNoteLoading || ehrConfig?.isElation,
      fetchPolicy: 'no-cache',
      variables: {
        userId: userId,
        userPreferencesTypeId: userPreferenceId,
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (data) => {
        let defaultForm =
          data?.userPreferences.length > 0 && data?.userPreferences[data?.userPreferences.length - 1]?.preferencesJson;
        const id = data?.userPreferences[data?.userPreferences.length - 1]?.id;
        if (defaultForm && id) {
          defaultForm = JSON.parse(defaultForm);
          setCareTimelineDetail(prev => {
            return {
              ...prev,
              defaultTemplate: {formId: defaultForm.defaultForm, id}
            };
          });
        } else {
          setCareTimelineDetail(prev => {
            return {
              ...prev,
              defaultTemplate: {formId: prev.soapNote?.id, id: ''}
            };
          });
       }
      },
    }
  );

  useEffect(() => {
    setCareTimelineDetail(prev => {
      return {
        ...prev,
        loading: true
      }
    });
    getAccountUserLocation({
      variables: {
        accountId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
  }, []);

  const renderDefaultThemeHeader = (): JSX.Element => {
    return (
      <View backgroundColor={formThemeConfig?.topBar?.backgroundColor}>
        <View marginY={2}>
          <BrandingLogoWrapper
            accountNameTextColor={formThemeConfig?.accountName?.color}
            accountLogoPosition={formThemeConfig?.accountLogo?.position}
            accountNamePosition={formThemeConfig?.accountName?.position}
            isFromPrint={true}
          />
        </View>
      </View>
    );
  }


  return (
    <>
      {(careTimelineState.loading || defaultNotLoading) && (
        <TimeLineSkeletonLoader />
      )}
      {!defaultNotLoading && !careTimelineState?.loading && !careTimelineState.timelineCategories?.length && (
        <NoNotes title="noTimelineForMemberMsg" />
      )}
      {!defaultNotLoading && !careTimelineState?.loading && careTimelineState.timelineCategories?.length > 0 && (
        <CareTimelineView
          navigation={props?.navigation}
          ehrConfig={careTimelineState.ehrConfig}
          noteConfig={careTimelineState.noteConfig}
          timelineCategories={careTimelineState.timelineCategories}
          ehrCapabilities={careTimelineState.ehrCapabilities}
          accountUserList={careTimelineState.accountUserList}
          currentUserData={careTimelineState.currentUserData}
          personData={personData}
          unFormattedContactData={props?.unFormattedContactData}
          defaultTemplate={careTimelineState.defaultTemplate}
          header={careTimelineState.header}
          footer={careTimelineState.footer}
          soapNote={careTimelineState?.soapNote}
          orderTimelineNoteData={orderTimelineNoteData || {} as IPatientNoteCardProps}
          goBackHandler={goBackHandler ? goBackHandler : undefined}
        />
      )}
    </>
  )
};
