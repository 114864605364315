export const EMAIL_INBOX_USER_CODE = {
  ALL: 'ALL',
  PATIENT_AND_LEAD: 'PATIENT_AND_LEAD',
  OTHER: 'OTHER'
}

export const EMAIL_INBOX_USER_VIEW = [
  {
    code: EMAIL_INBOX_USER_CODE.ALL,
    title: 'All (includes Patients, Leads and Contacts & others)'
  },
  {
    code: EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD,
    title: 'Only Patients, Leads & Contacts'
  },
  {
    code: EMAIL_INBOX_USER_CODE.OTHER,
    title: 'Only Others'
  }
]