import { View, VStack } from 'native-base';
import type { RouteObject } from 'react-router-dom';
import { AppDashboardView } from '../components/common/AppDashboard';
import { NoRouteMatch } from '../components/common/NoRouteMatch';
import TitleSubtitleView from '../components/common/TitleSubtitleView/TitleSubtitleView';
import ZoomView from '../components/common/ZoomView/ZoomView';
import { PersonOmniView } from '../components/PersonOmniView/PersonOmniView';
import AllContactsView from '../components/RightSideContainer/AllContactsView/AllContactsView';
import AllLeadsView from '../components/RightSideContainer/AllContactsView/AllLeadsView';
//import {AppointmentTypes} from '../components/RightSideContainer/AccountSettings';
import Analytics from '../components/RightSideContainer/BrazilCodeMR/pages/analytics';
import Opportunity from '../components/RightSideContainer/BrazilCodeMR/pages/Opportunity';
import OpportunityCreation from '../components/RightSideContainer/BrazilCodeMR/pages/OpportunityCreation';
import OpportunityDetails from '../components/RightSideContainer/BrazilCodeMR/pages/OpportunityDetails';
import { CloudTelephony } from '../components/RightSideContainer/CloudTelephony';
import { Segments } from '../components/RightSideContainer/Contacts';
import { TagsMenu } from '../components/RightSideContainer/Contacts/Tags/TagsMenu';
import CampaignManagementMainView from '../components/RightSideContainer/ContentManagement/CampaignManagement/CampaignManagementMainView';
// import MediaLibraryView from '../components/RightSideContainer/ContentManagement/MediaLibrary/MediaLibraryView';
import CampaignManagementSegmentView from '../components/RightSideContainer/ContentManagement/CampaignManagement/CampaignManagementSegmentView';
import GroupDetailMainView from '../components/RightSideContainer/ContentManagement/CampaignManagement/GroupDetails/GroupDetailMainView';
import { AddOrUpdateEmailComponent } from '../components/RightSideContainer/ContentManagement/EmailComponents/AddOrUpdateEmailComponent';
import { EmailTemplates } from '../components/RightSideContainer/ContentManagement/EmailTemplates';
import {
  EmailTemplateCreator,
  EmailTemplateEditor
} from '../components/RightSideContainer/ContentManagement/EmailTemplates/CreateOrEditTemplate';
import DefaultPageNavigation from '../components/RightSideContainer/DefaultPageNavigation/DefaultPageNavigation';
import EmployerMainView from '../components/RightSideContainer/EmployerManagement/EmployerMainView/EmployerMainView';
import { JourneysOfCare } from '../components/RightSideContainer/Journeys';
import AssignCareJourneyWorkflow from '../components/RightSideContainer/Journeys/JourneysOfCare/AssignCareJourneyWorkflow/AssignCareJourneyWorkflow';
import Logs from '../components/RightSideContainer/Logs/Logs';
import { Calendar } from '../components/RightSideContainer/Management';
import { Campaign } from '../components/RightSideContainer/Marketing';
import { MembersManagement } from '../components/RightSideContainer/MembersManagement';
import PrintPreviewDownload from '../components/RightSideContainer/PrintPreview/PrintPreviewDownload';
import {
  SalesPipeline
} from '../components/RightSideContainer/Sales';
import MembershipOrderDetailView from '../components/RightSideContainer/Sales/ProductsAndServices/Orders/OrderDetailView/MembershipOrderDetailView/MembershipOrderDetailView';
import Setting from '../components/RightSideContainer/Settings/Setting';
import { Knowledgebase, Tickets } from '../components/RightSideContainer/Support';
import { Channels, Skills } from '../components/RightSideContainer/TeamInbox';
import CallLogsMainView from '../components/RightSideContainer/TeamInbox/CallLogs/CallLogsMainView';
import { IntegrationLanding } from '../components/RightSideContainer/TeamInbox/Integrations/IntegrationLanding/IntegrationLanding';
import { filterRoutByUserRolePermission } from '../components/RightSideContainer/UserAccess/UserAccessPermission';
import UserSettings from '../components/RightSideContainer/UserAccess/UserSettings';
import CalendarView from '../components/RightSideContainer/Workbenches/CalendarView/CalendarView';
import { AddOrUpdateWorkflow } from '../components/RightSideContainer/Workflow';
import WorkflowAudit from '../components/RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowAudit';
import WorkflowAuditGraph from '../components/RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowAuditGraph';
import { PERSON_TYPES } from '../constants';
import { LockScreen } from '../screens/MainScreen/LockScreen';
import LoginScreen from '../screens/MainScreen/LoginScreen';
import { commonBodyContentContainer } from '../styles/CommonBodyContentStyle';
import { isChildAccount, isMasterAccount, IS_FEATURE_FLAG_ENABLED } from '../utils/commonUtils';
import { JourneyAnalytics } from '../components/RightSideContainer/Journeys/Analytics';
import { JourneyContactList } from '../components/RightSideContainer/Journeys/JourneyContactList';
import JourneyAnalyticsView from '../screens/JourneyAnalyticsView/JourneyAnalyticsView';
import {isAccountConfigEnabled} from '../utils/configUtils';
import {CONFIG_CODES} from '../constants/AccountConfigConst';
import ConversationSidebarV2 from '../components/RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarV2';
import TaskAnalyticsView from '../components/common/CareDashboard/TaskAnalytics/TaskAnalyticsView';
import { MIDDLE_CONTAINER_TABS } from '../components/PersonOmniView/MiddleContainer/MiddleContainerConst';
import UserTaskManager from '../components/TaskModule/UserTaskManager/UserTaskManager';
import ImportContactLogView from '../components/RightSideContainer/Contacts/ImportLog/ImportContactLogView';
import SwitchAccount from '../screens/MainScreen/SwitchAccount/SwitchAccount';
export const getBusinessStudioRoutes = (): RouteObject[] => {
  const isMasterAccountFlag = isMasterAccount();
  const isChildAccountFlag = isChildAccount()
  const allRoute: RouteObject[] = [
    {
      path: '/',
      element: <DefaultPageNavigation />,
    },
    {
      path: '/switch_account',
      element: <SwitchAccount />
    },
    {
      path: '/download-report',
      element: <PrintPreviewDownload />,
    },
    // {
    //   path: '/home',
    //   element: <AppInfoView />,
    // },
    {
      path: '/home',
      element: <AppDashboardView />,
    },
    {
      path: '/login',
      element: <LoginScreen />,
    },
    {
      path: '/workflow',
      element: <LoginScreen />,
    },
    {
      path: '/admin/patients/:tabName',
      element: <Setting />,
    },
    {
      path: '/admin/setting',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/all',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/patients',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/communication',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/other',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/microAutomation',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/schedule',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/forms',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/journey',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/labs',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/order',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/databaseTrigger',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/note',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/patient-case',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/imaging-report',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/patient-referral',
      element: <Setting />,
    },
    {
      path: '/admin/workflow/care-plan',
      element: <Setting />,
    },
    {
      path: '/admin',
      element: <DefaultPageNavigation />,
    },
    {
      path: '/admin/commerce/journeys/:journeyId/members',
      element: (
        <View>
          <View style={{backgroundColor: '#fff'}}>
            <TitleSubtitleView
            />
          </View>
          <View>
            <VStack style={[commonBodyContentContainer.pageContentContainer]}>
             <JourneyContactList />
            </VStack>
          </View>
        </View>
      ),
    },
    {
      path: '/admin/commerce/journeys/:journeyId/analytics',
      element: <JourneyAnalyticsView />
    },
    {
      path: '/inbox/:tabName',
      element: <ConversationSidebarV2 />,
    },
    // {
    //   path: '/members',
    //   element: <Members />,
    // },
    // {
    //   path: '/payments',
    //   element: <Orders />,
    // },
    {
      path: '/admin/forms/:tabName',
      element: <Setting />,
    },
    {
      path: '/admin/forms/create',
      element: <Setting />,
    },
    {path: '/admin/:tabName/paymentSetup', element: <AddOrUpdateWorkflow />},
    {
      path: '/admin/forms/update',
      element: <Setting />,
    },
    {
      path: '/admin/forms/responses',
      element: <Setting />,
    },

    {
      path: '/admin/:tabName/paymentSetup',
      element: <AddOrUpdateWorkflow />,
    },
    {
      path: '/admin/:tabName/automation/execution/audit/:workflowId',
      element: <WorkflowAudit />,
    },
    {
      path: '/admin/:tabName/:tabType/automation/execution/audit/:workflowId',
      element: <WorkflowAudit />,
    },
    {
      path: '/admin/:tabName/:tabType/automation/execution/audit/:workflowId/graph',
      element: <WorkflowAuditGraph />,
    },
    {
      path: '/admin/:tabName/automation/execution/audit/:workflowId/graph',
      element: <WorkflowAuditGraph />,
    },
    {
      path: '/admin/:tabName/automation/create',
      element: <AddOrUpdateWorkflow />,
    },

    {
      path: '/admin/:tabName/automation/update/:workflowId',
      element: <AddOrUpdateWorkflow />,
    },
    {
      path: '/admin/:tabName/:tabType/automation/update/:workflowId',
      element: <AddOrUpdateWorkflow />,
    },

    {
      path: '/admin/:tabName/:tabType/automation/create',
      element: <AddOrUpdateWorkflow />,
    },
    {
      path: '/admin/:tabName/ivr/create',
      element: <AddOrUpdateWorkflow />,
    },
    {
      path: '/admin/:tabName/number/create',
      element: <AddOrUpdateWorkflow />,
    },
    {
      path: '/admin/:tabName/ivr/update/:workflowId',
      element: <AddOrUpdateWorkflow />,
    },
    {
      path: '/admin/campaignManager',
      element: <Campaign />,
    },
    {
      path: '/admin/tagManagement',
      element: <TagsMenu />,
    },
    {
      path: '/admin/contentManagement',
      element: <Setting />,
    },
    {
      path: '/admin/communication/:tabName',
      element: <Setting />,
    },
    {
      path: '/admin/communication/ivr',
      element: <Setting />,
    },
    {
      path: '/admin/communication/communication_types',
      element: <Setting />,
    },
    {
      path: '/admin/communication/video_call',
      element: <Setting />,
    },
    {
      path: '/admin/communication/number',
      element: <Setting />,
    },
    {
      path: '/admin/communication/inbox/create',
      element: <Setting />,
    },
    {
      path: '/admin/communication/inbox/edit/:channel_id/:id',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/emailTemplates',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/mediaLibrary',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/patientEducation',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/llmTemplates',
      element: <Setting />,
    },
    {
      path: '/campaignManagement/:type',
      element: <CampaignManagementMainView />,
    },
    {
      path: '/group',
      element: <CampaignManagementSegmentView />,
    },
    {
      path: '/campaignManagement',
      element: <CampaignManagementMainView />,
    },
    {
      path: '/campaignManagement/active',
      element: <CampaignManagementMainView />,
    },
    {
      path: '/campaignManagement/draft',
      element: <CampaignManagementMainView />,
    },
    {
      path: '/campaignManagement/ended',
      element: <CampaignManagementMainView />,
    },
    {
      path: '/call-logs',
      element: <CallLogsMainView />,
    },
    {
      path: '/call-logs/allCalls',
      element: <CallLogsMainView />,
    },
    {
      path: '/call-logs/incoming',
      element: <CallLogsMainView />,
    },
    {
      path: '/call-logs/outgoing',
      element: <CallLogsMainView />,
    },
    {
      path: '/call-logs/missed',
      element: <CallLogsMainView />,
    },
    {
      path: '/admin/contentManagement/pushNotifications',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/smsTemplates',
      element: <Setting />,
    },
    {
      path: '/members',
      element: <AllContactsView />,
    },
    // {
    //   path: '/members/:contactType',
    //   element: <AllContactsView />,
    // },
    {
      path: '/members/prospect',
      element: <AllLeadsView />,
    },
    {
      path: '/members/lead_groups',
      element: <AllLeadsView />,
    },
    {
      path: '/members/importSummary',
      element: <AllLeadsView />,
    },
    {
      path: '/members/patient',
      element: <AllContactsView />,
    },
    {
      path: '/members/myPatient',
      element: <AllContactsView />,
    },
    {
      path: '/members/groups',
      element: <AllContactsView />,
    },
    {
      path: '/members/groups/:context/:id',
      element: <GroupDetailMainView />,
    },
    {
      path: '/members/lead_groups/:id',
      element: <GroupDetailMainView />,
    },
    {
      path: '/suggestionRules/rule/:context/:id',
      element: <GroupDetailMainView />,
    },
    {
      path: '/members/employers',
      element: <AllContactsView />,
    },
    // {
    //   path: '/members/supporting-memeber',
    //   element: <AllMembersView />,
    // },
    {
      path: '/channels',
      element: <Channels />,
    },
    {
      path: '/integrations',
      element: <IntegrationLanding showTableTopBar={true} />,
    },
    {
      path: '/skills',
      element: <Skills />,
    },
    // {
    //   path: '/responses',
    //   element: <Responses />,
    // },
    {
      path: '/admin/account/locations',
      // element: <TeamMembersView />,
      element: <Setting />,
    },

    {
      path: '/admin/account/logs',
      // element: <TeamMembersView />,
      element: <Setting />,
    },
    {
      path: '/admin/account/holidays',
      element: <Setting />,
    },
    {
      path: '/admin/account/mergedOrDelayed',
      element: <Setting />,
    },
    {
      path: '/admin/account/phoneWhiteList',
      element: <Setting />,
    },
    {
      path: '/admin/account/emailWhiteList',
      element: <Setting />,
    },
    {
      path: '/admin/invoice',
      element: <Setting />,
    },
    {
      path: '/segments',
      element: <Segments />,
    },
    {
      path: '/salesPipeline',
      element: <SalesPipeline />,
    },
    {
      path: '/tickets',
      element: <Tickets />,
    },
    {
      path: '/knowledgebase',
      element: <Knowledgebase />,
    },
    // {
    //   path: '/reports',
    //   element: <Reports />,
    // },
    {
      path: '/calendar',
      element: <Calendar />,
    },
    {
      path: '/admin/journey',
      element: <JourneysOfCare />,
    },
    //-----Products--------
    {
      path: '/admin/commerce/:type',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/:tabName',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/orders/payment/:number',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/product/:id',
      element: <Setting />,
    },
    // {
    //   path: 'admin/commerce/:type/orders',
    //   element: <ProductsAndServices />,
    // },
    {
      path: '/admin/commerce/:type/product/createWidget',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/taxonomy/:taxonmoyId',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/product/:id/journey/create',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/product/:id/journey/:careJourneyId',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/product/:id/journey/create',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/product/:id/journey/:careJourneyId/clone/:clone',
      element: <Setting />,
    },
    {
      path: '/admin/commerce/:type/product/:id/journey/:careJourneyId',
      element: <Setting />,
    },
    {
      path: '/admin/schedule/:tabName',
      element: <Setting />,
    },
    {
      path: '/members/prospect/:id',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.LEAD} />
      ),
    },
    {
      path: '/members/:memberType',
      element: <AllLeadsView />,
    },
    {
      path: '/members/supporting-memeber/:id',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.VISITOR} />
      ),
    },
    {
      path: '/members/patient/:id',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} />
      ),
    },
    {
      path: '/members/patient/:patientId/product/:id/assignJourney/:careJourneyId',
      element: (
        <AssignCareJourneyWorkflow
          personData={{}}
          personType={PERSON_TYPES.CUSTOMER}
        />
      ),
    },
    {
      path: '/members/patient/:id/notes/:noteDocumentReferenceId',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.NOTES}/>
      ),
    },
    {
      path: '/members/patient/:id/notes',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.NOTES}/>
      ),
    },
    {
      path: '/members/patient/:id/timeline',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.TIMELINE}/>
      ),
    },
    {
      path: '/members/patient/:id/journeys',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.JOURNEYS}/>
      ),
    },
    {
      path: '/members/patient/:id/documents',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.DOCUMENTS}/>
      ),
    },
    {
      path: '/members/patient/:id/formResponses',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.FORM_RESPONSES}/>
      ),
    },
    {
      path: '/members/patient/:id/orders',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.ORDERS}/>
      ),
    },
    {
      path: '/members/patient/:id/carePlan',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} tabCode={MIDDLE_CONTAINER_TABS.CARE_PLAN}/>
      ),
    },
    {
      path: 'emailTemplates',
      element: <EmailTemplates />,
    },
    // {
    //   path: 'pushNotifications',
    //   element: <PushNotifications />,
    // },
    {
      path: '/admin/contentManagement/emailTemplates/editor/:id',
      element: <EmailTemplateEditor />,
    },
    {
      path: '/admin/contentManagement/emailComponent/edit/:id',
      element: <AddOrUpdateEmailComponent />,
    },
    {
      path: '/admin/contentManagement/emailComponent/create',
      element: <AddOrUpdateEmailComponent />,
    },
    {
      path: '/admin/contentManagement/emailTemplates/create',
      element: <EmailTemplateCreator />,
    },
    // {
    //   path: '/employers',
    //   // element: <EmployerManagement />,
    // },
    {
      path: '/employees/:id',
      element: <MembersManagement
      callBackToParent={(data: any) => {
      }}
      />,
    },
    {
      path: '/opportunities',
      element: <Opportunity />,
    },
    {
      path: '/opportunities/:opportunityId/view',
      element: <OpportunityDetails />,
    },
    {
      path: '/opportunities/automation',
      element: <OpportunityDetails />,
    },
    {
      path: '/opportunities/new',
      element: <OpportunityCreation />,
    },
    {
      path: '/employers/members/patient/:id',
      element: (
        <PersonOmniView personData={{}} personType={PERSON_TYPES.CUSTOMER} />
      ),
    },
    {
      path: '/admin/contentManagement/emailTemplates/html',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/emailComponent',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/emailTemplates/html/:id',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/patientEducation/create',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/patientEducation/:id',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/forms',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/forms/create',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/forms/update',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/forms/analytics',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/forms/responses',
      element: <Setting />,
    },
    {
      path: '/admin/contentManagement/forms/pending',
      element: <Setting />
    },
    {
      path: '/admin/contentManagement/cannedResponse',
      element: <Setting />,
    },
    {
      path: '*',
      element: <NoRouteMatch />,
    },
    {
      path: '/admin/userSettings/:tabName',
      element: <UserSettings />,
    },
    {
      path: '/start_meet/:token',
      element: <ZoomView />,
    },
    {
      path: '/leave_meet/:is_end_meet',
      element: <ZoomView />,
    },
    {
      path: '/admin/webWidgets',
      element: <Setting />,
    },
    {
      path: '/admin/integration/config/:tabName',
      element: <Setting />,
    },
    {
      path: '/admin/integration/config',
      element: <Setting />,
    },
    {
      path: '/admin/virtualCallCenter/:tabName',
      element: <CloudTelephony />,
    },
    {
      path: '/admin/commerce/:type/order/:id',
      element: <Setting />,
    },
    {
      path: '/admin/logs',
      element: <Logs />,
    },
    {
      path: '/admin/logs/audit',
      element: <Logs />,
    },
    {
      path: '/lockScreen',
      element: <LockScreen />,
    },
    {
      path: '/admin/CareProgramBilling/billable',
      element: <Setting />,
    },
    {
      path: '/admin/CareProgramBilling/billingHistory',
      element: <Setting />,
    },
    {
      path: '/admin/wearables/accessConfiguration',
      element: <Setting />,
    },
  ];

  if (!isMasterAccountFlag) {
    const taskAndCalenderRoutes = [
      {
        path: '/schedule',
        element: <CalendarView />,
      },
      {
        path: '/tasks/:tabName',
        element: (
          <View>
            <View style={{backgroundColor: '#fff'}}>
              <TitleSubtitleView
                titleLabelId="dailyTasks"
                subtitleLabelId="manageDailyTasks"
              />
            </View>
            <View>
              <VStack style={[commonBodyContentContainer.pageContentContainer]}>
                <UserTaskManager />
              </VStack>
            </View>
          </View>
        ),
      },
      {
        path: '/tasks/analytics',
        element: (
          <View>
          <View style={{backgroundColor: '#fff'}}>
            <TitleSubtitleView
              titleLabelId="dailyTasks"
              subtitleLabelId="manageDailyTasks"
            />
          </View>
          <View>
            <VStack style={[commonBodyContentContainer.pageContentContainer]}>
            <TaskAnalyticsView/>
            </VStack>
          </View>
        </View>
        ),
      },
      {
        path: '/tasks/:taskId/comment/:commentId',
        element: (
          <View>
            <View style={{backgroundColor: '#fff'}}>
              <TitleSubtitleView
                titleLabelId="dailyTasks"
                subtitleLabelId="manageDailyTasks"
              />
            </View>
            <View>
              <VStack style={[commonBodyContentContainer.pageContentContainer]}>
                <UserTaskManager />
              </VStack>
            </View>
          </View>
        ),
      },
      {
        path: '/admin/taskManagement/:tabName',
        element: <Setting />,
      },
      {
        path: '/admin/workflow/tasks',
        element: <Setting />,
      },
    ]
    allRoute.push(...taskAndCalenderRoutes)
  }

  if (!isChildAccountFlag) {
    const masterAccountUserManagment = [
      {
        path: '/admin/account/accessControl',
        // element: <TeamMembersView />,
        element: <Setting />,

      },
      {
        path: '/admin/account/teamMembers',
        // element: <TeamMembersView />,
        element: <Setting />,
      },
    ]
    allRoute.push(...masterAccountUserManagment);
  }
  if (!isAccountConfigEnabled(CONFIG_CODES.DISABLE_ANALYTICS) &&
      isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API)) {
    const costTemplateRoute = [
      {
        path: '/admin/costTemplate/:tabName',
        element: <Setting />,
      },
      {
        path: '/admin/costTemplate/:tabName/:costType/detail/:id',
        element: <Setting />,
      },
    ];
    allRoute.push(...costTemplateRoute);

  }
  if (!isAccountConfigEnabled(CONFIG_CODES.DISABLE_EMPLOYER_TAB) && !isChildAccountFlag) {
    const employerTabRoutes = [
    {
      path: 'members/employers/:id/members',
      element: <EmployerMainView />,
    },
    {
      path: 'members/employers/:id/dashboard',
      element: <EmployerMainView />,
    },
    ]
    allRoute.push(...employerTabRoutes);
  }
  if (!isAccountConfigEnabled(CONFIG_CODES.DISABLE_ANALYTICS) && !isChildAccountFlag) {
    const analyticsRoutes = [
      {
        path: '/analytics',
        element: <Analytics />,
      },
      {
        path: '/analytics/updateForm',
        element: <Analytics />,
      },
      {
        path: '/analytics_employer',
        element: <Analytics />,
      },
    ]
    allRoute.push(...analyticsRoutes);
  }
  if (IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP')) {
    const membershipRoute = [
      {
        path: '/admin/membership',
        element: <Setting />,
      },
      {
        path: '/admin/membership/orders',
        element: <Setting />,
      },
      {
        path: '/admin/membership/products',
        element: <Setting />,
      },
      {
        path: '/admin/membership/paymentSetup',
        element: <Setting />,
      },
      {
        path: '/admin/membership/products/:id',
        element: <Setting />,
      },
      {
        path: '/admin/membership/order/:id',
        element: <Setting />,
      },
      {
        path: '/admin/oneTime/order/:id',
        element: <MembershipOrderDetailView />,
      }
    ];
    allRoute.push(...membershipRoute);
  }

  const billingRoute = [
    {
      path: '/admin/billing',
      element: <Setting />,
    },
    {
      path: '/admin/billing/orders',
      element: <Setting />,
    },
    {
      path: '/admin/billing/plans',
      element: <Setting />,
    },
    {
      path: '/admin/billing/products',
      element: <Setting />,
    },
    {
      path: '/admin/billing/paymentSetup',
      element: <Setting />,
    },
    {
      path: '/admin/billing/order/:id',
      element: <Setting />,
    },
  ];
  allRoute.push(...billingRoute);
  return filterRoutByUserRolePermission(allRoute);
};
