import { HStack, Text, View, VStack } from "native-base";
import { useEffect, useState } from "react";
import { getAccountUUID, getUserUUID } from "../../../../utils/commonUtils";
import { isWeb } from "../../../../utils/platformCheckUtils";
import { getUserPermissionByEntityAndActionCode } from "../../../RightSideContainer/UserAccess/UserAccessPermission";
import { getAllUserActionMastersData } from "../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi";
import { DisplayText } from "../../DisplayText/DisplayText";
import { styles } from './BusinessGoalsStyles';
import BusinessGoalsTab from "./BusinessGoalsTab";
import { UsageLevelState } from "./Interfaces";

const BusinessGoals = () => {
    const tenantId = getAccountUUID();
    const userUuid = getUserUUID();
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState<UsageLevelState>({
        loading: false,
        actionViewCode: '',
        personType: '',
        personTypeUuid: '',
        userList: [],
        userActionLevels: [],
        userActionLevelMap: {},
        userTaskDisplayCategory: [],
        userPoolSelect: [],
        userListForNumbers: [],
        categoryList: [],
        isCollapse: true,
    });

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
            getActions();
        }
    }, [!state.userActionLevelMap.length || !state.userActionLevels.length]);

    const getActions = async () => {
        setState((prev) => {
            return {
                ...prev,
                loading: true,
            };
        });
        try {
            const response = await getAllUserActionMastersData({
                userId: userUuid,
                tenantId: tenantId
            });
            const userActionMastersList = response.userActionMasters || [];
            let userActionLevels = response.userActionLevels;
            const userActions = response.userActions;
            const userActionLevelMap: any = {}
            userActionLevels.forEach((userActionLevel: any) => {
                userActionLevelMap[userActionLevel.code] = []
            });

            userActionMastersList.forEach((userActionMaster: any) => {
                if(getUserPermissionByEntityAndActionCode(userActionMaster.mainTabCode, userActionMaster.sideMenuCode)){
                    userActionLevelMap[userActionMaster.level].push(userActionMaster)
                }
            });

            userActionLevels = userActionLevels.filter((userActionLevel:any, index:number)=>{
                return !!userActionLevelMap[userActionLevel.code].length;
            })



            setState((prev) => {
                return {
                    ...prev,
                    userActionLevelMap,
                    userActionLevels
                }
            });
        } catch (error) {

            setState((prev) => {
                return {
                    ...prev,
                    loading: false,
                };
            });
        }
    }


    return (
        <VStack flex={1}>
            <Text style={styles.titleHeader}>Get Started</Text>
            <HStack
                flex={1}
                style={{
                    justifyContent: isWeb() ? 'space-between' : 'flex-start',
                }}
                //overflow= {'scroll'}
            >
                {
                    state.userActionLevels.map((userActionLevel, position) => {

                        return <VStack flex={1}
                            style={{
                                minWidth: 260,
                                marginRight: position !== state.userActionLevels.length - 1 ? 4 : 0,
                                marginLeft: position !== 0 ? 4 : 0,
                            }}
                        >
                            <BusinessGoalsTab
                                level={userActionLevel.code}
                                title={userActionLevel.value || ''}
                                description={userActionLevel?.metadata?.description || ''}
                                list={state.userActionLevelMap[userActionLevel.code] || []}
                                isLoading={isLoading}
                                afterCollapseText={userActionLevel?.metadata?.afterCollapseText}
                                isCollapse={state.isCollapse}
                                setCollapse={(isCollapse: boolean) => {
                                    setState((prev) => {
                                        return {
                                            ...prev,
                                            isCollapse: isCollapse
                                        };
                                    });
                                }}
                            />
                        </VStack>
                    })
                }
            </HStack>
        </VStack>
    );
};

export default BusinessGoals;
