import { Drawer, Form, Tooltip, Select, Checkbox, Divider, notification } from 'antd';
import { Spinner } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../styles';
import {BUTTON_TYPE} from '../../../../constants';
import { View, TextInput, Pressable, Text } from 'react-native';
import './CustomFieldStyle.css'
import {ALL_CONTACT_TYPE, ATTRIBUTE_TYPE, CONTACT_TYPES_VALUE, CUSTOM_FIELD_REF_KEYS, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG, ENTITY_TYPE} from './CustomFieldConst';
import {getCustomFieldBodyData, getFormattedCustomContactTypesData, validateCustomFieldData} from './CustomFieldUtils';
import {IAddCustomField, IAddCustomFieldState, IContactTypes, ICustomFieldData, ICustomFieldDataErrors, ICustomFieldFormBodyData, ICustomFieldFormKeys, ICustomFieldRef, ICustomToast} from './interface';
import {createCustomField, updateCustomField} from './CustomFieldService';
import {useToast} from '../../../Toast/ToastProvider';
import {ToastType} from '../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {getContactTypes} from '../CustomContactTypes/CustomContactTypeApi';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {capitalizeFirstOtherSmallLetter} from '../../ContentManagement/CampaignManagement/Helper';


export const AddCustomFieldDrawer = (props: IAddCustomField) => {
  const { isDrawerOpen, onDrawerClose, selectedCustomField, title } = props;
  const [stateData, setStateData] = useState<IAddCustomFieldState>({
    isDrawerOpen: isDrawerOpen,
    loading: false,
    errors: {} as ICustomFieldDataErrors,
    selectedType: '',
    selectedCustomField: {} as ICustomFieldData,
    addUpdateCustomFieldLoading: false,
    customContactTypes: [],
    selectedCustomContactTypes: [],
    customContactTypesLoading: false as boolean,
    hoveredIndex: null,
    visible: false,
    show: false
  })

  const [form] = Form.useForm();
  const customFieldRef = useRef<ICustomFieldRef>({} as any);
  const toast = useToast();
  const intl = useIntl();
  const { Option } = Select;

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const isDisabled = (id: string) => {
    if (props?.contactTypeId) {
      return id !== props?.contactTypeId && id !== ALL_CONTACT_TYPE.id
    }
    return stateData?.selectedCustomContactTypes?.[0]?.id === ALL_CONTACT_TYPE.id && id !== ALL_CONTACT_TYPE.id;
  }

  const handleMouseEnter = (index: number) => {
    setStateData((prev)=> {
      return {
        ...prev,
        hoveredIndex: index
      }
    })
  };

  const handleMouseLeave = () => {
    setStateData((prev)=> {
      return {
        ...prev,
        hoveredIndex: null
      }
    })
  };

  const fetchContactTypes = async () => {
    setStateData((prev) => ({
      ...prev,
      customContactTypesLoading: true,
    }));
    try {
      const response = await getContactTypes('');
      if (response?.data) {
        const formattedData = getFormattedCustomContactTypesData(response.data);
        let defaultContactType = {} as IContactTypes;
        if (props?.contactTypeId) {
          defaultContactType = formattedData?.find((customType) => customType?.id === props?.contactTypeId) || {} as IContactTypes;
        }
        setStateData((prev) => ({
          ...prev,
          customContactTypesLoading: false,
          customContactTypes: [ALL_CONTACT_TYPE, ...formattedData],
          selectedCustomContactTypes: defaultContactType?.id ? [defaultContactType] : prev.selectedCustomContactTypes,
        }));
      } else {
        setStateData((prev) => ({
          ...prev,
          customContactTypesLoading: false,
          customContactTypes: [],
        }));
      }
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        customContactTypesLoading: false,
      }));
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  useEffect(() => {
    customFieldRef.current['title'].value = title || '';
    CUSTOM_FIELD_REF_KEYS.forEach((singleKey: ICustomFieldFormKeys) => {
      if (customFieldRef.current[singleKey] && selectedCustomField?.id) {
        customFieldRef.current[singleKey].value = selectedCustomField[singleKey] || '';
      }
    });
  }, [selectedCustomField]);

  useEffect(() => {
    setStateData((prev)=> {
      return {
        ...prev,
        selectedType: selectedCustomField?.type || '',
        selectedCustomField: selectedCustomField
      }
    })
  },[selectedCustomField])

  useEffect(()=> {
    fetchContactTypes();
  },[])

  const onDrawerVisibleChange = (isOpen: boolean)=> {
    setStateData((prev)=> {
      return {
        ...prev,
        isDrawerOpen: isOpen,
      }
    })
  }

  useEffect(()=> {
    setStateData((prev)=> {
      return {
        ...prev,
        selectedCustomContactTypes: selectedCustomField?.contactTypes || [],
      }
    })
  },[])

  const onSubmit = async (formData: ICustomFieldFormBodyData) => {
    setStateData((prev) => ({
      ...prev,
      addUpdateCustomFieldLoading: true,
    }));
    const contactTypesId = stateData?.selectedCustomContactTypes?.map((contactType: IContactTypes)=> contactType?.id)
    const createCustomFiledPayload = {
      id: formData?.id,
      body: {
        label: formData?.name,
        entityType: ENTITY_TYPE.CONTACT,
        description: formData?.description,
        datatype: formData?.type,
        contactTypes: contactTypesId,
      }
    }
    try {
      const response = formData?.id ? await updateCustomField(createCustomFiledPayload) : await createCustomField(createCustomFiledPayload);
      let customFieldData = {} as ICustomFieldData;
      if (response?.data?.id) {
        customFieldData = {
          id: response?.data?.id,
          title: response?.data?.label,
          description: response?.data?.description,
          visibleIn: [],
          type: response?.data?.datatype,
          active : response?.data?.isDisabled ? false : true,
          contactTypes: response?.data?.customContactTypes,
          isDefault: response?.data?.isDefault,
        }
      }
      setStateData((prev) => ({
        ...prev,
        addUpdateCustomFieldLoading: false,
      }));
      props?.onActionPerformed?.(COMMON_ACTION_CODES.SUCCESS, customFieldData);
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        addUpdateCustomFieldLoading: false,
      }));
      notification.error({
        message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 4.0,
        placement: 'topRight'
      });
    }
  }

  const onShowCustomFieldOptionVisibleChanges = (value: boolean) => {
    setStateData((prev)=> {
      return {
        ...prev,
        visible: value
      }
    })
  }

  const validateDataOnSubmit = ()=> {
    const validate = validateCustomFieldData(customFieldRef, stateData);
    if (validate.validate) {
      onSubmit(getCustomFieldBodyData(customFieldRef, stateData));
    }
    setStateData((prev)=> {
      return {
        ...prev,
        errors: validate.errors
      }
    });
  }

  const renderCustomContact = (index: number, customContact: IContactTypes) => {
    let name = customContact?.id === ALL_CONTACT_TYPE.id ? capitalizeFirstOtherSmallLetter(ALL_CONTACT_TYPE.id) : customContact?.value || customContact;
    if (name === CONTACT_TYPES_VALUE.CUSTOMER) {
      name = CONTACT_TYPES_VALUE.PATIENT;
    }
    return (
      <View style={{
        backgroundColor: Colors?.Custom.Gray200,
        paddingHorizontal: '8px',
        paddingVertical: '6px',
        marginRight: 6,
        borderRadius: 20,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 6,
      }}>
        <Text style={{
          color: Colors?.Custom.Gray700,
          fontSize: 14,
        }}>
          {name}
        </Text>
        <Pressable  onPress={()=> {
          const updatedSelectedCustomContactTypes = stateData?.selectedCustomContactTypes?.filter((CustomContactType: IContactTypes)=> {
            return CustomContactType?.id !== customContact?.id
          })
          setStateData((prev)=> {
            return {
              ...prev,
              selectedCustomContactTypes: updatedSelectedCustomContactTypes
            }
          })
        }}>
          <AntDesign style={{
            marginLeft: 3,
            color: Colors.Custom.Gray400
          }}
            size={12}
            name="close"
          />
        </Pressable>
      </View>
    )
  }

  const isChecked = (id: string) => {
    return stateData?.selectedCustomContactTypes?.find((customContact: IContactTypes) => customContact?.id === id) ? true : false
  }

  const onChange = (event: CheckboxChangeEvent, field: IContactTypes) => {
    let tempSelectedCustomContact = stateData?.selectedCustomContactTypes;
    if (field?.id === ALL_CONTACT_TYPE.id) {
      if (event?.target?.checked) {
        tempSelectedCustomContact = [field];
      } else {
        tempSelectedCustomContact = [];
      }
    } else {
      if (event?.target?.checked) {
        tempSelectedCustomContact = [...tempSelectedCustomContact,field];
      } else {
        tempSelectedCustomContact = tempSelectedCustomContact.filter((tempCustomContact) => {
          return field?.id !== tempCustomContact?.id
        })
      }
    }
    setStateData((prev) => {
      return {
        ...prev,
        selectedCustomContactTypes: tempSelectedCustomContact
      }
    });
  }

  const getTextColor = (id: string, index: number) => {
    return isDisabled(id) ? Colors.Custom.Gray200 : stateData?.hoveredIndex === index ? Colors.Custom.mainPrimaryPurple : Colors.Custom.Gray500;
  }

  const renderOptions = (
    <>
      {stateData?.customContactTypes?.map((field: IContactTypes, index: number) => {
          return (
            <>
            <Option key={field.id} value={field.id}>
              <Pressable
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginHorizontal: 14,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    borderWidth: '1px',
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                  key={field.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Checkbox
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                    }}
                    value={field?.id}
                    checked={isChecked(field.id)}
                    onChange={(event) => {
                      onChange(event, field)
                    }}
                    disabled={isDisabled(field.id)}
                  >
                    <Text style={{
                        marginLeft: 4,
                        color: getTextColor(field.id, index),
                        fontSize: 16,
                        alignSelf: 'center',
                      }}
                    >
                      {field?.value}
                    </Text>
                  </Checkbox>
                </div>
              </Pressable>
              {stateData?.customContactTypes?.length -1 > index && <Divider style={{margin: 0}} />}
              </Option>
            </>
          )
        })
      }
    </>
  );

  useEffect(()=>{
    onDrawerVisibleChange(isDrawerOpen)
  },[isDrawerOpen])

  return (
    <Drawer
      className='addCustomFieldDrawer'
      width={'720px'}
      visible={stateData?.isDrawerOpen}
      onClose={() => {
        onDrawerVisibleChange(false);
        onDrawerClose?.();
      }}
      style={{
        borderRadius: 10
      }}
      title={
        <View
          style={{
            paddingHorizontal: 26,
            marginTop: 16,
          }}
        >
          <ModalActionTitle
            title={
              selectedCustomField?.id
                ? 'updateCustomField'
                : 'addCustomField'
            }
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onDrawerVisibleChange(false);
                  onDrawerClose?.();
                },
              },
              {
                show: true,
                id: 2,
                btnText: selectedCustomField?.id ? 'update' : 'create',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                rightIcon: stateData?.loading && <Spinner />,
                onClick: () => {
                  validateDataOnSubmit()
                },
                isLoading: stateData?.addUpdateCustomFieldLoading
              },
            ]}
          />
        </View>
      }
    >
      <View
        style={{
          paddingHorizontal: 26
        }}
      >
        <Form
          className="custom-form"
          form={form}
          layout="vertical"
          labelCol={{
            style: {
              paddingLeft:3
            }
          }}
        >
          <Form.Item
            label="Name"
            tooltip={{
              title: 'Required field',icon: <Tooltip title="Required field">
                <span className="required-dot"></span>
              </Tooltip>
            }}
            validateStatus={stateData?.errors?.name ? 'error' : ''}
          >
            <TextInput
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 6,
                height: 40,
                width: '100%',
                paddingLeft: 10,
              }}
              ref={(ref) =>
                (customFieldRef.current['title'] = ref as any as any)
              }
              placeholder="Enter attribute name"
              placeholderTextColor={Colors.Custom.Gray300}
            />
            {stateData?.errors?.name && <div style={{ color: Colors?.Custom?.ErrorColor }}>
              {stateData?.errors?.name}
            </div>}
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={stateData?.errors?.description ? 'error' : ''}
          >
            <TextInput
              multiline
              numberOfLines={6}
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 6,
                width: '100%',
                paddingLeft: 10,
                paddingTop: 10,
              }}
              ref={(ref) =>
                (customFieldRef.current['description'] = ref as any as any)
              }
              placeholder="Enter Description here"
              placeholderTextColor={Colors.Custom.Gray300}
            />
            {stateData?.errors?.description && <div style={{ color: Colors?.Custom?.ErrorColor }}>
              {stateData?.errors?.description}
            </div>}
          </Form.Item>
          <Form.Item
            label="Type"
            tooltip={{
              title: 'Required field',icon: <Tooltip title="Required field">
                <span className="required-dot"></span>
              </Tooltip>
            }}
            style={{
              marginTop: -4
            }}
          >
            <Select
              style={{
                borderRadius: 6,
                width: '100%'
              }}
              defaultValue={selectedCustomField?.type}
              placeholder="Select attribute type"
              options={ATTRIBUTE_TYPE}
              onSelect={(value)=> {
                setStateData((prev)=> {
                  return {
                    ...prev,
                    selectedType: value,
                  }
                })
              }}
            />
            {stateData?.errors?.type && <div style={{ color: Colors?.Custom?.ErrorColor }}>
              {stateData?.errors?.type}
            </div>}
          </Form.Item>

          <Form.Item
            label="Applicable to"
            tooltip={{
              title: 'Required field',icon: <Tooltip title="Required field">
                <span className="required-dot"></span>
              </Tooltip>
            }}
            style={{
              marginTop: -4
            }}
          >
            <Select
              className="select-custom-field"
              popupClassName="select-custom-field"
              style={{
                borderRadius: 6,
                width: '100%',
              }}
              loading={stateData?.customContactTypesLoading}
              open={stateData?.visible}
              placeholder="Select which this applies to"
              onDropdownVisibleChange={(open) => onShowCustomFieldOptionVisibleChanges(open)}
            >
              {renderOptions}
            </Select>
            {stateData?.errors?.applicableTo && <div style={{ color: Colors?.Custom?.ErrorColor }}>
              {stateData?.errors?.applicableTo}
            </div>}
          </Form.Item>
        </Form>
        <View style={{
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}>
          {stateData?.selectedCustomContactTypes?.map((customContact: IContactTypes,index: number) => {
            return <>
              {renderCustomContact(index, customContact)}
            </>
          })}
        </View>
      </View>
    </Drawer>
  );
};