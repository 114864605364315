import React from "react";
import { Svg, Path } from 'react-native-svg';

function EyeIcon() {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path d="M24.7251 12.7043L24.3289 13.0093L24.7251 12.7043ZM24.7251 19.2957L24.3289 18.9907L24.7251 19.2957ZM26 16H25.5H26ZM7.27489 12.7043L7.67111 13.0093L7.27489 12.7043ZM7.27489 19.2957L7.67111 18.9907L7.27489 19.2957ZM7.67111 13.0093C9.32308 10.8631 12.0396 8.5 16 8.5V7.5C11.5966 7.5 8.62085 10.136 6.87868 12.3994L7.67111 13.0093ZM16 8.5C19.9604 8.5 22.6769 10.8631 24.3289 13.0093L25.1213 12.3994C23.3792 10.136 20.4034 7.5 16 7.5V8.5ZM24.3289 18.9907C22.6769 21.1369 19.9604 23.5 16 23.5V24.5C20.4034 24.5 23.3792 21.864 25.1213 19.6006L24.3289 18.9907ZM16 23.5C12.0396 23.5 9.32308 21.1369 7.67111 18.9907L6.87868 19.6006C8.62085 21.864 11.5966 24.5 16 24.5V23.5ZM24.3289 13.0093C24.7629 13.5732 25.0404 13.9382 25.2239 14.3535C25.4 14.7522 25.5 15.2261 25.5 16H26.5C26.5 15.1346 26.3875 14.5127 26.1386 13.9494C25.8971 13.4028 25.5372 12.9397 25.1213 12.3994L24.3289 13.0093ZM25.1213 19.6006C25.5372 19.0603 25.8971 18.5972 26.1386 18.0506C26.3875 17.4873 26.5 16.8654 26.5 16H25.5C25.5 16.7739 25.4 17.2478 25.2239 17.6465C25.0404 18.0618 24.7629 18.4268 24.3289 18.9907L25.1213 19.6006ZM6.87868 12.3994C6.46279 12.9397 6.10285 13.4028 5.86137 13.9494C5.61251 14.5127 5.5 15.1346 5.5 16H6.5C6.5 15.2261 6.59997 14.7522 6.77608 14.3535C6.95956 13.9382 7.23707 13.5732 7.67111 13.0093L6.87868 12.3994ZM7.67111 18.9907C7.23707 18.4268 6.95956 18.0618 6.77608 17.6465C6.59997 17.2478 6.5 16.7739 6.5 16H5.5C5.5 16.8654 5.61251 17.4873 5.86137 18.0506C6.10285 18.5972 6.46279 19.0603 6.87868 19.6006L7.67111 18.9907ZM18.5 16C18.5 17.3807 17.3807 18.5 16 18.5V19.5C17.933 19.5 19.5 17.933 19.5 16H18.5ZM16 18.5C14.6193 18.5 13.5 17.3807 13.5 16H12.5C12.5 17.933 14.067 19.5 16 19.5V18.5ZM13.5 16C13.5 14.6193 14.6193 13.5 16 13.5V12.5C14.067 12.5 12.5 14.067 12.5 16H13.5ZM16 13.5C17.3807 13.5 18.5 14.6193 18.5 16H19.5C19.5 14.067 17.933 12.5 16 12.5V13.5Z" fill="#6F7A90" />
    </Svg>
  )
}

export default EyeIcon;