import { Colors } from '../../../styles/Colors';
import { Collapse, Select as AntSelect, List, Modal, Button } from 'antd';
import { useIntl } from 'react-intl';
import {
  Box,
  HStack,
  Text,
  View,
  Pressable,
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
const { Panel } = Collapse;
import CommonService from '../../../services/CommonService/CommonService';
import DiagnosisSurfacingSvg from '../../common/Svg/DiagnosisSurfacingSvg';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import UnitySvg from '../../common/Svg/UnitySvg';
import './Styles.css';
import { CommonDataContext } from '../../../context/CommonDataContext';
import BaseService from '../../../services/CommonService/BaseService';
import { fhirAddOrUpdate } from '../../../services/CommonService/AidBoxService';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import { LLMSkeletonLoader } from './LLMSkeletonLoader';
import { ILeftContainerProps } from './interfaces';
import { SearchCode } from '../../common/PAMISearch/PAMISearchHelper';

interface Coding {
  code: string;
  system?: string;
  display?: string;
}

interface Code {
  coding: Coding[];
}
interface Resource {
  id: string;
  code: Code;
  clinicalStatus: Code;
}
interface CheckboxValue {
  snomed: string;
  name: string;
}

interface Entry {
  resource: Resource;
}
interface Problem {
  name?: string;
  snomed: string;
  category?: string;
  evidence?: string;
}

const DiagnosisSurfacingConstants = {
  problemListItem: 'problem-list-item',
  clinicalStatus: 'Active',
  resourceType: 'Condition',
  promiseOkStatus: 'fulfilled',
  loaderMsg1: 'Analyzing Diagnosis Surfacing',
  surfacingTitle: 'Diagnoses Surfacing'
}

export const DiagnosisSurfacing = (props: ILeftContainerProps) => {
  const [filteredProblems, setFilteredProblems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [pendingCheckboxValue, setPendingCheckboxValue] = useState<CheckboxValue | null>(null);
  const [modalAction, setModalAction] = useState<string>('');
  const [cachedId, setCachedId] = useState<string>('');
  const patientId = props?.personData?.patientId || props?.personData?.patientUuid;
  const locationId = props?.personData?.accountLocationUuid


  const handleCheckboxChange = (value: CheckboxValue, action: string) => {
    setModalAction(action)
    setPendingCheckboxValue(value);
    setShowWarning(true);
  };

  const handleConfirm = async () => {
    try {
      setShowWarning(false);
      if (pendingCheckboxValue && modalAction == 'add') {
        const currentDate = new Date().toISOString().split('T')[0];
        const fhirData = {
          resourceType: DiagnosisSurfacingConstants.resourceType,
          clinicalStatus: {
            coding: [{ code: 'active', display: DiagnosisSurfacingConstants.clinicalStatus }]
          },
          subject: { reference: `Patient/${patientId}` },
          note: [
            {
              text: 'Unity AI'
            }
          ],
          onsetDateTime: currentDate,
          code: {
            text: pendingCheckboxValue.name,
            coding: [{
              display: pendingCheckboxValue.name,
              system: SearchCode?.SNOMED,
              code: pendingCheckboxValue?.snomed
            }]
          },
          category: [{ coding: [{ code: DiagnosisSurfacingConstants.problemListItem }] }]
        };

        fhirAddOrUpdate(
          FHIR_RESOURCE.CONDITION,
          '',
          fhirData,
          { patientId, locationId },
          (response) => {
            setPendingCheckboxValue(null);
            alreadyAttachedConditions()
          },
          (error) => {
          }
        );
      }
      else {
        setShowWarning(false);
        const urlToRejectCodes = '/snomed/reject'
        const payload = {
          name: pendingCheckboxValue?.name,
          snomed: pendingCheckboxValue?.snomed,
          contactUuId: props?.contactUUID,
          cachedId: cachedId
        }
        await llmflowService.post(urlToRejectCodes, payload)
        setPendingCheckboxValue(null);
        alreadyAttachedConditions()
      }
    } catch (error) {

    }
  };

  const handleCancel = () => {
    setShowWarning(false);
    setPendingCheckboxValue(null);
  };

  const [isActive, setIsActive] = useState(false);
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;

  useEffect(() => {
    alreadyAttachedConditions()
  }, []);

  const alreadyAttachedConditions = async (isCached = true) => {
    try {
      setLoading(true);
      const { axios } = BaseService.getSharedInstance();
      const payloadForProblems = {
        contactUuid: props?.contactUUID,
        isCatched: isCached
      };
      const urlToFetchAssociateConditions = `/integration/api/patient/Condition?patient=${patientId}&category=problem-list-item&location=${locationId}`;
      const urlToFetchAllProblems = `/problem`;
      try {
        const [conditionResponse, problemsResponse] = await Promise.allSettled([
          axios.get(urlToFetchAssociateConditions),
          llmflowService.post(urlToFetchAllProblems, payloadForProblems)
        ]);

        let responseForProblems: { data: { response: Problem[], cachedId: string } } = { data: { response: [], cachedId: '' } };
        let cachedId = ''
        if (problemsResponse?.status == 'fulfilled') {
          responseForProblems = problemsResponse.value;
          cachedId = responseForProblems?.data?.cachedId
          setCachedId(cachedId)
        }
        const urlToFetchRejectedCodes = `/snomed/rejection/ids?contactUuId=${props.contactUUID}&cachedId=${cachedId}`;
        const rejectedCodesResponse = await llmflowService.get(urlToFetchRejectedCodes)
        const rejectedCodes: string[] = rejectedCodesResponse?.data?.data?.rejectedCodes ? rejectedCodesResponse?.data?.data?.rejectedCodes : [];

        const entries: Entry[] = conditionResponse?.status == 'fulfilled' && conditionResponse?.value?.data?.resource?.data?.entry ? conditionResponse?.value?.data?.resource?.data?.entry : [];

        const snomedCodes: string[] = entries
          ?.flatMap(entry =>
            entry?.resource?.clinicalStatus?.coding?.some(status => status?.code === 'Active')
              ? entry?.resource?.code?.coding
                .filter(code => code?.system === SearchCode.SNOMED)
                .map(code => code?.code)
              : []
          )
          .filter((code): code is string => Boolean(code));

        const updatedRejectedCodes = await Promise.all(
          responseForProblems?.data?.response?.map(async (code) => {
            try {
              const url = `/integration/api/codes?limit=25&offset=0&searchType=Problem&searchString=${code?.snomed}`
              const response = await axios.get(url);
              if (response?.data?.codeable?.length > 0) {
                return null;
              } else {
                return code?.snomed;
              }
            } catch {
              return code?.snomed;
            }
          })
        );
        const finalRejectedCodes = [...rejectedCodes, ...updatedRejectedCodes.filter(code => code !== null)];
        let filteredProblems: Problem[] = [];
        if (responseForProblems?.data?.response?.length) {
          const data: Problem[] = responseForProblems?.data?.response;
          filteredProblems = data.filter(problem =>
            !snomedCodes.includes(String(problem?.snomed)) &&
            !finalRejectedCodes.includes(String(problem?.snomed))
          );
        }
        setFilteredProblems(filteredProblems);
      } catch (error) {
      } finally {
        setLoading(false);
        setIsActive(false)
      }
    } catch (error) {

      setLoading(false);
    }
  };

  const loaderTemeplateValues = [
    intl.formatMessage({ id: DiagnosisSurfacingConstants?.loaderMsg1 }),
    intl.formatMessage({ id: DiagnosisSurfacingConstants?.loaderMsg1 })
  ];

  return (
    <Box
      mb={5}
      shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
      borderRadius={8}
    >
      <Collapse
        className='llm-collapse'
        defaultActiveKey={[1]}
        collapsible='header'
        style={{
          borderWidth: 0.5,
          ...(!isSidecarContext && {
            borderColor: Colors.Custom.ContainerBGColor
          }),
        }}
        expandIconPosition='end'
      >
        <Panel
          header={
            <HStack alignItems={'center'} space={3}>
              <DiagnosisSurfacingSvg />
              <Text
                color={Colors.Custom.ProfileViewTextColor}
                fontSize={20}
                fontWeight={600}
              >
                {intl.formatMessage({ id: DiagnosisSurfacingConstants.surfacingTitle })}
              </Text>
            </HStack>
          }
          key={'1'}
        >
          <div className='llm-content'>
            {
              loading ? (<View padding={4} height={200} justifyContent={'center'}>
                <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'diagnosis' + 'skeleton'}/>
              </View>) :
                (<View>
                  <div style={{ maxHeight: '200px', overflowY: 'scroll', padding: '10px' }}>
                    <List
                      dataSource={filteredProblems}
                      renderItem={item => (
                        <List.Item key={item?.snomedCode} style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <span>{item?.name}</span>
                            <div style={{ fontSize: '12px', color: 'grey' }}>
                              Source: {item?.evidence}
                            </div>
                          </div>
                          <div>
                            <Button
                              type={selectedValues.includes(item?.snomedCode) ? 'primary' : 'default'}
                              onClick={() => handleCheckboxChange(item, 'add')}
                              style={{ marginRight: '8px' }}
                            >
                              Add
                            </Button>
                            <Button
                              type='default'
                              onClick={() => handleCheckboxChange(item, 'reject')}
                            >
                              Reject
                            </Button>
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                  {showWarning && (
                    <Modal
                      title='Confirmation'
                      visible={showWarning}
                      onOk={handleConfirm}
                      onCancel={handleCancel}
                      footer={[
                        <Button key='back' onClick={handleCancel}>
                          Cancel
                        </Button>,
                        <Button key='submit' type='primary' onClick={handleConfirm}>
                          {modalAction === 'reject' ? 'Reject' : 'Add'}
                        </Button>,
                      ]}
                    >
                      <p>Are you sure you want to {modalAction} this item?</p>
                    </Modal>
                  )}

                </View>)}
            <HStack
              space={2}
              style={{
                flexDirection: 'row',
                paddingHorizontal: 12,
                justifyContent: 'space-between',
                paddingVertical: 5,
                borderBottomLeftRadius: 9,
                borderBottomRightRadius: 9,
              }}
            >
              <View
                style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
              >
                <UnitySvg iconKey={'diagnosis' + 'UnitySvg'} />
                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={600}
                  noOfLines={1}
                  style={{
                    // @ts-ignore: Unreachable code error
                    backgroundImage:
                      'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textAlign: 'center',
                  }}
                >
                  {intl.formatMessage({ id: 'poweredByUnity' })}
                </Text>

                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={550}
                  noOfLines={1}
                  style={{
                    color: Colors.FoldPixel.GRAY200,
                    fontFamily: 'Courier',
                    textAlign: 'center',
                  }}
                >
                  {intl.formatMessage({ id: 'alpha' })}
                </Text>
              </View>
              <Pressable
                flexDirection={'row'}
                alignItems={'center'}
                onPress={() => {
                  setIsActive(true);
                  alreadyAttachedConditions(false);
                }}
              >
                <HardRefreshIconSvg isActive={isActive} iconKey={'diagnosis'} />
              </Pressable>
            </HStack>
          </div>
        </Panel>
      </Collapse>
    </Box>
  );
};
