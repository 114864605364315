import { Extension } from 'fhir/r4';
import { DATE_FORMATS } from '../../../../constants';
import {EHR} from '../../../../constants/FhirConstant';
import {IALlowedCategory, IAllowedNoteComponent, IMlov} from '../../../../Interfaces';
import { getEHRName } from '../../../../utils/capabilityUtils';
import {
  fromBase64,
  isEmptyArray,
  isString,
} from '../../../../utils/commonUtils';
import {
  getCurrentTimeZone,
  getDateStrFromFormat,
  getDateStrFromFormatWithTimezone,
  getMomentObj,
} from '../../../../utils/DateUtils';
import {
  getMlovCodeFromId,
} from '../../../../utils/mlovUtils';
import FoldAdvantangeReview from '../../../common/AppDashboard/FoldAdvantangeReview';
import { CustomComponentKey } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomComponentUtils';
import {forEachExtensiveFormComponent} from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { IDocumentRefContent } from '../../LeftContainer/RecentActivity/OrdersInterface';
import { IAvailableEhr, INoteAuditTrail, INoteRiskScore, INotesFormattedDataProps, IPatientNoteCardProps, NoteOperation } from '../interfaces';
import { OrderType } from '../Orders/OrdersAndReports/OrderConstants';
import { getExtensionValue, getExtensionValueBoolean, getOrderType } from '../Orders/OrdersAndReports/OrderUtils';
import { EXTENSION_URLS } from './components/AddOrUpdateTemplate/constant';
import { clipText } from './components/AddOrUpdateTemplate/helper';
import { ORDER, REASON, RESOURCE_AUDIT_STATUS } from './constants';
import {
  Context,
  DocStatus,
  EHRName,
  IAppointmentDetail,
  IFormComponent,
  INoteContent,
  NoteApiResponse,
  NoteEntry,
  NoteSource,
  NotesResource,
  Reference,
} from './interfaces';
import {
  IPatientNotesDisplayData,
  IPatientNotesDisplayItem,
} from './PatientNotes';
import { allowFoldFormDrivenNotes, allowNoteOperation } from '../CareTimeline/CareTimelineUtils';
import { getExtensionByUrl } from '../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { isArray } from 'lodash';
import { isAllowedToSignEHRNotesByRole } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';

export enum EXTENSION_URL_KEYS {
  FORM_NAME = 'form-name',
  FORM_ID = 'form-id',
  FORM_KEY = 'form-key',
  SIGNED_BY = 'signed-by',
  SIGNED_DATE = 'signed-date',
  ORDER_TYPE = 'request-group-category',
  ADDITIONAL_SECTIONS = 'additional-sections',
  ORDER_STATUS = 'order-status',
}

interface finalResult {
  month: string;
  data: any[];
  dateString: string;
}

const ORDER_TITLE = 'Order'
export const SOAP_NOTE_FORM = 'Soap Note';
export const ELATION_NON_VISIT_NOTE_CODE = 'NON_VISIT';

export const getDisplayData = (
  data: any
): {displayData: IPatientNotesDisplayData; appointmentIds: string[]} => {
  const displayData: IPatientNotesDisplayData = {};
  const appointmentIds: string[] = [];
  if (data.entry && data.entry.length > 0) {
    const resourceList: any[] = data.entry
      .filter((entryData: any) => entryData.resource)
      .map((entryData: any) => entryData.resource);
    const sortedResourceList = resourceList.sort(
      (resource1: any, resource2: any) => {
        const date1 = getEncounterDate(resource1, false);
        const date2 = getEncounterDate(resource2, false);
        if (date1 && date2) {
          const dateObject1 = new Date(date1);
          const dateObject2 = new Date(date2);
          return dateObject2 > dateObject1 ? 1 : -1;
        }
        return 0;
      }
    );
    sortedResourceList.forEach((resourceData: any) => {
      const encounterDate = getEncounterDate(resourceData, false);
      if (encounterDate) {
        const groupDate = getMomentObj(new Date(encounterDate)).format(
          'MMMM YYYY'
        );
        if (!displayData[groupDate]) {
          displayData[groupDate] = [];
        }
        const appointmentId = getAppointmentId(resourceData) || '';
        if (appointmentId) {
          appointmentIds.push(appointmentId);
        }
        displayData[groupDate].push({
          title: getTitle(resourceData),
          description: getDescription(resourceData) || '',
          encounterDate: encounterDate,
          lastModifiedDate: getEncounterDate(resourceData, true),
          iconName: 'calendar',
          status: '',
          statusId: '',
          appointmentId: appointmentId,
          encounterId: resourceData.id || '',
        });
      }
    });
  }
  return {displayData: displayData, appointmentIds: appointmentIds};
};

export const getStatusValue = (
  appointmentData: {id: string; statusId: string}[],
  appointmentStatusList: IMlov[],
  appointmentId?: string
) => {
  const statusId = getStatusId(appointmentData, appointmentId) || '';
  return getMlovCodeFromId(appointmentStatusList, statusId);
};

const getStatusId = (
  appointmentData: {id: string; statusId: string}[],
  appointmentId?: any
) => {
  const matchedData = appointmentData.filter(
    (appointment) => appointment.id === appointmentId
  );
  if (matchedData.length) {
    return matchedData[0].statusId;
  }
};
export const getTimeLineCardDate = (displayData: IPatientNotesDisplayItem) => {
  const date = getDateStrFromFormat(displayData.encounterDate, 'MMM DD');

  return date;
};
export const getDate = (
  displayData: IPatientNotesDisplayItem,
  appointmentData: {id: string; statusId: string}[],
  checkedInStatusId: string,
  checkedOutStatusId: string
) => {
  if (displayData.appointmentId) {
    const statusId = getStatusId(appointmentData, displayData.appointmentId);
    const date = getDateStrFromFormat(
      statusId === checkedOutStatusId
        ? displayData.lastModifiedDate
        : displayData.encounterDate,
      'MM/DD/YYYY'
    );
    if (statusId === checkedInStatusId) {
      return 'Checked in on ' + date;
    } else if (statusId === checkedOutStatusId) {
      return 'Checked out on ' + date;
    }
  }
  // if (
  //   resourceData.period &&
  //   resourceData.period.start &&
  //   resourceData.period.end
  // ) {
  //   const date = getDateStrFromFormat(
  //     resourceData.period.start,
  //     'MM/DD/YYYY'
  //   );
  //   const startTime = getDateStrFromFormat(
  //     resourceData.period.start,
  //     DATE_FORMATS.DISPLAY_TIME_FORMAT
  //   );
  //   const endTime = getDateStrFromFormat(
  //     resourceData.period.end,
  //     DATE_FORMATS.DISPLAY_TIME_FORMAT
  //   );
  //   return date + ',  ' + startTime + ' - ' + endTime;
  // }
};

export const getDescription = (resourceData: any) => {
  if (resourceData.type && resourceData.type.length > 0) {
    if (resourceData.type[0].coding && resourceData.type[0].coding.length > 0) {
      return resourceData.type[0].coding[0].display;
    }
  }
};

export const getTitle = (resourceData: any) => {
  if (resourceData.type && resourceData.type.length > 0) {
    return resourceData.type[0].text;
  }
};

export const getAppointmentId = (resourceData: any) => {
  if (resourceData.appointment && resourceData.appointment.length > 0) {
    return resourceData.appointment[0].id;
  }
};

export const getEncounterDate = (
  resourceData: any,
  returnUpdatedDate: boolean
): string => {
  let encounterDate = '';
  if (resourceData.meta && resourceData.meta) {
    if (
      !returnUpdatedDate &&
      resourceData.meta.extension &&
      resourceData.meta.extension.length > 0 &&
      resourceData.meta.extension[0].valueInstant
    ) {
      encounterDate = resourceData.meta.extension[0].valueInstant;
    } else if (resourceData.meta.lastUpdated) {
      encounterDate = resourceData.meta.lastUpdated;
    }
  } else if (resourceData.period && resourceData.period.start) {
    encounterDate = resourceData.period.start;
  }
  return encounterDate;
};

export const getColorSchemeForStatus = (
  appointmentData: {id: string; statusId: string}[],
  checkedInStatusId: string,
  checkedOutStatusId: string,
  appointmentId?: string
): string => {
  const statusId = getStatusId(appointmentData, appointmentId);
  if (statusId === checkedInStatusId) {
    return 'success';
  } else if (statusId === checkedOutStatusId) {
    return 'warning';
  }
  return 'primary';
};

export const isReadOnly = (
  appointmentId: string,
  checkedOutStatusId: string,
  appointmentData: {id: string; statusId: string}[]
): boolean => {
  if (appointmentId) {
    const statusId = getStatusId(appointmentData, appointmentId);
    return statusId === checkedOutStatusId;
  }
  return false;
};

export const getNoteDataForAddUpdate = (
  encounterId: string,
  soapNoteFormId: string,
  formComponents: any[],
  formChanges: any,
  personData: any
) => {
  const updatedComponents = formComponents;
  forEachExtensiveFormComponent(updatedComponents, (component) => {
    if (formChanges && formChanges.hasOwnProperty(component.key)) {
      component.selectedValue = formChanges[component.key];
    }
  });
  return {
    formId: soapNoteFormId,
    formResponse: {components: updatedComponents},
    reference: {},
    patientId: personData?.patientUuid || '',
    contactId: personData?.contactUUID || '',
    encounterId: encounterId,
  };
};

export const getDocumentReferenceDataInFormFormat = (
  response: any,
  formComponents: any[]
) => {
  if (
    response &&
    response.data &&
    response.data.entry &&
    response.data.entry.length
  ) {
    const prevFormChanges: any = {};
    response.data.entry.forEach((entryData: any) => {
      if (
        entryData &&
        entryData.resource &&
        entryData.resource.content &&
        entryData.resource.content.length
      ) {
        const contentData = entryData.resource.content[0];
        if (
          contentData.attachment &&
          contentData.attachment.data &&
          contentData.attachment.title
        ) {
          const text = fromBase64(contentData.attachment.data);
          const matchedComponents = formComponents.filter(
            (component) => component.type === contentData.attachment.title
          );
          if (isString(text)) {
            if (matchedComponents.length > 0 && matchedComponents[0].key) {
              prevFormChanges[matchedComponents[0].key] = text;
            } else {
              prevFormChanges[contentData.attachment.title] = text;
            }
          }
        }
      }
    });
    return prevFormChanges;
  }
};

export const getFormComponentsResponse = (response: any) => {
  let components: any[] = [];
  if (
    response &&
    response.data &&
    response.data.form &&
    response.data.form.components
  ) {
    components = response.data.form.components || [];
    components = components.filter((component) => component.key !== 'submit');
  }
  return components;
};
export const getAppointmentIdFromReference = (context: Context) => {
  if (context && context?.related) {
    const reference: Reference = context.related[0];
    if (reference && reference.reference) {
      return reference.reference.split('/')[1];
    }
  }
};


// Kept for future reference => ELATION
// export const getFormattedNotesData = (
//   data: NoteApiResponse
// ): IPatientNote[] => {
//   const result: IPatientNote[] = [];
//   const resources = data.entry.map((item) => item.resource);
//   resources.forEach((resource) => {
//     result.push({
//       status: resource.status,
//       docStatus: resource.docStatus,
//       type: getNoteType(resource),
//       category: getNoteCategory(resource),
//       date: resource.date,
//       description: resource.description,
//       practitionerId: getPractitionerId(resource),
//       content: getNoteContent(resource),
//       id: resource.id.toString(),
//     });
//   });
//   return result;
// };
export const getFormKey = (attachment: any) => {
  const extension = attachment.extension;
  if (extension && extension.length > 0) {
    let key = '';
    extension.find((value: any) => {
      if (value.url.includes(EXTENSION_URL_KEYS.FORM_KEY)) {
        key = value.valueString;
      }
    });
    return key;
  } else {
    return '';
  }
};


export const getAuthorUUID = (
  author: {
    reference: string;
  }[]
) => {
  const reference = author[0]?.reference || '';
  const separator = '/';
  return reference.split(separator).pop() || '';
};

const getElationTemplateNameFromCapabilityData = (
  code: string,
  data?: IALlowedCategory[]
) => {
  const template = data?.filter((item) => {
    if (item?.code === code) {
      return item;
    }
  })?.[0];
  return template?.foldDisplay || code;
};

const getElationTemplateReadOnlyStatusFromCapabilityData = (
  code: string,
  data?: IALlowedCategory[]
) => {
  const template = data?.filter((item: IALlowedCategory) => {
    if (item?.code === code) {
      return item;
    }
  })?.[0];
  return !!template?.readOnly;
};

export const getFormContent = (data:string,key:string) => {
   return key === REASON
      ? {
          chiefComplaint: {
            displayName: data,
          },
        }
      : data;
}

export const getOrderTypeFromExtension = (extension?: Extension[] | any[]) => {
  if (extension && extension.length > 0) {
    let orderType = '';
    extension.find((value: any) => {
      if (value?.url?.includes(EXTENSION_URL_KEYS.ORDER_TYPE)) {
        orderType = value?.valueString;
      }
    });
    return orderType || '';
  }
};

export const getFormattedNotesData = (
  entries: NoteEntry[],
  soapNote: {
    id: string;
    name: string;
  },
  currentEHR: EHRName,
  elationFormData?: {
    categories?: IALlowedCategory[];
    noteComponents?: IAllowedNoteComponent[];
  }
): {formattedData: finalResult[]; userIds: string[]} => {
  const isElation = currentEHR === EHRName.ELATION;
  const isFold = currentEHR === EHRName.FOLD;
  const userIds: string[] = [];
  let result: any[] = [];
  result = (entries || []).map((entry) => {
    const timeLineCardDate = getDateStrFromFormat(
      entry.resource.date,
      'MMM DD'
    );
    const appointmentId = entry.resource.context
      ? getAppointmentIdFromReference(entry.resource.context)
      : '';
    const date = getDateStrFromFormat(entry.resource.date, 'MMMM YYYY');
    const createdDate = getDateStrFromFormat(entry.resource.date);
    const day = getDateStrFromFormat(entry.resource.date, 'DD');
    const docStatus = entry.resource.docStatus || '';
    const authorUUID = entry.resource.author
      ? getAuthorUUID(entry.resource.author)
      : '';
      let formName = isElation

      ? getElationTemplateNameFromCapabilityData(
           entry.resource?.type?.coding?.[0]?.code,
          elationFormData?.categories
        )
      : '';
    const isReadOnly = isElation
      ? getElationTemplateReadOnlyStatusFromCapabilityData(
          entry.resource?.type?.coding?.[0]?.code,
          elationFormData?.categories
        )
      : false;
    let formId = isElation
      ?  entry.resource?.type?.coding?.[0]?.code
      : '';
    let signedByUserId = '';
    let additionalSections = '';
    const signedDate = getExtensionValue(entry.resource, EXTENSION_URLS.signedDate);
    if (entry.resource.extension) {
      entry.resource.extension.forEach((extension) => {
        if (extension.url.includes(EXTENSION_URL_KEYS.FORM_NAME)) {
          formName = extension.valueString;
        }
        if (extension.url.includes(EXTENSION_URL_KEYS.FORM_ID)) {
          formId = extension.valueString;
        }
        if (extension.url.includes(EXTENSION_URL_KEYS.SIGNED_BY)) {
          signedByUserId = extension.valueString?.toString();
        }
        if (extension.url.includes(EXTENSION_URL_KEYS.ADDITIONAL_SECTIONS)) {
          additionalSections = extension.valueString?.toString();
        }
      });
    } else {
      formName = soapNote.name;
      formId = soapNote.id;
    }
    // let formKey = '';

    const formData = entry.resource.content;
    const formContent: IFormComponent[] = [];
    formData.forEach((data) => {
      formContent.push({
        title: data.attachment.title,
        data:
          data.attachment.data &&
          (isFold
            ? fromBase64(data?.attachment?.data)
            : getFormContent(
                data?.attachment?.data,
                data.attachment.title,
              )),
        key: getFormKey(data.attachment) || data.attachment.title,
        orderType: getOrderTypeFromExtension(data.attachment.extension),
      });
    });
    authorUUID && userIds.push(authorUUID);
    signedByUserId && userIds.push(signedByUserId);
    const showDeleteButton = docStatus === DocStatus.PRELIMINARY && (isFold || isElation);
    const showPrintButton = docStatus === DocStatus.FINAL && isFold;
    const orderExists = formContent.some((item) => item.orderType !== '');
    return {
      ...(isElation && {isReadOnly}),
      docStatus,
      authorUUID,
      linkedAppointmentId: appointmentId,
      id: entry.resource.id,
      timeLineCardDate,
      date,
      prevResourceDate: entry?.resource?.date,
      createdDate,
      day,
      formName,
      formId,
      formContent,
      signedByUserId,
      additionalSections,
      showDeleteButton,
      showPrintButton,
      orderExists,
      signedDate
    };
  });
  //Filter MONTH wise
  const months = [...new Set(result.map((item) => item.date))];

  const finalResult: finalResult[] = months.map((month) => ({
    month,
    dateString: '',
    data: result.filter((item) => item.date === month),
  }));
  //FILTER DAY WISE
  finalResult.forEach((month) => {
    const days = [...new Set(month.data.map((item) => item.timeLineCardDate))];
    days.sort(
      (a: string, b: string) =>
        parseInt(b.slice(b.length - 2, b.length)) -
        parseInt(a.slice(a.length - 2, a.length))
    );
    month.dateString = month.data[0].createdDate;
    month.data = days.map((day) => ({
      day,

      cards: month.data
        .filter((item) => item.timeLineCardDate === day)
        .sort(
          (a, b) =>
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
        ),
    }));
  });
  finalResult.map((month) => month.data.sort((a, b) => a.day - b.day));
  //SORT MONTH WISE
  finalResult.sort(
    (a, b) =>
      new Date(b.dateString).getTime() - new Date(a.dateString).getTime()
  );
  return {
    formattedData: finalResult,
    userIds: [...new Set(userIds)].map((item) => item + ''),
  };
};

export const getNoteType = (resource: NotesResource) => {
  const type = resource.type;
  const coding = type.coding || [];
  if (isEmptyArray(coding)) {
    return '';
  }
  return coding[0]?.display || '';
};

export const getNoteCategory = (resource: NotesResource) => {
  const category = resource.category;
  if (isEmptyArray(category)) {
    return '';
  }
  const coding = category[0]?.coding || [];
  if (isEmptyArray(coding)) {
    return '';
  }
  return coding[0]?.display || '';
};

export const getNoteContent = (resource: NotesResource): INoteContent[] => {
  const result: INoteContent[] = [];
  const content = resource.content;
  if (isEmptyArray(content)) {
    return result;
  }
  content.forEach((item) => {
    result.push({
      data: item?.attachment?.data || '',
      title: item?.attachment?.title || '',
      formatCode: item?.format?.code || '',
      formatDisplay: item?.format?.display || '',
    });
  });

  return result;
};

export const getPractitionerId = (resource: NotesResource) => {
  const author = resource.author;
  if (isEmptyArray(author)) {
    return '';
  }
  const reference = author[0]?.reference || '';
  const separator = reference.includes('/') ? '/' : ':';
  return reference.split(separator).pop() || '';
};

export const isAthenaCaliberEHR = (ehrName: string) => {
  if (!ehrName) {
    return false;
  }
  return ehrName === EHR.ATHENA_CALIBER;
};

export const isElationEHR = (ehrName: string) => {
  if (!ehrName) {
    return false;
  }
  return ehrName === EHR.ELATION_CALIBER;
};

export function hasJsonStructure(str: string) {
  if (typeof str !== 'string') return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    // [object String] condition added as for date component the value was coming with 2 nested quotes e.g. '"2023-12-23T00:000:00Z"'
    return type === '[object Object]' || type === '[object Array]' || type === '[object String]';
  } catch (err) {
    return false;
  }
}

export const getLatestAppointment = (data: any[]) => {
  const filteredData: IAppointmentDetail[] = data
    .sort(function (a: any, b: any) {
      return (
        new Date(b.startDateTime).getTime() -
        new Date(a.startDateTime).getTime()
      );
    })
    .filter((item) => {
      return !item.reference.documentReferenceId;
    })
    .filter((item) => {
      return !item.reference.documentReferenceId;
    });
  return filteredData;
};

export const getAppointmentLocation = (
  appointment: IAppointmentDetail,
  appointmentUserList: any
) => {
  let locationName = null;
  const userPracticeLocationId = appointment.userPracticeLocationId;
  const userId = appointment.participants.find((participant: any) => {
    return participant?.user && participant?.user?.uuid;
  })?.user?.uuid;

  if (userId && userPracticeLocationId) {
    const user = appointmentUserList?.find((user: any) => {
      return user.uuid === userId;
    });

    locationName = (user?.userPracticeLocations || []).forEach(
      (location: any) => {
        return location.uuid === userPracticeLocationId;
      }
    )?.accountLocation?.practiceLocation?.name;
  }
  return (
    locationName || appointment?.appointmentType?.locationType?.value || ''
  );
};


export const getCurrentEHR = ():EHRName => {
  const ehrName = getEHRName();
  return ehrName || EHRName.FOLD;
}

export const getUserNameById = (id: string | undefined, userList: any[]) => {
  if (!id || !userList || !userList?.length) {
    return '';
  }
  let findUser = userList?.find((item) => item?.uuid === id);
  if (findUser && findUser?.uuid) {
    return findUser?.name;
  } else {
    findUser = userList?.find((item) => item?.externalUserId === id);
    return findUser?.name || '';
  }
};

export const findUserById = (id: string | undefined, userList: any[] | undefined) => {
  if (!id || !userList || !userList?.length) {
    return '';
  }
  let findUser = userList?.find((item) => item?.uuid === id);
  if (findUser && findUser?.uuid) {
    return findUser;
  } else {
    findUser = userList?.find((item) => item?.externalUserId === id);
    return findUser || {};
  }
};

export const getFormContentByEhrAndFormData = (formData: any[], useBase64Data: boolean) => {
  const formContent: IFormComponent[] = [];
  formData.forEach((data) => {
    formContent.push({
      title: data.attachment.title,
      data:
        data.attachment.data &&
        (useBase64Data
          ? fromBase64(data?.attachment?.data)
          : getFormContent(data?.attachment?.data, data.attachment.title)),
      key: getFormKey(data.attachment) || data.attachment.title,
    });
  });
  return formContent;
};

const getFormNameByEHR = (
  isFold: boolean,
  resource: any,
  formattedNoteArg: INotesFormattedDataProps
) => {
  if (isFold) {
    return getExtensionValue(resource, EXTENSION_URLS.formName);
  } else {
    return getElationTemplateNameFromCapabilityData(
      resource?.type?.coding?.[0]?.code,
      formattedNoteArg?.elationFormData?.categories
    );
  }
};

const getFormId = (resource: any, isFold: boolean) => {
  if (isFold) {
    return getExtensionValue(resource, EXTENSION_URLS.formId);
  } else {
    return resource?.type?.coding?.[0]?.code;
  }
}

export const getSource = (extensions:Extension[]) => {
  return (
    getExtensionByUrl(extensions, EXTENSION_URLS.source)?.valueString || ''
  );
};

export const getRawPayload = (extensions:Extension[]) => {
  return (
    getExtensionByUrl(extensions, EXTENSION_URLS.rawPayload)?.valueString || ''
  );
};

export const getFormattedEncountersForCareTimeline = (
  encountersApiResponse: {entry: any[]},
  formattedNoteArg: INotesFormattedDataProps
) => {
  const result: IPatientNoteCardProps[] = [];
  const entry = encountersApiResponse.entry;
  entry.forEach((element) => {
    const resource = element.resource;
    const source = getSource(resource.extension);
    if (source === NoteSource.FOLD) {
      const rawJson = getRawPayload(resource.extension);
      const note = formatSingleNote({resource:JSON.parse(rawJson)}, true, formattedNoteArg);
      result.push(note);
    } else {
      const visitName: Extension = getExtensionByUrl(
        resource?.extension || [],
        EXTENSION_URLS.visitName,
      );
      const providerFirstName: Extension = getExtensionByUrl(
        resource?.extension || [],
        EXTENSION_URLS.providerFirstName,
      );
      const providerLastName: Extension = getExtensionByUrl(
        resource?.extension || [],
        EXTENSION_URLS.providerLastName,
      );
      const providerName = `${
        providerFirstName?.valueString ? providerFirstName?.valueString : ''
      } ${providerLastName?.valueString ? providerLastName?.valueString : ''}`;

      result.push({
        visitName: visitName.valueString,
        isEncounterNote: true,
        resourceId: resource?.id,
        // appointmentId: resource?.appointment[0]?.reference,
        createdDate: resource?.period?.start,
        // locationId: resource?.location[0]?.location?.reference,
        status: resource?.status,
        encounterType: resource?.type[0]?.coding[0]?.display,
        authorUuid: '',
        isAllowToPrint: true,
        // we dont need this flags for encounters but they are mandatory in interface
        isCreateUserAndLoginUserIsSame: false,
        isSignedByUserAndLoginUserIsSame: false,
        isAllowToDelete: false,
        isAllowToUnlock: false,
        isAllowToAutoSave: false,
        isAllowToSign: false,
        isAllowToSave: false,
        isReadOnly: false,
        providerName,
      });
    }
  });

  return result;
};

const getFormRiskScore = (extension:Extension[]) => {
  const riskScoreDetailsJSON = getExtensionValue(
    {extension: extension},
    EXTENSION_URLS.formRiskScore
  );
  if(!riskScoreDetailsJSON?.length){
    return []
  }
  const riskScoreDetails: INoteRiskScore[] =
    typeof riskScoreDetailsJSON === 'string' && riskScoreDetailsJSON.length > 0
      ? JSON.parse(riskScoreDetailsJSON)
      : [];
  const sortedList:INoteRiskScore[] = [];
  if(isArray(riskScoreDetails)){
    // first push items without groupIds
    riskScoreDetails?.forEach?.((item) => {
      if (!item?.groupId?.length) {
        sortedList.push(item);
      }
    });
    // then push all item with groupIds
    riskScoreDetails?.forEach((item) => {
      if (item.groupId?.length) {
        sortedList.push(item);
      }
    });
  }

  return sortedList
}


const formatSingleNote = (
  entry: NoteEntry,
  isFoldFormDriven: boolean,
  formattedNoteArg: INotesFormattedDataProps,
) => {
  const {ehrConfig,contextData} = formattedNoteArg
  const docStatus = entry.resource.docStatus || '';
  const formData = entry?.resource?.content || [];
  const authorUUID = entry.resource.author
    ? getAuthorUUID(entry.resource.author)
    : '';
  const signedDate = getExtensionValue(
    entry.resource,
    EXTENSION_URLS.signedDate
  );
  const signedByUserId = getExtensionValue(
    entry.resource,
    EXTENSION_URLS.signedBy
  );

  const unlockedByUserId = getExtensionValue(
    entry.resource,
    EXTENSION_URLS.unlockedBy
  );

  const amendedByUserId = getExtensionValue(
    entry.resource,
    EXTENSION_URLS.amendedBy
  );

  const amendedDate = getExtensionValue(
    entry.resource,
    EXTENSION_URLS.amendedDate
  );

  const formLogId = getExtensionValue(entry.resource, EXTENSION_URLS.formLogId);

  const formResponseId = getExtensionValue(
    entry.resource,
    EXTENSION_URLS.formResponseId
  );

  const isCreateUserAndLoginUserIsSame =
    authorUUID === formattedNoteArg.loginUserId;
  const createdByUserName = getUserNameById(
    authorUUID,
    formattedNoteArg?.accountUserList
  );
  const signedByUserName = getUserNameById(
    signedByUserId?.toString(),
    formattedNoteArg?.accountUserList
  );
  const unlockedByUserName = getUserNameById(
    unlockedByUserId,
    formattedNoteArg?.accountUserList
  );
  const amendedByUserName = getUserNameById(
    amendedByUserId,
    formattedNoteArg?.accountUserList
  );

  const formRiskScore = getFormRiskScore(entry.resource?.extension);

  const isSignedByUserAndLoginUserIsSame =
    signedByUserId === formattedNoteArg.loginUserId;
  const isUnlockedByUserAndLoginUserIsSame =
    unlockedByUserId === formattedNoteArg.loginUserId;
  let isAllowToSign = false;
  let isAllowToSave = false;
  if (isFoldFormDriven && docStatus === DocStatus.PRELIMINARY) {
    if (signedDate || signedByUserId) {
      isAllowToSign =
        isAllowedToSignEHRNotesByRole(contextData.currentUserRoles, contextData.userPermissions) ||
        isSignedByUserAndLoginUserIsSame ||
        isUnlockedByUserAndLoginUserIsSame;
    } else {
      isAllowToSign =
      isAllowedToSignEHRNotesByRole(contextData.currentUserRoles, contextData.userPermissions) || isCreateUserAndLoginUserIsSame;
    }
    isAllowToSave = isFoldFormDriven;
  }

  const isSignedDocumentReference = signedByUserId && signedDate;
  const isAllowToDelete =
    !isSignedDocumentReference &&
    allowNoteOperation(NoteOperation.DELETE, ehrConfig, {
      contextData: formattedNoteArg.contextData
    }) &&
    docStatus === DocStatus.PRELIMINARY;
  const isAllowToUnlock =
    (docStatus === DocStatus.FINAL || docStatus === DocStatus.AMENDED) &&
    (allowNoteOperation(NoteOperation.UNLOCK, ehrConfig, {
      contextData: formattedNoteArg.contextData
    }) ||
    isSignedByUserAndLoginUserIsSame);
  const isAllowToAutoSave = allowNoteOperation(
    NoteOperation.AUTO_SAVE,
    ehrConfig,
    {
      contextData: formattedNoteArg.contextData
    }
  );
  const isAllowToPrint =
    allowNoteOperation(NoteOperation.PRINT, ehrConfig, {
      contextData: formattedNoteArg.contextData
    }) &&
    (isSignedDocumentReference ||
      docStatus === DocStatus.FINAL ||
      docStatus === DocStatus.AMENDED);

  const isReadOnly = allowNoteOperation(NoteOperation.READ_ONLY, ehrConfig, {
    contextData: formattedNoteArg.contextData
  })
    ? getElationTemplateReadOnlyStatusFromCapabilityData(
        entry.resource?.type?.coding?.[0]?.code,
        formattedNoteArg?.elationFormData?.categories
      )
    : false;
  const linkResourceIds: string[] = [];
  // filter note match resource id, which is an task ids
  (formattedNoteArg?.resourceMapList || []).forEach(resourceMap => {
    if (resourceMap?.sourceId === `${entry.resource.id}`) {
      linkResourceIds.push(resourceMap?.resourceId);
    }
  });
  let linkTasks: ITask[] = [];
  if (linkResourceIds?.length && formattedNoteArg?.linkTasks?.length) {
    linkTasks = (formattedNoteArg.linkTasks).filter(linkTask => {
      return linkResourceIds.includes(linkTask?.id);
    })
  }
  return {
    status: docStatus,
    authorUuid: authorUUID,
    linkedAppointmentId: entry?.resource?.context
      ? getAppointmentIdFromReference(entry.resource.context)
      : '',
    resourceId: entry.resource.id,
    createdDate: entry.resource.date,
    description: entry.resource.description,
    formName: getFormNameByEHR(
      isFoldFormDriven,
      entry.resource,
      formattedNoteArg
    ),
    formId: getFormId(entry.resource, isFoldFormDriven),
    signedByUserId: signedByUserId,
    signedDate: signedDate,
    additionalSections: getExtensionValue(
      entry.resource,
      EXTENSION_URLS.docRefAdditionalSection
    ),
    isCreateUserAndLoginUserIsSame,
    isSignedByUserAndLoginUserIsSame,
    isAllowToDelete,
    isAllowToPrint,
    isSignedDocumentReference,
    isAllowToUnlock,
    isAllowToAutoSave,
    isAllowToSign,
    createdByUserName,
    signedByUserName,
    formContent: getFormContentByEhrAndFormData(formData, isFoldFormDriven),
    isAllowToSave,
    isReadOnly,
    unlockedByUserName,
    unlockedByUserId,
    amendedByUserId,
    amendedByUserName,
    amendedDate,
    isEncounterNote: false,
    noteRiskScore: formRiskScore || [],
    linkTasks: linkTasks,
    allowToShowToPatient: getExtensionValueBoolean(entry.resource, EXTENSION_URLS.showToPatient),
    formLogId,
    formResponseId,
  } as IPatientNoteCardProps;
};

export const getFormattedNoteForCareTimeline = (formattedNoteArg: INotesFormattedDataProps) => {
  // const {isFold, isElation, isAthena} = formattedNoteArg.ehrConfig;
  const {ehrConfig} = formattedNoteArg
  const isFoldFormDriven = allowFoldFormDrivenNotes(
    formattedNoteArg.ehrConfig,
    formattedNoteArg?.additionalFlags
  );
  const formattedData: IPatientNoteCardProps[] = (
    formattedNoteArg?.noteResponse || []
  ).map((entry) => {
    return formatSingleNote(entry, isFoldFormDriven, formattedNoteArg);
  });
  return !isFoldFormDriven
    ? formattedData.sort(
        (a, b) =>
          new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()
      )
    : formattedData;
};


export const filterOrderContentFromDocumentRef = (documentRefResponse: any): IDocumentRefContent[] => {
  const docRefContent: IDocumentRefContent[] = documentRefResponse?.content || [];
  return (docRefContent || []).filter(content => {
    return (content?.attachment && content?.attachment?.title && content?.attachment?.title === ORDER_TITLE);
  });
};

export const isOrderTypeDataExist = (orderType: string, docRefContent: IDocumentRefContent[]): boolean => {
  return (docRefContent || []).some(content => {
    if (content?.attachment && content?.attachment && content?.attachment?.extension?.length) {
      let requestGroupCategory = getExtensionValue(content.attachment, EXTENSION_URLS.requestGroupCategory);
      if (!requestGroupCategory) {
        requestGroupCategory = getExtensionValue(content.attachment, EXTENSION_URLS.orderCategory);
      }
      const categoryArray = requestGroupCategory ? requestGroupCategory?.split('|') : [];
      if (categoryArray?.length) {
        return (categoryArray || []).some(category => {
          return category === orderType;
        });
      }
    }
  });
};

export const hasChiefComplaintComponent = (formData: any) => {
  let hasChiefComplaint = false;
  const components = formData?.form?.components || [];
  forEachExtensiveFormComponent(components, (item) => {
    if (item.type === CustomComponentKey.CHIEF_COMPLAINT) {
      hasChiefComplaint = true;
    }
  });
  return hasChiefComplaint;
}

export const getNoteSubHeaderText = (noteData: IPatientNoteCardProps | undefined) => {
  const isSignedNote = (noteData && (noteData.status === DocStatus.AMENDED || noteData.status === DocStatus.FINAL));
  if (noteData && !isSignedNote) {
    return ['In Progress Note']
  }
  if (noteData && isSignedNote) {
    if (noteData.authorUuid && noteData?.signedByUserId && noteData.authorUuid === noteData?.signedByUserId) {
      return [`Created and signed by ${noteData.signedByUserName}`];
    } else {
      return [
        `Created by ${noteData.createdByUserName}`,
        `Signed by ${noteData.signedByUserName}`,
      ];
    }
  }
  return [];
}

export const getNoteSubHeaderTextForSignedNoteView = (
  auditVersionDetails?: INoteAuditTrail,
  accountUserList?: any[],
  authorUuid?: string
) => {
  const actionPerformedBy = getUserNameById(auditVersionDetails?.userId, accountUserList || []);
  const createdBy = authorUuid ? `Created by ${authorUuid}` : undefined;

  if (!auditVersionDetails || !actionPerformedBy) {
    return [];
  }

  const isActionPerformedByAuthor = authorUuid === auditVersionDetails.userId;

  if (auditVersionDetails.action === RESOURCE_AUDIT_STATUS.AMEND) {
    if (isActionPerformedByAuthor) {
      return [`Created, Amended and Signed by ${actionPerformedBy}`];
    }
    return createdBy
      ? [createdBy, `Amended and Signed by ${actionPerformedBy}`]
      : [`Amended and Signed by ${actionPerformedBy}`];
  }

  if (auditVersionDetails.action === RESOURCE_AUDIT_STATUS.SIGN) {
    if (isActionPerformedByAuthor) {
      return [`Created and signed by ${actionPerformedBy}`];
    }
    return createdBy
      ? [createdBy, `Signed by ${actionPerformedBy}`]
      : [`Signed by ${actionPerformedBy}`];
  }

  return [];
};

export const getNoteHeaderText = (noteData: IPatientNoteCardProps | undefined) => {
  if (!noteData || !noteData.formName || !noteData.description) {
    return [`New Note`];
  }
  const isSignedNote = noteData && noteData.status === DocStatus.FINAL;
  if (isSignedNote) {
    return [`Signed Note: ${noteData.description || noteData.formName}`];
  }
  return [clipText(`${noteData.description || noteData.formName}`, 26)]
}


export const getAuditStatusByCode = (auditStatus: string | undefined) => {
  if (auditStatus) {
    switch (auditStatus) {
      case RESOURCE_AUDIT_STATUS.CREATE:
        return ' Created By ';
      case RESOURCE_AUDIT_STATUS.AMEND:
        return ' Amended and Signed By ';
      case RESOURCE_AUDIT_STATUS.SIGN:
        return ' Signed  By ';
      case RESOURCE_AUDIT_STATUS.UNLOCK:
        return ' Amended By ';
    }
  }
  return '';
};

export const renderAuditTrailText = (
  auditTrail: INoteAuditTrail,
  accountUserList: any[],
) => {
  const auditByUser = getUserNameById(
    auditTrail?.userId,
    accountUserList || [],
  );
  const auditStatusName = getAuditStatusByCode(auditTrail?.action);
  return `${auditStatusName} ${auditByUser} on ${getDateStrFromFormatWithTimezone(
    auditTrail.actionDate,
    getCurrentTimeZone(),
    DATE_FORMATS.MESSAGE_DATE_FORMAT,
  )}`;
};

export const isAuditMatchWithStatus = (audiTrail: INoteAuditTrail, statusToCheck: string[]) => {
  return statusToCheck.includes(audiTrail.action);
};

export const getFilterResourceAudit = (resourceAuditData?: INoteAuditTrail[], isSignedNoteView?: boolean) => {
  const auditStatusCodes = isSignedNoteView ? [RESOURCE_AUDIT_STATUS.AMEND, RESOURCE_AUDIT_STATUS.SIGN] : [RESOURCE_AUDIT_STATUS.AMEND, RESOURCE_AUDIT_STATUS.SIGN, RESOURCE_AUDIT_STATUS.UNLOCK];
  let filteredData = (resourceAuditData || []).filter(audiTrail => {
      return isAuditMatchWithStatus(audiTrail, auditStatusCodes);
  });

  if (isSignedNoteView) {
      filteredData = filteredData.sort((a, b) => {
          return new Date(b.actionDate)?.getTime() - new Date(a.actionDate)?.getTime();
      });
  }
  return filteredData;
};
