import React from 'react';
import {CLOUD_TELEPHONY_CODE, GROUP_MEMBER_TYPE} from '../../../../../constants';
import {CONVERSATION_ACTION_CODES, GROUP_ACTION_CODES} from '../../../../../constants/ActionConst';
import BaseService from '../../../../../services/CommonService/BaseService';
import {IGroupMember, IGroupUser} from '../../../../../services/Conversations/interfaces';
import {isCallMessageType, isChannelEmail, isChannelTwillioSms, isEFaxChannelInboxType} from '../../../../../utils/commonUtils';
import ArchivedIcon from '../../../../common/Svg/ArchivedIcon';
import AssignUserSvg from '../../../../common/Svg/AssignUserSvg';
import ChatCircleSvg from '../../../../common/Svg/ChatCircleSvg';
import AllConversationSvg from '../../../../common/Svg/ConversationAllSvg/AllConversationSvg';
import CallIncomingAvatar from '../../../../common/Svg/ConversationAllSvg/CallIncomingAvatar';
import CallMissedAvatar from '../../../../common/Svg/ConversationAllSvg/CallMissedAvatar';
import CallNewSvg from '../../../../common/Svg/ConversationAllSvg/CallNewSvg';
import CallOutgoingAvatar from '../../../../common/Svg/ConversationAllSvg/CallOutgoingAvatar';
import ChatCircleAvatar from '../../../../common/Svg/ConversationAllSvg/ChatCircleAvatar';
import FavouriteSvg from '../../../../common/Svg/ConversationAllSvg/FavouriteSvg';
import InternalChatSvg from '../../../../common/Svg/ConversationAllSvg/InternalChatSvg';
import MailCircleAvatar from '../../../../common/Svg/ConversationAllSvg/MailCircleAvatar';
import MentionIcon from '../../../../common/Svg/ConversationAllSvg/MentionIcon';
import MissedCallSvg from '../../../../common/Svg/ConversationAllSvg/MissedCallSvg';
import SMSCircleAvatar from '../../../../common/Svg/ConversationAllSvg/SMSCircleAvatar';
import StarredSvg from '../../../../common/Svg/ConversationAllSvg/StarredSvg';
import MailCircleSvg from '../../../../common/Svg/MailCircleSvg';
import ChatActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ChatActionSvg';
import EmailActionSvg from '../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import SMSCircleSvg from '../../../../common/Svg/SMSCircleSvg';
import {ICallLogEntry} from '../../CallLogs/interface';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../ConversationConst';
import {canShowArchive, isGroupConversation, isInternalChatConversation, isMissedCallMessageInUnreadMessages, isUserMentionedInSelectedConversation} from '../MessagingWindow/MessagingUtils';
import {IConversationData} from '../interfaces';
import {ConversationMenuObj, IConversationChannelList, IUnreadParams, IUserConversationFilterResp, IUserPreferenceContactType} from './Interfaces';
import UnassignUserSvg from '../../../../common/Svg/UnassignUserSvg';
import {IAccountUsers} from '../../../../PersonOmniView/MiddleContainer/FormResponseTableView/interfaces';
import ConversationSelectionSvg from '../../../../common/Svg/ConversationAllSvg/ConversationSelectionSvg';
import EFaxActionSvg from '../../../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import EFaxCircleActionSvg from '../../../../common/Svg/PersonActionSvgIcons/EFaxCircleActionSvg';
import PhoneChangeSvg from '../../../../common/Svg/ConversationAllSvg/PhoneChangeSvg';
import { TWILIO_CALL_STATUS } from '../../CallLogs/CallLogsConst';
import { ISideCarConversationTabList } from '../../../../common/SideCar/SideCarConversationTabBar';
import ChatSquareAvatar from '../../../../common/Svg/ConversationAllSvg/ChatSquareAvatarSvg';
import SMSSquareAvatar from '../../../../common/Svg/ConversationAllSvg/SMSSquareAvatar';
import MailSquareAvatar from '../../../../common/Svg/ConversationAllSvg/MailSquareAvatar';
import CallIncomingSquareAvatar from '../../../../common/Svg/ConversationAllSvg/CallIncomingSquareAvatar';
import CallMissedSquareAvatar from '../../../../common/Svg/ConversationAllSvg/CallMissedSquareAvatar';
import CallOutgoingSquareAvatar from '../../../../common/Svg/ConversationAllSvg/CallOutgoingSquareAvatar';
import EFaxSquareAvatar from '../../../../common/Svg/PersonActionSvgIcons/EFaxSquareAvatar';
import SMSPopoverActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SMSPopoverActionSvg';
import MailPopoverActionSvg from '../../../../common/Svg/PersonActionSvgIcons/MailPopoverActionSvg';
import ChatPopoverActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ChatPopoverActionSvg';
import VideoPopoverActionSvg from '../../../../common/Svg/PersonActionSvgIcons/VideoPopoverActionSvg';
import AppointmentPopoverActionSvg from '../../../../common/Svg/PersonActionSvgIcons/AppointmentPopoverActionSvg';
import FileIconActionSvg from '../../../../common/Svg/PersonActionSvgIcons/FileIconActionSvg';
import SelectChatActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SelectChatActionSvg';
import ChartActionSvg from '../../../../common/Svg/ConversationAllSvg/ChartActionSvg';
import PhonePopoverActionSvg from '../../../../common/Svg/PersonActionSvgIcons/PhonePopoverActionSvg';
import ScheduleMsgActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ScheduleMsgActionSvg';
import CopyActionSvg from '../../../../common/Svg/PersonActionSvgIcons/CopyActionSvg';
import GroupActionSvg from '../../../../common/Svg/PersonActionSvgIcons/GroupActionSvg';
import ArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ArchiveActionSvg';
import UnArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/UnArchiveActionSvg';
import AssignedToOthersSvg from '../../../../common/Svg/AssignedToOthersSvg';
import SearchActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SearchActionSvg';

export const DISPLAY_CONVERSATION_SIDEBAR_LIST: ConversationMenuObj[] = [
  {
    header: 'Inbox',
    intlId: 'inboxChannelHeader',
    list: [
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
        title: 'Assigned to me',
        intlId: 'assignedToMeTab',
        path: 'assigned_me',
        code: 'ASSIGN_TO_ME_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
        imagePath: require('../../../../../assets/images/Conversations/assigned_to_me.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
        title: 'Assigned to others',
        intlId: 'assignedToOthersTab',
        path: 'assigned_other',
        code: 'ASSIGN_TO_OTHER_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
        imagePath: require('../../../../../assets/images/Conversations/assigned_to_me.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
        title: 'Unassigned',
        intlId: 'unassignedTab',
        path: 'unassigned',
        code: 'UNASSIGN_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
        imagePath: require('../../../../../assets/images/MemberActions/unassign.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_MENTION,
        title: 'Mentions',
        intlId: 'mentionsTab',
        path: 'mentions',
        code: 'MENTION_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_MENTION,
        imagePath: require('../../../../../assets/images/Conversations/mentions.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS,
        title: 'Missed Calls',
        intlId: 'missedCallsTab',
        path: 'missed',
        code: 'MISSED_CALL_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS,
        imagePath: require('../../../../../assets/images/Conversations/missed_call.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
        title: 'Archived',
        intlId: 'archivedTab',
        path: 'archived',
        code: 'ARCHIVED_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
        imagePath: require('../../../../../assets/images/Conversations/archived.png'),
      },
    ],
  },
  {
    header: 'Channels',
    intlId: 'channelHeader',
    list: [
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_ALL,
        title: 'All',
        intlId: 'allTab',
        path: 'allConversations',
        code: 'ALL_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_ALL,
        imagePath: require('../../../../../assets/images/Conversations/allConversation.png')
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
        title: 'Chats',
        intlId: 'chatsTab',
        path: 'conversations',
        code: 'GROUP_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
        imagePath: require('../../../../../assets/images/Conversations/messages.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
        title: 'SMS',
        intlId: 'smsTab',
        path: 'sms',
        code: 'SMS_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
        imagePath: require('../../../../../assets/images/Conversations/sms.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.ALL_CALLS,
        title: 'Calls',
        path: 'all_calls',
        intlId: 'callsTab',
        code: 'ALL_CALLS',
        tabCode: CHANNEL_TYPE_CODE.ALL_CALLS,
        imagePath: require('../../../../../assets/images/Conversations/call.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
        title: 'Email',
        intlId: 'emailTab',
        path: 'email',
        code: 'EMAIL_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
        imagePath: require('../../../../../assets/images/Conversations/email.png'),
      },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
        title: 'Fax',
        intlId: 'faxTab',
        path: 'fax',
        code: 'FAX_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
        imagePath: require('../../../../../assets/images/Conversations/efax.png'),
      },
      // {
      //   key: CHANNEL_TYPE_CODE.CALL_LOGS,
      //   title: 'Calls',
      //   path: 'call-logs',
      //   intlId: 'callTab',
      //   // code: 'CALLS',
      //   tabCode: CHANNEL_TYPE_CODE.CALL_LOGS,
      //   imagePath: require('../../../../../assets/images/Conversations/call.png'),
      // },
      {
        key: CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
        backgroundColor: '#F37232',
        showLeftBorder: true,
        title: 'Internal Chats',
        intlId: 'internalChatsTab',
        path: 'internal',
        code: 'INTERNAL_CHAT',
        tabCode: CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
        imagePath: require('../../../../../assets/images/Conversations/internal.png'),
      },
    ],
  },
];

export const SIDECAR_CONVERSATION_TAB_BAR_LIST: ISideCarConversationTabList[] = [
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_ALL,
    title: 'All',
    code: 'ALL_CHAT',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
    title: 'Assigned to me',
    code: 'ASSIGN_TO_ME_CHAT',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_MENTION,
    title: 'Mentions',
    code: 'MENTION_CHAT',
  },
  {
    key: CHANNEL_TYPE_CODE.ALL_CALLS,
    title: 'Calls',
    code: 'ALL_CALLS',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
    title: 'Internal Chats',
    code: 'INTERNAL_CHAT',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
    title: 'Assigned to others',
    code: 'ASSIGN_TO_OTHER_CHAT',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
    title: 'Unassigned',
    code: 'UNASSIGN_CHAT',
  },
  {
    key: CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
    title: 'Archived',
    code: 'ARCHIVED_CHAT',
  }
];

export const DISPLAY_CONVERSATION_SIDEBAR_ICON = (
  code: string,
  color: string
) => {
  switch (code) {
    case CONVERSATION_ACTION_CODES.COPY:
      return <CopyActionSvg customStrokeColor={color} width={16} height={16} />;
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME:
      return <AssignUserSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER:
      return <AssignedToOthersSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED:
      return <UnassignUserSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_MENTION:
      return <MentionIcon customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_STARRED:
      return <StarredSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED:
      return <ArchivedIcon customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_ALL:
      return <AllConversationSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
      return <ChatActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
      return <ChatPopoverActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
      return <SmsActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.START_SMS:
      return <SMSPopoverActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
      return <EmailActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.START_EMAIL:
      return <MailPopoverActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CALL_LOGS:
      return <PhonePopoverActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_INTERNAL:
      return <InternalChatSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
      return <VideoPopoverActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.CHART:
      return <ChartActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.FAVOURITE:
      return <FavouriteSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.FILES:
      return <FileIconActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.FAVOURITE:
      return <FavouriteSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT:
      return <AppointmentPopoverActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS:
      return <MissedCallSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST:
      return <ScheduleMsgActionSvg customStrokeColor={color} />;
    case GROUP_ACTION_CODES.GROUP_HEADER_CLICKED:
      return <GroupActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.SELECT_CHAT:
      return <SelectChatActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.ARCHIVE:
      return <ArchiveActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.UNARCHIVE:
      return <UnArchiveActionSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.ASSIGN:
      return <AssignUserSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT:
      return <PhoneChangeSvg customStrokeColor={color} />;
    case CONVERSATION_ACTION_CODES.SEARCH_MESSAGE:
      return <SearchActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
      return <EFaxActionSvg customStrokeColor={color} />;
    case CHANNEL_TYPE_CODE.ALL_CALLS:
      return <CallNewSvg customStrokeColor={color} />;
    default:
      return <AllConversationSvg customStrokeColor={color} />;
  }
};

export const DISPLAY_CONVERSATION_MORE_OPTION_LIST: IConversationChannelList[] =
  [
    {
      key: CONVERSATION_ACTION_CODES.COPY,
      title: 'Copy',
      path: '',
    },
    {
      key: GROUP_ACTION_CODES.GROUP_HEADER_CLICKED,
      title: 'Edit Group',
      path: '',
    },
    {
      key: CHANNEL_TYPE_CODE.CALL_LOGS,
      title: 'Calls',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      title: 'Chats',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_SMS,
      title: 'SMS',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_EMAIL,
      title: 'Email',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      title: 'Video',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.CHART,
      title: 'Chart',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
      title: 'Appointment',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.FILES,
      title: 'Files',
      path: ''
    },
    {
      key: CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
      title: 'Scheduled messages',
      path: ''
    },
    {
      key: CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT,
      title: 'Update Contact Display',
      path: ''
    },
    // {
    //   key: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
    //   title: 'Chat',
    //   path: ''
    // },
    // {
    //     key: CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
    //     title: 'Archived',
    //     path: 'archived',
    // },
    // {
    //     key: CONVERSATION_ACTION_CODES.FAVOURITE,
    //     title: 'Favourite',
    //     path: '',
    // },
  ];

  export const COMMUNICATION_ACTION_LIST = [
    CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
    CONVERSATION_ACTION_CODES.START_SMS,
    CONVERSATION_ACTION_CODES.START_EMAIL,
    CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
    CHANNEL_TYPE_CODE.CALL_LOGS
  ]


  export const DISPLAY_CONVERSATION_MORE_OPTION_LIST_WITH_CONTAINER: IConversationChannelList[] =
  [
    {
      key: CONVERSATION_ACTION_CODES.SELECT_CHAT,
      title: 'Select Chats',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.ARCHIVE,
      title: 'Archive',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.UNARCHIVE,
      title: 'Unarchive',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.ASSIGN,
      title: 'Assign',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.ASSIGNED,
      title: 'Assigned',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
      title: 'Search',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.COPY,
      title: 'Copy',
      path: '',
    },
    {
      key: GROUP_ACTION_CODES.GROUP_HEADER_CLICKED,
      title: 'Edit Group',
      path: '',
    },
    {
      key: CHANNEL_TYPE_CODE.CALL_LOGS,
      title: 'Calls',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      title: 'Chats',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_SMS,
      title: 'SMS',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_EMAIL,
      title: 'Email',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      title: 'Video',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.CHART,
      title: 'Chart',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
      title: 'Appointment',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.FILES,
      title: 'Files',
      path: ''
    },
    {
      key: CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
      title: 'Scheduled messages',
      path: ''
    },
    {
      key: CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT,
      title: 'Update Contact Display',
      path: ''
    },
  ];

export const INTERNAL_CONVERSATION_MORE_OPTION_LIST: IConversationChannelList[] =
  [
    {
      key: CONVERSATION_ACTION_CODES.SELECT_CHAT,
      title: 'Select Chats',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
      title: 'Search',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.COPY,
      title: 'Copy',
      path: '',
    },
    {
      key: GROUP_ACTION_CODES.GROUP_HEADER_CLICKED,
      title: 'Edit Group',
      path: '',
    },
    // {
    //   key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
    //   title: 'Video',
    //   path: '',
    // },
    // {
    //   key: CONVERSATION_ACTION_CODES.CHART,
    //   title: 'Chart',
    //   path: '',
    // },
    {
      key: CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
      title: 'Scheduled messages',
      path: ''
    },
  ];
  export const BROADCAST_MORE_OPTION_LIST: IConversationChannelList[] =
  [
    {
      key: CONVERSATION_ACTION_CODES.COPY,
      title: 'Copy',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.FILES,
      title: 'Files',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
      title: 'Scheduled messages',
      path: ''
    },
  ];

export const getConversationTypeImgSvg = (
  singleConversationData: IConversationData
): JSX.Element => {
  if (singleConversationData?.conversationInbox?.channelType) {
    const type = singleConversationData?.conversationInbox?.channelType || '';
    if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
      return <MailCircleSvg></MailCircleSvg>;
    } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      return <SMSCircleSvg></SMSCircleSvg>;
    } else {
      return <ChatCircleSvg></ChatCircleSvg>;
    }
  } else {
    return <ChatCircleSvg></ChatCircleSvg>;
  }
};

export const getConversationTypeAvatar = (
  singleConversationData: IConversationData,
  callLastActivityCode?: string,
  selectedInboxTypeCode?: string,
  enableConversationSelection?: boolean
): JSX.Element => {
  if (enableConversationSelection) {
    return <ConversationSelectionSvg/>
  }
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS) {
    if (callLastActivityCode) {
      if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
        return <CallIncomingAvatar />;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
        return <CallOutgoingAvatar />;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
        return <CallMissedAvatar />;
      }
    }
    return <CallMissedAvatar />;
  } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS) {
    if (callLastActivityCode) {
      if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
        return <CallIncomingAvatar />;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
        return <CallOutgoingAvatar />;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
        return <CallMissedAvatar />;
      }
    };
    return <CallMissedAvatar />;
  } else if (singleConversationData?.conversationInbox?.channelType) {
    const type = singleConversationData?.conversationInbox?.channelType || '';
    if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
      return <MailCircleAvatar />;
    } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      if (callLastActivityCode) {
        if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
          return <CallIncomingAvatar />;
        } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
          return <CallOutgoingAvatar />;
        } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
          return <CallMissedAvatar />;
        }
      }
      return <SMSCircleAvatar />;
    } else if (type === CHANNEL_TYPE.CHANNEL_EFAX) {
      return <EFaxCircleActionSvg/>
    } else {
      const isInternalChatGroup = isInternalChatConversation({
        selectedConversation: singleConversationData
      })
      return <ChatCircleAvatar isInternalChat={isInternalChatGroup ? true : false} width={42} height={42} />;
    }
  } else {
    const isInternalChatGroup = isInternalChatConversation({
      selectedConversation: singleConversationData
    })
    return <ChatCircleAvatar isInternalChat={isInternalChatGroup ? true : false} />;
  }
};

export const getConversationTypeAvatarV2 = (
  singleConversationData: IConversationData,
  callLastActivityCode?: string,
  selectedInboxTypeCode?: string,
  enableConversationSelection?: boolean
): JSX.Element => {
  if (enableConversationSelection) {
    return <ConversationSelectionSvg width={36} height={36}/>
  }
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS) {
    if (callLastActivityCode) {
      if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
        return <CallIncomingSquareAvatar width={36} height={36}/>;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
        return <CallOutgoingSquareAvatar width={36} height={36}/>;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
        return <CallMissedSquareAvatar width={36} height={36}/>;
      }
    }
    return <CallMissedSquareAvatar width={36} height={36}/>;
  } else if (singleConversationData?.conversationInbox?.channelType) {
    const type = singleConversationData?.conversationInbox?.channelType || '';
    if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
      return <MailSquareAvatar width={36} height={36}/>;
    } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      if (callLastActivityCode) {
        if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
          return <CallIncomingSquareAvatar width={36} height={36} />;
        } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
          return <CallOutgoingSquareAvatar width={36} height={36}/>;
        } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
          return <CallMissedSquareAvatar width={36} height={36}/>;
        }
      }
      return <SMSSquareAvatar width={36} height={36}/>;
    } else if (type === CHANNEL_TYPE.CHANNEL_EFAX) {
      return <EFaxSquareAvatar width={36} height={36}/>
    } else {
      const isInternalChatGroup = isInternalChatConversation({
        selectedConversation: singleConversationData
      })
      return <ChatSquareAvatar isInternalChat={isInternalChatGroup ? true : false} width={36} height={36} />;
    }
  } else {
    const isInternalChatGroup = isInternalChatConversation({
      selectedConversation: singleConversationData
    })
    return <ChatSquareAvatar isInternalChat={isInternalChatGroup ? true : false} width={36} height={36} />;
  }
}

export const getCallLogsAvatar = (callLogData: ICallLogEntry) => {
  if (callLogData?.direction == 'inbound') {
    if (callLogData.status == 'completed') {
      return <CallIncomingSquareAvatar width={36} height={36}/>;
    } else if (
      callLogData.status == TWILIO_CALL_STATUS.BUSY ||
      callLogData.status == TWILIO_CALL_STATUS.NO_ANSWER ||
      callLogData.status == TWILIO_CALL_STATUS.FAILED
    ) {
      return <CallMissedSquareAvatar width={36} height={36}/>;
    } else {
      return <CallIncomingSquareAvatar width={36} height={36}/>;
    }
  } else if (callLogData?.direction == 'Outgoing Dial') {
    return <CallOutgoingSquareAvatar width={36} height={36}/>;
  }
  return <CallIncomingSquareAvatar width={36} height={36}/>;
}

export const getGroupName = (singleConversationData: IConversationData): string => {
  return singleConversationData?.groupConversation?.name || '';
};

export const getConversationUnreadCount = async (args: {
  type: IConversationTypes;
}) => {
  const {type} = args;
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.post(
      'crm-nest/conversation/unread-count',
      {
        conversationTypes: [type],
      }
    );
    return response.data;
  } catch (error) {

    return error;
  }
};

export const getConversationUnreadCountAll = async (args: {
  type: IConversationTypes[];
}) => {
  const {type} = args;
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.post(
      'crm-nest/conversation/unread-count',
      {
        conversationTypes: type,
      }
    );
    return response.data;
  } catch (error) {

    return error;
  }
};

export const getConversationUnreadCountAllV2 = async (args: {
  type: IConversationTypes[];
  userPreferenceContactType?: IUserPreferenceContactType;
}) => {
  const {type, userPreferenceContactType} = args;
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.post(
      'crm-nest/conversation/v2/unread-count',
      {
        conversationTypes: type,
        userPreferenceContactType
      }
    );
    return response.data;
  } catch (error) {

    return error;
  }
};
export const getAllConversationUnreadCount = async (typeArray?: IConversationTypes[]) => {
  const promiseList = (typeArray || CONVERSATION_TYPES_ARRAY).map((item) => {
    return getConversationUnreadCount({type: item});
  });
  try {
    const response = await Promise.all(promiseList);
    const unreadMap = getUnreadCountMap({response});
    return unreadMap;
  } catch (error) {
    return error;
  }
};

export const getAllConversationUnreadCountForAllTabs = async () => {
  try {
    const response = await getConversationUnreadCountAll({type: CONVERSATION_TYPES_ARRAY} as any);
    let unreadMap = [];
    if (response && Object.keys(response)?.length) {
      unreadMap = getUnreadCountMapForAll(response);
    }
    return unreadMap;
  } catch (error) {
    return error;
  }
};

export const getAllConversationUnreadCountForAllTabsV2 = async (userPreferenceContactType?: IUserPreferenceContactType) => {
  try {
    const response = await getConversationUnreadCountAllV2({type: CONVERSATION_TYPES_ARRAY, userPreferenceContactType} as any);
    let unreadMap = [];
    if (response && Object.keys(response)?.length) {
      unreadMap = getUnreadCountMapForAllV2(response);
    }
    return unreadMap;
  } catch (error) {
    return getUnreadCountMapForAllV2(unreadCountDefaultData);
  }
};

const getUnreadCountMapForAllV2 = (response: any) => {
  const unreadMap: any = {};
  const defaultValue = {
    count:0,
    conversationIdList: [],
    messageCount:0
  };
  const keys = Object.keys(response);
  keys.forEach((singleKey: string) => {
    unreadMap[singleKey] = response?.[singleKey]?.data ?? {};
  });
  unreadMap['ALL_CHAT'] = defaultValue;
  unreadMap['ARCHIVED_CHAT'] = defaultValue
  unreadMap['ALL_MESSAGE_CHAT'] = defaultValue;
  return unreadMap;
};

const getUnreadCountMapForAll = (response: any) => {
  const unreadMap: any = {};
  let total = 0;
  let messageTotal = 0;
  const keys = Object.keys(response);
  keys.forEach((singleKey: string) => {
    const count = response?.[singleKey]?.data?.count ?? 0;
    const messageCount = response?.[singleKey]?.data?.messageCount ?? 0;
    unreadMap[singleKey] = response?.[singleKey]?.data?.count ?? 0;
    if (isUpdateAllCount({key: singleKey})) {
      total = total + count;
    }
    if (isUpdateMessageCount({key: singleKey})) {
      messageTotal = messageTotal + messageCount;
    }
  });
  unreadMap['ALL_CHAT'] = total;
  unreadMap['ARCHIVED_CHAT'] = 0
  unreadMap['ALL_MESSAGE_CHAT'] = messageTotal;
  return unreadMap;
};


const getUnreadCountMap = (args: {response: any[]}) => {
  const {response} = args;
  const unreadMap: any = {};
  let total = 0;
  let messageTotal = 0;
  response.forEach((item) => {
    const key = Object.keys(item)[0];
    const count = item?.[key]?.data?.count ?? 0;
    const messageCount = item?.[key]?.data?.messageCount ?? 0;
    unreadMap[key] = item?.[key]?.data?.count ?? 0;
    if (isUpdateAllCount({key})) {
      total = total + count;
    }
    if (isUpdateMessageCount({key})) {
      messageTotal = messageTotal + messageCount;
    }
  });
  unreadMap['ALL_CHAT'] = total;
  unreadMap['ARCHIVED_CHAT'] = 0
  unreadMap['ALL_MESSAGE_CHAT'] = messageTotal;
  return unreadMap;
};

export const getFinalConversationList = (
  displayTabCodeList: IConversationChannelList[],
  isDetailsContainerVisible?: boolean,
  selectedConversation?: IConversationData,
  isPatientMessagingEnabled?: boolean,
  selectedInboxTypeCode?: string,
  isShowFinallyAssignee?: boolean,
  assignUserName?: string,
  isInstantChatView?: boolean,
  isPatientSidecarContext?: boolean
) => {
  let array = [...displayTabCodeList];
  if (isPatientSidecarContext) {
    const removedActionArray = [
      // CONVERSATION_ACTION_CODES.START_CONVERSATION,
      // CONVERSATION_ACTION_CODES.START_SMS,
      // CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      // CONVERSATION_ACTION_CODES.START_EMAIL,
      // CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
      // CHANNEL_TYPE_CODE.CALL_LOGS
    ];
    array = array.filter((item) => {
      return !removedActionArray.includes(item.key);
    });
  }
  if (!isPatientMessagingEnabled) {
    array = array.filter((item)=> {
      return item.key !== CONVERSATION_ACTION_CODES.START_GROUP_CHAT
    })
  }
  if (isInstantChatView) {
    array = array.filter((item)=> {
      return item.key !== CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT
    })
  }
  if (selectedInboxTypeCode && canShowArchive(selectedInboxTypeCode ?? '', selectedConversation)) {
   if (selectedConversation?.status !== 1 ) {
    array = array.filter((item)=> {
      return (item.key !== CONVERSATION_ACTION_CODES.UNARCHIVE)
    })
   } else {
    array = array.filter((item)=> {
      return (item.key !== CONVERSATION_ACTION_CODES.ARCHIVE)
    })
   }
  } else {
    array = array.filter((item)=> {
      return (item.key !== (CONVERSATION_ACTION_CODES.ARCHIVE) && (item.key !== CONVERSATION_ACTION_CODES.UNARCHIVE))
    })
  }
  if (isShowFinallyAssignee) {
    if (assignUserName) {
      array = array.filter((item)=> {
        return (item.key !== CONVERSATION_ACTION_CODES.ASSIGN)
      })
    } else {
      array = array.filter((item)=> {
        return (item.key !== CONVERSATION_ACTION_CODES.ASSIGNED)
      })
    }
  } else {
    array = array.filter((item)=> {
      return (item.key !== CONVERSATION_ACTION_CODES.ASSIGNED && item.key !== CONVERSATION_ACTION_CODES.ASSIGN)
    })
  }
  const channelType = selectedConversation?.conversationInbox?.channelType || '';

  if (isEFaxChannelInboxType(channelType)) {
    const eFaxNotAllowedAction = [
      CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
      CONVERSATION_ACTION_CODES.CHART,
      CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
    ];
    array = array.filter((item) => {
      return !eFaxNotAllowedAction.includes(item.key);
    });
  }

  if (!isDetailsContainerVisible) {
    const newArray = array.filter((item: IConversationChannelList) => {
      if (isGroupConversation(selectedConversation || ({} as any))) {
        if (
          item.key !== CONVERSATION_ACTION_CODES.START_GROUP_CHAT
        ) {
          return item;
        }
      } else if (
        isChannelTwillioSms(channelType)
      ) {
        if (
          item.key !== GROUP_ACTION_CODES.GROUP_HEADER_CLICKED &&
          item.key !== CONVERSATION_ACTION_CODES.START_SMS &&
          item.key !== CONVERSATION_ACTION_CODES.FILES
        ) {
          return item;
        }
      } else if (isChannelEmail(channelType)) {
        if (
          item.key !== GROUP_ACTION_CODES.GROUP_HEADER_CLICKED &&
          item.key !== CONVERSATION_ACTION_CODES.START_EMAIL &&
          item.key !== CONVERSATION_ACTION_CODES.COPY &&
          item.key !== CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST
        ) {
          return item;
        }
      }
      else {
        if (item.key !== GROUP_ACTION_CODES.GROUP_HEADER_CLICKED) {
          return item;
        }
      }
    })
    return newArray
  } else {
    if (!isGroupConversation(selectedConversation || {} as any)) {
      const newArray = array.filter((item: IConversationChannelList) => {
        if (item.key !== GROUP_ACTION_CODES.GROUP_HEADER_CLICKED) {
          return item
        }
      })
      array = newArray;
      if (isDetailsContainerVisible && isChannelTwillioSms(channelType)) {
        const newArray = array.filter((item: IConversationChannelList) => {
          if (item.key !== CONVERSATION_ACTION_CODES.START_SMS) {
            return item
          }
        })
        array = newArray;
      }
      if (isChannelEmail(selectedConversation?.conversationInbox?.channelType || '')) {
        const newArray = array.filter((item: IConversationChannelList) => {
          if (
            item.key !== CONVERSATION_ACTION_CODES.START_EMAIL &&
            item.key !== CONVERSATION_ACTION_CODES.COPY &&
            item.key !== CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST &&
            item.key !== CONVERSATION_ACTION_CODES.SELECT_CHAT &&
            item.key !== CONVERSATION_ACTION_CODES.SEARCH_MESSAGE
          ) {
            return item;
          }
        })
        array = newArray;
      }
    }
    else if (isDetailsContainerVisible && isGroupConversation(selectedConversation || {} as any)) {
      const newArray = array.filter((item: IConversationChannelList) => {
        if (item.key !== CONVERSATION_ACTION_CODES.START_GROUP_CHAT) {
          return item
        }
      })
      array = newArray;
    }
  }
  return array as IConversationChannelList[];
}
// export const getChannelList =((isEmployer: boolean)=> {
//   if(isEmployer && USER_PERMISSIONS_CONST.IS_EMPLOYER_OWNER_SHOW_ONLY_INTERNAL){
//     const internalChat= DISPLAY_CHANNEL_LIST[DISPLAY_CHANNEL_LIST.length - 1]
//       internalChat.title = 'Chats'
//     return [internalChat];
//   }else{
//     return DISPLAY_CHANNEL_LIST;
//   }
// })

export const getCountString = (args: {count: number}) => {
  const {count} = args;
  return count;
  if (count > 9) {
    return `9+`;
  }
  return count.toString();
};

export const CONVERSATION_TYPES = {
  GROUP_CHAT: 'GROUP_CHAT',
  EMAIL_CHAT: 'EMAIL_CHAT',
  SMS_CHAT: 'SMS_CHAT',
  ASSIGN_TO_ME_CHAT: 'ASSIGN_TO_ME_CHAT',
  MENTION_CHAT: 'MENTION_CHAT',
  MISSED_CALL_CHAT: 'MISSED_CALL_CHAT',
  ARCHIVED_CHAT: 'ARCHIVED_CHAT',
  INTERNAL_CHAT: 'INTERNAL_CHAT',
  ALL_CHAT: 'ALL_CHAT',
  ALL_CALLS: 'ALL_CALLS',
  ASSIGN_TO_OTHER_CHAT: 'ASSIGN_TO_OTHER_CHAT',
  UNASSIGN_CHAT: 'UNASSIGN_CHAT',
  FAX_CHAT: 'FAX_CHAT'
};

export type IConversationTypes = keyof typeof CONVERSATION_TYPES;

export const CONVERSATION_TYPES_ARRAY: IConversationTypes[] = [
  'GROUP_CHAT',
  'EMAIL_CHAT',
  'SMS_CHAT',
  'ASSIGN_TO_ME_CHAT',
  'MENTION_CHAT',
  'MISSED_CALL_CHAT',
  'ARCHIVED_CHAT',
  'INTERNAL_CHAT',
  'UNASSIGN_CHAT',
  'FAX_CHAT'
];

export const unreadCountDefaultData = {
  GROUP_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  EMAIL_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  SMS_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  ASSIGN_TO_ME_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  MENTION_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  MISSED_CALL_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  ARCHIVED_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  INTERNAL_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  UNASSIGN_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
  FAX_CHAT: {
    data: {
      count: 0,
      conversationIdList: [],
      messageCount: 0
    }
  },
};

export const INITIAL_UNREAD_MAP = {
  ARCHIVED_CHAT: 0,
  ASSIGN_TO_ME_CHAT: 0,
  EMAIL_CHAT: 0,
  GROUP_CHAT: 0,
  INTERNAL_CHAT: 0,
  MENTION_CHAT: 0,
  MISSED_CALL_CHAT: 0,
  SMS_CHAT: 0,
  ALL_CHAT: 0,
  ALL_CALLS: 0,
  ASSIGN_TO_OTHER_CHAT: 0,
  UNASSIGN_CHAT: 0,
  ALL_MESSAGE_CHAT: 0,
  FAX_CHAT: 0,
};


export const INITIAL_UNREAD_MAP_V2: { [key: string]: {
  count: number,
  conversationIdList: {uuid:string}[],
  messageCount: number
} } = {
  ARCHIVED_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  ASSIGN_TO_ME_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  EMAIL_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  GROUP_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  INTERNAL_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  MENTION_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  MISSED_CALL_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  SMS_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  ALL_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  ALL_CALLS: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  ASSIGN_TO_OTHER_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  UNASSIGN_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  ALL_MESSAGE_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  },
  FAX_CHAT: {
    count:0,
    conversationIdList: [],
    messageCount:0
  }
};

export const isInternalChat = (args: {messageData: any}) => {
  const {messageData} = args;
  const groupCode: string =
    messageData?.conversation?.groupConversation?.groupType?.code || '';
  return groupCode && groupCode.toLowerCase().includes('internal');
};

export const isConversationArchived = (args: {messageData: any}) => {
  const {messageData} = args;
  const status = messageData?.conversation?.status;
  return status == 1;
};

export const isUserMentionedInMessage = (args: {messageData: any}) => {
  const {messageData} = args;
  const mentionArray: any[] =
    messageData?.conversation?.conversationMentions || [];
  return mentionArray.length > 0;
};

export const getSearchParams = (): IUnreadParams => {
  const unreadParamsString = sessionStorage.getItem('unreadParams');
  const unreadParams = JSON.parse(unreadParamsString || '{}');
  const selectedConversationId = unreadParams['selectedConversationId'] || '0';
  const channelType = unreadParams['channelType'];
  const updateUnreadCount = unreadParams['updateUnreadCount'];
  const isUserMentioned = unreadParams['isUserMentioned'];
  const isMissedCallMessage = unreadParams['isMissedCallMessage'];
  const inboxId = unreadParams['inboxId'] || '0';
  const isInternalConversation = unreadParams['isInternalConversation'];
  const status = unreadParams['status'] || '0';
  const assigneeId = unreadParams['assigneeId'] || '-1';
  const conversationId = unreadParams['conversationId'] || '0'
  const conversationMentionId = unreadParams['conversationMentionId'] || '';
  const result: IUnreadParams = {
    selectedConversationDisplayId: parseInt(selectedConversationId),
    channelType: channelType,
    updateUnreadCount: updateUnreadCount,
    isUserMentioned: isUserMentioned,
    isMissedCallMessage: isMissedCallMessage,
    inboxId: parseInt(inboxId),
    isInternalConversation: isInternalConversation,
    status: parseInt(status),
    assigneeId: parseInt(assigneeId),
    conversationId: parseInt(conversationId),
    conversationUuid: unreadParams['conversationUuid'],
    conversationMentionId: conversationMentionId,
  };
  return result;
};

export const isUpdateAllCount = (args: {key: string}) => {
  const {key} = args;
  const includeCountArr = [
    CONVERSATION_TYPES.MISSED_CALL_CHAT,
    CONVERSATION_TYPES.SMS_CHAT,
    CONVERSATION_TYPES.EMAIL_CHAT,
    CONVERSATION_TYPES.GROUP_CHAT,
    CONVERSATION_TYPES.FAX_CHAT,
  ];
  return includeCountArr?.includes(key);
};

export const isUpdateMessageCount = (args: {key: string}) => {
  const {key} = args;
  const includeCountArr = [
    CONVERSATION_TYPES.MISSED_CALL_CHAT,
    CONVERSATION_TYPES.SMS_CHAT,
    CONVERSATION_TYPES.EMAIL_CHAT,
    CONVERSATION_TYPES.GROUP_CHAT,
    CONVERSATION_TYPES.INTERNAL_CHAT,
    CONVERSATION_TYPES.FAX_CHAT,
  ];
  return includeCountArr.includes(key);
};

export const setSearchParamsForSelectedConversation = async (args: {
  selectedConversation: IConversationData;
}) => {
  const {selectedConversation} = args;
  const unreadParams: any = {};
  const selectedConversationDisplayId = selectedConversation.displayId;
  const conversationId = selectedConversation.id;
  const conversationUuid = selectedConversation.uuid;
  const areUnreadMessagesPresent =
    selectedConversation?.unreadMsgCount > 0 ||
    !!selectedConversation.conversationMentionId;
  const conversationChannelType =
    selectedConversation?.conversationInbox?.channelType || '';
  const isMissedCallMessage = isMissedCallMessageInUnreadMessages({
    selectedConversation,
  });
  const isUserMentioned = isUserMentionedInSelectedConversation({
    selectedConversation,
  });
  const isInternalConversation = isInternalChatConversation({
    selectedConversation,
  });
  const inboxId = selectedConversation.inboxId;
  const status = selectedConversation.status;
  const assigneeId = selectedConversation.assigneeId;
  unreadParams['selectedConversationId'] = selectedConversationDisplayId;
  unreadParams['conversationId'] = conversationId;
  unreadParams['assigneeId'] = assigneeId;
  unreadParams['conversationUuid'] = conversationUuid;
  unreadParams['status'] = status;
  unreadParams['inboxId'] = inboxId;
  unreadParams['channelType'] = conversationChannelType;
  if (areUnreadMessagesPresent) {
    unreadParams['updateUnreadCount'] = true;
  } else {
    unreadParams['updateUnreadCount'] = false;
  }
  if (isUserMentioned) {
    unreadParams['isUserMentioned'] = true;
  } else {
    unreadParams['isUserMentioned'] = false;
  }
  if (isMissedCallMessage) {
    unreadParams['isMissedCallMessage'] = true;
  } else {
    unreadParams['isMissedCallMessage'] = false;
  }
  if (isInternalConversation) {
    unreadParams['isInternalConversation'] = true;
  } else {
    unreadParams['isInternalConversation'] = false;
  }
  if (selectedConversation.conversationMentionId) {
    unreadParams['conversationMentionId'] =
      selectedConversation.conversationMentionId;
  }
  sessionStorage.setItem('unreadParams', JSON.stringify(unreadParams));
};

export const resetSearchParamsForSelectedConversation = () => {
  sessionStorage.setItem('unreadParams', '');
};

export const getAccountUserByUserId = (accountUsers?: IAccountUsers[], userId?: number) => {
  const accountUser = accountUsers?.filter((user: any) => {
    return user?.userId === userId;
  })
  return accountUser || [];
}


export const getGroupMembersListForAssignee = (groupMembers: IGroupMember[], myUserUUID: string) => {
  const privateConversMembers: IGroupUser[] = [];
  groupMembers?.map((item: IGroupMember) => {
    if (item?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
      if (getAccountUserByUserId(item?.user?.accountUsers, item?.user?.id)?.[0]?.isActive === true) {
        privateConversMembers.push(item.user as IGroupUser)
      }
    }
  })
  return privateConversMembers;
}

export const isUpdateSMSCount = (args: {messageData: any}) => {
  const {messageData} = args;
  const messageType = messageData.message_type;
  return isCallMessageType({type: messageType});
};

export const isConversationArchivedFromMessage = (msgData: any) => {
  const status = msgData?.status;
  return status == 1;
};

export const getCodeAndUserConversationFilterData = (filterDataList: IUserConversationFilterResp[]): {
  [index: string]: IUserConversationFilterResp
} => {
  const filterCodeValueDataObj: {
    [index: string]: IUserConversationFilterResp
  } = {};
  filterDataList.forEach((singleFilterData) => {
    if (singleFilterData?.filterCode) {
      filterCodeValueDataObj[singleFilterData?.filterCode] = singleFilterData;
    }
  });
  return filterCodeValueDataObj;
}
