import { ITableFilter } from '../../../Interfaces';
import { ContactSMSData } from '../../common/SMSDrawer/interfaces';
import {getTenantForDemo} from '../Analytics/EmployerAnalytics/tempDataFile';
import { GroupMemberData } from './interfaces';

const LENGTH = 20;

export const EMPLOYEE_TYPE_FILTERS = {
  ONLY_EMPLOYEES: 'ONLY_EMPLOYEES',
  ALL: "ALL"
}

export const MEMBERSHIP_STATUS_FILTERS: ITableFilter[] = [
  {text: 'Inactive', value: 'inactive'},
  {text: 'Active', value: 'active'},
  {text: 'Unpaid', value: 'unpaid'},
  {text: 'Unconfirmed', value: 'unconfirmed'},
  {text: 'Ended', value: 'ended'},
  {text: 'Archived', value: 'archived'},
  {text: 'Pending', value: 'pending'},
];

export const TYPE_STATUS_FILTERS: ITableFilter[] = [
  {text: 'Employee', value: 'employee'},
  {text: 'Child', value: 'child'},
  {text: 'Spouse', value: 'spouse'},
];

export const isDynamicGroup = (data: any) => {
  const patientFilter = data.metadata?.patientFilter || {};
  const result = !patientFilter?.contactIds;
  return result;
};

export const getGroupNameText = (text: string,length?: number) => {
  if (!text) {
    return '';
  }
  if (text.length <= LENGTH) {
    return text;
  }
  return `${text.substring(0, length || LENGTH)}...`;
};

export const getGroupMembersList = (groupMembersData: GroupMemberData[], total: number) => {
  if (total <= 3) {
    return {
      groupMembersData,
      showPlusMore: false,
    };
  }
  return {
    groupMembersData: groupMembersData.slice(0, 3),
    showPlusMore: true,
  };
};

export const getContactSMSDataFromRawData = (args: {
  data: any[];
}): ContactSMSData[] => {
  const {data} = args;
  const result: ContactSMSData[] = [];
  data.forEach((item) => {
    result.push({
      contactUuid: item?.rawData?.contact?.uuid,
      id: item?.contact?.id?.toString(),
      name: item?.contact?.name,
      phoneNumber: item?.rawData?.contact?.phoneNumber || '',
      contactConsents: item?.contact?.contactConsents || [],
    });
  });
  return result;
};

export const hasClickedOnDependentCount = (args: {clickEvent: any}) => {
  const {clickEvent} = args;
  const text = clickEvent?.target?.innerText || '';
  const num = parseInt(text);
  return !isNaN(num);
};

export const getWhereConditionForEmployees = (args: {
  employerId: string;
  searchString: string;
  type: string[]
  startFrom: string
  startTo: string
  endFrom: string
  endTo: string
  selectedPlans: string[],
  selectedStatus: string[]
}) => {
  const {
    employerId,
    searchString,
    type,
    startFrom,
    startTo,
    endFrom,
    endTo,
    selectedPlans,
    selectedStatus,
  } = args;
  const result: any = {
    employerId: {
      _eq: employerId,
    },
    isDeleted: {
      _eq: false,
    },
    contact: {
      _or: [
        {
          email: {
            _ilike: searchString,
          },
        },
        {
          name: {
            _ilike: searchString,
          },
        },
      ],
    },
  };
  if (type.length) {
    result.type = {
      _in: type,
    };
  }
  result.contactMembership = {}
  if (startFrom || startTo || endFrom || endTo) {
    result.contactMembership = {
      startDate: {
        ...(startFrom && {_gte: startFrom}),
        ...(startTo && {_lt: startTo}),
      },
      endDate: {
        ...(endFrom && {_gte: endFrom}),
        ...(endTo && {_lt: endTo}),
      },
    };
  }
  const _and=[]
  if (selectedStatus.length > 0) {
    if (selectedStatus.length === 1 && selectedStatus.includes('ended')) {
      result.contactMembership = {
        ...result.contactMembership,
        isDeleted: {_eq: false},
      };
      result._not = {
        contactMembership: {
          isDeleted: {_eq: false},
          status: {_nin: selectedStatus},
        },
      };
    } else {
      result.contactMembership = {
        ...result.contactMembership,
        isDeleted: {_eq: false},
        status: {_in: selectedStatus},
      };
    }
  } else {
    result.contactMembership = {
      ...result.contactMembership,
      isDeleted: {_eq: false},
    };
  }

  if (selectedPlans.length > 0) {
    const planFilter = selectedPlans.map((item) => {
      return {
        planName: {
          _eq: item,
        },
      };
    });
    _and.push({
      _or: planFilter,
    });
  }
  if (_and.length > 0) {
    result.contactMembership = {
      ...result.contactMembership,
      _and,
    };
  }

  if (getTenantForDemo() && result.contactMembership) {
    delete result.contactMembership;
  }

  return result;
};

export const PATIENT_FILTERS_TYPE_STATUS: ITableFilter[] = [
  {text: 'Employee', value: 'employee'},
  {text: 'Child', value: 'child'},
  {text: 'Spouse', value: 'spouse'},
];
