import { useQuery } from '@apollo/client';
import { Select } from 'antd';

import { HStack, Radio, Skeleton, Text, View } from 'native-base';
import { useContext, useState } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { IMlov, ISearchFieldProps, IUser } from '../../../Interfaces';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { MLOV_CATEGORY, MLOV_CODES, USER_ROLE_CODES } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ScheduleEventQueries, UserQueries } from '../../../services';
import { Colors } from '../../../styles';
import { getAccountUUID } from '../../../utils/commonUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId
} from '../../../utils/mlovUtils';
import { AppointmentAvailabilityCode } from '../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import { IAppointmentType } from '../CalendarWidget/BookingWorkflows/BookAppointment/Interfaces';
import { USER_SELECTION_CODE } from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
const { Option } = Select;

export interface IAttachAppointmentDetails {
  appointmentTypes: IAppointmentType[];
  selectedAppointment: IAppointmentType;
  accountUsers: IUser[];
  selectedUsers: IUser[];
  userIds: string[];
  appointmentRoleTypeUsers: IUser[];
  selectedUserType: string;
  infoMessage: string;
}

const AppointmentTypeSearch = (props: ISearchFieldProps) => {
  const { value, isShowError, onChange } = props;
  const accountId = getAccountUUID();
  const commonContextData = useContext(CommonDataContext);
  const isHideInternalLabel = !!props?.isHideInternalLabel;
  const isHideUser = !!props?.isHideUser;
  const optional = !!props?.optional;
  const CARE_TEAM_BASED_APPOINTMENT_MESSAGE = 'Selected appointment type can only be scheduled with the member\'s care team';
  const PROVIDER_BASED_APPOINTMENT_MESSAGE = 'Selected appointment type has users configured which cannot be changed';

  const headers = {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  };
  const userRolesMlovs =
    getMlovListFromCategory(commonContextData.MLOV, MLOV_CATEGORY.USER_ROLES) ||
    [];

  const [appointmentData, setAppointmentData] =
    useState<IAttachAppointmentDetails>({
      appointmentTypes: [],
      selectedAppointment: {} as IAppointmentType,
      accountUsers: [],
      selectedUsers: [],
      userIds: [],
      appointmentRoleTypeUsers: [],
      selectedUserType: USER_SELECTION_CODE.ALL_USER,
      infoMessage: ''
    });

  const getDefaultAppointmentDetails = (
    appointmentTypeList: IAppointmentType[]
  ) => {
    const appointmentTypeId = value?.id;
    const appointmentUsers: string | undefined = value?.appointmentUsers;
    let appointmentType: IAppointmentType = {} as IAppointmentType;
    let userIdList: string[] = [];
    if (appointmentTypeId) {
      appointmentType =
        appointmentTypeList.find((appointment) => {
          return appointment?.id === appointmentTypeId;
        }) || ({} as IAppointmentType);
    }
    if (appointmentUsers) {
      userIdList = appointmentUsers?.split(',');
    } else if (appointmentType) {
      userIdList = getUserIds(filterUniqueUsers(getAccountUserOfSelectedRole(appointmentType)))
    }
    return {
      appointmentType: appointmentType,
      userIdList: userIdList,
    };
  };
  const filterUniqueUsers = (users: IUser[]) => {
    const uniqueUsers: IUser[] = [];
    users.forEach((user) => {
      if (!uniqueUsers.find((uniqueUser) => uniqueUser.id === user.id)) {
        const isAnyActive = (user?.accountUsers||[]).some((accountUser)=>{ return accountUser?.isActive})
        if(isAnyActive){
          uniqueUsers.push(user);
        }
      }
    });
    return uniqueUsers;
  }
  const setDefaultAppointmentType = (
    appointmentTypeList: IAppointmentType[]
  ) => {
    const defaultAppointmentData =
      getDefaultAppointmentDetails(appointmentTypeList);
    let userSelectionType = USER_SELECTION_CODE.CUSTOM;

    if (
      defaultAppointmentData?.appointmentType?.id &&
      (defaultAppointmentData?.userIdList?.length || defaultAppointmentData?.appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM)
    ) {
      const appointmentType = defaultAppointmentData?.appointmentType;
      const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
      const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
      if (isCareTeamBased || isProviderBased) {
        handleCustomAppointmentTypes(appointmentType);
        return;
      } else if (appointmentType?.appointmentTypeGroup?.length) {
        const userList: IUser[] = getAccountUserOfSelectedRole(appointmentType);
        if (userList?.length === defaultAppointmentData?.userIdList?.length) {
          userSelectionType = USER_SELECTION_CODE.ALL_USER;
        }
        setAppointmentData((prev) => {
          return {
            ...prev,
            selectedAppointment: appointmentType,
            appointmentRoleTypeUsers: filterUniqueUsers(userList),
            userIds: defaultAppointmentData?.userIdList,
            selectedUserType: userSelectionType,
            infoMessage: '',
          };
        });
        handleCallback(
          appointmentType,
          defaultAppointmentData?.userIdList,
          userSelectionType
        );
        return;
      }
    }
    const defaultAppointment: IAppointmentType = appointmentTypeList?.length
      ? appointmentTypeList[0]
      : ({} as IAppointmentType);
    onAppointmentTypeChange(defaultAppointment);
  };

  const calendarUsersData = useQuery(
    UserQueries.GET_USERS_FOR_CALENDAR_WITH_LOCATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId: accountId,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
      onCompleted: (data: any) => {
        setAppointmentData((prev) => {
          return {
            ...prev,
            accountUsers: data?.users,
          };
        });
      },
      onError: (error) => {

      },
    }
  );

  const appointmentTypeAPIData = useQuery(
    props.isShowAllAppointmentType ?  ScheduleEventQueries.GET_ALL_APPOINTMENT_TYPES : ScheduleEventQueries.GET_ALL_PATIENT_FACING_APPOINTMENT_TYPES ,
    {
      skip: calendarUsersData.loading,
      variables: {
        searchString: `%%`,
        categoryCodes: [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: (data: any) => {
        if(!optional || value?.id){
          setDefaultAppointmentType(data?.appointmentTypes);
        }
        setAppointmentData((prev) => {
          return {
            ...prev,
            appointmentTypes: data?.appointmentTypes,
          };
        });
      },
      onError(error) {

      },
    }
  );

  const getUserIds = (userList: IUser[]) => {
    const userIds: string[] = [];
    (userList || []).forEach((user) => {
      userIds.push(user.uuid);
    });
    return userIds;
  };

  const handleCustomAppointmentTypes = (appointmentType: IAppointmentType | undefined) => {
    const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
    if (isCareTeamBased) {
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: appointmentType,
          appointmentRoleTypeUsers: [],
          userIds: [],
          infoMessage: CARE_TEAM_BASED_APPOINTMENT_MESSAGE,
        };
      });
      handleCallback(
        appointmentType,
        [],
        USER_SELECTION_CODE.CUSTOM
      );
    } else if (isProviderBased) {
      const userIds = appointmentType.userPool?.userPoolUsers?.map(item => item.userId) || [];
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: appointmentType,
          appointmentRoleTypeUsers: appointmentData.accountUsers.filter(item => userIds.includes(item.uuid)),
          userIds: userIds,
          infoMessage: PROVIDER_BASED_APPOINTMENT_MESSAGE,
        };
      });
      handleCallback(
        appointmentType,
        userIds,
        USER_SELECTION_CODE.CUSTOM
      );
    }
  }

  const onAppointmentTypeChange = (appointmentType: IAppointmentType|undefined) => {
    const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;

    if (isCareTeamBased || isProviderBased) {
      handleCustomAppointmentTypes(appointmentType);
    } else if (appointmentType?.appointmentTypeGroup?.length) {
      const userList: IUser[] = getAccountUserOfSelectedRole(appointmentType);

      const userIds =
        appointmentData.selectedUserType === USER_SELECTION_CODE.ALL_USER
          ? getUserIds(userList)
          : [];
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: appointmentType,
          appointmentRoleTypeUsers: filterUniqueUsers(userList),
          userIds,
          infoMessage: '',
        };
      });
      handleCallback(
        appointmentType,
        userIds,
        appointmentData.selectedUserType
      );
    } else {
      handleCallback(
        undefined,
        undefined,
        appointmentData.selectedUserType
      );
    }
  };
  const getNestedUserRoles = (userRoles: any[]) => {
    if (userRoles?.length) {
      const data = userRoles.map((role) => role.userRole?.userRole?.id);
      return data;
    }
    return [];
  };
  const getAccountUserOfSelectedRole = (appointmentType: IAppointmentType) => {
    const roleIds = (appointmentType?.appointmentTypeGroup || []).map(
      (group) => group.roleId
    );

    const userList: IUser[] = [];
    roleIds.forEach((roleId) => {
      appointmentData.accountUsers.forEach((user) => {
        const userRoleIds = getNestedUserRoles(user?.userRoles || []);
        // const userRoleIds = user?.userRoles?.[0]?.userRole?.userRole?.id;
        if (userRoleIds.includes(roleId)) {
          userList.push(user);
        }
      });
    });
    return userList;

  };

  const compareUserRoleWithAppointmentRole = (
    user: IUser,
    allRoles: IMlov[]
  ) => {
    // ========= FUTURE REFERENCE =========
    // return (user.userRoles || []).some((selectedRole: any) => {
    //   const userRoleId = selectedRole.userRole?.userRole?.id;
    //   return role.some((appointmentRole) => {
    //     return userRoleId === appointmentRole.id;
    //   });
    // });
    return (user.userRoles || []).filter((selectedRole: any) => {
      const userRoleId = selectedRole.userRole?.userRole?.id;

      const roleIdsList = allRoles.map((appointmentRole) => appointmentRole.id);

      return roleIdsList.includes(userRoleId);
    });
  };

  const componentLoading =
    appointmentTypeAPIData.loading || calendarUsersData.loading;

  const onUserSelectHandler = (userIds: any) => {
    setAppointmentData((prev) => {
      return {
        ...prev,
        userIds,
      };
    });
    handleCallback(
      appointmentData.selectedAppointment,
      userIds,
      appointmentData.selectedUserType
    );
  };

  const handleUserSelectionType = (userSelectionType: string) => {
    const userList =
      userSelectionType === USER_SELECTION_CODE.CUSTOM
        ? []
        : getAccountUserOfSelectedRole(appointmentData.selectedAppointment);
    const userIds: any[] = [];
    if (userList?.length) {
      userList.forEach((user) => {
        userIds.push(user.uuid);
      });
    }
    setAppointmentData((prev) => {
      return {
        ...prev,
        userIds,
        selectedUserType: userSelectionType,
      };
    });
    handleCallback(
      appointmentData.selectedAppointment,
      userIds,
      userSelectionType
    );
  };

  const handleCallback = (
    appointmentType: IAppointmentType| undefined,
    userIds?: string[] | undefined,
    userSelectionType?: string | undefined
  ) => {
    const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    if (!appointmentType?.id || (!userIds?.length && !isCareTeamBased && !isHideUser)) {
      onChange(undefined);
      if(!appointmentType){
        setAppointmentData({...appointmentData, selectedAppointment:{} as IAppointmentType})
      }
      return;
    }
    let roleDetails: any = {};
    if (
      appointmentType?.appointmentTypeGroup?.length &&
      appointmentType?.appointmentTypeGroup[0]?.roleId
    ) {
      const roleId = appointmentType?.appointmentTypeGroup[0].roleId;
      roleDetails = {
        roleId,
        roleName: getMlovValueFromId(userRolesMlovs, roleId),
      };
    }
    const responseValue = {
      id: appointmentType?.id,
      eventName: appointmentType?.eventName,
      description: appointmentType?.description,
      appointmentUsers: userIds?.toString(),
      userSelection: userSelectionType,
      roleId: roleDetails?.roleId || undefined,
      roleName: roleDetails?.roleName || undefined,
      availabilityTypeCode: appointmentType?.availabilityTypeCode
    };
    onChange(responseValue);
  };

  const isCareTeamBasedAppointmentType = appointmentData.selectedAppointment.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
  const isProviderBasedAppointmentType = appointmentData.selectedAppointment.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;

  return (
    <>
      {componentLoading && (
        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!componentLoading && (
        <>
          <HStack>
           { !isHideInternalLabel &&  <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Appointment Type'}
                {!optional && <Text color="error.500">*</Text>}
              </Text>
            </HStack>}
            <HStack flex={7}>
              <View marginY={1} flex={1}>
              <Select
                suffixIcon={ props.isAutomationView?
                  <CaretDownOutlined
                    style={{
                      color: Colors.FoldPixel.GRAY300
                    }}
                  /> :
                  <DownOutlined/>
                }
                allowClear={props.allowClearAppointmentType}
                onClear={() => {
                  onAppointmentTypeChange(undefined);
                }}
                value={appointmentData?.selectedAppointment?.id}
                showSearch={true}
                placeholder={'Select Appointment Type'}
                status={props.isShowError && !appointmentData?.selectedAppointment?.id ? 'error' : ''}
                filterOption={(input, option: any) => {
                  return (
                    option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={(itemValue) => {
                  let appointmentValue;
                  (appointmentData?.appointmentTypes || []).some((appointment) => {
                    if (appointment.id === itemValue) {
                      appointmentValue = appointment;
                    }
                  });

                  onAppointmentTypeChange(appointmentValue);

                }}
              >
                {(appointmentData?.appointmentTypes || []).map((data) => {
                  return (
                    <Option value={data.id} title={data.eventName} key={data.id}>{data.eventName}</Option>
                  );
                })}

              </Select>
                {/* <ModalActionSelect
                  isRequired={!optional}
                  selectedValue={appointmentData?.selectedAppointment?.id || ''}
                  placeholder="Select Appointment Type"
                  endIcon={<CheckIcon size="5" />}
                  onValueChange={(value: string) => {
                    const selectedAppointment: IAppointmentType =
                      appointmentData.appointmentTypes.find((type) => {
                        return type.id === value;
                      }) || ({} as IAppointmentType);
                    onAppointmentTypeChange(selectedAppointment);
                  }}
                  data={appointmentData.appointmentTypes}
                  selectItemProps={{
                    key: 'id',
                    label: 'eventName',
                    value: 'id',
                  }}
                  customStyle={{ flex: 1 }}
                /> */}
              </View>
            </HStack>
          </HStack>
          {!!appointmentData.infoMessage && (
            <HStack>
              <HStack flex={2.4} />
              <HStack flex={7} alignItems={'flex-start'} space={2}>
                <Feather name="info" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
                <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                  {appointmentData.infoMessage}
                </Text>
              </HStack>
            </HStack>
          )}
          { !isHideUser && !isCareTeamBasedAppointmentType && !isProviderBasedAppointmentType &&
          <View style={{ marginTop: 18 }}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Users'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <Radio.Group
                    name="userSelection"
                    defaultValue={USER_SELECTION_CODE.ALL_USER}
                    value={appointmentData.selectedUserType}
                    onChange={handleUserSelectionType}
                  >
                    <HStack space={2}>
                      <Radio value={USER_SELECTION_CODE.ALL_USER} marginY={1}>
                        <Text marginLeft={2}>All</Text>
                      </Radio>

                      <Radio
                        value={USER_SELECTION_CODE.CUSTOM}
                        marginY={1}
                        marginLeft={1}
                      >
                        <Text marginLeft={2}>Custom</Text>
                      </Radio>
                    </HStack>
                  </Radio.Group>
                </View>
              </HStack>
            </HStack>
          </View> }
          {(appointmentData.selectedUserType === USER_SELECTION_CODE.CUSTOM || isProviderBasedAppointmentType) && !isHideUser && !isCareTeamBasedAppointmentType && (
            <View style={{ marginTop: 18 }}>
              <HStack>
                <HStack flex={2.4}>
                  {isProviderBasedAppointmentType && (
                    <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                      {'Users'}
                      {<Text color="error.500">*</Text>}
                    </Text>
                  )}
                </HStack>
                <HStack flex={7}>
                  <View marginY={1} flex={1}>
                    <Select
                      suffixIcon={ props.isAutomationView?
                        <CaretDownOutlined
                          style={{
                            color: Colors.FoldPixel.GRAY300
                          }}
                        /> :
                        <DownOutlined/>
                      }
                      mode={props.allowUserMultiSelect ? 'multiple' : undefined}
                      disabled={!appointmentData?.selectedAppointment?.id || isProviderBasedAppointmentType}
                      value={
                        props.allowUserMultiSelect
                          ? appointmentData.userIds
                          : appointmentData.userIds?.length > 0
                            ? appointmentData.userIds[0]
                            : undefined
                      }
                      allowClear
                      status={isShowError && (!appointmentData?.userIds || !appointmentData?.userIds?.length) ? 'error' : ''}
                      style={{ width: '100%', height: 'auto' }}
                      placeholder="Please select user"
                      filterOption={(input: string, option: any) => {
                        return (option?.children || '').toLowerCase().includes((input||'').toLowerCase());
                      }}
                      onChange={(value) => {
                        const data = Array.isArray(value) ? value : [value];
                        setAppointmentData((prev) => {
                          return {
                            ...prev,
                            userIds: data,
                          };
                        });
                        onUserSelectHandler(data);
                      }}
                    >
                      {appointmentData.appointmentRoleTypeUsers.map(
                        (user: IUser) => {
                          return <Option key={user.uuid}>{user.name}</Option>;
                        }
                      )}
                    </Select>
                  </View>
                </HStack>
              </HStack>
            </View>
          )}
        </>
      )}
    </>
  );
};

export default AppointmentTypeSearch;
