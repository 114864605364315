import {HStack, Skeleton, View, Text, Pressable} from 'native-base';
import {Colors} from '../../../../../styles/Colors';
import {Popover, Tooltip} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useContainerDimensions} from '../../../../CustomHooks/ContainerDimensionHook';
import { IUserRoleInterface } from '../interfaces';
import { IUserRoles } from '../../../../../services/Conversations/interfaces';

const RoleDataListView = (props: IUserRoleInterface) => {
  const {userRoles, isPopupView} = props;
  const componentRef = useRef();
  const {width} = useContainerDimensions(componentRef);
  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    maxRoleToShow: [] as any,
    loading: true,
  });

  useEffect(() => {
    getMaxWidth(userRoles);
  }, [props]);

  const getMaxWidth = (userRoles: IUserRoles[]) => {
    const maxTagsToShow: IUserRoles[] = [];
    let currentWidth = 0;
    const maxWidth = width / 17;
    if (userRoles?.length === 1) {
      setStateData((prev) => {
        return {
          ...prev,
          maxRoleToShow: userRoles,
          loading: false,
        };
      });
      return;
    } else if (userRoles?.length) {
      for (const role of userRoles) {
        if (
          currentWidth + (role?.userRole?.userRole?.value?.length || 0) <=
          maxWidth
        ) {
          currentWidth = role?.userRole?.userRole?.value?.length || 0;
          maxTagsToShow.push(role);
        } else {
          if (maxTagsToShow?.length === 0) {
            maxTagsToShow.push(role);
          }
          break;
        }
      }
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
    setStateData((prev) => {
      return {
        ...prev,
        maxRoleToShow: maxTagsToShow,
        loading: false,
      };
    });
  };

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };

  const renderRoles = (index: number, roleItem: IUserRoles, isPopover: boolean) => {
    return (
      <View
        key={index}
        backgroundColor={isPopover ? Colors.Custom.Blue50 : 'white'}
        mt={isPopover ? 1 : 0}
        borderRadius={isPopover ? 16 : 0}
        px={isPopover ? 3 : 0}
        style={{
          marginRight: 2,
        }}
      >
        <Tooltip title={roleItem?.userRole?.userRole?.value} placement="top">
          <Text
            wordBreak={'break-word'}
            color={isPopover ? 'blue.700' : Colors.Custom.Gray500}
            fontSize={12}
            fontWeight={400}
            numberOfLines={1}
            textOverflow={'ellipsis'}
            width={isPopupView ? '100%' : isPopover ? '100%' : width}
            maxW={120}
            isTruncated
          >
            {roleItem?.userRole?.userRole?.value +
              `${index < stateData.maxRoleToShow?.length - 1 ? ',' : ''}`}
          </Text>
        </Tooltip>
      </View>
    );
  };

  return (
    <>
      <View
        ref={componentRef}
        style={[
          {
            backgroundColor: '#fff',
            borderColor: Colors.Custom.Gray200,
          },
        ]}
      >
        {stateData.loading ? (
          <Skeleton.Text lines={1} />
        ) : (
          <>
            <HStack alignItems={'center'} flexWrap={'wrap'}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {stateData.maxRoleToShow && stateData.maxRoleToShow.length ? (
                  stateData.maxRoleToShow.map(
                    (roleItem: IUserRoles, index: number) => {
                      return <>{renderRoles(index, roleItem, false)}</>;
                    }
                  )
                ) : (
                  <></>
                )}
              </View>
              {userRoles?.length - stateData.maxRoleToShow?.length > 0 && (
                <Popover
                  overlayInnerStyle={{padding: 0, borderRadius: 12}}
                  overlayStyle={{padding: 0}}
                  overlay={{padding: 0}}
                  style={{padding: 0, borderRadius: 12}}
                  placement={'bottomLeft'}
                  trigger="click"
                  visible={stateData?.moreActionPopup}
                  onVisibleChange={handleMoreActionVisibleChange}
                  content={
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 300,
                      }}
                    >
                      <View
                        style={{flexDirection: 'row', alignItems: 'center'}}
                        flexWrap={'wrap'}
                        maxWidth={'90%'}
                      >
                        {userRoles &&
                          userRoles.length &&
                          userRoles.map((roleItem: IUserRoles, index: number) => {
                            return <>{renderRoles(index, roleItem, true)}</>;
                          })}
                      </View>
                    </View>
                  }
                >
                  <Pressable
                    onPress={() => {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          moreActionPopup: true,
                        };
                      });
                    }}
                  >
                    <Text
                      color={Colors.Custom.PrimaryColor}
                      fontSize={12}
                      fontWeight={400}
                    >
                      {`+${
                        userRoles?.length - stateData.maxRoleToShow?.length
                      } more`}
                    </Text>
                  </Pressable>
                </Popover>
              )}
            </HStack>
          </>
        )}
      </View>
    </>
  );
};

export default RoleDataListView;
