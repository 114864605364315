import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const MembershipSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.03 16.763L4.56 20.752l2.746-.344L8.244 23l3.377-5.462m0 0l.373-.622m-.373.622L14.979 23l.947-2.592 2.736.344-2.459-3.99m-4.582.776l-.392-.622M13.008 1.62c1.186-1.301 3.358-.412 3.272 1.349a1.876 1.876 0 001.97 1.96c1.76-.085 2.67 2.076 1.359 3.253a1.868 1.868 0 000 2.774c1.31 1.177.411 3.339-1.359 3.253a1.87 1.87 0 00-1.97 1.96c.086 1.751-2.086 2.65-3.272 1.35a1.878 1.878 0 00-2.784 0c-1.186 1.3-3.357.411-3.271-1.35a1.876 1.876 0 00-1.971-1.96c-1.76.086-2.669-2.076-1.358-3.253a1.868 1.868 0 000-2.774c-1.31-1.177-.412-3.338 1.358-3.252a1.87 1.87 0 001.97-1.961c-.085-1.751 2.086-2.65 3.272-1.35a1.878 1.878 0 002.784 0zm-1.397 10.513l-2.544 1.34.488-2.832-2.067-2.01 2.851-.41 1.272-2.583 1.273 2.582 2.85.412-2.066 2.009.488 2.831-2.545-1.34z"
      ></path>
    </svg>
  );
};

export default MembershipSvg;
