import React from 'react';
import {Colors} from '../../../styles/Colors';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ICommonSvgProps} from './interfaces';
import {Image} from 'native-base';

const UserSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor,width,height} = props;
  const bgColor = customStrokeColor || Colors.Custom.Gray500;

  return (
    <>
      {isWeb() ? (
        <svg
          width={width ? width : "inherit"}
          height={height ? height :"inherit"}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 10.5V9.5C10 8.96957 9.78929 8.46086 9.41421 8.08579C9.03914 7.71071 8.53043 7.5 8 7.5H4C3.46957 7.5 2.96086 7.71071 2.58579 8.08579C2.21071 8.46086 2 8.96957 2 9.5V10.5M8 3.5C8 4.60457 7.10457 5.5 6 5.5C4.89543 5.5 4 4.60457 4 3.5C4 2.39543 4.89543 1.5 6 1.5C7.10457 1.5 8 2.39543 8 3.5Z"
            stroke={bgColor}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <Image
          tintColor={customStrokeColor}
          size={'4'}
          source={require('../../../assets/images/PNGImages/simpleUser.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default UserSvg;
