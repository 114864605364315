import {Drawer, Select, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {IAddOrUpdateEFaxInboxDrawer} from './interface';
import {CONVERSATION_ACTION_CODES} from '../../../../constants/ActionConst';
import {
  useMediaQuery,
  Text,
  FormControl,
  useToast,
  Center,
  Spinner,
} from 'native-base';
import {
  EFAX_DRAWER_CODE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../constants/StringConst';
import Stack from '../../../common/LayoutComponents/Stack';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {Pressable, View} from 'react-native';
import {Colors} from '../../../../styles/Colors';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {ModalActionInput} from '../../../common/ModalActionCommonComponent/ModalActionInput';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {useLazyQuery, useMutation} from '@apollo/client';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import UserSvg from '../../../common/Svg/UserSvg';
import EFaxActionSvg from '../../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import {
  formatEFaxNumber,
  getUpdatedErrorMessageForInboxMemberSetting,
} from './EFaxInboxSettingUtils';
import {debounce} from 'lodash';
import {getAgentObject} from '../Integrations/Helper/formatIntegrationsData';
import {IInternalUsers} from '../../../common/CustomUserSearch/interface';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';

const AddOrUpdateEFaxInboxDrawer = (props: IAddOrUpdateEFaxInboxDrawer) => {
  const intl = useIntl();
  const {Option} = Select;
  const toast = useToast();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '40vw' : '35%';

  const [state, setState] = useState({
    loading: false,
    userLoading: false,
    internalUsers: [] as IInternalUsers[],
    selectedUsers: [] as IInternalUsers[],
    inboxName: '',
    updatedInboxMemberList: [] as IInternalUsers[],
    errors: {} as any,
  });

  const [getInternalUsers] = useLazyQuery(InboxQueries.GetInternalUsers, {
    fetchPolicy: 'no-cache',
  });

  const [getInitialInternalUsers] = useLazyQuery(
    InboxQueries.GetInitialInternalUsers,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [deleteInboxesMembers] = useMutation(InboxQueries.deleteInboxesMembers);
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);
  const [updateInboxName] = useMutation(InboxQueries.updateSMSInboxName);

  const getInitialUsers = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const response = await getInitialInternalUsers({
        variables: {
          roleCode: USER_ROLE_CODES.EMPLOYER,
          limit: 10,
        },
      });
      setState((prev: any) => {
        const internalUsers = props?.selectedInbox?.inboxMembers;
        let updatedInternalUser;
        if (internalUsers) {
          const filteredInternalUser = response?.data?.accountUsers?.filter(
            (item: any) => {
              return !internalUsers?.find(
                (data) => data?.userId === item?.userId
              );
            }
          );
          updatedInternalUser = [...filteredInternalUser, ...internalUsers];
        }
        return {
          ...prev,
          loading: false,
          selectedUsers: props?.selectedInbox?.inboxMembers,
          inboxName: props?.selectedInbox?.name,
          internalUsers: updatedInternalUser,
          updatedInboxMemberList: updatedInternalUser,
        };
      });
    } catch (e) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        ToastType.error
      );
    }
  };

  const searchInternalUser = async (searchString: string) => {
    setState((prev) => {
      return {
        ...prev,
        userLoading: true,
      };
    });
    const response = await getInternalUsers({
      variables: {
        roleCode: USER_ROLE_CODES.EMPLOYER,
        limit: 10,
        searchString: `%${searchString}%`,
      },
    });
    setState((prev) => {
      const selectedUsersUuids = prev?.selectedUsers?.map((item) => {
        return item?.userId;
      });
      let updatedInternalUsers = [];
      if (selectedUsersUuids) {
        const filterInternalUsersList = response?.data?.accountUsers?.filter(
          (item: any) => !selectedUsersUuids?.includes(item?.userId)
        );
        updatedInternalUsers = [
          ...filterInternalUsersList,
          ...prev?.selectedUsers,
        ];
      } else {
        updatedInternalUsers = response?.data?.accountUsers;
      }
      return {
        ...prev,
        internalUsers: response?.data?.accountUsers,
        userLoading: false,
        updatedInboxMemberList: updatedInternalUsers,
      };
    });
  };

  useEffect(() => {
    getInitialUsers();
  }, []);

  const closeModal = () => {
    props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
  };

  const onUserChange = (userIds: number[]) => {
    const selectedUserData = state?.updatedInboxMemberList?.filter((item) => {
      return userIds.includes(item?.userId);
    });
    const selectedAssignedUser = [] as IInternalUsers[];
    selectedUserData?.forEach((item) => {
      if (item?.userId) {
        selectedAssignedUser.push(item);
      }
    });
    setState((prev) => {
      return {
        ...prev,
        selectedUsers: selectedUserData,
      };
    });
  };

  const handleOnSave = async () => {
    if (props?.selectedInbox?.id) {
      const errorData = getUpdatedErrorMessageForInboxMemberSetting(state);
      if (errorData?.isErrorPresent) {
        setState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              ...errorData.errors,
            },
            loading: false,
          };
        });
      } else {
        try {
          setState((prev) => {
            return {
              ...prev,
              loading: true,
            };
          });
          const selectedUserIds = state?.selectedUsers?.map((item) => {
            return item?.userId;
          });
          const addNewUserList: any = [];

          const inboxMemberUserIds = props?.selectedInbox?.inboxMembers?.map(
            (item) => {
              return item?.userId;
            }
          );
          const deleteUserList = inboxMemberUserIds?.filter(
            (item) => !selectedUserIds?.includes(item)
          );
          selectedUserIds.forEach((singleUserId) => {
            if (!inboxMemberUserIds?.includes(singleUserId)) {
              addNewUserList.push({
                userId: singleUserId,
                inboxId: props?.selectedInbox?.id,
                isDeleted: false,
              });
            }
          });
          const response = await Promise.all([
            handleAddInboxMember(addNewUserList),
            handleDeleteInboxMember(props?.selectedInbox?.id, deleteUserList),
            handleInboxNameChange(props?.selectedInbox?.id, state?.inboxName),
          ]);
          closeModal();
          showToast(
            toast,
            intl.formatMessage({
              id: 'userAddedSuccessfully',
            }),
            ToastType.success
          );
        } catch (e) {
          setState((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
          showToast(
            toast,
            intl.formatMessage({
              id: 'apiErrorMsg',
            }),
            ToastType.error
          );
        }
      }
    }
  };

  const handleDeleteInboxMember = async (
    inboxId: number,
    userList?: number[]
  ) => {
    if (userList && userList?.length && inboxId) {
      const deleteMemberData = await deleteInboxesMembers({
        variables: {
          inboxId,
          userIds: userList,
        },
      });
    }
  };

  const handleAddInboxMember = async (addNewUserList: any[]) => {
    if (addNewUserList) {
      const addedMemberData = await createInboxMembers({
        variables: {
          objects: addNewUserList,
        },
      });
    }
  };

  const handleInboxNameChange = async (inboxId: number, inboxName: string) => {
    if (inboxId && inboxName && inboxName !== props?.selectedInbox?.name) {
      const updatedInboxData = await updateInboxName({
        variables: {
          id: inboxId,
          name: inboxName,
          enableAutoAssignment: false,
          inboxType: null,
        },
      });
    }
  };

  const getTitleView = (): JSX.Element => {
    return (
      <Stack direction="row" style={{alignItems: 'center'}}>
        <ModalActionTitle
          title={
            props?.drawerCode === EFAX_DRAWER_CODE.UPDATE_INBOX
              ? 'updateDetails'
              : 'getEFax'
          }
          titleColor=""
        />
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
          }}
        >
          <View style={{paddingRight: 20}}>
            <Pressable
              style={{
                backgroundColor: Colors.Custom.mainPrimaryPurple,
                paddingVertical: 4,
                paddingHorizontal: 8,
                borderRadius: 4,
              }}
              onPress={handleOnSave}
            >
              <Text color={'white'} fontSize={'md'}>
                {intl.formatMessage({id: 'save'})}
              </Text>
            </Pressable>
          </View>
          <View
            style={{
              paddingLeft: 10,
              borderLeftWidth: 1,
              borderLeftColor: Colors.Custom.Gray300,
              marginVertical: 5,
            }}
          >
            <Pressable onPress={closeModal}>
              <AntIcon name="close" size={20} color={Colors.Custom.Gray400} />
            </Pressable>
          </View>
        </Stack>
      </Stack>
    );
  };

  const getInternalUsersOption = () => {
    return state?.internalUsers?.map((item: any) => {
      return (
        <Option
          searchValue={item?.user?.name}
          key={item?.userId}
          value={item?.userId}
        >
          <Stack direction="row" style={{alignItems: 'center'}}>
            <UserSvg width={15} height={15} />
            <Text paddingLeft={2} color={Colors.Custom.Gray500}>
              {item?.user?.name}
            </Text>
          </Stack>
        </Option>
      );
    });
  };

  const getUpdateInboxElement = () => {
    return (
      <Stack
        direction="column"
        style={{
          paddingHorizontal: 24,
          paddingVertical: 16,
          opacity: state?.loading ? 0.5 : 1,
        }}
      >
        <View style={{paddingBottom: 16}}>
          <Text
            style={{
              color: Colors.Custom.Gray500,
              fontSize: 14,
              paddingBottom: 4,
            }}
          >
            {intl.formatMessage({id: 'purchasedNumber'})}
          </Text>
          <Stack direction="row" style={{alignItems: 'center'}}>
            <EFaxActionSvg width={30} height={30} />
            <Stack direction="column">
              <Text paddingLeft={2} color={Colors.Custom.Gray500}>
                {formatEFaxNumber(
                  props?.selectedInbox?.channelEfax?.efaxNumber
                )}
              </Text>
              <Text paddingLeft={2} color={Colors.Custom.Gray500}>
                {props?.selectedInbox?.name}
              </Text>
            </Stack>
          </Stack>
        </View>

        <ModalActionInput
          fieldIs={'input'}
          label={'inboxName'}
          placeholder="Enter inbox name"
          isRequired={true}
          isInvalid={state?.inboxName ? '' : state?.errors?.inboxName}
          errors={state?.inboxName ? '' : state?.errors?.inboxName}
          errorText={state?.inboxName ? '' : state?.errors?.inboxName}
          value={state.inboxName}
          onChangeText={(value: string) => {
            setState((prev) => {
              return {
                ...prev,
                inboxName: value,
              };
            });
          }}
          extraStyle={{flex: 1}}
        />

        <FormControl paddingY={4} isInvalid={state?.errors?.inboxMember}>
          <FormControl.Label isRequired>
            <DisplayText
              size={'smMedium'}
              textLocalId={'addAgents'}
              extraStyles={{color: Colors.Custom.Gray500}}
            />
          </FormControl.Label>

          <Select
            showSearch={true}
            allowClear={true}
            placeholder="Search and select user"
            maxTagCount={'responsive'}
            mode="multiple"
            className="eFax-select"
            value={state?.selectedUsers?.map((item) => {
              return item?.userId;
            })}
            filterOption={(input, option) =>
              (option?.searchValue as unknown as string)
                ?.toLocaleLowerCase()
                ?.includes(input?.toLowerCase())
            }
            onSearch={debounce(searchInternalUser, 500)}
            onChange={(value) => {
              onUserChange(value);
            }}
            notFoundContent={
              state.userLoading ? (
                <Spin size="small" />
              ) : (
                <Text>No Data Found</Text>
              )
            }
          >
            {getInternalUsersOption()}
          </Select>

          {(state?.errors?.inboxMember && !state?.selectedUsers?.length)  && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {state?.errors?.inboxMember}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </Stack>
    );
  };

  const getAddInboxElement = () => {
    return <Text>work in progress</Text>;
  };

  const getBodyElement = (drawerCode: string) => {
    switch (drawerCode) {
      case EFAX_DRAWER_CODE.UPDATE_INBOX:
        return getUpdateInboxElement();
      case EFAX_DRAWER_CODE.ADD_INBOX:
        return getAddInboxElement();
    }
  };

  return (
    <Drawer
      className="custom-drawer-styles"
      visible={props?.visible}
      width={drawerWidth}
      style={{paddingLeft: '0', paddingRight: '0'}}
      closable
      onClose={() => closeModal()}
      title={getTitleView()}
    >
      {state.loading && (
        <View
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 10,
            backgroundColor: 'coolGray.50:alpha.70',
          }}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {getBodyElement(props?.drawerCode)}
    </Drawer>
  );
};

export default AddOrUpdateEFaxInboxDrawer;
