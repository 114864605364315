import moment from 'moment';
import { Box, Center, HStack, Pressable, Text, VStack, View, useToast, Tooltip } from 'native-base';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Colors } from '../../../../styles';
import { isWeb } from '../../../../utils/platformCheckUtils';
import VoiceRecordPlaySvg from '../../../common/Svg/SideMenu/VoiceRecordPlaySvg';
import TranscriptionsView from '../Transcriptions/TranscriptionsView';
import { getCallStatus, isShowCallTrail, isShowTranscription, parseTwilioRecordingUrl } from './CallLogsUtils';
import { ICallLogEntry, ITwilioSidData } from './interface';
import { getDurationInFormat } from '../../../common/ChatUI/MessageBox/MessageBoxUtils';
import { IContact } from '../Conversations/interfaces';
import TransitionIconSvg from '../../../common/Svg/ConversationAllSvg/TransitionIconSvg';
import { getSignedRecordingApi } from '../Conversations/ConversationChannelNew/ConversationAPIService';
import { ActivityIndicator } from 'react-native';
import { useIntl } from 'react-intl';
import { TwilioRecordingUrlPrefix } from '../../../../constants/Configs';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { CallTrailButton } from '../../CallTrail';
import { getCallLogsAvatar } from '../Conversations/ConversationChannelNew/ConversationSidebarUtils';
import { CALL_DETAILS_FLEX_VALUES } from './CallLogsConst';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';

interface CallLogDetailProps {
  callLogEntry: ICallLogEntry;
  contactInfo: IContact;
}

const CallLogDetailItem = (props: CallLogDetailProps) => {
  const {callLogEntry, contactInfo} = props;
  const showCallTrail = isShowCallTrail(callLogEntry)
  const isCallAndTranscriptionEnable = isAccountConfigEnabled(CONFIG_CODES.SHOW_CALL_RECORDING_AND_TRANSCRIPTION);
  const toast = useToast();
  const intl = useIntl();
  const [state, setState] = useState({
    recordingStart: false,
    transcriptionOpen: false,
    callLogEntry: callLogEntry,
    isLoading: false,
    recordingSignedUrl: '' as string,
  });

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        recordingStart: false,
        transcriptionOpen: false,
        callLogEntry: callLogEntry,
        isLoading: false,
        recordingSignedUrl: '' as string,
      };
    });
  }, [props?.callLogEntry?.id]);

  const getExtensionNumber = () => {
    const extensionNumber = callLogEntry?.fromExtensionNumber || callLogEntry?.toExtensionNumber || '';
    let extensionLabel = '';
    if (extensionNumber) {
      extensionLabel = ` (Ext:${extensionNumber})`;
    }
    return extensionLabel;
  };

  const fetchSignedRecordingUrl = async (existingRecordingUrl: string) => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const transcriptionParams: ITwilioSidData =
      parseTwilioRecordingUrl(existingRecordingUrl);
    if (!transcriptionParams.accountSid && !transcriptionParams.recordingSid) {
      showErrorMsg();
      return;
    }
    const recordingRes = await getSignedRecordingApi(transcriptionParams).catch(
      (err) => {
        showErrorMsg();
      }
    );
    if (recordingRes?.data?.url) {
      state.recordingSignedUrl = recordingRes?.data?.url;
      state.isLoading = false;
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          recordingStart: true,
          recordingSignedUrl: recordingRes?.data?.url,
        };
      });
    }
  };

  const showErrorMsg = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
    setState((prev) => {
      return {
        ...prev,
        isLoading: false,
        recordingStart: false,
      };
    });
  }

  return (
    <VStack
      space={4}
      style={{
        paddingVertical: 14,
        backgroundColor: '#ffffff',
        borderColor: Colors.Custom.Gray200,
        borderBottomWidth: 1,
      }}
    >
      <HStack>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flex: CALL_DETAILS_FLEX_VALUES.CALL_TYPE,
            marginLeft: 24,
          }}
        >
          <HStack
            alignItems={'center'}
            space={1}
            style={{marginLeft: 0, marginTop: 0}}
          >
            <View
              style={{
                marginRight: 10,
              }}
            >
              {getCallLogsAvatar(callLogEntry)}
            </View>
            <Text
              alignSelf={'center'}
              noOfLines={1}
              style={{height: 20, width: isWeb() ? 'auto' : 300}}
              size={'smNormal'}
              color={Colors.Custom.Gray500}
            >
              {getCallStatus(callLogEntry)}
            </Text>
          </HStack>
        </View>

        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flex: CALL_DETAILS_FLEX_VALUES.DATE_TIME,
          }}
        >
          <Text size={'smNormal'} color={Colors.Custom.Gray500}>
            {moment(callLogEntry?.dateTime).format('MMM DD yyyy hh:mm A') ??
              '-'}
          </Text>
        </View>

        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flex: CALL_DETAILS_FLEX_VALUES.DURATION,
          }}
        >
          <Text size={'smNormal'} color={Colors.Custom.Gray500}>
            {getDurationInFormat(callLogEntry)}
          </Text>
        </View>

        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flex: CALL_DETAILS_FLEX_VALUES.OUT_OF_OFFICE,
          }}
        >
          <Text size={'smNormal'} color={Colors.Custom.Gray500}>
            {/* {`${callLogEntry?.duration ?? 0} Minutes`}. */}
            {callLogEntry?.isOutOfOfficeHours ? 'Yes' : '-'}
          </Text>
        </View>

        <View
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: CALL_DETAILS_FLEX_VALUES.ACTIONS,
            flexDirection: 'row',
          }}
        >
          {showCallTrail ? (
            <View
              style={{
                marginRight: 10,
              }}
            >
              <CallTrailButton callId={callLogEntry.id} />
            </View>
          ) : (
            <></>
          )}

          {isShowTranscription(callLogEntry, isCallAndTranscriptionEnable) ? (
            <Tooltip
              hasArrow
              label={`${intl.formatMessage({id: 'transcription'})}`}
              placement="top"
            >
              <Pressable
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      transcriptionOpen: true,
                    };
                  });
                }}
                style={{
                  marginRight: 10,
                }}
              >
                <TransitionIconSvg
                  height={'20px'}
                  width={'20px'}
                  customStrokeColor={Colors.Custom.Gray500}
                />
              </Pressable>
            </Tooltip>
          ) : (
            <></>
          )}

          {isShowTranscription(callLogEntry, isCallAndTranscriptionEnable) &&
          !state.recordingStart &&
          !state.isLoading ? (
            <Tooltip
              hasArrow
              label={`${intl.formatMessage({id: 'recording'})}`}
              placement="top"
            >
              <Pressable
                onPress={() => {
                  const callRecordingUrl =
                    callLogEntry.recording || callLogEntry.callrecord || '';
                  if (callRecordingUrl?.includes(TwilioRecordingUrlPrefix)) {
                    state.recordingSignedUrl = callRecordingUrl;
                    setState((prev) => {
                      return {
                        ...prev,
                        recordingStart: true,
                        recordingSignedUrl: callRecordingUrl,
                      };
                    });
                  } else {
                    fetchSignedRecordingUrl(callRecordingUrl);
                  }
                }}
              >
                <VoiceRecordPlaySvg customStrokeColor={Colors.Custom.Gray500} />
              </Pressable>
            </Tooltip>
          ) : state.isLoading ? (
            <ActivityIndicator color={Colors.Custom.PrimaryColor} />
          ) : (
            <></>
          )}
        </View>
        <Center width={100}></Center>
      </HStack>
      {state.recordingSignedUrl?.length && state.recordingStart ? (
        <Center>
            <ReactPlayer
              playing={true}
              width={300}
              height={50}
              controls={true}
              url={state.recordingSignedUrl}
              onEnded={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    recordingStart: false,
                  };
                });
              }}
            />
        </Center>
      ) : undefined}
      {state.transcriptionOpen && (
        <TranscriptionsView
          callLogEntry={callLogEntry}
          isVisible={state.transcriptionOpen}
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                transcriptionOpen: false,
              };
            });
          }}
          contactInfo={{
            contactData: contactInfo,
            contactType: contactInfo?.contactType?.contactType,
          }}
        />
      )}
    </VStack>
  );
};

export default CallLogDetailItem;
