import {Text, Modal, FormControl, Input, Button, Select} from 'native-base';
import React, {useState} from 'react';
import {IRoles, IStructureData, IStructureModalProps} from '..';
import {styles} from './TeamMembersModalStyles';
import {rolesDummyData} from '../../../../../dummyData';

const {Content, CloseButton, Header, Body, Footer} = Modal;
const {Label} = FormControl;
const {Item} = Select;

const StructureModal = (props: IStructureModalProps) => {
  const {isModalOpen, onModalClose, onSave, structureData} = props;
  const [errors, setErrors]: any = useState({});
  const [data, setData] = useState<IStructureData>(structureData);

  const validateName = (): boolean => {
    if (!data.name) {
      setErrors({
        ...errors,
        name: 'Name is required',
      });
      return false;
    } else if (data.name.length < 6) {
      setErrors({
        ...errors,
        name: 'Name is too short',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        name: '',
      });
    }

    return true;
  };

  const validateEmail = (): boolean => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!data.email.match(emailRegex)) {
      setErrors({
        ...errors,
        email: 'Enter a valid email address',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        email: '',
      });
    }
    return true;
  };

  const validatePhoneNumber = (): boolean => {
    // validation for phone number
    const phoneNumberRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!data.phone.match(phoneNumberRegex)) {
      setErrors({
        ...errors,
        phoneNumber: 'Enter a valid phone number',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        phoneNumber: '',
      });
    }
    return true;
  };

  const handleOnSave = () => {
    if (validateEmail() && validatePhoneNumber() && validateName()) {
      onSave(data);
      onModalClose();
    } else {
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => onModalClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <Text style={styles.modalHeaderText}>Edit Structure</Text>
        </Header>
        <Body>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Name</Label>
            <Input
              isRequired
              style={styles.inputStyle}
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              defaultValue={data.name}
              onChangeText={(name: string) => {
                setData({
                  ...data,
                  name: name,
                });
                validateName();
              }}
            />
            {errors.name && <Text style={styles.errorText}>{errors.name}</Text>}
          </FormControl>

          {/* role */}
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Role</Label>
            <Select
              style={styles.selectStyle}
              accessibilityLabel="Select Role"
              placeholder="Select Role"
              selectedValue={data.role}
              onValueChange={(role: string) => {
                setData({
                  ...data,
                  role: role,
                });
              }}
            >
              {rolesDummyData.map((singleRole: IRoles) => {
                return (
                  <Item
                    key={singleRole.id}
                    label={singleRole.value}
                    value={singleRole.id}
                  />
                );
              })}
            </Select>
          </FormControl>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Phone</Label>
            <Input
              type="phone"
              style={styles.inputStyle}
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              // maxLength={10}
              defaultValue={data.phone}
              onChangeText={(phoneNumber: string) => {
                setData({
                  ...data,
                  phone: phoneNumber,
                });
                validatePhoneNumber();
              }}
            />
            {errors.phoneNumber && (
              <Text style={styles.errorText}>{errors.phoneNumber}</Text>
            )}
          </FormControl>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Email</Label>
            <Input
              type="email"
              style={styles.inputStyle}
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              defaultValue={data.email}
              onChangeText={(email: string) => {
                setData({
                  ...data,
                  email: email,
                });
                validateEmail();
              }}
            />
            {errors.email && (
              <Text style={styles.errorText}>{errors.email}</Text>
            )}
          </FormControl>

          <Footer style={styles.modalFooter}>
            <Button.Group>
              <Button
                onPress={() => {
                  handleOnSave();
                }}
              >
                Save
              </Button>
              <Button colorScheme="dark" onPress={() => onModalClose()}>
                Cancel
              </Button>
            </Button.Group>
          </Footer>
        </Body>
      </Content>
    </Modal>
  );
};

export default StructureModal;
