import {gql} from '@apollo/client';

export const UpdateTag = gql`
  mutation MyMutation(
    $description: String!
    $title: String!
    $id: bigint!
    $color: String!
  ) {
    updateLabel(
      pk_columns: {id: $id}
      _set: {description: $description, title: $title, color: $color}
    ) {
      description
      title
      id
      color
      createdAt
    }
  }
`;

export const GetCategories = gql`
  query getMlovCategories($mlovCategory: String = "LabelType") {
    mlovs(where: {category: {category: {_eq: $mlovCategory}}}) {
      id
      code
      description
      value
    }
  }
`;

export const GetLabelsByFilter = gql`
  query MyQuery($category: uuid!, $searchString: String!, $limit: Int = 100) {
    labels(
      limit: $limit,
      where: {
        labelTypes: {labelTypeId: {_eq: $category}}
        title: {_ilike: $searchString}
      }
      order_by: {title: asc}
    ) {
      id
      accountId
      createdAt
      updatedAt
      color
      description
      title
      tagCount {
        id
      }
      labelTypes {
        labelId
        labelTypeId
      }
    }
  }
`;

export const GetLabelByFilterPagination = gql`
  query GetLabelByFilterPagination(
    $category: uuid!
    $searchString: String!
    $limit: Int!
    $offset: Int!
    $orderBy: [labels_order_by!]
  ) {
    labels(
      where: {
        labelTypes: {labelTypeId: {_eq: $category}}
        title: {_ilike: $searchString}
      }
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      accountId
      color
      createdAt
      description
      id
      title
      updatedAt
      labelTypes {
        labelTypeId
      }
    }
    labelAggregate(
      where: {
        labelTypes: {labelTypeId: {_eq: $category}}
        title: {_ilike: $searchString}
      }
      order_by: $orderBy
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CreateLabel = gql`
  mutation createLabel(
    $accountId: bigint!
    $color: String!
    $description: String!
    $title: String!
  ) {
    createLabel(
      object: {
        accountId: $accountId
        color: $color
        description: $description
        title: $title
      }
    ) {
      id
      uuid
      title
      updatedAt
      createdAt
      accountId
      color
      description
    }
  }
`;

export const CreateLabelType = gql`
  mutation createLabelType(
    $accountId: Int!
    $labelId: Int!
    $labelTypeId: uuid!
  ) {
    createLabelType(
      object: {
        accountId: $accountId
        labelId: $labelId
        isDeleted: false
        labelTypeId: $labelTypeId
      }
    ) {
      accountId
      createdAt
      createdBy
      id
      labelId
      labelTypeId
      updatedAt
      updatedBy
    }
  }
`;

export const AddLabelTaggings = gql`
  mutation addLabelTaggings(
    $addTagObjectList: [label_taggings_insert_input!]!
  ) {
    createLabelTaggings(objects: $addTagObjectList) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DeleteTag = gql`
  mutation deleteTag($id: bigint!) {
    updateLabel(pk_columns: {id: $id}, _set: {isDeleted: true}) {
      id
    }
  }
`;

export const GetConversationContactTags = gql`
  query MyQuery($taggableId: Int!, $taggableTypeId: uuid!) {
    labels(
      limit: 100,
      where: {
        labelTaggings: {
          taggableId: {_eq: $taggableId}
          _and: {taggableTypeId: {_eq: $taggableTypeId}}
        }
      }
    ) {
      color
      description
      id
      title
      labelTaggings(
        where: {
          taggableId: {_eq: $taggableId}
          _and: {taggableTypeId: {_eq: $taggableTypeId}}
        }
      ) {
        taggableId
        taggableTypeId
        taggerId
        labelId
        taggerTypeId
        id
      }
    }
  }
`;

export const RemoveAttachedTag = gql`
  mutation removeAttachedTag($id: uuid!) {
    updateLabelTaggings(_set: {isDeleted: true}, where: {id: {_eq: $id}}) {
      affected_rows
      returning {
        id
        labels {
          color
          description
          id
          title
        }
      }
    }
  }
`;

export const RemoveMultiAttachedTag = gql`
  mutation removeAttachedTag($idList: [uuid!]!) {
    updateLabelTaggings(_set: {isDeleted: true}, where: {id: {_in: $idList}}) {
      affected_rows
      returning {
        id
        labels {
          color
          description
          id
          title
        }
      }
    }
  }
`;

export const CreateLabels = gql`
  mutation createLabels(
    $labelList: [labels_insert_input!]!
  ) {
    createLabels(objects: $labelList) {
      affected_rows
      returning {
        id
        uuid
        title
      }
    }
  }
`;

export const CreateLabelTypes = gql`
  mutation createLabelTypes(
    $labelTypeList: [label_types_insert_input!]!
  ) {
    createLabelTypes(objects: $labelTypeList) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GetLabelByTitle = gql`
  query getLabelIdByTitle($title: String!) {
    labels(
      limit: 1,
      where: {
        title: {_eq: $title},
        isDeleted: {_eq: false}
      }
    ) {
      id
    }
  }
`;

export default {
  UpdateTag,
  GetCategories,
  GetLabelsByFilter,
  CreateLabel,
  CreateLabelType,
  AddLabelTaggings,
  DeleteTag,
  GetConversationContactTags,
  RemoveAttachedTag,
  RemoveMultiAttachedTag,
  GetLabelByFilterPagination,
  CreateLabels,
  CreateLabelTypes,
  GetLabelByTitle,
};
