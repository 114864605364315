import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

const margin = 6;

export const styles = StyleSheet.create({
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
    backgroundColor: '#fff',
    // paddingBottom: 16,
    // borderBottomWidth: 1,
    // borderColor: Colors.Custom.BorderColor,
  },
  rightViewContainer: {
    flex: 1,
    backgroundColor: 'white',
    //marginLeft: margin / 2,
  },
  leftViewContainer: {
    flex: 0.2,
    marginRight: margin / 2,
  },
  stackContainer: {
    flex: 1,
  },
  mainContainer: {
    // margin: 16,
    backgroundColor: '#fff',
    flex: 1,
  },
});
