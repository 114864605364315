import { Tooltip } from 'antd';
import {Text,Pressable, View, HStack, Icon} from 'native-base';
import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {Dimensions} from 'react-native';
import {MODULE_PAGINATION_COUNT} from '../../../../constants';
import { IUserResp } from '../../../../services/User/interface';
import {Colors} from '../../../../styles';
import {NoDataFound} from '../../../common/NoDataFound';
import {SearchBar} from '../../../common/SearchBar';
import FilterNewIconSvg from '../../../common/Svg/FilterNewIconSvg';
import {renderConversationFooter} from '../Conversations/ConversationContainer/ConversationList/ConversationListComponents';
import {ConversationListLoader} from '../Conversations/ConversationContainer/ConversationList/ConversationListLoader';
import CallLogEntry from './CallLogEntry';
import {ICallLogEntry} from './interface';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';

interface CallLogListProps {
  callLogs: ICallLogEntry[];
  selectedCallLog: number;
  onPressItem: (index: any) => void;
  loadMoreCallLogs: () => void;
  isLoading: boolean;
  isLoadMoreLoading: boolean;
  setSearchText: (index: any) => void;
  userList: IUserResp[];
  reloadCallLogs?:()=> void;
  onFilterModal?: ()=> void;
  conversationFilterProps?: any,
  isDialPadVisible?: boolean;
  showFetchMore: boolean;
}

const CallLogList = (props: CallLogListProps) => {
  const {height} = Dimensions.get('window');
  const intl = useIntl();
  // const finalListHeight = height - 255;
  const removeHeight = parseInt((0.8 / 100) * height + '');
  const finalListHeight = height - removeHeight - 240;

  const [callLogsState, setCallLogsState] =
  useState({
    conversationFilterProps: props?.conversationFilterProps || {} as any,
  });

  useEffect(() => {
    setCallLogsState((prev) => {
      return {
        ...prev,
        conversationFilterProps: props?.conversationFilterProps
      };
    });
  }, [props?.conversationFilterProps]);

  const renderCallLogList = () => {
    return (
      <>
        {props?.callLogs.map((item, index) => {
          return (
            <View
              style={{
                overflow: 'hidden',
              }}
              key={item.id + index}
            >
              <Pressable
                background={
                  props?.selectedCallLog == index
                    ? Colors.Custom.Gray100
                    : '#ffffff'
                }
                style={[
                  {
                    //borderRadius: 16,
                    overflow: 'hidden',
                    //borderWidth: props?.selectedCallLog == index ? 1 : 0,
                    //borderColor: Colors.Custom.Gray200,
                  },
                ]}
                onPress={() => {
                  props?.onPressItem(index);
                }}
              >
                {false && props?.selectedCallLog == index && (
                  <View
                    style={{
                      width: 10,
                      backgroundColor: Colors.primary['300'],
                      position: 'absolute',
                      zIndex: 22,
                    }}
                    height={'full'}
                  ></View>
                )}
                <CallLogEntry
                  callLog={item}
                />
              </Pressable>
            </View>
          );
        })}
        {renderConversationFooter(
          props?.showFetchMore,
          props?.isLoadMoreLoading,
          props?.callLogs?.length,
          props?.loadMoreCallLogs,
          false,
          MODULE_PAGINATION_COUNT.CALL_LOG_SIZE,
          'loadMoreCallLogs'
        )}
      </>
    );
  };

  const getRefreshCallLogsElement = (): JSX.Element => {
    return (
      <Tooltip
        title={intl.formatMessage({ id: 'refresh' })}
        placement={'bottom'}
      >
        <View alignItems={'center'}>
          <Pressable
            onPress={() => {
             props?.reloadCallLogs && props?.reloadCallLogs();
            }}
            style={{justifyContent: 'center'}}
          >
            <Icon
              as={MaterialCommunityIcon}
              name={'reload'}
              size={'5'}
              color={Colors.Custom.Gray500}
            />
          </Pressable>
        </View>
      </Tooltip>
    );
  }

  const callsRightTobBar = (): JSX.Element => {
    return (
      <View mt={2} flex={0.2} style={{ justifyContent: 'center' }} alignContent={'center'} justifyContent={'center'}>
        <HStack alignItems={'center'} justifyContent={'center'}>
        <View>{getRefreshCallLogsElement()}</View>
          <View ml={2} mr={2}>
            <Pressable
              onPress={() => {
                props?.onFilterModal && props?.onFilterModal();
              }}
              style={[
                {
                  width: 32,
                  height: 32,
                  justifyContent: 'center',
                },
              ]}
            >
              <Tooltip
                title={callLogsState?.conversationFilterProps?.count?.join(
                  ' \n'
                )}
                arrowPointAtCenter={false}
                placement={'left'}
                overlayStyle={{ whiteSpace: 'pre-line' }}
              >
                {callLogsState?.conversationFilterProps?.count
                  ?.length ? (
                  <View
                    style={{
                      position: 'absolute',
                      top: -7.5,
                      right: -7.5,
                      backgroundColor: Colors.danger[500],
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={{ color: '#ffffff' }}>
                      {
                        callLogsState?.conversationFilterProps
                          ?.count?.length
                      }
                    </Text>
                  </View>
                ) : null}
               <FilterNewIconSvg
                isEnabled={true}
              />
              </Tooltip>
            </Pressable>
          </View>
        </HStack>
      </View>
    );
  };

  return (
    <View>
      <HStack flex={1} style={{marginTop: 4}} justifyContent={'center'}>
        <View flex={0.8}>
        <SearchBar
          onChange={(value: string) => {
            props?.setSearchText(value);
          }}
          borderWidth={1}
          placeholderText="Search"
          searchBarInputStyles={{
            marginTop: 2,
            marginLeft: 4,
            //marginRight: 4,
            backgroundColor: '#ffffff',
          }}
          isDialPadVisible={props.isDialPadVisible}
        />
        </View>
        {callsRightTobBar()}
      </HStack>
      {props?.isLoading ? (
        <ConversationListLoader listCount={10} selectedInboxTypeCode={''} />
      ) : (
        <div
          style={{
            height: finalListHeight,
            overflow: 'scroll',
            marginTop: 15,
            scrollBehavior: 'smooth',
            marginLeft: 16,
            marginRight: 16,
          }}
        >
          <>
            {' '}
            {props?.callLogs?.length ? (
              renderCallLogList()
            ) : (
              <View
                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
              >
                <NoDataFound displayString="noCallLogs" />
              </View>
            )}
          </>
        </div>
      )}
    </View>
  );
};

export default CallLogList;
